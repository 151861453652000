import styled from 'styled-components';

export const SelectContainer = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
  isWhite?: boolean;
}>`
  position: relative;
  width: 100%;
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};
  margin-bottom: 9px;
  cursor: pointer;
  outline: none;
  background: ${({isEditing, isError}) =>
    isEditing
      ? isEditing && isError
        ? '#FF7E7D'
        : 'transparent'
      : 'transparent'};
  border: ${({isWhite}) =>
    isWhite ? 'solid 1px #F1F1F2' : 'solid 1px rgba(112,112,112,0.3)'};

  border-radius: 15px;
  color: ${({isWhite}) => (isWhite ? `#F1F1F2` : `#707070`)};
  height: 28px;
  text-align: right;
  font-size: 11px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  & > p {
    //position: absolute;
    //color: #07020f;
    text-align: center;
    color: ${({isWhite}) => (isWhite ? `#F1F1F2` : `rgba(112,112,112,0.3)`)};
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 0.1px;
    font-weight: 400;
    //left: 8.5px;
    //top: 0px;
    cursor: pointer;
  }

  & > span {
    line-height: 22px;
    color: black;
    padding: 5px 10px 5px 80px;
  }

  & > svg {
    display: ${({isEditing}) => (isEditing ? `block` : `none`)};
    position: absolute;
    top: 7px;
    right: 9px;
  }
  & > select:focus {
    background: #f2f7ff;
  }
`;

export const DropDownStyle = styled.div<{isVisible: boolean}>`
  position: absolute;
  top: 21px;
  left: 0;
  width: 100%;
  max-height: 115px;
  padding: 2px 0px 2px 8px;
  display: flex;
  flex-direction: column;
  background: #dce0e9;
  z-index: 1;
  border: 1px solid #dce0e9;
  transition: max-height 0.2s ease;
  overflow-y: scroll;
  visibility: ${({isVisible}) => (isVisible ? 'unset' : 'hidden')};
  &::-webkit-scrollbar {
    width: 7px;
    height: 5px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #3a87f0;
    border-radius: 25px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
`;

export const DropdownItem = styled.div<{isActive: boolean}>`
  width: 100%;
  background: ${({isActive}) => (isActive ? '#89BFFF' : 'transparent')};
  border-radius: 7px;
  height: 22px;
  font-size: 11px;
  line-height: 24px;
  text-align: center;
  &:hover {
    background: #89bfff;
  }
`;
