import React, {useState, useEffect, useContext, useRef} from 'react';
import styled, {css} from 'styled-components';
import jwt_decode from 'jwt-decode';
import {useCookies} from 'react-cookie';
import {Navigate, useNavigate} from 'react-router-dom';
import useAlert from 'hooks/useAlert';
import {LoadingCircle} from 'utils/loadingCircle';

import api from 'services/axiosConfig';

import arrowDown from 'images/svg/blue-arrow-down.svg';
import defaultAvatar from 'images/profilowe z tłem.png';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import isValidNip from 'utils/isValidNip';
import {StatisticsContext} from 'contexts/StatisticsContext';

import {
  CountryAndNationalities,
  DeactivationReason,
  MangopayAccount,
  MangopayCompanyAccount,
  ProducerData,
  Regulations,
  Role,
} from './interface';

import {
  AccountBasicButtonWrapper,
  AccountBasicDate,
  AccountBasicDateDateWrapper,
  AccountBasicDateImgWrapper,
  AccountBigBlueBtn,
  AccountGrid,
  AccountHeaderWrapper,
  AccountMainWrapper,
  AccountSellerDate,
  AccountStyleMain,
  AccountFormWrapper,
  MangoPayBasicButton,
  PopUpButton,
  PopupWrapper,
  PopUpContentWrapper,
  PopupHeader,
  AccountProducerAboutMeWrapper,
  InputView,
  NewStyleCheckBoxWrapper,
  HoverTextForEdit,
  PlanAndSubWrapper,
  InputViewForData,
} from './styles';

import {AccountText} from './AccountText';
import {AccountMangopayNaturalPersonForm} from './AccountMangopayNaturalPersonForm';
import {AccountMugoForm} from './AccountMugoForm';
import {ErrorStar} from '../../../components/ErrorStar';
import {
  NewStyleCheckBox,
  NewStyleImgButtonWrapper,
  NewStyleLeftSectionImgButtonWrapper,
  NewStyleLeftSectionImgWrapper,
} from '../../MyAccount/Settings/styles';
import {AccountMangopayCompanyForm} from './AccountMangopayCompanyForm';
import {AccountMangopayFirstTime} from './AccountMangopayFirstTime';
import {Select} from '../../../components/Select';
import {UserDataContext} from '../../../contexts/UserDataContext';
import {isEndDateNear} from '../../../utils/isEndDateNear';
import {light} from '../../../constants/theme';
import {ResolutionContext} from '../../../contexts/ResolutionContext';
import {
  SvgCloseButtonWrapper,
  WrapperForButton,
} from '../../../components/PopUp';
import {EditBtnWrapper, EditSvgWrapper} from 'constants/globalElements';
import {AccountProducerContext} from 'contexts/AccountProducerContext';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SettlementInputWrapper = styled.fieldset<{
  isProducerEditing?: boolean;
}>`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({isProducerEditing}) =>
    isProducerEditing
      ? 'rgba(23, 117, 211, 0.51)'
      : 'rgba(255, 255, 255, 0.11)'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > legend {
    font-size: 11px;
    margin-left: 25px;
    padding-left: 5px;
    font-weight: 400;
    width: 90px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > input {
    width: 100%;
    height: 100%;
    padding: 0 30px;
    padding-bottom: 4px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: left;
    outline: none;

    ::placeholder {
      color: white;
    }
  }
`;

interface GetLabelResponse {
  Address: string;
  BankAccount: string;
  City: string;
  Country: string;
  Email: string;
  FirstName: string;
  Id: string;
  LabelName: string;
  LastName: string;
  NIP: string;
  Pesel: string;
  PhoneNumber: string;
  PostCode: string;
  TaxOffice: string;
  CompanyName: string;
  Website: string;
  LicensorType: number;
  Status: number;
}

export const SettlementSelectWrapper = styled.fieldset<{
  isProducerEditing?: boolean;
}>`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({isProducerEditing}) =>
    isProducerEditing
      ? 'rgba(23, 117, 211, 0.51)'
      : 'rgba(255, 255, 255, 0.11)'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > legend {
    font-size: 11px;
    margin-left: 25px;
    padding-left: 5px;
    font-weight: 400;
    width: 90px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > select {
    width: 90%;
    color: white;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: left;
    padding-left: 30px;
    padding-bottom: 4px;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: 104px 4px;

    & > option {
      background: #060817;
    }
  }
`;

export const SelectView = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  position: relative;
  width: 100%;
  margin-top: 9px;
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};
  & > p {
    position: absolute;
    //color: #07020f;
    color: ${({isEditing, theme}) =>
      !isEditing ? theme.colors.fontBlackOp50 : theme.colors.fontBlackOp100};
    //font-size: 11px;
    font-size: 12px;
    line-height: 24px;
    /* letter-spacing: 0.625px; */
    font-weight: 400;
    left: 8.5px;
    top: 0px;
    cursor: default;
  }
  & > select {
    cursor: ${({isEditing}) => (isEditing ? 'pointer' : 'default')};
    outline: none;
    background: ${({isEditing, isError}) =>
      isEditing
        ? isEditing && isError
          ? '#FF2320;'
          : 'transparent'
        : 'transparent'};
    border: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : 'none'};
    border-bottom: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
    border-radius: ${({isEditing, isError}) =>
      isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
    color: ${({theme}) => theme.colors.fontBlackOp100};
    width: 100%;
    height: 22px;
    line-height: 20px;
    /* text-align: right; */
    text-align: -webkit-right;
    text-align-last: right;
    //font-size: 11px;
    font-size: 12px;
    padding-right: ${({isEditing}) => (isEditing ? `14.5px` : `8.5px`)};
    opacity: 1 !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    & > option {
      background: ${({theme}) => theme.colors.headerContentBackground};

      color: ${({theme}) => theme.colors.fontBlackOp100};
      cursor: pointer;
    }

    & > option:checked {
      color: ${({theme}) => theme.colors.headerBlackFont};
      background-color: ${({theme}) => theme.colors.pageHeader};
      border-radius: 7px;
    }

    &:focus {
      /* border: 1px solid #0091ff; */
      background: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit ? theme.colors.bodyBg : 'transparent'};
      border: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit
          ? ` 1px solid ${theme.colors.blueTheme}`
          : notEdit && isEditing
          ? `solid 1px ${theme.colors.fontBlackOp15}`
          : 'none'};
      border-bottom: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit
          ? `solid 1px ${theme.colors.fontBlue}`
          : notEdit
          ? `solid 1px ${theme.colors.fontBlackOp15}`
          : `solid 1px ${theme.colors.fontBlackOp15}`};
    }
  }
  & > svg {
    display: ${({isEditing}) => (isEditing ? `block` : `none`)};
    position: absolute;
    top: 8px;
    right: 4px;
  }
  & > select:focus {
    /* background: #f2f7ff; */
  }
  @media (max-width: 1024px) {
    width: 367px;
    /* height: 24px; */
    height: 28px;
    margin-top: 0px;
    background: ${({isEditing, theme}) =>
      isEditing ? theme.colors.inputMobileBg : 'transparent'};
    border-radius: ${({isEditing}) => (isEditing ? '5px' : '0px')};

    border: ${({isEditing, theme}) =>
      isEditing
        ? `1px solid ${theme.colors.inputMobileBorderColor}`
        : '1px solid transparent'};
    border-bottom: ${({theme}) =>
      `1px solid ${theme.colors.inputMobileBorderColor}`};
    &:focus-within {
      background: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit ? theme.colors.bodyBg : 'transparent'};
      border: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit
          ? ` 1px solid ${theme.colors.blueTheme}`
          : notEdit && isEditing
          ? `solid 1px ${theme.colors.fontBlackOp15}`
          : 'none'};
      border-bottom: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit
          ? `solid 1px ${theme.colors.fontBlue}`
          : notEdit
          ? `solid 1px ${theme.colors.fontBlackOp15}`
          : `solid 1px ${theme.colors.fontBlackOp15}`};
    }
    display: flex;
    justify-content: end;
    & > select {
      width: auto;
      /* text-align: right; */
      text-align: -webkit-right;
      text-align-last: right;
      text-indent: 5px hanging;
      height: 100%;
      font-size: 13px;
      /* line-height: 20px; */
      line-height: 24px;
      font-weight: 400;
      letter-spacing: 0.625px;
      border: none;
      //opacity: ${({isEditing}) => (isEditing ? '1' : '0.3')};
      color: ${({isEditing, theme}) =>
        isEditing ? theme.colors.fontBlackOp100 : theme.colors.fontBlackOp100};
      padding-left: 0;
      padding-right: ${({isEditing}) => (isEditing ? '22.5px' : '3px')};
      border-radius: 5px;
      &:focus {
        background: transparent;
        border: none;
        border-bottom: none;
      }
    }
    & > p {
      font-size: 13px;
      /* line-height: 22px; */
      line-height: 24px;
      letter-spacing: 0.625px;
      left: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
    }
    & > svg {
      top: 8px;
      right: 8px;
      & > g {
        & > path {
          fill: ${({theme}) => theme.colors.fontBlackOp100};
        }
      }
    }
  }

  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

export const StyledSelectView = styled(SelectView)`
  & select {
    border-radius: 5px;
    /* background: ${({theme}) => theme.colors.textareaBackground}; */
  }
`;

const statusInfo = {
  0: 'Płatnik',
  1: 'Oczekiwanie na dokumenty',
  2: 'Oczekiwanie na zatwierdzenie',
  3: 'Aktywny',
  4: 'Dokumenty odrzucone',
  5: 'Dokumenty nieaktualne',
  6: 'Zablokowany',
};

interface SettlementAddress {
  country: string;
  region: string;
  street: string;
  flatNumber: string;
  postalCode: string;
  buildingNumber: string;
  email: string;
}

export interface City {
  City: string;
  Region: string;
}

const rolesTypeArr = [
  {text: 'Bitmaker', value: 0},
  {text: 'Songwriter', value: 1},
  {text: 'MixMaster', value: 2},
  {text: 'MusicProducent', value: 3},
  {text: 'VideoProducent', value: 4},
  {text: 'SocialMediaManager', value: 5},
  {text: 'Inluencer', value: 6},
  {text: 'TikToker', value: 7},
  {text: 'YoutubeLawyer', value: 8},
  {text: 'GraphicDesigner', value: 9},
  {text: 'WebDesigner', value: 10},
  {text: 'TEst nowych ', value: 100},
  {text: 'TEst nowych second ', value: 101},
];
const rolesTypeObj = {
  0: {text: 'Bitmaker', value: 0},
  1: {text: 'Songwriter', value: 1},
  2: {text: 'MixMaster', value: 2},
  3: {text: 'MusicProducent', value: 3},
  4: {text: 'VideoProducent', value: 4},
  5: {text: 'SocialMediaManager', value: 5},
  6: {text: 'Inluencer', value: 6},
  7: {text: 'TikToker', value: 7},
  8: {text: 'YoutubeLawyer', value: 8},
  9: {text: 'GraphicDesigner', value: 9},
  10: {text: 'WebDesigner', value: 10},
  101: {text: 'TEst nowych second ', value: 101},
  100: {text: 'TEst nowych ', value: 100},
};

const Account: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies([]);

  // console.log('cookies:', cookies);

  const {decodedToken} = useContext(ProducerDataContext);
  const [userData, setUserData] = useContext(UserDataContext);

  if (userData.FirstName === null) {
    window.location.href = '/account/settings';
  }

  const {Alert} = useAlert();

  const [statistics, setStatistics] = useContext(StatisticsContext);

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }

  const [isHoveredForEditBtn, setIsHoveredForEditBtn] =
    useState<boolean>(false);

  const [isMangopayAccountLoading, setIsMangopayAccountLoading] =
    useState<boolean>(false);
  const [isLabelLoading, setIsLabelLoading] = useState<boolean>(false);
  const [mangoPayRegulations, setMangoPayRegulations] =
    useState<boolean>(false);
  const [cities, setCities] = useState<City[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [skill, setSkill] = useState<number | string>('');
  const [pesel, setPesel] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>('');
  const [taxOffice, setTaxOffice] = useState<string>('');
  const [representative, setRepresentative] = useState<string>('');

  const [secondSkill, setSecondSkill] = useState<number | string>('');
  const [deactivationReasons, setDeactivationReasons] = useState<
    DeactivationReason[]
  >([]);
  const [deactivationReason, setDeactivationReason] = useState<number | string>(
    '',
  );
  const [deactivationReasonDescription, setDeactivationReasonDescription] =
    useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [userFirstName, setUserFirstName] = useState<string>('');
  const [userLastName, setUserLastName] = useState<string>('');
  const [userCity, setUserCity] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [producerImage, setProducerImage] = useState('');
  const [producerPlans, setProducerPlans] = useState<{
    ActiveTo: string | null;
    Name: string;
    Id: string;
  }>({ActiveTo: null, Name: '', Id: ''});
  const [errosBasicData, setErrorBasicData] = useState({
    userName: {isError: false, text: ''},
    userFirstName: {isError: false, text: ''},
    userLastName: {isError: false, text: ''},
    postalCode: {isError: false, text: ''},
    skill: {isError: false, text: ''},
    producerImagePreview: {isError: false, text: ''},
    producerImage: {isError: false, text: ''},
    mugoStatus: {isError: false, text: ''},
    showErrors: false,
  });
  const [textForPlansPopup, setTextForPlansPopup] = useState<string>('');
  const [producerAccountIsEndDateNear, setProducerAccountIsEndDateNear] =
    useState<boolean>(false);
  const [producerImagePreview, setProducerImagePreview] = useState('');
  const [
    displayProducerNameAsPublicDisplayName,
    setDisplayProducerNameAsPublicDisplayName,
  ] = useState<boolean>(false);
  const [regulations, setRegulations] = useState<Regulations>({
    acceptMarketingAgreement: false,
    acceptMugoStudioStatute: false,
    acceptPrivatePolicyStatute: false,
  });
  // const [producerData, setProducerData] = useState<ProducerData>({
  //   AcceptMarketingAggreement: false,
  //   AcceptMugoStudioSatute: false,
  //   AcceptPrivatePolcySatute: false,
  //   DisplayProducerNameAsPublicDisplayName: false,
  //   DraftCardId: '',
  //   FirstName: '',
  //   FtpPhotoFilePath: '',
  //   Id: '',
  //   LabelId: '',
  //   LastName: '',
  //   AboutMe: '',
  //   Name: '',
  //   PublishedCardId: '',
  //   Role: 0,
  //   SecondaryRole: 0,
  //   Status: 0,
  //   PostalCode: '',
  //   Comments: [],
  //   NIP: null,
  //   Pesel: '',
  //   CompanyName: '',
  //   Representative: '',
  //   TaxOffice: '',
  //   SettlementAddress: {
  //     Country: '',
  //     FlatNumber: '',
  //     PostalCode: '',
  //     Region: '',
  //     Street: '',
  //     BuildingNumber: '',
  //   },
  //   SettlementFirstName: '',
  //   SettlementLastName: '',
  //   SettlementType: 0,
  // });
  const [basicDataType, setBasicDataType] = useState('name');
  const [settlementType, setSettlementType] = useState<number>(0);
  const [settlementFirstName, setSettlementFirstName] = useState<string>('');
  const [settlementLastName, setSettlementLastName] = useState<string>('');
  const [NIP, setNIP] = useState<string>('');
  const [settlementAddress, setSettlementAddress] = useState<SettlementAddress>(
    {
      country: '',
      region: '',
      street: '',
      flatNumber: '',
      postalCode: '',
      buildingNumber: '',
      email: '',
    },
  );
  const [settlementBirthday, setSettlementBirthday] = useState('');
  const [mangopayAccount, setmangopayAccount] = useState<MangopayAccount>({
    FirstName: '',
    LastName: '',
    Email: '',
    Birthday: '',
    Nationality: '',
    CountryOfResidence: '',
    Address: {
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      PostalCode: '',
      Country: '',
      FlatNumber: '',
      BuildNumber: '',
    },
    Status: -1,
    Type: 0,
    UserCategory: '',
    KYCLevel: '',
    DocIdentityProof: {
      Type: '',
      Status: '',
      RefusedReasonMessage: '',
      RefusedReasonType: '',
      ProcessedDate: '',
      Id: '',
      CreationDate: '',
    },
    Pesel: '',
    TermsAndConditionsAccepted: false,
    IBAN: '',
  });
  const [mangopayType, setMangopayType] = useState<number>(-1);
  const [mangopayFormIsValidated, setMangopayFormIsValidated] =
    useState<boolean>(false);
  const [isFirstMango, setIsFirstMango] = useState(true);

  const [mangopayCompanyAccount, setmangopayCompanyAccount] =
    useState<MangopayCompanyAccount>({
      LegalPersonType: '',
      Name: '',
      LegalRepresentativeBirthday: '',
      LegalRepresentativeCountryOfResidence: '',
      LegalRepresentativeNationality: '',
      LegalRepresentativeFirstName: '',
      LegalRepresentativeLastName: '',
      Email: '',
      CompanyNumber: '',
      TermsAndConditionsAccepted: false,
      HeadquartersAddress: {
        AddressLine1: '',
        AddressLine2: '',
        City: '',
        Region: '',
        PostalCode: '',
        Country: '',
        FlatNumber: '',
        BuildNumber: '',
      },
      LegalRepresentativeAddress: {
        AddressLine1: '',
        AddressLine2: '',
        City: '',
        Region: '',
        PostalCode: '',
        Country: '',
        FlatNumber: '',
        BuildNumber: '',
      },
      IBAN: '',
      Status: -1,
      Type: 0,
      UserCategory: '',
      KYCLevel: '',
      DocIdentityProof: {
        Type: '',
        Status: '',
        RefusedReasonMessage: '',
        RefusedReasonType: '',
        ProcessedDate: '',
        Id: '',
        CreationDate: '',
      },
      DocArticlesOfAssociation: {
        Type: '',
        Status: '',
        RefusedReasonMessage: '',
        RefusedReasonType: '',
        ProcessedDate: '',
        Id: '',
        CreationDate: '',
      },
      DocRegistrationProof: {
        Type: '',
        Status: '',
        RefusedReasonMessage: '',
        RefusedReasonType: '',
        ProcessedDate: '',
        Id: '',
        CreationDate: '',
      },
      UboDeclaration: {
        CreationDate: '',
        Id: '',
        Message: '',
        ProcessedDate: '',
        Reason: '',
        Status: '',
        Ubos: [],
      },
    });

  const [isProducerEditing, setProducerEditing] = useState<boolean>(false);
  const [isSettlementEditing, setSettlementEditing] = useState<boolean>(false);
  const [isBasicDataEditing, setIsBasicDataEditing] = useState<boolean>(false);
  const [producerExist, setProducerExist] = useState<boolean>(false);
  const [isMangopayEditing, setIsMangopayEditing] = useState<boolean>(false);

  const [showSaveQuestion, setShowSaveQuestion] = useState<boolean>(false);

  const [isSettlementAccountCreated, setSettlementAccountCreated] =
    useState<boolean>(false);
  const [currentComment, setCurrentComment] = useState(0);
  const [cityFromPostalCode, setCityFromPostalCode] = useState<string>('');

  const [label, setLabel] = useState({
    CompanyName: '',
    LabelName: '',
    FirstName: '',
    LastName: '',
    Status: 0,
    LicensorType: 0,
  });

  const [countriesArr, setCountriesArr] = useState<CountryAndNationalities[]>(
    [],
  );
  const [nationalitiesArr, setNationalitiesArr] = useState<
    CountryAndNationalities[]
  >([]);

  const navigate = useNavigate();

  const inputFileRef = useRef(null as any);
  const inputIdFileRef = useRef(null as any);
  const imgRef = useRef<HTMLInputElement>(document.createElement('input'));
  const {windowWidth} = useContext(ResolutionContext);
  const [producerData, setProducerData] = useContext(AccountProducerContext);

  const handleMangopayEditingMode = () => {
    if (isMangopayEditing) {
      // setmangopayAccount((prev)=>{})
      // getMangoPayUser();
      location.reload();
      setIsMangopayEditing(false);
    } else if (!isMangopayEditing) {
      setIsMangopayEditing(true);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getImageFile = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();
      const fileSize = fileName.size;
      const maxFileSize = 2 * 1024 * 1024; // 2MB limit
      if (fileSize > maxFileSize) {
        Alert(
          'Rozmiar pliku przekracza 2MB. Wybierz mniejszy plik.',
          'Komunikat',
        );
        return;
      }
      if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
        const base64 = await toBase64(e.target.files[0]);
        const body = base64.toString().split(',')[1];

        // setCurrentUserData((prev) => ({
        //   ...prev,
        //   ImagePath: URL.createObjectURL(e.target.files[0]),
        // }));
        setProducerImagePreview(URL.createObjectURL(e.target.files[0]));
        setProducerImage(body);
        setErrorBasicData((prev) => ({
          ...prev,
          producerImage: {isError: false, text: ' '},
        }));
        Alert(
          `Dodano plik ${e.target.files[0].name.slice(0, 20)}`,
          'Komunikat',
        );
      } else {
        Alert('Wybierz zdjęcie w formacie .jpg lub .png', 'Komunikat');
      }
    }
  };

  const checkArrBeforChangeSkill = (arr, skill) => {
    // console.log(arr.filter((item) => item.value !== skill));
    return arr.filter((item) => item.value !== skill);
  };

  const handleMangopayLoadingRing = (isLoading) =>
    setIsMangopayAccountLoading(isLoading);

  const changeMangoPayType = (type: number) => setMangopayType(type);

  const changeMangoPayStatus = () =>
    setmangopayAccount((prevState) => ({...prevState, Status: 0}));

  const changeMangoPayCompanyStatus = () =>
    setmangopayCompanyAccount((prevState) => ({...prevState, Status: 0}));

  const createMangoPayCompanyAccount = async (
    data: MangopayCompanyAccount,
    idImgs: string[],
  ) => {
    if (isMangopayAccountLoading) return;
    setIsMangopayAccountLoading(true);
    try {
      const body = {
        LegalPersonType: data.LegalPersonType,
        Name: data.Name,
        LegalRepresentativeBirthday: `${data.LegalRepresentativeBirthday}T00:00:00`,
        LegalRepresentativeCountryOfResidence:
          data.LegalRepresentativeCountryOfResidence,
        LegalRepresentativeNationality: data.LegalRepresentativeNationality,
        LegalRepresentativeFirstName: data.LegalRepresentativeFirstName,
        LegalRepresentativeLastName: data.LegalRepresentativeLastName,
        Email: data.Email,
        CompanyNumber: data.CompanyNumber,
        TermsAndConditionsAccepted: data.TermsAndConditionsAccepted,
        HeadquartersAddress: {
          AddressLine1: `${data.HeadquartersAddress.AddressLine1}/${data.HeadquartersAddress.FlatNumber} ${data.HeadquartersAddress.BuildNumber} `,
          AddressLine2: `${data.HeadquartersAddress.FlatNumber} / ${data.HeadquartersAddress.BuildNumber}`,
          City: data.HeadquartersAddress.City,
          Region: data.HeadquartersAddress.City,
          PostalCode: data.HeadquartersAddress.PostalCode,
          Country: data.HeadquartersAddress.Country,
        },
        LegalRepresentativeAddress: {
          AddressLine1: `${data.LegalRepresentativeAddress.AddressLine1}/${data.LegalRepresentativeAddress.FlatNumber} ${data.LegalRepresentativeAddress.BuildNumber} `,
          AddressLine2: `${data.LegalRepresentativeAddress.FlatNumber} / ${data.LegalRepresentativeAddress.BuildNumber}`,
          City: data.LegalRepresentativeAddress.City,
          Region: data.HeadquartersAddress.City,
          PostalCode: data.LegalRepresentativeAddress.PostalCode,
          Country: data.LegalRepresentativeAddress.Country,
        },
        // IBAN: data.IBAN,
      };

      if (mangopayCompanyAccount.Status === -1) {
        const response = await api.post(
          `mangopayaccount/RegisterLegalOwner`,
          body,
          {
            headers: {Authorization: `Bearer ${cookies.token}`},
          },
        );
        setmangopayCompanyAccount(data);
        // setmangopayCompanyAccount((prevState) => ({
        //   ...prevState,
        //   KYCLevel: 'LIGHT',
        //   UserCategory: 'OWNER',
        //   DocIdentityProof: {
        //     Type: '',
        //     Status: 'null',
        //     RefusedReasonMessage: '',
        //     RefusedReasonType: 'DOCUMENT_MISSING',
        //     ProcessedDate: '',
        //     Id: '',
        //     CreationDate: '',
        //   },
        //   DocRegistrationProof: {
        //     Type: '',
        //     Status: 'null',
        //     RefusedReasonMessage: '',
        //     RefusedReasonType: 'DOCUMENT_MISSING',
        //     ProcessedDate: '',
        //     Id: '',
        //     CreationDate: '',
        //   },
        //   DocArticlesOfAssociation: {
        //     Type: '',
        //     Status: 'null',
        //     RefusedReasonMessage: '',
        //     RefusedReasonType: 'DOCUMENT_MISSING',
        //     ProcessedDate: '',
        //     Id: '',
        //     CreationDate: '',
        //   },
        //   UboDeclaration: {
        //     CreationDate: '',
        //     Id: '',
        //     Message: '',
        //     ProcessedDate: '',
        //     Reason: '',
        //     Status: '',
        //     Ubos: [{
        //       Id: '',
        //       FirstName: '',
        //       LastName: '',
        //       Nationality: '',
        //       Birthday: '',
        //       Birthplace: {City: '', Country: ''},
        //       Address: {
        //         AddressLine1: '',
        //         AddressLine2: '',
        //         City: '',
        //         Region: '',
        //         PostalCode: '',
        //         Country: '',
        //         FlatNumber: '',
        //         BuildNumber: '',
        //       }z],
        //   },
        // }));
        // location.reload();
        changeMangoPayCompanyStatus();
        setIsBasicDataEditing(false);
        setSettlementEditing(false);
      } else {
        const response = await api.post(
          `mangopayaccount/UpdateLegalOwner`,
          body,
          {
            headers: {Authorization: `Bearer ${cookies.token}`},
          },
        );
        // Alert(
        //   'Konto MANGOPAY zostało zaktualizowane',
        //   'Komunikat',
        //   'Zamknij',
        //   true,
        // );
        setIsBasicDataEditing(false);
        setSettlementEditing(false);
      }
      // await sendIdScansToMangopay(idImgs);
    } catch (error) {
      console.error(error);
      // if (error.response.data.Error.Message === 'Producer has active offers.') {
      //   return Alert(
      //     'Nie możesz aktualizować danych w Mango posiadając wystawione oferty.',
      //     'Komunikat',
      //   );
      // }
    } finally {
      setIsMangopayAccountLoading(false);
    }
  };

  const createMangoPayAccount = async (
    data: MangopayAccount,
    idImgs: string[],
  ) => {
    const {
      FirstName,
      LastName,
      Email,
      Birthday,
      Nationality,
      CountryOfResidence,
      Address,
      TermsAndConditionsAccepted,
    } = data;
    if (isMangopayAccountLoading) return;
    setIsMangopayAccountLoading(true);
    try {
      const body = {
        FirstName,
        LastName,
        Email,
        TermsAndConditionsAccepted: TermsAndConditionsAccepted,
        Birthday: `${Birthday}T00:00:00`,
        Nationality,
        CountryOfResidence: Address.Country,
        Address: {
          AddressLine1: `${Address.AddressLine1} / ${Address.FlatNumber} ${Address.BuildNumber}`,
          AddressLine2: `${Address.FlatNumber} / ${Address.BuildNumber}`,
          City: Address.City,
          Region: Address.City,
          PostalCode: Address.PostalCode,
          Country: Address.Country,
        },
      };
      // console.log(body, 'body do wysłania');

      if (mangopayAccount.Status === -1) {
        const response = await api.post(
          `mangopayaccount/RegisterNaturalOwner`,
          body,
          {
            headers: {Authorization: `Bearer ${cookies.token}`},
          },
        );
        setmangopayAccount(data);
        setmangopayAccount((prevState) => ({
          ...prevState,
          KYCLevel: 'LIGHT',
          UserCategory: 'OWNER',
          DocIdentityProof: {
            Type: '',
            Status: 'null',
            RefusedReasonMessage: '',
            RefusedReasonType: 'DOCUMENT_MISSING',
            ProcessedDate: '',
            Id: '',
            CreationDate: '',
          },
        }));
        changeMangoPayStatus();
        setIsBasicDataEditing(false);
        setSettlementEditing(false);
      } else {
        const response = await api.post(
          `mangopayaccount/UpdateNaturalOwner`,
          body,
          {
            headers: {Authorization: `Bearer ${cookies.token}`},
          },
        );
        // Alert(
        //   'Konto MANGOPAY zostało zaktualizowane',
        //   'Komunikat',
        //   'Zamknij',
        //   true,
        // );
        setIsBasicDataEditing(false);
        setSettlementEditing(false);
      }

      await sendIdScansToMangopay(idImgs);

      Alert(
        'Formularz z danymi został wysłany, proszę czekać na zweryfikowanie',
        'Komunikat',
        'Zamknij',
        true,
      );
    } catch (error) {
      console.error(error);
      if (error.response.data.Error.Message === 'Producer has active offers.') {
        return Alert(
          'Nie możesz aktualizować danych w Mango posiadając wystawione oferty.',
          'Komunikat',
        );
      }
    } finally {
      setIsMangopayAccountLoading(false);
    }
  };

  const deleteMangoPay = async () => {
    try {
      const respons = await api.delete(`mangopayaccount`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      Alert('konto mangopay usuniete', 'komunikat', 'Zamknij', true);
    } catch (error) {
      console.error(error);
    }
  };

  const getMangoPayUser = async () => {
    if (isMangopayAccountLoading) return;
    setIsMangopayAccountLoading(true);
    try {
      const response = await api.get(`mangopayaccount`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      // console.log(response.data.Response, 'respons');
      if (response.data.Response.Type === 0) {
        // console.log(
        //   'aktualizuje dane ???? w account?',
        //   response.data.Response.Status,
        // );
        setmangopayAccount((prevState) => ({
          ...prevState,
          Birthday: response.data.Response.NaturalUser.Birthday.slice(0, 10),
          CountryOfResidence:
            response.data.Response.NaturalUser.CountryOfResidence,
          Email: response.data.Response.NaturalUser.Email,
          FirstName: response.data.Response.NaturalUser.FirstName,
          LastName: response.data.Response.NaturalUser.LastName,
          Nationality: response.data.Response.NaturalUser.Nationality,
          Status: response.data.Response.Status,
          TermsAndConditionsAccepted:
            response.data.Response.NaturalUser.TermsAndConditionsAccepted,
          KYCLevel: response.data.Response.NaturalUser.KYCLevel,
          Type: response.data.Response.NaturalUser.Type,
          UserCategory: response.data.Response.NaturalUser.UserCategory,
          DocIdentityProof:
            response.data.Response.NaturalUser.DocIdentityProof === null
              ? {
                  Type: '',
                  Status: 'null',
                  RefusedReasonMessage: '',
                  RefusedReasonType: 'DOCUMENT_MISSING',
                  ProcessedDate: '',
                  Id: '',
                  CreationDate: '',
                }
              : response.data.Response.NaturalUser.DocIdentityProof,
          IBAN:
            response.data.Response.NaturalUser.IBAN === null
              ? ''
              : response.data.Response.NaturalUser.IBAN.slice(2),
          Address: {
            ...prevState.Address,
            City: response.data.Response.NaturalUser.Address.City,
            Country: response.data.Response.NaturalUser.Address.Country,
            FlatNumber:
              response.data.Response.NaturalUser.Address.AddressLine2 === null
                ? response.data.Response.NaturalUser.Address.AddressLine2
                : response.data.Response.NaturalUser.Address.AddressLine2.slice(
                    0,
                    response.data.Response.NaturalUser.Address.AddressLine2.indexOf(
                      '/',
                    ),
                  ).trim(),
            BuildNumber:
              response.data.Response.NaturalUser.Address.AddressLine2 === null
                ? response.data.Response.NaturalUser.Address.AddressLine2
                : response.data.Response.NaturalUser.Address.AddressLine2.slice(
                    response.data.Response.NaturalUser.Address.AddressLine2.indexOf(
                      '/',
                    ) + 1,
                  ).trim(),
            PostalCode: response.data.Response.NaturalUser.Address.PostalCode,
            AddressLine1:
              response.data.Response.NaturalUser.Address.AddressLine1 === null
                ? response.data.Response.NaturalUser.Address.AddressLine1
                : response.data.Response.NaturalUser.Address.AddressLine1.slice(
                    0,
                    response.data.Response.NaturalUser.Address.AddressLine1.indexOf(
                      '/',
                    ),
                  ).trim(),
            AddressLine2:
              response.data.Response.NaturalUser.Address.AddressLine2,
          },
        }));
        setMangoPayRegulations(
          response.data.Response.NaturalUser.TermsAndConditionsAccepted,
        );
      }
      if (response.data.Response.Type === 1) {
        setmangopayCompanyAccount((prevState) => ({
          ...prevState,
          LegalPersonType: response.data.Response.LegalUser.LegalPersonType,
          Name: response.data.Response.LegalUser.Name,
          LegalRepresentativeBirthday:
            response.data.Response.LegalUser.LegalRepresentativeBirthday.slice(
              0,
              10,
            ),
          LegalRepresentativeCountryOfResidence:
            response.data.Response.LegalUser
              .LegalRepresentativeCountryOfResidence,
          LegalRepresentativeNationality:
            response.data.Response.LegalUser.LegalRepresentativeNationality,
          LegalRepresentativeFirstName:
            response.data.Response.LegalUser.LegalRepresentativeFirstName,
          LegalRepresentativeLastName:
            response.data.Response.LegalUser.LegalRepresentativeLastName,
          Email: response.data.Response.LegalUser.Email,
          CompanyNumber: response.data.Response.LegalUser.CompanyNumber,
          TermsAndConditionsAccepted:
            response.data.Response.LegalUser.TermsAndConditionsAccepted,
          KYCLevel: response.data.Response.LegalUser.KYCLevel,
          Type: response.data.Response.LegalUser.Type,
          UserCategory: response.data.Response.LegalUser.UserCategory,
          HeadquartersAddress: {
            AddressLine1:
              response.data.Response.LegalUser.HeadquartersAddress.AddressLine1.slice(
                0,
                response.data.Response.LegalUser.HeadquartersAddress.AddressLine1.indexOf(
                  '/',
                ),
              ).trim(),
            AddressLine2:
              response.data.Response.LegalUser.HeadquartersAddress.AddressLine2,
            City: response.data.Response.LegalUser.HeadquartersAddress.City,
            Region: response.data.Response.LegalUser.HeadquartersAddress.Region,
            PostalCode:
              response.data.Response.LegalUser.HeadquartersAddress.PostalCode,
            Country:
              response.data.Response.LegalUser.HeadquartersAddress.Country,
            FlatNumber:
              response.data.Response.LegalUser.HeadquartersAddress.AddressLine2.slice(
                0,
                response.data.Response.LegalUser.HeadquartersAddress.AddressLine2.indexOf(
                  '/',
                ),
              ).trim(),
            BuildNumber:
              response.data.Response.LegalUser.HeadquartersAddress.AddressLine2.slice(
                response.data.Response.LegalUser.HeadquartersAddress.AddressLine2.indexOf(
                  '/',
                ) + 1,
              ).trim(),
          },
          LegalRepresentativeAddress: {
            AddressLine1:
              response.data.Response.LegalUser.LegalRepresentativeAddress
                .AddressLine1 === null
                ? response.data.Response.LegalUser.LegalRepresentativeAddress
                    .AddressLine1
                : response.data.Response.LegalUser.LegalRepresentativeAddress.AddressLine1.slice(
                    0,
                    response.data.Response.LegalUser.LegalRepresentativeAddress.AddressLine1.indexOf(
                      '/',
                    ),
                  ).trim(),
            AddressLine2:
              response.data.Response.LegalUser.LegalRepresentativeAddress
                .AddressLine2,
            City: response.data.Response.LegalUser.LegalRepresentativeAddress
              .City,
            Region:
              response.data.Response.LegalUser.LegalRepresentativeAddress
                .Region,
            PostalCode:
              response.data.Response.LegalUser.LegalRepresentativeAddress
                .PostalCode,
            Country:
              response.data.Response.LegalUser.LegalRepresentativeAddress
                .Country,
            FlatNumber:
              response.data.Response.LegalUser.LegalRepresentativeAddress.AddressLine2.slice(
                0,
                response.data.Response.LegalUser.LegalRepresentativeAddress.AddressLine2.indexOf(
                  '/',
                ),
              ).trim(),
            BuildNumber:
              response.data.Response.LegalUser.LegalRepresentativeAddress.AddressLine2.slice(
                response.data.Response.LegalUser.LegalRepresentativeAddress.AddressLine2.indexOf(
                  '/',
                ) + 1,
              ).trim(),
          },
          IBAN:
            response.data.Response.LegalUser.IBAN === null
              ? ''
              : response.data.Response.LegalUser.IBAN.slice(2),
          DocIdentityProof:
            response.data.Response.LegalUser.DocIdentityProof === null
              ? {
                  Type: '',
                  Status: 'null',
                  RefusedReasonMessage: '',
                  RefusedReasonType: 'DOCUMENT_MISSING',
                  ProcessedDate: '',
                  Id: '',
                  CreationDate: '',
                }
              : response.data.Response.LegalUser.DocIdentityProof,
          DocArticlesOfAssociation:
            response.data.Response.LegalUser.DocArticlesOfAssociation === null
              ? {
                  Type: '',
                  Status: 'null',
                  RefusedReasonMessage: '',
                  RefusedReasonType: 'DOCUMENT_MISSING',
                  ProcessedDate: '',
                  Id: '',
                  CreationDate: '',
                }
              : response.data.Response.LegalUser.DocArticlesOfAssociation,
          DocRegistrationProof:
            response.data.Response.LegalUser.DocRegistrationProof === null
              ? {
                  Type: '',
                  Status: 'null',
                  RefusedReasonMessage: '',
                  RefusedReasonType: 'DOCUMENT_MISSING',
                  ProcessedDate: '',
                  Id: '',
                  CreationDate: '',
                }
              : response.data.Response.LegalUser.DocRegistrationProof,
          UboDeclaration:
            response.data.Response.LegalUser.UboDeclaration === null
              ? {
                  CreationDate: '',
                  Id: '',
                  Message: '',
                  ProcessedDate: '',
                  Reason: '',
                  Status: '',
                  Ubos: [],
                }
              : response.data.Response.LegalUser.UboDeclaration,
          Status: response.data.Response.Status,
        }));
      }
      setMangopayType(
        response.data.Response.Type !== null ? response.data.Response.Type : 0,
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsMangopayAccountLoading(false);
    }
  };

  const resetAllErrors = () => {
    setErrorBasicData({
      userName: {isError: false, text: ''},
      userFirstName: {isError: false, text: ''},
      userLastName: {isError: false, text: ''},
      postalCode: {isError: false, text: ''},
      skill: {isError: false, text: ''},
      producerImagePreview: {isError: false, text: ''},
      producerImage: {isError: false, text: ''},
      mugoStatus: {isError: false, text: ''},
      showErrors: false,
    });
  };

  const changeProducerAccountType = (name: string) => {
    if (name === 'name') {
      setBasicDataType('name');
      setDisplayProducerNameAsPublicDisplayName(false);
      resetAllErrors();
    } else if (name === 'nick') {
      setBasicDataType('nick');
      setDisplayProducerNameAsPublicDisplayName(true);
      resetAllErrors();
    }
  };

  const isEndDate = (endDate) => {
    const now = new Date();
    const diffTime = endDate.getTime() - now.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const getSellerPlans = async () => {
    try {
      const response = await api.get('Seller', {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      if (response.data.Response.CurrentPlan !== null) {
        setProducerPlans({
          ActiveTo: response.data.Response.CurrentPlan.ActiveTo.slice(0, 10),
          Name: response.data.Response.CurrentPlan.Plan.Name,
          Id: response.data.Response.CurrentPlan.Plan.Id,
        });
        if (
          isEndDateNear(new Date(response.data.Response.CurrentPlan.ActiveTo))
        ) {
          setProducerAccountIsEndDateNear(true);
          setTextForPlansPopup(
            `Twój plan zakończy się ${response.data.Response.CurrentPlan.ActiveTo.slice(
              0,
              10,
            )}\n Przedłuż go w zakładce PRO.\n Przejdź do zakładki PRO, aby go przedłużyć.`,
          );

          if (
            isEndDate(new Date(response.data.Response.CurrentPlan.ActiveTo)) < 0
          ) {
            setTextForPlansPopup(
              `Twój plan zakończył się ${response.data.Response.CurrentPlan.ActiveTo.slice(
                0,
                10,
              )}\n Odnów go w zakładce PRO.`,
            );
          }

          // Alert(
          //   `Twój plan niedługo się kończy: ${response.data.Response.CurrentPlan.ActiveTo.slice(
          //     0,
          //     10,
          //   )} Przejdz do zakładki PRO aby go przedłużyć`,
          //   'Komunikat',
          // );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendIdScansToMangopay = async (idImgs: string[]) => {
    if (idImgs.length < 2) return;
    if (isMangopayAccountLoading) return;
    setIsMangopayAccountLoading(true);
    try {
      const body = {
        Type: 'IDENTITY_PROOF',
        Pages: idImgs,
      };

      const response = await api.post(
        `mangopayaccount/RegisterKYCDocument`,
        body,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsMangopayAccountLoading(false);
    }
  };

  const handleMugoStudioRegulation = (key: string) => {
    if (key === 'AcceptMugoStudioStatute') {
      setRegulations((prevState) => ({
        ...prevState,
        acceptMugoStudioStatute: !prevState.acceptMugoStudioStatute,
      }));
    }
    if (key === 'AcceptPrivatePolicyStatute') {
      setRegulations((prevState) => ({
        ...prevState,
        acceptPrivatePolicyStatute: !prevState.acceptPrivatePolicyStatute,
      }));
    }
    if (key === 'AcceptMarketingAgreement') {
      setRegulations((prevState) => ({
        ...prevState,
        acceptMarketingAgreement: !prevState.acceptMarketingAgreement,
      }));
    }
    setErrorBasicData((prev) => ({
      ...prev,
      mugoStatus: {
        isError: false,
        text: ' ',
      },
    }));
  };

  const handleProducerAboutMe = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > 250) {
      setProducerData((prevState) => ({
        ...prevState,
        AboutMe: e.target.value.slice(0, 250),
      }));
      return;
    }
    setProducerData((prevState) => ({...prevState, AboutMe: e.target.value}));
  };

  const getLabel = async () => {
    if (
      !decodedToken.OwnedLabelId ||
      decodedToken.OwnedLabelId === '00000000-0000-0000-0000-000000000000'
    )
      return;
    setIsLabelLoading(true);
    try {
      const response: {data: {Response: GetLabelResponse}} = await api.get(
        `Label`,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      const {
        Address,
        BankAccount,
        City,
        Country,
        Email,
        FirstName,
        Id,
        LabelName,
        LastName,
        NIP,
        Pesel,
        Status,
        LicensorType,
        CompanyName,
        TaxOffice,
      } = response.data.Response;
      setNIP(NIP);
      setPesel(Pesel === null ? '' : Pesel);
      setSettlementFirstName(FirstName);
      setSettlementLastName(LastName);
      setLabel({
        CompanyName,
        LabelName,
        FirstName,
        LastName,
        Status,
        LicensorType,
      });

      if (mangopayAccount.Email === '') {
        setmangopayAccount((prev) => ({...prev, Email}));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLabelLoading(false);
    }
  };

  const validateData = (data) => {
    const onlyLettersWithPolishCharactersAndSpecialRegex =
      /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]+$/;
    const onlyLettersWithPolishCharactersRegex =
      /^[A-Za-zA-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const {
      FirstName,
      LastName,
      Name,
      displayProducerNameAsPublicDisplayName,
      Skill,
      PostalCode,
      ProducerImage,
      MugoStatus,
    } = data;

    const validatedData = {
      FirstName: false,
      LastName: false,
      Name: false,
      Skill: false,
      PostalCode: false,
      ProducerImage: false,
      MugoStatus: false,

      ShowErrors: false,
    };

    if (!MugoStatus) {
      setErrorBasicData((prev) => ({
        ...prev,
        mugoStatus: {
          isError: true,
          text: 'Zaakceptowanie regulaminu MUGO Studio jest wymagane.',
        },
      }));
      validatedData.MugoStatus = true;
    }

    if (
      ProducerImage === null ||
      (ProducerImage === '' && producerImagePreview === '')
    ) {
      setErrorBasicData((prev) => ({
        ...prev,
        producerImage: {isError: true, text: 'Zdjęcie jest wymagane'},
      }));
      validatedData.ProducerImage = true;
    }

    if (displayProducerNameAsPublicDisplayName) {
      if (Name === '') {
        setErrorBasicData((prev) => ({
          ...prev,
          userName: {isError: true, text: 'Nick nie może być pusty'},
        }));
        validatedData.Name = true;
      }
    }

    if (!displayProducerNameAsPublicDisplayName) {
      if (!onlyLettersWithPolishCharactersRegex.test(FirstName)) {
        setErrorBasicData((prev) => ({
          ...prev,
          userFirstName: {
            isError: true,
            text: 'Imię nie może zawierać spacji ',
          },
        }));
        validatedData.FirstName = true;
      }
      if (FirstName.length > 20) {
        setErrorBasicData((prev) => ({
          ...prev,
          userFirstName: {
            isError: true,
            text: 'Imię nie powinno być dłuższe niż 20 znaków',
          },
        }));
        validatedData.FirstName = true;
      }
      if (FirstName === '') {
        setErrorBasicData((prev) => ({
          ...prev,
          userFirstName: {isError: true, text: 'Imię nie może być puste'},
        }));
        validatedData.FirstName = true;
      }

      if (!onlyLettersWithPolishCharactersAndSpecialRegex.test(LastName)) {
        setErrorBasicData((prev) => ({
          ...prev,
          userLastName: {
            isError: true,
            text: 'Nazwisko nie może zawierać spacji',
          },
        }));
        validatedData.LastName = true;
      }
      if (LastName.length > 63) {
        setErrorBasicData((prev) => ({
          ...prev,
          userLastName: {
            isError: true,
            text: 'Nazwisko nie powinno być dłuższe niż 63 znaki',
          },
        }));
        validatedData.LastName = true;
      }
      if (LastName === '') {
        setErrorBasicData((prev) => ({
          ...prev,
          userLastName: {isError: true, text: 'Nazwisko nie może być puste'},
        }));
        validatedData.LastName = true;
      }
    }
    // console.log(PostalCode, 'to jest postal code');
    if (PostalCode === '') {
      setErrorBasicData((prev) => ({
        ...prev,
        postalCode: {
          isError: true,
          text: 'Pole kod pocztowy nie może być puste',
        },
      }));
      validatedData.PostalCode = true;
    }

    if (Skill.length === 0) {
      setErrorBasicData((prev) => ({
        ...prev,
        skill: {
          isError: true,
          text: 'Pole profesji nie może być puste',
        },
      }));
      validatedData.Skill = true;
    }

    const nameOrFirstAndLast = displayProducerNameAsPublicDisplayName
      ? !validatedData.Name
      : !validatedData.FirstName && !validatedData.LastName;

    // console.log(validatedData, 'walidacja na podstwwowe true');
    // console.log(
    //   'to zwraca validacja',
    //   nameOrFirstAndLast &&
    //     !validatedData.PostalCode &&
    //     !validatedData.Skill &&
    //     !validatedData.PostalCode &&
    //     !validatedData.ProducerImage &&
    //     !validatedData.MugoStatus,
    // );
    if (
      nameOrFirstAndLast &&
      !validatedData.PostalCode &&
      !validatedData.Skill &&
      !validatedData.PostalCode &&
      !validatedData.ProducerImage &&
      !validatedData.MugoStatus
    ) {
      resetAllErrors();
      return true;
    } else {
      setErrorBasicData((prev) => ({...prev, showErrors: true}));
      return false;
    }
  };

  const createProducer = async (OwnedLabelId: string) => {
    // if (Object.values(regulations).every((item) => item)) {
    if (
      !validateData({
        FirstName: userFirstName,
        LastName: userLastName,
        Name: userName,
        Skill: skill,
        PostalCode: postalCode,
        ProducerImage: producerImage,
        displayProducerNameAsPublicDisplayName:
          displayProducerNameAsPublicDisplayName,
        MugoStatus: regulations.acceptMugoStudioStatute,
      })
    )
      return;
    if (isMangopayAccountLoading) return;

    setIsMangopayAccountLoading(true);
    // if (

    //   !regulations.acceptMugoStudioStatute
    // )
    // return
    if (decodedToken.ProducerId !== '00000000-0000-0000-0000-000000000000')
      if (skill === '') return console.log('musisz wybrac skill');

    try {
      const body = displayProducerNameAsPublicDisplayName
        ? {
            LabelId: OwnedLabelId,
            Name: userName,
            Role: skill,
            SecondaryRole: secondSkill === 0 ? null : secondSkill,
            DisplayProducerNameAsPublicDisplayName:
              displayProducerNameAsPublicDisplayName,
            PostalCode: postalCode,
            Base64Photo: producerImage,
            AboutMe: producerData.AboutMe,
            AcceptMugoStudioStatute: regulations.acceptMugoStudioStatute,
            AcceptPrivatePolicyStatute: regulations.acceptPrivatePolicyStatute,
            AcceptMarketingAgreement: regulations.acceptMarketingAgreement,
          }
        : {
            LabelId: OwnedLabelId,
            FirstName: userFirstName,
            LastName: userLastName,
            Role: skill,
            SecondaryRole: secondSkill === 0 ? null : secondSkill,
            AboutMe: producerData.AboutMe,
            DisplayProducerNameAsPublicDisplayName:
              displayProducerNameAsPublicDisplayName,
            PostalCode: postalCode,
            Base64Photo: producerImage,
            AcceptMugoStudioStatute: regulations.acceptMugoStudioStatute,
            AcceptPrivatePolicyStatute: regulations.acceptPrivatePolicyStatute,
            AcceptMarketingAgreement: regulations.acceptMarketingAgreement,
          };
      // console.log(body, 'create producer');
      // console.log(JSON.stringify(body));
      const response = await api.post(`Producer`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      await getCitiesFromPostalCode(postalCode);
      await refreshAuthToken();
      Alert('Udało się stworzyć producenta', 'Komunikat');
      // navigate(0);
    } catch (error) {
      console.error(error);
      Alert(
        'Coś poszło nie tak, spróbuj ponownie za chwilę.',
        'Komunikat',
        'Ok',
        true,
      );
    } finally {
      setIsMangopayAccountLoading(false);
      setIsBasicDataEditing(false);
    }
    // }
  };

  const editProducer = async (producerId: string) => {
    if (
      !validateData({
        FirstName: userFirstName,
        LastName: userLastName,
        Name: userName,
        Skill: skill,
        PostalCode: postalCode,
        ProducerImage: producerImage,
        displayProducerNameAsPublicDisplayName:
          displayProducerNameAsPublicDisplayName,
        MugoStatus: regulations.acceptMugoStudioStatute,
      })
    )
      return;
    if (isMangopayAccountLoading) return;
    setIsMangopayAccountLoading(true);
    if (displayProducerNameAsPublicDisplayName && !userName) return;
    if (
      !displayProducerNameAsPublicDisplayName &&
      (!userFirstName || !userLastName)
    )
      return;

    try {
      const body = displayProducerNameAsPublicDisplayName
        ? {
            ProducerId: producerId,
            // FirstName: userFirstName,
            // LastName: userLastName,
            AboutMe: producerData.AboutMe,
            Name: userName,
            Role: skill,
            SecondaryRole: secondSkill === 0 ? null : secondSkill,
            DisplayProducerNameAsPublicDisplayName:
              displayProducerNameAsPublicDisplayName,
            PostalCode: postalCode,
            AcceptMugoStudioStatute: regulations.acceptMugoStudioStatute,
            AcceptPrivatePolicyStatute: regulations.acceptPrivatePolicyStatute,
            AcceptMarketingAgreement: regulations.acceptMarketingAgreement,
            RemoveCurrenntPhoto: producerImage === null ? true : false,
            Base64Photo: producerImage,
            SettlementType: settlementType,
            SettlementFirstName: settlementFirstName,
            SettlementLastName: settlementLastName,
            SettlementAddress: {
              Country: settlementAddress.country,
              Region: settlementAddress.region,
              Street: settlementAddress.street,
              FlatNumber: settlementAddress.flatNumber,
              BuildingNumber: settlementAddress.buildingNumber,
              PostalCode: settlementAddress.postalCode,
            },
            Nip: NIP,
            Pesel: pesel,
          }
        : {
            ProducerId: producerId,
            FirstName: userFirstName,
            LastName: userLastName,
            // Name: userName,
            Role: skill,
            SecondaryRole: secondSkill === 0 ? null : secondSkill,
            DisplayProducerNameAsPublicDisplayName:
              displayProducerNameAsPublicDisplayName,
            PostalCode: postalCode,
            Base64Photo: producerImage,
            AboutMe: producerData.AboutMe,
            AcceptMugoStudioStatute: regulations.acceptMugoStudioStatute,
            AcceptPrivatePolicyStatute: regulations.acceptPrivatePolicyStatute,
            AcceptMarketingAgreement: regulations.acceptMarketingAgreement,
            RemoveCurrenntPhoto: producerImage === null ? true : false,
            SettlementType: settlementType,
            SettlementFirstName: settlementFirstName,
            SettlementLastName: settlementLastName,
            SettlementAddress: {
              Country: settlementAddress.country,
              Region: settlementAddress.region,
              Street: settlementAddress.street,
              FlatNumber: settlementAddress.flatNumber,
              BuildingNumber: settlementAddress.buildingNumber,
              PostalCode: settlementAddress.postalCode,
            },
            Nip: NIP,
            Pesel: pesel,
          };
      // console.log(JSON.stringify(body));
      const response = await api.put(`Producer`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });

      await getCitiesFromPostalCode(postalCode);
      // refreshAuthToken();
      // getProducerData(decodedToken.ProducerId);
      setProducerEditing(false);
      setSettlementEditing(false);

      Alert('Edytowano dane podstawowe', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert('Coś poszło nie tak, proszę spróbować później', 'Komunikat');
    } finally {
      setIsBasicDataEditing(false);
      setIsMangopayAccountLoading(false);
    }
  };

  const getProducerData = async (producerId: string) => {
    setIsMangopayAccountLoading(true);

    try {
      const response = await api.get(`Producer/${producerId}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setProducerExist(true);
      setProducerData(response.data.Response);
      const {
        Role,
        SecondaryRole,
        LastName,
        FirstName,
        Name,
        DisplayProducerNameAsPublicDisplayName,
        PostalCode,
        SettlementType,
        SettlementFirstName,
        SettlementLastName,
        NIP,
        SettlementAddress,
        FtpPhotoFilePath,
        AcceptMarketingAgreement,
        AcceptMugoStudioStatute,
        AcceptPrivatePolicyStatute,
        Pesel,
        AboutMe,
        Representative,
        CompanyName,
        TaxOffice,
      } = response.data.Response;
      setSkill(Role);
      setSecondSkill(SecondaryRole === null ? '' : SecondaryRole);
      setUserName(Name);
      setUserFirstName(FirstName);
      setUserLastName(LastName);
      setDisplayProducerNameAsPublicDisplayName(
        DisplayProducerNameAsPublicDisplayName,
      );
      setBasicDataType(
        DisplayProducerNameAsPublicDisplayName ? 'nick' : 'name',
      );
      setPesel(Pesel === null ? '' : Pesel);
      setRepresentative(Representative);
      setPostalCode(PostalCode);
      setCompanyName(CompanyName);
      setTaxOffice(TaxOffice);
      setSettlementFirstName(SettlementFirstName);
      setSettlementLastName(SettlementLastName);
      setSettlementType(SettlementType);
      setNIP(NIP === null ? '' : NIP);
      setSettlementAddress({
        country: SettlementAddress.Country,
        region: SettlementAddress.Region,
        flatNumber: SettlementAddress.FlatNumber,
        postalCode: SettlementAddress.PostalCode,
        street: SettlementAddress.Street,
        buildingNumber: SettlementAddress.BuildingNumber,
        email: SettlementAddress.Email,
      });
      setProducerImagePreview(FtpPhotoFilePath);
      // setProducerImage(FtpPhotoFilePath);
      setSettlementAccountCreated(SettlementAddress.Country ? true : false);
      setRegulations({
        acceptMarketingAgreement: AcceptMarketingAgreement,
        acceptMugoStudioStatute: AcceptMugoStudioStatute,
        acceptPrivatePolicyStatute: AcceptPrivatePolicyStatute,
      });
      // if (mangopayAccount.FirstName === '') {
      //   setmangopayAccount((prevState) => ({
      //     ...prevState,
      //     FirstName,
      //     LastName,
      //     City: SettlementAddress.Region,
      //     Country: SettlementAddress.Country,
      //     CountryOfResidence: SettlementAddress.Country,
      //     Email: SettlementAddress.Email,
      //     Nationality: SettlementAddress.Country,
      //     PostalCode: SettlementAddress.PostalCode,
      //     Street: SettlementAddress.Street,
      //   }));
      // }
      getMangoPayUser();
    } catch (error) {
      console.log(error);
    } finally {
      setIsMangopayAccountLoading(false);
    }
  };

  const deleteComment = async (commentId: string, producerId: string) => {
    try {
      const body = {
        CommentId: commentId,
        ProducerId: producerId,
      };

      const response = await api.delete(`Producer/Comments`, {
        data: {...body},
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      getProducerData(decodedToken.ProducerId);
    } catch (error) {
      console.log(error);
    }
  };

  const deactivateProducer = async (producerId: string) => {
    if (!producerId || producerId === '00000000-0000-0000-0000-000000000000')
      return;
    if (deactivationReason === '') return;
    if (deactivationReason === 2 && !deactivationReasonDescription) return;
    try {
      const body = {
        ProducerId: producerId,
        Reason: deactivationReason,
        ReasonDescription: deactivationReasonDescription,
      };

      const response = await api.delete(`Producer`, {
        data: {...body},
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      refreshAuthToken();
    } catch (error) {
      console.error(error);
    }
  };

  const refreshAuthToken = async () => {
    try {
      const body = {};

      const response = await api.post(`Auth/RefreshToken`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });

      const date = new Date();
      date.setTime(
        date.getTime() + response.data.Response.LifetimeInMinuts * 60 * 1000,
      );

      setCookie('token', response.data.Response.Token, {
        expires: date,
      });
      navigate(0);
    } catch (error) {
      console.error(error);
    }
  };

  // const getTokenMugo = () => {
  //   const locationSearch = window.location.search;
  //   if (locationSearch.split('').length > 1) {
  //     const array = locationSearch.split('?');
  //     const token = array[1];
  //     setTokenMugo(token);
  //   }
  // };

  const getCitiesFromPostalCode = async (PostalCode: string) => {
    if (!PostalCode) return;

    try {
      const params = {
        PostalCode,
      };

      const response = await api.get('Localizations', {params});
      setCities(response.data.Response);
      setCityFromPostalCode(response.data.Response[0].City);
    } catch (error) {
      console.log(error);
    }
  };

  const getRoles = async () => {
    try {
      const response = await api.get('Producer/Roles');
      setRoles(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const getDeactivationReasons = async () => {
    try {
      const response = await api.get('Producer/DeactivationReasons');
      setDeactivationReasons(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const getCountries = async () => {
    try {
      const response = await api.get('MangopayAccount/countries');
      setCountriesArr(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const getNationalities = async () => {
    try {
      const response = await api.get('MangopayAccount/nationalities');
      setNationalitiesArr(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000') {
      setIsBasicDataEditing(true);
    }
  }, [decodedToken.ProducerId]);

  useEffect(() => {
    if (!cookies.token && window.location.search.split('').length < 1) {
      window.location.href = '/identity/login';
      return;
    }

    if (!cookies.token) return;
    getCountries();
    getNationalities();
    getRoles();
    getDeactivationReasons();
  }, [cookies]);

  useEffect(() => {
    if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000')
      return;
    getProducerData(decodedToken.ProducerId);
    getSellerPlans();
  }, []);

  useEffect(() => {
    getCitiesFromPostalCode(producerData.PostalCode);
  }, [producerData]);

  useEffect(() => {
    if (producerData.LabelId && !isSettlementAccountCreated) {
      getLabel();
    }
  }, [producerData]);

  const saveProducerDate = () => {
    // setIsBasicDataEditing(false);

    if (producerExist) {
      // console.log('zapisuje producera po edycji');
      editProducer(decodedToken.ProducerId);
    } else {
      // console.log('nowe konto');
      createProducer(decodedToken.OwnedLabelId);
    }
  };

  const ToggleEditProducerDate = async () => {
    if (isBasicDataEditing) {
      getProducerData(decodedToken.ProducerId);
      setIsBasicDataEditing(false);
    } else if (!isBasicDataEditing) {
      setIsBasicDataEditing(true);
    }
  };

  const handleSkillChange = (value) => {
    setSkill(value);
    handleSecondSkillChange(null);
    // console.log('skil:', skill, 'secondSKil:', secondSkill);
  };

  const handleSkillSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSkill(+e.target.value);
    setErrorBasicData((prev) => ({
      ...prev,
      skill: {
        isError: false,
        text: ' ',
      },
    }));
    handleSecondSkillChange(null);
  };

  const handleSecondSkillSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSecondSkill(+e.target.value);
    setErrorBasicData((prev) => ({
      ...prev,
      skill: {
        isError: false,
        text: ' ',
      },
    }));
  };

  const handleSecondSkillChange = (value) => setSecondSkill(value);

  const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
    setErrorBasicData((prev) => ({
      ...prev,
      userName: {
        isError: false,
        text: ' ',
      },
    }));
  };

  const handleUserFirstNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const filteredValue = e.target.value.replace(
      /[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g,
      '',
    );
    setUserFirstName(filteredValue);
    setErrorBasicData((prev) => ({
      ...prev,
      userFirstName: {
        isError: false,
        text: ' ',
      },
    }));
  };

  const handleUserLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filteredValue = e.target.value.replace(
      /[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g,
      '',
    );
    setUserLastName(filteredValue);
    setErrorBasicData((prev) => ({
      ...prev,
      userLastName: {
        isError: false,
        text: ' ',
      },
    }));
  };

  const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCode(
      e.target.value
        .replace(/\D/g, '')
        .slice(0, 6)
        .replace(/(\d{2})(\d{1,3})/, '$1-$2'),
    );
    setErrorBasicData((prev) => ({
      ...prev,
      postalCode: {
        isError: false,
        text: ' ',
      },
    }));
  };

  const navigateTo = (url: string) => navigate(url);

  const goToEdit = () => {
    setIsMangopayEditing(true);
    if (mangopayType === -1) {
      setMangopayType(0);
    }
  };

  const saveOnlineProdcuer = () => {
    saveProducerDate();
    setShowSaveQuestion(false);
  };
  const saveMangoandProducer = async () => {
    if (mangopayFormIsValidated) {
      if (mangopayType === 0) {
        await createMangoPayAccount(mangopayAccount, []);
        // await saveProducerDate();
      } else {
        await createMangoPayCompanyAccount(mangopayCompanyAccount, []);
        // await saveProducerDate();
      }
    } else {
      Alert(
        'Wprowadzone dane w formularzu są niekompletne, proszę poprawić dane przed ponownym wysłaniem',
        'Komunikat',
      );
    }
    setShowSaveQuestion(false);
  };

  const showSavePopUp = (data, validated) => {
    if (data.Type === 0) {
      setmangopayAccount(data);
    } else {
      setmangopayCompanyAccount(data);
    }
    setMangopayFormIsValidated(validated);
    setShowSaveQuestion(true);
  };

  // console.log(producerPlans.ActiveTo,'plan aktywny do')

  const setCookieForProducerPlan = () => {
    // console.log('ustawiam cookie');
    setCookie('producerAccountIsEndDateNear', true, {maxAge: 3600});
  };

  const producerAccountIsEndPopup = (
    <PopupWrapper>
      <PopUpContentWrapper>
        <SvgCloseButtonWrapper
          onClick={() => {
            setCookieForProducerPlan();
            setProducerAccountIsEndDateNear(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="13"
            viewBox="0 0 13 13"
          >
            <path
              id="Path_3807"
              data-name="Path 3807"
              d="M23.688,21.869l-1.819,1.819-4.681-4.681-4.681,4.681-1.819-1.819,4.681-4.681-4.681-4.681,1.819-1.819,4.681,4.681,4.681-4.681,1.819,1.819-4.681,4.681Z"
              transform="translate(-10.688 -10.688)"
              fill="#07020f"
            />
          </svg>
        </SvgCloseButtonWrapper>
        <PopupHeader>{/*<h4>Komunikat</h4>*/}</PopupHeader>
        <pre>{textForPlansPopup}</pre>
        <PopUpButton
          onClick={() => {
            // console.log('ide do pro');
            setCookieForProducerPlan();
            window.location.href = 'pro';
          }}
          isBlue={true}
        >
          Idź do PRO
        </PopUpButton>
      </PopUpContentWrapper>
    </PopupWrapper>
  );

  const popup = (
    <PopupWrapper>
      <PopUpContentWrapper>
        <PopupHeader></PopupHeader>
        <p>Czy chcesz wysłać formularz mangopay przed wyjściem?</p>
        <div>
          <PopUpButton onClick={saveMangoandProducer} isBlue={true}>
            Tak
          </PopUpButton>
          <PopUpButton onClick={saveOnlineProdcuer}>Nie</PopUpButton>
        </div>
      </PopUpContentWrapper>
    </PopupWrapper>
  );

  return (
    <AccountMainWrapper>
      {cookies.producerAccountIsEndDateNear === undefined
        ? producerAccountIsEndDateNear
          ? producerAccountIsEndPopup
          : null
        : null}
      <AccountStyleMain>
        {(isMangopayAccountLoading || isLabelLoading) && <LoadingCircle />}
        <AccountHeaderWrapper>
          {' '}
          {/* {windowWidth < 1024 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.5"
              height="20.074"
              viewBox="0 0 11.5 20.074"
            >
              <path
                id="Path_4070"
                data-name="Path 4070"
                d="M11.093,18.031,2.851,10.137l8.141-7.86a1.362,1.362,0,0,0,.1-1.846,1.361,1.361,0,0,0-1.845.1L0,10.139,9.344,19.78a1.346,1.346,0,0,0,1.834.085,1.347,1.347,0,0,0-.085-1.834"
                transform="translate(0 -0.114)"
                fill="#fff"
              />
            </svg>
          )} */}
          <p>Profil Twórcy</p>
          {/*{isBasicDataEditing ? (*/}
          {/*  <BackButton onClick={() => location.reload()}>*/}
          {/*    <svg*/}
          {/*      id="Arrow_Top_Bar_Accent"*/}
          {/*      data-name="Arrow – Top Bar / Accent"*/}
          {/*      xmlns="http://www.w3.org/2000/svg"*/}
          {/*      width="8.195"*/}
          {/*      height="14"*/}
          {/*      viewBox="0 0 8.195 14"*/}
          {/*    >*/}
          {/*      <path*/}
          {/*        id="Arrow"*/}
          {/*        d="M6.513,13.715.2,7.475a.666.666,0,0,1,0-.95L6.513.285a.994.994,0,0,1,1.394,0,.967.967,0,0,1,0,1.377L2.506,7l5.4,5.337a.967.967,0,0,1,0,1.377.994.994,0,0,1-1.394,0"*/}
          {/*        transform="translate(0 0)"*/}
          {/*        fill="#fff"*/}
          {/*      />*/}
          {/*    </svg>*/}
          {/*  </BackButton>*/}
          {/*) : (*/}
          {/*  <BackButton onClick={() => navigate(-1)}>*/}
          {/*    <svg*/}
          {/*      id="Arrow_Top_Bar_Accent"*/}
          {/*      data-name="Arrow – Top Bar / Accent"*/}
          {/*      xmlns="http://www.w3.org/2000/svg"*/}
          {/*      width="8.195"*/}
          {/*      height="14"*/}
          {/*      viewBox="0 0 8.195 14"*/}
          {/*    >*/}
          {/*      <path*/}
          {/*        id="Arrow"*/}
          {/*        d="M6.513,13.715.2,7.475a.666.666,0,0,1,0-.95L6.513.285a.994.994,0,0,1,1.394,0,.967.967,0,0,1,0,1.377L2.506,7l5.4,5.337a.967.967,0,0,1,0,1.377.994.994,0,0,1-1.394,0"*/}
          {/*        transform="translate(0 0)"*/}
          {/*        fill="#fff"*/}
          {/*      />*/}
          {/*    </svg>*/}
          {/*  </BackButton>*/}
          {/*)}*/}
          {/*{isBasicDataEditing ? (*/}
          {/*  <SaveAndEditButton onClick={saveProducerDate}>*/}
          {/*    <svg*/}
          {/*      xmlns="http://www.w3.org/2000/svg"*/}
          {/*      width="14"*/}
          {/*      height="18"*/}
          {/*      viewBox="0 0 14 18"*/}
          {/*    >*/}
          {/*      <defs>*/}
          {/*        <clipPath id="clip-path">*/}
          {/*          <rect width="14" height="18" fill="none" />*/}
          {/*        </clipPath>*/}
          {/*      </defs>*/}
          {/*      <g*/}
          {/*        id="Icons_24px_Document"*/}
          {/*        data-name="Icons – 24px / Document"*/}
          {/*        clipPath="url(#clip-path)"*/}
          {/*      >*/}
          {/*        <path*/}
          {/*          id="Icons_24px_Document-2"*/}
          {/*          data-name="Icons – 24px / Document"*/}
          {/*          d="M14,18H0V0H9.384L9.6.194l4.2,4.154.2.216V18ZM1.4,1.384V16.616H12.6V5.539H8.4V1.384Zm8.4,1V4.154h1.794Zm.7,11.467h-7V12.461h7v1.384Zm0-2.769h-7V9.692h7v1.384Zm0-2.769h-7V6.923h7V8.307Z"*/}
          {/*          fill="#fff"*/}
          {/*        />*/}
          {/*      </g>*/}
          {/*    </svg>*/}
          {/*  </SaveAndEditButton>*/}
          {/*) : (*/}
          {/*  <SaveAndEditButton onClick={goToEdit}>*/}
          {/*    <svg*/}
          {/*      xmlns="http://www.w3.org/2000/svg"*/}
          {/*      width="17.27"*/}
          {/*      height="16.658"*/}
          {/*      viewBox="0 0 17.27 16.658"*/}
          {/*    >*/}
          {/*      <g*/}
          {/*        id="Icon_feather-edit-3"*/}
          {/*        data-name="Icon feather-edit-3"*/}
          {/*        transform="translate(-3.75 -3.41)"*/}
          {/*      >*/}
          {/*        <path*/}
          {/*          id="Path_3712"*/}
          {/*          data-name="Path 3712"*/}
          {/*          d="M18,30h7.885"*/}
          {/*          transform="translate(-5.615 -10.682)"*/}
          {/*          fill="none"*/}
          {/*          stroke="rgba(248,248,248,0.86)"*/}
          {/*          strokeLinecap="round"*/}
          {/*          strokeLinejoin="round"*/}
          {/*          strokeWidth="1.5"*/}
          {/*        />*/}
          {/*        <path*/}
          {/*          id="Path_3713"*/}
          {/*          data-name="Path 3713"*/}
          {/*          d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"*/}
          {/*          fill="none"*/}
          {/*          stroke="rgba(248,248,248,0.86)"*/}
          {/*          strokeLinecap="round"*/}
          {/*          strokeLinejoin="round"*/}
          {/*          strokeWidth="1.5"*/}
          {/*        />*/}
          {/*      </g>*/}
          {/*    </svg>*/}
          {/*  </SaveAndEditButton>*/}
          {/*)}*/}
        </AccountHeaderWrapper>
        <AccountGrid>
          <AccountBasicDate isEditing={isBasicDataEditing}>
            {isHoveredForEditBtn && (
              <HoverTextForEdit>
                <p>{isBasicDataEditing ? 'Anuluj' : 'Edytuj'}</p>
              </HoverTextForEdit>
            )}
            <EditBtnWrapper
              onMouseEnter={() => setIsHoveredForEditBtn(true)}
              onMouseLeave={() => setIsHoveredForEditBtn(false)}
              isEditing={isBasicDataEditing}
            >
              <EditSvgWrapper
                isEditing={isBasicDataEditing}
                onClick={ToggleEditProducerDate}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.27"
                  height="16.658"
                  viewBox="0 0 17.27 16.658"
                >
                  <g
                    id="Icon_feather-edit-3"
                    data-name="Icon feather-edit-3"
                    transform="translate(-3.75 -3.41)"
                  >
                    <path
                      id="Path_3712"
                      data-name="Path 3712"
                      d="M18,30h7.885"
                      transform="translate(-5.615 -10.682)"
                      fill="none"
                      stroke={
                        isBasicDataEditing
                          ? 'rgba(58,135,240,1)'
                          : 'rgba(7,2,15,0.7)'
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                    <path
                      id="Path_3713"
                      data-name="Path 3713"
                      d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                      fill="none"
                      stroke={
                        isBasicDataEditing
                          ? 'rgba(58,135,240,1)'
                          : 'rgba(7,2,15,0.7)'
                      }
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </g>
                </svg>
              </EditSvgWrapper>
            </EditBtnWrapper>
            {/*<AccountBasicDateImgWrapper>*/}
            <p>
              {windowWidth >= 1024
                ? 'Profil twórcy - dane podstawowe'
                : 'Dane podstawowe'}
            </p>
            {/*  <img src={producerData.FtpPhotoFilePath} alt="" />*/}
            {/*</AccountBasicDateImgWrapper>*/}
            <AccountBasicDateImgWrapper
              style={
                errosBasicData.producerImage.isError
                  ? {borderColor: 'red'}
                  : null
              }
              isNotPhoto={
                producerImagePreview === null || producerImagePreview === ''
              }
              isEditing={isBasicDataEditing}
            >
              {producerImagePreview !== null && producerImagePreview !== '' ? (
                <img src={producerImagePreview} alt="Awatar" />
              ) : isBasicDataEditing ? null : (
                <img src={defaultAvatar} alt="Awatar domyślny" />
                // <svg
                //   xmlns="http://www.w3.org/2000/svg"
                //   width="61.719"
                //   height="64"
                //   viewBox="0 0 61.719 64"
                // >
                //   <defs>
                //     <clipPath id="clip-path">
                //       <rect
                //         id="Rectangle_3689"
                //         data-name="Rectangle 3689"
                //         width="61.719"
                //         height="64"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //     </clipPath>
                //   </defs>
                //   <g
                //     id="Group_6791"
                //     data-name="Group 6791"
                //     transform="translate(0 0)"
                //   >
                //     <g
                //       id="Group_6790"
                //       data-name="Group 6790"
                //       transform="translate(0 0)"
                //       clipPath="url(#clip-path)"
                //     >
                //       <path
                //         id="Path_4071"
                //         data-name="Path 4071"
                //         d="M21.735,68.319A21.759,21.759,0,0,1,0,46.584a3.485,3.485,0,0,1,6.971,0A14.781,14.781,0,0,0,21.735,61.349a3.485,3.485,0,0,1,0,6.97"
                //         transform="translate(0 -4.318)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4072"
                //         data-name="Path 4072"
                //         d="M44.194,45.181H33.907a3.485,3.485,0,0,1,0-6.97h6.7C39.625,21.7,32.111,7.847,32.029,7.7a3.485,3.485,0,1,1,6.108-3.358c.391.709,9.541,17.584,9.541,37.354a3.484,3.484,0,0,1-3.484,3.485"
                //         transform="translate(-3.048 -0.254)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4073"
                //         data-name="Path 4073"
                //         d="M21.735,68.319A21.759,21.759,0,0,1,0,46.584a3.485,3.485,0,0,1,6.971,0A14.781,14.781,0,0,0,21.735,61.349a3.485,3.485,0,0,1,0,6.97"
                //         transform="translate(0 -4.318)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4074"
                //         data-name="Path 4074"
                //         d="M44.194,45.181H33.907a3.485,3.485,0,0,1,0-6.97h6.7C39.625,21.7,32.111,7.847,32.029,7.7a3.485,3.485,0,1,1,6.108-3.358c.391.709,9.541,17.584,9.541,37.354a3.484,3.484,0,0,1-3.484,3.485"
                //         transform="translate(-3.048 -0.254)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4075"
                //         data-name="Path 4075"
                //         d="M3.485,11.533A3.485,3.485,0,0,1,0,8.048V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                //         transform="translate(0 0)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4076"
                //         data-name="Path 4076"
                //         d="M3.485,11.533A3.485,3.485,0,0,1,0,8.048V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                //         transform="translate(0 0)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4077"
                //         data-name="Path 4077"
                //         d="M64.33,11.533a3.485,3.485,0,0,1-3.485-3.485V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                //         transform="translate(-6.097 0)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //       <path
                //         id="Path_4078"
                //         data-name="Path 4078"
                //         d="M64.33,11.533a3.485,3.485,0,0,1-3.485-3.485V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                //         transform="translate(-6.097 0)"
                //         fill="rgba(23,22,29,0.12)"
                //       />
                //     </g>
                //   </g>
                // </svg>
              )}
              {(producerImagePreview === null && isBasicDataEditing) ||
              (producerImagePreview === '' && isBasicDataEditing) ? (
                <>
                  <img src={defaultAvatar} alt="Awatar domyślny" />
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="61.719"
                    height="64"
                    viewBox="0 0 61.719 64"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_3689"
                          data-name="Rectangle 3689"
                          width="61.719"
                          height="64"
                          fill="rgba(23,22,29,0.12)"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Group_6791"
                      data-name="Group 6791"
                      transform="translate(0 0)"
                    >
                      <g
                        id="Group_6790"
                        data-name="Group 6790"
                        transform="translate(0 0)"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Path_4071"
                          data-name="Path 4071"
                          d="M21.735,68.319A21.759,21.759,0,0,1,0,46.584a3.485,3.485,0,0,1,6.971,0A14.781,14.781,0,0,0,21.735,61.349a3.485,3.485,0,0,1,0,6.97"
                          transform="translate(0 -4.318)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4072"
                          data-name="Path 4072"
                          d="M44.194,45.181H33.907a3.485,3.485,0,0,1,0-6.97h6.7C39.625,21.7,32.111,7.847,32.029,7.7a3.485,3.485,0,1,1,6.108-3.358c.391.709,9.541,17.584,9.541,37.354a3.484,3.484,0,0,1-3.484,3.485"
                          transform="translate(-3.048 -0.254)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4073"
                          data-name="Path 4073"
                          d="M21.735,68.319A21.759,21.759,0,0,1,0,46.584a3.485,3.485,0,0,1,6.971,0A14.781,14.781,0,0,0,21.735,61.349a3.485,3.485,0,0,1,0,6.97"
                          transform="translate(0 -4.318)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4074"
                          data-name="Path 4074"
                          d="M44.194,45.181H33.907a3.485,3.485,0,0,1,0-6.97h6.7C39.625,21.7,32.111,7.847,32.029,7.7a3.485,3.485,0,1,1,6.108-3.358c.391.709,9.541,17.584,9.541,37.354a3.484,3.484,0,0,1-3.484,3.485"
                          transform="translate(-3.048 -0.254)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4075"
                          data-name="Path 4075"
                          d="M3.485,11.533A3.485,3.485,0,0,1,0,8.048V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                          transform="translate(0 0)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4076"
                          data-name="Path 4076"
                          d="M3.485,11.533A3.485,3.485,0,0,1,0,8.048V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                          transform="translate(0 0)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4077"
                          data-name="Path 4077"
                          d="M64.33,11.533a3.485,3.485,0,0,1-3.485-3.485V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                          transform="translate(-6.097 0)"
                          fill="rgba(23,22,29,0.12)"
                        />
                        <path
                          id="Path_4078"
                          data-name="Path 4078"
                          d="M64.33,11.533a3.485,3.485,0,0,1-3.485-3.485V3.485a3.485,3.485,0,1,1,6.97,0V8.048a3.485,3.485,0,0,1-3.485,3.485"
                          transform="translate(-6.097 0)"
                          fill="rgba(23,22,29,0.12)"
                        />
                      </g>
                    </g>
                  </svg> */}
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '0px',
                      right: '5px',
                    }}
                  >
                    {' '}
                    <svg
                      onClick={() => imgRef.current.click()}
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                    >
                      <g
                        id="Group_6685"
                        data-name="Group 6685"
                        transform="translate(-575.5 -906.5)"
                      >
                        <rect
                          id="Rectangle_2486"
                          data-name="Rectangle 2486"
                          width="19"
                          height="19"
                          rx="4"
                          transform="translate(575.5 906.5)"
                          fill="#0091ff"
                        />
                        <path
                          id="Path_3683"
                          data-name="Path 3683"
                          d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                          transform="translate(405.631 808.208)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </div>
                  {/* <p
                    style={{
                      top: '74%',
                      left: '51%',
                      fontSize: '12px',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    Dodaj grafikę
                  </p> */}
                </>
              ) : null}
              <NewStyleLeftSectionImgButtonWrapper>
                <input
                  type="file"
                  onChange={getImageFile}
                  accept=".jpeq,.jpg,.png"
                  hidden
                  ref={imgRef}
                />
                {isBasicDataEditing &&
                producerImagePreview !== null &&
                producerImagePreview !== '' ? (
                  <NewStyleImgButtonWrapper
                    style={
                      windowWidth >= 1024
                        ? {
                            background: '#ff0300',
                            width: '19px',
                            height: '19px',
                            borderRadius: '4px',
                            position: 'absolute',
                            bottom: '-60px',
                            right: '0px',
                          }
                        : {
                            background: '#ff0300',
                            width: '19px',
                            height: '19px',
                            borderRadius: '4px',
                            position: 'absolute',
                            bottom: '-85px',
                            right: '5px',
                          }
                    }
                    onClick={() => {
                      // setCurrentUserData((prevState) => ({
                      //   ...prevState,
                      //   ImagePath: null,
                      // }));
                      setProducerImage(null);
                      setProducerImagePreview(null);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </NewStyleImgButtonWrapper>
                ) : null}
              </NewStyleLeftSectionImgButtonWrapper>
              {errosBasicData.producerImage.isError ? (
                <ErrorStar text={errosBasicData.producerImage.text} />
              ) : null}
            </AccountBasicDateImgWrapper>
            <AccountBasicButtonWrapper
              style={!isBasicDataEditing ? {display: 'none'} : null}
              isEditing={isBasicDataEditing}
            >
              <MangoPayBasicButton
                disabled={!isBasicDataEditing}
                onClick={() => changeProducerAccountType('name')}
                value={basicDataType}
                messagesType="name"
              >
                Imię i nazwisko
              </MangoPayBasicButton>
              <MangoPayBasicButton
                disabled={!isBasicDataEditing}
                onClick={() => changeProducerAccountType('nick')}
                value={basicDataType}
                messagesType="nick"
              >
                Nick
              </MangoPayBasicButton>
            </AccountBasicButtonWrapper>
            <AccountBasicDateDateWrapper>
              {basicDataType === 'name' ? (
                <>
                  <InputView
                    isError={
                      errosBasicData.showErrors &&
                      errosBasicData.userFirstName.isError
                    }
                    style={windowWidth < 1024 ? null : {marginTop: '8px'}}
                    isEditing={isBasicDataEditing}
                  >
                    <p>Imię</p>
                    <input
                      type="text"
                      readOnly={!isBasicDataEditing}
                      placeholder="Pole obowiązkowe"
                      value={userFirstName}
                      onChange={handleUserFirstNameChange}
                    />
                    {errosBasicData.userFirstName.isError ? (
                      <ErrorStar text={errosBasicData.userFirstName.text} />
                    ) : null}
                    {/*<ErrorStar*/}
                    {/*  text={*/}
                    {/*    'Imię nie może być puste, proszę uzupełnić dane. Imie musi mieć więcej niz 2 znaki'*/}
                    {/*  }*/}
                    {/*/>*/}
                  </InputView>
                  <InputView
                    style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                    isEditing={isBasicDataEditing}
                    isError={
                      errosBasicData.showErrors &&
                      errosBasicData.userLastName.isError
                    }
                  >
                    <p>Nazwisko</p>
                    <input
                      type="text"
                      placeholder="Pole obowiązkowe"
                      readOnly={!isBasicDataEditing}
                      value={userLastName}
                      onChange={handleUserLastNameChange}
                    />
                    {errosBasicData.userLastName.isError ? (
                      <ErrorStar text={errosBasicData.userLastName.text} />
                    ) : null}
                    {/*<ErrorStar*/}
                    {/*  text={*/}
                    {/*    'Nazwisko nie może być puste, proszę uzupełnić dane. Nazwisko musi mieć więcej niz 2 znaki'*/}
                    {/*  }*/}
                    {/*/>*/}
                  </InputView>
                </>
              ) : null}
              {basicDataType === 'nick' ? (
                <InputView
                  style={windowWidth < 1024 ? null : {marginTop: '8px'}}
                  isEditing={isBasicDataEditing}
                  isError={
                    errosBasicData.showErrors && errosBasicData.userName.isError
                  }
                >
                  <p>Nick</p>
                  <input
                    type="text"
                    readOnly={!isBasicDataEditing}
                    placeholder="Pole obowiązkowe"
                    value={userName}
                    onChange={handleUserNameChange}
                  />
                  {errosBasicData.userName.isError ? (
                    <ErrorStar text={errosBasicData.userName.text} />
                  ) : null}
                  {/*<ErrorStar*/}
                  {/*  text={*/}
                  {/*    'Imię nie może być puste, proszę uzupełnić dane. Imie musi mieć więcej niz 2 znaki'*/}
                  {/*  }*/}
                  {/*/>*/}
                </InputView>
              ) : null}
              <InputView
                style={windowWidth < 1024 ? null : {marginTop: '6px'}}
                isEditing={isBasicDataEditing}
                isError={
                  errosBasicData.showErrors && errosBasicData.postalCode.isError
                }
              >
                <p>{isBasicDataEditing ? 'Kod pocztowy' : 'Miejscowość'}</p>
                <input
                  type="text"
                  id="region"
                  placeholder="Pole obowiązkowe"
                  readOnly={!isBasicDataEditing}
                  maxLength={6}
                  value={isBasicDataEditing ? postalCode : cityFromPostalCode}
                  onChange={handlePostalCodeChange}
                />
                {/*<ErrorStar*/}
                {/*  text={*/}
                {/*    'Pole nie może być puste, proszę uzupełnić dane. Miasto musi mieć więcej niz 2 znaki'*/}
                {/*  }*/}
                {errosBasicData.postalCode.isError ? (
                  <ErrorStar text={errosBasicData.postalCode.text} />
                ) : null}
              </InputView>
              <StyledSelectView
                isEditing={isBasicDataEditing}
                isError={
                  errosBasicData.showErrors && errosBasicData.skill.isError
                }
              >
                <p>Profesja</p>
                <select
                  value={skill}
                  disabled={!isBasicDataEditing}
                  onChange={handleSkillSelectChange}
                >
                  <option hidden value={''}>
                    Wybierz
                  </option>
                  {roles
                    .filter((item) => item.FlagValue % 100 === 0)
                    .map((role) => {
                      const {FieldName, FlagValue} = role;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {FieldName}
                        </option>
                      );
                    })}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.439"
                  height="11"
                  viewBox="0 0 6.439 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(6.439 11) rotate(180)"
                  >
                    <path
                      id="Arrow"
                      d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                      transform="translate(0 0)"
                      fill="#3986ef"
                    />
                  </g>
                </svg>
                {errosBasicData.skill.isError ? (
                  <ErrorStar text={errosBasicData.skill.text} />
                ) : null}
              </StyledSelectView>
              {/* <Select
                selectWidth={
                  windowWidth <= 340
                    ? '90%'
                    : windowWidth <= 400
                    ? '311px'
                    : windowWidth <= 1024
                    ? '358px'
                    : '100%'
                }
                customStyles={{
                  width:
                    windowWidth <= 340
                      ? '90%'
                      : windowWidth <= 400
                      ? '311px'
                      : windowWidth <= 1024
                      ? '358px'
                      : '100%',
                  marginTop: '5px',
                }}
                isEditing={isBasicDataEditing}
                value={
                  skill !== null && skill !== ''
                    ? {
                        value: skill,
                        text: roles
                          .filter((item) => item.FlagValue === skill)
                          .map((item) => item.FieldName)[0],
                      }
                    : {value: '', text: ''}
                }
                // values={rolesTypeArr}
                values={roles
                  .map((item) => ({
                    value: item.FlagValue,
                    text: item.FieldName,
                  }))
                  .filter((item) => item.value % 100 === 0)}
                title="Profesja"
                onChange={(v) => handleSkillChange(v)}
              ></Select> */}

              <StyledSelectView
                isEditing={isBasicDataEditing}
                notEdit={
                  roles
                    .map((item) => ({
                      value: item.FlagValue,
                      text: item.FieldName,
                    }))
                    .filter(
                      (item) =>
                        item.value > Number(skill) &&
                        item.value < Number(skill) + 100,
                    ).length < 1
                }
              >
                <p>Specjalizacja</p>
                <select
                  value={secondSkill}
                  onChange={handleSecondSkillSelectChange}
                  disabled={
                    roles
                      .map((item) => ({
                        value: item.FlagValue,
                        text: item.FieldName,
                      }))
                      .filter(
                        (item) =>
                          item.value > Number(skill) &&
                          item.value < Number(skill) + 100,
                      ).length < 1 || !isBasicDataEditing
                  }
                >
                  <option value={''}>
                    {isBasicDataEditing ? 'Wybierz' : ''}
                  </option>
                  {roles
                    .filter(
                      (item) =>
                        item.FlagValue > Number(skill) &&
                        item.FlagValue < Number(skill) + 100,
                    )
                    .map((role) => {
                      const {FieldName, FlagValue} = role;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {FieldName}
                        </option>
                      );
                    })}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.439"
                  height="11"
                  viewBox="0 0 6.439 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(6.439 11) rotate(180)"
                  >
                    <path
                      id="Arrow"
                      d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                      transform="translate(0 0)"
                      fill="#3986ef"
                    />
                  </g>
                </svg>
              </StyledSelectView>
              {/* <Select
                selectWidth={
                  windowWidth <= 340
                    ? '90%'
                    : windowWidth <= 400
                    ? '311px'
                    : windowWidth <= 1024
                    ? '358px'
                    : '100%'
                }
                customStyles={{
                  width:
                    windowWidth <= 340
                      ? '90%'
                      : windowWidth <= 400
                      ? '311px'
                      : windowWidth <= 1024
                      ? '358px'
                      : '100%',
                  marginTop: '5px',
                }}
                isEditing={isBasicDataEditing}
                notEdit={
                  roles
                    .map((item) => ({
                      value: item.FlagValue,
                      text: item.FieldName,
                    }))
                    .filter(
                      (item) =>
                        item.value > Number(skill) &&
                        item.value < Number(skill) + 100,
                    ).length < 1
                }
                value={
                  secondSkill !== null && secondSkill !== ''
                    ? {
                        value: secondSkill,
                        text: roles
                          .filter((item) => item.FlagValue === secondSkill)
                          .map((item) => item.FieldName)[0],
                      }
                    : {value: '', text: ''}
                }
                // values={rolesTypeArr}
                values={roles
                  .map((item) => ({
                    value: item.FlagValue,
                    text: item.FieldName,
                  }))
                  .filter(
                    (item) =>
                      item.value > Number(skill) &&
                      item.value < Number(skill) + 100,
                  )}
                title="Specjalizacja"
                onChange={(v) => handleSecondSkillChange(v)}
              ></Select> */}
              <AccountProducerAboutMeWrapper
                isEditing={isBasicDataEditing}
                type={basicDataType}
                isError={producerData.AboutMe.length >= 250}
              >
                <h4>O mnie</h4>
                <textarea
                  disabled={!isBasicDataEditing}
                  onChange={(e) => handleProducerAboutMe(e)}
                  placeholder="Uzupełnij"
                  value={producerData.AboutMe}
                />
                {isBasicDataEditing && (
                  <p>{`${producerData.AboutMe.length}/250`}</p>
                )}
              </AccountProducerAboutMeWrapper>
              <NewStyleCheckBoxWrapper isEdit={isBasicDataEditing}>
                <a
                  target="blank"
                  href="https://fmmtp.pl/mugostudio/Regulamin%20Serwisu_Mugo_Studio_17.09.2024.pdf"
                  style={
                    windowWidth < 1024
                      ? {}
                      : !isBasicDataEditing
                      ? {color: '#707070', fontWeight: 'normal'}
                      : null
                  }
                >
                  Regulamin MUGO STUDIO
                </a>
                {isBasicDataEditing ? (
                  <NewStyleCheckBox
                    onClick={() =>
                      handleMugoStudioRegulation('AcceptMugoStudioStatute')
                    }
                    isActive={regulations.acceptMugoStudioStatute}
                  >
                    <div></div>
                  </NewStyleCheckBox>
                ) : (
                  <p
                    style={
                      windowWidth < 1024
                        ? {paddingRight: '3.5px'}
                        : {paddingRight: '8.5px', fontWeight: 'normal'}
                    }
                  >
                    {regulations.acceptMugoStudioStatute ? 'Tak' : 'Nie'}
                  </p>
                )}
                {errosBasicData.mugoStatus.isError ? (
                  <ErrorStar text={errosBasicData.mugoStatus.text} />
                ) : null}
              </NewStyleCheckBoxWrapper>
              {/* <NewStyleCheckBoxWrapper isEdit={isBasicDataEditing}>
                <p
                  style={
                    !isBasicDataEditing
                      ? {color: '#707070', fontWeight: 'normal'}
                      : null
                  }
                >
                  Polityka prywatności*
                </p>
                {isBasicDataEditing ? (
                  <NewStyleCheckBox
                    onClick={() =>
                      handleMugoStudioRegulation('AcceptPrivatePolicyStatute')
                    }
                    isActive={regulations.acceptPrivatePolicyStatute}
                  >
                    <div></div>
                  </NewStyleCheckBox>
                ) : (
                  <p style={{paddingRight: '8.5px', fontWeight: 'normal'}}>
                    {regulations.acceptPrivatePolicyStatute ? 'Tak' : 'Nie'}
                  </p>
                )}
              </NewStyleCheckBoxWrapper>
              <NewStyleCheckBoxWrapper isEdit={isBasicDataEditing}>
                <p
                  style={
                    !isBasicDataEditing
                      ? {color: '#707070', fontWeight: 'normal'}
                      : null
                  }
                >
                  Zgody marketingowe
                </p>
                {isBasicDataEditing ? (
                  <NewStyleCheckBox
                    onClick={() =>
                      handleMugoStudioRegulation('AcceptMarketingAgreement')
                    }
                    isActive={regulations.acceptMarketingAgreement}
                  >
                    <div></div>
                  </NewStyleCheckBox>
                ) : (
                  <p style={{paddingRight: '8.5px', fontWeight: 'normal'}}>
                    {regulations.acceptMarketingAgreement ? 'Tak' : 'Nie'}
                  </p>
                )}
              </NewStyleCheckBoxWrapper> */}
            </AccountBasicDateDateWrapper>
            {isBasicDataEditing && (
              <AccountBigBlueBtn
                onClick={saveProducerDate}
                style={
                  windowWidth <= light.breakpointsJs.mobile
                    ? {background: '#3986EF', marginTop: '20px'}
                    : {
                        background: '#3986EF',
                        position: 'absolute',
                        bottom: '11px',
                        left: '11px',
                      }
                }
              >
                Zapisz
              </AccountBigBlueBtn>
            )}
            {!isBasicDataEditing && (
              <PlanAndSubWrapper>
                {' '}
                <InputView
                  style={
                    windowWidth <= light.breakpointsJs.mobile
                      ? {marginTop: '15px'}
                      : {marginTop: '5px'}
                  }
                  isEditing={isBasicDataEditing}
                  notEdit={true}
                >
                  <p>Twój Plan</p>
                  <input
                    type="text"
                    readOnly={true}
                    placeholder="Brak"
                    value={
                      producerPlans.Id ===
                      '0a9da0bf-cd5d-11ed-9742-000d3a44fa94'
                        ? 'Podstawowy'
                        : producerPlans.Name
                    }
                  />
                </InputView>
                {windowWidth < 1024 ? (
                  producerPlans.Id ===
                  '0a9da0bf-cd5d-11ed-9742-000d3a44fa94' ? null : (
                    <AccountBigBlueBtn
                      onClick={() => (window.location.href = 'pro')}
                      style={
                        windowWidth <= light.breakpointsJs.mobile
                          ? {background: '#3986EF', marginTop: '20px'}
                          : {
                              background: '#3986EF',
                              position: 'absolute',
                              bottom: '11px',
                              left: '11px',
                            }
                      }
                    >
                      Zmodyfikuj plan
                    </AccountBigBlueBtn>
                  )
                ) : (
                  <InputViewForData
                    style={
                      producerPlans.Id ===
                      '0a9da0bf-cd5d-11ed-9742-000d3a44fa94'
                        ? {marginTop: '0px'}
                        : null
                    }
                    isEditing={isBasicDataEditing}
                    notEdit={true}
                  >
                    {producerPlans.Id ===
                    '0a9da0bf-cd5d-11ed-9742-000d3a44fa94' ? (
                      <p style={windowWidth < 1024 ? null : {marginTop: '0px'}}>
                        Zmodyfikuj plan
                      </p>
                    ) : (
                      <p>Aktywny do</p>
                    )}
                    {producerPlans.Id ===
                    '0a9da0bf-cd5d-11ed-9742-000d3a44fa94' ? (
                      <input
                        style={
                          windowWidth < 1024
                            ? {
                                cursor: 'pointer',
                                borderRadius: '12px',
                                width: '110px',
                                color: 'white',
                                textAlign: 'center',
                                background: '#0091FF',
                                paddingRight: '0px',
                                lineHeight: '22px',
                                height: '22px',
                                marginBottom: '5px',
                              }
                            : {
                                cursor: 'pointer',
                                borderRadius: '15px',
                                width: '100px',
                                color: 'white',
                                textAlign: 'center',
                                background: '#0091FF',
                                paddingRight: '0px',
                                height: '24px',
                                marginBottom: '5px',
                              }
                        }
                        readOnly={true}
                        value="Przejdź do pro"
                        onClick={() => (window.location.href = 'pro')}
                      />
                    ) : isBasicDataEditing ? (
                      <input
                        type="date"
                        readOnly={true}
                        value={producerPlans.ActiveTo}
                        placeholder=""
                        id="UserName"
                      />
                    ) : producerPlans.ActiveTo === null ? null : (
                      <span>
                        {' '}
                        {new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        })
                          .format(new Date(producerPlans.ActiveTo))
                          .replace(',', '')}
                      </span>
                    )}
                  </InputViewForData>
                )}
              </PlanAndSubWrapper>
            )}
          </AccountBasicDate>
          <AccountSellerDate>
            {/*<AccountText status={1} />*/}
            <AccountFormWrapper>
              {decodedToken.ProducerId ===
              '00000000-0000-0000-0000-000000000000' ? (
                <AccountMangopayFirstTime
                  isProducerExist={
                    decodedToken.ProducerId !==
                    '00000000-0000-0000-0000-000000000000'
                  }
                  goToEdit={goToEdit}
                />
              ) : mangopayType === -1 && !isMangopayEditing ? (
                <AccountMangopayFirstTime
                  isProducerExist={
                    decodedToken.ProducerId !==
                    '00000000-0000-0000-0000-000000000000'
                  }
                  goToEdit={goToEdit}
                />
              ) : mangopayType === 0 ? (
                <AccountMangopayNaturalPersonForm
                  mangopay={mangopayAccount}
                  createAccount={createMangoPayAccount}
                  nationalities={nationalitiesArr}
                  countries={countriesArr}
                  formType={mangopayType}
                  isEditing={isMangopayEditing}
                  handleEditingMode={handleMangopayEditingMode}
                  handleType={changeMangoPayType}
                  showSavePopUp={showSavePopUp}
                />
              ) : (
                <AccountMangopayCompanyForm
                  mangopay={mangopayCompanyAccount}
                  createAccount={createMangoPayCompanyAccount}
                  formType={mangopayType}
                  nationalities={nationalitiesArr}
                  countries={countriesArr}
                  isEditing={isMangopayEditing}
                  handleEditingMode={handleMangopayEditingMode}
                  handleType={changeMangoPayType}
                  showSavePopUp={showSavePopUp}
                  handleLoading={handleMangopayLoadingRing}
                />
              )}
              <AccountMugoForm label={label} status={mangopayAccount.Status} />
            </AccountFormWrapper>
          </AccountSellerDate>
        </AccountGrid>
      </AccountStyleMain>
      {/* <button
        style={{position: 'fixed', bottom: '20px', left: '20px'}}
        onClick={deleteMangoPay}
      >
        Usuń konto mangopay
      </button> */}
      {showSaveQuestion ? popup : null}
    </AccountMainWrapper>
  );
};

export default Account;
