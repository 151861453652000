import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > h1 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 392px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    /* display: none; */
    width: 100%;
  }
`;

export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 9px;
  margin-top: 31px;
  margin-bottom: 21px;

  & > h1 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > a {
    text-decoration: none;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.05px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #0091ff;

    & > svg {
      margin-top: 2px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    & > a {
      /* margin-top: 16px; */
      color: transparent;

      & > svg {
        width: 9.37px;
        height: 16px;
      }
    }
  }
`;

export const CategoriesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileCategoriesWrapper = styled.div`
  width: 100%;
  display: flex !important;
  /* align-items: center; */
  justify-content: center;
  gap: 13px;
  height: 234px;
`;

export const SliderWrapper = styled.div`
  display: none;
  /* margin-bottom: 10px; */
  width: 100%;
  /* height: 330px; */

  .slick-dots {
    bottom: -6px;
  }

  .slick-dots li {
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp100};
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: unset;
  }
`;

export const SingleCategory = styled.div`
  width: 172px;
  height: 197px;
  background: ${({theme}) => theme.colors.popularCategoryBackground};
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  cursor: pointer;
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 2px 2px 0px;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.12);
  transition: background 0.15s;

  &:hover {
    background: ${({theme}) => theme.colors.singleArticleHoverBackground};

    & > img {
      filter: brightness(1.1);
    }
  }

  & > img {
    height: 168px;
    width: 168px;
    border-radius: 22px;
  }

  & > p {
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 7px 2px 0px;

    & > img {
      height: 158px;
      width: 158px;
      border-radius: 18px;
    }

    & > p {
      font-size: 13px;
    }
  }
`;
