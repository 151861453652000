import styled from 'styled-components';

export const TrackMainBlockWrapper = styled.div<{
  backgroundAlpha: number;
  backgroundColor: string;
  isMobile: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`};
  /* border-radius: 24px; */
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
  /* padding: 20px 50px 25px 50px; */
  padding: ${({isMobile}) => (isMobile ? '16px  ' : '20px 50px 25px 50px')};
  //gap: 10px;
  //justify-content: space-between;
  @media (max-width: 1024px) {
    padding: 16px;
  }
  @media (max-width: 375px) {
    padding: 14px;
  }
  @media (max-width: 340px) {
    padding: 10px;
  }
`;

export const OfferTitleBlockHeader = styled.div<{theme?: string}>`
  height: 10%;
  width: 100%;
  display: flex;
  gap: 11px;
  align-items: center;

  & > h4 {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    padding: 0 5px;
  }

  & > svg {
    width: 27.38px;
    height: 21px;
    & > path {
      fill: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  @media (max-width: 1065px) {
    height: 5%;
  }
  @media (max-width: 920px) {
    & > h4 {
      font-size: 16px;
    }
    & > svg {
      height: 16px;
    }
  }
  @media (max-width: 780px) {
    & > h4 {
      font-size: 14px;
    }
    & > svg {
      height: 14px;
    }
  }
  @media (max-width: 720px) {
    & > h4 {
      font-size: 12px;
    }
    & > svg {
      height: 12px;
    }
  }
  @media (max-width: 650px) {
    & > h4 {
      padding: 0;
      font-size: 11px;
    }
    & > svg {
      height: 11px;
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      font-size: 10px;
    }
    & > svg {
      height: 10px;
    }
  }
  @media (max-width: 550px) {
    & > h4 {
      font-size: 8px;
    }
    & > svg {
      height: 8px;
    }
  }
  @media (max-width: 430px) {
    & > h4 {
      font-size: 6px;
    }
    & > svg {
      height: 6px;
    }
  }
`;

export const OfferBlockWrapper = styled.div<{
  theme?: string;
}>`
  position: relative;
  border-radius: 9px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid
    ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  &:hover {
    border: ${({theme}) =>
      theme === '000000'
        ? 'solid 2px black'
        : theme === '0C426A'
        ? 'solid 2px #0C426A'
        : 'solid 2px white'};
  }
`;

export const OfferBlockHeader = styled.div<{
  Width: number;
  Height: number;
  mobileOverview: boolean;
}>`
  flex-basis: ${({Height, Width}) =>
    Height === 3 && Width === 3
      ? '36%'
      : Height === 2 && Width === 2
      ? '35%'
      : Height > Width
      ? '40%'
      : '50%'};
  display: flex;
  flex-direction: ${({Height, Width, mobileOverview}) =>
    mobileOverview && Height > 1
      ? `column`
      : Height > Width
      ? `column`
      : `row`};
  & > img {
    height: 120px;
  }
  @media (max-width: 960px) {
    & > img {
      height: ${({Height, Width}) =>
        Height === 2 && Width === 2
          ? `200px`
          : Height === 2 && Width === 3
          ? '320px'
          : Height === 3 && Width === 3
          ? '370px'
          : Height === 2 && Width === 1
          ? '180px'
          : Height > Width
          ? '100%'
          : '110px'};
    }
  }
  @media (max-width: 920px) {
    & > img {
      height: ${({Height, Width}) =>
        Height === 2 && Width === 2
          ? `190px`
          : Height === 2 && Width === 3
          ? '300px'
          : Height === 3 && Width === 3
          ? '360px'
          : Height === 2 && Width === 1
          ? '170px'
          : Height > Width
          ? '100%'
          : '100px'};
    }
  }
  @media (max-width: 820px) {
    & > img {
      height: ${({Height, Width}) =>
        Height === 2 && Width === 2
          ? `180px`
          : Height === 2 && Width === 3
          ? '280px'
          : Height === 3 && Width === 3
          ? '340px'
          : Height === 2 && Width === 1
          ? '150px'
          : Height > Width
          ? '100%'
          : '85px'};
    }
  }
  @media (max-width: 750px) {
    & > img {
      height: ${({Height, Width}) =>
        Height === 2 && Width === 2
          ? `170px`
          : Height === 2 && Width === 3
          ? '240px'
          : Height === 3 && Width === 3
          ? '320px'
          : Height === 2 && Width === 1
          ? '140px'
          : Height > Width
          ? '100%'
          : '70px'};
    }
  }
  @media (max-width: 660px) {
    & > img {
      height: ${({Height, Width}) =>
        Height === 2 && Width === 2
          ? `160px`
          : Height === 2 && Width === 3
          ? '210px'
          : Height === 3 && Width === 3
          ? '260px'
          : Height === 2 && Width === 1
          ? '130px'
          : Height > Width
          ? '100%'
          : '64px'};
    }
  }
  @media (max-width: 580px) {
    & > img {
      height: ${({Height, Width}) =>
        Height === 2 && Width === 2
          ? `140px`
          : Height === 2 && Width === 3
          ? '180px'
          : Height === 3 && Width === 3
          ? '220px'
          : Height === 2 && Width === 1
          ? '120px'
          : Height > Width
          ? '100%'
          : '48px'};
    }
  }
  @media (max-width: 480px) {
    & > img {
      height: ${({Height, Width}) =>
        Height === 2 && Width === 2
          ? `120px`
          : Height === 2 && Width === 3
          ? '160px'
          : Height === 3 && Width === 3
          ? '200px'
          : Height === 2 && Width === 1
          ? '100px'
          : Height > Width
          ? '100%'
          : '32px'};
    }
  }
`;

export const OfferBlockNameAndButtonWrapper = styled.div<{
  Width: number;
  Height: number;
  fontColor: string;
  mobileOverview: boolean;
}>`
  display: flex;
  width: 100%;
  flex-direction: column;

  & > h4 {
    text-align: ${({Width, Height}) => (Height > Width ? 'center' : 'left')};
    font-size: ${({Width, Height}) =>
      Width === 3 || Height === 3
        ? '22px'
        : Width === 2 || Height === 2
        ? '18px'
        : '10px'};
    margin-left: 10px;
    padding: 12px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    font-weight: 600;
  }

  @media (max-width: 960px) {
    & > h4 {
      margin-left: 8px;
      padding: 10px;
    }
  }
  @media (max-width: 920px) {
    & > h4 {
      padding: 8px;
    }
  }
  @media (max-width: 750px) {
    & > h4 {
      font-size: ${({Width, Height}) =>
        Width === 3 || Height === 3
          ? '16px'
          : Width === 2 || Height === 2
          ? '16px'
          : '10px'};
      margin-left: 5px;
      padding: 6px;
    }
  }
  @media (max-width: 660px) {
    & > h4 {
      margin-left: 2px;
      padding: 3px;
      font-size: ${({Width, Height}) =>
        Width === 3 || Height === 3
          ? '14px'
          : Width === 2 || Height === 2
          ? '14px'
          : '8px'};
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      margin-left: 1px;
      padding: 1px;
      font-size: ${({Width, Height}) =>
        Width === 3 || Height === 3
          ? '13px'
          : Width === 2 || Height === 2
          ? '12px'
          : '7px'};
    }
  }
  @media (max-width: 480px) {
    & > h4 {
      margin-left: 1px;
      padding: 1px;
      font-size: ${({Width, Height}) =>
        Width === 3 || Height === 3
          ? '12px'
          : Width === 2 || Height === 2
          ? '11px'
          : '6px'};
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      margin-left: 1px;
      padding: 1px;
      font-size: ${({Width, Height}) =>
        Width === 3 || Height === 3
          ? '11px'
          : Width === 2 || Height === 2
          ? '10px'
          : '6px'};
    }
  }
`;

export const OfferBlockButtonWrapper = styled.div<{
  fontColor: string;
  Width: number;
  Height: number;
  mobileOverview: boolean;
}>`
  display: flex;
  flex-direction: ${({Width, mobileOverview}) =>
    Width === 1 || mobileOverview ? `column` : `row`};
  width: 100%;
  height: 100%;
  gap: 5px;
  padding: ${({Width, Height}) =>
    Width === 2 && Height === 2 ? '30px 10px' : '5px 10px'};
  align-items: ${({Width, mobileOverview, Height}) =>
    Width === 1 || mobileOverview || Height > Width ? `center` : `end`};
  justify-content: center;
  & > button {
    width: ${({Width, Height}) =>
      Width === 3 || Height === 3 ? '150px' : '129px'};
    border: 1px solid rgba(255, 255, 255, 0.21);
    color: ${({fontColor}) => `rgb(${fontColor})`};
    background-color: transparent;
    cursor: pointer;
    font-size: ${({Width, Height}) =>
      Width === 3 || Height === 3
        ? '11px'
        : Width === 2 || Height === 2
        ? '10px'
        : '8px'};
    padding: 5px;
    &:hover {
      border-color: rgba(255, 255, 255, 0.61);
    }
  }
  @media (max-width: 1050px) {
    & > button {
      width: ${({Width, Height}) =>
        Width === 2 && Height === 2 ? '110px' : '129px'};
    }
  }
  @media (max-width: 950px) {
    gap: 3px;
    padding: 3px 5px;
    flex-direction: ${({Width, Height}) => (Width > Height ? 'row' : 'column')};
    align-items: center;
  }
  @media (max-width: 820px) {
    & > button {
      width: 100px;
      padding: 2px;
      font-size: ${({Width, Height}) =>
        Width === 3 || Height === 3
          ? '10px'
          : Width === 2 || Height === 2
          ? '10px'
          : '6px'};
    }
  }
  @media (max-width: 750px) {
    & > button {
      padding: 1px;
      font-size: ${({Width, Height}) =>
        Width === 3 || Height === 3
          ? '9px'
          : Width === 2 || Height === 2
          ? '8px'
          : '5px'};
    }
  }
  @media (max-width: 660px) {
    gap: 1px;
    padding: 1px;
    & > button {
      width: 80px;
    }
  }
  @media (max-width: 560px) {
    & > button {
      width: 60px;
      font-size: ${({Width, Height}) =>
        Width === 3 || Height === 3
          ? '7px'
          : Width === 2 || Height === 2
          ? '6px'
          : '4px'};
    }
  }
  @media (max-width: 480px) {
    & > button {
      width: 42px;
      font-size: ${({Width, Height}) =>
        Width === 3 || Height === 3
          ? '6px'
          : Width === 2 || Height === 2
          ? '5px'
          : '3px'};
    }
  }
  @media (max-width: 380px) {
    & > button {
      width: 32px;
      font-size: ${({Width, Height}) =>
        Width === 3 || Height === 3
          ? '5px'
          : Width === 2 || Height === 2
          ? '4px'
          : '2px'};
    }
  }
`;

export const OfferBlockContent = styled.div<{
  fontColor: string;
  Width: number;
  Height: number;
  mobileOverview: boolean;
}>`
  flex-basis: ${({Height, Width}) =>
    Height === 3 && Width === 3
      ? '54%'
      : Height === 2 && Width === 2
      ? '55%'
      : Height > Width
      ? '60%'
      : '40%'};
  margin-top: ${({Width}) => (Width === 3 ? '10px' : '20px')};
  & > p {
    padding: ${({Width, Height, mobileOverview}) =>
      mobileOverview ? '5px' : Width === 3 || Height === 3 ? '10px' : '5px'};
    text-align: justify;
    font-size: ${({Width, Height, mobileOverview}) =>
      mobileOverview
        ? '9px'
        : Width === 3 && Height === 3
        ? '24px'
        : Width === 2 && Height === 2
        ? '18px'
        : Width === 3 || Height === 3
        ? '13px'
        : Width === 2 || Height === 2
        ? '12px'
        : '10px'};
    line-height: ${({Width, Height, mobileOverview}) =>
      mobileOverview
        ? '18px'
        : Width === 3 && Height === 3
        ? '48px'
        : Width === 2 && Height === 2
        ? '36px'
        : Width === 3 || Height === 3
        ? '26px'
        : Width === 2 || Height === 2
        ? '20px'
        : '10px'};
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }
  @media (max-width: 1020px) {
    margin-top: 10px;
  }
  @media (max-width: 940px) {
    margin-top: 3px;
    & > p {
      font-size: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '23px'
          : Width === 2 && Height === 2
          ? '17px'
          : Width === 3 || Height === 3
          ? '11px'
          : Width === 2 || Height === 2
          ? '11px'
          : '8px'};
    }
  }
  @media (max-width: 850px) {
    margin-top: 2px;
    & > p {
      font-size: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '21px'
          : Width === 2 && Height === 2
          ? '15px'
          : Width === 3 || Height === 3
          ? '9px'
          : Width === 2 || Height === 2
          ? '9px'
          : '7px'};
      line-height: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '42px'
          : Width === 2 && Height === 2
          ? '30px'
          : Width === 3 || Height === 3
          ? '18px'
          : Width === 2 || Height === 2
          ? '18px'
          : '9px'};
    }
  }
  @media (max-width: 750px) {
    & > p {
      font-size: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '19px'
          : Width === 2 && Height === 2
          ? '12px'
          : Width === 3 || Height === 3
          ? '7px'
          : Width === 2 || Height === 2
          ? '7px'
          : '6px'};
      line-height: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '38px'
          : Width === 2 && Height === 2
          ? '24px'
          : Width === 3 || Height === 3
          ? '14px'
          : Width === 2 || Height === 2
          ? '14px'
          : '8px'};
    }
  }
  @media (max-width: 660px) {
    margin-top: 1px;
    & > p {
      font-size: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '16px'
          : Width === 2 && Height === 2
          ? '10px'
          : Width === 3 || Height === 3
          ? '6px'
          : Width === 2 || Height === 2
          ? '6px'
          : '5px'};
      line-height: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '32px'
          : Width === 2 && Height === 2
          ? '20px'
          : Width === 3 || Height === 3
          ? '12px'
          : Width === 2 || Height === 2
          ? '12px'
          : '7px'};
    }
  }
  @media (max-width: 580px) {
    margin-top: 1px;
    & > p {
      font-size: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '12px'
          : Width === 2 && Height === 2
          ? '8px'
          : Width === 3 || Height === 3
          ? '5px'
          : Width === 2 || Height === 2
          ? '5px'
          : '4px'};
      line-height: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '24px'
          : Width === 2 && Height === 2
          ? '16px'
          : Width === 3 || Height === 3
          ? '8px'
          : Width === 2 || Height === 2
          ? '8px'
          : '6px'};
    }
  }
  @media (max-width: 480px) {
    margin-top: 1px;
    & > p {
      font-size: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '11px'
          : Width === 2 && Height === 2
          ? '9px'
          : Width === 3 || Height === 3
          ? '4px'
          : Width === 2 || Height === 2
          ? '4px'
          : '3px'};
      line-height: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '22px'
          : Width === 2 && Height === 2
          ? '14px'
          : Width === 3 || Height === 3
          ? '6px'
          : Width === 2 || Height === 2
          ? '6px'
          : '4px'};
    }
  }
  @media (max-width: 380px) {
    margin-top: 1px;
    & > p {
      padding: 1px;
      font-size: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '10px'
          : Width === 2 && Height === 2
          ? '8px'
          : Width === 3 || Height === 3
          ? '3px'
          : Width === 2 || Height === 2
          ? '3px'
          : '2px'};
      line-height: ${({Width, Height}) =>
        Width === 3 && Height === 3
          ? '20px'
          : Width === 2 && Height === 2
          ? '16px'
          : Width === 3 || Height === 3
          ? '3px'
          : Width === 2 || Height === 2
          ? '3px'
          : '2px'};
    }
  }
`;

export const OfferBlockFooter = styled.div`
  flex-basis: 10%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 10px 15px;
  @media (max-width: 1000px) {
    padding: 0;
  }
`;

export const OfferBlockWinWinInfo = styled.div<{
  fontColor: string;
  theme: string;
  Width: number;
  Height: number;
}>`
  width: ${({Height, Width}) =>
    Height === 3 && Width === 3
      ? '130px'
      : Height === 2 && Width === 2
      ? '120px'
      : '94px'};
  height: ${({Height, Width}) =>
    Height === 3 && Width === 3
      ? '35px'
      : Height === 2 && Width === 2
      ? '30px'
      : '22px'};
  border: ${({fontColor}) => `solid 1px rgb(${fontColor})`};
  display: flex;
  gap: 8.7px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-right: 22px;
  cursor: pointer;

  & > p {
    color: ${({fontColor}) => `rgb(${fontColor})`};
    font-size: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '11px'
        : Height === 2 && Width === 2
        ? '10px'
        : '9px'};
  }
  g {
    & > path {
      stroke: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
    & > circle {
      stroke: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  @media (max-width: 1100px) {
    margin-right: unset;
  }
  @media (max-width: 920px) {
    height: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '30px'
        : Height === 2 && Width === 2
        ? '25px'
        : '18px'};
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '130px'
        : Height === 2 && Width === 2
        ? '120px'
        : '84px'};
    & > p {
      font-size: ${({Height, Width}) =>
        Height === 3 && Width === 3
          ? '11px'
          : Height === 2 && Width === 2
          ? '10px'
          : '8px'};
    }
  }
  @media (max-width: 850px) {
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '125px'
        : Height === 2 && Width === 2
        ? '110px'
        : '74px'};
    & > p {
      font-size: ${({Height, Width}) =>
        Height === 3 && Width === 3
          ? '11px'
          : Height === 2 && Width === 2
          ? '10px'
          : '7px'};
    }
  }
  @media (max-width: 750px) {
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '120px'
        : Height === 2 && Width === 2
        ? '100px'
        : '64px'};
  }
  @media (max-width: 660px) {
    height: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '25px'
        : Height === 2 && Width === 2
        ? '23px'
        : '14px'};
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '115px'
        : Height === 2 && Width === 2
        ? '90px'
        : '54px'};
    & > p {
      font-size: ${({Height, Width}) =>
        Height === 3 && Width === 3
          ? '11px'
          : Height === 2 && Width === 2
          ? '9px'
          : '6px'};
    }
  }
  @media (max-width: 560px) {
    height: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '23px'
        : Height === 2 && Width === 2
        ? '22px'
        : '13px'};
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '110px'
        : Height === 2 && Width === 2
        ? '85px'
        : '48px'};
    & > p {
      font-size: ${({Height, Width}) =>
        Height === 3 && Width === 3
          ? '10px'
          : Height === 2 && Width === 2
          ? '8px'
          : '5px'};
    }
  }
  @media (max-width: 480px) {
    height: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '20px'
        : Height === 2 && Width === 2
        ? '18px'
        : '10px'};
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '100px'
        : Height === 2 && Width === 2
        ? '80px'
        : '42px'};
    & > p {
      font-size: ${({Height, Width}) =>
        Height === 3 && Width === 3
          ? '9px'
          : Height === 2 && Width === 2
          ? '7px'
          : '4px'};
    }
  }
  @media (max-width: 380px) {
    height: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '18px'
        : Height === 2 && Width === 2
        ? '16px'
        : '8px'};
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '100px'
        : Height === 2 && Width === 2
        ? '80px'
        : '42px'};
    & > p {
      font-size: ${({Height, Width}) =>
        Height === 3 && Width === 3
          ? '8px'
          : Height === 2 && Width === 2
          ? '6px'
          : '3px'};
    }
  }
`;

export const OfferBlockPriceButton = styled.button<{
  fontColor: string;
  bgColor: string;
  Width: number;
  Height: number;
}>`
  width: ${({Height, Width}) =>
    Height === 3 && Width === 3
      ? '130px'
      : Height === 2 && Width === 2
      ? '120px'
      : '94px'};
  height: ${({Height, Width}) =>
    Height === 3 && Width === 3
      ? '35px'
      : Height === 2 && Width === 2
      ? '30px'
      : '22px'};
  border: ${({fontColor}) => `solid 1px rgb(${fontColor})`};
  border-radius: 5px;
  background: ${({fontColor}) => `rgb(${fontColor})`};
  font-size: ${({Height, Width}) =>
    Height === 3 && Width === 3
      ? '11px'
      : Height === 2 && Width === 2
      ? '10px'
      : '9px'};
  color: ${({bgColor}) => `rgb(${bgColor})`};
  transition: all 0.15s;
  cursor: pointer;
  margin: 0 7px;
  @media (max-width: 920px) {
    height: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '30px'
        : Height === 2 && Width === 2
        ? '25px'
        : '18px'};
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '130px'
        : Height === 2 && Width === 2
        ? '120px'
        : '84px'};

    font-size: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '11px'
        : Height === 2 && Width === 2
        ? '10px'
        : '8px'};
  }
  @media (max-width: 850px) {
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '125px'
        : Height === 2 && Width === 2
        ? '110px'
        : '74px'};

    font-size: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '11px'
        : Height === 2 && Width === 2
        ? '10px'
        : '7px'};
  }
  @media (max-width: 750px) {
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '120px'
        : Height === 2 && Width === 2
        ? '100px'
        : '64px'};
  }
  @media (max-width: 660px) {
    height: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '25px'
        : Height === 2 && Width === 2
        ? '23px'
        : '14px'};
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '115px'
        : Height === 2 && Width === 2
        ? '90px'
        : '54px'};

    font-size: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '11px'
        : Height === 2 && Width === 2
        ? '9px'
        : '6px'};
  }
  @media (max-width: 560px) {
    height: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '23px'
        : Height === 2 && Width === 2
        ? '22px'
        : '13px'};
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '110px'
        : Height === 2 && Width === 2
        ? '85px'
        : '48px'};

    font-size: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '10px'
        : Height === 2 && Width === 2
        ? '8px'
        : '5px'};
  }
  @media (max-width: 480px) {
    height: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '20px'
        : Height === 2 && Width === 2
        ? '18px'
        : '10px'};
    width: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '100px'
        : Height === 2 && Width === 2
        ? '80px'
        : '42px'};

    font-size: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '9px'
        : Height === 2 && Width === 2
        ? '7px'
        : '4px'};
  }
  @media (max-width: 380px) {
    height: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '18px'
        : Height === 2 && Width === 2
        ? '16px'
        : '8px'};

    font-size: ${({Height, Width}) =>
      Height === 3 && Width === 3
        ? '8px'
        : Height === 2 && Width === 2
        ? '6px'
        : '3px'};
  }
`;

export const OfferAddButonWrapper = styled.div<{theme: string}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 48px;
    height: 48px;
    cursor: pointer;
    & > g {
      & > path {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};
      }
    }
  }
`;

export const OfferBorderForNewList = styled.div<{
  theme?: string;
}>`
  border-radius: 9px;
  position: relative;
  padding: 5px;
  display: flex;
  flex-direction: column;
  height: 85%;
  width: 100%;
`;

export const OfferNewListWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &::-webkit-scrollbar {
    width: 24px;
    height: 10px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment {
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #125faf;
    border-radius: 25px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
`;

export const OfferListItem = styled.div<{theme: string}>`
  text-align: center;
  width: 80%;
  padding: 5px;
  cursor: pointer;
  border: ${({theme}) =>
    theme === '000000'
      ? 'solid 1px black'
      : theme === '0C426A'
      ? 'solid 1px #0C426A'
      : 'solid 1px white'};

  & > p {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  }
`;

export const DeleteButton = styled.button<{fontColor: string}>`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 15px;
  background-color: transparent;
  padding: 5px;
  border: none;
  color: ${({fontColor}) => `rgb(${fontColor})`};
  cursor: pointer;
  z-index: 100;
`;

export const NewOffertBlockWrapper = styled.div`
  flex-grow: 1;
  /* margin: 16px; */
  cursor: pointer;
`;

export const NewOffertBlockColumnWrapper = styled.div<{Height: number}>`
  padding: 5px;
  padding-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > img {
    height: ${({Height}) => (Height === 3 ? '192px' : '292px')};
    width: ${({Height}) => (Height === 3 ? '192px' : '292px')};
  }
`;

export const NewOffertNameProduct = styled.h2<{
  Width: number;
  fontColor: string;
}>`
  font-size: ${({Width}) => (Width === 9 ? '36px' : '21px')};
  line-height: ${({Width}) => (Width === 9 ? '48px' : '32px')};
  letter-spacing: -0.25px;
  font-weight: bold;
  color: ${({fontColor}) => `rgb(${fontColor})`};
`;

export const NewOffertCategoryWrapper = styled.div<{
  Width: number;
  fontColor: string;
}>`
  display: flex;
  width: 100%;
  justify-content: ${({Width}) => (Width === 3 ? 'center' : 'flex-start')};
  & > p {
    font-size: ${({Width}) => (Width === 9 ? '18px' : '11px')};
    line-height: ${({Width}) => (Width === 9 ? '36px' : '22px')};
    letter-spacing: -0.25px;
    font-weight: bold;
    padding-right: 5px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }
`;

export const NewOffertProductType = styled.p<{
  Width: number;
  fontColor: string;
}>`
  font-size: ${({Width}) => (Width === 9 ? '18px' : '11px')};
  line-height: ${({Width}) => (Width === 9 ? '18px' : '11px')};
  margin-bottom: ${({Width}) =>
    Width === 6 ? '14px' : Width === 9 ? '24px' : '0px'};
  letter-spacing: -0.25px;
  font-weight: normal;
  color: ${({fontColor}) => `rgb(${fontColor})`};
`;

export const NewOfferDescription = styled.p<{Width: number; fontColor: string}>`
  padding-left: ${({Width}) => (Width === 3 ? '30px' : '0px')};
  padding-right: ${({Width}) => (Width === 3 ? '30px' : '10px')};
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  overflow-y: hidden;
  color: ${({fontColor}) => `rgb(${fontColor})`};
`;

export const NewOffertBlockFooter = styled.div`
  height: 52px;
  width: 100%;
  background-color: #ffffff;
`;

export const NewOffertFooterContent = styled.div<{Width: number}>`
  margin-left: ${({Width}) => (Width === 3 ? '0' : '339px')};
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NewOfertPirceWrapper = styled.div`
  margin-left: 21px;
  & > p {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.25px;
  }
`;

export const NewOfertWinWinWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 18px;
  width: 82px;
  height: 20px;
  border: 1px solid #fb9614;
  border-radius: 11px;

  & > p {
    font-size: 9px;
    //line-height: 24px;
    letter-spacing: 0.15px;
    color: #fb9614;
  }
`;

export const NewOffertBlockRowWrapper = styled.div`
  padding: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  & > img {
    position: absolute;
    left: 12px;
    top: 12px;
    height: 315px;
    width: 315px;
  }
`;

export const NewOffertBlockRowContentWrapper = styled.div`
  margin-left: 339px;
  height: 100%;
`;
