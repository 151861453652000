import styled from 'styled-components';

export const EditBtnWrapper = styled.div<{
  isEditing: boolean;
}>`
  position: absolute;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  right: 10px;
  top: 8px;
  width: 46px;
  overflow: hidden;
  height: 50px;
  border-radius: 16px;
  border: ${({theme}) => `1px solid ${theme.colors.borderOutsideForEditBtn}`};
  background: ${({isEditing, theme}) =>
    isEditing ? theme.colors.fontBlue : theme.colors.bgForEditBtn};
  transition: 0.2s;
  transform: scale(0.65);

  @media (max-width: 1024px) {
    right: 10px;
    top: 10px;
  }
  @media (max-width: 400px) {
    top: 5px;
    right: 5px;
  }
  @media (max-width: 340px) {
    right: 5px;
    top: 5px;
  }
`;

export const EditSvgWrapper = styled.div<{isEditing: boolean}>`
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  width: 44px;
  height: 38px;
  overflow: hidden;
  margin-top: ${({isEditing}) => (isEditing ? '9px' : '0px')};
  border-radius: 15px;
  border: ${({theme}) => `1px solid ${theme.colors.borderInsideForEditBtn}`};
  background: ${({theme}) => theme.colors.bgWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: margin-top 0.2s;
  & > svg {
    & > g {
      & > path {
        stroke: ${({theme, isEditing}) =>
          isEditing ? theme.colors.fontBlue : theme.colors.fontBlackOp100};
      }
    }
  }
`;
