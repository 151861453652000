import React, {useContext} from 'react';
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import TouchCarousel from 'react-touch-carousel';
import touchWithMouseHOC from 'react-touch-carousel/lib/touchWithMouseHOC';
import {StatisticsContext} from 'contexts/StatisticsContext';
import {clearAllFilters} from 'utils/clearAllFilters';

export const NavMain = styled.div<{navPosition?: string}>`
  height: 52px;
  width: 100%;
  position: absolute;
  top: ${({navPosition}) => (navPosition === 'low' ? '103px' : '48px')};
  margin: 0 auto;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  margin-bottom: 2px;
  overflow: hidden;

  @media (max-width: 768px) {
    justify-content: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 7px;
  pointer-events: all;
  height: 28px;
  user-select: none;
  background: ${({theme}) => theme.colors.routerBg};
  padding: 0 5px;
  border-radius: 16px 16px 0 0;

  & a {
    text-decoration: none;
    font-size: 11px;
    min-width: 85px;
    color: white;
    padding: 2.5px 10px;
    cursor: pointer;
    transition: all 0.15s;
    border-radius: 5px;
    position: relative;
    text-align: center;
    border: none;
    border-radius: 11px;
    background: transparent;
    transition: all 0.15s;

    &:hover {
      color: ${({theme}) => theme.colors.fontBlue};
      background: ${({theme}) => theme.colors.bgWhite};
    }
  }

  & .active {
    color: ${({theme}) => theme.colors.fontBlue};
    /* background: rgb(0, 33, 58);
    background: linear-gradient(
      180deg,
      rgba(0, 33, 58, 1) 0%,
      rgba(35, 39, 82, 1) 100%
    ); */
    border: none;
    border-radius: 11px;
    background: ${({theme}) => theme.colors.bgWhite};
    cursor: default;

    &:hover {
      color: ${({theme}) => theme.colors.fontBlue};
      background: ${({theme}) => theme.colors.bgWhite};
    }
  }
`;

export const NewMessage = styled.div`
  background: #00d10f;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  right: -6px;

  & > p {
    text-align: center;
    color: black;
    font-weight: 700;
    font-size: 11px;
  }
`;

interface Props {
  navPosition?: string;
}

const MyStudioNavbar: React.FC<Props> = ({navPosition}) => {
  const [statistics, setStatistics] = useContext(StatisticsContext);

  const linksList = [
    {
      location: '/producer',
      name: 'Konto',
    },
    {
      location: '/card',
      name: 'Wizytówka',
    },
    {
      location: '/messages',
      name: 'Wiadomości',
    },
    {
      location: '/myproducts',
      name: 'Oferty',
    },
    {
      location: '/conditions',
      name: 'Warunki',
    },
    {
      location: '/transactions',
      name: 'Transakcje',
    },
    {
      location: '/pro',
      name: 'PRO',
    },
  ];

  function CarouselContainer(props) {
    const {cursor, ...rest} = props;
    const translateX = cursor.toFixed(5) * 100;

    if (window.location.search.split('?')[2] === 'isMobile=true') return null;

    return (
      <NavMain navPosition={navPosition}>
        <NavWrapper
          style={{transform: `translate3d(${translateX}px, 0, 0)`}}
          {...rest}
        />
      </NavMain>
    );
  }

  // const Container = touchWithMouseHOC(CarouselContainer);

  function renderCard(index, modIndex, cursor) {
    const item = linksList[modIndex];
    const {location, name} = item;
    return (
      <NavLink
        to={location}
        key={index}
        onClick={() => {
          clearAllFilters();
        }}
      >
        {name}
        {/* {name === 'Wiadomości' && statistics.NewMessagesCount > 0 && (
          <NewMessage>
            <p>{statistics.NewMessagesCount}</p>
          </NewMessage>
        )} */}
      </NavLink>
    );
  }

  return (
    <TouchCarousel
      component={CarouselContainer}
      cardCount={linksList.length}
      cardSize={100}
      loop={false}
      renderCard={renderCard}
    />
  );
};

export default MyStudioNavbar;
