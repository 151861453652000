import styled from 'styled-components';

export const LongDescriptionBlockWrapper = styled.div<{
  backgroundAlpha: number;
  backgroundColor: string;
}>`
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  //gap: 10px;
  justify-content: space-between;
  //padding: 10px;
  background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`};
  /* border-radius: 24px; */
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
`;

export const LongDescriptionBlockHeader = styled.div<{theme?: string}>`
  height: 10%;
  width: 100%;
  display: flex;
  gap: 11px;
  align-items: center;

  & > h4 {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    padding: 0 5px;
  }

  & > svg {
    width: 27.38px;
    height: 21px;
    & > path {
      fill: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  @media (max-width: 1065px) {
    height: 5%;
  }
  @media (max-width: 920px) {
    & > h4 {
      font-size: 16px;
    }
    & > svg {
      height: 16px;
    }
  }
  @media (max-width: 780px) {
    & > h4 {
      font-size: 14px;
    }
    & > svg {
      height: 14px;
    }
  }
  @media (max-width: 720px) {
    & > h4 {
      font-size: 12px;
    }
    & > svg {
      height: 12px;
    }
  }
  @media (max-width: 650px) {
    & > h4 {
      padding: 0;
      font-size: 11px;
    }
    & > svg {
      height: 11px;
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      font-size: 10px;
    }
    & > svg {
      height: 10px;
    }
  }
  @media (max-width: 550px) {
    & > h4 {
      font-size: 8px;
    }
    & > svg {
      height: 8px;
    }
  }
  @media (max-width: 1024px) {
    & > p {
      font-size: 12px;
      line-height: 20px;
    }
    & > textarea {
      font-size: 12px;
      line-height: 60px;
    }
  }

  @media (max-width: 375px) {
    & > p {
      font-size: 12px;
      line-height: 20px;
    }
    & > textarea {
      font-size: 12px;
      line-height: 60px;
    }
  }

  @media (max-width: 320px) {
    & > p {
      font-size: 12px;
      line-height: 20px;
    }
    & > textarea {
      font-size: 12px;
      line-height: 60px;
    }
  }
`;

export const LongDescriptionBlockContent = styled.div<{
  mobileOverview: boolean;
  Height: number;
  Width: number;
  fontColor: string;
}>`
  width: 100%;
  height: 100%;
  //display: grid;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  /* background-color: red; */
  //gap: 60px;
  grid-template-columns: repeat(auto-fill, 339px);
  /* padding: ${({mobileOverview, Height}) =>
    mobileOverview
      ? '16px'
      : Height === 1
      ? '5px 58px 15px 58px'
      : '5px 58px 22px 58px'}; */
  padding: ${({mobileOverview, Height}) =>
    mobileOverview ? '16px' : Height === 1 ? '0 58px' : '0 58px'};
  & > span {
    position: absolute;
    right: 10px;
    bottom: 95px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }

  @media (max-width: 1065px) {
    padding: 0px;
    //gap: 10px;
    //height: 95%;
    grid-template-columns: ${({Width, mobileOverview}) =>
      !mobileOverview ? `repeat(${Width}, 1fr)` : 'repeat(auto-fill, 287px)'};
    grid-template-rows: ${({Height, mobileOverview}) =>
      !mobileOverview ? `repeat(${Height}, 1fr)` : 'repeat(auto-fill, 258px)'};
  }
`;

export const LongDescriptionContent = styled.div<{
  fontColor: string;
  Height: number;
  mobileOverview: boolean;
  interline: number;
}>`
  /* padding: ${({mobileOverview, Height}) =>
    mobileOverview
      ? '16px'
      : Height === 1
      ? '5px 58px 15px 58px'
      : '5px 58px 22px 58px'}; */
  width: 100%;
  height: ${({mobileOverview}) => (mobileOverview ? 'auto' : 'auto')};
  position: relative;
  overflow: hidden;

  & > p {
    /* display: -webkit-box;
    -webkit-line-clamp: ${({Height}) =>
      Height === 3 ? 14 : Height === 4 ? 33 : 33};
    -webkit-box-orient: vertical; */
    overflow: hidden;
    font-size: 13px;
    /* line-height: 21px; */

    line-height: ${({mobileOverview, interline}) =>
      interline === 0 ? '21px' : mobileOverview ? '21px' : `${interline}px`};
    letter-spacing: -0.05px;
    text-align: left;
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }
  & > textarea,
  textarea:disabled {
    display: block;

    overflow: hidden;
    width: 100%;

    /* height: auto; */

    //height: ${({Height}) => (Height === 4 ? '452px' : '339px')};
    background: transparent;
    border: none;
    text-align: left;
    outline: none;
    font-size: 13px;
    /* line-height: 21px; */
    line-height: ${({mobileOverview, interline}) =>
      interline === 0 ? '21px' : mobileOverview ? '21px' : `${interline}px`};
    letter-spacing: -0.05px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    resize: none;
    opacity: 1;
  }

  @media (max-width: 1024px) {
    padding: 16px;
    height: 100%;
    & > p {
      font-size: 13px;
      line-height: 21px;
    }
    & > textarea,
    textarea:disabled {
      font-size: 13px;
      line-height: 21px;
    }
  }
  @media (max-width: 420px) {
    & > p {
      line-height: 18px;
    }
    & > textarea,
    textarea:disabled {
      line-height: 18px;
    }
  }
  @media (max-width: 400px) {
    & > p {
      font-size: 13px;
      line-height: 18px;
    }
    & > textarea,
    textarea:disabled {
      font-size: 13px;
      line-height: 18px;
    }
  }
  @media (max-width: 390px) {
    & > p {
      font-size: 13px;
      line-height: 16px;
    }
    & > textarea,
    textarea:disabled {
      font-size: 13px;
      line-height: 16px;
    }
  }
  @media (max-width: 375px) {
    padding: 14px;
    & > p {
      line-height: 14px;
    }
    & > textarea,
    textarea:disabled {
      line-height: 14px;
    }
  }
  @media (max-width: 360px) {
    & > p {
      line-height: 13px;
    }
    & > textarea,
    textarea:disabled {
      line-height: 13px;
    }
  }
  @media (max-width: 340px) {
    & > p {
      line-height: 12px;
    }
    & > textarea,
    textarea:disabled {
      line-height: 12px;
    }
  }
  @media (max-width: 325px) {
    padding: 10px;
    & > p {
      font-size: 12px;
      line-height: 12px;
    }
    & > textarea,
    textarea:disabled {
      font-size: 12px;
      line-height: 12px;
    }
  }
`;

export const LongDescriptionTitleContent = styled.div<{titleColor: string}>`
  & > p {
    padding-bottom: 5px;
    width: 100%;
    text-align: justify;
    font-size: 38px;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: -0.25px;
    color: ${({titleColor}) => `rgb(${titleColor})`};
  }

  & > textarea {
    width: 100%;
    height: 40px;
    background: transparent;
    border: none;
    text-align: justify;
    outline: none;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: -0.25px;
    line-height: 32px;
    overflow: hidden;
    color: ${({titleColor}) => `rgb(${titleColor})`};
    resize: none;
  }
`;
