import styled, {css} from 'styled-components';
import {PageTheme, PageStyle, BlockType, BlockColor} from '../interface';

export const Counter = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: ${({theme}) => theme.colors.whiteOp021};
  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    user-select: none;
    cursor: default;
    font-size: 9px;
    font-weight: 500;
    color: ${({theme}) => theme.colors.whiteOp092};
  }

  @media (max-width: 1242px) {
    height: 20px;
    width: 20px;

    & > p {
      font-size: 11px;
    }
  }
`;

export const CounterButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px 0;
  user-select: none;
`;

export const ColorBlock = styled.div<{
  color: string;
  currentColor: string;
}>`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 1px solid ${({theme}) => theme.colors.whiteOp021};
  cursor: ${({color, currentColor}) =>
    color === currentColor ? 'default' : 'pointer'};
  transition: all 0.15s;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({color}) => `#${color}`};

  & > img {
    display: ${({color, currentColor}) =>
      color === currentColor ? 'block' : 'none'};
  }

  /* &:nth-child(1) {
    /* background: ${({theme}) => theme.colors.white}; */
  /* background: ${({color}) => `#${color}`}; */
  /* } */

  /* &:nth-child(2) {
    background: ${({theme}) => theme.colors.lightGray};
  }

  &:nth-child(3) {
    background: ${({theme}) => theme.colors.gray};
  }

  &:nth-child(4) {
    background: ${({theme}) => theme.colors.dark};
  }

  &:nth-child(5) {
    background: ${({theme}) => theme.colors.darkBlue};
  }  */

  &:hover {
    border: ${({color, currentColor, theme}) =>
      color === currentColor
        ? `1px solid ${theme.colors.whiteOp021}`
        : '1px solid rgba(1, 245, 34, 0.74)'};
  }

  @media (max-width: 1242px) {
    height: 25px;
    width: 25px;

    & > img {
      width: 25px;
      height: 25px;
    }
  }
`;

export const PaletteWrapper = styled.div`
  display: flex;
  width: 95px;
  left: -10.5px;
  position: relative;
  padding: 0 4px;
  gap: 0 3px;

  @media (max-width: 1242px) {
    width: 150px;
    left: -17px;
  }
`;

export const SettingsContent = styled.div`
  width: 100%;
  background: ${({theme}) => theme.colors.whiteOp011};
  border-radius: 5.5px;
  border: 1px solid ${({theme}) => theme.colors.whiteOp021};
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    width: 74px;
    padding: 8px 0;
    font-size: 9px;
    color: ${({theme}) => theme.colors.white};
    border-bottom: 1px solid ${({theme}) => theme.colors.whiteOp021};
    text-align: center;
  }

  @media (max-width: 1242px) {
    & > p {
      width: 110px;
      font-size: 14px;
    }
  }
`;

export const SettingsWithCounter = styled.div`
  width: 74px;
  padding: 8px 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.whiteOp021};
  display: flex;
  align-items: center;
  gap: 0 5px;
  justify-content: center;

  & > p {
    font-size: 8px;
    color: ${({theme}) => theme.colors.whiteOp076};
    line-height: 24px;
    left: -3px;
    position: relative;
  }

  @media (max-width: 1242px) {
    width: 110px;

    & > p {
      font-size: 11px;
    }
  }
`;

export const SettingsWithPalette = styled.div`
  width: 74px;
  padding: 8px 0;
  border-bottom: 1px solid ${({theme}) => theme.colors.whiteOp021};
  display: flex;
  flex-direction: column;

  & > p {
    font-size: 8px;
    color: ${({theme}) => theme.colors.whiteOp076};
    line-height: 24px;
    left: -3px;
    position: relative;
  }

  @media (max-width: 1242px) {
    width: 110px;

    & > p {
      font-size: 11px;
      text-align: center;
      left: 0;
    }
  }
`;

export const SettingsWithSlider = styled.div`
  width: 140px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;

  & > p {
    font-size: 15px;
    color: rgba(7, 2, 15, 0.5);
    position: relative;
    padding-bottom: 5px;
  }

  @media (max-width: 1242px) {
    width: 110px;

    & > p {
      font-size: 11px;
    }
  }
`;

export const Slider = styled.input`
  /* width: 140px; */
  width: 85px;
  top: 11px;
  height: 2px;
  background: rgba(7, 2, 15, 0.5);
  border-radius: 25px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  left: -2px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: rgba(7, 2, 15, 0.5);
    width: 13px;
    height: 13px;
    border: 2px solid rgba(7, 2, 15, 0.5);
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: rgba(7, 2, 15, 0.5);
    width: 13px;
    height: 13px;
    border: 2px solid rgba(7, 2, 15, 0.5);
    border-radius: 50%;
  }

  @media (max-width: 1242px) {
    width: 110px;

    & > p {
      font-size: 11px;
    }
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: rgba(7, 2, 15, 0.5);
    width: 16px;
    height: 16px;
    border: 2px solid rgba(7, 2, 15, 0.5);
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: rgba(7, 2, 15, 0.5);
    width: 16px;
    height: 16px;
    border: 5px solid rgba(7, 2, 15, 0.5);
    border-radius: 50%;
  }
`;

export const WidthButtonAddCounter = styled.img<{blockWidth: number}>`
  cursor: ${({blockWidth}) => (blockWidth === 3 ? 'default' : 'pointer')};
  filter: ${({blockWidth}) => (blockWidth === 3 ? 'brightness(0.4)' : 'none')};
  transition: all 0.15s;

  &:hover {
    filter: ${({blockWidth}) =>
      blockWidth === 3 ? 'brightness(0.4)' : 'brightness(0.6)'};
  }

  @media (max-width: 1242px) {
    width: 15px;
  }
`;

export const WidthButtonSubtractCounter = styled.img<{blockWidth: number}>`
  cursor: ${({blockWidth}) => (blockWidth === 1 ? 'default' : 'pointer')};
  filter: ${({blockWidth}) => (blockWidth === 1 ? 'brightness(0.4)' : 'none')};
  transition: all 0.15s;

  &:hover {
    filter: ${({blockWidth}) =>
      blockWidth === 1 ? 'brightness(0.4)' : 'brightness(0.6)'};
  }

  @media (max-width: 1242px) {
    width: 15px;
  }
`;

export const HeightButtonAddCounter = styled.img<{blockHeight: number}>`
  cursor: ${({blockHeight}) => (blockHeight === 3 ? 'default' : 'pointer')};
  filter: ${({blockHeight}) =>
    blockHeight === 3 ? 'brightness(0.4)' : 'none'};
  transition: all 0.15s;

  &:hover {
    filter: ${({blockHeight}) =>
      blockHeight === 3 ? 'brightness(0.4)' : 'brightness(0.6)'};
  }

  @media (max-width: 1242px) {
    width: 15px;
  }
`;

export const HeightButtonSubtractCounter = styled.img<{blockHeight: number}>`
  cursor: ${({blockHeight}) => (blockHeight === 1 ? 'default' : 'pointer')};
  filter: ${({blockHeight}) =>
    blockHeight === 1 ? 'brightness(0.4)' : 'none'};
  transition: all 0.15s;

  &:hover {
    filter: ${({blockHeight}) =>
      blockHeight === 1 ? 'brightness(0.4)' : 'brightness(0.6)'};
  }

  @media (max-width: 1242px) {
    width: 15px;
  }
`;

export const CloseSection = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 1;
  background-color: transparent;
`;

export const EditContainer = styled.div<{
  isEditing: boolean;
  blockHeight?: number;
}>`
  ${({isEditing}) =>
    isEditing &&
    css`
      position: absolute;
      width: 100%;
      height: calc(100% - 55px);
      /* height: calc(100% - 110px); */

      bottom: 0;
      /* bottom: 55px; */
      //top: 55px;
      left: 0;
      //right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(6px);
      /* border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px; */
      //padding: 30px 0;
      z-index: 5;
      background: rgba(0, 0, 0, 0.3);
      transition: all 0.15s;
      /* overflow-y: scroll; */
      &::-webkit-scrollbar {
        width: 10px;
        height: 5px;
      }
        &::-webkit-scrollbar-thumb {
          background: #125faf;
          border-radius: 5px;
          border: 3px solid rgba(0, 0, 0, 0);
          background-clip: padding-box;
        }

        &::-webkit-scrollbar-track {
          background: black;
          padding: 3px;
        }
      }

      & > div {
        //display: flex;
        //flex-direction: row;
        //gap: 0 10px;
        //padding: 80px 50px;
        //background: rgba(40, 40, 40, 0.5);
        //border: 1px solid rgba(40, 40, 40, 0.6);
        //border-radius: 9px;
        //max-height: 94vh;
        //overflow-y: auto;
      
        position: absolute;
        z-index: 2;
        

       
      //
      //  &::-webkit-scrollbar-button {
      //    width: 0;
      //    height: 0;
      //    display: none;
      //  }
      //
      //  &::-webkit-scrollbar-corner {
      //    background-color: transparent;
      //  }
      //
      //  &::-webkit-scrollbar-thumb {
      //    background: #790cea;
      //    border-radius: 5px;
      //    border: 2px solid rgba(0, 0, 0, 0);
      //    background-clip: padding-box;
      //  }
      //
      //  &::-webkit-scrollbar-track {
      //    background: black;
      //    padding: 3px;
      //  }
      //}
    `}
`;

export const LinksWrapper = styled.div`
  width: 100%;
  padding: 0 60px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
export const SelectWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const OneLinkWrapper = styled.div<{isMargin?: boolean}>`
  display: flex;
  margin-right: ${({isMargin}) => (isMargin ? '15px' : 0)};
  gap: 5px;
  /* padding-bottom: 11px; */
  align-items: center;
  & > svg {
    cursor: pointer;
  }
`;
export const InputForLink = styled.div`
  width: 376px;
  height: 24px;
  padding: 0 10px;
  background: rgba(112, 112, 112, 0.09);
  border: 1px solid rgba(112, 112, 112, 0.5);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;

  & > p {
    font-size: 13px;
    line-height: 24px;
  }
  & > input {
    font-size: 13px;
    line-height: 24px;
    border: none;
    background: transparent;
    outline: none;
    text-align: right;
    min-width: 240px;
  }
`;

export const EditExtraOptionsWrapper = styled.div`
  width: 100%;
  /* background: #d8d8d8; */
  background: ${({theme}) => theme.colors.bodyBg};
  min-height: 42px;
  padding: 7px;
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
`;

export const EditOptionsWrapper = styled.div<{
  Width?: number;
  Height?: number;
  Type: number;
}>`
  //position: absolute;
  /* position: fixed; */
  //bottom: 0px;
  //top: 100px;
  //left: 420px;
  bottom: ${({Height, Type}) =>
    (Height === 1 && Type === 1) ||
    (Height === 1 && Type === 2) ||
    (Height === 1 && Type === 8192) ||
    (Height === 1 && Type === 16384)
      ? '205px'
      : (Height === 2 && Type === 1) || (Height === 2 && Type === 2)
      ? '145px'
      : (Height === 3 && Type === 1) || (Height === 3 && Type === 2)
      ? '90px'
      : (Height === 4 && Type === 1) || (Height === 4 && Type === 2)
      ? '35px'
      : (Height === 5 && Type === 1) || (Height === 5 && Type === 2)
      ? '5px'
      : Height === 1
      ? '240px'
      : Height === 2
      ? '185px'
      : Height === 3
      ? '125px'
      : Height === 4
      ? '70px'
      : Height === 5
      ? '15px'
      : '0px'};
  /* width: 1000px; */
  width: 100%;
  /* background: #d8d8d8; */
  background: ${({theme}) => theme.colors.bodyBg};
  min-height: 42px;
  padding: 7px;
  gap: 11px;
  //height: 32px;
  margin: 0 auto;
  flex-wrap: wrap;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px; */

  //background: #f1f1f2;
  //border-radius: 9px;
  //border: solid 1px rgba(112, 112, 112, 0.3);

  & > p {
    font-size: 13px;
    color: rgba(7, 2, 15, 0.5);
    line-height: 24px;
  }

  @media (max-width: 1242px) {
    width: 150px;
    display: none;

    & > p {
      font-size: 16px;
    }
  }
`;

export const OptionsWrapper = styled.div`
  width: 95px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px 0;

  & > p {
    font-size: 13px;
    color: ${({theme}) => theme.colors.white};
    line-height: 24px;
  }

  @media (max-width: 1242px) {
    width: 150px;

    & > p {
      font-size: 16px;
    }
  }
`;

export const OptionsContent = styled.div`
  width: 100%;
  background: ${({theme}) => theme.colors.whiteOp011};
  border-radius: 5.5px;
  border: 1px solid ${({theme}) => theme.colors.whiteOp021};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1242px) {
    background: rgb(41, 41, 41);
  }
`;

const SingleOption = styled.p`
  width: 74px;
  padding: 8px 0;
  font-size: 9px;
  transition: all 0.15s;
  border-bottom: 1px solid ${({theme}) => theme.colors.whiteOp021};
  color: ${({theme}) => theme.colors.whiteOp050};
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${({theme}) => theme.colors.white};
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 1242px) {
    width: 110px;
    font-size: 11px;
  }
`;

export const BlockTypeOption = styled(SingleOption)<{
  currentBlockType: number;
  blockType: number;
}>`
  cursor: ${({currentBlockType, blockType}) =>
    currentBlockType === blockType ? 'default' : 'pointer'};
  color: ${({theme, currentBlockType, blockType}) =>
    currentBlockType === blockType
      ? theme.colors.white
      : theme.colors.whiteOp050};
`;

export const SettingsItemWrapper = styled.div<{isFirstItem?: boolean}>`
  /* min-width: 158px;
  width: 158px;
  height: 32px; */
  width: 136px;
  height: 25px;
  display: flex;
  justify-content: space-between;
  /* background: #dfdfdf; */
  background: ${({theme}) => theme.colors.mobileTransactionTopBorder};
  border-radius: 9px;
  border-top: ${({isFirstItem}) =>
    isFirstItem ? 'none' : 'solid 1px rgba(112,112,112,0.3)'};
  border: solid 1px rgba(23, 22, 29, 0.21);
  border-color: ${({theme}) => theme.colors.fontBlackOp15};
  & > p {
    /* color: rgba(7, 2, 15, 0.5); */
    color: ${({theme}) => theme.colors.fontBlackOp50};
    font-size: 11px;
    letter-spacing: -0.15px;
    line-height: 25px;
    padding-left: 10px;
    padding-right: 8px;
  }
`;

export const SettingsCounterSvgWrapper = styled.div`
  width: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //padding-left: 8px;
  //padding-right: 8px;
  & > p {
    font-size: 9px;
    letter-spacing: -0.15px;
    line-height: 32px;
    color: #3a87f0;
    cursor: default;
  }
  & > svg {
    cursor: pointer;
  }
`;

export const SettingsSvgBg = styled.div`
  padding: 4px 8px;
  line-height: 32px;

  cursor: pointer;
  & > svg {
    width: 10px;
    height: 10px;
  }
`;

export const BackButtonEdit = styled.button`
  width: 55px;
  height: 42px;
  position: absolute;
  left: 0px;
  border: none;
  border-right: solid 1px white;
  background: #92c5ff;
  cursor: pointer;
  border-radius: 15px 0 0 0;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  //color: #352f40;
  transition: all 0.15s;

  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
`;

export const SaveAndEditButtonEdit = styled.button`
  width: 55px;
  height: 42px;
  position: absolute;
  right: 0px;
  border: none;
  border-left: 1px solid white;
  background: #92c5ff;
  border-radius: 0 15px 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  //color: #352f40;
  transition: all 0.15s;

  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
`;

export const SaveButtonsWrapper = styled.div<{Width: number}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: ${({Width}) =>
    Width === 9 ? '10px' : Width === 8 ? '-50px' : '-55px'};
  top: 0;
`;

export const HoverText = styled.p`
  position: fixed;
  width: 200px;
  top: 10px;
  margin: 0 auto;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  color: white;
`;

export const UserColorPopUpWrapper = styled.div<{Height: number}>`
  position: fixed;
  left: 40%;
  //top: 25%;
  top: ${({Height}) => (Height === 1 ? '10%' : Height === 2 ? '18%' : '25%')};
  width: 2px;
  height: 2px;
  background: transparent;
  & > input {
    width: 1px;
    height: 1px;
    background: transparent;
  }
  & > button {
    position: absolute;
    bottom: -310px;
    width: 158px;
    height: 32px;
    background: #f1f1f2;
    border-radius: 9px;
    border: solid 1px rgba(112, 112, 112, 0.3);
    margin-left: 37px;
    cursor: pointer;
  }
`;

export const EditWrapper = styled.div<{
  blockWidth: number;
}>`
  position: absolute;
  z-index: 2;
  top: 16px;
  border-radius: 16px;
  width: ${({blockWidth}) => {
    if (blockWidth >= 1 && blockWidth <= 18) {
      return `${56 + (blockWidth - 1) * 56}px`;
    } else {
      return '339px';
    }
  }};
`;

export const EditHeader = styled.header`
  /* background: #0091ff; */
  /* background: #d8d8d8; */
  background: ${({theme}) => theme.colors.bodyBg};
  width: 100%;
  height: 32px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  justify-content: center;
  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 11px;
    line-height: 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
  }
`;

export const EditHeaderSvgWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  height: 100%;
  margin-left: 20px;
  & > svg {
    cursor: pointer;
  }
`;

export const InputTypeNumber = styled.input`
  background: transparent;
  outline: none;
  border: none;
`;
