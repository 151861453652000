import {Block} from '../../interface';
import React, {useContext, useEffect, useState} from 'react';
import {useCookies} from 'react-cookie';
import api from '../../../../../services/axiosConfig';
import {hexToRgb} from '../../index';
import {Offer} from '../RecomendedProductionBlock';
import {
  DeleteButton,
  TrackAddButonWrapper,
  TrackBlockButtonWrapper,
  TrackBlockContent,
  TrackBlockFooter,
  TrackBlockHeader,
  TrackBlockNameAndButtonWrapper,
  TrackBlockPriceButton,
  TrackBlockWinWinInfo,
  TrackBlockWrapper,
  TrackBorderForNewList,
  TrackListItem,
  TrackMainBlockWrapper,
  TrackNewListWrapper,
} from './style';

import {light} from '../../../../../constants/theme';

import {useNavigate} from 'react-router-dom';
import {ProducerDataContext} from '../../../../../contexts/ProducerDataContext';
import {ResolutionContext} from 'contexts/ResolutionContext';

import TrackBlockRowView from './TrackBlockRowView';
import TrackBlockColumnView from './TrackBlockColumnView';
import deleteBlack from '../../../../../images/svg/delete-black.svg';
import defaultCoverImg from '../../../../../images/svg/defaultOfferImg.svg';
import {
  ButtonBg,
  ButtonLeftAndRightWrapper,
} from '../RecomendedProductionBlock/styles';
import {VisitedProducerContext} from '../../../../../contexts/VisitedProducerContext';

interface Props {
  theme: string;
  block: Block;
  mobileOverview: boolean;
  isEditing: boolean;
  updateTrack: ({offer: Offer}) => void;
}

const TrackBlockComponent: React.FC<Props> = ({
  theme,
  mobileOverview,
  block,
  isEditing,
  updateTrack,
}) => {
  const {FontColorHex, BackgroundColorHex, Offer, WebArea, OfferId} = block;
  const {Width, Height} = WebArea;
  const initOffer = {
    Id: 'ADD',
    Name: `Ilustracja wygenerowana przez sztuczną
inteligencję`,
    IsWinWin: true,
    Description: '',
    CoverFile: defaultCoverImg,
    GrossValue: 40,
    Currency: 'zł',
    Category: {Name: 'Grafika', CategoryId: 'test1'},
    SecondCategoryId: {CategoryId: 'Ilustracja', Name: 'Ilustracja'},
    ThirdCategoryId: {CategoryId: null, Name: ''},
    ProductType: 8,
  };

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [offer, setOffer] = useState<Offer>(initOffer);
  const [category, setCategory] = useState<string>('Muzyka');
  const [allOffers, setAllOffers] = useState([]);
  const {
    Id,
    Name,
    IsWinWin,
    Description,
    GrossValue,
    Currency,
    Category,
    CoverFile,
  } = offer;

  const [cookies, setCookie] = useCookies(['token']);
  const navigate = useNavigate();

  const {decodedToken} = useContext(ProducerDataContext);
  const {windowWidth} = useContext(ResolutionContext);
  const [visitedProducer, setVisitedProducer] = useContext(
    VisitedProducerContext,
  );

  const dowloadDataToAdd = async () => {
    try {
      const response = await api.get(`Offer`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setAllOffers(
        response.data.Response.filter(
          (item) => item.ProducerId === decodedToken.ProducerId,
        )
          .filter((item) => item.ProductType === 8)
          .map((item) => ({
            Id: item.Id,
            Name: item.Name,
            Category: item.Category,
            IsWinWin: item.IsWinWin,
            Description: item.Description,
            GrossValue: item.GrossValue,
            Currency: item.Currency,
            CoverFile: item.CoverFile,
            ProductDemoFile: item.ProductDemoFile,
            Categories: item.Categories,
            SecondCategoryId: item.SecondCategoryId,
            ThirdCategoryId: item.ThirdCategoryId,
            ProductType: item.ProductType,
          })),
      );
      setIsAdding(true);
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(
  //   'tets czy name sie zmienia w offert',
  //   block.Offer.Name,
  //   block.TitleColorHex,
  // );

  function addItemToOffer(item: Offer) {
    setOffer(item);
    // updateTrack({offer: item});
    setIsAdding(false);
  }

  function removeOffer() {
    setOffer({
      Id: 'ADD',
      Name: '',
      IsWinWin: false,
      Description: '',
      GrossValue: 0,
      Currency: '',
      Category: {Name: '', CategoryId: ''},
      SecondCategoryId: {CategoryId: '', Name: ''},
      ThirdCategoryId: {CategoryId: '', Name: ''},
    });
    // updateTrack({offer: ''});
  }

  const toTrackInfo = (id: string) => {
    if (!isEditing) {
      navigate(`/tracks/main/${id}`);
    }
  };

  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);

  return (
    <TrackMainBlockWrapper
      isMobile={mobileOverview}
      backgroundAlpha={block.BlockOpacityIn0To100Ratio}
      backgroundColor={BackgroundColorRgb}
    >
      {isEditing && offer.Id === 'ADDs' && (
        <TrackBorderForNewList theme={theme}>
          {isAdding ? (
            <TrackNewListWrapper theme={theme}>
              {allOffers.map((item) => (
                <TrackListItem
                  onClick={() => addItemToOffer(item)}
                  theme={theme}
                  key={item.Id}
                >
                  <p>{item.Name}</p>
                </TrackListItem>
              ))}
            </TrackNewListWrapper>
          ) : (
            <TrackAddButonWrapper onClick={dowloadDataToAdd} theme={theme}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <g
                  id="Icon_feather-plus-circle"
                  data-name="Icon feather-plus-circle"
                  transform="translate(0.5 0.5)"
                  opacity="0.76"
                >
                  <path
                    id="Path_3319"
                    data-name="Path 3319"
                    d="M11,7A4,4,0,1,1,7,3,4,4,0,0,1,11,7Z"
                    transform="translate(-3 -3)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_3320"
                    data-name="Path 3320"
                    d="M18,12v3.2"
                    transform="translate(-14 -9.6)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_3321"
                    data-name="Path 3321"
                    d="M12,18h3.2"
                    transform="translate(-9.6 -14)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            </TrackAddButonWrapper>
          )}
        </TrackBorderForNewList>
      )}
      {!isAdding &&
        offer.Id !== 'ADDs' &&
        (block.Offer === null ? (
          OfferId === '00000000-0000-0000-0000-000000000000' ? (
            <TrackBlockRowView
              offerId={OfferId}
              isMobile={mobileOverview}
              offer={offer}
              ImgSrc={offer.CoverFile}
              SoundSrc={offer.ProductDemoFile}
              IsWinWin={offer.IsWinWin}
              ProductType={offer.ProductType}
              Width={Width}
              TitleColorHex={block.TitleColorHex}
              Height={Height}
              FontColor={hexToRgb(FontColorHex)}
              GrossValue={offer.GrossValue}
              Currency={offer.Currency}
              BackgroundColor={hexToRgb(BackgroundColorHex)}
              Name={offer.Name}
              Category={offer.Category}
              removeOffer={removeOffer}
              isEditing={isEditing}
              SecondCategoryId={offer.SecondCategoryId}
              ThirdCategoryId={offer.ThirdCategoryId}
              Description={offer.Description}
              BlockOpacityIn0To100Ratio={block.BlockOpacityIn0To100Ratio}
            />
          ) : (
            <div></div>
          )
        ) : (
          <TrackBlockRowView
            isMobile={mobileOverview}
            offer={block.Offer}
            offerId={OfferId}
            ImgSrc={block.Offer.CoverFile}
            SoundSrc={block.Offer.ProductDemoFile}
            IsWinWin={block.Offer.IsWinWin}
            ProductType={block.Offer.ProductType}
            Width={Width}
            TitleColorHex={block.TitleColorHex}
            Height={Height}
            FontColor={hexToRgb(FontColorHex)}
            GrossValue={block.Offer.GrossValue}
            Currency={block.Offer.Currency}
            BackgroundColor={hexToRgb(BackgroundColorHex)}
            Name={block.Offer.Name}
            Category={block.Offer.Category}
            removeOffer={removeOffer}
            isEditing={isEditing}
            SecondCategoryId={block.Offer.SecondCategoryId}
            ThirdCategoryId={block.Offer.ThirdCategoryId}
            Description={block.Offer.Description}
            BlockOpacityIn0To100Ratio={block.BlockOpacityIn0To100Ratio}
          />
        ))}
    </TrackMainBlockWrapper>
  );
};

export default TrackBlockComponent;
