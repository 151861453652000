import React, {useState} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import api from 'services/axiosConfig';
import useAlert from 'hooks/useAlert';
import mugoImage from 'images/post_mugo.png';
import {LoadingCircle} from 'utils/loadingCircle';
import {MobileInput} from 'components/MobileInput';
import {InputView} from 'pages/MyStudio/Account/styles';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  /* @media (max-width: 1045px) {
    width: 360px;
  } */
`;

export const MobilePageHeader = styled.h4`
  color: #07020f;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.1px;
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.colors.pageBodyLeft};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const MobileMain = styled.div`
  width: 348px;
  min-height: calc(100vh - 105px);
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  padding: 40px 0px 14px;
  flex-direction: column;
  align-items: center;
  background: transparent;

  & > a {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-decoration: underline;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    padding: 40px 14px 14px;
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: 1fr;
  gap: 11px 0;
  grid-auto-rows: unset;

  @media (max-width: 1045px) {
  }
`;

export const RecoverWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-row: span 6;
  /* height: 372px; */

  @media (max-width: 1045px) {
  }
`;

export const RecoverHeader = styled.div`
  width: 100%;
  position: relative;
  height: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: white;
  }
`;

export const RecoverContent = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px;
  gap: 14px;

  & > a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 11px;
    /* line-height: 21px; */
  }
`;

export const InputWrapper = styled.div<{isRedBorder?: boolean}>`
  background: ${({isRedBorder}) => (isRedBorder ? `#FF7E7D` : 'transparent')};
  border: 1px solid rgba(112, 112, 112, 0.3);
  height: 22px;
  border-radius: 5px;
  width: 100%;
  margin-top: ${({isRedBorder}) => (isRedBorder ? `0` : '10px')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.625px;
    font-weight: 400;
    margin-left: 8.5px;
  }

  & > input {
    text-align: right;
    font-size: 11px;
    line-height: 18px;
    margin-right: 8.5px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    letter-spacing: 0.625px;
    font-weight: 400;
    background-color: transparent;
    border: none;

    &::placeholder {
      color: rgba(7, 2, 15, 0.5);
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: ${({theme}) =>
        theme.colors.fontBlackOp50} !important;
    }
  }

  & > input:focus {
    outline: none;
  }
`;

export const PrimaryButton = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
}>`
  width: 100%;
  height: 44px;
  border: none;
  border: ${({borderColor}) =>
    !borderColor ? 'none' : `1px solid ${borderColor}`};
  background: ${({background}) => (!background ? '#3A87F0' : background)};
  border-radius: 15px;
  font-size: 15px;
  color: ${({color}) => (!color ? '#ffffff' : color)};
  transition: all 0.15s;
  cursor: pointer;
  display: flex;
  align-items: center;
  //justify-content: center;
  gap: 6px;
  font-weight: 400;
  letter-spacing: 0.7px;
  position: relative;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 3px;
    letter-spacing: 0.07px;
    font-weight: 400;
    font-size: 17px;
  }
`;

export const PageHeaderWrapper = styled.div`
  width: 1020px;
  display: flex;
  height: 590px;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  background: ${({theme}) => theme.colors.bgWhite};
  border-radius: 24px 24px 21px 21px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.625px;
  }
`;

export const PageHeaderContent = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  display: grid;
  align-items: center;
  grid-template-columns: 535px 485px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightSectionContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 24.5px 85px 11px 88px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > a {
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-decoration: underline;
  }
`;

interface RecoverData {
  email: string;
  code: string;
  password: string;
  replayPassword: string;
}

const RecoverComponent: React.FC = () => {
  const [recoverData, setRecoverData] = useState<RecoverData>({
    code: '',
    email: '',
    password: '',
    replayPassword: '',
  });
  const [isPasswordChangeView, setIsPasswordChangeView] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const {Alert} = useAlert();

  const handleRecoverDataChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setRecoverData((prev) => ({...prev, [e.target.id]: e.target.value}));

  const requestPasswordChange = async () => {
    const {email} = recoverData;
    if (email.length === 0)
      return Alert('Email nie może być pusty', 'Komunikat');

    if (isLoading) return;
    setIsLoading(true);
    try {
      const body = {
        Email: email,
      };

      const response = await api.post(`Auth/RequestPasswordChange`, body);
      Alert(
        'Na Twój adres email wysłaliśmy kod potrzebny do zmiany hasła',
        'Komunikat',
      );
      setIsPasswordChangeView(true);
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsLoading(false);
    }
  };

  const changePassword = async () => {
    const {email, code, password, replayPassword} = recoverData;

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (email.length === 0)
      return Alert('Email nie może być pusty', 'Komunikat');

    if (password.length === 0)
      return Alert('Hasło nie może być puste', 'Komunikat');
    if (!passwordRegex.test(password))
      return Alert(
        'Hasło musi zawierać minimum 8 znaków, 1 znak specjalny, 1 wielką i małą literę i 1 liczbę',
        'Komunikat',
      );

    if (password !== replayPassword)
      return Alert('Oba hasła muszą być identyczne', 'Komunikat');

    try {
      const body = {
        Email: email,
        Code: code,
        Password: password,
      };
      console.log(body);
      const response = await api.put(`Auth/ChangePassword`, body);
      Alert('Twoje hasło zostało zmienione', 'Komunikat');
      navigate('/identity/login');
    } catch (error) {
      console.error(error);
      alert(error.response.data.Error.Message);
    }
  };

  return (
    <>
      <MainWrapper>
        <Main>
          {isLoading && <LoadingCircle />}
          <PageHeaderWrapper>
            <PageHeaderHeaderWrapper>
              <PageHeaderHeader>
                <p>Witamy w MUGO STUDIO!</p>
              </PageHeaderHeader>
            </PageHeaderHeaderWrapper>
            <PageHeaderContent>
              <ImageWrapper>
                <img src={mugoImage} />
              </ImageWrapper>
              <RightSectionContent>
                {isPasswordChangeView ? (
                  <>
                    <h1 style={{marginBottom: '27.5px'}}>Komunikat </h1>
                    <p>Email z linkiem do zmiany hasła został wysłany</p>
                    {/*<InputWrapper>*/}
                    {/*  <p>Kod</p>*/}
                    {/*  <input*/}
                    {/*    type="text"*/}
                    {/*    placeholder="Pole obowiązkowe"*/}
                    {/*    value={recoverData.code}*/}
                    {/*    onChange={handleRecoverDataChange}*/}
                    {/*    id="code"*/}
                    {/*  />*/}
                    {/*</InputWrapper>*/}
                    {/*<InputWrapper>*/}
                    {/*  <p>Nowe hasło</p>*/}
                    {/*  <input*/}
                    {/*    type="password"*/}
                    {/*    placeholder="Pole obowiązkowe"*/}
                    {/*    value={recoverData.password}*/}
                    {/*    onChange={handleRecoverDataChange}*/}
                    {/*    id="password"*/}
                    {/*  />*/}
                    {/*</InputWrapper>*/}
                    {/*<InputWrapper style={{marginBottom: '308px'}}>*/}
                    {/*  <p>Potwierdź hasło</p>*/}
                    {/*  <input*/}
                    {/*    type="password"*/}
                    {/*    placeholder="Pole obowiązkowe"*/}
                    {/*    value={recoverData.replayPassword}*/}
                    {/*    onChange={handleRecoverDataChange}*/}
                    {/*    id="replayPassword"*/}
                    {/*  />*/}
                    {/*</InputWrapper>*/}
                    {/*<PrimaryButton onClick={() => changePassword()}>*/}
                    {/*  Zmień*/}
                    {/*</PrimaryButton>*/}
                  </>
                ) : (
                  <>
                    <h1 style={{marginBottom: '27.5px'}}>
                      Przypomnienie hasła
                    </h1>
                    <InputView isEditing={true} style={{marginBottom: '310px'}}>
                      <p>Email</p>
                      <input
                        type="text"
                        placeholder="Pole obowiązkowe"
                        value={recoverData.email}
                        onChange={handleRecoverDataChange}
                        id="email"
                      />
                    </InputView>
                    <Link to={'/identity/login'}>Logowanie</Link>
                    <Link
                      style={{marginBottom: '14px'}}
                      to={'/identity/register'}
                    >
                      Zarejestruj się
                    </Link>
                    <PrimaryButton
                      onClick={() => requestPasswordChange()}
                      style={{justifyContent: 'center'}}
                    >
                      {isLoading ? 'Wysyłanie...' : 'Dalej'}
                    </PrimaryButton>
                  </>
                )}
              </RightSectionContent>
            </PageHeaderContent>
          </PageHeaderWrapper>
          {/* <p>Witamy w MUGO STUDIO</p>
        <Grid>
          {isPasswordChangeView ? (
            <RecoverWrapper>
              <RecoverHeader>
                <p>ZMIANA HASŁA</p>
              </RecoverHeader>
              <RecoverContent>
                <InputWrapper>
                  <p>Kod</p>
                  <input
                    type="text"
                    placeholder="Uzupełnij"
                    value={recoverData.code}
                    onChange={handleRecoverDataChange}
                    id="code"
                  />
                </InputWrapper>
                <InputWrapper>
                  <p>Nowe hasło</p>
                  <input
                    type="password"
                    placeholder="Uzupełnij"
                    value={recoverData.password}
                    onChange={handleRecoverDataChange}
                    id="password"
                  />
                </InputWrapper>
                <InputWrapper>
                  <p>Potwierdź hasło</p>
                  <input
                    type="password"
                    placeholder="Uzupełnij"
                    value={recoverData.replayPassword}
                    onChange={handleRecoverDataChange}
                    id="replayPassword"
                  />
                </InputWrapper>
                <PrimaryButton onClick={() => changePassword()}>
                  Zmień
                </PrimaryButton>
              </RecoverContent>
            </RecoverWrapper>
          ) : (
            <RecoverWrapper>
              <RecoverHeader>
                <p>PRZYPOMNIENIE HASŁA</p>
              </RecoverHeader>
              <RecoverContent>
                <InputWrapper>
                  <p>Email</p>
                  <input
                    type="text"
                    placeholder="Uzupełnij"
                    value={recoverData.email}
                    onChange={handleRecoverDataChange}
                    id="email"
                  />
                </InputWrapper>
                <Link to={'/identity/login'}>Logowanie</Link>
                <Link to={'/identity/register'}>Zarejestruj się</Link>
                <PrimaryButton onClick={() => requestPasswordChange()}>
                  Wyślij
                </PrimaryButton>
              </RecoverContent>
            </RecoverWrapper>
          )}
        </Grid> */}
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        <MobileMain>
          {isLoading && <LoadingCircle />}
          <MobilePageHeader style={{marginBottom: '82px'}}>
            Przypomnienie hasła
          </MobilePageHeader>
          <div style={{marginBottom: '72px', width: '100%'}}>
            <InputView isEditing={true}>
              <p>Email</p>
              <input
                type="text"
                value={recoverData.email}
                onChange={handleRecoverDataChange}
                id="email"
              />
            </InputView>
          </div>
          <Link to={'/identity/login'}>Logowanie</Link>
          <Link style={{marginBottom: '95px'}} to={'/identity/register'}>
            Zarejestruj się
          </Link>
          <PrimaryButton
            onClick={() => requestPasswordChange()}
            style={{marginBottom: '7px', justifyContent: 'center'}}
          >
            {isLoading ? 'Wysyłanie...' : 'Dalej'}
          </PrimaryButton>
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};

export default RecoverComponent;
