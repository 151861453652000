import React, {useState} from 'react';
import {Block} from '../../interface';
import {GreatHeaderBlockTitle, GreatHeaderBlockWrapper} from './styles';
import {hexToRgb} from '../..';
import useAlert from 'hooks/useAlert';

interface Props {
  theme: string;
  block: Block;
  isEditing: boolean;
  changeText: ({title}) => void;
  mobileOverview: boolean;
}

export const GreatHeaderBlock: React.FC<Props> = ({
  theme,
  block,
  isEditing,
  changeText,
  mobileOverview,
}) => {
  const initTitle = block.Title === null ? '' : block.Title;

  const [title, setTitle] = useState<string>(initTitle);

  const {Alert} = useAlert();

  const checkTitleLength = () => {
    if (title.length > 15) {
      setTitle(title.slice(0, 15));
      changeText({title: title.slice(0, 15)});
      return Alert('Tekst ma limit 15 znaków', 'Tekst ma limit 15 znaków');
    }
  };
  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value);
    changeText({title: e.target.value});
  };

  const {Format, HorizontalFormat, VerticalFormat, Interline} = block;

  return (
    <GreatHeaderBlockWrapper
      bgColor={hexToRgb(block.BackgroundColorHex)}
      backgroundAlpha={block.BlockOpacityIn0To100Ratio}
      style={
        VerticalFormat === 1
          ? {justifyContent: 'flex-start'}
          : VerticalFormat === 4
          ? {justifyContent: 'flex-end'}
          : VerticalFormat === 8
          ? {justifyContent: 'justify'}
          : {justifyContent: 'center'}
      }
    >
      <GreatHeaderBlockTitle
        mobileOverview={mobileOverview}
        fontColor={hexToRgb(block.FontColorHex)}
        interline={Interline}
      >
        {isEditing ? (
          <textarea
            style={
              HorizontalFormat === 1
                ? {textAlign: 'center'}
                : HorizontalFormat === 4
                ? {textAlign: 'right'}
                : HorizontalFormat === 8
                ? {textAlign: 'justify'}
                : {textAlign: 'left'}
            }
            placeholder={'Nagłówek'}
            onKeyUp={checkTitleLength}
            value={title}
            onChange={(e) => handleTitleChange(e)}
          />
        ) : (
          <p
            style={
              HorizontalFormat === 1
                ? {textAlign: 'center'}
                : HorizontalFormat === 4
                ? {textAlign: 'right'}
                : HorizontalFormat === 8
                ? {textAlign: 'justify'}
                : {textAlign: 'left'}
            }
          >
            {title.length > 15 ? title.slice(0, 15) : title}
          </p>
        )}
      </GreatHeaderBlockTitle>
    </GreatHeaderBlockWrapper>
  );
};
