import styled from 'styled-components';

export const ListWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: #17181b;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ListItem = styled.div`
  border: white 1px solid;
  cursor: pointer;
  margin: 5px;
  width: 80%;
  text-align: center;
  & > p {
    color: white;
  }
`;
