import React, {useState} from 'react';
import {ErrorStarWrapper, ErrorText, Star} from './styles';

interface Props {
  text: string;
  right?: boolean;
  fixed?: boolean;
  color?: string;
  onlineInfo?: boolean;
}

export const ErrorStar: React.FC<Props> = ({
  text,
  right = false,
  fixed = false,
  color = '',
  onlineInfo = false,
}) => {
  const [isShowing, setIsShowing] = useState<boolean>(false);
  return (
    <ErrorStarWrapper>
      <Star
        onMouseEnter={() => setIsShowing(true)}
        onMouseLeave={() => setIsShowing(false)}
      >
        <p style={color !== '' ? {color: color} : null}>*</p>
        {isShowing && (
          <ErrorText
            info={onlineInfo}
            style={
              right
                ? {right: '5px'}
                : fixed
                ? {
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                  }
                : null
            }
          >
            <p>{text}</p>
          </ErrorText>
        )}
      </Star>
    </ErrorStarWrapper>
  );
};
