import axios from 'axios';

const createConfig = () => {
  console.log(window._env_.API_URL)
  const api = axios.create({
    baseURL: window._env_.API_URL,
  });

  return api;
};

const api = createConfig();

export default api;
