import React, {useContext, useEffect, useState} from 'react';
import {
  OfferBlockButtonWrapper,
  OfferBlockContent,
  OfferBlockFooter,
  OfferBlockNameAndButtonWrapper,
  OfferBlockHeader,
  OfferBlockPriceButton,
  OfferBlockWinWinInfo,
  OfferBlockWrapper,
  OfferNewListWrapper,
  OfferListItem,
  OfferBorderForNewList,
  OfferAddButonWrapper,
  DeleteButton,
  OfferTitleBlockHeader,
  TrackMainBlockWrapper,
  NewOffertBlockWrapper,
  NewOffertBlockFooter,
  NewOffertBlockColumnWrapper,
  NewOffertNameProduct,
  NewOffertCategoryWrapper,
  NewOffertProductType,
  NewOfferDescription,
  NewOffertFooterContent,
  NewOfertPirceWrapper,
  NewOfertWinWinWrapper,
  NewOffertBlockRowWrapper,
  NewOffertBlockRowContentWrapper,
} from './styles';

import {light} from '../../../../../constants/theme';

import {hexToRgb} from '../../index';
import {Block} from '../../interface';
import {Offer} from '../RecomendedProductionBlock';
import {useCookies} from 'react-cookie';
import api from '../../../../../services/axiosConfig';
import {useNavigate} from 'react-router-dom';
import {ProducerDataContext} from '../../../../../contexts/ProducerDataContext';
import {ResolutionContext} from 'contexts/ResolutionContext';

import deleteBlack from '../../../../../images/svg/delete-black.svg';
import {OfferBlockRowView} from './OfferBlockRowView';
import {OfferBlockColumnView} from './OfferBlockColumnView';
import {
  ButtonBg,
  ButtonLeftAndRightWrapper,
} from '../RecomendedProductionBlock/styles';
import {EditHoverContext} from '../../../../../contexts/EditHoverContext';
import defaultCoverImg from '../../../../../images/svg/defaultOfferImg.svg';

interface Props {
  theme: string;
  block: Block;
  mobileOverview: boolean;
  isEditing: boolean;
  updateOffer: ({offer: Offer}) => void;
}

const OfferBlockComponent: React.FC<Props> = ({
  theme,
  mobileOverview,
  block,
  isEditing,
  updateOffer,
}) => {
  const {
    WebArea,
    FontColorHex,
    BackgroundColorHex,
    OfferId,
    Offer,
    BlockOpacityIn0To100Ratio,
    TitleColorHex,
  } = block;
  const {Width, Height} = WebArea;
  const initOffer = {
    Id: 'ADD',
    Name: `Ilustracja wygenerowana przez sztuczną
inteligencję`,
    IsWinWin: true,
    Description: '',
    CoverFile: defaultCoverImg,
    GrossValue: 40,
    Currency: 'zł',
    Category: {Name: 'Grafika', CategoryId: 'test1'},
    SecondCategoryId: {CategoryId: 'Ilustracja', Name: 'Ilustracja'},
    ThirdCategoryId: {CategoryId: null, Name: ''},
    ProductType: 0,
  };

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [offer, setOffer] = useState<Offer>(initOffer);
  const [category, setCategory] = useState<string>('Muzyka');
  const [allOffers, setAllOffers] = useState([]);
  const {
    Id,
    Name,
    IsWinWin,
    Description,
    GrossValue,
    Currency,
    Category,
    SecondCategoryId,
    ThirdCategoryId,
    CoverFile,
    ProductType,
  } = offer;

  const [cookies, setCookie] = useCookies(['token']);
  const navigate = useNavigate();

  const {decodedToken} = useContext(ProducerDataContext);
  const {windowWidth} = useContext(ResolutionContext);
  const [blockHover, setBlockHover] = useContext(EditHoverContext);

  const toProductInfo = (id: string) => {
    if (!isEditing) {
      navigate(`/products/main/${id}`);
    }
  };

  const dowloadDataToAdd = async () => {
    try {
      const response = await api.get(`Offer`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setAllOffers(
        response.data.Response.filter(
          (item) => item.ProducerId === decodedToken.ProducerId,
        )
          .filter((item) => item.ProductType !== 8)
          .map((item) => ({
            Id: item.Id,
            Name: item.Name,
            Category: item.Category,
            SecondCategoryId: item.SecondCategoryId,
            ThirdCategoryId: item.ThirdCategoryId,
            IsWinWin: item.IsWinWin,
            Description: item.Description,
            GrossValue: item.GrossValue,
            Currency: item.Currency,
            CoverFile: item.CoverFile,
            ProductType: item.ProductType,
          })),
      );
      setIsAdding(true);
    } catch (error) {
      console.error(error);
    }
  };

  function addItemToOffer(item: Offer) {
    // updateOffer({offer: item});
    setOffer(item);
    setIsAdding(false);
  }

  function removeOffer() {
    setOffer({
      Id: 'ADD',
      Name: '',
      IsWinWin: false,
      Description: '',
      GrossValue: 0,
      Currency: '',
      Category: {CategoryId: '', Name: ''},
      SecondCategoryId: {CategoryId: '', Name: ''},
      ThirdCategoryId: {CategoryId: '', Name: ''},
      ProductType: 0,
    });
    // updateOffer({offer: ''});
  }

  const hoverTextOn = (text: string) => {
    setBlockHover((prev) => ({Active: true, Text: text}));
  };

  const hoverTextOff = () => {
    setBlockHover((prev) => ({Active: false, Text: ''}));
  };
  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);
  return (
    <TrackMainBlockWrapper
      isMobile={mobileOverview}
      backgroundAlpha={BlockOpacityIn0To100Ratio}
      backgroundColor={BackgroundColorRgb}
    >
      {offer.Id === 'ADDs' && isEditing && (
        <OfferBorderForNewList theme={theme}>
          {isAdding ? (
            <OfferNewListWrapper theme={theme}>
              {allOffers.map((item) => (
                <OfferListItem
                  onClick={() => addItemToOffer(item)}
                  theme={theme}
                  key={item.Id}
                >
                  <p>{item.Name}</p>
                </OfferListItem>
              ))}
            </OfferNewListWrapper>
          ) : (
            <OfferAddButonWrapper onClick={dowloadDataToAdd} theme={theme}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
              >
                <g
                  id="Icon_feather-plus-circle"
                  data-name="Icon feather-plus-circle"
                  transform="translate(0.5 0.5)"
                  opacity="0.76"
                >
                  <path
                    id="Path_3319"
                    data-name="Path 3319"
                    d="M11,7A4,4,0,1,1,7,3,4,4,0,0,1,11,7Z"
                    transform="translate(-3 -3)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_3320"
                    data-name="Path 3320"
                    d="M18,12v3.2"
                    transform="translate(-14 -9.6)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_3321"
                    data-name="Path 3321"
                    d="M12,18h3.2"
                    transform="translate(-9.6 -14)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            </OfferAddButonWrapper>
          )}
        </OfferBorderForNewList>
      )}
      {!isAdding && offer.Id !== 'ADDs' && (
        <NewOffertBlockWrapper
          style={
            Width === 3 && Height === 3
              ? {
                  border: `1px solid rgba(255, 255, 255, 0.21)`,
                  borderRadius: '13px',
                }
              : null
          }
          onClick={
            block.Offer === null
              ? () => console.log('brak oferty')
              : () => toProductInfo(Offer.ProductId)
          }
        >
          {block.Offer === null ? (
            OfferId === '00000000-0000-0000-0000-000000000000' ? (
              <OfferBlockRowView
                isMobile={mobileOverview}
                removeOffer={removeOffer}
                Width={Width}
                Height={Height}
                isEditing={isEditing}
                offer={offer}
                fontColor={hexToRgb(FontColorHex)}
                bgColor={hexToRgb(TitleColorHex)}
                productId={offer.ProductId}
              />
            ) : (
              <div></div>
            )
          ) : (
            <OfferBlockRowView
              isMobile={mobileOverview}
              removeOffer={removeOffer}
              Width={Width}
              Height={Height}
              isEditing={isEditing}
              offer={block.Offer}
              fontColor={hexToRgb(FontColorHex)}
              bgColor={hexToRgb(TitleColorHex)}
              productId={offer.ProductId}
            />
          )}
        </NewOffertBlockWrapper>
      )}
    </TrackMainBlockWrapper>
  );
};

export default OfferBlockComponent;
