import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useNavigate} from 'react-router-dom';

import {
  MainWrapper,
  SearchBarWrapper,
  SearchButton,
  SingleBannerWrapper,
} from './styles';

import SliderImage1 from '../HomeImages/slider_2_2.jpg';
import MobileSliderImage1 from '../HomeImages/mobilka.png';
import SliderImage2 from '../HomeImages/2.png';
import MobileSliderImage2 from '../HomeImages/lot_mob.png';
import SliderImage3 from '../HomeImages/3.png';
import SliderImage4 from '../HomeImages/4.png';
import MobileSliderImage3 from '../HomeImages/prod_mob.png';
import MobileSliderImage4 from '../HomeImages/twor_mob.png';

export const HeaderSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [firstBannerInputText, setFirstBannerInputText] = useState<string>();
  const [secondBannerInputText, setSecondBannerInputText] = useState<string>();
  const [thirdBannerInputText, setThirdBannerInputText] = useState<string>();
  const [fourthBannerInputText, setFourthBannerInputText] = useState<string>();
  const [width, setWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const handleFirstBannerInputTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setFirstBannerInputText(e.target.value);

  const handleSecondBannerInputTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setSecondBannerInputText(e.target.value);

  const handleThirdBannerInputTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setThirdBannerInputText(e.target.value);

  const handleFourthBannerInputTextChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setFourthBannerInputText(e.target.value);

  const handleFirstBannerOnClick = () => {
    if (!firstBannerInputText) return;
    navigate('tracks/main');
    sessionStorage.setItem('trackFilterSearchString', firstBannerInputText);
  };

  const handleSecondBannerOnClick = () => {
    if (!secondBannerInputText) return;
    navigate('products/main');
    sessionStorage.setItem('offerFilterSearchString', secondBannerInputText);
    sessionStorage.setItem('offerFilterProductType', '4');
  };

  const handleThirdBannerOnClick = () => {
    if (!thirdBannerInputText) return;
    navigate('products/main');
    sessionStorage.setItem('offerFilterSearchString', thirdBannerInputText);
    sessionStorage.setItem('offerFilterProductType', '1');
  };

  const handleFourthBannerOnClick = () => {
    if (!fourthBannerInputText) return;
    navigate('producers/main');
    sessionStorage.setItem(
      'producersFilterSearchString',
      fourthBannerInputText,
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const mainWrapperStyle = {
    width: width <= 400 ? `${width}px` : '',
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 20000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const sliderList = [
    {
      id: 1,
      header: 'Na dzień dobry znajdź Usługę \n natychmiast podejmuj realizacje',
      searchBarPlaceholder: 'Znajdź swój wymarzony track',
      text: 'Najlepsze wyniki uzyskasz wpisując specjalizacje i inne \n charakterystyczne cechy dla kategorii produkcji jaką chcesz \n spełnić! np. Teledysk, Tekst piosenki, Social media.',
      background: SliderImage1,
      mobileBackground: MobileSliderImage1,
      bannerInputText: firstBannerInputText,
      handleInputTextChange: handleFirstBannerInputTextChange,
      handleOnClick: handleFirstBannerOnClick,
    },
    {
      id: 2,
      header: 'Na dzień dobry znajdź Twórcę \n natychmiast podejmuj realizacje',
      searchBarPlaceholder: 'Usługi przekraczające oczekiwania',
      text: 'Najlepsze wyniki uzyskasz wpisując specjalizacje i inne charakterystyczne cechy dla kategorii produkcji jaką chcesz spełnić! np. Teledysk, Tekst piosenki, Social media.',
      background: SliderImage2,
      mobileBackground: MobileSliderImage2,
      bannerInputText: secondBannerInputText,
      handleInputTextChange: handleSecondBannerInputTextChange,
      handleOnClick: handleSecondBannerOnClick,
    },
    {
      id: 3,
      header:
        'Na dzień dobry znajdź Digital \n natychmiast podejmuj realizacje',
      searchBarPlaceholder: 'Poznaj bazę produktów cyfrowych',
      text: 'Najlepsze wyniki uzyskasz wpisując specjalizacje i inne \n charakterystyczne cechy dla kategorii produkcji jaką chcesz \n spełnić! np. Teledysk, Tekst piosenki, Social media.',
      background: SliderImage3,
      mobileBackground: MobileSliderImage3,
      bannerInputText: thirdBannerInputText,
      handleInputTextChange: handleThirdBannerInputTextChange,
      handleOnClick: handleThirdBannerOnClick,
    },
    {
      id: 4,
      header: 'Na dzień dobry znajdź Track \n natychmiast podejmuj realizacje',
      searchBarPlaceholder: 'Poznaj niezwykłych twórców',
      text: 'Najlepsze wyniki uzyskasz wpisując specjalizacje i inne \n charakterystyczne cechy dla kategorii produkcji jaką chcesz \n spełnić! np. Teledysk, Tekst piosenki, Social media.',
      background: SliderImage4,
      mobileBackground: MobileSliderImage4,
      bannerInputText: fourthBannerInputText,
      handleInputTextChange: handleFourthBannerInputTextChange,
      handleOnClick: handleFourthBannerOnClick,
    },
  ];

  return (
    <MainWrapper style={mainWrapperStyle}>
      <Slider {...settings}>
        {sliderList.map((banner, index) => {
          const {
            background,
            header,
            searchBarPlaceholder,
            text,
            id,
            mobileBackground,
            bannerInputText,
            handleInputTextChange,
            handleOnClick,
          } = banner;
          return (
            <SingleBannerWrapper
              key={index}
              background={background}
              mobileBackground={mobileBackground}
            >
              {/* <h1>{header}</h1> */}
              <SearchBarWrapper>
                <input
                  type="text"
                  value={bannerInputText}
                  onChange={handleInputTextChange}
                  placeholder={searchBarPlaceholder}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleOnClick();
                    }
                  }}
                />
                <SearchButton onClick={() => handleOnClick()}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.146"
                    height="14"
                    viewBox="0 0 14.146 14"
                  >
                    <path
                      id="Path_1507"
                      data-name="Path 1507"
                      d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                      transform="translate(-2587.94 -535.001)"
                      fill="#fff"
                    />
                  </svg>
                </SearchButton>
              </SearchBarWrapper>
              {/* <p>{text}</p> */}
            </SingleBannerWrapper>
          );
        })}
      </Slider>
    </MainWrapper>
  );
};
