import React from 'react';
import {ListItem, ListWrapper} from './styles';
import {Offer} from '../index';

interface Props {
  productsList: Offer[];
  addItemToRecomended: (item: Offer) => void;
  closeList: () => void;
}

export const RecomendedProductionListView: React.FC<Props> = ({
  productsList,
  addItemToRecomended,
  closeList,
}) => {
  return (
    <ListWrapper>
      <button
        onClick={closeList}
        style={{
          zIndex: '99',
          position: 'absolute',
          left: 0,
          top: 0,
          color: 'white',
          fontSize: '24px',
          cursor: 'pointer',
          background: 'transparent',
        }}
      >
        X
      </button>
      {productsList.map((product) => (
        <ListItem key={product.Id} onClick={() => addItemToRecomended(product)}>
          <p>{product.Name}</p>
        </ListItem>
      ))}
    </ListWrapper>
  );
};
