import React, {createContext, useContext, useState, useEffect} from 'react';

interface IsOrderPageOpenContextType {
  isOrderPageOpen: boolean;
  setIsOrderPageOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsOrderPageOpenContext = createContext<
  IsOrderPageOpenContextType | undefined
>(undefined);

export const useIsOrderPageOpenContext = () => {
  const context = useContext(IsOrderPageOpenContext);
  if (!context) {
    throw new Error(
      'useIsOrderPageOpenContext must be used within a IsOrderPageOpenProvider',
    );
  }
  return context;
};

export const IsOrderPageOpenProvider: React.FC = ({children}) => {
  const [isOrderPageOpen, setIsOrderPageOpen] = useState(false);

  return (
    <IsOrderPageOpenContext.Provider
      value={{isOrderPageOpen, setIsOrderPageOpen}}
    >
      {children}
    </IsOrderPageOpenContext.Provider>
  );
};
