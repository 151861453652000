import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {Block} from '../../interface';

import deleteBlack from '../../../../../images/svg/delete-black.svg';
import {
  BannerAddButonWrapper,
  BannerBlockContent,
  BannerBlockHeader,
  BannerBlockWrapper,
  BannerHoverImg,
  BannerImgWrapper,
  BannerItemForList,
  BannerListWrapper,
  DeleteButton,
} from './styles';
import {hexToRgb} from '../../index';
import {EditHoverContext} from 'contexts/EditHoverContext';

import contentBlack from '../../../../../images/content_black.png';
import contentWhite from '../../../../../images/content_white.png';
import portfolioBlack from '../../../../../images/portfolio_black.png';
import portfolioWhite from '../../../../../images/portfolio_white.png';
import shopBlack from '../../../../../images/shop_black.png';
import shopWhite from '../../../../../images/shop_white.png';

const bannerList = [
  {Url: contentBlack, Name: 'Kontent (czarny)'},
  {Url: contentWhite, Name: 'Kontent (biały)'},
  {Url: portfolioBlack, Name: 'Portfolio (czarny)'},
  {Url: portfolioWhite, Name: 'Portfolio (biały)'},
  {Url: shopBlack, Name: 'Sklep (czarny)'},
  {Url: shopWhite, Name: 'Sklep (biały)'},
];

import useAlert from 'hooks/useAlert';
import {
  ButtonBg,
  ButtonLeftAndRightWrapper,
} from '../RecomendedProductionBlock/styles';
import {VisitedProducerContext} from '../../../../../contexts/VisitedProducerContext';

interface Props {
  theme: string;
  block: Block;
  isEditing: boolean;
  updateBanner: ({banner}) => void;
  mobileOverview: boolean;
}

export const BannerBlockComponent: React.FC<Props> = ({
  theme,
  block,
  isEditing,
  updateBanner,
  mobileOverview,
}) => {
  const initImg = block.CurrentFileFtpFilePath
    ? block.CurrentFileFtpFilePath
    : '';
  const initDelte =
    block.RemoveCurrentBanner !== undefined ? block.RemoveCurrentBanner : false;
  const [img, setImg] = useState(initImg);

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [hoveredImg, setHoveredImg] = useState<string>('');
  const [imgDeleted, setImgDeleted] = useState<boolean>(false);
  const [visitedProducer, setVisitedProducer] = useContext(
    VisitedProducerContext,
  );
  const [blockHover, setBlockHover] = useContext(EditHoverContext);

  const {FontColorHex, WebArea, BackgroundColorHex, BlockOpacityIn0To100Ratio} =
    block;
  const {Width, Height} = WebArea;

  const {Alert} = useAlert();

  const imgRef = useRef<HTMLInputElement>(document.createElement('input'));

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getImageFile = async (e: any) => {
    setImgDeleted(false);
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();
      try {
        if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
          const base64 = await toBase64(e.target.files[0]);
          const newBanner = {
            Base64BannerString: base64.toString().split(',')[1],
            CurrentFileFtpFilePath: URL.createObjectURL(e.target.files[0]),
            RemoveCurrentBanner: false,
          };
          console.log(e.target.files[0], newBanner.CurrentFileFtpFilePath);
          updateBanner({banner: newBanner});
          setImg(URL.createObjectURL(e.target.files[0]));
          Alert(`Dodano plik ${e.target.files[0].name}`, 'Komunikat');
        } else {
          Alert('Wybierz zdjęcie w formacie .jpg lub .png', 'Komunikat');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const deleteImg = () => {
    const newBanner = {
      Base64BannerString: '',
      CurrentFileFtpFilePath: '',
      RemoveCurrentBanner: true,
    };
    setImgDeleted(true);
    updateBanner({banner: newBanner});
    setImg('');
  };

  const saveHoveredImg = async (img: string) => {
    // console.log(img.slice(22));
    setImgDeleted(false);
    setIsHovered(false);
    console.log('img w img', img);
    try {
      const base64 = img.slice(22);
      const newBanner = {
        Base64BannerString: base64,
        CurrentFileFtpFilePath: img,
        RemoveCurrentBanner: false,
      };
      updateBanner({banner: newBanner});
      setImg(img);
      Alert(`Dodano plik `, 'Komunikat');
    } catch (error) {
      console.error(error);
    }
  };

  const hoverTextOn = (text: string) => {
    setBlockHover((prev) => ({Active: true, Text: text}));
  };

  const hoverTextOff = () => {
    setBlockHover((prev) => ({Active: false, Text: ''}));
  };

  const hoverOn = (img: string) => {
    setHoveredImg(img);
    setIsHovered(true);
  };

  const hoverOf = () => {
    setIsHovered(false);
    setHoveredImg('');
  };
  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);
  return (
    <BannerBlockWrapper
      backgroundAlpha={BlockOpacityIn0To100Ratio}
      backgroundColor={BackgroundColorRgb}
    >
      {/*<BannerBlockHeader theme={theme}>*/}
      {/*  <svg*/}
      {/*    xmlns="http://www.w3.org/2000/svg"*/}
      {/*    width="27.376"*/}
      {/*    height="21"*/}
      {/*    viewBox="0 0 27.376 21"*/}
      {/*  >*/}
      {/*    <path*/}
      {/*      id="Icon_zocial-persona"*/}
      {/*      data-name="Icon zocial-persona"*/}
      {/*      d="M11.233,16.481a51.26,51.26,0,0,1-5.675-.192s.222-3.388,1.772-4,1.93-.523,2.467-.848l.04-.022v-.912A4.2,4.2,0,0,1,8.669,8.594a1.514,1.514,0,0,1-.441-1.033,1.407,1.407,0,0,1,.215-.735c.092-1.91,1.307-3.175,2.794-3.175s2.7,1.264,2.794,3.175a1.4,1.4,0,0,1,.215.735A1.514,1.514,0,0,1,13.8,8.594a4.192,4.192,0,0,1-1.167,1.914v.912l.04.022c.539.325.918.238,2.468.848s1.771,4,1.771,4a51.529,51.529,0,0,1-5.683.192M18.37-.035H.994a1,1,0,0,0-1,1v19a1,1,0,0,0,1,1H18.37l9-10.5-9-10.5Z"*/}
      {/*      transform="translate(0.006 0.035)"*/}
      {/*      fill="#0c426a"*/}
      {/*    />*/}
      {/*  </svg>*/}

      {/*  <h4>Banner</h4>*/}
      {/*</BannerBlockHeader>*/}
      <BannerBlockContent>
        {isEditing && img === '' ? (
          <BannerListWrapper Width={Width} Height={Height}>
            <BannerItemForList
              onClick={() => {
                imgRef.current.click();
              }}
              style={{border: 'none'}}
            >
              {`Dodaj własny banner (1012 x ${Height * 56})`}
            </BannerItemForList>
            {/* {bannerList.map((item) => (
              <BannerItemForList
                onClick={() => saveHoveredImg(item.Url)}
                onMouseEnter={() => hoverOn(item.Url)}
                onMouseLeave={hoverOf}
                key={item.Name}
              >
                {item.Name}
              </BannerItemForList>
            ))} */}
          </BannerListWrapper>
        ) : null}
        {visitedProducer.CardType === 0 && !isEditing ? null : (
          <ButtonLeftAndRightWrapper Width={Width}>
            {isEditing ? (
              <ButtonBg
                onMouseEnter={() => hoverTextOn('Usuń banner')}
                onMouseLeave={hoverTextOff}
                aria-disabled={img.length < 1}
                onClick={deleteImg}
                className="DeleteTrackSvg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.3"
                  height="13.5"
                  viewBox="0 0 12.3 13.5"
                >
                  <g
                    id="Icon_feather-trash"
                    data-name="Icon feather-trash"
                    transform="translate(0.75 0.75)"
                  >
                    <path
                      id="Path_3663"
                      data-name="Path 3663"
                      d="M4.5,9H15.3"
                      transform="translate(-4.5 -6.6)"
                      fill="none"
                      stroke={`rgb(0,0,0)`}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      style={img.length < 1 ? {opacity: '0.5'} : null}
                    />
                    <path
                      id="Path_3664"
                      data-name="Path 3664"
                      d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                      transform="translate(-6.3 -3)"
                      fill="none"
                      stroke={`rgb(0,0,0)`}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                    />
                  </g>
                </svg>
              </ButtonBg>
            ) : null}
          </ButtonLeftAndRightWrapper>
        )}

        {img.length > 0 && !imgDeleted && (
          <BannerImgWrapper Width={Width} Height={Height}>
            {!imgDeleted && !isHovered && <img src={img} alt="Banner" />}
            {/*{isEditing && (*/}
            {/*  <DeleteButton*/}
            {/*    fontColor={hexToRgb(FontColorHex)}*/}
            {/*    Width={Width}*/}
            {/*    Height={Height}*/}
            {/*    onClick={deleteImg}*/}
            {/*  >*/}
            {/*    <img src={deleteBlack} />*/}
            {/*  </DeleteButton>*/}
            {/*)}*/}
          </BannerImgWrapper>
        )}
        {isEditing && img.length < 1 && (
          <BannerImgWrapper>
            {isHovered && <BannerHoverImg src={hoveredImg} alt="HoverBanner" />}
            {/*<BannerAddButonWrapper*/}
            {/*  onClick={() => {*/}
            {/*    imgRef.current.click();*/}
            {/*  }}*/}
            {/*  theme={theme}*/}
            {/*>*/}
            {/*  <svg*/}
            {/*    xmlns="http://www.w3.org/2000/svg"*/}
            {/*    width="9"*/}
            {/*    height="9"*/}
            {/*    viewBox="0 0 9 9"*/}
            {/*  >*/}
            {/*    <g*/}
            {/*      id="Icon_feather-plus-circle"*/}
            {/*      data-name="Icon feather-plus-circle"*/}
            {/*      transform="translate(0.5 0.5)"*/}
            {/*      opacity="0.76"*/}
            {/*    >*/}
            {/*      <path*/}
            {/*        id="Path_3319"*/}
            {/*        data-name="Path 3319"*/}
            {/*        d="M11,7A4,4,0,1,1,7,3,4,4,0,0,1,11,7Z"*/}
            {/*        transform="translate(-3 -3)"*/}
            {/*        fill="none"*/}
            {/*        stroke="#fff"*/}
            {/*        strokeLinecap="round"*/}
            {/*        strokeLinejoin="round"*/}
            {/*        strokeWidth="1"*/}
            {/*      />*/}
            {/*      <path*/}
            {/*        id="Path_3320"*/}
            {/*        data-name="Path 3320"*/}
            {/*        d="M18,12v3.2"*/}
            {/*        transform="translate(-14 -9.6)"*/}
            {/*        fill="none"*/}
            {/*        stroke="#fff"*/}
            {/*        strokeLinecap="round"*/}
            {/*        strokeLinejoin="round"*/}
            {/*        strokeWidth="1"*/}
            {/*      />*/}
            {/*      <path*/}
            {/*        id="Path_3321"*/}
            {/*        data-name="Path 3321"*/}
            {/*        d="M12,18h3.2"*/}
            {/*        transform="translate(-9.6 -14)"*/}
            {/*        fill="none"*/}
            {/*        stroke="#fff"*/}
            {/*        strokeLinecap="round"*/}
            {/*        strokeLinejoin="round"*/}
            {/*        strokeWidth="1"*/}
            {/*      />*/}
            {/*    </g>*/}
            {/*  </svg>*/}
            <input
              type="file"
              onChange={getImageFile}
              accept=".jpeq,.jpg,.png"
              hidden
              ref={imgRef}
            />
            {/*</BannerAddButonWrapper>*/}
          </BannerImgWrapper>
        )}
      </BannerBlockContent>
    </BannerBlockWrapper>
  );
};
