import styled, {css} from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;
`;

export const Main = styled.div<{overview?: boolean}>`
  width: ${({overview}) => (overview ? '1020px' : '1020px')};
  //display: flex;
  position: relative;
  pointer-events: all;
  top: ${({overview}) => (overview ? '-55px' : '0')};
  //justify-content: ${({overview}) => (overview ? 'center' : 'space-between')};
  margin: ${({overview}) => (overview ? '0 auto ' : ' 0')};
  //
  //@media (max-width: 1256px) {
  //  justify-content: center;
  //  width: unset;
  //}
  //
  //@media (max-width: 1065px) {
  //  width: 95%;
  //  /* gap: 5px; */
  //}
`;

export const OptionsInColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 262px 0;
`;

export const OptionsWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: 1020px;
  width: 95px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;

  & > p {
    font-size: 13px;
    //color: ${({theme}) => theme.colors.white};
    color: black;
    line-height: 24px;
  }

  @media (max-width: 1256px) {
    width: 100%;

    & > p {
      line-height: unset;
      font-size: 32px;
      text-transform: uppercase;
      margin-top: 10px;
      font-weight: 700;
    }
  }
`;

export const MobileOptionsInColumn = styled(OptionsInColumn)`
  width: 100%;
  min-height: 100vh;
  top: 0px !important;
  left: 0px !important;
  gap: 0;
  display: none;

  @media (max-width: 660px) {
    display: flex;
  }
`;

export const CloseMobileMenu = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 32px;
  color: ${({theme}) => theme.colors.whiteOp050};
  cursor: pointer;
  font-weight: 900;

  &:hover {
    color: ${({theme}) => theme.colors.white};
  }
`;

export const OptionsContent = styled.div<{isLeft?: boolean}>`
  width: 100%;
  //background: ${({theme}) => theme.colors.whiteOp011};
  background: #3a87f0;

  border-radius: ${({isLeft}) => (isLeft ? `0 0 0 15px` : `0 0 15px 0`)};
  border: 1px solid ${({theme}) => theme.colors.whiteOp021};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SingleOption = styled.p`
  width: 100%;
  height: 22px;
  padding: 8px 0;
  line-height: 6px;
  font-size: 9px;
  transition: all 0.15s;
  //border-bottom: 1px solid ${({theme}) => theme.colors.whiteOp021};
  //color: ${({theme}) => theme.colors.whiteOp050};
  color: black;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: ${({theme}) => theme.colors.white};
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const PageThemeOption = styled(SingleOption)<{
  pageTheme: string;
  themeType: string;
}>`
  cursor: ${({pageTheme, themeType}) =>
    pageTheme === themeType ? 'default' : 'pointer'};
  background: ${({pageTheme, themeType}) =>
    pageTheme === themeType ? 'white' : 'transparent'};
  border-radius: 11px;
`;

export const PageStyleOption = styled(SingleOption)<{
  pageStyle: string;
  styleType: string;
}>`
  cursor: ${({pageStyle, styleType}) =>
    pageStyle === styleType ? 'default' : 'pointer'};
  background: ${({pageStyle, styleType}) =>
    pageStyle === styleType ? 'white' : 'transparent'};
  border-radius: 11px;
`;

export const BlockTypeOption = styled(SingleOption)<{
  currentBlockType: number;
  blockType: number;
}>`
  cursor: ${({currentBlockType, blockType}) =>
    currentBlockType === blockType ? 'default' : 'pointer'};
  color: ${({theme, currentBlockType, blockType}) =>
    currentBlockType === blockType
      ? theme.colors.white
      : theme.colors.whiteOp050};
`;

export const MainPanelWrapper = styled.div`
  height: 78vh;
  width: 1020px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 24px 24px 21px 21px;
  /* border: 1px solid #c7c9cf; */
  border: ${({theme}) => `1px solid ${theme.colors.mainBorderColor}`};
  box-shadow: ${({theme}) => theme.colors.mainBoxShadow};
`;

export const MainPanelHeader = styled.div`
  width: 100%;
  height: 62px;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  /* background: #39393a; */
  background: ${({theme}) => theme.colors.bodyBg};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  & > p {
    color: ${({theme}) => theme.colors.whiteOp092};
    font-size: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ArrowWrapper = styled.div<{isRightArrow?: boolean}>`
  position: relative;

  & > svg {
    cursor: pointer;
    position: absolute;
    bottom: -15px;
    left: ${({isRightArrow}) => (isRightArrow ? '-15px' : '-13px')};

    &:hover {
      filter: brightness(1.1);
    }

    &:active {
      filter: brightness(0.9);
    }
  }
`;

export const HeaderAddButton = styled.button`
  width: 85px;
  height: 22px;
  border-radius: 15px;
  border: solid 1px #3a87f0;
  background: #3a87f0;
  font-size: 11px;
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const HeaderPreviewButton = styled.button`
  width: 112px;
  height: 28px;
  font-size: 11px;
  color: #3a87f0;
  border: solid 1px #3a87f0;
  border-radius: 15px;
  background: transparent;
  cursor: pointer;
  transition: 0.15s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const HeaderButtonWrapper = styled.div`
  display: flex;
  width: 340px;
  //gap: 5px;
  margin-left: 520px;
  padding-left: 10px;
`;

export const PanelHeader = styled.div<{isMainHeader?: boolean}>`
  width: 100%;
  height: 42px;

  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background: #89bfff;
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    color: ${({theme, isMainHeader}) =>
      isMainHeader ? theme.colors.whiteOp092 : '#89BFFF'};
    font-size: ${({isMainHeader}) => (isMainHeader ? '15px' : '11px')};
    padding-left: 17px;
  }

  @media (max-width: 510px) {
    & > p {
      font-size: 11px;
    }
  }

  @media (max-width: 482px) {
    & > p {
      font-size: 9px;
    }
  }

  @media (max-width: 450px) {
    font-size: 12px;

    & > p {
      font-size: 9px;
    }
  }
`;

export const PanelHederSvgAndBtnWrapper = styled.div`
  display: flex;
  //gap: 3px;
  padding-right: 3px;
  & > button {
    width: 70px;
    height: 28px;
    border: 1px solid rgba(255, 255, 255, 0.21);
    border-radius: 15px;
    background: transparent;
    font-size: 11px;
    color: #ffffff;
    transition: all 0.15s;
    cursor: pointer;
    margin: 0px;
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(0.9);
    }
  }
  & > svg {
    border: 1px solid rgba(255, 255, 255, 0.21);
    width: 28px;
    height: 28px;
    transition: 0.15s;
    border-radius: 50%;
  }
`;

export const TitleWithSvg = styled.div<{blockWidth: number}>`
  display: flex;
  margin: 0 auto;
  padding-left: 100px;
  //gap: 0 15px;
  transition: 0.15s;

  & > img {
    cursor: pointer;
    transition: all 0.15s;
    user-select: none;

    &:hover {
      filter: brightness(0.6);
    }
  }

  ${({blockWidth}) =>
    blockWidth === 1 &&
    css`
      padding-left: 10px;
      gap: 0;

      & > p {
        font-size: 9px;
      }
    `};

  @media (max-width: 955px) {
    padding-left: 10px;
    align-items: center;

    & > p {
      font-size: 9px;
    }
  }

  @media (max-width: 815px) {
    & > p {
      font-size: ${({blockWidth}) => blockWidth === 1 && '7px'};
    }
  }

  @media (max-width: 765px) {
    & > p {
      display: ${({blockWidth}) => blockWidth === 1 && 'none'};
    }
  }

  @media (max-width: 450px) {
    & > p {
      font-size: 9px;
      display: ${({blockWidth}) => blockWidth === 1 && 'none'};
    }
    & > button {
      width: 66px;
    }
  }

  @media (max-width: 395px) {
    & > p {
      font-size: 7px;
    }
  }
`;

export const StyledButton = styled.button`
  width: 94px;
  height: 22px;
  border: 1px solid ${({theme}) => theme.colors.whiteOp021};
  border-radius: 15px;
  background: transparent;
  font-size: 9px;
  color: ${({theme}) => theme.colors.white};
  transition: all 0.15s;
  cursor: pointer;
  margin: 0 7px;

  &:hover {
    border: 1px solid ${({theme}) => theme.colors.white};
  }

  @media (max-width: 450px) {
    width: 66px;
  }
`;

export const CloseButton = styled.button`
  width: 94px;
  height: 22px;
  border: 1px solid ${({theme}) => theme.colors.whiteOp021};
  border-radius: 5px;
  background: transparent;
  font-size: 9px;
  color: ${({theme}) => theme.colors.white};
  transition: all 0.15s;
  cursor: pointer;
  position: absolute;
  top: 0px;
  z-index: 1;

  &:hover {
    border: 1px solid ${({theme}) => theme.colors.white};
  }

  @media (max-width: 1065px) {
  }
`;

export const DotsMenu = styled.img`
  margin: 0 10px 0 5px;
  cursor: pointer;
  transition: all 0.15s;
  user-select: none;

  &:hover {
    filter: brightness(0.6);
  }
`;

export const BurgerMenu = styled.img`
  margin: 0 8px 0 13px;
  cursor: pointer;
  transition: all 0.15s;
  user-select: none;

  &:hover {
    filter: brightness(0.6);
  }
`;

export const AddImage = styled.img`
  cursor: pointer;
  transition: all 0.15s;
  user-select: none;

  &:hover {
    filter: brightness(0.6);
  }

  & > img {
    user-select: none;
  }
`;

export const MainPanelContentWrapper = styled.div<{
  overview?: boolean;
  isBasicPlan: boolean;
  isChooseMode?: boolean;
}>`
  width: ${({overview, isChooseMode}) =>
    isChooseMode ? '1020px' : overview ? '1020px' : '1012px'};
  //height: calc(100% - 22px);
  //border: 2px solid #171717;
  border-top: none;
  background: ${({theme, isBasicPlan, isChooseMode}) =>
    isChooseMode
      ? theme.colors.bgWhite
      : isBasicPlan
      ? theme.colors.bgWhite
      : 'transparent'};
  height: ${({overview}) => (overview ? 'calc(75vh)' : 'calc(82vh)')};
  //min-height: 745px;
  padding-left: ${({overview}) => (overview ? '0px' : '1px')};

  //padding: ${({overview}) => (overview ? 'unset' : '5px 0px 5px 10px')};

  position: relative;
  // border-top-left-radius: ${({overview}) => (overview ? '15px' : 'none')};
  // border-top-right-radius: ${({overview}) => (overview ? '15px' : 'none')};
  border-bottom-left-radius: ${({overview, isChooseMode}) =>
    isChooseMode ? 'none' : overview ? 'none' : '15px'};
  border-bottom-right-radius: ${({overview, isChooseMode}) =>
    isChooseMode ? 'none' : overview ? 'none' : '15px'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: ${({overview}) => (overview ? '10px' : '10px')};
    //width: 2px;
    height: 10px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(112, 112, 112, 0.3);
    border-radius: 25px;
    border: 1.5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 1.5px;
  }
`;

export const MainPanelContent = styled.div`
  width: 113px;
  height: 113px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const AltPanelContent = styled.div<{
  backgroundAlpha: number;
  backgroundColor: string;
  blockWidth: number;
  overview?: boolean;
}>`
  width: ${({overview, blockWidth}) =>
    overview && blockWidth === 1 ? '100.28%' : '100%'};
  //transition: all 0.15s;
  //height: ${({overview}) => (overview ? '100%' : 'calc(100% - 28px)')};
  height: 100%;
  /* background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`}; */

  display: flex;
  justify-content: space-around;

  /* border: 5px solid transparent; */
  border: ${({overview}) => (overview ? 'none' : '1px solid transparent')};
  /* align-items: center; */
`;

export const PanelImage = styled.div`
  height: 100%;
  width: 338px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(41, 41, 41);

  @media (max-width: 1065px) {
    padding: 15px 16px;
    width: unset;
    aspect-ratio: 1;
  }
`;

export const AltPanelImage = styled.div<{
  blockWidth: number;
  blockHeight: number;
}>`
  height: 112px;
  width: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
  justify-content: center;
  background: rgb(41, 41, 41);
  border: 1px solid ${({theme}) => theme.colors.whiteOp038};
`;

export const MainPanelImageText = styled.p`
  padding: 6px 33px;
  font-size: 13px;
  color: ${({theme}) => theme.colors.whiteOp092};
  text-align: center;
  line-height: 24px;
`;

export const MainPanelTextWrapper = styled.div`
  padding: 54px 55px;
  width: 693px;
  height: 100%;
  display: flex;
  flex-direction: column;
  //gap: 30px 0;

  & > p {
    font-size: 13px;
    color: ${({theme}) => theme.colors.whiteOp092};
    text-align: justify;
    line-height: 28px;
  }
`;

export const AltPanelTextWrapper = styled.div<{
  fontColor: string;
  blockWidth: number;
}>`
  //padding: 10px 10px;
  /* width: calc(100% - 338px); */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  //gap: 10px 0;
  /* display: ${({blockWidth}) => (blockWidth === 3 ? 'flex' : 'none')}; */

  & > h1 {
    font-size: 32px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    line-height: 36px;
    font-weight: 400;
  }

  & > h2 {
    font-size: 16px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    line-height: 24px;
    font-weight: 400;
  }

  & > h3 {
    font-size: 16px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    line-height: 24px;
    padding-left: 21px;
    font-weight: 400;
  }

  /* & > div {
    display: flex;
    width: 100%;
    justify-content: space-between;

    & > h4 {
      font-size: 16px;
      color: ${({fontColor}) => `rgb(${fontColor})`};
      opacity: 0.38;
      line-height: 24px;
      padding-left: 21px;
      font-weight: 400;
    }

    & > p {
      font-size: 16px;
      color: rgb(255, 0, 0);
      line-height: 24px;
      padding-left: 21px;
    }
  } */

  & > p {
    font-size: 11px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    opacity: 0.76;
    text-align: justify;
    padding-left: 21px;
    margin-top: 20px;
  }
`;

export const AltPanelImageText = styled.p`
  font-size: 13px;
  color: ${({theme}) => theme.colors.whiteOp058};
  line-height: 44px;
`;

export const PanelContainer = styled.div<{
  blockWidth: number;
  blockHeight: number;
  isEditing: boolean;
  overview?: boolean;
  bgColor?: string;
}>`
  //height: 100%;
  //width: 100%;
  //transition: height 0.15s, width 0.15s;
  /* background: ${({isEditing, bgColor}) =>
    isEditing ? 'transparent' : `rgb(${bgColor})`}; */
  z-index: 1;
  height: ${({blockHeight}) => {
    if (blockHeight >= 1 && blockHeight <= 18) {
      return `${56 + (blockHeight - 1) * 56}px`;
    } else {
      return '339px';
    }
  }};
  width: ${({blockWidth}) => {
    if (blockWidth >= 1 && blockWidth <= 18) {
      return `${56 + (blockWidth - 1) * 56}px`;
    } else {
      return '339px';
    }
  }};
  transition: width 0.15s, height 0.15s;
  grid-row: ${({blockHeight}) => {
    if (blockHeight >= 1 && blockHeight <= 18) {
      return `span ${blockHeight}`;
    } else {
      return 'span 18';
    }
  }};
  grid-column: ${({blockWidth}) => {
    if (blockWidth >= 1 && blockWidth <= 18) {
      return `span ${blockWidth}`;
    } else {
      return 'span 18';
    }
  }};
`;

export const PanelSvgWrapper = styled.div<{
  blockHeight: number;
  blockWidth: number;
  isHover: boolean;
  isEditing: boolean;
}>`
  position: absolute;
  border: 3px dashed transparent;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 6;
  display: ${({isEditing}) => (isEditing ? 'flex' : 'none')};

  //flex-direction: column;
  flex-direction: ${({blockHeight, blockWidth}) =>
    blockHeight === 1 && blockWidth === 1
      ? 'column'
      : blockHeight === 1 || blockHeight === 2
      ? 'row'
      : 'column'};
  justify-content: space-between;
  align-items: center;
  &:hover {
    border: 3px dashed #3a87f0;
    background: rgba(0, 145, 255, 0.15);
  }
  & > img {
    cursor: pointer;
  }
`;
export const PanelSvgEditIconWrapper = styled.div<{
  blockHeight: number;
  blockWidth: number;
}>`
  display: flex;
  flex-direction: ${({blockHeight, blockWidth}) =>
    blockHeight === 1 && blockWidth === 1 ? 'row' : 'column'};
  & > svg {
    z-index: 3;
    cursor: pointer;
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(0.9);
    }
  }
`;

export const BlocksWrapper = styled.div<{overview?: boolean}>`
  gap: 5px 5px;
  width: 100%;
  height: auto;
`;

/////////////////////////////////////// new businessCard view /////////////////////////////////

export const NewMainWrapper = styled.div`
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;
`;

export const NewMain = styled.div`
  width: 1020px;
  height: 619px;
  /* border: 1px solid #c7c9cf; */
  border: ${({theme}) => `1px solid ${theme.colors.mainBorderColor}`};
  border-radius: 9px 9px 21px 21px;
`;

export const NewMainHeader = styled.div`
  width: 100%;
  min-height: 55px;
  /* height: 62px; */
  z-index: 2;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  /* border-bottom: ${({theme}) => `1px solid ${theme.colors.bodyBg}`}; */
  border-bottom: 1px solid rgba(112, 112, 112, 0.3);
  /* background: #f1f1f2; */
  background: ${({theme}) => theme.colors.bodyBg};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: 0.15s;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 21px;
    font-size: 18px;
    letter-spacing: -0.15px;
  }
  @media (max-width: 1024px) {
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    & > P {
      font-size: 13px;
    }
  }
`;

export const MainHeaderLeftPanelWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 54px;
  z-index: 3;
  width: 147px;
  height: 345px;
  transition: 0.15s;
  overflow: hidden;
`;

export const MainHeaderHiddenWrapper = styled.div`
  position: absolute;
  top: -345px;
  left: 0;
  width: 147px;
  height: 345px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 3;
  background: #3a87f0;
  border: 1px solid #707070;
  border-bottom-right-radius: 9px;
  border-top: none;
  overflow: hidden;
  border-left: none;
  transition: 0.25s;
`;

export const MainHeaderLeftPanelEditWrapper = styled.div`
  padding-top: 13px;
  height: 177px;
  width: 100%;
  background: #f1f1f2;
  border-bottom-right-radius: 9px;
  border-bottom: 1px solid #707070;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MainHeaderLeftPanelSvgWrapper = styled.div`
  display: flex;
  & > svg {
    cursor: pointer;
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(0.9);
    }
  }
`;

export const MainHeaderLeftPanelButton = styled.div<{
  isActive?: boolean;
}>`
  width: 120px;
  height: 28px;
  background: ${({isActive}) => (isActive ? '#00d10f' : '#f1f1f2')};
  border-radius: 15px;
  font-size: 11px;
  text-align: center;
  line-height: 28px;
  letter-spacing: 0.1px;
  cursor: pointer;
  color: ${({isActive}) => (isActive ? '#f1f1f2' : '#3a87f0')};
  box-shadow: 1px 3px 4px 2px rgba(0, 0, 0, 0.12);
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
`;

export const MainHeaderLeftPanelCheckBoxWrapper = styled.div`
  padding-top: 15px;
  width: 70%;
  display: flex;
  //background: #f1f1f2;
  justify-content: space-around;
  & > p {
    font-size: 11px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #f1f1f2;
  }
`;
export const NewStyleCheckBox = styled.div<{isActive?: boolean}>`
  width: 36px;
  cursor: pointer;
  height: 21px;
  border-radius: 25px;
  border: ${({isActive}) =>
    isActive ? `1px solid #f1f1f2` : `1px solid grey`};
  position: relative;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;

  & > div {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    -webkit-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    left: ${({isActive}) => (isActive ? `14px` : `4px`)};
    top: 1px;
    background: ${({isActive}) => (isActive ? `#f1f1f2` : `grey`)};
  }
`;

export const MainHeaderLeftPanelText = styled.p`
  padding: 8px 11px 7px 15px;
  font-size: 9px;
  text-align: center;
  font-weight: normal;
  line-height: 13px;
  letter-spacing: 0.15px;
  color: #ffffff;
`;

export const NewMainHeaderTitle = styled.div`
  height: 100%;
  width: 147px;
  border-right: 1px solid #c7c9cf;
  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    cursor: pointer;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.1px;
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(0.9);
    }
  }
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
`;

export const NewMainHeaderStatus = styled.div`
  padding-top: 8px;
  height: 100%;
  width: 147px;
  display: flex;
  //justify-content: center;
  & > h4 {
    color: #0066ff;
    font-size: 13px;
    line-height: 36px;
    letter-spacing: -0.15px;
    font-weight: normal;
  }
  & > p {
    padding-right: 7px;
    font-size: 9px;
    line-height: 21px;
    letter-spacing: 0.1px;
  }
`;

export const FooterWrapper = styled.div`
  height: 55px;
  min-height: 55px;
  background: #f8f8f8;
  width: 1020px;
  z-index: 1;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  padding-left: 26px;
  padding-right: 23px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FooterSellerAvatarAndInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterCircleAvatar = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 9px;
  & > img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
`;

export const FooterSellerInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  & > p {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.15px;
    color: #5c5c66;
  }
`;

export const FooterBigBlueBtn = styled.button`
  width: 149px;
  height: 32px;
  font-size: 13px;
  line-height: 24px;
  color: white;
  border: none;
  cursor: pointer;
  background: #3a87f0;
  border-radius: 9px;
`;

export const FooterSellerAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  & > p {
    font-size: 9px;
    line-height: 13px;
    letter-spacing: 0.15px;
    color: #5c5c66;
  }
`;

export const NoPlanWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.colors.bgWhite};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const NoPlanSvgWrapper = styled.div`
  margin-bottom: 20px;
`;
export const NoPlanText = styled.p`
  font-size: 13px;
  letter-spacing: -0.15px;
  line-height: 21px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  @media (max-width: 340px) {
    font-size: 11px;
    line-height: 18px;
  }
`;
export const NoPlanBtnWrapper = styled.div`
  margin-top: 32px;
  width: 312px;
  border: ${({theme}) => `2px solid ${theme.colors.blueTheme}`};
  border-radius: 16px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.07px;
    color: ${({theme}) => theme.colors.blueTheme};
    cursor: pointer;
  }
  @media (max-width: 340px) {
    margin-top: 20px;
    width: 280px;
    height: 40px;
    border-radius: 12px;
  }
`;

export const CardHeaderSvgWrapperLeft = styled.div<{isActive?: boolean}>`
  position: absolute;
  left: 19px;
  display: flex;
  gap: 13px;
  height: 100%;

  align-items: center;
  & > svg {
    cursor: pointer;
  }
`;

export const CardSvg = styled.svg<{isActive: boolean}>`
  & > g {
    & > g:first-child {
      stroke: ${({isActive, theme}) =>
        isActive ? theme.colors.blueTheme : theme.colors.fontBlackOp50};
    }
    & > g {
      & > path {
        fill: ${({isActive, theme}) =>
          isActive ? theme.colors.fontBlackOp100 : theme.colors.fontBlackOp50};
      }
    }
  }
`;

export const CardHeaderStatusWrapperRight = styled.div`
  position: absolute;
  right: 19px;
  display: flex;
  gap: 13px;
  height: 100%;
  align-items: center;
  & > svg {
    cursor: pointer;
  }
`;

export const DraftBlueBtn = styled.button`
  width: 149px;
  height: 32px;
  background: #0091ff;
  border-radius: 9px;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 13px;
  line-height: 32px;
  letter-spacing: 0.1px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  margin-left: 24px;
`;

export const DraftRedBtn = styled.button`
  width: 100px;
  height: 32px;
  background: #ff0300;
  border-radius: 9px;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 13px;
  line-height: 32px;
  letter-spacing: 0.1px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  margin-right: 10px;
`;

export const StatusWrapper = styled.div<{status: number}>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > span {
    width: 220px;
    height: 3px;
    background: ${({status}) =>
      status === 1
        ? '#ff0300'
        : status === 2
        ? '#00D10f'
        : status === 3
        ? '#0091ff'
        : '#fb9614'};
    border-radius: 25px;
  }
  & > p {
    color: ${({status}) =>
      status === 1
        ? '#ff0300'
        : status === 2
        ? '#00D10f'
        : status === 3
        ? '#0091ff'
        : '#fb9614'};
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
`;

export const HederNav = styled.section`
  height: 57px;
  min-height: 57px;
  width: 100%;
  background: white;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  align-items: center;
  padding-left: 19px;
`;

export const GridStructur = styled.div<{isEditing: boolean}>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${({isEditing}) => (isEditing ? 'grid' : 'none')};
  padding-left: 1px;
  padding-right: 2px;
  grid-template-columns: repeat(18, 56px);
  grid-auto-rows: 56px;
`;
export const GridOneItem = styled.div`
  z-index: 1;
  border: 1px dashed #707070;
  opacity: 0.3;
  border-right: none;
  border-top: none;
  cursor: pointer;
`;
