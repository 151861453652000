import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import mugoImage from '../../../../images/post_mugo.png';
import {InputView} from '../../../MyAccount/Settings/styles';
import {ErrorStar} from '../../../../components/ErrorStar';
import {
  Checkbox,
  RegulationsContainer,
  RegulationsContent,
} from '../../Register';
import {useCookies} from 'react-cookie';
import api from '../../../../services/axiosConfig';
import useAlert from '../../../../hooks/useAlert';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  /* @media (max-width: 1045px) {
    width: 360px;
  } */
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: 1fr;
  gap: 11px 0;
  grid-auto-rows: unset;

  @media (max-width: 1045px) {
  }
`;

export const InputWrapper = styled.div<{isRedBorder?: boolean}>`
  background: ${({isRedBorder}) => (isRedBorder ? `#FF7E7D` : 'transparent')};
  border: 1px solid rgba(112, 112, 112, 0.3);
  height: 22px;
  border-radius: 5px;
  width: 100%;
  margin-top: ${({isRedBorder}) => (isRedBorder ? `0` : '10px')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    color: #07020f;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.625px;
    font-weight: 400;
    margin-left: 8.5px;
  }

  & > input {
    text-align: right;
    font-size: 11px;
    line-height: 18px;
    margin-right: 8.5px;
    color: rgba(7, 2, 15, 0.5);
    letter-spacing: 0.625px;
    font-weight: 400;
    background-color: transparent;
    border: none;

    &::placeholder {
      color: rgba(7, 2, 15, 0.5);
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: rgba(7, 2, 15, 0.5) !important;
    }
  }

  & > input:focus {
    outline: none;
  }
`;

export const PrimaryButton = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
}>`
  width: 100%;
  height: 44px;
  border: none;
  border: ${({borderColor}) =>
    !borderColor ? 'none' : `1px solid ${borderColor}`};
  background: ${({background}) => (!background ? '#3A87F0' : background)};
  border-radius: 15px;
  font-size: 15px;
  color: ${({color}) => (!color ? '#ffffff' : color)};
  transition: all 0.15s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 400;
  letter-spacing: 0.7px;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const PageHeaderWrapper = styled.div`
  width: 1020px;
  display: flex;
  height: 590px;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  background: ${({theme}) => theme.colors.bgWhite};
  border-radius: 24px 24px 21px 21px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.625px;
  }
`;

export const PageHeaderContent = styled.div`
  width: 100%;
  /* height: calc(100% - 55px); */
  display: grid;
  align-items: center;
  grid-template-columns: 535px 485px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RightSectionContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 21.5px 85px 11px 88px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > a {
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-decoration: underline;
  }
`;

const SingleRegulationWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  & > p {
    color: rgba(255, 255, 255, 0.86);
    line-height: 24px;
    font-size: 11px;
    padding-left: 30px;
  }
`;

interface RegulationRules {
  first: boolean;
  second: boolean;
  third: boolean;
  fourth: boolean;
  fifth: boolean;
  sixth: boolean;
  seventh: boolean;
}

export const ExternalLogin: React.FC = () => {
  const [regulationRules, setRegulationRules] = useState<RegulationRules>({
    fifth: false,
    first: false,
    fourth: false,
    second: false,
    seventh: false,
    sixth: false,
    third: false,
  });

  const [cookies, setCookie] = useCookies(['token']);

  const {Alert} = useAlert();

  const navigate = useNavigate();
  const locationSearch = window.location.search;

  const params = new URLSearchParams(locationSearch);
  const {active, token} = useParams();

  const addHours = (date: Date, hours: number) => {
    date.setHours(date.getHours() + hours);

    return date;
  };

  const handleRegulationRuleChange = (rule: string, value: boolean) =>
    setRegulationRules((prev) => ({...prev, [rule]: value}));

  const logIn = async () => {
    try {
      const response = await api.get(`Account/GetUser`, {
        headers: {Authorization: `Bearer ${token}`},
      });
      const today = new Date();
      setCookie('token', token, {
        expires: addHours(today, 10),
        path: '/',
      });
      // navigate('/');
      // navigate(0);
      if (response.data.Response.FirstName === null) {
        window.location.href = '/producer';
        return;
      }
      const productType = localStorage.getItem('productType');
      const productId = localStorage.getItem('productId');

      localStorage.removeItem('productId');
      localStorage.removeItem('productType');

      if (productType && productId) {
        if (productType === '8') {
          navigate(`/tracks/main/${productId}`);
          navigate(0);
        } else {
          navigate(`/products/main/${productId}`);
          navigate(0);
        }
      } else {
        navigate('/producer');
        navigate(0);
      }
    } catch (error) {
      console.error(error);
    }
  };

  console.log(active, token);

  const sendRulesToAccount = async () => {
    if (
      !regulationRules.first ||
      !regulationRules.second ||
      !regulationRules.third
    )
      return Alert('Zaakceptuj pola obowiązkowe', 'Komunikat');
    try {
      const body = {
        StudioAllowNotifications: regulationRules.fifth,
        StudioAcceptedRequlations: regulationRules.first,
        StudioAcceptedPrivacyPolicy: regulationRules.second,
        StudioAcceptedMarketing: regulationRules.third,
      };
      const response = await api.put(`Account/UpdateAgreements`, body, {
        headers: {Authorization: `bearer ${token}`},
      });
      const today = new Date();
      setCookie('token', token, {
        expires: addHours(today, 10),
        path: '/',
      });
      window.location.href = '/account/settings';
    } catch (error) {
      console.error(error);
      Alert('Coś poszło nie tak', 'Błąd');
    }
    console.log('wysyłam', regulationRules);
  };

  useEffect(() => {
    if (active === '0') {
      console.log('pokazuje regulamin');
    }
    if (active === '1') {
      logIn();
    }
    if (active !== '1' && active !== '0') {
      navigate('/identity/login');
    }
  }, []);

  return (
    <MainWrapper>
      {active === '0' && (
        <RegulationsContainer isRegulationsOpen={active === '0'}>
          <RegulationsContent>
            <h4>WARUNKI I ZASADY</h4>
            <SingleRegulationWrapper>
              <Checkbox
                value={regulationRules.first}
                onClick={() =>
                  handleRegulationRuleChange('first', !regulationRules.first)
                }
              />
              <p>
                *Oświadczam, że zapoznałam/em się z Regulaminem konta
                użytkowanika oraz Polityką prywatności i akceptuję ich
                postanowienia.
              </p>
            </SingleRegulationWrapper>
            <SingleRegulationWrapper>
              <Checkbox
                value={regulationRules.second}
                onClick={() =>
                  handleRegulationRuleChange('second', !regulationRules.second)
                }
              />
              <p>
                *Przeczytałam/em i zrozumiałam/em Klauzulę Informacyjną
                dotyczącą przetwarzania moich danych osobowych.
              </p>
            </SingleRegulationWrapper>
            <SingleRegulationWrapper>
              <Checkbox
                value={regulationRules.third}
                onClick={() =>
                  handleRegulationRuleChange('third', !regulationRules.third)
                }
              />
              <p>
                *Żądam uruchomienia usługi przed upływem terminu przysługującego
                mi prawa do odstąpienia od Kontraktu bez podania przyczyn.
              </p>
            </SingleRegulationWrapper>
            <SingleRegulationWrapper>
              <Checkbox
                value={regulationRules.fourth}
                onClick={() =>
                  handleRegulationRuleChange('fourth', !regulationRules.fourth)
                }
              />
              <p>
                Rozumiem, że zgłoszenie żądania powoduje utratę prawa do
                odstąpienia.
              </p>
            </SingleRegulationWrapper>
            <SingleRegulationWrapper>
              <Checkbox
                value={regulationRules.fifth}
                onClick={() =>
                  handleRegulationRuleChange('fifth', !regulationRules.fifth)
                }
              />
              <p>
                Wyrażam zgodę na przetwarzanie moich danych osobowych, w celu
                otrzymywania na podany adres e-mail Newslettera Serwisu
                internetowego mugo.pl tworzonego przez MyMusic sp. j.., pod
                adresem https://mymusic.pl/ Rozumiem, że moja zgoda jest
                dobrowolna i nie muszę jej udzielać, przy czym w przypadku jej
                nieudzielenia bądź późniejszego jej wycofania, jak również
                przesłania wniosku o zmianę lub usunięcie moich danych, stracę
                możliwość uczestniczenia w w/w działaniach marketingowych
                MyMusic sp. j.. Zostałam/em poinformowana/y o przysługującym mi
                prawie dostępu do treści moich danych oraz ich poprawiania.
              </p>
            </SingleRegulationWrapper>
            <SingleRegulationWrapper>
              <Checkbox
                value={regulationRules.sixth}
                onClick={() =>
                  handleRegulationRuleChange('sixth', !regulationRules.sixth)
                }
              />
              <p>
                Wyrażam zgodę na przekazywanie przez MyMusic sp. j.. z siedzibą
                w Wysogotowie, przy ul. Wierzbowej 31, 62-081 Wysogotowo, treści
                marketingowych za pośrednictwem moich urządzeń
                telekomunikacyjnych, w szczególności takich jak laptop, telefon
                czy smartfon, zgodnie z art. 172 ust. 1 ustawy z dnia 16 lipca
                2004 r. Prawo telekomunikacyjne.
              </p>
            </SingleRegulationWrapper>
            <SingleRegulationWrapper>
              <Checkbox
                value={regulationRules.seventh}
                onClick={() =>
                  handleRegulationRuleChange(
                    'seventh',
                    !regulationRules.seventh,
                  )
                }
              />
              <p>
                Wyrażam zgodę na otrzymywanie informacji handlowej od MyMusic
                sp. j. z siedzibą w Wysogotowie, przy ul. Wierzbowej 31, 62-081
                Wysogotowo, zgodnie z art. 10 ustawy z dnia 18 lipca 2002 r. o
                świadczeniu usług drogą elektroniczną.
              </p>
            </SingleRegulationWrapper>
            <br />
            <SingleRegulationWrapper>
              <p>* - pole obowiązkowe.</p>
            </SingleRegulationWrapper>
            <PrimaryButton
              onClick={() => sendRulesToAccount()}
              style={{width: '310px', marginTop: '20px'}}
            >
              Zaakceptuj i zarejestruj
            </PrimaryButton>
          </RegulationsContent>
        </RegulationsContainer>
      )}
      <Main>
        <PageHeaderWrapper>
          <PageHeaderHeaderWrapper>
            <PageHeaderHeader>
              <p>Witamy w MUGO STUDIO</p>
            </PageHeaderHeader>
          </PageHeaderHeaderWrapper>
          <PageHeaderContent>
            <ImageWrapper>
              <img src={mugoImage} />
            </ImageWrapper>
            <RightSectionContent>
              <h1 style={{marginBottom: '14px'}}>Logowanie </h1>
              <InputView isEditing={true}>
                <p>Login</p>
                <input type="text" readOnly={true} id="login" />
              </InputView>
              <InputView isEditing={true} style={{marginBottom: '100px'}}>
                <p>Hasło</p>
                <input type="password" readOnly={true} id="password" />
              </InputView>
              <Link to={'/identity/recover'}>Nie pamiętasz hasła?</Link>
              <Link to={'/identity/register'}>Zarejestruj się</Link>
              <Link
                style={{marginBottom: '14px'}}
                to={'/identity/recover/email'}
              >
                Przyślij ponownie e-mail potwierdzający
              </Link>
              <PrimaryButton
                style={{marginBottom: '7px'}}
                // ref={loginButtonRef}
                // onClick={() => logIn()}
              >
                Zaloguj
              </PrimaryButton>
              {/* <PrimaryButton
                style={{marginBottom: '7px'}}
                background="#0067F0"
                // onClick={loginWithFacebook}
              >
                Zaloguj się przez Facebook
              </PrimaryButton>
              <PrimaryButton
                style={{marginBottom: '7px'}}
                background="#FFFFFF"
                color="#07020F"
                borderColor="rgba(112,112,112,0.3)"
                // onClick={loginWithGoogle}
              >
                Zaloguj się przez Google
              </PrimaryButton>
              <PrimaryButton background="#000000">
                Zaloguj się przez Apple
              </PrimaryButton> */}
            </RightSectionContent>
          </PageHeaderContent>
        </PageHeaderWrapper>
      </Main>
    </MainWrapper>
  );
};
