import React, {useEffect, useRef, useState} from 'react';
import {hexToRgb} from '../../index';
import {ShortDescriptionBlockWrapper, ShortDescriptionContent} from './styles';
import {Block} from '../../interface';
import useAlert from '../../../../../hooks/useAlert';

export interface ShortDescriptionBlock extends Block {
  Text: string;
}
interface BlockShortTextAndTitle {
  Text: string;
  Title: string;
}

interface Props {
  theme: string;
  block: ShortDescriptionBlock;
  isEditing: boolean;
  changeText: ({shortBlockText: BlockShortTextAndTitle}) => void;
  mobileOverview: boolean;
}

const ShortDescriptionBlockComponent: React.FC<Props> = ({
  theme,
  block,
  isEditing,
  changeText,
  mobileOverview,
}) => {
  const initTitle = block.Title === null ? '' : block.Title;

  const [aboutMe, setAboutMe] = useState<BlockShortTextAndTitle>({
    Text: block.Text,
    Title: initTitle,
  });
  const {
    FontColorHex,
    WebArea,
    Format,
    BlockOpacityIn0To100Ratio,
    BackgroundColorHex,
    HorizontalFormat,
    VerticalFormat,
    Interline,
  } = block;
  const {Height} = WebArea;

  const {Alert} = useAlert();

  const textRef = useRef(null);

  const handleAboutMeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    key: number,
  ) => {
    // console.log('scroll height', e.target.value.length, e.target.scrollHeight);
    if (e.target.scrollHeight > 83)
      return Alert(
        'Tekst nie mieści się w bloku',
        'Tekst nie mieści się w bloku',
      );
    if (key === 1) {
      // if (e.target.value.length > 345) return;
      setAboutMe((prevState) => ({...prevState, Text: e.target.value}));
      changeText({shortBlockText: {...aboutMe, Text: e.target.value}});
    } else if (key === 2) {
      setAboutMe((prevState) => ({...prevState, Title: e.target.value}));
      changeText({shortBlockText: {...aboutMe, Title: e.target.value}});
    }
  };

  const checkTitleLength = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (aboutMe.Title.length > 14) {
      setAboutMe((prevState) => ({
        ...prevState,
        Title: aboutMe.Title.slice(0, 14),
      }));
      changeText({
        shortBlockText: {...aboutMe, Title: aboutMe.Title.slice(0, 14)},
      });
    }
  };
  useEffect(() => {
    const adjustTextareaHeight = () => {
      const textarea = textRef.current;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    adjustTextareaHeight();
  }, [aboutMe.Text]);

  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);
  return (
    <ShortDescriptionBlockWrapper
      mobileOverview={mobileOverview}
      backgroundAlpha={BlockOpacityIn0To100Ratio}
      backgroundColor={BackgroundColorRgb}
    >
      <ShortDescriptionContent
        mobileOverview={mobileOverview}
        fontColor={hexToRgb(FontColorHex)}
        height={Height}
        style={
          VerticalFormat === 1
            ? {justifyContent: 'flex-start'}
            : VerticalFormat === 4
            ? {justifyContent: 'flex-end'}
            : VerticalFormat === 8
            ? {justifyContent: 'justify'}
            : {justifyContent: 'center'}
        }
        interline={Interline}
      >
        {isEditing ? (
          <textarea
            style={
              HorizontalFormat === 1
                ? {textAlign: 'center'}
                : HorizontalFormat === 4
                ? {textAlign: 'right'}
                : HorizontalFormat === 8
                ? {textAlign: 'justify'}
                : {textAlign: 'left'}
            }
            value={aboutMe.Text}
            ref={textRef}
            onChange={(e) => handleAboutMeChange(e, 1)}
            placeholder="Napisz coś o sobie..."
          />
        ) : (
          <textarea
            style={
              HorizontalFormat === 1
                ? {textAlign: 'center'}
                : HorizontalFormat === 4
                ? {textAlign: 'right'}
                : HorizontalFormat === 8
                ? {textAlign: 'justify'}
                : {textAlign: 'left'}
            }
            ref={textRef}
            value={aboutMe.Text}
            disabled={true}
          >
            {/* {aboutMe.Text} */}
          </textarea>
        )}
      </ShortDescriptionContent>
    </ShortDescriptionBlockWrapper>
  );
};

export default ShortDescriptionBlockComponent;
