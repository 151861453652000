import styled from 'styled-components';

export const ImgColumnWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 14px;
  /* border: 1px solid rgba(23, 24, 27, 0.5); */
  display: flex;
  flex-direction: column;
  //padding: 1px;
  gap: 3px;
  overflow: hidden;
`;

export const ImgColumnFullImgWrapper = styled.div`
  width: 305px;
  //flex-basis: 305px;
  min-height: 305px;
  height: 305px;
  border-radius: 13px;
  /* border: 1px solid rgba(150, 150, 162, 0); */
  & > img {
    width: 100%;
    height: 100%;
    border-radius: 13px;
  }

  @media (max-width: 425px) {
    width: 387px;
    min-height: 387px;
    height: 387px;
  }

  @media (max-width: 375px) {
    width: 339px;
    min-height: 339px;
    height: 339px;
  }
  @media (max-width: 320px) {
    width: 284px;
    min-height: 284px;
    height: 284px;
  }
`;
