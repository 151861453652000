import React, {useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useNavigate} from 'react-router-dom';

import {RecommendedProducer, Article} from '../index';

import BigArticleImage1 from '../HomeImages/big_article_1.jpg';
import MobileBigArticleImage1 from '../HomeImages/mobile_big_article_1.jpg';

import {
  MainWrapper,
  ProducersWrapper,
  SingleProducer,
  TopHeader,
  MobileProducersWrapper,
  SliderWrapper,
  MobileSingleProducer,
} from './styles';

interface Props {
  RecommendedProducers: RecommendedProducer[];
  Article: Article;
}

export const BigArticle: React.FC<Props> = ({
  RecommendedProducers,
  Article,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 20000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const splitArrayIntoChunks = (array, chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <MainWrapper>
      <TopHeader>
        <img src={Article && Article.PicturePath} />
        <div>
          <h2>{Article && Article.Category}</h2>
          <h1>{Article && Article.Title}</h1>
          <p>{Article && Article.Description}</p>
        </div>
        <a href={`/czytelnia/${Article && Article.UrlPath}`}>
          Czytaj cały artykuł
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.438"
            height="11"
            viewBox="0 0 6.438 11"
          >
            <g
              id="Arrow_Top_Bar_Accent"
              data-name="Arrow – Top Bar / Accent"
              transform="translate(0)"
              opacity="0.92"
            >
              <path
                id="Arrow"
                d="M1.321.224l4.961,4.9a.523.523,0,0,1,0,.747l-4.961,4.9a.781.781,0,0,1-1.095,0,.759.759,0,0,1,0-1.082L4.47,5.5.226,1.306a.76.76,0,0,1,0-1.082.781.781,0,0,1,1.095,0"
                transform="translate(0 0)"
                fill="#0091ff"
              />
            </g>
          </svg>
        </a>
      </TopHeader>
      <ProducersWrapper>
        {RecommendedProducers &&
          RecommendedProducers.map((producer, index) => {
            if (index > 5) return;
            const {
              Description,
              Id,
              Location,
              PicturePath,
              ProducerName,
              Roles,
            } = producer;

            return (
              <SingleProducer
                key={Id}
                onClick={() => navigate(`/producers/card/${Id}`)}
              >
                <img src={PicturePath} alt="image" />
                <div>
                  <h1>{ProducerName}</h1>
                  <p>{Roles.map((role) => `${role} `)}</p>
                  <p>{Location}</p>
                </div>
              </SingleProducer>
            );
          })}
      </ProducersWrapper>

      <SliderWrapper>
        <Slider {...settings}>
          {RecommendedProducers &&
            splitArrayIntoChunks(RecommendedProducers, 2).map(
              (chunk, index) => {
                return (
                  <MobileProducersWrapper key={index}>
                    {chunk.map((producer, index) => {
                      const {
                        Description,
                        Id,
                        Location,
                        PicturePath,
                        ProducerName,
                        Roles,
                      } = producer;

                      return (
                        <MobileSingleProducer
                          key={Id}
                          onClick={() => navigate(`/producers/card/${Id}`)}
                        >
                          <div>
                            <img src={PicturePath} alt="image" />
                            <h1>{ProducerName}</h1>
                            <div>
                              <p>{Roles.map((role) => `${role} `)}</p>
                              <p>{Location}</p>
                            </div>
                          </div>
                        </MobileSingleProducer>
                      );
                    })}
                  </MobileProducersWrapper>
                );
              },
            )}
        </Slider>
      </SliderWrapper>
    </MainWrapper>
  );
};
