import styled from 'styled-components';

export const SingleCardWrapper = styled.div<{smallHeigth: boolean}>`
  width: calc(100% - 2px);
  height: 90px;
  background: ${({theme}) => theme.colors.producersBackground};
  border-radius: 16px;
  border: ${({theme}) => `1px solid ${theme.colors.producersBorderColor}`};
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px;
  position: relative;
  cursor: pointer;
  transition: all 0.15s;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  &:hover {
    border-color: #0066ff;
  }

  @media (max-width: 1024px) {
    height: ${({smallHeigth}) => (smallHeigth ? '134px' : '214px')};
    flex-direction: column;
    gap: 0px;
    border: none;
    background: ${({theme}) => theme.colors.mobileTrackListHeaderBq};
  }
  @media (max-width: 425px) {
    height: ${({smallHeigth}) => (smallHeigth ? '134px' : '214px')};
    flex-direction: column;
  }
`;

export const SingleCardMobileImgAndNameWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
`;

export const SingleCardImageWrapper = styled.div`
  height: 78px;
  width: 78px;
  border-radius: 11px;
  background: ${({theme}) => theme.colors.producersBackground};
  //z-index: 2;

  & > img {
    height: 100%;
    width: 100%;
    //aspect-ratio: 1;
    border-radius: 11px;
  }

  @media (max-width: 1024px) {
    height: 102px;
    width: 102px;
    border-radius: 11px;
    & > img {
      height: 102px;
      width: 102px;
      border-radius: 11px;
    }
  }
`;

export const SingleCardContentWrapper = styled.div`
  background: transparent;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  transition: all 0.15s;
  position: relative;

  & > svg {
    top: 0px;
    right: 0px;
    position: absolute;
  }

  @media (max-width: 1024px) {
    padding-left: 11px;
    display: block;
  }
`;

export const StyledText = styled.p`
  font-size: 11px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  line-height: 16px;
  font-weight: 400;
  /* width: 680px; */
  width: 740px;
  letter-spacing: -0.25px;
  transition: all 0.15s;
  text-decoration: none;
  height: 32px;

  @media (max-width: 1024px) {
    font-size: 11px;
    line-height: 18px;
    width: 367px;
    margin-left: 7px;
  }

  @media (max-width: 425px) {
    font-size: 11px;
    line-height: 18px;
    width: 367px;
  }
  @media (max-width: 400px) {
    font-size: 11px;
    line-height: 18px;
    width: 350px;
  }
  @media (max-width: 320px) {
    width: 280px;
    line-height: 16px;
  }
`;

export const PromotionText = styled.p`
  font-size: 11px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  transition: all 0.15s;
  text-decoration: none;
  position: absolute;
  top: 0px;
  right: 16px;
  display: flex;
  gap: 5px;
`;

export const StyledTextName = styled.p`
  font-size: 11px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.05px;
  transition: all 0.15s;
  text-decoration: none;
  width: 100%;

  @media (max-width: 1024px) {
    padding-top: 3px;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
  }
`;

export const StyledTextRole = styled.p`
  font-size: 11px;
  color: ${({theme}) => theme.colors.roleBlackFont};
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.05px;
  transition: all 0.15s;
  text-decoration: none;
  @media (max-width: 1024px) {
    line-height: 16px;
    letter-spacing: -0.15px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
`;

export const ProducerNameWrapper = styled.div`
  @media (max-width: 1024px) {
    height: 45px;
  }
`;

export const RoleAndCityWrapper = styled.div`
  padding-bottom: 3px;
`;

export const SvgFollowWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  gap: 10px;
  display: flex;
  & > svg {
    & > path {
      fill: ${({theme}) => theme.colors.mobileSearchBarTextColor};
    }
  }
`;

export const SvgVerificationWrapper = styled.div`
  & > svg {
    & > g {
      & > path {
        stroke: ${({theme}) => theme.colors.mobileSearchBarTextColor};
      }
    }
  }
`;
