import styled from 'styled-components';

export const SpotifyWrapper = styled.div<{
  bgColor: string;
  backgroundAlpha: number;
}>`
  height: 100%;
  width: 100%;
  background: ${({bgColor, backgroundAlpha}) =>
    `rgb(${bgColor},${backgroundAlpha})`};
`;
