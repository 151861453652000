import styled from 'styled-components';

export const OverviewWrapper = styled.div`
  width: 339px;
  height: 339px;
  background: #17181b;
  position: absolute;
  //top: -113px;
  //left: -113px;
  box-shadow: -3px 4px 4px 6px rgba(0, 0, 0, 0.22);
  z-index: 1;
  //border: 4px solid #3a87f0;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  //overflow: hidden;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const HeaderOverview = styled.div`
  //width: 226px;
  height: 42px;
  background: #89bfff;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
`;
