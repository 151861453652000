import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import {useCookies} from 'react-cookie';
import styled, {css} from 'styled-components';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';
import qs from 'qs';
import {countTruthyVariables} from 'utils/countTruthyVariables';
import useAlert from 'hooks/useAlert';
import useInfinityScroller from 'hooks/useInfinityScroller';

import {ProductTypeEnum, ProductStatusEnum} from 'constants/conditionEnums';
import searchIcon from 'images/svg/search.svg';
import twoArrowsIcon from 'images/svg/two-arrows.svg';
import listIcon from 'images/svg/list.svg';
import arrowLeft from 'images/svg/transparent-left-arrow.svg';
import arrowRight from 'images/svg/transparent-arrow-right.svg';
import arrowDown from 'images/svg/purple-select-arrow.svg';
import filterIcon from 'images/svg/filter-icon.svg';
import noImage from 'images/noimage.png';
import trackPlaceholder from 'images/svg/track-placeholder.svg';

import Selector from 'components/FilterSelector';
import {UserDataContext} from 'contexts/UserDataContext';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import api from 'services/axiosConfig';
import {getProductTypePolishName} from 'utils/getProductTypePolishName';
import PopupButton from 'components/PopUp/PopupButton';
import {LoadingCircle} from 'utils/loadingCircle';
import {useMenuContext} from 'contexts/MenuContext';
import {useFilterMenuContext} from 'contexts/FilterMenuContext';
import {useSearchBarContext} from 'contexts/SearchBarContext';

import {
  Grid,
  CheckboxWithTitleWrapper,
  CustomBlueCheckbox,
  CountFilterDisplay,
  FilterButtonSvg,
  SortButtonSvg,
  FilterWrapper,
  FilterInputWrapper,
  SingleFilterWrapper,
  FilterMenuWrapper,
  FilterInput,
  SelectWrapper,
  PageHeaderContent,
  PageHeaderWrapper,
} from 'pages/Products/index';

import {
  MobileInput,
  MobileInputTitle,
  MobileInputWrapper,
  MobileSelect,
  MobileSelectWrapper,
  CustomSelectArrow,
} from 'pages/Products/';

import {
  Main,
  MainWrapper,
  MobileArea,
  MobileProductsWrapper,
  MobileRowSpan,
  MobileSliderWrapper,
  ProductsContent,
  ProductsContentHeader,
  ProductsHeader,
  ProductsHeaderButton,
  ProductsMain,
  ProductsWrapper,
  DeleteButton,
  FilterButton,
} from './styles';
import {Navigate} from 'react-router-dom';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';
import {
  TrackPlaceholderSvg,
  DigitalProductPlaceholderSvg,
  ServicePlaceholderSvg,
} from 'components/SvgComponents';

interface Category {
  Id: string;
  ParentCategoryId: string | null;
  Name: string;
  Description: string;
  ProductType: number;
  Position: number;
}

interface Product {
  Id: string;
  Name: string;
  ProductStatus: number;
  ProductType: number;
  Categories: Category[];
  CoverLink: string;
  OfferEndDate: string | null;
  ProductDisplayStatus: number;
  ViewInfo: ViewInfo;
  HasOffer: boolean;
  HasPromotedOffer: boolean;
  OfferStartDate: string | null;
}

interface ViewInfo {
  LastViewDate: string;
  ViewsCount: number;
}

interface ProductOption {
  FieldName: string;
  FlagValue: number;
}

interface FilterCategory {
  Name: string;
  Id: string;
  ParentCategoryId: string | null;
}

type Observer =
  | string
  | ((instance: HTMLDivElement | null) => void)
  | React.RefObject<HTMLDivElement>
  | null
  | undefined;

enum SortBy {
  DATE_ASCENDING = 'DATE_ASCENDING',
  DATE_DESCENDING = 'DATE_DESCENDING',
  NAME_ASCENDING = 'NAME_ASCENDING',
  NAME_DESCENDING = 'NAME_DESCENDING',
  POPULARITY = 'POPULARITY',
}

export const MobileFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.mobileFilterMenuSvgColor};
    }

    & circle {
      fill: ${({theme}) => theme.colors.mobileFilterMenuSvgColor};
    }
  }
`;

export const ClearFiltersButton = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  background: #ff0300;
  position: absolute;
  right: 344px;
  top: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    right: 5px;
    top: unset;
  }
`;

export const MobileSearchInputWrapper = styled.div`
  background: ${({theme}) => theme.colors.headerContentBackground};
  width: 258px;
  height: 38.8px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 16.5px;

  & > input {
    width: 196px;
    outline: none;
    border: none;
    background: transparent;
    color: ${({theme}) => theme.colors.mobileFilterMenuFontColor};
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15px;

    &::placeholder {
      color: ${({theme}) => theme.colors.mobileFilterMenuFontColor};
    }
  }

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.mobileFilterMenuSvgColor};
    }
  }
`;

export const MobileAddButtonWrapper = styled.div`
  width: 100%;
  height: 104px;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.mobileAddNewProductBorderColor};
  display: flex;
  align-items: center;

  & > p {
    color: ${({theme}) => theme.colors.mobileAddNewProductFontColor};
    font-weight: 400;
    font-size: 17px;
    letter-spacing: -0.05px;
  }

  & > div {
    width: 130px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MobileSingleConditionWrapper = styled.div<{ref: any}>`
  width: 100%;
  height: 116px;
  border-radius: 16px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  background: ${({theme}) => theme.colors.mobileOfferListingBackground};
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  & > img {
    width: 102px;
    height: 102px;
    margin-left: 8px;
    margin-right: 12px;
    border-radius: 9px;
  }

  & > svg {
    margin-left: 32px;
    margin-right: 24.5px;
  }

  & > div {
    position: relative;
    height: 100%;
    display: flex;
    width: 284px;
    flex-direction: column;

    & > p {
      font-size: 11px;
      margin-top: 11px;
      color: ${({theme}) => theme.colors.fontBlackOp50};
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.05px;
    }

    & > h5 {
      font-size: 18px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      width: 252px;
      height: 42px;
      letter-spacing: -0.15px;
      font-weight: bold;
      line-height: 21px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    & > svg {
      position: absolute;
      top: 12.5px;
      right: 13px;
    }

    & > button {
      width: 12px;
      height: 13px;
      background: transparent;
      border: none;
      position: absolute;
      cursor: pointer;
      bottom: 17px;
      right: 18.8px;
    }
  }
  @media (max-width: 400px) {
    & > div {
      width: 235px;
      & > h5 {
      }
    }
  }
`;

export const MenuMain = styled.div<{isMenuOpen: boolean; decodedToken?: any}>`
  width: 100%;
  background: white;
  border-top: ${({isMenuOpen}) => (isMenuOpen ? '4px solid #3A87F0' : 'none')};
  height: ${({isMenuOpen}) => (isMenuOpen ? '100vh' : '0px')};
  border-radius: 16px;
  position: fixed;
  overflow-y: auto;
  bottom: 0;
  transition: all 0.35s ease-out;
  z-index: 20;
  padding-bottom: ${({isMenuOpen}) => (isMenuOpen ? '42px' : '0px')};
`;

export const MenuWrapper = styled.div<{decodedToken?: any}>`
  width: 100%;
  padding: 0 32px;
  gap: 13px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #f8f8f8; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const MobileMain = styled.div`
  width: 396px;
  min-height: calc(100vh - 105px);
  margin-bottom: 57px;
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  padding: 8px 0px;
  flex-direction: column;
  align-items: center;
  background: transparent;
  gap: 9px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* padding: unset; */
    padding: 8px;
    gap: 5px;
  }
`;

export const NavButtonsWrapper = styled.div`
  width: 358px;
  height: 0px;
  position: fixed;
  top: 15px;
  z-index: 2;

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
  }
`;

export const MobileSearchBar = styled.input<{isActive?: boolean}>`
  display: ${({isActive}) => (isActive ? 'unset' : 'none')};
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 0.15px;
  font-weight: 400;
  color: #07020f;
  background: #dce0e9;
  width: 255px;
  border-radius: 7px;
  border: none;
  position: absolute;
  right: 79px;
  padding: 0 13px;
  top: -3px;
  outline: none;

  &::placeholder {
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: calc(100% - 120px);
    right: 88px;
  }
`;

export const FilterSvg = styled.svg<{isActive?: boolean}>`
  position: absolute;
  right: 56px;

  & path {
    fill: ${({isActive}) => (isActive ? '#3A87F0' : 'unset')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 65px;
  }
`;

export const SearchSvg = styled.svg<{isActive?: boolean}>`
  position: absolute;
  right: 92px;
  margin-top: 1px;

  & path {
    fill: ${({isActive}) => (isActive ? '#3A87F0' : 'unset')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 101px;
  }
`;

export const MobileInputFilterWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  position: relative;
  background: #dce0e9;
  height: 48px;
  gap: 5px;
  padding: 11px 25px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-size: 17px;
    color: #352f40;
    font-weight: 500;
    letter-spacing: -0.0625px;
    margin: 0;
  }

  & > input {
    width: 100%;
    text-align: right;
    border: none;
    outline: none;
    background: transparent;
    font-size: 17px;
    color: #3a87f0;
    font-weight: 500;
    letter-spacing: -0.0625px;

    &::placeholder {
      color: rgba(7, 2, 15, 0.3);
    }
  }
`;

export const MobileSelectFilterWrapper = styled.div<{value?: any}>`
  width: 100%;
  border-radius: 16px;
  position: relative;
  background: #dce0e9;
  height: 48px;
  gap: 5px;
  padding: 11px 25px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > select {
    width: 100%;
    font-size: 17px;
    background: transparent;
    border: none;
    outline: none;
    color: ${({value}) => (value ? '#3a87f0' : '#352f40')};
    font-weight: 500;
    letter-spacing: -0.0625px;
    margin: 0;
    -webkit-appearance: none;

    & > option {
      color: #352f40;
    }
  }
`;

export const MobileCheckboxFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;

  & > p {
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.15px;
    line-height: 16px;
    color: #07020f;
  }
`;

export const MobileCheckbox = styled.div<{
  isActive: boolean;
}>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({isActive}) =>
    isActive ? '3px solid #0066FF' : '3px solid rgba(112,112,112,0.3)'};
  cursor: ${({isActive}) => (isActive ? 'default' : 'pointer')};

  & > svg {
    display: ${({isActive}) => (isActive ? 'unset' : 'none')};
    margin-left: 1px;
  }
`;

export const CloseIsLoginPageOpenWrapper = styled.div`
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(7, 2, 15, 0.3);
  position: relative;
  top: 0px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -0.05px;
  color: #352f40;
  margin-bottom: 20px;
`;

export const CloseIsLoginPageOpen = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  font-size: 20px;
  color: black;
  font-weight: 900;
  /* top: 20px; */
  right: 23.5px;
`;

export const MobileSingleProductWrapper = styled.div`
  width: 100%;
  min-height: 128px;
  border-radius: 16px;
  border: none;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px 8px 17px;
  transition: all 0.15s;
  cursor: pointer;

  /* &:active {
    border: 3px solid #3986ef;
    filter: brightness(0.95);
  } */

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    border-radius: 0px;
  }
`;

export const IconWithDataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12.5px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    gap: 9px;
  }
`;

export const MobileDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 74px;

  & > p {
    font-size: 13px;
    font-weight: 400;
    color: rgba(7, 2, 15, 0.5);
    letter-spacing: -0.05px;
    line-height: 16px;
  }

  & > h5 {
    font-size: 18px;
    color: #07020f;
    letter-spacing: -0.15px;
    font-weight: bold;
    line-height: 21px;
    margin-bottom: 2px;
  }

  /* @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    height: 48px;

    & > p {
      margin-top: 3px;
    }
  } */
`;

export const ContentWrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
`;

export const SingleProductWrapper = styled.div<{ref: any}>`
  width: 100%;
  position: relative;
  height: 92px;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.myProductsBorder};
  background: ${({theme}) => theme.colors.myProductsBackground};
  display: grid;
  align-items: center;
  padding: 5px 5px;
  grid-template-columns: 82px 688px 112px;
  transition: all 0.15s;
  cursor: pointer;
  /* box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.12); */

  &:hover {
    border: 1px solid #3986ef;
  }

  &:active {
    border: 1px solid #3986ef;
    filter: brightness(0.95);
  }
`;

export const ProductDataWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-left: 15px;

  & > h5 {
    font-weight: bold;
    letter-spacing: -0.15px;
    font-size: 18px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > p {
    font-weight: 400;
    letter-spacing: -0.05px;
    font-size: 11px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const TextInLine = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;

  & > h5 {
    font-weight: 400;
    letter-spacing: 0.1px;
    font-size: 9px;
    color: rgba(53, 47, 64, 0.5);
  }

  & > p {
    font-weight: 400;
    letter-spacing: 0.1px;
    font-size: 9px;
    color: rgba(53, 47, 64, 1);
  }
`;

export const SingleSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  gap: 4px;

  & > h4 {
    color: white;
    font-size: 15px;
    font-weight: 400;
  }

  & > p {
    font-size: 9px;
    color: rgba(7, 2, 15, 1);
    font-weight: 400;
    letter-spacing: 0.1px;
  }

  & > h5 {
    font-size: 15px;
    color: white;
  }

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledStatus = styled.h2<{ProductDisplayStatus?: number}>`
  font-size: 11px;
  font-weight: 500;
  width: 112px;
  height: 24px;
  letter-spacing: -0.15px;
  inline-size: 112px;
  /* margin-left: 10px; */
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  overflow-wrap: break-word;
  text-align: center;
  color: black;

  ${({ProductDisplayStatus}) => {
    switch (ProductDisplayStatus) {
      case 0:
        return css`
          background: rgba(251, 156, 31, 0.7);
        `;
      case 1:
      case 2:
        return css`
          background: rgba(25, 214, 38, 0.7);
        `;
      case 3:
        return css`
          background: rgba(114, 112, 242, 0.7);
        `;
      case 4:
        return css`
          background: rgba(0, 102, 255, 0.7);
        `;
      case 5:
      case 6:
        return css`
          background: rgba(255, 4, 0, 0.7);
        `;

      default:
        return css`
          background: rgba(251, 156, 31, 0.7);
        `;
    }
  }}
`;

export const StyledStatusWrapper = styled.h2<{ProductDisplayStatus?: number}>`
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 7px;

  & > h1 {
    letter-spacing: 0.1px;
    font-size: 11px;
    font-weight: 500;

    ${({ProductDisplayStatus, theme}) => {
      switch (ProductDisplayStatus) {
        case 0:
          return css`
            color: ${theme.colors.statusNameBlack};
          `;
        case 1:
          return css`
            color: #00d10f;
          `;
        case 3:
        case 4:
          return css`
            color: #0091ff;
          `;
        case 2:
        case 5:
          return css`
            color: #fb9614;
          `;
        case 6:
          return css`
            color: #ff0300;
          `;

        default:
          return css`
            color: #fb9614;
          `;
      }
    }}
  }
`;

export const StyledStatusLine = styled.div<{ProductDisplayStatus?: number}>`
  width: 220px;
  border-bottom: 3px solid ${({theme}) => theme.colors.statusDefaultLine};
  position: relative;
  border-radius: 25px;

  & > div {
    position: absolute;
    left: 0px;
    border-radius: 25px;
    height: 3px;

    ${({ProductDisplayStatus, theme}) => {
      switch (ProductDisplayStatus) {
        case 0:
          return css`
            background: ${theme.colors.statusLineBlack};
            width: 30px;
          `;
        case 1:
          return css`
            background: #00d10f;
            width: 60px;
          `;
        case 3:
        case 4:
          return css`
            background: #0091ff;
            width: 220px;
          `;
        case 2:
        case 5:
          return css`
            background: #fb9614;
            width: 155px;
          `;
        case 6:
          return css`
            background: #ff0300;
            width: 220px;
          `;

        default:
          return css`
            background: #fb9614;
            width: 155px;
          `;
      }
    }}
  }
`;

export const CoverImage = styled.img`
  width: 82px;
  height: 82px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 11px;
    width: 74px;
    height: 74px;
    border: 1px solid rgba(112, 112, 112, 0.3);
  }

  /* @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    border-radius: 7px;
    width: 48px;
    height: 48px;
  } */
`;

export const PlaceholderImageWrapper = styled.div`
  height: 82px;
  width: 82px;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.trackPlaceholderSvgColor};
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
    }
  }
`;

export const ProductGenreWrapper = styled.div`
  width: 100%;
  height: 22px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2px;
  margin-right: 30px;

  & > div {
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > p {
      font-weight: 600;
      color: white;
      font-size: 9px;
    }
  }

  @media (max-width: 1100px) {
    width: 298px;
    margin-right: unset;
    margin-top: 7px;
  }
`;

export const AddButtonWrapper = styled.div`
  position: absolute;
  right: 43px;
  top: -45px;
  height: 22px;
  width: 82px;
`;

export const PrimaryButton = styled.button`
  border-radius: 9px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  background: transparent;
  font-size: 11px;
  color: #352f40;
  cursor: pointer;
  transition: all 0.15s;
  width: 119px;
  height: 38px;
  letter-spacing: 0.625px;

  &:hover {
    filter: brightness(1.2);
  }
`;

export const PublicationDateWrapper = styled.div`
  display: flex;
  gap: 35px;

  & > h2 {
    font-size: 11px;
    letter-spacing: -0.05px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.viewsCountColor};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    padding-left: unset;
    padding-right: 10px;
    padding-top: unset;
    justify-content: space-between;
    gap: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    padding-right: unset;
    position: absolute;
    bottom: 11px;
    gap: 35px;
    left: 40px;
    right: 40px;
  }
`;

export const PublicationDate = styled.div`
  display: flex;
  gap: 3px;

  & > span {
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 9px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }

  & > p {
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 9px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const PromotionDate = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 6px;

  & > span {
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 9px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }

  & > p {
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 9px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* bottom: unset;
    right: 3px; */
    position: unset;

    & > p {
      font-size: 13px;
      font-weight: 400;
      color: rgba(7, 2, 15, 0.5);
      letter-spacing: -0.05px;
      line-height: 16px;
    }
  }

  /* @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: unset;
    position: unset;
    gap: unset;
  } */
`;

export const SingleFilterHeaderWrapper = styled.div`
  height: 40px;
  width: 100%;
  border-bottom: 1px solid rgba(7, 2, 15, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  & > p {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-bottom: none;

    & > p {
      font-size: 17px;
      color: #352f40;
      letter-spacing: -0.05px;
      line-height: 24px;
      font-weight: 400;
    }
  }
`;

export const SingleFilterContentWrapper = styled.div`
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: ${({theme}) => theme.colors.pageHeader};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.headerBlackFont};
    font-size: 15px;
    letter-spacing: 0.625px;
  }
`;

export const FilterButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ArrowWrapper = styled.div<{isActive?: boolean; length?: number}>`
  width: 84px;
  height: 21px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  border-radius: 7px;
  background: ${({isActive, length}) =>
    isActive || length > 0 ? 'rgba(137, 190, 255, 0.3)' : 'transparent'};

  &:hover {
    background: rgba(137, 190, 255, 0.3);
  }

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.grayFontOp100};
    }
  }

  & > p {
    position: absolute;
    background: #89bfff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    right: 6px;
    letter-spacing: 0.15px;
    font-size: 14px;
    color: ${({theme}) => theme.colors.headerBlackFont};
  }
`;

export const AddButton = styled.button`
  width: 120px;
  height: 100%;
  position: absolute;
  right: 0px;
  border: none;
  border-left: 1px solid white;
  background: transparent;
  border-radius: 0 24px 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: ${({theme}) => theme.colors.grayFontOp100};
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const BackButton = styled.button`
  width: 120px;
  height: 100%;
  position: absolute;
  left: 0px;
  border: none;
  border-right: 1px solid white;
  background: transparent;
  border-radius: 9px 0 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const DeleteTagButton = styled.div`
  position: absolute;
  top: 10px;
  right: 14px;

  & > button {
    width: 18px;
    height: 18px;
    border: none;
    border-radius: 4px;
    background: #ff7e7d !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.15s;
  }

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const getProductDisplayStatusDescription = (status: number) => {
  switch (status) {
    case 0:
      return 'Draft';
    case 1:
      return 'Gotowa';
    case 2:
      return 'Oferta gotowa do wystawienia';
    case 3:
      return 'Oferta aktywna';
    case 4:
      return 'Oferta promowana';
    case 5:
      return 'Oferta wygasła';
    case 6:
      return 'Nieaktywna';
    case 7:
      return 'Oferta wycofana';
    default:
      return 'Undefined';
  }
};

const getFormattedDate = (date: string) => {
  if (!date) return;
  const availabilityEndDate = new Date(date);
  const year = availabilityEndDate.getFullYear();
  let month: string | number = availabilityEndDate.getMonth() + 1;
  let day: string | number = availabilityEndDate.getDate();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  return `${day}.${month}.${year}`;
};

export const productStatusesMocked = [
  {
    FieldName: 'Draft',
    FlagValue: 0,
  },
  {
    FieldName: 'Gotowa',
    FlagValue: 1,
  },
  {
    FieldName: 'Oferta gotowa do wystawienia',
    FlagValue: 2,
  },
  {
    FieldName: 'Oferta aktywna',
    FlagValue: 3,
  },
  {
    FieldName: 'Oferta promowana',
    FlagValue: 4,
  },
  {
    FieldName: 'Oferta wygasła',
    FlagValue: 5,
  },
  {
    FieldName: 'Nieaktywna',
    FlagValue: 6,
  },
];

const Products: React.FC = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['token']);
  const [userData, setUserData] = useContext(UserDataContext);

  const {decodedToken} = useContext(ProducerDataContext);

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }

  if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000') {
    return <Navigate to="/producer" />;
  }

  if (userData.FirstName === null) {
    window.location.href = '/account/settings';
  }

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEditing, setEditing] = useState<boolean>(false);
  const [productId, setProductId] = useState<string | undefined>(undefined);
  const [products, setProducts] = useState<Product[]>([]);
  const [searchString, setSearchString] = useState<string>(
    sessionStorage.getItem('productFilterSearchString')
      ? sessionStorage.getItem('productFilterSearchString')
      : '',
  );
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [hasMoreProducers, setHasMoreProducers] = useState(true);
  // const [productTypes, setProductTypes] = useState<ProductOption[]>([]);
  const [minGrossValue, setMinGrossValue] = useState<
    number | string | undefined
  >(
    sessionStorage.getItem('productFilterMinGrossValue')
      ? +sessionStorage.getItem('productFilterMinGrossValue')
      : null,
  );
  const [maxGrossValue, setMaxGrossValue] = useState<
    number | string | undefined
  >(
    sessionStorage.getItem('productFilterMaxGrossValue')
      ? +sessionStorage.getItem('productFilterMaxGrossValue')
      : null,
  );
  const [productCategories, setProductCategories] = useState<FilterCategory[]>(
    [],
  );
  const [primaryCategory, setPrimaryCategory] = useState<string>(
    sessionStorage.getItem('productFilterPrimaryCategory')
      ? sessionStorage.getItem('productFilterPrimaryCategory')
      : '',
  );
  const [secondaryCategories, setSecondaryCategories] = useState<
    FilterCategory[]
  >([]);
  const [secondaryCategory, setSecondaryCategory] = useState<string>(
    sessionStorage.getItem('productFilterSecondaryCategory')
      ? sessionStorage.getItem('productFilterSecondaryCategory')
      : '',
  );
  const [tertiaryCategories, setTertiaryCategories] = useState<
    FilterCategory[]
  >([]);
  const [tertiaryCategory, setTertiaryCategory] = useState<string>(
    sessionStorage.getItem('productFilterTertiaryCategory')
      ? sessionStorage.getItem('productFilterTertiaryCategory')
      : '',
  );
  const [productType, setProductType] = useState<number | string>(
    sessionStorage.getItem('productFilterProductType')
      ? +sessionStorage.getItem('productFilterProductType')
      : '',
  );
  const [productStatus, setProductStatus] = useState<number | string>(
    sessionStorage.getItem('productFilterProductStatus')
      ? +sessionStorage.getItem('productFilterProductStatus')
      : '',
  );
  const [isWinwin, setIsWinwin] = useState<boolean>(
    sessionStorage.getItem('productFilterIsWinwin') === 'yes' ? true : false,
  );
  const [isFavourite, setIsFavourite] = useState<boolean>(
    sessionStorage.getItem('productFilterIsFavourite') === 'yes' ? true : false,
  );
  const [hasPublishedActiveOffer, setHasPublishedActiveOffer] =
    useState<boolean>(
      sessionStorage.getItem('productFilterHasPublishedActiveOffer') === 'yes'
        ? true
        : false,
    );
  const [selectedProduct, setSelectedProduct] = useState<number>(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [isSortMenuOpen, setIsSortMenuOpen] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortBy | string>(
    sessionStorage.getItem('productFilterSortBy')
      ? sessionStorage.getItem('productFilterSortBy')
      : SortBy.DATE_DESCENDING,
  );

  const lastElementRef = useInfinityScroller(
    isLoading,
    hasMoreProducers,
    setPageNumber,
  );

  const mobileLastElementRef = useInfinityScroller(
    isLoading,
    hasMoreProducers,
    setPageNumber,
  );

  const mobileSearchBarRef = useRef<HTMLInputElement>(null);
  const mobileFilterMenuWrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();
  const {setIsMenuOpen} = useMenuContext();
  const {isMobileFilterMenuOpen, setIsMobileFilterMenuOpen} =
    useFilterMenuContext();
  const {isMobileSearchBarOpen, setIsMobileSearchBarOpen} =
    useSearchBarContext();

  const navigate = useNavigate();
  const {Alert} = useAlert();

  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    sessionStorage.setItem('productFilterSearchString', e.target.value);
  };

  const handleIsMobileSearchBarOpenChange = (value: boolean) =>
    setIsMobileSearchBarOpen(value);

  const handleIsMobileFilterMenuOpenChange = (value: boolean) =>
    setIsMobileFilterMenuOpen(value);

  const handleIsFilterMenuOpenChange = (value: boolean) =>
    setIsFilterMenuOpen(value);

  const handleIsSortMenuOpenChange = (value: boolean) =>
    setIsSortMenuOpen(value);

  const handleProductTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProductType(+e.target.value);
    sessionStorage.setItem('productFilterProductType', e.target.value);
  };

  const handleMinGrossValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let userInput = e.target.value;
    userInput = userInput.replace(/[^0-9.,]/g, '');
    userInput = userInput.replace(',', '.');
    setMinGrossValue(userInput);
    if (userInput) {
      sessionStorage.setItem('productFilterMinGrossValue', userInput);
    }
  };

  const handleMaxGrossValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    let userInput = e.target.value;
    userInput = userInput.replace(/[^0-9.,]/g, '');
    userInput = userInput.replace(',', '.');
    setMaxGrossValue(userInput);
    if (userInput) {
      sessionStorage.setItem('productFilterMaxGrossValue', userInput);
    }
  };

  const handleSortByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    sessionStorage.setItem('productFilterSortBy', e.target.value);
    setSortBy(e.target.value);
  };

  const getAllCategories = async () => {
    try {
      const response = await api.get(`product-constants/categories`);
      setCategories(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteProduct = async (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const response = await api.delete(`products/${id}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      // getProductsList(
      //   searchString,
      //   productType,
      //   // productStatus,
      //   primaryCategory,
      //   secondaryCategory,
      //   tertiaryCategory,
      //   minGrossValue,
      //   maxGrossValue,
      //   isWinwin,
      //   isFavourite,
      //   hasPublishedActiveOffer,
      //   sortBy,
      //   pageNumber
      // ),
      setProducts((prev) => prev.filter((product) => product.Id !== id));
      Alert('Oferta została usunięta.', 'Komunikat');
    } catch (error) {
      console.log(error);
    }
  };

  const handlePrimaryCategoryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setPrimaryCategory(e.target.value);
    sessionStorage.setItem('productFilterPrimaryCategory', e.target.value);
  };

  const handleSecondaryCategoryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSecondaryCategory(e.target.value);
    sessionStorage.setItem('productFilterSecondaryCategory', e.target.value);
  };

  const handleTertiaryCategoryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setTertiaryCategory(e.target.value);
    sessionStorage.setItem('productFilterTertiaryCategory', e.target.value);
  };

  const handleIsWinwinChange = (value: boolean) => {
    setIsWinwin(value);
    if (value === true) {
      sessionStorage.setItem('productFilterIsWinwin', 'yes');
    } else {
      sessionStorage.setItem('productFilterIsWinwin', 'no');
    }
  };

  const handleIsFavouriteChange = (value: boolean) => {
    setIsFavourite(value);
    if (value === true) {
      sessionStorage.setItem('productFilterIsFavourite', 'yes');
    } else {
      sessionStorage.setItem('productFilterIsFavourite', 'no');
    }
  };

  const handleHasPublishedActiveOfferChange = (value: boolean) => {
    setHasPublishedActiveOffer(value);
    if (value === true) {
      sessionStorage.setItem('productFilterHasPublishedActiveOffer', 'yes');
    } else {
      sessionStorage.setItem('productFilterHasPublishedActiveOffer', 'no');
    }
  };

  const handleProductStatusChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    sessionStorage.setItem('productFilterProductStatus', e.target.value);
    if (e.target.value === '') return setProductStatus(e.target.value);
    setProductStatus(+e.target.value);
  };

  const nextProduct = () => {
    if (selectedProduct === products.length - 1) {
      return setSelectedProduct(0);
    }
    return setSelectedProduct((prev) => prev + 1);
  };

  const prevProduct = () => {
    if (selectedProduct === 0) {
      return setSelectedProduct(products.length - 1);
    }
    return setSelectedProduct((prev) => prev - 1);
  };

  const clearFilters = () => {
    setSearchString('');
    sessionStorage.removeItem('productFilterSearchString');
    setSearchString('');
    sessionStorage.removeItem('productFilterSearchString');
    setMinGrossValue(null);
    sessionStorage.removeItem('productFilterMinGrossValue');
    setMaxGrossValue(null);
    sessionStorage.removeItem('productFilterMaxGrossValue');
    setPrimaryCategory('');
    sessionStorage.removeItem('productFilterPrimaryCategory');
    setSecondaryCategory('');
    sessionStorage.removeItem('productFilterSecondaryCategory');
    setTertiaryCategory('');
    sessionStorage.removeItem('productFilterTertiaryCategory');
    setProductType('');
    sessionStorage.removeItem('productFilterProductType');
    setProductStatus('');
    sessionStorage.removeItem('productFilterProductStatus');
    setIsWinwin(false);
    sessionStorage.removeItem('productFilterIsWinwin');
    setIsFavourite(false);
    sessionStorage.removeItem('productFilterIsFavourite');
    setHasPublishedActiveOffer(false);
    sessionStorage.removeItem('productFilterHasPublishedActiveOffer');
  };

  const getProductsList = async (
    searchString: string,
    productType: string | number,
    // productStatus: string | number,
    primaryCategory: string,
    secondaryCategory: string,
    tertiaryCategory: string,
    minGrossValue: number | undefined | string,
    maxGrossValue: number | undefined | string,
    isWinwin: boolean,
    isFavourite: boolean,
    hasPublishedActiveOffer: boolean,
    sortBy: SortBy | string,
    pageNumber: number,
  ) => {
    try {
      setIsLoading(true);
      const categoryIds = [];
      if (primaryCategory) categoryIds.push(primaryCategory);

      if (secondaryCategory) categoryIds.push(secondaryCategory);

      if (tertiaryCategory) categoryIds.push(tertiaryCategory);

      const params = {
        pageSize: 20,
        pageNumber: pageNumber,
        searchString,
        sortType:
          sortBy === SortBy.DATE_ASCENDING || sortBy === SortBy.NAME_ASCENDING
            ? 0
            : 1,
        sortBy:
          sortBy === SortBy.NAME_ASCENDING || sortBy === SortBy.NAME_DESCENDING
            ? 1
            : sortBy === SortBy.POPULARITY
            ? 3
            : 2,
        isWinwin,
        isFavourite,
        hasPublishedActiveOffer,
        productType,
        minGrossValue: minGrossValue ? +minGrossValue * 100 : undefined,
        maxGrossValue: maxGrossValue ? +maxGrossValue * 100 : undefined,
        productStatus: '',
        categoryIds,
      };

      const response = await api.get(
        `producers/${decodedToken.ProducerId}/Products/listing`,
        {
          params,
          paramsSerializer: (params) => qs.stringify(params),
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );
      setHasMoreProducers(response.data.Response.length > 0);
      setProducts((prev) => [...prev, ...response.data.Response]);

      response.data.Response.map((singleProduct) =>
        singleProduct.Categories.map((category) => {
          setProductCategories((prev) =>
            [...prev.filter((el) => el.Id === category.Id)].length === 0
              ? [
                  ...prev,
                  {
                    Name: category.Name,
                    Id: category.Id,
                    ParentCategoryId: category.ParentCategoryId,
                  },
                ]
              : [...prev],
          );

          return;
        }),
      );
      // response.data.Response.map((singleProduct) =>
      //   singleProduct.Categories.map((category) => {
      //     if (category.Position === 2) {
      //       setSecondaryCategories((prev) =>
      //         [...prev.filter((el) => el.Id === category.Id)].length === 0
      //           ? [
      //               ...prev,
      //               {
      //                 Name: category.Name,
      //                 Id: category.Id,
      //               },
      //             ]
      //           : [...prev],
      //       );
      //     }
      //     return;
      //   }),
      // );

      // response.data.Response.map((singleProduct) =>
      //   singleProduct.Categories.map((category) => {
      //     if (category.Position === 3) {
      //       setTertiaryCategories((prev) =>
      //         [...prev.filter((el) => el.Id === category.Id)].length === 0
      //           ? [
      //               ...prev,
      //               {
      //                 Name: category.Name,
      //                 Id: category.Id,
      //               },
      //             ]
      //           : [...prev],
      //       );
      //     }
      //     return;
      //   }),
      // );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProductsListDebounce = useCallback(
    _.debounce(
      (
        searchString,
        productType,
        // productStatus,
        primaryCategory,
        secondaryCategory,
        tertiaryCategory,
        minGrossValue,
        maxGrossValue,
        isWinwin,
        isFavourite,
        hasPublishedActiveOffer,
        sortBy,
        pageNumber,
      ) =>
        getProductsList(
          searchString,
          productType,
          // productStatus,
          primaryCategory,
          secondaryCategory,
          tertiaryCategory,
          minGrossValue,
          maxGrossValue,
          isWinwin,
          isFavourite,
          hasPublishedActiveOffer,
          sortBy,
          pageNumber,
        ),
      500,
    ),
    [],
  );

  useEffect(() => {
    getProductsListDebounce(
      searchString,
      productType,
      // productStatus,
      primaryCategory,
      secondaryCategory,
      tertiaryCategory,
      minGrossValue,
      maxGrossValue,
      isWinwin,
      isFavourite,
      hasPublishedActiveOffer,
      sortBy,
      pageNumber,
    );
  }, [
    searchString,
    productType,
    // productStatus,
    primaryCategory,
    secondaryCategory,
    tertiaryCategory,
    minGrossValue,
    maxGrossValue,
    isWinwin,
    isFavourite,
    hasPublishedActiveOffer,
    sortBy,
    pageNumber,
  ]);

  useEffect(() => {
    setProducts([]);
    setPageNumber(0);
  }, [
    searchString,
    productType,
    // productStatus,
    primaryCategory,
    secondaryCategory,
    tertiaryCategory,
    minGrossValue,
    maxGrossValue,
    isWinwin,
    isFavourite,
    hasPublishedActiveOffer,
    sortBy,
  ]);

  useEffect(() => {
    if (primaryCategory !== '') {
      sessionStorage.setItem('productFilterPrimaryCategory', primaryCategory);
    }
    setSecondaryCategory('');
    setTertiaryCategory('');
    sessionStorage.setItem('productFilterSecondaryCategory', '');
    sessionStorage.setItem('productFilterTertiaryCategory', '');
  }, [primaryCategory]);

  useEffect(() => {
    if (secondaryCategory !== '') {
      sessionStorage.setItem(
        'productFilterSecondaryCategory',
        secondaryCategory,
      );
    }
    setTertiaryCategory('');
    sessionStorage.setItem('productFilterTertiaryCategory', '');
  }, [secondaryCategory]);

  useEffect(() => {
    if (tertiaryCategory !== '') {
      sessionStorage.setItem('productFilterTertiaryCategory', tertiaryCategory);
    }
  }, [tertiaryCategory]);

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileFilterMenuWrapperRef.current) {
        if (isMobileFilterMenuOpen) {
          mobileFilterMenuWrapperRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileFilterMenuWrapperRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isMobileFilterMenuOpen]);

  return (
    <>
      <MainWrapper>
        <Main>
          {isLoading && <LoadingCircle />}
          <PageHeaderWrapper
            onMouseLeave={() => {
              handleIsFilterMenuOpenChange(false);
              handleIsSortMenuOpenChange(false);
            }}
          >
            <PageHeaderHeaderWrapper>
              <PageHeaderHeader>
                <p>Oferty</p>
              </PageHeaderHeader>
              <AddButton onClick={() => navigate('/myproducts/add')}>
                Dodaj
              </AddButton>
            </PageHeaderHeaderWrapper>
            <PageHeaderContent>
              <FilterWrapper>
                <SortButtonSvg
                  value={isSortMenuOpen}
                  onClick={() => {
                    handleIsSortMenuOpenChange(!isSortMenuOpen);
                    handleIsFilterMenuOpenChange(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.824"
                  height="13.204"
                  viewBox="0 0 18.824 13.204"
                >
                  <g
                    id="Group_1311"
                    data-name="Group 1311"
                    transform="translate(-2536.541 -534.989)"
                  >
                    <path
                      id="Path_1508"
                      data-name="Path 1508"
                      d="M2543.457,537.33l-2.582-2.1a1.076,1.076,0,0,0-1.357,0l-2.581,2.1a1.075,1.075,0,0,0,1.357,1.668l.9-.732v6.852a1.075,1.075,0,1,0,2.15,0v-6.735l.756.615a1.075,1.075,0,1,0,1.357-1.668Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1509"
                      data-name="Path 1509"
                      d="M2548.448,545.852l2.582,2.1a1.074,1.074,0,0,0,1.357,0l2.581-2.1a1.075,1.075,0,0,0-1.357-1.668l-.9.732v-6.852a1.075,1.075,0,0,0-2.151,0V544.8l-.756-.615a1.075,1.075,0,0,0-1.357,1.668Z"
                      fill="#797c93"
                    />
                  </g>
                </SortButtonSvg>
                <FilterInputWrapper>
                  {/* <img src={searchIcon} alt="search" /> */}
                  <svg
                    id="Group_4104"
                    data-name="Group 4104"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14.146"
                    height="14"
                    viewBox="0 0 14.146 14"
                  >
                    <path
                      id="Path_1507"
                      data-name="Path 1507"
                      d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                      transform="translate(-2587.94 -535.001)"
                      fill="#0091ff"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder="Wyszukaj..."
                    value={searchString}
                    onChange={handleSearchStringChange}
                  />
                </FilterInputWrapper>
                {countTruthyVariables(
                  searchString,
                  primaryCategory,
                  secondaryCategory,
                  tertiaryCategory,
                  minGrossValue,
                  maxGrossValue,
                  productType,
                  productStatus,
                  isWinwin,
                  isFavourite,
                  hasPublishedActiveOffer,
                ) > 0 && (
                  <CountFilterDisplay>
                    {countTruthyVariables(
                      searchString,
                      primaryCategory,
                      secondaryCategory,
                      tertiaryCategory,
                      minGrossValue,
                      maxGrossValue,
                      productType,
                      productStatus,
                      isWinwin,
                      isFavourite,
                      hasPublishedActiveOffer,
                    )}
                  </CountFilterDisplay>
                )}
                <FilterButtonSvg
                  value={isFilterMenuOpen}
                  onClick={() => {
                    handleIsSortMenuOpenChange(false);
                    handleIsFilterMenuOpenChange(!isFilterMenuOpen);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.87"
                  height="15.047"
                  viewBox="0 0 20.87 15.047"
                >
                  <g
                    id="Group_1312"
                    data-name="Group 1312"
                    transform="translate(-2473.175 -536.818)"
                  >
                    <path
                      id="Path_1510"
                      data-name="Path 1510"
                      d="M2480.2,539.042h13a.85.85,0,0,0,0-1.7h-13a.85.85,0,0,0,0,1.7Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1511"
                      data-name="Path 1511"
                      d="M2493.2,543.517h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                      fill="#797c93"
                    />
                    <path
                      id="Path_1512"
                      data-name="Path 1512"
                      d="M2493.2,549.4h-13a.85.85,0,0,0,0,1.7h13a.85.85,0,0,0,0-1.7Z"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_1"
                      data-name="Ellipse 1"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 548.864)"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_2"
                      data-name="Ellipse 2"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 542.853)"
                      fill="#797c93"
                    />
                    <circle
                      id="Ellipse_3"
                      data-name="Ellipse 3"
                      cx="1.5"
                      cy="1.5"
                      r="1.5"
                      transform="translate(2473.175 536.818)"
                      fill="#797c93"
                    />
                  </g>
                </FilterButtonSvg>
                {countTruthyVariables(
                  searchString,
                  primaryCategory,
                  secondaryCategory,
                  tertiaryCategory,
                  minGrossValue,
                  maxGrossValue,
                  productType,
                  productStatus,
                  isWinwin,
                  isFavourite,
                  hasPublishedActiveOffer,
                ) > 0 && (
                  <ClearFiltersButton onClick={() => clearFilters()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.9"
                      height="12"
                      viewBox="0 0 10.9 12"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h9.9"
                          transform="translate(-4.5 -6.8)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                          transform="translate(-6.4 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </ClearFiltersButton>
                )}
              </FilterWrapper>
              {isSortMenuOpen && (
                <FilterMenuWrapper>
                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Kolejność</p>
                        <select onChange={handleSortByChange} value={sortBy}>
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={SortBy.DATE_ASCENDING}>
                            Od najstarszego
                          </option>
                          <option value={SortBy.DATE_DESCENDING}>
                            Od najnowszego
                          </option>
                          <option value={SortBy.NAME_ASCENDING}>
                            Nazwa A-Z
                          </option>
                          <option value={SortBy.NAME_DESCENDING}>
                            Nazwa Z-A
                          </option>
                          <option value={SortBy.POPULARITY}>Popularność</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>
                </FilterMenuWrapper>
              )}
              {isFilterMenuOpen && (
                <FilterMenuWrapper>
                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Typ produktu</p>
                        <select
                          value={productType}
                          onChange={handleProductTypeChange}
                        >
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>
                          {ProductTypeEnum.map((productType) => {
                            const {FieldName, FlagValue} = productType;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {getProductTypePolishName(FlagValue)}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                      <SelectWrapper>
                        <p>Kategoria</p>
                        <select
                          value={primaryCategory}
                          onChange={handlePrimaryCategoryChange}
                        >
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>
                          {productCategories
                            .filter(
                              (category) => category.ParentCategoryId === null,
                            )
                            .map((category) => {
                              const {Name, Id} = category;

                              return (
                                <option value={Id} key={Id}>
                                  {Name.length > 25
                                    ? Name.slice(0, 25) + '...'
                                    : Name}
                                </option>
                              );
                            })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                      <SelectWrapper>
                        <p>Podkategoria</p>
                        <select
                          value={secondaryCategory}
                          onChange={handleSecondaryCategoryChange}
                        >
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>

                          {secondaryCategories
                            .filter(
                              (category) =>
                                category.ParentCategoryId === primaryCategory,
                            )
                            .map((category) => {
                              const {Name, Id} = category;

                              return (
                                <option value={Id} key={Id}>
                                  {Name.length > 25
                                    ? Name.slice(0, 25) + '...'
                                    : Name}
                                </option>
                              );
                            })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                      <SelectWrapper>
                        <p>Podkategoria</p>
                        <select
                          value={tertiaryCategory}
                          onChange={handleTertiaryCategoryChange}
                        >
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>
                          {tertiaryCategories
                            .filter(
                              (category) =>
                                category.ParentCategoryId === secondaryCategory,
                            )
                            .map((category) => {
                              const {Name, Id} = category;

                              return (
                                <option value={Id} key={Id}>
                                  {Name.length > 25
                                    ? Name.slice(0, 25) + '...'
                                    : Name}
                                </option>
                              );
                            })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>

                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <SelectWrapper>
                        <p>Status</p>
                        <select
                          value={productStatus}
                          onChange={handleProductStatusChange}
                        >
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          <option value={''}>Wszystko</option>
                          {productStatusesMocked.map((productStatus) => {
                            const {FieldName, FlagValue} = productStatus;

                            return (
                              <option value={FlagValue} key={FlagValue}>
                                {FieldName}
                              </option>
                            );
                          })}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#0091ff"
                            />
                          </g>
                        </svg>
                      </SelectWrapper>
                      <FilterInput>
                        <p>Cena od</p>
                        <input
                          type="text"
                          placeholder="0zł"
                          value={minGrossValue}
                          onChange={handleMinGrossValueChange}
                        />
                        <svg
                          id="Group_4104"
                          data-name="Group 4104"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.136"
                          height="13"
                          viewBox="0 0 13.136 13"
                        >
                          <path
                            id="Path_1507"
                            data-name="Path 1507"
                            d="M2600.834,546.715l-3.268-2.978a5.4,5.4,0,0,0-.416-7.158h0a5.4,5.4,0,1,0-.652,8.183l3.342,3.046a.739.739,0,1,0,1-1.093Zm-10.49-3.33a4.229,4.229,0,1,1,2.989,1.238A4.233,4.233,0,0,1,2590.344,543.385Z"
                            transform="translate(-2587.94 -535.001)"
                            fill="#0091ff"
                          />
                        </svg>
                      </FilterInput>
                      <FilterInput>
                        <p>Cena do</p>
                        <input
                          type="text"
                          placeholder="0zł"
                          value={maxGrossValue}
                          onChange={handleMaxGrossValueChange}
                        />
                        <svg
                          id="Group_4104"
                          data-name="Group 4104"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13.136"
                          height="13"
                          viewBox="0 0 13.136 13"
                        >
                          <path
                            id="Path_1507"
                            data-name="Path 1507"
                            d="M2600.834,546.715l-3.268-2.978a5.4,5.4,0,0,0-.416-7.158h0a5.4,5.4,0,1,0-.652,8.183l3.342,3.046a.739.739,0,1,0,1-1.093Zm-10.49-3.33a4.229,4.229,0,1,1,2.989,1.238A4.233,4.233,0,0,1,2590.344,543.385Z"
                            transform="translate(-2587.94 -535.001)"
                            fill="#0091ff"
                          />
                        </svg>
                      </FilterInput>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>

                  <SingleFilterWrapper>
                    <SingleFilterContentWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Tylko Win-Win</p>
                        <CustomBlueCheckbox
                          isActive={isWinwin}
                          onClick={() => handleIsWinwinChange(!isWinwin)}
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Tylko obserwowane oferty</p>
                        <CustomBlueCheckbox
                          isActive={isFavourite}
                          onClick={() => handleIsFavouriteChange(!isFavourite)}
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                      <CheckboxWithTitleWrapper>
                        <p>Wyklucz nieaktywne</p>
                        <CustomBlueCheckbox
                          isActive={hasPublishedActiveOffer}
                          onClick={() =>
                            handleHasPublishedActiveOfferChange(
                              !hasPublishedActiveOffer,
                            )
                          }
                        >
                          <div />
                        </CustomBlueCheckbox>
                      </CheckboxWithTitleWrapper>
                    </SingleFilterContentWrapper>
                  </SingleFilterWrapper>
                </FilterMenuWrapper>
              )}
            </PageHeaderContent>
          </PageHeaderWrapper>
          <Grid>
            {products.map((product) => {
              const {
                Categories,
                Id,
                Name,
                ProductStatus,
                ProductType,
                CoverLink,
                OfferEndDate,
                ProductDisplayStatus,
                OfferStartDate,
                HasOffer,
                ViewInfo,
              } = product;

              const productTypeValue = getProductTypePolishName(
                ProductTypeEnum.filter(
                  (productType) => productType.FlagValue === ProductType,
                )[0]?.FlagValue,
              );

              const productStatusValue = ProductStatusEnum.filter(
                (productStatus) => productStatus.FlagValue === ProductStatus,
              )[0]?.FieldName;

              const firstCategory = Categories.filter(
                (category) => category.Position === 1,
              );
              const firstCategoryValue =
                firstCategory.length > 0 ? firstCategory[0].Name : '-';

              const secondCategory = Categories.filter(
                (category) => category.Position === 2,
              );
              const secondCategoryValue =
                secondCategory.length > 0 ? secondCategory[0].Name : '-';

              const tertiaryCategory = Categories.filter(
                (category) => category.Position === 3,
              );
              const tertiaryCategoryValue =
                tertiaryCategory.length > 0 ? tertiaryCategory[0].Name : '-';

              if (
                productStatus !== '' &&
                productStatus !== ProductDisplayStatus
              )
                return;
              return (
                <SingleProductWrapper
                  key={Id}
                  ref={lastElementRef}
                  onClick={() => {
                    navigate(`/myproducts/product/${Id}`);
                  }}
                >
                  <SingleSection>
                    {CoverLink ? (
                      <CoverImage src={CoverLink} alt={'brak zdjęcia'} />
                    ) : ProductType === 8 ? (
                      <PlaceholderImageWrapper>
                        <TrackPlaceholderSvg />
                      </PlaceholderImageWrapper>
                    ) : ProductType === 4 ? (
                      <PlaceholderImageWrapper>
                        <ServicePlaceholderSvg />
                      </PlaceholderImageWrapper>
                    ) : (
                      <PlaceholderImageWrapper>
                        <DigitalProductPlaceholderSvg />
                      </PlaceholderImageWrapper>
                    )}
                  </SingleSection>
                  <SingleSection>
                    <ProductDataWrapper>
                      <p>{productTypeValue}</p>
                      <h5>{Name}</h5>
                      {HasOffer && (
                        <PublicationDateWrapper>
                          <h2>
                            {`${
                              ViewInfo.ViewsCount
                            } wyświetleń • opublikowano ${getFormattedDate(
                              OfferStartDate,
                            )}`}
                          </h2>
                          {/* <PublicationDate>
                            <span>Data publikacji:</span>
                            <p>{getFormattedDate(OfferStartDate)}</p>
                          </PublicationDate>
                          <PublicationDate>
                            <span>Liczba wyświetleń:</span>
                            <p>{ViewInfo.ViewsCount}</p>
                          </PublicationDate> */}
                        </PublicationDateWrapper>
                      )}
                    </ProductDataWrapper>
                  </SingleSection>
                  <SingleSection style={{position: 'relative'}}>
                    {/* <StyledStatus ProductDisplayStatus={ProductDisplayStatus}>
                      {getProductDisplayStatusDescription(ProductDisplayStatus)}
                    </StyledStatus> */}
                    <StyledStatusWrapper
                      ProductDisplayStatus={ProductDisplayStatus}
                    >
                      <h1>
                        {getProductDisplayStatusDescription(
                          ProductDisplayStatus,
                        )}
                      </h1>
                      <StyledStatusLine
                        ProductDisplayStatus={ProductDisplayStatus}
                      >
                        <div />
                      </StyledStatusLine>
                    </StyledStatusWrapper>
                    {/* {ProductDisplayStatus === 4 && (
                      <PromotionDate>
                        <span>Do dnia:</span>
                        <p>{getFormattedDate(OfferEndDate)}</p>
                      </PromotionDate>
                    )} */}
                  </SingleSection>
                  {(ProductStatus === 0 ||
                    (ProductDisplayStatus !== 3 &&
                      ProductDisplayStatus !== 4)) && (
                    <DeleteTagButton>
                      <PopupButton
                        buttons={['Anuluj', 'Usuń']}
                        title={'Uwaga'}
                        text={` Czy na pewno chcesz usunąć ofertę ${Name}?
                     `}
                        onClick={(e) => deleteProduct(e, Id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10.9"
                          height="12"
                          viewBox="0 0 10.9 12"
                        >
                          <g
                            id="Icon_feather-trash"
                            data-name="Icon feather-trash"
                            transform="translate(0.5 0.5)"
                          >
                            <path
                              id="Path_3663"
                              data-name="Path 3663"
                              d="M4.5,9h9.9"
                              transform="translate(-4.5 -6.8)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                            <path
                              id="Path_3664"
                              data-name="Path 3664"
                              d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                              transform="translate(-6.4 -3)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1"
                            />
                          </g>
                        </svg>
                      </PopupButton>
                    </DeleteTagButton>
                  )}
                </SingleProductWrapper>
              );
            })}
          </Grid>
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        <MenuMain
          isMenuOpen={isMobileFilterMenuOpen}
          ref={mobileFilterMenuWrapperRef}
        >
          <CloseIsLoginPageOpenWrapper>
            Filtrowanie
            <CloseIsLoginPageOpen
              onClick={() => {
                handleIsMobileFilterMenuOpenChange(false);
                setIsBodyOverflowBlocked(false);
                handleIsSortMenuOpenChange(false);
                handleIsFilterMenuOpenChange(false);
                mobileFilterMenuWrapperRef.current.style.transition =
                  'all 0.35s ease-out';
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.625"
                height="16.625"
                viewBox="0 0 16.625 16.625"
              >
                <path
                  id="Path_3807"
                  data-name="Path 3807"
                  d="M27.313,24.987l-2.326,2.326L19,21.326l-5.987,5.987-2.326-2.326L16.674,19l-5.987-5.987,2.326-2.326L19,16.674l5.987-5.987,2.326,2.326L21.326,19Z"
                  transform="translate(-10.688 -10.688)"
                  fill="#07020f"
                />
              </svg>
            </CloseIsLoginPageOpen>
          </CloseIsLoginPageOpenWrapper>
          <MenuWrapper>
            {isSortMenuOpen && (
              <>
                <MobileSelectFilterWrapper value={sortBy}>
                  <select onChange={handleSortByChange} value={sortBy}>
                    <option hidden value={''}>
                      Wybierz
                    </option>
                    <option value={SortBy.DATE_ASCENDING}>
                      Od najstarszego
                    </option>
                    <option value={SortBy.DATE_DESCENDING}>
                      Od najnowszego
                    </option>
                    <option value={SortBy.NAME_ASCENDING}>Nazwa A-Z</option>
                    <option value={SortBy.NAME_DESCENDING}>Nazwa Z-A</option>
                    <option value={SortBy.POPULARITY}>Popularność</option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
              </>
            )}
            {isFilterMenuOpen && (
              <>
                <MobileSelectFilterWrapper value={productType}>
                  <select
                    value={productType}
                    onChange={handleProductTypeChange}
                  >
                    <option hidden value={''}>
                      Typ produktu
                    </option>
                    <option value={''}>Wszystko</option>
                    {ProductTypeEnum.map((productType) => {
                      const {FieldName, FlagValue} = productType;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {getProductTypePolishName(FlagValue)}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileSelectFilterWrapper value={primaryCategory}>
                  <select
                    value={primaryCategory}
                    onChange={handlePrimaryCategoryChange}
                  >
                    <option hidden value={''}>
                      Kategoria
                    </option>
                    <option value={''}>Wszystko</option>
                    {productCategories
                      .filter((category) => category.ParentCategoryId === null)
                      .map((category) => {
                        const {Name, Id} = category;

                        return (
                          <option value={Id} key={Id}>
                            {Name}
                          </option>
                        );
                      })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileSelectFilterWrapper value={secondaryCategory}>
                  <select
                    value={secondaryCategory}
                    onChange={handleSecondaryCategoryChange}
                  >
                    <option hidden value={''}>
                      Podkategoria
                    </option>
                    <option value={''}>Wszystko</option>
                    {secondaryCategories
                      .filter(
                        (category) =>
                          category.ParentCategoryId === primaryCategory,
                      )
                      .map((category) => {
                        const {Name, Id} = category;

                        return (
                          <option value={Id} key={Id}>
                            {Name}
                          </option>
                        );
                      })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileSelectFilterWrapper value={tertiaryCategory}>
                  <select
                    value={tertiaryCategory}
                    onChange={handleTertiaryCategoryChange}
                  >
                    <option hidden value={''}>
                      Podkategoria
                    </option>
                    <option value={''}>Wszystko</option>
                    {tertiaryCategories
                      .filter(
                        (category) =>
                          category.ParentCategoryId === secondaryCategory,
                      )
                      .map((category) => {
                        const {Name, Id} = category;

                        return (
                          <option value={Id} key={Id}>
                            {Name}
                          </option>
                        );
                      })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileSelectFilterWrapper value={productStatus}>
                  <select
                    value={productStatus}
                    onChange={handleProductStatusChange}
                  >
                    <option hidden value={''}>
                      Status
                    </option>
                    <option value={''}>Wszystko</option>
                    {productStatusesMocked.map((productStatus) => {
                      const {FieldName, FlagValue} = productStatus;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {FieldName}
                        </option>
                      );
                    })}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="7.609"
                    height="13"
                    viewBox="0 0 7.609 13"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(-21.391 -14) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M6.048,12.735.185,6.941a.618.618,0,0,1,0-.882L6.048.265a.923.923,0,0,1,1.294,0,.9.9,0,0,1,0,1.278L2.327,6.5l5.015,4.956a.9.9,0,0,1,0,1.279.923.923,0,0,1-1.294,0"
                        transform="translate(-29 -27)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </MobileSelectFilterWrapper>
                <MobileInputFilterWrapper>
                  <p>Cena&nbsp;od</p>
                  <input
                    placeholder="0zł"
                    type="text"
                    value={minGrossValue}
                    onChange={handleMinGrossValueChange}
                  />
                </MobileInputFilterWrapper>
                <MobileInputFilterWrapper>
                  <p>Cena&nbsp;do</p>
                  <input
                    placeholder="0zł"
                    type="text"
                    value={maxGrossValue}
                    onChange={handleMaxGrossValueChange}
                  />
                </MobileInputFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Tylko Win-Win</p>
                  <MobileCheckbox
                    isActive={isWinwin}
                    onClick={() => handleIsWinwinChange(!isWinwin)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Tylko obserwowane oferty</p>
                  <MobileCheckbox
                    isActive={isFavourite}
                    onClick={() => handleIsFavouriteChange(!isFavourite)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
                <MobileCheckboxFilterWrapper>
                  <p>Wyklucz nieaktywne</p>
                  <MobileCheckbox
                    isActive={hasPublishedActiveOffer}
                    onClick={() =>
                      handleHasPublishedActiveOfferChange(
                        !hasPublishedActiveOffer,
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22.167"
                      height="22.167"
                      viewBox="0 0 22.167 22.167"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M22.729,11.646A11.083,11.083,0,1,1,11.646.563,11.083,11.083,0,0,1,22.729,11.646ZM10.364,17.514l8.223-8.223a.715.715,0,0,0,0-1.011L17.576,7.269a.715.715,0,0,0-1.011,0L9.858,13.975,6.727,10.844a.715.715,0,0,0-1.011,0L4.7,11.855a.715.715,0,0,0,0,1.011l4.648,4.648a.715.715,0,0,0,1.011,0Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#06f"
                      />
                    </svg>
                  </MobileCheckbox>
                </MobileCheckboxFilterWrapper>
              </>
            )}
          </MenuWrapper>
        </MenuMain>
        <MobileMain>
          <MobileFilterWrapper>
            {countTruthyVariables(
              searchString,
              primaryCategory,
              secondaryCategory,
              tertiaryCategory,
              minGrossValue,
              maxGrossValue,
              productType,
              productStatus,
              isWinwin,
              isFavourite,
              hasPublishedActiveOffer,
            ) > 0 && (
              <CountFilterDisplay style={{top: '118px', right: '20px'}}>
                {countTruthyVariables(
                  searchString,
                  primaryCategory,
                  secondaryCategory,
                  tertiaryCategory,
                  minGrossValue,
                  maxGrossValue,
                  productType,
                  productStatus,
                  isWinwin,
                  isFavourite,
                  hasPublishedActiveOffer,
                )}
              </CountFilterDisplay>
            )}
            <svg
              onClick={() => {
                setIsBodyOverflowBlocked(true);
                setIsMobileSearchBarOpen(false);
                handleIsSortMenuOpenChange(true);
                handleIsFilterMenuOpenChange(false);
                handleIsMobileFilterMenuOpenChange(!isMobileFilterMenuOpen);
                if (!isMobileFilterMenuOpen) {
                  setTimeout(() => {
                    mobileFilterMenuWrapperRef.current.style.transition =
                      'none';
                  }, 350);
                }
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="23.197"
              height="17.036"
              viewBox="0 0 23.197 17.036"
            >
              <g
                id="Group_1924"
                data-name="Group 1924"
                transform="translate(-255.98 -356.674)"
                opacity="0.86"
              >
                <g
                  id="Group_1923"
                  data-name="Group 1923"
                  transform="translate(255.98 356.674)"
                >
                  <path
                    id="Path_1649"
                    data-name="Path 1649"
                    d="M258.494,352.337a1.071,1.071,0,0,0-1.437,0l-3.981,3.585a1.076,1.076,0,1,0,1.439,1.6l2.185-1.968v11.931a1.075,1.075,0,0,0,2.151,0V355.553l2.185,1.968a1.076,1.076,0,1,0,1.439-1.6Z"
                    transform="translate(-252.719 -352.06)"
                  />
                  <path
                    id="Path_1650"
                    data-name="Path 1650"
                    d="M269.854,363.478a1.073,1.073,0,0,0-1.518-.079l-2.187,1.97V353.436a1.075,1.075,0,1,0-2.149,0v11.931l-2.185-1.968a1.076,1.076,0,1,0-1.439,1.6l3.981,3.585a1.076,1.076,0,0,0,1.439,0L269.775,365A1.075,1.075,0,0,0,269.854,363.478Z"
                    transform="translate(-246.934 -351.822)"
                  />
                </g>
              </g>
            </svg>
            <MobileSearchInputWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.157"
                height="15"
                viewBox="0 0 15.157 15"
              >
                <g id="Group_1310" data-name="Group 1310" opacity="0.86">
                  <path
                    id="Path_1507"
                    data-name="Path 1507"
                    d="M2602.818,548.518l-3.771-3.436a6.235,6.235,0,0,0-.48-8.26h0a6.231,6.231,0,1,0-.752,9.442l3.856,3.514a.853.853,0,1,0,1.149-1.261Zm-12.1-3.843a4.879,4.879,0,1,1,3.449,1.429A4.884,4.884,0,0,1,2590.714,544.675Z"
                    transform="translate(-2587.94 -535.001)"
                  />
                </g>
              </svg>
              <input
                type="text"
                placeholder="Wyszukaj..."
                value={searchString}
                onChange={handleSearchStringChange}
              />
            </MobileSearchInputWrapper>
            <svg
              onClick={() => {
                setIsBodyOverflowBlocked(true);
                setIsMobileSearchBarOpen(false);
                handleIsSortMenuOpenChange(false);
                handleIsFilterMenuOpenChange(true);
                handleIsMobileFilterMenuOpenChange(!isMobileFilterMenuOpen);
                if (!isMobileFilterMenuOpen) {
                  setTimeout(() => {
                    mobileFilterMenuWrapperRef.current.style.transition =
                      'none';
                  }, 350);
                }
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="19.2"
              height="18.021"
              viewBox="0 0 19.2 18.021"
            >
              <g
                id="Group_1925"
                data-name="Group 1925"
                transform="translate(-155.861 -459.378)"
                opacity="0.86"
              >
                <path
                  id="Path_1651"
                  data-name="Path 1651"
                  d="M173.319,462.311H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 0.126)"
                />
                <path
                  id="Path_1652"
                  data-name="Path 1652"
                  d="M173.319,468.486H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 0.886)"
                />
                <path
                  id="Path_1653"
                  data-name="Path 1653"
                  d="M173.319,474.366H163.211a.955.955,0,0,1,0-1.909h10.107a.955.955,0,1,1,0,1.909Z"
                  transform="translate(0.787 1.609)"
                />
                <circle
                  id="Ellipse_11"
                  data-name="Ellipse 11"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 472.907)"
                />
                <circle
                  id="Ellipse_12"
                  data-name="Ellipse 12"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 466.156)"
                />
                <circle
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="2.246"
                  cy="2.246"
                  r="2.246"
                  transform="translate(155.861 459.378)"
                />
              </g>
            </svg>
            {countTruthyVariables(
              searchString,
              primaryCategory,
              secondaryCategory,
              tertiaryCategory,
              minGrossValue,
              maxGrossValue,
              productType,
              productStatus,
              isWinwin,
              isFavourite,
              hasPublishedActiveOffer,
            ) > 0 && (
              <ClearFiltersButton onClick={() => clearFilters()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.9"
                  height="12"
                  viewBox="0 0 10.9 12"
                >
                  <g
                    id="Icon_feather-trash"
                    data-name="Icon feather-trash"
                    transform="translate(0.5 0.5)"
                  >
                    <path
                      id="Path_3663"
                      data-name="Path 3663"
                      d="M4.5,9h9.9"
                      transform="translate(-4.5 -6.8)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                    <path
                      id="Path_3664"
                      data-name="Path 3664"
                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                      transform="translate(-6.4 -3)"
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </ClearFiltersButton>
            )}
          </MobileFilterWrapper>
          <MobileAddButtonWrapper onClick={() => navigate('/myproducts/add')}>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="33.371"
                height="33.066"
                viewBox="0 0 33.371 33.066"
              >
                <path
                  id="Path_1660"
                  data-name="Path 1660"
                  d="M203.6,115.33l-13.791.3.241-13.676a1.5,1.5,0,1,0-2.99,0l.24,13.676-13.79-.3a1.5,1.5,0,1,0,0,2.99l13.79-.3-.24,13.675a1.5,1.5,0,1,0,2.99,0l-.241-13.675,13.791.3a1.5,1.5,0,1,0,0-2.99Z"
                  transform="translate(-171.869 -100.292)"
                  fill="#0091ff"
                />
              </svg>
            </div>
            <p>Dodaj nowy produkt</p>
          </MobileAddButtonWrapper>
          {isLoading && <LoadingCircle />}
          {products.map((product) => {
            const {
              Categories,
              Id,
              Name,
              ProductStatus,
              ProductType,
              CoverLink,
              OfferEndDate,
              ProductDisplayStatus,
              OfferStartDate,
              HasOffer,
              ViewInfo,
            } = product;

            const productTypeValue = getProductTypePolishName(
              ProductTypeEnum.filter(
                (productType) => productType.FlagValue === ProductType,
              )[0]?.FlagValue,
            );

            const productStatusValue = ProductStatusEnum.filter(
              (productStatus) => productStatus.FlagValue === ProductStatus,
            )[0]?.FieldName;

            const firstCategory = Categories.filter(
              (category) => category.Position === 1,
            );
            const firstCategoryValue =
              firstCategory.length > 0 ? firstCategory[0].Name : '-';

            const secondCategory = Categories.filter(
              (category) => category.Position === 2,
            );
            const secondCategoryValue =
              secondCategory.length > 0 ? secondCategory[0].Name : '-';

            const tertiaryCategory = Categories.filter(
              (category) => category.Position === 3,
            );
            const tertiaryCategoryValue =
              tertiaryCategory.length > 0 ? tertiaryCategory[0].Name : '-';

            if (productStatus !== '' && productStatus !== ProductDisplayStatus)
              return;
            return (
              <>
                <MobileSingleConditionWrapper
                  key={Id}
                  ref={mobileLastElementRef}
                  onClick={() => {
                    navigate(`/myproducts/product/${Id}`);
                  }}
                >
                  <img src={CoverLink ? CoverLink : noImage} alt="" />
                  <div>
                    <p>{productTypeValue}</p>
                    <h5>{Name}</h5>
                    <StyledStatusWrapper
                      ProductDisplayStatus={ProductDisplayStatus}
                    >
                      <h1>
                        {getProductDisplayStatusDescription(
                          ProductDisplayStatus,
                        )}
                      </h1>
                      <StyledStatusLine
                        ProductDisplayStatus={ProductDisplayStatus}
                      >
                        <div />
                      </StyledStatusLine>
                    </StyledStatusWrapper>
                    {(ProductStatus === 0 ||
                      (ProductDisplayStatus !== 3 &&
                        ProductDisplayStatus !== 4)) && (
                      <button>
                        <PopupButton
                          buttons={['Anuluj', 'Usuń']}
                          title={'Uwaga'}
                          text={` Czy na pewno chcesz usunąć ofertę ${Name}?
                     `}
                          onClick={(e) => deleteProduct(e, Id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13.2"
                            height="14.5"
                            viewBox="0 0 13.2 14.5"
                          >
                            <g
                              id="Icon_feather-trash"
                              data-name="Icon feather-trash"
                              transform="translate(0.75 0.75)"
                            >
                              <path
                                id="Path_3663"
                                data-name="Path 3663"
                                d="M4.5,9H16.2"
                                transform="translate(-4.5 -6.4)"
                                fill="none"
                                stroke="#ff0300"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_3664"
                                data-name="Path 3664"
                                d="M16.6,5.6v9.1A1.3,1.3,0,0,1,15.3,16H8.8a1.3,1.3,0,0,1-1.3-1.3V5.6m1.95,0V4.3A1.3,1.3,0,0,1,10.75,3h2.6a1.3,1.3,0,0,1,1.3,1.3V5.6"
                                transform="translate(-6.2 -3)"
                                fill="none"
                                stroke="#ff0300"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </PopupButton>
                      </button>
                    )}
                  </div>
                </MobileSingleConditionWrapper>
              </>
            );
          })}
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};

export default Products;
