import React, {createContext, useContext, useState} from 'react';

interface IsBodyOverflowBlockedType {
  isBodyOverflowBlocked: boolean;
  setIsBodyOverflowBlocked: React.Dispatch<React.SetStateAction<boolean>>;
}

const IsBodyOverflowBlockedContext = createContext<
  IsBodyOverflowBlockedType | undefined
>(undefined);

export const useIsBodyOverflowBlockedContext = () => {
  const context = useContext(IsBodyOverflowBlockedContext);
  if (!context) {
    throw new Error(
      'useIsBodyOverflowBlockedContext must be used within a IsBodyOverflowBlockedProvider',
    );
  }
  return context;
};

export const IsBodyOverflowBlockedProvider: React.FC = ({children}) => {
  const [isBodyOverflowBlocked, setIsBodyOverflowBlocked] = useState(false);

  return (
    <IsBodyOverflowBlockedContext.Provider
      value={{isBodyOverflowBlocked, setIsBodyOverflowBlocked}}
    >
      {children}
    </IsBodyOverflowBlockedContext.Provider>
  );
};
