import React, {FC} from 'react';
import styled from 'styled-components';
import {hexToRgb} from '../..';
import {Block} from '../../interface';

interface Props {
  isEditing: boolean;
  block: Block;
}

const Wrapper = styled.div<{
  bgColor: string;
  backgroundAlpha: number;
}>`
  width: 100%;
  height: 100%;
  min-height: 34px;
  background: ${({bgColor, backgroundAlpha}) =>
    `rgb(${bgColor},${backgroundAlpha})`};
`;

export const EmptyBlockComponent: React.FC<Props> = ({isEditing, block}) => {
  return (
    <Wrapper
      bgColor={hexToRgb(block.BackgroundColorHex)}
      backgroundAlpha={block.BlockOpacityIn0To100Ratio}
    ></Wrapper>
  );
};
