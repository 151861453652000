import styled, {keyframes} from 'styled-components';

export const TrackOneColumnWrapper = styled.div<{
  isSelected: boolean;
  isMobile: boolean;
}>`
  height: 110px;
  min-height: 110px;
  width: ${({isMobile}) => (isMobile ? '100%' : '296px')};
  min-width: ${({isMobile}) => (isMobile ? '100%' : '296px')};
  //background: #ffffff;
  //position: absolute;
  display: flex;
  /* border: solid 1px rgba(112, 112, 112, 0.3); */
  border: ${({theme, isSelected}) =>
    isSelected
      ? `solid 1px ${theme.colors.blueTheme}`
      : `solid 1px rgba(112, 112, 112, 0.3)`};
  background: ${({theme, isSelected}) =>
    isSelected
      ? theme.colors.singleArticleHoverBackground
      : theme.colors.popularCategoryBackground};
  border-radius: 24px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;

  & > input {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0.3;
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
  }

  & > input::-webkit-slider-thumb {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: ew-resize;
    background: #434343;
  }
  @media (max-width: 1024px) {
    width: 100%;
    min-width: 100%;
  }
  @media (max-width: 360px) {
    height: 100px;
    min-height: 100px;
  }
  @media (max-width: 330px) {
    height: 95px;
    min-height: 95px;
  }
  @media (max-width: 320px) {
    height: 90px;
    min-height: 90px;
  }
`;

export const TrackOneColumnImgWrapper = styled.div`
  width: 102px;
  height: 102px;
  border-radius: 21px;
  margin-left: 4px;
  align-self: center;
  position: relative;
  /* border: 1px solid rgba(112, 112, 112, 0.3); */

  & > img {
    //border-radius: 7px;
    height: 100%;
    width: 100%;
    border-radius: 21px;
  }
  & > svg {
    top: 50%;
    left: 245%;
    width: 48px;
    height: 48px;
    transform: translate(-50%, -50%);

    border-radius: 50%;
    position: absolute;
    z-index: 90;
    cursor: pointer;
  }
  @media (max-width: 360px) {
    width: 97px;
    height: 97px;
    margin-left: 1px;
  }
  @media (max-width: 330px) {
    width: 92px;
    height: 92px;
  }
  @media (max-width: 320px) {
    width: 87px;
    height: 87px;
  }
`;

export const TrackOneColumnSvgContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const TrackOneColumnTextContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  padding: 19px 20px 19px 13px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  //opacity: 0.5;
`;

export const TrackOneColumnNameTrack = styled.h2`
  font-size: 11px;
  line-height: 14px;
  height: 30px;
  overflow: hidden;
  font-weight: 400;
  width: 100px;
  letter-spacing: -0.25px;

  //width: 122px;
  //overflow: hidden;
`;

export const TrackOneColumnCategoryWrapper = styled.div`
  display: flex;
  width: 100%;
  line-height: 22px;
  padding-left: 7px;
  //justify-content: center;
  //padding-bottom: 25px;
  & > p {
    font-size: 11px;
    letter-spacing: -0.25px;
    padding: 2px;
    opacity: 0.5;
  }
`;

export const TrackOneColumnProductType = styled.div`
  font-size: 9px;
  letter-spacing: -0.25px;
  color: #17181b;
`;
export const TrackOneColumnFooterWrapper = styled.div`
  position: absolute;
  height: 23px;
  //border-top: 1px solid rgba(0, 0, 0, 0.15);
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  & > p {
    font-size: 9px;
    line-height: 12px;
    text-align: center;
    color: #5271ff;
  }
`;
export const TrackOneColumnPriceWrapper = styled.div`
  //padding-right: 5px;
  //padding-left: 7px;
  height: 17px;
  width: 100%;
  & > p {
    font-size: 13px;
    //line-height: 32px;
    letter-spacing: -0.35px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-weight: bold;
  }
`;

export const TrackOneColumnWinWin = styled.div`
  display: flex;
  height: 15px;

  & > p {
    font-size: 9px;
    //line-height: 24px;
    letter-spacing: 0.15px;
    color: #fb9614;
  }
`;

export const DeleteTrackButton = styled.button<{fontColor: string}>`
  position: absolute;
  bottom: -5px;
  right: 0;
  background-color: transparent;
  padding: 5px;
  border: none;
  color: ${({fontColor}) => `rgb(${fontColor})`};
  cursor: pointer;
`;

export const WinWinAndPriceWrapper = styled.div`
  width: 100%;
  display: flex;
  //justify-content: flex-end;
  /* align-items: center; */
  flex-direction: column;
  gap: 2px;
`;

const ldsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LdsRing = styled.div`
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
`;

export const LdsRingDiv = styled.div<{color: string}>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: ${({color}) => `8px solid ${color}`};
  border-radius: 50%;
  animation: ${ldsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({color}) => ` ${color} transparent transparent transparent`};

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
