import React, {createContext, useState} from 'react';

type Context = [
  {BackgroundColor: string; FontColor: string; TitleColor: string},
  React.Dispatch<
    React.SetStateAction<{
      BackgroundColor: string;
      FontColor: string;
      TitleColor: string;
    }>
  >,
];

interface Props {
  children: React.ReactNode;
}

export const BusinessCardColorsContext = createContext<Context>(null!);

const BusinessCardColorsProvider: React.FC<Props> = ({children}) => {
  const [businessCardColors, setBusinessCardColors] = useState<{
    BackgroundColor: string;
    FontColor: string;
    TitleColor: string;
  }>({
    BackgroundColor: '17161D',
    FontColor: 'F8F8F8',
    TitleColor: '54BAFC',
  });

  return (
    <BusinessCardColorsContext.Provider
      value={[businessCardColors, setBusinessCardColors]}
    >
      {children}
    </BusinessCardColorsContext.Provider>
  );
};

export default BusinessCardColorsProvider;
