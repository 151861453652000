import styled, {keyframes} from 'styled-components';

export const CommentOneWrapper = styled.div<{
  isWritingMode;
  isLogged;
  isMobile: boolean;
}>`
  width: ${({isMobile}) => (isMobile ? '100%' : '296px')};

  min-width: 296px;
  height: ${({isMobile}) => (isMobile ? '135px' : '240px')};

  border-radius: 24px;
  /* border: 1px solid ${({theme}) => theme.colors.fontBlackOp15}; */
  padding: 15px;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.12);
  background: ${({isLogged, isWritingMode}) =>
    isWritingMode && !isLogged ? 'rgba(112,112,112,0.07)' : 'white'};
  @media (max-width: 1024px) {
    height: 140px;
    width: 100%;
  }
  @media (max-width: 400px) {
    height: 135px;
  }
  @media (max-width: 390px) {
    height: 130px;
  }
  @media (max-width: 375px) {
    padding: 10px;
    height: 125px;
  }
  @media (max-width: 360px) {
    height: 115px;
  }
  @media (max-width: 340px) {
    height: 110px;
  }
  @media (max-width: 330px) {
    height: 105px;
  }
`;

export const CommentOneHeader = styled.div<{isNewComment: boolean}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > p {
    font-size: 13px;
    line-height: 13px;
    letter-spacing: -0.35px;
    font-weight: bold;
    color: ${({theme, isNewComment}) =>
      isNewComment ? theme.colors.blueTheme : theme.colors.fontBlackOp100};
  }
`;

export const CommentOneHeaderSvgWrapper = styled.div`
  & > svg {
    width: 10px;
    height: 10px;
  }
`;

export const CommentText = styled.div<{
  isNewComment: boolean;
  isMobile: boolean;
}>`
  margin-top: ${({isMobile}) => (isMobile ? '5px' : '15px')};
  /* height: 155px; */
  height: ${({isMobile}) => (isMobile ? 'auto' : '155px')};
  width: 100%;
  overflow: hidden;
  & > p {
    font-size: ${({isMobile}) => (isMobile ? '12px' : '13px')};
    width: 100%;
    line-height: ${({isMobile}) => (isMobile ? '15px' : '21px')};
    letter-spacing: -0.35px;
  }
  & > textarea {
    font-size: ${({isMobile}) => (isMobile ? '12px' : '13px')};
    line-height: ${({isMobile}) => (isMobile ? '15px' : '21px')};
    letter-spacing: -0.35px;
    border: none;
    outline: none;
    background: transparent;
    overflow: hidden;
    resize: none;
    width: 100%;
  }

  @media (max-width: 1024px) {
    height: auto;
    margin-top: 5px;

    & > p {
      font-size: 12px;

      line-height: 15px;
    }
    & > textarea {
      font-size: 12px;
      line-height: 15px;
    }
  }
  @media (max-width: 390px) {
    & > p {
      line-height: 14px;
    }
    & > textarea {
      line-height: 14px;
    }
  }
  @media (max-width: 375px) {
    & > p {
      font-size: 11px;

      line-height: 13px;
    }
    & > textarea {
      font-size: 11px;
      line-height: 13px;
    }
  }
  @media (max-width: 360px) {
    & > p {
      font-size: 10px;

      line-height: 12px;
    }
    & > textarea {
      font-size: 10px;
      line-height: 12px;
    }
  }
  @media (max-width: 330px) {
    margin-top: 2px;
  }
`;

export const CommentBtn = styled.p`
  width: 100%;
  font-size: 11px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: ${({theme}) => theme.colors.blueTheme};
  text-align: right;
  cursor: pointer;
`;

export const InfoTextWrapper = styled.div`
  width: 296px;
  min-width: 296px;
  height: 240px;
  border-radius: 24px;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  padding: 15px;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.12);
  background: rgba(112, 112, 112, 0.07);
  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    font-size: 13px;
    width: 230px;
    text-align: center;
    font-weight: bold;
    line-height: 21px;
    letter-spacing: -0.35px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
`;
