import {hexToRgb} from '../../../index';
import React, {useEffect, useState} from 'react';
import {Block} from '../../../interface';
import {Offer} from '../../RecomendedProductionBlock';
import {useCookies} from 'react-cookie';
import api from '../../../../../../services/axiosConfig';
import {useNavigate} from 'react-router-dom';

import deleteBlack from '../../../../../../images/svg/delete-black.svg';
import soundtrackMedium from '../../../../../../images/svg/soundtrack-medium.svg';
import soundtrackSmall from '../../../../../../images/svg/soundtrack-small.svg';
import {
  DeleteTrackButton,
  TrackOneColumnCategoryWrapper,
  TrackOneColumnFooterWrapper,
  TrackOneColumnImgWrapper,
  TrackOneColumnNameTrack,
  TrackOneColumnPriceWrapper,
  TrackOneColumnProductType,
  TrackOneColumnSvgContent,
  TrackOneColumnTextContent,
  TrackOneColumnWinWin,
  TrackOneColumnWrapper,
  WinWinAndPriceWrapper,
  LdsRingDiv,
  LdsRing,
} from './styles';

import {GradientForImg} from '../../TrackBlock/TrackBlockRowView/styles';

interface Props {
  theme: string;
  block: Block;
  mobileOverview: boolean;
  isEditing: boolean;
  offer: Offer;
  isMainBlock?: boolean;
  changeSelectedTrack: (track) => void;
  isSelected: boolean;
}

export const RecomendedTrackOneComponent: React.FC<Props> = ({
  theme,
  block,
  mobileOverview,
  isEditing,
  offer,
  isMainBlock,
  changeSelectedTrack,
  isSelected,
}) => {
  const {WebArea, FontColorHex, BackgroundColorHex} = block;
  const {Width, Height} = WebArea;

  const navigate = useNavigate();

  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isAudioPlayed, setIsAudioPlayed] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elapsed, setElapsed] = useState(0);
  const [duration, setDuration] = useState(0);

  // console.log('offert w RecomdnedTrack ONE:', offer);

  const pauseAudio = (
    e: React.MouseEvent<SVGSVGElement>,
    audio: HTMLAudioElement,
  ) => {
    e.stopPropagation();
    audio.pause();
    setIsAudioPlayed(false);
  };

  const playAudio = (
    e: React.MouseEvent<SVGSVGElement>,
    audio: HTMLAudioElement,
  ) => {
    e.stopPropagation();
    // console.log('loading');
    setIsLoading(true);
    const playPromise = audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // console.log('gram');
          setIsLoading(false);
          // console.log('moge tu pauze dac');
        })
        .catch((error) => {
          console.log('pauza w error???');
        });
    }
    // console.log(audio.src);
    setIsAudioPlayed(true);
  };

  useEffect(() => {
    if (isAudioPlayed) {
      setInterval(() => {
        const _duration = Math.floor(audio.duration);
        const _elapsed = Math.floor(audio.currentTime);

        setDuration(_duration);
        setElapsed(_elapsed);
      }, 100);
    }
  }, [isAudioPlayed]);

  useEffect(() => {
    if (elapsed === duration && isAudioPlayed && elapsed > 10) {
      audio.currentTime = 0;
      audio.pause();
      setIsAudioPlayed(false);
    }
  }, [elapsed]);

  useEffect(() => {
    if (offer.ProductDemoFile === null) return;
    if (offer.ProductDemoFile) {
      setAudio(new Audio(offer.ProductDemoFile));
    }
  }, [offer.ProductDemoFile]);

  // useEffect(() => {
  //   if (startIndex !== null) {
  //     audio.pause();
  //     setIsAudioPlayed(false);
  //   }
  //   setStartIndex(trackIndex);
  // }, [trackIndex]);

  const toTrackInfo = (id: string) => {
    if (!isEditing) {
      navigate(`/tracks/main/${id}`);
    }
  };

  const {
    Id,
    Name,
    IsWinWin,
    Description,
    GrossValue,
    Currency,
    CoverFile,
    Category,
    SecondCategoryId,
    ThirdCategoryId,
  } = offer;
  const rgbBg = hexToRgb(block.TitleColorHex);

  return (
    <TrackOneColumnWrapper
      isMobile={mobileOverview}
      isSelected={isSelected}
      onClick={
        isEditing
          ? () => {
              changeSelectedTrack(offer);
            }
          : null
      }
      style={
        isEditing
          ? {background: `rgb(${rgbBg})`}
          : {background: `rgb(${rgbBg})`}
      }
      // style={{top: position.top, left: position.left, zIndex: position.index}}
    >
      <TrackOneColumnImgWrapper>
        <GradientForImg
        // style={{
        //   background: `linear-gradient(90deg, rgb(${hexToRgb(
        //     BackgroundColorHex,
        //   )},0.15) 40%,rgb(${hexToRgb(BackgroundColorHex)}) 100%`,
        // }}
        ></GradientForImg>
        <img src={offer.CoverFile} alt="CoverImg" />
        {offer.ProductDemoFile === null ? null : isLoading ? (
          <LdsRing>
            <LdsRingDiv color={`rgb(${hexToRgb(FontColorHex)})`}></LdsRingDiv>
            <LdsRingDiv color={`rgb(${hexToRgb(FontColorHex)})`}></LdsRingDiv>
            <LdsRingDiv color={`rgb(${hexToRgb(FontColorHex)})`}></LdsRingDiv>
          </LdsRing>
        ) : !isAudioPlayed ? (
          <svg
            onClick={(e) => playAudio(e, audio)}
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <g
              id="Group_7718"
              data-name="Group 7718"
              transform="translate(-1108 -540)"
            >
              <g
                id="Group_6047"
                data-name="Group 6047"
                transform="translate(1108 540)"
              >
                <g
                  id="Rectangle_3282"
                  data-name="Rectangle 3282"
                  transform="translate(0 0)"
                  fill="rgba(47,47,104,0.5)"
                  stroke="rgba(112,112,112,0.3)"
                  strokeWidth="1"
                >
                  <rect width="48" height="48" rx="24" stroke="none" />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="47"
                    height="47"
                    rx="23.5"
                    fill="none"
                  />
                </g>
                <g
                  id="Group_6009"
                  data-name="Group 6009"
                  transform="translate(4.114 4.114)"
                >
                  <g
                    id="Rectangle_3283"
                    data-name="Rectangle 3283"
                    fill="#f8f8f8"
                    stroke="rgba(112,112,112,0.3)"
                    strokeWidth="1"
                  >
                    <rect
                      width="39.771"
                      height="39.771"
                      rx="19.886"
                      stroke="none"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="38.771"
                      height="38.771"
                      rx="19.386"
                      fill="none"
                    />
                  </g>
                  <path
                    id="Icon_material-play-circle-filled"
                    data-name="Icon material-play-circle-filled"
                    d="M22.429,3A19.429,19.429,0,1,0,41.857,22.429,19.436,19.436,0,0,0,22.429,3ZM18.543,31.171V13.686L30.2,22.429Z"
                    transform="translate(-2.543 -2.543)"
                    fill="#0091ff"
                  />
                </g>
              </g>
            </g>
          </svg>
        ) : (
          <svg
            onClick={(e) => pauseAudio(e, audio)}
            id="Group_4876"
            data-name="Group 4876"
            xmlns="http://www.w3.org/2000/svg"
            width="60.04"
            height="60.04"
            viewBox="0 0 89.04 89.04"
          >
            <path
              id="Path_1675"
              data-name="Path 1675"
              d="M1179.171,455.969a44.52,44.52,0,1,1,44.52-44.52A44.575,44.575,0,0,1,1179.171,455.969Zm0-79.146a34.627,34.627,0,1,0,34.626,34.627A34.668,34.668,0,0,0,1179.171,376.822Z"
              transform="translate(-1134.651 -366.929)"
              fill={`rgb(${hexToRgb(FontColorHex)})`}
            />
            <rect
              id="Rectangle_865"
              data-name="Rectangle 865"
              width="26.382"
              height="26.382"
              rx="1"
              transform="translate(31.333 31.327)"
              fill={`rgb(${hexToRgb(FontColorHex)})`}
            />
          </svg>
        )}
      </TrackOneColumnImgWrapper>
      <TrackOneColumnSvgContent>
        {/*<img src={soundtrackSmall} alt="Soundtrack" />*/}
        <TrackOneColumnTextContent>
          {/* <TrackOneColumnCategoryWrapper>
            <p style={{color: `rgb(${hexToRgb(FontColorHex)})`}}>
              {Category === null ? '' : Category.Name} ·{' '}
            </p>
            <p style={{color: `rgb(${hexToRgb(FontColorHex)})`}}>
              {SecondCategoryId === null ? '' : SecondCategoryId.Name}
              {ThirdCategoryId === null ? '' : '·'}
            </p>
            <p style={{color: `rgb(${hexToRgb(FontColorHex)})`}}>
              {ThirdCategoryId === null ? '' : ThirdCategoryId.Name}
            </p>
          </TrackOneColumnCategoryWrapper> */}
          <WinWinAndPriceWrapper>
            {IsWinWin ? (
              <TrackOneColumnWinWin
                style={{borderColor: `rgb(${hexToRgb(FontColorHex)})`}}
              >
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="59.496"
                  height="15"
                  viewBox="0 0 59.496 15"
                >
                  {/* <defs>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_3780"
                        data-name="Rectangle 3780"
                        width="59.496"
                        height="15"
                        fill="#fb9614"
                      />
                    </clipPath>
                  </defs> */}
                  <g
                    id="Group_6972"
                    data-name="Group 6972"
                    clipPath="url(#clip-path)"
                  >
                    <path
                      id="Path_4111"
                      data-name="Path 4111"
                      d="M211,39.406l-.955,2.075c-.239.519-.486,1.071-.717,1.6h-.016c-.041-.511-.091-1.054-.148-1.573l-.231-2.1h-1.243l-.988,2.108c-.239.511-.494,1.071-.708,1.565h-.016c-.033-.494-.082-1.046-.132-1.557l-.206-2.117h-1.771l.749,5.963h1.754l.659-1.408c.239-.511.486-1.054.716-1.581h.017c.041.519.09,1.029.148,1.557l.156,1.433h1.787l2.866-5.963Z"
                      transform="translate(-180.493 -34.887)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4112"
                      data-name="Path 4112"
                      d="M274.371,39.406l-1.054,5.963h1.7l1.054-5.963Z"
                      transform="translate(-241.976 -34.888)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4113"
                      data-name="Path 4113"
                      d="M297.789,39.406l-.3,1.7c-.107.609-.214,1.26-.3,1.8l-.016.008c-.288-.552-.593-1.112-.9-1.664l-1.013-1.837h-1.581l-1.054,5.963h1.631l.3-1.7c.107-.609.214-1.26.3-1.8l.016-.008c.288.552.593,1.112.9,1.664l1.013,1.837h1.581l1.054-5.963Z"
                      transform="translate(-259.077 -34.887)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4114"
                      data-name="Path 4114"
                      d="M349.151,61.271h2.067l.222-1.252h-2.067Z"
                      transform="translate(-309.115 -53.137)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4115"
                      data-name="Path 4115"
                      d="M378.044,39.406l-.955,2.075c-.239.519-.486,1.071-.717,1.6h-.016c-.041-.511-.091-1.054-.148-1.573l-.231-2.1h-1.244l-.988,2.108c-.239.511-.494,1.071-.708,1.565h-.017c-.033-.494-.082-1.046-.132-1.557l-.206-2.117h-1.771l.749,5.963h1.754l.659-1.408c.239-.511.486-1.054.716-1.581h.017c.041.519.09,1.029.148,1.557l.157,1.433H376.9l2.866-5.963Z"
                      transform="translate(-328.38 -34.887)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4116"
                      data-name="Path 4116"
                      d="M441.411,39.406l-1.054,5.963h1.7l1.054-5.963Z"
                      transform="translate(-389.861 -34.888)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4117"
                      data-name="Path 4117"
                      d="M464.829,39.406l-.3,1.7c-.107.609-.214,1.26-.3,1.8l-.016.008c-.288-.552-.593-1.112-.9-1.664l-1.013-1.837h-1.581l-1.054,5.963H461.3l.3-1.7c.107-.609.214-1.26.3-1.8l.016-.008c.288.552.593,1.112.9,1.664l1.013,1.837h1.581l1.054-5.963Z"
                      transform="translate(-406.963 -34.887)"
                      fill="#fb9614"
                    />
                    <path
                      id="Path_4118"
                      data-name="Path 4118"
                      d="M17.734,2.336H16.572l-.881-.881H12.773l2.985,2.985.817-.041h1.159a.8.8,0,0,1,.8.8V9.79a.8.8,0,0,1-.8.8l-3.141,0L12.481,12.7a.8.8,0,0,1-1.135,0L8.1,9.46a.8.8,0,0,1-.184-.85l5.752-.183.289-.289a2.867,2.867,0,0,0,0-4.054L10.715.841a2.867,2.867,0,0,0-4.054,0L5.152,2.349l-2.286,0A2.87,2.87,0,0,0,0,5.214V9.8a2.87,2.87,0,0,0,2.867,2.867h.985l1.012,1.012H7.782L4.663,10.56,3.8,10.6h-.93a.8.8,0,0,1-.8-.8V5.214a.8.8,0,0,1,.8-.8l3.141,0L8.12,2.3a.8.8,0,0,1,1.135,0L12.5,5.543a.8.8,0,0,1,.184.85l-5.752.183-.289.289a2.867,2.867,0,0,0,0,4.054l3.243,3.243a2.87,2.87,0,0,0,4.054,0l1.508-1.508,2.286,0A2.87,2.87,0,0,0,20.6,9.79V5.2a2.87,2.87,0,0,0-2.867-2.867"
                      transform="translate(0 -0.001)"
                      fill="#fb9614"
                    />
                  </g>
                </svg>
              </TrackOneColumnWinWin>
            ) : (
              <TrackOneColumnWinWin
                style={{borderColor: `rgb(${hexToRgb(FontColorHex)})`}}
              ></TrackOneColumnWinWin>
            )}
            <TrackOneColumnPriceWrapper>
              {' '}
              <p style={{color: `rgb(${hexToRgb(FontColorHex)})`}}>{`od ${
                GrossValue / 100
              } ${Currency}`}</p>
            </TrackOneColumnPriceWrapper>
          </WinWinAndPriceWrapper>
          <TrackOneColumnNameTrack
            style={{color: `rgb(${hexToRgb(FontColorHex)})`}}
          >
            {Name}
          </TrackOneColumnNameTrack>
          {/*<TrackOneColumnFooterWrapper>*/}
          {/*  <p>Track</p>*/}
          {/*</TrackOneColumnFooterWrapper>*/}
        </TrackOneColumnTextContent>
      </TrackOneColumnSvgContent>
    </TrackOneColumnWrapper>
  );
};
