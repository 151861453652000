import React, {useCallback, useRef} from 'react';
import {DotButton, useDotButton} from './EmblaCarouselDotButton';
import useEmblaCarousel from 'embla-carousel-react';
import {useEffect, useState, useLayoutEffect} from 'react';
import {
  Embla,
  EmblaContainer,
  EmblaControls,
  EmblaSlide,
  EmblaViewPort,
} from './styles';
import api from 'services/axiosConfig';

const EmblaCarousel = (props) => {
  const {slides, options, activeItem, goTo} = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [activeIndex, setActiveIndex] = useState(activeItem);
  const [isLoaded, setIsLoaded] = useState(true);

  const activeItemRef = useRef(null);

  const {selectedIndex, scrollSnaps, onDotButtonClick} = useDotButton(
    emblaApi,
    activeItem,
  );
  const logSlidesInView = useCallback((emblaApi) => {
    emblaApi.scrollTo(activeItem);
  }, []);

  useEffect(() => {
    if (emblaApi) {
      logSlidesInView(emblaApi);
    }
  }, [emblaApi]);

  // ...

  return (
    <Embla>
      <EmblaViewPort ref={emblaRef}>
        <EmblaContainer>
          {slides.map((item, index) => (
            <EmblaSlide
              isActive={activeIndex === index}
              id={item.name}
              ref={index === activeIndex ? activeItemRef : null}
              onClick={() => {
                goTo(item.url, item.name);
                onDotButtonClick(index);
                setActiveIndex(index);
              }}
              key={index}
            >
              <p>{item.name}</p>
            </EmblaSlide>
          ))}
        </EmblaContainer>
      </EmblaViewPort>

      <EmblaControls>
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={'embla__dot'.concat(
                index === selectedIndex ? ' embla__dot--selected' : '',
              )}
            />
          ))}
        </div>
      </EmblaControls>
    </Embla>
  );
};

export default EmblaCarousel;
