import React, {useContext, useState} from 'react';
import {
  Background,
  CloseAdd,
  ListingItem,
  ListingWrapper,
  MainWrapper,
  SvgWrapper,
} from './styles';
import {OverviewBlock} from './OverviewBlock';
import {ResolutionContext} from 'contexts/ResolutionContext';

interface Role {
  FieldName: string;
  FlagValue: number;
}

interface Props {
  allowdBlock: Role[];
  addNewBlock: (type: number) => void;
  randomCords: {y: number; x: number};
  maxHeight: number;
  isOpenListing: boolean;
  toggleListing: (item: boolean) => void;
}

const translateBlockTypeName = (type: number) => {
  switch (type) {
    case 1:
      return 'O mnie - krótki';
    case 2:
      return 'O mnie - długi';
    case 4:
      return 'Galeria audio';
    case 8:
      return 'Galeria video';
    case 16:
      return 'Galeria obrazów';
    case 32:
      return 'Komentarze';
    case 64:
      return 'Blok podstawowy';
    case 128:
      return 'Baner';
    case 256:
      return 'Polecane produkty';
    case 512:
      return 'Czysty';
    case 1024:
      return 'Polecany track';
    case 2048:
      return 'Polecane tracki';
    case 4096:
      return 'Social media';
    case 8192:
      return 'Tytuł';
    case 16384:
      return 'Nagłówek';
    case 32768:
      return 'Polecany produkt';
    case 65536:
      return 'YouTube';
    case 131072:
      return 'Duży nagłówek';
    case 262144:
      return 'Zdjęcie z tekstem';
    case 524288:
      return 'Spotify playlista';
  }
};

export const AddBlock: React.FC<Props> = ({
  allowdBlock,
  addNewBlock,
  randomCords,
  maxHeight,
  isOpenListing,
  toggleListing,
}) => {
  const [iconsType, setIconsType] = useState<number>(0);
  const {windowWidth} = useContext(ResolutionContext);

  const y =
    randomCords.y > maxHeight ? (maxHeight - 1) * 113 : randomCords.y * 113;
  const x = randomCords.x * 113;

  return (
    <MainWrapper style={isOpenListing ? {display: 'flex'} : {display: 'none'}}>
      {isOpenListing ? (
        <CloseAdd
          onClick={() => {
            toggleListing(false);
            setIconsType(0);
          }}
        ></CloseAdd>
      ) : null}
      <Background style={{opacity: 0}}></Background>
      <SvgWrapper style={{opacity: 0}} onClick={() => toggleListing(true)}>
        {/*{translateBlockSvg(iconsType)}*/}
        {!isOpenListing && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="66"
            height="46"
            viewBox="0 0 66 46"
          >
            <defs>
              <filter
                id="Background"
                x="0"
                y="0"
                width="66"
                height="46"
                filterUnits="userSpaceOnUse"
              >
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="3" result="blur" />
                <feFlood floodOpacity="0.161" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
              </filter>
            </defs>
            <g
              id="Group_4592"
              data-name="Group 4592"
              transform="translate(-309 -294)"
            >
              <g
                transform="matrix(1, 0, 0, 1, 309, 294)"
                filter="url(#Background)"
              >
                <rect
                  id="Background-2"
                  data-name="Background"
                  width="48"
                  height="28"
                  rx="14"
                  transform="translate(9 6)"
                  fill="#f1f1f2"
                />
              </g>
              <path
                id="Path_3740"
                data-name="Path 3740"
                d="M189.142,108.478l-7.507.162.131-7.445a.818.818,0,1,0-1.628,0l.131,7.445-7.507-.162a.817.817,0,1,0,0,1.628l7.507-.162-.131,7.444a.818.818,0,1,0,1.628,0l-.131-7.444,7.507.162a.817.817,0,1,0,0-1.628Z"
                transform="translate(161.131 204.436)"
                fill="#3a87f0"
              />
            </g>
          </svg>
        )}
      </SvgWrapper>
      {isOpenListing ? (
        <ListingWrapper isLaptop={windowWidth < 1440}>
          {allowdBlock.map((blockType, index) => {
            if (blockType.FlagValue === 4) return;

            return (
              <ListingItem
                firstItem={index === 0}
                key={blockType.FlagValue}
                // currentBlockType={blockForEditing.Type}
                // blockType={blockType.FlagValue}
                onMouseEnter={() => setIconsType(blockType.FlagValue)}
                onClick={() => {
                  addNewBlock(blockType.FlagValue);
                  toggleListing(false);
                  setIconsType(0);
                }}
              >
                {translateBlockTypeName(blockType.FlagValue)}
              </ListingItem>
            );
          })}
        </ListingWrapper>
      ) : null}
      {isOpenListing && <OverviewBlock Type={iconsType} Cords={randomCords} />}
    </MainWrapper>
  );
};
