import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react';

import {LoadingCircle} from 'utils/loadingCircle';
import {ProducerDataContext} from 'contexts/ProducerDataContext';

import api from 'services/axiosConfig';
import {Navigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {useNavigate} from 'react-router-dom';
import {
  Grid,
  Main,
  MainWrapper,
  PageHeaderContent,
  PageHeaderHeader,
  PageHeaderHeaderWrapper,
  PageHeaderWrapper,
  SearchWrapper,
  SingleCardWrapper,
} from './styles';
import {InputView} from 'pages/MyAccount/Settings/styles';

interface Block {
  BackgroundColorHex: string;
  BlockOpacityIn0To100Ratio: number;
  FontColorHex: string;
  Height: number;
  Id: string;
  IsRequiredOnCard: boolean;
  StartX: number;
  Type: number;
  Width: number;
}

interface ProducerCard {
  Id: string;
  DisplayName: string;
}

const ProducerList = () => {
  const [producers, setProducers] = useState<ProducerCard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchString, setSearchString] = useState('');

  const [cookies, setCookie] = useCookies(['token']);
  const {decodedToken} = useContext(ProducerDataContext);

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }

  const navigate = useNavigate();

  const handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const filteredValue = e.target.value.replace(
    //   /[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g,
    //   '',
    // );
    setSearchString(e.target.value);
  };

  const getProducers = async () => {
    try {
      const params = {};

      const response = await api.get(`Producer/names`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setProducers(response.data.Response);
      // console.log('producers: ', response.data.Response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getProducers();
  }, []);

  const filteredProducers = producers.filter((producer) =>
    producer.DisplayName.toLowerCase().includes(searchString.toLowerCase()),
  );

  return (
    <MainWrapper>
      <Main>
        {isLoading && <LoadingCircle />}

        <PageHeaderWrapper>
          <PageHeaderHeaderWrapper>
            <PageHeaderHeader>
              <p>Twórcy (ADMIN)</p>
            </PageHeaderHeader>
          </PageHeaderHeaderWrapper>
          <PageHeaderContent>
            <SearchWrapper>
              <InputView isEditing={true} style={{marginTop: '0px'}}>
                <p>Wyszukaj</p>
                <input
                  type="text"
                  placeholder="nazwa"
                  value={searchString}
                  onChange={handleSearchStringChange}
                />
              </InputView>
            </SearchWrapper>{' '}
          </PageHeaderContent>
        </PageHeaderWrapper>
        <Grid>
          {filteredProducers.map((producer) => {
            return (
              <SingleCardWrapper
                onClick={() => {
                  navigate(`/editCard/${producer.Id}`);
                }}
                key={producer.Id}
              >
                <p>{producer.DisplayName}</p>
              </SingleCardWrapper>
            );
          })}
        </Grid>
      </Main>
    </MainWrapper>
  );
};

export default ProducerList;
