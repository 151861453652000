import styled from 'styled-components';

export const ErrorStarWrapper = styled.div`
  position: absolute;
  right: -7px;
  top: -11px;
  cursor: pointer;
`;

export const Star = styled.div`
  color: red;
  font-size: 15px;
  font-weight: bold;
  padding: 2px;
  position: relative;

  & > p {
  }
`;

export const ErrorText = styled.div<{info?: boolean}>`
  width: 279px;
  //height: 114px;
  height: 80px;
  border-radius: 15px;
  //background: rgba(232, 232, 232, 0.95);
  background: rgba(248, 248, 248, 0.96);
  //border: 2px solid #ff7e7d;
  border: ${({info}) => (info ? '2px solid #3986ef' : '2px solid #ff7e7d')};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -273px;
  bottom: 15px;
  z-index: 99;

  & > p {
    width: 228px;
    //height: 92px;
    font-weight: normal;
    padding: 10px;
    font-size: 11px;
    color: #282c34;
    letter-spacing: 0.1px;
    line-height: 16px;
    text-align: center;
  }
`;
