import styled from 'styled-components';

export const MainWrapper = styled.div`
  color: ${({theme}) => theme.colors.bgWhite};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 143px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */
  pointer-events: all;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1024px) {
    width: 400px;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const PageHeaderWrapper = styled.div`
  width: 1020px;
  display: flex;
  /* height: 152px; */
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  flex-direction: column;
  align-items: center;
  /* background: white; */
  border-radius: 24px 24px 21px 21px;
  position: absolute;
  top: 88px;
  z-index: 20;
  background: ${({theme}) => theme.colors.headerContentBackground};
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    /* color: ${({theme}) => theme.colors.headerBlackFont}; */
    color: white;
    font-size: 15px;
    letter-spacing: 0.625px;
  }
`;

export const PageHeaderContent = styled.div`
  width: 100%;
  /* height: calc(100% - 55px); */
  /* height: 48px; */
  padding: 3px 0;
  /* display: grid; */
  background: ${({theme}) => theme.colors.headerContentBackground};
  border-radius: 0px 0px 21px 21px;
  align-items: center;
`;

export const ArticlesListWrapper = styled.div`
  margin-top: 50px;
  padding-top: 20px;
  height: auto;
  width: auto;
  display: flex;
  gap: 10px;
`;

export const ArticleListColumn = styled.div`
  width: 292px;
  display: flex;
  flex-direction: column;

  gap: 13px;
  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 13px;
  }
  @media (max-width: 400px) {
    padding: 0 8px;
    padding-bottom: 70px;
  }
`;

export const ArticleSearchWrapper = styled.div`
  width: 250px;
  margin-left: 10px;
`;

export const FilterMenuWrapper = styled.div`
  width: 100%;
  /* max-height: calc(90%); */
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* height: 40px; */
  margin-bottom: 20px;
  background: ${({theme}) => theme.colors.headerContentBackground};
  /* gap: 5px; */

  @media (max-width: 1045px) {
    grid-template-columns: 1fr;
  }
`;
