import React, {useState, useContext, useRef, useEffect} from 'react';
import styled from 'styled-components';

import dragArrows from 'images/svg/drag-arrows.svg';
import subtractCounter from 'images/svg/subtract-counter.svg';
import addCounter from 'images/svg/add-counter.svg';
import ApproveSvg from 'images/svg/approve.svg';
import {renderBlockContent, hexToRgb} from '../index';
import {ResolutionContext} from 'contexts/ResolutionContext';
import {UserThemeContext} from 'contexts/UserThemeContext';
import {EditHoverContext} from 'contexts/EditHoverContext';
import useAlert from '../../../../hooks/useAlert';

import {Block, Offer, BlockFile, UrlType} from '../interface';

import {
  AltPanelTextWrapper,
  PanelHeader,
  AltPanelContent,
  PanelContainer,
} from '../styles';

import {
  ColorBlock,
  EditContainer,
  EditOptionsWrapper,
  SettingsWithSlider,
  Slider,
  SettingsItemWrapper,
  SettingsCounterSvgWrapper,
  CloseSection,
  BackButtonEdit,
  SaveAndEditButtonEdit,
  SaveButtonsWrapper,
  SettingsSvgBg,
  HoverText,
  UserColorPopUpWrapper,
  EditWrapper,
  EditHeader,
  EditHeaderSvgWrapper,
  EditExtraOptionsWrapper,
  LinksWrapper,
  OneLinkWrapper,
  InputForLink,
  SelectWrapper,
  InputTypeNumber,
} from './styles';

export const OptionsSpan = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 1242px) {
    justify-content: space-between;
    gap: 10px;
  }
`;

export const MobileEditOptionsWrapper = styled(EditOptionsWrapper)`
  display: none;

  @media (max-width: 1242px) {
    display: flex;
  }
`;

import {Props} from './interface';
import {Comment} from '../../Account/interface';
import {ButtonUpAndDownBg} from '../Blocks/CommentsBlock/styles';
import {BusinessCardColorsContext} from '../../../../contexts/BusinessCardColorsContext';
import {Link} from 'react-router-dom';
import {SelectView} from 'pages/MyAccount/Settings/styles';
import {useCookies} from 'react-cookie';

import {ProducerDataContext} from '../../../../contexts/ProducerDataContext';
import api from 'services/axiosConfig';

export const StyledSelectView = styled(SelectView)`
  width: 376px;
  & select {
    border-radius: 5px;
    /* background: ${({theme}) => theme.colors.textareaBackground}; */
  }
`;

interface AboutMe {
  TextOne: string;
  TextTwo: string;
  TextThree: string;
  Title: string;
}

interface BlockShortTextAndTitle {
  Text: string;
  Title: string;
}

interface Banner {
  Base64BannerString: string;
  CurrentFileFtpFilePath: string;
  RemoveCurrentBanner: boolean;
}

export interface TitleBlockContent {
  Title: string;
  SubTitle: string;
}

export interface SocialBlockContent {
  Url: string;
  Type: number;
}

interface DataToUpdate {
  url?: SocialBlockContent;
  comments?: Comment[];
  shortBlockText?: BlockShortTextAndTitle;
  longText?: AboutMe;
  blockFileIds?: string[];
  blockFiles?: BlockFile[];
  offers?: Offer[];
  offerIds?: string[];
  offer?: Offer;
  banner?: Banner;
  type?: number;
  texts?: {TextOne?: string; TextTwo?: string};
  titleBlockContent?: TitleBlockContent;
  title?: string;
  base64Files?: string[];
}

const domainMap = {
  1: ['facebook.com', 'fb.com'],
  2: ['twitter.com', 'x.com'],
  3: ['soundcloud.com'],
  4: ['instagram.com'],
  5: ['pinterest.com'],
  6: ['tiktok.com'],
  7: ['youtube.com', 'youtu.be'],
  8: ['spotify.com', 'open.spotify.com'],
};

// const validateLink = (url, type) => {
//   if (type === 0) return true; // Jeśli typ to 0, nie trzeba walidować

//   try {
//     const parsedUrl = new URL(url);
//     const domain = parsedUrl.hostname;

//     if (!url.startsWith('https://')) {
//       return    Alert(
//         'Nie można dodać url, proszę podać cały link. Przykład https://www.google.com/ ',
//         'Nie można dodać url, proszę podać cały link. Przykład https://www.google.com/ ',
//       );  false;
//     }

//     if (domainMap[type]) {
//       return domainMap[type].some((validDomain) =>
//         domain.includes(validDomain),
//       );
//     }

//     return false; // Jeśli typ jest nieznany lub nie ma odpowiednich domen
//   } catch (e) {
//     return false; // Zwraca false, jeśli URL jest niepoprawny
//   }
// };

const blockSizeRules = {
  1: {
    Width: {MaxSize: 18, MinSize: 18, UnitCounter: 1},
    Height: {MaxSize: 2, MinSize: 1, UnitCounter: 1},
    SpecialRule: 0,
  },
  2: {
    Width: {MaxSize: 18, MinSize: 18, UnitCounter: 1},
    Height: {MaxSize: 18, MinSize: 1, UnitCounter: 1},
    SpecialRule: 0,
  },
  4: {
    Width: {MaxSize: 18, MinSize: 1, UnitCounter: 1},
    Height: {MaxSize: 10, MinSize: 1, UnitCounter: 1},
    SpecialRule: 0,
  },
  8: {
    Width: {MaxSize: 18, MinSize: 1, UnitCounter: 1},
    Height: {MaxSize: 10, MinSize: 1, UnitCounter: 1},
  },
  16: {
    Width: {MaxSize: 18, MinSize: 18, UnitCounter: 1},
    Height: {MaxSize: 5, MinSize: 4, UnitCounter: 1},
  },
  32: {
    Width: {MaxSize: 18, MinSize: 9, UnitCounter: 1},
    Height: {MaxSize: 5, MinSize: 5, UnitCounter: 1},
    SpecialRule: 0,
  },
  64: {
    Width: {MaxSize: 18, MinSize: 18, UnitCounter: 1},
    Height: {MaxSize: 6, MinSize: 2, UnitCounter: 1},
    SpecialRule: 0,
  },
  128: {
    Width: {MaxSize: 18, MinSize: 1, UnitCounter: 1},
    Height: {MaxSize: 10, MinSize: 1, UnitCounter: 1},
    SpecialRule: 0,
  },
  256: {
    Width: {MaxSize: 18, MinSize: 5, UnitCounter: 1},
    Height: {MaxSize: 6, MinSize: 3, UnitCounter: 1},
    SpecialRule: 0,
  },
  512: {
    Width: {MaxSize: 18, MinSize: 1, UnitCounter: 1},
    Height: {MaxSize: 10, MinSize: 1, UnitCounter: 1},
    SpecialRule: 0,
  },
  1024: {
    Width: {MaxSize: 18, MinSize: 2, UnitCounter: 1},
    Height: {MaxSize: 6, MinSize: 1, UnitCounter: 1},
    SpecialRule: 1024,
    Grid: [
      {w: 3, h: 1},
      {w: 5, h: 2},
      {w: 2, h: 3},
    ],
  },
  2048: {
    Width: {MaxSize: 18, MinSize: 3, UnitCounter: 1},
    Height: {MaxSize: 6, MinSize: 3, UnitCounter: 1},
    SpecialRule: 0,
  },
  4096: {
    Width: {MaxSize: 18, MinSize: 1, UnitCounter: 1},
    Height: {MaxSize: 2, MinSize: 1, UnitCounter: 1},
    SpecialRule: 0,
  },
  8192: {
    Width: {MaxSize: 18, MinSize: 18, UnitCounter: 1},
    Height: {MaxSize: 2, MinSize: 2, UnitCounter: 1},
    SpecialRule: 0,
  },
  16384: {
    Width: {MaxSize: 18, MinSize: 18, UnitCounter: 1},
    Height: {MaxSize: 1, MinSize: 1, UnitCounter: 1},
    SpecialRule: 0,
  },
  32768: {
    Width: {MaxSize: 18, MinSize: 18, UnitCounter: 1},
    Height: {MaxSize: 6, MinSize: 6, UnitCounter: 1},
    SpecialRule: 0,
  },
  65536: {
    Width: {MaxSize: 18, MinSize: 18, UnitCounter: 1},
    Height: {MaxSize: 6, MinSize: 4, UnitCounter: 1},
    SpecialRule: 0,
  },
  131072: {
    Width: {MaxSize: 18, MinSize: 18, UnitCounter: 1},
    Height: {MaxSize: 6, MinSize: 3, UnitCounter: 1},
    SpecialRule: 0,
  },
  262144: {
    Width: {MaxSize: 18, MinSize: 18, UnitCounter: 1},
    Height: {MaxSize: 6, MinSize: 6, UnitCounter: 1},
    SpecialRule: 0,
  },
};

const textPositionOptions = [1, 2, 3, 4];

const getUrlLinksForArray = (arr: UrlType[], type: number) => {
  if (!arr) return '';
  if (arr.length === 0) {
    return '';
  }
  const arrAfterFilter = arr.filter((item) => item.UrlType === type);
  if (arrAfterFilter.length > 0) {
    return arrAfterFilter[0].Url;
  } else return '';
};

const EditBlockComponent: React.FC<Props> = ({
  roles,
  blockForEditing,
  setBlockForEditing,
  selectedObject,
  isEditing,
  toggleEditing,
  setProducer,
  bgColors,
  saveNewColorForBlock,
  blockTypes,
  mobileOverview,
  updateCommentBlockProducer,
  ProducerIdForOffert,
  isAdminMode,
  producerIdForEditBlock,
}) => {
  // const newObject = blockForEditing;
  const {
    BackgroundColorHex,
    BlockOpacityIn0To100Ratio,
    FontColorHex,
    TitleColorHex,
    Format,
    Id,
    Type,
    WebArea,
    FirstName,
    DisplayProducerNameAsPublicDisplayName,
    FtpPhotoFilePath,
    LastName,
    Name,
    PostalCode,
    Role,
    SecondaryRole,
    Text,
    Title,
    TextOne,
    TextTwo,
    TextThree,
    Comments,
    BlockFiles,
    OfferId,
    Offer,
    Urls,
    Url,
    BlockFileIds,
    HorizontalFormat,
    VerticalFormat,
    Interline,
    MaskType,
    SpotifyUrl,
  } = blockForEditing;

  const {StartX, StartY, Width, Height} = WebArea;

  const startType = React.useRef(Type);

  if (blockForEditing.BlockFiles === undefined || startType.current !== Type) {
    blockForEditing.BlockFiles = [];
  }

  if (blockForEditing.CurrentFileFtpFilePath === undefined) {
    blockForEditing.CurrentFileFtpFilePath = '';
  }

  if (blockForEditing.Offers === undefined || startType.current !== Type) {
    blockForEditing.Offers = [];
  }

  const initIndexTextPosition =
    textPositionOptions.indexOf(blockForEditing.Format) === -1
      ? 0
      : textPositionOptions.indexOf(blockForEditing.Format);

  // const initIndexTextPositionHorizontal =
  //   textPositionOptions.indexOf(blockForEditing.HorizontalBlockFormat) === -1
  //     ? 0
  //     : textPositionOptions.indexOf(blockForEditing.HorizontalBlockFormat);

  const initIndexBgColor =
    bgColors.indexOf(blockForEditing.BackgroundColorHex) === -1
      ? 0
      : bgColors.indexOf(blockForEditing.BackgroundColorHex);

  const initIndexFontColor =
    bgColors.indexOf(blockForEditing.FontColorHex) === -1
      ? 0
      : bgColors.indexOf(blockForEditing.FontColorHex);

  const initIndexTitleColor =
    bgColors.indexOf(blockForEditing.TitleColorHex) === -1
      ? 0
      : bgColors.indexOf(blockForEditing.TitleColorHex);

  const initTitle = blockForEditing.Title === null ? '' : blockForEditing.Title;
  const initSubTitle =
    blockForEditing.SubTitle === null ? '' : blockForEditing.SubTitle;

  const initOffers = blockForEditing.Offers.map((offer) => offer.Id);

  const [newTitleBlock, setNewTitleBlock] = useState<TitleBlockContent>({
    Title: initTitle,
    SubTitle: initSubTitle,
  });
  const [textOne, setTextOne] = useState(TextOne);
  const [textTwo, setTextTwo] = useState(TextTwo);
  const [newHeaderTitleBlock, setNewHeaderTitleBlock] = useState(initTitle);
  const [newShortBlockText, setNewShortBlockText] =
    useState<BlockShortTextAndTitle>({Text, Title: initTitle});
  const [newLongBlockText, setNewLongBlockText] = useState<AboutMe>({
    TextOne,
    TextTwo,
    TextThree,
    Title: initTitle,
  });
  const [newCommentsBlock, setNewCommentsBlock] = useState<Comment[]>(Comments);
  const [newBlockFileIds, setNewBlockFileIds] =
    useState<string[]>(BlockFileIds);
  const [newBlockFile, setNewBlockFile] = useState<BlockFile[]>(BlockFiles);
  const [newBase64Files, setNewBase64Files] = useState([]);
  const [newOffers, setNewOffers] = useState(blockForEditing.Offers);
  const [newOfferIds, setNewOfferIds] = useState(initOffers);
  const [newOffer, setNewOffer] = useState(Offer);
  const [newBanner, setNewBanner] = useState({
    Base64BannerString: '',
    CurrentFileFtpFilePath: blockForEditing.CurrentFileFtpFilePath,
    RemoveCurrentBanner: false,
  });
  // const [newUrl, setNewUrl] = useState<SocialBlockContent>({
  //   Url: blockForEditing.Url,
  //   Type: blockForEditing.UrlType,
  // });

  const [isOpenUserColorPopUp, setIsOpenUserColorPopUp] =
    useState<boolean>(false);

  const [showButtonForSaveColor, setShowButtonForSaveColor] =
    useState<boolean>(false);
  const [clors, setColors] = useState<string[]>(bgColors);

  const [backgroundColorIndex, setBackgroundColorIndex] =
    useState<number>(initIndexBgColor);
  const [fontColorIndex, setFontColorIndex] =
    useState<number>(initIndexFontColor);
  const [titleColorIndex, setTitleColorIndex] =
    useState<number>(initIndexTitleColor);
  const [textPositionIndex, setTextPositionIndex] = useState(
    initIndexTextPosition,
  );

  const [textPositionHorizontalIndex, setTextPositionHorizontalIndex] =
    useState(HorizontalFormat);
  const [textPositionVerticalIndex, setTextPositionVerticalIndex] =
    useState(VerticalFormat);

  const [imgPositionMaskIndex, setImgPositionMaskIndex] = useState(
    MaskType === undefined ? 1 : MaskType,
  );
  const [colorType, setColorType] = useState<string>('');

  const [urlLinks, setUrlLinks] = useState<UrlType[]>(Urls);
  const [urlInputs, setUrlInputs] = useState(
    //   {
    //   Link: '',
    //   Facebook: '',
    //   Instagram: '',
    //   Souncloud: '',
    //   X: '',
    //   Pinterest: '',
    // }
    {
      Link: getUrlLinksForArray(Urls, 0),
      Facebook: getUrlLinksForArray(Urls, 1),
      Instagram: getUrlLinksForArray(Urls, 4),
      Souncloud: getUrlLinksForArray(Urls, 3),
      X: getUrlLinksForArray(Urls, 2),
      Pinterest: getUrlLinksForArray(Urls, 5),
      Tiktok: getUrlLinksForArray(Urls, 6),
      YouTube: getUrlLinksForArray(Urls, 7),
      Spotify: getUrlLinksForArray(Urls, 8),
    },
  );

  const [urlForYt, setUrlForYt] = useState<UrlType>(
    Url !== null ? Url : {UrlType: 7, Url: ''},
  );

  const [urlForSpotify, setUrlForSpotify] = useState<string>(
    SpotifyUrl !== null ? SpotifyUrl : '',
  );

  const [allOffers, setAllOffers] = useState([]);

  const [theme, setTheme] = useContext(UserThemeContext);
  const [businessCardColors, setBusinessCardColors] = useContext(
    BusinessCardColorsContext,
  );

  const {Alert} = useAlert();
  const [blockHover, setBlockHover] = useContext(EditHoverContext);
  const [cookies, setCookie] = useCookies(['token']);
  const {decodedToken} = useContext(ProducerDataContext);

  const inputColorRef = useRef<HTMLInputElement>(
    document.createElement('input'),
  );

  const removeUrlFromUrls = (arr: UrlType[], type: number) => {
    setUrlLinks(arr.filter((item) => item.UrlType !== type));
    setBlockForEditing((prev) => ({
      ...prev,
      Urls: arr.filter((item) => item.UrlType !== type),
    }));
    if (type === 0) {
      setUrlInputs((prev) => ({...prev, Link: ''}));
    }
    if (type === 1) {
      setUrlInputs((prev) => ({...prev, Facebook: ''}));
    }
    if (type === 2) {
      setUrlInputs((prev) => ({...prev, X: ''}));
    }
    if (type === 3) {
      setUrlInputs((prev) => ({...prev, Souncloud: ''}));
    }
    if (type === 4) {
      setUrlInputs((prev) => ({...prev, Instagram: ''}));
    }
    if (type === 5) {
      setUrlInputs((prev) => ({...prev, Pinterest: ''}));
    }
    if (type === 6) {
      setUrlInputs((prev) => ({...prev, Tiktok: ''}));
    }
    if (type === 7) {
      setUrlInputs((prev) => ({...prev, YouTube: ''}));
    }
    if (type === 8) {
      setUrlInputs((prev) => ({...prev, Spotify: ''}));
    }
  };

  const handleEditingBlock = (data: DataToUpdate) => {
    switch (Type) {
      case 1:
        setNewShortBlockText(data.shortBlockText);
        break;
      case 2:
        setNewLongBlockText(data.longText);
        break;
      case 4:
        setNewBlockFileIds(data.blockFileIds);
        setNewBlockFile(data.blockFiles);
        break;
      case 8:
        setNewBlockFileIds(data.blockFileIds);
        setNewBlockFile(data.blockFiles);
        break;
      case 16:
        setNewBlockFileIds(data.blockFileIds);
        setNewBlockFile(data.blockFiles);
        setNewBase64Files(data.base64Files);

        break;
      case 32:
        setNewCommentsBlock(data.comments);
        break;
      case 128:
        setNewBanner(data.banner);
        break;
      case 256:
        setNewOfferIds(data.offerIds);
        setNewOffers(data.offers);
        setBlockForEditing((prev) => ({
          ...prev,
          Offers: data.offers,
          OfferIds: data.offerIds,
        }));
        break;
      case 1024:
        setNewOffer(data.offer);
        break;
      case 2048:
        setNewOfferIds(data.offerIds);
        setNewOffers(data.offers);

        setBlockForEditing((prev) => ({
          ...prev,
          Offers: data.offers,
          OfferIds: data.offerIds,
        }));

        break;
      case 4096:
        // setNewUrl(data.url);
        break;
      case 8192:
        setNewTitleBlock(data.titleBlockContent);
        break;
      case 16384:
        setNewHeaderTitleBlock(data.title);
        break;
      case 32768:
        // setNewOffer(data.offer);
        break;
      case 65536:
        // setNewHeaderTitleBlock(data.title);
        break;
      case 131072:
        setNewHeaderTitleBlock(data.title);
        break;
      case 262144:
        // console.log('dane w edit z nowego bloku', data);
        setNewBlockFileIds(data.blockFileIds);
        setNewBlockFile(data.blockFiles);
        setNewBase64Files(data.base64Files);
        setTextOne(data.texts.TextOne);
        setTextTwo(data.texts.TextTwo);
        // setNewLongBlockText()
        break;
    }
  };

  const returnDiffrentElementFromTwoArr = (arr: Offer[], all: Offer[]) => {
    let newArr = [...all];
    all.forEach((item) => {
      if (arr.filter((id) => id.Id === item.Id).length > 0) {
        return (newArr = newArr.filter((newItem) => newItem.Id !== item.Id));
      }
    });
    return newArr;
  };

  const handleOfferSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const offerObj = JSON.parse(e.target.value);
    if (Type === 32768 || Type === 1024) {
      // console.log('one produkt or track', offerObj);

      setBlockForEditing((prev) => ({
        ...prev,
        Offer: JSON.parse(e.target.value),
        OfferId: JSON.parse(e.target.value).Id,
      }));

      setNewOffer(offerObj);
    } else {
      setBlockForEditing((prev) => ({
        ...prev,
        Offers: [...newOffers, JSON.parse(e.target.value)],
        OfferIds: [...newOfferIds, JSON.parse(e.target.value).Id],
      }));

      setNewOffers([...newOffers, JSON.parse(e.target.value)]);
      setNewOfferIds([...newOfferIds, JSON.parse(e.target.value).Id]);
    }
    // console.log(e.target.value);
    // console.log(JSON.parse(e.target.value));
  };

  const handleOfferClickChange = (offer) => {
    if (Type === 32768 || Type === 1024) {
      // console.log('one produkt or track', offerObj);

      setBlockForEditing((prev) => ({
        ...prev,
        Offer: offer,
        OfferId: offer.Id,
        TitleColorHex: 'dddaaf',
      }));

      setNewOffer(offer);
    } else {
      setBlockForEditing((prev) => ({
        ...prev,
        Offers: [...newOffers, offer],
        OfferIds: [...newOfferIds, offer.Id],
      }));

      setNewOffers([...newOffers, offer]);
      setNewOfferIds([...newOfferIds, offer.Id]);
    }
    // console.log(e.target.value);
    // console.log(JSON.parse(e.target.value));
  };

  const FontColorRgb = hexToRgb(FontColorHex);
  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);
  const TitleColorRgb = hexToRgb(TitleColorHex);

  const handleYtUrl = (e) => {
    setUrlForYt((prev) => ({...prev, Url: e.target.value}));
  };

  const handleSpotifyUrl = (e) => {
    setUrlForSpotify((prev) => e.target.value);
  };

  const handleInputsUrl = (e, type) => {
    if (type === 0) {
      setUrlInputs((prev) => ({...prev, Link: e.target.value}));
    }
    if (type === 2) {
      setUrlInputs((prev) => ({...prev, X: e.target.value}));
    }
    if (type === 1) {
      setUrlInputs((prev) => ({...prev, Facebook: e.target.value}));
    }
    if (type === 3) {
      setUrlInputs((prev) => ({...prev, Souncloud: e.target.value}));
    }

    if (type === 4) {
      setUrlInputs((prev) => ({...prev, Instagram: e.target.value}));
    }
    if (type === 5) {
      setUrlInputs((prev) => ({...prev, Pinterest: e.target.value}));
    }
    if (type === 6) {
      setUrlInputs((prev) => ({...prev, Tiktok: e.target.value}));
    }
    if (type === 7) {
      setUrlInputs((prev) => ({...prev, YouTube: e.target.value}));
    }
    if (type === 8) {
      setUrlInputs((prev) => ({...prev, Spotify: e.target.value}));
    }
  };

  const dowloadDataToAdd = async () => {
    try {
      const params = {
        producerId: ProducerIdForOffert,
      };
      const response = await api.get(`Offer`, {
        params,
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setAllOffers(
        response.data.Response
          // .filter(
          //   (item) => item.ProducerId === decodedToken.ProducerId,
          // )
          // .filter((item) => item.ProductType === 8)
          .map((item) => ({
            Id: item.Id,
            Name: item.Name,
            Category: item.Category,
            IsWinWin: item.IsWinWin,
            Description: item.Description,
            GrossValue: item.GrossValue,
            Currency: item.Currency,
            CoverFile: item.CoverFile,
            ProductDemoFile: item.ProductDemoFile,
            Categories: item.Categories,
            SecondCategoryId: item.SecondCategoryId,
            ThirdCategoryId: item.ThirdCategoryId,
            ProductType: item.ProductType,
          })),
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (Type === 32768 || Type === 2048 || Type === 1024 || Type === 256) {
      dowloadDataToAdd();
    }
  }, [Type]);

  // const validateLink = (url, type) => {
  //   console.log('', url);
  //   try {
  //     const parsedUrl = new URL(url);
  //     const domain = parsedUrl.hostname;

  //     if (type === 0) return true; // Jeśli typ to 0, nie trzeba walidować
  //     if (domainMap[type]) {
  //       return domainMap[type].some((validDomain) =>
  //         domain.includes(validDomain),
  //       );
  //     }

  //     return false; // Jeśli typ jest nieznany lub nie ma odpowiednich domen
  //   } catch (e) {
  //     return false; // Zwraca false, jeśli URL jest niepoprawny
  //   }
  // };

  const validateLink = (url, type) => {
    // "https://" jeśli brakuje

    try {
      const parsedUrl = new URL(url);
      const domain = parsedUrl.hostname;

      if (type === 0) return true; // Jeśli typ to 0, nie trzeba walidować
      if (domainMap[type]) {
        return domainMap[type].some((validDomain) =>
          domain.includes(validDomain),
        );
      }

      return false; // Jeśli typ jest nieznany lub nie ma odpowiednich domen
    } catch (e) {
      return false; // Zwraca false, jeśli URL jest niepoprawny
    }
  };

  const addNewUrl = (url: string, type: number) => {
    if (!url.startsWith('https://')) {
      url = 'https://' + url;
      if (type === 0) {
        setUrlInputs((prev) => ({...prev, Link: url}));
      }
      if (type === 2) {
        setUrlInputs((prev) => ({...prev, X: url}));
      }
      if (type === 1) {
        setUrlInputs((prev) => ({...prev, Facebook: url}));
      }
      if (type === 3) {
        setUrlInputs((prev) => ({...prev, Souncloud: url}));
      }

      if (type === 4) {
        setUrlInputs((prev) => ({...prev, Instagram: url}));
      }
      if (type === 5) {
        setUrlInputs((prev) => ({...prev, Pinterest: url}));
      }
      if (type === 6) {
        setUrlInputs((prev) => ({...prev, Tiktok: url}));
      }
      if (type === 7) {
        setUrlInputs((prev) => ({...prev, YouTube: url}));
      }
      if (type === 8) {
        setUrlInputs((prev) => ({...prev, Spotify: url}));
      }
    }

    if (!validateLink(url, type)) {
      if (type === 0) {
        setUrlInputs((prev) => ({...prev, Link: ''}));
      }
      if (type === 2) {
        setUrlInputs((prev) => ({...prev, X: ''}));
      }
      if (type === 1) {
        setUrlInputs((prev) => ({...prev, Facebook: ''}));
      }
      if (type === 3) {
        setUrlInputs((prev) => ({...prev, Souncloud: ''}));
      }

      if (type === 4) {
        setUrlInputs((prev) => ({...prev, Instagram: ''}));
      }
      if (type === 5) {
        setUrlInputs((prev) => ({...prev, Pinterest: ''}));
      }
      if (type === 6) {
        setUrlInputs((prev) => ({...prev, Tiktok: ''}));
      }
      if (type === 7) {
        setUrlInputs((prev) => ({...prev, YouTube: ''}));
      }
      if (type === 8) {
        setUrlInputs((prev) => ({...prev, Spotify: ''}));
      }
      return Alert(
        'Nie można dodać url, Podany url nie pasuje do wybranej domeny',
        'Podany link nie pasuje do wybranej domeny',
      );
    }
    if (url.length > 0) {
      const newArr = urlLinks.filter((item) => item.UrlType !== type);
      setUrlLinks((prev) => [...newArr, {UrlType: type, Url: url}]);
      // setUrlInputs({
      //   Link: '',
      //   Facebook: '',
      //   Instagram: '',
      //   Souncloud: '',
      //   X: '',
      //   Pinterest: '',
      // });
      setBlockForEditing((prev) => ({
        ...prev,
        Urls: [...newArr, {UrlType: type, Url: url}],
      }));
    }
  };

  const addNewUrlForYt = (url: UrlType) => {
    setBlockForEditing((prev) => ({
      ...prev,
      Url: url,
    }));
  };

  function convertToEmbedLink(spotifyUrl) {
    // Sprawdzenie, czy URL już zawiera "/embed"

    if (
      !spotifyUrl.includes('open.spotify.com') ||
      spotifyUrl.includes('<') ||
      spotifyUrl.includes('>')
    ) {
      return console.log('zły spotify');
    }

    if (spotifyUrl.includes('/embed')) {
      return spotifyUrl;
    }

    // Rozdzielenie URL na części przed i po "/album/", "/track/", itd.
    const parts = spotifyUrl.split(
      /(\/album\/|\/track\/|\/playlist\/|\/artist\/)/,
    );

    if (parts.length < 3) {
      // Jeśli URL jest nieprawidłowy lub nie zawiera rozpoznawanego formatu, zwróć oryginalny URL
      return spotifyUrl;
    }

    // Dodanie "/embed" do URL
    return parts[0] + '/embed' + parts[1] + parts[2];
  }

  const addNewUrlForSpotifyUrl = (url: string) => {
    const editUrl = convertToEmbedLink(url);

    setUrlForSpotify(editUrl);

    setBlockForEditing((prev) => ({
      ...prev,
      SpotifyUrl: editUrl,
    }));
  };

  const removeYtUrl = () => {
    setUrlForYt({Url: '', UrlType: 7});
    setBlockForEditing((prev) => ({
      ...prev,
      Url: {Url: '', UrlType: 7},
    }));
  };
  const removeSpotifyUrl = () => {
    setUrlForSpotify('');
    setBlockForEditing((prev) => ({
      ...prev,
      SpotifyUrl: '',
    }));
  };

  const saveBlockChanges = (id: string, blockChanges: Block) => {
    const {
      BackgroundColorHex,
      BlockOpacityIn0To100Ratio,
      FontColorHex,
      TitleColorHex,
      Id,
      Type,
      WebArea,
      MobileArea,
      DisplayProducerNameAsPublicDisplayName,
      FirstName,
      FtpPhotoFilePath,
      LastName,
      Street,
      Region,
      Name,
      PostalCode,
      Role,
      SecondaryRole,
      NIP,
      Comments,
      City,
    } = blockChanges;

    if (Type === 16) {
      if (newBlockFile.length < 3)
        return Alert(
          'Aby zapisać blok proszę dodać minimum 3 zdjęć',
          'Aby zapisać blok proszę dodać minimum 3 zdjęć',
        );
    }

    if (Type === 256) {
      if (newOffers.length < 3)
        return Alert(
          'Aby zapisać blok proszę dodać minimum 3 produkty',
          'Aby zapisać blok proszę dodać minimum 3 produkty',
        );
    }

    if (Type === 2048) {
      if (newOffers.length < 3)
        return Alert(
          'Aby zapisać blok proszę dodać minimum 3 tracki',
          'Aby zapisać blok proszę dodać minimum 3 tracki',
        );
    }

    document.body.style.overflowY = 'auto';
    toggleEditing(false);

    switch (Type) {
      case 64: {
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  WebArea,
                  MobileArea,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  Type,
                  DisplayProducerNameAsPublicDisplayName,
                  FirstName,
                  FtpPhotoFilePath,
                  LastName,
                  Name,
                  PostalCode,
                  City,
                  NIP,
                  Role,
                  SecondaryRole,
                };
              return block;
            }),
          ],
        }));
      }

      case 1: {
        console.log('1 type blok xd: ', blockChanges.Id, id);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  Title: newShortBlockText.Title,
                  Text: newShortBlockText.Text,
                  // Format: textPositionOptions[textPositionIndex],
                  HorizontalFormat: HorizontalFormat,
                  VerticalFormat: VerticalFormat,
                  Interline: Interline,
                };
              return block;
            }),
          ],
        }));
      }

      case 2: {
        console.log('2 type blok xd: ', blockChanges.Id, id);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  TextOne: newLongBlockText.TextOne,
                  TextTwo: newLongBlockText.TextTwo,
                  TextThree: newLongBlockText.TextThree,
                  Title: newLongBlockText.Title,
                  // Format: textPositionOptions[textPositionIndex],
                  HorizontalFormat: HorizontalFormat,
                  VerticalFormat: VerticalFormat,
                  Interline: Interline,
                };
              return block;
            }),
          ],
        }));
      }
      case 4: {
        console.log('4 type blok xd: ', blockChanges, newBlockFileIds);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  BlockFileIds: newBlockFileIds,
                  BlockFiles: newBlockFile,
                };
              return block;
            }),
          ],
        }));
      }
      case 8: {
        console.log('8 type blok xd: ', blockChanges, newBlockFileIds);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  BlockFileIds: newBlockFileIds,
                  BlockFiles: newBlockFile,
                };
              return block;
            }),
          ],
        }));
      }
      case 16: {
        console.log('16 type blok xd: ', blockChanges, newBlockFileIds);

        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  BlockFileIds: newBlockFileIds,
                  BlockFiles: newBlockFile,
                  Base64Files: newBase64Files,
                };
              return block;
            }),
          ],
        }));
      }

      case 32: {
        console.log('32 type blok xd: ', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  Comments: newCommentsBlock,
                };
              return block;
            }),
          ],
        }));
      }

      case 128: {
        console.log('128 type blok xd: ', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  Base64BannerString:
                    newBanner.Base64BannerString === ''
                      ? null
                      : newBanner.Base64BannerString,
                  CurrentFileFtpFilePath: newBanner.CurrentFileFtpFilePath,
                  RemoveCurrentBanner: newBanner.RemoveCurrentBanner,
                };
              return block;
            }),
          ],
        }));
      }

      case 256: {
        console.log('256 type blok xd: ', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  Offers: newOffers,
                  OfferIds: newOfferIds,
                };
              return block;
            }),
          ],
        }));
      }

      case 1024: {
        console.log('1024 type blok xd: ', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  OfferId:
                    newOffer !== null
                      ? newOffer.Id
                      : '00000000-0000-0000-0000-000000000000',
                  Offer: newOffer,
                };
              return block;
            }),
          ],
        }));
      }

      case 2048: {
        console.log('2048 type blok xd: ', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  Offers: newOffers,
                  OfferIds: newOfferIds,
                };
              return block;
            }),
          ],
        }));
      }

      case 4096: {
        console.log('4096 type blok xd: ', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  UrlLinks: urlLinks,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  // Url: newUrl.Url,
                  // UrlType: newUrl.Type,
                  Urls: urlLinks,
                };
              return block;
            }),
          ],
        }));
      }

      case 8192: {
        console.log('8192 type block', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  // Format: textPositionOptions[textPositionIndex],
                  HorizontalFormat: HorizontalFormat,
                  VerticalFormat: VerticalFormat,
                  Interline: Interline,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  Title: newTitleBlock.Title,
                  SubTitle: newTitleBlock.SubTitle,
                };
              return block;
            }),
          ],
        }));
      }

      case 16384: {
        console.log('16384 type block', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  // Format: textPositionOptions[textPositionIndex],
                  HorizontalFormat: HorizontalFormat,
                  VerticalFormat: VerticalFormat,
                  Interline: Interline,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  Title: newHeaderTitleBlock,
                };
              return block;
            }),
          ],
        }));
      }

      case 32768: {
        console.log('32768 type blok xd: ', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  OfferId:
                    newOffer !== null
                      ? newOffer.Id
                      : '00000000-0000-0000-0000-000000000000',
                  Offer: newOffer,
                };
              return block;
            }),
          ],
        }));
      }

      case 65536: {
        console.log('65536 type block', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  // Format: textPositionOptions[textPositionIndex],
                  HorizontalFormat: HorizontalFormat,
                  VerticalFormat: VerticalFormat,
                  Interline: Interline,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  Url: urlForYt,
                };
              return block;
            }),
          ],
        }));
      }

      case 131072: {
        console.log('131072 type block', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  // Format: textPositionOptions[textPositionIndex],
                  HorizontalFormat: HorizontalFormat,
                  VerticalFormat: VerticalFormat,
                  Interline: Interline,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  Title: newHeaderTitleBlock,
                };
              return block;
            }),
          ],
        }));
      }

      case 262144: {
        console.log('131072 type block', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  // Format: textPositionOptions[textPositionIndex],
                  HorizontalFormat: HorizontalFormat,
                  VerticalFormat: VerticalFormat,
                  Interline: Interline,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  TextOne: textOne,
                  TextTwo: textTwo,
                  BlockFileIds: newBlockFileIds,
                  BlockFiles: newBlockFile,
                  Base64Files: newBase64Files,
                  MaskType: imgPositionMaskIndex,
                };
              return block;
            }),
          ],
        }));
      }

      case 524288: {
        console.log('131072 type block', blockChanges);
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  // Format: textPositionOptions[textPositionIndex],
                  HorizontalFormat: HorizontalFormat,
                  VerticalFormat: VerticalFormat,
                  Interline: Interline,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                  SpotifyUrl: urlForSpotify,
                };
              return block;
            }),
          ],
        }));
      }

      default: {
        return setProducer((prev) => ({
          ...prev,
          Blocks: [
            ...prev.Blocks.map((block) => {
              if (id === block.Id)
                return {
                  BackgroundColorHex,
                  TitleColorHex,
                  BlockOpacityIn0To100Ratio,
                  FontColorHex,
                  Id:
                    Type === startType.current
                      ? Id
                      : '00000000-0000-0000-0000-000000000000',
                  WebArea,
                  MobileArea,
                  Type,
                };
              return block;
            }),
          ],
        }));
      }
    }
  };

  const translateBlockTypeName = (type: number) => {
    switch (type) {
      case 1:
        return 'O mnie - krótki';
      case 2:
        return 'O mnie - długi';
      case 4:
        return 'Galeria audio';
      case 8:
        return 'Galeria video';
      case 16:
        return 'Galeria obrazów';
      case 32:
        return 'Komentarze';
      case 128:
        return 'Baner';
      case 256:
        return 'Polecane produkty';
      case 512:
        return 'Czysty';
      case 1024:
        return 'Polecany track';
      case 2048:
        return 'Polecane tracki';
      case 4096:
        return 'Social media';
      case 8192:
        return 'Tytuł';
      case 16384:
        return 'Nagłówek';
      case 32768:
        return 'Polecany produkt';
      case 65536:
        return 'YouTube';
      case 131072:
        return 'Duży nagłówek';
      case 262144:
        return 'Zdjęcie z tekstem';
      case 524288:
        return 'Spotify playlista';
      case 64:
        return 'Podstawowy';
    }
  };

  const handleIndexTextPositonVertical = (index: number, action: string) => {
    console.log('akcja i index', action, index);
    switch (action) {
      case 'INCREASE': {
        if (index === 8) {
          setTextPositionVerticalIndex(1);
          return handleTextPositionVerticalChange(1);
        } else if (index === 4) {
          setTextPositionVerticalIndex(8);
          return handleTextPositionVerticalChange(8);
        } else if (index === 2) {
          setTextPositionVerticalIndex(4);
          return handleTextPositionVerticalChange(4);
        } else if (index === 1) {
          setTextPositionVerticalIndex(2);

          return handleTextPositionVerticalChange(2);
        } else {
          return console.log('bład');
        }
      }
      case 'DECREASE': {
        if (index === 8) {
          setTextPositionVerticalIndex(4);
          return handleTextPositionVerticalChange(4);
        } else if (index === 4) {
          setTextPositionVerticalIndex(2);
          return handleTextPositionVerticalChange(2);
        } else if (index === 2) {
          setTextPositionVerticalIndex(1);
          return handleTextPositionVerticalChange(1);
        } else if (index === 1) {
          setTextPositionVerticalIndex(8);
          return handleTextPositionVerticalChange(8);
        } else {
          return console.log('błads');
        }
      }
      default:
        return;
    }
  };

  const handleIndexImgPositonMask = (index: number, action: string) => {
    let newIndex = index;

    switch (action) {
      case 'INCREASE': {
        newIndex = (index + 1) % 4; // Dodaj 1, a następnie zresetuj na 0, jeśli nowy index to 4
        break;
      }
      case 'DECREASE': {
        newIndex = (index - 1 + 4) % 4; // Odejmij 1, dodaj 4 (dla uniknięcia negatywnych wartości), a potem zresetuj na 3, jeśli nowy index to -1
        break;
      }
      default:
        return console.error('Nieznana akcja:', action);
    }

    setImgPositionMaskIndex(newIndex);
    handleImgPositionMaskChange(newIndex);
  };

  const handleIndexTextPositonHorizontal = (index: number, action: string) => {
    switch (action) {
      case 'INCREASE': {
        if (index === 8) {
          setTextPositionHorizontalIndex(1);
          return handleTextPositionHorizontalChange(1);
        } else if (index === 4) {
          setTextPositionHorizontalIndex(8);
          return handleTextPositionHorizontalChange(8);
        } else if (index === 2) {
          setTextPositionHorizontalIndex(4);
          return handleTextPositionHorizontalChange(4);
        } else if (index === 1) {
          setTextPositionHorizontalIndex(2);

          return handleTextPositionHorizontalChange(2);
        } else {
          return console.log('bład');
        }
      }
      case 'DECREASE': {
        if (index === 8) {
          setTextPositionHorizontalIndex(4);
          return handleTextPositionHorizontalChange(4);
        } else if (index === 4) {
          setTextPositionHorizontalIndex(2);
          return handleTextPositionHorizontalChange(2);
        } else if (index === 2) {
          setTextPositionHorizontalIndex(1);
          return handleTextPositionHorizontalChange(1);
        } else if (index === 1) {
          setTextPositionHorizontalIndex(8);
          return handleTextPositionHorizontalChange(8);
        } else {
          return console.log('błads');
        }
      }
      default:
        return;
    }
  };

  const handleIndexTextPositon = (index: number, action: string) => {
    switch (action) {
      case 'INCREASE': {
        if (index >= textPositionOptions.length - 1) {
          handleTextPositionChange(textPositionOptions[0]);
          return setTextPositionIndex(0);
        } else {
          handleTextPositionChange(textPositionOptions[index + 1]);
          return setTextPositionIndex((prevState) => prevState + 1);
        }
      }
      case 'DECREASE': {
        if (index === 0) {
          handleTextPositionChange(
            textPositionOptions[textPositionOptions.length - 1],
          );

          return setTextPositionIndex(textPositionOptions.length - 1);
        } else {
          handleTextPositionChange(textPositionOptions[index - 1]);

          return setTextPositionIndex((prevState) => prevState - 1);
        }
      }
      default:
        return;
    }
  };

  const handleIndexTitleColor = (index: number, action: string) => {
    switch (action) {
      case 'INCREASE': {
        if (index >= bgColors.length - 1) {
          handleTitleColorChange(bgColors[0]);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            TitleColor: bgColors[0],
          }));
          return setTitleColorIndex(0);
        } else {
          handleTitleColorChange(bgColors[index + 1]);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            TitleColor: bgColors[index + 1],
          }));
          return setTitleColorIndex((prevState) => prevState + 1);
        }
      }
      case 'DECREASE': {
        if (index === 0) {
          handleTitleColorChange(bgColors[bgColors.length - 1]);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            TitleColor: bgColors[bgColors.length - 1],
          }));
          return setTitleColorIndex(bgColors.length - 1);
        } else {
          handleTitleColorChange(bgColors[index - 1]);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            TitleColor: bgColors[index - 1],
          }));
          return setTitleColorIndex((prevState) => prevState - 1);
        }
      }
      default:
        return;
    }
  };

  const handleIndexBackgroundColor = (index: number, action: string) => {
    console.log('bg kolors', bgColors);
    switch (action) {
      case 'INCREASE': {
        if (index >= bgColors.length - 1) {
          handleBackgroundColorChange(bgColors[0]);
          setIsOpenUserColorPopUp(false);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            BackgroundColor: bgColors[0],
          }));
          return setBackgroundColorIndex(0);
        } else {
          handleBackgroundColorChange(bgColors[index + 1]);
          setIsOpenUserColorPopUp(false);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            BackgroundColor: bgColors[index + 1],
          }));
          return setBackgroundColorIndex((prevState) => prevState + 1);
        }
      }
      case 'DECREASE': {
        if (index === 0) {
          handleBackgroundColorChange(bgColors[bgColors.length - 1]);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            BackgroundColor: bgColors[bgColors.length - 1],
          }));
          return setBackgroundColorIndex(bgColors.length - 1);
        } else {
          handleBackgroundColorChange(bgColors[index - 1]);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            BackgroundColor: bgColors[index + 1],
          }));
          return setBackgroundColorIndex((prevState) => prevState - 1);
        }
      }
      default:
        return;
    }
  };

  const handleIndexFontColor = (index: number, action: string) => {
    switch (action) {
      case 'INCREASE': {
        if (index >= bgColors.length - 1) {
          handleFontColorChange(bgColors[0]);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            FontColor: bgColors[0],
          }));
          return setFontColorIndex(0);
        } else {
          handleFontColorChange(bgColors[index + 1]);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            FontColor: bgColors[index + 1],
          }));
          return setFontColorIndex((prevState) => prevState + 1);
        }
      }
      case 'DECREASE': {
        if (index === 0) {
          handleFontColorChange(bgColors[bgColors.length - 1]);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            FontColor: bgColors[bgColors.length - 1],
          }));

          return setFontColorIndex(bgColors.length - 1);
        } else {
          handleFontColorChange(bgColors[index - 1]);
          setBusinessCardColors((prevState) => ({
            ...prevState,
            FontColor: bgColors[index - 1],
          }));

          return setFontColorIndex((prevState) => prevState - 1);
        }
      }
      default:
        return;
    }
  };

  const handleGridChange = (
    action: string,
    counter: number,
    counterName: string,
    type: number,
    width: number,
  ) => {
    if (Type === 32768) {
      if (action === 'INCREASE') {
        if (counter + 1 === 4 && width === 2) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 3, Height: 3},
          }));
        }
        if (counter + 1 === 4 && width === 3) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 3, Height: 4},
          }));
        }
        if (counter + 1 === 5 && width === 3) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 4, Height: 4},
          }));
        }
        if (counter + 1 === 5 && width === 4) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 6, Height: 4},
          }));
        }
        if (counter + 1 === 5 && width === 6) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 9, Height: 4},
          }));
        }
        if (counter + 1 === 5 && width === 9) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 2, Height: 3},
          }));
        }
      }
      if (action === 'DECREASE') {
        if (counter - 1 === 3 && width === 9) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 6, Height: 4},
          }));
        }
        if (counter - 1 === 3 && width === 6) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 4, Height: 4},
          }));
        }
        if (counter - 1 === 3 && width === 4) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 3, Height: 4},
          }));
        }
        if (counter - 1 === 3 && width === 3) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 3, Height: 3},
          }));
        }
        if (counter - 1 === 2 && width === 3) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 2, Height: 3},
          }));
        }
        if (counter - 1 === 2 && width === 2) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 9, Height: 4},
          }));
        }
      }
    }
    if (type === 1024) {
      if (action === 'INCREASE') {
        if (counter + 1 > 4) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 3, Height: 1},
          }));
        }
        if (counter + 1 === 2) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 5, Height: 2},
          }));
        }
        if (counter + 1 === 3) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 2, Height: 3},
          }));
        }

        if (counter + 1 === 4) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 9, Height: 4},
          }));
        }
      }
      if (action === 'DECREASE') {
        if (counter - 1 === 0) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 9, Height: 4},
          }));
        }
        if (counter - 1 === 1) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 3, Height: 1},
          }));
        }
        if (counter - 1 === 2) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 5, Height: 2},
          }));
        }
        if (counter - 1 === 3) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 2, Height: 3},
          }));
        }
      }
    }
    if (type === 8) {
      if (action === 'INCREASE') {
        if (counter + 1 === 4) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 2, Height: 2},
          }));
        }
        if (counter + 1 === 3) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 2, Height: 4},
          }));
        }
        if (counter + 1 === 5) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 5, Height: 3},
          }));
        }
      }
      if (action === 'DECREASE') {
        if (counter - 2 === 0) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 5, Height: 3},
          }));
        }
        if (counter - 2 === 2) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 2, Height: 2},
          }));
        }
        if (counter - 1 === 3) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 5, Height: 3},
          }));
        }
        if (counter - 1 === 2) {
          return setBlockForEditing((prev) => ({
            ...prev,
            WebArea: {...WebArea, Width: 2, Height: 4},
          }));
        }
      }
    }
  };

  const handleInterline = (action: string) => {
    switch (action) {
      case 'INCREASE':
        return setBlockForEditing((prev) => ({
          ...prev,
          Interline: Interline + 1,
        }));
      case 'DECREASE':
        return setBlockForEditing((prev) => ({
          ...prev,
          Interline: Interline - 1,
        }));
      default:
        return;
    }
  };

  const handleCounterChange = (
    action: string,
    counter: number,
    counterName: string,
  ) => {
    switch (action) {
      case 'INCREASE': {
        if (blockSizeRules[Type].SpecialRule === 32768) {
          if (counterName === 'Height' && Width === 2) return;
          if (counterName === 'Width' && Height === 3 && counter === 3) return;
          if (counterName === 'Height' && Width >= 4) return;
          if (counterName === 'Width' && Width === 4 && Height === 4)
            return setBlockForEditing((prev) => ({
              ...prev,
              WebArea: {...WebArea, [counterName]: 6},
            }));
          if (counterName === 'Width' && Width === 6 && Height === 4)
            return setBlockForEditing((prev) => ({
              ...prev,
              WebArea: {...WebArea, [counterName]: 9},
            }));
        }
        if (counter === blockSizeRules[Type][counterName].MaxSize) return;
        return setBlockForEditing((prev) => ({
          ...prev,
          WebArea: {
            ...WebArea,
            [counterName]:
              counter + blockSizeRules[Type][counterName].UnitCounter,
          },
        }));
      }
      case 'DECREASE': {
        if (blockSizeRules[Type].SpecialRule === 32768) {
          if (counterName === 'Height' && Height === 4 && Width === 4) return;
          if (counterName === 'Width' && Height === 4 && Width === 3) return;
          if (counterName === 'Height' && Width > 4) return;
          if (counterName === 'Width' && Width === 6 && Height === 4)
            return setBlockForEditing((prev) => ({
              ...prev,
              WebArea: {...WebArea, [counterName]: 4},
            }));
          if (counterName === 'Width' && Width === 9 && Height === 4)
            return setBlockForEditing((prev) => ({
              ...prev,
              WebArea: {...WebArea, [counterName]: 6},
            }));
        }
        if (counter === blockSizeRules[Type][counterName].MinSize) return;
        return setBlockForEditing((prev) => ({
          ...prev,
          WebArea: {
            ...WebArea,
            [counterName]:
              counter - blockSizeRules[Type][counterName].UnitCounter,
          },
        }));
      }
      default:
        return;
    }
  };

  const handleFontColorChange = (color: string) =>
    setBlockForEditing((prev) => ({...prev, FontColorHex: color}));

  const handleBackgroundColorChange = (color: string) =>
    setBlockForEditing((prev) => ({...prev, BackgroundColorHex: color}));

  const handleTextPositionChange = (positon: number) =>
    setBlockForEditing((prev) => ({...prev, Format: positon}));

  const handleTextPositionHorizontalChange = (positon: number) =>
    setBlockForEditing((prev) => ({...prev, HorizontalFormat: positon}));

  const handleTextPositionVerticalChange = (positon: number) => {
    console.log('ustawiam pozycje vert w edit ', positon);
    setBlockForEditing((prev) => ({...prev, VerticalFormat: positon}));
  };

  const handleImgPositionMaskChange = (positon: number) => {
    console.log('ustawiam pozycje vert w edit ', positon);
    setBlockForEditing((prev) => ({...prev, MaskType: positon}));
  };

  const handleTitleColorChange = (color: string) =>
    setBlockForEditing((prev) => ({...prev, TitleColorHex: color}));

  const handleBackgroundAlphaChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    console.log(+e.target.value);
    setBlockForEditing((prev) => ({
      ...prev,
      BlockOpacityIn0To100Ratio: +e.target.value,
    }));
  };

  const hoverTextOn = (text: string) => {
    setBlockHover((prev) => ({Active: true, Text: text}));
  };

  const hoverTextOff = () => {
    setBlockHover((prev) => ({Active: false, Text: ''}));
  };

  return (
    <EditContainer key={Id} isEditing={isEditing} blockHeight={Height}>
      {
        <HoverText>
          {blockHover.Text === ''
            ? translateBlockTypeName(Type)
            : blockHover.Text}
        </HoverText>
      }
      <CloseSection
        onClick={() => {
          toggleEditing(false);
          hoverTextOff();
        }}
      ></CloseSection>
      <EditWrapper blockWidth={Width}>
        <EditHeader>
          <p>{`Dodawanie bloku typu: ${translateBlockTypeName(Type)}`}</p>
          <EditHeaderSvgWrapper>
            <svg
              key="saveAfterAdd"
              onMouseEnter={() => hoverTextOn('Zapisz')}
              onMouseLeave={hoverTextOff}
              onClick={() => {
                saveBlockChanges(selectedObject.Id, blockForEditing);
                hoverTextOff();
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
            >
              <g
                id="Group_7260"
                data-name="Group 7260"
                transform="translate(-810.5 -324.5)"
              >
                <rect
                  id="Rectangle_2518"
                  data-name="Rectangle 2518"
                  width="19"
                  height="19"
                  rx="4"
                  transform="translate(810.5 324.5)"
                  fill="#0091ff"
                />
                <g
                  id="Icon_feather-save"
                  data-name="Icon feather-save"
                  transform="translate(810.5 324.5)"
                >
                  <path
                    id="Path_3700"
                    data-name="Path 3700"
                    d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_3701"
                    data-name="Path 3701"
                    d="M16.056,23.944V19.5H10.5v4.444"
                    transform="translate(-3.778 -9.444)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_3702"
                    data-name="Path 3702"
                    d="M10.5,4.5V7.278h4.444"
                    transform="translate(-3.778)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </g>
            </svg>

            {/* zapisz gora usun dol */}
            <svg
              className="CancelWriteMod"
              onMouseEnter={() => hoverTextOn('Anuluj')}
              onMouseLeave={hoverTextOff}
              onClick={() => {
                toggleEditing(false);
                hoverTextOff();
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
            >
              <g
                id="Group_7259"
                data-name="Group 7259"
                transform="translate(-896.5 -388)"
              >
                <rect
                  id="Rectangle_2518"
                  data-name="Rectangle 2518"
                  width="19"
                  height="19"
                  rx="4"
                  transform="translate(896.5 388)"
                  fill="#ff0300"
                />
                <g
                  id="Icon_feather-trash"
                  data-name="Icon feather-trash"
                  transform="translate(901 391.5)"
                >
                  <path
                    id="Path_3663"
                    data-name="Path 3663"
                    d="M4.5,9h9.9"
                    transform="translate(-4.5 -6.8)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_3664"
                    data-name="Path 3664"
                    d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                    transform="translate(-6.4 -3)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </g>
            </svg>
          </EditHeaderSvgWrapper>
        </EditHeader>

        <PanelContainer
          isEditing={isEditing}
          blockWidth={Width}
          blockHeight={Height}
        >
          <AltPanelContent
            backgroundAlpha={BlockOpacityIn0To100Ratio}
            backgroundColor={BackgroundColorRgb}
            blockWidth={Width}
            overview={true}
          >
            <AltPanelTextWrapper fontColor={FontColorRgb} blockWidth={Width}>
              {renderBlockContent(
                isAdminMode,
                producerIdForEditBlock,
                roles,
                blockForEditing,
                theme,
                mobileOverview,
                isEditing,
                handleEditingBlock,
              )}
            </AltPanelTextWrapper>
          </AltPanelContent>
        </PanelContainer>
        <EditExtraOptionsWrapper>
          <EditOptionsWrapper Type={Type} Width={Width} Height={Height}>
            {Type === 1024 ||
            Type === 8 ||
            Type === 160 ||
            Type === 327680 ? null : (
              <SettingsItemWrapper isFirstItem={true}>
                <p>Szerokość</p>
                <SettingsCounterSvgWrapper>
                  <SettingsSvgBg
                    onClick={() =>
                      handleCounterChange('DECREASE', Width, 'Width')
                    }
                  >
                    <svg
                      onClick={() =>
                        handleCounterChange('DECREASE', Width, 'Width')
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>

                  <p>{Width}</p>
                  <SettingsSvgBg
                    onClick={() =>
                      handleCounterChange('INCREASE', Width, 'Width')
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>
                </SettingsCounterSvgWrapper>
              </SettingsItemWrapper>
            )}
            {Type === 1024 ||
            Type === 8 ||
            Type === 160 ||
            Type === 327680 ? null : (
              <SettingsItemWrapper>
                <p>Wysokość</p>
                <SettingsCounterSvgWrapper>
                  <SettingsSvgBg
                    onClick={() =>
                      handleCounterChange('DECREASE', Height, 'Height')
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>

                  <p>{Height}</p>
                  <SettingsSvgBg
                    onClick={() =>
                      handleCounterChange('INCREASE', Height, 'Height')
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>
                </SettingsCounterSvgWrapper>
              </SettingsItemWrapper>
            )}
            {Type === 1024 || Type === 8 || Type === 160 || Type === 327680 ? (
              <SettingsItemWrapper>
                <p>Układ</p>
                <SettingsCounterSvgWrapper>
                  <SettingsSvgBg
                    onClick={() =>
                      handleGridChange(
                        'DECREASE',
                        Height,
                        'Height',
                        Type,
                        Width,
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>

                  {Type === 327680 ? (
                    <p>
                      {Height === 3 && Width === 2
                        ? '1'
                        : Height === 3 && Width === 3
                        ? '2'
                        : Height === 4 && Width === 3
                        ? '3'
                        : Height === 4 && Width === 4
                        ? '4'
                        : Height === 4 && Width === 6
                        ? '5'
                        : '6'}
                    </p>
                  ) : Type === 160 ? (
                    <p>
                      {Width === 9
                        ? '5'
                        : Width === 7
                        ? '4'
                        : Width === 5
                        ? '3'
                        : Width === 4
                        ? '2'
                        : '1'}
                    </p>
                  ) : (
                    <p>
                      {Type === 1024
                        ? Height
                        : Height === 2
                        ? '1'
                        : Height === 3
                        ? '3'
                        : '2'}
                    </p>
                  )}
                  <SettingsSvgBg
                    onClick={() =>
                      handleGridChange(
                        'INCREASE',
                        Height,
                        'Height',
                        Type,
                        Width,
                      )
                    }
                  >
                    <svg
                      onClick={() =>
                        handleGridChange(
                          'INCREASE',
                          Height,
                          'Height',
                          Type,
                          Width,
                        )
                      }
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>
                </SettingsCounterSvgWrapper>
              </SettingsItemWrapper>
            ) : null}
            {/*<p>Ustawienia</p>*/}
            {Type === 128 || Type === 512 || Type === 16 ? null : (
              <SettingsItemWrapper>
                <p>KolorA </p>
                <SettingsCounterSvgWrapper>
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexFontColor(fontColorIndex, 'DECREASE')
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>

                  <ColorBlock
                    color={bgColors[fontColorIndex]}
                    currentColor={''}
                  ></ColorBlock>
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexFontColor(fontColorIndex, 'INCREASE')
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>
                </SettingsCounterSvgWrapper>
              </SettingsItemWrapper>
            )}
            {Type === 128 || Type === 512 || Type === 16 ? null : (
              <SettingsItemWrapper>
                <p>KolorB</p>
                <SettingsCounterSvgWrapper>
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexTitleColor(titleColorIndex, 'DECREASE')
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>

                  <ColorBlock
                    color={bgColors[titleColorIndex]}
                    currentColor={''}
                  ></ColorBlock>
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexTitleColor(titleColorIndex, 'INCREASE')
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>
                </SettingsCounterSvgWrapper>
              </SettingsItemWrapper>
            )}
            {Type === 1281 ? null : (
              <SettingsItemWrapper>
                <p>Kolor tła</p>
                <SettingsCounterSvgWrapper>
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexBackgroundColor(
                        backgroundColorIndex,
                        'DECREASE',
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>

                  {/*<p>{backgroundColor[backgroundColorIndex]}</p>*/}

                  <ColorBlock
                    // onClick={() => {
                    //   inputColorRef.current.click();
                    // }}
                    color={bgColors[backgroundColorIndex]}
                    currentColor={''}
                  ></ColorBlock>

                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexBackgroundColor(
                        backgroundColorIndex,
                        'INCREASE',
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>
                </SettingsCounterSvgWrapper>
              </SettingsItemWrapper>
            )}
            <SettingsItemWrapper>
              <SettingsWithSlider>
                <Slider
                  type="range"
                  min="0.05"
                  step="0.05"
                  max="1"
                  value={BlockOpacityIn0To100Ratio}
                  onChange={(e) => handleBackgroundAlphaChange(e)}
                />
              </SettingsWithSlider>
              <p> {BlockOpacityIn0To100Ratio} </p>
              <SettingsCounterSvgWrapper>
                {/* <SettingsSvgBg
                  onClick={() =>
                    handleCounterChange('DECREASE', Height, 'Height')
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5"
                    height="8.745"
                    viewBox="0 0 5 8.745"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-forward"
                      data-name="Icon ionic-ios-arrow-forward"
                      d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                      transform="translate(-11.246 -6.196)"
                      fill="#3a87f0"
                    />
                  </svg>
                </SettingsSvgBg> */}
                {/* <InputTypeNumber
                  type="number"
                  value={BlockOpacityIn0To100Ratio}
                  onChange={(e) => handleBackgroundAlphaChange(e)}
                  step="1"
                  min="0"
                  max="100"
                /> */}
                {/* <p>{BlockOpacityIn0To100Ratio}</p> */}
                {/* <SettingsSvgBg
                  onClick={() =>
                    handleCounterChange('INCREASE', Height, 'Height')
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5"
                    height="8.745"
                    viewBox="0 0 5 8.745"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-forward"
                      data-name="Icon ionic-ios-arrow-forward"
                      d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                      transform="translate(-11.246 -6.196)"
                      fill="#3a87f0"
                    />
                  </svg>
                </SettingsSvgBg> */}
              </SettingsCounterSvgWrapper>
            </SettingsItemWrapper>

            {Type === 1 ||
            Type === 2 ||
            Type === 8192 ||
            Type === 16384 ||
            Type === 131072 ||
            Type === 262144 ? (
              <SettingsItemWrapper>
                <p>Interlinia</p>
                <SettingsCounterSvgWrapper>
                  <SettingsSvgBg onClick={() => handleInterline('DECREASE')}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>

                  {/*<p>{backgroundColor[backgroundColorIndex]}</p>*/}
                  <p>{Interline}</p>
                  <SettingsSvgBg onClick={() => handleInterline('INCREASE')}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>
                </SettingsCounterSvgWrapper>
              </SettingsItemWrapper>
            ) : null}

            {Type === 1 ||
            Type === 2 ||
            Type === 8192 ||
            Type === 16384 ||
            Type === 131072 ||
            Type === 262144 ? (
              <SettingsItemWrapper>
                <p>Tekst H</p>
                <SettingsCounterSvgWrapper>
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexTextPositonHorizontal(
                        textPositionHorizontalIndex,
                        'DECREASE',
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>

                  {/*<p>{backgroundColor[backgroundColorIndex]}</p>*/}
                  <p>
                    {textPositionHorizontalIndex === 1
                      ? 'Center'
                      : textPositionHorizontalIndex === 2
                      ? 'Left'
                      : textPositionHorizontalIndex === 4
                      ? 'Right'
                      : textPositionHorizontalIndex === 8
                      ? 'Justifed'
                      : 'Left'}
                  </p>
                  {/* <p>{textPositionHorizontalIndex}</p> */}
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexTextPositonHorizontal(
                        textPositionHorizontalIndex,
                        'INCREASE',
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>
                </SettingsCounterSvgWrapper>
              </SettingsItemWrapper>
            ) : null}

            {Type === 1 ||
            Type === 2 ||
            Type === 8192 ||
            Type === 16384 ||
            Type === 131072 ||
            Type === 262144 ? (
              <SettingsItemWrapper>
                <p>Tekst V</p>
                <SettingsCounterSvgWrapper>
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexTextPositonVertical(
                        textPositionVerticalIndex,
                        'DECREASE',
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>

                  {/*<p>{backgroundColor[backgroundColorIndex]}</p>*/}
                  <p>
                    {textPositionVerticalIndex === 1
                      ? 'Top'
                      : textPositionVerticalIndex === 2
                      ? 'Center'
                      : textPositionVerticalIndex === 4
                      ? 'Down'
                      : textPositionVerticalIndex === 8
                      ? 'Justified'
                      : 'błąd'}
                  </p>
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexTextPositonVertical(
                        textPositionVerticalIndex,
                        'INCREASE',
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>
                </SettingsCounterSvgWrapper>
              </SettingsItemWrapper>
            ) : null}

            {Type === 262144 ? (
              <SettingsItemWrapper>
                <p>Maska </p>
                <SettingsCounterSvgWrapper>
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexImgPositonMask(
                        imgPositionMaskIndex,
                        'DECREASE',
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M12.754,10.567l3.309-3.307a.622.622,0,0,0,0-.883.63.63,0,0,0-.885,0l-3.749,3.747a.624.624,0,0,0-.018.862l3.765,3.773a.625.625,0,1,0,.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>

                  {/*<p>{backgroundColor[backgroundColorIndex]}</p>*/}
                  <p>{imgPositionMaskIndex}</p>
                  <SettingsSvgBg
                    onClick={() =>
                      handleIndexImgPositonMask(
                        imgPositionMaskIndex,
                        'INCREASE',
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="8.745"
                      viewBox="0 0 5 8.745"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-forward"
                        data-name="Icon ionic-ios-arrow-forward"
                        d="M14.739,10.567,11.43,7.261a.622.622,0,0,1,0-.883.63.63,0,0,1,.885,0l3.749,3.747a.624.624,0,0,1,.018.862l-3.765,3.773a.625.625,0,1,1-.885-.883Z"
                        transform="translate(-11.246 -6.196)"
                        fill="#3a87f0"
                      />
                    </svg>
                  </SettingsSvgBg>
                </SettingsCounterSvgWrapper>
              </SettingsItemWrapper>
            ) : null}
          </EditOptionsWrapper>
          {Type === 32768 || Type === 2048 || Type === 1024 || Type === 256 ? (
            <SelectWrapper>
              <StyledSelectView isEditing={true}>
                <p>Oferty</p>
                {Type === 32768 && (
                  <select
                    onChange={(e) => handleOfferSelectChange(e)}
                    disabled={
                      allOffers.length !== 0 &&
                      allOffers.filter((item) => item.ProductType !== 8)
                        .length === 0
                    }
                  >
                    <option hidden value={''}>
                      {allOffers.length !== 0 &&
                      allOffers.filter((item) => item.ProductType !== 8)
                        .length === 0
                        ? 'Brak aktywnych ofert'
                        : 'Wybierz'}
                    </option>
                    {allOffers.length !== 0 &&
                      allOffers
                        .filter((item) => item.ProductType !== 8)
                        .map((item) => (
                          <option value={JSON.stringify(item)} key={item.Id}>
                            {item.Name}
                          </option>
                        ))}
                  </select>
                )}
                {Type === 256 && (
                  <select
                    onChange={(e) => handleOfferSelectChange(e)}
                    disabled={
                      allOffers.length !== 0 &&
                      returnDiffrentElementFromTwoArr(
                        newOffers,
                        allOffers,
                      ).filter((item) => item.ProductType !== 8).length === 0
                    }
                  >
                    <option hidden value={''}>
                      {allOffers.length !== 0 &&
                      returnDiffrentElementFromTwoArr(
                        newOffers,
                        allOffers,
                      ).filter((item) => item.ProductType !== 8).length === 0
                        ? 'Brak aktywnych ofert'
                        : 'Wybierz'}
                    </option>
                    {allOffers.length !== 0 &&
                      returnDiffrentElementFromTwoArr(newOffers, allOffers)
                        .filter((item) => item.ProductType !== 8)
                        .map((item) => (
                          <option value={JSON.stringify(item)} key={item.Id}>
                            {item.Name}
                          </option>
                        ))}
                  </select>
                )}
                {Type === 2048 && (
                  <select
                    onChange={(e) => handleOfferSelectChange(e)}
                    disabled={
                      allOffers.length !== 0 &&
                      returnDiffrentElementFromTwoArr(
                        newOffers,
                        allOffers,
                      ).filter((item) => item.ProductType === 8).length === 0
                    }
                  >
                    <option hidden value={''}>
                      {allOffers.length !== 0 &&
                      returnDiffrentElementFromTwoArr(
                        newOffers,
                        allOffers,
                      ).filter((item) => item.ProductType === 8).length === 0
                        ? 'Brak aktywnych ofert'
                        : 'Wybierz'}
                    </option>
                    {allOffers.length !== 0 &&
                      returnDiffrentElementFromTwoArr(newOffers, allOffers)
                        .filter((item) => item.ProductType === 8)
                        .map((item) => (
                          <option value={JSON.stringify(item)} key={item.Id}>
                            {item.Name}
                          </option>
                        ))}
                  </select>
                )}

                {Type === 1024 && (
                  <select
                    onChange={(e) => handleOfferSelectChange(e)}
                    disabled={
                      allOffers.length !== 0 &&
                      allOffers.filter((item) => item.ProductType === 8)
                        .length === 0
                    }
                  >
                    <option hidden value={''}>
                      {allOffers.length !== 0 &&
                      allOffers.filter((item) => item.ProductType === 8)
                        .length === 0
                        ? 'Brak aktywnych ofert'
                        : 'Wybierz'}
                    </option>
                    {allOffers.length !== 0 &&
                      allOffers
                        .filter((item) => item.ProductType === 8)
                        .map((item) => (
                          <option value={JSON.stringify(item)} key={item.Id}>
                            {item.Name}
                          </option>
                        ))}
                  </select>
                )}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.439"
                  height="11"
                  viewBox="0 0 6.439 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(6.439 11) rotate(180)"
                  >
                    <path
                      id="Arrow"
                      d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                      transform="translate(0 0)"
                      fill="#3986ef"
                    />
                  </g>
                </svg>
              </StyledSelectView>
            </SelectWrapper>
          ) : null}

          {Type === 4096 && (
            <LinksWrapper>
              <OneLinkWrapper isMargin={true}>
                <InputForLink>
                  <p>Link</p>
                  <input
                    onChange={(e) => handleInputsUrl(e, 0)}
                    value={urlInputs.Link}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrl(urlInputs.Link, 0)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeUrlFromUrls(urlLinks, 0)}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
              <OneLinkWrapper>
                <InputForLink>
                  <p>Instagram</p>
                  <input
                    onChange={(e) => handleInputsUrl(e, 4)}
                    value={urlInputs.Instagram}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrl(urlInputs.Instagram, 4)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeUrlFromUrls(urlLinks, 4)}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
              <OneLinkWrapper isMargin={true}>
                <InputForLink>
                  <p>Facebook</p>
                  <input
                    onChange={(e) => handleInputsUrl(e, 1)}
                    value={urlInputs.Facebook}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrl(urlInputs.Facebook, 1)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeUrlFromUrls(urlLinks, 1)}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
              <OneLinkWrapper>
                <InputForLink>
                  <p>Soundcloud</p>
                  <input
                    onChange={(e) => handleInputsUrl(e, 3)}
                    value={urlInputs.Souncloud}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrl(urlInputs.Souncloud, 3)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeUrlFromUrls(urlLinks, 3)}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
              <OneLinkWrapper isMargin={true}>
                <InputForLink>
                  <p>X</p>
                  <input
                    onChange={(e) => handleInputsUrl(e, 2)}
                    value={urlInputs.X}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrl(urlInputs.X, 2)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeUrlFromUrls(urlLinks, 2)}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
              <OneLinkWrapper>
                <InputForLink>
                  <p>Pinterest</p>
                  <input
                    onChange={(e) => handleInputsUrl(e, 5)}
                    value={urlInputs.Pinterest}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrl(urlInputs.Pinterest, 5)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeUrlFromUrls(urlLinks, 5)}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
              <OneLinkWrapper isMargin={true}>
                <InputForLink>
                  <p>Tiktok</p>
                  <input
                    onChange={(e) => handleInputsUrl(e, 6)}
                    value={urlInputs.Tiktok}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrl(urlInputs.Tiktok, 6)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeUrlFromUrls(urlLinks, 6)}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
              <OneLinkWrapper isMargin={false}>
                <InputForLink>
                  <p>YouTube</p>
                  <input
                    onChange={(e) => handleInputsUrl(e, 7)}
                    value={urlInputs.YouTube}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrl(urlInputs.YouTube, 7)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeUrlFromUrls(urlLinks, 7)}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
              <OneLinkWrapper isMargin={false}>
                <InputForLink>
                  <p>Spotify</p>
                  <input
                    onChange={(e) => handleInputsUrl(e, 8)}
                    value={urlInputs.Spotify}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrl(urlInputs.Spotify, 8)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeUrlFromUrls(urlLinks, 8)}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
            </LinksWrapper>
          )}

          {Type === 65536 && (
            <LinksWrapper>
              <OneLinkWrapper isMargin={true}>
                <InputForLink>
                  <p>YouTube</p>
                  <input
                    onChange={(e) => handleYtUrl(e)}
                    value={urlForYt.Url}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrlForYt(urlForYt)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeYtUrl()}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
            </LinksWrapper>
          )}
          {Type === 524288 && (
            <LinksWrapper>
              <OneLinkWrapper isMargin={true}>
                <InputForLink>
                  <p>SpotifyUrl</p>
                  <input
                    onChange={(e) => handleSpotifyUrl(e)}
                    value={urlForSpotify}
                    placeholder="Uzupełnij"
                  />
                </InputForLink>
                <svg
                  onClick={() => addNewUrlForSpotifyUrl(urlForSpotify)}
                  className="save"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7260"
                    data-name="Group 7260"
                    transform="translate(-810.5 -324.5)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(810.5 324.5)"
                      fill="#0091ff"
                    />
                    <g
                      id="Icon_feather-save"
                      data-name="Icon feather-save"
                      transform="translate(810.5 324.5)"
                    >
                      <path
                        id="Path_3700"
                        data-name="Path 3700"
                        d="M13.389,14.5H5.611A1.111,1.111,0,0,1,4.5,13.389V5.611A1.111,1.111,0,0,1,5.611,4.5h6.111L14.5,7.278v6.111A1.111,1.111,0,0,1,13.389,14.5Z"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3701"
                        data-name="Path 3701"
                        d="M16.056,23.944V19.5H10.5v4.444"
                        transform="translate(-3.778 -9.444)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3702"
                        data-name="Path 3702"
                        d="M10.5,4.5V7.278h4.444"
                        transform="translate(-3.778)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
                <svg
                  onClick={() => removeSpotifyUrl()}
                  className="delete"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                >
                  <g
                    id="Group_7259"
                    data-name="Group 7259"
                    transform="translate(-896.5 -388)"
                  >
                    <rect
                      id="Rectangle_2518"
                      data-name="Rectangle 2518"
                      width="19"
                      height="19"
                      rx="4"
                      transform="translate(896.5 388)"
                      fill="#ff0300"
                    />
                    <g
                      id="Icon_feather-trash"
                      data-name="Icon feather-trash"
                      transform="translate(901 391.5)"
                    >
                      <path
                        id="Path_3663"
                        data-name="Path 3663"
                        d="M4.5,9h9.9"
                        transform="translate(-4.5 -6.8)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                      <path
                        id="Path_3664"
                        data-name="Path 3664"
                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                        transform="translate(-6.4 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                </svg>
              </OneLinkWrapper>
            </LinksWrapper>
          )}
        </EditExtraOptionsWrapper>
      </EditWrapper>
    </EditContainer>
  );
};

export default EditBlockComponent;
