import styled from 'styled-components';

export const SelectContainer = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  position: absolute;
  top: -8px;
  right: ${({isEditing}) => (isEditing ? '95px' : '60px')};
  width: 50px;
  margin-top: 9px;
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};
  cursor: pointer;
  outline: none;
  background: ${({isEditing, isError}) =>
    isEditing
      ? isEditing && isError
        ? '#FF7E7D'
        : 'transparent'
      : 'transparent'};
  border: none;
  border-radius: ${({isEditing, isError}) =>
    isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
  color: ${({isEditing}) => (isEditing ? `#707070` : `#07020F`)};
  height: 23.5px;
  text-align: right;
  font-size: 13px;
  padding-right: ${({isEditing}) => (isEditing ? `14.5px` : `19.5px`)};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  & > p {
    position: absolute;
    //color: #07020f;
    color: ${({isEditing}) => (!isEditing ? `#707070` : `#07020F`)};
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.625px;
    font-weight: 400;
    left: 8.5px;
    top: 0px;
    cursor: default;
  }

  & > span {
    line-height: 22px;
    color: ${({isEditing}) => (isEditing ? `#707070` : `#07020F`)};
    /* padding: 5px 20px 5px 0px; */
  }

  & > svg {
    display: ${({isEditing}) => (isEditing ? `block` : `none`)};
    position: absolute;
    top: 6px;
    right: 4px;
  }
  & > select:focus {
    background: #f2f7ff;
  }
  @media (max-width: 1024px) {
    top: -5px;
    right: 85px;
    & > span {
      line-height: 20px;
    }
    & > svg {
      right: 5px;
      top: 5px;
    }
  }
`;

export const DropDownStyle = styled.div<{isVisible: boolean}>`
  position: absolute;
  top: 21px;
  left: 0;
  width: 100%;
  max-height: 115px;
  padding: 2px 0px 2px 8px;
  display: flex;
  flex-direction: column;
  background: #dce0e9;
  z-index: 1;
  border: 1px solid #dce0e9;
  transition: max-height 0.2s ease;
  overflow-y: scroll;
  visibility: ${({isVisible}) => (isVisible ? 'unset' : 'hidden')};
  &::-webkit-scrollbar {
    width: 7px;
    height: 5px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #3a87f0;
    border-radius: 25px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
`;

export const DropdownItem = styled.div<{isActive: boolean}>`
  width: 100%;
  background: ${({isActive}) => (isActive ? '#89BFFF' : 'transparent')};
  border-radius: 7px;
  height: 22px;
  font-size: 11px;
  line-height: 24px;
  text-align: center;
  &:hover {
    background: #89bfff;
  }
`;
