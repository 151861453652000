import React, {useContext, useRef, useState} from 'react';
import {
  DemoAddButonInEditWrapper,
  DemoAddButonWrapper,
  DemoEditWrapper,
  DemoReelBlockContent,
  DemoReelBlockHeader,
  DemoReelBlockWrapper,
  DemoReelWrapper,
} from './styles';
import {Block, BlockFile} from '../../interface';
import api from '../../../../../services/axiosConfig';
import {useCookies} from 'react-cookie';

import SingleDemo from './SingleDemo';
import useAlert from 'hooks/useAlert';
import {hexToRgb} from '../../index';
import {EditHoverContext} from 'contexts/EditHoverContext';

export interface DemoReelBlock extends Block {
  BlockFileIds: string[];
  BlockFiles: BlockFile[];
}

interface Props {
  theme: string;
  block: DemoReelBlock;
  isEditing: boolean;
  mobileOverview: boolean;
  updateDemoGallery: ({
    blockFileIds: BlockFileIds,
    blockFiles: BlockFiles,
  }) => void;
}

const DemoReelBlockComponent: React.FC<Props> = ({
  theme,
  block,
  mobileOverview,
  updateDemoGallery,
  isEditing,
}) => {
  const {FontColorHex, BlockFiles, WebArea} = block;
  const {Width, Height} = WebArea;

  const initDemo = BlockFiles
    ? BlockFiles
    : [{BlockId: '', CreationDate: '', FileName: '', FilePath: '', Id: ''}];

  const {Alert} = useAlert();

  const [demos, setDemos] = useState<BlockFile[]>(initDemo);
  const [demoFile, setDemoFile] = useState();
  const [demoName, setDemoName] = useState('');
  const [isAdding, setIsAdding] = useState<boolean>(false);

  const [cookies, setCookie] = useCookies(['token']);

  const demoRef = useRef<HTMLInputElement>(document.createElement('input'));

  const [blockHover, setBlockHover] = useContext(EditHoverContext);

  const deleteDemo = (id: string) => {
    setDemos((prev) => prev.filter((item) => item.Id !== id));
    const demoAfterDelete = demos.filter((demo) => demo.Id !== id);
    updateDemoGallery({
      blockFileIds: demoAfterDelete.map((demo) => demo.Id),
      blockFiles: demoAfterDelete,
    });
  };

  const handleDemoNameChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setDemoName(e.target.value);

  const getDemoFile = async (e: any) => {
    if (demoName.length < 2) return alert('Nazwa musi być dłuższa niż 2 znaki');
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();
      try {
        if (
          extFile === 'mp4' ||
          extFile === 'ico' ||
          extFile === 'rar' ||
          extFile === 'rtf' ||
          extFile === 'srt' ||
          extFile === 'flac' ||
          extFile === 'wav' ||
          extFile === 'mp3'
        ) {
          const formData = new FormData();
          formData.append('File', e.target.files[0]);
          const body = formData;
          const response = await api.post(
            `Producer/block/${block.Id}/BlockFile`,
            body,
            {
              headers: {
                Authorization: `bearer ${cookies.token}`,
                fileName: demoName,
              },
            },
          );
          const newBlockFile = {
            BlockId: block.Id,
            CreationDate: '',
            FileName: demoName,
            FilePath: URL.createObjectURL(e.target.files[0]),
            Id: response.data.Response.Id,
          };
          updateDemoGallery({
            blockFileIds: [
              ...demos.map((demo) => demo.Id),
              response.data.Response.Id,
            ],
            blockFiles: [...demos, newBlockFile],
          });
          setDemoFile(e.target.files[0].name);
          setDemos((prev) => [...prev, newBlockFile]);
          setDemoName('');
          setIsAdding(false);
          Alert(`Dodano plik ${e.target.files[0].name}`, 'Komunikat');
        } else {
          Alert('Zły format pliku', 'Komunikat');
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      return;
    }
  };

  const hoverTextOn = (text: string) => {
    setBlockHover((prev) => ({Active: true, Text: text}));
  };

  const hoverTextOff = () => {
    setBlockHover((prev) => ({Active: false, Text: ''}));
  };

  return (
    <DemoReelBlockWrapper>
      <DemoReelBlockContent>
        {[
          ...demos,
          {
            BlockId: '',
            CreationDate: '',
            FileName: '',
            FilePath: '',
            Id: 'Add',
          },
        ].map((audio, index) => {
          if (audio.Id.length > 0 && audio.Id !== 'Add') {
            return (
              <SingleDemo
                key={audio.Id}
                BlockAudio={audio}
                theme={theme}
                isEditing={isEditing}
                deleteDemo={deleteDemo}
                Height={Height}
                Width={Width}
                fontColor={hexToRgb(FontColorHex)}
              />
            );
          } else if (isEditing) {
            return (
              <DemoReelWrapper
                Height={Height}
                Width={Width}
                key={audio.Id}
                theme={theme}
              >
                {!isAdding ? (
                  <DemoAddButonWrapper
                    theme={theme}
                    onClick={() => setIsAdding(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <g
                        id="Icon_feather-plus-circle"
                        data-name="Icon feather-plus-circle"
                        transform="translate(0.5 0.5)"
                        opacity="0.76"
                      >
                        <path
                          id="Path_3319"
                          data-name="Path 3319"
                          d="M11,7A4,4,0,1,1,7,3,4,4,0,0,1,11,7Z"
                          transform="translate(-3 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3320"
                          data-name="Path 3320"
                          d="M18,12v3.2"
                          transform="translate(-14 -9.6)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                        <path
                          id="Path_3321"
                          data-name="Path 3321"
                          d="M12,18h3.2"
                          transform="translate(-9.6 -14)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1"
                        />
                      </g>
                    </svg>
                  </DemoAddButonWrapper>
                ) : (
                  <DemoEditWrapper theme={theme}>
                    <textarea
                      onChange={handleDemoNameChange}
                      value={demoName}
                      placeholder="Podaj nazwę..."
                    />
                    <DemoAddButonInEditWrapper
                      onClick={() => demoRef.current.click()}
                      theme={theme}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                      >
                        <g
                          id="Icon_feather-plus-circle"
                          data-name="Icon feather-plus-circle"
                          transform="translate(0.5 0.5)"
                          opacity="0.76"
                        >
                          <path
                            id="Path_3319"
                            data-name="Path 3319"
                            d="M11,7A4,4,0,1,1,7,3,4,4,0,0,1,11,7Z"
                            transform="translate(-3 -3)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                          <path
                            id="Path_3320"
                            data-name="Path 3320"
                            d="M18,12v3.2"
                            transform="translate(-14 -9.6)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                          <path
                            id="Path_3321"
                            data-name="Path 3321"
                            d="M12,18h3.2"
                            transform="translate(-9.6 -14)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                        </g>
                      </svg>
                      <input
                        type={'file'}
                        onChange={getDemoFile}
                        ref={demoRef}
                        hidden
                        accept=".mp4,.mp3"
                      />
                    </DemoAddButonInEditWrapper>
                  </DemoEditWrapper>
                )}
              </DemoReelWrapper>
            );
          }
        })}
      </DemoReelBlockContent>
    </DemoReelBlockWrapper>
  );
};
export default DemoReelBlockComponent;
