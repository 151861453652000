import styled, {keyframes} from 'styled-components';

export const TrackRowWrapper = styled.div<{
  bgColor: string;
  Width: number;
  isMobile: boolean;
}>`
  //padding: 5px;
  width: 100%;
  /* height: 100%; */
  //background: #ffffff;
  background: ${({bgColor}) => `rgba(${bgColor})`};
  border-radius: 24px;
  display: flex;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.21);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);

  & > input {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    opacity: 0.3;
    background: ${({bgColor}) => `linear-gradient(
      to right,
      rgba(${bgColor}) 0%,
      rgba(${bgColor}) 60%,
      transparent 60%,
      transparent 100%
    )`};
    width: 100%;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
  }

  & > input::-webkit-slider-thumb {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-appearance: none;
    cursor: ew-resize;
    background: #434343;
  }
  @media (max-width: 425px) {
    /* height: ${({Width}) => (Width === 3 ? '100%' : '251px')}; */
  }

  @media (max-width: 375px) {
    /* height: ${({Width}) => (Width === 3 ? '100%' : '218px')}; */
  }

  @media (max-width: 320px) {
    /* height: ${({Width}) => (Width === 3 ? '100%' : '144px')}; */
  }
`;

export const TrackRowImgWrapper = styled.div<{
  Width: number;
  isMobile: boolean;
}>`
  /* height: 284px; */
  height: ${({isMobile}) => (isMobile ? '182px ' : '284px')};
  width: ${({isMobile}) => (isMobile ? '182px ' : '284px')};
  min-height: ${({isMobile}) => (isMobile ? '182px ' : '284px')};
  min-width: ${({isMobile}) => (isMobile ? '182px ' : '284px')};
  align-self: center;
  position: relative;
  border: 1px solid transparent;
  //border-radius: 13px 0px 0px 13px;
  border-right: none;
  & > img {
    border-radius: 22px;
    /* height: 100%;
    width: 100%; */
    max-width: ${({isMobile}) => (isMobile ? '182px ' : '284px')};
    height: 100%;
  }
  & > svg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 90;
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    height: 182px;
    width: 182px;
    min-height: 182px;
    min-width: 182px;
    top: 50%;
    transform: translate(0, -50%);
    & > img {
      max-width: 180px;
    }
  }

  @media (max-width: 415px) {
    height: 172px;
    width: 172px;
    min-height: 172px;
    min-width: 172px;
    top: 50%;
    transform: translate(0, -50%);
    & > img {
      max-width: 170px;
    }
    & > svg {
      left: 50%;
    }
  }

  @media (max-width: 390px) {
    height: 162px;
    width: 162px;
    min-height: 162px;
    min-width: 162px;
    top: 50%;
    transform: translate(0, -50%);
    & > img {
      max-width: 160px;
    }
  }

  @media (max-width: 375px) {
    height: 152px;
    width: 152px;
    min-height: 152px;
    min-width: 152px;
    top: 50%;
    transform: translate(0, -50%);
    & > img {
      max-width: 150px;
    }
  }
  @media (max-width: 360px) {
    height: 142px;
    width: 142px;
    min-height: 142px;
    min-width: 142px;
    top: 50%;
    transform: translate(0, -50%);
    & > img {
      max-width: 140px;
    }
  }

  @media (max-width: 330px) {
    height: 132px;
    width: 132px;
    min-height: 132px;
    min-width: 132px;
    top: 50%;
    transform: translate(0, -50%);
    & > img {
      max-width: 130px;
    }
  }

  @media (max-width: 320px) {
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export const GradientForImg = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  border-radius: 13px;
`;

export const TrackRowSvgContent = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const TrackRowTextContent = styled.div<{isMobile: boolean}>`
  /* padding: 40px 0; */
  margin-left: 58px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({isMobile}) => (isMobile ? '17px 0 ' : '40px 0')};

  //opacity: 0.5;
  margin-left: ${({isMobile}) => (isMobile ? '15px' : '58px')};
  @media (max-width: 1024px) {
    padding: 17px 0;
    margin-left: 15px;
  }
`;

export const TrackRowNameTrack = styled.h2<{Width: number; isMobile: boolean}>`
  font-size: ${({isMobile}) => (isMobile ? '21px' : '24px')};
  margin-right: ${({isMobile}) => (isMobile ? '0px' : '58px')};
  line-height: 31px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  letter-spacing: -0.25px;
  overflow: hidden;
  width: auto;
  display: ${({isMobile}) => (isMobile ? '-webkit-box' : 'block')};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;

  @media (max-width: 1024px) {
    width: auto;
    margin-right: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 21px;
  }

  @media (max-width: 360px) {
    font-size: 18px;
    line-height: 24px;
  }
  @media (max-width: 320px) {
    /* padding-top: 0px; */
  }
`;

export const TrackRowDescription = styled.div`
  height: 202px;
  padding-bottom: 10px;
  width: 509px;
  overflow: hidden;
  margin-top: 10px;
  & > p {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.15px;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }
`;

export const TrackRowCategoryWrapper = styled.div<{Width: number}>`
  display: flex;
  width: 100%;
  /* margin-left: 10px; */
  & > p {
    opacity: 0.5;
    font-size: 15px;
    letter-spacing: -0.25px;
    line-height: 18px;
  }
  @media (max-width: 1024px) {
    /* margin-left: 5px; */
    & > p {
      font-size: 13px;
    }
  }
`;

export const TrackRowProductType = styled.div<{Width: number}>`
  font-size: ${({Width}) => (Width === 3 || Width === 5 ? '11px' : '18px')};
  letter-spacing: -0.25px;
  color: #17181b;
  padding-bottom: ${({Width}) => (Width === 3 ? '1px' : '0px')};
  line-height: ${({Width}) => (Width === 3 || Width === 5 ? '32px' : '32px')};
`;
export const TrackRowFooterWrapper = styled.div`
  width: 100%;
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
`;
export const TrackRowPriceWrapper = styled.div<{
  Width: number;
  isMobile: boolean;
}>`
  width: 100%;
  display: flex;

  gap: ${({isMobile}) => (isMobile ? '5px' : '10px')};
  & > p {
    /* line-height: 15px; */
    font-size: ${({isMobile}) => (isMobile ? '16px' : '24px')};
    letter-spacing: -0.15px;
    color: white;
    font-family: 'Inter', sans-serif;

    font-weight: bold;
    /* margin-left: 10px; */
  }

  @media (max-width: 1024px) {
    gap: 5px;
    & > p {
      /* line-height: 32px;
      f */
      font-size: 16px;
    }
  }

  @media (max-width: 360px) {
    & > p {
      font-size: 14px;
    }
  }

  @media (max-width: 320px) {
    & > p {
      /* line-height: 24px; */
    }
  }
`;

export const TrackRowWinWin = styled.div<{Width: number; isMobile: boolean}>`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-around;
  -webkit-justify-content: space-around;
  -ms-flex-pack: space-around;
  justify-content: space-around;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0px;
  margin-right: ${({isMobile}) => (isMobile ? '0px' : '5px')};
  width: ${({isMobile}) => (isMobile ? '73px' : '82px')};
  height: 20px;
  gap: ${({isMobile}) => (isMobile ? '0px' : '5px')};

  & > p {
    line-height: ${({isMobile}) => (isMobile ? '24px' : '15px')};
    font-size: 24px;
    letter-spacing: -0.15px;
    color: white;
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    gap: 0px;
    width: 73px;
    & > p {
      line-height: 24px;
    }
  }

  @media (max-width: 375px) {
    & > p {
      line-height: 24px;
    }
  }
  @media (max-width: 360px) {
    height: 18px;
  }

  @media (max-width: 320px) {
    & > p {
      line-height: 24px;
    }
  }
`;
const ldsRingAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const LdsRing = styled.div`
  /* top: -50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  z-index: 99;
  display: inline-block;
  position: relative;
  width: 48px;
  height: 48px;
`;

export const LdsRingDiv = styled.div<{color: string}>`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 48px;
  height: 48px;
  /* margin: 8px; */
  border: ${({color}) => `8px solid ${color}`};
  border-radius: 50%;
  animation: ${ldsRingAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({color}) => ` ${color} transparent transparent transparent`};

  &:nth-child(1) {
    animation-delay: -0.45s;
  }

  &:nth-child(2) {
    animation-delay: -0.3s;
  }

  &:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export const PlayIconWrapper = styled.div<{isMobile: boolean}>`
  position: absolute;
  /* right: 39px;
  bottom: 36px; */
  z-index: 99;
  right: ${({isMobile}) => (isMobile ? 'none' : '39px')};
  left: ${({isMobile}) => (isMobile ? '70px' : 'none')};
  bottom: ${({isMobile}) => (isMobile ? '60px' : '36px')};
  cursor: pointer;
  & > svg {
    width: 48px;
    height: 48px;
  }
  @media (max-width: 1024px) {
    right: none;
    left: 70px;
    bottom: 60px;
  }
  @media (max-width: 390px) {
    left: 55px;
  }
  @media (max-width: 375px) {
    left: 55px;
    bottom: 55px;
  }
  @media (max-width: 360px) {
    left: 45px;
    bottom: 45px;
  }
`;
