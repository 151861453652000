import styled from 'styled-components';

export const VideoBlockWrapper = styled.div<{
  bgColor: string;
  backgroundAlpha: number;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  background: ${({bgColor, backgroundAlpha}) =>
    `rgb(${bgColor},${backgroundAlpha})`};
`;

export const VideoBlockHeader = styled.div<{theme?: string}>`
  height: 10%;
  width: 100%;
  display: flex;
  gap: 11px;
  align-items: center;

  & > h4 {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    padding: 0 5px;
  }

  & > svg {
    width: 27.38px;
    height: 21px;
    & > path {
      fill: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  @media (max-width: 1065px) {
    height: 5%;
  }
  @media (max-width: 920px) {
    & > h4 {
      font-size: 16px;
    }
    & > svg {
      height: 16px;
    }
  }
  @media (max-width: 780px) {
    & > h4 {
      font-size: 14px;
    }
    & > svg {
      height: 14px;
    }
  }
  @media (max-width: 720px) {
    & > h4 {
      font-size: 12px;
    }
    & > svg {
      height: 12px;
    }
  }
  @media (max-width: 650px) {
    & > h4 {
      padding: 0;
      font-size: 11px;
    }
    & > svg {
      height: 11px;
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      font-size: 10px;
    }
    & > svg {
      height: 10px;
    }
  }
  @media (max-width: 550px) {
    & > h4 {
      font-size: 8px;
    }
    & > svg {
      height: 8px;
    }
  }
  @media (max-width: 430px) {
    & > h4 {
      font-size: 6px;
    }
    & > svg {
      height: 6px;
    }
  }
`;

export const VideoBlockContent = styled.div`
  width: 100%;
  height: 100%;
`;

////////////////////////////////

export const VideoBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  & > h1 {
    color: white;
  }
`;

export const VideoWrapper = styled.div<{Width: number; Height: number}>`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  padding-top: 16px;
  justify-content: center;
  //overflow: hidden;
  border-radius: 10px;
  & > video {
    border: 1px solid rgba(255, 255, 255, 0.21);
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    height: ${({Height, Width}) =>
      Height === 2 && Width === 2
        ? '194px'
        : Height === 4 && Width === 2
        ? '345px'
        : '300px'};
    width: ${({Height, Width}) =>
      Height === 2 && Width === 2
        ? '194px'
        : Height === 4 && Width === 2
        ? '194px'
        : '534px'};
    object-fit: cover;
  }
`;

export const VideoControls = styled.div<{Width: number; Height: number}>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  bottom: 0px;
  padding: 14px;
  width: 100%;
  max-width: 450px;
  flex-wrap: wrap;
  //background: rgba(255, 255, 255, 0.25);
  //box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.1);
  //backdrop-filter: blur(4px);
  //-webkit-backdrop-filter: blur(4px);
  //border-radius: 10px;
  //border: 1px solid rgba(255, 255, 255, 0.18);
  //transform: translateY(150%);
  transition: all 0.3s ease-in-out;

  & > input[type='range'] {
    position: absolute;
    bottom: ${({Width}) => (Width === 2 ? '5px' : '10px')};
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    overflow: hidden;
    appearance: none;
    height: 5px;
    width: ${({Width}) => (Width === 2 ? '157px' : '493px')};
    &:focus {
      outline: none;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 5px;
      width: 5px;
      background: #fff;
      border-radius: 50%;
      box-shadow: -100vw 0 0 100vw #fff;
    }
  }
`;

export const VideoActions = styled.div<{Width: number; Height: number}>`
  height: 41px;
  width: 52px;
  & > button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
`;

export const VideoBlockVelocity = styled.select`
  appearance: none;
  background: none;
  color: white;
  outline: none;
  border: none;
  text-align: center;
  font-size: 16px;
  & > option {
    color: gray;
  }
`;

export const MuteBtn = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  & > svg {
    width: 24px;
    height: 24px;
    fill: white;
  }
`;
export const FullScreenBtn = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  & > svg {
    width: 24px;
    height: 24px;
    fill: white;
  }
`;

export const VideoAddButtonWrapper = styled.div<{theme: string}>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 48px;
    height: 48px;
    cursor: pointer;
    & > g {
      & > path {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};
      }
    }
  }
`;

export const DeleteButton = styled.button<{
  theme: string;
  Width: number;
  Height: number;
}>`
  position: absolute;
  top: 10px;
  right: 15px;
  background-color: transparent;
  font-size: 16px;
  font-weight: bold;
  padding: 5px;
  border: none;
  color: ${({theme}) =>
    theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  cursor: pointer;
`;
