import React, {useContext, useState} from 'react';
import {
  ImgRowFullImgWrapper,
  ImgRowWrapper,
  ImgSliderWrapper,
  SliderWrapper,
} from './styles';
import styled from 'styled-components';
import {BlockFile} from '../../../interface';
import Slider from 'react-slick';

import {ResolutionContext} from 'contexts/ResolutionContext';
import {useImage} from 'contexts/ImageContext';

const ImgRowLastImgWrapper = styled.div<{Width: number}>`
  flex-grow: 1;
  height: 305px;
  border-radius: 13px;
  & > img {
    border-radius: 13px;
    height: 305px;
    width: 305px;
  }
`;

interface Props {
  Width: number;
  Height: number;
  Images: BlockFile[];
  ImgIndex: number;
  isEditing: boolean;
  handleSelected: (id, base) => void;
  selectedId: string;
  isMobile: boolean;
}

export const ImgRow: React.FC<Props> = ({
  Width,
  Images,
  ImgIndex,
  isEditing,
  handleSelected,
  selectedId,
  isMobile,
  Height,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const {windowWidth} = useContext(ResolutionContext);
  const {selectImage} = useImage();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  function divideArr(arr, chunkSize: number) {
    const result = [];
    if (isMobile || windowWidth <= 1024) {
      for (let i = 0; i < arr.length; i += 2) {
        result.push(arr.slice(i, i + 2));
      }
    } else {
      for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
      }
    }

    return result;
  }

  function howMuchDots(number, chunkSize: number) {
    if (isMobile || windowWidth <= 1024) {
      return Math.ceil(number / 2);
    }
    return Math.ceil(number / chunkSize);
  }

  return (
    <ImgRowWrapper isMobile={isMobile}>
      {/* {Images[ImgIndex] === undefined ? null : (
        <ImgRowFullImgWrapper>
          <img src={Images[ImgIndex].FilePath} alt="IMG" />
        </ImgRowFullImgWrapper>
      )}
      {Images[ImgIndex + 1] === undefined ? null : Width >= 7 ? (
        <ImgRowFullImgWrapper style={isEditing ? {opacity: '0.25'} : null}>
          <img src={Images[ImgIndex + 1].FilePath} alt="IMG" />
        </ImgRowFullImgWrapper>
      ) : null}
      {Images[ImgIndex + 2] === undefined ? null : Width >= 9 ? (
        <ImgRowFullImgWrapper style={isEditing ? {opacity: '0.25'} : null}>
          <img src={Images[ImgIndex + 2].FilePath} alt="IMG" />
        </ImgRowFullImgWrapper>
      ) : null}
      {Images[ImgIndex + 3] === undefined ? null : Width >= 9 ? (
        <ImgRowFullImgWrapper style={isEditing ? {opacity: '0.25'} : null}>
          <img src={Images[ImgIndex + 3].FilePath} alt="IMG" />
        </ImgRowFullImgWrapper>
      ) : null}
      {Images[ImgIndex + 4] === undefined ? null : Width >= 9 ? (
        <ImgRowFullImgWrapper style={isEditing ? {opacity: '0.25'} : null}>
          <img src={Images[ImgIndex + 4].FilePath} alt="IMG" />
        </ImgRowFullImgWrapper>
      ) : null} */}
      {/* {Images[ImgIndex + (Width === 9 ? 3 : Width === 7 ? 2 : 1)] ===
      undefined ? null : (
        <ImgRowLastImgWrapper
          style={isEditing ? {opacity: '0.25'} : null}
          Width={Width}
        >
          <img
            src={
              Images[ImgIndex + (Width === 9 ? 3 : Width === 7 ? 2 : 1)]
                .FilePath
            }
            alt="IMG"
          />
        </ImgRowLastImgWrapper>
      )} */}
      <SliderWrapper isBig={Height >= 5} isMobile={isMobile}>
        <Slider {...settings}>
          {[...new Array(howMuchDots(Images.length, Height >= 5 ? 3 : 5))].map(
            (array, index) => {
              return (
                <ImgSliderWrapper
                  isBig={Height >= 5}
                  isMobile={isMobile}
                  key={index}
                >
                  {divideArr(Images, Height >= 5 ? 3 : 5)[index].map(
                    (blockFile, index) => {
                      const {Id, FilePath, FileName} = blockFile;

                      return (
                        <ImgRowFullImgWrapper
                          isBig={Height >= 5}
                          isMobile={isMobile}
                          isSelected={Id === selectedId}
                          onClick={
                            isEditing
                              ? () => handleSelected(Id, FileName)
                              : () => selectImage(FilePath)
                          }
                          key={Id}
                        >
                          <img src={FilePath} />
                        </ImgRowFullImgWrapper>
                      );
                    },
                  )}
                </ImgSliderWrapper>
              );
            },
          )}
        </Slider>
      </SliderWrapper>
    </ImgRowWrapper>
  );
};
