import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const SingleProductWithViewsCountWrapper = styled.div<{
  isHovered?: boolean;
}>`
  height: 160px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const SingleProductWrapper = styled.div<{
  isHovered?: boolean;
}>`
  /* height: 170px; */
  width: 100%;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.producersBorderColor};
  background: ${({theme}) => theme.colors.tracksTopSectionBackground};
  border-radius: 16px;
  display: flex;
  position: relative;
  cursor: pointer;
  transition: all 0.15s;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const StyledMobileLink = styled(Link)`
  text-decoration: none;
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: unset;
    width: 100%;
  }
`;

export const MobileSingleProductWithViewsCountWrapper = styled.div<{
  isHovered?: boolean;
}>`
  height: 170px;
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const MobileViewsCount = styled.div<{isFollow: boolean}>`
  /* width: 100%; */
  /* width: 100%; */
  display: flex;
  align-items: center;
  /* justify-content: center; */

  gap: 10px;

  /* position: absolute;
    bottom: 10px;
    left: 126px; */

  & > p {
    color: ${({theme}) => theme.colors.viewsCountColor};
    font-weight: 400;
    letter-spacing: -0.05px;
    font-size: 11px;
  }
  & > svg {
    width: 21px;
    margin-top: 3px;
    & > path {
      fill: ${({theme, isFollow}) =>
        isFollow ? theme.colors.blueTheme : theme.colors.bgBlack};
    }
  }
  @media (max-width: 1024px) {
    margin-left: 7px;
    margin-top: 5px;
    width: auto;
    & > p {
      width: 220px;
    }
    & > svg {
      /* margin-left: 8px; */
      /* position: absolute;
      right: 15px;
      bottom: 9px; */
      margin-top: 0px;
    }
  }
  @media (max-width: 400px) {
    & > svg {
      /* margin-left: 8px; */
    }
  }
  @media (max-width: 375px) {
    & > p {
      font-size: 10px;
      width: 202px;
    }
    & > svg {
      /* margin-left: 0px; */
    }
  }
  @media (max-width: 360px) {
    & > svg {
      /* margin-left: 5px; */
    }
  }
  @media (max-width: 340px) {
    gap: 5px;
    & > p {
      font-size: 8px;
      width: 152px;
    }
    & > svg {
      margin-left: 0px;
    }
  }
`;

export const TopSection = styled.div`
  height: 46px;
  width: 100%;
  padding: 4px 12px;
  display: flex;
  gap: 12px;
  align-items: center;

  @media (max-width: 1024px) {
    padding: 1px 0px 1px;
    gap: 12px;
    height: 54px;
  }
`;

export const ProducerImage = styled.img`
  /* width: 48px;
  height: 48px;
  border-radius: 16px; */
  width: 34px;
  height: 34px;
  border-radius: 7px;
  @media (max-width: 1024px) {
    width: 34px;
    height: 34px;
    border-radius: 7px;
    margin-left: 11px;
  }
`;

export const ProducerDataWrapper = styled.div`
  width: calc(100% - 9px - 37px);
  height: 100%;
  display: flex;
  padding-bottom: 1px;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;

  & > h4 {
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    & > svg {
      margin-left: 9px;
      margin-bottom: 4px;

      & > path {
        fill: ${({theme}) => theme.colors.mobileSearchBarTextColor};
      }
    }
  }

  & > p {
    font-size: 11px;
    font-weight: bold;
    line-height: 11px;
    letter-spacing: -0.05px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
`;

export const BottomSection = styled.div`
  height: 100%;
  width: 100%;
  /* background: rgba(255, 255, 255, 0.3); */
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  @media (max-width: 1024px) {
    border-radius: 14px;
    height: 116px;
    /* border: 1px solid rgba(112, 112, 112, 0.3); */
    border: none;
    background: ${({theme}) => theme.colors.mobileTrackListBodyBq};
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  }
`;

export const MobileCoverWithNameWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: unset;
  gap: 12px;
  /* position: relative; */

  & > img {
    border-radius: 11px;
    border: 1px solid rgba(112, 112, 112, 0.3);
    /* height: 78px;
      width: 78px; */
    height: 102px;
    width: 102px;
    margin: 7px 0px 7px 7px;
  }
`;

export const MobileNameWithCategoriesWrapper = styled.div`
  height: 100%;
  /* width: 575px; */
  width: 555px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 5px;

  & > h4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    margin-bottom: 4px;
  }

  & > p {
    font-size: 11px;
    letter-spacing: -0.05px;
    line-height: 15px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > h5 {
    margin-top: 5px;
    font-size: 11px;
    letter-spacing: -0.25px;
    line-height: 16px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* width: calc(100% - 104px - 10px); */

    height: auto;
    /* width: max-content; */
    width: 100%;
    & > p {
      line-height: 18px;
      color: ${({theme}) => theme.colors.fontBlackOp50};
    }
    & > h4 {
      max-width: 243px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: transform 0.3s ease;
      /* &::after {
        content: '...';
      } */
    }
    & > svg {
      position: absolute;
      right: 8px;
      fill: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
  @media (max-width: 400px) {
    & > h4 {
      font-size: 17px;
      width: 220px;
    }
  }
  @media (max-width: 360px) {
    & > h4 {
      font-size: 15px;
      width: 180px;
    }
  }
  @media (max-width: 340px) {
    & > h4 {
      font-size: 12px;
      width: 160px;
    }
    & > p {
      font-size: 8px;
    }
  }
`;

export const AtributesWrapper = styled.div`
  display: flex;
  padding-top: 4px;
  & > p {
    font-size: 11px;
    letter-spacing: -0.05px;
    line-height: 15px;
    font-weight: 400;
    padding-right: 4px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const CoverWithDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  /* height: 159px; */
  background: ${({theme}) => theme.colors.headerContentBackground};
  border-radius: 16px;
  border: 1px solid transparent;
  transition: border 0.15s;
  padding: 6px;

  &:hover {
    border: 1px solid ${({theme}) => theme.colors.blueTheme};
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 21px;
    padding: 0 4px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const MobileCoverWithDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  /* background: ${({theme}) => theme.colors.headerContentBackground}; */
  border-radius: 16px;
  padding: 0px 2px 0px 0px;

  & > p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 21px;
    padding: 0 4px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const MobileSingleProductWrapper = styled.div`
  /* height: 222px; */
  height: 170px;
  width: 100%;
  flex-direction: column;
  border: 1px solid rgba(112, 112, 112, 0.3);
  background: rgba(239, 240, 242, 0.3);
  border-radius: 16px;
  display: none;
  position: relative;
  cursor: pointer;
  transition: all 0.15s;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;

    height: 266px;
    /* border: 1px solid rgba(255, 255, 255, 1); */
    border: none;
    background: ${({theme}) => theme.colors.mobileTrackListHeaderBq};
    padding: 0px;
    -webkit-tap-highlight-color: transparent;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* border-radius: 0px; */
  }
`;

export const IconWithDataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12.5px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    gap: 7px;
  }
`;

export const MobileDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70px;
  gap: 2px;

  & > p {
    font-size: 11px;
    color: rgba(7, 2, 15, 0.7);
    letter-spacing: 0.25px;
    line-height: 15px;
  }

  & > h4 {
    font-size: 18px;
    color: rgba(7, 2, 15, 1);
    letter-spacing: 0.1px;
    font-weight: 400;
    line-height: 21px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 154px;
    gap: 2px;

    & > h4 {
      min-height: 48px;
    }
  }
`;

export const SingleSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;

  & > h4 {
    color: white;
    font-size: 15px;
    font-weight: 400;
  }

  & > p {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }

  & > h5 {
    font-size: 15px;
    color: white;
  }

  &:nth-child(6) {
    gap: 10px;
  }
`;

export const ContentContainer = styled.div`
  width: 657px;
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  padding: 5px 15px 5px 5px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 4.5px 5px 10px;
    justify-content: space-between;
    gap: 15.5px;
  }
`;

export const DescriptionContainer = styled.div`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 90px;
    padding: 0px 7px;
    overflow-y: auto;
    display: unset;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      margin: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #3a87f0;
      border-radius: 25px;
      border: 4px solid transparent;
      background-clip: padding-box;
    }

    & > p {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.25px;
      line-height: 18px;
      color: #352f40;
    }
  }
`;

export const CoverWithNameWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;

  & > img {
    border-radius: 11px;
    background: white;
    width: 104px;
    height: 104px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    height: 170px;
    position: relative;
    gap: 7px;

    & > img {
      width: 170px;
      height: 170px;
    }
  }
`;

export const NameWithDescriptionWrapper = styled.div`
  height: 100%;
  width: calc(100% - 10px - 104px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: space-between;

  & > h4 {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: #07020f;
    margin-top: 6px;
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: #352f40;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% - 170px);

    & > h4 {
      width: 100%;
      height: 104px;
      margin-top: unset;
    }
  }
`;

export const PriceWithUserDataWrapper = styled.div`
  height: 100%;
  width: 337px;
  display: flex;
  flex-direction: column;
  background: rgba(47, 47, 104, 0.05);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 120px;
    width: 100%;
    border-top-right-radius: unset;
    border-top-left-radius: unset;
    justify-content: space-between;
    border-bottom-left-radius: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const DesktopPriceWithWinWinWrapper = styled.div`
  /* width: 255px; */
  /* width: 275px; */
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 39px;
    align-items: center;
    padding: 0px 23px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    border-top-right-radius: 0px;
  }
`;

export const DesktopPriceWithTitleWrapper = styled.div`
  display: flex;
  gap: 14.5px;

  & > h4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};

    & > span {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.15px;
      line-height: 21px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: center;

    & > h4 {
      display: flex;
      gap: 7px;
      margin-top: unset;
    }
  }
`;

export const PriceWithWinWinWrapper = styled.div`
  height: 57px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2px 18px 8px 9px;
  border-top-right-radius: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 53px;
    align-items: center;
    padding: 0px 23px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    border-top-right-radius: 0px;
  }
`;

export const PriceWithTitleWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & > h4 {
    margin-top: 4px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: #07020f;

    & > span {
      font-size: 21px;
      font-weight: 400;
      letter-spacing: -0.15px;
      line-height: 21px;
      color: #07020f;
    }
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 24px;
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: center;

    & > h4 {
      display: flex;
      gap: 7px;
      margin-top: unset;
    }
  }
`;

export const UserImageWithDataWrapper = styled.div`
  height: 57px;
  width: 100%;
  display: flex;
  gap: 9px;
  border-bottom-right-radius: 16px;
  background: rgba(47, 47, 104, 0.05);
  padding: 5.5px 7px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-bottom-left-radius: 16px;
    height: 60px;
    padding: 6px 9px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const UserImage = styled.img`
  border-radius: 9px;
  background: white;
  width: 46px;
  height: 46px;
`;

export const UserDataWrapper = styled.div`
  height: 100%;
  width: calc(100% - 46px - 9px);
  display: flex;
  flex-direction: column;

  & > h4 {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: #352f40;
  }

  & > p {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: -0.05px;
    line-height: 14px;
    color: #352f40;
  }
`;

export const StyledStatus = styled.h2`
  color: #19d627;
  font-size: 15px;
  font-weight: 400;
`;

export const StyledDate = styled.h2`
  color: #19d627;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: 0.5px;
`;

export const PublishDataWrapper = styled.div`
  margin-top: 4px;
  display: flex;
  gap: 35px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    position: unset;
    flex-direction: row;
    gap: 30px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    position: unset;
    flex-direction: column;
    gap: unset;
    margin-bottom: 6px;
  }
`;

export const TextInLine = styled.div`
  display: flex;
  gap: 4px;

  & > h5 {
    font-weight: 500;
    letter-spacing: 0.15px;
    font-size: 9px;
    color: rgba(53, 47, 64, 0.5);
  }

  & > p {
    font-weight: 500;
    letter-spacing: 0.15px;
    font-size: 9px;
    color: rgba(53, 47, 64, 1);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    & > h5 {
      font-weight: 500;
      letter-spacing: 0.15px;
      font-size: 9px;
      color: rgba(53, 47, 64, 0.5);
      line-height: 15px;
    }

    & > p {
      font-weight: 500;
      letter-spacing: 0.15px;
      font-size: 9px;
      color: rgba(53, 47, 64, 1);
      line-height: 15px;
    }

    &:first-of-type {
      margin-left: 4px;
    }

    &:last-of-type {
      margin-left: 0px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    &:first-of-type {
      margin-left: 6px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }
`;

export const WinWinWithPriceSpan = styled.div`
  position: absolute;
  top: 14px;
  right: 21.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: unset;
    top: unset;
    right: 0;
    gap: 6px;
    left: 0;
    top: 121px;
    padding-right: unset;
    flex-direction: column;
    position: absolute;
    margin-top: unset;
    height: unset;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    padding-right: 9px;
    gap: unset;
    position: unset;
    flex-direction: column;
    margin-top: unset;
    height: unset;
    width: 100%;
    align-items: flex-end;
  }
`;

export const ProductGenreWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 5px;
  margin-bottom: 2px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    position: unset;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 32px);
    margin-right: unset;
    gap: 5px;
    margin-bottom: unset;

    & > div {
      background: transparent;
      border: 1px solid rgba(112, 112, 112, 0.3);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;

      & > p {
        font-weight: 500;
        color: rgba(53, 47, 64, 1);
        font-size: 11px;
        letter-spacing: 0.1px;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    margin-top: unset;
  }
`;

export const SingleProductGenre = styled.div`
  background: transparent;
  border: 1px solid rgba(112, 112, 112, 0.3);
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;

  & > p {
    font-weight: 500;
    color: #07020f;
    font-size: 11px;
    letter-spacing: 0.15px;
  }
`;

export const WinWinWithButtonWrapper = styled.div`
  width: 85px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin: 0 64px;
  justify-content: center;
`;

export const CoverImageWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin-left: 6px;
  border-radius: 11px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  & > img {
    width: 70px;
    height: 70px;
    border-radius: 11px;
    aspect-ratio: 1;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: relative;
    margin-left: unset;
    margin-right: unset;
    width: 70px;
    height: 70px;

    & > img {
      border-radius: 11px;
      width: 70px;
      height: 70px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 154px;
    height: 154px;

    & > img {
      border-radius: 11px;
      width: 154px;
      height: 154px;
    }
  }
`;

export const MobileImageWithInfoSpan = styled.div`
  display: flex;

  & > img {
    width: 67px;
    height: 67px;
    border-radius: 11px;
    aspect-ratio: 1;
    margin-right: 18px;
  }

  &:hover {
    filter: brightness(1.2);
    background: rgba(255, 255, 255, 0.15);
  }

  @media (max-width: 1100px) {
    &:hover {
      filter: unset;
      background: unset;
    }
  }
`;

export const MobileWinWinWithPriceSpan = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const ProductInfoWrapper = styled.div`
  width: 80%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;

  & > h4 {
    font-size: 18px;
    line-height: 24px;
    color: #07020f;
    position: relative;
    letter-spacing: 0.1;
    font-weight: 500;

    & > svg {
      height: 11px;
      width: 11px;
      position: absolute;
      right: -18px;
      top: -2px;

      & > path {
        fill: #0066ff;
      }
    }
  }

  & > p {
    color: rgba(7, 2, 15, 0.7);
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.25px;
    font-weight: 400;
  }

  & > span {
    display: flex;
    gap: 2.8px;
    color: #0066ff;
    align-items: center;
    margin-left: -8px;
    line-height: 11px;
    font-size: 9px;
    font-weight: 700;

    & > svg {
      height: 11px;
      width: 11px;

      & > path {
        fill: #0066ff;
      }
    }
  }

  @media (max-width: 1100px) {
    margin-right: unset;
    width: unset;

    & > span {
      position: absolute;
      top: 0px;
      right: 10px;
    }

    & > h4 {
      margin-top: 6px;
    }
  }
`;

export const PromotionText = styled.h2`
  font-size: 11px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  transition: all 0.15s;
  text-decoration: none;
  position: absolute;
  right: 16px;
`;

export const DesktopWinWinInfo = styled.div<{IsWinWin?: boolean}>`
  display: ${({IsWinWin}) => (IsWinWin ? 'unset' : 'none')};
  margin-top: 3px;
`;

export const WinWinInfo = styled.div<{IsWinWin?: boolean}>`
  display: ${({IsWinWin}) => (IsWinWin ? 'unset' : 'none')};
  margin-top: 3px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 0px;
    & > svg {
      margin-left: 5px;
      margin-top: 4px;
    }
  }
`;

export const PriceInfoWrapper = styled.div`
  /* width: 85px;
    height: 22px; */
  /* border-radius: 5px; */
  /* border: none; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(47, 47, 104, 0.05); */

  & > p {
    font-size: 13px;
    font-weight: 700;
    color: #07020f;
    letter-spacing: 0.1px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    & > p {
      font-size: 18px;
      font-weight: 700;
      color: #07020f;
      letter-spacing: -0.15px;
      line-height: 24px;
    }
  }
`;

export const DesktopPlayButtonWrapper = styled.div`
  /* width: 58px;
    height: 58px; */
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  position: absolute;
  gap: 15px;
  right: 18px;
  /* top: 59px; */
  top: 86px;
  z-index: 4;

  & > img {
    /* width: 58px;
      height: 58px; */
    height: 45px;
    width: 45px;
    cursor: pointer;

    &:hover {
      filter: brightness(0.95);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    left: unset;
    width: unset;
    height: unset;
    bottom: 8px;
    right: 36px;

    & > img {
      height: unset;
      width: unset;

      &:hover {
        filter: unset;
      }
    }
  }
`;

export const PlayButtonWrapper = styled.div`
  width: calc(100% - 138px);
  height: 24px;
  display: flex;
  align-items: center;
  position: absolute;
  gap: 15px;
  left: 122px;
  top: 40.5px;

  & > img {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
      filter: brightness(0.7);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    left: unset;
    width: unset;
    height: unset;
    bottom: 24px;
    right: 33px;

    & > img {
      height: unset;
      width: unset;

      &:hover {
        filter: unset;
      }
    }
  }
`;

export const TransparentDiv = styled.div<{isAudioPlayed: boolean}>`
  width: 100%;
  height: 100vh;
  /* background: rgba(255, 0, 0, 0.2); */
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;

  display: ${({isAudioPlayed}) => (isAudioPlayed ? 'block' : 'none')};
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 5px;
  background-color: rgba(47, 47, 104, 0.15);
  position: relative;
  border-radius: 5px;
`;

export const Progress = styled.div<{progress: number}>`
  height: 100%;
  width: ${(props) => props.progress}%;
  background-color: ${(props) =>
    props.progress === 100 ? 'rgba(47,47,104,0.15)' : 'rgba(47,47,104,0.5)'};
`;

export const MobileViewsAndPublishedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    font-size: 11px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    line-height: 14px;
    /* color: ${({theme}) => theme.colors.viewsCountColor}; */
    font-weight: 400;
    letter-spacing: -0.05px;
  }
`;
