import React, {useState, useEffect} from 'react';
import styled, {css} from 'styled-components';

import {hexToRgb, getSpecificRole} from '../../index';
import defaultAvatar from 'images/profilowe z tłem.png';
import api from 'services/axiosConfig';
import {Block} from '../../interface';
import {
  DefaultBlockContent,
  DefaultBlockDataWrapper,
  DefaultBlockImage,
  DefaultBlockLocation,
  DefaultBlockName,
  DefaultBlockRole,
  DefaultBlockRoleAndDataWrapper,
  DefaultBlockWrapper,
} from './styles';

interface Props {
  block: Block;
  theme: string;
  mobileOverview: boolean;
  roles: Role[];
}

export interface Role {
  FieldName: string;
  FlagValue: number;
}

export interface City {
  City: string;
  Region: string;
}

const DefaultBlockComponent: React.FC<Props> = ({
  block,
  theme,
  mobileOverview,
  roles,
}) => {
  const {
    Id,
    WebArea,
    BackgroundColorHex,
    BlockOpacityIn0To100Ratio,
    FontColorHex,
    FirstName,
    FtpPhotoFilePath,
    LastName,
    Name,
    Type,
    Role,
    SecondaryRole,
    PostalCode,
    ProducerCountry,
    City,
  } = block;

  const {Width, Height} = WebArea;

  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);
  // console.log(roles, Role, SecondaryRole, 'jak dodaje');
  return (
    <DefaultBlockWrapper
      Height={Height}
      backgroundAlpha={BlockOpacityIn0To100Ratio}
      backgroundColor={BackgroundColorRgb}
    >
      <DefaultBlockContent
        Width={Width}
        Height={Height}
        mobileOverview={mobileOverview}
      >
        <DefaultBlockImage
          mobileOverview={mobileOverview}
          Width={Width}
          Height={Height}
          src={FtpPhotoFilePath}
        />
        <DefaultBlockDataWrapper
          Width={Width}
          Height={Height}
          mobileOverview={mobileOverview}
          theme={theme}
        >
          {FirstName ? (
            <>
              <DefaultBlockName
                Width={Width}
                Height={Height}
                fontColor={hexToRgb(FontColorHex)}
              >
                {FirstName} <br /> {LastName}
              </DefaultBlockName>
              {/*<DefaultBlockName*/}
              {/*  Width={Width}*/}
              {/*  Height={Height}*/}
              {/*  fontColor={hexToRgb(FontColorHex)}*/}
              {/*>*/}
              {/*  {LastName}*/}
              {/*</DefaultBlockName>*/}
            </>
          ) : (
            <DefaultBlockName
              Width={Width}
              Height={Height}
              fontColor={hexToRgb(FontColorHex)}
            >
              {Name}
            </DefaultBlockName>
          )}
          <DefaultBlockRoleAndDataWrapper>
            <DefaultBlockRole
              Width={Width}
              Height={Height}
              fontColor={hexToRgb(FontColorHex)}
            >
              {/* {`${getSpecificRole(roles, Role)} • ${getSpecificRole(
                roles,
                SecondaryRole,
              )}`} */}
              {`${getSpecificRole(roles, Role)}${
                SecondaryRole === null ? '' : ','
              } ${
                SecondaryRole === null
                  ? ''
                  : getSpecificRole(roles, SecondaryRole)
              }`}
            </DefaultBlockRole>

            <DefaultBlockLocation
              Width={Width}
              Height={Height}
              fontColor={hexToRgb(FontColorHex)}
            >
              {`${City?.length > 0 ? City : 'Nie znaleziono'}`}
            </DefaultBlockLocation>
          </DefaultBlockRoleAndDataWrapper>
        </DefaultBlockDataWrapper>
      </DefaultBlockContent>
    </DefaultBlockWrapper>
  );
};

export default DefaultBlockComponent;
