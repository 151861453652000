import React, {useEffect, useState} from 'react';
import {Link, Navigate, useNavigate, useParams} from 'react-router-dom';
import api from '../../../../services/axiosConfig';
import useAlert from 'hooks/useAlert';

import mugoImage from '../../../../images/post_mugo.png';
import styled from 'styled-components';

import openEye from 'images/svg/openEye.svg';

import {InputView} from '../../../MyAccount/Settings/styles';
import {
  GoogleFacebookAppleLogoWrapper,
  PrimaryAppleButton,
} from 'pages/Identity/Login';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  /* @media (max-width: 1045px) {
    width: 360px;
  } */
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: 1fr;
  gap: 11px 0;
  grid-auto-rows: unset;

  @media (max-width: 1045px) {
  }
`;

export const InputWrapper = styled.div<{isRedBorder?: boolean}>`
  background: ${({isRedBorder}) => (isRedBorder ? `#FF7E7D` : 'transparent')};
  border: 1px solid rgba(112, 112, 112, 0.3);
  height: 22px;
  border-radius: 5px;
  width: 100%;
  margin-top: ${({isRedBorder}) => (isRedBorder ? `0` : '10px')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    color: #07020f;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.625px;
    font-weight: 400;
    margin-left: 8.5px;
  }

  & > input {
    text-align: right;
    font-size: 11px;
    line-height: 18px;
    margin-right: 8.5px;
    color: rgba(7, 2, 15, 0.5);
    letter-spacing: 0.625px;
    font-weight: 400;
    background-color: transparent;
    border: none;

    &::placeholder {
      color: rgba(7, 2, 15, 0.5);
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: rgba(7, 2, 15, 0.5) !important;
    }
  }

  & > input:focus {
    outline: none;
  }
`;

const IconShowPasswordWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  & > svg {
    overflow: visible;
    width: 100%;
    height: 100%;
  }
  & > img {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    top: 12px;

    & > img {
      width: 19px;
      height: 19px;
    }
  }
`;

export const PrimaryButton = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
}>`
  width: 100%;
  height: 44px;
  border: none;
  border: ${({borderColor}) =>
    !borderColor ? 'none' : `1px solid ${borderColor}`};
  background: ${({background}) => (!background ? '#3A87F0' : background)};
  border-radius: 15px;
  font-size: 15px;
  color: ${({color}) => (!color ? '#ffffff' : color)};
  transition: all 0.15s;
  cursor: pointer;
  display: flex;
  align-items: center;
  //justify-content: center;
  gap: 6px;
  font-weight: 400;
  letter-spacing: 0.7px;
  position: relative;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 16px;
    letter-spacing: 0.07px;
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 7px;

    &:first-of-type {
      margin-bottom: 21px;
    }
  }
`;

export const PageHeaderWrapper = styled.div`
  width: 1020px;
  display: flex;
  height: 590px;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 9px 9px 21px 21px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.625px;
  }
`;

export const PageHeaderContent = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  display: grid;
  align-items: center;
  grid-template-columns: 535px 485px;
`;

export const ImageWrapper = styled.div`
  /* width: 100%; */
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
`;

export const RightSectionContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 24.5px 85px 11px 88px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: #07020f;
  }

  & > a {
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: rgba(93, 93, 97, 0.7);
    text-decoration: underline;
  }
`;

// const PopupWrapper = styled.div`
//   z-index: 99;
//   position: fixed;
//   left: 0;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.3);
//   color: white;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// `;

// export const PopupHeader = styled.div`
//   background: #89bfff;
//   width: 100%;
//   height: 35%;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   border-top-right-radius: 14px;
//   border-top-left-radius: 14px;
//   & > h4 {
//     font-size: 16px;
//     color: black;
//     font-weight: normal;
//     margin-bottom: 10px;
//   }
// `;

// export const PopUpContentWrapper = styled.div`
//   width: 400px;
//   min-height: 200px;
//   border: 1px solid #89bfff;
//   border-radius: 15px;
//   overflow-x: hidden;
//   background-color: white;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   & > p {
//     margin-top: 10px;
//     color: black;
//     width: 80%;
//     text-align: center;
//     font-size: 16px;
//     line-height: 21px;
//   }
// `;

// export const PopUpButton = styled.button<{isBlue?: boolean}>`
//   background-color: ${({isBlue}) => (isBlue ? `#3986ef` : '#3986ef')};
//   color: white;
//   font-weight: normal;
//   cursor: pointer;
//   //border: ${({isBlue}) => (isBlue ? `#7270F2 solid 1px` : 'grey solid 1px')};
//   border: none;
//   border-radius: 5px;
//   text-align: center;
//   padding: 5px 0px;
//   font-size: 11px;
//   width: 100px;
//   margin: 5px;
//   margin-top: 25px;
//   &:hover {
//     filter: brightness(1.1);
//   }
//   &:active {
//     filter: brightness(0.9);
//   }
// `;

export const PopupWrapper = styled.div`
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    background-color: rgba(112, 112, 112, 0.3);
  }
`;

export const PopupHeader = styled.div`
  background: #0091ff;
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  & > h4 {
    font-size: 16px;
    color: black;
    font-weight: normal;
  }
  //@media (max-width: 1024px) {
  //  display: none;
  //}
`;

export const PopUpContentWrapper = styled.div`
  width: 292px;
  //height: 161px;
  //border: 1px solid rgba(112, 112, 112, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  //justify-content: center;
  align-items: center;
  & > p {
    margin-top: 10px;
    color: black;
    width: 80%;
    text-align: center;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.25px;
  }
  & > pre {
    margin-top: 10px;
    color: black;
    width: 100%;
    text-align: center;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.25px;
  }
  //@media (max-width: 1024px) {
  //  width: 80%;
  //  & > p {
  //    margin-top: 38px;
  //    margin-bottom: 19px;
  //    font-size: 13px;
  //    letter-spacing: -0.025px;
  //  }
  //}
  //
  //@media (max-width: 400px) {
  //  width: 80%;
  //}
  //@media (max-width: 340px) {
  //  width: 80%;
  //}
`;

export const PopUpButton = styled.button<{isBlue?: boolean}>`
  background-color: ${({isBlue}) => (isBlue ? `#3986ef` : '#3986ef')};
  color: white;
  font-weight: normal;
  cursor: pointer;
  //border: ${({isBlue}) => (isBlue ? `#7270F2 solid 1px` : 'grey solid 1px')};
  border: none;
  border-radius: 9px;
  text-align: center;
  padding: 5px 0px;
  font-size: 13px;
  line-height: 24px;
  width: 149px;
  height: 32px;
  margin: 12px;
  transition: all 0.15s;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
  //@media (max-width: 1024px) {
  //  margin: 10px;
  //  height: 44px;
  //  width: 80%;
  //  font-size: 15px;
  //  border-radius: 16px;
  //}
  //@media (max-width: 400px) {
  //  width: 80%;
  //}
  //@media (max-width: 340px) {
  //  width: 80%;
  //}
`;

export const ConfirmRegister: React.FC = () => {
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [goToLogin, setGoToLogin] = useState(false);

  const locationSearch = window.location.search;
  const navigate = useNavigate();

  const params = new URLSearchParams(locationSearch);
  const {type} = useParams();

  const {Alert} = useAlert();

  const confirmAccount = async () => {
    try {
      const response = await api.get(
        `Auth/EmailConfirmation?userId=${params
          .get('userId')
          .replaceAll(' ', '+')}&code=${params
          .get('code')
          .replaceAll(' ', '+')}`,
      );
      // setTimeout(() => {
      //   Alert('Konto zostało aktywowane', 'Konto zostało aktywowane');
      //   navigate('/identity/login');
      // }, 3000);
      Alert('Konto zostało aktywowane', 'Konto zostało aktywowane');
      setGoToLogin(true);
    } catch (error) {
      setErrorMessage(error.response.data.Error.Message);
      if (error.response.data.Error.Message === 'Email is already confirmed.') {
        Alert(
          'Adres e-mail został już potwierdzony.',
          'Adres e-mail został już potwierdzony.',
        );
        setGoToLogin(true);
      } else {
        Alert(
          'Coś poszło nie tak spróbuj później.',
          'Coś poszło nie tak spróbuj później.',
        );
        setGoToLogin(true);
      }
      // Alert(error.response.data.Error.Message, 'Błąd');
    }
  };
  useEffect(() => {
    if (params.get('userId') === null) {
      setIsError(true);
      // return <Navigate to="/identity/login" />;
    } else {
      confirmAccount();
    }
  }, []);

  useEffect(() => {
    if (goToLogin) {
      navigate('/identity/login');
    }
  }, [goToLogin]);
  return (
    <MainWrapper
      onClick={() => {
        navigate('/identity/login');
      }}
    >
      <Main>
        <PageHeaderWrapper>
          <PageHeaderHeaderWrapper>
            <PageHeaderHeader>
              <p>Witamy w MUGO STUDIO</p>
            </PageHeaderHeader>
          </PageHeaderHeaderWrapper>
          <PageHeaderContent>
            <ImageWrapper>
              <img src={mugoImage} />
            </ImageWrapper>
            <RightSectionContent>
              {isError ? (
                <>
                  <h1 style={{marginBottom: '27.5px'}}>Błąd</h1>
                  <p>{errorMessage}</p>
                  <Link to={'/identity/login'}>Wróć do logowania</Link>
                </>
              ) : (
                <>
                  <h1 style={{marginBottom: '14px'}}>Logowanie</h1>
                  <InputView isEditing={true}>
                    <p>Login</p>
                    <input type="text" readOnly={true} id="login" />
                  </InputView>
                  <InputView isEditing={true} style={{marginBottom: '100px'}}>
                    <p>Hasło</p>
                    <input type="password" readOnly={true} id="password" />
                    <IconShowPasswordWrapper>
                      <img src={openEye} alt="showPAssword" />
                    </IconShowPasswordWrapper>
                  </InputView>
                  <Link to={'/identity/recover'}>Nie pamiętasz hasła?</Link>
                  <Link to={'/identity/register'}>Zarejestruj się</Link>
                  <Link
                    style={{marginBottom: '14px'}}
                    to={'/identity/recover/email'}
                  >
                    Wyślij ponownie e-mail potwierdzający
                  </Link>
                  <PrimaryButton
                    style={{marginBottom: '7px', justifyContent: 'center'}}
                  >
                    Zaloguj się
                  </PrimaryButton>
                  <PrimaryButton
                    style={{marginBottom: '7px'}}
                    background="#FFFFFF"
                    color="#07020F"
                    borderColor="rgba(112,112,112,0.3)"
                    // onClick={loginWithFacebook}
                  >
                    <GoogleFacebookAppleLogoWrapper>
                      {/* <svg
                        fill="#FFFFFF"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 58 58"
                        width="38px"
                        height="38px"
                      >
                        <path d="M32,6C17.642,6,6,17.642,6,32c0,13.035,9.603,23.799,22.113,25.679V38.89H21.68v-6.834h6.433v-4.548	c0-7.529,3.668-10.833,9.926-10.833c2.996,0,4.583,0.223,5.332,0.323v5.965h-4.268c-2.656,0-3.584,2.52-3.584,5.358v3.735h7.785	l-1.055,6.834h-6.73v18.843C48.209,56.013,58,45.163,58,32C58,17.642,46.359,6,32,6z" />
                      </svg> */}
                      <svg
                        id="Facebook_f_logo__2021_"
                        data-name="Facebook_f_logo_(2021)"
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                      >
                        <defs>
                          <linearGradient
                            id="linear-gradient"
                            x1="-6.934"
                            y1="10.216"
                            x2="-6.934"
                            y2="10.241"
                            gradientUnits="objectBoundingBox"
                          >
                            <stop offset="0" stopColor="#0062e0" />
                            <stop offset="1" stopColor="#19afff" />
                          </linearGradient>
                        </defs>
                        <path
                          id="Path_3758"
                          data-name="Path 3758"
                          d="M11.69,27.86A14.028,14.028,0,0,1,14,0a14.028,14.028,0,0,1,2.31,27.86l-.77-.63H12.46Z"
                          fill="url(#linear-gradient)"
                        />
                        <path
                          id="Path_3759"
                          data-name="Path 3759"
                          d="M23,20.26l.63-3.92H19.92V13.61c0-1.12.42-1.96,2.1-1.96h1.82V8.08a22.893,22.893,0,0,0-3.08-.28,5.072,5.072,0,0,0-5.46,5.46v3.08H11.8v3.92h3.5v9.87a12.81,12.81,0,0,0,4.62,0V20.26Z"
                          transform="translate(-3.54 -2.34)"
                          fill="#fff"
                        />
                      </svg>
                    </GoogleFacebookAppleLogoWrapper>
                    Zaloguj się przez Facebook
                  </PrimaryButton>
                  <PrimaryButton
                    style={{marginBottom: '7px'}}
                    background="#FFFFFF"
                    color="#07020F"
                    borderColor="rgba(112,112,112,0.3)"
                    // onClick={loginWithGoogle}
                  >
                    <GoogleFacebookAppleLogoWrapper>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        width="36px"
                        height="36px"
                      >
                        <path
                          fill="#fbc02d"
                          d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                        />
                        <path
                          fill="#e53935"
                          d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                        />
                        <path
                          fill="#4caf50"
                          d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                        />
                        <path
                          fill="#1565c0"
                          d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                        />
                      </svg>
                    </GoogleFacebookAppleLogoWrapper>
                    Zaloguj się przez Google
                  </PrimaryButton>
                  <PrimaryAppleButton>
                    <GoogleFacebookAppleLogoWrapper>
                      <svg
                        fill="#FFFFFF"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30 30"
                        width="36px"
                        height="36px"
                      >
                        {' '}
                        <path d="M25.565,9.785c-0.123,0.077-3.051,1.702-3.051,5.305c0.138,4.109,3.695,5.55,3.756,5.55 c-0.061,0.077-0.537,1.963-1.947,3.94C23.204,26.283,21.962,28,20.076,28c-1.794,0-2.438-1.135-4.508-1.135 c-2.223,0-2.852,1.135-4.554,1.135c-1.886,0-3.22-1.809-4.4-3.496c-1.533-2.208-2.836-5.673-2.882-9 c-0.031-1.763,0.307-3.496,1.165-4.968c1.211-2.055,3.373-3.45,5.734-3.496c1.809-0.061,3.419,1.242,4.523,1.242 c1.058,0,3.036-1.242,5.274-1.242C21.394,7.041,23.97,7.332,25.565,9.785z M15.001,6.688c-0.322-1.61,0.567-3.22,1.395-4.247 c1.058-1.242,2.729-2.085,4.17-2.085c0.092,1.61-0.491,3.189-1.533,4.339C18.098,5.937,16.488,6.872,15.001,6.688z" />
                      </svg>
                    </GoogleFacebookAppleLogoWrapper>
                    Zaloguj się przez Apple
                  </PrimaryAppleButton>
                </>
              )}
            </RightSectionContent>
          </PageHeaderContent>
        </PageHeaderWrapper>
        {/* <p>Witamy w MUGO STUDIO</p>
        <Grid>
          <LoginWrapper>
            <LoginHeader>
              <p>LOGOWANIE</p>
            </LoginHeader>
            <LoginContent onKeyUp={handleKeyUp}>
              <InputWrapper style={{marginBottom: '6px'}}>
                <p>Login</p>
                <input
                  type="text"
                  placeholder="Uzupełnij"
                  value={loginData.login}
                  onChange={handleLoginDataChange}
                  id="login"
                />
              </InputWrapper>
              <InputWrapper>
                <p>Hasło</p>
                <input
                  type="password"
                  placeholder="Uzupełnij"
                  value={loginData.password}
                  onChange={handleLoginDataChange}
                  id="password"
                />
              </InputWrapper>
              <Link to={'/identity/recover'}>Zapomniałeś hasła?</Link>
              <Link to={'/identity/register'}>Zarejestruj się</Link>
              <Link to={'#'}>Przyślij ponownie e-mail potwierdzający</Link>
              <PrimaryButton ref={loginButtonRef} onClick={() => logIn()}>
                Zaloguj
              </PrimaryButton>
              <PrimaryButton background="#0067F0">
                <img
                  src={facebookLogo}
                  style={{height: '30px', width: '30px'}}
                />
                Zaloguj się przez Facebook
              </PrimaryButton>
              <PrimaryButton background="#FFFFFF" color="#000000">
                <img src={googleLogo} style={{height: '28px', width: '28px'}} />
                Zaloguj się przez ExternalLogin
              </PrimaryButton>
              <PrimaryButton background="#000000">
                <img src={appleLogo} style={{height: '15px', width: '15px'}} />
                Sign in with Apple
              </PrimaryButton>
            </LoginContent>
          </LoginWrapper>
        </Grid> */}
      </Main>
      {/* {isError ? null : (
        <PopupWrapper>
          <PopUpContentWrapper>
            <PopupHeader>
              <h4>Potwierdzenie aktywacji konta</h4>
            </PopupHeader>
            <div>
              <PopUpButton isBlue={true} onClick={confirmAccount}>
                Aktywuj
              </PopUpButton>
            </div>
          </PopUpContentWrapper>
        </PopupWrapper>
      )} */}
    </MainWrapper>
  );
};
