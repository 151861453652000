import styled, {css, keyframes} from 'styled-components';

export const RecomendedProductionBlockWrapper = styled.div<{
  productsLength: number;
  backgroundAlpha: number;
  backgroundColor: string;
  isMobile: boolean;
}>`
  width: 100%;
  height: 100%;
  background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`};
  /* border-radius: 24px; */
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
  //padding: ${({productsLength}) => (productsLength === 1 ? 0 : '5px')};
  overflow: hidden;
  //position: relative;
  padding: ${({isMobile}) => (isMobile ? '16px ' : '20px 50px 25px 50px')};
  & > p {
    color: white;
  }
  @media (max-width: 1024px) {
    padding: 16px;
  }
  @media (max-width: 375px) {
    padding: 14px;
  }
  @media (max-width: 340px) {
    padding: 10px;
  }
`;

export const RecomendedProductionBlockHeader = styled.div<{theme?: string}>`
  height: 10%;
  width: 100%;
  display: flex;
  gap: 11px;
  align-items: center;

  & > h4 {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    padding: 0 5px;
  }

  & > svg {
    width: 27.38px;
    height: 21px;
    & > path {
      fill: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  & > button {
    width: 49px;
    height: 21px;
    border: 1px solid
      ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 9px;
    font-weight: 600;
    display: flex;
    background: transparent;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }

  @media (max-width: 1065px) {
    height: 5%;
  }
  @media (max-width: 920px) {
    & > h4 {
      font-size: 16px;
    }
    & > svg {
      height: 16px;
    }
  }
  @media (max-width: 780px) {
    & > h4 {
      font-size: 14px;
    }
    & > svg {
      height: 14px;
    }
  }
  @media (max-width: 720px) {
    & > h4 {
      font-size: 12px;
    }
    & > svg {
      height: 12px;
    }
  }
  @media (max-width: 650px) {
    & > h4 {
      padding: 0;
      font-size: 11px;
    }
    & > svg {
      height: 11px;
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      font-size: 10px;
    }
    & > svg {
      height: 10px;
    }
  }
  @media (max-width: 550px) {
    & > h4 {
      font-size: 8px;
    }
    & > svg {
      height: 8px;
    }
  }
  @media (max-width: 430px) {
    & > h4 {
      font-size: 6px;
    }
    & > svg {
      height: 6px;
    }
  }
`;

export const RecomendedProductionBlockNavigation = styled.div<{theme?: string}>`
  display: flex;
  gap: 6px;
  align-items: center;

  & > svg {
    height: 16px;
    width: 16px;
    cursor: pointer;

    & > g {
      & > g {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};

        & > path {
          fill: ${({theme}) =>
            theme === '000000'
              ? 'black'
              : theme === '0C426A'
              ? '#0C426A'
              : 'white'};
        }
      }
    }
  }

  & > p {
    font-size: 11px;
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  }
  @media (max-width: 920px) {
    & > svg {
      width: 15px;
      height: 15px;
    }
    & > p {
      font-size: 10px;
    }
  }
  @media (max-width: 830px) {
    gap: 3px;
    & > svg {
      width: 14px;
      height: 14px;
    }
    & > p {
      font-size: 9px;
    }
  }
  @media (max-width: 780px) {
    gap: 2px;
    & > svg {
      width: 12px;
      height: 12px;
    }
    & > p {
      font-size: 8px;
    }
  }
  @media (max-width: 650px) {
    gap: 1px;
    & > svg {
      width: 10px;
      height: 10px;
    }
    & > p {
      font-size: 7px;
    }
  }
  @media (max-width: 580px) {
    gap: 1px;
    & > svg {
      width: 8px;
      height: 8px;
    }
    & > p {
      font-size: 6px;
    }
  }
  @media (max-width: 450px) {
    gap: 1px;
    & > svg {
      width: 5px;
      height: 5px;
    }
    & > p {
      font-size: 4px;
    }
  }
`;

export const RecomendedProductionBlockContent = styled.div<{
  mobileOverview: boolean;
  Height: number;
  Width: number;
}>`
  width: 100%;
  height: 90%;
  display: grid;
  justify-content: space-between;
  gap: 60px;
  grid-template-columns: repeat(auto-fill, 287px);
  grid-template-rows: repeat(auto-fill, 258px);

  @media (max-width: 1065px) {
    gap: 10px;
    height: 95%;
    grid-template-columns: ${({Width, mobileOverview}) =>
      !mobileOverview ? `repeat(${Width}, 1fr)` : 'repeat(auto-fill, 287px)'};
    grid-template-rows: ${({Height, mobileOverview}) =>
      !mobileOverview ? `repeat(${Height}, 1fr)` : 'repeat(auto-fill, 258px)'};
  }
`;

export const RecomendedProductionWrapper = styled.div<{
  theme?: string;
}>`
  position: relative;
  border-radius: 9px;
  cursor: pointer;
  padding: 5px;
  border: 1px solid
    ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  &:hover {
    border: ${({theme}) =>
      theme === '000000'
        ? 'solid 2px black'
        : theme === '0C426A'
        ? 'solid 2px #0C426A'
        : 'solid 2px white'};
  }
`;

export const DeleteButton = styled.button<{fontColor: string}>`
  position: absolute;
  top: 0;
  right: 5px;
  background-color: transparent;
  padding: 5px;
  border: none;
  color: ${({fontColor}) => `rgb(${fontColor})`};
  cursor: pointer;
`;

export const RecomendedProductionHeader = styled.div<{
  mobileOverview: boolean;
}>`
  flex-basis: 50%;
  display: flex;
  & > img {
    height: 129px;
  }
  @media (max-width: 960px) {
    & > img {
      height: 110px;
    }
  }
  @media (max-width: 920px) {
    & > img {
      height: 100px;
    }
  }
  @media (max-width: 820px) {
    & > img {
      height: 85px;
    }
  }
  @media (max-width: 750px) {
    & > img {
      height: 70px;
    }
  }
  @media (max-width: 660px) {
    & > img {
      height: 64px;
    }
  }
  @media (max-width: 580px) {
    & > img {
      height: 48px;
    }
  }
  @media (max-width: 480px) {
    & > img {
      height: 32px;
    }
  }
  @media (max-width: 380px) {
    & > img {
      height: 24px;
    }
  }
`;

export const RecomendedProductionNameAndButtonWrapper = styled.div<{
  fontColor: string;
}>`
  display: flex;
  width: 100%;
  flex-direction: column;

  & > h4 {
    text-align: left;
    font-size: 14px;
    margin-left: 10px;
    padding: 12px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    font-weight: 600;
  }
  @media (max-width: 960px) {
    & > h4 {
      font-size: 12px;
      margin-left: 8px;
      padding: 10px;
    }
  }
  @media (max-width: 920px) {
    & > h4 {
      padding: 8px;
    }
  }
  @media (max-width: 750px) {
    & > h4 {
      font-size: 10px;
      margin-left: 5px;
      padding: 6px;
    }
  }
  @media (max-width: 660px) {
    & > h4 {
      font-size: 8px;
      margin-left: 2px;
      padding: 3px;
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      font-size: 7px;
      margin-left: 1px;
      padding: 1px;
    }
  }
  @media (max-width: 480px) {
    & > h4 {
      font-size: 6px;
      margin-left: 1px;
      padding: 1px;
    }
  }
  @media (max-width: 380px) {
    & > h4 {
      font-size: 4px;
      margin-left: 1px;
      padding: 1px;
    }
  }
`;

export const RecomendedProductionButtonWrapper = styled.div<{
  fontColor: string;
}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 5px;
  padding: 5px 10px;
  & > button {
    border: 1px solid rgba(255, 255, 255, 0.21);
    color: ${({fontColor}) => `rgb(${fontColor})`};
    background-color: transparent;
    cursor: pointer;
    font-size: 8px;
    padding: 4px;
    &:hover {
      border-color: rgba(255, 255, 255, 0.61);
    }
  }
  @media (max-width: 920px) {
    gap: 3px;
    padding: 3px 5px;
  }
  @media (max-width: 820px) {
    & > button {
      font-size: 6px;
      padding: 2px;
    }
  }
  @media (max-width: 750px) {
    & > button {
      font-size: 5px;
      padding: 1px;
    }
  }
  @media (max-width: 660px) {
    gap: 1px;
    padding: 1px;
  }
  @media (max-width: 480px) {
    & > button {
      font-size: 3px;
    }
  }
  @media (max-width: 380px) {
    padding: 0;
    & > button {
      font-size: 2px;
    }
  }
`;

export const RecomendedProductionContent = styled.div<{fontColor: string}>`
  flex-basis: 40%;
  margin-top: 5px;
  & > p {
    font-size: 10px;
    line-height: 16px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }
  @media (max-width: 920px) {
    margin-top: 3px;
    & > p {
      font-size: 8px;
      line-height: 12px;
    }
  }
  @media (max-width: 750px) {
    margin-top: 2px;
    & > p {
      font-size: 7px;
      line-height: 10px;
    }
  }
  @media (max-width: 660px) {
    margin-top: 1px;
    & > p {
      font-size: 6px;
      line-height: 8px;
    }
  }
  @media (max-width: 580px) {
    margin-top: 1px;
    & > p {
      font-size: 5px;
      line-height: 6px;
    }
  }
  @media (max-width: 480px) {
    margin-top: 1px;
    & > p {
      font-size: 3px;
      line-height: 3px;
    }
  }
  @media (max-width: 380px) {
    margin-top: 1px;
    & > p {
      font-size: 2px;
      line-height: 2px;
    }
  }
`;

export const RecomendedProductionFooter = styled.div`
  flex-basis: 10%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 10px 15px;

  @media (max-width: 920px) {
    padding: 8px 12px;
  }
  @media (max-width: 780px) {
    padding: 5px 8px;
  }
  @media (max-width: 660px) {
    padding: 2px 5px;
  }
  @media (max-width: 480px) {
    padding: 1px 3px;
  }
`;

export const RecomendedProductionWinWinInfo = styled.div<{
  fontColor: string;
  theme: string;
}>`
  height: 22px;
  width: 94px;
  border: ${({fontColor}) => `solid 1px rgb(${fontColor})`};
  display: flex;
  gap: 8.7px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-right: 22px;
  cursor: pointer;

  & > p {
    color: ${({fontColor}) => `rgb(${fontColor})`};
    font-size: 9px;
  }
  g {
    & > path {
      stroke: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
    & > circle {
      stroke: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  @media (max-width: 1100px) {
    margin-right: unset;
  }
  @media (max-width: 920px) {
    width: 84px;
  }
  @media (max-width: 820px) {
    width: 74px;
  }
  @media (max-width: 750px) {
    width: 64px;
    height: 18px;
    & > p {
      font-size: 7px;
    }
  }
  @media (max-width: 660px) {
    width: 54px;
    height: 14px;
    & > p {
      font-size: 6px;
    }
  }
  @media (max-width: 660px) {
    width: 48px;
    height: 13px;
    & > p {
      font-size: 5px;
    }
  }
  @media (max-width: 480px) {
    width: 30px;
    height: 10px;
    & > p {
      font-size: 3px;
    }
  }
  @media (max-width: 380px) {
    width: 28px;
    height: 8px;
    & > p {
      font-size: 2px;
    }
  }
`;

export const RecomendedProductionPriceButton = styled.button<{
  fontColor: string;
  bgColor: string;
}>`
  width: 94px;
  height: 22px;
  border: ${({fontColor}) => `solid 1px rgb(${fontColor})`};
  border-radius: 5px;
  background: ${({fontColor}) => `rgb(${fontColor})`};
  font-size: 9px;
  color: ${({bgColor}) => `rgb(${bgColor})`};
  transition: all 0.15s;
  cursor: pointer;
  margin: 0 7px;
  @media (max-width: 920px) {
    width: 84px;
  }
  @media (max-width: 820px) {
    width: 74px;
  }
  @media (max-width: 750px) {
    width: 64px;
    height: 18px;
    font-size: 7px;
  }
  @media (max-width: 660px) {
    width: 54px;
    height: 14px;
    font-size: 6px;
  }
  @media (max-width: 660px) {
    width: 48px;
    height: 13px;
    font-size: 5px;
  }
  @media (max-width: 480px) {
    width: 30px;
    height: 10px;
    font-size: 3px;
  }
  @media (max-width: 380px) {
    width: 28px;
    height: 8px;
    font-size: 2px;
  }
`;

export const RecomendedAddButonWrapper = styled.div<{theme: string}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 48px;
    height: 48px;
    cursor: pointer;
    & > g {
      & > path {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};
      }
    }
  }
`;

export const RecomendedNewListWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &::-webkit-scrollbar {
    width: 24px;
    height: 10px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment {
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #125faf;
    border-radius: 25px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
`;

export const RecomendedListItem = styled.div<{theme: string}>`
  text-align: center;
  width: 80%;
  padding: 5px;
  cursor: pointer;
  border: ${({theme}) =>
    theme === '000000'
      ? 'solid 1px black'
      : theme === '0C426A'
      ? 'solid 1px #0C426A'
      : 'solid 1px white'};

  & > p {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  }
`;

export const ResponseWrapper = styled.div<{theme: string}>`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({theme}) =>
    theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};

  & > p {
    font-size: 12px;
  }
  & > h4 > {
    font-size: 16px;
  }
`;

/// new view

const changeBgAniamtionOne = keyframes`
  0% {
  
    opacity: 0.3;
  }
  100% {
   
    opacity: 0.5;
  }
`;
const changeBgAniamtionSecondOne = keyframes`
  0% {
 
    opacity: 0.3;
  }
  100% {
    
    opacity: 0.5;
  }
`;

const changeBgAniamtionTwo = keyframes`
  0% {
  
    opacity: 0.7;
  }
  100% {
   
    opacity: 0.5;
  }
`;
const changeBgAniamtionSecondTwo = keyframes`
  0% {
 
    opacity: 0.7;
  }
  100% {
    
    opacity: 0.5;
  }
`;

const changeBgAniamtionThree = keyframes`
  0% {
  
    opacity: 0.8;
  }
  100% {
   
    opacity: 0.7;
  }
`;
const changeBgAniamtionSecondThree = keyframes`
  0% {
 
    opacity: 0.8;
  }
  100% {
    
    opacity: 0.7;
  }
`;

const slideRight = keyframes`
  0% {
    //opacity: 0;
    transform: rotateY(60deg);
    //transform: translateY(-100%);
  }
  100% {
    //opacity: 1;
    //transform: translateY(0);
    transform: rotateY(0deg);
  }
`;

const slideLeft = keyframes`
  0% {
    //opacity: 0;
    //transform: translateY(-99%);
    transform: rotateY(59deg);
  }
  100% {
    //opacity: 1;
    //transform: translateY(0);
    transform: rotateY(0deg);
  }
`;

const mainSlideRight = keyframes`
  0% {
    //opacity: 0;
    //transform: translateY(-100%);
    //z-index: 2;
    transform:translateX(-40%) rotateY(59deg) ;

  }
  100% {
    //opacity: 1;
    //transform: translateY(0) ;
    transform:translateX(0)  rotateY(0deg) ;
  }
`;

const mainSlideRightSecond = keyframes`
  0% {
    //opacity: 0;
    //transform: translateY(-99%);
    transform:translateX(-39%) rotateY(60deg) ;
   
  }
  100% {
    //opacity: 1;
    //transform: translateY(0);
    transform:translateX(0) rotateY(0deg) ;
   
  }
`;

const mainSlideLeft = keyframes`
  0% {
    //opacity: 0;
    //transform: translateY(100%);
    transform:translateX(40%) rotateY(-60deg) ;
  }
  100% {
    //opacity: 1;
    //transform: translateY(0);
    transform:translateX(0) rotateY(0deg) ;
  }
`;

const mainSlideLeftSecond = keyframes`
  0% {
    //opacity: 0;
    //transform: translateY(99%);
    transform:translateX(39%) rotateY(-59deg) ;
  }
  100% {
    //opacity: 1;
    //transform: translateY(0);
    transform:translateX(0) rotateY(0deg) ;
  }
`;

const deleteElement = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
`;
const deleteElementSecond = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-99%);
  }
`;

export const ProductionsNewWrapper = styled.div`
  width: 100%;
  height: 287px;
  position: relative;
  padding: 19px 57px 0px 57px;
  display: flex;
  overflow: hidden;
  margin-bottom: 5px;
`;

export const SingleProductContentWrapper = styled.div<{
  useSlideInAnimation: boolean;
  bgColor: string;
}>`
  height: 100%;
  //width: 194px;
  /* min-height: 194px; */
  width: 172px !important;
  /* box-shadow: 0 3px 9px rgba(0, 0, 0, 0.58); */
  border-radius: 24px;
  background: ${({bgColor}) => `rgba(${bgColor})`};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  animation: ${({useSlideInAnimation}) =>
      useSlideInAnimation ? slideLeft : slideRight}
    0.8s ease-in;

  & > img {
    width: 168px;
    height: 168px;
    border-radius: 22px;
  }
`;

export const MainProductContentWrapper = styled.div<{bgColor: string}>`
  height: 100%;
  width: 172px;
  /* box-shadow: 0 3px 9px rgba(0, 0, 0, 0.58); */
  border-radius: 24px;
  //background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: ${({bgColor}) => `rgba(${bgColor})`};

  & > img {
    width: 168px;
    height: 168px;
    border-radius: 22px;
  }
`;

export const SingleProductHeader = styled.div`
  height: 33px;
  width: 155px;
  overflow: hidden;
  margin-bottom: 4px;
`;

export const SingleProductName = styled.p`
  font-size: 11px;
  letter-spacing: -0.35px;
  font-weight: bold;
  text-align: center;
  //line-height: 32px;
  //height: 40px;
`;
export const SingleProductCategory = styled.div`
  width: 100%;
  overflow: hidden;

  display: flex;
  justify-content: center;
  height: 18px;
  margin-top: 5px;
  & > p {
    font-size: 11px;
    line-height: 18px;
    letter-spacing: -0.35px;
    opacity: 0.5;
  }
`;

export const SingleProductPriceAndWinWinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export const SingleProductPrice = styled.p`
  font-size: 13px;
  letter-spacing: -0.25px;
  font-weight: bold;
  //padding: 5px;
`;
export const SingleProductWinWinWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  & > p {
    font-size: 9px;
    //line-height: 24px;
    letter-spacing: 0.15px;
    color: #fb9614;
  }
`;
export const SingleProductTitle = styled.p`
  color: rgba(23, 24, 27, 0.5);
  font-size: 10px;
  letter-spacing: -0.25px;
  align-self: self-start;
  padding-left: 10px;
`;
export const SingleProductDescription = styled.p`
  color: #000000;
  font-size: 10px;
  letter-spacing: 0.05px;
  line-height: 12px;
  padding: 2px 5px 2px 10px;
  height: 75px;
  overflow: hidden;
`;

export const SingleProductFooter = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  height: 23px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
`;
export const SingleProductType = styled.p`
  font-size: 11px;
  line-height: 28px;
  text-align: center;
  //color: #5271ff;
`;

export const ButtonUpAndDownBg = styled.div`
  width: 38px;
  height: 21px;
  //background: #f8f8f8;
  //background: rgba(23, 24, 27, 0.8);
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  //margin-left: 5px;
  cursor: pointer;
  //box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const SingleProductBg = styled.div<{startOpacity: string}>`
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: ${({startOpacity}) => startOpacity};
`;

export const ButtonLeftAndRightWrapper = styled.div<{Width: number}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 5px;
  //padding: 5px;
  bottom: 5px;
  z-index: 5;
  cursor: pointer;
`;

export const ButtonBg = styled.div`
  width: 38px;
  height: 21px;
  background: #f8f8f8;
  //background: rgba(23, 24, 27, 0.8);
  display: flex;
  border-radius: 7px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  //margin: 3px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  & > svg {
    width: 14px;
    height: 18px;
  }
`;

export const DeleteProductButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 0px;
  z-index: 3;
`;

export const ProductAddButonWrapper = styled.div<{theme: string}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 48px;
    height: 48px;
    cursor: pointer;
    & > g {
      & > path {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};
      }
    }
  }
`;

export const EmptyWinWin = styled.div`
  height: 15px;
  width: 100%;
  & > p {
    font-size: 11px;
    font-weight: 400;
    text-align: center;
    letter-spacing: -0.35px;
    opacity: 0.5;
  }
`;

export const ProductsWrapper = styled.div<{isMobile: boolean}>`
  width: 100%;
  display: flex !important;
  border-radius: 24px;

  height: ${({isMobile}) => (isMobile ? '425px ' : '320px')};
  justify-content: ${({isMobile}) => (isMobile ? 'space-between ' : 'center')};
  gap: 11px;
  @media (max-width: 1024px) {
    height: 425px;
    justify-content: space-between;
  }
`;

export const SliderWrapper = styled.div<{isMobile: boolean}>`
  width: 100%;
  height: 330px;

  .slick-dots {
    bottom: ${({isMobile}) => (isMobile ? '95px' : '-8px')};
  }

  .slick-dots li {
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp100};
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    .slick-dots {
      bottom: 95px;
    }
  }
`;

export const SingleProduct = styled.div<{
  isSelected: boolean;
  bgColor: string;
  bgOpacity: number;
}>`
  width: 172px;
  height: 287px;
  background: ${({theme, isSelected, bgColor, bgOpacity}) =>
    // isSelected
    //   ? theme.colors.singleArticleHoverBackground
    //   : theme.colors.popularCategoryBackground
    `rgba(${bgColor},${bgOpacity})`};
  cursor: pointer;
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  padding: 2px 2px 0px;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: background 0.15s;

  &:hover {
    background: ${({theme}) => theme.colors.singleArticleHoverBackground};

    & > img {
      filter: brightness(1.1);
    }
  }

  & > img {
    height: 168px;
    width: 168px;
    border-radius: 22px;
    margin-bottom: 6px;
  }

  & > h1 {
    overflow: hidden;
    width: 100%;
    /* height: 33px; */
    height: 43px;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;
    padding: 0 6px;
    /* margin-bottom: 6px; */
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  & > h2 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;
    padding: 0 6px;
    margin-bottom: 9px;
  }

  & > p {
    height: 15px;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-align: center;
    padding: 0 6px;
    margin-bottom: 2px;
  }

  & > h4 {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-align: center;
    padding: 0 6px;
  }

  & > svg {
    width: 59.5px;
    height: 15px;
    margin-bottom: 2px;
  }

  @media (max-width: 390px) {
    width: 162px;
    padding: 1px 1px 0;

    & > img {
      height: 158px;
      width: 158px;
    }
  }
  @media (max-width: 360px) {
    width: 152px;

    & > img {
      height: 148px;
      width: 148px;
    }
  }
  @media (max-width: 340px) {
    width: 142px;
    height: 260px;
    & > img {
      height: 138px;
      width: 138px;
    }
  }
`;
