import styled from 'styled-components';

export const GreatHeaderBlockWrapper = styled.div<{
  bgColor: string;
  backgroundAlpha: number;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  //justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  /* padding: 12px 12px 12px 0px; */
  background: ${({bgColor, backgroundAlpha}) =>
    `rgb(${bgColor},${backgroundAlpha})`};
  position: relative;
`;
export const GreatHeaderBlockTitle = styled.div<{
  fontColor: string;
  mobileOverview: boolean;
  interline: number;
}>`
  width: 100%;
  text-align: center;
  padding-left: ${({mobileOverview}) => (mobileOverview ? '16px' : '58px')};
  padding-right: ${({mobileOverview}) => (mobileOverview ? '16px' : '58px')};
  /* padding-top: ${({mobileOverview}) => (mobileOverview ? '16px' : '0')}; */
  padding-bottom: ${({mobileOverview}) => (mobileOverview ? '16px' : '0')};
  height: ${({mobileOverview}) => (mobileOverview ? 'auto' : '105px')};
  /* position: absolute; */
  bottom: 0;
  & > p {
    font-size: ${({mobileOverview}) => (mobileOverview ? '36px' : '85px')};
    font-weight: bold;
    letter-spacing: 0.05px;
    /* line-height: 100px; */
    line-height: ${({mobileOverview, interline}) =>
      interline === 0 ? '50px' : mobileOverview ? '36px' : `${interline}px`};
    padding-top: 8px;
    font-family: 'Inter', sans-serif;
    /* padding-bottom: 12px; */
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }

  & > textarea,
  textarea:disabled {
    text-align: center;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    padding-top: 4px;
    font-family: 'Inter', sans-serif;
    font-size: ${({mobileOverview}) => (mobileOverview ? '36px' : '85px')};
    opacity: 1;
    font-weight: normal;
    /* line-height: 100px; */
    line-height: ${({mobileOverview, interline}) =>
      interline === 0 ? '72px' : mobileOverview ? '36px' : `${interline}px`};
    letter-spacing: -0.05px;
    overflow: hidden;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    resize: none;
  }
  @media (max-width: 1024px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0px;
    height: auto;
    position: none;
    /* position: relative; */
    & > p {
      font-size: 36px;
      /* position: absolute;
      bottom: 0; */
      line-height: 72px;
      width: 100%;
    }
    & > textarea,
    textarea:disabled {
      /* position: absolute; */
      bottom: 0;
      font-size: 36px;
      line-height: 26px;
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    padding-left: 14px;
    padding-right: 14px;
  }
  @media (max-width: 340px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
