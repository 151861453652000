import styled, {css} from 'styled-components';

export const Main = styled.header<{isInactive?: boolean}>`
  width: 100%;
  height: 80px;
  /* background: #f8f8f8; */
  background: ${({theme}) => theme.colors.mainHeader};
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  /* border-bottom: 1px solid ${({theme}) => theme.colors.whiteOp030}; */

  @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    /* height: 48px; */
    height: auto;
    background: ${({theme}) => theme.colors.mainHeader};
    border-bottom: ${({theme}) => `1px solid ${theme.colors.bgGrey}`};
    display: ${({isInactive}) => (isInactive ? 'none' : 'flex')};
    flex-wrap: wrap;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: sticky;
    top: 0px;
    z-index: 2;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.07));
  }
`;

export const MenuMain = styled.div<{isMenuOpen: boolean; decodedToken?: any}>`
  position: absolute;
  height: 310px;
  width: 100%;
  display: none;
  flex-direction: column;
  z-index: 99;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: ${(props) => (props.isMenuOpen ? 'flex' : 'none')};
    /* min-height: calc(100vh - 105px); */
    min-height: 110vh;

    position: fixed;
    top: 48px;

    background: #f8f8f8;

    touch-action: none;

    @media (max-height: 500px) {
      /* overflow-y: auto; */
      /* min-height: 325px; */
      /* padding-top: 66px; */
      height: calc(100vh - 105px);
      min-height: unset;
    }
  }
`;

export const MenuWrapper = styled.div<{decodedToken?: any}>`
  width: 100%;
  height: 100%;
  z-index: 2;
  background: ${({theme}) => theme.colors.pageBodyLeft};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    touch-action: none;
    padding-bottom: 10vh;

    @media (max-height: 500px) {
      overflow-y: auto;
      padding-top: ${({decodedToken}) => (decodedToken ? '66px' : 'unset')};
    }

    @media (max-height: 430px) {
      padding-top: ${({decodedToken}) => (decodedToken ? '126px' : 'unset')};
    }

    @media (max-height: 370px) {
      padding-top: ${({decodedToken}) => (decodedToken ? '196px' : 'unset')};
    }
  }
`;

export const SingleMenuLink = styled.div<{isActive?: boolean}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 19px;

  & > p {
    color: ${({isActive, theme}) =>
      isActive ? theme.colors.fontBlue : theme.colors.fontBlackOp100};
    transition: all 0.15s;
    letter-spacing: 0.15px;
    cursor: ${({isActive}) => (isActive ? 'default' : 'pointer')};
    text-decoration: none;

    &:hover {
      color: rgba(255, 255, 255, 0.92);
    }
  }

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const MenuLinkSmall = styled.div<{isActive?: boolean}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  margin-bottom: 10px;

  & > p {
    color: ${({isActive, theme}) =>
      isActive ? theme.colors.fontBlue : theme.colors.fontBlackOp100};
    transition: all 0.15s;
    letter-spacing: 0.15px;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    cursor: ${({isActive}) => (isActive ? 'default' : 'pointer')};
    text-decoration: none;

    &:hover {
      color: rgba(255, 255, 255, 0.92);
    }
  }

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    /* width: 328px; */
    justify-content: space-between;
    z-index: 6;
    padding: 20px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* padding: 0px 24px; */
    justify-content: space-between;
  }
`;

export const MenuButton = styled.div<{isMenuOpen: boolean}>`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 33px;
    height: 33px; */
    user-select: none;

    ${({isMenuOpen}) =>
      isMenuOpen &&
      css`
        /* border-radius: 50%;
        padding: 10px;
        background: rgba(255, 255, 255, 0.21); */
      `}
  }
  & > img {
    cursor: pointer;
    width: 18px;
    height: 12px;
  }
  & > svg {
    & > g {
      & > path {
        stroke: ${({theme, isMenuOpen}) =>
          isMenuOpen ? theme.colors.blueTheme : theme.colors.fontBlackOp50};
      }
    }
  }
`;

export const BackButton = styled.div`
  height: 80px;
  width: 81px;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  & > img {
    height: 21px;
    cursor: pointer;
    transition: all 0.15s;

    &:hover {
      filter: brightness(0.6);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const LeftLine = styled.div`
  height: 55px;
  width: 1px;
  background: #352f40;
  left: -18px;
  position: absolute;
`;

export const UserNameWithMenuWrapper = styled.div<{
  isActive: boolean;
  isMenuOpen: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -478px;
`;

export const TextWithSvg = styled.div<{isActive: boolean; isMenuOpen: boolean}>`
  gap: 0 10px;
  width: 160px;
  display: flex;
  /* position: absolute; */
  padding: 0 8px;
  border-radius: 9px;
  border: ${({theme}) => `1px solid ${theme.colors.headerBorderColor}`};
  background: ${({isMenuOpen, theme}) =>
    isMenuOpen ? theme.colors.mainHeader : 'unset'};
  /* right: -491px; */
  z-index: 333;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 55px;
  /* transition: all 0.15s; */
  cursor: pointer;
  box-shadow: ${({isMenuOpen}) =>
    isMenuOpen ? '0px 3px 5px 0px rgba(0, 0, 0, 0.12)' : 'unset'};

  &:hover {
    & > p {
    }
  }

  & > p {
    font-size: 11px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    transition: all 0.15s;
    /* width: 80px; */
    height: 16px;
    /* overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      display: none;
    }
  }

  ${({isActive}) =>
    isActive &&
    css`
      cursor: default;
    `}

  & > img {
    height: 16px;

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      margin: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0;
    padding: 10px;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: -372px;
  top: 5px;
  justify-content: center;
  transition: all 0.15s;
  cursor: pointer;

  &:hover {
    border-radius: 0 0 9px 9px;
  }

  & > img {
    height: 20px;
    width: 118.34px;
    margin-top: 14px;

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      height: 18px;
      margin: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0;
    padding: 0;
    height: 100%;
  }
`;

export const LinkTitleOnHover = styled.div`
  position: absolute;
  background: ${({theme}) => theme.colors.blueTheme};
  height: 14px;
  padding: 0 9px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  top: 6px;
  left: 36px;

  & > p {
    width: 100%;
    color: white;
    font-size: 9px;
    letter-spacing: 0.625px;
    font-weight: 400;
    white-space: nowrap;
  }
`;

export const SingleNavLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  /* padding: 0 8px; */
  /* margin-bottom: 10px; */
  /* transition: all 0.15s; */
  position: relative;
  cursor: pointer;
  border-radius: 0 0 9px 9px;

  &:hover {
    /* border: 1px solid rgba(255, 255, 255, 0.21);
      background: #7270f2; */

    & > p {
      color: #3a87f0;
    }

    & > img {
      /* filter: opacity(1); */
    }

    & > div {
      display: flex;
    }
  }
`;

export const NavWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0 30px;
  position: relative;
  margin-bottom: 18px;

  &:hover {
    & > img {
      filter: opacity(1);
    }
  }

  & > .active {
    /* border: 1px solid rgba(255, 255, 255, 0.21);
    background: #7270f2; */
    cursor: pointer;

    & > svg {
      & > path {
        fill: #3a87f0;
      }
      & > g {
        & > path {
          fill: #3a87f0;
        }
      }
    }

    & > p {
      color: #3a87f0;
      /* border-bottom: 2px solid ${({theme}) => theme.colors.white}; */
    }

    & > div {
      display: none;
    }

    &:hover {
      border-radius: 50%;
      background: rgba(57, 134, 239, 0.09);

      & > div {
        display: flex;
      }

      & > svg {
        & > g {
          & > path {
            fill: #3a87f0;
          }
        }
      }

      & > img {
        /* filter: opacity(0.3); */
      }
    }
  }

  & > .inactive {
    border-radius: 50%;
    & > img {
      /* filter: opacity(0.3); */
    }

    &:hover {
      border-radius: 50%;
      background: rgba(57, 134, 239, 0.09);

      & > svg {
        & > g {
          & > path {
            fill: #3a87f0;
          }
        }
      }

      & > img {
        /* filter: opacity(0.3); */
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const NavbarIcon = styled.img`
  /* position: absolute;
  top: 18px; */
`;

export const ButtonMenuWrapper = styled.div<{isMenuOpen: boolean}>`
  min-height: 88px;
  position: absolute;
  top: 36px;
  width: 100%;
  display: none;
  flex-direction: column;
  overflow: hidden;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
  background: ${({theme}) => theme.colors.bgGrey};
  padding-top: 18px;
  opacity: 1;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: ${({theme}) => `1px solid ${theme.colors.headerBorderColor}`};
  border-top: 1px solid hsla(0, 0%, 100%, 0.4);

  display: ${({isMenuOpen}) => (isMenuOpen ? 'flex' : 'none')};

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    top: 49px;
    right: 22px;
  }

  @media (max-width: 360px) {
    right: 0;
    width: 100%;
  }

  & > a {
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-decoration: none;
    /* height: 22px; */
    font-size: 11px;
    font-weight: 400;
    line-height: 22px;
    padding: 0;
    letter-spacing: 0.625px;
    z-index: 100;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;

    & > span {
      width: 100%;
      text-align: center;
      /* height: calc(100% - 6px);
      width: calc(100% - 6px); */
      border-radius: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* padding: 0 0 0 25px; */

      &:hover {
        color: ${({theme}) => theme.colors.fontBlackOp100};
        background: ${({theme}) => theme.colors.blueTheme};

        & > img {
          opacity: 1;
        }
      }

      & > img {
        margin: 0 23px 0 0;
        height: 23px;
        width: 21px;
        enable-background: new 0 0 32 32;
        opacity: 0.5;
      }
    }
  }
`;

export const NavIconsWrapper = styled.div`
  display: flex;
  gap: 22px;
  height: 48px;
  align-items: center;
  position: absolute;
  right: -290px;
  top: 5px;
`;

export const DarkLightModeIconsWrapper = styled.div`
  /* position: absolute; */
  right: -300px;
  top: 6px;
  gap: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* padding-right: 10px; */
  border-right: ${({theme}) => `solid 1px ${theme.colors.blueTheme}`};
  & > svg {
    cursor: pointer;
    & > g {
      & > path {
        fill: ${({theme}) => theme.colors.fontBlackOp100};
      }
    }
  }
`;

export const MobileNavSliderWrapper = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    color: black;
    width: 100%;
    display: flex;
  }
`;

export const MobileNavSliderItem = styled.div<{isActive: boolean}>`
  /* width: 150px;
  min-width: 150px; */
  width: 33%;
  min-width: 33%;
  background: ${({isActive, theme}) =>
    isActive ? theme.colors.mobileNavbarActiveItemBg : 'transparent'};
  padding: 10px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  cursor: pointer;
  & > p {
    color: ${({isActive, theme}) =>
      isActive ? theme.colors.fontBlue : theme.colors.mobileNavbarText};
    text-align: center;
  }
`;

export const TestWrapper = styled.div`
  display: none;
  @media (max-width: 1024px) {
    /* position: absolute; */
    width: 100%;
    margin: 0 auto;
    /* pointer-events: none; */
    display: flex;
    /* align-items: center;
  justify-content: center; */
    /* user-select: none; */

    overflow: hidden;
    transition: unset;
  }
`;

export const ColorThemeWrapper = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    /* width: 77px; */
    height: 25px;
    justify-content: space-between;
    align-items: center;
    gap: 22px;
    margin-right: 15px;
  }
`;
