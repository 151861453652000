import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const NavButtonsWrapper = styled.div`
  width: 370px;
  /* height: 0px;
  position: fixed;
  top: 15px; */
  /* z-index: 2; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* padding-top: 10px; */

  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const MenuMain = styled.div<{isMenuOpen: boolean; decodedToken?: any}>`
  width: 100%;
  background: ${({theme}) => theme.colors.bgWhite};
  border-top: ${({isMenuOpen}) => (isMenuOpen ? '4px solid #3A87F0' : 'none')};
  height: ${({isMenuOpen}) => (isMenuOpen ? '100vh' : '0px')};
  border-radius: 16px;
  position: fixed;
  overflow-y: auto;
  bottom: 0;
  transition: all 0.35s ease-out;
  z-index: 20;
  padding-bottom: ${({isMenuOpen}) => (isMenuOpen ? '42px' : '0px')};
`;

export const MenuWrapper = styled.div<{decodedToken?: any}>`
  width: 100%;
  padding: 0 32px;
  gap: 13px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  border-radius: 15px;
  /* background: rgba(255, 255, 255, 0.1); */
  /* padding: 10px; */
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 143px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-bottom: 20px; */
  pointer-events: all;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1100px) {
    width: 720px;
  }

  @media (max-width: 800px) {
    width: 360px;
  }
`;

export const Grid = styled.div`
  width: 100%;
  background: transparent;
  height: 100%;
  //display: grid;
  border-radius: 9px;
  /* padding: 10px; */
  padding-top: 50px;
  padding-left: 14px;
  gap: 10px 11px;
  //grid-template-columns: repeat(3, 1fr);
  //grid-auto-rows: 116px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  @media (max-width: 1200px) {
    height: calc(100vh - 116px);
  }

  @media (max-width: 1100px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const FilterWrapper = styled.div`
  width: 100%;
  height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > img {
    cursor: pointer;
  }
`;

export const FilterInputWrapper = styled.div`
  width: 132px;
  height: 28px;
  border-radius: 9px;
  background: transparent;
  border: ${({theme}) => `solid 1px ${theme.colors.headerBorderColor}`};
  position: absolute;
  /* right: 36px; */
  right: 142px;
  top: -121px;
  display: flex;
  justify-content: space-between;
  /* padding: 9px 11px; */
  align-items: center;
  margin: 0;

  & > img {
    width: 11px;
    margin: 0 11px;
  }
  & > svg {
    width: 14.15px;
    margin-left: 10px;
    margin-right: 7px;
    & > g {
      & > path {
        fill: ${({theme}) => theme.colors.fontBlackOp100};
      }
    }
  }
  & > input {
    width: calc(100% - 20px - 28px - 3px);
    height: 100%;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 11px;
    font-weight: 400;
    background: transparent;
    letter-spacing: 0.15px;
    border: none;
    text-align: left;
    outline: none;

    ::placeholder {
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const FilterButton = styled.div<{isFilterMenuOpen: boolean}>`
  width: 28px;
  height: 22px;
  border-radius: 7px;
  margin-right: 1px;
  background: ${({isFilterMenuOpen, theme}) =>
    isFilterMenuOpen ? '#DCE0E9' : theme.colors.blueTheme};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > img {
    height: 15px;
    width: 15px;
  }
`;

export const SingleFilterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const SingleFilterHeaderWrapper = styled.div`
  height: 40px;
  width: 100%;
  border-bottom: 1px solid rgba(7, 2, 15, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  & > p {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-bottom: none;

    & > p {
      font-size: 17px;
      color: #352f40;
      letter-spacing: -0.05px;
      line-height: 24px;
      font-weight: 400;
    }
  }
`;

export const SingleFilterContentWrapper = styled.div`
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
`;

export const FilterMenuWrapper = styled.div`
  width: 100%;
  /* max-height: calc(90%); */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* height: 40px; */
  margin-bottom: 20px;
  /* gap: 5px; */

  @media (max-width: 1045px) {
    grid-template-columns: 1fr;
  }
`;

export const PageHeaderWrapper = styled.div`
  width: 1020px;
  display: flex;
  /* height: 152px; */
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  align-items: center;
  background: ${({theme}) => theme.colors.bgWhite};
  border-radius: 24px 24px 21px 21px;
  position: absolute;
  top: 88px;
  z-index: 30;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.headerBlackFont};
    font-size: 15px;
    letter-spacing: 0.625px;
  }
`;

export const PageHeaderContent = styled.div`
  width: 100%;
  /* height: calc(100% - 55px); */
  padding: 0;
  display: grid;
  align-items: center;
`;

export const SearchWrapper = styled.div`
  width: 40%;
  padding: 10px;
  margin: 0 auto;
`;

export const SingleCardWrapper = styled.div`
  width: calc(100% - 2px);
  height: 90px;
  background: ${({theme}) => theme.colors.producersBackground};
  border-radius: 16px;
  border: ${({theme}) => `1px solid ${theme.colors.producersBorderColor}`};
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px;
  position: relative;
  cursor: pointer;
  transition: all 0.15s;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  & > p {
    font-size: 18px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.05px;
    transition: all 0.15s;
    text-decoration: none;
    margin-left: 10px;
    padding: 10px;
  }

  &:hover {
    border-color: #0066ff;
  }
`;
