import React, {useState} from 'react';
import {Block} from '../../interface';
import {YouTubeBlockWrapper, YtWrapper} from './styles';
import {hexToRgb} from '../..';
import youtubePlaceholder from '../../../../../images/CardOverview/placeholder_youtube.png';

interface Props {
  theme: string;
  block: Block;
  isEditing: boolean;
  changeText: ({title}) => void;
  mobileOverview: boolean;
}

export const YouTubeBlock: React.FC<Props> = ({
  theme,
  block,
  isEditing,
  changeText,
  mobileOverview,
}) => {
  // function convertYouTubeLinkToEmbed(link) {
  //   if (!link) return;
  //   const url = new URL(link);
  //   const videoId = url.searchParams.get('v');
  //   return `https://www.youtube.com/embed/${videoId}`;
  // }

  function convertYouTubeLinkToEmbed(link) {
    if (!link) return;
    try {
      const url = new URL(link);
      let videoId;
      if (url.hostname.includes('youtu.be')) {
        videoId = url.pathname.slice(1); // dla skróconego formatu youtu.be
      } else {
        videoId = url.searchParams.get('v'); // dla normalnego formatu
      }
      return `https://www.youtube.com/embed/${videoId}`;
    } catch (error) {
      console.error('Invalid YouTube link:', error);
      return null;
    }
  }

  return (
    <YouTubeBlockWrapper
      bgColor={hexToRgb(block.BackgroundColorHex)}
      backgroundAlpha={block.BlockOpacityIn0To100Ratio}
    >
      {block.Url ? (
        block.Url.Url !== '' ? (
          <YtWrapper
            isMobile={mobileOverview}
            width="560"
            height="315"
            // allowFullScreen
            src={convertYouTubeLinkToEmbed(block.Url.Url)}
          ></YtWrapper>
        ) : (
          <img
            style={{width: 'auto', height: '100%'}}
            src={youtubePlaceholder}
            alt="yt"
          />
        )
      ) : (
        <img
          style={{width: 'auto', height: '100%'}}
          src={youtubePlaceholder}
          alt="yt"
        />
      )}
    </YouTubeBlockWrapper>
  );
};
