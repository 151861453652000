import styled from 'styled-components';

export const Embla = styled.section`
  display: none;
  @media (max-width: 1024px) {
    display: block;
    max-width: 100%;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 33%;
  }
`;

export const EmblaViewPort = styled.div`
  overflow: hidden;
`;

export const EmblaContainer = styled.div`
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
`;

export const EmblaSlide = styled.div<{isActive: boolean}>`
  /* flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing); */
  width: 33%;
  min-width: 33%;
  background: ${({isActive, theme}) =>
    isActive ? theme.colors.mobileNavbarActiveItemBg : 'transparent'};
  padding: 10px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  cursor: pointer;
  & > p {
    color: ${({isActive, theme}) =>
      isActive ? theme.colors.fontBlue : theme.colors.mobileNavbarText};
    text-align: center;
  }
`;

export const EmblaControls = styled.div`
  display: none;
`;
