import {
  AccountBankEditAndSaveBtn,
  AccountBasicButtonWrapper,
  AccountBigBlueBtn,
  AccountBigBlueBtnWrapper,
  AccountBox,
  AccountBoxText,
  AccountBoxWrapper,
  AccountInfoText,
  AccountInputWrapper,
  AccountMangopayCompanyWrapper,
  AccountMangoPayFormWrapper,
  AccountMangopayWrapper,
  AccountSmallBtn,
  AccountSmallInputWrapper,
  AccountStatusInfo,
  BlueBox,
  FlexSaveBtn,
  IdScansButtonWrapper,
  IdScansWrapper,
  LogoContainer,
  MangoPayBasicButton,
  PaymentLogoWrapper,
  RelativeDiv,
  ScansResponsWrapper,
  ScrollWrapper,
  SendBtn,
  SmallBtnWrapper,
  WrongIdMessageWrapper,
  InputView,
  SelectView,
  HoverTextForEdit,
  MangopayStatusWrapper,
  MangopayHeaderForm,
  DescriptionForInput,
  InputViewForData,
  StyledStatusWrapper,
  StyledStatusLine,
  TwoSmallInputsWrapper,
} from '../styles';
import mangopayLogo from '../../../../images/svg/mangopay-logo.svg';
import ueLogo from '../../../../images/ue-logo-text.png';
import React, {useContext, useEffect, useRef, useState} from 'react';
import useAlert from '../../../../hooks/useAlert';
// import {InputView, SelectView} from '../../../MyAccount/Settings/styles';
import {ErrorStar} from '../../../../components/ErrorStar';
import {
  CountryAndNationalities,
  MangopayAccount,
  MangopayCompanyAccount,
  MangopayCompanyAccountErrors,
  UBO,
} from '../interface';
import PopupButton from '../../../../components/PopUp/PopupButton';
import api from '../../../../services/axiosConfig';
import {useCookies} from 'react-cookie';
import {Select} from '../../../../components/Select';
import {ResolutionContext} from '../../../../contexts/ResolutionContext';
import {light} from 'constants/theme';
import {EditBtnWrapper, EditSvgWrapper} from 'constants/globalElements';
import {StyledSelectView} from '..';
import {getKYCLevelStatusDescription} from '../AccountMangopayNaturalPersonForm';
import {ProducerDataContext} from 'contexts/ProducerDataContext';

const mangopayRefusedReasonType = {
  DOCUMENT_UNREADABLE: 'Dokument nieczytelny',
  DOCUMENT_NOT_ACCEPTED: 'Dokument nieakceptowany',
  DOCUMENT_HAS_EXPIRED: 'Ważność dokumentu',
  DOCUMENT_INCOMPLETE: 'Dokument niekompletny',
  DOCUMENT_MISSING: 'Brak dokumentu',
  DOCUMENT_DO_NOT_MATCH_USER_DATA: 'Dokument nie pasuję do danych użytkownika',
  DOCUMENT_FALSIFIED: 'Dokument sfałszowany',
  UNDERAGE_PERSON: 'Osoba niepełnoletnia',
  SPECIFIC_CASE: 'Szczególny przypadek',
  CREATED: 'Coś poszło nie tak, prosimy ponownie przesłać dokument',
};

const mangopayBusinessTypeObj = {
  BUSINESS: {text: 'Spółka firmowa', value: 'BUSINESS'},
  // PARTNERSHIP: {text: 'Spółka partnerska', value: 'PARTNERSHIP'},
  ORGANIZATION: {text: 'Fundacja', value: 'ORGANIZATION'},
  SOLETRADER: {text: 'Jednoosobowa działalność gosp.', value: 'SOLETRADER'},
};

const mangopayBusinessTypeOptionsArr = [
  {text: 'Spółka firmowa', value: 'BUSINESS'},
  // {text: 'Spółka partnerska', value: 'PARTNERSHIP'},
  {text: 'Fundacja', value: 'ORGANIZATION'},
  {text: 'Jednoosobowa działalność gosp.', value: 'SOLETRADER'},
];

const nationalityTypeObj = {
  PL: {text: 'Polska', value: 'PL'},
};

const nationalityTypeOptionsArr = [{text: 'Polska', value: 'PL'}];

interface Props {
  mangopay: MangopayCompanyAccount;
  createAccount: (
    data: MangopayCompanyAccount,
    imgs: string[],
  ) => Promise<void>;
  formType: number;
  handleType: (type: number) => void;
  isEditing: boolean;
  nationalities: CountryAndNationalities[];
  countries: CountryAndNationalities[];
  handleEditingMode: () => void;
  handleLoading: (isLoading: boolean) => void;
  showSavePopUp: (data: MangopayCompanyAccount, validated: boolean) => void;
}

export const AccountMangopayCompanyForm: React.FC<Props> = ({
  mangopay,
  createAccount,
  formType,
  handleType,
  isEditing,
  handleEditingMode,
  showSavePopUp,
  nationalities,
  countries,
  handleLoading,
}) => {
  const [mangopayUbo, setMangopayUbo] = useState<UBO>({
    Id: '',
    FirstName: '',
    LastName: '',
    Nationality: '',
    Birthday: null,
    Birthplace: {City: '', Country: ''},
    Address: {
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      Region: '',
      PostalCode: '',
      Country: '',
      FlatNumber: '',
      BuildNumber: '',
    },
  });

  const [mangopayUboError, setMangopayUboError] = useState({
    FirstName: false,
    LastName: false,
    Nationality: false,
    Birthday: false,
    ShowErrors: false,
    Birthplace: {City: false, Country: false},
    Address: {
      AddressLine1: false,
      AddressLine2: false,
      City: false,
      Region: false,
      PostalCode: false,
      Country: false,
      FlatNumber: false,
      BuildNumber: false,
    },
  });

  const [uboPersonTwo, setUboPersonTwo] = useState<UBO>({
    Id: '',
    FirstName: '',
    LastName: '',
    Nationality: '',
    Birthday: null,
    Birthplace: {City: '', Country: ''},
    Address: {
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      Region: '',
      PostalCode: '',
      Country: '',
      FlatNumber: '',
      BuildNumber: '',
    },
  });

  const [uboPersonTwoError, setUboPersonTwoError] = useState({
    FirstName: false,
    LastName: false,
    Nationality: false,
    Birthday: false,
    ShowErrors: false,
    Birthplace: {City: false, Country: false},
    Address: {
      AddressLine1: false,
      AddressLine2: false,
      City: false,
      Region: false,
      PostalCode: false,
      Country: false,
      FlatNumber: false,
      BuildNumber: false,
    },
  });

  const [addUboTwo, setAddUboTwo] = useState(false);

  const [uboPersonThree, setUboPersonThree] = useState<UBO>({
    Id: '',
    FirstName: '',
    LastName: '',
    Nationality: '',
    Birthday: null,
    Birthplace: {City: '', Country: ''},
    Address: {
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      Region: '',
      PostalCode: '',
      Country: '',
      FlatNumber: '',
      BuildNumber: '',
    },
  });

  const [uboPersonThreeError, setUboPersonThreeError] = useState({
    FirstName: false,
    LastName: false,
    Nationality: false,
    Birthday: false,
    ShowErrors: false,
    Birthplace: {City: false, Country: false},
    Address: {
      AddressLine1: false,
      AddressLine2: false,
      City: false,
      Region: false,
      PostalCode: false,
      Country: false,
      FlatNumber: false,
      BuildNumber: false,
    },
  });

  const [addUboThree, setAddUboThree] = useState(false);

  const [uboPersonFour, setUboPersonFour] = useState<UBO>({
    Id: '',
    FirstName: '',
    LastName: '',
    Nationality: '',
    Birthday: null,
    Birthplace: {City: '', Country: ''},
    Address: {
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      Region: '',
      PostalCode: '',
      Country: '',
      FlatNumber: '',
      BuildNumber: '',
    },
  });

  const [uboPersonFourError, setUboPersonFourError] = useState({
    FirstName: false,
    LastName: false,
    Nationality: false,
    Birthday: false,
    ShowErrors: false,
    Birthplace: {City: false, Country: false},
    Address: {
      AddressLine1: false,
      AddressLine2: false,
      City: false,
      Region: false,
      PostalCode: false,
      Country: false,
      FlatNumber: false,
      BuildNumber: false,
    },
  });

  const [addUboFour, setAddUboFour] = useState(false);

  const [somethingIsChnagedInUbo, setSomethingIsChnagedInUbo] = useState(false);

  const [mangopayAccount, setmangopayAccount] =
    useState<MangopayCompanyAccount>({
      LegalPersonType: 'SOLETRADER',
      Name: '',
      LegalRepresentativeBirthday: null,
      LegalRepresentativeCountryOfResidence: '',
      LegalRepresentativeNationality: '',
      LegalRepresentativeFirstName: '',
      LegalRepresentativeLastName: '',
      Email: '',
      CompanyNumber: '',
      TermsAndConditionsAccepted: false,
      HeadquartersAddress: {
        AddressLine1: '',
        AddressLine2: '',
        City: '',
        Region: '',
        PostalCode: '',
        Country: '',
        FlatNumber: '',
        BuildNumber: '',
      },
      LegalRepresentativeAddress: {
        AddressLine1: '',
        AddressLine2: '',
        City: '',
        Region: '',
        PostalCode: '',
        Country: '',
        FlatNumber: '',
        BuildNumber: '',
      },
      IBAN: '',
      Status: -1,
      Type: 1,
      UserCategory: '',
      KYCLevel: '',
      DocIdentityProof: {
        Type: '',
        Status: '',
        RefusedReasonMessage: '',
        RefusedReasonType: '',
        ProcessedDate: '',
        Id: '',
        CreationDate: '',
      },
      DocArticlesOfAssociation: {
        Type: '',
        Status: '',
        RefusedReasonMessage: '',
        RefusedReasonType: '',
        ProcessedDate: '',
        Id: '',
        CreationDate: '',
      },
      DocRegistrationProof: {
        Type: '',
        Status: '',
        RefusedReasonMessage: '',
        RefusedReasonType: '',
        ProcessedDate: '',
        Id: '',
        CreationDate: '',
      },
      UboDeclaration: {
        CreationDate: '',
        Id: '',
        Message: '',
        ProcessedDate: '',
        Reason: '',
        Status: '',
        Ubos: [],
      },
    });
  const [mangopayErrors, setMangopayErrors] =
    useState<MangopayCompanyAccountErrors>({
      LegalPersonType: false,
      Name: false,
      LegalRepresentativeBirthday: false,
      LegalRepresentativeCountryOfResidence: false,
      LegalRepresentativeNationality: false,
      LegalRepresentativeFirstName: false,
      LegalRepresentativeLastName: false,
      Email: false,
      CompanyNumber: false,
      TermsAndConditionsAccepted: false,
      HeadquartersAddress: {
        AddressLine1: false,
        AddressLine2: false,
        City: false,
        Region: false,
        PostalCode: false,
        Country: false,
      },
      LegalRepresentativeAddress: {
        AddressLine1: false,
        AddressLine2: false,
        City: false,
        Region: false,
        PostalCode: false,
        Country: false,
      },
      ShowErrors: false,
      IBAN: false,
    });
  const [idScans, setIdScans] = useState<string[]>([]);
  const [validateScans, setValidateScans] = useState({
    front: false,
    back: false,
  });
  const [btnType, setBtnType] = useState({
    front: false,
    back: false,
  });
  const [idScansFrontError, setIdScansFrontError] = useState({
    showErrors: false,
    frontId: {
      isError: true,
      text: 'Skanu dowodu tożsamości jest wymagane',
    },
    backId: {
      isError: true,
      text: 'Skanu dowodu tożsamości jest wymagane',
    },
  });

  const [isHoveredForEditBtn, setIsHoveredForEditBtn] =
    useState<boolean>(false);

  const [registrationScans, setRegistrationScans] = useState<string[]>([]);
  const [validateRegistrationScans, setValidateRegistrationScans] = useState({
    front: false,
    back: false,
  });
  const [btnRegistrationType, setBtnRegistrationType] = useState({
    front: false,
    back: false,
  });

  const [registrationScansFrontError, setRegistrationScansFrontError] =
    useState({
      showErrors: false,
      frontId: {
        isError: true,
        text: 'Skan dokumentu rejestracji firmy jest wymagane',
      },
      backId: {
        isError: true,
        text: 'Skan dokumentu rejestracji firmy jest wymagane',
      },
    });

  const [
    validateArticlesOfAssociationScans,
    setValidateArticlesOfAssociationScans,
  ] = useState({
    front: false,
    back: false,
  });
  const [articlesOfAssociationScans, setArticlesOfAssociationScans] = useState<
    string[]
  >([]);
  const [btnArticlesOfAssociationType, setBtnArticlesOfAssociationType] =
    useState({
      front: false,
      back: false,
    });

  const [
    articlesOfAssociationScansFrontError,
    setArticlesOfAssociationScansFrontError,
  ] = useState({
    showErrors: false,
    frontId: {
      isError: true,
      text: 'Skan statutu stowarzyszenia jest wymagane',
    },
    backId: {
      isError: true,
      text: 'Skan statutu stowarzyszenia jest wymagane',
    },
  });

  const {Alert} = useAlert();

  const [cookies] = useCookies(['token']);

  const [isSomethingChange, setIsSomethingChange] = useState(false);

  const inputIdFileRef = useRef(null as any);
  const inputIdFileBackRef = useRef(null as any);

  const inputAdrressFileRef = useRef(null as any);
  const inputAdrressBackFileRef = useRef(null as any);

  const inputArticlesOfAssociationFileRef = useRef(null as any);
  const inputArticlesOfAssociationBackFileRef = useRef(null as any);
  const {windowWidth} = useContext(ResolutionContext);
  const {decodedToken} = useContext(ProducerDataContext);

  if (mangopayAccount.Status !== mangopay.Status) setmangopayAccount(mangopay);

  // console.log(mangopayAccount, 'account state');
  // console.log(mangopay, 'mangopay props');

  if (
    mangopay.UboDeclaration !== null &&
    mangopay.UboDeclaration.Ubos.length !== 0 &&
    mangopayAccount.Status !== mangopay.Status
  ) {
    setMangopayUbo((prevState) => ({
      ...prevState,
      Id: mangopay.UboDeclaration.Ubos[0].Id,
      FirstName: mangopay.UboDeclaration.Ubos[0].FirstName,
      LastName: mangopay.UboDeclaration.Ubos[0].LastName,
      Nationality: mangopay.UboDeclaration.Ubos[0].Nationality,
      Birthday: mangopay.UboDeclaration.Ubos[0].Birthday,
      Birthplace: {
        City: mangopay.UboDeclaration.Ubos[0].Birthplace.City,
        Country: mangopay.UboDeclaration.Ubos[0].Birthplace.Country,
      },
      Address: {
        AddressLine1:
          mangopay.UboDeclaration.Ubos[0].Address.AddressLine1.slice(
            0,
            mangopay.UboDeclaration.Ubos[0].Address.AddressLine1.indexOf('/'),
          ).trim(),
        AddressLine2: mangopay.UboDeclaration.Ubos[0].Address.AddressLine2,
        City: mangopay.UboDeclaration.Ubos[0].Address.City,
        Region: '',
        PostalCode: mangopay.UboDeclaration.Ubos[0].Address.PostalCode,
        Country: mangopay.UboDeclaration.Ubos[0].Address.Country,
        FlatNumber: mangopay.UboDeclaration.Ubos[0].Address.AddressLine2.slice(
          0,
          mangopay.UboDeclaration.Ubos[0].Address.AddressLine2.indexOf('/'),
        ).trim(),
        BuildNumber: mangopay.UboDeclaration.Ubos[0].Address.AddressLine2.slice(
          mangopay.UboDeclaration.Ubos[0].Address.AddressLine2.indexOf('/') + 1,
        ).trim(),
      },
    }));
  }

  if (
    mangopay.UboDeclaration !== null &&
    mangopay.UboDeclaration.Ubos.length > 1 &&
    mangopayAccount.Status !== mangopay.Status
  ) {
    setUboPersonTwo((prevState) => ({
      ...prevState,
      Id: mangopay.UboDeclaration.Ubos[1].Id,
      FirstName: mangopay.UboDeclaration.Ubos[1].FirstName,
      LastName: mangopay.UboDeclaration.Ubos[1].LastName,
      Nationality: mangopay.UboDeclaration.Ubos[1].Nationality,
      Birthday: mangopay.UboDeclaration.Ubos[1].Birthday.slice(0, 10),
      Birthplace: {
        City: mangopay.UboDeclaration.Ubos[1].Birthplace.City,
        Country: mangopay.UboDeclaration.Ubos[1].Birthplace.Country,
      },
      Address: {
        AddressLine1:
          mangopay.UboDeclaration.Ubos[1].Address.AddressLine1.slice(
            0,
            mangopay.UboDeclaration.Ubos[1].Address.AddressLine1.indexOf('/'),
          ).trim(),
        AddressLine2: mangopay.UboDeclaration.Ubos[1].Address.AddressLine2,
        City: mangopay.UboDeclaration.Ubos[1].Address.City,
        Region: '',
        PostalCode: mangopay.UboDeclaration.Ubos[1].Address.PostalCode,
        Country: mangopay.UboDeclaration.Ubos[1].Address.Country,
        FlatNumber: mangopay.UboDeclaration.Ubos[1].Address.AddressLine2.slice(
          0,
          mangopay.UboDeclaration.Ubos[1].Address.AddressLine2.indexOf('/'),
        ).trim(),
        BuildNumber: mangopay.UboDeclaration.Ubos[1].Address.AddressLine2.slice(
          mangopay.UboDeclaration.Ubos[1].Address.AddressLine2.indexOf('/') + 1,
        ).trim(),
      },
    }));
    setAddUboTwo(true);
  }

  if (
    mangopay.UboDeclaration !== null &&
    mangopay.UboDeclaration.Ubos.length > 2 &&
    mangopayAccount.Status !== mangopay.Status
  ) {
    setUboPersonThree((prevState) => ({
      ...prevState,
      Id: mangopay.UboDeclaration.Ubos[2].Id,
      FirstName: mangopay.UboDeclaration.Ubos[2].FirstName,
      LastName: mangopay.UboDeclaration.Ubos[2].LastName,
      Nationality: mangopay.UboDeclaration.Ubos[2].Nationality,
      Birthday: mangopay.UboDeclaration.Ubos[2].Birthday.slice(0, 10),
      Birthplace: {
        City: mangopay.UboDeclaration.Ubos[2].Birthplace.City,
        Country: mangopay.UboDeclaration.Ubos[2].Birthplace.Country,
      },
      Address: {
        AddressLine1:
          mangopay.UboDeclaration.Ubos[2].Address.AddressLine1.slice(
            0,
            mangopay.UboDeclaration.Ubos[2].Address.AddressLine1.indexOf('/'),
          ).trim(),
        AddressLine2: mangopay.UboDeclaration.Ubos[2].Address.AddressLine2,
        City: mangopay.UboDeclaration.Ubos[2].Address.City,
        Region: '',
        PostalCode: mangopay.UboDeclaration.Ubos[2].Address.PostalCode,
        Country: mangopay.UboDeclaration.Ubos[2].Address.Country,
        FlatNumber: mangopay.UboDeclaration.Ubos[2].Address.AddressLine2.slice(
          0,
          mangopay.UboDeclaration.Ubos[2].Address.AddressLine2.indexOf('/'),
        ).trim(),
        BuildNumber: mangopay.UboDeclaration.Ubos[2].Address.AddressLine2.slice(
          mangopay.UboDeclaration.Ubos[2].Address.AddressLine2.indexOf('/') + 1,
        ).trim(),
      },
    }));
    setAddUboThree(true);
  }

  if (
    mangopay.UboDeclaration !== null &&
    mangopay.UboDeclaration.Ubos.length > 3 &&
    mangopayAccount.Status !== mangopay.Status
  ) {
    setUboPersonFour((prevState) => ({
      ...prevState,
      Id: mangopay.UboDeclaration.Ubos[3].Id,
      FirstName: mangopay.UboDeclaration.Ubos[3].FirstName,
      LastName: mangopay.UboDeclaration.Ubos[3].LastName,
      Nationality: mangopay.UboDeclaration.Ubos[3].Nationality,
      Birthday: mangopay.UboDeclaration.Ubos[3].Birthday.slice(0, 10),
      Birthplace: {
        City: mangopay.UboDeclaration.Ubos[3].Birthplace.City,
        Country: mangopay.UboDeclaration.Ubos[3].Birthplace.Country,
      },
      Address: {
        AddressLine1:
          mangopay.UboDeclaration.Ubos[3].Address.AddressLine1.slice(
            0,
            mangopay.UboDeclaration.Ubos[3].Address.AddressLine1.indexOf('/'),
          ).trim(),
        AddressLine2: mangopay.UboDeclaration.Ubos[3].Address.AddressLine2,
        City: mangopay.UboDeclaration.Ubos[3].Address.City,
        Region: '',
        PostalCode: mangopay.UboDeclaration.Ubos[3].Address.PostalCode,
        Country: mangopay.UboDeclaration.Ubos[3].Address.Country,
        FlatNumber: mangopay.UboDeclaration.Ubos[3].Address.AddressLine2.slice(
          0,
          mangopay.UboDeclaration.Ubos[3].Address.AddressLine2.indexOf('/'),
        ).trim(),
        BuildNumber: mangopay.UboDeclaration.Ubos[3].Address.AddressLine2.slice(
          mangopay.UboDeclaration.Ubos[3].Address.AddressLine2.indexOf('/') + 1,
        ).trim(),
      },
    }));
    setAddUboFour(true);
  }

  // UBO TWO
  const handleUboPersonTwoChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (e.target.id === 'FirstName' || e.target.id === 'LastName') {
      const value = e.target.value;

      const filteredValue = value.replace(/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g, '');
      setUboPersonTwo((prev) => ({...prev, [e.target.id]: filteredValue}));
    } else {
      setUboPersonTwo((prev) => ({...prev, [e.target.id]: e.target.value}));
    }

    setUboPersonTwoError((prevState) => ({
      ...prevState,
      [e.target.id]: false,
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonTwoAddressPostalCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setUboPersonTwo((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        PostalCode: value
          .replace(/\D/g, '')
          .slice(0, 6)
          .replace(/(\d{2})(\d{1,3})/, '$1-$2'),
      },
    }));
    setUboPersonTwoError((prevState) => ({
      ...prevState,
      Address: {...prevState.Address, PostalCode: false},
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonTwoAddressChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setUboPersonTwo((prev) => ({
      ...prev,
      Address: {
        ...prev.Address,
        [e.target.id]: e.target.value,
      },
    }));
    setUboPersonTwoError((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        [e.target.id]: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonTwoBirthplaceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setUboPersonTwo((prev) => ({
      ...prev,
      Birthplace: {
        ...prev.Birthplace,
        [e.target.id]: e.target.value,
      },
    }));
    setUboPersonTwoError((prevState) => ({
      ...prevState,
      Birthplace: {
        ...prevState.Birthplace,
        [e.target.id]: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonTwoAddressCountry = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUboPersonTwo((prevState) => ({
      ...prevState,
      Address: {...prevState.Address, Country: e.target.value},
    }));
    setUboPersonTwoError((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        Country: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonTwoBirthplaceCountry = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUboPersonTwo((prevState) => ({
      ...prevState,
      Birthplace: {...prevState.Birthplace, Country: e.target.value},
    }));
    setUboPersonTwoError((prevState) => ({
      ...prevState,
      Birthplace: {
        ...prevState.Birthplace,
        Country: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonTwoNationality = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUboPersonTwo((prevState) => ({
      ...prevState,
      Nationality: e.target.value,
    }));
    setUboPersonTwoError((prevState) => ({
      ...prevState,
      Nationality: false,
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handlUboPersonTwoRegulationsChange = () => {
    setAddUboTwo((prevState) => !prevState);
  };

  // UBO TWO END

  // UBO THREE
  const handleUboPersonThreeChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (e.target.id === 'FirstName' || e.target.id === 'LastName') {
      const value = e.target.value;

      const filteredValue = value.replace(/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g, '');
      setUboPersonThree((prev) => ({...prev, [e.target.id]: filteredValue}));
    } else {
      setUboPersonThree((prev) => ({...prev, [e.target.id]: e.target.value}));
    }

    setUboPersonThreeError((prevState) => ({
      ...prevState,
      [e.target.id]: false,
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonThreeAddressPostalCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setUboPersonThree((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        PostalCode: value
          .replace(/\D/g, '')
          .slice(0, 6)
          .replace(/(\d{2})(\d{1,3})/, '$1-$2'),
      },
    }));
    setUboPersonThreeError((prevState) => ({
      ...prevState,
      Address: {...prevState.Address, PostalCode: false},
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonThreeAddressChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setUboPersonThree((prev) => ({
      ...prev,
      Address: {
        ...prev.Address,
        [e.target.id]: e.target.value,
      },
    }));
    setUboPersonThreeError((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        [e.target.id]: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonThreeBirthplaceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setUboPersonThree((prev) => ({
      ...prev,
      Birthplace: {
        ...prev.Birthplace,
        [e.target.id]: e.target.value,
      },
    }));
    setUboPersonThreeError((prevState) => ({
      ...prevState,
      Birthplace: {
        ...prevState.Birthplace,
        [e.target.id]: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonThreeAddressCountry = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUboPersonThree((prevState) => ({
      ...prevState,
      Address: {...prevState.Address, Country: e.target.value},
    }));
    setUboPersonThreeError((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        Country: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonThreeBirthplaceCountry = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUboPersonThree((prevState) => ({
      ...prevState,
      Birthplace: {...prevState.Birthplace, Country: e.target.value},
    }));
    setUboPersonThreeError((prevState) => ({
      ...prevState,
      Birthplace: {
        ...prevState.Birthplace,
        Country: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonThreeNationality = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUboPersonThree((prevState) => ({
      ...prevState,
      Nationality: e.target.value,
    }));
    setUboPersonThreeError((prevState) => ({
      ...prevState,
      Nationality: false,
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handlUboPersonThreeRegulationsChange = () => {
    setAddUboThree((prevState) => !prevState);
  };

  // UBO THREE END

  // UBO Four
  const handleUboPersonFourChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (e.target.id === 'FirstName' || e.target.id === 'LastName') {
      const value = e.target.value;

      const filteredValue = value.replace(/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g, '');
      setUboPersonFour((prev) => ({...prev, [e.target.id]: filteredValue}));
    } else {
      setUboPersonFour((prev) => ({...prev, [e.target.id]: e.target.value}));
    }

    setUboPersonFourError((prevState) => ({
      ...prevState,
      [e.target.id]: false,
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonFourAddressPostalCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setUboPersonFour((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        PostalCode: value
          .replace(/\D/g, '')
          .slice(0, 6)
          .replace(/(\d{2})(\d{1,3})/, '$1-$2'),
      },
    }));
    setUboPersonFourError((prevState) => ({
      ...prevState,
      Address: {...prevState.Address, PostalCode: false},
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonFourAddressChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setUboPersonFour((prev) => ({
      ...prev,
      Address: {
        ...prev.Address,
        [e.target.id]: e.target.value,
      },
    }));
    setUboPersonFourError((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        [e.target.id]: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonFourBirthplaceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setUboPersonFour((prev) => ({
      ...prev,
      Birthplace: {
        ...prev.Birthplace,
        [e.target.id]: e.target.value,
      },
    }));
    setUboPersonFourError((prevState) => ({
      ...prevState,
      Birthplace: {
        ...prevState.Birthplace,
        [e.target.id]: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonFourAddressCountry = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUboPersonFour((prevState) => ({
      ...prevState,
      Address: {...prevState.Address, Country: e.target.value},
    }));
    setUboPersonFourError((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        Country: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonFourBirthplaceCountry = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUboPersonFour((prevState) => ({
      ...prevState,
      Birthplace: {...prevState.Birthplace, Country: e.target.value},
    }));
    setUboPersonFourError((prevState) => ({
      ...prevState,
      Birthplace: {
        ...prevState.Birthplace,
        Country: false,
      },
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handleUboPersonFourNationality = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setUboPersonFour((prevState) => ({
      ...prevState,
      Nationality: e.target.value,
    }));
    setUboPersonFourError((prevState) => ({
      ...prevState,
      Nationality: false,
    }));
    setSomethingIsChnagedInUbo(true);
  };

  const handlUboPersonFourRegulationsChange = () => {
    setAddUboFour((prevState) => !prevState);
  };
  // UBO Four END

  const checkAge = (birthday) => {
    const today = new Date();
    const birthdateArray = birthday.split('-');
    const birthdateObject = new Date(
      birthdateArray[0],
      birthdateArray[1] - 1,
      birthdateArray[2],
    );
    const ageDiffMilliseconds = today.getTime() - birthdateObject.getTime();
    const ageDate = new Date(ageDiffMilliseconds);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age >= 18;
  };

  const validateUboData = (data: UBO) => {
    const {FirstName, LastName, Birthday, Nationality, Birthplace, Address} =
      data;

    const validatedData = {
      FirstName: false,
      LastName: false,
      Nationality: false,
      Birthday: false,
      Birthplace: {City: false, Country: false},
      Address: {
        AddressLine1: false,
        AddressLine2: false,
        City: false,
        Region: false,
        PostalCode: false,
        Country: false,
        FlatNumber: false,
        BuildNumber: false,
      },
    };

    if (FirstName.length === 0) {
      setMangopayUboError((prevState) => ({...prevState, FirstName: true}));
      validatedData.FirstName = true;
    }

    if (LastName.length === 0) {
      setMangopayUboError((prevState) => ({...prevState, LastName: true}));
      validatedData.LastName = true;
    }

    if (Nationality.length === 0) {
      setMangopayUboError((prevState) => ({...prevState, Nationality: true}));
      validatedData.Nationality = true;
    }

    if (Birthday.length === 0) {
      setMangopayUboError((prevState) => ({...prevState, Birthday: true}));
      validatedData.Birthday = true;
    }

    if (!checkAge(Birthday)) {
      setMangopayUboError((prevState) => ({...prevState, Birthday: true}));
      validatedData.Birthday = true;
    }

    if (Birthplace.City.length === 0) {
      setMangopayUboError((prevState) => ({
        ...prevState,
        Birthplace: {...prevState.Birthplace, City: true},
      }));
      validatedData.Birthplace.City = true;
    }

    if (Birthplace.Country.length === 0) {
      setMangopayUboError((prevState) => ({
        ...prevState,
        Birthplace: {...prevState.Birthplace, Country: true},
      }));
      validatedData.Birthplace.Country = true;
    }

    if (Address.AddressLine1.length === 0) {
      setMangopayUboError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, AddressLine1: true},
      }));
      validatedData.Address.AddressLine1 = true;
    }

    if (Address.City.length === 0) {
      setMangopayUboError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, City: true},
      }));
      validatedData.Address.City = true;
    }

    if (Address.PostalCode.length === 0) {
      setMangopayUboError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, PostalCode: true},
      }));
      validatedData.Address.PostalCode = true;
    }

    if (Address.Country.length === 0) {
      setMangopayUboError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, Country: true},
      }));
      validatedData.Address.Country = true;
    }

    if (Address.FlatNumber.length === 0) {
      setMangopayUboError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, FlatNumber: true},
      }));
      validatedData.Address.FlatNumber = true;
    }

    if (
      !validatedData.FirstName &&
      !validatedData.LastName &&
      !validatedData.Nationality &&
      !validatedData.Birthday &&
      !validatedData.Birthplace.City &&
      !validatedData.Birthplace.Country &&
      !validatedData.Address.AddressLine1 &&
      !validatedData.Address.City &&
      !validatedData.Address.PostalCode &&
      !validatedData.Address.Country &&
      !validatedData.Address.FlatNumber
    ) {
      setMangopayUboError({
        FirstName: false,
        LastName: false,
        Nationality: false,
        Birthday: false,
        ShowErrors: false,
        Birthplace: {City: false, Country: false},
        Address: {
          AddressLine1: false,
          AddressLine2: false,
          City: false,
          Region: false,
          PostalCode: false,
          Country: false,
          FlatNumber: false,
          BuildNumber: false,
        },
      });
      return true;
    } else {
      setMangopayUboError((prevState) => ({...prevState, ShowErrors: true}));
      return false;
    }
  };

  const validateUboPersonTwoData = (data: UBO) => {
    const {FirstName, LastName, Birthday, Nationality, Birthplace, Address} =
      data;

    const validatedData = {
      FirstName: false,
      LastName: false,
      Nationality: false,
      Birthday: false,
      Birthplace: {City: false, Country: false},
      Address: {
        AddressLine1: false,
        AddressLine2: false,
        City: false,
        Region: false,
        PostalCode: false,
        Country: false,
        FlatNumber: false,
        BuildNumber: false,
      },
    };

    if (FirstName.length === 0) {
      setUboPersonTwoError((prevState) => ({...prevState, FirstName: true}));
      validatedData.FirstName = true;
    }

    if (LastName.length === 0) {
      setUboPersonTwoError((prevState) => ({...prevState, LastName: true}));
      validatedData.LastName = true;
    }

    if (Nationality.length === 0) {
      setUboPersonTwoError((prevState) => ({...prevState, Nationality: true}));
      validatedData.Nationality = true;
    }

    if (Birthday.length === 0) {
      setUboPersonTwoError((prevState) => ({...prevState, Birthday: true}));
      validatedData.Birthday = true;
    }

    if (!checkAge(Birthday)) {
      setUboPersonTwoError((prevState) => ({...prevState, Birthday: true}));
      validatedData.Birthday = true;
    }

    if (Birthplace.City.length === 0) {
      setUboPersonTwoError((prevState) => ({
        ...prevState,
        Birthplace: {...prevState.Birthplace, City: true},
      }));
      validatedData.Birthplace.City = true;
    }

    if (Birthplace.Country.length === 0) {
      setUboPersonTwoError((prevState) => ({
        ...prevState,
        Birthplace: {...prevState.Birthplace, Country: true},
      }));
      validatedData.Birthplace.Country = true;
    }

    if (Address.AddressLine1.length === 0) {
      setUboPersonTwoError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, AddressLine1: true},
      }));
      validatedData.Address.AddressLine1 = true;
    }

    if (Address.City.length === 0) {
      setUboPersonTwoError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, City: true},
      }));
      validatedData.Address.City = true;
    }

    if (Address.PostalCode.length === 0) {
      setUboPersonTwoError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, PostalCode: true},
      }));
      validatedData.Address.PostalCode = true;
    }

    if (Address.Country.length === 0) {
      setUboPersonTwoError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, Country: true},
      }));
      validatedData.Address.Country = true;
    }

    if (Address.FlatNumber.length === 0) {
      setUboPersonTwoError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, FlatNumber: true},
      }));
      validatedData.Address.FlatNumber = true;
    }

    if (
      !validatedData.FirstName &&
      !validatedData.LastName &&
      !validatedData.Nationality &&
      !validatedData.Birthday &&
      !validatedData.Birthplace.City &&
      !validatedData.Birthplace.Country &&
      !validatedData.Address.AddressLine1 &&
      !validatedData.Address.City &&
      !validatedData.Address.PostalCode &&
      !validatedData.Address.Country &&
      !validatedData.Address.FlatNumber
    ) {
      setUboPersonTwoError({
        FirstName: false,
        LastName: false,
        Nationality: false,
        Birthday: false,
        ShowErrors: false,
        Birthplace: {City: false, Country: false},
        Address: {
          AddressLine1: false,
          AddressLine2: false,
          City: false,
          Region: false,
          PostalCode: false,
          Country: false,
          FlatNumber: false,
          BuildNumber: false,
        },
      });
      return true;
    } else {
      setUboPersonTwoError((prevState) => ({...prevState, ShowErrors: true}));
      return false;
    }
  };

  const validateUboPersonThreeData = (data: UBO) => {
    const {FirstName, LastName, Birthday, Nationality, Birthplace, Address} =
      data;

    const validatedData = {
      FirstName: false,
      LastName: false,
      Nationality: false,
      Birthday: false,
      Birthplace: {City: false, Country: false},
      Address: {
        AddressLine1: false,
        AddressLine2: false,
        City: false,
        Region: false,
        PostalCode: false,
        Country: false,
        FlatNumber: false,
        BuildNumber: false,
      },
    };

    if (FirstName.length === 0) {
      setUboPersonThreeError((prevState) => ({...prevState, FirstName: true}));
      validatedData.FirstName = true;
    }

    if (LastName.length === 0) {
      setUboPersonThreeError((prevState) => ({...prevState, LastName: true}));
      validatedData.LastName = true;
    }

    if (Nationality.length === 0) {
      setUboPersonThreeError((prevState) => ({
        ...prevState,
        Nationality: true,
      }));
      validatedData.Nationality = true;
    }

    if (Birthday.length === 0) {
      setUboPersonThreeError((prevState) => ({...prevState, Birthday: true}));
      validatedData.Birthday = true;
    }

    if (!checkAge(Birthday)) {
      setUboPersonThreeError((prevState) => ({...prevState, Birthday: true}));
      validatedData.Birthday = true;
    }

    if (Birthplace.City.length === 0) {
      setUboPersonThreeError((prevState) => ({
        ...prevState,
        Birthplace: {...prevState.Birthplace, City: true},
      }));
      validatedData.Birthplace.City = true;
    }

    if (Birthplace.Country.length === 0) {
      setUboPersonThreeError((prevState) => ({
        ...prevState,
        Birthplace: {...prevState.Birthplace, Country: true},
      }));
      validatedData.Birthplace.Country = true;
    }

    if (Address.AddressLine1.length === 0) {
      setUboPersonThreeError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, AddressLine1: true},
      }));
      validatedData.Address.AddressLine1 = true;
    }

    if (Address.City.length === 0) {
      setUboPersonThreeError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, City: true},
      }));
      validatedData.Address.City = true;
    }

    if (Address.PostalCode.length === 0) {
      setUboPersonThreeError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, PostalCode: true},
      }));
      validatedData.Address.PostalCode = true;
    }

    if (Address.Country.length === 0) {
      setUboPersonThreeError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, Country: true},
      }));
      validatedData.Address.Country = true;
    }

    if (Address.FlatNumber.length === 0) {
      setUboPersonThreeError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, FlatNumber: true},
      }));
      validatedData.Address.FlatNumber = true;
    }

    if (
      !validatedData.FirstName &&
      !validatedData.LastName &&
      !validatedData.Nationality &&
      !validatedData.Birthday &&
      !validatedData.Birthplace.City &&
      !validatedData.Birthplace.Country &&
      !validatedData.Address.AddressLine1 &&
      !validatedData.Address.City &&
      !validatedData.Address.PostalCode &&
      !validatedData.Address.Country &&
      !validatedData.Address.FlatNumber
    ) {
      setUboPersonThreeError({
        FirstName: false,
        LastName: false,
        Nationality: false,
        Birthday: false,
        ShowErrors: false,
        Birthplace: {City: false, Country: false},
        Address: {
          AddressLine1: false,
          AddressLine2: false,
          City: false,
          Region: false,
          PostalCode: false,
          Country: false,
          FlatNumber: false,
          BuildNumber: false,
        },
      });
      return true;
    } else {
      setUboPersonThreeError((prevState) => ({...prevState, ShowErrors: true}));
      return false;
    }
  };

  const validateUboPersonFourData = (data: UBO) => {
    const {FirstName, LastName, Birthday, Nationality, Birthplace, Address} =
      data;

    const validatedData = {
      FirstName: false,
      LastName: false,
      Nationality: false,
      Birthday: false,
      Birthplace: {City: false, Country: false},
      Address: {
        AddressLine1: false,
        AddressLine2: false,
        City: false,
        Region: false,
        PostalCode: false,
        Country: false,
        FlatNumber: false,
        BuildNumber: false,
      },
    };

    if (FirstName.length === 0) {
      setUboPersonFourError((prevState) => ({...prevState, FirstName: true}));
      validatedData.FirstName = true;
    }

    if (LastName.length === 0) {
      setUboPersonFourError((prevState) => ({...prevState, LastName: true}));
      validatedData.LastName = true;
    }

    if (Nationality.length === 0) {
      setUboPersonFourError((prevState) => ({...prevState, Nationality: true}));
      validatedData.Nationality = true;
    }

    if (Birthday.length === 0) {
      setUboPersonFourError((prevState) => ({...prevState, Birthday: true}));
      validatedData.Birthday = true;
    }

    if (!checkAge(Birthday)) {
      setUboPersonFourError((prevState) => ({...prevState, Birthday: true}));
      validatedData.Birthday = true;
    }

    if (Birthplace.City.length === 0) {
      setUboPersonFourError((prevState) => ({
        ...prevState,
        Birthplace: {...prevState.Birthplace, City: true},
      }));
      validatedData.Birthplace.City = true;
    }

    if (Birthplace.Country.length === 0) {
      setUboPersonFourError((prevState) => ({
        ...prevState,
        Birthplace: {...prevState.Birthplace, Country: true},
      }));
      validatedData.Birthplace.Country = true;
    }

    if (Address.AddressLine1.length === 0) {
      setUboPersonFourError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, AddressLine1: true},
      }));
      validatedData.Address.AddressLine1 = true;
    }

    if (Address.City.length === 0) {
      setUboPersonFourError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, City: true},
      }));
      validatedData.Address.City = true;
    }

    if (Address.PostalCode.length === 0) {
      setUboPersonFourError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, PostalCode: true},
      }));
      validatedData.Address.PostalCode = true;
    }

    if (Address.Country.length === 0) {
      setUboPersonFourError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, Country: true},
      }));
      validatedData.Address.Country = true;
    }

    if (Address.FlatNumber.length === 0) {
      setUboPersonFourError((prevState) => ({
        ...prevState,
        Address: {...prevState.Address, FlatNumber: true},
      }));
      validatedData.Address.FlatNumber = true;
    }

    if (
      !validatedData.FirstName &&
      !validatedData.LastName &&
      !validatedData.Nationality &&
      !validatedData.Birthday &&
      !validatedData.Birthplace.City &&
      !validatedData.Birthplace.Country &&
      !validatedData.Address.AddressLine1 &&
      !validatedData.Address.City &&
      !validatedData.Address.PostalCode &&
      !validatedData.Address.Country &&
      !validatedData.Address.FlatNumber
    ) {
      setUboPersonFourError({
        FirstName: false,
        LastName: false,
        Nationality: false,
        Birthday: false,
        ShowErrors: false,
        Birthplace: {City: false, Country: false},
        Address: {
          AddressLine1: false,
          AddressLine2: false,
          City: false,
          Region: false,
          PostalCode: false,
          Country: false,
          FlatNumber: false,
          BuildNumber: false,
        },
      });
      return true;
    } else {
      setUboPersonFourError((prevState) => ({...prevState, ShowErrors: true}));
      return false;
    }
  };

  const validateImgMangopay = () => {
    // console.log('nowa funkcja do zdjec');
    const validatedData = {
      IdScans: false,
      RegistrationScans: false,
      ArticlesOfAssociationScans: false,
    };

    if (
      mangopayAccount.DocIdentityProof.Status !== 'VALIDATED' &&
      mangopayAccount.DocIdentityProof.Status !== 'VALIDATION_ASKED'
    ) {
      if (idScans.length < 2) {
        setIdScansFrontError((prevState) => ({
          ...prevState,
          showErrors: true,
        }));
        validatedData.IdScans = true;
      }

      if (
        mangopayAccount.DocRegistrationProof.Status !== 'VALIDATED' &&
        mangopayAccount.DocRegistrationProof.Status !== 'VALIDATION_ASKED'
      ) {
        if (registrationScans.length < 1) {
          setRegistrationScansFrontError((prevState) => ({
            ...prevState,
            showErrors: true,
          }));
          validatedData.RegistrationScans = true;
        }
      }

      if (
        mangopayAccount.LegalPersonType === 'ORGANIZATION' ||
        mangopayAccount.LegalPersonType === 'BUSINESS'
      ) {
        if (
          mangopayAccount.DocArticlesOfAssociation.Status !== 'VALIDATED' &&
          mangopayAccount.DocArticlesOfAssociation.Status !== 'VALIDATION_ASKED'
        ) {
          if (articlesOfAssociationScans.length < 1) {
            setArticlesOfAssociationScansFrontError((prevState) => ({
              ...prevState,
              showErrors: true,
            }));
            validatedData.ArticlesOfAssociationScans = true;
          }
        }
      }
    }

    // console.log('validate img', validatedData);

    if (
      !validatedData.IdScans &&
      !validatedData.RegistrationScans &&
      !validatedData.ArticlesOfAssociationScans
    ) {
      return false;
    } else return true;
  };

  const validateData = (data: MangopayCompanyAccount) => {
    const onlyLettersWithPolishCharactersRegex =
      /^[A-Za-zA-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const {
      LegalPersonType,
      Name,
      LegalRepresentativeBirthday,
      LegalRepresentativeCountryOfResidence,
      LegalRepresentativeNationality,
      LegalRepresentativeFirstName,
      LegalRepresentativeLastName,
      Email,
      CompanyNumber,
      TermsAndConditionsAccepted,
      HeadquartersAddress,
      LegalRepresentativeAddress,
      IBAN,
    } = data;

    const validatedData = {
      LegalPersonType: false,
      Name: false,
      LegalRepresentativeBirthday: false,
      LegalRepresentativeCountryOfResidence: false,
      LegalRepresentativeNationality: false,
      LegalRepresentativeFirstName: false,
      LegalRepresentativeLastName: false,
      Email: false,
      CompanyNumber: false,
      TermsAndConditionsAccepted: false,
      HeadquartersAddress: {
        AddressLine1: false,
        AddressLine2: false,
        City: false,
        Region: false,
        PostalCode: false,
        Country: false,
      },
      LegalRepresentativeAddress: {
        AddressLine1: false,
        AddressLine2: false,
        City: false,
        Region: false,
        PostalCode: false,
        Country: false,
      },
      ShowErrors: false,
      IBAN: false,
      UBO: false,
      UBOPersonTwo: false,
      UBOPersonThree: false,
      UBOPersonFour: false,
      IMGS: false,
    };

    if (validateImgMangopay()) {
      validatedData.IMGS = true;
    }

    if (LegalPersonType.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalPersonType: true,
      }));
      validatedData.LegalPersonType = true;
    }

    if (Name.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        Name: true,
      }));
      validatedData.Name = true;
    }

    if (LegalPersonType === 'BUSINESS') {
      if (CompanyNumber.length === 0) {
        setMangopayErrors((prev) => ({
          ...prev,
          CompanyNumber: true,
        }));
        validatedData.CompanyNumber = true;
      }
    }

    if (IBAN.length === 0) {
      setMangopayErrors((prev) => ({...prev, IBAN: true}));
      validatedData.IBAN = true;
    }

    if (LegalRepresentativeNationality.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeNationality: true,
      }));
      validatedData.LegalRepresentativeNationality = true;
    }

    if (LegalRepresentativeCountryOfResidence.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeCountryOfResidence: true,
      }));
      validatedData.LegalRepresentativeCountryOfResidence = true;
    }

    if (LegalRepresentativeFirstName.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeFirstName: true,
      }));
      validatedData.LegalRepresentativeFirstName = true;
    }
    if (
      !onlyLettersWithPolishCharactersRegex.test(LegalRepresentativeFirstName)
    ) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeFirstName: true,
      }));
      validatedData.LegalRepresentativeFirstName = true;
    }
    if (LegalRepresentativeFirstName.length > 63) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeFirstName: true,
      }));
      validatedData.LegalRepresentativeFirstName = true;
    }
    if (LegalRepresentativeLastName.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeLastName: true,
      }));
      validatedData.LegalRepresentativeLastName = true;
    }
    if (
      !onlyLettersWithPolishCharactersRegex.test(LegalRepresentativeLastName)
    ) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeLastName: true,
      }));
      validatedData.LegalRepresentativeLastName = true;
    }
    if (LegalRepresentativeLastName.length > 63) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeLastName: true,
      }));
      validatedData.LegalRepresentativeLastName = true;
    }
    if (Email.length === 0) {
      setMangopayErrors((prev) => ({...prev, Email: true}));
      validatedData.Email = true;
    }
    if (!emailRegex.test(Email)) {
      setMangopayErrors((prev) => ({...prev, Email: true}));
      validatedData.Email = true;
    }

    if (LegalRepresentativeBirthday.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeBirthday: true,
      }));
      validatedData.LegalRepresentativeBirthday = true;
    }
    if (!checkAge(LegalRepresentativeBirthday)) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeBirthday: true,
      }));
      validatedData.LegalRepresentativeBirthday = true;
    }

    if (HeadquartersAddress.AddressLine1.length === 0) {
      // console.log('error', mangopayAccount.HeadquartersAddress.AddressLine1);
      setMangopayErrors((prev) => ({
        ...prev,
        HeadquartersAddress: {...prev.HeadquartersAddress, AddressLine1: true},
      }));
      validatedData.HeadquartersAddress.AddressLine1 = true;
    }
    if (HeadquartersAddress.City.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        HeadquartersAddress: {...prev.HeadquartersAddress, City: true},
      }));
      validatedData.HeadquartersAddress.City = true;
    }
    if (HeadquartersAddress.Country.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        HeadquartersAddress: {...prev.HeadquartersAddress, Country: true},
      }));
      validatedData.HeadquartersAddress.Country = true;
    }
    if (HeadquartersAddress.PostalCode.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        HeadquartersAddress: {...prev.HeadquartersAddress, PostalCode: true},
      }));
      validatedData.HeadquartersAddress.PostalCode = true;
    }

    if (LegalRepresentativeAddress.AddressLine1.length === 0) {
      // console.log(
      //   'error',
      //   mangopayAccount.LegalRepresentativeAddress.AddressLine1,
      // );
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeAddress: {
          ...prev.LegalRepresentativeAddress,
          AddressLine1: true,
        },
      }));
      validatedData.LegalRepresentativeAddress.AddressLine1 = true;
    }
    if (LegalRepresentativeAddress.City.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeAddress: {
          ...prev.LegalRepresentativeAddress,
          City: true,
        },
      }));
      validatedData.LegalRepresentativeAddress.City = true;
    }
    if (LegalRepresentativeAddress.Country.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeAddress: {
          ...prev.LegalRepresentativeAddress,
          Country: true,
        },
      }));
      validatedData.LegalRepresentativeAddress.Country = true;
    }
    if (LegalRepresentativeAddress.PostalCode.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        LegalRepresentativeAddress: {
          ...prev.LegalRepresentativeAddress,
          PostalCode: true,
        },
      }));
      validatedData.LegalRepresentativeAddress.PostalCode = true;
    }

    if (mangopayAccount.LegalPersonType === 'BUSINESS') {
      validatedData.UBO = !validateUboData(mangopayUbo);
    }
    if (addUboTwo) {
      validatedData.UBOPersonTwo = !validateUboPersonTwoData(uboPersonTwo);
    }
    if (addUboThree) {
      validatedData.UBOPersonThree =
        !validateUboPersonThreeData(uboPersonThree);
    }
    if (addUboFour) {
      validatedData.UBOPersonFour = !validateUboPersonFourData(uboPersonFour);
    }

    if (!TermsAndConditionsAccepted) {
      setMangopayErrors((prev) => ({
        ...prev,
        TermsAndConditionsAccepted: true,
      }));
      validatedData.TermsAndConditionsAccepted = true;
    }
    // console.log(validatedData, 'walidacja true');
    if (
      !validatedData.LegalPersonType &&
      !validatedData.Name &&
      !validatedData.LegalRepresentativeBirthday &&
      !validatedData.LegalRepresentativeCountryOfResidence &&
      !validatedData.LegalRepresentativeNationality &&
      !validatedData.LegalRepresentativeFirstName &&
      !validatedData.LegalRepresentativeLastName &&
      !validatedData.Email &&
      !validatedData.TermsAndConditionsAccepted &&
      !validatedData.HeadquartersAddress.AddressLine1 &&
      !validatedData.HeadquartersAddress.City &&
      !validatedData.HeadquartersAddress.PostalCode &&
      !validatedData.HeadquartersAddress.Country &&
      !validatedData.LegalRepresentativeAddress.AddressLine1 &&
      !validatedData.LegalRepresentativeAddress.City &&
      !validatedData.LegalRepresentativeAddress.PostalCode &&
      !validatedData.LegalRepresentativeAddress.Country &&
      !validatedData.UBO &&
      !validatedData.UBOPersonTwo &&
      !validatedData.UBOPersonThree &&
      !validatedData.UBOPersonFour &&
      !validatedData.IMGS
    ) {
      setMangopayErrors({
        LegalPersonType: false,
        Name: false,
        LegalRepresentativeBirthday: false,
        LegalRepresentativeCountryOfResidence: false,
        LegalRepresentativeNationality: false,
        LegalRepresentativeFirstName: false,
        LegalRepresentativeLastName: false,
        Email: false,
        CompanyNumber: false,
        TermsAndConditionsAccepted: false,
        HeadquartersAddress: {
          AddressLine1: false,
          AddressLine2: false,
          City: false,
          Region: false,
          PostalCode: false,
          Country: false,
        },
        LegalRepresentativeAddress: {
          AddressLine1: false,
          AddressLine2: false,
          City: false,
          Region: false,
          PostalCode: false,
          Country: false,
        },
        ShowErrors: false,
        IBAN: false,
      });
      return true;
    } else {
      setMangopayErrors((prev) => ({...prev, ShowErrors: true}));
      return false;
    }
  };

  const createUbo = async () => {
    if (!somethingIsChnagedInUbo) return;
    if (mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED') return;
    const uboOne = {
      Id: mangopayUbo.Id === '' ? null : mangopayUbo.Id,
      FirstName: mangopayUbo.FirstName,
      LastName: mangopayUbo.LastName,
      Nationality: mangopayUbo.Nationality,
      Birthday: mangopayUbo.Birthday,
      Birthplace: {
        City: mangopayUbo.Birthplace.City,
        Country: mangopayUbo.Birthplace.Country,
      },
      Address: {
        AddressLine1: `${mangopayUbo.Address.AddressLine1} / ${mangopayUbo.Address.FlatNumber} ${mangopayUbo.Address.BuildNumber}`,
        AddressLine2: `${mangopayUbo.Address.FlatNumber} / ${mangopayUbo.Address.BuildNumber}`,
        City: mangopayUbo.Address.City,
        PostalCode: mangopayUbo.Address.PostalCode,
        Country: mangopayUbo.Address.Country,
      },
    };
    const uboTwo = {
      Id: uboPersonTwo.Id === '' ? null : uboPersonTwo.Id,
      FirstName: uboPersonTwo.FirstName,
      LastName: uboPersonTwo.LastName,
      Nationality: uboPersonTwo.Nationality,
      Birthday: uboPersonTwo.Birthday,
      Birthplace: {
        City: uboPersonTwo.Birthplace.City,
        Country: uboPersonTwo.Birthplace.Country,
      },
      Address: {
        AddressLine1: `${uboPersonTwo.Address.AddressLine1} / ${uboPersonTwo.Address.FlatNumber} ${uboPersonTwo.Address.BuildNumber}`,
        AddressLine2: `${uboPersonTwo.Address.FlatNumber} / ${uboPersonTwo.Address.BuildNumber}`,
        City: uboPersonTwo.Address.City,
        PostalCode: uboPersonTwo.Address.PostalCode,
        Country: uboPersonTwo.Address.Country,
      },
    };
    const uboThree = {
      Id: uboPersonThree.Id === '' ? null : uboPersonThree.Id,
      FirstName: uboPersonThree.FirstName,
      LastName: uboPersonThree.LastName,
      Nationality: uboPersonThree.Nationality,
      Birthday: uboPersonThree.Birthday,
      Birthplace: {
        City: uboPersonThree.Birthplace.City,
        Country: uboPersonThree.Birthplace.Country,
      },
      Address: {
        AddressLine1: `${uboPersonThree.Address.AddressLine1} / ${uboPersonThree.Address.FlatNumber} ${uboPersonThree.Address.BuildNumber}`,
        AddressLine2: `${uboPersonThree.Address.FlatNumber} / ${uboPersonThree.Address.BuildNumber}`,
        City: uboPersonThree.Address.City,
        PostalCode: uboPersonThree.Address.PostalCode,
        Country: uboPersonThree.Address.Country,
      },
    };
    const uboFour = {
      Id: uboPersonFour.Id === '' ? null : uboPersonFour.Id,
      FirstName: uboPersonFour.FirstName,
      LastName: uboPersonFour.LastName,
      Nationality: uboPersonFour.Nationality,
      Birthday: uboPersonFour.Birthday,
      Birthplace: {
        City: uboPersonFour.Birthplace.City,
        Country: uboPersonFour.Birthplace.Country,
      },
      Address: {
        AddressLine1: `${uboPersonFour.Address.AddressLine1} / ${uboPersonFour.Address.FlatNumber} ${uboPersonFour.Address.BuildNumber}`,
        AddressLine2: `${uboPersonFour.Address.FlatNumber} / ${uboPersonFour.Address.BuildNumber}`,
        City: uboPersonFour.Address.City,
        PostalCode: uboPersonFour.Address.PostalCode,
        Country: uboPersonFour.Address.Country,
      },
    };
    const ubosToSend =
      addUboFour && addUboThree && addUboTwo
        ? [uboOne, uboTwo, uboThree, uboFour]
        : addUboThree && addUboTwo
        ? [uboOne, uboTwo, uboThree]
        : addUboTwo
        ? [uboOne, uboTwo]
        : [uboOne];
    try {
      const body = ubosToSend;
      const response = await api.post(
        `mangopayaccount/RegisterUBO`,
        {Ubos: body},
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleKrsNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setmangopayAccount((prevState) => ({
      ...prevState,
      CompanyNumber: e.target.value.replace(/\D/g, ''),
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      CompanyNumber: false,
    }));
  };

  const handleHeadquartersAddressPostalCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setmangopayAccount((prevState) => ({
      ...prevState,
      HeadquartersAddress: {
        ...prevState.HeadquartersAddress,
        PostalCode: value
          .replace(/\D/g, '')
          .slice(0, 6)
          .replace(/(\d{2})(\d{1,3})/, '$1-$2'),
      },
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      HeadquartersAddress: {
        ...prevState.HeadquartersAddress,
        [e.target.id]: false,
      },
    }));
    setIsSomethingChange(true);
  };

  const handleUboAddressPostalCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setMangopayUbo((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        PostalCode: value
          .replace(/\D/g, '')
          .slice(0, 6)
          .replace(/(\d{2})(\d{1,3})/, '$1-$2'),
      },
    }));
    setMangopayUboError((prevState) => ({
      ...prevState,
      Address: {...prevState.Address, PostalCode: false},
    }));
    setIsSomethingChange(true);
    setSomethingIsChnagedInUbo(true);
  };

  const handleLegalRepresentativeAddressPostalCodeChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setmangopayAccount((prevState) => ({
      ...prevState,
      LegalRepresentativeAddress: {
        ...prevState.LegalRepresentativeAddress,
        PostalCode: value
          .replace(/\D/g, '')
          .slice(0, 6)
          .replace(/(\d{2})(\d{1,3})/, '$1-$2'),
      },
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      LegalRepresentativeAddress: {
        ...prevState.LegalRepresentativeAddress,
        [e.target.id]: false,
      },
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayAccountChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (
      e.target.id === 'LegalRepresentativeLastName' ||
      e.target.id === 'LegalRepresentativeFirstName'
    ) {
      const value = e.target.value;

      const filteredValue = value.replace(/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g, '');
      setmangopayAccount((prev) => ({...prev, [e.target.id]: filteredValue}));
    } else {
      setmangopayAccount((prev) => ({...prev, [e.target.id]: e.target.value}));
    }

    setMangopayErrors((prevState) => ({
      ...prevState,
      [e.target.id]: false,
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayUboChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (e.target.id === 'FirstName' || e.target.id === 'LastName') {
      const value = e.target.value;

      const filteredValue = value.replace(/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g, '');
      setMangopayUbo((prev) => ({...prev, [e.target.id]: filteredValue}));
    } else {
      setMangopayUbo((prev) => ({...prev, [e.target.id]: e.target.value}));
    }

    setMangopayUboError((prevState) => ({
      ...prevState,
      [e.target.id]: false,
    }));
    setIsSomethingChange(true);
    setSomethingIsChnagedInUbo(true);
  };

  const sendIdScansToMangopay = async (idImgs: string[], type) => {
    if (idImgs.length < 1) return;
    handleLoading(true);
    try {
      const body = {
        Type: type,
        Pages: idImgs,
      };

      const response = await api.post(
        `mangopayaccount/RegisterKYCDocument`,
        body,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
    } catch (error) {
      console.error(error);
    } finally {
      handleLoading(false);
    }
  };

  const formatIBAN = (input) => {
    const formattedInput = input.replace(/[^0-9]/g, '');

    let formattedIBAN = '';
    for (let i = 0; i < formattedInput.length; i++) {
      if (i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22) {
        formattedIBAN += ' ';
      }
      formattedIBAN += formattedInput[i];
    }

    return formattedIBAN;
  };

  const handleMangopayIBAN = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // setmangopayAccount((prevState) => ({
    //   ...prevState,
    //   // IBAN: value.replace(/\D/g, '').slice(0, 26),
    //   IBAN: value
    //     .replace(/[^0-9]/g, '')
    //     .replace(/\s/g, '')
    //     .replace(/(\d{4})/g, '$1 ')
    //     .trim(),
    // }));
    setmangopayAccount((prevState) => ({
      ...prevState,
      IBAN: formatIBAN(value),
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      IBAN: false,
    }));
  };

  const sentMangopayIBAN = async () => {
    // console.log(mangopayAccount.IBAN);
    try {
      const body = {IBAN: `PL${mangopayAccount.IBAN.replace(/\s+/g, '')}`};

      const response = await api.post(
        `mangopayaccount/RegisterIBANBankAccount`,
        body,
        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleMangopayHeadquartersAddressChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setmangopayAccount((prev) => ({
      ...prev,
      HeadquartersAddress: {
        ...prev.HeadquartersAddress,
        [e.target.id]: e.target.value,
      },
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      HeadquartersAddress: {
        ...prevState.HeadquartersAddress,
        [e.target.id]: false,
      },
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayUboAddressChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setMangopayUbo((prev) => ({
      ...prev,
      Address: {
        ...prev.Address,
        [e.target.id]: e.target.value,
      },
    }));
    setMangopayUboError((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        [e.target.id]: false,
      },
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayUboBirthplaceChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setMangopayUbo((prev) => ({
      ...prev,
      Birthplace: {
        ...prev.Birthplace,
        [e.target.id]: e.target.value,
      },
    }));
    setMangopayUboError((prevState) => ({
      ...prevState,
      Birthplace: {
        ...prevState.Birthplace,
        [e.target.id]: false,
      },
    }));
    setIsSomethingChange(true);
    setSomethingIsChnagedInUbo(true);
  };

  const handleMangopayLegalRepresentativeAddressChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setmangopayAccount((prev) => ({
      ...prev,
      LegalRepresentativeAddress: {
        ...prev.LegalRepresentativeAddress,
        [e.target.id]: e.target.value,
      },
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      LegalRepresentativeAddress: {
        ...prevState.LegalRepresentativeAddress,
        [e.target.id]: false,
      },
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayHeadquartersAddressCountry = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setmangopayAccount((prevState) => ({
      ...prevState,
      HeadquartersAddress: {
        ...prevState.HeadquartersAddress,
        Country: e.target.value,
      },
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      HeadquartersAddress: {
        ...prevState.HeadquartersAddress,
        Country: false,
      },
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayUboAddressCountry = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setMangopayUbo((prevState) => ({
      ...prevState,
      Address: {...prevState.Address, Country: e.target.value},
    }));
    setMangopayUboError((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        Country: false,
      },
    }));
    setIsSomethingChange(true);
    setSomethingIsChnagedInUbo(true);
  };

  const handleMangopayUboBirthplaceCountry = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setMangopayUbo((prevState) => ({
      ...prevState,
      Birthplace: {...prevState.Birthplace, Country: e.target.value},
    }));
    setMangopayUboError((prevState) => ({
      ...prevState,
      Birthplace: {
        ...prevState.Birthplace,
        Country: false,
      },
    }));
    setIsSomethingChange(true);
    setSomethingIsChnagedInUbo(true);
  };

  const handleMangopayUboNationality = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setMangopayUbo((prevState) => ({
      ...prevState,
      Nationality: e.target.value,
    }));
    setMangopayUboError((prevState) => ({
      ...prevState,
      Nationality: false,
    }));
    setIsSomethingChange(true);
    setSomethingIsChnagedInUbo(true);
  };

  const handleMangopayLegalRepresentativeAddressCountry = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setmangopayAccount((prevState) => ({
      ...prevState,
      LegalRepresentativeAddress: {
        ...prevState.LegalRepresentativeAddress,
        Country: e.target.value,
      },
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      LegalRepresentativeAddress: {
        ...prevState.LegalRepresentativeAddress,
        Country: false,
      },
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayLegalPersonType = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    // console.log(e.target.value);
    setmangopayAccount((prevState) => ({
      ...prevState,
      LegalPersonType: e.target.value,
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayLegalRepresentativeNationality = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setmangopayAccount((prevState) => ({
      ...prevState,
      LegalRepresentativeNationality: e.target.value,
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      LegalRepresentativeNationality: false,
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayLegalRepresentativeCountryOfResidence = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setmangopayAccount((prevState) => ({
      ...prevState,
      LegalRepresentativeCountryOfResidence: e.target.value,
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      LegalRepresentativeCountryOfResidence: false,
    }));
    setIsSomethingChange(true);
  };

  const handleMangoPayRegulationsChange = () => {
    setmangopayAccount((prev) => ({
      ...prev,
      TermsAndConditionsAccepted: !prev.TermsAndConditionsAccepted,
    }));
    setIsSomethingChange(true);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getIdImage = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();
      if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
        const base64 = await toBase64(e.target.files[0]);
        const body = base64.toString().split(',')[1];
        if (idScans[0] === body) {
          return Alert(
            `Wybrano takie samo zdjęcie, proszę wybrać inne`,
            'Komunikat',
          );
        }
        if (idScans.length < 2) {
          setIdScans((prev) => [...prev, body]);
          if (btnType.front) {
            setValidateScans((prevState) => ({...prevState, front: true}));
            setIdScansFrontError((prevState) => ({
              ...prevState,
              frontId: {isError: false, text: ''},
            }));
          }
          if (btnType.back) {
            setValidateScans((prevState) => ({...prevState, back: true}));
            setIdScansFrontError((prevState) => ({
              ...prevState,
              backId: {isError: false, text: ''},
            }));
          }
          Alert(
            `Dodano plik ${e.target.files[0].name.slice(0, 20)}`,
            'Komunikat',
          );
        }
      } else {
        Alert('Wybierz zdjęcie w formacie .jpg lub .png', 'Komunikat');
      }
    }
  };

  // const getRegistrationImage = async (e: any) => {
  //   if (e.target.value.length > 0) {
  //     const fileName = e.target.value;
  //     const dotIndex = fileName.lastIndexOf('.');
  //     const extFile = fileName
  //       .substr(dotIndex + 1, fileName.length)
  //       .toLowerCase();
  //     if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
  //       const base64 = await toBase64(e.target.files[0]);
  //       const body = base64.toString().split(',')[1];
  //       if (registrationScans[0] === body) {
  //         return Alert(
  //           `Wybrano takie samo zdjęcie, proszę wybrać inne`,
  //           'Komunikat',
  //         );
  //       }
  //       if (registrationScans.length < 5) {
  //         setRegistrationScans((prev) => [...prev, body]);
  //         if (btnRegistrationType.front) {
  //           setValidateRegistrationScans((prevState) => ({
  //             ...prevState,
  //             front: true,
  //           }));
  //           setRegistrationScansFrontError((prevState) => ({
  //             ...prevState,
  //             frontId: {isError: false, text: ''},
  //           }));
  //         }
  //         if (btnRegistrationType.back) {
  //           setValidateRegistrationScans((prevState) => ({
  //             ...prevState,
  //             back: true,
  //           }));
  //           setRegistrationScansFrontError((prevState) => ({
  //             ...prevState,
  //             backId: {isError: false, text: ''},
  //           }));
  //         }
  //         Alert(
  //           `Dodano plik ${e.target.files[0].name.slice(0, 20)}`,
  //           'Komunikat',
  //         );
  //       }
  //     } else {
  //       Alert('Wybierz zdjęcie w formacie .jpg lub .png', 'Komunikat');
  //     }
  //   }
  // };

  const getRegistrationImage = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();

      if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
        const base64 = await toBase64(e.target.files[0]);
        const body = base64.toString().split(',')[1]; // Usuwamy prefiks Base64

        if (registrationScans[0] === body) {
          return Alert(
            `Wybrano takie samo zdjęcie, proszę wybrać inne`,
            'Komunikat',
          );
        }

        if (registrationScans.length < 5) {
          setRegistrationScans((prev) => [...prev, body]);

          setValidateRegistrationScans((prevState) => ({
            ...prevState,
            front: true,
          }));
          setRegistrationScansFrontError((prevState) => ({
            ...prevState,
            frontId: {isError: false, text: ''},
          }));

          setValidateRegistrationScans((prevState) => ({
            ...prevState,
            back: true,
          }));
          setRegistrationScansFrontError((prevState) => ({
            ...prevState,
            backId: {isError: false, text: ''},
          }));

          Alert(
            `Dodano plik ${e.target.files[0].name.slice(0, 20)}`,
            'Komunikat',
          );
        }
      } else if (extFile === 'pdf') {
        const file = e.target.files[0];

        // Obsługa plików PDF
        if (registrationScans.length < 5) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const base64String = e.target?.result?.toString();
            const body = base64String?.split(',')[1]; // Usunięcie prefiksu Base64

            setRegistrationScans((prev) => [...prev, body]);

            Alert(`Dodano plik PDF ${file.name}`, 'Komunikat');
          };
          reader.readAsDataURL(file); // Konwersja PDF na Base64

          setValidateRegistrationScans((prevState) => ({
            ...prevState,
            front: true,
          }));
          setRegistrationScansFrontError((prevState) => ({
            ...prevState,
            frontId: {isError: false, text: ''},
          }));

          setValidateRegistrationScans((prevState) => ({
            ...prevState,
            back: true,
          }));
          setRegistrationScansFrontError((prevState) => ({
            ...prevState,
            backId: {isError: false, text: ''},
          }));
        }
      } else {
        Alert(
          'Wybierz zdjęcie w formacie .jpg, .png lub plik PDF',
          'Komunikat',
        );
      }
    }
  };

  // const getArticlesOfAssociationFile = async (e: any) => {
  //   if (e.target.value.length > 0) {
  //     const fileName = e.target.value;
  //     const dotIndex = fileName.lastIndexOf('.');
  //     const extFile = fileName
  //       .substr(dotIndex + 1, fileName.length)
  //       .toLowerCase();
  //     if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
  //       const base64 = await toBase64(e.target.files[0]);
  //       const body = base64.toString().split(',')[1];
  //       if (articlesOfAssociationScans[0] === body) {
  //         return Alert(
  //           `Wybrano takie samo zdjęcie, proszę wybrać inne`,
  //           'Komunikat',
  //         );
  //       }
  //       if (articlesOfAssociationScans.length < 2) {
  //         setArticlesOfAssociationScans((prev) => [...prev, body]);
  //         if (btnArticlesOfAssociationType.front) {
  //           setValidateArticlesOfAssociationScans((prevState) => ({
  //             ...prevState,
  //             front: true,
  //           }));
  //           setArticlesOfAssociationScansFrontError((prevState) => ({
  //             ...prevState,
  //             frontId: {isError: false, text: ''},
  //           }));
  //         }
  //         if (btnArticlesOfAssociationType.back) {
  //           setValidateArticlesOfAssociationScans((prevState) => ({
  //             ...prevState,
  //             back: true,
  //           }));
  //           setArticlesOfAssociationScansFrontError((prevState) => ({
  //             ...prevState,
  //             backId: {isError: false, text: ''},
  //           }));
  //         }
  //         Alert(
  //           `Dodano plik ${e.target.files[0].name.slice(0, 20)}`,
  //           'Komunikat',
  //         );
  //       }
  //     } else {
  //       Alert('Wybierz zdjęcie w formacie .jpg lub .png', 'Komunikat');
  //     }
  //   }
  // };

  const getArticlesOfAssociationFile = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();

      if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
        const base64 = await toBase64(e.target.files[0]);
        const body = base64.toString().split(',')[1];

        if (articlesOfAssociationScans[0] === body) {
          return Alert(
            `Wybrano takie samo zdjęcie, proszę wybrać inne`,
            'Komunikat',
          );
        }

        if (articlesOfAssociationScans.length < 5) {
          setArticlesOfAssociationScans((prev) => [...prev, body]);

          setValidateArticlesOfAssociationScans((prevState) => ({
            ...prevState,
            front: true,
          }));
          setArticlesOfAssociationScansFrontError((prevState) => ({
            ...prevState,
            frontId: {isError: false, text: ''},
          }));

          setValidateArticlesOfAssociationScans((prevState) => ({
            ...prevState,
            back: true,
          }));
          setArticlesOfAssociationScansFrontError((prevState) => ({
            ...prevState,
            backId: {isError: false, text: ''},
          }));

          Alert(
            `Dodano plik ${e.target.files[0].name.slice(0, 20)}`,
            'Komunikat',
          );
        }
      } else if (extFile === 'pdf') {
        const file = e.target.files[0];

        // Możesz tutaj dodać logikę dla plików PDF
        if (articlesOfAssociationScans.length < 5) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const base64String = e.target?.result?.toString();
            const body = base64String?.split(',')[1]; // Usunięcie prefiksu Base64

            setArticlesOfAssociationScans((prev) => [...prev, body]);

            Alert(`Dodano plik PDF ${file.name}`, 'Komunikat');
          };
          reader.readAsDataURL(file); // Przekształca PDF na Base64

          setValidateArticlesOfAssociationScans((prevState) => ({
            ...prevState,
            front: true,
          }));
          setArticlesOfAssociationScansFrontError((prevState) => ({
            ...prevState,
            frontId: {isError: false, text: ''},
          }));

          setValidateArticlesOfAssociationScans((prevState) => ({
            ...prevState,
            back: true,
          }));
          setArticlesOfAssociationScansFrontError((prevState) => ({
            ...prevState,
            backId: {isError: false, text: ''},
          }));
        }
      } else {
        Alert(
          'Wybierz zdjęcie w formacie .jpg, .png lub plik PDF',
          'Komunikat',
        );
      }
    }
  };

  const getOfferForProducer = async (producerId: string) => {
    try {
      const response = await api.get(
        `/producer/${producerId}/active-offers-count`,
      );
      if (response.data.Response < 1) return false;
      if (response.data.Response > 0) return true;
    } catch (error) {
      console.log(error);
    }
  };

  const checkDataBeforeSend = async () => {
    if (!validateData(mangopayAccount))
      return console.log('złe dane w company mangopay');

    if (await getOfferForProducer(decodedToken.ProducerId)) {
      return Alert(
        'Nie możesz aktualizować danych w Mango posiadając wystawione oferty.',
        'Komunikat',
      );
    }
    await createAccount(
      {
        ...mangopayAccount,
        UboDeclaration: {
          CreationDate: '',
          Id: '',
          Message: '',
          ProcessedDate: '',
          Reason: '',
          Status: '',
          Ubos: [mangopayUbo],
        },
      },
      idScans,
    );
    await sentMangopayIBAN();
    if (
      mangopayAccount.LegalPersonType === 'BUSINESS' &&
      mangopayUbo.FirstName.length > 1
    ) {
      await createUbo();
    }
    if (mangopayAccount.LegalPersonType === 'BUSINESS') {
      console.log('scan');
      await sendIdScansToMangopay(idScans, 'IDENTITY_PROOF');
      await sendIdScansToMangopay(registrationScans, 'REGISTRATION_PROOF');
      await sendIdScansToMangopay(
        articlesOfAssociationScans,
        'ARTICLES_OF_ASSOCIATION',
      );
    }
    if (mangopayAccount.LegalPersonType === 'ORGANIZATION') {
      console.log('ORGANIZATION');
      await sendIdScansToMangopay(idScans, 'IDENTITY_PROOF');
      await sendIdScansToMangopay(registrationScans, 'REGISTRATION_PROOF');
      await sendIdScansToMangopay(
        articlesOfAssociationScans,
        'ARTICLES_OF_ASSOCIATION',
      );
    }
    if (mangopayAccount.LegalPersonType === 'SOLETRADER') {
      console.log('SOLETRADER');
      await sendIdScansToMangopay(idScans, 'IDENTITY_PROOF');
      await sendIdScansToMangopay(registrationScans, 'REGISTRATION_PROOF');
    }
    setIsSomethingChange(false);
    Alert(
      'Formularz z danymi został wysłany, proszę czekać na zweryfikowanie',
      'Komunikat',
      'Zamknij',
      true,
    );
  };

  const CheckFormDataBeforeDeclineEditMode = () => {
    if (
      mangopayAccount.LegalRepresentativeFirstName === '' ||
      mangopayAccount.Name === '' ||
      mangopayAccount.Email === ''
    ) {
      handleType(-1);
    }
  };

  const statusView = () => (
    <AccountStatusInfo>
      <pre>
        {mangopayAccount.Status === 1
          ? `W trakcie weryfikacji konta zauważyliśmy braki.\nUzupełnij proszę następujące dane..`
          : mangopayAccount.Status === 2
          ? `Twoje dane są w trakcie przetwarzania przez MangoPay.\nPowiadomimy Cię
      o sytuacji w ciągu 48h`
          : mangopayAccount.KYCLevel === 'REGULAR'
          ? 'Portfel MUGO jest aktywny.\nWystawiaj oferty i wypłacaj swoje zarobki.'
          : mangopayAccount.Status === 5
          ? `Dane konta nie zgadzają się z danymi dokumentu.\nProszę zaktualizować dane`
          : `Twoje konto jest w trakcie weryfikacji.\nProszę czekać`}
      </pre>
    </AccountStatusInfo>
  );

  return (
    <AccountMangopayCompanyWrapper
      isEditing={isEditing}
      status={mangopayAccount.Status}
    >
      <MangopayHeaderForm>
        {isHoveredForEditBtn && (
          <HoverTextForEdit>
            <p>{isEditing ? 'Anuluj' : 'Edytuj'}</p>
          </HoverTextForEdit>
        )}
        <EditBtnWrapper
          onMouseEnter={() => setIsHoveredForEditBtn(true)}
          onMouseLeave={() => setIsHoveredForEditBtn(false)}
          isEditing={isEditing}
        >
          <EditSvgWrapper
            onMouseEnter={() => console.log('hover edit anuluj')}
            isEditing={isEditing}
            onClick={() => {
              handleEditingMode();
              CheckFormDataBeforeDeclineEditMode();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.27"
              height="16.658"
              viewBox="0 0 17.27 16.658"
            >
              <g
                id="Icon_feather-edit-3"
                data-name="Icon feather-edit-3"
                transform="translate(-3.75 -3.41)"
              >
                <path
                  id="Path_3712"
                  data-name="Path 3712"
                  d="M18,30h7.885"
                  transform="translate(-5.615 -10.682)"
                  fill="none"
                  stroke={isEditing ? 'rgba(58,135,240,1)' : 'rgba(7,2,15,0.7)'}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <path
                  id="Path_3713"
                  data-name="Path 3713"
                  d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                  fill="none"
                  stroke={isEditing ? 'rgba(58,135,240,1)' : 'rgba(7,2,15,0.7)'}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </g>
            </svg>
          </EditSvgWrapper>
        </EditBtnWrapper>
        {/* {isSomethingChange ? (
          <FlexSaveBtn
            onClick={() => {
              showSavePopUp(mangopayAccount, validateData(mangopayAccount));
              setIsSomethingChange(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="18"
              viewBox="0 0 14 18"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect width="14" height="18" fill="none" />
                </clipPath>
              </defs>
              <g
                id="Icons_24px_Document"
                data-name="Icons – 24px / Document"
                clipPath="url(#clip-path)"
              >
                <path
                  id="Icons_24px_Document-2"
                  data-name="Icons – 24px / Document"
                  d="M14,18H0V0H9.384L9.6.194l4.2,4.154.2.216V18ZM1.4,1.384V16.616H12.6V5.539H8.4V1.384Zm8.4,1V4.154h1.794Zm.7,11.467h-7V12.461h7v1.384Zm0-2.769h-7V9.692h7v1.384Zm0-2.769h-7V6.923h7V8.307Z"
                  fill="#fff"
                />
              </g>
            </svg>
          </FlexSaveBtn>
        ) : null} */}
        <h4>
          {mangopayAccount.UserCategory !== 'OWNER'
            ? 'Rejestracja portfela MUGO '
            : 'Portfel MUGO '}
        </h4>
        <PaymentLogoWrapper status={mangopayAccount.Status}>
          <LogoContainer>
            <svg
              id="logo"
              xmlns="http://www.w3.org/2000/svg"
              width="115.685"
              height="17"
              viewBox="0 0 115.685 17"
            >
              <path
                id="Path_3733"
                data-name="Path 3733"
                d="M62.841.564A4.653,4.653,0,0,0,60.583,0a3.828,3.828,0,0,0-2.046.494,4.43,4.43,0,0,0-1.481,1.34h-.071V.282H53.6V12.2h3.386V5.361a2.416,2.416,0,0,1,.282-1.27,2.242,2.242,0,0,1,.776-.846,2.436,2.436,0,0,1,1.27-.353,2.074,2.074,0,0,1,1.552.635A2.249,2.249,0,0,1,61.43,5.22v6.913h3.315V4.8a5.949,5.949,0,0,0-.494-2.61A4.53,4.53,0,0,0,62.841.564Z"
                transform="translate(-15.791 0)"
                fill="#f8f8f8"
              />
              <path
                id="Path_3734"
                data-name="Path 3734"
                d="M18.834,0h.141a4.169,4.169,0,0,1,4.162,4.162v7.971H19.822V3.95a1.085,1.085,0,0,0-1.058-1.058h0a1,1,0,0,0-.988.776L16.224,9.946A3.189,3.189,0,0,1,9.946,9.17V3.95A1.085,1.085,0,0,0,8.888,2.892a1,1,0,0,0-.988.776L6.419,9.876a3.244,3.244,0,0,1-3.174,2.469A3.177,3.177,0,0,1,0,9.17V.282H3.315V9.734h0L4.938,3.315a4.171,4.171,0,0,1,8.253.846v5.5h0l1.552-6.419A4.161,4.161,0,0,1,18.834,0Z"
                transform="translate(0 0)"
                fill="#f8f8f8"
              />
              <path
                id="Path_3735"
                data-name="Path 3735"
                d="M43.141,1.763h0L43.211.282H46.6V12.2H43.282V10.722h-.141a3.321,3.321,0,0,1-1.058.917,4.508,4.508,0,0,1-1.34.564,5.273,5.273,0,0,1-4.232-.564,5.308,5.308,0,0,1-1.9-2.187A7.064,7.064,0,0,1,33.9,6.207a8.07,8.07,0,0,1,.635-3.245,4.806,4.806,0,0,1,1.9-2.187A5.278,5.278,0,0,1,39.261,0a5.5,5.5,0,0,1,1.552.212,5.293,5.293,0,0,1,1.27.564A2.888,2.888,0,0,1,43.141,1.763ZM41.871,9.17a3.265,3.265,0,0,0,1.058-1.129h0a4.356,4.356,0,0,0,.353-1.763,4.621,4.621,0,0,0-.353-1.763,2.49,2.49,0,0,0-1.058-1.2,2.844,2.844,0,0,0-1.552-.423,3.021,3.021,0,0,0-1.552.423,3.265,3.265,0,0,0-1.058,1.129,4.356,4.356,0,0,0-.353,1.763,4.621,4.621,0,0,0,.353,1.763A2.744,2.744,0,0,0,38.767,9.1a2.608,2.608,0,0,0,1.481.423A3.919,3.919,0,0,0,41.871,9.17Z"
                transform="translate(-9.987)"
                fill="#f8f8f8"
                fillRule="evenodd"
              />
              <path
                id="Path_3736"
                data-name="Path 3736"
                d="M79.841,1.834h.071V.282H83.3v11.5a5.461,5.461,0,0,1-.776,2.822,4.23,4.23,0,0,1-2.187,1.763A7.834,7.834,0,0,1,77.09,17a9.379,9.379,0,0,1-2.963-.423,5.441,5.441,0,0,1-2.116-1.34,3.916,3.916,0,0,1-.988-2.187h3.315a1.746,1.746,0,0,0,.988,1.129,4.718,4.718,0,0,0,1.834.353,3.272,3.272,0,0,0,1.481-.282,1.929,1.929,0,0,0,.917-.917,3.279,3.279,0,0,0,.353-1.481V10.228h-.071a4.078,4.078,0,0,1-1.622,1.27,4.631,4.631,0,0,1-2.257.494,5.334,5.334,0,0,1-2.751-.705,5.91,5.91,0,0,1-1.9-2.046,7.429,7.429,0,0,1-.705-3.1,6.3,6.3,0,0,1,.705-3.1A5.055,5.055,0,0,1,73.21.917,3.873,3.873,0,0,1,75.89,0a4.818,4.818,0,0,1,2.257.494A5.088,5.088,0,0,1,79.841,1.834ZM78.5,8.606a3.778,3.778,0,0,0,1.058-1.058h0a3.678,3.678,0,0,0,.353-1.622A3.919,3.919,0,0,0,79.559,4.3,2.55,2.55,0,0,0,78.5,3.245a2.809,2.809,0,0,0-1.552-.353,3.591,3.591,0,0,0-1.552.353A3.778,3.778,0,0,0,74.339,4.3a3.908,3.908,0,0,0,0,3.245A1.945,1.945,0,0,0,75.4,8.606a2.809,2.809,0,0,0,1.552.353A2.415,2.415,0,0,0,78.5,8.606Z"
                transform="translate(-20.799 0)"
                fill="#f8f8f8"
                fillRule="evenodd"
              />
              <path
                id="Path_3737"
                data-name="Path 3737"
                d="M99.493.776A6.314,6.314,0,0,0,96.249,0,6.891,6.891,0,0,0,93,.776a5.359,5.359,0,0,0-2.257,2.257A7.154,7.154,0,0,0,89.9,6.278a6.364,6.364,0,0,0,.846,3.245,5.8,5.8,0,0,0,2.328,2.187,7.173,7.173,0,0,0,6.49,0,5.2,5.2,0,0,0,2.257-2.187,6.183,6.183,0,0,0,.846-3.245,6.364,6.364,0,0,0-.846-3.245A7.1,7.1,0,0,0,99.493.776Zm-.705,7.266a3.511,3.511,0,0,1-1.058,1.2,2.8,2.8,0,0,1-2.963,0,3.511,3.511,0,0,1-1.058-1.2,3.436,3.436,0,0,1-.423-1.763,3.436,3.436,0,0,1,.423-1.763,3.511,3.511,0,0,1,1.058-1.2,2.438,2.438,0,0,1,1.411-.494,3.021,3.021,0,0,1,1.552.423,3.511,3.511,0,0,1,1.058,1.2,3.622,3.622,0,0,1,.423,1.763,5,5,0,0,1-.423,1.834Z"
                transform="translate(-26.485 0)"
                fill="#f8f8f8"
                fillRule="evenodd"
              />
              <path
                id="Path_3738"
                data-name="Path 3738"
                d="M116.336,0a5.461,5.461,0,0,1,2.822.776,5.8,5.8,0,0,1,1.9,2.187,7.607,7.607,0,0,1,.635,3.245,6.68,6.68,0,0,1-.705,3.245,5.193,5.193,0,0,1-1.9,2.187,5.033,5.033,0,0,1-2.751.776,5.5,5.5,0,0,1-1.552-.212,4.679,4.679,0,0,1-1.34-.635,4.507,4.507,0,0,1-1.058-.917h0v6H109V.282h3.386V1.763h0a6.536,6.536,0,0,1,.988-.988,4.679,4.679,0,0,1,1.34-.635A13.772,13.772,0,0,1,116.336,0Zm.564,9.17a3.265,3.265,0,0,0,1.058-1.129h0a3.435,3.435,0,0,0,.353-1.763,4.356,4.356,0,0,0-.353-1.763A2.36,2.36,0,0,0,116.9,3.386a3.615,3.615,0,0,0-1.552-.423,2.683,2.683,0,0,0-1.552.423,2.961,2.961,0,0,0-1.058,1.2,3.6,3.6,0,0,0-.353,1.763,4.621,4.621,0,0,0,.353,1.763A2.341,2.341,0,0,0,113.8,9.241a2.844,2.844,0,0,0,1.552.423A4.005,4.005,0,0,0,116.9,9.17Z"
                transform="translate(-32.112 0)"
                fill="#f8f8f8"
                fillRule="evenodd"
              />
              <path
                id="Path_3739"
                data-name="Path 3739"
                d="M137.241,1.793h0a4.3,4.3,0,0,0-1.058-.917,4.114,4.114,0,0,0-1.27-.564,5.61,5.61,0,0,0-4.373.564,5.8,5.8,0,0,0-1.9,2.187A7.834,7.834,0,0,0,128,6.307a6.868,6.868,0,0,0,.705,3.245,5.192,5.192,0,0,0,1.9,2.187,5.032,5.032,0,0,0,2.751.776,4.69,4.69,0,0,0,1.481-.212,7.645,7.645,0,0,0,1.34-.564,6.583,6.583,0,0,0,1.058-.917h.071V12.3h3.315V.312h-3.386V1.793ZM136.888,8a2.341,2.341,0,0,1-1.058,1.129,2.844,2.844,0,0,1-1.552.423,2.608,2.608,0,0,1-1.481-.423A3.265,3.265,0,0,1,131.739,8a3.435,3.435,0,0,1-.353-1.763,4.622,4.622,0,0,1,.353-1.763A2.075,2.075,0,0,1,132.8,3.345a3.057,3.057,0,0,1,3.1,0,2.961,2.961,0,0,1,1.058,1.2,4.356,4.356,0,0,1,.353,1.763A3.173,3.173,0,0,1,136.888,8Z"
                transform="translate(-37.71 -0.029)"
                fill="#f8f8f8"
                fillRule="evenodd"
              />
              <path
                id="Path_3740"
                data-name="Path 3740"
                d="M153.283,6.537,155.329.4h3.456l-5.22,13.544a4.117,4.117,0,0,1-1.693,2.187,5.623,5.623,0,0,1-3.1.705H147.5v-2.68h1.27a2.811,2.811,0,0,0,1.2-.212,1.909,1.909,0,0,0,.705-.776l.282-.776L146.3.471h3.456l2.116,6.137c.212.635.423,1.34.705,2.187h.071C152.86,7.877,153.072,7.172,153.283,6.537Z"
                transform="translate(-43.101 -0.118)"
                fill="#f8f8f8"
              />
            </svg>
          </LogoContainer>
          {/*<LogoContainer>*/}
          {/*  <img src={ueLogo} />*/}
          {/*</LogoContainer>*/}
        </PaymentLogoWrapper>
        {mangopayAccount.UserCategory === 'OWNER' ? statusView() : null}
        {mangopayAccount.UserCategory !== 'OWNER' ? (
          <AccountBasicButtonWrapper
            isEditing={isEditing}
            style={{marginBottom: '8px'}}
          >
            <MangoPayBasicButton
              disabled={!isEditing}
              onClick={() => handleType(0)}
              value="person"
              messagesType={formType === 0 ? 'person' : 'company'}
            >
              Osoba fizyczna
            </MangoPayBasicButton>
            <MangoPayBasicButton
              disabled={!isEditing}
              onClick={() => handleType(1)}
              value="company"
              messagesType={formType === 0 ? 'person' : 'company'}
            >
              Działalność gospodarcza
            </MangoPayBasicButton>
          </AccountBasicButtonWrapper>
        ) : (
          <StyledStatusWrapper KYCLevelStatus={mangopayAccount.KYCLevel}>
            <h1>{getKYCLevelStatusDescription(mangopayAccount.KYCLevel)}</h1>
            <StyledStatusLine KYCLevelStatus={mangopayAccount.KYCLevel}>
              <div />
            </StyledStatusLine>
          </StyledStatusWrapper>
        )}
      </MangopayHeaderForm>
      <ScrollWrapper isImgAdded={mangopayAccount.UserCategory === 'OWNER'}>
        <AccountMangoPayFormWrapper isEditing={isEditing}>
          <StyledSelectView
            isEditing={isEditing}
            isError={
              mangopayErrors.ShowErrors && mangopayErrors.LegalPersonType
            }
          >
            <p>Forma rejestracji</p>
            <select
              value={mangopayAccount.LegalPersonType}
              onChange={handleMangopayLegalPersonType}
            >
              {' '}
              <option hidden value={''}>
                Wybierz
              </option>
              <option value={'BUSINESS'}>Spółka firmowa</option>
              <option value={'ORGANIZATION'}>Fundacja</option>
              <option value={'SOLETRADER'}>
                Jednoosobowa działalność gosp.
              </option>
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.439"
              height="11"
              viewBox="0 0 6.439 11"
            >
              <g
                id="Arrow_Top_Bar_Accent"
                data-name="Arrow – Top Bar / Accent"
                transform="translate(6.439 11) rotate(180)"
              >
                <path
                  id="Arrow"
                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                  transform="translate(0 0)"
                  fill="#3986ef"
                />
              </g>
            </svg>
            {mangopayErrors.LegalPersonType ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </StyledSelectView>
          <InputView
            isError={mangopayErrors.ShowErrors && mangopayErrors.Name}
            isEditing={isEditing}
            style={windowWidth > 1024 ? {marginTop: '8px'} : {marginTop: '0px'}}
          >
            <p>Nazwa firmy</p>
            <input
              type="text"
              placeholder=""
              id="Name"
              readOnly={!isEditing}
              value={mangopayAccount.Name}
              onChange={handleMangopayAccountChange}
            />
            {mangopayErrors.Name ? (
              <ErrorStar
                fixed={true}
                text={'Pole nazwa jest obowiązkowe. Prosimy uzupełnić dane'}
              />
            ) : null}
          </InputView>
          {/* {mangopayAccount.LegalPersonType === 'BUSINESS' ? ( */}
          <InputView
            isError={mangopayErrors.ShowErrors && mangopayErrors.CompanyNumber}
            isEditing={isEditing}
            style={windowWidth < 1024 ? null : {marginTop: '5px'}}
          >
            <p>
              {mangopayAccount.LegalPersonType === `SOLETRADER` ? 'NIP' : 'KRS'}
            </p>
            <input
              maxLength={10}
              type="text"
              placeholder=""
              id="CompanyNumber"
              readOnly={!isEditing}
              value={mangopayAccount.CompanyNumber}
              onChange={handleKrsNumberChange}
            />
            {mangopayErrors.CompanyNumber ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole numer firmy jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </InputView>
          {/* // ) : null} */}
          <AccountInfoText>Adres siedziby głównej</AccountInfoText>
          <InputView
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.HeadquartersAddress.AddressLine1
            }
            isEditing={isEditing}
          >
            <p>Ulica</p>
            <input
              type="text"
              placeholder=""
              id="AddressLine1"
              readOnly={!isEditing}
              value={mangopayAccount.HeadquartersAddress.AddressLine1}
              onChange={handleMangopayHeadquartersAddressChange}
              style={windowWidth < 1024 ? null : {marginTop: '5px'}}
            />
            {mangopayErrors.HeadquartersAddress.AddressLine1 ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole ulica  siedziby głównej jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </InputView>
          <TwoSmallInputsWrapper>
            <InputView
              isEditing={isEditing}
              isMediumInput={true}
              style={
                windowWidth < 374
                  ? {width: '141px', marginTop: '0px'}
                  : windowWidth < 400
                  ? {width: '155px', marginTop: '0px'}
                  : windowWidth < 1024
                  ? {width: '183px', marginTop: '0px'}
                  : {
                      width: '50%',
                      marginTop: '5px',
                    }
              }
            >
              <p>Nr budynku</p>
              <input
                type="text"
                placeholder=""
                id="FlatNumber"
                readOnly={!isEditing}
                value={mangopayAccount.HeadquartersAddress.FlatNumber}
                onChange={handleMangopayHeadquartersAddressChange}
              />
              {mangopayErrors.HeadquartersAddress.AddressLine2 ? (
                <ErrorStar
                  fixed={true}
                  text={
                    'Pole numer bloku siedziby głównej jest obowiązkowe. Prosimy uzupełnić dane'
                  }
                />
              ) : null}
            </InputView>
            <InputView
              isEditing={isEditing}
              isSmallInput={true}
              style={
                windowWidth < 374
                  ? {width: '135px', marginTop: '0px', marginLeft: '10px'}
                  : windowWidth < 400
                  ? {width: '150px', marginTop: '0px', marginLeft: '10px'}
                  : windowWidth < 1024
                  ? {width: '178px', marginTop: '0px', marginLeft: '10px'}
                  : {
                      width: '159px',
                      marginTop: '5px',
                    }
              }
            >
              <p>Nr lokalu</p>
              <input
                type="text"
                placeholder=""
                id="BuildNumber"
                readOnly={!isEditing}
                value={mangopayAccount.HeadquartersAddress.BuildNumber}
                onChange={handleMangopayHeadquartersAddressChange}
              />
            </InputView>
          </TwoSmallInputsWrapper>
          <TwoSmallInputsWrapper>
            <InputView
              isError={
                mangopayErrors.ShowErrors &&
                mangopayErrors.HeadquartersAddress.PostalCode
              }
              isEditing={isEditing}
              isMediumInput={true}
              style={
                windowWidth < 374
                  ? {width: '141px', marginTop: '0px'}
                  : windowWidth < 400
                  ? {width: '155px', marginTop: '0px'}
                  : windowWidth < 1024
                  ? {width: '183px', marginTop: '0px'}
                  : {
                      width: '50%',
                      marginTop: '5px',
                    }
              }
            >
              <p>Kod pocztowy</p>
              <input
                style={{paddingLeft: '70px'}}
                type="text"
                placeholder=""
                id="PostalCode"
                maxLength={6}
                readOnly={!isEditing}
                value={mangopayAccount.HeadquartersAddress.PostalCode}
                onChange={handleHeadquartersAddressPostalCodeChange}
              />
              {mangopayErrors.HeadquartersAddress.PostalCode ? (
                <ErrorStar
                  fixed={true}
                  text={
                    'Pole kod pocztowy siedziby głównej jest obowiązkowe. Prosimy uzupełnić dane'
                  }
                />
              ) : null}
            </InputView>
            <InputView
              isError={
                mangopayErrors.ShowErrors &&
                mangopayErrors.HeadquartersAddress.City
              }
              isSmallInput={true}
              isEditing={isEditing}
              style={
                windowWidth < 374
                  ? {width: '135px', marginTop: '0px', marginLeft: '10px'}
                  : windowWidth < 400
                  ? {width: '150px', marginTop: '0px', marginLeft: '10px'}
                  : windowWidth < 1024
                  ? {width: '178px', marginTop: '0px', marginLeft: '10px'}
                  : {
                      width: '159px',
                      marginTop: '5px',
                    }
              }
            >
              <p>Miasto</p>
              <input
                style={{paddingLeft: '70px'}}
                type="text"
                placeholder=""
                id="City"
                readOnly={!isEditing}
                value={mangopayAccount.HeadquartersAddress.City}
                onChange={handleMangopayHeadquartersAddressChange}
              />
              {mangopayErrors.HeadquartersAddress.City ? (
                <ErrorStar
                  fixed={true}
                  text={
                    'Pole miasto siedziby głównej jest obowiązkowe. Prosimy uzupełnić dane'
                  }
                />
              ) : null}
            </InputView>
          </TwoSmallInputsWrapper>
          <StyledSelectView
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.HeadquartersAddress.Country
            }
            isEditing={isEditing}
          >
            <p>Kraj</p>
            <select
              value={mangopayAccount.HeadquartersAddress.Country}
              onChange={handleMangopayHeadquartersAddressCountry}
            >
              {' '}
              <option hidden value={''}>
                Wybierz
              </option>
              {countries.map((item) => (
                <option key={item.FlagValue} value={item.FieldName}>
                  {item.DisplayValue}
                </option>
              ))}
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.439"
              height="11"
              viewBox="0 0 6.439 11"
            >
              <g
                id="Arrow_Top_Bar_Accent"
                data-name="Arrow – Top Bar / Accent"
                transform="translate(6.439 11) rotate(180)"
              >
                <path
                  id="Arrow"
                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                  transform="translate(0 0)"
                  fill="#3986ef"
                />
              </g>
            </svg>
            {mangopayErrors.HeadquartersAddress.Country ? (
              <ErrorStar
                fixed={true}
                text={'Pole kraj jest obowiązkowe. Prosimy uzupełnić dane'}
              />
            ) : null}
          </StyledSelectView>
          {/* <Select
            selectWidth={
              windowWidth <= 340
                ? '90%'
                : windowWidth <= 400
                ? '311px'
                : windowWidth <= 1024
                ? '358px'
                : '100%'
            }
            customStyles={{
              width:
                windowWidth <= 340
                  ? '90%'
                  : windowWidth <= 400
                  ? '311px'
                  : windowWidth <= 1024
                  ? '358px'
                  : '100%',
              marginTop: '5px',
            }}
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.HeadquartersAddress.Country
            }
            isEditing={isEditing}
            value={
              mangopayAccount.HeadquartersAddress.Country !== null &&
              mangopayAccount.HeadquartersAddress.Country !== ''
                ? nationalityTypeObj[
                    mangopayAccount.HeadquartersAddress.Country
                  ]
                : {value: '', text: ''}
            }
            values={nationalityTypeOptionsArr}
            title="Kraj"
            onChange={(v) => handleMangopayHeadquartersAddressCountry(v)}
          >
            {' '}
            {mangopayErrors.HeadquartersAddress.Country ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </Select> */}
          <AccountInfoText>Przedstawiciel prawny</AccountInfoText>
          <InputView
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.LegalRepresentativeFirstName
            }
            isEditing={isEditing}
            style={windowWidth < 1024 ? null : {marginTop: '5px'}}
          >
            <p>Imię</p>
            <input
              type="text"
              placeholder=""
              id="LegalRepresentativeFirstName"
              readOnly={!isEditing}
              value={mangopayAccount.LegalRepresentativeFirstName}
              onChange={handleMangopayAccountChange}
            />
            {mangopayErrors.LegalRepresentativeFirstName ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole Imię reprezentanta jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </InputView>
          <InputView
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.LegalRepresentativeLastName
            }
            isEditing={isEditing}
            style={windowWidth < 1024 ? null : {marginTop: '5px'}}
          >
            <p>Nazwisko</p>
            <input
              type="text"
              placeholder=""
              id="LegalRepresentativeLastName"
              readOnly={!isEditing}
              value={mangopayAccount.LegalRepresentativeLastName}
              onChange={handleMangopayAccountChange}
            />
            {mangopayErrors.LegalRepresentativeLastName ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole Nazwisko reprezentanta jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </InputView>
          <InputView
            isError={mangopayErrors.ShowErrors && mangopayErrors.Email}
            isEditing={isEditing}
            style={windowWidth < 1024 ? null : {marginTop: '5px'}}
          >
            <p>E-mail</p>
            <input
              type="email"
              placeholder=""
              id="Email"
              readOnly={!isEditing}
              style={
                mangopayAccount.Status === 2 || mangopayAccount.Status === 3
                  ? {cursor: 'default'}
                  : null
              }
              value={mangopayAccount.Email}
              onChange={handleMangopayAccountChange}
            />
            {mangopayErrors.Email ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole email jest obowiązkowe. E-mail musi zawierać @. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </InputView>
          <InputViewForData
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.LegalRepresentativeBirthday
            }
            isEditing={isEditing}
            style={windowWidth < 1024 ? null : {marginTop: '5px'}}
          >
            <p>Data urodzenia</p>
            {isEditing ? (
              <input
                type="date"
                id="LegalRepresentativeBirthday"
                placeholder=""
                readOnly={!isEditing}
                value={mangopayAccount.LegalRepresentativeBirthday}
                onChange={handleMangopayAccountChange}
              />
            ) : (
              <span>
                {' '}
                {new Intl.DateTimeFormat('default', {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                })
                  .format(new Date(mangopayAccount.LegalRepresentativeBirthday))
                  .replace(',', '')}
              </span>
            )}
            {mangopayErrors.LegalRepresentativeBirthday ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole data urodzenia reprezentanta jest obowiązkowe, minimum 18 lat. Prosimy poprawnie uzupełnić dane.'
                }
              />
            ) : null}
          </InputViewForData>
          <InputView
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.LegalRepresentativeAddress.AddressLine1
            }
            isEditing={isEditing}
            style={windowWidth < 1024 ? null : {marginTop: '5px'}}
          >
            <p>Ulica</p>
            <input
              type="text"
              placeholder=""
              id="AddressLine1"
              readOnly={!isEditing}
              value={mangopayAccount.LegalRepresentativeAddress.AddressLine1}
              onChange={handleMangopayLegalRepresentativeAddressChange}
            />
            {mangopayErrors.LegalRepresentativeAddress.AddressLine1 ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole ulica przedstawiciela prawnego jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </InputView>
          <TwoSmallInputsWrapper>
            <InputView
              isError={
                mangopayErrors.ShowErrors &&
                mangopayErrors.HeadquartersAddress.PostalCode
              }
              isEditing={isEditing}
              isMediumInput={true}
              style={
                windowWidth < 374
                  ? {width: '141px', marginTop: '0px'}
                  : windowWidth < 400
                  ? {width: '155px', marginTop: '0px'}
                  : windowWidth < 1024
                  ? {width: '183px', marginTop: '0px'}
                  : {
                      width: '50%',
                      marginTop: '5px',
                    }
              }
            >
              <p>Nr budynku</p>
              <input
                type="text"
                maxLength={26}
                placeholder=""
                id="FlatNumber"
                readOnly={!isEditing}
                value={mangopayAccount.LegalRepresentativeAddress.FlatNumber}
                onChange={handleMangopayLegalRepresentativeAddressChange}
              />
            </InputView>
            <InputView
              isEditing={isEditing}
              isSmallInput={true}
              style={
                windowWidth < 374
                  ? {width: '135px', marginTop: '0px', marginLeft: '10px'}
                  : windowWidth < 400
                  ? {width: '150px', marginTop: '0px', marginLeft: '10px'}
                  : windowWidth < 1024
                  ? {width: '178px', marginTop: '0px', marginLeft: '10px'}
                  : {
                      width: '159px',
                      marginTop: '5px',
                    }
              }
            >
              <p>Nr lokalu</p>
              <input
                type="text"
                placeholder=""
                id="BuildNumber"
                readOnly={!isEditing}
                value={mangopayAccount.LegalRepresentativeAddress.BuildNumber}
                onChange={handleMangopayLegalRepresentativeAddressChange}
              />
            </InputView>
          </TwoSmallInputsWrapper>
          <TwoSmallInputsWrapper>
            <InputView
              isError={
                mangopayErrors.ShowErrors &&
                mangopayErrors.LegalRepresentativeAddress.PostalCode
              }
              isEditing={isEditing}
              isSmallInput={true}
              style={
                windowWidth < 374
                  ? {width: '141px', marginTop: '0px'}
                  : windowWidth < 400
                  ? {width: '155px', marginTop: '0px'}
                  : windowWidth < 1024
                  ? {width: '183px', marginTop: '0px'}
                  : {
                      width: '50%',
                      marginTop: '5px',
                    }
              }
            >
              <p>Kod pocztowy</p>
              <input
                style={{paddingLeft: '70px'}}
                type="text"
                placeholder=""
                id="PostalCode"
                maxLength={6}
                readOnly={!isEditing}
                value={mangopayAccount.LegalRepresentativeAddress.PostalCode}
                onChange={handleLegalRepresentativeAddressPostalCodeChange}
              />
              {mangopayErrors.LegalRepresentativeAddress.PostalCode ? (
                <ErrorStar
                  fixed={true}
                  text={
                    'Pole kod pocztowy przedstawiciela prawnego jest obowiązkowe. Prosimy uzupełnić dane'
                  }
                />
              ) : null}
            </InputView>
            <InputView
              isError={
                mangopayErrors.ShowErrors &&
                mangopayErrors.LegalRepresentativeAddress.City
              }
              isEditing={isEditing}
              isMediumInput={true}
              style={
                windowWidth < 374
                  ? {width: '135px', marginTop: '0px', marginLeft: '10px'}
                  : windowWidth < 400
                  ? {width: '150px', marginTop: '0px', marginLeft: '10px'}
                  : windowWidth < 1024
                  ? {width: '178px', marginTop: '0px', marginLeft: '10px'}
                  : {
                      width: '159px',
                      marginTop: '5px',
                    }
              }
            >
              <p>Miasto</p>
              <input
                style={{paddingLeft: '70px'}}
                type="text"
                placeholder=""
                id="City"
                readOnly={!isEditing}
                value={mangopayAccount.LegalRepresentativeAddress.City}
                onChange={handleMangopayLegalRepresentativeAddressChange}
              />
              {mangopayErrors.LegalRepresentativeAddress.City ? (
                <ErrorStar
                  fixed={true}
                  text={
                    'Pole miasto przedstawiciela prawnego jest obowiązkowe. Prosimy uzupełnić dane'
                  }
                />
              ) : null}
            </InputView>
          </TwoSmallInputsWrapper>
          <StyledSelectView
            style={windowWidth < 1024 ? null : {marginTop: '5px'}}
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.LegalRepresentativeAddress.Country
            }
            isEditing={isEditing}
          >
            <p>Kraj</p>
            <select
              value={mangopayAccount.LegalRepresentativeAddress.Country}
              onChange={handleMangopayLegalRepresentativeAddressCountry}
            >
              {' '}
              <option hidden value={''}>
                Wybierz
              </option>
              {countries.map((item) => (
                <option key={item.FlagValue} value={item.FieldName}>
                  {item.DisplayValue}
                </option>
              ))}
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.439"
              height="11"
              viewBox="0 0 6.439 11"
            >
              <g
                id="Arrow_Top_Bar_Accent"
                data-name="Arrow – Top Bar / Accent"
                transform="translate(6.439 11) rotate(180)"
              >
                <path
                  id="Arrow"
                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                  transform="translate(0 0)"
                  fill="#3986ef"
                />
              </g>
            </svg>
            {mangopayErrors.LegalRepresentativeAddress.Country ? (
              <ErrorStar
                fixed={true}
                text={'Pole kraj jest obowiązkowe. Prosimy uzupełnić dane'}
              />
            ) : null}
          </StyledSelectView>
          {/* <Select
            selectWidth={
              windowWidth <= 340
                ? '90%'
                : windowWidth <= 400
                ? '311px'
                : windowWidth <= 1024
                ? '358px'
                : '100%'
            }
            customStyles={{
              width:
                windowWidth <= 340
                  ? '90%'
                  : windowWidth <= 400
                  ? '311px'
                  : windowWidth <= 1024
                  ? '358px'
                  : '100%',
              marginTop: '5px',
            }}
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.LegalRepresentativeAddress.Country
            }
            isEditing={isEditing}
            value={
              mangopayAccount.LegalRepresentativeAddress.Country !== null &&
              mangopayAccount.LegalRepresentativeAddress.Country !== ''
                ? nationalityTypeObj[
                    mangopayAccount.LegalRepresentativeAddress.Country
                  ]
                : {value: '', text: ''}
            }
            values={nationalityTypeOptionsArr}
            title="Kraj"
            onChange={(v) => handleMangopayLegalRepresentativeAddressCountry(v)}
          >
            {' '}
            {mangopayErrors.LegalRepresentativeAddress.Country ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </Select> */}
          <StyledSelectView
            style={{width: '50%', marginTop: '5px'}}
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.LegalRepresentativeNationality
            }
            isEditing={isEditing}
          >
            <p>Obywatelstwo</p>
            <select
              value={mangopayAccount.LegalRepresentativeNationality}
              onChange={handleMangopayLegalRepresentativeNationality}
            >
              {' '}
              <option hidden value={''}>
                Wybierz
              </option>
              {nationalities.map((item) => (
                <option key={item.FlagValue} value={item.FieldName}>
                  {item.DisplayValue}
                </option>
              ))}
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.439"
              height="11"
              viewBox="0 0 6.439 11"
            >
              <g
                id="Arrow_Top_Bar_Accent"
                data-name="Arrow – Top Bar / Accent"
                transform="translate(6.439 11) rotate(180)"
              >
                <path
                  id="Arrow"
                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                  transform="translate(0 0)"
                  fill="#3986ef"
                />
              </g>
            </svg>
            {mangopayErrors.LegalRepresentativeNationality ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole Obywatelstwo jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </StyledSelectView>
          {/* <Select
            selectWidth={
              windowWidth <= 340
                ? '90%'
                : windowWidth <= 400
                ? '311px'
                : windowWidth <= 1024
                ? '358px'
                : '50%'
            }
            customStyles={{
              width:
                windowWidth <= 340
                  ? '90%'
                  : windowWidth <= 400
                  ? '311px'
                  : windowWidth <= 1024
                  ? '358px'
                  : '50%',
              marginTop: '5px',
            }}
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.LegalRepresentativeNationality
            }
            isEditing={isEditing}
            value={
              mangopayAccount.LegalRepresentativeNationality !== null &&
              mangopayAccount.LegalRepresentativeNationality !== ''
                ? nationalityTypeObj[
                    mangopayAccount.LegalRepresentativeNationality
                  ]
                : {value: '', text: ''}
            }
            values={nationalityTypeOptionsArr}
            title="Narodowość"
            onChange={(v) => handleMangopayLegalRepresentativeNationality(v)}
          >
            {mangopayErrors.LegalRepresentativeNationality ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </Select> */}
          <StyledSelectView
            style={{width: isEditing ? '159px' : '156px', marginTop: '5px'}}
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.LegalRepresentativeCountryOfResidence
            }
            isEditing={isEditing}
          >
            <p>Kraj zamieszkania</p>
            <select
              value={mangopayAccount.LegalRepresentativeCountryOfResidence}
              onChange={handleMangopayLegalRepresentativeCountryOfResidence}
            >
              {' '}
              <option hidden value={''}>
                Wybierz
              </option>
              {countries.map((item) => (
                <option key={item.FlagValue} value={item.FieldName}>
                  {item.DisplayValue}
                </option>
              ))}
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="6.439"
              height="11"
              viewBox="0 0 6.439 11"
            >
              <g
                id="Arrow_Top_Bar_Accent"
                data-name="Arrow – Top Bar / Accent"
                transform="translate(6.439 11) rotate(180)"
              >
                <path
                  id="Arrow"
                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                  transform="translate(0 0)"
                  fill="#3986ef"
                />
              </g>
            </svg>
            {mangopayErrors.LegalRepresentativeCountryOfResidence ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole kraj zamieszkania jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </StyledSelectView>
          {/* <Select
            selectWidth={
              windowWidth <= 340
                ? '90%'
                : windowWidth <= 400
                ? '311px'
                : windowWidth <= 1024
                ? '358px'
                : '159px'
            }
            customStyles={{
              width:
                windowWidth <= 340
                  ? '90%'
                  : windowWidth <= 400
                  ? '311px'
                  : windowWidth <= 1024
                  ? '358px'
                  : '159px',
              marginTop: '5px',
            }}
            isError={
              mangopayErrors.ShowErrors &&
              mangopayErrors.LegalRepresentativeCountryOfResidence
            }
            isEditing={isEditing}
            value={
              mangopayAccount.LegalRepresentativeCountryOfResidence !== null &&
              mangopayAccount.LegalRepresentativeCountryOfResidence !== ''
                ? nationalityTypeObj[
                    mangopayAccount.LegalRepresentativeCountryOfResidence
                  ]
                : {value: '', text: ''}
            }
            values={nationalityTypeOptionsArr}
            title="Kraj zamieszkania"
            onChange={(v) =>
              handleMangopayLegalRepresentativeCountryOfResidence(v)
            }
          >
            {' '}
            {mangopayErrors.LegalRepresentativeCountryOfResidence ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </Select> */}
          <DescriptionForInput isEditing={isEditing}>
            Rachunek bankowy
          </DescriptionForInput>
          <InputView
            isError={mangopayErrors.ShowErrors && mangopayErrors.IBAN}
            isEditing={isEditing}
            style={{marginTop: '5px'}}
          >
            <p> {windowWidth <= 400 ? '' : 'Numer'}</p>
            <input
              style={
                windowWidth <= 400
                  ? {
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      textAlign: 'center',
                    }
                  : {paddingLeft: '0px'}
              }
              type="text"
              placeholder=""
              readOnly={!isEditing}
              id="IBAN"
              onChange={handleMangopayIBAN}
              // value={mangopayAccount.IBAN.replace(/[^0-9]/g, '')
              //   .replace(/\s/g, '')
              //   .replace(/(\d{4})/g, '$1 ')
              //   .trim()}
              value={formatIBAN(mangopayAccount.IBAN)}
              maxLength={32}
            />
            {mangopayErrors.IBAN ? (
              <ErrorStar
                fixed={true}
                text={
                  'Pole numer konta bankowego jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </InputView>
          {mangopayAccount.LegalPersonType === 'BUSINESS' ? (
            <>
              <AccountInfoText style={{fontSize: '9px', lineHeight: '15px'}}>
                Prosimy poprawnie wprowadzać dane i przemyśleć ilość
                beneficjentów. Dane można poprawić, dodać kolejnego beneficjenta
                dopiero jak kotno przejdzie weryfikacje. Maksymalna ilość
                beneficjentów to 4
              </AccountInfoText>
              <AccountInfoText>Dane beneficjenta rzeczywistego</AccountInfoText>
              <InputView
                isError={
                  mangopayUboError.ShowErrors && mangopayUboError.FirstName
                }
                isEditing={isEditing}
                style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                notEdit={
                  mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                }
              >
                <p>Imię</p>
                <input
                  type="text"
                  placeholder=""
                  id="FirstName"
                  readOnly={
                    mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                      ? true
                      : !isEditing
                  }
                  value={mangopayUbo.FirstName}
                  onChange={handleMangopayUboChange}
                />
                {mangopayUboError.FirstName ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      'Pole imię beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                    }
                  />
                ) : null}
              </InputView>
              <InputView
                isError={
                  mangopayUboError.ShowErrors && mangopayUboError.LastName
                }
                isEditing={isEditing}
                style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                notEdit={
                  mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                }
              >
                <p>Nazwisko</p>
                <input
                  type="text"
                  placeholder=""
                  id="LastName"
                  readOnly={
                    mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                      ? true
                      : !isEditing
                  }
                  value={mangopayUbo.LastName}
                  onChange={handleMangopayUboChange}
                />
                {mangopayUboError.LastName ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      'Pole nazwisko beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                    }
                  />
                ) : null}
              </InputView>
              <StyledSelectView
                style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                isError={
                  mangopayUboError.ShowErrors && mangopayUboError.Nationality
                }
                isEditing={isEditing}
              >
                <p>Obywatelstwo</p>
                <select
                  disabled={
                    mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                  }
                  value={mangopayUbo.Nationality}
                  onChange={handleMangopayUboNationality}
                >
                  {' '}
                  <option hidden value={''}>
                    Wybierz
                  </option>
                  {nationalities.map((item) => (
                    <option key={item.FlagValue} value={item.FieldName}>
                      {item.DisplayValue}
                    </option>
                  ))}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.439"
                  height="11"
                  viewBox="0 0 6.439 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(6.439 11) rotate(180)"
                  >
                    <path
                      id="Arrow"
                      d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                      transform="translate(0 0)"
                      fill="#3986ef"
                    />
                  </g>
                </svg>
                {mangopayUboError.Nationality ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      'Pole Obywatelstwo beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                    }
                  />
                ) : null}
              </StyledSelectView>
              {/* <Select
                notEdit={
                  mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                }
                isError={
                  mangopayUboError.ShowErrors && mangopayUboError.Nationality
                }
                selectWidth={
                  windowWidth <= 340
                    ? '90%'
                    : windowWidth <= 400
                    ? '311px'
                    : windowWidth <= 1024
                    ? '358px'
                    : '100%'
                }
                customStyles={{
                  width:
                    windowWidth <= 340
                      ? '90%'
                      : windowWidth <= 400
                      ? '311px'
                      : windowWidth <= 1024
                      ? '358px'
                      : '100%',
                  marginTop: '5px',
                }}
                isEditing={isEditing}
                value={
                  mangopayUbo.Nationality !== null &&
                  mangopayUbo.Nationality !== ''
                    ? nationalityTypeObj[mangopayUbo.Nationality]
                    : {value: '', text: ''}
                }
                values={nationalityTypeOptionsArr}
                title="Narodowość"
                onChange={(v) => handleMangopayUboNationality(v)}
              >
                {mangopayUboError.Nationality ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      'Pole narodowość beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                    }
                  />
                ) : null}
              </Select> */}
              <AccountInfoText>
                Miejsce urodzenia beneficjenta rzeczywistego
              </AccountInfoText>
              <InputViewForData
                notEdit={
                  mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                }
                isError={
                  mangopayUboError.ShowErrors && mangopayUboError.Birthday
                }
                isEditing={isEditing}
                style={windowWidth < 1024 ? null : {marginTop: '5px'}}
              >
                <p>Data urodzenia</p>
                {isEditing ? (
                  <input
                    type="date"
                    id="Birthday"
                    placeholder=""
                    readOnly={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                        ? true
                        : !isEditing
                    }
                    value={mangopayUbo.Birthday}
                    onChange={handleMangopayUboChange}
                  />
                ) : (
                  <span>
                    {' '}
                    {new Intl.DateTimeFormat('default', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    })
                      .format(new Date(mangopayUbo.Birthday))
                      .replace(',', '')}
                  </span>
                )}
                {mangopayUboError.Birthday ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      'Pole data urodzenia beneficjenta jest obowiązkowe, minimum 18 lat. Prosimy poprawnie uzupełnić dane.'
                    }
                  />
                ) : null}
              </InputViewForData>
              <TwoSmallInputsWrapper>
                <InputView
                  notEdit={
                    mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                  }
                  isError={
                    mangopayUboError.ShowErrors &&
                    mangopayUboError.Birthplace.City
                  }
                  isEditing={isEditing}
                  isMediumInput={true}
                  style={
                    windowWidth < 374
                      ? {width: '141px', marginTop: '0px'}
                      : windowWidth < 400
                      ? {width: '155px', marginTop: '0px'}
                      : windowWidth < 1024
                      ? {width: '183px', marginTop: '0px'}
                      : {
                          width: '50%',
                          marginTop: '5px',
                        }
                  }
                >
                  <p>Miasto</p>
                  <input
                    style={{paddingLeft: '70px'}}
                    type="text"
                    placeholder=""
                    id="City"
                    readOnly={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                        ? true
                        : !isEditing
                    }
                    value={mangopayUbo.Birthplace.City}
                    onChange={handleMangopayUboBirthplaceChange}
                  />
                  {mangopayUboError.Birthplace.City ? (
                    <ErrorStar
                      fixed={true}
                      text={
                        'Pole miasto urodzenia beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                      }
                    />
                  ) : null}
                </InputView>
                <StyledSelectView
                  style={
                    windowWidth < 374
                      ? {width: '135px', marginTop: '0px', marginLeft: '10px'}
                      : windowWidth < 400
                      ? {width: '150px', marginTop: '0px', marginLeft: '10px'}
                      : windowWidth < 1024
                      ? {width: '178px', marginTop: '0px', marginLeft: '10px'}
                      : {
                          width: '159px',
                          marginTop: '5px',
                        }
                  }
                  isError={
                    mangopayUboError.ShowErrors &&
                    mangopayUboError.Birthplace.Country
                  }
                  isEditing={isEditing}
                >
                  <p>Kraj</p>
                  <select
                    disabled={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                    }
                    value={mangopayUbo.Birthplace.Country}
                    onChange={handleMangopayUboBirthplaceCountry}
                  >
                    {' '}
                    <option hidden value={''}>
                      Wybierz
                    </option>
                    {countries.map((item) => (
                      <option key={item.FlagValue} value={item.FieldName}>
                        {item.DisplayValue}
                      </option>
                    ))}
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                  {mangopayUboError.Birthplace.Country ? (
                    <ErrorStar
                      fixed={true}
                      text={
                        'Pole kraj urodzenia beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                      }
                    />
                  ) : null}
                </StyledSelectView>
              </TwoSmallInputsWrapper>
              {/* <Select
                notEdit={
                  mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                }
                isError={
                  mangopayUboError.ShowErrors &&
                  mangopayUboError.Birthplace.Country
                }
                selectWidth={
                  windowWidth <= 340
                    ? '90%'
                    : windowWidth <= 400
                    ? '311px'
                    : windowWidth <= 1024
                    ? '358px'
                    : '159px'
                }
                customStyles={{
                  width:
                    windowWidth <= 340
                      ? '90%'
                      : windowWidth <= 400
                      ? '311px'
                      : windowWidth <= 1024
                      ? '358px'
                      : '159px',
                  marginTop: '5px',
                }}
                isEditing={isEditing}
                value={
                  mangopayUbo.Birthplace.Country !== null &&
                  mangopayUbo.Birthplace.Country !== ''
                    ? nationalityTypeObj[mangopayUbo.Birthplace.Country]
                    : {value: '', text: ''}
                }
                values={nationalityTypeOptionsArr}
                title="Kraj"
                onChange={(v) => handleMangopayUboBirthplaceCountry(v)}
              >
                {mangopayUboError.Birthplace.Country ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      'Pole kraj urodzin beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                    }
                  />
                ) : null}
              </Select> */}
              <AccountInfoText>
                Adres beneficjenta rzeczywistego
              </AccountInfoText>
              <InputView
                notEdit={
                  mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                }
                isError={
                  mangopayUboError.ShowErrors &&
                  mangopayUboError.Address.AddressLine1
                }
                isEditing={isEditing}
                style={windowWidth < 1024 ? null : {marginTop: '5px'}}
              >
                <p>Ulica</p>
                <input
                  type="text"
                  placeholder=""
                  id="AddressLine1"
                  readOnly={
                    mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                      ? true
                      : !isEditing
                  }
                  value={mangopayUbo.Address.AddressLine1}
                  onChange={handleMangopayUboAddressChange}
                />
                {mangopayUboError.Address.AddressLine1 ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      'Pole ulica beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                    }
                  />
                ) : null}
              </InputView>
              <TwoSmallInputsWrapper>
                <InputView
                  notEdit={
                    mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                  }
                  isError={
                    mangopayUboError.ShowErrors &&
                    mangopayUboError.Address.FlatNumber
                  }
                  isEditing={isEditing}
                  isMediumInput={true}
                  style={
                    windowWidth < 374
                      ? {width: '141px', marginTop: '0px'}
                      : windowWidth < 400
                      ? {width: '155px', marginTop: '0px'}
                      : windowWidth < 1024
                      ? {width: '183px', marginTop: '0px'}
                      : {
                          width: '50%',
                          marginTop: '5px',
                        }
                  }
                >
                  <p>Nr budynku</p>
                  <input
                    type="text"
                    placeholder=""
                    id="FlatNumber"
                    readOnly={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                        ? true
                        : !isEditing
                    }
                    value={mangopayUbo.Address.FlatNumber}
                    onChange={handleMangopayUboAddressChange}
                  />
                  {mangopayUboError.Address.FlatNumber ? (
                    <ErrorStar
                      fixed={true}
                      text={
                        'Pole numer bloku beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                      }
                    />
                  ) : null}
                </InputView>
                <InputView
                  notEdit={
                    mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                  }
                  isEditing={isEditing}
                  isSmallInput={true}
                  style={
                    windowWidth < 374
                      ? {width: '135px', marginTop: '0px', marginLeft: '10px'}
                      : windowWidth < 400
                      ? {width: '150px', marginTop: '0px', marginLeft: '10px'}
                      : windowWidth < 1024
                      ? {width: '178px', marginTop: '0px', marginLeft: '10px'}
                      : {
                          width: '159px',
                          marginTop: '5px',
                        }
                  }
                >
                  <p>Nr lokalu</p>
                  <input
                    type="text"
                    placeholder=""
                    id="BuildNumber"
                    readOnly={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                        ? true
                        : !isEditing
                    }
                    value={mangopayUbo.Address.BuildNumber}
                    onChange={handleMangopayUboAddressChange}
                  />
                </InputView>
              </TwoSmallInputsWrapper>
              <TwoSmallInputsWrapper>
                <InputView
                  notEdit={
                    mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                  }
                  isError={
                    mangopayUboError.ShowErrors &&
                    mangopayUboError.Address.PostalCode
                  }
                  isEditing={isEditing}
                  isMediumInput={true}
                  style={
                    windowWidth < 374
                      ? {width: '141px', marginTop: '0px'}
                      : windowWidth < 400
                      ? {width: '155px', marginTop: '0px'}
                      : windowWidth < 1024
                      ? {width: '183px', marginTop: '0px'}
                      : {
                          width: '50%',
                          marginTop: '5px',
                        }
                  }
                >
                  <p>Kod pocztowy</p>
                  <input
                    style={{paddingLeft: '70px'}}
                    type="text"
                    placeholder=""
                    id="PostalCode"
                    maxLength={6}
                    readOnly={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                        ? true
                        : !isEditing
                    }
                    value={mangopayUbo.Address.PostalCode}
                    onChange={handleUboAddressPostalCodeChange}
                  />
                  {mangopayUboError.Address.PostalCode ? (
                    <ErrorStar
                      fixed={true}
                      text={
                        'Pole kod pocztowy beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                      }
                    />
                  ) : null}
                </InputView>
                <InputView
                  notEdit={
                    mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                  }
                  isError={
                    mangopayUboError.ShowErrors && mangopayUboError.Address.City
                  }
                  isEditing={isEditing}
                  isSmallInput={true}
                  style={
                    windowWidth < 374
                      ? {width: '135px', marginTop: '0px', marginLeft: '10px'}
                      : windowWidth < 400
                      ? {width: '150px', marginTop: '0px', marginLeft: '10px'}
                      : windowWidth < 1024
                      ? {width: '178px', marginTop: '0px', marginLeft: '10px'}
                      : {
                          width: '159px',
                          marginTop: '5px',
                        }
                  }
                >
                  <p>Miasto</p>
                  <input
                    style={{paddingLeft: '70px'}}
                    type="text"
                    placeholder=""
                    id="City"
                    readOnly={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                        ? true
                        : !isEditing
                    }
                    value={mangopayUbo.Address.City}
                    onChange={handleMangopayUboAddressChange}
                  />
                  {mangopayUboError.Address.City ? (
                    <ErrorStar
                      fixed={true}
                      text={
                        'Pole miasto beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                      }
                    />
                  ) : null}
                </InputView>
              </TwoSmallInputsWrapper>
              <StyledSelectView
                style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                isError={
                  mangopayUboError.ShowErrors &&
                  mangopayUboError.Address.Country
                }
                isEditing={isEditing}
              >
                <p>Kraj</p>
                <select
                  disabled={
                    mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                  }
                  value={mangopayUbo.Address.Country}
                  onChange={handleMangopayUboAddressCountry}
                >
                  {' '}
                  <option hidden value={''}>
                    Wybierz
                  </option>
                  {countries.map((item) => (
                    <option key={item.FlagValue} value={item.FieldName}>
                      {item.DisplayValue}
                    </option>
                  ))}
                </select>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.439"
                  height="11"
                  viewBox="0 0 6.439 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(6.439 11) rotate(180)"
                  >
                    <path
                      id="Arrow"
                      d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                      transform="translate(0 0)"
                      fill="#3986ef"
                    />
                  </g>
                </svg>
                {mangopayUboError.Address.Country ? (
                  <ErrorStar
                    text={
                      'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                    }
                  />
                ) : null}
              </StyledSelectView>
              {/* <Select
                notEdit={
                  mangopayAccount.UboDeclaration.Status === 'VALIDATION_ASKED'
                }
                isError={
                  mangopayUboError.ShowErrors &&
                  mangopayUboError.Address.Country
                }
                selectWidth={
                  windowWidth <= 340
                    ? '90%'
                    : windowWidth <= 400
                    ? '311px'
                    : windowWidth <= 1024
                    ? '358px'
                    : '100%'
                }
                customStyles={{
                  width:
                    windowWidth <= 340
                      ? '90%'
                      : windowWidth <= 400
                      ? '311px'
                      : windowWidth <= 1024
                      ? '358px'
                      : '100%',
                  marginTop: '5px',
                }}
                isEditing={isEditing}
                value={
                  mangopayUbo.Address.Country !== null &&
                  mangopayUbo.Address.Country !== ''
                    ? nationalityTypeObj[mangopayUbo.Address.Country]
                    : {value: '', text: ''}
                }
                values={nationalityTypeOptionsArr}
                title="Kraj"
                onChange={(v) => handleMangopayUboAddressCountry(v)}
              >
                {' '}
                {mangopayUboError.Address.Country ? (
                  <ErrorStar
                    text={
                      'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                    }
                  />
                ) : null}
              </Select> */}
              {mangopayAccount.UboDeclaration.Status ===
              'VALIDATION_ASKED' ? null : isEditing ? (
                <AccountBoxWrapper style={{marginBottom: '10px'}}>
                  <AccountBoxText>
                    <p>Dodaj kolejnego beneficjenta</p>
                    <span>Beneficjent numer dwa</span>
                  </AccountBoxText>
                  <AccountBox
                    isActive={addUboTwo}
                    onClick={handlUboPersonTwoRegulationsChange}
                  >
                    <BlueBox isActive={addUboTwo} />
                  </AccountBox>
                </AccountBoxWrapper>
              ) : null}
              {addUboTwo ? (
                <>
                  <AccountInfoText>
                    Dane beneficjenta rzeczywistego
                  </AccountInfoText>
                  <InputView
                    notEdit={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                    }
                    isError={
                      uboPersonTwoError.ShowErrors &&
                      uboPersonTwoError.FirstName
                    }
                    isEditing={isEditing}
                    style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                  >
                    <p>Imię</p>
                    <input
                      type="text"
                      placeholder=""
                      id="FirstName"
                      readOnly={
                        mangopayAccount.UboDeclaration.Status ===
                        'VALIDATION_ASKED'
                          ? true
                          : !isEditing
                      }
                      value={uboPersonTwo.FirstName}
                      onChange={handleUboPersonTwoChange}
                    />
                    {uboPersonTwoError.FirstName ? (
                      <ErrorStar
                        fixed={true}
                        text={
                          'Pole imię beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                        }
                      />
                    ) : null}
                  </InputView>
                  <InputView
                    notEdit={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                    }
                    isError={
                      uboPersonTwoError.ShowErrors && uboPersonTwoError.LastName
                    }
                    isEditing={isEditing}
                    style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                  >
                    <p>Nazwisko</p>
                    <input
                      type="text"
                      placeholder=""
                      id="LastName"
                      readOnly={
                        mangopayAccount.UboDeclaration.Status ===
                        'VALIDATION_ASKED'
                          ? true
                          : !isEditing
                      }
                      value={uboPersonTwo.LastName}
                      onChange={handleUboPersonTwoChange}
                    />
                    {uboPersonTwoError.LastName ? (
                      <ErrorStar
                        fixed={true}
                        text={
                          'Pole nazwisko beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                        }
                      />
                    ) : null}
                  </InputView>
                  <StyledSelectView
                    style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                    isError={
                      uboPersonTwoError.ShowErrors &&
                      uboPersonTwoError.Nationality
                    }
                    isEditing={isEditing}
                  >
                    <p>Obywatelstwo</p>
                    <select
                      disabled={
                        mangopayAccount.UboDeclaration.Status ===
                        'VALIDATION_ASKED'
                      }
                      value={uboPersonTwo.Nationality}
                      onChange={handleUboPersonTwoNationality}
                    >
                      {' '}
                      <option hidden value={''}>
                        Wybierz
                      </option>
                      {nationalities.map((item) => (
                        <option key={item.FlagValue} value={item.FieldName}>
                          {item.DisplayValue}
                        </option>
                      ))}
                    </select>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.439"
                      height="11"
                      viewBox="0 0 6.439 11"
                    >
                      <g
                        id="Arrow_Top_Bar_Accent"
                        data-name="Arrow – Top Bar / Accent"
                        transform="translate(6.439 11) rotate(180)"
                      >
                        <path
                          id="Arrow"
                          d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                          transform="translate(0 0)"
                          fill="#3986ef"
                        />
                      </g>
                    </svg>
                    {uboPersonTwoError.Nationality ? (
                      <ErrorStar
                        fixed={true}
                        text={
                          'Pole Obywatelstwo beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                        }
                      />
                    ) : null}
                  </StyledSelectView>
                  {/* <Select
                    notEdit={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                    }
                    isError={
                      uboPersonTwoError.ShowErrors &&
                      uboPersonTwoError.Nationality
                    }
                    selectWidth={
                      windowWidth <= 340
                        ? '90%'
                        : windowWidth <= 400
                        ? '311px'
                        : windowWidth <= 1024
                        ? '358px'
                        : '100%'
                    }
                    customStyles={{
                      width:
                        windowWidth <= 340
                          ? '90%'
                          : windowWidth <= 400
                          ? '311px'
                          : windowWidth <= 1024
                          ? '358px'
                          : '100%',
                      marginTop: '5px',
                    }}
                    isEditing={isEditing}
                    value={
                      uboPersonTwo.Nationality !== null &&
                      uboPersonTwo.Nationality !== ''
                        ? nationalityTypeObj[uboPersonTwo.Nationality]
                        : {value: '', text: ''}
                    }
                    values={nationalityTypeOptionsArr}
                    title="Narodowość"
                    onChange={(v) => handleUboPersonTwoNationality(v)}
                  >
                    {uboPersonTwoError.Nationality ? (
                      <ErrorStar
                        fixed={true}
                        text={
                          'Pole narodowość beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                        }
                      />
                    ) : null}
                  </Select> */}
                  <AccountInfoText>
                    Miejsce urodzenia beneficjenta rzeczywistego
                  </AccountInfoText>
                  <InputViewForData
                    notEdit={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                    }
                    isError={
                      uboPersonTwoError.ShowErrors && uboPersonTwoError.Birthday
                    }
                    isEditing={isEditing}
                    style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                  >
                    <p>Data urodzenia</p>
                    {isEditing ? (
                      <input
                        type="date"
                        id="Birthday"
                        placeholder=""
                        readOnly={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                            ? true
                            : !isEditing
                        }
                        value={uboPersonTwo.Birthday}
                        onChange={handleUboPersonTwoChange}
                      />
                    ) : (
                      <span>
                        {' '}
                        {new Intl.DateTimeFormat('default', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        })
                          .format(new Date(uboPersonTwo.Birthday))
                          .replace(',', '')}
                      </span>
                    )}
                    {uboPersonTwoError.Birthday ? (
                      <ErrorStar
                        fixed={true}
                        text={
                          'Pole data urodzenia beneficjenta jest obowiązkowe, minimum 18 lat. Prosimy poprawnie uzupełnić dane.'
                        }
                      />
                    ) : null}
                  </InputViewForData>
                  <TwoSmallInputsWrapper>
                    <InputView
                      notEdit={
                        mangopayAccount.UboDeclaration.Status ===
                        'VALIDATION_ASKED'
                      }
                      isError={
                        uboPersonTwoError.ShowErrors &&
                        uboPersonTwoError.Birthplace.City
                      }
                      isEditing={isEditing}
                      isMediumInput={true}
                      style={
                        windowWidth < 374
                          ? {width: '141px', marginTop: '0px'}
                          : windowWidth < 400
                          ? {width: '155px', marginTop: '0px'}
                          : windowWidth < 1024
                          ? {width: '183px', marginTop: '0px'}
                          : {
                              width: '50%',
                              marginTop: '5px',
                            }
                      }
                    >
                      <p>Miasto</p>
                      <input
                        style={{paddingLeft: '70px'}}
                        type="text"
                        placeholder=""
                        id="City"
                        readOnly={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                            ? true
                            : !isEditing
                        }
                        value={uboPersonTwo.Birthplace.City}
                        onChange={handleUboPersonTwoBirthplaceChange}
                      />
                      {uboPersonTwoError.Birthplace.City ? (
                        <ErrorStar
                          fixed={true}
                          text={
                            'Pole miasto urodzenia beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                          }
                        />
                      ) : null}
                    </InputView>
                    <StyledSelectView
                      style={
                        windowWidth < 374
                          ? {
                              width: '135px',
                              marginTop: '0px',
                              marginLeft: '10px',
                            }
                          : windowWidth < 400
                          ? {
                              width: '150px',
                              marginTop: '0px',
                              marginLeft: '10px',
                            }
                          : windowWidth < 1024
                          ? {
                              width: '178px',
                              marginTop: '0px',
                              marginLeft: '10px',
                            }
                          : {
                              width: '159px',
                              marginTop: '5px',
                            }
                      }
                      isError={
                        uboPersonTwoError.ShowErrors &&
                        uboPersonTwoError.Birthplace.Country
                      }
                      isEditing={isEditing}
                    >
                      <p>Kraj</p>
                      <select
                        disabled={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                        }
                        value={uboPersonTwo.Birthplace.Country}
                        onChange={handleUboPersonTwoBirthplaceCountry}
                      >
                        {' '}
                        <option hidden value={''}>
                          Wybierz
                        </option>
                        {countries.map((item) => (
                          <option key={item.FlagValue} value={item.FieldName}>
                            {item.DisplayValue}
                          </option>
                        ))}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                      {uboPersonTwoError.Birthplace.Country ? (
                        <ErrorStar
                          fixed={true}
                          text={
                            'Pole kraj urodzin beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                          }
                        />
                      ) : null}
                    </StyledSelectView>
                  </TwoSmallInputsWrapper>
                  {/* <Select
                    notEdit={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                    }
                    isError={
                      uboPersonTwoError.ShowErrors &&
                      uboPersonTwoError.Birthplace.Country
                    }
                    selectWidth={
                      windowWidth <= 340
                        ? '90%'
                        : windowWidth <= 400
                        ? '311px'
                        : windowWidth <= 1024
                        ? '358px'
                        : '159px'
                    }
                    customStyles={{
                      width:
                        windowWidth <= 340
                          ? '90%'
                          : windowWidth <= 400
                          ? '311px'
                          : windowWidth <= 1024
                          ? '358px'
                          : '159px',
                      marginTop: '5px',
                    }}
                    isEditing={isEditing}
                    value={
                      uboPersonTwo.Birthplace.Country !== null &&
                      uboPersonTwo.Birthplace.Country !== ''
                        ? nationalityTypeObj[uboPersonTwo.Birthplace.Country]
                        : {value: '', text: ''}
                    }
                    values={nationalityTypeOptionsArr}
                    title="Kraj"
                    onChange={(v) => handleUboPersonTwoBirthplaceCountry(v)}
                  >
                    {uboPersonTwoError.Birthplace.Country ? (
                      <ErrorStar
                        fixed={true}
                        text={
                          'Pole kraj urodzin beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                        }
                      />
                    ) : null}
                  </Select> */}
                  <AccountInfoText>
                    Adres beneficjenta rzeczywistego
                  </AccountInfoText>
                  <InputView
                    notEdit={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                    }
                    isError={
                      uboPersonTwoError.ShowErrors &&
                      uboPersonTwoError.Address.AddressLine1
                    }
                    isEditing={isEditing}
                    style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                  >
                    <p>Ulica</p>
                    <input
                      type="text"
                      placeholder=""
                      id="AddressLine1"
                      readOnly={
                        mangopayAccount.UboDeclaration.Status ===
                        'VALIDATION_ASKED'
                          ? true
                          : !isEditing
                      }
                      value={uboPersonTwo.Address.AddressLine1}
                      onChange={handleUboPersonTwoAddressChange}
                    />
                    {uboPersonTwoError.Address.AddressLine1 ? (
                      <ErrorStar
                        fixed={true}
                        text={
                          'Pole ulica beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                        }
                      />
                    ) : null}
                  </InputView>
                  <TwoSmallInputsWrapper>
                    <InputView
                      notEdit={
                        mangopayAccount.UboDeclaration.Status ===
                        'VALIDATION_ASKED'
                      }
                      isError={
                        uboPersonTwoError.ShowErrors &&
                        uboPersonTwoError.Address.FlatNumber
                      }
                      isEditing={isEditing}
                      isMediumInput={true}
                      style={
                        windowWidth < 374
                          ? {width: '141px', marginTop: '0px'}
                          : windowWidth < 400
                          ? {width: '155px', marginTop: '0px'}
                          : windowWidth < 1024
                          ? {width: '183px', marginTop: '0px'}
                          : {
                              width: '50%',
                              marginTop: '5px',
                            }
                      }
                    >
                      <p>Nr budynku</p>
                      <input
                        type="text"
                        placeholder=""
                        id="FlatNumber"
                        readOnly={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                            ? true
                            : !isEditing
                        }
                        value={uboPersonTwo.Address.FlatNumber}
                        onChange={handleUboPersonTwoAddressChange}
                      />
                      {uboPersonTwoError.Address.FlatNumber ? (
                        <ErrorStar
                          fixed={true}
                          text={
                            'Pole numer bloku beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                          }
                        />
                      ) : null}
                    </InputView>
                    <InputView
                      notEdit={
                        mangopayAccount.UboDeclaration.Status ===
                        'VALIDATION_ASKED'
                      }
                      isEditing={isEditing}
                      isSmallInput={true}
                      style={
                        windowWidth < 374
                          ? {
                              width: '135px',
                              marginTop: '0px',
                              marginLeft: '10px',
                            }
                          : windowWidth < 400
                          ? {
                              width: '150px',
                              marginTop: '0px',
                              marginLeft: '10px',
                            }
                          : windowWidth < 1024
                          ? {
                              width: '178px',
                              marginTop: '0px',
                              marginLeft: '10px',
                            }
                          : {
                              width: '159px',
                              marginTop: '5px',
                            }
                      }
                    >
                      <p>Nr lokalu</p>
                      <input
                        type="text"
                        placeholder=""
                        id="BuildNumber"
                        readOnly={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                            ? true
                            : !isEditing
                        }
                        value={uboPersonTwo.Address.BuildNumber}
                        onChange={handleUboPersonTwoAddressChange}
                      />
                    </InputView>
                  </TwoSmallInputsWrapper>
                  <TwoSmallInputsWrapper>
                    <InputView
                      notEdit={
                        mangopayAccount.UboDeclaration.Status ===
                        'VALIDATION_ASKED'
                      }
                      isError={
                        uboPersonTwoError.ShowErrors &&
                        uboPersonTwoError.Address.PostalCode
                      }
                      isEditing={isEditing}
                      isMediumInput={true}
                      style={
                        windowWidth < 374
                          ? {width: '141px', marginTop: '0px'}
                          : windowWidth < 400
                          ? {width: '155px', marginTop: '0px'}
                          : windowWidth < 1024
                          ? {width: '183px', marginTop: '0px'}
                          : {
                              width: '50%',
                              marginTop: '5px',
                            }
                      }
                    >
                      <p>Kod pocztowy</p>
                      <input
                        style={{paddingLeft: '70px'}}
                        type="text"
                        placeholder=""
                        id="PostalCode"
                        maxLength={6}
                        readOnly={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                            ? true
                            : !isEditing
                        }
                        value={uboPersonTwo.Address.PostalCode}
                        onChange={handleUboPersonTwoAddressPostalCodeChange}
                      />
                      {uboPersonTwoError.Address.PostalCode ? (
                        <ErrorStar
                          fixed={true}
                          text={
                            'Pole kod pocztowy beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                          }
                        />
                      ) : null}
                    </InputView>
                    <InputView
                      notEdit={
                        mangopayAccount.UboDeclaration.Status ===
                        'VALIDATION_ASKED'
                      }
                      isError={
                        uboPersonTwoError.ShowErrors &&
                        uboPersonTwoError.Address.City
                      }
                      isEditing={isEditing}
                      isSmallInput={true}
                      style={
                        windowWidth < 374
                          ? {
                              width: '135px',
                              marginTop: '0px',
                              marginLeft: '10px',
                            }
                          : windowWidth < 400
                          ? {
                              width: '150px',
                              marginTop: '0px',
                              marginLeft: '10px',
                            }
                          : windowWidth < 1024
                          ? {
                              width: '178px',
                              marginTop: '0px',
                              marginLeft: '10px',
                            }
                          : {
                              width: '159px',
                              marginTop: '5px',
                            }
                      }
                    >
                      <p>Miasto</p>
                      <input
                        style={{paddingLeft: '70px'}}
                        type="text"
                        placeholder=""
                        id="City"
                        readOnly={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                            ? true
                            : !isEditing
                        }
                        value={uboPersonTwo.Address.City}
                        onChange={handleUboPersonTwoAddressChange}
                      />
                      {uboPersonTwoError.Address.City ? (
                        <ErrorStar
                          fixed={true}
                          text={
                            'Pole miasto beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                          }
                        />
                      ) : null}
                    </InputView>
                  </TwoSmallInputsWrapper>
                  <StyledSelectView
                    style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                    isError={
                      uboPersonTwoError.ShowErrors &&
                      uboPersonTwoError.Address.Country
                    }
                    isEditing={isEditing}
                  >
                    <p>Kraj</p>
                    <select
                      disabled={
                        mangopayAccount.UboDeclaration.Status ===
                        'VALIDATION_ASKED'
                      }
                      value={uboPersonTwo.Address.Country}
                      onChange={handleUboPersonTwoAddressCountry}
                    >
                      {' '}
                      <option hidden value={''}>
                        Wybierz
                      </option>
                      {countries.map((item) => (
                        <option key={item.FlagValue} value={item.FieldName}>
                          {item.DisplayValue}
                        </option>
                      ))}
                    </select>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6.439"
                      height="11"
                      viewBox="0 0 6.439 11"
                    >
                      <g
                        id="Arrow_Top_Bar_Accent"
                        data-name="Arrow – Top Bar / Accent"
                        transform="translate(6.439 11) rotate(180)"
                      >
                        <path
                          id="Arrow"
                          d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                          transform="translate(0 0)"
                          fill="#3986ef"
                        />
                      </g>
                    </svg>
                    {uboPersonTwoError.Address.Country ? (
                      <ErrorStar
                        text={
                          'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                        }
                      />
                    ) : null}
                  </StyledSelectView>
                  {/* <Select
                    notEdit={
                      mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED'
                    }
                    isError={
                      uboPersonTwoError.ShowErrors &&
                      uboPersonTwoError.Address.Country
                    }
                    selectWidth={
                      windowWidth <= 340
                        ? '90%'
                        : windowWidth <= 400
                        ? '311px'
                        : windowWidth <= 1024
                        ? '358px'
                        : '100%'
                    }
                    customStyles={{
                      width:
                        windowWidth <= 340
                          ? '90%'
                          : windowWidth <= 400
                          ? '311px'
                          : windowWidth <= 1024
                          ? '358px'
                          : '100%',
                      marginTop: '5px',
                    }}
                    isEditing={isEditing}
                    value={
                      uboPersonTwo.Address.Country !== null &&
                      uboPersonTwo.Address.Country !== ''
                        ? nationalityTypeObj[uboPersonTwo.Address.Country]
                        : {value: '', text: ''}
                    }
                    values={nationalityTypeOptionsArr}
                    title="Kraj"
                    onChange={(v) => handleUboPersonTwoAddressCountry(v)}
                  >
                    {' '}
                    {uboPersonTwoError.Address.Country ? (
                      <ErrorStar
                        text={
                          'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                        }
                      />
                    ) : null}
                  </Select> */}
                  {mangopayAccount.UboDeclaration.Status ===
                  'VALIDATION_ASKED' ? null : isEditing ? (
                    <AccountBoxWrapper style={{marginBottom: '10px'}}>
                      <AccountBoxText>
                        <p>Dodaj kolejnego beneficjenta</p>
                        <span>Beneficjent numer trzy</span>
                      </AccountBoxText>
                      <AccountBox
                        isActive={addUboThree}
                        onClick={handlUboPersonThreeRegulationsChange}
                      >
                        <BlueBox isActive={addUboThree} />
                      </AccountBox>
                    </AccountBoxWrapper>
                  ) : null}
                  {addUboThree && addUboTwo ? (
                    <>
                      <AccountInfoText>
                        Dane beneficjenta rzeczywistego
                      </AccountInfoText>
                      <InputView
                        notEdit={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                        }
                        isError={
                          uboPersonThreeError.ShowErrors &&
                          uboPersonThreeError.FirstName
                        }
                        isEditing={isEditing}
                        style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                      >
                        <p>Imię</p>
                        <input
                          type="text"
                          placeholder=""
                          id="FirstName"
                          readOnly={
                            mangopayAccount.UboDeclaration.Status ===
                            'VALIDATION_ASKED'
                              ? true
                              : !isEditing
                          }
                          value={uboPersonThree.FirstName}
                          onChange={handleUboPersonThreeChange}
                        />
                        {uboPersonThreeError.FirstName ? (
                          <ErrorStar
                            fixed={true}
                            text={
                              'Pole imię beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                            }
                          />
                        ) : null}
                      </InputView>
                      <InputView
                        notEdit={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                        }
                        isError={
                          uboPersonThreeError.ShowErrors &&
                          uboPersonThreeError.LastName
                        }
                        isEditing={isEditing}
                        style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                      >
                        <p>Nazwisko</p>
                        <input
                          type="text"
                          placeholder=""
                          id="LastName"
                          readOnly={
                            mangopayAccount.UboDeclaration.Status ===
                            'VALIDATION_ASKED'
                              ? true
                              : !isEditing
                          }
                          value={uboPersonThree.LastName}
                          onChange={handleUboPersonThreeChange}
                        />
                        {uboPersonThreeError.LastName ? (
                          <ErrorStar
                            fixed={true}
                            text={
                              'Pole nazwisko beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                            }
                          />
                        ) : null}
                      </InputView>
                      <StyledSelectView
                        style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                        isError={
                          uboPersonThreeError.ShowErrors &&
                          uboPersonThreeError.Nationality
                        }
                        isEditing={isEditing}
                      >
                        <p>Obywatelstwo</p>
                        <select
                          disabled={
                            mangopayAccount.UboDeclaration.Status ===
                            'VALIDATION_ASKED'
                          }
                          value={uboPersonThree.Nationality}
                          onChange={handleUboPersonThreeNationality}
                        >
                          {' '}
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          {nationalities.map((item) => (
                            <option key={item.FlagValue} value={item.FieldName}>
                              {item.DisplayValue}
                            </option>
                          ))}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                        {uboPersonThreeError.Nationality ? (
                          <ErrorStar
                            fixed={true}
                            text={
                              'Pole Obywatelstwo beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                            }
                          />
                        ) : null}
                      </StyledSelectView>
                      {/* <Select
                        notEdit={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                        }
                        isError={
                          uboPersonThreeError.ShowErrors &&
                          uboPersonThreeError.Nationality
                        }
                        selectWidth={
                          windowWidth <= 340
                            ? '90%'
                            : windowWidth <= 400
                            ? '311px'
                            : windowWidth <= 1024
                            ? '358px'
                            : '100%'
                        }
                        customStyles={{
                          width:
                            windowWidth <= 340
                              ? '90%'
                              : windowWidth <= 400
                              ? '311px'
                              : windowWidth <= 1024
                              ? '358px'
                              : '100%',
                          marginTop: '5px',
                        }}
                        isEditing={isEditing}
                        value={
                          uboPersonThree.Nationality !== null &&
                          uboPersonThree.Nationality !== ''
                            ? nationalityTypeObj[uboPersonThree.Nationality]
                            : {value: '', text: ''}
                        }
                        values={nationalityTypeOptionsArr}
                        title="Narodowość"
                        onChange={(v) => handleUboPersonThreeNationality(v)}
                      >
                        {uboPersonThreeError.Nationality ? (
                          <ErrorStar
                            fixed={true}
                            text={
                              'Pole narodowość beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                            }
                          />
                        ) : null}
                      </Select> */}
                      <AccountInfoText>
                        Miejsce urodzenia beneficjenta rzeczywistego
                      </AccountInfoText>
                      <InputViewForData
                        notEdit={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                        }
                        isError={
                          uboPersonThreeError.ShowErrors &&
                          uboPersonThreeError.Birthday
                        }
                        isEditing={isEditing}
                        style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                      >
                        <p>Data urodzenia</p>
                        {isEditing ? (
                          <input
                            type="date"
                            id="Birthday"
                            placeholder=""
                            readOnly={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                                ? true
                                : !isEditing
                            }
                            value={uboPersonThree.Birthday}
                            onChange={handleUboPersonThreeChange}
                          />
                        ) : (
                          <span>
                            {' '}
                            {new Intl.DateTimeFormat('default', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })
                              .format(new Date(uboPersonThree.Birthday))
                              .replace(',', '')}
                          </span>
                        )}
                        {uboPersonThreeError.Birthday ? (
                          <ErrorStar
                            fixed={true}
                            text={
                              'Pole data urodzenia beneficjenta jest obowiązkowe, minimum 18 lat. Prosimy poprawnie uzupełnić dane.'
                            }
                          />
                        ) : null}
                      </InputViewForData>
                      <TwoSmallInputsWrapper>
                        <InputView
                          notEdit={
                            mangopayAccount.UboDeclaration.Status ===
                            'VALIDATION_ASKED'
                          }
                          isError={
                            uboPersonThreeError.ShowErrors &&
                            uboPersonThreeError.Birthplace.City
                          }
                          isEditing={isEditing}
                          isMediumInput={true}
                          style={
                            windowWidth < 374
                              ? {width: '141px', marginTop: '0px'}
                              : windowWidth < 400
                              ? {width: '155px', marginTop: '0px'}
                              : windowWidth < 1024
                              ? {width: '183px', marginTop: '0px'}
                              : {
                                  width: '50%',
                                  marginTop: '5px',
                                }
                          }
                        >
                          <p>Miasto</p>
                          <input
                            style={{paddingLeft: '70px'}}
                            type="text"
                            placeholder=""
                            id="City"
                            readOnly={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                                ? true
                                : !isEditing
                            }
                            value={uboPersonThree.Birthplace.City}
                            onChange={handleUboPersonThreeBirthplaceChange}
                          />
                          {uboPersonThreeError.Birthplace.City ? (
                            <ErrorStar
                              fixed={true}
                              text={
                                'Pole miasto urodzenia beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                              }
                            />
                          ) : null}
                        </InputView>
                        <StyledSelectView
                          style={
                            windowWidth < 374
                              ? {
                                  width: '135px',
                                  marginTop: '0px',
                                  marginLeft: '10px',
                                }
                              : windowWidth < 400
                              ? {
                                  width: '150px',
                                  marginTop: '0px',
                                  marginLeft: '10px',
                                }
                              : windowWidth < 1024
                              ? {
                                  width: '178px',
                                  marginTop: '0px',
                                  marginLeft: '10px',
                                }
                              : {
                                  width: '159px',
                                  marginTop: '5px',
                                }
                          }
                          isError={
                            uboPersonThreeError.ShowErrors &&
                            uboPersonThreeError.Birthplace.Country
                          }
                          isEditing={isEditing}
                        >
                          <p>Kraj</p>
                          <select
                            disabled={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                            }
                            value={uboPersonThree.Birthplace.Country}
                            onChange={handleUboPersonThreeBirthplaceCountry}
                          >
                            {' '}
                            <option hidden value={''}>
                              Wybierz
                            </option>
                            {countries.map((item) => (
                              <option
                                key={item.FlagValue}
                                value={item.FieldName}
                              >
                                {item.DisplayValue}
                              </option>
                            ))}
                          </select>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="6.439"
                            height="11"
                            viewBox="0 0 6.439 11"
                          >
                            <g
                              id="Arrow_Top_Bar_Accent"
                              data-name="Arrow – Top Bar / Accent"
                              transform="translate(6.439 11) rotate(180)"
                            >
                              <path
                                id="Arrow"
                                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                transform="translate(0 0)"
                                fill="#3986ef"
                              />
                            </g>
                          </svg>
                          {uboPersonThreeError.Birthplace.Country ? (
                            <ErrorStar
                              fixed={true}
                              text={
                                'Pole kraj urodzin beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                              }
                            />
                          ) : null}
                        </StyledSelectView>
                      </TwoSmallInputsWrapper>
                      {/* <Select
                        notEdit={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                        }
                        isError={
                          uboPersonThreeError.ShowErrors &&
                          uboPersonThreeError.Birthplace.Country
                        }
                        selectWidth={
                          windowWidth <= 340
                            ? '90%'
                            : windowWidth <= 400
                            ? '311px'
                            : windowWidth <= 1024
                            ? '358px'
                            : '100%'
                        }
                        customStyles={{
                          width:
                            windowWidth <= 340
                              ? '90%'
                              : windowWidth <= 400
                              ? '311px'
                              : windowWidth <= 1024
                              ? '358px'
                              : '100%',
                          marginTop: '5px',
                        }}
                        isEditing={isEditing}
                        value={
                          uboPersonThree.Birthplace.Country !== null &&
                          uboPersonThree.Birthplace.Country !== ''
                            ? nationalityTypeObj[
                                uboPersonThree.Birthplace.Country
                              ]
                            : {value: '', text: ''}
                        }
                        values={nationalityTypeOptionsArr}
                        title="Kraj"
                        onChange={(v) =>
                          handleUboPersonThreeBirthplaceCountry(v)
                        }
                      >
                        {uboPersonThreeError.Birthplace.Country ? (
                          <ErrorStar
                            fixed={true}
                            text={
                              'Pole kraj urodzin beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                            }
                          />
                        ) : null}
                      </Select> */}
                      <AccountInfoText>
                        Adres beneficjenta rzeczywistego
                      </AccountInfoText>
                      <InputView
                        notEdit={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                        }
                        isError={
                          uboPersonThreeError.ShowErrors &&
                          uboPersonThreeError.Address.AddressLine1
                        }
                        isEditing={isEditing}
                        style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                      >
                        <p>Ulica</p>
                        <input
                          type="text"
                          placeholder=""
                          id="AddressLine1"
                          readOnly={
                            mangopayAccount.UboDeclaration.Status ===
                            'VALIDATION_ASKED'
                              ? true
                              : !isEditing
                          }
                          value={uboPersonThree.Address.AddressLine1}
                          onChange={handleUboPersonThreeAddressChange}
                        />
                        {uboPersonThreeError.Address.AddressLine1 ? (
                          <ErrorStar
                            fixed={true}
                            text={
                              'Pole ulica beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                            }
                          />
                        ) : null}
                      </InputView>
                      <TwoSmallInputsWrapper>
                        <InputView
                          notEdit={
                            mangopayAccount.UboDeclaration.Status ===
                            'VALIDATION_ASKED'
                          }
                          isError={
                            uboPersonThreeError.ShowErrors &&
                            uboPersonThreeError.Address.FlatNumber
                          }
                          isEditing={isEditing}
                          isMediumInput={true}
                          style={
                            windowWidth < 374
                              ? {width: '141px', marginTop: '0px'}
                              : windowWidth < 400
                              ? {width: '155px', marginTop: '0px'}
                              : windowWidth < 1024
                              ? {width: '183px', marginTop: '0px'}
                              : {
                                  width: '50%',
                                  marginTop: '5px',
                                }
                          }
                        >
                          <p>Nr budynku</p>
                          <input
                            type="text"
                            placeholder=""
                            id="FlatNumber"
                            readOnly={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                                ? true
                                : !isEditing
                            }
                            value={uboPersonThree.Address.FlatNumber}
                            onChange={handleUboPersonThreeAddressChange}
                          />
                          {uboPersonThreeError.Address.FlatNumber ? (
                            <ErrorStar
                              fixed={true}
                              text={
                                'Pole numer bloku beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                              }
                            />
                          ) : null}
                        </InputView>
                        <InputView
                          notEdit={
                            mangopayAccount.UboDeclaration.Status ===
                            'VALIDATION_ASKED'
                          }
                          isEditing={isEditing}
                          isSmallInput={true}
                          style={
                            windowWidth < 374
                              ? {
                                  width: '135px',
                                  marginTop: '0px',
                                  marginLeft: '10px',
                                }
                              : windowWidth < 400
                              ? {
                                  width: '150px',
                                  marginTop: '0px',
                                  marginLeft: '10px',
                                }
                              : windowWidth < 1024
                              ? {
                                  width: '178px',
                                  marginTop: '0px',
                                  marginLeft: '10px',
                                }
                              : {
                                  width: '159px',
                                  marginTop: '5px',
                                }
                          }
                        >
                          <p>Nr lokalu</p>
                          <input
                            type="text"
                            placeholder=""
                            id="BuildNumber"
                            readOnly={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                                ? true
                                : !isEditing
                            }
                            value={uboPersonThree.Address.BuildNumber}
                            onChange={handleUboPersonThreeAddressChange}
                          />
                        </InputView>
                      </TwoSmallInputsWrapper>
                      <TwoSmallInputsWrapper>
                        <InputView
                          notEdit={
                            mangopayAccount.UboDeclaration.Status ===
                            'VALIDATION_ASKED'
                          }
                          isError={
                            uboPersonThreeError.ShowErrors &&
                            uboPersonThreeError.Address.PostalCode
                          }
                          isEditing={isEditing}
                          isMediumInput={true}
                          style={
                            windowWidth < 374
                              ? {width: '141px', marginTop: '0px'}
                              : windowWidth < 400
                              ? {width: '155px', marginTop: '0px'}
                              : windowWidth < 1024
                              ? {width: '183px', marginTop: '0px'}
                              : {
                                  width: '50%',
                                  marginTop: '5px',
                                }
                          }
                        >
                          <p>Kod pocztowy</p>
                          <input
                            style={{paddingLeft: '70px'}}
                            type="text"
                            placeholder=""
                            id="PostalCode"
                            maxLength={6}
                            readOnly={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                                ? true
                                : !isEditing
                            }
                            value={uboPersonThree.Address.PostalCode}
                            onChange={
                              handleUboPersonThreeAddressPostalCodeChange
                            }
                          />
                          {uboPersonThreeError.Address.PostalCode ? (
                            <ErrorStar
                              fixed={true}
                              text={
                                'Pole kod pocztowy beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                              }
                            />
                          ) : null}
                        </InputView>
                        <InputView
                          notEdit={
                            mangopayAccount.UboDeclaration.Status ===
                            'VALIDATION_ASKED'
                          }
                          isError={
                            uboPersonThreeError.ShowErrors &&
                            uboPersonThreeError.Address.City
                          }
                          isEditing={isEditing}
                          isSmallInput={true}
                          style={
                            windowWidth < 374
                              ? {
                                  width: '135px',
                                  marginTop: '0px',
                                  marginLeft: '10px',
                                }
                              : windowWidth < 400
                              ? {
                                  width: '150px',
                                  marginTop: '0px',
                                  marginLeft: '10px',
                                }
                              : windowWidth < 1024
                              ? {
                                  width: '178px',
                                  marginTop: '0px',
                                  marginLeft: '10px',
                                }
                              : {
                                  width: '159px',
                                  marginTop: '5px',
                                }
                          }
                        >
                          <p>Miasto</p>
                          <input
                            style={{paddingLeft: '70px'}}
                            type="text"
                            placeholder=""
                            id="City"
                            readOnly={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                                ? true
                                : !isEditing
                            }
                            value={uboPersonThree.Address.City}
                            onChange={handleUboPersonThreeAddressChange}
                          />
                          {uboPersonThreeError.Address.City ? (
                            <ErrorStar
                              fixed={true}
                              text={
                                'Pole miasto beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                              }
                            />
                          ) : null}
                        </InputView>
                      </TwoSmallInputsWrapper>
                      <StyledSelectView
                        style={windowWidth < 1024 ? null : {marginTop: '5px'}}
                        isError={
                          uboPersonThreeError.ShowErrors &&
                          uboPersonThreeError.Address.Country
                        }
                        isEditing={isEditing}
                      >
                        <p>Kraj</p>
                        <select
                          disabled={
                            mangopayAccount.UboDeclaration.Status ===
                            'VALIDATION_ASKED'
                          }
                          value={uboPersonThree.Address.Country}
                          onChange={handleUboPersonThreeAddressCountry}
                        >
                          {' '}
                          <option hidden value={''}>
                            Wybierz
                          </option>
                          {countries.map((item) => (
                            <option key={item.FlagValue} value={item.FieldName}>
                              {item.DisplayValue}
                            </option>
                          ))}
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                        {uboPersonThreeError.Address.Country ? (
                          <ErrorStar
                            text={
                              'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                            }
                          />
                        ) : null}
                      </StyledSelectView>
                      {/* <Select
                        notEdit={
                          mangopayAccount.UboDeclaration.Status ===
                          'VALIDATION_ASKED'
                        }
                        isError={
                          uboPersonThreeError.ShowErrors &&
                          uboPersonThreeError.Address.Country
                        }
                        selectWidth={
                          windowWidth <= 340
                            ? '90%'
                            : windowWidth <= 400
                            ? '311px'
                            : windowWidth <= 1024
                            ? '358px'
                            : '100%'
                        }
                        customStyles={{
                          width:
                            windowWidth <= 340
                              ? '90%'
                              : windowWidth <= 400
                              ? '311px'
                              : windowWidth <= 1024
                              ? '358px'
                              : '100%',
                          marginTop: '5px',
                        }}
                        isEditing={isEditing}
                        value={
                          uboPersonThree.Address.Country !== null &&
                          uboPersonThree.Address.Country !== ''
                            ? nationalityTypeObj[uboPersonThree.Address.Country]
                            : {value: '', text: ''}
                        }
                        values={nationalityTypeOptionsArr}
                        title="Kraj"
                        onChange={(v) => handleUboPersonThreeAddressCountry(v)}
                      >
                        {' '}
                        {uboPersonThreeError.Address.Country ? (
                          <ErrorStar
                            text={
                              'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                            }
                          />
                        ) : null}
                      </Select> */}
                      {mangopayAccount.UboDeclaration.Status ===
                      'VALIDATION_ASKED' ? null : isEditing ? (
                        <AccountBoxWrapper style={{marginBottom: '10px'}}>
                          <AccountBoxText>
                            <p>Dodaj kolejnego beneficjenta</p>
                            <span>Beneficjent numer cztery</span>
                          </AccountBoxText>
                          <AccountBox
                            isActive={addUboFour}
                            onClick={handlUboPersonFourRegulationsChange}
                          >
                            <BlueBox isActive={addUboFour} />
                          </AccountBox>
                        </AccountBoxWrapper>
                      ) : null}
                      {addUboFour && addUboThree && addUboTwo ? (
                        <>
                          <AccountInfoText>
                            Dane beneficjenta rzeczywistego
                          </AccountInfoText>
                          <InputView
                            notEdit={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                            }
                            isError={
                              uboPersonFourError.ShowErrors &&
                              uboPersonFourError.FirstName
                            }
                            isEditing={isEditing}
                            style={
                              windowWidth < 1024 ? null : {marginTop: '5px'}
                            }
                          >
                            <p>Imię</p>
                            <input
                              type="text"
                              placeholder=""
                              id="FirstName"
                              readOnly={
                                mangopayAccount.UboDeclaration.Status ===
                                'VALIDATION_ASKED'
                                  ? true
                                  : !isEditing
                              }
                              value={uboPersonFour.FirstName}
                              onChange={handleUboPersonFourChange}
                            />
                            {uboPersonFourError.FirstName ? (
                              <ErrorStar
                                fixed={true}
                                text={
                                  'Pole imię beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                }
                              />
                            ) : null}
                          </InputView>
                          <InputView
                            notEdit={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                            }
                            isError={
                              uboPersonFourError.ShowErrors &&
                              uboPersonFourError.LastName
                            }
                            isEditing={isEditing}
                            style={
                              windowWidth < 1024 ? null : {marginTop: '5px'}
                            }
                          >
                            <p>Nazwisko</p>
                            <input
                              type="text"
                              placeholder=""
                              id="LastName"
                              readOnly={
                                mangopayAccount.UboDeclaration.Status ===
                                'VALIDATION_ASKED'
                                  ? true
                                  : !isEditing
                              }
                              value={uboPersonFour.LastName}
                              onChange={handleUboPersonFourChange}
                            />
                            {uboPersonFourError.LastName ? (
                              <ErrorStar
                                fixed={true}
                                text={
                                  'Pole nazwisko beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                }
                              />
                            ) : null}
                          </InputView>
                          <StyledSelectView
                            style={
                              windowWidth < 1024 ? null : {marginTop: '5px'}
                            }
                            isError={
                              uboPersonFourError.ShowErrors &&
                              uboPersonFourError.Nationality
                            }
                            isEditing={isEditing}
                          >
                            <p>Obywatelstwo</p>
                            <select
                              disabled={
                                mangopayAccount.UboDeclaration.Status ===
                                'VALIDATION_ASKED'
                              }
                              value={uboPersonFour.Nationality}
                              onChange={handleUboPersonFourNationality}
                            >
                              {' '}
                              <option hidden value={''}>
                                Wybierz
                              </option>
                              {nationalities.map((item) => (
                                <option
                                  key={item.FlagValue}
                                  value={item.FieldName}
                                >
                                  {item.DisplayValue}
                                </option>
                              ))}
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.439"
                              height="11"
                              viewBox="0 0 6.439 11"
                            >
                              <g
                                id="Arrow_Top_Bar_Accent"
                                data-name="Arrow – Top Bar / Accent"
                                transform="translate(6.439 11) rotate(180)"
                              >
                                <path
                                  id="Arrow"
                                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                  transform="translate(0 0)"
                                  fill="#3986ef"
                                />
                              </g>
                            </svg>
                            {uboPersonFourError.Nationality ? (
                              <ErrorStar
                                fixed={true}
                                text={
                                  'Pole Obywatelstwo beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                }
                              />
                            ) : null}
                          </StyledSelectView>
                          {/* <Select
                            notEdit={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                            }
                            isError={
                              uboPersonFourError.ShowErrors &&
                              uboPersonFourError.Nationality
                            }
                            selectWidth={
                              windowWidth <= 340
                                ? '90%'
                                : windowWidth <= 400
                                ? '311px'
                                : windowWidth <= 1024
                                ? '358px'
                                : '100%'
                            }
                            customStyles={{
                              width:
                                windowWidth <= 340
                                  ? '90%'
                                  : windowWidth <= 400
                                  ? '311px'
                                  : windowWidth <= 1024
                                  ? '358px'
                                  : '100%',
                              marginTop: '5px',
                            }}
                            isEditing={isEditing}
                            value={
                              uboPersonFour.Nationality !== null &&
                              uboPersonFour.Nationality !== ''
                                ? nationalityTypeObj[uboPersonFour.Nationality]
                                : {value: '', text: ''}
                            }
                            values={nationalityTypeOptionsArr}
                            title="Narodowość"
                            onChange={(v) => handleUboPersonFourNationality(v)}
                          >
                            {uboPersonFourError.Nationality ? (
                              <ErrorStar
                                fixed={true}
                                text={
                                  'Pole narodowość beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                }
                              />
                            ) : null}
                          </Select> */}
                          <AccountInfoText>
                            Miejsce urodzenia beneficjenta rzeczywistego
                          </AccountInfoText>
                          <InputViewForData
                            notEdit={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                            }
                            isError={
                              uboPersonFourError.ShowErrors &&
                              uboPersonFourError.Birthday
                            }
                            isEditing={isEditing}
                            style={
                              windowWidth < 1024 ? null : {marginTop: '5px'}
                            }
                          >
                            <p>Data urodzenia</p>
                            {isEditing ? (
                              <input
                                type="date"
                                id="Birthday"
                                placeholder=""
                                readOnly={
                                  mangopayAccount.UboDeclaration.Status ===
                                  'VALIDATION_ASKED'
                                    ? true
                                    : !isEditing
                                }
                                value={uboPersonFour.Birthday}
                                onChange={handleUboPersonFourChange}
                              />
                            ) : (
                              <span>
                                {' '}
                                {new Intl.DateTimeFormat('default', {
                                  year: 'numeric',
                                  month: 'numeric',
                                  day: 'numeric',
                                })
                                  .format(new Date(uboPersonFour.Birthday))
                                  .replace(',', '')}
                              </span>
                            )}
                            {uboPersonFourError.Birthday ? (
                              <ErrorStar
                                fixed={true}
                                text={
                                  'Pole data urodzenia beneficjenta jest obowiązkowe, minimum 18 lat. Prosimy poprawnie uzupełnić dane.'
                                }
                              />
                            ) : null}
                          </InputViewForData>
                          <TwoSmallInputsWrapper>
                            <InputView
                              notEdit={
                                mangopayAccount.UboDeclaration.Status ===
                                'VALIDATION_ASKED'
                              }
                              isError={
                                uboPersonFourError.ShowErrors &&
                                uboPersonFourError.Birthplace.City
                              }
                              isEditing={isEditing}
                              isMediumInput={true}
                              style={
                                windowWidth < 374
                                  ? {width: '141px', marginTop: '0px'}
                                  : windowWidth < 400
                                  ? {width: '155px', marginTop: '0px'}
                                  : windowWidth < 1024
                                  ? {width: '183px', marginTop: '0px'}
                                  : {
                                      width: '50%',
                                      marginTop: '5px',
                                    }
                              }
                            >
                              <p>Miasto</p>
                              <input
                                style={{paddingLeft: '70px'}}
                                type="text"
                                placeholder=""
                                id="City"
                                readOnly={
                                  mangopayAccount.UboDeclaration.Status ===
                                  'VALIDATION_ASKED'
                                    ? true
                                    : !isEditing
                                }
                                value={uboPersonFour.Birthplace.City}
                                onChange={handleUboPersonFourBirthplaceChange}
                              />
                              {uboPersonFourError.Birthplace.City ? (
                                <ErrorStar
                                  fixed={true}
                                  text={
                                    'Pole miasto urodzenia beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                  }
                                />
                              ) : null}
                            </InputView>
                            <StyledSelectView
                              style={
                                windowWidth < 374
                                  ? {
                                      width: '135px',
                                      marginTop: '0px',
                                      marginLeft: '10px',
                                    }
                                  : windowWidth < 400
                                  ? {
                                      width: '150px',
                                      marginTop: '0px',
                                      marginLeft: '10px',
                                    }
                                  : windowWidth < 1024
                                  ? {
                                      width: '178px',
                                      marginTop: '0px',
                                      marginLeft: '10px',
                                    }
                                  : {
                                      width: '159px',
                                      marginTop: '5px',
                                    }
                              }
                              isError={
                                uboPersonFourError.ShowErrors &&
                                uboPersonFourError.Birthplace.Country
                              }
                              isEditing={isEditing}
                            >
                              <p>Kraj</p>
                              <select
                                disabled={
                                  mangopayAccount.UboDeclaration.Status ===
                                  'VALIDATION_ASKED'
                                }
                                value={uboPersonFour.Birthplace.Country}
                                onChange={handleUboPersonFourBirthplaceCountry}
                              >
                                {' '}
                                <option hidden value={''}>
                                  Wybierz
                                </option>
                                {countries.map((item) => (
                                  <option
                                    key={item.FlagValue}
                                    value={item.FieldName}
                                  >
                                    {item.DisplayValue}
                                  </option>
                                ))}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3986ef"
                                  />
                                </g>
                              </svg>
                              {uboPersonFourError.Birthplace.Country ? (
                                <ErrorStar
                                  fixed={true}
                                  text={
                                    'Pole kraj urodzin beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                  }
                                />
                              ) : null}
                            </StyledSelectView>
                          </TwoSmallInputsWrapper>
                          {/* <Select
                            notEdit={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                            }
                            isError={
                              uboPersonFourError.ShowErrors &&
                              uboPersonFourError.Birthplace.Country
                            }
                            selectWidth={
                              windowWidth <= 340
                                ? '90%'
                                : windowWidth <= 400
                                ? '311px'
                                : windowWidth <= 1024
                                ? '358px'
                                : '159px'
                            }
                            customStyles={{
                              width:
                                windowWidth <= 340
                                  ? '90%'
                                  : windowWidth <= 400
                                  ? '311px'
                                  : windowWidth <= 1024
                                  ? '358px'
                                  : '159px',
                              marginTop: '5px',
                            }}
                            isEditing={isEditing}
                            value={
                              uboPersonFour.Birthplace.Country !== null &&
                              uboPersonFour.Birthplace.Country !== ''
                                ? nationalityTypeObj[
                                    uboPersonFour.Birthplace.Country
                                  ]
                                : {value: '', text: ''}
                            }
                            values={nationalityTypeOptionsArr}
                            title="Kraj"
                            onChange={(v) =>
                              handleUboPersonFourBirthplaceCountry(v)
                            }
                          >
                            {uboPersonFourError.Birthplace.Country ? (
                              <ErrorStar
                                fixed={true}
                                text={
                                  'Pole kraj urodzin beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                }
                              />
                            ) : null}
                          </Select> */}
                          <AccountInfoText>
                            Adres beneficjenta rzeczywistego
                          </AccountInfoText>
                          <InputView
                            notEdit={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                            }
                            isError={
                              uboPersonFourError.ShowErrors &&
                              uboPersonFourError.Address.AddressLine1
                            }
                            isEditing={isEditing}
                            style={
                              windowWidth < 1024 ? null : {marginTop: '5px'}
                            }
                          >
                            <p>Ulica</p>
                            <input
                              type="text"
                              placeholder=""
                              id="AddressLine1"
                              readOnly={
                                mangopayAccount.UboDeclaration.Status ===
                                'VALIDATION_ASKED'
                                  ? true
                                  : !isEditing
                              }
                              value={uboPersonFour.Address.AddressLine1}
                              onChange={handleUboPersonFourAddressChange}
                            />
                            {uboPersonFourError.Address.AddressLine1 ? (
                              <ErrorStar
                                fixed={true}
                                text={
                                  'Pole ulica beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                }
                              />
                            ) : null}
                          </InputView>
                          <TwoSmallInputsWrapper>
                            <InputView
                              notEdit={
                                mangopayAccount.UboDeclaration.Status ===
                                'VALIDATION_ASKED'
                              }
                              isError={
                                uboPersonFourError.ShowErrors &&
                                uboPersonFourError.Address.FlatNumber
                              }
                              isEditing={isEditing}
                              isMediumInput={true}
                              style={
                                windowWidth < 374
                                  ? {width: '141px', marginTop: '0px'}
                                  : windowWidth < 400
                                  ? {width: '155px', marginTop: '0px'}
                                  : windowWidth < 1024
                                  ? {width: '183px', marginTop: '0px'}
                                  : {
                                      width: '50%',
                                      marginTop: '5px',
                                    }
                              }
                            >
                              <p>Nr budynku</p>
                              <input
                                type="text"
                                placeholder=""
                                id="FlatNumber"
                                readOnly={
                                  mangopayAccount.UboDeclaration.Status ===
                                  'VALIDATION_ASKED'
                                    ? true
                                    : !isEditing
                                }
                                value={uboPersonFour.Address.FlatNumber}
                                onChange={handleUboPersonFourAddressChange}
                              />
                              {uboPersonFourError.Address.FlatNumber ? (
                                <ErrorStar
                                  fixed={true}
                                  text={
                                    'Pole numer bloku beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                  }
                                />
                              ) : null}
                            </InputView>
                            <InputView
                              notEdit={
                                mangopayAccount.UboDeclaration.Status ===
                                'VALIDATION_ASKED'
                              }
                              isEditing={isEditing}
                              isSmallInput={true}
                              style={
                                windowWidth < 374
                                  ? {
                                      width: '135px',
                                      marginTop: '0px',
                                      marginLeft: '10px',
                                    }
                                  : windowWidth < 400
                                  ? {
                                      width: '150px',
                                      marginTop: '0px',
                                      marginLeft: '10px',
                                    }
                                  : windowWidth < 1024
                                  ? {
                                      width: '178px',
                                      marginTop: '0px',
                                      marginLeft: '10px',
                                    }
                                  : {
                                      width: '159px',
                                      marginTop: '5px',
                                    }
                              }
                            >
                              <p>Nr lokalu</p>
                              <input
                                type="text"
                                placeholder=""
                                id="BuildNumber"
                                readOnly={
                                  mangopayAccount.UboDeclaration.Status ===
                                  'VALIDATION_ASKED'
                                    ? true
                                    : !isEditing
                                }
                                value={uboPersonFour.Address.BuildNumber}
                                onChange={handleUboPersonFourAddressChange}
                              />
                            </InputView>
                          </TwoSmallInputsWrapper>
                          <TwoSmallInputsWrapper>
                            <InputView
                              notEdit={
                                mangopayAccount.UboDeclaration.Status ===
                                'VALIDATION_ASKED'
                              }
                              isError={
                                uboPersonFourError.ShowErrors &&
                                uboPersonFourError.Address.PostalCode
                              }
                              isEditing={isEditing}
                              isMediumInput={true}
                              style={
                                windowWidth < 374
                                  ? {width: '141px', marginTop: '0px'}
                                  : windowWidth < 400
                                  ? {width: '155px', marginTop: '0px'}
                                  : windowWidth < 1024
                                  ? {width: '183px', marginTop: '0px'}
                                  : {
                                      width: '50%',
                                      marginTop: '5px',
                                    }
                              }
                            >
                              <p>Kod pocztowy</p>
                              <input
                                style={{paddingLeft: '70px'}}
                                type="text"
                                placeholder=""
                                id="PostalCode"
                                maxLength={6}
                                readOnly={
                                  mangopayAccount.UboDeclaration.Status ===
                                  'VALIDATION_ASKED'
                                    ? true
                                    : !isEditing
                                }
                                value={uboPersonFour.Address.PostalCode}
                                onChange={
                                  handleUboPersonFourAddressPostalCodeChange
                                }
                              />
                              {uboPersonFourError.Address.PostalCode ? (
                                <ErrorStar
                                  fixed={true}
                                  text={
                                    'Pole kod pocztowy beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                  }
                                />
                              ) : null}
                            </InputView>
                            <InputView
                              notEdit={
                                mangopayAccount.UboDeclaration.Status ===
                                'VALIDATION_ASKED'
                              }
                              isError={
                                uboPersonFourError.ShowErrors &&
                                uboPersonFourError.Address.City
                              }
                              isEditing={isEditing}
                              isSmallInput={true}
                              style={
                                windowWidth < 374
                                  ? {
                                      width: '135px',
                                      marginTop: '0px',
                                      marginLeft: '10px',
                                    }
                                  : windowWidth < 400
                                  ? {
                                      width: '150px',
                                      marginTop: '0px',
                                      marginLeft: '10px',
                                    }
                                  : windowWidth < 1024
                                  ? {
                                      width: '178px',
                                      marginTop: '0px',
                                      marginLeft: '10px',
                                    }
                                  : {
                                      width: '159px',
                                      marginTop: '5px',
                                    }
                              }
                            >
                              <p>Miasto</p>
                              <input
                                style={{paddingLeft: '70px'}}
                                type="text"
                                placeholder=""
                                id="City"
                                readOnly={
                                  mangopayAccount.UboDeclaration.Status ===
                                  'VALIDATION_ASKED'
                                    ? true
                                    : !isEditing
                                }
                                value={uboPersonFour.Address.City}
                                onChange={handleUboPersonFourAddressChange}
                              />
                              {uboPersonFourError.Address.City ? (
                                <ErrorStar
                                  fixed={true}
                                  text={
                                    'Pole miasto beneficjenta jest obowiązkowe. Prosimy uzupełnić dane'
                                  }
                                />
                              ) : null}
                            </InputView>
                          </TwoSmallInputsWrapper>
                          <StyledSelectView
                            style={
                              windowWidth < 1024 ? null : {marginTop: '5px'}
                            }
                            isError={
                              uboPersonFourError.ShowErrors &&
                              uboPersonFourError.Address.Country
                            }
                            isEditing={isEditing}
                          >
                            <p>Kraj</p>
                            <select
                              disabled={
                                mangopayAccount.UboDeclaration.Status ===
                                'VALIDATION_ASKED'
                              }
                              value={uboPersonFour.Address.Country}
                              onChange={handleUboPersonFourAddressCountry}
                            >
                              {' '}
                              <option hidden value={''}>
                                Wybierz
                              </option>
                              {countries.map((item) => (
                                <option
                                  key={item.FlagValue}
                                  value={item.FieldName}
                                >
                                  {item.DisplayValue}
                                </option>
                              ))}
                            </select>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.439"
                              height="11"
                              viewBox="0 0 6.439 11"
                            >
                              <g
                                id="Arrow_Top_Bar_Accent"
                                data-name="Arrow – Top Bar / Accent"
                                transform="translate(6.439 11) rotate(180)"
                              >
                                <path
                                  id="Arrow"
                                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                  transform="translate(0 0)"
                                  fill="#3986ef"
                                />
                              </g>
                            </svg>
                            {uboPersonFourError.Address.Country ? (
                              <ErrorStar
                                text={
                                  'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                                }
                              />
                            ) : null}
                          </StyledSelectView>
                          {/* <Select
                            notEdit={
                              mangopayAccount.UboDeclaration.Status ===
                              'VALIDATION_ASKED'
                            }
                            isError={
                              uboPersonFourError.ShowErrors &&
                              uboPersonFourError.Address.Country
                            }
                            selectWidth={
                              windowWidth <= 340
                                ? '90%'
                                : windowWidth <= 400
                                ? '311px'
                                : windowWidth <= 1024
                                ? '358px'
                                : '100%'
                            }
                            customStyles={{
                              width:
                                windowWidth <= 340
                                  ? '90%'
                                  : windowWidth <= 400
                                  ? '311px'
                                  : windowWidth <= 1024
                                  ? '358px'
                                  : '100%',
                              marginTop: '5px',
                            }}
                            isEditing={isEditing}
                            value={
                              uboPersonFour.Address.Country !== null &&
                              uboPersonFour.Address.Country !== ''
                                ? nationalityTypeObj[
                                    uboPersonFour.Address.Country
                                  ]
                                : {value: '', text: ''}
                            }
                            values={nationalityTypeOptionsArr}
                            title="Kraj"
                            onChange={(v) =>
                              handleUboPersonFourAddressCountry(v)
                            }
                          >
                            {' '}
                            {uboPersonFourError.Address.Country ? (
                              <ErrorStar
                                text={
                                  'Pole Typ osoby prawnej jest obowiązkowe. Prosimy uzupełnić dane'
                                }
                              />
                            ) : null}
                          </Select> */}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}

          {mangopayAccount.DocIdentityProof.Status !== 'VALIDATED' &&
          mangopayAccount.DocIdentityProof.Status !== 'VALIDATION_ASKED' ? (
            <IdScansWrapper>
              <p>Skan dowodu tożsamości</p>

              <RelativeDiv style={{top: '-27px', right: '-213px'}}>
                <ErrorStar
                  onlineInfo={true}
                  fixed={true}
                  color="black"
                  text={
                    mangopayAccount.LegalPersonType === 'SOLETRADER'
                      ? 'Paszport, dowód osobisty, prawo jazdy lub zezwolenie na pobyt osoby prowadzącej jednoosobową działalność gospodarczą. Skan powinien być w poziomie'
                      : 'Paszport, dowód osobisty, prawo jazdy lub zezwolenie na pobyt przedstawiciela ustawowego. '
                  }
                />
              </RelativeDiv>
              <IdScansButtonWrapper isEditing={isEditing}>
                <p>Przód</p>
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    setBtnType((prevState) => ({...prevState, front: true}));
                    inputIdFileRef.current.click();
                  }}
                  style={
                    validateScans.front
                      ? {background: '#00d10f'}
                      : mangopayAccount.DocIdentityProof.Status ===
                          'OUT_OF_DATE' ||
                        mangopayAccount.DocIdentityProof.Status === 'REFUSED' ||
                        mangopayAccount.DocIdentityProof.Status === 'CREATED' ||
                        mangopayAccount.DocIdentityProof.Status === 'null'
                      ? {background: '#FF7E7D'}
                      : idScansFrontError.showErrors &&
                        idScansFrontError.frontId.isError
                      ? {background: '#FF2320'}
                      : null
                  }
                >
                  {validateScans.front ? 'Dodano' : 'Dodaj'}
                </button>
                {validateScans.front ? null : mangopayAccount.DocIdentityProof
                    .Status === 'OUT_OF_DATE' ||
                  mangopayAccount.DocIdentityProof.Status === 'REFUSED' ||
                  mangopayAccount.DocIdentityProof.Status === 'CREATED' ||
                  mangopayAccount.DocIdentityProof.Status === 'null' ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      mangopayRefusedReasonType[
                        mangopayAccount.DocIdentityProof.RefusedReasonType
                      ]
                    }
                  />
                ) : idScansFrontError.showErrors &&
                  idScansFrontError.frontId.isError ? (
                  <ErrorStar
                    fixed={true}
                    text={idScansFrontError.frontId.text}
                  />
                ) : null}
              </IdScansButtonWrapper>
              <input
                type="file"
                onChange={getIdImage}
                accept=".jpeg,.jpg,.png"
                hidden
                ref={inputIdFileRef}
              />
              <input
                type="file"
                onChange={getIdImage}
                accept=".jpeg,.jpg,.png"
                hidden
                ref={inputIdFileBackRef}
              />
              <IdScansButtonWrapper isEditing={isEditing}>
                <p>Tył</p>
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    setBtnType((prevState) => ({...prevState, back: true}));
                    inputIdFileBackRef.current.click();
                  }}
                  style={
                    validateScans.back
                      ? {background: '#00d10f'}
                      : mangopayAccount.DocIdentityProof.Status ===
                          'OUT_OF_DATE' ||
                        mangopayAccount.DocIdentityProof.Status === 'REFUSED' ||
                        mangopayAccount.DocIdentityProof.Status === 'CREATED' ||
                        mangopayAccount.DocIdentityProof.Status === 'null'
                      ? {background: '#FF7E7D'}
                      : idScansFrontError.showErrors &&
                        idScansFrontError.backId.isError
                      ? {background: '#FF2320'}
                      : null
                  }
                >
                  {validateScans.back ? 'Dodano' : 'Dodaj'}
                </button>
                {validateScans.back ? null : mangopayAccount.DocIdentityProof
                    .Status === 'OUT_OF_DATE' ||
                  mangopayAccount.DocIdentityProof.Status === 'REFUSED' ||
                  mangopayAccount.DocIdentityProof.Status === 'CREATED' ||
                  mangopayAccount.DocIdentityProof.Status === 'null' ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      mangopayRefusedReasonType[
                        mangopayAccount.DocIdentityProof.RefusedReasonType
                      ]
                    }
                  />
                ) : idScansFrontError.showErrors &&
                  idScansFrontError.backId.isError ? (
                  <ErrorStar
                    fixed={true}
                    text={idScansFrontError.backId.text}
                  />
                ) : null}
              </IdScansButtonWrapper>
            </IdScansWrapper>
          ) : null}
          {mangopayAccount.DocRegistrationProof.Status !== 'VALIDATED' &&
          mangopayAccount.DocRegistrationProof.Status !== 'VALIDATION_ASKED' ? (
            <IdScansWrapper>
              <p>Skan dokumentu rejestracji firmy</p>
              <RelativeDiv style={{top: '-27px', right: '-170px'}}>
                <ErrorStar
                  onlineInfo={true}
                  fixed={true}
                  color="black"
                  text={
                    mangopayAccount.LegalPersonType === 'BUSINESS'
                      ? 'Uwaga - wpis z CEiDG musi być wygenerowany w języku angielskim i nie starszy niż 3 miesiące. Możesz go pobrać z rządowej strony: https://www.biznes.gov.pl/'
                      : mangopayAccount.LegalPersonType === 'ORGANIZATION'
                      ? 'Dowód rejestracji'
                      : 'Uwaga - wpis z CEiDG musi być wygenerowany w języku angielskim i nie starszy niż 3 miesiące. Możesz go pobrać z rządowej strony: https://www.biznes.gov.pl/'
                  }
                />
              </RelativeDiv>
              <IdScansButtonWrapper isEditing={isEditing}>
                <p>{`${registrationScans.length}/5`}</p>
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    setBtnType((prevState) => ({...prevState, front: true}));
                    inputAdrressFileRef.current.click();
                  }}
                  style={
                    registrationScans.length === 5 ||
                    validateRegistrationScans.front
                      ? {background: '#00d10f'}
                      : mangopayAccount.DocRegistrationProof.Status ===
                          'OUT_OF_DATE' ||
                        mangopayAccount.DocRegistrationProof.Status ===
                          'REFUSED' ||
                        mangopayAccount.DocRegistrationProof.Status ===
                          'CREATED' ||
                        mangopayAccount.DocRegistrationProof.Status === 'null'
                      ? {background: '#FF7E7D'}
                      : registrationScansFrontError.showErrors &&
                        registrationScansFrontError.frontId.isError
                      ? {background: '#FF2320'}
                      : null
                  }
                >
                  {registrationScans.length < 1
                    ? 'Dodaj zdjęcie'
                    : 'Dodaj kolejne'}
                </button>
                {validateRegistrationScans.front ? null : mangopayAccount
                    .DocRegistrationProof.Status === 'OUT_OF_DATE' ||
                  mangopayAccount.DocRegistrationProof.Status === 'REFUSED' ||
                  mangopayAccount.DocRegistrationProof.Status === 'CREATED' ||
                  mangopayAccount.DocRegistrationProof.Status === 'null' ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      mangopayRefusedReasonType[
                        mangopayAccount.DocRegistrationProof.RefusedReasonType
                      ]
                    }
                  />
                ) : registrationScansFrontError.showErrors &&
                  registrationScansFrontError.frontId.isError ? (
                  <ErrorStar
                    fixed={true}
                    text={registrationScansFrontError.frontId.text}
                  />
                ) : null}
                {/* <p>Przód</p>
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    setBtnRegistrationType((prevState) => ({
                      ...prevState,
                      front: true,
                    }));
                    inputAdrressFileRef.current.click();
                  }}
                  style={
                    validateRegistrationScans.front
                      ? {background: '#00d10f'}
                      : mangopayAccount.DocRegistrationProof.Status ===
                          'OUT_OF_DATE' ||
                        mangopayAccount.DocRegistrationProof.Status ===
                          'CREATED' ||
                        mangopayAccount.DocRegistrationProof.Status ===
                          'REFUSED' ||
                        mangopayAccount.DocRegistrationProof.Status === 'null'
                      ? {background: '#FF7E7D'}
                      : registrationScansFrontError.showErrors &&
                        registrationScansFrontError.frontId.isError
                      ? {background: '#FF2320'}
                      : null
                  }
                >
                  {validateRegistrationScans.front ? 'Dodano' : 'Dodaj'}
                </button>
                {validateRegistrationScans.front ? null : mangopayAccount
                    .DocRegistrationProof.Status === 'OUT_OF_DATE' ||
                  mangopayAccount.DocRegistrationProof.Status === 'REFUSED' ||
                  mangopayAccount.DocRegistrationProof.Status === 'CREATED' ||
                  mangopayAccount.DocRegistrationProof.Status === 'null' ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      mangopayRefusedReasonType[
                        mangopayAccount.DocRegistrationProof.RefusedReasonType
                      ]
                    }
                  />
                ) : registrationScansFrontError.showErrors &&
                  registrationScansFrontError.frontId.isError ? (
                  <ErrorStar
                    fixed={true}
                    text={registrationScansFrontError.frontId.text}
                  />
                ) : null} */}
              </IdScansButtonWrapper>
              <input
                type="file"
                onChange={getRegistrationImage}
                accept=".jpeg,.jpg,.png,.pdf"
                hidden
                ref={inputAdrressFileRef}
              />
              <input
                type="file"
                onChange={getRegistrationImage}
                accept=".jpeg,.jpg,.png,.pdf"
                hidden
                ref={inputAdrressBackFileRef}
              />
              {/* <IdScansButtonWrapper isEditing={isEditing}>
                <p>Tył</p>
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    setBtnRegistrationType((prevState) => ({
                      ...prevState,
                      back: true,
                    }));
                    inputAdrressBackFileRef.current.click();
                  }}
                  style={
                    validateRegistrationScans.back
                      ? {background: '#00d10f'}
                      : mangopayAccount.DocRegistrationProof.Status ===
                          'OUT_OF_DATE' ||
                        mangopayAccount.DocRegistrationProof.Status ===
                          'REFUSED' ||
                        mangopayAccount.DocRegistrationProof.Status ===
                          'CREATED' ||
                        mangopayAccount.DocRegistrationProof.Status === 'null'
                      ? {background: '#FF7E7D'}
                      : registrationScansFrontError.showErrors &&
                        registrationScansFrontError.backId.isError
                      ? {background: '#FF2320'}
                      : null
                  }
                >
                  {validateRegistrationScans.back ? 'Dodano' : 'Dodaj'}
                </button>
                {validateRegistrationScans.back ? null : mangopayAccount
                    .DocRegistrationProof.Status === 'OUT_OF_DATE' ||
                  mangopayAccount.DocRegistrationProof.Status === 'REFUSED' ||
                  mangopayAccount.DocRegistrationProof.Status === 'CREATED' ||
                  mangopayAccount.DocRegistrationProof.Status === 'null' ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      mangopayRefusedReasonType[
                        mangopayAccount.DocRegistrationProof.RefusedReasonType
                      ]
                    }
                  />
                ) : registrationScansFrontError.showErrors &&
                  registrationScansFrontError.backId.isError ? (
                  <ErrorStar
                    fixed={true}
                    text={registrationScansFrontError.backId.text}
                  />
                ) : null}
              </IdScansButtonWrapper> */}
            </IdScansWrapper>
          ) : null}
          {mangopayAccount.DocArticlesOfAssociation.Status !== 'VALIDATED' &&
          mangopayAccount.DocArticlesOfAssociation.Status !==
            'VALIDATION_ASKED' &&
          mangopayAccount.LegalPersonType !== 'SOLETRADER' ? (
            <IdScansWrapper>
              <p>Skan statutu stowarzyszenia</p>
              <RelativeDiv style={{top: '-27px', right: '-160px'}}>
                <ErrorStar
                  onlineInfo={true}
                  fixed={true}
                  color="black"
                  text={
                    mangopayAccount.LegalPersonType === 'BUSINESS'
                      ? 'Aktualny i podpisany Akt Notarialny'
                      : 'Aktualny i podpisany Statut'
                  }
                />
              </RelativeDiv>
              <IdScansButtonWrapper isEditing={isEditing}>
                <p>{`${articlesOfAssociationScans.length}/5`}</p>
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    setBtnType((prevState) => ({...prevState, front: true}));
                    inputArticlesOfAssociationFileRef.current.click();
                  }}
                  style={
                    articlesOfAssociationScans.length === 5 ||
                    validateArticlesOfAssociationScans.front
                      ? {background: '#00d10f'}
                      : mangopayAccount.DocArticlesOfAssociation.Status ===
                          'OUT_OF_DATE' ||
                        mangopayAccount.DocArticlesOfAssociation.Status ===
                          'REFUSED' ||
                        mangopayAccount.DocArticlesOfAssociation.Status ===
                          'CREATED' ||
                        mangopayAccount.DocArticlesOfAssociation.Status ===
                          'null'
                      ? {background: '#FF7E7D'}
                      : articlesOfAssociationScansFrontError.showErrors &&
                        articlesOfAssociationScansFrontError.frontId.isError
                      ? {background: '#FF2320'}
                      : null
                  }
                >
                  {articlesOfAssociationScans.length < 1
                    ? 'Dodaj zdjęcie'
                    : 'Dodaj kolejne'}
                </button>
                {validateArticlesOfAssociationScans.front ? null : mangopayAccount
                    .DocArticlesOfAssociation.Status === 'OUT_OF_DATE' ||
                  mangopayAccount.DocArticlesOfAssociation.Status ===
                    'REFUSED' ||
                  mangopayAccount.DocArticlesOfAssociation.Status ===
                    'CREATED' ||
                  mangopayAccount.DocArticlesOfAssociation.Status === 'null' ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      mangopayRefusedReasonType[
                        mangopayAccount.DocArticlesOfAssociation
                          .RefusedReasonType
                      ]
                    }
                  />
                ) : articlesOfAssociationScansFrontError.showErrors &&
                  articlesOfAssociationScansFrontError.frontId.isError ? (
                  <ErrorStar
                    fixed={true}
                    text={articlesOfAssociationScansFrontError.frontId.text}
                  />
                ) : null}
                {/* ////// */}
                {/* <p>Przód</p>
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    setBtnArticlesOfAssociationType((prevState) => ({
                      ...prevState,
                      front: true,
                    }));
                    inputArticlesOfAssociationFileRef.current.click();
                  }}
                  style={
                    validateArticlesOfAssociationScans.front
                      ? {background: '#00d10f'}
                      : mangopayAccount.DocArticlesOfAssociation.Status ===
                          'OUT_OF_DATE' ||
                        mangopayAccount.DocArticlesOfAssociation.Status ===
                          'REFUSED' ||
                        mangopayAccount.DocArticlesOfAssociation.Status ===
                          'CREATED' ||
                        mangopayAccount.DocArticlesOfAssociation.Status ===
                          'null'
                      ? {background: '#FF7E7D'}
                      : articlesOfAssociationScansFrontError.showErrors &&
                        articlesOfAssociationScansFrontError.frontId.isError
                      ? {background: '#FF2320'}
                      : null
                  }
                >
                  {validateArticlesOfAssociationScans.front
                    ? 'Dodano'
                    : 'Dodaj'}
                </button>
                {validateArticlesOfAssociationScans.front ? null : mangopayAccount
                    .DocArticlesOfAssociation.Status === 'OUT_OF_DATE' ||
                  mangopayAccount.DocArticlesOfAssociation.Status ===
                    'REFUSED' ||
                  mangopayAccount.DocArticlesOfAssociation.Status ===
                    'CREATED' ||
                  mangopayAccount.DocArticlesOfAssociation.Status === 'null' ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      mangopayRefusedReasonType[
                        mangopayAccount.DocArticlesOfAssociation
                          .RefusedReasonType
                      ]
                    }
                  />
                ) : articlesOfAssociationScansFrontError.showErrors &&
                  articlesOfAssociationScansFrontError.frontId.isError ? (
                  <ErrorStar
                    fixed={true}
                    text={articlesOfAssociationScansFrontError.frontId.text}
                  />
                ) : null} */}
              </IdScansButtonWrapper>
              <input
                type="file"
                onChange={getArticlesOfAssociationFile}
                accept=".jpeg,.jpg,.png,.pdf"
                hidden
                ref={inputArticlesOfAssociationFileRef}
              />
              <input
                type="file"
                onChange={getArticlesOfAssociationFile}
                accept=".jpeg,.jpg,.png,.pdf"
                hidden
                ref={inputArticlesOfAssociationBackFileRef}
              />
              {/* <IdScansButtonWrapper isEditing={isEditing}>
                <p>Tył</p>
                <button
                  disabled={!isEditing}
                  onClick={() => {
                    setBtnArticlesOfAssociationType((prevState) => ({
                      ...prevState,
                      back: true,
                    }));
                    inputArticlesOfAssociationBackFileRef.current.click();
                  }}
                  style={
                    validateArticlesOfAssociationScans.back
                      ? {background: '#00d10f'}
                      : mangopayAccount.DocArticlesOfAssociation.Status ===
                          'OUT_OF_DATE' ||
                        mangopayAccount.DocArticlesOfAssociation.Status ===
                          'REFUSED' ||
                        mangopayAccount.DocArticlesOfAssociation.Status ===
                          'CREATED' ||
                        mangopayAccount.DocArticlesOfAssociation.Status ===
                          'null'
                      ? {background: '#FF7E7D'}
                      : articlesOfAssociationScansFrontError.showErrors &&
                        articlesOfAssociationScansFrontError.backId.isError
                      ? {background: '#FF2320'}
                      : null
                  }
                >
                  {validateArticlesOfAssociationScans.back ? 'Dodano' : 'Dodaj'}
                </button>
                {validateArticlesOfAssociationScans.back ? null : mangopayAccount
                    .DocArticlesOfAssociation.Status === 'OUT_OF_DATE' ||
                  mangopayAccount.DocArticlesOfAssociation.Status ===
                    'CREATED' ||
                  mangopayAccount.DocArticlesOfAssociation.Status ===
                    'REFUSED' ||
                  mangopayAccount.DocArticlesOfAssociation.Status === 'null' ? (
                  <ErrorStar
                    fixed={true}
                    text={
                      mangopayRefusedReasonType[
                        mangopayAccount.DocArticlesOfAssociation
                          .RefusedReasonType
                      ]
                    }
                  />
                ) : articlesOfAssociationScansFrontError.showErrors &&
                  articlesOfAssociationScansFrontError.backId.isError ? (
                  <ErrorStar
                    fixed={true}
                    text={articlesOfAssociationScansFrontError.backId.text}
                  />
                ) : null}
              </IdScansButtonWrapper> */}
            </IdScansWrapper>
          ) : null}
          {mangopayAccount.Status === -1 || mangopayAccount.Status === 0 ? (
            <AccountBoxWrapper style={{marginBottom: '10px'}}>
              <AccountBoxText>
                <a
                  target="blank"
                  href="https://letmegooglethat.com/?q=regulamin+mango+pay"
                  style={
                    mangopayErrors.ShowErrors &&
                    !mangopayAccount.TermsAndConditionsAccepted
                      ? {color: '#FF0300'}
                      : null
                  }
                >
                  Regulamin MangoPay
                </a>
              </AccountBoxText>
              <AccountBox
                isActive={mangopayAccount.TermsAndConditionsAccepted}
                onClick={handleMangoPayRegulationsChange}
              >
                <BlueBox
                  isActive={mangopayAccount.TermsAndConditionsAccepted}
                />
              </AccountBox>
            </AccountBoxWrapper>
          ) : null}
          {mangopayAccount.UserCategory === 'OWNER' ? (
            <AccountBigBlueBtnWrapper
              isEditing={isEditing}
              isNaturalBottomPosition={windowWidth < 1024}
              hidden={!isEditing}
            >
              <PopupButton
                onClick={checkDataBeforeSend}
                buttons={['Rezygnuje', 'Akceptuje']}
                title="Chcesz wysłać zmiany konta na mangopay?"
                text="Wysłanie zmian spowoduje zmianę konta na nieaktywne, do czasu ponownej weryfikacji danych przez MangoPay. "
              >
                <AccountBigBlueBtn> Wyślij dane ponownie</AccountBigBlueBtn>
              </PopupButton>
            </AccountBigBlueBtnWrapper>
          ) : (
            <AccountBigBlueBtn
              hidden={!isEditing}
              disabled={!isEditing}
              onClick={checkDataBeforeSend}
              style={{
                background: '#3986EF',
              }}
            >
              Załóż portfel
            </AccountBigBlueBtn>
          )}
        </AccountMangoPayFormWrapper>
      </ScrollWrapper>
    </AccountMangopayCompanyWrapper>
  );
};
