import React, {useState, useEffect, useContext, useCallback} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {NewMessagePage} from './newMessage';

const MessagesComponent: React.FC = () => {
  const {decodedToken} = useContext(ProducerDataContext);

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }
  // if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000') {
  //   return <Navigate to="/producer" />;
  // }

  return <NewMessagePage />;
};
export default MessagesComponent;
