import React, {createContext, useContext, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';
import {useIsOrderPageOpenContext} from 'contexts/IsOrderPageOpenContext';

interface FilterMenuContextType {
  isMobileFilterMenuOpen: boolean;
  setIsMobileFilterMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FilterMenuContext = createContext<FilterMenuContextType | undefined>(
  undefined,
);

export const useFilterMenuContext = () => {
  const context = useContext(FilterMenuContext);
  if (!context) {
    throw new Error(
      'useFilterMenuContext must be used within a FilterMenuProvider',
    );
  }
  return context;
};

export const FilterMenuProvider: React.FC = ({children}) => {
  const [isMobileFilterMenuOpen, setIsMobileFilterMenuOpen] = useState(false);
  const location = useLocation();
  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();
  const {setIsOrderPageOpen} = useIsOrderPageOpenContext();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setIsMobileFilterMenuOpen(false);
    scrollToTop();
    setIsOrderPageOpen(false);
    setIsBodyOverflowBlocked(false);
  }, [location]);

  return (
    <FilterMenuContext.Provider
      value={{isMobileFilterMenuOpen, setIsMobileFilterMenuOpen}}
    >
      {children}
    </FilterMenuContext.Provider>
  );
};
