import styled from 'styled-components';

import arrowUp from 'images/svg/blue-arrow-up.svg';
import arrowDown from 'images/svg/blue-arrow-down.svg';
import disapproveIcon from 'images/svg/transparent-disapprove-red-bg.svg';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileSliderWrapper = styled.div`
  width: 100%;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;

  & > p {
    font-size: 13px;
    letter-spacing: 0.5px;
    font-weight: 500;
    line-height: 24px;
    color: white;
  }

  & > img {
    width: 24px;
    cursor: pointer;
    height: 24px;
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 143px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */
  pointer-events: all;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1045px) {
    width: 360px;
  }
`;

export const ProductsWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: span 3;

  @media (max-width: 1045px) {
    display: none;
  }
`;

export const MobileProductsWrapper = styled.div`
  border-radius: 9px;
  display: none;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: span 1;

  @media (max-width: 1045px) {
    display: flex;
    background: rgba(6, 8, 23, 0.85);
  }
`;

export const ProductsHeader = styled.div`
  width: 100%;
  position: relative;
  height: 35px;
  background: #187ee4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px 9px 0 0;

  & > p {
    font-size: 14px;
    color: #060817;
  }
`;

export const ProductsMain = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  & > h3 {
    font-size: 9px;
    font-weight: 600;
    line-height: 41px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #187ee4;
    position: absolute;
    top: -5px;

    &:nth-of-type(1) {
      left: 237px;
    }

    &:nth-of-type(2) {
      left: 536px;
    }
  }
`;

export const ProductsHeaderButton = styled.button`
  background: transparent;
  border: 2px solid #060817;
  border-radius: 30px;
  font-size: 9px;
  font-weight: 600;
  color: #060817;
  padding: 0 12px;
  line-height: 20px;
  position: absolute;
  right: 17px;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.8);
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const FilterWrapper = styled.div`
  width: 100%;
  height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > img {
    cursor: pointer;
  }
`;

export const FilterInputWrapper = styled.div`
  width: 132px;
  height: 28px;
  border-radius: 9px;
  background: transparent;
  border: ${({theme}) => `solid 1px ${theme.colors.headerBorderColor}`};
  position: absolute;
  /* right: 36px; */
  right: 142px;
  top: -121px;
  display: flex;
  justify-content: space-between;
  /* padding: 9px 11px; */
  align-items: center;
  margin: 0;

  & > img {
    width: 11px;
    margin: 0 11px;
  }
  & > svg {
    width: 14.15px;
    margin-left: 10px;
    margin-right: 7px;
    & > g {
      & > path {
        fill: ${({theme}) => theme.colors.fontBlackOp100};
      }
    }
  }
  & > input {
    width: calc(100% - 20px - 28px - 3px);
    height: 100%;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 11px;
    font-weight: 400;
    background: transparent;
    letter-spacing: 0.15px;
    border: none;
    text-align: left;
    outline: none;

    ::placeholder {
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const FilterButton = styled.div<{isFilterMenuOpen: boolean}>`
  width: 28px;
  height: 22px;
  border-radius: 7px;
  margin-right: 1px;
  background: ${({isFilterMenuOpen, theme}) =>
    isFilterMenuOpen ? '#DCE0E9' : theme.colors.blueTheme};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & > img {
    height: 15px;
    width: 15px;
  }
`;

export const ProductsContentHeader = styled.div`
  height: 53px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 22px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  position: relative;
  padding-top: 12px;

  & > h4 {
    font-size: 9px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0px;
    color: white;

    &:nth-of-type(1) {
      margin-left: 62px;
    }

    &:nth-of-type(2) {
      margin-left: 94px;
    }

    &:nth-of-type(3) {
      margin-left: 98px;
    }

    &:nth-of-type(4) {
      margin-left: 88px;
    }

    &:nth-of-type(5) {
      margin-left: 78px;
    }

    &:nth-of-type(6) {
      margin-left: 92px;
    }
  }
`;

export const ProductsContent = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  height: calc(100% - 35px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 24px;
    height: 10px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-image: url(${arrowUp});
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment {
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #125faf;
    border-radius: 25px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }

  @media (max-width: 1045px) {
    overflow-y: hidden;
    padding-bottom: 0;
  }
`;

export const SingleProductWrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  padding: 5px 6px;
  padding-right: 46px;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 9px;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.15);
  }

  & > div {
    width: 74px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    border: 1px solid rgba(255, 255, 255, 0.11);

    & > img {
      width: 24px;
      height: 16.5px;
    }

    & > p {
      font-size: 13px;
      font-weight: 500;
      color: white;
    }
  }

  & > h3 {
    font-size: 11px;
    font-weight: 600;
    line-height: 41px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #187ee4;
  }

  @media (max-width: 1045px) {
    height: 100%;
    padding: 0;
    width: 100%;
    flex-direction: column;
    justify-content: unset;
  }
`;

export const MobileSingleProductWrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  padding: 5px 6px;
  align-items: center;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 9px;

  & > h3 {
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0.2px;
    color: #187ee4;
    margin-top: 20px;
    margin-bottom: 4px;
  }

  @media (max-width: 1045px) {
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: unset;
    gap: 6px;
  }
`;

export const MobileArea = styled.div`
  width: 126px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);

  & > img {
    width: 24px;
    height: 16.5px;
  }

  & > p {
    font-size: 13px;
    font-weight: 500;
    color: white;
  }
`;

export const MobileRowSpan = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  & > p {
    font-size: 9px;
    letter-spacing: 0px;
    font-weight: 600;
    color: white;
    line-height: 36px;
  }
`;

export const DeleteButton = styled.div`
  background-image: url(${disapproveIcon});
  background-position: center center;
  width: 27px !important;
  height: 27px !important;
  border-radius: 2px !important;
  cursor: pointer;

  @media (max-width: 1045px) {
    position: absolute;
    top: 17px;
    right: 25px;
  }
`;
