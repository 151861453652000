export const getOrderTypePolishName = (orderType: number): string => {
  switch (orderType) {
    case 1:
      return 'Zakup';
    case 2:
      return 'Plan';
    case 3:
      return 'Sprzedaż';
    case 4:
      return 'Opłata';
    default:
      return 'Undefined';
  }
};
