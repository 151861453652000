import styled from 'styled-components';

export const YouTubeBlockWrapper = styled.div<{
  bgColor: string;
  backgroundAlpha: number;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 12px 12px 12px 0px; */
  background: ${({bgColor, backgroundAlpha}) =>
    `rgb(${bgColor},${backgroundAlpha})`};
  position: relative;
`;

export const YtWrapper = styled.iframe<{isMobile: boolean}>`
  /* width: 560px;
  height: 315px; */
  height: ${({isMobile}) => (isMobile ? '105px' : '315px')};
  width: ${({isMobile}) => (isMobile ? '186px' : '560px')};
  border: none;
  margin: ${({isMobile}) => (isMobile ? '10px' : '0px')};
  @media (max-width: 1024px) {
    width: 186px;
    height: 105px;
    margin: 10px;
  }
`;
