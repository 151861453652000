import styled, {css} from 'styled-components';

import arrowDown from 'images/svg/blue-arrow-down.svg';
import arrowRight from 'images/svg/select-arrow-right.svg';
import approveIcon from 'images/svg/transparent-approve-green-bg.svg';
import disapproveIcon from 'images/svg/transparent-disapprove-red-bg.svg';

export const Main = styled.div`
  width: 1038px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 20px;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1045px) {
    width: 360px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 1000px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 9px;
  padding: 11px 30px 26px;
  margin-bottom: 17px;

  & > p {
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-size: 11px;
    line-height: 21px;
  }

  @media (max-width: 1045px) {
    width: 328px;
    padding: 11px 26px;
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 372px;
  gap: 2px;

  @media (max-width: 1045px) {
    grid-template-columns: 100%;
    grid-auto-rows: 372px;
    gap: 11px;
  }
`;

export const SubscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 342px;
  height: 100%;
  padding: 12px 16px 0;
  border-radius: 0 0 9px 0;
  background: rgba(0, 102, 255, 0.11);

  & > h5 {
    font-size: 11px;
    line-height: 22px;
    letter-spacing: 0.5px;
    font-weight: 400;
    text-align: center;
    color: white;
  }

  & > p {
    font-size: 8px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 15px;
    text-align: center;
  }

  @media (max-width: 1045px) {
    justify-content: center;
    padding: 0px 16px 0;
  }
`;

export const InputsGrid = styled.div`
  width: 100%;
  display: grid;
  gap: 16px 17px;
  grid-template-columns: 1fr;
`;

export const InputsGridSecondSection = styled.div`
  width: 100%;
  display: grid;
  gap: 5px 17px;
  grid-template-columns: 1fr;
`;

export const SettlementDataDisplay = styled.fieldset`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.11);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > legend {
    font-size: 11px;
    margin-left: 25px;
    padding-left: 5px;
    font-weight: 400;
    width: 70px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > p {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 30px;
    padding-bottom: 4px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: left;
    outline: none;
  }
`;

export const SettlementInputWrapper = styled.fieldset<{
  isProducerEditing?: boolean;
}>`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({isProducerEditing}) =>
    isProducerEditing
      ? 'rgba(23, 117, 211, 0.51)'
      : 'rgba(255, 255, 255, 0.11)'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > legend {
    font-size: 11px;
    margin-left: 25px;
    padding-left: 5px;
    font-weight: 400;
    width: 70px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > input {
    width: 100%;
    height: 100%;
    padding: 0 30px;
    padding-bottom: 4px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: left;
    outline: none;

    ::placeholder {
      color: white;
    }
  }
`;

export const SettlementSelectWrapper = styled.fieldset<{
  isProducerEditing?: boolean;
}>`
  width: 100%;
  height: 46px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({isProducerEditing}) =>
    isProducerEditing
      ? 'rgba(23, 117, 211, 0.51)'
      : 'rgba(255, 255, 255, 0.11)'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > legend {
    font-size: 11px;
    margin-left: 25px;
    padding-left: 5px;
    font-weight: 400;
    width: 70px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > select {
    width: 90%;
    color: white;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: left;
    padding-left: 13px;
    padding-bottom: 4px;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: 256px 4px;

    & > option {
      background: #060817;
    }
  }
`;

export const TextInLineSpan = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    color: #00ff9d;
    font-size: 11px;
    line-height: 41px;
    padding-left: 5px;
    font-weight: 500;
  }

  & > div {
    padding: 3px 8px;
    background: rgba(0, 102, 255, 0.3);
    border-radius: 7px;

    & > p {
      font-size: 13px;
      font-weight: 700;
      color: white;
    }
  }
`;

export const SubscriptionButton = styled.button`
  width: 87px;
  height: 24px;
  border: none;
  border-radius: 15px;
  background: #0b56ce;
  color: white;
  cursor: pointer;
  font-size: 11px;
  text-transform: uppercase;
  margin: 0 auto;
  -webkit-box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.3);
`;

//// new styles
export const NewStyleMainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NewStyleMain = styled.div`
  width: 1020px;
  height: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  position: absolute;
  top: 88px;
  border-radius: 24px 24px 21px 21px;
  /* margin-bottom: 20px; */
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    /* color: rgba(255, 255, 255, 0.92); */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    top: 0px;
    position: relative;
    height: unset;
    margin-bottom: 20px;
    background: ${({theme}) => theme.colors.mobileBodyBg};
    border: none;
  }
`;

export const NewStyleHeaderWrapper = styled.div`
  width: 100%;
  /* background: #89bfff; */
  background: #0091ff;
  border-radius: 24px 24px 0 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > h4 {
    letter-spacing: -0.015px;
    font-size: 18px;
    /* color: #07020f; */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 21px;
    font-weight: 400;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const HeaderBackButton = styled.button`
  width: 120px;
  height: 100%;
  position: absolute;
  left: 0px;
  border: none;
  border-right: 1px solid white;
  background: transparent;
  border-radius: 9px 0 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  /* color: #352f40; */
  color: ${({theme}) => theme.colors.fontBlackOp100};
  transition: all 0.15s;
  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const HeaderSaveButton = styled.button`
  width: 120px;
  height: 100%;
  position: absolute;
  right: 0px;
  border: none;
  border-left: 1px solid white;
  background: transparent;
  border-radius: 0 9px 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;
  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const NewStyleGrid = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  border-radius: 0 0 21px 21px;
  /* background: white; */
  background: ${({theme}) => theme.colors.pageBodyLeft};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 610px;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    /* background: #eeeef1; */
    background: ${({theme}) => theme.colors.mobileBodyBg};
    border: none;
  }
`;

export const NewStyleLeftSection = styled.div`
  width: 100%;
  padding: 0px 8px 7px 7.5px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

export const NewStyleLeftSectionImgWrapper = styled.div<{
  isEditing: boolean;
  isNotPhoto: boolean;
}>`
  width: 210px;
  height: 210px;
  /* background: #f6f6f6; */
  border-radius: 15px;
  margin-top: 7px;
  margin-bottom: 15px;
  color: white;
  position: relative;
  border: ${({theme, isEditing, isNotPhoto}) =>
    isEditing && isNotPhoto
      ? `1px solid ${theme.colors.fontBlackOp15}`
      : 'none'};

  & > img {
    height: 100%;
    width: 100%;
    border-radius: 15px;
  }
  & > div {
    & > svg {
      cursor: pointer;
    }
  }

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    & > g {
      & > g {
        & > path {
          fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
        }
      }
    }
  }

  & > p {
    position: absolute;
    top: 63%;
    left: 50%;
    /* color: black; */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    cursor: pointer;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1024px) {
    width: 180px;
    height: 180px;
    & > svg {
      height: 96px;
      width: 96px;
    }

    & > p {
      font-size: 12px;
      top: 85%;
    }
  }
`;

export const NewStyleLeftSectionImgButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
`;

export const NewStyleImgButtonWrapper = styled.button<{isAddButton?: boolean}>`
  width: 92px;
  height: 52px;
  margin: 5px;
  border: none;
  background: ${({isAddButton}) => (isAddButton ? `transparent` : `#0091FF`)};
  border-radius: 15px;
  border: ${({isAddButton}) =>
    isAddButton ? `1px solid rgba(0,0,0,0.12)` : `none`};
  cursor: pointer;
  font-weight: 400;
  //font-size: 11px;
  font-size: 14px;
  letter-spacing: 0.625px;
  color: ${({isAddButton}) => (isAddButton ? `#07020F` : `white`)};
  transition: all 0.15s;
`;

export const NewStyleLeftSectionDateWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NewStyleCheckBoxWrapper = styled.div<{isStatute?: boolean}>`
  width: 100%;
  margin-top: ${({isStatute}) => (isStatute ? `20px` : '5px')};
  display: flex;
  justify-content: space-between;
  padding-bottom: 3px;
  border-bottom: ${({theme}) => `solid 1px ${theme.colors.fontBlackOp15}`};

  & > p {
    //color: #07020f;
    //font-size: 11px;
    font-size: 12px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 0.625px;
    margin-left: 2.5px;
    /* color: #807d84; */
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: 1024px) {
    width: 358px;
    border-bottom: none;
    & > p {
      font-size: 13px;
      line-height: 24px;
      margin-left: 6px;
      font-weight: normal;
    }
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

export const NewStyleCheckBox = styled.div<{isActive?: boolean}>`
  width: 36px;
  cursor: pointer;
  height: 21px;
  border-radius: 25px;
  border: ${({isActive, theme}) =>
    isActive
      ? `1px solid ${theme.colors.blueTheme}`
      : `solid 1px ${theme.colors.fontBlackOp15}`};
  position: relative;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;

  & > div {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    -webkit-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    left: ${({isActive}) => (isActive ? `16px` : `4px`)};
    top: 2px;
    background: ${({isActive, theme}) =>
      isActive ? `${theme.colors.blueTheme}` : `${theme.colors.fontBlackOp50}`};
  }
`;

export const NewStyleCenterSection = styled.div`
  width: 100%;
  height: 100%;
  //margin-top: 10px;
  padding: 0px 20px;
  gap: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: space-between;
  position: relative;
  /* border-left: 1px solid rgba(255, 255, 255, 0.21);
  border-right: 1px solid rgba(255, 255, 255, 0.21); */
  @media (max-width: 1024px) {
    margin-top: 20px;
  }
`;

export const NewStyleCenterSectionInputsWrappers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledButtonSetting = styled.button`
  margin-top: 20px;
  width: 100%;
  min-height: 44px;
  font-size: 15px;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 0.7px;
  border: none;
  color: white;
  border-radius: 15px;
  /* background: #3986ef; */
  background: ${({theme}) => theme.colors.blueTheme};
`;

export const NewStyleCenterSectionTextandButtonWrapper = styled.div`
  & > p {
    padding: 0 20px;
    /* color: rgba(7, 2, 15, 0.7); */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    //font-size: 9px;
    line-height: 15px;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-align: center;
  }
`;

export const NewStyleCenterSectionPasswordRedInfo = styled.p<{
  isActive: boolean;
}>`
  /* color: #d62220; */
  color: ${({theme}) => theme.colors.statusRed};
  font-size: 10px;
  line-height: 21px;
  text-align: right;
  //margin-top: 5px;
  //margin-bottom: -5px;
  display: ${({isActive}) => (isActive ? 'block' : 'none')};
  @media (max-width: 1100px) {
    font-size: 7px;
    line-height: 14px;
  }
`;

export const NewStyleRightSection = styled.div`
  padding: 10px 7px 0px;
  margin: 8px 8px 0px 0px;
  display: flex;
  height: calc(100% - 14px);
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    padding-bottom: 70px;
    width: 100%;
  }
`;

export const NewStyleRightSectionHeader = styled.div`
  text-align: center;
  color: white;
  margin-bottom: 15px;
  margin-top: 20px;
  position: relative;

  & > h4 {
    //font-size: 11px;
    font-size: 15px;
    letter-spacing: -0.015px;
    font-weight: 400;
    line-height: 21px;
    /* color: #07020f; */
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > p {
    padding: 0 20px;
    text-align: center;
    /* color: rgba(7, 2, 15, 0.7); */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    //font-size: 9px;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.5px;
  }
`;

export const NewStyleRightSectionCheckBoxWithText = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;

  & > span {
    margin-left: 2.5px;
    margin-top: 3px;
    //color: rgba(7, 2, 15, 0.7);
    /* color: #352f40; */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    //font-size: 9px;
    font-size: 11px;
    letter-spacing: -0.025px;
    line-height: 13px;
    //padding-right: 40px;
    & > a {
      cursor: pointer;
      //color: rgba(7, 2, 15, 0.7);
      /* color: #3a87f0; */
      color: ${({theme}) => theme.colors.fontBlue};
      //font-size: 9px;
      font-size: 11px;
      letter-spacing: -0.025px;
      line-height: 13px;
      //padding-right: 40px;
    }
  }

  & > p {
    margin-left: 2px;
    margin-top: 5px;
    font-size: 9px;
    line-height: 12px;
  }

  @media (max-width: 1024px) {
    width: 358px;
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

export const EmailandPhoneNumberButton = styled.button`
  width: 160px;
  height: 25px;
  margin-top: 10px;
  /* border: 1px solid #3986ef; */
  border: ${({theme}) => `1px solid ${theme.colors.blueTheme}`};
  /* background: #3986ef; */
  background: ${({theme}) => theme.colors.blueTheme};
  border-radius: 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: white;
  transition: all 0.15s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const InputView = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  position: relative;
  width: 100%;
  margin-top: 13px;
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};
  & > input {
    outline: none;
    background: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? theme.colors.statusRed
          : 'transparent'
        : 'transparent'};
    border: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : 'none'};
    border-bottom: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
    border-radius: ${({isEditing, isError}) =>
      isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
    color: ${({isEditing, theme}) =>
      isEditing ? theme.colors.fontBlackOp50 : theme.colors.fontBlackOp100};
    width: 100%;
    height: 22px;
    text-align: right;
    //font-size: 11px;
    font-size: 12px;
    padding-right: 8.5px;
    padding-left: 110px;
    &:-webkit-autofill {
      -webkit-text-fill-color: ${({theme}) =>
        theme.colors.fontBlackOp50} !important;
    }
  }
  & > input[type='date']::-webkit-calendar-picker-indicator {
    display: ${({isEditing, notEdit}) =>
      isEditing && !notEdit ? `block` : `none`};
  }

  & > input:-webkit-autofill {
    border: 1px solid rgb(225, 225, 225);
    /* transition: background-color 0s ease-in-out 0s; */
  }

  & > input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    color: ${({theme}) => theme.colors.fontBlackOp100} !important;
    -webkit-text-fill-color: ${({theme}) =>
      theme.colors.fontBlackOp100} !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
  }

  & > input:focus {
    background: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit ? theme.colors.bodyBg : 'transparent'};
    border: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit
        ? ` 1px solid ${theme.colors.blueTheme}`
        : notEdit && isEditing
        ? `solid 1px ${theme.colors.fontBlackOp15}`
        : 'none'};
    border-bottom: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit
        ? `solid 1px ${theme.colors.blueTheme} `
        : notEdit
        ? `solid 1px ${theme.colors.fontBlackOp15}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
  }
  & > p {
    position: absolute;
    //color: #07020f;
    color: ${({isEditing, theme}) =>
      !isEditing
        ? `${theme.colors.fontBlackOp50}`
        : `${theme.colors.fontBlackOp100}`};
    //font-size: 11px;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.625px;
    font-weight: 400;
    left: 8.5px;
    top: 0px;
    cursor: default;
  }

  @media (max-width: 1024px) {
    & > input[type='date'] {
      text-align: right !important;
    }
    width: 358px;
    height: 28px;
    background: ${({isEditing, theme}) =>
      isEditing ? theme.colors.inputMobileBg : 'transparent'};
    border-radius: ${({isEditing}) => (isEditing ? '5px' : '0px')};
    /* border: ${({isEditing, notEdit}) =>
      isEditing && !notEdit
        ? ` 1px solid rgba(112,112,112,0.3)`
        : notEdit && isEditing
        ? 'solid 1px rgba(112,112,112,0.3)'
        : 'solid 1px rgba(112,112,112,0.3)'}; */
    /* border: 1px solid transparent; */
    border: ${({isEditing, theme}) =>
      isEditing ? `1px solid ${theme.colors.inputMobileBorderColor}` : 'none'};
    border-bottom: ${({theme}) =>
      `1px solid ${theme.colors.inputMobileBorderColor}`};
    /* box-shadow: ${({isEditing, theme}) =>
      isEditing ? 'none' : theme.colors.inputBoxShadowColor}; */
    &:focus-within {
      background: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit ? theme.colors.bodyBg : 'transparent'};
      border: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit
          ? ` 1px solid ${theme.colors.blueTheme}`
          : notEdit && isEditing
          ? `solid 1px ${theme.colors.fontBlackOp15}`
          : 'none'};
      border-bottom: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit
          ? `solid 1px ${theme.colors.fontBlue}`
          : notEdit
          ? `solid 1px ${theme.colors.fontBlackOp15}`
          : `solid 1px ${theme.colors.fontBlackOp15}`};
    }
    & > input {
      width: 100%;
      height: 100%;
      font-size: 13px;
      line-height: 22px;
      font-weight: 500;
      letter-spacing: 0.625px;
      border: none;
      //opacity: ${({isEditing}) => (isEditing ? '1' : '0.3')};
      color: ${({isEditing, theme}) =>
        isEditing ? theme.colors.fontBlackOp100 : theme.colors.fontBlackOp100};
      padding-left: 0;
      padding-right: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
      border-radius: 5px;
    }

    & > input:focus {
      background: transparent;
      border: none;
      border-bottom: none;
    }
    & > p {
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.625px;
      left: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
    }
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

export const SelectView = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  position: relative;
  width: 100%;
  margin-top: 9px;
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};
  & > p {
    position: absolute;
    //color: #07020f;
    color: ${({isEditing, theme}) =>
      !isEditing
        ? theme.colors.fontBlackOp50To100
        : theme.colors.fontBlackOp100};
    //font-size: 11px;
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 0.1px;
    font-weight: 400;
    left: ${({isEditing}) => (isEditing ? '11.5px' : '0px')};
    top: 1px;
    cursor: default;
  }
  & > select {
    cursor: pointer;
    outline: none;
    background: ${({isEditing, isError}) =>
      isEditing
        ? isEditing && isError
          ? '#FF7E7D'
          : 'transparent'
        : 'transparent'};
    border: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : 'none'};
    border-bottom: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
    border-radius: ${({isEditing, isError}) =>
      isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
    color: ${({theme}) => theme.colors.fontBlackOp100};
    width: 100%;
    height: 22px;
    line-height: 20px;
    text-align: right;
    //font-size: 11px;
    font-size: 11px;
    letter-spacing: 0.1px;
    padding-right: ${({isEditing}) => (isEditing ? `26.5px` : `0px`)};
    opacity: 1 !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    text-align: -webkit-right;
    text-align-last: right;
    text-indent: 5px hanging;

    & > option {
      background: ${({theme}) => theme.colors.headerContentBackground};

      color: ${({theme}) => theme.colors.fontBlackOp100};
      cursor: pointer;
    }

    & > option:checked {
      color: ${({theme}) => theme.colors.headerBlackFont};
      background-color: ${({theme}) => theme.colors.pageHeader};
      border-radius: 7px;
    }

    &:focus {
      border: 1px solid #0091ff;
    }
  }
  & > svg {
    display: ${({isEditing}) => (isEditing ? `block` : `none`)};
    position: absolute;
    top: 8px;
    right: 10px;
  }
  & > select:focus {
    /* background: #f2f7ff; */
  }
`;

export const BackButton = styled.button`
  width: 55px;
  height: 100%;
  position: absolute;
  left: 0px;
  border: none;
  border-right: solid 1px white;
  background: #92c5ff;
  border-radius: 9px 0 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const SaveAndEditButton = styled.button`
  width: 55px;
  height: 100%;
  position: absolute;
  right: 0px;
  border: none;
  border-left: 1px solid white;
  background: #92c5ff;
  border-radius: 0 9px 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;
