import React, {createContext, useState} from 'react';

type Context = [
  {Id: string; CardType: number},
  React.Dispatch<React.SetStateAction<{Id: string; CardType: number}>>,
];

interface Props {
  children: React.ReactNode;
}

export const VisitedProducerContext = createContext<Context>(null!);

const VisitedProducerProvider: React.FC<Props> = ({children}) => {
  const [visitedProducer, setVisitedProducer] = useState<{
    Id: string;
    CardType: number;
  }>({
    Id: '',
    CardType: 0,
  });

  return (
    <VisitedProducerContext.Provider
      value={[visitedProducer, setVisitedProducer]}
    >
      {children}
    </VisitedProducerContext.Provider>
  );
};

export default VisitedProducerProvider;
