import React, {useContext, useEffect, useState} from 'react';
import {
  MainWrapper,
  Main,
  HeaderWrapper,
  NavigateWrapper,
  ContentWrapper,
  ImgTitleLeadCategoryWrapper,
  ImgWithText,
  DetailTextWrapper,
  CategoryText,
  TitleText,
  LeadText,
  LongTextWrapper,
  ScrollWrapper,
  MobileTitleWrapper,
} from './styles';

import {ResolutionContext} from '../../../contexts/ResolutionContext';
import articlePortfel from 'images/articlePortfel.jpg';
import testowyPlikWebp from 'images/plikWEBP.webp';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import api from 'services/axiosConfig';
import {BackButton} from 'pages/Tracks/TrackDetail';

interface Props {
  PhotoPath: string;
  PictureSource: string | null;
  Category: {CategoryId: string; Name: string};
  Title: string;
  Lead: string;
  UrlPath: string;
  Content: string;
  Id: string;
  PictureDescription: string;
  SeoTitle: string;
  SeoDescription: string;
}

const ArticleDetail: React.FC = () => {
  const [cookies, setCookie] = useCookies(['token']);

  const {windowWidth} = useContext(ResolutionContext);

  const params = useParams();

  const navigate = useNavigate();

  const [articleDetail, setArticleDetail] = useState<Props>({
    PhotoPath: 'string',
    PictureSource: null,
    Category: {CategoryId: '', Name: ''},
    Title: '',
    Lead: '',
    UrlPath: '',
    Content: '',
    Id: '',
    PictureDescription: '',
    SeoTitle: '',
    SeoDescription: '',
  });

  const getArticlesDetails = async (Id: string) => {
    try {
      const response = await api.get(`Articles/path/${Id}`);

      // console.log(response.data.Response);
      setArticleDetail(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getArticlesDetails(params.id);
  }, []);

  const {
    Category,
    Title,
    PictureSource,
    PhotoPath,
    Lead,
    Content,
    PictureDescription,
    SeoTitle,
    SeoDescription,
  } = articleDetail;

  return (
    <MainWrapper>
      <title>{SeoTitle}</title>
      <meta name="description" content={SeoDescription}></meta>
      <Main>
        <HeaderWrapper>
          {' '}
          <BackButton
            onClick={() =>
              // navigate(`/czytelnia/${Category.Name.replace(/ /g, '-')}`)
              navigate(-1)
            }
          >
            <svg
              id="Arrow_Top_Bar_Accent"
              data-name="Arrow – Top Bar / Accent"
              xmlns="http://www.w3.org/2000/svg"
              width="8.195"
              height="14"
              viewBox="0 0 8.195 14"
            >
              <path
                id="Arrow"
                d="M6.513,13.715.2,7.475a.666.666,0,0,1,0-.95L6.513.285a.994.994,0,0,1,1.394,0,.967.967,0,0,1,0,1.377L2.506,7l5.4,5.337a.967.967,0,0,1,0,1.377.994.994,0,0,1-1.394,0"
                transform="translate(0 0)"
                fill="#fff"
              />
            </svg>
          </BackButton>
        </HeaderWrapper>
        <NavigateWrapper>
          <p style={windowWidth < 1024 ? {paddingLeft: '0'} : null}>Home</p>
          {/* <p>-{Category.Name}</p> */}
          {/* <Link
            to={`/czytelnia/${Category.Name}`}
            state={{CategoryId: Category.CategoryId}}
          > */}
          <p
            style={{cursor: 'pointer'}}
            onClick={() =>
              // navigate(`/czytelnia/${Category.Name.replace(/ /g, '-')}`)
              navigate(-1)
            }
          >
            <span> {` > `}</span>
            {Category.Name}
          </p>
          {/* </Link> */}
          <h4>
            <span> {` > `}</span>
            {Title}
          </h4>
        </NavigateWrapper>
        <ScrollWrapper>
          <ContentWrapper>
            {windowWidth < 1024 && (
              <MobileTitleWrapper>
                {' '}
                <CategoryText>{Category.Name}</CategoryText>
                <TitleText> {Title}</TitleText>
              </MobileTitleWrapper>
            )}
            <ImgTitleLeadCategoryWrapper>
              <ImgWithText>
                {' '}
                <img src={PhotoPath} alt={PictureDescription} />{' '}
                {PictureSource === null ? null : <p>Źródło: {PictureSource}</p>}
              </ImgWithText>
              <DetailTextWrapper>
                {windowWidth > 1024 && (
                  <>
                    {' '}
                    <CategoryText>{Category.Name}</CategoryText>
                    <TitleText>{Title}</TitleText>
                  </>
                )}
                <LeadText>{Lead}</LeadText>
              </DetailTextWrapper>
            </ImgTitleLeadCategoryWrapper>
            {/* <LongTextWrapper>{arrTest.map((item) => item)}</LongTextWrapper> */}
            <LongTextWrapper
              dangerouslySetInnerHTML={{__html: Content}}
            ></LongTextWrapper>
          </ContentWrapper>
        </ScrollWrapper>
      </Main>
    </MainWrapper>
  );
};

export default ArticleDetail;
