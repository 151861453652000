import React, {Children, useEffect, useRef, useState} from 'react';
import {DropdownItem, DropDownStyle, SelectContainer} from './styles';

interface Props {
  value: {value: string | number; text: string};
  values: {value: string | number; text: string}[];
  onChange: (v: string) => void;
  isError?: boolean;
  isEditing: boolean;
  title: string;
  selectWidth: string;
  notEdit?: boolean;
  customStyles?: any;
}

export const PhoneCodeSelect: React.FC<Props> = ({
  value,
  values,
  onChange,
  isEditing,
  isError,
  title,
  selectWidth,
  children,
  notEdit = false,
  customStyles = null,
}) => {
  const [currentValue, setCurrentValue] = useState<{
    value: string | number;
    text: string;
  }>(value);
  const [open, setOpen] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValueChange = (value) => {
    setCurrentValue(value);
  };

  const handleChange = (value) => {
    handleValueChange(value);
    if (onChange) onChange(value.value);
    handleClose();
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectRef.current &&
      !selectRef.current.contains(event.target as Node)
    ) {
      setOpen(false);
    }
  };

  return (
    <SelectContainer
      ref={selectRef}
      notEdit={notEdit}
      style={customStyles === null ? {width: selectWidth} : customStyles}
      // style={{width: selectWidth}}
      isError={currentValue.text.length > 0 ? false : isError}
      isEditing={isEditing}
    >
      <p style={title.length > 15 ? {letterSpacing: '0'} : null}>{title}</p>
      <span onClick={isEditing ? handleOpen : null}>
        {currentValue.text === '' ? value.text : currentValue.text}
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6.439"
        height="11"
        viewBox="0 0 6.439 11"
      >
        <g
          id="Arrow_Top_Bar_Accent"
          data-name="Arrow – Top Bar / Accent"
          transform="translate(6.439 11) rotate(180)"
        >
          <path
            id="Arrow"
            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
            transform="translate(0 0)"
            fill="#3986ef"
          />
        </g>
      </svg>
      <DropDownStyle
        isVisible={open}
        style={
          customStyles === null ? null : {background: customStyles.background}
        }
      >
        {values.map((value) => (
          <DropdownItem
            isActive={value.text === currentValue.text}
            onClick={() => handleChange(value)}
            key={value.value}
          >
            {value.text}
          </DropdownItem>
        ))}
      </DropDownStyle>
      {currentValue.text.length > 0 ? null : children}
    </SelectContainer>
  );
};
