import styled from 'styled-components';

export const ShortDescriptionBlockWrapper = styled.div<{
  backgroundAlpha: number;
  backgroundColor: string;
  mobileOverview: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  //gap: 20px;
  //justify-content: space-between;
  background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`};
  /* border-radius: 24px; */
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
`;

export const ShortDescriptionContent = styled.div<{
  fontColor: string;
  height: number;
  mobileOverview: boolean;
  interline: number;
}>`
  //padding: 6px 15px;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  /* padding: 18px 58px 22px 58px; */
  /* padding: ${({mobileOverview, height}) =>
    mobileOverview
      ? '16px'
      : height === 1
      ? '7px 58px 15px 58px'
      : '7px 58px 22px 58px'}; */
  padding: ${({mobileOverview, height}) =>
    mobileOverview ? '16px' : height === 1 ? '0 58px' : '0 58px'};
  & > p {
    /* width: 100%;

    text-align: initial;
    font-size: ${({mobileOverview}) => (mobileOverview ? '17px' : '18px')};
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: ${({mobileOverview}) => (mobileOverview ? '18px' : '26px')};
    letter-spacing: -0.35px;
    color: ${({fontColor}) => `rgb(${fontColor})`}; */
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    text-align: initial;
    outline: none;
    font-size: ${({mobileOverview}) => (mobileOverview ? '17px' : '18px')};
    font-family: 'Inter', sans-serif;
    font-weight: 600;

    line-height: ${({mobileOverview, interline}) =>
      interline === 0 ? '18px' : mobileOverview ? '18px' : `${interline}px`};
    letter-spacing: -0.35px;
    overflow: hidden;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    resize: none;
  }

  & > textarea,
  textarea:disabled {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    text-align: initial;
    opacity: 1;
    outline: none;
    font-size: ${({mobileOverview}) => (mobileOverview ? '17px' : '18px')};
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    /* line-height: ${({mobileOverview}) =>
      mobileOverview ? '18px' : '26px'}; */
    line-height: ${({mobileOverview, interline}) =>
      interline === 0 ? '18px' : mobileOverview ? '18px' : `${interline}px`};
    letter-spacing: -0.35px;
    overflow: hidden;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    resize: none;
  }
  @media (max-width: 1024px) {
    padding: 16px;
    /* padding-left: 4vw;
    padding-right: 4vw; */

    & > p {
      font-size: 16px;
      line-height: 16px;
    }
    & > textarea,
    textarea:disabled {
      font-size: 16px;
      line-height: 16px;
    }
  }
  @media (max-width: 420px) {
    & > p {
      line-height: 15px;
    }
    & > textarea,
    textarea:disabled {
      line-height: 15px;
    }
  }
  @media (max-width: 415px) {
    & > p {
      font-size: 15px;
      line-height: 15px;
    }
    & > textarea,
    textarea:disabled {
      font-size: 15px;
      line-height: 15px;
    }
  }

  @media (max-width: 390px) {
    & > p {
      font-size: 14px;
      line-height: 14px;
    }
    & > textarea,
    textarea:disabled {
      font-size: 14px;
      line-height: 14px;
    }
  }
  @media (max-width: 375px) {
    padding: 14px;
    & > p {
      font-size: 13px;
      line-height: 13px;
    }
    & > textarea,
    textarea:disabled {
      font-size: 13px;
      line-height: 13px;
    }
  }
  @media (max-width: 345px) {
    & > p {
      font-size: 12px;
      line-height: 14px;
    }
    & > textarea,
    textarea:disabled {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @media (max-width: 340px) {
    padding: 10px;
    & > p {
      line-height: 14px;
    }
    & > textarea,
    textarea:disabled {
      line-height: 14px;
    }
  }
  @media (max-width: 320px) {
    padding: 10px;
    & > p {
      line-height: 12px;
    }
    & > textarea,
    textarea:disabled {
      line-height: 12px;
    }
  }
`;

export const ShortDescriptionTitleContent = styled.div<{titleColor: string}>`
  & > p {
    padding-bottom: 10px;
    width: 100%;
    text-align: justify;
    font-size: 38px;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: -0.25px;
    color: ${({titleColor}) => `rgb(${titleColor})`};
  }

  & > textarea,
  textarea:disabled {
    width: 100%;
    height: 40px;
    background: transparent;
    border: none;
    text-align: justify;
    outline: none;
    font-size: 38px;
    font-weight: bold;
    letter-spacing: -0.25px;
    line-height: 32px;
    opacity: 1;
    overflow: hidden;
    color: ${({titleColor}) => `rgb(${titleColor})`};
    resize: none;
  }
`;
