import {Select} from 'components/Select';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  BlueDataText,
  CheckBox,
  InfoText,
  PayCheckOptionWrapper,
  PriceWrapper,
  PrimaryButton,
  ProMessageContentWrapper,
  ProMessageHeader,
  ProMessageTitle,
  ProMessageWapper,
  BackButton,
  ImgWrapper,
} from './styles';
import api from '../../../../services/axiosConfig';
import {useCookies} from 'react-cookie';
import useAlert from '../../../../hooks/useAlert';
import {useNavigate} from 'react-router-dom';
import _ from 'lodash';
import {ErrorStar} from '../../../../components/ErrorStar';
import {LoadingCircle} from 'utils/loadingCircle';
import payWithCard from 'images/payWithCard.png';
import blikLogo from 'images/Blik_logo.png';

import {StyledSelectView} from 'pages/MyStudio/Account';
import {InputView} from 'pages/MyAccount/Settings/styles';
import {ProducerDataContext} from 'contexts/ProducerDataContext';

interface Order {
  Id: string;
  Price: string | number;
  Time: string | number;
  Name: string;
}
interface Props {
  OrderData: Order;
  close: () => void;
  KYCLevel: string;
}

export const ProMessage: React.FC<Props> = ({OrderData, close, KYCLevel}) => {
  const [cookies, setCookie] = useCookies(['token']);
  const [price, setPrice] = useState<string | number>(OrderData.Price);
  const [isError, setIsError] = useState(false);
  const [confirmedCoupon, setConfirmedCoupon] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPayP24Loading, setIsPayP24Loading] = useState<boolean>(false);
  const [isPayTransferLoading, setIsPayTransferLoading] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const {Alert} = useAlert();

  const [data, setData] = useState({
    text: `1 miesiąc (${Number(OrderData.Price) / 100}PLN)`,
    value: 0,
  });
  const [paymentMethod, setPayMentMethod] = useState('przelewy24');
  const [coupon, setCoupon] = useState('');

  const {decodedToken} = useContext(ProducerDataContext);

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCoupon(e.target.value);
    setIsError(false);
  };

  const handlePaymentMethod = (v) => setPayMentMethod(v);

  const handleDataChange = (value) => setData(value);

  const getPriceWithCoupon = async (coupon) => {
    if (coupon.lenght < 2) return;
    try {
      const body = {
        planId: OrderData.Id,
        duration: 30,
        coupon: coupon,
      };

      const respons = await api.get(`Coupon`, {
        headers: {Authorization: `bearer ${cookies.token}`},
        params: body,
      });

      if (respons.data.Response.PriceAfterDiscount !== null) {
        setPrice(respons.data.Response.PriceAfterDiscount);
        setConfirmedCoupon(true);
        setIsError(false);
      }
    } catch (error) {
      setIsError(true);
      setConfirmedCoupon(false);
      console.error(error);
    }
  };

  const getPriceWithCouponDebounce = useCallback(
    _.debounce((coupon) => getPriceWithCoupon(coupon), 2000),
    [],
  );

  const payWithPay24 = async () => {
    if (isPayP24Loading) return;
    setIsPayP24Loading(true);
    try {
      const body = {
        PlanId: OrderData.Id,
        Coupon: coupon,
      };
      const respons = await api.post(
        'MangopayTransaction/BuyPlanCardPayIn',
        body,
        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );
      close();
      window.location.href = respons.data.Response.RedirectUrl;
    } catch (error) {
      if (
        error?.response?.data?.Error?.Message ===
        `Property Producer's Settlement Address has invalid value.`
      ) {
        Alert(
          error.response.data.Error.Message,
          'Utwórz konto MangoPay, aby móc kupić plan.',
        );
      } else {
        Alert(error.response.data.Error.Message, 'Błąd');
      }
    } finally {
      setIsPayP24Loading(false);
    }
  };

  const payWithMugoWallet = async () => {
    if (isPayTransferLoading) return;
    setIsPayTransferLoading(true);
    try {
      const body = {
        PlanId: OrderData.Id,
        Coupon: coupon,
      };
      const respons = await api.post(
        'MangopayTransaction/BuyPlanTransfer',
        body,
        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );

      Alert('Dziękujemy za zakup', 'Komunikat', 'Zamknij');
      navigate('/transactions');
      // close();
      // window.location.href = respons.data.Response.RedirectUrl;
    } catch (error) {
      console.error(error);

      if (
        error.response.data.Error.Message ===
        'Insufficient funds in the wallet.'
      ) {
        Alert('Za mało środków w portfelu.', 'Komunikat');
        close();
      } else if (
        error?.response?.data?.Error?.Message ===
        `Property Producer's Settlement Address has invalid value.`
      ) {
        Alert(
          error.response.data.Error.Message,
          'Utwórz konto MangoPay, aby móc kupić plan.',
        );
      } else {
        Alert(
          'Wystąpił problem z twoim konte mangopay. Sprawdź zakładke konto czy status konta jest "Zaakceptowany"',
          'Komunikat',
        );
        close();
      }
    } finally {
      setIsPayTransferLoading(false);
    }
  };

  const payByBlik = async () => {
    if (isPayP24Loading) return;
    if (isPayTransferLoading) return;
    setIsPayP24Loading(true);
    try {
      const body = {
        PlanId: OrderData.Id,
        Coupon: coupon,
      };

      const response = await api.post(
        `MangopayTransaction/BuyPlanBlikWebPayIn`,
        body,
        {
          headers: {
            Authorization: `Bearer ${cookies.token}`,
          },
        },
      );
      // Alert('Zamówienie zostało stworzone.', 'Komunikat');
      window.location.href = response.data.Response.RedirectUrl;
    } catch (error) {
      if (
        error?.response?.data?.Error?.Message ===
        `Property Producer's Settlement Address has invalid value.`
      ) {
        Alert(
          error.response.data.Error.Message,
          'Utwórz konto MangoPay, aby móc kupić plan.',
        );
      } else {
        Alert(error.response.data.Error.Message, 'Błąd');
      }
    } finally {
      setIsPayP24Loading(false);
    }
  };

  const pay = () => {
    if (paymentMethod === 'przelewy24') {
      return payWithPay24();
    } else if (paymentMethod === 'mugo') {
      return payWithMugoWallet();
    } else if (paymentMethod === 'blik') {
      return payByBlik();
    }
  };

  const today = new Date();
  const todayPlus30Days = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);

  const dd = String(todayPlus30Days.getDate()).padStart(2, '0');
  const mm = String(todayPlus30Days.getMonth() + 1).padStart(2, '0');
  const yyyy = todayPlus30Days.getFullYear();

  const formattedDate = `${dd}-${mm}-${yyyy}`;

  const getSellerPlans = async () => {
    console.log('pobieram pi');
    try {
      const response = await api.get(`Seller/GetPlanPrice/${OrderData.Id}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      if (response.data.Response) {
        setPrice(response.data.Response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (coupon.length > 1) {
      getPriceWithCouponDebounce(coupon);
    }
  }, [coupon]);

  useEffect(() => {
    getSellerPlans();
  }, []);

  return (
    <ProMessageWapper>
      {(isPayP24Loading || isPayTransferLoading) && <LoadingCircle />}
      <ProMessageHeader>
        <BackButton onClick={close}>
          <svg
            id="Arrow_Top_Bar_Accent"
            data-name="Arrow – Top Bar / Accent"
            xmlns="http://www.w3.org/2000/svg"
            width="8.195"
            height="14"
            viewBox="0 0 8.195 14"
          >
            <path
              id="Arrow"
              d="M6.513,13.715.2,7.475a.666.666,0,0,1,0-.95L6.513.285a.994.994,0,0,1,1.394,0,.967.967,0,0,1,0,1.377L2.506,7l5.4,5.337a.967.967,0,0,1,0,1.377.994.994,0,0,1-1.394,0"
              transform="translate(0 0)"
              fill="#fff"
            />
          </svg>
        </BackButton>
        <h2>Zamówienie</h2>
      </ProMessageHeader>
      <ProMessageContentWrapper>
        <ProMessageTitle>
          <p>Produkt/usługa:</p>
          <p style={{fontWeight: '600'}}>Dostęp do konta {OrderData.Name}</p>
        </ProMessageTitle>
        {/* <Select
          customStyles={{
            background: 'transparent',
            width: '312px',
            margin: '0 auto',
          }}
          selectWidth={'100%'}
          title="Okres"
          isEditing={true}
          value={data}
          values={[
            {text: `1 miesiąc (${Number(OrderData.Price) / 100}PLN)`, value: 0},
          ]}
          onChange={(v) => handleDataChange(v)}
        /> */}
        <StyledSelectView style={{width: '360px'}} isEditing={true}>
          <p>Okres</p>
          <select value={data.value} onChange={(v) => handleDataChange(v)}>
            {' '}
            <option hidden value={''}>
              Wybierz
            </option>
            <option value={0}>{`1 miesiąc (${Number(price) / 100}PLN)`}</option>
          </select>
        </StyledSelectView>
        <BlueDataText>Do dnia {formattedDate}</BlueDataText>
        <InfoText>
          Sprawdź inne dostępne okresy, skorzystaj z subskrypcji dzięki temu nie
          tracisz możliwości jakie daje konto.
        </InfoText>
        <PriceWrapper>
          <p>RAZEM</p>
          <h4>{(Number(price) / 100).toFixed(2).replace('.', ',')}zł</h4>
        </PriceWrapper>
        <InputView style={{width: '360px'}} isEditing={true} isError={isError}>
          <p>Kod promocyjny </p>
          <input
            style={confirmedCoupon ? {background: '#19D627'} : null}
            value={coupon}
            disabled={confirmedCoupon}
            onChange={(e) => handleCouponChange(e)}
            placeholder="Uzupełnij"
          />
          {isError ? (
            <ErrorStar text={'Podany kupon jest nieprawidłowy'} />
          ) : null}
        </InputView>
        <PriceWrapper>
          <p>Kwota do zapłaty</p>
          <h4>{(Number(price) / 100).toFixed(2).replace('.', ',')}zł</h4>
        </PriceWrapper>
        <PayCheckOptionWrapper
          isActive={paymentMethod === 'mugo'}
          onClick={
            KYCLevel === 'REGULAR' ? () => handlePaymentMethod('mugo') : null
          }
          style={
            KYCLevel === 'REGULAR'
              ? null
              : {cursor: 'default', background: '#dce0e9'}
          }
        >
          <CheckBox
            isActive={paymentMethod === 'mugo'}
            style={KYCLevel === 'REGULAR' ? null : {cursor: 'default'}}
          >
            {' '}
            {paymentMethod === 'mugo' ? <span></span> : null}
          </CheckBox>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="93"
            height="37"
            viewBox="0 0 93 37"
          >
            <g
              id="Group_4379"
              data-name="Group 4379"
              transform="translate(-220 -1082)"
            >
              <text
                id="_Value"
                data-name="↳ Value"
                transform="translate(220 1098)"
                fill="#647b9c"
                fontSize="15"
                fontFamily="SegoeUI, Segoe UI"
                letterSpacing="-0.005em"
              >
                <tspan x="0" y="0">
                  Portfel
                </tspan>
                <tspan x="0" y="15">
                  MUGO
                </tspan>
              </text>
              <path
                id="Icon_awesome-coins"
                data-name="Icon awesome-coins"
                d="M0,28.5v3C0,33.982,6.047,36,13.5,36S27,33.982,27,31.5v-3c-2.9,2.046-8.212,3-13.5,3S2.9,30.544,0,28.5ZM22.5,9C29.953,9,36,6.982,36,4.5S29.953,0,22.5,0,9,2.018,9,4.5,15.047,9,22.5,9ZM0,21.122V24.75c0,2.482,6.047,4.5,13.5,4.5S27,27.232,27,24.75V21.122c-2.9,2.391-8.22,3.628-13.5,3.628S2.9,23.513,0,21.122Zm29.25.773C33.279,21.115,36,19.666,36,18V15a17.267,17.267,0,0,1-6.75,2.426ZM13.5,11.25C6.047,11.25,0,13.767,0,16.875S6.047,22.5,13.5,22.5,27,19.983,27,16.875,20.953,11.25,13.5,11.25Zm15.42,3.959c4.219-.759,7.08-2.25,7.08-3.959v-3c-2.5,1.765-6.785,2.714-11.3,2.939A7.874,7.874,0,0,1,28.92,15.209Z"
                transform="translate(277 1083)"
                fill="#b3b2b1"
              />
            </g>
          </svg>
        </PayCheckOptionWrapper>

        <PayCheckOptionWrapper
          isActive={paymentMethod === 'przelewy24'}
          onClick={() => handlePaymentMethod('przelewy24')}
        >
          <CheckBox isActive={paymentMethod === 'przelewy24'}>
            {' '}
            {paymentMethod === 'przelewy24' ? <span></span> : null}
          </CheckBox>

          <img src={payWithCard} alt="CardLogo" />
        </PayCheckOptionWrapper>

        <PayCheckOptionWrapper
          isActive={paymentMethod === 'blik'}
          onClick={() => handlePaymentMethod('blik')}
        >
          <CheckBox isActive={paymentMethod === 'blik'}>
            {' '}
            {paymentMethod === 'blik' ? <span></span> : null}
          </CheckBox>

          <ImgWrapper>
            <img style={{height: ' 37px'}} src={blikLogo} alt="BlikLogo" />
          </ImgWrapper>
        </PayCheckOptionWrapper>
      </ProMessageContentWrapper>
      <PrimaryButton onClick={pay}>
        <h4>Zamawiam i płacę</h4>
        <p>
          Klikając w ten przycisk potwierdzasz zamówienie, a Twórca otrzyma od
          Ciebie zamówienie
        </p>
      </PrimaryButton>
    </ProMessageWapper>
  );
};
