import styled, {css, keyframes} from 'styled-components';

export const CommentBlockWrapper = styled.div<{
  backgroundAlpha: number;
  backgroundColor: string;
  isMobile: boolean;
}>`
  width: ${({isMobile}) => (isMobile ? '100%' : '100%')};
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: ${({isMobile}) =>
    isMobile ? '13px 10px 10px 10px' : ' 20px 50px 0px 50px'};
  background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`};

  align-self: ${({isMobile}) => (isMobile ? 'center' : ' none')};

  @media (max-width: 1024px) {
    overflow: hidden;
    width: 100%;
    padding: 16px;
    align-self: center;
    padding-bottom: 0px;
  }
  @media (max-width: 375px) {
    /* padding: 13px 30px 0px 35px; */
    padding: 14px;
    padding-bottom: 0px;
  }
  @media (max-width: 340px) {
    padding: 10px;
    padding-bottom: 0px;
  }
`;

export const CommentBlockHeader = styled.div<{theme?: string; Width: number}>`
  height: 10%;
  width: 100%;
  display: flex;
  gap: 11px;
  align-items: center;

  & > h4 {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    padding: 0 5px;
  }

  & > svg {
    width: 27.38px;
    height: 21px;
    & > path {
      fill: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  & > button {
    width: 49px;
    height: 21px;
    border: 1px solid
      ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 9px;
    font-weight: 600;
    display: flex;
    background: transparent;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }

  /* @media (max-width: 1065px) {
    height: 5%;
  }
  @media (max-width: 920px) {
    & > h4 {
      font-size: ${({Width}) => (Width === 1 ? '14px' : '16px')};
    }
    & > svg {
      height: ${({Width}) => (Width === 1 ? '14px' : '16px')};
    }
    & > button {
      width: 45px;
      height: 18px;
      font-size: 9px;
    }
  }
  @media (max-width: 780px) {
    & > h4 {
      font-size: ${({Width}) => (Width === 1 ? '12px' : '14px')};
    }
    & > svg {
      height: ${({Width}) => (Width === 1 ? '12px' : '14px')};
    }
    & > button {
      font-size: 8px;
    }
  }
  @media (max-width: 720px) {
    & > h4 {
      font-size: ${({Width}) => (Width === 1 ? '10px' : '12px')};
    }
    & > svg {
      height: ${({Width}) => (Width === 1 ? '10px' : '12px')};
    }
    & > button {
      width: 40px;
      height: 16px;
      font-size: 7px;
    }
  }
  @media (max-width: 650px) {
    & > h4 {
      padding: 0;
      font-size: ${({Width}) => (Width === 1 ? '9px' : '11px')};
    }
    & > svg {
      height: ${({Width}) => (Width === 1 ? '9px' : '11px')};
    }
    & > button {
      width: 38px;
      height: 14px;
      font-size: 6px;
    }
  }

  @media (max-width: 580px) {
    & > h4 {
      font-size: ${({Width}) => (Width === 1 ? '8px' : '10px')};
    }
    & > svg {
      height: ${({Width}) => (Width === 1 ? '8px' : '10px')};
    }
    & > button {
      width: 32px;
      height: 12px;
      font-size: 5px;
    }
  }
  @media (max-width: 550px) {
    & > h4 {
      font-size: ${({Width}) => (Width === 1 ? '6px' : '8px')};
    }
    & > svg {
      height: ${({Width}) => (Width === 1 ? '6px' : '8px')};
    }
    & > button {
      font-size: 4px;
    }
  } */
`;

export const CommentBlockNavigation = styled.div<{theme?: string}>`
  display: flex;
  gap: 6px;
  align-items: center;

  & > svg {
    height: 16px;
    width: 16px;
    cursor: pointer;

    & > g {
      & > g {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};

        & > path {
          fill: ${({theme}) =>
            theme === '000000'
              ? 'black'
              : theme === '0C426A'
              ? '#0C426A'
              : 'white'};
        }
      }
    }
  }

  & > p {
    font-size: 11px;
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  }
  @media (max-width: 920px) {
    & > svg {
      width: 15px;
      height: 15px;
    }
    & > p {
      font-size: 10px;
    }
  }
  @media (max-width: 830px) {
    gap: 3px;
    & > svg {
      width: 14px;
      height: 14px;
    }
    & > p {
      font-size: 9px;
    }
  }
  @media (max-width: 780px) {
    gap: 2px;
    & > svg {
      width: 12px;
      height: 12px;
    }
    & > p {
      font-size: 8px;
    }
  }
  @media (max-width: 650px) {
    gap: 1px;
    & > svg {
      width: 10px;
      height: 10px;
    }
    & > p {
      font-size: 7px;
    }
  }
  @media (max-width: 580px) {
    gap: 1px;
    & > svg {
      width: 8px;
      height: 8px;
    }
    & > p {
      font-size: 6px;
    }
  }
  @media (max-width: 450px) {
    gap: 1px;
    & > svg {
      width: 5px;
      height: 5px;
    }
    & > p {
      font-size: 4px;
    }
  }
`;

export const CommentBlockContent = styled.div<{
  mobileOverview: boolean;
  Height: number;
  Width: number;
}>`
  width: 100%;
  height: 90%;
  display: grid;
  justify-content: space-between;
  gap: 40px;
  grid-template-columns: repeat(auto-fill, 282px);
  grid-template-rows: repeat(auto-fill, 258px);

  @media (max-width: 1065px) {
    gap: 10px;
    height: 95%;
    grid-template-columns: ${({Width, mobileOverview}) =>
      !mobileOverview ? `repeat(${Width}, 1fr)` : 'repeat(auto-fill, 287px)'};
    grid-template-rows: ${({Height, mobileOverview}) =>
      !mobileOverview ? `repeat(${Height}, 1fr)` : 'repeat(auto-fill, 258px)'};
  }
`;

export const CommentWrapper = styled.div<{theme?: string}>`
  border-radius: 9px;
  position: relative;
  border: 1px solid
    ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;
export const DeleteButton = styled.button<{theme: string}>`
  position: absolute;
  top: -27px;
  right: -20px;
  font-size: 18px;
  background-color: transparent;
  padding: 5px;
  border: none;
  color: ${({theme}) =>
    theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  cursor: pointer;
`;

export const CommentHeader = styled.div<{theme?: string; fontColor: string}>`
  height: 20%;
  width: 100%;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid
    ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};

  & > input {
    background: transparent;
    border: none;
    text-align: right;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    outline: none;
    font-size: 11px;

    &::placeholder {
      color: ${({fontColor}) => `rgb(${fontColor})`};
    }
  }
`;

export const StarWrapper = styled.div<{theme?: string}>`
  display: flex;
  gap: 4px;

  & > img {
    width: 16px;
    height: 16px;
  }

  & > svg {
    width: 16px;
    height: 16px;

    & > path {
      fill: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }
  @media (max-width: 780px) {
    gap: 3px;
    & > svg {
      width: 14px;
      height: 14px;
    }
  }
  @media (max-width: 650px) {
    gap: 2px;
    & > svg {
      width: 12px;
      height: 12px;
    }
  }
  @media (max-width: 580px) {
    gap: 1px;
    & > svg {
      width: 10px;
      height: 10px;
    }
  }
  @media (max-width: 550px) {
    & > svg {
      width: 8px;
      height: 8px;
    }
  }
  @media (max-width: 450px) {
    & > svg {
      width: 6px;
      height: 6px;
    }
  } ;
`;

export const CommentDataAndUserNameWrapper = styled.div<{fontColor: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  & > p {
    color: ${({fontColor}) => `rgb(${fontColor})`};
    font-size: 9px;
    text-align: right;
  }
  @media (max-width: 780px) {
    & > p {
      font-size: 8px;
    }
  }
  @media (max-width: 650px) {
    & > p {
      font-size: 7px;
    }
  }
  @media (max-width: 580px) {
    & > p {
      font-size: 6px;
    }
  }
  @media (max-width: 550px) {
    & > p {
      font-size: 5px;
    }
  }
  @media (max-width: 450px) {
    & > p {
      font-size: 4px;
    }
  }
`;

export const CommentContent = styled.div<{theme?: string; fontColor: string}>`
  padding: 6px 15px;
  width: 100%;
  height: 80%;
  position: relative;

  & > p {
    font-size: 11px;
    line-height: 21px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }

  & > textarea {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 11px;
    line-height: 21px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    resize: none;

    &::placeholder {
      font-size: 11px;
      line-height: 21px;
      color: ${({fontColor}) => `rgb(${fontColor})`};
    }
  }

  & > button {
    position: absolute;
    bottom: 8px;
    right: 12px;
    width: 49px;
    height: 21px;
    border: 1px solid
      ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 9px;
    font-weight: 600;
    display: flex;
    background: transparent;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
  }
  @media (max-width: 780px) {
    & > p {
      font-size: 10px;
    }
  }
  @media (max-width: 650px) {
    & > p {
      font-size: 9px;
    }
  }
  @media (max-width: 580px) {
    & > p {
      font-size: 8px;
    }
  }
  @media (max-width: 550px) {
    & > p {
      font-size: 7px;
    }
  }
  @media (max-width: 450px) {
    & > p {
      font-size: 6px;
    }
  }
`;

// new view

export const NewCommentContentWrapper = styled.div<{isMobile: boolean}>`
  //background: #17181b;
  width: 100%;
  flex-grow: 1;
  height: 100%;

  overflow: hidden;
  display: flex !important;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  gap: ${({isMobile}) => (isMobile ? '12px' : '0px')};
  padding-bottom: 50px;
  /* gap: 4px; */
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const NewCommentContent = styled.div`
  width: 100%;
  height: 100%;
  //border-radius: 5px;
  position: relative;
  display: flex;

  gap: 4px;
`;

const slideUp = keyframes`
  0% {
    //opacity: 0;
    transform: rotateX(60deg);
    //transform: translateY(-100%);
  }
  100% {
    //opacity: 1;
    //transform: translateY(0);
    transform: rotateX(0deg);
  }
`;

const slideDown = keyframes`
  0% {
    //opacity: 0;
    //transform: translateY(-99%);
    transform: rotateX(59deg);
  }
  100% {
    //opacity: 1;
    //transform: translateY(0);
    transform: rotateX(0deg);
  }
`;

const mainSlideUp = keyframes`
  0% {
    //opacity: 0;
    //transform: translateY(-100%);
    z-index: 2;
    transform:translateY(-50%) rotateX(89deg) ;

  }
  100% {
    //opacity: 1;
    //transform: translateY(0) ;
    transform:translateY(0)  rotateX(0deg) ;
  }
`;

const mainSlideUpSecond = keyframes`
  0% {
    //opacity: 0;
    //transform: translateY(-99%);
    transform:translateY(-49%) rotateX(90deg) ;
   
  }
  100% {
    //opacity: 1;
    //transform: translateY(0);
    transform:translateY(0) rotateX(0deg) ;
   
  }
`;

const mainSlideDown = keyframes`
  0% {
    opacity: 0;
    //transform: translateY(100%);
    transform:translateY(50%) rotateX(-90deg) ;
  }
  100% {
    opacity: 1;
    //transform: translateY(0);
    transform:translateY(0) rotateX(0deg) ;
  }
`;

const mainSlideDownSecond = keyframes`
  0% {
    opacity: 0;
    //transform: translateY(99%);
    transform:translateY(49%) rotateX(-89deg) ;
  }
  100% {
    opacity: 1;
    //transform: translateY(0);
    transform:translateY(0) rotateX(0deg) ;
  }
`;

const deleteElement = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
`;
const deleteElementSecond = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-99%);
  }
`;

export const FirstAndLastCommentWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 124px;
  min-height: 124px;
  background: transparent;
  border: solid 1px rgba(0, 0, 0, 0.05);
  border-radius: 13px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  flex-direction: column;
  //padding: 5px;
`;

export const NewCommentSingielCommentWrapper = styled.div<{
  useSlideInAnimation: boolean;
  deleteAnimation?: boolean;
  mainItem?: boolean;
  topAnimation?: boolean;
}>`
  position: absolute;
  width: 296px;
  height: 240px;
  min-height: 240px;
  background: transparent;
  border: solid 1px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 5px;
`;
//   animation: ${({useSlideInAnimation}) =>
//       useSlideInAnimation ? slideDown : slideUp}
//     0.8s ease-in;

export const MainSingielCommentWrapper = styled.div<{
  useSlideInAnimation: boolean;
  deleteAnimation?: boolean;
  mainItem?: boolean;
  topAnimation?: boolean;
}>`
  position: absolute;
  width: 296px;
  min-height: 240px;
  height: 240px;
  background: transparent;
  border: solid 1px rgba(0, 0, 0, 0.05);
  border-radius: 24px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

export const SingielCommentHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 10px;
  padding: 0px 5px;
`;

export const SingielCommentNameAndTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 37px;
  flex-grow: 1;
  padding-left: 7px;
  & > p {
    flex-grow: 3;
    font-size: 13px;
    font-weight: bold;
    color: blue;
    overflow: hidden;
    letter-spacing: -0.25px;
  }
  & > span {
    display: block;
    overflow: hidden;
    font-size: 18px;
    padding-left: 10px;
    color: rgba(255, 255, 255, 0.5);
    //opacity: 0.5;
  }

  @media (max-width: 425px) {
    & > p {
      font-size: 12px;
    }
    & > span {
      font-size: 12px;
    }
  }

  @media (max-width: 375px) {
    & > p {
      font-size: 12px;
    }
    & > span {
      font-size: 12px;
    }
  }

  @media (max-width: 320px) {
    & > p {
      font-size: 12px;
    }
    & > span {
      font-size: 12px;
    }
  }
`;

export const CircleAvatarWrapper = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 100%;
  //background-color: orange;
  & > img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
  & > svg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-color: #f6f6f6;
  }
`;

export const SingielCommentStarRateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: center;
  & > span {
    display: block;
    overflow: hidden;
    font-size: 11px;
    padding-right: 8px;
    color: rgba(255, 255, 255, 0.5);
    //opacity: 0.5;
  }
  & > svg {
    margin-left: 3px;
    height: 11px;
    width: 11px;
  }
  @media (max-width: 425px) {
    & > svg {
      height: 11px;
      width: 11px;
    }
  }

  @media (max-width: 375px) {
    & > svg {
      height: 11px;
      width: 11px;
    }
  }

  @media (max-width: 320px) {
    & > svg {
      height: 11px;
      width: 11px;
    }
  }
`;

export const SingielCommentTextWrapper = styled.div`
  font-size: 13px;
  letter-spacing: 0.05px;
  line-height: 18px;
  color: #000000;
  margin-left: 10px;
  margin-top: 15px;
  margin-right: 15px;
  overflow: hidden;
  & > textarea {
    font-size: 13px;
    letter-spacing: 0.05px;
    line-height: 18px;
    color: black;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    text-align: initial;
    outline: none;
    overflow-y: hidden;
    resize: none;
  }
  @media (max-width: 425px) {
    margin-top: 10px;
  }
`;

const changeBgAniamtionOne = keyframes`
  0% {
  
    opacity: 0.3;
  }
  100% {
   
    opacity: 0.5;
  }
`;
const changeBgAniamtionSecondOne = keyframes`
  0% {
 
    opacity: 0.3;
  }
  100% {
    
    opacity: 0.5;
  }
`;

const changeBgAniamtionTwo = keyframes`
  0% {
  
    opacity: 0.7;
  }
  100% {
   
    opacity: 0.5;
  }
`;
const changeBgAniamtionSecondTwo = keyframes`
  0% {
 
    opacity: 0.7;
  }
  100% {
    
    opacity: 0.5;
  }
`;

const changeBgAniamtionThree = keyframes`
  0% {
  
    opacity: 0.8;
  }
  100% {
   
    opacity: 0.7;
  }
`;
const changeBgAniamtionSecondThree = keyframes`
  0% {
 
    opacity: 0.8;
  }
  100% {
    
    opacity: 0.7;
  }
`;

export const SingielCommentBg = styled.div<{
  startOpacity: string;
  animationOpacity: string;
  useSlideInAnimation: boolean;
  index: number;
}>`
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: ${({startOpacity}) => startOpacity};

  ${({useSlideInAnimation, index}) => {
    if (index === 3) {
      return css`
        animation: ${() =>
            useSlideInAnimation
              ? changeBgAniamtionThree
              : changeBgAniamtionSecondThree}
          0.6s ease-in;
      `;
    }
    if (index === 2) {
      return css`
        animation: ${() =>
            useSlideInAnimation
              ? changeBgAniamtionTwo
              : changeBgAniamtionSecondTwo}
          0.6s ease-in;
      `;
    } else {
      return css`
        animation: ${() =>
            useSlideInAnimation
              ? changeBgAniamtionOne
              : changeBgAniamtionSecondOne}
          0.6s ease-in;
      `;
    }
  }}
`;

export const NewCommentButtonWrapper = styled.div`
  background: #ffffff;
  height: 52px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > p {
    font-size: 11px;
    letter-spacing: -0.15px;
    color: #000000;
  }
`;

export const NewCommentButton = styled.button`
  min-width: 126px;
  height: 22.5px;
  border-radius: 15px;
  background: #3a87f0;
  color: #ffffff;
  font-size: 11px;
  letter-spacing: 0.1px;
  border: none;
  cursor: pointer;
`;

export const NewCommentUpAndDownArrowWrapper = styled.div<{Width: number}>`
  position: absolute;
  bottom: -5px;
  right: ${({Width}) =>
    Width === 9 ? '10px' : Width === 8 ? '-50px' : '-55px'};
  z-index: 4;
  display: flex;
  flex-direction: column;
`;
export const ButtonUpAndDownBg = styled.div`
  width: 38px;
  height: 21px;
  background: #f8f8f8;
  //background: rgba(23, 24, 27, 0.8);
  display: flex;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const EmptyCommentInfoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-around;
  width: 140px;
  & > p {
    font-size: 13px;
  }
`;

export const DeleteCommentButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 5px;
  bottom: 0px;
`;

export const TextLimiterWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;
`;

export const SliderWrapper = styled.div<{isMobile: boolean}>`
  /* width: 100%; */
  width: ${({isMobile}) => (isMobile ? '100%' : '100%')};
  align-self: ${({isMobile}) => (isMobile ? 'center' : ' none')};
  height: 330px;

  .slick-dots {
    bottom: 30px;
  }

  .slick-dots li {
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp100};
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    overflow: hidden;
    width: 100%;

    align-self: center;
    .slick-dots {
      bottom: 20px;
    }
  }

  @media (max-width: 420px) {
    .slick-dots {
      bottom: 25px;
    }
  }
`;
