import styled from 'styled-components';

export const MainWrapper = styled.div`
  color: ${({theme}) => theme.colors.bgWhite};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Main = styled.div`
  width: 1020px;
  height: 725px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({theme}) => theme.colors.bgWhite};
  position: absolute;
  top: 88px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);
  border-radius: 24px 24px 21px 21px;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  overflow: hidden;
  & > p {
    font-size: 14px;
    color: ${({theme}) => theme.colors.fontBlakOp100};
    line-height: 24px;
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    top: 48px;
    border: none;
    border-radius: 0px;
    height: auto;
  }
`;
export const HeaderWrapper = styled.div`
  z-index: 2;
  width: 100%;
  height: 55px;
  min-height: 55px;
  font-size: 15px;
  line-height: 24px;
  /* border-radius: 24px 24px 0 0; */
  letter-spacing: 0.625px;
  font-weight: 400;
  background: ${({theme}) => theme.colors.pageHeader};
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  color: ${({theme}) => theme.colors.fontBlackOp100};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const NavigateWrapper = styled.div`
  width: 100%;
  padding-left: 18px;
  display: flex;
  margin-bottom: 15px;
  height: 15px;
  & > p {
    padding-left: 7px;
    font-size: 11px;
    line-height: 21px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    & > span {
      padding: 0 5px;
    }
  }
  & > h4 {
    padding-left: 7px;
    font-size: 11px;
    line-height: 21px;
    letter-spacing: -0.15px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    & > span {
      padding: 0 5px;
    }
  }
  & > a {
    text-decoration: none;
    & > p {
      padding-left: 7px;
      font-size: 11px;
      line-height: 21px;
      letter-spacing: -0.15px;
      color: ${({theme}) => theme.colors.fontBlackOp50};
      & > span {
        padding: 0 5px;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 0 28px;
    /* height: auto; */
    margin-bottom: 12px;
    margin-top: 12px;
    & > p {
    }
    & > h4 {
      width: 121px;
      overflow: hidden;
      -webkit-line-clamp: 1;
      display: -webkit-box;
    }
  }
`;
export const ScrollWrapper = styled.div`
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  height: auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
  @media (max-width: 1024px) {
    overflow-y: auto;
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 716px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const ImgTitleLeadCategoryWrapper = styled.div`
  display: flex;
  gap: 23px;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const ImgWithText = styled.div`
  width: 264px;
  height: 280px;
  & > img {
    width: 264px;
    height: 264px;
  }
  & > p {
    text-align: right;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    font-size: 9px;
    line-height: 21px;
    letter-spacing: -0.15px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    & > img {
      width: 100%;
      height: auto;
      aspect-ratio: 1;
    }
    & > p {
      padding-right: 6px;
    }
  }
`;

export const DetailTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 418px;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 28px;
  }
`;

export const CategoryText = styled.p`
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
  line-height: 18px;
  letter-spacing: -0.05px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
`;
export const TitleText = styled.h1`
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.15px;
  font-weight: 400;
  padding-bottom: 13px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  @media (max-width: 1024px) {
    max-width: 356px;
  }
`;
export const LeadText = styled.p`
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.15px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  @media (max-width: 1024px) {
    margin-top: 12px;
  }
`;

export const LongTextWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 30px;
  font-size: 15px;
  line-height: 24px;
  -webkit-letter-spacing: -0.15px;
  -moz-letter-spacing: -0.15px;
  -ms-letter-spacing: -0.15px;
  letter-spacing: -0.15px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  & > p {
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    padding: 0 28px;
    margin-top: 40px;
    padding-bottom: 200px;
  }
`;

export const MobileTitleWrapper = styled.div`
  padding: 0 28px;
`;
