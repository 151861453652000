import styled from 'styled-components';

export const BannerBlockWrapper = styled.div<{
  backgroundAlpha: number;
  backgroundColor: string;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`};
  /* border-radius: 24px; */
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
`;

export const BannerBlockHeader = styled.div<{theme?: string}>`
  height: 10%;
  width: 100%;
  display: flex;
  gap: 11px;
  align-items: center;

  & > h4 {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    padding: 0 5px;
  }

  & > svg {
    width: 27.38px;
    height: 21px;
    & > path {
      fill: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  @media (max-width: 1065px) {
    height: 5%;
  }
  @media (max-width: 920px) {
    & > h4 {
      font-size: 16px;
    }
    & > svg {
      height: 16px;
    }
  }
  @media (max-width: 780px) {
    & > h4 {
      font-size: 14px;
    }
    & > svg {
      height: 14px;
    }
  }
  @media (max-width: 720px) {
    & > h4 {
      font-size: 12px;
    }
    & > svg {
      height: 12px;
    }
  }
  @media (max-width: 650px) {
    & > h4 {
      padding: 0;
      font-size: 11px;
    }
    & > svg {
      height: 11px;
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      font-size: 10px;
    }
    & > svg {
      height: 10px;
    }
  }
  @media (max-width: 550px) {
    & > h4 {
      font-size: 8px;
    }
    & > svg {
      height: 8px;
    }
  }
  @media (max-width: 430px) {
    & > h4 {
      font-size: 6px;
    }
    & > svg {
      height: 6px;
    }
  }
`;

export const BannerBlockContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const BannerListWrapper = styled.div<{Width?: number; Height?: number}>`
  /* width: 158px; */

  background: #f1f1f2;
  position: absolute;
  text-align: center;
  /* left: ${({Width}) =>
    Width === 9
      ? '10px'
      : Width === 8
      ? '-50px'
      : Width === 7
      ? '-105px'
      : Width === 6
      ? '-160px'
      : '-178px'};
  top: ${({Height}) => (Height === 1 ? '-115px' : '0px')}; */

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 9px;
  z-index: 99;
`;

export const BannerItemForList = styled.p`
  border-top: 1px solid rgba(112, 112, 112, 0.3);
  font-size: 15px;
  padding: 0 20px;
  color: rgba(7, 2, 15, 0.5);
  line-height: 32px;
  cursor: pointer;
  &:hover {
    color: rgba(7, 2, 15, 0.8);
  }
`;

export const BannerHoverImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 10;
`;

export const BannerImgWrapper = styled.div<{Width?: number; Height?: number}>`
  position: relative;
  width: 100%;
  height: 100%;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    //aspect-ratio: 16/9;
  }

  @media (max-width: 1024px) {
  }
`;
export const DeleteButton = styled.button<{
  fontColor: string;
  Width?: number;
  Height?: number;
}>`
  position: absolute;
  top: ${({Height}) => (Height === 1 ? '5px' : Height === 2 ? '15px' : '25px')};
  right: ${({Width}) => (Width === 1 ? '10px' : Width === 2 ? '20px' : '35px')};
  font-size: 18px;
  background-color: transparent;
  padding: 5px;
  border: none;
  color: ${({fontColor}) => `rgb(${fontColor})`};
  cursor: pointer;
`;

export const BannerAddButonWrapper = styled.div<{theme: string}>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 48px;
    height: 48px;
    cursor: pointer;
    & > g {
      & > path {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};
      }
    }
  }
`;
