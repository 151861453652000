import styled, {css} from 'styled-components';

import arrowUp from 'images/svg/blue-arrow-up.svg';
import arrowDown from 'images/svg/blue-arrow-down.svg';
import infoIcon from 'images/svg/black-info.svg';
import approveIcon from 'images/svg/transparent-approve-green-bg.svg';
import disapproveIcon from 'images/svg/transparent-disapprove-red-bg.svg';
import arrowLeft from 'images/svg/transparent-left-arrow.svg';
import arrowRight from 'images/svg/transparent-arrow-right.svg';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: 738px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  position: relative;
  /* margin-bottom: 20px; */
  pointer-events: all;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const Grid = styled.div`
  width: 100%;
  gap: 9px;
  height: 571px;
  border-radius: 16px 16px 24px 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 571px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    height: unset;
  }
`;

export const ClientSelectWrapper = styled.div`
  width: 334px;
  height: 38px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    position: absolute;
    top: -6px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > select {
    width: 90%;
    color: white;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: center;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: 282px 4px;

    & > option {
      background: #060817;
    }
  }

  @media (max-width: 1045px) {
    width: 270px;

    & > select {
      font-size: 10px;
      background-position: 226px 4px;
    }
  }
`;

export const WinWinSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  @media (max-width: 1045px) {
    flex-direction: column;
    padding: 14px 20px;
  }
`;

export const WinWinSectionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > h4 {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: white;
    padding-left: 15px;
    text-transform: uppercase;

    @media (max-width: 1045px) {
      padding-left: 0;
      margin-bottom: 5px;
    }
  }

  & > p {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.86);
  }

  @media (max-width: 1045px) {
    text-align: center;
    width: 100% !important;
    margin-bottom: 15px;
  }
`;

export const PriceSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 35px;

  & > h4 {
    font-size: 13px;
    font-weight: 500;
    color: white;
  }

  & > p {
    font-size: 11px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.86);
  }
`;

export const TwoInputsSpan = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;

  @media (max-width: 1045px) {
    flex-direction: column;
  }
`;

export const InvoiceInlineSpan = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    font-size: 13px;
    color: white;
  }

  @media (max-width: 1045px) {
    display: none;
  }
`;

export const MobileInvoiceInlineSpan = styled.div`
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;

  & > p {
    font-size: 13px;
    color: white;
  }

  @media (max-width: 1045px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MainHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 9px;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const HeaderButton = styled.button`
  background: transparent;
  border: 1px solid white;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-size: 9px;
  font-weight: 400;
  color: white;
  padding: 0 12px;
  line-height: 20px;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const DescriptionWrapper = styled.div`
  width: 1000px;
  min-height: 82px;
  padding: 13px 72px;
  border-radius: 9px;
  background: rgba(255, 255, 255, 0.07);
  margin-top: 8px;
  margin-bottom: 15px;

  & > p {
    font-size: 11px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: rgba(255, 255, 255, 0.86);
    text-align: justify;
  }
  @media (max-width: 1045px) {
    width: 100%;
    padding: 11px 27px;
  }
`;

export const BasicDataWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: span 1;
  /* grid-row: span 4; */
  height: 464px;

  @media (max-width: 1045px) {
    height: 464px;
    background: rgba(6, 8, 23, 0.85);
    /* grid-row: span 1; */
  }
`;

export const BasicDataHeader = styled.div`
  width: 100%;
  position: relative;
  border-radius: 9px 9px 0 0;
  background: #00ff9d;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: #060817;
  }
`;

export const BasicDataContent = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
  padding: 23px 15px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const ProductsWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: 1/2;
  /* grid-row: span 3; */
  height: 400px;

  @media (max-width: 1045px) {
    height: 372px;
    background: rgba(6, 8, 23, 0.95);
    order: 99;
    grid-row: span 1;
  }
`;

export const ProductsHeader = styled.div`
  width: 100%;
  position: relative;
  border-radius: 9px 9px 0 0;
  background: #187ee4;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: #060817;
  }
`;

export const ProductsContent = styled.div`
  width: 100%;
  padding: 12px 40px;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 24px;
    height: 10px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-image: url(${arrowUp});
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment {
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #125faf;
    border-radius: 25px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
`;

export const SingleProductWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  justify-content: space-between;
  text-align: center;

  &:last-of-type {
    border-bottom: none;
  }

  & > h4 {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: #187ee4;
  }

  & > p {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: white;
  }
`;

export const ConditionBlockWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: span 2;
  /* grid-row: span 3; */
  height: 400px;

  @media (max-width: 1045px) {
    display: none;
  }
`;

export const MobileConditionBlockWrapper = styled.div`
  border-radius: 9px;
  display: none;
  height: 510px;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: span 1;
  grid-row: span 2;

  @media (max-width: 1045px) {
    display: flex;
    background: rgba(6, 8, 23, 1);
  }
`;

export const ConditionSecondBlockWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  /* grid-column: span 2; */
  /* grid-row: span 2; */
  grid-row: span 5;
  grid-column: 2/4;

  @media (max-width: 1045px) {
    grid-column: span 1;
    grid-row: span 2;
    background: rgba(6, 8, 23, 0.95);
  }
`;

export const ConditionThirdBlockWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: span 2;
  grid-row: span 1;

  @media (max-width: 1045px) {
    grid-column: span 1;
    background: rgba(6, 8, 23, 0.95);
  }
`;

export const ConditionWinWinOfferWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: 2/4;
  grid-row: span 1;

  @media (max-width: 1045px) {
    grid-column: span 1;
    background: rgba(6, 8, 23, 0.95);
  }
`;

export const ConditionBlockHeader = styled.div`
  width: 100%;
  position: relative;
  border-radius: 9px 9px 0 0;
  background: transparent;
  height: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: white;
  }
`;

export const ConditionHeaderTitle = styled.p`
  font-size: 14px;
  color: white;
`;

export const ConditionMainContent = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionSelectSpan = styled.div`
  display: flex;
  justify-content: space-between;
  width: 232px;

  @media (max-width: 1045px) {
    justify-content: space-evenly;
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  height: 38px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    position: absolute;
    top: -7px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > input {
    width: 100%;
    height: 100%;
    padding: 4px 14px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: center;
    outline: none;

    ::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  height: 43px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    position: absolute;
    top: -6px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > select {
    width: 90%;
    color: white;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: center;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: 255px 4px;

    & > option {
      background: #060817;
    }
  }
`;

export const TextareaWrapper = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
}>`
  position: relative;
  width: 100%;
  border-radius: 5px;
  min-height: 173px;
  background: ${({isEditing, isError}) =>
    isEditing
      ? isEditing && isError
        ? '#FF7E7D'
        : 'transparent'
      : 'transparent'};
  border: none;
  border-radius: ${({isEditing, isError}) =>
    isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
  color: ${({isEditing}) => (isEditing ? `#707070` : `#07020F`)};

  & > h5 {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.625px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    position: absolute;
    top: 2px;
    left: 11.5px;
  }

  & > h4 {
    display: ${({isEditing}) => (isEditing ? 'unset' : 'none')};
  }
`;

export const StyledTextarea = styled.textarea<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  width: 100%;
  min-height: 173px;
  height: 100%;
  border: none;
  padding: 20px 11.5px 6px;
  background: ${({theme}) => theme.colors.textareaBackground};
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-size: 12px;
  border: solid 1px ${({theme}) => theme.colors.fontBlackOp15};
  line-height: 24px;
  border-radius: 5px;
  resize: none;
  outline: none;
  font-weight: 400;

  &:focus {
    border: 1px solid #0091ff;
  }
`;

export const SectionTextareaWrapper = styled.div`
  position: relative;
  width: 633px;
  height: 114px;
  margin-bottom: 20px;

  & > p {
    position: absolute;
    top: -9px;
    width: 100%;
    text-align: center;
    font-size: 9px;
    letter-spacing: 0.5px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.48);
  }

  @media (max-width: 1045px) {
    width: 310px;
    height: 248px;
    margin-bottom: 20px;
  }
`;

export const InfoIcon = styled.div`
  display: flex;
  width: 21px;
  height: 21px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const ConditionContentHeader = styled.div`
  width: 100%;
  display: flex;
  height: 55px;
  align-items: center;
  text-align: center;
  position: relative;

  & > p {
    font-size: 9px;
    font-weight: 600;
    color: white;
    letter-spacing: 0px;
    text-align: center;
    position: absolute;

    &:nth-child(1) {
      top: 10px;
      left: 66px;
    }

    &:nth-child(2) {
      top: 10px;
      left: 208px;
    }

    &:nth-child(3) {
      bottom: 2px;
      left: 214px;
    }

    &:nth-child(4) {
      bottom: 2px;
      left: 294px;
    }

    &:nth-child(5) {
      top: 10px;
      right: 172px;
    }

    &:nth-child(6) {
      top: 10px;
      right: 48px;
    }
  }

  & > div {
    position: absolute;
    pointer-events: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    height: 56px;

    &:nth-child(7) {
      top: 4px;
      left: 170px;
      width: 178px;
    }

    &:nth-child(8) {
      top: 4px;
      left: 354px;
      width: 218px;
    }
  }
`;

export const ConditionContent = styled.div`
  width: 100%;
  padding: 9px;
  height: calc(100% - 42px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 24px;
    height: 10px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-image: url(${arrowUp});
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment {
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #125faf;
    border-radius: 25px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
`;

export const SingleConditionWrapper = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  padding: 5px 6px;
  align-items: center;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 9px;
`;

export const ConditionSelectWrapper = styled.div`
  width: 148px;
  height: 38px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    position: absolute;
    top: -6px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > select {
    width: 90%;
    color: white;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: center;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: 106px 4px;

    & > option {
      background: #060817;
    }
  }
`;

export const ConditionCheckbox = styled.div<{value: boolean}>`
  width: 27px;
  height: 27px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-position: center center;
  background-image: url(${({value}) => (value ? approveIcon : disapproveIcon)});
`;

export const WinWinOfferCheckbox = styled.div<{value: boolean}>`
  width: 27px;
  height: 27px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 35px;
  cursor: pointer;
  background-position: center center;
  background-image: url(${({value}) => (value ? approveIcon : disapproveIcon)});

  @media (max-width: 1045px) {
    position: unset;
  }
`;

export const NameWrapper = styled.div`
  width: 148px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);

  & > p {
    font-size: 13px;
    font-weight: 500;
    color: white;
  }
`;

export const MobileSliderWrapper = styled.div`
  width: 100%;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > p {
    font-size: 13px;
    letter-spacing: 0.5px;
    font-weight: 500;
    line-height: 24px;
    color: white;
  }

  & > img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const MobileConditionMainContent = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9px;
  padding-bottom: 0px;
`;

export const MobileSingleConditionWrapper = styled.div`
  width: 100%;
  height: calc(100% - 39px);
  background: rgba(255, 255, 255, 0.07);
  border-radius: 9px;
  padding-top: 15px;
  padding-bottom: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileTitle = styled.p`
  font-size: 9px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 9px;
  letter-spacing: 0px;
  color: white;
`;

export const InlineSpan = styled.div`
  width: 165px;
  display: flex;
  justify-content: space-between;

  & > p {
    width: 45%;
  }
`;

export const NumberWrapper = styled.div`
  width: 74px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);

  & > p {
    font-size: 13px;
    font-weight: 500;
    color: white;
  }
`;

export const ConditionSwitch = styled.div<{value: boolean}>`
  width: 48px;
  height: 28px;
  border-radius: 25px;
  border: 1px solid
    ${({value}) => (value ? '#19d627' : 'rgba(255,255,255,0.11)')};
  position: relative;
  cursor: pointer;
  transition: all 0.15s;

  & > div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    transition: all 0.15s ease-in;

    ${({value}) =>
      value
        ? css`
            left: 20px;
            top: 1px;
            background: #19d627;
          `
        : css`
            left: 1px;
            top: 1px;
            background: rgba(255, 255, 255, 0.21);
          `};
  }
`;

export const HeaderSwitch = styled(ConditionSwitch)`
  position: absolute;
  right: 3px;
`;

export const FirstSection = styled.div`
  width: 100%;
  padding: 32px 35px 23px 23px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.21); */
  position: relative;

  & > p {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.48);
    text-align: center;
    position: absolute;

    &:nth-of-type(1) {
      top: 10px;
      left: 242px;
      width: 40px;
    }
    &:nth-of-type(2) {
      top: 9px;
      width: 48px;
      left: 603px;
    }
  }

  @media (max-width: 1045px) {
    display: none;
  }
`;

export const MobileFirstSection = styled.div`
  width: 100%;
  padding: 24px;
  display: none;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  position: relative;
  flex-direction: column;

  & > p {
    font-size: 9px;
    line-height: 11px;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.48);
    text-align: center;
  }

  @media (max-width: 1045px) {
    display: flex;
  }
`;

export const MobileInputWithSelectSpan = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
`;

export const MobileSelectSpan = styled.div`
  width: 220px;
  display: flex;
  justify-content: space-between;
  margin-top: 38px;
  margin-bottom: 28px;
`;

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);

  @media (max-width: 1045px) {
    flex-direction: column;
    padding: 14px 20px;
  }
`;

export const SectionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > h4 {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: white;
    padding-left: 15px;
    text-transform: uppercase;

    @media (max-width: 1045px) {
      padding-left: 0;
      margin-bottom: 5px;
    }
  }

  & > p {
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 1045px) {
    text-align: center;
    width: 100% !important;
    margin-bottom: 15px;
  }
`;

export const WinWinOfferSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 20px;
  border: none;
  position: relative;

  @media (max-width: 1045px) {
    flex-direction: column;
    padding: 14px 20px;
  }
`;

export const WinWinOfferSectionTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > h4 {
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: white;
    text-align: center;

    @media (max-width: 1045px) {
      padding-left: 0;
      margin-bottom: 5px;
    }
  }

  & > p {
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 1045px) {
    text-align: center;
    width: 100% !important;
    margin-bottom: 15px;
  }
`;
