import {
  FullScreenBtn,
  MuteBtn,
  VideoActions,
  VideoBlockContainer,
  VideoBlockVelocity,
  VideoControls,
  VideoWrapper,
  DeleteButton,
} from '../styles';
import React, {useRef} from 'react';
import useVideoPlayer from '../../../../../../hooks/useVideoPlayer';
import {Block} from '../../../interface';
import {ProgressSvg} from '../progressSvg';

interface Props {
  video: string;
  block: Block;
  theme: string;
  isEditing: boolean;
  deleteVideo: () => void;
}

export const VideoComponent: React.FC<Props> = ({
  video,
  block,
  theme,
  deleteVideo,
  isEditing,
}) => {
  const videoElement = useRef(null);
  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    toggleFullscreen,
  } = useVideoPlayer(videoElement);

  const {WebArea} = block;
  const {Width, Height} = WebArea;

  return (
    <VideoBlockContainer>
      <VideoWrapper Width={Width} Height={Height}>
        <video
          src={video}
          ref={videoElement}
          onTimeUpdate={handleOnTimeUpdate}
        />
        {/*{isEditing && (*/}
        {/*  <DeleteButton*/}
        {/*    onClick={deleteVideo}*/}
        {/*    Width={Width}*/}
        {/*    Height={Height}*/}
        {/*    theme={theme}*/}
        {/*  >*/}
        {/*    X*/}
        {/*  </DeleteButton>*/}
        {/*)}*/}
        <VideoControls Width={Width} Height={Height}>
          {(Width === 2 && Height === 2) ||
          (Width === 5 && Height === 3) ||
          (Width === 2 && Height === 4) ? (
            <input
              type="range"
              min="0"
              max="100"
              value={playerState.progress}
              onChange={(e) => handleVideoProgress(e)}
            />
          ) : null}
          {/*{Width === 2 && Height === 4 ? (*/}
          {/*  <ProgressSvg*/}
          {/*    color={`rgb(${block.BackgroundColorHex})`}*/}
          {/*    percent={playerState.progress}*/}
          {/*  />*/}
          {/*) : null}*/}
          <VideoActions Width={Width} Height={Height}>
            <button onClick={togglePlay}>
              {!playerState.isPlaying ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="52"
                  height="44"
                  viewBox="0 0 52 44"
                >
                  <defs>
                    <filter
                      id="Background"
                      x="0"
                      y="0"
                      width="52"
                      height="44"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.302" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    id="Group_5721"
                    data-name="Group 5721"
                    transform="translate(-885 -560)"
                  >
                    <g
                      transform="matrix(1, 0, 0, 1, 885, 560)"
                      filter="url(#Background)"
                    >
                      <rect
                        id="Background-2"
                        data-name="Background"
                        width="34"
                        height="26"
                        rx="7"
                        transform="translate(9 6)"
                        fill="#f1f1f2"
                      />
                    </g>
                    <path
                      id="Icon_open-media-play"
                      data-name="Icon open-media-play"
                      d="M0,0V10.773L10.773,5.386Z"
                      transform="translate(907 573.5)"
                      fill="#f23591"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="52"
                  height="44"
                  viewBox="0 0 52 44"
                >
                  <defs>
                    <filter
                      id="Background"
                      x="0"
                      y="0"
                      width="52"
                      height="44"
                      filterUnits="userSpaceOnUse"
                    >
                      <feOffset dy="3" />
                      <feGaussianBlur stdDeviation="3" result="blur" />
                      <feFlood floodOpacity="0.302" />
                      <feComposite operator="in" in2="blur" />
                      <feComposite in="SourceGraphic" />
                    </filter>
                  </defs>
                  <g
                    id="Group_5722"
                    data-name="Group 5722"
                    transform="translate(-922 -560)"
                  >
                    <g
                      transform="matrix(1, 0, 0, 1, 922, 560)"
                      filter="url(#Background)"
                    >
                      <rect
                        id="Background-2"
                        data-name="Background"
                        width="34"
                        height="26"
                        rx="7"
                        transform="translate(9 6)"
                        fill="#f1f1f2"
                      />
                    </g>
                    <path
                      id="Icon_open-media-stop"
                      data-name="Icon open-media-stop"
                      d="M0,0V9H9V0Z"
                      transform="translate(944 574.5)"
                      fill="#f23591"
                    />
                  </g>
                </svg>
              )}
            </button>
          </VideoActions>
          <VideoBlockVelocity
            value={playerState.speed}
            onChange={(e) => handleVideoSpeed(e)}
          >
            <option value="0.50">0.50</option>
            <option value="1">1</option>
            <option value="1.25">1.25</option>
            <option value="2">2</option>
          </VideoBlockVelocity>
          <MuteBtn onClick={toggleMute}>
            {!playerState.isMuted ? (
              <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M16 21c3.527-1.547 5.999-4.909 5.999-9S19.527 4.547 16 3v2c2.387 1.386 3.999 4.047 3.999 7S18.387 17.614 16 19v2z"></path>
                <path d="M16 7v10c1.225-1.1 2-3.229 2-5s-.775-3.9-2-5zM4 17h2.697L14 21.868V2.132L6.697 7H4c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2z"></path>
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg">
                <path d="m7.727 6.313-4.02-4.02-1.414 1.414 18 18 1.414-1.414-2.02-2.02A9.578 9.578 0 0 0 21.999 12c0-4.091-2.472-7.453-5.999-9v2c2.387 1.386 3.999 4.047 3.999 7a8.13 8.13 0 0 1-1.671 4.914l-1.286-1.286C17.644 14.536 18 13.19 18 12c0-1.771-.775-3.9-2-5v7.586l-2-2V2.132L7.727 6.313zM4 17h2.697L14 21.868v-3.747L3.102 7.223A1.995 1.995 0 0 0 2 9v6c0 1.103.897 2 2 2z"></path>
              </svg>
            )}
          </MuteBtn>
          <FullScreenBtn onClick={toggleFullscreen}>
            <svg xmlns="http://www.w3.org/2000/svg">
              <path d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z"></path>
            </svg>
          </FullScreenBtn>
        </VideoControls>
      </VideoWrapper>
    </VideoBlockContainer>
  );
};
