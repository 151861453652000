import React, {useContext, useRef, useState} from 'react';
import {
  PopUpComponent,
  PopUpButton,
  PopUpContentWrapper,
  PopupHeader,
  WrapperForButton,
} from '../components/PopUp';

type UseModalShowReturnType = {
  show: boolean;
  setShow: (value: boolean) => void;
  onHide: () => void;
};

const useModalShow = (): UseModalShowReturnType => {
  const [show, setShow] = useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide,
  };
};

type ModalContextType = {
  showConfirmation: (
    buttons: string[],
    title: string,
    message: string | JSX.Element,
  ) => Promise<boolean>;
};

type ConfirmationModalContextProviderProps = {
  children: React.ReactNode;
};

const ConfirmationModalContext = React.createContext<ModalContextType>(
  {} as ModalContextType,
);

const ConfirmationModalContextProvider: React.FC<
  ConfirmationModalContextProviderProps
> = (props) => {
  const {setShow, show, onHide} = useModalShow();
  const [content, setContent] = useState<{
    buttons: string[];
    title: string;
    message: string | JSX.Element;
  } | null>();
  const resolver = useRef<(...args) => void>();

  const handleShow = (
    buttons: string[],
    title: string,
    message: string | JSX.Element,
  ): Promise<boolean> => {
    setContent({
      buttons,
      title,
      message,
    });
    setShow(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const modalContext: ModalContextType = {
    showConfirmation: handleShow,
  };

  const handleOk = () => {
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}

      {content && (
        <PopUpComponent show={show} onHide={onHide}>
          <PopUpContentWrapper>
            <PopupHeader>
              <h4 style={{fontSize: '13px', marginBottom: 0}}>
                {content.title}
              </h4>
            </PopupHeader>
            <p>{content.message}</p>
            <WrapperForButton>
              <PopUpButton style={{width: '110px'}} onClick={handleCancel}>
                {content.buttons[0]}
              </PopUpButton>
              <PopUpButton
                style={{width: '110px'}}
                isBlue={true}
                onClick={handleOk}
              >
                {content.buttons[1]}
              </PopUpButton>
            </WrapperForButton>
          </PopUpContentWrapper>
          <></>
        </PopUpComponent>
      )}
    </ConfirmationModalContext.Provider>
  );
};

const useConfirmationModalContext = (): ModalContextType =>
  useContext(ConfirmationModalContext);

export {useModalShow, useConfirmationModalContext};

export default ConfirmationModalContextProvider;
