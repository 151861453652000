import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 894px;
  height: 894px;
  display: flex;
  background: #000000;
  margin-top: 36px;
  border-radius: 24px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.12);
  /* padding: 2px; */
  position: relative;
  align-items: center;
  justify-content: center;
  /* gap: 4px; */

  & > svg {
    position: absolute;
    right: 31px;
    bottom: 30px;
    cursor: pointer;
  }

  & > img {
    border-radius: 24px;
    height: 100%;
    width: 100%;
    user-select: none;

    &:first-of-type {
      display: unset;
    }

    &:last-of-type {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* display: none; */
    width: unset;
    flex-direction: column;
    height: unset;
    align-items: center;
    padding: 0px;

    & > img {
      border-radius: 18px;
      height: unset;
      width: 100%;

      &:first-of-type {
        display: none;
      }

      &:last-of-type {
        display: unset;
      }
    }

    & > svg {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* height: 746px; */

    & > img {
      height: unset;
      width: 100%;
    }
  }
`;

export const TransparentDiv = styled.div<{isAudioPlayed: boolean}>`
  width: 100%;
  height: 100vh;
  /* background: rgba(255, 0, 0, 0.2); */
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 5;

  display: ${({isAudioPlayed}) => (isAudioPlayed ? 'block' : 'none')};
`;

export const TracksWrapper = styled.div`
  width: calc(100% - 358px);
  height: 357px;
  display: flex !important;
  gap: 5px;
  justify-content: flex-start;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    justify-content: center;
    gap: 7px;
  }
`;

export const SliderWrapper = styled.div`
  width: 706px;
  height: 362px;
  position: absolute;
  bottom: 55px;

  & > div {
    height: 100%;
  }

  .slick-dots {
    bottom: -30px;
    /* text-align: unset; */
  }

  .slick-dots li {
    border: 1px solid white;
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: white;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileSliderWrapper = styled.div`
  display: none;
  margin-top: 11px;
  width: 100%;
  /* width: 359px; */
  height: 390px;
  position: absolute;
  align-items: center;
  bottom: 10px;

  & > div {
    height: 100%;
  }

  .slick-dots {
    bottom: 4px;
    text-align: center;
  }

  .slick-dots li {
    border: 1px solid white;
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: white;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    display: unset;
  }
`;

export const DesktopPlayButtonWrapper = styled.div`
  /* width: 58px;
    height: 58px; */
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  position: absolute;
  gap: 15px;
  /* right: 18px; */
  /* top: 59px; */
  bottom: 23px;
  z-index: 4;

  & > img {
    /* width: 58px;
      height: 58px; */
    height: 48px;
    width: 48px;
    cursor: pointer;

    &:hover {
      filter: brightness(0.95);
    }
  }
`;

export const SingleTrack = styled.div`
  width: 173px;
  border-radius: 21px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.5);
  transition: background 0.15s;
  position: relative;

  & > img {
    height: 173px;
    width: 173px;
    border-radius: 21px;
    margin-bottom: 4px;
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 19px;

    & > h1 {
      height: 30px;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.05px;
      color: #ffffff;
      /* margin-bottom: 12px; */
      display: flex;
      align-items: center;
      justify-content: center;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
    }

    & > h5 {
      height: 30px;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.05px;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
    }

    & > h2 {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.15px;
      line-height: 32px;
      color: white;
      text-align: center;
    }

    & > p {
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.05px;
      color: white;
      text-align: center;
      /* margin-bottom: 4px; */
    }

    & > h4 {
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.05px;
      color: rgba(255, 255, 255, 0.5);
      text-align: center;
    }

    & > svg {
      width: 51.56px;
      height: 13px;
      /* margin-bottom: 5px; */
    }
  }

  &:hover {
    /* background: #242424; */
    filter: brightness(0.95);

    /* & > img {
      filter: brightness(1.2);
    } */
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
  }
`;

export const MobileSingleTrack = styled.div`
  width: 173px;
  height: 276px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  background: #ffffff;
  padding: 7px;
  transition: background 0.15s;
  border: 1px solid rgba(112, 112, 112, 0.3);

  & > img {
    height: 158px;
    width: 158px;
    border-radius: 18px;
    margin-bottom: 11px;
    border: 1px solid rgba(112, 112, 112, 0.3);
  }

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 12px;
    padding-right: 4px;

    & > h1 {
      height: 30px;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: -0.05px;
      color: #000000;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    & > h2 {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.15px;
      color: #000000;
    }

    & > p {
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.05px;
      color: #000000;
      margin-bottom: 4px;
    }

    & > h4 {
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.05px;
      color: rgba(0, 0, 0, 0.5);
    }

    & > svg {
      width: 51.56px;
      height: 13px;
      /* margin-bottom: 5px; */

      & path {
        fill: black;
      }
    }
  }

  &:hover {
    background: #ffffff;

    & > img {
      filter: brightness(1);
    }
  }
`;
