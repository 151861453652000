import styled from 'styled-components';
import {SectionHeader} from '../PopularCategories/styles';

export const StyledSectionHeader = styled(SectionHeader)`
  & > h1 {
    &:first-of-type {
      display: unset;
    }

    &:last-of-type {
      display: none;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      &:first-of-type {
        display: none;
      }

      &:last-of-type {
        display: unset;
      }
    }
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > h1 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 392px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    /* display: none; */
    width: 100%;
  }
`;

export const ArticlesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileArticlesWrapper = styled.div`
  display: none;
  width: calc(100%);
  height: 576px;
  /* align-items: center; */
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex !important;
  }
`;

export const SliderWrapper = styled.div`
  display: none;
  /* margin-bottom: 10px; */
  width: 100%;
  /* margin-bottom: 14px; */
  /* height: 330px; */

  .slick-dots {
    bottom: -8px;
  }

  .slick-dots li {
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp100};
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: unset;
  }
`;

export const SingleArticleWrapper = styled.div`
  width: 292px;
  height: 546px;
  background: ${({theme}) => theme.colors.singleArticleBackground};
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2px 2px 12px;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.12);
  transition: background 0.15s;
  cursor: pointer;

  &:hover {
    background: ${({theme}) => theme.colors.singleArticleHoverBackground};

    & > img {
      filter: brightness(1.1);
    }
  }

  & > img {
    height: 288px;
    width: 288px;
    border-radius: 22px;
  }

  & > h2 {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.05px;
    line-height: 18px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    padding: 0 12px;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  & > h1 {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    padding: 0 12px;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    padding: 0 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
  }
`;

export const MobileSingleArticleWrapper = styled.div`
  width: 392px;
  height: 546px;
  background: ${({theme}) => theme.colors.mainHeader};
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 9px 2px 4px;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.12);
  transition: background 0.15s;
  cursor: pointer;

  &:hover {
    background: ${({theme}) => theme.colors.singleArticleHoverBackground};

    & > img {
      filter: brightness(1.1);
    }
  }

  & > img {
    height: 372px;
    width: 372px;
    border-radius: 18px;
  }

  & > h2 {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.05px;
    line-height: 18px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    padding: 0 16px;
    width: 100%;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  & > h1 {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    padding: 0 16px;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    padding: 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: calc(100% - 14px);
    padding-top: 2px;

    & > img {
      width: 100%;
      aspect-ratio: 1/1;
      border-radius: 22px;
    }
  }
`;
