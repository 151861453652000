import styled from 'styled-components';

export const MainWrapper = styled.div`
  //left: 0;
  //top: 0;
  top: 65%;
  left: 46%;
  //margin-right: -50%;
  //transform: translate(-50%, -50%);
  width: 113px;
  height: 113px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const CloseAdd = styled.div`
  position: fixed;
  z-index: 1;
  /* background-color: transparent; */
  background: rgba(0, 0, 0, 0.7);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  /* top: 144px; */
`;

export const Background = styled.div`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: #3a87f0;
  opacity: 0.05;
`;

export const SvgWrapper = styled.div`
  z-index: 2;
  cursor: pointer;
`;

export const ListingWrapper = styled.div<{isLaptop: boolean}>`
  z-index: 2;
  bottom: -40px;

  /* right: 226px; */
  right: 406px;
  position: absolute;
  width: 158.5px;
  //height: 298px;
  border-radius: 9px;
  background: #f1f1f2;
  border: 1px solid rgba(112, 112, 112, 0.3);
`;

export const ListingItem = styled.p<{firstItem?: boolean}>`
  text-align: center;
  height: 27px;
  font-size: 11px;
  line-height: 24px;
  color: rgba(7, 2, 15, 0.5);
  letter-spacing: 0.15px;
  cursor: pointer;
  border-top: ${({firstItem}) =>
    firstItem ? 'none' : '1px solid rgba(112, 112, 112, 0.3)'};

  &:hover {
    color: #3a87f0;
    background: rgba(58, 135, 240, 0.15);
  }
`;
