import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

import api from 'services/axiosConfig';
import useAlert from 'hooks/useAlert';
import mugoImage from 'images/post_mugo.png';
import checkboxUncheckedSvg from 'images/svg/regulation-checkbox.svg';
import openEye from 'images/svg/openEye.svg';
import closeEye from 'images/svg/closeEye.svg';

import greenApproveRegisterIconSvg from 'images/svg/green-approve-register-icon.svg';

import {ErrorStar} from 'components/ErrorStar';
import {PhoneCodeSelect} from '../../../components/Select/PhoneCodeSelect';
import {LoadingCircle} from 'utils/loadingCircle';
import {MobileInput} from 'components/MobileInput';
import {InputView} from 'pages/MyAccount/Settings/styles';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.colors.pageBodyLeft};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const MobileMain = styled.div`
  width: 348px;
  min-height: calc(100vh - 105px);
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  padding: 40px 0px 14px;
  flex-direction: column;
  align-items: center;
  background: transparent;

  & > a {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-decoration: underline;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    padding: 40px 14px 14px;
  }
`;

export const MobilePageHeader = styled.h4`
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0.1px;
`;

export const PhoneCodeSelectWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    & svg {
      width: 8.6585px;
      height: 14.5px;
    }
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  /* @media (max-width: 1045px) {
    width: 360px;
  } */
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: 1fr;
  gap: 11px 0;
  grid-auto-rows: unset;

  @media (max-width: 1045px) {
  }
`;

export const Checkbox = styled.div<{value?: boolean}>`
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-image: url(${checkboxUncheckedSvg});
  background-image: ${({value}) =>
    value
      ? `url(${greenApproveRegisterIconSvg})`
      : `url(${checkboxUncheckedSvg})`};
  background-position: center center;
  position: absolute;
  left: 0px;
  top: 4px;
  cursor: pointer;
  //filter: ${({value}) => value && 'hue-rotate(270deg)'};
`;

export const RegulationsContainer = styled.div<{isRegulationsOpen: boolean}>`
  ${({isRegulationsOpen}) =>
    isRegulationsOpen &&
    css`
      position: fixed;
      width: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(6px);
      padding: 30px 0;
      top: 0;
      left: 0;
      z-index: 5;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.15s;
    `}
`;

export const RegulationsContent = styled.div`
  width: 705px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 94vh;
  overflow-y: auto;

  & > h4 {
    font-size: 13px;
    line-height: 24px;
    margin-bottom: 10px;
    color: white;
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }

  @media (max-width: 1242px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 25px 50px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: unset;
  }
`;

export const InputWrapper = styled.div<{isRedBorder?: boolean}>`
  background: ${({isRedBorder}) => (isRedBorder ? `#FF7E7D` : 'transparent')};
  border: 1px solid rgba(112, 112, 112, 0.3);
  height: 22px;
  border-radius: 5px;
  width: 100%;
  margin-top: ${({isRedBorder}) => (isRedBorder ? `0` : '10px')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    color: #07020f;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.625px;
    font-weight: 400;
    margin-left: 8.5px;
  }

  & > input {
    text-align: right;
    font-size: 11px;
    line-height: 18px;
    margin-right: 8.5px;
    color: rgba(7, 2, 15, 0.5);
    letter-spacing: 0.625px;
    font-weight: 400;
    background-color: transparent;
    border: none;

    &::placeholder {
      color: rgba(7, 2, 15, 0.5);
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: rgba(7, 2, 15, 0.5) !important;
    }
  }

  & > input:focus {
    outline: none;
  }
`;

export const PrimaryButton = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
}>`
  width: 100%;
  height: 44px;
  border: none;
  border: ${({borderColor}) =>
    !borderColor ? 'none' : `1px solid ${borderColor}`};
  background: ${({background}) => (!background ? '#3A87F0' : background)};
  border-radius: 15px;
  font-size: 15px;
  color: ${({color}) => (!color ? '#ffffff' : color)};
  transition: all 0.15s;
  cursor: pointer;
  display: flex;
  align-items: center;
  //justify-content: center;
  gap: 6px;
  font-weight: 400;
  letter-spacing: 0.7px;
  position: relative;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 16px;
    letter-spacing: 0.07px;
    font-weight: 400;
    font-size: 17px;
    min-height: 44px;
  }
`;

export const PageHeaderWrapper = styled.div`
  width: 1020px;
  display: flex;
  height: 590px;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  background: ${({theme}) => theme.colors.bgWhite};
  border-radius: 24px 24px 21px 21px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.625px;
  }
`;

export const PageHeaderContent = styled.div`
  width: 100%;
  /* height: calc(100% - 55px); */
  display: grid;
  align-items: center;
  grid-template-columns: 535px 485px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightSectionContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 3px 85px 3px 88px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: #07020f;
  }

  & > a {
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-decoration: underline;
  }
`;

const SingleRegulationWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  & > p {
    color: rgba(255, 255, 255, 0.86);
    line-height: 24px;
    font-size: 11px;
    padding-left: 30px;
  }
`;

const IconShowPasswordWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  & > svg {
    overflow: visible;
    width: 100%;
    height: 100%;
  }
  & > img {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    top: 12px;

    & > img {
      width: 19px;
      height: 19px;
    }
  }
`;

const dataTypeArr = [
  {text: '+48', value: 0},
  {text: '+49', value: 1},
  {text: '+50', value: 2},
  {text: '+59', value: 3},
];
const dataTypeObj = {
  0: {text: '+48', value: 0},
  1: {text: '+49', value: 1},
  2: {text: '+50', value: 2},
  3: {text: '+59', value: 3},
};

interface RegisterData {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  phoneNumber: string;
  password: string;
  replayPassword: string;
}

interface RegulationRules {
  first: boolean;
  second: boolean;
  third: boolean;
  fourth: boolean;
  fifth: boolean;
  sixth: boolean;
  seventh: boolean;
}

interface ErrorVerification {
  firstNameError: string | null;
  lastNameError: string | null;
  userNameError: string | null;
  emailError: string | null;
  phoneNumberError: string | null;
  passwordError: string | null;
  replayPasswordError: string | null;
}

const RegisterComponent: React.FC = () => {
  const [isRegulationsOpen, setRegulationsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [registerData, setRegisterData] = useState<RegisterData>({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phoneNumber: '',
    replayPassword: '',
    userName: '',
  });
  const [regulationRules, setRegulationRules] = useState<RegulationRules>({
    fifth: false,
    first: false,
    fourth: true,
    second: false,
    seventh: false,
    sixth: false,
    third: false,
  });
  const [errorVerification, setErrorVerification] = useState<ErrorVerification>(
    {
      emailError: null,
      firstNameError: null,
      lastNameError: null,
      passwordError: null,
      phoneNumberError: null,
      replayPasswordError: null,
      userNameError: null,
    },
  );

  const [phoneNumberCode, setPhoneNumberCode] = useState({
    text: '+48',
    value: 0,
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [showReplayPassword, setShowReplayPassword] = useState<boolean>(false);

  const navigate = useNavigate();

  const {Alert} = useAlert();

  const handlePhoneNumberCode = (value) =>
    setPhoneNumberCode(dataTypeObj[value]);

  const validateFormAndHandleIsRegulationOpenChange = async (
    value: boolean,
  ) => {
    const {
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      replayPassword,
      userName,
    } = registerData;

    const onlyLettersRegex = /^[A-Za-z]+$/;
    const onlyLettersAndNumbersRegex = /^[A-Za-z0-9]+$/;
    const numberAndLettersWithPolishCharactersRegex =
      /^[A-Za-z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]+$/;
    const onlyLettersWithPolishCharactersRegex =
      /^[A-Za-zA-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/;
    const onlyLettersWithPolishCharactersAndSpecialRegex =
      /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]+$/;
    const onlyNumberRegex = /^\d+$/;
    // const passwordRegex =
    //   /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*+.,;:'"<>?/\\[\]])[a-zA-Z0-9!@#$%^&*+.,;:'"?/\\[\]]{8,}$/;
    const passwordRegex =
      /^(?=.*[0-9])(?=.*[a-ząćęłńóśźż])(?=.*[A-ZĄĆĘŁŃÓŚŹŻ])(?=.*[!@#$%^&*+.,;:'"<>?/\\[\]])[a-zA-Z0-9!@#$%^&*+.,;:'"?/\\[\]ąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]{8,}$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setErrorVerification({
      emailError: null,
      firstNameError: null,
      lastNameError: null,
      passwordError: null,
      phoneNumberError: null,
      replayPasswordError: null,
      userNameError: null,
    });
    let areThereErrors = false;
    // firstName

    if (!onlyLettersWithPolishCharactersRegex.test(firstName)) {
      setErrorVerification((prev) => ({
        ...prev,
        firstNameError:
          'Spacja,cyfry oraz znaki specjalne nie są dozwolone. Przykładowe znaki specjalne !@#$%^&*()_+.,;:"<>? ',
      }));
      areThereErrors = true;
    }

    if (firstName.length < 1) {
      setErrorVerification((prev) => ({
        ...prev,
        firstNameError: 'Imię nie może być puste',
      }));
      areThereErrors = true;
    }

    if (firstName.length > 63) {
      setErrorVerification((prev) => ({
        ...prev,
        firstNameError: 'Imię nie powinno być dłuższe niż 63 znaki',
      }));
      areThereErrors = true;
    }
    // lastName

    if (!onlyLettersWithPolishCharactersAndSpecialRegex.test(lastName)) {
      setErrorVerification((prev) => ({
        ...prev,
        lastNameError: 'Nazwisko nie może być puste, nie może zawierać cyfr',
      }));
      areThereErrors = true;
    }

    if (lastName.length > 63) {
      setErrorVerification((prev) => ({
        ...prev,
        lastNameError: 'Nazwisko ma limit 63 liter',
      }));
      areThereErrors = true;
    }

    if (lastName.length < 2) {
      setErrorVerification((prev) => ({
        ...prev,
        lastNameError: 'Nazwisko nie może być puste',
      }));
      areThereErrors = true;
    }
    // userName
    if (userName.length === 0) {
      setErrorVerification((prev) => ({
        ...prev,
        userNameError: 'Nazwa użytkownika nie może być pusta',
      }));
      areThereErrors = true;
    }

    if (!onlyLettersAndNumbersRegex.test(userName)) {
      setErrorVerification((prev) => ({
        ...prev,
        userNameError:
          'Polskie litery, spacja oraz znaki specjalne nie są dozwolone. Przykładowe znaki specjalne !@#$%^&*()_+.,;:"<>? ',
      }));
      areThereErrors = true;
    }

    if (userName.length > 63) {
      setErrorVerification((prev) => ({
        ...prev,
        userNameError: 'Nazwa użytkownika jest za długa. Limit znaków to 63',
      }));
      areThereErrors = true;
    }
    // email
    if (email.length === 0) {
      setErrorVerification((prev) => ({
        ...prev,
        emailError: 'Nieprawidłowy adres e-mail',
      }));
      areThereErrors = true;
    }

    if (!emailRegex.test(email)) {
      setErrorVerification((prev) => ({
        ...prev,
        emailError: 'Nieprawidłowy adres e-mail',
      }));
      areThereErrors = true;
    }
    // phoneNumber
    // if (phoneNumber.length === 0) {
    //   setErrorVerification((prev) => ({
    //     ...prev,
    //     phoneNumberError: 'Nieprawidłowy numer telefonu',
    //   }));
    //   areThereErrors = true;
    // }

    if (phoneNumber.length > 0) {
      if (
        !onlyNumberRegex.test(phoneNumber.replace(/\s+/g, '')) ||
        phoneNumber.length < 9 ||
        phoneNumber.length > 12
      ) {
        setErrorVerification((prev) => ({
          ...prev,
          phoneNumberError: 'Nieprawidłowy numer telefonu',
        }));
        areThereErrors = true;
      }
    }
    // password
    if (password.length === 0) {
      setErrorVerification((prev) => ({
        ...prev,
        passwordError: 'Hasło nie może być puste',
      }));
      areThereErrors = true;
    }

    if (!passwordRegex.test(password.split(' ').join(''))) {
      setErrorVerification((prev) => ({
        ...prev,
        passwordError:
          'Hasło musi zawierać minimum 8 znaków, 1 znak specjalny, 1 wielką i małą literę i 1 liczbę. Nie może zawierać spacji oraz znaków `<>{}|-_()`',
      }));
      areThereErrors = true;
    }
    // replayPassword
    if (password !== replayPassword || !replayPassword) {
      setErrorVerification((prev) => ({
        ...prev,
        replayPasswordError: 'Oba hasła muszą być identyczne',
      }));
      areThereErrors = true;
    }

    if (areThereErrors) return;
    // setRegulationsOpen(value);
    try {
      const body = {
        userName: userName,
        email: email,
        phoneNumber: phoneNumber.split(' ').join(''),
      };
      const response = await api.get(`auth/check-availability`, {params: body});
      console.log('response for check userData', response.data.Response);
      if (response.data.Response.EmailTaken) {
        setErrorVerification((prev) => ({
          ...prev,
          emailError: 'Ten email jest już zajęty',
        }));
        areThereErrors = true;
      }
      if (response.data.Response.UserNameTaken) {
        setErrorVerification((prev) => ({
          ...prev,
          userNameError: 'Ta nazwa użytkownika jest już zajęta',
        }));
        areThereErrors = true;
      }
      if (response.data.Response.PhoneNumberTaken) {
        setErrorVerification((prev) => ({
          ...prev,
          phoneNumberError: 'Numer już zarejestrowany',
        }));
        areThereErrors = true;
      }
      if (areThereErrors) return;
      setRegulationsOpen(value);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRegisterDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegisterData((prev) => ({...prev, [e.target.name]: e.target.value}));
    // console.log(e.target.value, 'value');
    // console.log(e.target.className, 'klasa');
    setErrorVerification((prev) => ({...prev, [e.target.className]: null}));
  };

  const handleRegisterDataUserNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const filteredValue = e.target.value.replace(/[^a-zA-Z0-9 -]/g, '');
    setRegisterData((prev) => ({...prev, [e.target.name]: filteredValue}));
    // console.log(e.target.value, 'value');
    // console.log(e.target.className, 'klasa');
    setErrorVerification((prev) => ({...prev, [e.target.className]: null}));
  };

  const handleUserLastNameInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    // Tutaj zastosuj regex, aby usunąć cyfry z wprowadzonego tekstu
    const filteredValue = value.replace(/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g, '');
    setRegisterData((prev) => ({...prev, lastName: filteredValue}));
    setErrorVerification((prev) => ({...prev, [e.target.className]: null}));
  };

  const handleUserFirstNameInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    // Tutaj zastosuj regex, aby usunąć cyfry z wprowadzonego tekstu
    const filteredValue = value.replace(/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g, '');
    setRegisterData((prev) => ({...prev, firstName: filteredValue}));
    setErrorVerification((prev) => ({...prev, [e.target.className]: null}));
  };

  const handlePhoneNumberChange = (event) => {
    const {value} = event.target;
    setRegisterData((prevState) => ({
      ...prevState,
      phoneNumber: value
        .replace(/[^0-9]/g, '')
        .replace(/\s/g, '')
        .replace(/(\d{3})/g, '$1 ')
        .trim(),
    }));
    setErrorVerification((prev) => ({...prev, [event.target.className]: null}));
  };

  const handleRegulationRuleChange = (rule: string, value: boolean) =>
    setRegulationRules((prev) => ({...prev, [rule]: value}));

  const showErrorInCreateAccount = (data) => {
    const errorArr = [];
    const {
      EmailValidationMessage,
      FirstNameValidationMessage,
      LastNameValidationMessage,
      PasswordValidationMessage,
      PhoneNumberValidationMessage,
      UserNameValidationMessage,
    } = data;
    if (EmailValidationMessage !== null) {
      errorArr.push(EmailValidationMessage);
    }
    if (FirstNameValidationMessage !== null) {
      errorArr.push(FirstNameValidationMessage);
    }
    if (LastNameValidationMessage !== null) {
      errorArr.push(LastNameValidationMessage);
    }
    if (PasswordValidationMessage !== null) {
      errorArr.push(PasswordValidationMessage);
    }
    if (PhoneNumberValidationMessage !== null) {
      errorArr.push(PhoneNumberValidationMessage);
    }
    if (UserNameValidationMessage !== null) {
      errorArr.push(UserNameValidationMessage);
    }

    if (errorArr.length > 0) {
      return errorArr.join(' ');
    } else return '';
  };
  console.log(registerData.phoneNumber);
  const createAccount = async () => {
    const {
      email,
      firstName,
      lastName,
      password,
      phoneNumber,
      replayPassword,
      userName,
    } = registerData;
    if (
      !regulationRules.first ||
      !regulationRules.second ||
      !regulationRules.third
    )
      return Alert('Zaakceptuj pola obowiązkowe', 'Komunikat');
    if (isLoading) return;
    setIsLoading(true);

    try {
      const body = {
        FirstName: firstName,
        LastName: lastName,
        UserName: userName,
        Email: email,
        PhoneNumber: phoneNumber.replace(/\s+/g, ''),
        PhoneNumberCode: phoneNumberCode.text,
        Password: password.split(' ').join(''),
        StudioAllowNotifications: regulationRules.fifth,
        StudioAcceptedRequlations: regulationRules.first,
        StudioAcceptedPrivacyPolicy: regulationRules.second,
        StudioAcceptedMarketing: regulationRules.sixth,
      };

      const response = await api.post(`Auth/register`, body);
      Alert(
        'Na podany adres e-mail został wysłany link aktywacyjny',
        'Dziękujemy za rejestrację!',
      );
      navigate('/identity/login');
    } catch (error) {
      console.error(error);
      Alert(
        showErrorInCreateAccount(
          JSON.parse(`${error.response.data.Error.Message}`),
        ),
        'Błąd',
      );
      // console.log(JSON.parse(`${error.response.data.Error.Message}`));
      setRegulationsOpen(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <MainWrapper>
        {isRegulationsOpen && (
          <RegulationsContainer isRegulationsOpen={isRegulationsOpen}>
            <RegulationsContent>
              <h4>WARUNKI I ZASADY</h4>
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.first}
                  onClick={() =>
                    handleRegulationRuleChange('first', !regulationRules.first)
                  }
                />
                <p>
                  *Oświadczam, że zapoznałam/em się z Regulaminem konta
                  użytkowanika oraz Polityką prywatności i akceptuję ich
                  postanowienia.
                </p>
              </SingleRegulationWrapper>
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.second}
                  onClick={() =>
                    handleRegulationRuleChange(
                      'second',
                      !regulationRules.second,
                    )
                  }
                />
                <p>
                  *Przeczytałam/em i zrozumiałam/em Klauzulę Informacyjną
                  dotyczącą przetwarzania moich danych osobowych.
                </p>
              </SingleRegulationWrapper>
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.third}
                  onClick={() =>
                    handleRegulationRuleChange('third', !regulationRules.third)
                  }
                />
                <p>
                  *Żądam uruchomienia usługi przed upływem terminu
                  przysługującego mi prawa do odstąpienia od Kontraktu bez
                  podania przyczyn. Rozumiem, że zgłoszenie żądania powoduje
                  utratę prawa do odstąpienia.
                </p>
              </SingleRegulationWrapper>
              {/* <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.fourth}
                  onClick={() =>
                    handleRegulationRuleChange(
                      'fourth',
                      !regulationRules.fourth,
                    )
                  }
                />
                <p>
                  Rozumiem, że zgłoszenie żądania powoduje utratę prawa do
                  odstąpienia.
                </p>
              </SingleRegulationWrapper> */}
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.fifth}
                  onClick={() =>
                    handleRegulationRuleChange('fifth', !regulationRules.fifth)
                  }
                />
                <p>
                  Wyrażam zgodę na przetwarzanie moich danych osobowych, w celu
                  otrzymywania na podany adres e-mail Newslettera Serwisu
                  internetowego mugo.pl tworzonego przez MyMusic sp. j.., pod
                  adresem https://mymusic.pl/ Rozumiem, że moja zgoda jest
                  dobrowolna i nie muszę jej udzielać, przy czym w przypadku jej
                  nieudzielenia bądź późniejszego jej wycofania, jak również
                  przesłania wniosku o zmianę lub usunięcie moich danych, stracę
                  możliwość uczestniczenia w w/w działaniach marketingowych
                  MyMusic sp. j.. Zostałam/em poinformowana/y o przysługującym
                  mi prawie dostępu do treści moich danych oraz ich poprawiania.
                </p>
              </SingleRegulationWrapper>
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.sixth}
                  onClick={() =>
                    handleRegulationRuleChange('sixth', !regulationRules.sixth)
                  }
                />
                <p>
                  Wyrażam zgodę na przekazywanie przez MyMusic sp. j.. z
                  siedzibą w Wysogotowie, przy ul. Wierzbowej 31, 62-081
                  Wysogotowo, treści marketingowych za pośrednictwem moich
                  urządzeń telekomunikacyjnych, w szczególności takich jak
                  laptop, telefon czy smartfon, zgodnie z art. 172 ust. 1 ustawy
                  z dnia 16 lipca 2004 r. Prawo telekomunikacyjne.
                </p>
              </SingleRegulationWrapper>
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.seventh}
                  onClick={() =>
                    handleRegulationRuleChange(
                      'seventh',
                      !regulationRules.seventh,
                    )
                  }
                />
                <p>
                  Wyrażam zgodę na otrzymywanie informacji handlowej od MyMusic
                  sp. j. z siedzibą w Wysogotowie, przy ul. Wierzbowej 31,
                  62-081 Wysogotowo, zgodnie z art. 10 ustawy z dnia 18 lipca
                  2002 r. o świadczeniu usług drogą elektroniczną.
                </p>
              </SingleRegulationWrapper>
              <br />
              <SingleRegulationWrapper>
                <p>* - pole obowiązkowe.</p>
              </SingleRegulationWrapper>
              <PrimaryButton
                onClick={() => createAccount()}
                style={{
                  width: '310px',
                  marginTop: '40px',
                  justifyContent: 'center',
                }}
              >
                {isLoading ? 'Wysyłanie' : 'Zaakceptuj i zarejestruj'}
              </PrimaryButton>
            </RegulationsContent>
          </RegulationsContainer>
        )}
        <Main>
          {isLoading && <LoadingCircle style={{zIndex: 99}} />}
          <PageHeaderWrapper>
            <PageHeaderHeaderWrapper>
              <PageHeaderHeader>
                <p>Witamy w MUGO STUDIO!</p>
              </PageHeaderHeader>
            </PageHeaderHeaderWrapper>
            <PageHeaderContent>
              <ImageWrapper>
                <img src={mugoImage} />
              </ImageWrapper>
              <RightSectionContent>
                <h1 style={{marginBottom: '5px', marginTop: '24px'}}>
                  Rejestracja
                </h1>
                <InputView
                  isError={errorVerification.firstNameError !== null}
                  isEditing={true}
                >
                  <p>Imię</p>
                  <input
                    type="text"
                    value={registerData.firstName}
                    onChange={handleUserFirstNameInputChange}
                    className="firstNameError"
                    name="firstName"
                    id="firstNameRegister"
                    style={{paddingLeft: '90px'}}
                  />
                  {errorVerification.firstNameError !== null && (
                    <ErrorStar text={errorVerification.firstNameError} />
                  )}
                </InputView>
                <InputView
                  isError={errorVerification.lastNameError !== null}
                  isEditing={true}
                >
                  <p>Nazwisko</p>
                  <input
                    type="text"
                    value={registerData.lastName}
                    onChange={handleUserLastNameInputChange}
                    className="lastNameError"
                    id="lastNameRegister"
                    name="lastName"
                    style={{paddingLeft: '90px'}}
                  />
                  {errorVerification.lastNameError !== null && (
                    <ErrorStar text={errorVerification.lastNameError} />
                  )}
                </InputView>
                <InputView
                  isError={errorVerification.userNameError !== null}
                  isEditing={true}
                >
                  <p>Login</p>
                  <input
                    type="text"
                    value={registerData.userName}
                    onChange={handleRegisterDataUserNameChange}
                    id="userNameRegister"
                    className="userNameError"
                    name="userName"
                    style={{paddingLeft: '90px'}}
                  />
                  {errorVerification.userNameError !== null && (
                    <ErrorStar text={errorVerification.userNameError} />
                  )}
                </InputView>
                <InputView
                  isError={errorVerification.emailError !== null}
                  isEditing={true}
                >
                  <p>E-mail</p>
                  <input
                    type="text"
                    value={registerData.email}
                    onChange={handleRegisterDataChange}
                    id="emailRegister"
                    name="email"
                    className="emailError"
                    style={{paddingLeft: '90px'}}
                  />
                  {errorVerification.emailError !== null && (
                    <ErrorStar text={errorVerification.emailError} />
                  )}
                </InputView>
                <InputView
                  isError={errorVerification.phoneNumberError !== null}
                  isEditing={true}
                >
                  <p>Telefon</p>
                  <input
                    type="tel"
                    value={registerData.phoneNumber}
                    onChange={handlePhoneNumberChange}
                    id="phoneNumberRegister"
                    name="phoneNumberRegister"
                    className="phoneNumberError"
                    maxLength={11}
                    style={{paddingLeft: '90px'}}
                  />
                  {errorVerification.phoneNumberError !== null && (
                    <ErrorStar text={errorVerification.phoneNumberError} />
                  )}
                  <PhoneCodeSelect
                    onChange={(v) => handlePhoneNumberCode(v)}
                    selectWidth={'50px'}
                    isEditing={true}
                    title={''}
                    values={dataTypeArr}
                    value={phoneNumberCode}
                  />
                </InputView>
                <InputView
                  isError={errorVerification.passwordError !== null}
                  isEditing={true}
                >
                  <p>Hasło</p>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    value={registerData.password}
                    onChange={handleRegisterDataChange}
                    id="passwordRegister"
                    name="password"
                    className="passwordError"
                    style={{paddingLeft: '90px', paddingRight: '17.5px'}}
                  />
                  {errorVerification.passwordError !== null && (
                    <ErrorStar text={errorVerification.passwordError} />
                  )}
                  <IconShowPasswordWrapper
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  >
                    {showPassword ? (
                      <img src={closeEye} alt="hidePAssword" />
                    ) : (
                      <img src={openEye} alt="showPAssword" />
                    )}
                    {/* {showPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15.414"
                        viewBox="0 0 20 15.414"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect width="20" height="15.414" fill="none" />
                          </clipPath>
                        </defs>
                        <g
                          id="Input_Action_Light_Hide_Password"
                          data-name="Input Action – Light / Hide Password"
                          clipPath="url(#clip-path)"
                        >
                          <path
                            id="Combined_Shape"
                            data-name="Combined Shape"
                            d="M3,14l2.085-2.085A15.885,15.885,0,0,1,0,8,16.153,16.153,0,0,1,4.739,4.25a11.7,11.7,0,0,1,8.735-.723L17,0l1.414,1.414-14,14ZM7,8a2.984,2.984,0,0,0,.437,1.563l4.126-4.126A3,3,0,0,0,7,8Zm1.507,4.906,1.939-1.939a3.018,3.018,0,0,0,2.52-2.519L16.475,4.94A17.3,17.3,0,0,1,20,8a16.168,16.168,0,0,1-4.738,3.75A11.672,11.672,0,0,1,10,13,11.777,11.777,0,0,1,8.508,12.906Z"
                            fill="#0065f2"
                          />
                        </g>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 20 10"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect width="20" height="10" fill="none" />
                          </clipPath>
                        </defs>
                        <g
                          id="Input_Action_Light_Show_Password"
                          data-name="Input Action – Light / Show Password"
                          clipPath="url(#clip-path)"
                        >
                          <path
                            id="Combined_Shape"
                            data-name="Combined Shape"
                            d="M10,10A12.913,12.913,0,0,1,2.929,7.5,18.309,18.309,0,0,1,0,5l.006-.007A18.5,18.5,0,0,1,2.929,2.5,12.911,12.911,0,0,1,10,0a12.911,12.911,0,0,1,7.071,2.5A18.32,18.32,0,0,1,20,5l-.006.007A18.5,18.5,0,0,1,17.071,7.5,12.913,12.913,0,0,1,10,10Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,10,2Z"
                            transform="translate(0)"
                            fill="#0065f2"
                          />
                        </g>
                      </svg>
                    )} */}
                  </IconShowPasswordWrapper>
                </InputView>
                <InputView
                  style={{marginBottom: '100px'}}
                  isError={
                    errorVerification.replayPasswordError !== null ||
                    registerData.replayPassword !== registerData.password
                  }
                  isEditing={true}
                >
                  <p>Potwierdź hasło</p>
                  <input
                    type={showReplayPassword ? 'text' : 'password'}
                    value={registerData.replayPassword}
                    onChange={handleRegisterDataChange}
                    id="replayPasswordRegister"
                    name="replayPassword"
                    className="replayPasswordError"
                    style={{paddingLeft: '90px', paddingRight: '17.5px'}}
                  />
                  {/* {errorVerification.replayPasswordError !== null && (
                    <ErrorStar text={errorVerification.replayPasswordError} />
                  )} */}
                  {registerData.replayPassword !== registerData.password && (
                    <ErrorStar text={'Hasła muszą być identyczne'} />
                  )}

                  <IconShowPasswordWrapper
                    onClick={() =>
                      setShowReplayPassword((prevState) => !prevState)
                    }
                  >
                    {showReplayPassword ? (
                      <img src={closeEye} alt="hidePAssword" />
                    ) : (
                      <img src={openEye} alt="showPAssword" />
                    )}
                    {/* {showReplayPassword ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15.414"
                        viewBox="0 0 20 15.414"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect width="20" height="15.414" fill="none" />
                          </clipPath>
                        </defs>
                        <g
                          id="Input_Action_Light_Hide_Password"
                          data-name="Input Action – Light / Hide Password"
                          clipPath="url(#clip-path)"
                        >
                          <path
                            id="Combined_Shape"
                            data-name="Combined Shape"
                            d="M3,14l2.085-2.085A15.885,15.885,0,0,1,0,8,16.153,16.153,0,0,1,4.739,4.25a11.7,11.7,0,0,1,8.735-.723L17,0l1.414,1.414-14,14ZM7,8a2.984,2.984,0,0,0,.437,1.563l4.126-4.126A3,3,0,0,0,7,8Zm1.507,4.906,1.939-1.939a3.018,3.018,0,0,0,2.52-2.519L16.475,4.94A17.3,17.3,0,0,1,20,8a16.168,16.168,0,0,1-4.738,3.75A11.672,11.672,0,0,1,10,13,11.777,11.777,0,0,1,8.508,12.906Z"
                            fill="#0065f2"
                          />
                        </g>
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 20 10"
                      >
                        <defs>
                          <clipPath id="clip-path">
                            <rect width="20" height="10" fill="none" />
                          </clipPath>
                        </defs>
                        <g
                          id="Input_Action_Light_Show_Password"
                          data-name="Input Action – Light / Show Password"
                          clipPath="url(#clip-path)"
                        >
                          <path
                            id="Combined_Shape"
                            data-name="Combined Shape"
                            d="M10,10A12.913,12.913,0,0,1,2.929,7.5,18.309,18.309,0,0,1,0,5l.006-.007A18.5,18.5,0,0,1,2.929,2.5,12.911,12.911,0,0,1,10,0a12.911,12.911,0,0,1,7.071,2.5A18.32,18.32,0,0,1,20,5l-.006.007A18.5,18.5,0,0,1,17.071,7.5,12.913,12.913,0,0,1,10,10Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,10,2Z"
                            transform="translate(0)"
                            fill="#0065f2"
                          />
                        </g>
                      </svg>
                    )} */}
                  </IconShowPasswordWrapper>
                </InputView>
                <Link to={'/identity/login'}>Logowanie</Link>
                <a
                  style={{marginBottom: '14px'}}
                  href={
                    'https://fmmtp.pl/mugostudio/Regulamin%20Serwisu_Mugo_Studio_17.09.2024.pdf'
                  }
                  target="__blank"
                >
                  Regulamin konta użytkownika
                </a>
                <PrimaryButton
                  style={{justifyContent: 'center'}}
                  onClick={() =>
                    validateFormAndHandleIsRegulationOpenChange(
                      !isRegulationsOpen,
                    )
                  }
                >
                  Dalej
                </PrimaryButton>
              </RightSectionContent>
            </PageHeaderContent>
          </PageHeaderWrapper>
          {/* <p>Witamy w MUGO STUDIO</p>
        <Grid>
          <RegisterWrapper>
            <RegisterHeader>
              <p>LOGOWANIE</p>
            </RegisterHeader>
            <RegisterContent>
              <InputWrapper>
                <p>Imię</p>
                <input
                  type="text"
                  placeholder="Wprowadź imię"
                  value={registerData.firstName}
                  onChange={handleRegisterDataChange}
                  id="firstName"
                />
              </InputWrapper>
              <InputWrapper>
                <p>Nazwisko</p>
                <input
                  type="text"
                  placeholder="Wprowadź nazwisko"
                  value={registerData.lastName}
                  onChange={handleRegisterDataChange}
                  id="lastName"
                />
              </InputWrapper>
              <InputWrapper>
                <p>Nazwa</p>
                <input
                  type="text"
                  placeholder="Wprowadź nazwę użytkownika"
                  value={registerData.userName}
                  onChange={handleRegisterDataChange}
                  id="userName"
                />
              </InputWrapper>
              <InputWrapper>
                <p>E-mail</p>
                <input
                  type="text"
                  placeholder="Wprowadź adres e-mail"
                  value={registerData.email}
                  onChange={handleRegisterDataChange}
                  id="email"
                />
              </InputWrapper>
              <InputWrapper>
                <p>Nr telefonu</p>
                <input
                  type="text"
                  placeholder="Wprowadź numer telefonu"
                  value={registerData.phoneNumber}
                  onChange={handleRegisterDataChange}
                  id="phoneNumber"
                />
              </InputWrapper>
              <InputWrapper>
                <p>Hasło</p>
                <input
                  type="password"
                  placeholder="Uzupełnij"
                  value={registerData.password}
                  onChange={handleRegisterDataChange}
                  id="password"
                />
              </InputWrapper>
              <InputWrapper>
                <p>Potwierdź hasło</p>
                <input
                  type="password"
                  placeholder="Potwierdź hasło"
                  value={registerData.replayPassword}
                  onChange={handleRegisterDataChange}
                  id="replayPassword"
                />
              </InputWrapper>
              <Link to={'/identity/login'}>Logowanie</Link>
              <Link to={'#'}>Regulamin konta użytkownika</Link>
              <PrimaryButton
                onClick={() =>
                  validateFormAndHandleIsRegulationOpenChange(
                    !isRegulationsOpen,
                  )
                }
              >
                Dalej
              </PrimaryButton>
            </RegisterContent>
          </RegisterWrapper>
        </Grid> */}
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        {isRegulationsOpen && (
          <RegulationsContainer isRegulationsOpen={isRegulationsOpen}>
            <RegulationsContent>
              <h4>WARUNKI I ZASADY</h4>
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.first}
                  onClick={() =>
                    handleRegulationRuleChange('first', !regulationRules.first)
                  }
                />
                <p>
                  *Oświadczam, że zapoznałam/em się z Regulaminem konta
                  użytkowanika oraz Polityką prywatności i akceptuję ich
                  postanowienia.
                </p>
              </SingleRegulationWrapper>
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.second}
                  onClick={() =>
                    handleRegulationRuleChange(
                      'second',
                      !regulationRules.second,
                    )
                  }
                />
                <p>
                  *Przeczytałam/em i zrozumiałam/em Klauzulę Informacyjną
                  dotyczącą przetwarzania moich danych osobowych.
                </p>
              </SingleRegulationWrapper>
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.third}
                  onClick={() =>
                    handleRegulationRuleChange('third', !regulationRules.third)
                  }
                />
                <p>
                  *Żądam uruchomienia usługi przed upływem terminu
                  przysługującego mi prawa do odstąpienia od Kontraktu bez
                  podania przyczyn. Rozumiem, że zgłoszenie żądania powoduje
                  utratę prawa do odstąpienia.
                </p>
              </SingleRegulationWrapper>
              {/* <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.fourth}
                  onClick={() =>
                    handleRegulationRuleChange(
                      'fourth',
                      !regulationRules.fourth,
                    )
                  }
                />
                <p>
                  Rozumiem, że zgłoszenie żądania powoduje utratę prawa do
                  odstąpienia.
                </p>
              </SingleRegulationWrapper> */}
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.fifth}
                  onClick={() =>
                    handleRegulationRuleChange('fifth', !regulationRules.fifth)
                  }
                />
                <p>
                  Wyrażam zgodę na przetwarzanie moich danych osobowych, w celu
                  otrzymywania na podany adres e-mail Newslettera Serwisu
                  internetowego mugo.pl tworzonego przez MyMusic sp. j.., pod
                  adresem https://mymusic.pl/ Rozumiem, że moja zgoda jest
                  dobrowolna i nie muszę jej udzielać, przy czym w przypadku jej
                  nieudzielenia bądź późniejszego jej wycofania, jak również
                  przesłania wniosku o zmianę lub usunięcie moich danych, stracę
                  możliwość uczestniczenia w w/w działaniach marketingowych
                  MyMusic sp. j.. Zostałam/em poinformowana/y o przysługującym
                  mi prawie dostępu do treści moich danych oraz ich poprawiania.
                </p>
              </SingleRegulationWrapper>
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.sixth}
                  onClick={() =>
                    handleRegulationRuleChange('sixth', !regulationRules.sixth)
                  }
                />
                <p>
                  Wyrażam zgodę na przekazywanie przez MyMusic sp. j.. z
                  siedzibą w Wysogotowie, przy ul. Wierzbowej 31, 62-081
                  Wysogotowo, treści marketingowych za pośrednictwem moich
                  urządzeń telekomunikacyjnych, w szczególności takich jak
                  laptop, telefon czy smartfon, zgodnie z art. 172 ust. 1 ustawy
                  z dnia 16 lipca 2004 r. Prawo telekomunikacyjne.
                </p>
              </SingleRegulationWrapper>
              <SingleRegulationWrapper>
                <Checkbox
                  value={regulationRules.seventh}
                  onClick={() =>
                    handleRegulationRuleChange(
                      'seventh',
                      !regulationRules.seventh,
                    )
                  }
                />
                <p>
                  Wyrażam zgodę na otrzymywanie informacji handlowej od MyMusic
                  sp. j. z siedzibą w Wysogotowie, przy ul. Wierzbowej 31,
                  62-081 Wysogotowo, zgodnie z art. 10 ustawy z dnia 18 lipca
                  2002 r. o świadczeniu usług drogą elektroniczną.
                </p>
              </SingleRegulationWrapper>
              <br />
              <SingleRegulationWrapper>
                <p>* - pole obowiązkowe.</p>
              </SingleRegulationWrapper>
              <PrimaryButton
                onClick={() => createAccount()}
                style={{
                  width: '310px',
                  marginTop: '40px',
                  justifyContent: 'center',
                }}
              >
                {isLoading ? 'Wysyłanie' : 'Zaakceptuj i zarejestruj'}
              </PrimaryButton>
            </RegulationsContent>
          </RegulationsContainer>
        )}
        <MobileMain>
          {isLoading && <LoadingCircle />}
          <MobilePageHeader style={{marginBottom: '50px'}}>
            Rejestracja
          </MobilePageHeader>
          <div
            style={{
              marginBottom: '35px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <InputView
              isEditing={true}
              isError={errorVerification.firstNameError !== null}
            >
              <p>Imię</p>
              <input
                type="text"
                value={registerData.firstName}
                onChange={handleRegisterDataChange}
                className="firstNameError"
                name="firstName"
                id="firstName"
                style={{paddingLeft: '90px'}}
              />
              {errorVerification.firstNameError !== null && (
                <ErrorStar text={errorVerification.firstNameError} />
              )}
            </InputView>
            <InputView
              isEditing={true}
              isError={errorVerification.lastNameError !== null}
            >
              <p>Nazwisko</p>
              <input
                type="text"
                value={registerData.lastName}
                onChange={handleRegisterDataChange}
                className="lastNameError"
                id="lastName"
                name="lastName"
                style={{paddingLeft: '90px'}}
              />
              {errorVerification.lastNameError !== null && (
                <ErrorStar text={errorVerification.lastNameError} />
              )}
            </InputView>
            <InputView
              isEditing={true}
              isError={errorVerification.userNameError !== null}
            >
              <p>Użytkownik</p>
              <input
                type="text"
                value={registerData.userName}
                onChange={handleRegisterDataChange}
                id="userName"
                name="userName"
                className="userNameError"
                style={{paddingLeft: '90px'}}
              />
              {errorVerification.userNameError !== null && (
                <ErrorStar text={errorVerification.userNameError} />
              )}
            </InputView>
            <InputView
              isEditing={true}
              isError={errorVerification.emailError !== null}
            >
              <p>E-mail</p>
              <input
                type="text"
                value={registerData.email}
                onChange={handleRegisterDataChange}
                id="email"
                name="email"
                className="emailError"
                style={{paddingLeft: '90px'}}
              />
              {errorVerification.emailError !== null && (
                <ErrorStar text={errorVerification.emailError} />
              )}
            </InputView>
            <InputView
              isEditing={true}
              isError={errorVerification.phoneNumberError !== null}
            >
              <p>Telefon</p>
              <input
                type="tel"
                value={registerData.phoneNumber}
                onChange={handlePhoneNumberChange}
                id="phoneNumber"
                name="phoneNumber"
                className="phoneNumberError"
                maxLength={11}
                style={{paddingLeft: '90px'}}
              />
              {errorVerification.phoneNumberError !== null && (
                <ErrorStar text={errorVerification.phoneNumberError} />
              )}
              <PhoneCodeSelectWrapper>
                <PhoneCodeSelect
                  onChange={(v) => handlePhoneNumberCode(v)}
                  selectWidth={'50px'}
                  isEditing={true}
                  title={''}
                  values={dataTypeArr}
                  value={phoneNumberCode}
                  customStyles={{top: '3px', fontSize: '17px', right: '106px'}}
                />
              </PhoneCodeSelectWrapper>
            </InputView>
            <InputView
              isEditing={true}
              isError={errorVerification.passwordError !== null}
            >
              <p>Hasło</p>
              <input
                type={showPassword ? 'text' : 'password'}
                value={registerData.password}
                onChange={handleRegisterDataChange}
                id="password"
                name="password"
                className="passwordError"
                style={{paddingLeft: '90px', paddingRight: '17.5px'}}
              />
              {errorVerification.passwordError !== null && (
                <ErrorStar text={errorVerification.passwordError} />
              )}
            </InputView>
            <InputView
              isEditing={true}
              isError={errorVerification.replayPasswordError !== null}
            >
              <p>Potwierdź hasło</p>
              <input
                type={showReplayPassword ? 'text' : 'password'}
                value={registerData.replayPassword}
                onChange={handleRegisterDataChange}
                id="replayPassword"
                name="replayPassword"
                className="replayPasswordError"
                style={{paddingLeft: '90px', paddingRight: '17.5px'}}
              />
              {errorVerification.replayPasswordError !== null && (
                <ErrorStar text={errorVerification.replayPasswordError} />
              )}
            </InputView>
          </div>
          <Link to={'/identity/login'}>Logowanie</Link>

          <a
            style={{marginBottom: '65px'}}
            href={
              'https://fmmtp.pl/mugostudio/Regulamin%20Serwisu_Mugo_Studio_17.09.2024.pdf'
            }
            target="__blank"
          >
            Regulamin konta użytkownika
          </a>
          <PrimaryButton
            style={{marginBottom: '57px', justifyContent: 'center'}}
            onClick={() =>
              validateFormAndHandleIsRegulationOpenChange(!isRegulationsOpen)
            }
          >
            Dalej
          </PrimaryButton>
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};

export default RegisterComponent;
