import React, {useState, useEffect, useContext, useRef} from 'react';
import styled, {css} from 'styled-components';
import api from 'services/axiosConfig';
import {useCookies} from 'react-cookie';
import {useNavigate, useParams, Link} from 'react-router-dom';

import {ConditionComponent} from './ConditionComponent';
import {TransactionFee} from 'pages/MyStudio/Conditions/EditCondition';
import arrowRight from 'images/svg/select-arrow-right.svg';
import {
  ConditionSectionDescriptionExample,
  UserMetaData,
} from 'pages/MyStudio/Conditions/EditCondition';
import {LoadingCircle} from 'utils/loadingCircle';
import {InputView, SelectView} from 'pages/MyAccount/Settings/styles';
import {getProductTypePolishName} from 'utils/getProductTypePolishName';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {ResolutionContext} from 'contexts/ResolutionContext';
import {getProductDisplayStatusDescription} from '../index';
import {
  NoFileSvg,
  TrackPlaceholderSvg,
  DigitalProductPlaceholderSvg,
  ServicePlaceholderSvg,
} from 'components/SvgComponents';
import {ExpandableHeaderWithDescriptionWrapper} from 'pages/Tracks/TrackDetail';
import {
  CheckboxInfoWrapper,
  ConditionDetailsWrapper,
  BlueHeaderComponent,
  ConditionSectionDescription,
  SectionHeaderNoEditing,
  SellerObligationWrapper,
  AddSellerObligationWrapper,
  AddSellerObligationInputWrapper,
  SelectViewContainer,
  SmallSectionTitleWithSlider,
  ConditionSlider,
  ConditionTitleWithInputWrapper,
  ConditionTitleWithInputAndSelectWrapper,
  DisplayGrossValueNoEditing,
  ConditionTitleWithSliderAndSelectWrapper,
  DisplayGrossValueWithTaxNoEditing,
  StyledInputView,
} from 'pages/MyStudio/Conditions/EditCondition';

import {
  CurrencyEnum,
  ExploitationDigitalEnum,
  ExploitationPhysicalEnum,
  ExploitationYoutubeEnum,
  ProductTypeEnum,
  ProtectiveUmbrellaEnum,
  RightsEndEnum,
  TimeToFinishEnum,
  TaxesEnum,
  ProductStatusEnum,
  TimeToUploadForCustomer,
} from 'constants/conditionEnums';
import {ErrorStar} from 'components/ErrorStar';
import useAlert from 'hooks/useAlert';
import noImage from 'images/noimage.png';
import trackPlaceholder from 'images/svg/track-placeholder.svg';
import PopupButton from 'components/PopUp/PopupButton';
import {EditBtnWrapper, EditSvgWrapper} from 'constants/globalElements';
import {ExpandableDescription} from 'components/ExpandableDescription';
import {ExpandableDescriptionOneLine} from 'components/ExpandableDescriptionOneLine';
import pauseIcon from 'images/svg/pause-icon.svg';
import playIcon from 'images/svg/play-icon.svg';
import {ExpandableHeaderWithDescription} from 'components/ExpandableHeaderWithDescription';
import {ExpandableArrowWithDescription} from 'components/ExpandableArrowWithDescription';
import {
  AddFileButton,
  MobileStyledInputView,
  StyledBlueHeaderWrapper,
  StyledConditionSectionDescription,
  StyledDisplayGrossValueNoEditing,
  StyledDisplayGrossValueWithTaxNoEditing,
  StyledExpandableHeaderWithDescriptionWrapper,
  StyledLeftDot,
  StyledSectionHeaderNoEditing,
  StyledSelectViewContainer,
  StyledSellerObligationWrapper,
  AddImageButton,
  AddTagButton,
  AddTagWrapper,
  MobileSaveProductConditionButton,
  ArrowButtonLeft,
  ArrowButtonRight,
  BackArrowButtonLeft,
  BasicDataWrapper,
  CategoriesWithSymbolWrapper,
  CategoriesWrapper,
  CategorySelectWrapper,
  ConditionButtonsBox,
  ConditionDetailsBox,
  ConditionSmallSectionDescription,
  MobileHeader,
  MobileOfferSectionWrapper,
  MobileAvailibityDurationWrapper,
  MobileHeaderWithButtonWrapper,
  MobileProductDataWrapper,
  MobileConditionsWrapper,
  MobileDisplayDescriptionWrapper,
  UserDataSelectViewContainer,
  MobileProductImageWrapper,
  MobileConditionsContentBox,
  MobileOptionsWrapper,
  MobileSingleOption,
  ConditionStyledTextarea,
  ConditionTextareaWrapper,
  ConditionsBannerWrapper,
  ConditionsContentBox,
  ConditionsContentWrapper,
  ConditionsWrapper,
  CreateConditionsButton,
  CustomAlertWrapper,
  DeleteButton,
  DeleteConditionButton,
  DeleteFileButton,
  DeleteTagButton,
  DesktopPlayButtonWrapper,
  DigitalFileWithTitleWrapper,
  DigitalFilesWrapper,
  MainWrapperStep1,
  MobileMain,
  MobileMainWrapper,
  DisplaySingleCategory,
  FileNameWithSvgSpan,
  FileWithTitleWrapper,
  FileWrapper,
  FilesWrapper,
  FirstStepContentWrapper,
  FirstStepMain,
  GalleryWithPlaceholderWrapper,
  GalleryWrapper,
  GenerateImageButton,
  HeaderWrapper,
  Image,
  ImageWrapper,
  ImagesContainer,
  InputViewStyledInput,
  Main,
  MainWrapper,
  MobileBtnWrapper,
  NoFileWrapper,
  OfferCounter,
  OfferDisplayData,
  OfferSectionCenterSide,
  OfferSectionContentWrapper,
  OfferSectionLeftSide,
  OfferSectionRightSide,
  OfferSectionWrapper,
  OfferSlider,
  OfferSliderWrapper,
  OptionsWrapper,
  Placeholder,
  MobileGallerySingleImage,
  MobileGalleryWrapper,
  PlaceholderImage,
  PlayButtonMainWrapper,
  MobileAddTagButton,
  MobileGenerateImageButton,
  MobileTrackPlaceholderSvgWrapper,
  ProductAdditionalDescriptionWrapper,
  ProductDataContentWrapper,
  ProductDataWithConditionsWrapper,
  MobileStyledStatusLine,
  MobileStyledStatusWrapper,
  ProductDataWrapper,
  ProductDescriptionWrapper,
  ProductImageWrapper,
  ProductNameWithDescriptionWrapper,
  MobileTagsWrapper,
  ProductTypeButton,
  ProductTypeButtonMobile,
  ProductTypeButtonTextWrapper,
  PublishOfferButton,
  SaveConditionButton,
  SaveProductButton,
  SaveProductConditionButton,
  SectionHeader,
  SectionTitle,
  SelectProductTypeWrapper,
  SelectViewStyledSelect,
  SingleOption,
  SingleProductType,
  SingleTagWrapper,
  SmallSectionTitleWithWinWinAndSlider,
  StyledStatus,
  StyledStatusLine,
  StyledStatusWithTitle,
  StyledStatusWrapper,
  StyledTextarea,
  SymbolWithTagsWrapper,
  TagsWrapper,
  TextWithLink,
  TextareaLettersCount,
  TextareaWrapper,
  TrackCategoriesWrapper,
  TrackFileWrapper,
  TrackPlaceholderSvgWrapper,
} from './styles';

export const ClearServiceSelectButton = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background: #ff0300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s;
  position: absolute;
  top: 6px;
  right: 8px;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const ServiceGalleryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: unset;
  }
`;

export const ServiceGalleryHeader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > p {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const ServiceTypeSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-bottom: 94px;

  & > p {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    width: 100%;
    text-align: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: unset;
  }
`;

export const ServiceButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    gap: 15px;
    flex-direction: column;
    justify-content: unset;
    align-items: center;
  }
`;

export const ServiceButton = styled.div`
  width: 314px;
  height: 78px;
  border-radius: 11px;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  cursor: pointer;

  & > p {
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const DeleteProductButton = styled.div`
  background: #0091ff;
  border-radius: 15px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 7px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);

  & > button {
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
  }
`;

export interface Plan {
  AvaliableBlocks: number;
  CanHighlightCard: boolean;
  Cost: number;
  Description: string;
  Id: string;
  MaxOffersHighlights: number;
  MaxDigitalOffers: number;
  MaxServiceOffers: number;
  MaxTrackOffers: number;
  Name: string;
  ValidDays: number;
}

export interface CurrentPlan {
  ActiveFrom: string;
  Plan: Plan;
}

export interface Seller {
  CurrentPlan: CurrentPlan;
  ProducerId: string;
}

export interface OfferCount {
  FeaturedOffersCount: number;
  ProducerId: string;
  TotalOffersCount: number;
  DigitalProductsOffersCount: number;
  ServicesOffersCount: number;
  TracksOffersCount: number;
}

export interface ErrorVerification {
  nameError: string | null;
  descriptionError: string | null;
}

export interface ProductCategory {
  Id: string;
  ParentCategoryId: string | null;
  Name: string;
  Description: string;
  ProductType: number;
  Position: number;
}

export interface File {
  Id?: string;
  File: string;
  FilePath?: string;
  ProductFileType: number;
  Name: string;
  ProductId?: string;
}

export interface CoverFile {
  Id: string;
  ProductId: string;
  Name: string;
  FilePath: string;
  PositionOrder: number;
}

export interface ConditionList {
  Id: string;
  Name: string;
  ProductType: number;
}

export interface AttributeValue {
  AttributeId: string;
  Id: string;
  Name: string;
  Position: number;
}

export interface Attribute {
  Id: string;
  Name: string;
  Position: number;
  ProductType: number;
  AttributeValues: AttributeValue[];
}

export interface Condition {
  AnotherLimits: string;
  AssignedProducts: any[];
  AssignedProductsCount: number;
  CanPriceBeNegotiated: boolean;
  Currency: number;
  DateToUploadForCustomer: string | null;
  Description: string;
  ExploitationDigital: number;
  ExploitationPhysical: number;
  ExploitationYoutube: number;
  GrossValue: number;
  Id: string;
  IndicationOfContentAuthor: string;
  Invoice: boolean;
  IsDefault: boolean;
  IsExclusive: number;
  Name: string;
  NetValue: number;
  ProducerId: string;
  ProductType: number;
  ProtectiveUmbrella: number;
  RightsEnd: number;
  SellerObligations: string[];
  SellerRestrictions: string[];
  Tax: number;
  TimeToFinish: number;
  TimeToFinishDate: string;
  TimeToUploadForCustomer: number | string;
  TotalAmount: number;
  TransactionFee: TransactionFee;
  WinWinDigital: number;
  WinWinPhysical: number;
  WinWinYoutube: number;
}

export interface ConditionWithHelpers extends Condition {
  isIndicationOfContentAuthor: boolean;
  userWinWin: boolean;
  IsEdited: boolean;
}

export const getFormattedDate = (dateToFormat: string = undefined) => {
  const date = dateToFormat ? new Date(dateToFormat) : new Date();
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let day: string | number = date.getDate();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

const MyProductDetail: React.FC = () => {
  const [conditionOne, setConditionOne] = useState<ConditionWithHelpers | null>(
    null,
  );
  const [conditionTwo, setConditionTwo] = useState<ConditionWithHelpers | null>(
    null,
  );
  const [conditionThree, setConditionThree] =
    useState<ConditionWithHelpers | null>(null);
  const [initialConditionOne, setInitialConditionOne] = useState(conditionOne);
  const [initialConditionTwo, setInitialConditionTwo] = useState(conditionTwo);
  const [initialConditionThree, setInitialConditionThree] =
    useState(conditionThree);
  const [singleProductId, setSingleProductId] = useState<string | undefined>(
    undefined,
  );
  const [createNewProductStep, setCreateNewProductStep] = useState<number>(1);
  const [productType, setProductType] = useState<number | string>('');
  const [isProductEditing, setIsProductEditing] = useState<boolean>(true);
  const [isConditionEditing, setIsConditionEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [symbol, setSymbol] = useState<string>('');
  const [files, setFiles] = useState<File[]>([]);
  const [vibe, setVibe] = useState<string>('');
  const [tone, setTone] = useState<string>('');
  const [tempo, setTempo] = useState<string>('');
  const [primaryCategory, setPrimaryCategory] = useState<string>('');
  const [secondaryCategory, setSecondaryCategory] = useState<string>('');
  const [tertiaryCategory, setTertiaryCategory] = useState<string>('');
  const [productCategories, setProductCategories] = useState<ProductCategory[]>(
    [],
  );
  const [imagePreview, setImagePreview] = useState(undefined);
  const [productId, setProductId] = useState<string>('');
  const [isReadyForOffer, setIsReadyForOffer] = useState<boolean>(false);
  const [productDisplayStatus, setProductDisplayStatus] = useState<number>(99);
  const [isProductLoading, setIsProductLoading] = useState<boolean>(false);
  const [attributeValues, setAttributeValues] = useState<string[]>([]);
  const [isProductCreating, setIsProductCreating] = useState<boolean>(false);
  const [tag, setTag] = useState<string>('');
  const [tags, setTags] = useState<string[]>([]);
  const [coverFiles, setCoverFiles] = useState<CoverFile[]>([]);
  const [description, setDescription] = useState<string>('');
  const [longDescription, setLongDescription] = useState<string>('');
  const [seller, setSeller] = useState<Seller>({
    CurrentPlan: {
      ActiveFrom: '',
      Plan: {
        AvaliableBlocks: 0,
        CanHighlightCard: false,
        Cost: 0,
        Description: '',
        Id: '',
        MaxOffersHighlights: 0,
        MaxDigitalOffers: 0,
        MaxServiceOffers: 0,
        MaxTrackOffers: 0,
        Name: '',
        ValidDays: 0,
      },
    },
    ProducerId: '',
  });
  const [offerCount, setOfferCount] = useState<OfferCount>({
    FeaturedOffersCount: 0,
    ProducerId: '',
    TotalOffersCount: 0,
    DigitalProductsOffersCount: 0,
    ServicesOffersCount: 0,
    TracksOffersCount: 0,
  });
  const [errorVerification, setErrorVerification] = useState<ErrorVerification>(
    {
      nameError: null,
      descriptionError: null,
    },
  );
  const [userMetaData, setUserMetaData] = useState<UserMetaData>({
    HasIBAN: false,
    HasMugoLabel: false,
    IsLegal: false,
  });
  // const [selectedConditionOne, setSelectedConditionOne] = useState<string>('');
  // const [selectedConditionTwo, setSelectedConditionTwo] = useState<string>('');
  // const [selectedConditionThree, setSelectedConditionThree] =
  //   useState<string>('');
  // const [conditionOneName, setConditionOneName] = useState<string>('');
  // const [conditionTwoName, setConditionTwoName] = useState<string>('');
  // const [conditionThreeName, setConditionThreeName] = useState<string>('');
  const [selectedCondition, setSelectedCondition] = useState<number>(1);
  const [conditionsList, setConditionsList] = useState<ConditionList[]>([]);
  const [productAttributes, setProductAttributes] = useState<Attribute[]>([]);
  const [isConditionLoading, setIsConditionLoading] = useState<boolean>(false);
  // const [userGuaranteedAmount, setUserGuaranteedAmount] =
  //   useState<boolean>(true);
  // const [userWinWin, setUserWinWin] = useState<boolean>(false);
  // const [dateToUploadForCustomer, setDateToUploadForCustomer] = useState<
  //   string | null
  // >(null);
  const [isProductUpdating, setIsProductUpdating] = useState<boolean>(false);
  // const [isDefault, setIsDefault] = useState<boolean>(false);
  // const [isExclusive, setIsExclusive] = useState<number>(1);
  // const [exploitationDigital, setExploitationDigital] = useState<number>(1);
  // const [exploitationPhysical, setExploitationPhysical] = useState<number>(1);
  // const [timeToFinish, setTimeToFinish] = useState<number>(1);
  // const [protectiveUmbrella, setProtectiveUmbrella] = useState<number>(1);
  // const [exploitationYoutube, setExploitationYoutube] = useState<number>(1);
  // const [tax, setTax] = useState<number>(23);
  // const [currency, setCurrency] = useState<number>(0);
  // const [conditionDescription, setConditionDescription] = useState<string>('');
  // const [paymentDeadline, setPaymentDeadline] = useState<number>(0);
  // const [rightEnd, setRightEnd] = useState<number>(0);
  // const [anotherLimits, setAnotherLimits] = useState<string>('');
  // const [grossValue, setGrossValue] = useState<number>(0);
  // const [invoice, setInvoice] = useState<boolean>(false);
  // const [canPriceBeNegotiated, setCanPriceBeNegotiated] =
  //   useState<boolean>(true);
  // const [paymentDeadlineValue, setPaymentDeadlineValue] = useState<number>(0);
  // const [sellerObligations, setSellerObligations] = useState<string[]>([]);
  // const [sellerRestrictions, setSellerRestrictions] = useState<string[]>([]);
  // const [sellerObligation, setSellerObligation] = useState<string>('');
  // const [sellerRestriction, setSellerRestriction] = useState<string>('');
  // const [selectedFieldOfUse, setSelectedFieldOfUse] = useState<number>(0);
  // const [winWinDigital, setWinWinDigital] = useState<number>(0);
  // const [winWinPhysical, setWinWinPhysical] = useState<number>(0);
  // const [winWinYoutube, setWinWinYoutube] = useState<number>(0);
  const [isConditionCreating, setIsConditionCreating] =
    useState<boolean>(false);
  // const [isNewConditionCreateOpen, setIsNewConditionCreateOpen] =
  //   useState<boolean>(false);
  // const [isEditConditionCreateOpen, setIsEditConditionCreateOpen] =
  //   useState<boolean>(false);
  // const [isEditingShownAndDisabled, setIsEditingShownAndDisabled] =
  //   useState<boolean>(false);
  const [availibityDuration, setAvailibityDuration] = useState<number>(7);
  const [isOfferCreating, setIsOfferCreating] = useState<boolean>(false);
  const [isImageGenerating, setIsImageGenerating] = useState<boolean>(false);
  const [highlightOffer, setHighlightOffer] = useState<boolean>(true);
  const [autoRenewalEnabled, setAutoRenewalEnabled] = useState<boolean>(true);
  const [isOfferLoading, setIsOfferLoading] = useState<boolean>(false);
  const [offerId, setOfferId] = useState<string>('');
  const [availabilityEndDate, setAvailabilityEndDate] = useState<string>('');
  const [isOfferUpdating, setIsOfferUpdating] = useState<boolean>(false);
  const [isConditionUpdating, setIsConditionUpdating] =
    useState<boolean>(false);
  const [isUpdateButtonClicked, setIsUpdateButtonClicked] =
    useState<boolean>(false);
  // const [timeToUploadForCustomer, setTimeToUploadForCustomer] = useState<
  //   number | string
  // >('');
  // const [assignedProductsCount, setAssignedProductsCount] = useState<number>(0);
  const [canBeRepublished, setCanBeRepublished] = useState<boolean>(false);
  const [isSaveOrCreateConditionMenuOpen, setIsSaveOrCreateConditionMenuOpen] =
    useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [indicationOfContentAuthor, setIndicationOfContentAuthor] =
  //   useState<string>('');
  const [isMobileConditionsVisible, setIsMobiileConditionsVisible] =
    useState<boolean>(false);
  // const [transactionFee, setTransactionFee] = useState<TransactionFee>({
  //   Brutto: 0,
  //   Name: '',
  //   Netto: 0,
  //   Tax: 0,
  //   TaxTotal: 0,
  // });
  // const [isIndicationOfContentAuthor, setIsIndicationOfContentAuthor] =
  //   useState<boolean>(true);
  const [activeFile, setActiveFile] = useState<string | null>(null);
  const [longDescriptionOrGallery, setLongDescriptionOrGallery] =
    useState<string>('');

  const deepEqual = (obj1, obj2) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  };

  const handleIsSaveOrCreateConditionMenuOpen = (value: boolean) =>
    setIsSaveOrCreateConditionMenuOpen(value);

  const handleIsMobileConditionsVisibleChange = (value: boolean) =>
    setIsMobiileConditionsVisible(value);

  const handleButtonClick = (fileType: string) => {
    setActiveFile((prevFileType) =>
      prevFileType === fileType ? null : fileType,
    );
  };

  const audioFiles = {
    demo:
      files.filter((file) => file.ProductFileType === 1 && file.FilePath)
        .length > 0
        ? files.filter((file, i) => file.ProductFileType === 1)[0].FilePath
        : files.filter((file) => file.ProductFileType === 1 && file.File)
            .length > 0
        ? `data:audio/mp3;base64,${
            files.filter((file, i) => file.ProductFileType === 1)[0].File
          }`
        : '',

    snippet:
      files.filter((file) => file.ProductFileType === 3 && file.FilePath)
        .length > 0
        ? files.filter((file, i) => file.ProductFileType === 3)[0].FilePath
        : files.filter((file) => file.ProductFileType === 3 && file.File)
            .length > 0
        ? `data:audio/mp3;base64,${
            files.filter((file, i) => file.ProductFileType === 3)[0].File
          }`
        : '',

    main:
      files.filter((file) => file.ProductFileType === 2 && file.FilePath)
        .length > 0
        ? files.filter((file, i) => file.ProductFileType === 2)[0].FilePath
        : files.filter((file) => file.ProductFileType === 2 && file.File)
            .length > 0
        ? `data:audio/mp3;base64,${
            files.filter((file, i) => file.ProductFileType === 2)[0].File
          }`
        : '',
  };

  const galleryRef = useRef<HTMLDivElement | null>(null);

  const params = useParams();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['token']);
  const {Alert} = useAlert();
  const {decodedToken} = useContext(ProducerDataContext);
  const {windowWidth} = useContext(ResolutionContext);

  const coverFileRef = useRef<HTMLInputElement>(
    document.createElement('input'),
  );
  const demoFileRef = useRef<HTMLInputElement>(document.createElement('input'));
  const productFileRef = useRef<HTMLInputElement>(
    document.createElement('input'),
  );
  const snippetFileRef = useRef<HTMLInputElement>(
    document.createElement('input'),
  );
  const [isSnippetAudioPlayed, setIsSnippetAudioPlayed] =
    useState<boolean>(false);
  const [isProductAudioPlayed, setIsProductAudioPlayed] =
    useState<boolean>(false);
  const [isDemoAudioPlayed, setIsDemoAudioPlayed] = useState<boolean>(false);
  const demoAudioRef = useRef<HTMLAudioElement | null>(null);
  const productAudioRef = useRef<HTMLAudioElement | null>(null);
  const snippetAudioRef = useRef<HTMLAudioElement | null>(null);

  const images = files.filter((file) => file.ProductFileType === 1);

  const handleProductAudioRefPlayPause = () => {
    if (productAudioRef.current) {
      if (productAudioRef.current.paused) {
        productAudioRef.current.play();
        setIsProductAudioPlayed(true);
      } else {
        productAudioRef.current.pause();
        setIsProductAudioPlayed(false);
      }
    }
  };

  const handleDemoAudioRefPlayPause = () => {
    if (demoAudioRef.current) {
      if (demoAudioRef.current.paused) {
        demoAudioRef.current.play();
        setIsDemoAudioPlayed(true);
      } else {
        demoAudioRef.current.pause();
        setIsDemoAudioPlayed(false);
      }
    }
  };

  const handleSnippetAudioRefPlayPause = () => {
    console.log(audioFiles);
    if (snippetAudioRef.current) {
      if (snippetAudioRef.current.paused) {
        snippetAudioRef.current.play();
        setIsSnippetAudioPlayed(true);
      } else {
        snippetAudioRef.current.pause();
        setIsSnippetAudioPlayed(false);
      }
    }
  };

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNextClick = (itemsPerRow: number) => {
    setCurrentIndex((prevIndex) =>
      prevIndex < images.length - itemsPerRow ? prevIndex + 1 : prevIndex,
    );
  };

  // const handleTimeToUploadForCustomerChange = (
  //   e: React.ChangeEvent<HTMLSelectElement>,
  // ) => {
  //   if (+e.target.value < 0) return;
  //   setTimeToUploadForCustomer(+e.target.value);
  // };

  const calculateTranslateValue = () => {
    const itemWidth = galleryRef.current?.children[0]?.clientWidth + 3 || 0;
    return -currentIndex * itemWidth;
  };

  const handleScroll = (itemsPerRow: number) => {
    if (galleryRef.current) {
      const scrollPosition = galleryRef.current.scrollLeft;
      const itemWidth = galleryRef.current.clientWidth + 3 / itemsPerRow;
      const newIndex = Math.floor(scrollPosition / itemWidth);
      setCurrentIndex(newIndex);
    }
  };

  // const handleDeleteClick = (index: number) => {
  //   setImages((prevImages) => {
  //     const newImages = [...prevImages];
  //     newImages.splice(index, 1);
  //     return newImages;
  //   });
  // };

  const handleHighlightOfferChange = (value: boolean) =>
    setHighlightOffer(value);

  const handleAutoRenewalEnabledChange = (value: boolean) =>
    setAutoRenewalEnabled(value);

  const addTagOnKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') return addTag(tag);
  };

  // const returnConditionDataToDefault = () => {
  //   setAnotherLimits('');
  //   setCanPriceBeNegotiated(true);
  //   setCurrency(0);
  //   setGrossValue(0);
  //   setInvoice(false);
  //   setPaymentDeadline(0);
  //   setConditionDescription('');
  //   setPaymentDeadlineValue(0);
  //   setRightEnd(0);
  //   setIsDefault(false);
  //   setIndicationOfContentAuthor('');
  //   setTax(23);
  //   setSellerObligations([]);
  //   setSellerRestrictions([]);
  //   setDateToUploadForCustomer(null);
  //   setAssignedProductsCount(0);
  //   setTimeToUploadForCustomer('');
  //   setIsIndicationOfContentAuthor(false);
  //   setIsExclusive(1);
  //   setProtectiveUmbrella(1);
  //   setTimeToFinish(1);
  //   setExploitationDigital(1);
  //   setExploitationPhysical(1);
  //   setExploitationYoutube(1);
  //   setWinWinDigital(0);
  //   setWinWinPhysical(0);
  //   setWinWinYoutube(0);
  // };

  const deleteTag = (tagToDelete: string) =>
    setTags((prev) => prev.filter((tag) => tag !== tagToDelete));

  const addTag = (newTag: string) => {
    if (!newTag) return;
    if (
      tags.filter((tag) => tag.toLowerCase() === newTag.toLowerCase()).length >
      0
    )
      return;

    setTags((prev) => [...prev, newTag]);
    setTag('');
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTag(e.target.value);
  };

  const deactivateOffer = async (offerId: string) => {
    try {
      const body = {
        OfferId: offerId,
      };

      const response = await api.put(`offer/${offerId}/deactivate`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      const {
        Featured,
        AvailabilityDuration,
        Id,
        AvailabilityEndDate,
        CanBeRepublished,
        AutoRenewalEnabled,
      } = response.data.Response;
      setAutoRenewalEnabled(AutoRenewalEnabled);
      setCanBeRepublished(CanBeRepublished);
      setHighlightOffer(Featured);
      setAvailibityDuration(AvailabilityDuration);
      getOfferCount(decodedToken.ProducerId);
      setOfferId(Id);
      setAvailabilityEndDate(AvailabilityEndDate);
      getSingleProduct(singleProductId);
      Alert('Oferta została wycofana', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const restoreOffer = async (offerId: string) => {
    try {
      const body = {OfferId: offerId};

      const response = await api.put(`offer/${offerId}/restore`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      const {
        Featured,
        AvailabilityDuration,
        Id,
        AvailabilityEndDate,
        CanBeRepublished,
        AutoRenewalEnabled,
      } = response.data.Response;
      Alert('Oferta została wznowiona', 'Komunikat');
      setAutoRenewalEnabled(AutoRenewalEnabled);
      setCanBeRepublished(CanBeRepublished);
      setHighlightOffer(Featured);
      setAvailibityDuration(AvailabilityDuration);
      getOfferCount(decodedToken.ProducerId);
      setOfferId(Id);
      setAvailabilityEndDate(AvailabilityEndDate);
      getSingleProduct(singleProductId);
      setIsProductEditing(false);
      setIsConditionEditing(false);
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const disableOffer = async (offerId: string) => {
    if (!canBeRepublished) return;
    try {
      const body = {OfferId: offerId};

      const response = await api.put(`offer/${offerId}/disable`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      const {
        Featured,
        AvailabilityDuration,
        Id,
        AvailabilityEndDate,
        CanBeRepublished,
        AutoRenewalEnabled,
      } = response.data.Response;
      setCanBeRepublished(CanBeRepublished);
      setOfferId('');
      getSingleOffer(singleProductId);
      getSingleProduct(singleProductId);
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  const updateProduct = async (productId: string, newConditionId = '') => {
    setErrorVerification({
      nameError: null,
      descriptionError: null,
    });
    let areThereErrors = false;

    if (!name) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          nameError: 'Uzupełnij nazwe produktu',
        }));
        areThereErrors = true;
      }
    }

    if (name.length > 50) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          nameError: 'Nazwa produktu może mieć maksymalnie 50 znaków',
        }));
        areThereErrors = true;
      }
    }

    if (productType !== 8 && description.length < 50) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          descriptionError: 'Opis produktu musi mieć minimum 50 znaków',
        }));
        areThereErrors = true;
      }
    }

    if (productType !== 8 && !description) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          descriptionError: 'Uzupełnij opis produktu',
        }));
        areThereErrors = true;
      }
    }

    if (productType === '') return Alert('Wybierz typ produktu', 'Komunikat');

    if (areThereErrors) return;

    if (files.filter((file) => file.ProductFileType === 0).length === 0) {
      return Alert('Okładka jest wymagana', 'Komunikat');
    }

    if (
      productType === 1 &&
      files.filter((file) => file.ProductFileType === 2).length === 0
    ) {
      return Alert('Plik finalny jest wymagany', 'Komunikat');
    }

    if (
      productType === 8 &&
      (files.filter((file) => file.ProductFileType === 2).length === 0 ||
        files.filter((file) => file.ProductFileType === 3).length === 0)
    ) {
      return Alert(
        'Plik finalny i wersja do odsłuchu jest wymagana',
        'Komunikat',
      );
    }

    if (productType === 8 && (!tone || !vibe || !tempo)) {
      return Alert('Wybierz tempo, tonacje i nastrój', 'Komunikat');
    }

    const categories = [];
    if (primaryCategory) categories.push(primaryCategory);
    if (secondaryCategory) categories.push(secondaryCategory);
    if (tertiaryCategory) categories.push(tertiaryCategory);
    if (categories.length === 0) return Alert('Wybierz kategorie', 'Komunikat');

    const attributeValues = [];
    if (tone) attributeValues.push(tone);
    if (vibe) attributeValues.push(vibe);
    if (tempo) attributeValues.push(tempo);

    if (isProductUpdating) return;
    setIsProductUpdating(true);

    try {
      const body = {
        ProductId: productId,
        Name: name,
        Symbol: symbol,
        Description: description,
        ProductStatus:
          files.filter((file) => file.ProductFileType === 0).length > 0 ? 1 : 0,
        ProductType: productType,
        LongDescription: longDescription,
        // ConditionsIds: [],
        Tags: tags,
        SellerObligations: [],
        SellerRestrictions: [],
        Categories: categories,
        AttributeValues: attributeValues,
        // AvailabilityStartDate: availabilityStartDate,
        // AvailabilityEndDate: availabilityEndDate,
        Files: files,
        ConditionOne:
          newConditionId && selectedCondition === 1
            ? newConditionId
            : conditionOne?.Id
            ? conditionOne?.Id
            : undefined,
        ConditionTwo:
          newConditionId && selectedCondition === 2
            ? newConditionId
            : conditionTwo?.Id
            ? conditionTwo?.Id
            : undefined,
        ConditionThree:
          newConditionId && selectedCondition === 3
            ? newConditionId
            : conditionThree?.Id
            ? conditionThree?.Id
            : undefined,
      };

      const response = await api.put(`Products`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setCanBeRepublished(response.data.Response.CanOfferBeRepublished);
      getSingleProduct(productId);
      setIsConditionEditing(false);
      // setIsNewConditionCreateOpen(false);
      // setIsEditConditionCreateOpen(false);
      setCreateNewProductStep(0);
      if (conditionOne?.Id || conditionTwo?.Id || conditionThree?.Id) {
        // setIsEditingShownAndDisabled(true);
      } else {
        // setIsEditingShownAndDisabled(false);
      }
      return response.data.Response.ReadyForOffer;
    } catch (error) {
      console.error(error);
      if (
        error?.response?.data?.Error?.Message ===
        'There need to be at least one main file.'
      ) {
        Alert('Musi być dodany conajmniej jeden plik finalny.', 'Błąd.');
      } else Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsProductUpdating(false);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const editProduct = async (productId: string, newConditionId = '') => {
    setErrorVerification({
      nameError: null,
      descriptionError: null,
    });
    let areThereErrors = false;

    if (!name) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          nameError: 'Uzupełnij nazwe produktu',
        }));
        areThereErrors = true;
      }
    }

    if (name.length > 50) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          nameError: 'Nazwa produktu może mieć maksymalnie 50 znaków',
        }));
        areThereErrors = true;
      }
    }

    if (productType !== 8 && description.length < 50) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          descriptionError: 'Opis produktu musi mieć minimum 50 znaków',
        }));
        areThereErrors = true;
      }
    }

    if (productType !== 8 && !description) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          descriptionError: 'Uzupełnij opis produktu',
        }));
        areThereErrors = true;
      }
    }

    if (productType === '') return Alert('Wybierz typ produktu', 'Komunikat');

    if (areThereErrors) return;

    if (files.filter((file) => file.ProductFileType === 0).length === 0) {
      return Alert('Okładka jest wymagana', 'Komunikat');
    }

    if (
      productType === 1 &&
      files.filter((file) => file.ProductFileType === 2).length === 0
    ) {
      return Alert('Plik finalny jest wymagany', 'Komunikat');
    }

    if (
      productType === 8 &&
      (files.filter((file) => file.ProductFileType === 2).length === 0 ||
        files.filter((file) => file.ProductFileType === 3).length === 0)
    ) {
      return Alert(
        'Plik finalny i wersja do odsłuchu jest wymagana',
        'Komunikat',
      );
    }

    if (productType === 8 && (!tone || !vibe || !tempo)) {
      return Alert('Wybierz tempo, tonacje i nastrój', 'Komunikat');
    }

    const categories = [];
    if (primaryCategory) categories.push(primaryCategory);
    if (secondaryCategory) categories.push(secondaryCategory);
    if (tertiaryCategory) categories.push(tertiaryCategory);
    if (categories.length === 0) return Alert('Wybierz kategorie', 'Komunikat');

    const attributeValues = [];
    if (tone) attributeValues.push(tone);
    if (vibe) attributeValues.push(vibe);
    if (tempo) attributeValues.push(tempo);

    if (isProductUpdating) return;
    setIsProductUpdating(true);

    try {
      const body = {
        ProductId: productId,
        Name: name,
        Symbol: symbol,
        Description: description,
        ProductStatus:
          files.filter((file) => file.ProductFileType === 0).length > 0 ? 1 : 0,
        ProductType: productType,
        LongDescription: longDescription,
        // ConditionsIds: [],
        Tags: tags,
        SellerObligations: [],
        SellerRestrictions: [],
        Categories: categories,
        AttributeValues: attributeValues,
        // AvailabilityStartDate: availabilityStartDate,
        // AvailabilityEndDate: availabilityEndDate,
        Files: files,
        ConditionOne:
          newConditionId && selectedCondition === 1
            ? newConditionId
            : conditionOne?.Id
            ? conditionOne?.Id
            : undefined,
        ConditionTwo:
          newConditionId && selectedCondition === 2
            ? newConditionId
            : conditionTwo?.Id
            ? conditionTwo?.Id
            : undefined,
        ConditionThree:
          newConditionId && selectedCondition === 3
            ? newConditionId
            : conditionThree?.Id
            ? conditionThree?.Id
            : undefined,
      };

      const response = await api.put(`Products`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      // setOfferId('');
      setCanBeRepublished(response.data.Response.CanOfferBeRepublished);
      getSingleProduct(productId);
      setIsConditionEditing(false);
      setIsProductEditing(false);
      // setIsNewConditionCreateOpen(false);
      // setIsEditConditionCreateOpen(false);
      // if (
      //   selectedConditionOne ||
      //   selectedConditionTwo ||
      //   selectedConditionThree
      // ) {
      //   setIsEditingShownAndDisabled(true);
      // } else {
      //   setIsEditingShownAndDisabled(false);
      // }
      return response.data.Response.ReadyForOffer;
    } catch (error) {
      console.error(error);
      if (
        error?.response?.data?.Error?.Message ===
        'There need to be at least one main file.'
      ) {
        Alert('Musi być dodany conajmniej jeden plik finalny.', 'Błąd.');
      } else Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsProductUpdating(false);
    }
  };

  const getSeller = async (producerId: string) => {
    try {
      const response = await api.get(`seller`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setSeller(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const getOfferCount = async (producerId: string) => {
    try {
      const response = await api.get(`Offer/${producerId}/count`);
      setOfferCount(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getCoverFile = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();

      if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
        try {
          const base64 = await toBase64(e.target.files[0]);

          setFiles((prev) => [
            ...prev,
            {
              File: base64.toString().split(',')[1],
              ProductFileType: 0,
              Name: e.target.files[0].name,
              Position:
                files.filter((file) => file.ProductFileType === 0).length + 1,
            },
          ]);
          setImagePreview(URL.createObjectURL(e.target.files[0]));
          Alert(`Dodano plik ${e.target.files[0].name}`, 'Komunikat');
        } catch (error) {
          console.log(error);
        }
      } else {
        Alert('Wybierz zdjęcie w formacie .jpg lub .png', 'Komunikat');
      }
    } else {
      return;
    }
  };

  const backToProductEditing = () => {
    setIsProductEditing(true);
    setIsConditionEditing(false);
    setInitialConditionOne(null);
    setInitialConditionTwo(null);
    setInitialConditionThree(null);
    setConditionOne(null);
    setConditionTwo(null);
    setConditionThree(null);
  };

  const getImagePreview = (file: any) => URL.createObjectURL(file);

  const handleAvailibityDurationChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setAvailibityDuration(+e.target.value);

  const createOffer = async () => {
    // if (!availabilityStartDate || !availabilityEndDate)
    //   return alert('Wybierz date rozpoczęcia i zakończenia oferty.');
    if (!isReadyForOffer)
      return Alert('Oferta nie jest gotowa do publikacji', 'Komunikat');
    if (isOfferCreating) return;
    setIsOfferCreating(true);
    try {
      // const date1: any = new Date(availabilityStartDate);
      // const date2: any = new Date(availabilityEndDate);
      // const diffTime = Math.abs(date2 - date1);
      // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      const body = {
        ProductId: productId,
        // ProducerId: decodedToken.ProducerId,
        Featured:
          offerCount.FeaturedOffersCount >=
          seller.CurrentPlan.Plan.MaxOffersHighlights
            ? false
            : highlightOffer,
        AvailabilityDuration: availibityDuration,
        AutoRenewalEnabled: autoRenewalEnabled,
      };

      const response = await api.post(`Offer`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      getSingleOffer(productId);
      getOfferCount(decodedToken.ProducerId);
      getSingleProduct(singleProductId);
      setIsProductEditing(false);
      setIsConditionEditing(false);
      Alert('Oferta została opublikowana', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsOfferCreating(false);
    }
  };

  const generateImage = async () => {
    setErrorVerification({
      nameError: null,
      descriptionError: null,
    });
    let areThereErrors = false;

    if (!name) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          nameError: 'Uzupełnij nazwe produktu',
        }));
        areThereErrors = true;
      }
    }

    if (name.length > 50) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          nameError: 'Nazwa produktu może mieć maksymalnie 50 znaków',
        }));
        areThereErrors = true;
      }
    }

    const categories = [];
    if (primaryCategory) categories.push(primaryCategory);
    if (secondaryCategory) categories.push(secondaryCategory);
    if (tertiaryCategory) categories.push(tertiaryCategory);
    if (categories.length === 0) return Alert('Wybierz kategorie', 'Komunikat');

    if (productType === '') return Alert('Wybierz typ produktu', 'Komunikat');

    if (areThereErrors) return;

    if (isImageGenerating) return;
    setIsImageGenerating(true);

    try {
      const body = {
        Hd: true,
        ProducerId: decodedToken.ProducerId,
        ProductName: name,
        Tags: tags.toString(),
        CategoryName: primaryCategory
          ? productCategories.filter(
              (category) => category.Id === primaryCategory,
            )[0].Name
          : '',
        SecondCategoryName: secondaryCategory
          ? productCategories.filter(
              (category) => category.Id === secondaryCategory,
            )[0].Name
          : '',
        ThirdCategoryName: tertiaryCategory
          ? productCategories.filter(
              (category) => category.Id === tertiaryCategory,
            )[0].Name
          : '',
        Attributes: '',
        MyPrompt: '',
      };

      const response = await api.post(`Producer/dalle`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setFiles((prev) => [
        ...prev,
        {
          File: response.data.Response.B64,
          ProductFileType: 0,
          Name: 'dalle',
          Position:
            files.filter((file) => file.ProductFileType === 0).length + 1,
        },
      ]);
      setImagePreview(response.data.Response.B64);
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsImageGenerating(false);
    }
  };

  const updateOffer = async () => {
    // if (!isReadyForOffer) return;
    if (isOfferUpdating) return;
    setIsOfferUpdating(true);
    try {
      const body = {
        ProductId: singleProductId,
        Featured:
          offerCount.FeaturedOffersCount >=
          seller.CurrentPlan.Plan.MaxOffersHighlights
            ? false
            : highlightOffer,
        Id: offerId,
        AutoRenewalEnabled: autoRenewalEnabled,
      };

      const response = await api.put(`Offer`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setIsUpdateButtonClicked(true);
      getSingleOffer(productId);
      getSingleProduct(singleProductId);
      getOfferCount(decodedToken.ProducerId);
      Alert('Oferta została zaktualizowana', 'Komunikat');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsOfferUpdating(false);
    }
  };

  const getSingleOffer = async (productId: string) => {
    setIsOfferLoading(true);
    if (isOfferLoading) return;

    try {
      const response = await api.get(`products/${productId}/offer`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      const {
        Featured,
        AvailabilityDuration,
        Id,
        AvailabilityEndDate,
        CanBeRepublished,
        AutoRenewalEnabled,
      } = response.data.Response;
      setAutoRenewalEnabled(AutoRenewalEnabled);
      setCanBeRepublished(CanBeRepublished);
      setHighlightOffer(Featured);
      setAvailibityDuration(AvailabilityDuration);
      setOfferId(Id);
      setAvailabilityEndDate(AvailabilityEndDate);
    } catch (error) {
      console.error(error);
    } finally {
      setIsOfferLoading(false);
    }
  };

  const getSingleProduct = async (id: string) => {
    setIsProductLoading(true);
    if (isProductLoading) return;

    try {
      const response = await api.get(
        `producers/${decodedToken.ProducerId}/products/${id}`,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      console.log('res: ', response.data);
      const {
        AttributeValues,
        // AvailabilityEndDate,
        // AvailabilityStartDate,
        Categories,
        ConditionsIds,
        CoverFiles,
        DemoFiles,
        Description,
        LongDescription,
        Id,
        Name,
        MainFiles,
        ProductStatus,
        ProductDisplayStatus,
        ProductType,
        SellerObligations,
        SellerRestrictions,
        ReadyForOffer,
        Symbol,
        Tags,
        AssignedConditions,
        CanOfferBeRepublished,
      } = response.data.Response;
      const tagsNames = [];
      Tags.map((tag: {Id: string; Name: string; ProductId: string}) =>
        tagsNames.push(tag.Name),
      );

      const firstCategory = Categories.filter(
        (category) => category.ParentCategoryId === null,
      );

      const secondCategory =
        firstCategory.length > 0 &&
        Categories.filter(
          (category) => category.ParentCategoryId === firstCategory[0].Id,
        );

      const tertiaryCategory =
        secondCategory.length > 0 &&
        Categories.filter(
          (category) => category.ParentCategoryId === secondCategory[0].Id,
        );

      // const availabilityStartDateSplitStringArray =
      //   AvailabilityStartDate.split('-');
      // const availabilityStartDateString = `${
      //   availabilityStartDateSplitStringArray[0]
      // }-${
      //   availabilityStartDateSplitStringArray[1]
      // }-${availabilityStartDateSplitStringArray[2].substring(2, 0)}`;

      // const availabilityEndDateSplitStringArray =
      //   AvailabilityEndDate.split('-');
      // const availabilityEndDateString = `${
      //   availabilityEndDateSplitStringArray[0]
      // }-${
      //   availabilityEndDateSplitStringArray[1]
      // }-${availabilityEndDateSplitStringArray[2].substring(2, 0)}`;

      const coverFilesWithProductFileType = CoverFiles.map((file) => ({
        ...file,
        ProductFileType: 0,
      }));

      const demoFilesWithProductFileType = DemoFiles.filter(
        (file) => file.ProductFileType === 1,
      ).map((file) => ({
        ...file,
        ProductFileType: 1,
      }));

      const mainFilesWithProductFileType = MainFiles.map((file) => ({
        ...file,
        ProductFileType: 2,
      }));

      const snippetFilesWithProductFileType = DemoFiles.filter(
        (file) => file.ProductFileType === 3,
      ).map((file) => ({
        ...file,
        ProductFileType: 3,
      }));

      if (ProductType === 4 && LongDescription) {
        setLongDescriptionOrGallery('longDescription');
      } else if (ProductType === 4 && !LongDescription)
        setLongDescriptionOrGallery('gallery');
      setProductDisplayStatus(ProductDisplayStatus);
      setIsReadyForOffer(ReadyForOffer);
      setName(Name);
      setSymbol(Symbol);
      setTags(tagsNames);
      setCoverFiles(CoverFiles);
      setProductType(ProductType);
      // setAvailabilityStartDate(availabilityStartDateString);
      // setAvailabilityEndDate(availabilityEndDateString);
      setCanBeRepublished(CanOfferBeRepublished);
      setDescription(Description);
      setLongDescription(LongDescription);
      setFiles(
        coverFilesWithProductFileType
          .concat(demoFilesWithProductFileType)
          .concat(mainFilesWithProductFileType)
          .concat(snippetFilesWithProductFileType),
      );
      // SellerObligations.map((sellerObligation) =>
      //   setSellerObligations((prev) => [...prev, sellerObligation.Name]),
      // );
      setConditionOne(null);
      setConditionTwo(null);
      setConditionThree(null);

      AssignedConditions.map((assignedCondition) => {
        const {ConditionId, OrdinalNumber} = assignedCondition;

        if (OrdinalNumber === 1) {
          setInitialConditionOne({
            ...assignedCondition.Condition,
            GrossValue: assignedCondition.Condition.GrossValue / 100,
            AssignedProducts: [{Id: Id}],
            IsExclusive: assignedCondition.Condition.IsExclusive ? 1 : 2,
            Tax:
              assignedCondition.Condition.IsTaxFree === true
                ? 99
                : assignedCondition.Condition.Tax,
            userWinWin:
              assignedCondition.Condition.WinWinDigital ||
              assignedCondition.Condition.WinWinPhysical ||
              assignedCondition.Condition.WinWinYoutube
                ? true
                : false,
          });
          return setConditionOne({
            ...assignedCondition.Condition,
            GrossValue: assignedCondition.Condition.GrossValue / 100,
            AssignedProducts: [{Id: Id}],
            IsExclusive: assignedCondition.Condition.IsExclusive ? 1 : 2,
            Tax:
              assignedCondition.Condition.IsTaxFree === true
                ? 99
                : assignedCondition.Condition.Tax,
            userWinWin:
              assignedCondition.Condition.WinWinDigital ||
              assignedCondition.Condition.WinWinPhysical ||
              assignedCondition.Condition.WinWinYoutube
                ? true
                : false,
          });
        } else if (OrdinalNumber === 2) {
          setInitialConditionTwo({
            ...assignedCondition.Condition,
            GrossValue: assignedCondition.Condition.GrossValue / 100,
            AssignedProducts: [{Id: Id}],
            IsExclusive: assignedCondition.Condition.IsExclusive ? 1 : 2,
            Tax:
              assignedCondition.Condition.IsTaxFree === true
                ? 99
                : assignedCondition.Condition.Tax,
            userWinWin:
              assignedCondition.Condition.WinWinDigital ||
              assignedCondition.Condition.WinWinPhysical ||
              assignedCondition.Condition.WinWinYoutube
                ? true
                : false,
          });
          return setConditionTwo({
            ...assignedCondition.Condition,
            GrossValue: assignedCondition.Condition.GrossValue / 100,
            AssignedProducts: [{Id: Id}],
            IsExclusive: assignedCondition.Condition.IsExclusive ? 1 : 2,
            Tax:
              assignedCondition.Condition.IsTaxFree === true
                ? 99
                : assignedCondition.Condition.Tax,
            userWinWin:
              assignedCondition.Condition.WinWinDigital ||
              assignedCondition.Condition.WinWinPhysical ||
              assignedCondition.Condition.WinWinYoutube
                ? true
                : false,
          });
        } else if (OrdinalNumber === 3) {
          setInitialConditionThree({
            ...assignedCondition.Condition,
            GrossValue: assignedCondition.Condition.GrossValue / 100,
            AssignedProducts: [{Id: Id}],
            IsExclusive: assignedCondition.Condition.IsExclusive ? 1 : 2,
            Tax:
              assignedCondition.Condition.IsTaxFree === true
                ? 99
                : assignedCondition.Condition.Tax,
            userWinWin:
              assignedCondition.Condition.WinWinDigital ||
              assignedCondition.Condition.WinWinPhysical ||
              assignedCondition.Condition.WinWinYoutube
                ? true
                : false,
          });
          return setConditionThree({
            ...assignedCondition.Condition,
            GrossValue: assignedCondition.Condition.GrossValue / 100,
            AssignedProducts: [{Id: Id}],
            IsExclusive: assignedCondition.Condition.IsExclusive ? 1 : 2,
            Tax:
              assignedCondition.Condition.IsTaxFree === true
                ? 99
                : assignedCondition.Condition.Tax,
            userWinWin:
              assignedCondition.Condition.WinWinDigital ||
              assignedCondition.Condition.WinWinPhysical ||
              assignedCondition.Condition.WinWinYoutube
                ? true
                : false,
          });
        }
        return;
      });

      // if (AssignedConditions.length > 0) {
      //   setIsEditingShownAndDisabled(true);
      // }

      // AttributeValues.map((attributeValue) =>
      //   setAttributeValues((prev) => [...prev, attributeValue.Id]),
      // );

      AttributeValues.map((AttributeValue) => {
        if (
          AttributeValue.AttributeId === 'f65268e6-c5f9-4f82-9203-68c673cbab06'
        ) {
          setTempo(AttributeValue.Id);
        }

        if (
          AttributeValue.AttributeId === 'f65268e6-c5f9-4f82-9203-68c673cbab08'
        ) {
          setVibe(AttributeValue.Id);
        }

        if (
          AttributeValue.AttributeId === 'f65268e6-c5f9-4f82-9203-68c673cbab07'
        ) {
          setTone(AttributeValue.Id);
        }
      });

      if (firstCategory.length > 0) {
        setPrimaryCategory(firstCategory[0].Id);
      }

      if (secondCategory.length > 0) {
        setSecondaryCategory(secondCategory[0].Id);
      }

      if (tertiaryCategory.length > 0) {
        setTertiaryCategory(tertiaryCategory[0].Id);
      }
    } catch (error) {
      console.error(error);
      navigate('/myproducts');
    } finally {
      setIsProductLoading(false);
    }
  };

  const createProduct = async () => {
    setErrorVerification({
      nameError: null,
      descriptionError: null,
    });
    let areThereErrors = false;

    if (!name) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          nameError: 'Uzupełnij nazwe produktu',
        }));
        areThereErrors = true;
      }
    }

    if (name.length > 50) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          nameError: 'Nazwa produktu może mieć maksymalnie 50 znaków',
        }));
        areThereErrors = true;
      }
    }

    if (productType !== 8 && description.length < 50) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          descriptionError: 'Opis produktu musi mieć minimum 50 znaków',
        }));
        areThereErrors = true;
      }
    }

    if (productType !== 8 && !description) {
      {
        setErrorVerification((prev) => ({
          ...prev,
          descriptionError: 'Uzupełnij opis produktu',
        }));
        areThereErrors = true;
      }
    }

    if (productType === '') return Alert('Wybierz typ produktu', 'Komunikat');

    if (areThereErrors) return;

    if (files.filter((file) => file.ProductFileType === 0).length === 0) {
      return Alert('Okładka jest wymagana', 'Komunikat');
    }

    if (
      productType === 1 &&
      files.filter((file) => file.ProductFileType === 2).length === 0
    ) {
      return Alert('Plik finalny jest wymagany', 'Komunikat');
    }

    if (
      productType === 8 &&
      (files.filter((file) => file.ProductFileType === 2).length === 0 ||
        files.filter((file) => file.ProductFileType === 3).length === 0)
    ) {
      return Alert(
        'Plik finalny i wersja do odsłuchu jest wymagana',
        'Komunikat',
      );
    }

    if (productType === 8 && (!tone || !vibe || !tempo)) {
      return Alert('Wybierz tempo, tonacje i nastrój', 'Komunikat');
    }

    const categories = [];
    if (primaryCategory) categories.push(primaryCategory);
    if (secondaryCategory) categories.push(secondaryCategory);
    if (tertiaryCategory) categories.push(tertiaryCategory);
    if (categories.length === 0) return Alert('Wybierz kategorie', 'Komunikat');

    const attributeValues = [];
    if (tone) attributeValues.push(tone);
    if (vibe) attributeValues.push(vibe);
    if (tempo) attributeValues.push(tempo);

    if (isProductCreating) return;
    setIsProductCreating(true);

    try {
      const body = {
        Name: name,
        Symbol: symbol,
        Description: description,
        LongDescription: longDescription,
        ProductStatus:
          files.filter((file) => file.ProductFileType === 0).length > 0 ? 1 : 0,
        ProductType: productType,
        // ConditionsIds: [],
        Tags: tags,
        SellerObligations: [],
        SellerRestrictions: [],
        Categories: categories,
        AttributeValues: attributeValues,
        // AvailabilityStartDate: availabilityStartDate,
        // AvailabilityEndDate: availabilityEndDate,
        Files: files,
        ConditionOne: conditionOne?.Id ? conditionOne?.Id : undefined,
        ConditionTwo: conditionTwo?.Id ? conditionTwo?.Id : undefined,
        ConditionThree: conditionThree?.Id ? conditionThree?.Id : undefined,
      };

      const response = await api.post(`Products`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      navigate(`/myproducts/product/${response.data.Response}`);
      getSingleProduct(response.data.Response);
      setSingleProductId(response.data.Response);
      setProductId(response.data.Response);
      setIsProductEditing(false);
      setIsConditionEditing(true);
    } catch (error) {
      console.error(error);
      if (
        error?.response?.data?.Error?.Message ===
        'There need to be at least one main file.'
      ) {
        Alert('Musi być dodany conajmniej jeden plik finalny.', 'Błąd.');
      } else Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsProductCreating(false);
    }
  };

  const getUserMetaData = async () => {
    try {
      const response = await api.get(
        `/Account/GetUserMetadata
  `,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );
      setUserMetaData(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const getProductCategories = async (productType: number | string) => {
    if (productType === '') return;
    try {
      const response = await api.get(
        `Product-Constants/${productType}/categories`,
      );
      setProductCategories(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  const getConditionsList = async (searchString: string) => {
    try {
      const response = await api.get(`Condition/getAll`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
        params: {
          searchString,
          pageSize: 500,
        },
      });
      response.data.map((condition) => {
        const {Id, Name, ProductType} = condition;

        setConditionsList((prev) => [...prev, {Id, Name, ProductType}]);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const createOrUpdateCondition = async (
    condition: ConditionWithHelpers,
    createCondition: boolean,
    conditionNumber: number,
  ): Promise<string | null> => {
    if (condition?.Id && !condition?.Name) {
      Alert('Uzupełnij nazwę warunku', 'Komunikat');
      return null;
    }

    if (condition?.Id && condition?.Name.length > 30) {
      Alert('Nazwa warunku nie może mieć więcej niż 30 znaków', 'Komunikat');
      return null;
    }

    if (condition?.Id && condition?.SellerObligations.length === 0) {
      Alert('Dodaj conajmniej jedno zobowiązanie', 'Komunikat');
      return null;
    }

    if (condition?.Id && !condition?.userWinWin && !condition?.GrossValue) {
      Alert(
        'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
        'Komunikat',
      );
      return null;
    }

    if (
      condition?.Id &&
      condition?.ProductType === 4 &&
      !condition?.GrossValue
    ) {
      Alert('Wypełnij kwotę gwarantowaną', 'Komunikat');
      return null;
    }

    if (
      condition?.Id &&
      condition?.ProductType !== 4 &&
      condition?.userWinWin &&
      !condition?.TimeToUploadForCustomer &&
      condition?.TimeToUploadForCustomer !== 0
    ) {
      Alert('Wybierz date wydania w mugo', 'Komunikat');
      return null;
    }

    if (
      condition?.Id &&
      condition?.ProductType !== 4 &&
      condition?.userWinWin &&
      !condition?.WinWinDigital &&
      !condition?.WinWinPhysical &&
      !condition?.WinWinYoutube
    ) {
      Alert('Uzupełnij udział w przychodach', 'Komunikat');
      return null;
    }

    if (isConditionCreating) return null;
    setIsConditionCreating(true);

    try {
      let response;
      const body = {
        Id: createCondition ? undefined : condition?.Id,
        ProducerId: decodedToken.ProducerId,
        IsExclusive:
          condition?.IsExclusive == 1
            ? true
            : condition?.IsExclusive == 2 && false,
        CanPriceBeNegotiated: condition?.CanPriceBeNegotiated,
        GrossValue: condition?.GrossValue * 100,
        Tax: condition?.Tax === 99 ? 0 : condition?.Tax,
        IsTaxFree: condition?.Tax === 99 ? true : false,
        ProductType: productType,
        Currency: 0,
        Description: condition?.Description,
        RightsEnd: condition?.RightsEnd,
        Name: condition?.Name,
        Invoice: condition?.Invoice,
        AnotherLimits: condition?.AnotherLimits,
        ExploitationDigital: condition?.ExploitationDigital,
        ExploitationPhysical: condition?.ExploitationPhysical,
        ExploitationYoutube: condition?.ExploitationYoutube,
        DateToUploadForCustomer:
          productType !== 4 && condition?.userWinWin
            ? new Date(condition?.DateToUploadForCustomer)
            : productType === 4
            ? null
            : null,
        TimeToUploadForCustomer: condition?.TimeToUploadForCustomer,
        WinWinDigital:
          productType !== 4 && condition?.userWinWin
            ? condition?.WinWinDigital
            : productType === 4
            ? 0
            : 0,
        WinWinPhysical:
          productType !== 4 && condition?.userWinWin
            ? condition?.WinWinPhysical
            : productType === 4
            ? 0
            : 0,
        WinWinYoutube:
          productType !== 4 && condition?.userWinWin
            ? condition?.WinWinYoutube
            : productType === 4
            ? 0
            : 0,
        SellerObligations: condition?.SellerObligations,
        SellerRestrictions: condition?.SellerRestrictions,
        TimeToFinish: condition?.TimeToFinish,
        ProtectiveUmbrella: condition?.ProtectiveUmbrella,
        TransactionFee: condition?.TransactionFee,
        IndicationOfContentAuthor: condition?.isIndicationOfContentAuthor
          ? condition?.IndicationOfContentAuthor
          : '',
      };

      if (createCondition) {
        response = await api.post('Condition/Create', body, {
          headers: {Authorization: `bearer ${cookies.token}`},
        });

        const newId = response.data.Response.Id;

        if (conditionNumber === 1) {
          setConditionOne({...condition, Id: newId});
        } else if (conditionNumber === 2) {
          setConditionTwo({...condition, Id: newId});
        } else if (conditionNumber === 3) {
          setConditionThree({...condition, Id: newId});
        }

        setConditionsList([]);
        getConditionsList('');

        return newId;
      } else {
        response = await api.put('Condition/Update', body, {
          headers: {Authorization: `bearer ${cookies.token}`},
        });

        setConditionsList([]);
        getConditionsList('');

        return condition?.Id;
      }
    } catch (error) {
      console.error('API error:', error);
      if (
        error?.response?.data?.Error?.Message ===
        'A condition with the same name already exists.'
      ) {
        Alert('Warunek o tej nazwie już istnieje.', 'Błąd.');
      } else if (
        error?.response?.data?.Error?.Message ===
        'There need to be at least one main file.'
      ) {
        Alert('Musi być dodany conajmniej jeden plik finalny.', 'Błąd.');
      } else {
        const errorMessage =
          error?.response?.data?.Error?.Message ||
          error.response?.data?.message ||
          error.message ||
          'Nieznany błąd';
        Alert(`${errorMessage}`, 'Błąd');
      }

      return null;
    } finally {
      setIsConditionCreating(false);
    }

    return null;
  };

  const updateProductAndConditions = async () => {
    try {
      let newId1: string | null = null;
      let newId2: string | null = null;
      let newId3: string | null = null;

      const adjustAssignedProductsCount = (condition: ConditionWithHelpers) => {
        const assignedProducts = condition?.AssignedProducts || [];
        if (assignedProducts.some((p) => p.Id === productId)) {
          return (condition?.AssignedProductsCount || 0) - 1;
        }
        return condition?.AssignedProductsCount || 0;
      };

      const hasConditionChanged = (initialCondition, currentCondition) => {
        return !deepEqual(initialCondition, currentCondition);
      };

      const handleCondition = async (
        condition,
        initialCondition,
        conditionIndex,
      ) => {
        const adjustedCount = adjustAssignedProductsCount(condition);
        const result = await createOrUpdateCondition(
          {...condition, AssignedProductsCount: adjustedCount},
          condition?.Id === `0000-${conditionIndex}` ||
            adjustedCount > 0 ||
            condition?.IsDefault,
          conditionIndex,
        );
        if (result === null) return null;
        return result;
      };

      const checkAndHandleConditions = async () => {
        if (
          conditionOne?.Id &&
          hasConditionChanged(initialConditionOne, conditionOne)
        ) {
          newId1 = await handleCondition(conditionOne, initialConditionOne, 1);
          if (newId1 === null) return false;
        }

        if (conditionTwo?.Id) {
          if (conditionTwo?.Id === conditionOne?.Id) {
            conditionTwo.Id = `0000-2`;
          }
          if (hasConditionChanged(initialConditionTwo, conditionTwo)) {
            newId2 = await handleCondition(
              conditionTwo,
              initialConditionTwo,
              2,
            );
            if (newId2 === null) return false;
          }
        }

        if (conditionThree?.Id) {
          if (
            conditionThree?.Id === conditionOne?.Id ||
            conditionThree?.Id === conditionTwo?.Id
          ) {
            conditionThree.Id = `0000-3`;
          }
          if (hasConditionChanged(initialConditionThree, conditionThree)) {
            newId3 = await handleCondition(
              conditionThree,
              initialConditionThree,
              3,
            );
            if (newId3 === null) return false;
          }
        }

        return true;
      };

      const conditionsHandled = await checkAndHandleConditions();

      if (!conditionsHandled) return;

      console.log('All conditions processed successfully:', {
        newId1,
        newId2,
        newId3,
      });

      if (newId1) conditionOne.Id = newId1;
      if (newId2) conditionTwo.Id = newId2;
      if (newId3) conditionThree.Id = newId3;

      const isReadyForOffer = await editProduct(productId);
      return isReadyForOffer;
    } catch (error) {
      console.error('Błąd podczas aktualizacji warunków lub produktu:', error);
      Alert('Błąd podczas aktualizacji warunków lub produktu:', 'Błąd');
    }
  };

  const handlePrimaryCategoryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setPrimaryCategory(e.target.value);

  const handleVibeChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setVibe(e.target.value);

  const handleToneChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setTone(e.target.value);

  const handleTempoChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setTempo(e.target.value);

  const handleSecondaryCategoryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setSecondaryCategory(e.target.value);

  const handleTertiaryCategoryChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => setTertiaryCategory(e.target.value);

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setDescription(e.target.value);
    setErrorVerification((prev) => ({...prev, descriptionError: null}));
    // setErrorVerification({
    //   nameError: null,
    //   descriptionError: null,
    // });
  };

  const handleLongDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setLongDescription(e.target.value);

  const handleSymbolChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSymbol(e.target.value);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorVerification((prev) => ({...prev, nameError: null}));
    setName(e.target.value);
  };

  const getDemoFile = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();

      if (
        (productType === 8 && extFile === 'mp3') ||
        (productType !== 8 &&
          (extFile === 'jpg' ||
            extFile === 'jpeg' ||
            extFile === 'png' ||
            extFile === 'gif'))
      ) {
        try {
          const base64 = await toBase64(e.target.files[0]);

          setFiles((prev) => [
            ...prev,
            {
              File: base64.toString().split(',')[1],
              ProductFileType: 1,
              Name: e.target.files[0].name,
              PositionOrder:
                files.filter((file) => file.ProductFileType === 1).length + 1,
            },
          ]);
          Alert(`Dodano plik ${e.target.files[0].name}`, 'Komunikat');
        } catch (error) {
          console.log(error);
        }
      } else {
        Alert('Zły format pliku', 'Komunikat');
      }
    } else {
      return;
    }
  };

  const getProductFile = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();

      if (
        (productType === 8 &&
          (extFile === 'rar' ||
            extFile === 'zip' ||
            extFile === 'wav' ||
            extFile === 'mp3')) ||
        productType !== 8
      ) {
        try {
          const base64 = await toBase64(e.target.files[0]);

          setFiles((prev) => [
            ...prev,
            {
              File: base64.toString().split(',')[1],
              ProductFileType: 2,
              Name: e.target.files[0].name,
              PositionOrder:
                files.filter((file) => file.ProductFileType === 2).length + 1,
            },
          ]);
          Alert(`Dodano plik ${e.target.files[0].name}`, 'Komunikat');
        } catch (error) {
          console.log(error);
        }
      } else {
        Alert('Zły format pliku', 'Komunikat');
      }
    } else {
      return;
    }
  };

  const getSnippetFile = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();

      if (
        (productType === 8 && extFile === 'mp3') ||
        (productType !== 8 &&
          (extFile === 'jpg' ||
            extFile === 'jpeg' ||
            extFile === 'png' ||
            extFile === 'mp4' ||
            extFile === 'pdf' ||
            extFile === 'ico' ||
            extFile === 'rar' ||
            extFile === 'rtf' ||
            extFile === 'srt' ||
            extFile === 'flac' ||
            extFile === 'wav' ||
            extFile === 'mp3' ||
            extFile === 'xlsx' ||
            extFile === 'zip'))
      ) {
        try {
          const base64 = await toBase64(e.target.files[0]);

          setFiles((prev) => [
            ...prev,
            {
              File: base64.toString().split(',')[1],
              ProductFileType: 3,
              Name: e.target.files[0].name,
              PositionOrder:
                files.filter((file) => file.ProductFileType === 3).length + 1,
            },
          ]);
          Alert(`Dodano plik ${e.target.files[0].name}`, 'Komunikat');
        } catch (error) {
          console.log(error);
        }
      } else {
        Alert('Zły format pliku', 'Komunikat');
      }
    } else {
      return;
    }
  };

  const deleteNewFile = (fileToDelete: File) => {
    const newFilesArray = files.filter((file) => {
      if (!file.Id && file.ProductFileType === fileToDelete.ProductFileType) {
        return file.Name !== fileToDelete.Name;
      } else {
        return file;
      }
    });
    setFiles(newFilesArray);
  };

  const deleteProduct = async (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const response = await api.delete(`products/${id}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      navigate('/myproducts');
      Alert('Oferta została usunięta.', 'Komunikat');
    } catch (error) {
      console.log(error);
    }
  };

  const deleteExistingFile = async (fileToDelete: File) => {
    try {
      const body = {
        ProductId: singleProductId,
        ProducerId: decodedToken.ProducerId,
        FileId: fileToDelete.Id,
        ProductFileType: fileToDelete.ProductFileType,
      };

      // const response = await api.delete(
      //   `products/${singleProductId}/file/${fileToDelete.Id}`,
      //   {
      //     data: {...body},
      //     headers: {
      //       Authorization: `Bearer ${cookies.token}`,
      //     },
      //   },
      // );

      const newFilesArray = files.filter((file) => {
        if (file.Id && file.ProductFileType === fileToDelete.ProductFileType) {
          return file.Id !== fileToDelete.Id;
        } else {
          return file;
        }
      });
      setFiles(newFilesArray);
    } catch (error) {
      console.log(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  // const deleteSellerObligations = (sellerObligation: string) =>
  //   setSellerObligations((prev) => [
  //     ...prev.filter(
  //       (prevSellerObligation) => prevSellerObligation !== sellerObligation,
  //     ),
  //   ]);

  // const deleteSellerRestrictions = (sellerRestriction: string) =>
  //   setSellerRestrictions((prev) => [
  //     ...prev.filter(
  //       (prevSellerRestriction) => prevSellerRestriction !== sellerRestriction,
  //     ),
  //   ]);

  const handleIsProductEditingChange = (value: boolean) =>
    setIsProductEditing(value);

  const getProductAttributes = async (productType: number | string) => {
    if (productType === '') return;
    try {
      const response = await api.get(
        `Product-Constants/${productType}/attributes`,
      );
      console.log(response.data.Response);
      setProductAttributes(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setSingleProductId(params.id);
    if (!params.id) {
      // setIsProductEditing(true);
    } else {
      setCreateNewProductStep(0);
      setIsProductEditing(false);
      setProductId(params.id);
      setIsMobiileConditionsVisible(true);
    }
  }, []);

  useEffect(() => {
    if (singleProductId) {
      getSingleProduct(singleProductId);
      getSingleOffer(singleProductId);
    }
  }, [singleProductId]);

  useEffect(() => {
    getOfferCount(decodedToken.ProducerId);
    getSeller(decodedToken.ProducerId);
    getConditionsList('');
    getUserMetaData();
  }, []);

  useEffect(() => {
    getProductCategories(productType);
    getProductAttributes(productType);
  }, [productType]);

  if (createNewProductStep === 1)
    return windowWidth > 1024 ? (
      <MainWrapper>
        <FirstStepMain>
          <HeaderWrapper>
            <p>Dodawanie oferty</p>
          </HeaderWrapper>
          <FirstStepContentWrapper>
            <h5>Wybierz typ oferty</h5>
            <SelectProductTypeWrapper>
              <SingleProductType>
                <ProductTypeButton
                  onClick={() => {
                    setCreateNewProductStep(2);
                    setProductType(1);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36.332"
                    height="36"
                    viewBox="0 0 36.332 36"
                  >
                    <path
                      id="Path_4013"
                      data-name="Path 4013"
                      d="M206.416,116.664l-15.015.323.262-14.89a1.636,1.636,0,1,0-3.255,0l.261,14.89-15.014-.323a1.635,1.635,0,1,0,0,3.255l15.014-.323-.261,14.888a1.637,1.637,0,1,0,3.255,0L191.4,119.6l15.015.323a1.635,1.635,0,1,0,0-3.255Z"
                      transform="translate(-171.869 -100.292)"
                      fill="rgba(7,2,15,0.7)"
                    />
                  </svg>

                  <h1>Produkt cyfrowy</h1>
                  <svg
                    id="Group_6457"
                    data-name="Group 6457"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="34.639"
                    height="36"
                    viewBox="0 0 34.639 36"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_3499"
                          data-name="Rectangle 3499"
                          width="34.639"
                          height="36"
                          fill="#07020f"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Group_6456"
                      data-name="Group 6456"
                      clipPath="url(#clip-path)"
                    >
                      <path
                        id="Path_4016"
                        data-name="Path 4016"
                        d="M65.2,0H44.874a.75.75,0,0,0-.749.75V21.079a.75.75,0,0,0,.749.75h2.98a.75.75,0,1,0,0-1.5H45.624V1.5h18.83V11.507l-1.271-1.271a1.682,1.682,0,0,0-2.376,0L59.1,11.942A.75.75,0,1,0,60.16,13L61.866,11.3a.181.181,0,0,1,.256,0l2.118,2.118a.752.752,0,0,0,.213.146v6.769H53.062a.75.75,0,1,0,0,1.5H65.2a.75.75,0,0,0,.749-.75V.75A.75.75,0,0,0,65.2,0"
                        transform="translate(-31.315)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4017"
                        data-name="Path 4017"
                        d="M19.646,39.773H18.435L22.6,35.608a.181.181,0,0,1,.256,0l5.336,5.336a.75.75,0,0,0,1.06-1.06l-5.336-5.336a1.68,1.68,0,0,0-2.376,0L16.247,39.84a.872.872,0,0,0,.548,1.432h2.1v17.4H1.5v-17.4h9.828a.75.75,0,1,0,0-1.5H.75a.75.75,0,0,0-.75.75v18.9a.75.75,0,0,0,.75.75h18.9a.75.75,0,0,0,.75-.75v-18.9a.75.75,0,0,0-.75-.75"
                        transform="translate(0 -24.169)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4018"
                        data-name="Path 4018"
                        d="M24.227,72.382a1.509,1.509,0,0,0-.8,1.335v6.468a1.512,1.512,0,0,0,2.358,1.254l4.8-3.234a1.513,1.513,0,0,0,0-2.509l-4.8-3.234a1.511,1.511,0,0,0-1.556-.081m.7,7.8V73.717l.021-.011,4.8,3.256Z"
                        transform="translate(-16.624 -51.242)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4019"
                        data-name="Path 4019"
                        d="M62.133,16.723a2.624,2.624,0,1,0-2.624,2.624,2.627,2.627,0,0,0,2.624-2.624m-3.748,0a1.124,1.124,0,1,1,1.124,1.124,1.126,1.126,0,0,1-1.124-1.124"
                        transform="translate(-40.37 -10.006)"
                        fill="#07020f"
                      />
                    </g>
                  </svg>
                </ProductTypeButton>
                <p>
                  Posiadasz gotowe zdjęcia, grafiki, ilustracje? <br />A może
                  teksty utworów czy produkty w wersji cyfrowej? <br />
                  Zaoferuj je użytkownikom.
                </p>
              </SingleProductType>
              <SingleProductType>
                <ProductTypeButton
                  onClick={() => {
                    setCreateNewProductStep(2);
                    setProductType(4);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36.332"
                    height="36"
                    viewBox="0 0 36.332 36"
                  >
                    <path
                      id="Path_4013"
                      data-name="Path 4013"
                      d="M206.416,116.664l-15.015.323.262-14.89a1.636,1.636,0,1,0-3.255,0l.261,14.89-15.014-.323a1.635,1.635,0,1,0,0,3.255l15.014-.323-.261,14.888a1.637,1.637,0,1,0,3.255,0L191.4,119.6l15.015.323a1.635,1.635,0,1,0,0-3.255Z"
                      transform="translate(-171.869 -100.292)"
                      fill="rgba(7,2,15,0.7)"
                    />
                  </svg>

                  <h1>Usługa</h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40.532"
                    height="34"
                    viewBox="0 0 40.532 34"
                  >
                    <path
                      id="Path_4020"
                      data-name="Path 4020"
                      d="M99.99,0c-3.589,0-6,2.813-6,7s2.411,7,6,7,6-2.813,6-7-2.411-7-6-7m0,12c-2.949,0-4-2.583-4-5s1.051-5,4-5,4,2.583,4,5-1.051,5-4,5"
                      transform="translate(-65.458)"
                      fill="#07020f"
                    />
                    <path
                      id="Path_4021"
                      data-name="Path 4021"
                      d="M37.418,48.113H32.565a10.074,10.074,0,0,0-8.049,4.051l-1.683-4.486a2.464,2.464,0,0,0-2.3-1.566H1.9A1.9,1.9,0,0,0,.154,48.761L5.615,61.505a1,1,0,0,0,.919.607h6.082a1,1,0,0,0,0-2H7.193l-5.142-12H20.529a.47.47,0,0,1,.438.285l2.27,6.05a9.87,9.87,0,0,0-.493,1.64c-.019.09-.6,2.683-.9,4.02l-3.932,0a3.385,3.385,0,0,0-3.382,3.381v.618h-12a1,1,0,0,0,0,2h13l12.292,0c.023,0,.045,0,.068,0h6.639a1,1,0,1,0,0-2h-5.46l.94-4.243a1,1,0,0,0-1.953-.432l-1.035,4.674H16.533v-.618a1.384,1.384,0,0,1,1.383-1.382l4.732,0a1,1,0,0,0,.975-.782s1.071-4.8,1.076-4.823a8.06,8.06,0,0,1,7.864-6.39h4.853a1,1,0,1,0,0-2"
                      transform="translate(0 -32.114)"
                      fill="#07020f"
                    />
                  </svg>
                </ProductTypeButton>
                <p>
                  Jesteś fotografem, grafikiem, programistą? <br /> Zajmujesz
                  się marketingiem internetowym? <br /> Zaproponuj innym swoje
                  usługi!
                </p>
              </SingleProductType>
              <SingleProductType>
                <ProductTypeButton
                  onClick={() => {
                    setCreateNewProductStep(2);
                    setProductType(8);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36.332"
                    height="36"
                    viewBox="0 0 36.332 36"
                  >
                    <path
                      id="Path_4013"
                      data-name="Path 4013"
                      d="M206.416,116.664l-15.015.323.262-14.89a1.636,1.636,0,1,0-3.255,0l.261,14.89-15.014-.323a1.635,1.635,0,1,0,0,3.255l15.014-.323-.261,14.888a1.637,1.637,0,1,0,3.255,0L191.4,119.6l15.015.323a1.635,1.635,0,1,0,0-3.255Z"
                      transform="translate(-171.869 -100.292)"
                      fill="rgba(7,2,15,0.7)"
                    />
                  </svg>

                  <h1>Track</h1>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="39.827"
                    height="34"
                    viewBox="0 0 39.827 34"
                  >
                    <path
                      id="Path_4022"
                      data-name="Path 4022"
                      d="M19.954,84.772a3.381,3.381,0,0,0-6.63-.643H4.167a.912.912,0,0,0,0,1.823h9.156a3.382,3.382,0,0,0,6.631-.641,1.379,1.379,0,0,0,0-.54m-4.94.268c0-2.032,3.034-2.014,3.143,0-.109,2.014-3.143,2.031-3.143,0"
                      transform="translate(-2.191 -54.431)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4023"
                      data-name="Path 4023"
                      d="M77.435,89.1H60.257a.912.912,0,0,0,0,1.823H77.435a.912.912,0,0,0,0-1.823"
                      transform="translate(-39.584 -59.397)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4024"
                      data-name="Path 4024"
                      d="M104.641,21.549c-.641.987.937,1.9,1.574.92a20.206,20.206,0,0,0,0-22.048c-.637-.98-2.216-.068-1.574.92a18.4,18.4,0,0,1,0,20.207"
                      transform="translate(-69.66 -0.001)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4025"
                      data-name="Path 4025"
                      d="M93.354,24.051c-.638.99.94,1.9,1.574.921a15.657,15.657,0,0,0,0-17.033c-.634-.983-2.213-.07-1.574.92a13.841,13.841,0,0,1,0,15.192"
                      transform="translate(-62.136 -5.011)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4026"
                      data-name="Path 4026"
                      d="M4.846,21.548a18.4,18.4,0,0,1,0-20.207c.641-.987-.937-1.9-1.574-.92a20.206,20.206,0,0,0,0,22.048c.637.98,2.216.067,1.574-.92"
                      transform="translate(0 0)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4027"
                      data-name="Path 4027"
                      d="M16.069,24.97c.633.983,2.212.07,1.574-.92a13.841,13.841,0,0,1,0-15.193c.638-.989-.94-1.9-1.574-.92a15.657,15.657,0,0,0,0,17.033"
                      transform="translate(-9.033 -5.01)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4028"
                      data-name="Path 4028"
                      d="M42.036,25.091a9.939,9.939,0,0,0,7.929-9.632,9.816,9.816,0,1,0-18.225,5.067,9.932,9.932,0,0,0,10.3,4.565M40.148,7.466a8.084,8.084,0,0,1,7.993,7.993,7.993,7.993,0,0,1-15.986,0,8.065,8.065,0,0,1,7.993-7.993"
                      transform="translate(-20.235 -3.769)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4029"
                      data-name="Path 4029"
                      d="M50.408,30.471a1.574,1.574,0,0,0,1.625-.085l4.2-2.833a1.58,1.58,0,0,0,0-2.62l-4.2-2.833a1.58,1.58,0,0,0-2.463,1.31v5.666a1.577,1.577,0,0,0,.838,1.395m.985-6.6,3.526,2.376-3.526,2.376Z"
                      transform="translate(-33.047 -14.553)"
                      fill="#1a1a1a"
                    />
                  </svg>
                </ProductTypeButton>
                <p>
                  Tworzysz muzykę? <br /> Masz szufladę pełną gotowych tracków?{' '}
                  <br />
                  Zaprezentuj je i zaoferuj użytkownikom MUGO Studio.
                </p>
              </SingleProductType>
            </SelectProductTypeWrapper>
          </FirstStepContentWrapper>
        </FirstStepMain>
      </MainWrapper>
    ) : (
      <MainWrapperStep1>
        <MobileHeader>
          <h1>Dodawanie oferty</h1>
          <svg
            onClick={() => navigate('/myproducts')}
            xmlns="http://www.w3.org/2000/svg"
            width="10.312"
            height="18"
            viewBox="0 0 10.312 18"
          >
            <path
              id="Path_4070"
              data-name="Path 4070"
              d="M9.947,16.18,2.557,9.1l7.3-7.048A1.221,1.221,0,0,0,9.943.4,1.221,1.221,0,0,0,8.289.485L0,9.1l8.378,8.646a1.207,1.207,0,0,0,1.645.076,1.208,1.208,0,0,0-.076-1.645"
              transform="translate(0 -0.114)"
              fill="#0091ff"
            />
          </svg>
        </MobileHeader>
        <FirstStepMain>
          <HeaderWrapper>
            <p>Dodawanie oferty </p>
          </HeaderWrapper>
          <FirstStepContentWrapper>
            <h5>Wybierz typ oferty</h5>
            <SelectProductTypeWrapper>
              <SingleProductType>
                <ProductTypeButtonMobile
                  isActive={productType === 1}
                  onClick={() => {
                    // setCreateNewProductStep(2);
                    setProductType(1);
                  }}
                >
                  <svg
                    id="Group_6457"
                    data-name="Group 6457"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="34.639"
                    height="36"
                    viewBox="0 0 34.639 36"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_3499"
                          data-name="Rectangle 3499"
                          width="34.639"
                          height="36"
                          fill="#07020f"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Group_6456"
                      data-name="Group 6456"
                      clipPath="url(#clip-path)"
                    >
                      <path
                        id="Path_4016"
                        data-name="Path 4016"
                        d="M65.2,0H44.874a.75.75,0,0,0-.749.75V21.079a.75.75,0,0,0,.749.75h2.98a.75.75,0,1,0,0-1.5H45.624V1.5h18.83V11.507l-1.271-1.271a1.682,1.682,0,0,0-2.376,0L59.1,11.942A.75.75,0,1,0,60.16,13L61.866,11.3a.181.181,0,0,1,.256,0l2.118,2.118a.752.752,0,0,0,.213.146v6.769H53.062a.75.75,0,1,0,0,1.5H65.2a.75.75,0,0,0,.749-.75V.75A.75.75,0,0,0,65.2,0"
                        transform="translate(-31.315)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4017"
                        data-name="Path 4017"
                        d="M19.646,39.773H18.435L22.6,35.608a.181.181,0,0,1,.256,0l5.336,5.336a.75.75,0,0,0,1.06-1.06l-5.336-5.336a1.68,1.68,0,0,0-2.376,0L16.247,39.84a.872.872,0,0,0,.548,1.432h2.1v17.4H1.5v-17.4h9.828a.75.75,0,1,0,0-1.5H.75a.75.75,0,0,0-.75.75v18.9a.75.75,0,0,0,.75.75h18.9a.75.75,0,0,0,.75-.75v-18.9a.75.75,0,0,0-.75-.75"
                        transform="translate(0 -24.169)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4018"
                        data-name="Path 4018"
                        d="M24.227,72.382a1.509,1.509,0,0,0-.8,1.335v6.468a1.512,1.512,0,0,0,2.358,1.254l4.8-3.234a1.513,1.513,0,0,0,0-2.509l-4.8-3.234a1.511,1.511,0,0,0-1.556-.081m.7,7.8V73.717l.021-.011,4.8,3.256Z"
                        transform="translate(-16.624 -51.242)"
                        fill="#07020f"
                      />
                      <path
                        id="Path_4019"
                        data-name="Path 4019"
                        d="M62.133,16.723a2.624,2.624,0,1,0-2.624,2.624,2.627,2.627,0,0,0,2.624-2.624m-3.748,0a1.124,1.124,0,1,1,1.124,1.124,1.126,1.126,0,0,1-1.124-1.124"
                        transform="translate(-40.37 -10.006)"
                        fill="#07020f"
                      />
                    </g>
                  </svg>
                  <ProductTypeButtonTextWrapper isActive={productType === 1}>
                    <h1>Produkt cyfrowy</h1>
                    <p>
                      Posiadasz gotowe zdjęcia, grafiki, ilustracje? A może
                      teksty utworów czy produkty w wersji cyfrowej? Zaoferuj je
                      użytkownikom.
                    </p>
                  </ProductTypeButtonTextWrapper>

                  {productType === 1 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M11.062,5.812A5.25,5.25,0,1,1,5.812.562,5.25,5.25,0,0,1,11.062,5.812ZM5.205,8.592,9.1,4.7a.339.339,0,0,0,0-.479l-.479-.479a.339.339,0,0,0-.479,0L4.966,6.916,3.483,5.433a.339.339,0,0,0-.479,0l-.479.479a.339.339,0,0,0,0,.479l2.2,2.2a.339.339,0,0,0,.479,0Z"
                        transform="translate(3.188 3.188)"
                        fill="#0091ff"
                      />
                      <path
                        id="Ellipse_325"
                        data-name="Ellipse 325"
                        d="M9,1.5A7.5,7.5,0,1,0,16.5,9,7.508,7.508,0,0,0,9,1.5M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
                        fill="#0091ff"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <path
                        id="Ellipse_325"
                        data-name="Ellipse 325"
                        d="M9,1.5A7.5,7.5,0,1,0,16.5,9,7.508,7.508,0,0,0,9,1.5M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
                        fill="#f5f5f5"
                        opacity="0.5"
                      />
                    </svg>
                  )}
                </ProductTypeButtonMobile>
              </SingleProductType>
              <SingleProductType>
                <ProductTypeButtonMobile
                  isActive={productType === 4}
                  onClick={() => {
                    // setCreateNewProductStep(2);
                    setProductType(4);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40.532"
                    height="34"
                    viewBox="0 0 40.532 34"
                  >
                    <path
                      id="Path_4020"
                      data-name="Path 4020"
                      d="M99.99,0c-3.589,0-6,2.813-6,7s2.411,7,6,7,6-2.813,6-7-2.411-7-6-7m0,12c-2.949,0-4-2.583-4-5s1.051-5,4-5,4,2.583,4,5-1.051,5-4,5"
                      transform="translate(-65.458)"
                      fill="#07020f"
                    />
                    <path
                      id="Path_4021"
                      data-name="Path 4021"
                      d="M37.418,48.113H32.565a10.074,10.074,0,0,0-8.049,4.051l-1.683-4.486a2.464,2.464,0,0,0-2.3-1.566H1.9A1.9,1.9,0,0,0,.154,48.761L5.615,61.505a1,1,0,0,0,.919.607h6.082a1,1,0,0,0,0-2H7.193l-5.142-12H20.529a.47.47,0,0,1,.438.285l2.27,6.05a9.87,9.87,0,0,0-.493,1.64c-.019.09-.6,2.683-.9,4.02l-3.932,0a3.385,3.385,0,0,0-3.382,3.381v.618h-12a1,1,0,0,0,0,2h13l12.292,0c.023,0,.045,0,.068,0h6.639a1,1,0,1,0,0-2h-5.46l.94-4.243a1,1,0,0,0-1.953-.432l-1.035,4.674H16.533v-.618a1.384,1.384,0,0,1,1.383-1.382l4.732,0a1,1,0,0,0,.975-.782s1.071-4.8,1.076-4.823a8.06,8.06,0,0,1,7.864-6.39h4.853a1,1,0,1,0,0-2"
                      transform="translate(0 -32.114)"
                      fill="#07020f"
                    />
                  </svg>
                  <ProductTypeButtonTextWrapper isActive={productType === 4}>
                    <h1>Usługa</h1>
                    <p>
                      Jesteś fotografem, grafikiem, programistą? Zajmujesz się
                      marketingiem internetowym? Zaproponuj innym swoje usługi!
                    </p>
                  </ProductTypeButtonTextWrapper>

                  {productType === 4 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M11.062,5.812A5.25,5.25,0,1,1,5.812.562,5.25,5.25,0,0,1,11.062,5.812ZM5.205,8.592,9.1,4.7a.339.339,0,0,0,0-.479l-.479-.479a.339.339,0,0,0-.479,0L4.966,6.916,3.483,5.433a.339.339,0,0,0-.479,0l-.479.479a.339.339,0,0,0,0,.479l2.2,2.2a.339.339,0,0,0,.479,0Z"
                        transform="translate(3.188 3.188)"
                        fill="#0091ff"
                      />
                      <path
                        id="Ellipse_325"
                        data-name="Ellipse 325"
                        d="M9,1.5A7.5,7.5,0,1,0,16.5,9,7.508,7.508,0,0,0,9,1.5M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
                        fill="#0091ff"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <path
                        id="Ellipse_325"
                        data-name="Ellipse 325"
                        d="M9,1.5A7.5,7.5,0,1,0,16.5,9,7.508,7.508,0,0,0,9,1.5M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
                        fill="#f5f5f5"
                        opacity="0.5"
                      />
                    </svg>
                  )}
                </ProductTypeButtonMobile>
              </SingleProductType>

              <SingleProductType>
                <ProductTypeButtonMobile
                  isActive={productType === 8}
                  onClick={() => {
                    // setCreateNewProductStep(2);
                    setProductType(8);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="39.827"
                    height="34"
                    viewBox="0 0 39.827 34"
                  >
                    <path
                      id="Path_4022"
                      data-name="Path 4022"
                      d="M19.954,84.772a3.381,3.381,0,0,0-6.63-.643H4.167a.912.912,0,0,0,0,1.823h9.156a3.382,3.382,0,0,0,6.631-.641,1.379,1.379,0,0,0,0-.54m-4.94.268c0-2.032,3.034-2.014,3.143,0-.109,2.014-3.143,2.031-3.143,0"
                      transform="translate(-2.191 -54.431)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4023"
                      data-name="Path 4023"
                      d="M77.435,89.1H60.257a.912.912,0,0,0,0,1.823H77.435a.912.912,0,0,0,0-1.823"
                      transform="translate(-39.584 -59.397)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4024"
                      data-name="Path 4024"
                      d="M104.641,21.549c-.641.987.937,1.9,1.574.92a20.206,20.206,0,0,0,0-22.048c-.637-.98-2.216-.068-1.574.92a18.4,18.4,0,0,1,0,20.207"
                      transform="translate(-69.66 -0.001)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4025"
                      data-name="Path 4025"
                      d="M93.354,24.051c-.638.99.94,1.9,1.574.921a15.657,15.657,0,0,0,0-17.033c-.634-.983-2.213-.07-1.574.92a13.841,13.841,0,0,1,0,15.192"
                      transform="translate(-62.136 -5.011)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4026"
                      data-name="Path 4026"
                      d="M4.846,21.548a18.4,18.4,0,0,1,0-20.207c.641-.987-.937-1.9-1.574-.92a20.206,20.206,0,0,0,0,22.048c.637.98,2.216.067,1.574-.92"
                      transform="translate(0 0)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4027"
                      data-name="Path 4027"
                      d="M16.069,24.97c.633.983,2.212.07,1.574-.92a13.841,13.841,0,0,1,0-15.193c.638-.989-.94-1.9-1.574-.92a15.657,15.657,0,0,0,0,17.033"
                      transform="translate(-9.033 -5.01)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4028"
                      data-name="Path 4028"
                      d="M42.036,25.091a9.939,9.939,0,0,0,7.929-9.632,9.816,9.816,0,1,0-18.225,5.067,9.932,9.932,0,0,0,10.3,4.565M40.148,7.466a8.084,8.084,0,0,1,7.993,7.993,7.993,7.993,0,0,1-15.986,0,8.065,8.065,0,0,1,7.993-7.993"
                      transform="translate(-20.235 -3.769)"
                      fill="#1a1a1a"
                    />
                    <path
                      id="Path_4029"
                      data-name="Path 4029"
                      d="M50.408,30.471a1.574,1.574,0,0,0,1.625-.085l4.2-2.833a1.58,1.58,0,0,0,0-2.62l-4.2-2.833a1.58,1.58,0,0,0-2.463,1.31v5.666a1.577,1.577,0,0,0,.838,1.395m.985-6.6,3.526,2.376-3.526,2.376Z"
                      transform="translate(-33.047 -14.553)"
                      fill="#1a1a1a"
                    />
                  </svg>
                  <ProductTypeButtonTextWrapper isActive={productType === 8}>
                    <h1>Track</h1>
                    <p>
                      Tworzysz muzykę? Masz szufladę pełną gotowych tracków?
                      Zaprezentuj je i zaoferuj użytkownikom MUGO Sudio.
                    </p>
                  </ProductTypeButtonTextWrapper>

                  {productType === 8 ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M11.062,5.812A5.25,5.25,0,1,1,5.812.562,5.25,5.25,0,0,1,11.062,5.812ZM5.205,8.592,9.1,4.7a.339.339,0,0,0,0-.479l-.479-.479a.339.339,0,0,0-.479,0L4.966,6.916,3.483,5.433a.339.339,0,0,0-.479,0l-.479.479a.339.339,0,0,0,0,.479l2.2,2.2a.339.339,0,0,0,.479,0Z"
                        transform="translate(3.188 3.188)"
                        fill="#0091ff"
                      />
                      <path
                        id="Ellipse_325"
                        data-name="Ellipse 325"
                        d="M9,1.5A7.5,7.5,0,1,0,16.5,9,7.508,7.508,0,0,0,9,1.5M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
                        fill="#0091ff"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <path
                        id="Ellipse_325"
                        data-name="Ellipse 325"
                        d="M9,1.5A7.5,7.5,0,1,0,16.5,9,7.508,7.508,0,0,0,9,1.5M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z"
                        fill="#f5f5f5"
                        opacity="0.5"
                      />
                    </svg>
                  )}
                </ProductTypeButtonMobile>
              </SingleProductType>
            </SelectProductTypeWrapper>
          </FirstStepContentWrapper>
          <MobileBtnWrapper>
            <button
              onClick={() => {
                if (
                  productType === 8 ||
                  productType === 4 ||
                  productType === 1
                ) {
                  setCreateNewProductStep(2);
                }
              }}
            >
              Dalej
            </button>
          </MobileBtnWrapper>
        </FirstStepMain>
      </MainWrapperStep1>
    );
  else
    return (
      <>
        <MainWrapper>
          <Main>
            {(isOfferLoading ||
              isProductLoading ||
              isConditionLoading ||
              isConditionCreating ||
              isConditionUpdating ||
              isProductCreating ||
              isProductUpdating ||
              isOfferCreating ||
              isImageGenerating ||
              isOfferUpdating) && <LoadingCircle />}
            {isSaveOrCreateConditionMenuOpen && (
              <CustomAlertWrapper>
                <div>
                  <div>
                    <h4>Uwaga</h4>
                  </div>
                  <p>
                    Warunek jest przypisany do wielu produktów. <br /> Czy
                    chcesz wprowadzić zmiany warunku do każdego produktu czy
                    stworzyć nowy warunek?
                  </p>
                  <button
                    onClick={() => {
                      // updateCondition();
                      // handleIsEditConditionCreateOpen(false);
                      // handleIsEditingShownAndDisabled(true);
                      handleIsSaveOrCreateConditionMenuOpen(false);
                    }}
                  >
                    Zamień
                  </button>
                  <button
                    onClick={() => {
                      // createCondition();
                      handleIsSaveOrCreateConditionMenuOpen(false);
                    }}
                  >
                    Stwórz nowy
                  </button>
                  <button
                    onClick={() => handleIsSaveOrCreateConditionMenuOpen(false)}
                  >
                    Anuluj
                  </button>
                </div>
              </CustomAlertWrapper>
            )}
            <OfferSectionWrapper>
              <HeaderWrapper>
                <p>
                  {ProductTypeEnum.length > 0 &&
                  ProductTypeEnum.filter(
                    (type) => type.FlagValue === productType,
                  ).length > 0
                    ? getProductTypePolishName(
                        ProductTypeEnum.filter(
                          (type) => type.FlagValue === productType,
                        )[0].FlagValue,
                      )
                    : ''}
                </p>
                {!productId ? (
                  <DeleteProductButton
                    onClick={(e) => {
                      navigate('/myproducts');
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.5"
                      height="17.056"
                      viewBox="0 0 15.5 17.056"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.75 0.75)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9h14"
                          transform="translate(-4.5 -5.889)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M18.389,6.111V17a1.556,1.556,0,0,1-1.556,1.556H9.056A1.556,1.556,0,0,1,7.5,17V6.111m2.333,0V4.556A1.556,1.556,0,0,1,11.389,3H14.5a1.556,1.556,0,0,1,1.556,1.556V6.111"
                          transform="translate(-5.944 -3)"
                          fill="none"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                  </DeleteProductButton>
                ) : (
                  productDisplayStatus !== 3 &&
                  productDisplayStatus !== 4 && (
                    <DeleteProductButton>
                      <PopupButton
                        buttons={['Anuluj', 'Usuń']}
                        title={'Uwaga'}
                        text={` Czy na pewno chcesz usunąć ofertę ${name}?
                     `}
                        onClick={(e) => {
                          if (!productId) {
                            navigate('/myproducts');
                          } else {
                            deleteProduct(e, params.id);
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.5"
                          height="17.056"
                          viewBox="0 0 15.5 17.056"
                        >
                          <g
                            id="Icon_feather-trash"
                            data-name="Icon feather-trash"
                            transform="translate(0.75 0.75)"
                          >
                            <path
                              id="Path_3663"
                              data-name="Path 3663"
                              d="M4.5,9h14"
                              transform="translate(-4.5 -5.889)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3664"
                              data-name="Path 3664"
                              d="M18.389,6.111V17a1.556,1.556,0,0,1-1.556,1.556H9.056A1.556,1.556,0,0,1,7.5,17V6.111m2.333,0V4.556A1.556,1.556,0,0,1,11.389,3H14.5a1.556,1.556,0,0,1,1.556,1.556V6.111"
                              transform="translate(-5.944 -3)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </PopupButton>
                    </DeleteProductButton>
                  )
                )}
              </HeaderWrapper>
              <OfferSectionContentWrapper>
                <OfferSectionLeftSide
                  isReadyForOffer={isReadyForOffer}
                  style={!isReadyForOffer ? {} : {width: '280px'}}
                >
                  <OfferCounter>
                    <h5>Oferta</h5>
                    {productType === 4 ? (
                      <p>{`${offerCount.ServicesOffersCount}/${seller.CurrentPlan.Plan.MaxServiceOffers}`}</p>
                    ) : productType === 8 ? (
                      <p>{`${offerCount.TracksOffersCount}/${seller.CurrentPlan.Plan.MaxTrackOffers}`}</p>
                    ) : (
                      <p>{`${offerCount.DigitalProductsOffersCount}/${seller.CurrentPlan.Plan.MaxDigitalOffers}`}</p>
                    )}
                  </OfferCounter>
                  {!isReadyForOffer ? (
                    <p>
                      Szczegółowo opisz produkt lub usługę – zachęć do kupna!
                      <br />
                      Określ typ i specyfikację – ułatw wyszukiwanie oferty.
                      <br />
                      Zdefiniuj warunki dotyczące transakcji.
                    </p>
                  ) : isReadyForOffer &&
                    (productDisplayStatus === 3 ||
                      productDisplayStatus === 4) ? (
                    <p>
                      Twoja oferta pojawiła się właśnie w MUGO Studio.
                      Powodzenia!
                    </p>
                  ) : (
                    <p>
                      Masz to! Teraz czas na opublikowanie oferty. Promowanie
                      oferty to większe możliwości dotarcia do odbiorców i
                      większa szansa na sprzedaż.
                    </p>
                  )}
                </OfferSectionLeftSide>
                <OfferSectionCenterSide isReadyForOffer={isReadyForOffer}>
                  {!offerId ? (
                    <>
                      <OfferSliderWrapper>
                        <p>Oferta wyróżniona</p>
                        <OfferSlider
                          isHighlighted={
                            offerCount.FeaturedOffersCount >=
                            seller.CurrentPlan.Plan.MaxOffersHighlights
                              ? false
                              : highlightOffer
                          }
                          onClick={() =>
                            handleHighlightOfferChange(!highlightOffer)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <OfferSliderWrapper style={{marginTop: '6px'}}>
                        <p>Automatyczne odnawianie</p>
                        <OfferSlider
                          isHighlighted={autoRenewalEnabled}
                          onClick={() =>
                            handleAutoRenewalEnabledChange(!autoRenewalEnabled)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <SelectView isEditing={true} style={{marginTop: '6px'}}>
                        <p>Wystaw ofertę na (dni)</p>
                        <select
                          placeholder="Wybierz"
                          value={availibityDuration}
                          onChange={handleAvailibityDurationChange}
                        >
                          <option value="7">7</option>
                          <option value="14">14</option>
                          <option value="21">21</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </>
                  ) : offerId && new Date(availabilityEndDate) < new Date() ? (
                    <>
                      <OfferSliderWrapper>
                        <p>Oferta wyróżniona</p>
                        <OfferSlider
                          isHighlighted={
                            offerCount.FeaturedOffersCount >=
                            seller.CurrentPlan.Plan.MaxOffersHighlights
                              ? false
                              : highlightOffer
                          }
                          onClick={() =>
                            handleHighlightOfferChange(!highlightOffer)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <OfferSliderWrapper style={{marginTop: '6px'}}>
                        <p>Automatyczne odnawianie</p>
                        <OfferSlider
                          isHighlighted={autoRenewalEnabled}
                          onClick={() =>
                            handleAutoRenewalEnabledChange(!autoRenewalEnabled)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <SelectView isEditing={true} style={{marginTop: '6px'}}>
                        <p>Wystaw ofertę na (dni)</p>
                        <select
                          placeholder="Wybierz"
                          value={availibityDuration}
                          onChange={handleAvailibityDurationChange}
                        >
                          <option value="7">7</option>
                          <option value="14">14</option>
                          <option value="21">21</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </>
                  ) : offerId &&
                    productDisplayStatus === 7 &&
                    canBeRepublished ? (
                    <>
                      <OfferSliderWrapper>
                        <p>Oferta wyróżniona</p>
                        <OfferSlider
                          isHighlighted={
                            offerCount.FeaturedOffersCount >=
                            seller.CurrentPlan.Plan.MaxOffersHighlights
                              ? false
                              : highlightOffer
                          }
                          onClick={() => {
                            handleHighlightOfferChange(!highlightOffer);
                            if (
                              offerCount.FeaturedOffersCount >=
                              seller.CurrentPlan.Plan.MaxOffersHighlights
                            )
                              return;
                            disableOffer(offerId);
                          }}
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <OfferSliderWrapper style={{marginTop: '6px'}}>
                        <p>Automatyczne odnawianie</p>
                        <OfferSlider
                          isHighlighted={autoRenewalEnabled}
                          onClick={() => {
                            handleAutoRenewalEnabledChange(!autoRenewalEnabled);
                            disableOffer(offerId);
                          }}
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <OfferDisplayData style={{marginTop: '6px'}}>
                        <p style={{paddingLeft: '7px'}}>Aktywna do</p>
                        <h5>{getFormattedDate(availabilityEndDate)}</h5>
                      </OfferDisplayData>
                    </>
                  ) : offerId &&
                    productDisplayStatus === 7 &&
                    !canBeRepublished ? (
                    <>
                      <OfferSliderWrapper>
                        <p>Oferta wyróżniona</p>
                        <OfferSlider
                          isHighlighted={
                            offerCount.FeaturedOffersCount >=
                            seller.CurrentPlan.Plan.MaxOffersHighlights
                              ? false
                              : highlightOffer
                          }
                          onClick={() => {
                            handleHighlightOfferChange(!highlightOffer);
                            if (
                              offerCount.FeaturedOffersCount >=
                              seller.CurrentPlan.Plan.MaxOffersHighlights
                            )
                              return;
                            disableOffer(offerId);
                          }}
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <OfferSliderWrapper style={{marginTop: '6px'}}>
                        <p>Automatyczne odnawianie</p>
                        <OfferSlider
                          isHighlighted={autoRenewalEnabled}
                          onClick={() =>
                            handleAutoRenewalEnabledChange(!autoRenewalEnabled)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <SelectView isEditing={true} style={{marginTop: '6px'}}>
                        <p>Wystaw ofertę na (dni)</p>
                        <select
                          placeholder="Wybierz"
                          value={availibityDuration}
                          onChange={handleAvailibityDurationChange}
                        >
                          <option value="7">7</option>
                          <option value="14">14</option>
                          <option value="21">21</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </>
                  ) : (
                    <>
                      <OfferDisplayData>
                        <p>Oferta promowana</p>
                        <h5>{highlightOffer ? 'Tak' : 'Nie'}</h5>
                      </OfferDisplayData>
                      <OfferDisplayData style={{margin: '7px 0px'}}>
                        <p>Automatyczne odnawianie</p>
                        <h5>{autoRenewalEnabled ? 'Tak' : 'Nie'}</h5>
                      </OfferDisplayData>
                      <OfferDisplayData>
                        <p>Aktywna do</p>
                        <h5>{getFormattedDate(availabilityEndDate)}</h5>
                      </OfferDisplayData>
                    </>
                  )}
                </OfferSectionCenterSide>
                <OfferSectionRightSide>
                  <StyledStatusWrapper
                    ProductDisplayStatus={productDisplayStatus}
                  >
                    <h1>
                      {productDisplayStatus === 99
                        ? 'Draft'
                        : getProductDisplayStatusDescription(
                            productDisplayStatus,
                          )}
                    </h1>
                    <StyledStatusLine
                      ProductDisplayStatus={productDisplayStatus}
                    >
                      <div />
                    </StyledStatusLine>
                  </StyledStatusWrapper>
                  {/* <StyledStatusWithTitle>
                  <p>Status</p>
                  <StyledStatus ProductDisplayStatus={productDisplayStatus}>
                    {productDisplayStatus === 99
                      ? 'Draft'
                      : getProductDisplayStatusDescription(
                          productDisplayStatus,
                        )}
                  </StyledStatus>
                </StyledStatusWithTitle> */}
                  {!offerId ? (
                    <PublishOfferButton
                      isReadyForOffer={isReadyForOffer}
                      onClick={() => {
                        if (!isReadyForOffer) return;
                        if (
                          productType === 4 &&
                          (offerCount.ServicesOffersCount >=
                            seller.CurrentPlan.Plan.MaxServiceOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType === 8 &&
                          (offerCount.TracksOffersCount >=
                            seller.CurrentPlan.Plan.MaxTrackOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType === 1 &&
                          (offerCount.DigitalProductsOffersCount >=
                            seller.CurrentPlan.Plan.MaxDigitalOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        }
                        // if (
                        //   offerCount.TotalOffersCount >=
                        //     seller.CurrentPlan.Plan.MaxActiveOffers ||
                        //   !isReadyForOffer
                        // )
                        //   return Alert('Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.', 'Komunikat');
                        createOffer();
                      }}
                    >
                      Opublikuj
                    </PublishOfferButton>
                  ) : offerId && new Date(availabilityEndDate) < new Date() ? (
                    <PublishOfferButton
                      isReadyForOffer={isReadyForOffer}
                      onClick={() => {
                        if (!isReadyForOffer) return;
                        if (
                          productType === 4 &&
                          (offerCount.ServicesOffersCount >=
                            seller.CurrentPlan.Plan.MaxServiceOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType === 8 &&
                          (offerCount.TracksOffersCount >=
                            seller.CurrentPlan.Plan.MaxTrackOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType === 1 &&
                          (offerCount.DigitalProductsOffersCount >=
                            seller.CurrentPlan.Plan.MaxDigitalOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        }
                        createOffer();
                      }}
                    >
                      Opublikuj
                    </PublishOfferButton>
                  ) : offerId &&
                    productDisplayStatus === 7 &&
                    canBeRepublished ? (
                    <PublishOfferButton
                      isReadyForOffer={true}
                      onClick={() => restoreOffer(offerId)}
                    >
                      Wznów ofertę
                    </PublishOfferButton>
                  ) : offerId &&
                    productDisplayStatus === 7 &&
                    !canBeRepublished ? (
                    <PublishOfferButton
                      isReadyForOffer={isReadyForOffer}
                      onClick={() => {
                        if (!isReadyForOffer) return;
                        if (
                          productType === 4 &&
                          (offerCount.ServicesOffersCount >=
                            seller.CurrentPlan.Plan.MaxServiceOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType === 8 &&
                          (offerCount.TracksOffersCount >=
                            seller.CurrentPlan.Plan.MaxTrackOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType === 1 &&
                          (offerCount.DigitalProductsOffersCount >=
                            seller.CurrentPlan.Plan.MaxDigitalOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        }
                        createOffer();
                      }}
                    >
                      Opublikuj
                    </PublishOfferButton>
                  ) : (
                    <PublishOfferButton
                      isReadyForOffer={true}
                      onClick={() => deactivateOffer(offerId)}
                    >
                      Wycofaj ofertę
                    </PublishOfferButton>
                  )}
                </OfferSectionRightSide>
              </OfferSectionContentWrapper>
            </OfferSectionWrapper>

            <ProductDataWithConditionsWrapper>
              <ProductDataWrapper isEditing={isProductEditing}>
                <SectionHeader isEditing={isProductEditing}>
                  <p>Dane podstawowe</p>
                  {createNewProductStep === 0 &&
                  productDisplayStatus !== 3 &&
                  productDisplayStatus !== 4 &&
                  productDisplayStatus !== 6 &&
                  isProductEditing ? (
                    <PopupButton
                      buttons={['Kontynuuj', 'Zamknij']}
                      title={'Uwaga'}
                      text={`
                         Czy na pewno chcesz zamknąć edycje? Wszystkie zmiany nie zostaną zapisane.
                        `}
                      onClick={() => {
                        getSingleProduct(singleProductId);
                        setIsProductEditing(false);
                      }}
                    >
                      <EditBtnWrapper
                        style={{top: '0px'}}
                        isEditing={isProductEditing}
                      >
                        <EditSvgWrapper isEditing={isProductEditing}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.27"
                            height="16.658"
                            viewBox="0 0 17.27 16.658"
                          >
                            <g
                              id="Icon_feather-edit-3"
                              data-name="Icon feather-edit-3"
                              transform="translate(-3.75 -3.41)"
                            >
                              <path
                                id="Path_3712"
                                data-name="Path 3712"
                                d="M18,30h7.885"
                                transform="translate(-5.615 -10.682)"
                                fill="none"
                                stroke="rgba(7,2,15,0.7)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_3713"
                                data-name="Path 3713"
                                d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                                fill="none"
                                stroke="rgba(7,2,15,0.7)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </EditSvgWrapper>
                      </EditBtnWrapper>
                    </PopupButton>
                  ) : (
                    <EditBtnWrapper
                      style={{top: '0px'}}
                      isEditing={isProductEditing}
                      onClick={() => {
                        if (
                          createNewProductStep === 0 &&
                          !isConditionEditing &&
                          productDisplayStatus !== 3 &&
                          productDisplayStatus !== 4 &&
                          productDisplayStatus !== 6
                        ) {
                          handleIsProductEditingChange(!isProductEditing);
                        } else if (
                          productDisplayStatus === 3 ||
                          productDisplayStatus === 4 ||
                          productDisplayStatus === 6
                        ) {
                          Alert('W celu edycji wycofaj ofertę.', 'Komunikat');
                        }
                      }}
                    >
                      <EditSvgWrapper isEditing={isProductEditing}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.27"
                          height="16.658"
                          viewBox="0 0 17.27 16.658"
                        >
                          <g
                            id="Icon_feather-edit-3"
                            data-name="Icon feather-edit-3"
                            transform="translate(-3.75 -3.41)"
                          >
                            <path
                              id="Path_3712"
                              data-name="Path 3712"
                              d="M18,30h7.885"
                              transform="translate(-5.615 -10.682)"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3713"
                              data-name="Path 3713"
                              d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </EditSvgWrapper>
                    </EditBtnWrapper>
                  )}
                </SectionHeader>
                <ProductDataContentWrapper>
                  <BasicDataWrapper
                    style={
                      productType === 1
                        ? {marginBottom: '2px'}
                        : {marginBottom: '12px'}
                    }
                  >
                    <ProductImageWrapper>
                      {isProductEditing &&
                      files.filter((file) => file.ProductFileType === 0)
                        .length > 0 ? (
                        <>
                          <img
                            src={
                              files.filter(
                                (file) =>
                                  file.ProductFileType === 0 && file.FilePath,
                              ).length > 0
                                ? files.filter(
                                    (file) => file.ProductFileType === 0,
                                  )[0].FilePath
                                : `data:image/png;base64,${
                                    files.filter(
                                      (file) => file.ProductFileType === 0,
                                    )[0].File
                                  }`
                            }
                          />
                          <DeleteFileButton
                            onClick={() => {
                              if (
                                files.filter(
                                  (file) =>
                                    file.ProductFileType === 0 && file.Id,
                                ).length > 0
                              ) {
                                deleteExistingFile(
                                  files.filter(
                                    (file) =>
                                      file.ProductFileType === 0 && file.Id,
                                  )[0],
                                );
                              } else if (
                                files.filter(
                                  (file) =>
                                    file.ProductFileType === 0 && !file.Id,
                                ).length > 0
                              ) {
                                deleteNewFile(
                                  files.filter(
                                    (file) =>
                                      file.ProductFileType === 0 && !file.Id,
                                  )[0],
                                );
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10.9"
                              height="12"
                              viewBox="0 0 10.9 12"
                            >
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(0.5 0.5)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9h9.9"
                                  transform="translate(-4.5 -6.8)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                  transform="translate(-6.4 -3)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                              </g>
                            </svg>
                          </DeleteFileButton>
                        </>
                      ) : isProductEditing &&
                        files.filter((file) => file.ProductFileType === 0)
                          .length === 0 ? (
                        <>
                          <GenerateImageButton onClick={() => generateImage()}>
                            Wygeneruj
                          </GenerateImageButton>
                          <TrackPlaceholderSvgWrapper>
                            {productType === 8 ? (
                              <TrackPlaceholderSvg />
                            ) : productType === 4 ? (
                              <ServicePlaceholderSvg />
                            ) : (
                              <DigitalProductPlaceholderSvg />
                            )}
                          </TrackPlaceholderSvgWrapper>
                          <AddTagButton
                            onClick={() => {
                              if (isProductEditing) {
                                coverFileRef.current.click();
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.138"
                              height="15"
                              viewBox="0 0 15.138 15"
                            >
                              <path
                                id="Path_3683"
                                data-name="Path 3683"
                                d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                                transform="translate(-171.869 -100.292)"
                                fill="#fff"
                              />
                            </svg>
                          </AddTagButton>
                          <input
                            type={'file'}
                            hidden
                            onChange={getCoverFile}
                            accept=".jpeg,.jpg,.png"
                            ref={coverFileRef}
                          />
                        </>
                      ) : !isProductEditing &&
                        files.filter((file) => file.ProductFileType === 0)
                          .length > 0 ? (
                        <img
                          src={
                            files.filter(
                              (file) =>
                                file.ProductFileType === 0 && file.FilePath,
                            ).length > 0
                              ? files.filter(
                                  (file) => file.ProductFileType === 0,
                                )[0].FilePath
                              : `data:image/png;base64,${
                                  files.filter(
                                    (file) => file.ProductFileType === 0,
                                  )[0].File
                                }`
                          }
                        />
                      ) : (
                        <TrackPlaceholderSvgWrapper>
                          {productType === 8 ? (
                            <TrackPlaceholderSvg />
                          ) : productType === 4 ? (
                            <ServicePlaceholderSvg />
                          ) : (
                            <DigitalProductPlaceholderSvg />
                          )}
                        </TrackPlaceholderSvgWrapper>
                      )}
                    </ProductImageWrapper>

                    <ProductNameWithDescriptionWrapper
                      isProductEditing={isProductEditing}
                    >
                      {isProductEditing ? (
                        <>
                          <InputView
                            isError={errorVerification.nameError !== null}
                            isEditing={true}
                            style={{marginTop: 'unset'}}
                          >
                            <p>Nazwa</p>
                            <input
                              type="text"
                              value={name}
                              maxLength={50}
                              readOnly={!isProductEditing}
                              onChange={handleNameChange}
                              placeholder="Pole obowiązkowe"
                            />
                            {errorVerification.nameError !== null && (
                              <ErrorStar text={errorVerification.nameError} />
                            )}
                          </InputView>
                          <ProductDescriptionWrapper
                            isError={
                              errorVerification.descriptionError !== null
                            }
                          >
                            <h5>Opis</h5>
                            <textarea
                              maxLength={250}
                              value={description}
                              readOnly={!isProductEditing}
                              onChange={handleDescriptionChange}
                              placeholder="Uzupełnij"
                            />
                            {errorVerification.descriptionError !== null && (
                              <ErrorStar
                                text={errorVerification.descriptionError}
                              />
                            )}
                            <p>{description.length}/250</p>
                          </ProductDescriptionWrapper>
                        </>
                      ) : (
                        <>
                          <h1>{name}</h1>
                          <p>{description}</p>
                        </>
                      )}
                    </ProductNameWithDescriptionWrapper>
                  </BasicDataWrapper>
                  {productType === 4 &&
                    longDescriptionOrGallery === '' &&
                    isProductEditing && (
                      <ServiceTypeSelectWrapper>
                        <p>Zawartość dodatkowa</p>
                        <ServiceButtonsWrapper>
                          <ServiceButton
                            onClick={() =>
                              setLongDescriptionOrGallery('gallery')
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="36.332"
                              height="36"
                              viewBox="0 0 36.332 36"
                            >
                              <path
                                id="Path_3993"
                                data-name="Path 3993"
                                d="M206.416,116.664l-15.015.323.262-14.89a1.636,1.636,0,1,0-3.255,0l.261,14.89-15.014-.323a1.635,1.635,0,1,0,0,3.255l15.014-.323-.261,14.888a1.637,1.637,0,1,0,3.255,0L191.4,119.6l15.015.323a1.635,1.635,0,1,0,0-3.255Z"
                                transform="translate(-171.869 -100.292)"
                                fill="rgba(7,2,15,0.7)"
                              />
                            </svg>
                            <p>Galeria obrazów</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                            >
                              <path
                                id="Path_4272"
                                data-name="Path 4272"
                                d="M36,11.5v21A3.5,3.5,0,0,1,32.5,36h-21A3.5,3.5,0,0,1,8,32.5v-21A3.5,3.5,0,0,1,11.5,8h21A3.5,3.5,0,0,1,36,11.5Zm-3.5,0h-21V22.665l3.832-3.833,9.153,9.153,1.75-1.75a2.782,2.782,0,0,1,3.693.192L32.5,29Zm-8.75,6.125a2.625,2.625,0,1,1,2.625,2.625A2.621,2.621,0,0,1,23.75,17.625Z"
                                transform="translate(-8 -8)"
                                fill="#2b2b2b"
                              />
                            </svg>
                          </ServiceButton>
                          <ServiceButton
                            onClick={() =>
                              setLongDescriptionOrGallery('longDescription')
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="36.332"
                              height="36"
                              viewBox="0 0 36.332 36"
                            >
                              <path
                                id="Path_3993"
                                data-name="Path 3993"
                                d="M206.416,116.664l-15.015.323.262-14.89a1.636,1.636,0,1,0-3.255,0l.261,14.89-15.014-.323a1.635,1.635,0,1,0,0,3.255l15.014-.323-.261,14.888a1.637,1.637,0,1,0,3.255,0L191.4,119.6l15.015.323a1.635,1.635,0,1,0,0-3.255Z"
                                transform="translate(-171.869 -100.292)"
                                fill="rgba(7,2,15,0.7)"
                              />
                            </svg>
                            <p>Dodatkowy opis</p>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="30"
                              viewBox="0 0 26 30"
                            >
                              <g
                                id="Group_8127"
                                data-name="Group 8127"
                                transform="translate(-13823.2 -4400)"
                              >
                                <path
                                  id="Path_4277"
                                  data-name="Path 4277"
                                  d="M10.2,13.994h-1v2h1Zm12,2h1v-2h-1ZM10.2,20h-1v2h1Zm12,2h1V20h-1ZM10.2,7.994h-1v2h1Zm12,2h1v-2h-1ZM28.2,7h1V6.587l-.294-.294Zm-6-6,.706-.706L22.613,0H22.2Zm-12,14.994h12v-2h-12ZM10.2,22h12V20h-12Zm0-12.006h12v-2h-12ZM26.2,28H6.2v2h20Zm-21-1V3h-2V27Zm22-20V27h2V7ZM6.2,2h16V0H6.2Zm15.294-.294,6,6,1.413-1.413-6-6ZM6.2,28a1,1,0,0,1-1-1h-2a3,3,0,0,0,3,3Zm20,2a3,3,0,0,0,3-3h-2a1,1,0,0,1-1,1ZM5.2,3a1,1,0,0,1,1-1V0a3,3,0,0,0-3,3Z"
                                  transform="translate(13820 4400)"
                                  fill="#2b2b2b"
                                />
                              </g>
                            </svg>
                          </ServiceButton>
                        </ServiceButtonsWrapper>
                      </ServiceTypeSelectWrapper>
                    )}
                  {productType !== 8 &&
                    productType !== 1 &&
                    longDescriptionOrGallery === 'longDescription' && (
                      <ProductAdditionalDescriptionWrapper
                        isEditing={isProductEditing}
                      >
                        {isProductEditing ? (
                          <>
                            {' '}
                            <ClearServiceSelectButton
                              onClick={() => {
                                setLongDescriptionOrGallery('');
                                setLongDescription('');
                                setFiles(
                                  files.filter(
                                    (item) => item.ProductFileType !== 1,
                                  ),
                                );
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                              >
                                <g
                                  id="Group_4357"
                                  data-name="Group 4357"
                                  transform="translate(-40 -578.5)"
                                >
                                  <rect
                                    id="Rectangle_2518"
                                    data-name="Rectangle 2518"
                                    width="19"
                                    height="19"
                                    rx="4"
                                    transform="translate(40 578.5)"
                                    fill="#ff0300"
                                  />
                                  <g
                                    id="Icon_feather-trash"
                                    data-name="Icon feather-trash"
                                    transform="translate(44.5 582)"
                                  >
                                    <path
                                      id="Path_3663"
                                      data-name="Path 3663"
                                      d="M4.5,9h9.9"
                                      transform="translate(-4.5 -6.8)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_3664"
                                      data-name="Path 3664"
                                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                      transform="translate(-6.4 -3)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </ClearServiceSelectButton>
                            <h5>Dodatkowy opis</h5>
                            <textarea
                              placeholder="Pole opcjonalne"
                              maxLength={800}
                              value={longDescription}
                              readOnly={!isProductEditing}
                              onChange={handleLongDescriptionChange}
                            />
                          </>
                        ) : (
                          <h4>{longDescription}</h4>
                        )}
                      </ProductAdditionalDescriptionWrapper>
                    )}
                  {productType === 1 && (
                    <DigitalFilesWrapper>
                      <DigitalFileWithTitleWrapper>
                        <h1>Dodatkowa galeria</h1>
                        <p>
                          Dodaj dodatkowe materiały graficzne, które pozwolą
                          lepiej oddać charakter produktu. Np. wizualizacje,
                          przybliżenia na detal, warianty kolorystyczne.
                        </p>
                        <h5 style={{marginTop: '10px'}}>
                          Format pliku: <span>JPG, PNG, GIF</span>
                        </h5>
                        <h5>
                          Rozmiar: <span>maks. 2MB</span>
                        </h5>
                      </DigitalFileWithTitleWrapper>
                      <DigitalFileWithTitleWrapper>
                        <h1>Wersja finalna produktu</h1>
                        {isProductEditing &&
                        files.filter((file) => file.ProductFileType === 2)
                          .length > 0 ? (
                          files
                            .filter((file, i) => file.ProductFileType === 2)
                            .map((file, i) => {
                              const {File, Name, Id} = file;

                              if (i > 0) return;

                              return (
                                <TrackFileWrapper key={Id}>
                                  <h5>Wgrany plik finalny</h5>
                                  <FileNameWithSvgSpan>
                                    <p>{Name}</p>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="36"
                                      height="24"
                                      viewBox="0 0 36 24"
                                    >
                                      <path
                                        id="Icon_material-cloud-download"
                                        data-name="Icon material-cloud-download"
                                        d="M29.025,15.06a11.239,11.239,0,0,0-21-3A9,9,0,0,0,9,30H28.5a7.478,7.478,0,0,0,.525-14.94ZM25.5,19.5,18,27l-7.5-7.5H15v-6h6v6Z"
                                        transform="translate(0 -6)"
                                        fill="#0091ff"
                                      />
                                    </svg>
                                  </FileNameWithSvgSpan>
                                  <DeleteFileButton
                                    onClick={() => {
                                      if (
                                        files.filter(
                                          (file) =>
                                            file.ProductFileType === 2 &&
                                            file.Id,
                                        ).length > 0
                                      ) {
                                        deleteExistingFile(
                                          files.filter(
                                            (file) =>
                                              file.ProductFileType === 2 &&
                                              file.Id,
                                          )[0],
                                        );
                                      } else if (
                                        files.filter(
                                          (file) =>
                                            file.ProductFileType === 2 &&
                                            !file.Id,
                                        ).length > 0
                                      ) {
                                        deleteNewFile(
                                          files.filter(
                                            (file) =>
                                              file.ProductFileType === 2 &&
                                              !file.Id,
                                          )[0],
                                        );
                                      }
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10.9"
                                      height="12"
                                      viewBox="0 0 10.9 12"
                                    >
                                      <g
                                        id="Icon_feather-trash"
                                        data-name="Icon feather-trash"
                                        transform="translate(0.5 0.5)"
                                      >
                                        <path
                                          id="Path_3663"
                                          data-name="Path 3663"
                                          d="M4.5,9h9.9"
                                          transform="translate(-4.5 -6.8)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="1"
                                        />
                                        <path
                                          id="Path_3664"
                                          data-name="Path 3664"
                                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                          transform="translate(-6.4 -3)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="1"
                                        />
                                      </g>
                                    </svg>
                                  </DeleteFileButton>
                                </TrackFileWrapper>
                              );
                            })
                        ) : isProductEditing ? (
                          <FileWrapper>
                            <p>
                              Format pliku: <span>Wszystkie</span>
                            </p>
                            <p>
                              Rozmiar: maks. <span>150MB</span>
                            </p>
                            <AddFileButton
                              onClick={() => productFileRef.current.click()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.138"
                                height="15"
                                viewBox="0 0 15.138 15"
                              >
                                <path
                                  id="Path_3683"
                                  data-name="Path 3683"
                                  d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                                  transform="translate(-171.869 -100.292)"
                                  fill="#fff"
                                />
                              </svg>
                            </AddFileButton>
                            <input
                              type={'file'}
                              hidden
                              ref={productFileRef}
                              // accept={
                              //   '.jpeg,.jpg,.png,.mp4,.pdf,.ico,.rar,.rtf,.srt,.flac,.wav,.mp3,.xlsx,.zip'
                              // }
                              onChange={getProductFile}
                            />
                          </FileWrapper>
                        ) : !isProductEditing &&
                          files.filter((file) => file.ProductFileType === 2)
                            .length > 0 ? (
                          files
                            .filter((file) => file.ProductFileType === 2)
                            .map((file, i) => {
                              const {File, Name, Id} = file;

                              if (i > 0) return;

                              return (
                                <TrackFileWrapper key={Id}>
                                  <h5>Wgrany plik finalny</h5>
                                  <FileNameWithSvgSpan>
                                    <p>{Name}</p>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="36"
                                      height="24"
                                      viewBox="0 0 36 24"
                                    >
                                      <path
                                        id="Icon_material-cloud-download"
                                        data-name="Icon material-cloud-download"
                                        d="M29.025,15.06a11.239,11.239,0,0,0-21-3A9,9,0,0,0,9,30H28.5a7.478,7.478,0,0,0,.525-14.94ZM25.5,19.5,18,27l-7.5-7.5H15v-6h6v6Z"
                                        transform="translate(0 -6)"
                                        fill="#0091ff"
                                      />
                                    </svg>
                                  </FileNameWithSvgSpan>
                                </TrackFileWrapper>
                              );
                            })
                        ) : (
                          <NoFileWrapper>
                            <h5>Brak pliku</h5>
                          </NoFileWrapper>
                        )}
                      </DigitalFileWithTitleWrapper>
                    </DigitalFilesWrapper>
                  )}

                  {productType === 4 && longDescriptionOrGallery === 'gallery' && (
                    <ServiceGalleryWrapper>
                      {productType === 4 &&
                        longDescriptionOrGallery === 'gallery' &&
                        isProductEditing && (
                          <ServiceGalleryHeader>
                            <p>Galeria obrazów</p>
                            <ClearServiceSelectButton
                              style={{top: '0px', right: '0px'}}
                              onClick={() => {
                                setLongDescriptionOrGallery('');
                                setLongDescription('');
                                setFiles(
                                  files.filter(
                                    (item) => item.ProductFileType !== 1,
                                  ),
                                );
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                              >
                                <g
                                  id="Group_4357"
                                  data-name="Group 4357"
                                  transform="translate(-40 -578.5)"
                                >
                                  <rect
                                    id="Rectangle_2518"
                                    data-name="Rectangle 2518"
                                    width="19"
                                    height="19"
                                    rx="4"
                                    transform="translate(40 578.5)"
                                    fill="#ff0300"
                                  />
                                  <g
                                    id="Icon_feather-trash"
                                    data-name="Icon feather-trash"
                                    transform="translate(44.5 582)"
                                  >
                                    <path
                                      id="Path_3663"
                                      data-name="Path 3663"
                                      d="M4.5,9h9.9"
                                      transform="translate(-4.5 -6.8)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_3664"
                                      data-name="Path 3664"
                                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                      transform="translate(-6.4 -3)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </ClearServiceSelectButton>
                          </ServiceGalleryHeader>
                        )}
                      <GalleryWithPlaceholderWrapper>
                        {isProductEditing && (
                          <Placeholder>
                            <svg
                              id="Group_6964"
                              data-name="Group 6964"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="47"
                              height="48.847"
                              viewBox="0 0 47 48.847"
                            >
                              <g
                                id="Group_6456"
                                data-name="Group 6456"
                                clipPath="url(#clip-path)"
                              >
                                <path
                                  id="Path_4016"
                                  data-name="Path 4016"
                                  d="M72.726,0H45.142a1.017,1.017,0,0,0-1.017,1.017V28.6a1.017,1.017,0,0,0,1.017,1.017h4.044a1.017,1.017,0,1,0,0-2.034H46.159V2.034H71.708V15.613l-1.725-1.725a2.282,2.282,0,0,0-3.224,0L64.443,16.2a1.017,1.017,0,0,0,1.439,1.438L68.2,15.327a.246.246,0,0,1,.347,0L71.419,18.2a1.02,1.02,0,0,0,.29.2v9.184H56.251a1.017,1.017,0,0,0,0,2.034H72.726A1.017,1.017,0,0,0,73.743,28.6V1.017A1.017,1.017,0,0,0,72.726,0"
                                  transform="translate(-26.743)"
                                  fill="rgba(220,224,233,0.3)"
                                />
                                <path
                                  id="Path_4017"
                                  data-name="Path 4017"
                                  d="M26.658,41.813H25.014l5.651-5.651a.245.245,0,0,1,.347,0L38.253,43.4a1.017,1.017,0,1,0,1.438-1.439l-7.241-7.241a2.28,2.28,0,0,0-3.224,0L22.045,41.9a1.183,1.183,0,0,0,.744,1.942H25.64V67.453H2.034V43.847H15.37a1.017,1.017,0,1,0,0-2.034H1.017A1.017,1.017,0,0,0,0,42.83V68.47a1.017,1.017,0,0,0,1.017,1.017h25.64a1.017,1.017,0,0,0,1.017-1.017V42.83a1.017,1.017,0,0,0-1.017-1.017"
                                  transform="translate(0 -20.64)"
                                  fill="rgba(220,224,233,0.3)"
                                />
                                <path
                                  id="Path_4018"
                                  data-name="Path 4018"
                                  d="M24.513,72.445a2.047,2.047,0,0,0-1.088,1.812v8.776a2.051,2.051,0,0,0,3.2,1.7l6.511-4.388a2.052,2.052,0,0,0,0-3.4l-6.511-4.388a2.05,2.05,0,0,0-2.111-.11m.946,10.588V74.257l.028-.015L32,78.66Z"
                                  transform="translate(-14.197 -43.761)"
                                  fill="rgba(220,224,233,0.3)"
                                />
                                <path
                                  id="Path_4019"
                                  data-name="Path 4019"
                                  d="M64.005,17.659a3.56,3.56,0,1,0-3.56,3.56,3.565,3.565,0,0,0,3.56-3.56m-5.086,0a1.526,1.526,0,1,1,1.526,1.526,1.527,1.527,0,0,1-1.526-1.526"
                                  transform="translate(-34.476 -8.545)"
                                  fill="rgba(220,224,233,0.3)"
                                />
                              </g>
                            </svg>
                            <input
                              type={'file'}
                              hidden
                              ref={demoFileRef}
                              accept={'.jpeg,.jpg,.png,.gif'}
                              onChange={getDemoFile}
                            />
                            <AddFileButton
                              onClick={() => demoFileRef.current.click()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.138"
                                height="15"
                                viewBox="0 0 15.138 15"
                              >
                                <path
                                  id="Path_3683"
                                  data-name="Path 3683"
                                  d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                                  transform="translate(-171.869 -100.292)"
                                  fill="#fff"
                                />
                              </svg>
                            </AddFileButton>
                          </Placeholder>
                        )}
                        <GalleryWrapper isProductEditing={isProductEditing}>
                          {currentIndex > 0 && (
                            <ArrowButtonLeft onClick={handlePrevClick}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(0 0)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117.224.157,5.127a.523.523,0,0,0,0,.747l4.961,4.9a.781.781,0,0,0,1.095,0,.76.76,0,0,0,0-1.082L1.969,5.5,6.212,1.306a.76.76,0,0,0,0-1.082.781.781,0,0,0-1.095,0"
                                    fill="#fff"
                                  />
                                </g>
                              </svg>
                            </ArrowButtonLeft>
                          )}

                          <ImagesContainer
                            ref={galleryRef}
                            style={{
                              transform: `translateX(${calculateTranslateValue()}px)`,
                            }}
                            onScroll={() =>
                              handleScroll(isProductEditing ? 4 : 5)
                            }
                          >
                            {images.map((file, index) => (
                              <ImageWrapper key={index}>
                                <Image
                                  src={
                                    file.Id
                                      ? file.FilePath
                                      : `data:image/png;base64, ${file.File}`
                                  }
                                  alt={`Image ${index}`}
                                />
                                {isProductEditing && (
                                  <DeleteFileButton
                                    onClick={() => {
                                      if (
                                        files.filter(
                                          (el) =>
                                            el.ProductFileType === 1 &&
                                            el.Id &&
                                            el.Id === file.Id,
                                        ).length > 0
                                      ) {
                                        deleteExistingFile(
                                          files.filter(
                                            (el) =>
                                              el.ProductFileType === 1 &&
                                              el.Id &&
                                              el.Id === file.Id,
                                          )[0],
                                        );
                                      } else if (
                                        files.filter(
                                          (el) =>
                                            el.ProductFileType === 1 &&
                                            !el.Id &&
                                            el.Name === file.Name,
                                        ).length > 0
                                      ) {
                                        deleteNewFile(
                                          files.filter(
                                            (el) =>
                                              el.ProductFileType === 1 &&
                                              !el.Id &&
                                              el.Name === file.Name,
                                          )[0],
                                        );
                                      }
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10.9"
                                      height="12"
                                      viewBox="0 0 10.9 12"
                                    >
                                      <g
                                        id="Icon_feather-trash"
                                        data-name="Icon feather-trash"
                                        transform="translate(0.5 0.5)"
                                      >
                                        <path
                                          id="Path_3663"
                                          data-name="Path 3663"
                                          d="M4.5,9h9.9"
                                          transform="translate(-4.5 -6.8)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="1"
                                        />
                                        <path
                                          id="Path_3664"
                                          data-name="Path 3664"
                                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                          transform="translate(-6.4 -3)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="1"
                                        />
                                      </g>
                                    </svg>
                                  </DeleteFileButton>
                                )}
                              </ImageWrapper>
                            ))}
                          </ImagesContainer>

                          {currentIndex <
                            images.length - (isProductEditing ? 4 : 5) && (
                            <ArrowButtonRight
                              onClick={() =>
                                handleNextClick(isProductEditing ? 4 : 5)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#fff"
                                  />
                                </g>
                              </svg>
                            </ArrowButtonRight>
                          )}
                        </GalleryWrapper>
                      </GalleryWithPlaceholderWrapper>
                    </ServiceGalleryWrapper>
                  )}

                  {productType === 1 && (
                    <GalleryWithPlaceholderWrapper>
                      {isProductEditing && (
                        <Placeholder>
                          <svg
                            id="Group_6964"
                            data-name="Group 6964"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="47"
                            height="48.847"
                            viewBox="0 0 47 48.847"
                          >
                            <g
                              id="Group_6456"
                              data-name="Group 6456"
                              clipPath="url(#clip-path)"
                            >
                              <path
                                id="Path_4016"
                                data-name="Path 4016"
                                d="M72.726,0H45.142a1.017,1.017,0,0,0-1.017,1.017V28.6a1.017,1.017,0,0,0,1.017,1.017h4.044a1.017,1.017,0,1,0,0-2.034H46.159V2.034H71.708V15.613l-1.725-1.725a2.282,2.282,0,0,0-3.224,0L64.443,16.2a1.017,1.017,0,0,0,1.439,1.438L68.2,15.327a.246.246,0,0,1,.347,0L71.419,18.2a1.02,1.02,0,0,0,.29.2v9.184H56.251a1.017,1.017,0,0,0,0,2.034H72.726A1.017,1.017,0,0,0,73.743,28.6V1.017A1.017,1.017,0,0,0,72.726,0"
                                transform="translate(-26.743)"
                                fill="rgba(220,224,233,0.3)"
                              />
                              <path
                                id="Path_4017"
                                data-name="Path 4017"
                                d="M26.658,41.813H25.014l5.651-5.651a.245.245,0,0,1,.347,0L38.253,43.4a1.017,1.017,0,1,0,1.438-1.439l-7.241-7.241a2.28,2.28,0,0,0-3.224,0L22.045,41.9a1.183,1.183,0,0,0,.744,1.942H25.64V67.453H2.034V43.847H15.37a1.017,1.017,0,1,0,0-2.034H1.017A1.017,1.017,0,0,0,0,42.83V68.47a1.017,1.017,0,0,0,1.017,1.017h25.64a1.017,1.017,0,0,0,1.017-1.017V42.83a1.017,1.017,0,0,0-1.017-1.017"
                                transform="translate(0 -20.64)"
                                fill="rgba(220,224,233,0.3)"
                              />
                              <path
                                id="Path_4018"
                                data-name="Path 4018"
                                d="M24.513,72.445a2.047,2.047,0,0,0-1.088,1.812v8.776a2.051,2.051,0,0,0,3.2,1.7l6.511-4.388a2.052,2.052,0,0,0,0-3.4l-6.511-4.388a2.05,2.05,0,0,0-2.111-.11m.946,10.588V74.257l.028-.015L32,78.66Z"
                                transform="translate(-14.197 -43.761)"
                                fill="rgba(220,224,233,0.3)"
                              />
                              <path
                                id="Path_4019"
                                data-name="Path 4019"
                                d="M64.005,17.659a3.56,3.56,0,1,0-3.56,3.56,3.565,3.565,0,0,0,3.56-3.56m-5.086,0a1.526,1.526,0,1,1,1.526,1.526,1.527,1.527,0,0,1-1.526-1.526"
                                transform="translate(-34.476 -8.545)"
                                fill="rgba(220,224,233,0.3)"
                              />
                            </g>
                          </svg>
                          <input
                            type={'file'}
                            hidden
                            ref={demoFileRef}
                            accept={'.jpeg,.jpg,.png,.gif'}
                            onChange={getDemoFile}
                          />
                          <AddFileButton
                            onClick={() => demoFileRef.current.click()}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.138"
                              height="15"
                              viewBox="0 0 15.138 15"
                            >
                              <path
                                id="Path_3683"
                                data-name="Path 3683"
                                d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                                transform="translate(-171.869 -100.292)"
                                fill="#fff"
                              />
                            </svg>
                          </AddFileButton>
                        </Placeholder>
                      )}
                      <GalleryWrapper isProductEditing={isProductEditing}>
                        {currentIndex > 0 && (
                          <ArrowButtonLeft onClick={handlePrevClick}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.439"
                              height="11"
                              viewBox="0 0 6.439 11"
                            >
                              <g
                                id="Arrow_Top_Bar_Accent"
                                data-name="Arrow – Top Bar / Accent"
                                transform="translate(0 0)"
                              >
                                <path
                                  id="Arrow"
                                  d="M5.117.224.157,5.127a.523.523,0,0,0,0,.747l4.961,4.9a.781.781,0,0,0,1.095,0,.76.76,0,0,0,0-1.082L1.969,5.5,6.212,1.306a.76.76,0,0,0,0-1.082.781.781,0,0,0-1.095,0"
                                  fill="#fff"
                                />
                              </g>
                            </svg>
                          </ArrowButtonLeft>
                        )}

                        <ImagesContainer
                          ref={galleryRef}
                          style={{
                            transform: `translateX(${calculateTranslateValue()}px)`,
                          }}
                          onScroll={() =>
                            handleScroll(isProductEditing ? 4 : 5)
                          }
                        >
                          {images.map((file, index) => (
                            <ImageWrapper key={index}>
                              <Image
                                src={
                                  file.Id
                                    ? file.FilePath
                                    : `data:image/png;base64, ${file.File}`
                                }
                                alt={`Image ${index}`}
                              />
                              {isProductEditing && (
                                <DeleteFileButton
                                  onClick={() => {
                                    if (
                                      files.filter(
                                        (el) =>
                                          el.ProductFileType === 1 &&
                                          el.Id &&
                                          el.Id === file.Id,
                                      ).length > 0
                                    ) {
                                      deleteExistingFile(
                                        files.filter(
                                          (el) =>
                                            el.ProductFileType === 1 &&
                                            el.Id &&
                                            el.Id === file.Id,
                                        )[0],
                                      );
                                    } else if (
                                      files.filter(
                                        (el) =>
                                          el.ProductFileType === 1 &&
                                          !el.Id &&
                                          el.Name === file.Name,
                                      ).length > 0
                                    ) {
                                      deleteNewFile(
                                        files.filter(
                                          (el) =>
                                            el.ProductFileType === 1 &&
                                            !el.Id &&
                                            el.Name === file.Name,
                                        )[0],
                                      );
                                    }
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10.9"
                                    height="12"
                                    viewBox="0 0 10.9 12"
                                  >
                                    <g
                                      id="Icon_feather-trash"
                                      data-name="Icon feather-trash"
                                      transform="translate(0.5 0.5)"
                                    >
                                      <path
                                        id="Path_3663"
                                        data-name="Path 3663"
                                        d="M4.5,9h9.9"
                                        transform="translate(-4.5 -6.8)"
                                        fill="none"
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                      <path
                                        id="Path_3664"
                                        data-name="Path 3664"
                                        d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                        transform="translate(-6.4 -3)"
                                        fill="none"
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1"
                                      />
                                    </g>
                                  </svg>
                                </DeleteFileButton>
                              )}
                            </ImageWrapper>
                          ))}
                        </ImagesContainer>

                        {currentIndex <
                          images.length - (isProductEditing ? 4 : 5) && (
                          <ArrowButtonRight
                            onClick={() =>
                              handleNextClick(isProductEditing ? 4 : 5)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="6.439"
                              height="11"
                              viewBox="0 0 6.439 11"
                            >
                              <g
                                id="Arrow_Top_Bar_Accent"
                                data-name="Arrow – Top Bar / Accent"
                                transform="translate(6.439 11) rotate(180)"
                              >
                                <path
                                  id="Arrow"
                                  d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                  transform="translate(0 0)"
                                  fill="#fff"
                                />
                              </g>
                            </svg>
                          </ArrowButtonRight>
                        )}
                      </GalleryWrapper>
                    </GalleryWithPlaceholderWrapper>
                  )}

                  {productType === 1 && (
                    <CategoriesWithSymbolWrapper style={{gap: 'unset'}}>
                      <CategoriesWrapper>
                        {isProductEditing ? (
                          <>
                            <CategorySelectWrapper>
                              <select
                                value={primaryCategory}
                                onChange={handlePrimaryCategoryChange}
                                disabled={
                                  productCategories.filter(
                                    (category) =>
                                      category.ParentCategoryId === null,
                                  ).length === 0
                                }
                              >
                                <option value="">Kategoria</option>
                                {productCategories
                                  .filter(
                                    (category) =>
                                      category.ParentCategoryId === null,
                                  )
                                  .map((category) => {
                                    const {Id, Name} = category;

                                    return (
                                      <option value={Id} key={Id}>
                                        {Name}
                                      </option>
                                    );
                                  })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                            <CategorySelectWrapper>
                              <select
                                value={secondaryCategory}
                                onChange={handleSecondaryCategoryChange}
                                disabled={
                                  productCategories.filter(
                                    (category) =>
                                      category.ParentCategoryId ===
                                      primaryCategory,
                                  ).length === 0
                                }
                              >
                                <option value="">Podkategoria</option>
                                {productCategories
                                  .filter(
                                    (category) =>
                                      category.ParentCategoryId ===
                                      primaryCategory,
                                  )
                                  .map((category) => {
                                    const {Id, Name} = category;

                                    return (
                                      <option value={Id} key={Id}>
                                        {Name}
                                      </option>
                                    );
                                  })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                            <CategorySelectWrapper>
                              <select
                                value={tertiaryCategory}
                                onChange={handleTertiaryCategoryChange}
                                disabled={
                                  productCategories.filter(
                                    (category) =>
                                      category.ParentCategoryId ===
                                      secondaryCategory,
                                  ).length === 0
                                }
                              >
                                <option value="">Podkategoria</option>
                                {productCategories
                                  .filter(
                                    (category) =>
                                      category.ParentCategoryId ===
                                      secondaryCategory,
                                  )
                                  .map((category) => {
                                    const {Id, Name} = category;

                                    return (
                                      <option value={Id} key={Id}>
                                        {Name}
                                      </option>
                                    );
                                  })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                          </>
                        ) : (
                          <>
                            <DisplaySingleCategory>
                              <p>
                                {(productCategories.filter(
                                  (category) => category.Id === primaryCategory,
                                ).length > 0 &&
                                  productCategories.filter(
                                    (category) =>
                                      category.Id === primaryCategory,
                                  )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                            <DisplaySingleCategory>
                              <p>
                                {(productCategories.filter(
                                  (category) =>
                                    category.Id === secondaryCategory,
                                ).length > 0 &&
                                  productCategories.filter(
                                    (category) =>
                                      category.Id === secondaryCategory,
                                  )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                            <DisplaySingleCategory>
                              <p>
                                {(productCategories.filter(
                                  (category) =>
                                    category.Id === tertiaryCategory,
                                ).length > 0 &&
                                  productCategories.filter(
                                    (category) =>
                                      category.Id === tertiaryCategory,
                                  )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                          </>
                        )}
                      </CategoriesWrapper>
                      <SymbolWithTagsWrapper>
                        {isProductEditing ? (
                          <>
                            <TagsWrapper>
                              {tags.map((tag) => (
                                <SingleTagWrapper
                                  key={tag}
                                  style={
                                    isProductEditing
                                      ? {paddingLeft: 'unset'}
                                      : {paddingLeft: '7.5px'}
                                  }
                                >
                                  {isProductEditing && (
                                    <DeleteTagButton
                                      onClick={() => deleteTag(tag)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10.9"
                                        height="12"
                                        viewBox="0 0 10.9 12"
                                      >
                                        <g
                                          id="Icon_feather-trash"
                                          data-name="Icon feather-trash"
                                          transform="translate(0.5 0.5)"
                                        >
                                          <path
                                            id="Path_3663"
                                            data-name="Path 3663"
                                            d="M4.5,9h9.9"
                                            transform="translate(-4.5 -6.8)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                          />
                                          <path
                                            id="Path_3664"
                                            data-name="Path 3664"
                                            d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                            transform="translate(-6.4 -3)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                          />
                                        </g>
                                      </svg>
                                    </DeleteTagButton>
                                  )}
                                  <p>{tag}</p>
                                </SingleTagWrapper>
                              ))}
                              <AddTagWrapper onKeyUp={addTagOnKeyUp}>
                                <input
                                  type="text"
                                  value={tag}
                                  maxLength={20}
                                  onChange={handleTagChange}
                                  size={tag.length === 0 ? 1 : tag.length}
                                  placeholder="Tag..."
                                />
                                <AddTagButton onClick={() => addTag(tag)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.138"
                                    height="15"
                                    viewBox="0 0 15.138 15"
                                  >
                                    <path
                                      id="Path_3683"
                                      data-name="Path 3683"
                                      d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                                      transform="translate(-171.869 -100.292)"
                                      fill="#fff"
                                    />
                                  </svg>
                                </AddTagButton>
                              </AddTagWrapper>
                            </TagsWrapper>
                          </>
                        ) : (
                          <>
                            {/* <p>Symbol: {symbol}</p> */}
                            <p>
                              Tagi:{' '}
                              <span>
                                {tags.map(
                                  (tag, index) =>
                                    `${tag}${
                                      index === tags.length - 1 ? '' : ', '
                                    }`,
                                )}
                              </span>
                            </p>
                          </>
                        )}
                      </SymbolWithTagsWrapper>
                    </CategoriesWithSymbolWrapper>
                  )}

                  {productType === 8 && (
                    <FilesWrapper>
                      <FileWithTitleWrapper>
                        <h1>Wersja do odsłuchu</h1>
                        <p>
                          Przygotuj skróconą wersję tracka, która najlepiej odda
                          jego charakter <br />
                          (maksymalnie do 30 sekund){' '}
                        </p>
                        {isProductEditing &&
                        files.filter((file) => file.ProductFileType === 3)
                          .length > 0 ? (
                          files
                            .filter((file, i) => file.ProductFileType === 3)
                            .slice(0, 1)
                            .map((file, i) => {
                              const {File, Name, Id} = file;

                              if (i > 0) return;

                              return (
                                <TrackFileWrapper key={Id + Name}>
                                  <h5 title={Name}>{Name}</h5>
                                  <NoFileSvg />
                                  <Button
                                    onClick={() => {
                                      handleSnippetAudioRefPlayPause();
                                    }}
                                    isActive={isSnippetAudioPlayed}
                                  />
                                  <DeleteFileButton
                                    onClick={() => {
                                      if (
                                        files.filter(
                                          (file) =>
                                            file.ProductFileType === 3 &&
                                            file.Id,
                                        ).length > 0
                                      ) {
                                        deleteExistingFile(
                                          files.filter(
                                            (file) =>
                                              file.ProductFileType === 3 &&
                                              file.Id,
                                          )[0],
                                        );
                                      } else if (
                                        files.filter(
                                          (file) =>
                                            file.ProductFileType === 3 &&
                                            !file.Id,
                                        ).length > 0
                                      ) {
                                        deleteNewFile(
                                          files.filter(
                                            (file) =>
                                              file.ProductFileType === 3 &&
                                              !file.Id,
                                          )[0],
                                        );
                                      }
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10.9"
                                      height="12"
                                      viewBox="0 0 10.9 12"
                                    >
                                      <g
                                        id="Icon_feather-trash"
                                        data-name="Icon feather-trash"
                                        transform="translate(0.5 0.5)"
                                      >
                                        <path
                                          id="Path_3663"
                                          data-name="Path 3663"
                                          d="M4.5,9h9.9"
                                          transform="translate(-4.5 -6.8)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="1"
                                        />
                                        <path
                                          id="Path_3664"
                                          data-name="Path 3664"
                                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                          transform="translate(-6.4 -3)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="1"
                                        />
                                      </g>
                                    </svg>
                                  </DeleteFileButton>
                                </TrackFileWrapper>
                              );
                            })
                        ) : isProductEditing ? (
                          <FileWrapper>
                            <p>
                              Format pliku: <span>MP3</span>
                            </p>
                            <p>
                              Rozmiar: maks. <span>2MB</span>
                            </p>
                            <AddFileButton
                              onClick={() => snippetFileRef.current.click()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.138"
                                height="15"
                                viewBox="0 0 15.138 15"
                              >
                                <path
                                  id="Path_3683"
                                  data-name="Path 3683"
                                  d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                                  transform="translate(-171.869 -100.292)"
                                  fill="#fff"
                                />
                              </svg>
                            </AddFileButton>
                            <input
                              type={'file'}
                              hidden
                              ref={snippetFileRef}
                              accept={'.mp3'}
                              onChange={getSnippetFile}
                            />
                          </FileWrapper>
                        ) : !isProductEditing &&
                          files.filter((file) => file.ProductFileType === 3)
                            .length > 0 ? (
                          files
                            .filter((file, i) => file.ProductFileType === 3)
                            .slice(0, 1)
                            .map((file, i) => {
                              const {File, Name, Id} = file;

                              if (i > 0) return;

                              return (
                                <TrackFileWrapper key={Id + Name}>
                                  <h5 title={Name}>{Name}</h5>
                                  <NoFileSvg />
                                  <Button
                                    onClick={() => {
                                      handleSnippetAudioRefPlayPause();
                                    }}
                                    isActive={isSnippetAudioPlayed}
                                  />
                                </TrackFileWrapper>
                              );
                            })
                        ) : (
                          <NoFileWrapper>
                            <h5>Brak demo</h5>
                            <NoFileSvg />
                          </NoFileWrapper>
                        )}
                      </FileWithTitleWrapper>
                      <FileWithTitleWrapper>
                        <h1>Demo wersja do pobrania</h1>
                        <p>
                          Dodaj wersję tracka w pogorszonej jakości. Zabezpiecz
                          ją znakiem wodnym. Kupujący po pobraniu będzie mógł
                          lepiej zapoznać się z trackiem.
                        </p>
                        {isProductEditing &&
                        files.filter((file) => file.ProductFileType === 1)
                          .length > 0 ? (
                          files
                            .filter((file, i) => file.ProductFileType === 1)
                            .slice(0, 1)
                            .map((file, i) => {
                              const {File, Name, Id} = file;

                              if (i > 0) return;

                              return (
                                <TrackFileWrapper key={Id + Name}>
                                  <h5 title={Name}>{Name}</h5>
                                  <NoFileSvg />
                                  <Button
                                    onClick={() => {
                                      handleDemoAudioRefPlayPause();
                                    }}
                                    isActive={isDemoAudioPlayed}
                                  />
                                  <DeleteFileButton
                                    onClick={() => {
                                      if (
                                        files.filter(
                                          (file) =>
                                            file.ProductFileType === 1 &&
                                            file.Id,
                                        ).length > 0
                                      ) {
                                        deleteExistingFile(
                                          files.filter(
                                            (file) =>
                                              file.ProductFileType === 1 &&
                                              file.Id,
                                          )[0],
                                        );
                                      } else if (
                                        files.filter(
                                          (file) =>
                                            file.ProductFileType === 1 &&
                                            !file.Id,
                                        ).length > 0
                                      ) {
                                        deleteNewFile(
                                          files.filter(
                                            (file) =>
                                              file.ProductFileType === 1 &&
                                              !file.Id,
                                          )[0],
                                        );
                                      }
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10.9"
                                      height="12"
                                      viewBox="0 0 10.9 12"
                                    >
                                      <g
                                        id="Icon_feather-trash"
                                        data-name="Icon feather-trash"
                                        transform="translate(0.5 0.5)"
                                      >
                                        <path
                                          id="Path_3663"
                                          data-name="Path 3663"
                                          d="M4.5,9h9.9"
                                          transform="translate(-4.5 -6.8)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="1"
                                        />
                                        <path
                                          id="Path_3664"
                                          data-name="Path 3664"
                                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                          transform="translate(-6.4 -3)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="1"
                                        />
                                      </g>
                                    </svg>
                                  </DeleteFileButton>
                                </TrackFileWrapper>
                              );
                            })
                        ) : isProductEditing ? (
                          <FileWrapper>
                            <h4>(pole nieobowiązkowe)</h4>
                            <p>
                              Format pliku: <span>MP3</span>
                            </p>
                            <p>
                              Rozmiar: maks. <span>3MB</span>
                            </p>
                            <AddFileButton
                              onClick={() => demoFileRef.current.click()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.138"
                                height="15"
                                viewBox="0 0 15.138 15"
                              >
                                <path
                                  id="Path_3683"
                                  data-name="Path 3683"
                                  d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                                  transform="translate(-171.869 -100.292)"
                                  fill="#fff"
                                />
                              </svg>
                            </AddFileButton>
                            <input
                              type={'file'}
                              hidden
                              ref={demoFileRef}
                              accept={'.mp3'}
                              onChange={getDemoFile}
                            />
                          </FileWrapper>
                        ) : !isProductEditing &&
                          files.filter((file) => file.ProductFileType === 1)
                            .length > 0 ? (
                          files
                            .filter((file, i) => file.ProductFileType === 1)
                            .slice(0, 1)
                            .map((file, i) => {
                              const {File, Name, Id} = file;

                              if (i > 0) return;

                              return (
                                <TrackFileWrapper key={Id + Name}>
                                  <h5 title={Name}>{Name}</h5>
                                  <NoFileSvg />
                                  <Button
                                    onClick={() => {
                                      handleDemoAudioRefPlayPause();
                                    }}
                                    isActive={isDemoAudioPlayed}
                                  />
                                </TrackFileWrapper>
                              );
                            })
                        ) : (
                          <NoFileWrapper>
                            <h5>Brak demo</h5>
                            <NoFileSvg />
                          </NoFileWrapper>
                        )}
                      </FileWithTitleWrapper>
                      <FileWithTitleWrapper>
                        <h1>Wersja finalna tracka</h1>
                        <p>
                          Dodaj plik (WAV, MP3) bądź zestaw plików, np. ścieżki
                          lub wersje tracka (ZIP), które klient otrzyma po
                          zakupie.
                        </p>
                        {isProductEditing &&
                        files.filter((file) => file.ProductFileType === 2)
                          .length > 0 ? (
                          files
                            .filter((file, i) => file.ProductFileType === 2)
                            .slice(0, 1)
                            .map((file, i) => {
                              const {File, Name, Id} = file;

                              if (i > 0) return;

                              return (
                                <TrackFileWrapper key={Id + Name}>
                                  <h5 title={Name}>{Name}</h5>
                                  <NoFileSvg />
                                  <Button
                                    onClick={() => {
                                      handleProductAudioRefPlayPause();
                                    }}
                                    isActive={isProductAudioPlayed}
                                  />
                                  <DeleteFileButton
                                    onClick={() => {
                                      if (
                                        files.filter(
                                          (file) =>
                                            file.ProductFileType === 2 &&
                                            file.Id,
                                        ).length > 0
                                      ) {
                                        deleteExistingFile(
                                          files.filter(
                                            (file) =>
                                              file.ProductFileType === 2 &&
                                              file.Id,
                                          )[0],
                                        );
                                      } else if (
                                        files.filter(
                                          (file) =>
                                            file.ProductFileType === 2 &&
                                            !file.Id,
                                        ).length > 0
                                      ) {
                                        deleteNewFile(
                                          files.filter(
                                            (file) =>
                                              file.ProductFileType === 2 &&
                                              !file.Id,
                                          )[0],
                                        );
                                      }
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="10.9"
                                      height="12"
                                      viewBox="0 0 10.9 12"
                                    >
                                      <g
                                        id="Icon_feather-trash"
                                        data-name="Icon feather-trash"
                                        transform="translate(0.5 0.5)"
                                      >
                                        <path
                                          id="Path_3663"
                                          data-name="Path 3663"
                                          d="M4.5,9h9.9"
                                          transform="translate(-4.5 -6.8)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="1"
                                        />
                                        <path
                                          id="Path_3664"
                                          data-name="Path 3664"
                                          d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                          transform="translate(-6.4 -3)"
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="1"
                                        />
                                      </g>
                                    </svg>
                                  </DeleteFileButton>
                                </TrackFileWrapper>
                              );
                            })
                        ) : isProductEditing ? (
                          <FileWrapper>
                            <p>
                              Format pliku: <span>WAV, MP3 lub ZIP</span>
                            </p>
                            <p>
                              Rozmiar: maks. <span>150MB</span>
                            </p>
                            <AddFileButton
                              onClick={() => productFileRef.current.click()}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.138"
                                height="15"
                                viewBox="0 0 15.138 15"
                              >
                                <path
                                  id="Path_3683"
                                  data-name="Path 3683"
                                  d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                                  transform="translate(-171.869 -100.292)"
                                  fill="#fff"
                                />
                              </svg>
                            </AddFileButton>
                            <input
                              type={'file'}
                              hidden
                              ref={productFileRef}
                              accept={'.wav,.zip,.mp3,.rar'}
                              onChange={getProductFile}
                            />
                          </FileWrapper>
                        ) : !isProductEditing &&
                          files.filter((file) => file.ProductFileType === 2)
                            .length > 0 ? (
                          files
                            .filter((file) => file.ProductFileType === 2)
                            .slice(0, 1)
                            .map((file, i) => {
                              const {File, Name, Id} = file;

                              if (i > 0) return;

                              return (
                                <TrackFileWrapper key={Id + Name}>
                                  <h5 title={Name}>{Name}</h5>
                                  <NoFileSvg />
                                  <Button
                                    onClick={() => {
                                      handleProductAudioRefPlayPause();
                                    }}
                                    isActive={isProductAudioPlayed}
                                  />
                                </TrackFileWrapper>
                              );
                            })
                        ) : (
                          <NoFileWrapper>
                            <h5>Brak demo</h5>
                            <NoFileSvg />
                          </NoFileWrapper>
                        )}
                      </FileWithTitleWrapper>

                      {audioFiles.snippet && (
                        <audio ref={snippetAudioRef}>
                          <source
                            src={audioFiles['snippet']}
                            type="audio/mp3"
                          />
                          Your browser does not support the audio element.
                        </audio>
                      )}
                      {audioFiles.demo && (
                        <audio ref={demoAudioRef}>
                          <source src={audioFiles['demo']} type="audio/mp3" />
                          Your browser does not support the audio element.
                        </audio>
                      )}

                      {audioFiles.main && (
                        <audio ref={productAudioRef}>
                          <source src={audioFiles['main']} type="audio/mp3" />
                          Your browser does not support the audio element.
                        </audio>
                      )}
                    </FilesWrapper>
                  )}
                  {productType === 8 ? (
                    <div style={{marginTop: '9px'}}>
                      <SymbolWithTagsWrapper style={{alignItems: 'unset'}}>
                        {isProductEditing ? (
                          <>
                            {/* <InputView
                          isEditing={true}
                          isError={errorVerification.symbolError !== null}
                          style={{marginTop: '0px', marginBottom: '0px'}}
                        >
                          <p>Symbol</p>
                          <input
                            type="text"
                            placeholder="Uzupełnij"
                            value={symbol}
                            readOnly={!isProductEditing}
                            onChange={handleSymbolChange}
                          />
                          {errorVerification.symbolError !== null && (
                            <ErrorStar text={errorVerification.symbolError} />
                          )}
                        </InputView> */}
                            <TagsWrapper>
                              {tags.map((tag) => (
                                <SingleTagWrapper
                                  key={tag}
                                  style={
                                    isProductEditing
                                      ? {paddingLeft: 'unset'}
                                      : {paddingLeft: '7.5px'}
                                  }
                                >
                                  {isProductEditing && (
                                    <DeleteTagButton
                                      onClick={() => deleteTag(tag)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10.9"
                                        height="12"
                                        viewBox="0 0 10.9 12"
                                      >
                                        <g
                                          id="Icon_feather-trash"
                                          data-name="Icon feather-trash"
                                          transform="translate(0.5 0.5)"
                                        >
                                          <path
                                            id="Path_3663"
                                            data-name="Path 3663"
                                            d="M4.5,9h9.9"
                                            transform="translate(-4.5 -6.8)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                          />
                                          <path
                                            id="Path_3664"
                                            data-name="Path 3664"
                                            d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                            transform="translate(-6.4 -3)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                          />
                                        </g>
                                      </svg>
                                    </DeleteTagButton>
                                  )}
                                  <p>{tag}</p>
                                </SingleTagWrapper>
                              ))}
                              <AddTagWrapper onKeyUp={addTagOnKeyUp}>
                                <input
                                  type="text"
                                  value={tag}
                                  maxLength={20}
                                  onChange={handleTagChange}
                                  size={tag.length === 0 ? 1 : tag.length}
                                  placeholder="Tag..."
                                />
                                <AddTagButton onClick={() => addTag(tag)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.138"
                                    height="15"
                                    viewBox="0 0 15.138 15"
                                  >
                                    <path
                                      id="Path_3683"
                                      data-name="Path 3683"
                                      d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                                      transform="translate(-171.869 -100.292)"
                                      fill="#fff"
                                    />
                                  </svg>
                                </AddTagButton>
                              </AddTagWrapper>
                            </TagsWrapper>
                          </>
                        ) : (
                          <>
                            {/* <p>Symbol: {symbol}</p> */}
                            <p style={{display: 'unset'}}>
                              Tagi:{' '}
                              <span>
                                {tags.map(
                                  (tag, index) =>
                                    `${tag}${
                                      index === tags.length - 1 ? '' : ', '
                                    }`,
                                )}
                              </span>
                            </p>
                          </>
                        )}
                      </SymbolWithTagsWrapper>
                      <TrackCategoriesWrapper>
                        {isProductEditing ? (
                          <>
                            <CategorySelectWrapper>
                              <select
                                value={primaryCategory}
                                onChange={handlePrimaryCategoryChange}
                                disabled={
                                  productCategories.filter(
                                    (category) =>
                                      category.ParentCategoryId === null,
                                  ).length === 0
                                }
                              >
                                <option value="">Gatunek</option>
                                {productCategories
                                  .filter(
                                    (category) =>
                                      category.ParentCategoryId === null,
                                  )
                                  .map((category) => {
                                    const {Id, Name} = category;

                                    return (
                                      <option value={Id} key={Id}>
                                        {Name}
                                      </option>
                                    );
                                  })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                            <CategorySelectWrapper>
                              <select
                                value={secondaryCategory}
                                onChange={handleSecondaryCategoryChange}
                                disabled={
                                  productCategories.filter(
                                    (category) =>
                                      category.ParentCategoryId ===
                                      primaryCategory,
                                  ).length === 0
                                }
                              >
                                <option value="">Podgatunek</option>
                                {productCategories
                                  .filter(
                                    (category) =>
                                      category.ParentCategoryId ===
                                      primaryCategory,
                                  )
                                  .map((category) => {
                                    const {Id, Name} = category;

                                    return (
                                      <option value={Id} key={Id}>
                                        {Name}
                                      </option>
                                    );
                                  })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                            <div />
                            <CategorySelectWrapper>
                              <select value={vibe} onChange={handleVibeChange}>
                                <option value="">Nastrój</option>
                                {productAttributes.length > 0 &&
                                  productAttributes
                                    .filter(
                                      (productAttribute) =>
                                        productAttribute.Id ===
                                        'f65268e6-c5f9-4f82-9203-68c673cbab08',
                                    )[0]
                                    .AttributeValues.map((attributeValue) => {
                                      const {Id, Name} = attributeValue;

                                      return (
                                        <option value={Id} key={Id}>
                                          {Name}
                                        </option>
                                      );
                                    })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                            <CategorySelectWrapper>
                              <select
                                value={tempo}
                                onChange={handleTempoChange}
                              >
                                <option value="">Tempo</option>
                                {productAttributes.length > 0 &&
                                  productAttributes
                                    .filter(
                                      (productAttribute) =>
                                        productAttribute.Id ===
                                        'f65268e6-c5f9-4f82-9203-68c673cbab06',
                                    )[0]
                                    .AttributeValues.map((attributeValue) => {
                                      const {Id, Name} = attributeValue;

                                      return (
                                        <option value={Id} key={Id}>
                                          {Name}
                                        </option>
                                      );
                                    })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                            <CategorySelectWrapper>
                              <select value={tone} onChange={handleToneChange}>
                                <option value="">Tonacja</option>
                                {productAttributes.length > 0 &&
                                  productAttributes
                                    .filter(
                                      (productAttribute) =>
                                        productAttribute.Id ===
                                        'f65268e6-c5f9-4f82-9203-68c673cbab07',
                                    )[0]
                                    .AttributeValues.map((attributeValue) => {
                                      const {Id, Name} = attributeValue;

                                      return (
                                        <option value={Id} key={Id}>
                                          {Name}
                                        </option>
                                      );
                                    })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                          </>
                        ) : (
                          <>
                            <DisplaySingleCategory>
                              <p>
                                {(productCategories.filter(
                                  (category) => category.Id === primaryCategory,
                                ).length > 0 &&
                                  productCategories.filter(
                                    (category) =>
                                      category.Id === primaryCategory,
                                  )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                            <DisplaySingleCategory>
                              <p>
                                {(productCategories.filter(
                                  (category) =>
                                    category.Id === secondaryCategory,
                                ).length > 0 &&
                                  productCategories.filter(
                                    (category) =>
                                      category.Id === secondaryCategory,
                                  )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                            <div />
                            <DisplaySingleCategory>
                              <p>
                                {(vibe &&
                                  productAttributes.length > 0 &&
                                  productAttributes
                                    .filter(
                                      (productAttribute) =>
                                        productAttribute.Id ===
                                        'f65268e6-c5f9-4f82-9203-68c673cbab08',
                                    )[0]
                                    .AttributeValues.filter(
                                      (attributeValue) =>
                                        attributeValue.Id === vibe,
                                    )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                            <DisplaySingleCategory>
                              <p>
                                {(tempo &&
                                  productAttributes.length > 0 &&
                                  productAttributes
                                    .filter(
                                      (productAttribute) =>
                                        productAttribute.Id ===
                                        'f65268e6-c5f9-4f82-9203-68c673cbab06',
                                    )[0]
                                    .AttributeValues.filter(
                                      (attributeValue) =>
                                        attributeValue.Id === tempo,
                                    )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                            <DisplaySingleCategory>
                              <p>
                                {(tone &&
                                  productAttributes.length > 0 &&
                                  productAttributes
                                    .filter(
                                      (productAttribute) =>
                                        productAttribute.Id ===
                                        'f65268e6-c5f9-4f82-9203-68c673cbab07',
                                    )[0]
                                    .AttributeValues.filter(
                                      (attributeValue) =>
                                        attributeValue.Id === tone,
                                    )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                          </>
                        )}
                      </TrackCategoriesWrapper>
                    </div>
                  ) : productType !== 1 ? (
                    <CategoriesWithSymbolWrapper>
                      <CategoriesWrapper>
                        {isProductEditing ? (
                          <>
                            <CategorySelectWrapper>
                              <select
                                value={primaryCategory}
                                onChange={handlePrimaryCategoryChange}
                                disabled={
                                  productCategories.filter(
                                    (category) =>
                                      category.ParentCategoryId === null,
                                  ).length === 0
                                }
                              >
                                <option value="">Kategoria</option>
                                {productCategories
                                  .filter(
                                    (category) =>
                                      category.ParentCategoryId === null,
                                  )
                                  .map((category) => {
                                    const {Id, Name} = category;

                                    return (
                                      <option value={Id} key={Id}>
                                        {Name}
                                      </option>
                                    );
                                  })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                            <CategorySelectWrapper>
                              <select
                                value={secondaryCategory}
                                onChange={handleSecondaryCategoryChange}
                                disabled={
                                  productCategories.filter(
                                    (category) =>
                                      category.ParentCategoryId ===
                                      primaryCategory,
                                  ).length === 0
                                }
                              >
                                <option value="">Podkategoria</option>
                                {productCategories
                                  .filter(
                                    (category) =>
                                      category.ParentCategoryId ===
                                      primaryCategory,
                                  )
                                  .map((category) => {
                                    const {Id, Name} = category;

                                    return (
                                      <option value={Id} key={Id}>
                                        {Name}
                                      </option>
                                    );
                                  })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                            <CategorySelectWrapper>
                              <select
                                value={tertiaryCategory}
                                onChange={handleTertiaryCategoryChange}
                                disabled={
                                  productCategories.filter(
                                    (category) =>
                                      category.ParentCategoryId ===
                                      secondaryCategory,
                                  ).length === 0
                                }
                              >
                                <option value="">Podkategoria</option>
                                {productCategories
                                  .filter(
                                    (category) =>
                                      category.ParentCategoryId ===
                                      secondaryCategory,
                                  )
                                  .map((category) => {
                                    const {Id, Name} = category;

                                    return (
                                      <option value={Id} key={Id}>
                                        {Name}
                                      </option>
                                    );
                                  })}
                              </select>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="6.439"
                                height="11"
                                viewBox="0 0 6.439 11"
                              >
                                <g
                                  id="Arrow_Top_Bar_Accent"
                                  data-name="Arrow – Top Bar / Accent"
                                  transform="translate(6.439 11) rotate(180)"
                                >
                                  <path
                                    id="Arrow"
                                    d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                                    transform="translate(0 0)"
                                    fill="#3a87f0"
                                  />
                                </g>
                              </svg>
                            </CategorySelectWrapper>
                          </>
                        ) : (
                          <>
                            <DisplaySingleCategory>
                              <p>
                                {(productCategories.filter(
                                  (category) => category.Id === primaryCategory,
                                ).length > 0 &&
                                  productCategories.filter(
                                    (category) =>
                                      category.Id === primaryCategory,
                                  )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                            <DisplaySingleCategory>
                              <p>
                                {(productCategories.filter(
                                  (category) =>
                                    category.Id === secondaryCategory,
                                ).length > 0 &&
                                  productCategories.filter(
                                    (category) =>
                                      category.Id === secondaryCategory,
                                  )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                            <DisplaySingleCategory>
                              <p>
                                {(productCategories.filter(
                                  (category) =>
                                    category.Id === tertiaryCategory,
                                ).length > 0 &&
                                  productCategories.filter(
                                    (category) =>
                                      category.Id === tertiaryCategory,
                                  )[0].Name) ||
                                  '-'}
                              </p>
                            </DisplaySingleCategory>
                          </>
                        )}
                      </CategoriesWrapper>
                      <SymbolWithTagsWrapper>
                        {isProductEditing ? (
                          <>
                            {/* <InputView
                          isEditing={true}
                          isError={errorVerification.symbolError !== null}
                          style={{marginTop: '0px', marginBottom: '0px'}}
                        >
                          <p>Symbol</p>
                          <input
                            type="text"
                            placeholder="Uzupełnij"
                            value={symbol}
                            readOnly={!isProductEditing}
                            onChange={handleSymbolChange}
                          />
                          {errorVerification.symbolError !== null && (
                            <ErrorStar text={errorVerification.symbolError} />
                          )}
                        </InputView> */}
                            <TagsWrapper>
                              {tags.map((tag) => (
                                <SingleTagWrapper
                                  key={tag}
                                  style={
                                    isProductEditing
                                      ? {paddingLeft: 'unset'}
                                      : {paddingLeft: '7.5px'}
                                  }
                                >
                                  {isProductEditing && (
                                    <DeleteTagButton
                                      onClick={() => deleteTag(tag)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10.9"
                                        height="12"
                                        viewBox="0 0 10.9 12"
                                      >
                                        <g
                                          id="Icon_feather-trash"
                                          data-name="Icon feather-trash"
                                          transform="translate(0.5 0.5)"
                                        >
                                          <path
                                            id="Path_3663"
                                            data-name="Path 3663"
                                            d="M4.5,9h9.9"
                                            transform="translate(-4.5 -6.8)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                          />
                                          <path
                                            id="Path_3664"
                                            data-name="Path 3664"
                                            d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                            transform="translate(-6.4 -3)"
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="1"
                                          />
                                        </g>
                                      </svg>
                                    </DeleteTagButton>
                                  )}
                                  <p>{tag}</p>
                                </SingleTagWrapper>
                              ))}
                              <AddTagWrapper onKeyUp={addTagOnKeyUp}>
                                <input
                                  type="text"
                                  maxLength={20}
                                  value={tag}
                                  onChange={handleTagChange}
                                  size={tag.length === 0 ? 1 : tag.length}
                                  placeholder="Tag..."
                                />
                                <AddTagButton onClick={() => addTag(tag)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.138"
                                    height="15"
                                    viewBox="0 0 15.138 15"
                                  >
                                    <path
                                      id="Path_3683"
                                      data-name="Path 3683"
                                      d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                                      transform="translate(-171.869 -100.292)"
                                      fill="#fff"
                                    />
                                  </svg>
                                </AddTagButton>
                              </AddTagWrapper>
                            </TagsWrapper>
                          </>
                        ) : (
                          <>
                            {/* <p>Symbol: {symbol}</p> */}
                            <p>
                              Tagi:{' '}
                              <span>
                                {tags.map(
                                  (tag, index) =>
                                    `${tag}${
                                      index === tags.length - 1 ? '' : ', '
                                    }`,
                                )}
                              </span>
                            </p>
                          </>
                        )}
                      </SymbolWithTagsWrapper>
                    </CategoriesWithSymbolWrapper>
                  ) : (
                    <></>
                  )}

                  {isProductEditing && (
                    <SaveProductButton
                      onClick={() => {
                        if (createNewProductStep === 0) {
                          editProduct(productId);
                        }
                        // else if (
                        //   createNewProductStep === 2 &&
                        //   singleProductId
                        // ) {
                        //   editProductStep2(singleProductId);
                        // }
                        else {
                          createProduct();
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.5"
                        height="15.5"
                        viewBox="0 0 15.5 15.5"
                      >
                        <g
                          id="Icon_feather-save"
                          data-name="Icon feather-save"
                          transform="translate(-3.75 -3.75)"
                        >
                          <path
                            id="Path_3700"
                            data-name="Path 3700"
                            d="M16.944,18.5H6.056A1.556,1.556,0,0,1,4.5,16.944V6.056A1.556,1.556,0,0,1,6.056,4.5h8.556L18.5,8.389v8.556A1.556,1.556,0,0,1,16.944,18.5Z"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3701"
                            data-name="Path 3701"
                            d="M18.278,25.722V19.5H10.5v6.222"
                            transform="translate(-2.889 -7.222)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3702"
                            data-name="Path 3702"
                            d="M10.5,4.5V8.389h6.222"
                            transform="translate(-2.889)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </SaveProductButton>
                  )}
                </ProductDataContentWrapper>
              </ProductDataWrapper>

              <ConditionsWrapper isEditing={isConditionEditing}>
                <SectionHeader isEditing={isConditionEditing}>
                  {createNewProductStep === 2 && isConditionEditing && (
                    <BackArrowButtonLeft onClick={() => backToProductEditing()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(0 0)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117.224.157,5.127a.523.523,0,0,0,0,.747l4.961,4.9a.781.781,0,0,0,1.095,0,.76.76,0,0,0,0-1.082L1.969,5.5,6.212,1.306a.76.76,0,0,0,0-1.082.781.781,0,0,0-1.095,0"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </BackArrowButtonLeft>
                  )}

                  <p>Warunki</p>
                  {createNewProductStep === 0 &&
                  productDisplayStatus !== 3 &&
                  productDisplayStatus !== 4 &&
                  productDisplayStatus !== 6 &&
                  isConditionEditing ? (
                    <PopupButton
                      buttons={['Kontynuuj', 'Zamknij']}
                      title={'Uwaga'}
                      text={`
                         Czy na pewno chcesz zamknąć edycje? Wszystkie zmiany nie zostaną zapisane.
                        `}
                      onClick={() => {
                        getSingleProduct(singleProductId);
                        setIsConditionEditing(false);
                        if (conditionOne?.Id) {
                          setSelectedCondition(1);
                        } else if (conditionTwo?.Id) {
                          setSelectedCondition(2);
                        } else if (conditionThree?.Id) {
                          setSelectedCondition(3);
                        }
                        // setSelectedConditionOne('');
                        // setSelectedConditionThree('');
                        // setSelectedConditionTwo('');
                      }}
                    >
                      <EditBtnWrapper
                        style={{top: '0px'}}
                        isEditing={isConditionEditing}
                      >
                        <EditSvgWrapper isEditing={isConditionEditing}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.27"
                            height="16.658"
                            viewBox="0 0 17.27 16.658"
                          >
                            <g
                              id="Icon_feather-edit-3"
                              data-name="Icon feather-edit-3"
                              transform="translate(-3.75 -3.41)"
                            >
                              <path
                                id="Path_3712"
                                data-name="Path 3712"
                                d="M18,30h7.885"
                                transform="translate(-5.615 -10.682)"
                                fill="none"
                                stroke="rgba(7,2,15,0.7)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_3713"
                                data-name="Path 3713"
                                d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                                fill="none"
                                stroke="rgba(7,2,15,0.7)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </EditSvgWrapper>
                      </EditBtnWrapper>
                    </PopupButton>
                  ) : createNewProductStep === 2 && isProductEditing ? null : (
                    <EditBtnWrapper
                      style={{top: '0px'}}
                      isEditing={isConditionEditing}
                      onClick={() => {
                        if (
                          createNewProductStep === 0 &&
                          !isProductEditing &&
                          productDisplayStatus !== 3 &&
                          productDisplayStatus !== 4 &&
                          productDisplayStatus !== 6
                        ) {
                          setIsConditionEditing(!isConditionEditing);
                        } else if (
                          productDisplayStatus === 3 ||
                          productDisplayStatus === 4 ||
                          productDisplayStatus === 6
                        ) {
                          Alert('W celu edycji wycofaj ofertę.', 'Komunikat');
                        }
                      }}
                    >
                      <EditSvgWrapper isEditing={isConditionEditing}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.27"
                          height="16.658"
                          viewBox="0 0 17.27 16.658"
                        >
                          <g
                            id="Icon_feather-edit-3"
                            data-name="Icon feather-edit-3"
                            transform="translate(-3.75 -3.41)"
                          >
                            <path
                              id="Path_3712"
                              data-name="Path 3712"
                              d="M18,30h7.885"
                              transform="translate(-5.615 -10.682)"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3713"
                              data-name="Path 3713"
                              d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </EditSvgWrapper>
                    </EditBtnWrapper>
                  )}
                </SectionHeader>
                <ConditionsContentBox>
                  <ConditionDetailsBox>
                    <OptionsWrapper>
                      <SingleOption
                        isEditing={isConditionEditing}
                        haveCondition={conditionOne?.Id ? true : false}
                        value={1}
                        selectedCondition={selectedCondition}
                        onClick={() => {
                          if (selectedCondition === 2) {
                            if (conditionTwo?.Id && !conditionTwo?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              !conditionTwo?.userWinWin &&
                              !conditionTwo?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType === 4 &&
                              !conditionTwo?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType !== 4 &&
                              conditionTwo?.userWinWin &&
                              !conditionTwo?.TimeToUploadForCustomer &&
                              conditionTwo?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType !== 4 &&
                              conditionTwo?.userWinWin &&
                              !conditionTwo?.WinWinDigital &&
                              !conditionTwo?.WinWinPhysical &&
                              !conditionTwo?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          if (selectedCondition === 3) {
                            if (conditionThree?.Id && !conditionThree?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionThree?.Id &&
                              conditionThree?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionThree?.Id &&
                              conditionThree?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              !conditionThree?.userWinWin &&
                              !conditionThree?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType === 4 &&
                              !conditionThree?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType !== 4 &&
                              conditionThree?.userWinWin &&
                              !conditionThree?.TimeToUploadForCustomer &&
                              conditionThree?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType !== 4 &&
                              conditionThree?.userWinWin &&
                              !conditionThree?.WinWinDigital &&
                              !conditionThree?.WinWinPhysical &&
                              !conditionThree?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          setSelectedCondition(1);
                        }}
                      >
                        {selectedCondition === 1 ? (
                          <p>Wariant A</p>
                        ) : (
                          <span>A</span>
                        )}
                      </SingleOption>
                      <SingleOption
                        isEditing={isConditionEditing}
                        haveCondition={conditionTwo?.Id ? true : false}
                        value={2}
                        selectedCondition={selectedCondition}
                        onClick={() => {
                          if (selectedCondition === 1) {
                            if (conditionOne?.Id && !conditionOne?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionOne?.Id &&
                              conditionOne?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionOne?.Id &&
                              conditionOne?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              !conditionOne?.userWinWin &&
                              !conditionOne?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType === 4 &&
                              !conditionOne?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType !== 4 &&
                              conditionOne?.userWinWin &&
                              !conditionOne?.TimeToUploadForCustomer &&
                              conditionOne?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType !== 4 &&
                              conditionOne?.userWinWin &&
                              !conditionOne?.WinWinDigital &&
                              !conditionOne?.WinWinPhysical &&
                              !conditionOne?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          if (selectedCondition === 3) {
                            if (conditionThree?.Id && !conditionThree?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionThree?.Id &&
                              conditionThree?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionThree?.Id &&
                              conditionThree?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              !conditionThree?.userWinWin &&
                              !conditionThree?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType === 4 &&
                              !conditionThree?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType !== 4 &&
                              conditionThree?.userWinWin &&
                              !conditionThree?.TimeToUploadForCustomer &&
                              conditionThree?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType !== 4 &&
                              conditionThree?.userWinWin &&
                              !conditionThree?.WinWinDigital &&
                              !conditionThree?.WinWinPhysical &&
                              !conditionThree?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          setSelectedCondition(2);
                        }}
                      >
                        {selectedCondition === 2 ? (
                          <p>Wariant B</p>
                        ) : (
                          <span>B</span>
                        )}
                      </SingleOption>

                      <SingleOption
                        isEditing={isConditionEditing}
                        haveCondition={conditionThree?.Id ? true : false}
                        value={3}
                        selectedCondition={selectedCondition}
                        onClick={() => {
                          if (selectedCondition === 2) {
                            if (conditionTwo?.Id && !conditionTwo?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              !conditionTwo?.userWinWin &&
                              !conditionTwo?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType === 4 &&
                              !conditionTwo?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType !== 4 &&
                              conditionTwo?.userWinWin &&
                              !conditionTwo?.TimeToUploadForCustomer &&
                              conditionTwo?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType !== 4 &&
                              conditionTwo?.userWinWin &&
                              !conditionTwo?.WinWinDigital &&
                              !conditionTwo?.WinWinPhysical &&
                              !conditionTwo?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          if (selectedCondition === 1) {
                            if (conditionOne?.Id && !conditionOne?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionOne?.Id &&
                              conditionOne?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionOne?.Id &&
                              conditionOne?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              !conditionOne?.userWinWin &&
                              !conditionOne?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType === 4 &&
                              !conditionOne?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType !== 4 &&
                              conditionOne?.userWinWin &&
                              !conditionOne?.TimeToUploadForCustomer &&
                              conditionOne?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType !== 4 &&
                              conditionOne?.userWinWin &&
                              !conditionOne?.WinWinDigital &&
                              !conditionOne?.WinWinPhysical &&
                              !conditionOne?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          setSelectedCondition(3);
                        }}
                      >
                        {selectedCondition === 3 ? (
                          <p>Wariant C</p>
                        ) : (
                          <span>C</span>
                        )}
                      </SingleOption>
                    </OptionsWrapper>
                    {selectedCondition === 1 ? (
                      <ConditionComponent
                        condition={conditionOne}
                        setCondition={setConditionOne}
                        isConditionEditing={isConditionEditing}
                        conditionsList={conditionsList}
                        createNewProductStep={createNewProductStep}
                        isProductEditing={isProductEditing}
                        userMetaData={userMetaData}
                        selectedCondition={selectedCondition}
                        productTypeFromProduct={productType}
                        conditionNumber={1}
                        productId={productId}
                        initialCondition={initialConditionOne}
                        setInitialCondition={setInitialConditionOne}
                        conditionOne={conditionOne}
                        conditionTwo={conditionTwo}
                        conditionThree={conditionThree}
                      />
                    ) : selectedCondition === 2 ? (
                      <ConditionComponent
                        condition={conditionTwo}
                        setCondition={setConditionTwo}
                        isConditionEditing={isConditionEditing}
                        conditionsList={conditionsList}
                        createNewProductStep={createNewProductStep}
                        isProductEditing={isProductEditing}
                        userMetaData={userMetaData}
                        selectedCondition={selectedCondition}
                        productTypeFromProduct={productType}
                        conditionNumber={2}
                        productId={productId}
                        initialCondition={initialConditionTwo}
                        setInitialCondition={setInitialConditionTwo}
                        conditionOne={conditionOne}
                        conditionTwo={conditionTwo}
                        conditionThree={conditionThree}
                      />
                    ) : (
                      selectedCondition === 3 && (
                        <ConditionComponent
                          condition={conditionThree}
                          setCondition={setConditionThree}
                          isConditionEditing={isConditionEditing}
                          conditionsList={conditionsList}
                          createNewProductStep={createNewProductStep}
                          isProductEditing={isProductEditing}
                          userMetaData={userMetaData}
                          selectedCondition={selectedCondition}
                          productTypeFromProduct={productType}
                          conditionNumber={3}
                          productId={productId}
                          initialCondition={initialConditionThree}
                          setInitialCondition={setInitialConditionThree}
                          conditionOne={conditionOne}
                          conditionTwo={conditionTwo}
                          conditionThree={conditionThree}
                        />
                      )
                    )}
                    {isConditionEditing && (
                      <SaveProductButton
                        style={{zIndex: '5'}}
                        onClick={() => {
                          updateProductAndConditions();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.5"
                          height="15.5"
                          viewBox="0 0 15.5 15.5"
                        >
                          <g
                            id="Icon_feather-save"
                            data-name="Icon feather-save"
                            transform="translate(-3.75 -3.75)"
                          >
                            <path
                              id="Path_3700"
                              data-name="Path 3700"
                              d="M16.944,18.5H6.056A1.556,1.556,0,0,1,4.5,16.944V6.056A1.556,1.556,0,0,1,6.056,4.5h8.556L18.5,8.389v8.556A1.556,1.556,0,0,1,16.944,18.5Z"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3701"
                              data-name="Path 3701"
                              d="M18.278,25.722V19.5H10.5v6.222"
                              transform="translate(-2.889 -7.222)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3702"
                              data-name="Path 3702"
                              d="M10.5,4.5V8.389h6.222"
                              transform="translate(-2.889)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </SaveProductButton>
                    )}
                  </ConditionDetailsBox>
                </ConditionsContentBox>
              </ConditionsWrapper>
            </ProductDataWithConditionsWrapper>
          </Main>
        </MainWrapper>
        <MobileMainWrapper>
          <MobileHeader>
            <h1>{`Dodawanie oferty - ${
              ProductTypeEnum.length > 0 &&
              ProductTypeEnum.filter((type) => type.FlagValue === productType)
                .length > 0
                ? getProductTypePolishName(
                    ProductTypeEnum.filter(
                      (type) => type.FlagValue === productType,
                    )[0].FlagValue,
                  )
                : ''
            }`}</h1>
            <svg
              onClick={() => navigate('/myproducts')}
              xmlns="http://www.w3.org/2000/svg"
              width="10.312"
              height="18"
              viewBox="0 0 10.312 18"
            >
              <path
                id="Path_4070"
                data-name="Path 4070"
                d="M9.947,16.18,2.557,9.1l7.3-7.048A1.221,1.221,0,0,0,9.943.4,1.221,1.221,0,0,0,8.289.485L0,9.1l8.378,8.646a1.207,1.207,0,0,0,1.645.076,1.208,1.208,0,0,0-.076-1.645"
                transform="translate(0 -0.114)"
                fill="#0091ff"
              />
            </svg>
          </MobileHeader>
          <MobileMain>
            {(isOfferLoading ||
              isProductLoading ||
              isConditionLoading ||
              isConditionCreating ||
              isConditionUpdating ||
              isProductCreating ||
              isProductUpdating ||
              isOfferCreating ||
              isImageGenerating ||
              isOfferUpdating) && <LoadingCircle />}
            {isReadyForOffer && (
              <MobileOfferSectionWrapper>
                <h5>
                  Oferta{' '}
                  {productType === 4 ? (
                    <span>{`${offerCount.ServicesOffersCount}/${seller.CurrentPlan.Plan.MaxServiceOffers}`}</span>
                  ) : productType === 8 ? (
                    <span>{`${offerCount.TracksOffersCount}/${seller.CurrentPlan.Plan.MaxTrackOffers}`}</span>
                  ) : (
                    <span>{`${offerCount.DigitalProductsOffersCount}/${seller.CurrentPlan.Plan.MaxDigitalOffers}`}</span>
                  )}
                </h5>
                {!isReadyForOffer ? (
                  <p>
                    Szczegółowo opisz produkt lub usługę – zachęć do kupna!
                    <br />
                    Określ typ i specyfikację – ułatw wyszukiwanie oferty.
                    <br />
                    Zdefiniuj warunki dotyczące transakcji.
                  </p>
                ) : isReadyForOffer &&
                  (productDisplayStatus === 3 || productDisplayStatus === 4) ? (
                  <p>
                    Twoja oferta pojawiła się właśnie w MUGO Studio. Powodzenia!
                  </p>
                ) : (
                  <p>
                    Masz to! Teraz czas na opublikowanie oferty. Promowanie
                    oferty to większe możliwości dotarcia do odbiorców i większa
                    szansa na sprzedaż.
                  </p>
                )}
                <MobileStyledStatusWrapper
                  style={{width: '280px'}}
                  ProductDisplayStatus={productDisplayStatus}
                >
                  <h1>
                    {productDisplayStatus === 99
                      ? 'Draft'
                      : getProductDisplayStatusDescription(
                          productDisplayStatus,
                        )}
                  </h1>
                  <MobileStyledStatusLine
                    ProductDisplayStatus={productDisplayStatus}
                    style={{width: '280px'}}
                  >
                    <div />
                  </MobileStyledStatusLine>
                </MobileStyledStatusWrapper>
                <MobileAvailibityDurationWrapper>
                  {!offerId ? (
                    <>
                      <OfferSliderWrapper style={{width: '367px'}}>
                        <p>Oferta wyróżniona</p>
                        <OfferSlider
                          isHighlighted={
                            offerCount.FeaturedOffersCount >=
                            seller.CurrentPlan.Plan.MaxOffersHighlights
                              ? false
                              : highlightOffer
                          }
                          onClick={() =>
                            handleHighlightOfferChange(!highlightOffer)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <OfferSliderWrapper
                        style={{width: '367px', marginTop: '6px'}}
                      >
                        <p>Automatyczne odnawianie</p>
                        <OfferSlider
                          isHighlighted={autoRenewalEnabled}
                          onClick={() =>
                            handleAutoRenewalEnabledChange(!autoRenewalEnabled)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <SelectView
                        isEditing={true}
                        style={{width: '367px', marginTop: '6px'}}
                      >
                        <p>Wystaw ofertę na (dni)</p>
                        <select
                          placeholder="Wybierz"
                          value={availibityDuration}
                          onChange={handleAvailibityDurationChange}
                        >
                          <option value="7">7</option>
                          <option value="14">14</option>
                          <option value="21">21</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </>
                  ) : offerId && new Date(availabilityEndDate) < new Date() ? (
                    <>
                      <OfferSliderWrapper style={{width: '367px'}}>
                        <p>Oferta wyróżniona</p>
                        <OfferSlider
                          isHighlighted={
                            offerCount.FeaturedOffersCount >=
                            seller.CurrentPlan.Plan.MaxOffersHighlights
                              ? false
                              : highlightOffer
                          }
                          onClick={() =>
                            handleHighlightOfferChange(!highlightOffer)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <OfferSliderWrapper
                        style={{width: '367px', marginTop: '6px'}}
                      >
                        <p>Automatyczne odnawianie</p>
                        <OfferSlider
                          isHighlighted={autoRenewalEnabled}
                          onClick={() =>
                            handleAutoRenewalEnabledChange(!autoRenewalEnabled)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <SelectView
                        isEditing={true}
                        style={{width: '367px', marginTop: '6px'}}
                      >
                        <p>Wystaw ofertę na (dni)</p>
                        <select
                          placeholder="Wybierz"
                          value={availibityDuration}
                          onChange={handleAvailibityDurationChange}
                        >
                          <option value="7">7</option>
                          <option value="14">14</option>
                          <option value="21">21</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </>
                  ) : offerId &&
                    productDisplayStatus === 7 &&
                    canBeRepublished ? (
                    <>
                      <OfferSliderWrapper style={{width: '367px'}}>
                        <p>Oferta wyróżniona</p>
                        <OfferSlider
                          isHighlighted={
                            offerCount.FeaturedOffersCount >=
                            seller.CurrentPlan.Plan.MaxOffersHighlights
                              ? false
                              : highlightOffer
                          }
                          onClick={() => {
                            handleHighlightOfferChange(!highlightOffer);
                            if (
                              offerCount.FeaturedOffersCount >=
                              seller.CurrentPlan.Plan.MaxOffersHighlights
                            )
                              return;
                            disableOffer(offerId);
                          }}
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <OfferSliderWrapper
                        style={{width: '367px', marginTop: '6px'}}
                      >
                        <p>Automatyczne odnawianie</p>
                        <OfferSlider
                          isHighlighted={autoRenewalEnabled}
                          onClick={() =>
                            handleAutoRenewalEnabledChange(!autoRenewalEnabled)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <OfferDisplayData style={{marginTop: '6px'}}>
                        <p style={{paddingLeft: '7px'}}>Aktywna do</p>
                        <h5>{getFormattedDate(availabilityEndDate)}</h5>
                      </OfferDisplayData>
                    </>
                  ) : offerId &&
                    productDisplayStatus === 7 &&
                    !canBeRepublished ? (
                    <>
                      <OfferSliderWrapper>
                        <p>Oferta wyróżniona</p>
                        <OfferSlider
                          isHighlighted={
                            offerCount.FeaturedOffersCount >=
                            seller.CurrentPlan.Plan.MaxOffersHighlights
                              ? false
                              : highlightOffer
                          }
                          onClick={() => {
                            handleHighlightOfferChange(!highlightOffer);
                            if (
                              offerCount.FeaturedOffersCount >=
                              seller.CurrentPlan.Plan.MaxOffersHighlights
                            )
                              return;
                            disableOffer(offerId);
                          }}
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <OfferSliderWrapper style={{marginTop: '6px'}}>
                        <p>Automatyczne odnawianie</p>
                        <OfferSlider
                          isHighlighted={autoRenewalEnabled}
                          onClick={() =>
                            handleAutoRenewalEnabledChange(!autoRenewalEnabled)
                          }
                        >
                          <div />
                        </OfferSlider>
                      </OfferSliderWrapper>
                      <SelectView
                        isEditing={true}
                        style={{width: '367px', marginTop: '6px'}}
                      >
                        <p>Wystaw ofertę na (dni)</p>
                        <select
                          placeholder="Wybierz"
                          value={availibityDuration}
                          onChange={handleAvailibityDurationChange}
                        >
                          <option value="7">7</option>
                          <option value="14">14</option>
                          <option value="21">21</option>
                        </select>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="6.439"
                          height="11"
                          viewBox="0 0 6.439 11"
                        >
                          <g
                            id="Arrow_Top_Bar_Accent"
                            data-name="Arrow – Top Bar / Accent"
                            transform="translate(6.439 11) rotate(180)"
                          >
                            <path
                              id="Arrow"
                              d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                              transform="translate(0 0)"
                              fill="#3986ef"
                            />
                          </g>
                        </svg>
                      </SelectView>
                    </>
                  ) : (
                    <>
                      <OfferDisplayData>
                        <p>Oferta promowana</p>
                        <h5>{highlightOffer ? 'Tak' : 'Nie'}</h5>
                      </OfferDisplayData>
                      <OfferDisplayData>
                        <p>Automatyczne odnawianie</p>
                        <h5>{autoRenewalEnabled ? 'Tak' : 'Nie'}</h5>
                      </OfferDisplayData>
                      <OfferDisplayData>
                        <p>Aktywna do</p>
                        <h5>{getFormattedDate(availabilityEndDate)}</h5>
                      </OfferDisplayData>
                    </>
                  )}
                </MobileAvailibityDurationWrapper>
                <div style={{width: '312px', margin: '0 auto'}}>
                  {!offerId ? (
                    <PublishOfferButton
                      isReadyForOffer={isReadyForOffer}
                      onClick={() => {
                        if (!isReadyForOffer) return;
                        if (
                          productType === 4 &&
                          (offerCount.ServicesOffersCount >=
                            seller.CurrentPlan.Plan.MaxServiceOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType === 8 &&
                          (offerCount.TracksOffersCount >=
                            seller.CurrentPlan.Plan.MaxTrackOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType === 1 &&
                          (offerCount.DigitalProductsOffersCount >=
                            seller.CurrentPlan.Plan.MaxDigitalOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        }
                        // if (
                        //   offerCount.TotalOffersCount >=
                        //     seller.CurrentPlan.Plan.MaxActiveOffers ||
                        //   !isReadyForOffer
                        // )
                        //   return Alert('Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.', 'Komunikat');
                        createOffer();
                      }}
                    >
                      Opublikuj
                    </PublishOfferButton>
                  ) : offerId && new Date(availabilityEndDate) < new Date() ? (
                    <PublishOfferButton
                      isReadyForOffer={isReadyForOffer}
                      onClick={() => {
                        if (!isReadyForOffer) return;
                        if (
                          productType === 4 &&
                          (offerCount.ServicesOffersCount >=
                            seller.CurrentPlan.Plan.MaxServiceOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType === 8 &&
                          (offerCount.TracksOffersCount >=
                            seller.CurrentPlan.Plan.MaxTrackOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType === 1 &&
                          (offerCount.DigitalProductsOffersCount >=
                            seller.CurrentPlan.Plan.MaxDigitalOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        }
                        createOffer();
                      }}
                    >
                      Opublikuj
                    </PublishOfferButton>
                  ) : offerId &&
                    productDisplayStatus === 7 &&
                    canBeRepublished ? (
                    <PublishOfferButton
                      isReadyForOffer={true}
                      onClick={() => restoreOffer(offerId)}
                    >
                      Wznów ofertę
                    </PublishOfferButton>
                  ) : offerId &&
                    productDisplayStatus === 7 &&
                    !canBeRepublished ? (
                    <PublishOfferButton
                      isReadyForOffer={isReadyForOffer}
                      onClick={() => {
                        if (!isReadyForOffer) return;
                        if (
                          productType === 4 &&
                          (offerCount.TotalOffersCount >=
                            seller.CurrentPlan.Plan.MaxServiceOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        } else if (
                          productType !== 4 &&
                          (offerCount.TotalOffersCount >=
                            seller.CurrentPlan.Plan.MaxDigitalOffers ||
                            !isReadyForOffer)
                        ) {
                          return Alert(
                            'Twój plan nie wspiera większej liczby ofert, zmień plan lub wycofaj aktywną ofertę.',
                            'Komunikat',
                          );
                        }
                        createOffer();
                      }}
                    >
                      Opublikuj
                    </PublishOfferButton>
                  ) : (
                    <PublishOfferButton
                      isReadyForOffer={true}
                      onClick={() => deactivateOffer(offerId)}
                    >
                      Wycofaj ofertę
                    </PublishOfferButton>
                  )}
                </div>
              </MobileOfferSectionWrapper>
            )}

            <MobileProductDataWrapper>
              <MobileHeaderWithButtonWrapper>
                <h1>Dane podstawowe</h1>
                {createNewProductStep === 0 &&
                productDisplayStatus !== 3 &&
                productDisplayStatus !== 4 &&
                productDisplayStatus !== 6 &&
                isProductEditing ? (
                  <PopupButton
                    buttons={['Kontynuuj', 'Zamknij']}
                    title={'Uwaga'}
                    text={`
                         Czy na pewno chcesz zamknąć edycje? Wszystkie zmiany nie zostaną zapisane.
                        `}
                    onClick={() => {
                      getSingleProduct(singleProductId);
                      setIsProductEditing(false);
                    }}
                  >
                    <EditBtnWrapper
                      style={{top: '-12px'}}
                      isEditing={isProductEditing}
                    >
                      <EditSvgWrapper isEditing={isProductEditing}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.27"
                          height="16.658"
                          viewBox="0 0 17.27 16.658"
                        >
                          <g
                            id="Icon_feather-edit-3"
                            data-name="Icon feather-edit-3"
                            transform="translate(-3.75 -3.41)"
                          >
                            <path
                              id="Path_3712"
                              data-name="Path 3712"
                              d="M18,30h7.885"
                              transform="translate(-5.615 -10.682)"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3713"
                              data-name="Path 3713"
                              d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </EditSvgWrapper>
                    </EditBtnWrapper>
                  </PopupButton>
                ) : (
                  <EditBtnWrapper
                    style={{top: '0px'}}
                    isEditing={isProductEditing}
                    onClick={() => {
                      if (
                        createNewProductStep === 0 &&
                        !isConditionEditing &&
                        productDisplayStatus !== 3 &&
                        productDisplayStatus !== 4 &&
                        productDisplayStatus !== 6
                      ) {
                        handleIsProductEditingChange(!isProductEditing);
                      } else if (
                        productDisplayStatus === 3 ||
                        productDisplayStatus === 4 ||
                        productDisplayStatus === 6
                      ) {
                        Alert('W celu edycji wycofaj ofertę.', 'Komunikat');
                      }
                    }}
                  >
                    <EditSvgWrapper isEditing={isProductEditing}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.27"
                        height="16.658"
                        viewBox="0 0 17.27 16.658"
                      >
                        <g
                          id="Icon_feather-edit-3"
                          data-name="Icon feather-edit-3"
                          transform="translate(-3.75 -3.41)"
                        >
                          <path
                            id="Path_3712"
                            data-name="Path 3712"
                            d="M18,30h7.885"
                            transform="translate(-5.615 -10.682)"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3713"
                            data-name="Path 3713"
                            d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                            fill="none"
                            stroke="rgba(7,2,15,0.7)"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </EditSvgWrapper>
                  </EditBtnWrapper>
                )}
              </MobileHeaderWithButtonWrapper>
              {isProductEditing && productType !== 8 ? (
                <>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <InputView
                      isError={errorVerification.nameError !== null}
                      isEditing={true}
                      style={{marginTop: 'unset'}}
                    >
                      <p>Nazwa</p>
                      <input
                        type="text"
                        value={name}
                        maxLength={50}
                        readOnly={!isProductEditing}
                        onChange={handleNameChange}
                        placeholder="Pole obowiązkowe"
                      />
                      {errorVerification.nameError !== null && (
                        <ErrorStar text={errorVerification.nameError} />
                      )}
                    </InputView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Kategoria</p>
                      <select
                        value={primaryCategory}
                        onChange={handlePrimaryCategoryChange}
                        disabled={
                          productCategories.filter(
                            (category) => category.ParentCategoryId === null,
                          ).length === 0
                        }
                      >
                        <option value="">Kategoria</option>
                        {productCategories
                          .filter(
                            (category) => category.ParentCategoryId === null,
                          )
                          .map((category) => {
                            const {Id, Name} = category;

                            return (
                              <option value={Id} key={Id}>
                                {Name}
                              </option>
                            );
                          })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Podkategoria</p>
                      <select
                        value={secondaryCategory}
                        onChange={handleSecondaryCategoryChange}
                        disabled={
                          productCategories.filter(
                            (category) =>
                              category.ParentCategoryId === primaryCategory,
                          ).length === 0
                        }
                      >
                        <option value="">Podkategoria</option>
                        {productCategories
                          .filter(
                            (category) =>
                              category.ParentCategoryId === primaryCategory,
                          )
                          .map((category) => {
                            const {Id, Name} = category;

                            return (
                              <option value={Id} key={Id}>
                                {Name}
                              </option>
                            );
                          })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Subpodkategoria</p>
                      <select
                        value={tertiaryCategory}
                        onChange={handleTertiaryCategoryChange}
                        disabled={
                          productCategories.filter(
                            (category) =>
                              category.ParentCategoryId === secondaryCategory,
                          ).length === 0
                        }
                      >
                        <option value="">Subpodkategoria</option>
                        {productCategories
                          .filter(
                            (category) =>
                              category.ParentCategoryId === secondaryCategory,
                          )
                          .map((category) => {
                            const {Id, Name} = category;

                            return (
                              <option value={Id} key={Id}>
                                {Name}
                              </option>
                            );
                          })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>

                  <ProductDescriptionWrapper
                    style={{width: '358px'}}
                    isError={errorVerification.descriptionError !== null}
                  >
                    <h5>Opis</h5>
                    <textarea
                      maxLength={250}
                      value={description}
                      readOnly={!isProductEditing}
                      onChange={handleDescriptionChange}
                      placeholder="Uzupełnij"
                    />
                    {errorVerification.descriptionError !== null && (
                      <ErrorStar text={errorVerification.descriptionError} />
                    )}
                    <p>{description.length}/250</p>
                  </ProductDescriptionWrapper>
                </>
              ) : !isProductEditing && productType !== 8 ? (
                <>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <InputView
                      isError={errorVerification.nameError !== null}
                      isEditing={false}
                      style={{marginTop: 'unset'}}
                    >
                      <p>Nazwa</p>
                      <input
                        type="text"
                        value={name}
                        maxLength={50}
                        readOnly={!isProductEditing}
                        onChange={handleNameChange}
                        placeholder="Pole obowiązkowe"
                      />
                      {errorVerification.nameError !== null && (
                        <ErrorStar text={errorVerification.nameError} />
                      )}
                    </InputView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Kategoria</p>
                      <select
                        value={primaryCategory}
                        onChange={handlePrimaryCategoryChange}
                        disabled={
                          productCategories.filter(
                            (category) => category.ParentCategoryId === null,
                          ).length === 0
                        }
                      >
                        <option value="">Kategoria</option>
                        {productCategories
                          .filter(
                            (category) => category.ParentCategoryId === null,
                          )
                          .map((category) => {
                            const {Id, Name} = category;

                            return (
                              <option value={Id} key={Id}>
                                {Name}
                              </option>
                            );
                          })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Podkategoria</p>
                      <select
                        value={secondaryCategory}
                        onChange={handleSecondaryCategoryChange}
                        disabled={
                          productCategories.filter(
                            (category) =>
                              category.ParentCategoryId === primaryCategory,
                          ).length === 0
                        }
                      >
                        <option value="">Podkategoria</option>
                        {productCategories
                          .filter(
                            (category) =>
                              category.ParentCategoryId === primaryCategory,
                          )
                          .map((category) => {
                            const {Id, Name} = category;

                            return (
                              <option value={Id} key={Id}>
                                {Name}
                              </option>
                            );
                          })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Subpodkategoria</p>
                      <select
                        value={tertiaryCategory}
                        onChange={handleTertiaryCategoryChange}
                        disabled={
                          productCategories.filter(
                            (category) =>
                              category.ParentCategoryId === secondaryCategory,
                          ).length === 0
                        }
                      >
                        <option value="">Subpodkategoria</option>
                        {productCategories
                          .filter(
                            (category) =>
                              category.ParentCategoryId === secondaryCategory,
                          )
                          .map((category) => {
                            const {Id, Name} = category;

                            return (
                              <option value={Id} key={Id}>
                                {Name}
                              </option>
                            );
                          })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <MobileDisplayDescriptionWrapper>
                    <h5>Opis</h5>
                    <p>{description}</p>
                  </MobileDisplayDescriptionWrapper>
                </>
              ) : isProductEditing && productType === 8 ? (
                <>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <InputView
                      isError={errorVerification.nameError !== null}
                      isEditing={true}
                      style={{marginTop: 'unset'}}
                    >
                      <p>Nazwa</p>
                      <input
                        type="text"
                        value={name}
                        maxLength={50}
                        readOnly={!isProductEditing}
                        onChange={handleNameChange}
                        placeholder="Pole obowiązkowe"
                      />
                      {errorVerification.nameError !== null && (
                        <ErrorStar text={errorVerification.nameError} />
                      )}
                    </InputView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Kategoria</p>
                      <select
                        value={primaryCategory}
                        onChange={handlePrimaryCategoryChange}
                        disabled={
                          productCategories.filter(
                            (category) => category.ParentCategoryId === null,
                          ).length === 0
                        }
                      >
                        <option value="">Kategoria</option>
                        {productCategories
                          .filter(
                            (category) => category.ParentCategoryId === null,
                          )
                          .map((category) => {
                            const {Id, Name} = category;

                            return (
                              <option value={Id} key={Id}>
                                {Name}
                              </option>
                            );
                          })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Podkategoria</p>
                      <select
                        value={secondaryCategory}
                        onChange={handleSecondaryCategoryChange}
                        disabled={
                          productCategories.filter(
                            (category) =>
                              category.ParentCategoryId === primaryCategory,
                          ).length === 0
                        }
                      >
                        <option value="">Podkategoria</option>
                        {productCategories
                          .filter(
                            (category) =>
                              category.ParentCategoryId === primaryCategory,
                          )
                          .map((category) => {
                            const {Id, Name} = category;

                            return (
                              <option value={Id} key={Id}>
                                {Name}
                              </option>
                            );
                          })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Nastrój</p>
                      <select value={vibe} onChange={handleVibeChange}>
                        <option value="">Nastrój</option>
                        {productAttributes.length > 0 &&
                          productAttributes
                            .filter(
                              (productAttribute) =>
                                productAttribute.Id ===
                                'f65268e6-c5f9-4f82-9203-68c673cbab08',
                            )[0]
                            .AttributeValues.map((attributeValue) => {
                              const {Id, Name} = attributeValue;

                              return (
                                <option value={Id} key={Id}>
                                  {Name}
                                </option>
                              );
                            })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Tempo</p>
                      <select value={tempo} onChange={handleTempoChange}>
                        <option value="">Tempo</option>
                        {productAttributes.length > 0 &&
                          productAttributes
                            .filter(
                              (productAttribute) =>
                                productAttribute.Id ===
                                'f65268e6-c5f9-4f82-9203-68c673cbab06',
                            )[0]
                            .AttributeValues.map((attributeValue) => {
                              const {Id, Name} = attributeValue;

                              return (
                                <option value={Id} key={Id}>
                                  {Name}
                                </option>
                              );
                            })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Tonacja</p>
                      <select value={tone} onChange={handleToneChange}>
                        <option value="">Tonacja</option>
                        {productAttributes.length > 0 &&
                          productAttributes
                            .filter(
                              (productAttribute) =>
                                productAttribute.Id ===
                                'f65268e6-c5f9-4f82-9203-68c673cbab07',
                            )[0]
                            .AttributeValues.map((attributeValue) => {
                              const {Id, Name} = attributeValue;

                              return (
                                <option value={Id} key={Id}>
                                  {Name}
                                </option>
                              );
                            })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <ProductDescriptionWrapper
                    style={{width: '358px'}}
                    isError={errorVerification.descriptionError !== null}
                  >
                    <h5>Opis</h5>
                    <textarea
                      maxLength={250}
                      value={description}
                      readOnly={!isProductEditing}
                      onChange={handleDescriptionChange}
                      placeholder="Uzupełnij"
                    />
                    {errorVerification.descriptionError !== null && (
                      <ErrorStar text={errorVerification.descriptionError} />
                    )}
                    <p>{description.length}/250</p>
                  </ProductDescriptionWrapper>
                </>
              ) : (
                <>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <InputView
                      isError={errorVerification.nameError !== null}
                      isEditing={false}
                      style={{marginTop: 'unset'}}
                    >
                      <p>Nazwa</p>
                      <input
                        type="text"
                        value={name}
                        maxLength={50}
                        readOnly={!isProductEditing}
                        onChange={handleNameChange}
                        placeholder="Pole obowiązkowe"
                      />
                      {errorVerification.nameError !== null && (
                        <ErrorStar text={errorVerification.nameError} />
                      )}
                    </InputView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Kategoria</p>
                      <select
                        value={primaryCategory}
                        onChange={handlePrimaryCategoryChange}
                        disabled={
                          productCategories.filter(
                            (category) => category.ParentCategoryId === null,
                          ).length === 0
                        }
                      >
                        <option value="">Kategoria</option>
                        {productCategories
                          .filter(
                            (category) => category.ParentCategoryId === null,
                          )
                          .map((category) => {
                            const {Id, Name} = category;

                            return (
                              <option value={Id} key={Id}>
                                {Name}
                              </option>
                            );
                          })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Podkategoria</p>
                      <select
                        value={secondaryCategory}
                        onChange={handleSecondaryCategoryChange}
                        disabled={
                          productCategories.filter(
                            (category) =>
                              category.ParentCategoryId === primaryCategory,
                          ).length === 0
                        }
                      >
                        <option value="">Podkategoria</option>
                        {productCategories
                          .filter(
                            (category) =>
                              category.ParentCategoryId === primaryCategory,
                          )
                          .map((category) => {
                            const {Id, Name} = category;

                            return (
                              <option value={Id} key={Id}>
                                {Name}
                              </option>
                            );
                          })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Nastrój</p>
                      <select value={vibe} onChange={handleVibeChange}>
                        <option value="">Nastrój</option>
                        {productAttributes.length > 0 &&
                          productAttributes
                            .filter(
                              (productAttribute) =>
                                productAttribute.Id ===
                                'f65268e6-c5f9-4f82-9203-68c673cbab08',
                            )[0]
                            .AttributeValues.map((attributeValue) => {
                              const {Id, Name} = attributeValue;

                              return (
                                <option value={Id} key={Id}>
                                  {Name}
                                </option>
                              );
                            })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Tempo</p>
                      <select value={tempo} onChange={handleTempoChange}>
                        <option value="">Tempo</option>
                        {productAttributes.length > 0 &&
                          productAttributes
                            .filter(
                              (productAttribute) =>
                                productAttribute.Id ===
                                'f65268e6-c5f9-4f82-9203-68c673cbab06',
                            )[0]
                            .AttributeValues.map((attributeValue) => {
                              const {Id, Name} = attributeValue;

                              return (
                                <option value={Id} key={Id}>
                                  {Name}
                                </option>
                              );
                            })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <UserDataSelectViewContainer isEditing={isProductEditing}>
                    <SelectView isEditing={isProductEditing}>
                      <p>Tonacja</p>
                      <select value={tone} onChange={handleToneChange}>
                        <option value="">Tonacja</option>
                        {productAttributes.length > 0 &&
                          productAttributes
                            .filter(
                              (productAttribute) =>
                                productAttribute.Id ===
                                'f65268e6-c5f9-4f82-9203-68c673cbab07',
                            )[0]
                            .AttributeValues.map((attributeValue) => {
                              const {Id, Name} = attributeValue;

                              return (
                                <option value={Id} key={Id}>
                                  {Name}
                                </option>
                              );
                            })}
                      </select>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="6.439"
                        height="11"
                        viewBox="0 0 6.439 11"
                      >
                        <g
                          id="Arrow_Top_Bar_Accent"
                          data-name="Arrow – Top Bar / Accent"
                          transform="translate(6.439 11) rotate(180)"
                        >
                          <path
                            id="Arrow"
                            d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                            transform="translate(0 0)"
                            fill="#3986ef"
                          />
                        </g>
                      </svg>
                    </SelectView>
                  </UserDataSelectViewContainer>
                  <MobileDisplayDescriptionWrapper>
                    <h5>Opis</h5>
                    <p>{description}</p>
                  </MobileDisplayDescriptionWrapper>
                </>
              )}

              <MobileProductImageWrapper>
                {isProductEditing &&
                files.filter((file) => file.ProductFileType === 0).length >
                  0 ? (
                  <>
                    <img
                      src={
                        files.filter(
                          (file) => file.ProductFileType === 0 && file.FilePath,
                        ).length > 0
                          ? files.filter(
                              (file) => file.ProductFileType === 0,
                            )[0].FilePath
                          : `data:image/png;base64,${
                              files.filter(
                                (file) => file.ProductFileType === 0,
                              )[0].File
                            }`
                      }
                    />
                    <DeleteFileButton
                      onClick={() => {
                        if (
                          files.filter(
                            (file) => file.ProductFileType === 0 && file.Id,
                          ).length > 0
                        ) {
                          deleteExistingFile(
                            files.filter(
                              (file) => file.ProductFileType === 0 && file.Id,
                            )[0],
                          );
                        } else if (
                          files.filter(
                            (file) => file.ProductFileType === 0 && !file.Id,
                          ).length > 0
                        ) {
                          deleteNewFile(
                            files.filter(
                              (file) => file.ProductFileType === 0 && !file.Id,
                            )[0],
                          );
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10.9"
                        height="12"
                        viewBox="0 0 10.9 12"
                      >
                        <g
                          id="Icon_feather-trash"
                          data-name="Icon feather-trash"
                          transform="translate(0.5 0.5)"
                        >
                          <path
                            id="Path_3663"
                            data-name="Path 3663"
                            d="M4.5,9h9.9"
                            transform="translate(-4.5 -6.8)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                          <path
                            id="Path_3664"
                            data-name="Path 3664"
                            d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                            transform="translate(-6.4 -3)"
                            fill="none"
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1"
                          />
                        </g>
                      </svg>
                    </DeleteFileButton>
                  </>
                ) : isProductEditing &&
                  files.filter((file) => file.ProductFileType === 0).length ===
                    0 ? (
                  <>
                    <MobileGenerateImageButton onClick={() => generateImage()}>
                      Wygeneruj
                    </MobileGenerateImageButton>
                    <MobileTrackPlaceholderSvgWrapper>
                      {productType === 8 ? (
                        <TrackPlaceholderSvg />
                      ) : productType === 4 ? (
                        <ServicePlaceholderSvg />
                      ) : (
                        <DigitalProductPlaceholderSvg />
                      )}
                    </MobileTrackPlaceholderSvgWrapper>
                    <MobileAddTagButton
                      onClick={() => {
                        if (isProductEditing) {
                          coverFileRef.current.click();
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19.817"
                        height="19.636"
                        viewBox="0 0 19.817 19.636"
                      >
                        <path
                          id="Path_3683"
                          data-name="Path 3683"
                          d="M190.713,109.222l-8.19.176.143-8.122a.893.893,0,1,0-1.776,0l.143,8.122-8.189-.176a.892.892,0,1,0,0,1.776l8.189-.176-.143,8.121a.893.893,0,1,0,1.776,0l-.143-8.121,8.19.176a.892.892,0,1,0,0-1.776Z"
                          transform="translate(-171.869 -100.292)"
                          fill="#fff"
                        />
                      </svg>
                    </MobileAddTagButton>
                    <input
                      type={'file'}
                      hidden
                      onChange={getCoverFile}
                      accept=".jpeg,.jpg,.png"
                      ref={coverFileRef}
                    />
                  </>
                ) : !isProductEditing &&
                  files.filter((file) => file.ProductFileType === 0).length >
                    0 ? (
                  <img
                    src={
                      files.filter(
                        (file) => file.ProductFileType === 0 && file.FilePath,
                      ).length > 0
                        ? files.filter((file) => file.ProductFileType === 0)[0]
                            .FilePath
                        : `data:image/png;base64,${
                            files.filter(
                              (file) => file.ProductFileType === 0,
                            )[0].File
                          }`
                    }
                  />
                ) : (
                  <MobileTrackPlaceholderSvgWrapper>
                    {productType === 8 ? (
                      <TrackPlaceholderSvg />
                    ) : productType === 4 ? (
                      <ServicePlaceholderSvg />
                    ) : (
                      <DigitalProductPlaceholderSvg />
                    )}
                  </MobileTrackPlaceholderSvgWrapper>
                )}
              </MobileProductImageWrapper>
              {productType === 4 &&
                longDescriptionOrGallery === '' &&
                isProductEditing && (
                  <ServiceTypeSelectWrapper>
                    <p>Zawartość dodatkowa</p>
                    <ServiceButtonsWrapper>
                      <ServiceButton
                        onClick={() => setLongDescriptionOrGallery('gallery')}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36.332"
                          height="36"
                          viewBox="0 0 36.332 36"
                        >
                          <path
                            id="Path_3993"
                            data-name="Path 3993"
                            d="M206.416,116.664l-15.015.323.262-14.89a1.636,1.636,0,1,0-3.255,0l.261,14.89-15.014-.323a1.635,1.635,0,1,0,0,3.255l15.014-.323-.261,14.888a1.637,1.637,0,1,0,3.255,0L191.4,119.6l15.015.323a1.635,1.635,0,1,0,0-3.255Z"
                            transform="translate(-171.869 -100.292)"
                            fill="rgba(7,2,15,0.7)"
                          />
                        </svg>
                        <p>Galeria obrazów</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                        >
                          <path
                            id="Path_4272"
                            data-name="Path 4272"
                            d="M36,11.5v21A3.5,3.5,0,0,1,32.5,36h-21A3.5,3.5,0,0,1,8,32.5v-21A3.5,3.5,0,0,1,11.5,8h21A3.5,3.5,0,0,1,36,11.5Zm-3.5,0h-21V22.665l3.832-3.833,9.153,9.153,1.75-1.75a2.782,2.782,0,0,1,3.693.192L32.5,29Zm-8.75,6.125a2.625,2.625,0,1,1,2.625,2.625A2.621,2.621,0,0,1,23.75,17.625Z"
                            transform="translate(-8 -8)"
                            fill="#2b2b2b"
                          />
                        </svg>
                      </ServiceButton>
                      <ServiceButton
                        onClick={() =>
                          setLongDescriptionOrGallery('longDescription')
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="36.332"
                          height="36"
                          viewBox="0 0 36.332 36"
                        >
                          <path
                            id="Path_3993"
                            data-name="Path 3993"
                            d="M206.416,116.664l-15.015.323.262-14.89a1.636,1.636,0,1,0-3.255,0l.261,14.89-15.014-.323a1.635,1.635,0,1,0,0,3.255l15.014-.323-.261,14.888a1.637,1.637,0,1,0,3.255,0L191.4,119.6l15.015.323a1.635,1.635,0,1,0,0-3.255Z"
                            transform="translate(-171.869 -100.292)"
                            fill="rgba(7,2,15,0.7)"
                          />
                        </svg>
                        <p>Dodatkowy opis</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="30"
                          viewBox="0 0 26 30"
                        >
                          <g
                            id="Group_8127"
                            data-name="Group 8127"
                            transform="translate(-13823.2 -4400)"
                          >
                            <path
                              id="Path_4277"
                              data-name="Path 4277"
                              d="M10.2,13.994h-1v2h1Zm12,2h1v-2h-1ZM10.2,20h-1v2h1Zm12,2h1V20h-1ZM10.2,7.994h-1v2h1Zm12,2h1v-2h-1ZM28.2,7h1V6.587l-.294-.294Zm-6-6,.706-.706L22.613,0H22.2Zm-12,14.994h12v-2h-12ZM10.2,22h12V20h-12Zm0-12.006h12v-2h-12ZM26.2,28H6.2v2h20Zm-21-1V3h-2V27Zm22-20V27h2V7ZM6.2,2h16V0H6.2Zm15.294-.294,6,6,1.413-1.413-6-6ZM6.2,28a1,1,0,0,1-1-1h-2a3,3,0,0,0,3,3Zm20,2a3,3,0,0,0,3-3h-2a1,1,0,0,1-1,1ZM5.2,3a1,1,0,0,1,1-1V0a3,3,0,0,0-3,3Z"
                              transform="translate(13820 4400)"
                              fill="#2b2b2b"
                            />
                          </g>
                        </svg>
                      </ServiceButton>
                    </ServiceButtonsWrapper>
                  </ServiceTypeSelectWrapper>
                )}
              {productType !== 8 &&
                productType !== 1 &&
                longDescriptionOrGallery === 'longDescription' && (
                  <ProductAdditionalDescriptionWrapper
                    isEditing={isProductEditing}
                    style={
                      !isProductEditing && !longDescription
                        ? {display: 'none'}
                        : {width: '367px'}
                    }
                  >
                    {isProductEditing ? (
                      <>
                        <ClearServiceSelectButton
                          onClick={() => {
                            setLongDescriptionOrGallery('');
                            setLongDescription('');
                            setFiles(
                              files.filter(
                                (item) => item.ProductFileType !== 1,
                              ),
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <g
                              id="Group_4357"
                              data-name="Group 4357"
                              transform="translate(-40 -578.5)"
                            >
                              <rect
                                id="Rectangle_2518"
                                data-name="Rectangle 2518"
                                width="19"
                                height="19"
                                rx="4"
                                transform="translate(40 578.5)"
                                fill="#ff0300"
                              />
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(44.5 582)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9h9.9"
                                  transform="translate(-4.5 -6.8)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                  transform="translate(-6.4 -3)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                              </g>
                            </g>
                          </svg>
                        </ClearServiceSelectButton>
                        <h5>Dodatkowy opis</h5>
                        <textarea
                          placeholder="Pole opcjonalne"
                          maxLength={800}
                          value={longDescription}
                          readOnly={!isProductEditing}
                          onChange={handleLongDescriptionChange}
                        />
                      </>
                    ) : (
                      <h4>{longDescription}</h4>
                    )}
                  </ProductAdditionalDescriptionWrapper>
                )}
              {productType === 1 && (
                <DigitalFilesWrapper>
                  <DigitalFileWithTitleWrapper>
                    <h1>Dodatkowa galeria</h1>
                    <p>
                      Dodaj dodatkowe materiały graficzne, które pozwolą lepiej
                      oddać charakter produktu. Np. wizualizacje, przybliżenia
                      na detal, warianty kolorystyczne.
                    </p>
                    <h5 style={{marginTop: '10px'}}>
                      Format pliku: <span>JPG, PNG, GIF</span>
                    </h5>
                    <h5>
                      Rozmiar: <span>maks. 2MB</span>
                    </h5>
                  </DigitalFileWithTitleWrapper>
                  <DigitalFileWithTitleWrapper>
                    <h1>Wersja finalna produktu</h1>
                    {isProductEditing &&
                    files.filter((file) => file.ProductFileType === 2).length >
                      0 ? (
                      files
                        .filter((file, i) => file.ProductFileType === 2)
                        .map((file, i) => {
                          const {File, Name, Id} = file;

                          if (i > 0) return;

                          return (
                            <TrackFileWrapper key={Id}>
                              <h5>Wgrany plik finalny</h5>
                              <FileNameWithSvgSpan>
                                <p>{Name}</p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="36"
                                  height="24"
                                  viewBox="0 0 36 24"
                                >
                                  <path
                                    id="Icon_material-cloud-download"
                                    data-name="Icon material-cloud-download"
                                    d="M29.025,15.06a11.239,11.239,0,0,0-21-3A9,9,0,0,0,9,30H28.5a7.478,7.478,0,0,0,.525-14.94ZM25.5,19.5,18,27l-7.5-7.5H15v-6h6v6Z"
                                    transform="translate(0 -6)"
                                    fill="#0091ff"
                                  />
                                </svg>
                              </FileNameWithSvgSpan>
                              <DeleteFileButton
                                onClick={() => {
                                  if (
                                    files.filter(
                                      (file) =>
                                        file.ProductFileType === 2 && file.Id,
                                    ).length > 0
                                  ) {
                                    deleteExistingFile(
                                      files.filter(
                                        (file) =>
                                          file.ProductFileType === 2 && file.Id,
                                      )[0],
                                    );
                                  } else if (
                                    files.filter(
                                      (file) =>
                                        file.ProductFileType === 2 && !file.Id,
                                    ).length > 0
                                  ) {
                                    deleteNewFile(
                                      files.filter(
                                        (file) =>
                                          file.ProductFileType === 2 &&
                                          !file.Id,
                                      )[0],
                                    );
                                  }
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10.9"
                                  height="12"
                                  viewBox="0 0 10.9 12"
                                >
                                  <g
                                    id="Icon_feather-trash"
                                    data-name="Icon feather-trash"
                                    transform="translate(0.5 0.5)"
                                  >
                                    <path
                                      id="Path_3663"
                                      data-name="Path 3663"
                                      d="M4.5,9h9.9"
                                      transform="translate(-4.5 -6.8)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_3664"
                                      data-name="Path 3664"
                                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                      transform="translate(-6.4 -3)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </svg>
                              </DeleteFileButton>
                            </TrackFileWrapper>
                          );
                        })
                    ) : isProductEditing ? (
                      <FileWrapper>
                        <p>
                          Format pliku: <span>Wszystkie</span>
                        </p>
                        <p>
                          Rozmiar: maks. <span>150MB</span>
                        </p>
                        <AddFileButton
                          onClick={() => productFileRef.current.click()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.138"
                            height="15"
                            viewBox="0 0 15.138 15"
                          >
                            <path
                              id="Path_3683"
                              data-name="Path 3683"
                              d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                              transform="translate(-171.869 -100.292)"
                              fill="#fff"
                            />
                          </svg>
                        </AddFileButton>
                        <input
                          type={'file'}
                          hidden
                          ref={productFileRef}
                          // accept={
                          //   '.jpeg,.jpg,.png,.mp4,.pdf,.ico,.rar,.rtf,.srt,.flac,.wav,.mp3,.xlsx,.zip'
                          // }
                          onChange={getProductFile}
                        />
                      </FileWrapper>
                    ) : !isProductEditing &&
                      files.filter((file) => file.ProductFileType === 2)
                        .length > 0 ? (
                      files
                        .filter((file) => file.ProductFileType === 2)
                        .map((file, i) => {
                          const {File, Name, Id} = file;

                          if (i > 0) return;

                          return (
                            <TrackFileWrapper key={Id}>
                              <h5>Wgrany plik finalny</h5>
                              <FileNameWithSvgSpan>
                                <p>{Name}</p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="36"
                                  height="24"
                                  viewBox="0 0 36 24"
                                >
                                  <path
                                    id="Icon_material-cloud-download"
                                    data-name="Icon material-cloud-download"
                                    d="M29.025,15.06a11.239,11.239,0,0,0-21-3A9,9,0,0,0,9,30H28.5a7.478,7.478,0,0,0,.525-14.94ZM25.5,19.5,18,27l-7.5-7.5H15v-6h6v6Z"
                                    transform="translate(0 -6)"
                                    fill="#0091ff"
                                  />
                                </svg>
                              </FileNameWithSvgSpan>
                            </TrackFileWrapper>
                          );
                        })
                    ) : (
                      <NoFileWrapper>
                        <h5>Brak pliku</h5>
                      </NoFileWrapper>
                    )}
                  </DigitalFileWithTitleWrapper>
                </DigitalFilesWrapper>
              )}
              {productType === 4 && longDescriptionOrGallery === 'gallery' && (
                <ServiceGalleryWrapper>
                  <ServiceGalleryHeader>
                    <p>Galeria obrazów</p>
                    {productType === 4 &&
                      longDescriptionOrGallery === 'gallery' &&
                      isProductEditing && (
                        <ClearServiceSelectButton
                          style={{top: '0px', right: '0px'}}
                          onClick={() => {
                            setLongDescriptionOrGallery('');
                            setLongDescription('');
                            setFiles(
                              files.filter(
                                (item) => item.ProductFileType !== 1,
                              ),
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            viewBox="0 0 19 19"
                          >
                            <g
                              id="Group_4357"
                              data-name="Group 4357"
                              transform="translate(-40 -578.5)"
                            >
                              <rect
                                id="Rectangle_2518"
                                data-name="Rectangle 2518"
                                width="19"
                                height="19"
                                rx="4"
                                transform="translate(40 578.5)"
                                fill="#ff0300"
                              />
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(44.5 582)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9h9.9"
                                  transform="translate(-4.5 -6.8)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                  transform="translate(-6.4 -3)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                              </g>
                            </g>
                          </svg>
                        </ClearServiceSelectButton>
                      )}
                  </ServiceGalleryHeader>
                  <MobileGalleryWrapper>
                    {isProductEditing && (
                      <Placeholder>
                        <svg
                          id="Group_6964"
                          data-name="Group 6964"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="47"
                          height="48.847"
                          viewBox="0 0 47 48.847"
                        >
                          <g
                            id="Group_6456"
                            data-name="Group 6456"
                            clipPath="url(#clip-path)"
                          >
                            <path
                              id="Path_4016"
                              data-name="Path 4016"
                              d="M72.726,0H45.142a1.017,1.017,0,0,0-1.017,1.017V28.6a1.017,1.017,0,0,0,1.017,1.017h4.044a1.017,1.017,0,1,0,0-2.034H46.159V2.034H71.708V15.613l-1.725-1.725a2.282,2.282,0,0,0-3.224,0L64.443,16.2a1.017,1.017,0,0,0,1.439,1.438L68.2,15.327a.246.246,0,0,1,.347,0L71.419,18.2a1.02,1.02,0,0,0,.29.2v9.184H56.251a1.017,1.017,0,0,0,0,2.034H72.726A1.017,1.017,0,0,0,73.743,28.6V1.017A1.017,1.017,0,0,0,72.726,0"
                              transform="translate(-26.743)"
                              fill="rgba(220,224,233,0.3)"
                            />
                            <path
                              id="Path_4017"
                              data-name="Path 4017"
                              d="M26.658,41.813H25.014l5.651-5.651a.245.245,0,0,1,.347,0L38.253,43.4a1.017,1.017,0,1,0,1.438-1.439l-7.241-7.241a2.28,2.28,0,0,0-3.224,0L22.045,41.9a1.183,1.183,0,0,0,.744,1.942H25.64V67.453H2.034V43.847H15.37a1.017,1.017,0,1,0,0-2.034H1.017A1.017,1.017,0,0,0,0,42.83V68.47a1.017,1.017,0,0,0,1.017,1.017h25.64a1.017,1.017,0,0,0,1.017-1.017V42.83a1.017,1.017,0,0,0-1.017-1.017"
                              transform="translate(0 -20.64)"
                              fill="rgba(220,224,233,0.3)"
                            />
                            <path
                              id="Path_4018"
                              data-name="Path 4018"
                              d="M24.513,72.445a2.047,2.047,0,0,0-1.088,1.812v8.776a2.051,2.051,0,0,0,3.2,1.7l6.511-4.388a2.052,2.052,0,0,0,0-3.4l-6.511-4.388a2.05,2.05,0,0,0-2.111-.11m.946,10.588V74.257l.028-.015L32,78.66Z"
                              transform="translate(-14.197 -43.761)"
                              fill="rgba(220,224,233,0.3)"
                            />
                            <path
                              id="Path_4019"
                              data-name="Path 4019"
                              d="M64.005,17.659a3.56,3.56,0,1,0-3.56,3.56,3.565,3.565,0,0,0,3.56-3.56m-5.086,0a1.526,1.526,0,1,1,1.526,1.526,1.527,1.527,0,0,1-1.526-1.526"
                              transform="translate(-34.476 -8.545)"
                              fill="rgba(220,224,233,0.3)"
                            />
                          </g>
                        </svg>
                        <input
                          type={'file'}
                          hidden
                          ref={demoFileRef}
                          accept={'.jpeg,.jpg,.png,.gif'}
                          onChange={getDemoFile}
                        />
                        <AddFileButton
                          onClick={() => demoFileRef.current.click()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.138"
                            height="15"
                            viewBox="0 0 15.138 15"
                          >
                            <path
                              id="Path_3683"
                              data-name="Path 3683"
                              d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                              transform="translate(-171.869 -100.292)"
                              fill="#fff"
                            />
                          </svg>
                        </AddFileButton>
                      </Placeholder>
                    )}
                    {images.map((file, index) => (
                      <MobileGallerySingleImage key={index}>
                        <img
                          src={
                            file.Id
                              ? file.FilePath
                              : `data:image/png;base64, ${file.File}`
                          }
                          alt={`Image ${index}`}
                        />
                        {isProductEditing && (
                          <DeleteFileButton
                            onClick={() => {
                              if (
                                files.filter(
                                  (el) =>
                                    el.ProductFileType === 1 &&
                                    el.Id &&
                                    el.Id === file.Id,
                                ).length > 0
                              ) {
                                deleteExistingFile(
                                  files.filter(
                                    (el) =>
                                      el.ProductFileType === 1 &&
                                      el.Id &&
                                      el.Id === file.Id,
                                  )[0],
                                );
                              } else if (
                                files.filter(
                                  (el) =>
                                    el.ProductFileType === 1 &&
                                    !el.Id &&
                                    el.Name === file.Name,
                                ).length > 0
                              ) {
                                deleteNewFile(
                                  files.filter(
                                    (el) =>
                                      el.ProductFileType === 1 &&
                                      !el.Id &&
                                      el.Name === file.Name,
                                  )[0],
                                );
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10.9"
                              height="12"
                              viewBox="0 0 10.9 12"
                            >
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(0.5 0.5)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9h9.9"
                                  transform="translate(-4.5 -6.8)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                  transform="translate(-6.4 -3)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                              </g>
                            </svg>
                          </DeleteFileButton>
                        )}
                      </MobileGallerySingleImage>
                    ))}
                  </MobileGalleryWrapper>
                </ServiceGalleryWrapper>
              )}

              {productType === 1 && (
                <MobileGalleryWrapper>
                  {isProductEditing && (
                    <Placeholder>
                      <svg
                        id="Group_6964"
                        data-name="Group 6964"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="47"
                        height="48.847"
                        viewBox="0 0 47 48.847"
                      >
                        <g
                          id="Group_6456"
                          data-name="Group 6456"
                          clipPath="url(#clip-path)"
                        >
                          <path
                            id="Path_4016"
                            data-name="Path 4016"
                            d="M72.726,0H45.142a1.017,1.017,0,0,0-1.017,1.017V28.6a1.017,1.017,0,0,0,1.017,1.017h4.044a1.017,1.017,0,1,0,0-2.034H46.159V2.034H71.708V15.613l-1.725-1.725a2.282,2.282,0,0,0-3.224,0L64.443,16.2a1.017,1.017,0,0,0,1.439,1.438L68.2,15.327a.246.246,0,0,1,.347,0L71.419,18.2a1.02,1.02,0,0,0,.29.2v9.184H56.251a1.017,1.017,0,0,0,0,2.034H72.726A1.017,1.017,0,0,0,73.743,28.6V1.017A1.017,1.017,0,0,0,72.726,0"
                            transform="translate(-26.743)"
                            fill="rgba(220,224,233,0.3)"
                          />
                          <path
                            id="Path_4017"
                            data-name="Path 4017"
                            d="M26.658,41.813H25.014l5.651-5.651a.245.245,0,0,1,.347,0L38.253,43.4a1.017,1.017,0,1,0,1.438-1.439l-7.241-7.241a2.28,2.28,0,0,0-3.224,0L22.045,41.9a1.183,1.183,0,0,0,.744,1.942H25.64V67.453H2.034V43.847H15.37a1.017,1.017,0,1,0,0-2.034H1.017A1.017,1.017,0,0,0,0,42.83V68.47a1.017,1.017,0,0,0,1.017,1.017h25.64a1.017,1.017,0,0,0,1.017-1.017V42.83a1.017,1.017,0,0,0-1.017-1.017"
                            transform="translate(0 -20.64)"
                            fill="rgba(220,224,233,0.3)"
                          />
                          <path
                            id="Path_4018"
                            data-name="Path 4018"
                            d="M24.513,72.445a2.047,2.047,0,0,0-1.088,1.812v8.776a2.051,2.051,0,0,0,3.2,1.7l6.511-4.388a2.052,2.052,0,0,0,0-3.4l-6.511-4.388a2.05,2.05,0,0,0-2.111-.11m.946,10.588V74.257l.028-.015L32,78.66Z"
                            transform="translate(-14.197 -43.761)"
                            fill="rgba(220,224,233,0.3)"
                          />
                          <path
                            id="Path_4019"
                            data-name="Path 4019"
                            d="M64.005,17.659a3.56,3.56,0,1,0-3.56,3.56,3.565,3.565,0,0,0,3.56-3.56m-5.086,0a1.526,1.526,0,1,1,1.526,1.526,1.527,1.527,0,0,1-1.526-1.526"
                            transform="translate(-34.476 -8.545)"
                            fill="rgba(220,224,233,0.3)"
                          />
                        </g>
                      </svg>
                      <input
                        type={'file'}
                        hidden
                        ref={demoFileRef}
                        accept={'.jpeg,.jpg,.png,.gif'}
                        onChange={getDemoFile}
                      />
                      <AddFileButton
                        onClick={() => demoFileRef.current.click()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.138"
                          height="15"
                          viewBox="0 0 15.138 15"
                        >
                          <path
                            id="Path_3683"
                            data-name="Path 3683"
                            d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                            transform="translate(-171.869 -100.292)"
                            fill="#fff"
                          />
                        </svg>
                      </AddFileButton>
                    </Placeholder>
                  )}
                  {images.map((file, index) => (
                    <MobileGallerySingleImage key={index}>
                      <img
                        src={
                          file.Id
                            ? file.FilePath
                            : `data:image/png;base64, ${file.File}`
                        }
                        alt={`Image ${index}`}
                      />
                      {isProductEditing && (
                        <DeleteFileButton
                          onClick={() => {
                            if (
                              files.filter(
                                (el) =>
                                  el.ProductFileType === 1 &&
                                  el.Id &&
                                  el.Id === file.Id,
                              ).length > 0
                            ) {
                              deleteExistingFile(
                                files.filter(
                                  (el) =>
                                    el.ProductFileType === 1 &&
                                    el.Id &&
                                    el.Id === file.Id,
                                )[0],
                              );
                            } else if (
                              files.filter(
                                (el) =>
                                  el.ProductFileType === 1 &&
                                  !el.Id &&
                                  el.Name === file.Name,
                              ).length > 0
                            ) {
                              deleteNewFile(
                                files.filter(
                                  (el) =>
                                    el.ProductFileType === 1 &&
                                    !el.Id &&
                                    el.Name === file.Name,
                                )[0],
                              );
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.9"
                            height="12"
                            viewBox="0 0 10.9 12"
                          >
                            <g
                              id="Icon_feather-trash"
                              data-name="Icon feather-trash"
                              transform="translate(0.5 0.5)"
                            >
                              <path
                                id="Path_3663"
                                data-name="Path 3663"
                                d="M4.5,9h9.9"
                                transform="translate(-4.5 -6.8)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Path_3664"
                                data-name="Path 3664"
                                d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                transform="translate(-6.4 -3)"
                                fill="none"
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                            </g>
                          </svg>
                        </DeleteFileButton>
                      )}
                    </MobileGallerySingleImage>
                  ))}
                </MobileGalleryWrapper>
              )}
              {productType === 8 && (
                <FilesWrapper>
                  <FileWithTitleWrapper>
                    <h1>Wersja do odsłuchu</h1>
                    <p>
                      Przygotuj skróconą wersję tracka, która najlepiej odda
                      jego charakter <br />
                      (maksymalnie do 30 sekund){' '}
                    </p>
                    {isProductEditing &&
                    files.filter((file) => file.ProductFileType === 3).length >
                      0 ? (
                      files
                        .filter((file, i) => file.ProductFileType === 3)
                        .slice(0, 1)
                        .map((file, i) => {
                          const {File, Name, Id} = file;

                          if (i > 0) return;

                          return (
                            <TrackFileWrapper key={Id + Name}>
                              <h5 title={Name}>{Name}</h5>
                              <NoFileSvg />
                              <Button
                                onClick={() => {
                                  handleSnippetAudioRefPlayPause();
                                }}
                                isActive={isSnippetAudioPlayed}
                              />
                              <DeleteFileButton
                                onClick={() => {
                                  if (
                                    files.filter(
                                      (file) =>
                                        file.ProductFileType === 3 && file.Id,
                                    ).length > 0
                                  ) {
                                    deleteExistingFile(
                                      files.filter(
                                        (file) =>
                                          file.ProductFileType === 3 && file.Id,
                                      )[0],
                                    );
                                  } else if (
                                    files.filter(
                                      (file) =>
                                        file.ProductFileType === 3 && !file.Id,
                                    ).length > 0
                                  ) {
                                    deleteNewFile(
                                      files.filter(
                                        (file) =>
                                          file.ProductFileType === 3 &&
                                          !file.Id,
                                      )[0],
                                    );
                                  }
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10.9"
                                  height="12"
                                  viewBox="0 0 10.9 12"
                                >
                                  <g
                                    id="Icon_feather-trash"
                                    data-name="Icon feather-trash"
                                    transform="translate(0.5 0.5)"
                                  >
                                    <path
                                      id="Path_3663"
                                      data-name="Path 3663"
                                      d="M4.5,9h9.9"
                                      transform="translate(-4.5 -6.8)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_3664"
                                      data-name="Path 3664"
                                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                      transform="translate(-6.4 -3)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </svg>
                              </DeleteFileButton>
                            </TrackFileWrapper>
                          );
                        })
                    ) : isProductEditing ? (
                      <FileWrapper>
                        <p>
                          Format pliku: <span>MP3</span>
                        </p>
                        <p>
                          Rozmiar: maks. <span>2MB</span>
                        </p>
                        <AddFileButton
                          onClick={() => snippetFileRef.current.click()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.138"
                            height="15"
                            viewBox="0 0 15.138 15"
                          >
                            <path
                              id="Path_3683"
                              data-name="Path 3683"
                              d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                              transform="translate(-171.869 -100.292)"
                              fill="#fff"
                            />
                          </svg>
                        </AddFileButton>
                        <input
                          type={'file'}
                          hidden
                          ref={snippetFileRef}
                          accept={'.mp3'}
                          onChange={getSnippetFile}
                        />
                      </FileWrapper>
                    ) : !isProductEditing &&
                      files.filter((file) => file.ProductFileType === 3)
                        .length > 0 ? (
                      files
                        .filter((file, i) => file.ProductFileType === 3)
                        .slice(0, 1)
                        .map((file, i) => {
                          const {File, Name, Id} = file;

                          if (i > 0) return;

                          return (
                            <TrackFileWrapper key={Id + Name}>
                              <h5 title={Name}>{Name}</h5>
                              <NoFileSvg />
                              <Button
                                onClick={() => {
                                  handleSnippetAudioRefPlayPause();
                                }}
                                isActive={isSnippetAudioPlayed}
                              />
                            </TrackFileWrapper>
                          );
                        })
                    ) : (
                      <NoFileWrapper>
                        <h5>Brak demo</h5>
                        <NoFileSvg />
                      </NoFileWrapper>
                    )}
                  </FileWithTitleWrapper>
                  <FileWithTitleWrapper>
                    <h1>Demo wersja do pobrania</h1>
                    <p>
                      Dodaj wersję tracka w pogorszonej jakości. Zabezpiecz ją
                      znakiem wodnym. Kupujący po pobraniu będzie mógł lepiej
                      zapoznać się z trackiem.
                    </p>
                    {isProductEditing &&
                    files.filter((file) => file.ProductFileType === 1).length >
                      0 ? (
                      files
                        .filter((file, i) => file.ProductFileType === 1)
                        .slice(0, 1)
                        .map((file, i) => {
                          const {File, Name, Id} = file;

                          if (i > 0) return;

                          return (
                            <TrackFileWrapper key={Id + Name}>
                              <h5 title={Name}>{Name}</h5>
                              <NoFileSvg />
                              <Button
                                onClick={() => {
                                  handleDemoAudioRefPlayPause();
                                }}
                                isActive={isDemoAudioPlayed}
                              />
                              <DeleteFileButton
                                onClick={() => {
                                  if (
                                    files.filter(
                                      (file) =>
                                        file.ProductFileType === 1 && file.Id,
                                    ).length > 0
                                  ) {
                                    deleteExistingFile(
                                      files.filter(
                                        (file) =>
                                          file.ProductFileType === 1 && file.Id,
                                      )[0],
                                    );
                                  } else if (
                                    files.filter(
                                      (file) =>
                                        file.ProductFileType === 1 && !file.Id,
                                    ).length > 0
                                  ) {
                                    deleteNewFile(
                                      files.filter(
                                        (file) =>
                                          file.ProductFileType === 1 &&
                                          !file.Id,
                                      )[0],
                                    );
                                  }
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10.9"
                                  height="12"
                                  viewBox="0 0 10.9 12"
                                >
                                  <g
                                    id="Icon_feather-trash"
                                    data-name="Icon feather-trash"
                                    transform="translate(0.5 0.5)"
                                  >
                                    <path
                                      id="Path_3663"
                                      data-name="Path 3663"
                                      d="M4.5,9h9.9"
                                      transform="translate(-4.5 -6.8)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_3664"
                                      data-name="Path 3664"
                                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                      transform="translate(-6.4 -3)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </svg>
                              </DeleteFileButton>
                            </TrackFileWrapper>
                          );
                        })
                    ) : isProductEditing ? (
                      <FileWrapper>
                        <h4>(pole nieobowiązkowe)</h4>
                        <p>
                          Format pliku: <span>MP3</span>
                        </p>
                        <p>
                          Rozmiar: maks. <span>3MB</span>
                        </p>
                        <AddFileButton
                          onClick={() => demoFileRef.current.click()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.138"
                            height="15"
                            viewBox="0 0 15.138 15"
                          >
                            <path
                              id="Path_3683"
                              data-name="Path 3683"
                              d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                              transform="translate(-171.869 -100.292)"
                              fill="#fff"
                            />
                          </svg>
                        </AddFileButton>
                        <input
                          type={'file'}
                          hidden
                          ref={demoFileRef}
                          accept={'.mp3'}
                          onChange={getDemoFile}
                        />
                      </FileWrapper>
                    ) : !isProductEditing &&
                      files.filter((file) => file.ProductFileType === 1)
                        .length > 0 ? (
                      files
                        .filter((file, i) => file.ProductFileType === 1)
                        .slice(0, 1)
                        .map((file, i) => {
                          const {File, Name, Id} = file;

                          if (i > 0) return;

                          return (
                            <TrackFileWrapper key={Id + Name}>
                              <h5 title={Name}>{Name}</h5>
                              <NoFileSvg />
                              <Button
                                onClick={() => {
                                  handleDemoAudioRefPlayPause();
                                }}
                                isActive={isDemoAudioPlayed}
                              />
                            </TrackFileWrapper>
                          );
                        })
                    ) : (
                      <NoFileWrapper>
                        <h5>Brak demo</h5>
                        <NoFileSvg />
                      </NoFileWrapper>
                    )}
                  </FileWithTitleWrapper>
                  <FileWithTitleWrapper>
                    <h1>Wersja finalna tracka</h1>
                    <p>
                      Dodaj plik (WAV, MP3) bądź zestaw plików, np. ścieżki lub
                      wersje tracka (ZIP), które klient otrzyma po zakupie.
                    </p>
                    {isProductEditing &&
                    files.filter((file) => file.ProductFileType === 2).length >
                      0 ? (
                      files
                        .filter((file, i) => file.ProductFileType === 2)
                        .slice(0, 1)
                        .map((file, i) => {
                          const {File, Name, Id} = file;

                          if (i > 0) return;

                          return (
                            <TrackFileWrapper key={Id + Name}>
                              <h5 title={Name}>{Name}</h5>
                              <NoFileSvg />
                              <Button
                                onClick={() => {
                                  handleProductAudioRefPlayPause();
                                }}
                                isActive={isProductAudioPlayed}
                              />
                              <DeleteFileButton
                                onClick={() => {
                                  if (
                                    files.filter(
                                      (file) =>
                                        file.ProductFileType === 2 && file.Id,
                                    ).length > 0
                                  ) {
                                    deleteExistingFile(
                                      files.filter(
                                        (file) =>
                                          file.ProductFileType === 2 && file.Id,
                                      )[0],
                                    );
                                  } else if (
                                    files.filter(
                                      (file) =>
                                        file.ProductFileType === 2 && !file.Id,
                                    ).length > 0
                                  ) {
                                    deleteNewFile(
                                      files.filter(
                                        (file) =>
                                          file.ProductFileType === 2 &&
                                          !file.Id,
                                      )[0],
                                    );
                                  }
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10.9"
                                  height="12"
                                  viewBox="0 0 10.9 12"
                                >
                                  <g
                                    id="Icon_feather-trash"
                                    data-name="Icon feather-trash"
                                    transform="translate(0.5 0.5)"
                                  >
                                    <path
                                      id="Path_3663"
                                      data-name="Path 3663"
                                      d="M4.5,9h9.9"
                                      transform="translate(-4.5 -6.8)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="Path_3664"
                                      data-name="Path 3664"
                                      d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                      transform="translate(-6.4 -3)"
                                      fill="none"
                                      stroke="#fff"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </svg>
                              </DeleteFileButton>
                            </TrackFileWrapper>
                          );
                        })
                    ) : isProductEditing ? (
                      <FileWrapper>
                        <p>
                          Format pliku: <span>WAV, MP3 lub ZIP</span>
                        </p>
                        <p>
                          Rozmiar: maks. <span>150MB</span>
                        </p>
                        <AddFileButton
                          onClick={() => productFileRef.current.click()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15.138"
                            height="15"
                            viewBox="0 0 15.138 15"
                          >
                            <path
                              id="Path_3683"
                              data-name="Path 3683"
                              d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                              transform="translate(-171.869 -100.292)"
                              fill="#fff"
                            />
                          </svg>
                        </AddFileButton>
                        <input
                          type={'file'}
                          hidden
                          ref={productFileRef}
                          accept={'.wav,.zip,.mp3,.rar'}
                          onChange={getProductFile}
                        />
                      </FileWrapper>
                    ) : !isProductEditing &&
                      files.filter((file) => file.ProductFileType === 2)
                        .length > 0 ? (
                      files
                        .filter((file) => file.ProductFileType === 2)
                        .slice(0, 1)
                        .map((file, i) => {
                          const {File, Name, Id} = file;

                          if (i > 0) return;

                          return (
                            <TrackFileWrapper key={Id + Name}>
                              <h5 title={Name}>{Name}</h5>
                              <NoFileSvg />
                              <Button
                                onClick={() => {
                                  handleProductAudioRefPlayPause();
                                }}
                                isActive={isProductAudioPlayed}
                              />
                            </TrackFileWrapper>
                          );
                        })
                    ) : (
                      <NoFileWrapper>
                        <h5>Brak demo</h5>
                        <NoFileSvg />
                      </NoFileWrapper>
                    )}
                  </FileWithTitleWrapper>
                  {/* {activeFile && (
                    <AudioPlayer source={audioFiles[activeFile]} />
                  )} */}
                </FilesWrapper>
              )}

              {isProductEditing ? (
                <>
                  <MobileTagsWrapper style={{width: '367px'}}>
                    {tags.map((tag) => (
                      <SellerObligationWrapper
                        key={tag}
                        isEditing={isProductEditing}
                      >
                        <p>{tag}</p>
                        {isProductEditing && (
                          <DeleteTagButton
                            onClick={() => deleteTag(tag)}
                            style={{
                              minWidth: '19px',
                              minHeight: '19px',
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10.9"
                              height="12"
                              viewBox="0 0 10.9 12"
                            >
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(0.5 0.5)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9h9.9"
                                  transform="translate(-4.5 -6.8)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.2,5.2v7.7A1.1,1.1,0,0,1,14.1,14H8.6a1.1,1.1,0,0,1-1.1-1.1V5.2m1.65,0V4.1A1.1,1.1,0,0,1,10.25,3h2.2a1.1,1.1,0,0,1,1.1,1.1V5.2"
                                  transform="translate(-6.4 -3)"
                                  fill="none"
                                  stroke="#fff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                              </g>
                            </svg>
                          </DeleteTagButton>
                        )}
                      </SellerObligationWrapper>
                    ))}

                    <AddSellerObligationWrapper style={{width: '367px'}}>
                      <AddSellerObligationInputWrapper onKeyUp={addTagOnKeyUp}>
                        <input
                          placeholder="Nadaj tag"
                          value={tag}
                          maxLength={20}
                          onChange={handleTagChange}
                        />
                      </AddSellerObligationInputWrapper>
                      <AddTagButton onClick={() => addTag(tag)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.138"
                          height="15"
                          viewBox="0 0 15.138 15"
                        >
                          <path
                            id="Path_3683"
                            data-name="Path 3683"
                            d="M186.263,107.114l-6.256.135.109-6.2a.682.682,0,1,0-1.356,0l.109,6.2-6.256-.135a.681.681,0,1,0,0,1.356l6.256-.135-.109,6.2a.682.682,0,1,0,1.356,0l-.109-6.2,6.256.135a.681.681,0,1,0,0-1.356Z"
                            transform="translate(-171.869 -100.292)"
                            fill="#fff"
                          />
                        </svg>
                      </AddTagButton>
                    </AddSellerObligationWrapper>
                  </MobileTagsWrapper>
                </>
              ) : (
                <MobileDisplayDescriptionWrapper>
                  <h5>Tagi</h5>
                  <p>
                    {' '}
                    {tags.map(
                      (tag, index) =>
                        `${tag}${index === tags.length - 1 ? '' : ', '}`,
                    )}
                  </p>
                </MobileDisplayDescriptionWrapper>
              )}

              {isProductEditing && (
                <MobileBtnWrapper>
                  <button
                    onClick={() => {
                      if (createNewProductStep === 0) {
                        editProduct(productId);
                      } else {
                        createProduct().then(() => {
                          const categories = [];
                          if (primaryCategory) categories.push(primaryCategory);
                          if (secondaryCategory)
                            categories.push(secondaryCategory);
                          if (tertiaryCategory)
                            categories.push(tertiaryCategory);
                          if (
                            !name ||
                            name.length > 50 ||
                            (productType !== 8 && description.length < 50) ||
                            (productType !== 8 && !description) ||
                            productType === '' ||
                            files.filter((file) => file.ProductFileType === 0)
                              .length === 0 ||
                            (productType === 1 &&
                              files.filter((file) => file.ProductFileType === 2)
                                .length === 0) ||
                            (productType === 8 &&
                              files.filter((file) => file.ProductFileType === 2)
                                .length === 0 &&
                              files.filter((file) => file.ProductFileType === 3)
                                .length === 0) ||
                            (productType === 8 && (!tone || !vibe || !tempo)) ||
                            categories.length === 0
                          ) {
                            return;
                          } else {
                            handleIsMobileConditionsVisibleChange(true),
                              scrollToBottom();
                          }
                        });
                      }
                    }}
                  >
                    {createNewProductStep === 0
                      ? 'Zapisz'
                      : createNewProductStep === 2 && singleProductId
                      ? 'Zapisz'
                      : 'Dalej'}
                  </button>
                </MobileBtnWrapper>
              )}
            </MobileProductDataWrapper>

            {isMobileConditionsVisible && (
              <MobileConditionsWrapper>
                <MobileHeaderWithButtonWrapper>
                  <h1>Warunki</h1>
                  {createNewProductStep === 0 &&
                  productDisplayStatus !== 3 &&
                  productDisplayStatus !== 4 &&
                  productDisplayStatus !== 6 &&
                  isConditionEditing ? (
                    <PopupButton
                      buttons={['Kontynuuj', 'Zamknij']}
                      title={'Uwaga'}
                      text={`
                               Czy na pewno chcesz zamknąć edycje? Wszystkie zmiany nie zostaną zapisane.
                              `}
                      onClick={() => {
                        getSingleProduct(singleProductId);
                        setIsConditionEditing(false);
                        if (conditionOne?.Id) {
                          setSelectedCondition(1);
                        } else if (conditionTwo?.Id) {
                          setSelectedCondition(2);
                        } else if (conditionThree?.Id) {
                          setSelectedCondition(3);
                        }
                        // setSelectedConditionOne('');
                        // setSelectedConditionThree('');
                        // setSelectedConditionTwo('');
                      }}
                    >
                      <EditBtnWrapper
                        style={{top: '-12px'}}
                        isEditing={isConditionEditing}
                      >
                        <EditSvgWrapper isEditing={isConditionEditing}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.27"
                            height="16.658"
                            viewBox="0 0 17.27 16.658"
                          >
                            <g
                              id="Icon_feather-edit-3"
                              data-name="Icon feather-edit-3"
                              transform="translate(-3.75 -3.41)"
                            >
                              <path
                                id="Path_3712"
                                data-name="Path 3712"
                                d="M18,30h7.885"
                                transform="translate(-5.615 -10.682)"
                                fill="none"
                                stroke="rgba(7,2,15,0.7)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_3713"
                                data-name="Path 3713"
                                d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                                fill="none"
                                stroke="rgba(7,2,15,0.7)"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </EditSvgWrapper>
                      </EditBtnWrapper>
                    </PopupButton>
                  ) : createNewProductStep === 2 && isProductEditing ? null : (
                    <EditBtnWrapper
                      style={{top: '0px'}}
                      isEditing={isConditionEditing}
                      onClick={() => {
                        if (
                          createNewProductStep === 0 &&
                          !isProductEditing &&
                          productDisplayStatus !== 3 &&
                          productDisplayStatus !== 4 &&
                          productDisplayStatus !== 6
                        ) {
                          setIsConditionEditing(!isConditionEditing);
                        } else if (
                          productDisplayStatus === 3 ||
                          productDisplayStatus === 4 ||
                          productDisplayStatus === 6
                        ) {
                          Alert('W celu edycji wycofaj ofertę.', 'Komunikat');
                        }
                      }}
                    >
                      <EditSvgWrapper isEditing={isConditionEditing}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.27"
                          height="16.658"
                          viewBox="0 0 17.27 16.658"
                        >
                          <g
                            id="Icon_feather-edit-3"
                            data-name="Icon feather-edit-3"
                            transform="translate(-3.75 -3.41)"
                          >
                            <path
                              id="Path_3712"
                              data-name="Path 3712"
                              d="M18,30h7.885"
                              transform="translate(-5.615 -10.682)"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3713"
                              data-name="Path 3713"
                              d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                              fill="none"
                              stroke="rgba(7,2,15,0.7)"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </EditSvgWrapper>
                    </EditBtnWrapper>
                  )}
                </MobileHeaderWithButtonWrapper>
                <MobileConditionsContentBox
                  style={{width: '100%', height: '100%'}}
                >
                  <ConditionDetailsBox>
                    <MobileOptionsWrapper>
                      <MobileSingleOption
                        isEditing={isConditionEditing}
                        haveCondition={conditionOne?.Id ? true : false}
                        value={1}
                        selectedCondition={selectedCondition}
                        onClick={() => {
                          if (selectedCondition === 2) {
                            if (conditionTwo?.Id && !conditionTwo?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              !conditionTwo?.userWinWin &&
                              !conditionTwo?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType === 4 &&
                              !conditionTwo?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType !== 4 &&
                              conditionTwo?.userWinWin &&
                              !conditionTwo?.TimeToUploadForCustomer &&
                              conditionTwo?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType !== 4 &&
                              conditionTwo?.userWinWin &&
                              !conditionTwo?.WinWinDigital &&
                              !conditionTwo?.WinWinPhysical &&
                              !conditionTwo?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          if (selectedCondition === 3) {
                            if (conditionThree?.Id && !conditionThree?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionThree?.Id &&
                              conditionThree?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionThree?.Id &&
                              conditionThree?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              !conditionThree?.userWinWin &&
                              !conditionThree?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType === 4 &&
                              !conditionThree?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType !== 4 &&
                              conditionThree?.userWinWin &&
                              !conditionThree?.TimeToUploadForCustomer &&
                              conditionThree?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType !== 4 &&
                              conditionThree?.userWinWin &&
                              !conditionThree?.WinWinDigital &&
                              !conditionThree?.WinWinPhysical &&
                              !conditionThree?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          setSelectedCondition(1);
                        }}
                      >
                        {selectedCondition === 1 ? (
                          <p>Wariant A</p>
                        ) : (
                          <span>A</span>
                        )}
                      </MobileSingleOption>
                      <MobileSingleOption
                        isEditing={isConditionEditing}
                        haveCondition={conditionTwo?.Id ? true : false}
                        value={2}
                        selectedCondition={selectedCondition}
                        onClick={() => {
                          if (selectedCondition === 1) {
                            if (conditionOne?.Id && !conditionOne?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionOne?.Id &&
                              conditionOne?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionOne?.Id &&
                              conditionOne?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              !conditionOne?.userWinWin &&
                              !conditionOne?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType === 4 &&
                              !conditionOne?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType !== 4 &&
                              conditionOne?.userWinWin &&
                              !conditionOne?.TimeToUploadForCustomer &&
                              conditionOne?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType !== 4 &&
                              conditionOne?.userWinWin &&
                              !conditionOne?.WinWinDigital &&
                              !conditionOne?.WinWinPhysical &&
                              !conditionOne?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          if (selectedCondition === 3) {
                            if (conditionThree?.Id && !conditionThree?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionThree?.Id &&
                              conditionThree?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionThree?.Id &&
                              conditionThree?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              !conditionThree?.userWinWin &&
                              !conditionThree?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType === 4 &&
                              !conditionThree?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType !== 4 &&
                              conditionThree?.userWinWin &&
                              !conditionThree?.TimeToUploadForCustomer &&
                              conditionThree?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionThree?.Id &&
                              conditionThree?.ProductType !== 4 &&
                              conditionThree?.userWinWin &&
                              !conditionThree?.WinWinDigital &&
                              !conditionThree?.WinWinPhysical &&
                              !conditionThree?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          setSelectedCondition(2);
                        }}
                      >
                        {selectedCondition === 2 ? (
                          <p>Wariant B</p>
                        ) : (
                          <span>B</span>
                        )}
                      </MobileSingleOption>

                      <MobileSingleOption
                        isEditing={isConditionEditing}
                        haveCondition={conditionThree?.Id ? true : false}
                        value={3}
                        selectedCondition={selectedCondition}
                        onClick={() => {
                          if (selectedCondition === 2) {
                            if (conditionTwo?.Id && !conditionTwo?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              !conditionTwo?.userWinWin &&
                              !conditionTwo?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType === 4 &&
                              !conditionTwo?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType !== 4 &&
                              conditionTwo?.userWinWin &&
                              !conditionTwo?.TimeToUploadForCustomer &&
                              conditionTwo?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionTwo?.Id &&
                              conditionTwo?.ProductType !== 4 &&
                              conditionTwo?.userWinWin &&
                              !conditionTwo?.WinWinDigital &&
                              !conditionTwo?.WinWinPhysical &&
                              !conditionTwo?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          if (selectedCondition === 1) {
                            if (conditionOne?.Id && !conditionOne?.Name) {
                              return Alert(
                                'Uzupełnij nazwę warunku',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionOne?.Id &&
                              conditionOne?.Name.length > 30
                            ) {
                              return Alert(
                                'Nazwa warunku nie może mieć więcej niż 30 znaków',
                                'Komunikat',
                              );
                            }

                            if (
                              conditionOne?.Id &&
                              conditionOne?.SellerObligations.length === 0
                            )
                              return Alert(
                                'Dodaj conajmniej jedno zobowiązanie',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              !conditionOne?.userWinWin &&
                              !conditionOne?.GrossValue
                            )
                              return Alert(
                                'Weź udział w przychodach lub/i wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType === 4 &&
                              !conditionOne?.GrossValue
                            )
                              return Alert(
                                'Wypełnij kwotę gwarantowaną',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType !== 4 &&
                              conditionOne?.userWinWin &&
                              !conditionOne?.TimeToUploadForCustomer &&
                              conditionOne?.TimeToUploadForCustomer !== 0
                            )
                              return Alert(
                                'Wybierz date wydania w mugo',
                                'Komunikat',
                              );

                            if (
                              conditionOne?.Id &&
                              conditionOne?.ProductType !== 4 &&
                              conditionOne?.userWinWin &&
                              !conditionOne?.WinWinDigital &&
                              !conditionOne?.WinWinPhysical &&
                              !conditionOne?.WinWinYoutube
                            )
                              return Alert(
                                'Uzupełnij udział w przychodach',
                                'Komunikat',
                              );
                          }
                          setSelectedCondition(3);
                        }}
                      >
                        {selectedCondition === 3 ? (
                          <p>Wariant C</p>
                        ) : (
                          <span>C</span>
                        )}
                      </MobileSingleOption>
                    </MobileOptionsWrapper>
                    {selectedCondition === 1 ? (
                      <ConditionComponent
                        condition={conditionOne}
                        setCondition={setConditionOne}
                        isConditionEditing={isConditionEditing}
                        conditionsList={conditionsList}
                        createNewProductStep={createNewProductStep}
                        isProductEditing={isProductEditing}
                        userMetaData={userMetaData}
                        selectedCondition={selectedCondition}
                        productTypeFromProduct={productType}
                        conditionNumber={1}
                        productId={productId}
                        initialCondition={initialConditionOne}
                        setInitialCondition={setInitialConditionOne}
                        conditionOne={conditionOne}
                        conditionTwo={conditionTwo}
                        conditionThree={conditionThree}
                      />
                    ) : selectedCondition === 2 ? (
                      <ConditionComponent
                        condition={conditionTwo}
                        setCondition={setConditionTwo}
                        isConditionEditing={isConditionEditing}
                        conditionsList={conditionsList}
                        createNewProductStep={createNewProductStep}
                        isProductEditing={isProductEditing}
                        userMetaData={userMetaData}
                        selectedCondition={selectedCondition}
                        productTypeFromProduct={productType}
                        conditionNumber={2}
                        productId={productId}
                        initialCondition={initialConditionTwo}
                        setInitialCondition={setInitialConditionTwo}
                        conditionOne={conditionOne}
                        conditionTwo={conditionTwo}
                        conditionThree={conditionThree}
                      />
                    ) : (
                      selectedCondition === 3 && (
                        <ConditionComponent
                          condition={conditionThree}
                          setCondition={setConditionThree}
                          isConditionEditing={isConditionEditing}
                          conditionsList={conditionsList}
                          createNewProductStep={createNewProductStep}
                          isProductEditing={isProductEditing}
                          userMetaData={userMetaData}
                          selectedCondition={selectedCondition}
                          productTypeFromProduct={productType}
                          conditionNumber={3}
                          productId={productId}
                          initialCondition={initialConditionThree}
                          setInitialCondition={setInitialConditionThree}
                          conditionOne={conditionOne}
                          conditionTwo={conditionTwo}
                          conditionThree={conditionThree}
                        />
                      )
                    )}
                    {isConditionEditing && (
                      <SaveProductButton
                        style={{zIndex: '5'}}
                        onClick={() => {
                          updateProductAndConditions().then(
                            (isReadyForOffer) => {
                              if (isReadyForOffer) {
                                setTimeout(() => {
                                  scrollToTop();
                                }, 0);
                              }
                            },
                          );
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.5"
                          height="15.5"
                          viewBox="0 0 15.5 15.5"
                        >
                          <g
                            id="Icon_feather-save"
                            data-name="Icon feather-save"
                            transform="translate(-3.75 -3.75)"
                          >
                            <path
                              id="Path_3700"
                              data-name="Path 3700"
                              d="M16.944,18.5H6.056A1.556,1.556,0,0,1,4.5,16.944V6.056A1.556,1.556,0,0,1,6.056,4.5h8.556L18.5,8.389v8.556A1.556,1.556,0,0,1,16.944,18.5Z"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3701"
                              data-name="Path 3701"
                              d="M18.278,25.722V19.5H10.5v6.222"
                              transform="translate(-2.889 -7.222)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_3702"
                              data-name="Path 3702"
                              d="M10.5,4.5V8.389h6.222"
                              transform="translate(-2.889)"
                              fill="none"
                              stroke="#fff"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </SaveProductButton>
                    )}
                  </ConditionDetailsBox>
                </MobileConditionsContentBox>
              </MobileConditionsWrapper>
            )}
          </MobileMain>
        </MobileMainWrapper>
      </>
    );
};

export default MyProductDetail;

interface AudioPlayerProps {
  source: string;
  audioRef: any;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({source, audioRef}) => {
  return (
    <audio ref={audioRef}>
      <source src={source} type="audio/mp3" />
      Your browser does not support the audio element.
    </audio>
  );
};

interface ButtonProps {
  onClick: () => void;
  isActive: boolean;
}

const Button: React.FC<ButtonProps> = ({onClick, isActive}) => {
  return (
    <>
      <DesktopPlayButtonWrapper onClick={onClick}>
        {isActive ? <img src={pauseIcon} /> : <img src={playIcon} />}
      </DesktopPlayButtonWrapper>
    </>
  );
};

export const StyledBlueHeaderComponent: React.FC = ({children}) => {
  return (
    <StyledBlueHeaderWrapper>
      <StyledLeftDot />
      <h1>{children}</h1>
    </StyledBlueHeaderWrapper>
  );
};
