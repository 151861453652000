import React, {useState, createContext} from 'react';
import {ProducerData} from './../../pages/MyStudio/Account/interface';

type Context = [
  ProducerData,
  React.Dispatch<React.SetStateAction<ProducerData>>,
];

export const AccountProducerContext = createContext<Context>(null!);

interface Props {
  children: React.ReactNode;
}

const AccountProducerProvider: React.FC<Props> = ({children}) => {
  const [producerData, setProducerData] = useState<ProducerData>({
    AcceptMarketingAggreement: false,
    AcceptMugoStudioSatute: false,
    AcceptPrivatePolcySatute: false,
    DisplayProducerNameAsPublicDisplayName: false,
    DraftCardId: '',
    FirstName: '',
    FtpPhotoFilePath: '',
    Id: '',
    LabelId: '',
    LastName: '',
    AboutMe: '',
    Name: '',
    PublishedCardId: '',
    Role: 0,
    SecondaryRole: 0,
    Status: 0,
    PostalCode: '',
    Comments: [],
    NIP: null,
    Pesel: '',
    CompanyName: '',
    Representative: '',
    TaxOffice: '',
    SettlementAddress: {
      Country: '',
      FlatNumber: '',
      PostalCode: '',
      Region: '',
      Street: '',
      BuildingNumber: '',
    },
    SettlementFirstName: '',
    SettlementLastName: '',
    SettlementType: 0,
  });
  return (
    <AccountProducerContext.Provider value={[producerData, setProducerData]}>
      {children}
    </AccountProducerContext.Provider>
  );
};

export default AccountProducerProvider;
