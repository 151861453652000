import React, {useEffect, useRef, useState} from 'react';
import {hexToRgb} from '../../index';
import {
  LongDescriptionBlockWrapper,
  LongDescriptionBlockHeader,
  LongDescriptionBlockContent,
  LongDescriptionContent,
  LongDescriptionTitleContent,
} from './styles';
import {Block} from '../../interface';

import useAlert from 'hooks/useAlert';
import {keyboardKey} from '@testing-library/user-event';
import {values} from 'lodash';

export interface LongDescriptionBlock extends Block {
  TextOne: string | null;
  TextTwo: string | null;
  TextThree: string | null;
}

interface Props {
  theme: string;
  block: LongDescriptionBlock;
  mobileOverview: boolean;
  isEditing: boolean;
  changeText: ({longText: AboutMe}) => void;
}
interface AboutMe {
  TextOne: string;
  TextTwo: string;
  TextThree: string;
  Title: string;
}

export const sliceIntoChunks = (arr: string[], chunkSize: number) => {
  if (!arr) return [];
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const LongDescriptionBlockComponent: React.FC<Props> = ({
  theme,
  block,
  mobileOverview,
  isEditing,
  changeText,
}) => {
  const [currentTextPage, setCurrentTextPage] = useState<number>(0);
  const [isWriteModeForTextTwo, setIsWriteModeForTextTwo] = useState(false);
  const [isWriteModeForTextThree, setIsWriteModeForTextThree] = useState(false);

  const textOneRef = useRef(null);
  const textTwoRef = useRef(null);
  const textThreeRef = useRef(null);

  const {Alert} = useAlert();
  const {
    WebArea,
    FontColorHex,
    TextOne,
    TextTwo,
    TextThree,
    TitleColorHex,
    Format,
    BackgroundColorHex,
    BlockOpacityIn0To100Ratio,
    VerticalFormat,
    HorizontalFormat,
    Interline,
  } = block;

  const {Width, Height} = WebArea;

  const initTitle = block.Title === null ? '' : block.Title;

  const [aboutMe, setAboutMe] = useState<AboutMe>({
    TextOne,
    TextTwo,
    TextThree,
    Title: initTitle,
  });

  const checkSpaces = (value: string) => {
    const lastSpaces = value.lastIndexOf(' ');
    if (value.length < 30) return true;
    if (value.length - lastSpaces >= 30) return false;
    return true;
  };

  const handleAboutMeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    key: number,
  ) => {
    const scrollLimit = Height === 3 ? 297 : 421;

    if (e.target.value.length > 750) {
      setAboutMe((aboutMe) => ({
        ...aboutMe,
        TextOne: e.target.value.slice(0, 750),
      }));
      changeText({
        longText: {...aboutMe, TextOne: e.target.value.slice(0, 750)},
      });
      return;
    }
    if (key === 1) {
      setAboutMe((aboutMe) => ({...aboutMe, TextOne: e.target.value}));
      changeText({longText: {...aboutMe, TextOne: e.target.value}});
    } else if (key === 2) {
      setAboutMe((aboutMe) => ({...aboutMe, TextTwo: e.target.value}));
      changeText({longText: {...aboutMe, TextTwo: e.target.value}});
    } else if (key === 3) {
      setAboutMe((aboutMe) => ({...aboutMe, TextThree: e.target.value}));
      changeText({longText: {...aboutMe, TextThree: e.target.value}});
    } else if (key === 4) {
      setAboutMe((aboutMe) => ({...aboutMe, Title: e.target.value}));
      changeText({longText: {...aboutMe, Title: e.target.value}});
    }
  };

  const slicedComments = sliceIntoChunks(
    [aboutMe.TextOne, aboutMe.TextTwo, aboutMe.TextThree],
    mobileOverview ? 1 * Height : Width * Height,
  );

  const checkLength = (
    e: React.KeyboardEvent<HTMLTextAreaElement>,
    key: number,
  ) => {
    const limit = Height === 3 ? 640 : Height === 4 ? 910 : 1050;
    const element =
      key === 1
        ? aboutMe.TextOne
        : key === 2
        ? aboutMe.TextTwo
        : aboutMe.TextThree;

    if (key === 3 && e.key === 'Backspace' && aboutMe.TextThree.length === 0) {
      setIsWriteModeForTextThree(false);
      textTwoRef.current.focus();
      return;
    }
    if (key === 2 && e.key === 'Backspace' && aboutMe.TextTwo.length === 0) {
      setIsWriteModeForTextTwo(false);
      textOneRef.current.focus();
      return;
    }
    if (aboutMe.TextOne.length > 300) {
      setIsWriteModeForTextTwo(true);
    }
    if (aboutMe.TextTwo.length > 300) {
      setIsWriteModeForTextThree(true);
    }
    // console.log(element.length);

    // if (element.length > limit - 1) {
    //   if (key === 1) {
    //     setIsWriteModeForTextTwo(true);
    //     textTwoRef.current.focus();
    //   }
    //   if (key === 2) {
    //     setIsWriteModeForTextThree(true);
    //     textThreeRef.current.focus();
    //   }
    //   if (key === 1) {
    //     setAboutMe((aboutMe) => ({
    //       ...aboutMe,
    //       TextOne: element.substring(0, limit),
    //     }));
    //   } else if (key === 2) {
    //     setAboutMe((aboutMe) => ({
    //       ...aboutMe,
    //       TextTwo: element.substring(0, limit),
    //     }));
    //   } else if (key === 3) {
    //     setAboutMe((aboutMe) => ({
    //       ...aboutMe,
    //       TextThree: element.substring(0, limit),
    //     }));
    //   }
    // }
  };
  const checkTitleLength = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (aboutMe.Title.length > 14) {
      setAboutMe((prevState) => ({
        ...prevState,
        Title: aboutMe.Title.slice(0, 14),
      }));
      changeText({
        longText: {...aboutMe, Title: aboutMe.Title.slice(0, 14)},
      });
    }
  };
  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);

  useEffect(() => {
    const adjustTextareaHeight = () => {
      const textarea = textOneRef.current;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    adjustTextareaHeight();
  }, [aboutMe.TextOne]);

  return (
    <LongDescriptionBlockWrapper
      backgroundAlpha={BlockOpacityIn0To100Ratio}
      backgroundColor={BackgroundColorRgb}
    >
      <LongDescriptionBlockContent
        fontColor={hexToRgb(FontColorHex)}
        mobileOverview={mobileOverview}
        Height={Height}
        Width={Width}
        style={
          VerticalFormat === 1
            ? {justifyContent: 'flex-start'}
            : VerticalFormat === 4
            ? {justifyContent: 'flex-end'}
            : VerticalFormat === 8
            ? {justifyContent: 'justify'}
            : {justifyContent: 'center'}
        }
      >
        <LongDescriptionContent
          mobileOverview={mobileOverview}
          Height={Height}
          fontColor={hexToRgb(FontColorHex)}
          interline={Interline}
          key={1}
          style={
            VerticalFormat === 1
              ? {alignSelf: 'flex-start'}
              : VerticalFormat === 4
              ? {alignSelf: 'flex-end'}
              : VerticalFormat === 8
              ? {alignSelf: 'justify'}
              : {alignSelf: 'center'}
          }
        >
          {isEditing ? (
            <textarea
              style={
                HorizontalFormat === 1
                  ? {textAlign: 'center'}
                  : HorizontalFormat === 4
                  ? {textAlign: 'right'}
                  : HorizontalFormat === 8
                  ? {textAlign: 'justify'}
                  : {textAlign: 'left'}
              }
              value={aboutMe.TextOne}
              ref={textOneRef}
              onChange={(e) => handleAboutMeChange(e, 1)}
              // onKeyUp={(e) => checkLength(e, 1)}
              placeholder="Napisz coś o sobie..."
            />
          ) : (
            <textarea
              style={
                HorizontalFormat === 1
                  ? {textAlign: 'center'}
                  : HorizontalFormat === 4
                  ? {textAlign: 'right'}
                  : HorizontalFormat === 8
                  ? {textAlign: 'justify'}
                  : {textAlign: 'left'}
              }
              ref={textOneRef}
              value={aboutMe.TextOne}
              disabled={true}
            >
              {/* {aboutMe.TextOne} */}
            </textarea>
          )}
        </LongDescriptionContent>

        {isEditing && <span>{aboutMe.TextOne.length}/750</span>}
      </LongDescriptionBlockContent>
    </LongDescriptionBlockWrapper>
  );
};

export default LongDescriptionBlockComponent;
