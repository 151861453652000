import mugoImage from '../../../images/post_mugo.png';
import {Link} from 'react-router-dom';
import React, {useState} from 'react';
import {
  ImageWrapper,
  InputWrapper,
  Main,
  MainWrapper,
  PageHeaderContent,
  PageHeaderHeader,
  PageHeaderHeaderWrapper,
  PageHeaderWrapper,
  PrimaryButton,
  RightSectionContent,
  MobileMain,
  MobileMainWrapper,
  MobilePageHeader,
} from '../Recover';
import api from '../../../services/axiosConfig';
import useAlert from 'hooks/useAlert';
import {LoadingCircle} from 'utils/loadingCircle';
import {MobileInput} from 'components/MobileInput';
import {InputView} from 'pages/MyStudio/Account/styles';

export const ResendEmail: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {Alert} = useAlert();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const resendEmail = async () => {
    if (isLoading) return;
    if (email.length === 0)
      return Alert('Email nie może być pusty', 'Komunikat');
    setIsLoading(true);
    try {
      const body = {Email: email};
      const response = await api.post(`Auth/ResendActivationLink`, body);
      Alert(
        'Na Twój adres email wysłaliśmy kod potrzebny do zmiany hasła',
        'Komunikat',
      );
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <MainWrapper>
        <Main>
          {isLoading && <LoadingCircle />}
          <PageHeaderWrapper>
            <PageHeaderHeaderWrapper>
              <PageHeaderHeader>
                <p>Witamy w MUGO STUDIO!</p>
              </PageHeaderHeader>
            </PageHeaderHeaderWrapper>
            <PageHeaderContent>
              <ImageWrapper>
                <img src={mugoImage} />
              </ImageWrapper>
              <RightSectionContent>
                <h1 style={{marginBottom: '27.5px'}}>
                  Prześlij ponownie email
                </h1>
                <InputView isEditing={true} style={{marginBottom: '310px'}}>
                  <p>Email</p>
                  <input
                    type="text"
                    placeholder="Pole obowiązkowe"
                    value={email}
                    onChange={handleEmailChange}
                    id="email"
                  />
                </InputView>
                <Link to={'/identity/login'}>Logowanie</Link>
                <Link style={{marginBottom: '14px'}} to={'/identity/register'}>
                  Zarejestruj się
                </Link>
                <PrimaryButton
                  onClick={() => resendEmail()}
                  style={{justifyContent: 'center'}}
                >
                  {isLoading ? 'Wysyłanie...' : 'Wyślij'}
                </PrimaryButton>
              </RightSectionContent>
            </PageHeaderContent>
          </PageHeaderWrapper>
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        <MobileMain>
          {isLoading && <LoadingCircle />}
          <MobilePageHeader style={{marginBottom: '82px'}}>
            Prześlij ponownie email
          </MobilePageHeader>
          <div style={{marginBottom: '72px', width: '100%'}}>
            <MobileInput isEditing={true}>
              <p>Email</p>
              <input
                type="text"
                value={email}
                onChange={handleEmailChange}
                id="email"
              />
            </MobileInput>
          </div>
          <Link to={'/identity/login'}>Logowanie</Link>
          <Link style={{marginBottom: '95px'}} to={'/identity/register'}>
            Zarejestruj się
          </Link>
          <PrimaryButton
            onClick={() => resendEmail()}
            style={{justifyContent: 'center'}}
          >
            {isLoading ? 'Wysyłanie...' : 'Wyślij'}
          </PrimaryButton>
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};
