import React, {useEffect, useRef, useState} from 'react';
import {Block} from '../../interface';
import {
  TitleBlockSubtitleContent,
  TitleBlockTitleContent,
  TitleBlockWrapper,
} from './styles';
import {hexToRgb} from '../../index';
import {TitleBlockContent} from '../../EditBlockComponent';
import useAlert from 'hooks/useAlert';

interface Props {
  theme: string;
  block: Block;
  isEditing: boolean;
  changeText: ({titleBlockContent: TitleBlockContent}) => void;
  mobileOverview: boolean;
}

export const TitleBlock: React.FC<Props> = ({
  theme,
  block,
  isEditing,
  changeText,
  mobileOverview,
}) => {
  const initTitle = block.Title === null ? '' : block.Title;
  const initSubTitle = block.SubTitle === null ? '' : block.SubTitle;

  const [content, setContent] = useState<TitleBlockContent>({
    Title: initTitle,
    SubTitle: initSubTitle,
  });

  const {
    FontColorHex,
    TitleColorHex,
    BackgroundColorHex,
    BlockOpacityIn0To100Ratio,
    Format,
    HorizontalFormat,
    VerticalFormat,
    Interline,
  } = block;

  const {Alert} = useAlert();

  const titleRef = useRef(null);
  const subTitleRef = useRef(null);

  const checkTitleLength = (key: string) => {
    if (key === 'TITLE') {
      if (content.Title.length > 24) {
        setContent((prevState) => ({
          ...prevState,
          Title: content.Title.slice(0, 24),
        }));
        changeText({
          titleBlockContent: {...content, Title: content.Title.slice(0, 24)},
        });
        return Alert('Tekst ma limit 24 znaków', 'Tekst ma limit 24 znaków');
      }
    }
    if (key === 'SUBTITLE') {
      if (content.SubTitle.length > 80) {
        setContent((prevState) => ({
          ...prevState,
          SubTitle: content.SubTitle.slice(0, 80),
        }));
        changeText({
          titleBlockContent: {
            ...content,
            SubTitle: content.SubTitle.slice(0, 80),
          },
        });
        return Alert('Tekst ma limit 80 znaków', 'Tekst ma limit 80 znaków');
      }
    }
  };

  const handleContent = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    key: string,
  ) => {
    if (key === 'TITLE') {
      setContent((prevState) => ({...prevState, Title: e.target.value}));
      changeText({titleBlockContent: {...content, Title: e.target.value}});
    }
    if (key === 'SUBTITLE') {
      setContent((prevState) => ({...prevState, SubTitle: e.target.value}));
      changeText({titleBlockContent: {...content, SubTitle: e.target.value}});
    }
  };

  // useEffect(() => {
  //   const adjustTextareaHeight = () => {
  //     const textarea = titleRef.current;
  //     if (textarea) {
  //       textarea.style.height = 'auto';
  //       textarea.style.height = `${textarea.scrollHeight}px`;
  //     }
  //   };

  //   adjustTextareaHeight();
  // }, [content.Title]);

  // useEffect(() => {
  //   const adjustTextareaHeight = () => {
  //     const textarea = subTitleRef.current;
  //     if (textarea) {
  //       textarea.style.height = 'auto';
  //       textarea.style.height = `32px`;
  //     }
  //   };

  //   adjustTextareaHeight();
  // }, [content.SubTitle]);

  return (
    <TitleBlockWrapper
      bgColor={hexToRgb(BackgroundColorHex)}
      backgroundAlpha={BlockOpacityIn0To100Ratio}
      style={
        VerticalFormat === 1
          ? {justifyContent: 'flex-start'}
          : VerticalFormat === 4
          ? {justifyContent: 'flex-end'}
          : VerticalFormat === 8
          ? {justifyContent: 'justify'}
          : {justifyContent: 'center'}
      }
    >
      <TitleBlockTitleContent
        mobileOverview={mobileOverview}
        titleColor={hexToRgb(FontColorHex)}
        interline={Interline}
      >
        {isEditing ? (
          <textarea
            style={
              HorizontalFormat === 1
                ? {textAlign: 'center'}
                : HorizontalFormat === 4
                ? {textAlign: 'right'}
                : HorizontalFormat === 8
                ? {textAlign: 'justify'}
                : {textAlign: 'left'}
            }
            value={content.Title}
            onChange={(e) => handleContent(e, 'TITLE')}
            onKeyUp={() => checkTitleLength('TITLE')}
            placeholder="Tytuł"
            ref={titleRef}
          />
        ) : (
          <textarea
            style={
              HorizontalFormat === 1
                ? {textAlign: 'center'}
                : HorizontalFormat === 4
                ? {textAlign: 'right'}
                : HorizontalFormat === 8
                ? {textAlign: 'justify'}
                : {textAlign: 'left'}
            }
            ref={titleRef}
            value={content.Title}
            disabled={true}
          >
            {/* {content.Title} */}
          </textarea>
        )}
        {content.SubTitle ? (
          <TitleBlockSubtitleContent
            mobileOverview={mobileOverview}
            fontColor={hexToRgb(TitleColorHex)}
          >
            {isEditing ? (
              <textarea
                style={
                  HorizontalFormat === 1
                    ? {textAlign: 'center'}
                    : HorizontalFormat === 4
                    ? {textAlign: 'right'}
                    : HorizontalFormat === 8
                    ? {textAlign: 'justify'}
                    : {textAlign: 'left'}
                }
                value={content.SubTitle}
                onChange={(e) => handleContent(e, 'SUBTITLE')}
                onKeyUp={() => checkTitleLength('SUBTITLE')}
                placeholder="Podtytuł "
                ref={subTitleRef}
              />
            ) : (
              <textarea
                style={
                  HorizontalFormat === 1
                    ? {textAlign: 'center'}
                    : HorizontalFormat === 4
                    ? {textAlign: 'right'}
                    : HorizontalFormat === 8
                    ? {textAlign: 'justify'}
                    : {textAlign: 'left'}
                }
                disabled={true}
                value={content.SubTitle}
                ref={subTitleRef}
              >
                {/* {content.SubTitle} */}
              </textarea>
            )}
          </TitleBlockSubtitleContent>
        ) : isEditing ? (
          <TitleBlockSubtitleContent
            mobileOverview={mobileOverview}
            fontColor={hexToRgb(TitleColorHex)}
          >
            <textarea
              value={content.SubTitle}
              onChange={(e) => handleContent(e, 'SUBTITLE')}
              placeholder="Podtytuł "
              style={
                HorizontalFormat === 1
                  ? {textAlign: 'center'}
                  : HorizontalFormat === 4
                  ? {textAlign: 'right'}
                  : HorizontalFormat === 8
                  ? {textAlign: 'justify'}
                  : {textAlign: 'left'}
              }
            />
          </TitleBlockSubtitleContent>
        ) : null}
      </TitleBlockTitleContent>
    </TitleBlockWrapper>
  );
};
