import React, {useContext, useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import RecomendedProductionOneComponent from './RecomendedProductionOne/index';
import {
  RecomendedProductionBlockWrapper,
  SingleProductContentWrapper,
  ButtonLeftAndRightWrapper,
  ButtonBg,
  ProductAddButonWrapper,
  SliderWrapper,
  ProductsWrapper,
  SingleProduct,
} from './styles';
import {Block} from '../../interface';
import api from '../../../../../services/axiosConfig';
import {ProducerDataContext} from '../../../../../contexts/ProducerDataContext';
import {useCookies} from 'react-cookie';
import {RecomendedProductionListView} from './RecomendedProductionListView';
import {hexToRgb} from '../../index';
import {DeleteCommentButton} from '../CommentsBlock/styles';
import {transform} from 'lodash';

import {GalleryNextWrapper} from '../GalleryBlock/styles';

import PopularProductImage1 from 'images/articlePortfel.jpg';
import PopularProductImage2 from 'images/articleShorts.jpg';
import PopularProductImage3 from 'images/articleWinWin.jpg';
import PopularProductImage4 from 'images/articleYouTube.jpg';
import PopularProductImage5 from 'images/articlesSpotify.jpg';
import {useNavigate} from 'react-router-dom';

import {WinWinSvg} from 'components/SvgComponents';

import {ResolutionContext} from 'contexts/ResolutionContext';
import {VisitedProducerContext} from '../../../../../contexts/VisitedProducerContext';
import {EditHoverContext} from '../../../../../contexts/EditHoverContext';

interface Props {
  theme: string;
  block: Block;
  mobileOverview: boolean;
  isEditing: boolean;
  updateProductionBlock: ({offerIds, offers}) => void;
}

export interface SingleCategory {
  Name: string | null;
  CategoryId: string | null;
}

export interface Offer {
  AvailabilityEndDate?: string;
  AvailabilityStartDate?: string;
  Category?: SingleCategory | null;
  SecondCategoryId?: SingleCategory | null;
  ThirdCategoryId?: SingleCategory | null;
  CoverFile?: string;
  Description?: string;
  Featured?: boolean;
  GrossValue?: number;
  Id: string;
  IsWinWin?: boolean;
  Name?: string;
  NegotiatedOffer?: boolean;
  ProducerId?: string;
  ProducerName?: string;
  ProductDemoFile?: string;
  ProductId?: string;
  ProductType?: number;
  RightsEnd?: number;
  IsActive?: boolean;
  Tags?: string;
  Currency?: null | string;
}

export const sliceIntoChunks = (
  arr: Offer[],
  chunkSize: number,
  isEditing: boolean,
) => {
  if (!arr) return [];
  const res = [];
  const arrLength = isEditing ? arr.length : arr.length - 1;
  for (let i = 0; i < arrLength; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const returnDiffrentElementFromTwoArr = (arr: Offer[], all: Offer[]) => {
  let newArr = [...all];
  all.forEach((item) => {
    if (arr.filter((id) => id.Id === item.Id).length > 0) {
      return (newArr = newArr.filter((newItem) => newItem.Id !== item.Id));
    }
  });
  return newArr;
};

const popularCategories = [
  {
    title: 'Ilustracja wygenerowana przez sztuczną inteligencje',
    image: PopularProductImage1,
    isWinWin: true,
    price: 40,
  },
  {
    title: 'Napis w formie ilustracji, dla Twojego zespołu.',
    image: PopularProductImage2,
    isWinWin: false,
    price: 233,
  },
  {
    title: 'Grafika na ubrania i inne produkty promocyjne',
    image: PopularProductImage3,
    isWinWin: true,
    price: 40,
  },
  {
    title: 'Bajkowe zwierzęta w formie ilustracji na okładkę',
    image: PopularProductImage4,
    isWinWin: false,
    price: 40,
  },
  {
    title: 'Ilustracja dla dzieci do książek w każdej technice',
    image: PopularProductImage5,
    isWinWin: true,
    price: 230,
  },
  {
    title: 'Ilustracja wygenerowana przez sztuczną inteligencje2',
    image: PopularProductImage1,
    isWinWin: true,
    price: 40,
  },
  {
    title: 'Napis w formie ilustracji, dla Twojego zespołu.2',
    image: PopularProductImage2,
    isWinWin: false,
    price: 233,
  },
  {
    title: 'Grafika na ubrania i inne produkty promocyjne2',
    image: PopularProductImage3,
    isWinWin: true,
    price: 40,
  },
  {
    title: 'Bajkowe zwierzęta w formie ilustracji na okładkę2',
    image: PopularProductImage4,
    isWinWin: false,
    price: 40,
  },
  {
    title: 'Ilustracja dla dzieci do książek w każdej technice2',
    image: PopularProductImage5,
    isWinWin: true,
    price: 230,
  },
  {
    title: 'Ilustracja dla dzieci do książek w każdej technice2',
    image: PopularProductImage5,
    isWinWin: true,
    price: 230,
  },
];

const RecomendedProductionsBlockComponent: React.FC<Props> = ({
  theme,
  block,
  mobileOverview,
  isEditing,
  updateProductionBlock,
}) => {
  const {
    WebArea,
    OfferIds,
    Offers,
    BackgroundColorHex,
    BlockOpacityIn0To100Ratio,
    TitleColorHex,
    FontColorHex,
  } = block;
  const {Width} = WebArea;
  const initOffers = Offers ? Offers : [];

  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [productions, setProductions] = useState<Offer[]>(initOffers);
  const [productsList, setProductsList] = useState<Offer[]>([]);
  const [selectedProduct, setSelectProduct] = useState<Offer>({Id: ''});

  const [productIndex, setProductIndex] = useState(0);
  const [useSlideInAnimation, setUseSlideInAnimation] = useState(false);
  const [deleteAniamtion, setDeleteAnimation] = useState(false);
  const [goRight, setGoRight] = useState(false);

  const [cookies, setCookie] = useCookies(['token']);

  const {decodedToken} = useContext(ProducerDataContext);
  const [visitedProducer, setVisitedProducer] = useContext(
    VisitedProducerContext,
  );
  const [blockHover, setBlockHover] = useContext(EditHoverContext);
  const {windowWidth} = useContext(ResolutionContext);

  const [currentSlide, setCurrentSlide] = useState(0);

  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const changeSelectedProduct = (product) => setSelectProduct(product);

  const maxIndexForProduct = productions.length - 1;

  const handleAnimaton = (key: string) => {
    console.log('pokaz animacje', key);
    setUseSlideInAnimation((prevState) => !prevState);
    setDeleteAnimation(false);
    if (key === 'right') {
      setGoRight(true);
    } else {
      setGoRight(false);
    }
  };

  const hoverTextOn = (text: string) => {
    setBlockHover((prev) => ({Active: true, Text: text}));
  };

  const hoverTextOff = () => {
    setBlockHover((prev) => ({Active: false, Text: ''}));
  };

  const nextProduct = () => {
    if (productions.length <= 3 && !isEditing) return;
    if (productIndex === maxIndexForProduct) return;
    handleAnimaton('right');
    setProductIndex((prevState) => prevState + 1);
  };

  const prevProduct = () => {
    if (productions.length <= 3 && !isEditing) return;
    if (productIndex === 0) return;
    handleAnimaton('left');
    setProductIndex((prevState) => prevState - 1);
  };

  const addItemToRecomended = (offer: Offer) => {
    setProductions((prev) => [...prev, offer]);
    const dataToUpdate = [...productions, offer].map((item) => item.Id);
    updateProductionBlock({
      offers: [...productions, offer],
      offerIds: dataToUpdate,
    });
    setIsAdding(false);
  };

  const closeList = () => setIsAdding(false);

  const deleteItemFromRecomended = (id: string) => {
    if (id === '') return;
    setProductions((prev) => prev.filter((item) => item.Id !== id));
    const dataToUpdate = [...block.Offers].filter((item) => item.Id !== id);
    updateProductionBlock({
      offers: dataToUpdate,
      offerIds: dataToUpdate.map((item) => item.Id),
    });
    setSelectProduct({Id: ''});
    setUseSlideInAnimation((prevState) => !prevState);
    setDeleteAnimation(true);
  };

  const dowloadDataToAdd = async () => {
    try {
      const response = await api.get(`Offer`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      const allProducts = response.data.Response.filter(
        (item) => item.ProducerId === decodedToken.ProducerId,
      )
        .filter((item) => item.ProductType !== 8)
        .map((item) => ({
          Id: item.Id,
          Name: item.Name,
          Category: item.Category,
          IsWinWin: item.IsWinWin,
          Description: item.Description,
          GrossValue: item.GrossValue,
          Currency: item.Currency,
          CoverFile: item.CoverFile,
          ProductType: item.ProductType,
          SecondCategoryId: item.SecondCategoryId,
          ThirdCategoryId: item.ThirdCategoryId,
        }));
      setProductsList(
        returnDiffrentElementFromTwoArr(productions, allProducts),
      );
      setIsAdding(true);
    } catch (error) {
      console.error(error);
    }
  };
  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);
  const colorBrgb = hexToRgb(TitleColorHex);
  const colorArgb = hexToRgb(FontColorHex);

  function divideArr(arr) {
    const result = [];

    if (mobileOverview || windowWidth <= 1024) {
      for (let i = 0; i < arr.length; i += 2) {
        result.push(arr.slice(i, i + 2));
      }
    } else {
      for (let i = 0; i < arr.length; i += 5) {
        result.push(arr.slice(i, i + 5));
      }
    }

    return result;
  }

  function howMuchDots(number) {
    if (mobileOverview || windowWidth <= 1024) {
      return Math.ceil(number / 2);
    }
    return Math.ceil(number / 5);
  }
  console.log('mobile overviwe w pordukt multi', mobileOverview);
  return (
    <RecomendedProductionBlockWrapper
      isMobile={mobileOverview}
      backgroundAlpha={BlockOpacityIn0To100Ratio}
      backgroundColor={BackgroundColorRgb}
      productsLength={productions.length}
    >
      {isEditing && isAdding ? (
        <RecomendedProductionListView
          closeList={closeList}
          productsList={productsList}
          addItemToRecomended={addItemToRecomended}
        />
      ) : null}
      {productions.length === 160 && !isEditing ? (
        <RecomendedProductionOneComponent
          block={block}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          deleteItemFromRecomended={deleteItemFromRecomended}
          offer={productions[0]}
          dowloadDataToAdd={dowloadDataToAdd}
        />
      ) : (
        <SliderWrapper isMobile={mobileOverview}>
          <Slider {...settings}>
            {[...new Array(howMuchDots(Offers.length))].map((array, index) => {
              return (
                <ProductsWrapper isMobile={mobileOverview} key={index}>
                  {divideArr(Offers)[index].map((product, index) => {
                    const {
                      CoverFile,
                      Name,
                      IsWinWin,
                      GrossValue,
                      Category,
                      SecondCategoryId,
                    } = product;

                    return (
                      <SingleProduct
                        bgOpacity={BlockOpacityIn0To100Ratio}
                        bgColor={colorBrgb}
                        isSelected={selectedProduct.Id === product.Id}
                        onClick={
                          isEditing
                            ? () => changeSelectedProduct(product)
                            : () =>
                                navigate(`/products/main/${product.ProductId}`)
                        }
                        key={index}
                      >
                        <img src={CoverFile} alt="image" />
                        <h1 style={{color: `rgba(${colorArgb})`}}>{Name}</h1>
                        {IsWinWin ? (
                          <WinWinSvg />
                        ) : (
                          <p style={{color: `rgba(${colorArgb},0.5)`}}>KWOTA</p>
                        )}
                        <h2 style={{color: `rgba(${colorArgb})`}}>{`od ${
                          GrossValue / 100
                        }zł`}</h2>
                        <h4
                          style={{color: `rgba(${colorArgb},0.5)`}}
                        >{`${Category.Name} `}</h4>
                      </SingleProduct>
                    );
                  })}
                </ProductsWrapper>
              );
            })}
          </Slider>
        </SliderWrapper>
      )}
      {visitedProducer.CardType === 0 && !isEditing ? null : (
        <ButtonLeftAndRightWrapper
          style={
            visitedProducer.CardType === 1
              ? {flexDirection: 'row', gap: '5px', right: '5px', bottom: '5px'}
              : null
          }
          Width={Width}
        >
          {isEditing ? (
            <ButtonBg
              className="DelteProduct"
              onMouseEnter={() => hoverTextOn('Usuń produkt')}
              onMouseLeave={hoverTextOff}
              onClick={() => deleteItemFromRecomended(selectedProduct.Id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.3"
                height="13.5"
                viewBox="0 0 12.3 13.5"
              >
                <g
                  id="Icon_feather-trash"
                  data-name="Icon feather-trash"
                  transform="translate(0.75 0.75)"
                >
                  <path
                    id="Path_3663"
                    data-name="Path 3663"
                    d="M4.5,9H15.3"
                    transform="translate(-4.5 -6.6)"
                    fill="none"
                    stroke={`rgb(${hexToRgb(block.BackgroundColorHex)})`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                  <path
                    id="Path_3664"
                    data-name="Path 3664"
                    d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                    transform="translate(-6.3 -3)"
                    fill="none"
                    stroke={`rgb(${hexToRgb(block.BackgroundColorHex)})`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </ButtonBg>
          ) : null}
        </ButtonLeftAndRightWrapper>
      )}
    </RecomendedProductionBlockWrapper>
  );
};

export default RecomendedProductionsBlockComponent;
