import {
  DeleteButton,
  DemoReelWrapper,
  SoundTrackBg,
  TextWrapper,
} from '../styles';
import React, {useEffect, useState} from 'react';
import {BlockFile} from '../../../interface';

interface Props {
  BlockAudio: BlockFile;
  theme: string;
  isEditing: boolean;
  deleteDemo: (id: string) => void;
  Width: number;
  Height: number;
  fontColor: string;
}

const SingleDemo: React.FC<Props> = ({
  BlockAudio,
  theme,
  isEditing,
  deleteDemo,
  Width,
  Height,
  fontColor,
}) => {
  const [isAudioPlayed, setIsAudioPlayed] = useState<boolean>(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [elapsed, setElapsed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [percentOfTrack, setPercentOfTrack] = useState<number>(0);

  const {Id, FileName, FilePath} = BlockAudio;

  const playAudio = (
    e: React.MouseEvent<SVGSVGElement>,
    audio: HTMLAudioElement,
  ) => {
    e.stopPropagation();
    audio.play();
    setIsAudioPlayed(true);
  };

  const pauseAudio = (
    e: React.MouseEvent<SVGSVGElement>,
    audio: HTMLAudioElement,
  ) => {
    e.stopPropagation();
    audio.pause();
    setIsAudioPlayed(false);
  };

  const calculatePercentOfTrack = (num: number, secondNum: number) => {
    if (secondNum === 0) return;

    return Math.floor((num / secondNum) * 100);
  };

  useEffect(() => {
    if (isAudioPlayed) {
      setInterval(() => {
        const _duration = Math.floor(audio.duration);
        const _elapsed = Math.floor(audio.currentTime);

        setPercentOfTrack(calculatePercentOfTrack(_elapsed, _duration));
        setDuration(_duration);
        setElapsed(_elapsed);
      }, 100);
    }
  }, [isAudioPlayed]);

  useEffect(() => {
    if (elapsed === duration && isAudioPlayed && elapsed > 10) {
      audio.currentTime = 0;
      audio.pause();
      setIsAudioPlayed(false);
    }
  }, [elapsed]);

  useEffect(() => {
    if (BlockAudio.FilePath) {
      setAudio(new Audio(BlockAudio.FilePath));
    }
  }, [BlockAudio]);

  return (
    <DemoReelWrapper Height={Height} theme={theme} Width={Width}>
      <SoundTrackBg style={{width: `${percentOfTrack}%`}}></SoundTrackBg>
      {isEditing && (
        <DeleteButton onClick={() => deleteDemo(Id)} theme={theme}>
          X
        </DeleteButton>
      )}
      {!isAudioPlayed ? (
        <svg
          onClick={(e) => playAudio(e, audio)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 30 30"
        >
          <path
            id="Icon_material-play-circle-outline"
            data-name="Icon material-play-circle-outline"
            d="M15,24.75,24,18l-9-6.75ZM18,3A15,15,0,1,0,33,18,15.005,15.005,0,0,0,18,3Zm0,27A12,12,0,1,1,30,18,12.016,12.016,0,0,1,18,30Z"
            transform="translate(-3 -3)"
            fill={`rgb(${fontColor})`}
          />
        </svg>
      ) : (
        // <svg
        //   onClick={(e) => pauseAudio(e, audio)}
        //   xmlns="http://www.w3.org/2000/svg"
        //   width="23"
        //   height="23"
        //   viewBox="0 0 11 11"
        // >
        //   <path
        //     id="Icon_metro-pause"
        //     data-name="Icon metro-pause"
        //     d="M6.427,5.784H11.01v11H6.427Zm6.417,0h4.583v11H12.843Z"
        //     transform="translate(-6.427 -5.784)"
        //     fill="#fff"
        //   />
        // </svg>
        <svg
          onClick={(e) => pauseAudio(e, audio)}
          id="Group_5691"
          data-name="Group 5691"
          xmlns="http://www.w3.org/2000/svg"
          width="33.353"
          height="33.353"
          viewBox="0 0 33.353 33.353"
        >
          <path
            id="Path_1675"
            data-name="Path 1675"
            d="M1151.328,400.282A16.676,16.676,0,1,1,1168,383.605,16.7,16.7,0,0,1,1151.328,400.282Zm0-29.647a12.971,12.971,0,1,0,12.97,12.971A12.986,12.986,0,0,0,1151.328,370.635Z"
            transform="translate(-1134.651 -366.929)"
            fill={`rgb(${fontColor})`}
          />
          <rect
            id="Rectangle_865"
            data-name="Rectangle 865"
            width="9.882"
            height="9.882"
            rx="1"
            transform="translate(11.737 11.735)"
            fill={`rgb(${fontColor})`}
          />
        </svg>
      )}
      {Width === 1 ? null : (
        <TextWrapper Width={Width} Height={Height}>
          <p style={{color: `rgb(${fontColor})`}}>{FileName}</p>
          {Width === 3 ? (
            <span style={{color: `rgb(${fontColor})`}}>
              Wyjdzie z tego tutaj miejsca około 70
            </span>
          ) : null}
        </TextWrapper>
      )}
    </DemoReelWrapper>
  );
};

export default SingleDemo;
