import React, {createContext, useContext, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

interface TracksAudioContextType {
  isAudioPlayed: string;
  setIsAudioPlayed: React.Dispatch<React.SetStateAction<string>>;
}

const TracksAudioContext = createContext<TracksAudioContextType | undefined>(
  undefined,
);

export const useTracksAudioContext = () => {
  const context = useContext(TracksAudioContext);
  if (!context) {
    throw new Error(
      'useTracksAudioContext must be used within a TracksAudioProvider',
    );
  }
  return context;
};

export const TracksAudioProvider: React.FC = ({children}) => {
  const [isAudioPlayed, setIsAudioPlayed] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    setIsAudioPlayed('');
  }, [location]);

  return (
    <TracksAudioContext.Provider value={{isAudioPlayed, setIsAudioPlayed}}>
      {children}
    </TracksAudioContext.Provider>
  );
};
