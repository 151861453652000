import React, {useEffect, useState} from 'react';

import {hexToRgb} from '../../../index';
import {Block} from '../../../interface';
import {
  ProductAddButonWrapper,
  RecomendedProductionButtonWrapper,
  RecomendedProductionContent,
  RecomendedProductionFooter,
  RecomendedProductionHeader,
  RecomendedProductionNameAndButtonWrapper,
  RecomendedProductionPriceButton,
  RecomendedProductionWinWinInfo,
  RecomendedProductionWrapper,
  SingleProductContentWrapper,
} from '../styles';
import {Offer} from '../index';
import {useNavigate} from 'react-router-dom';
import {
  OneProductBlockColumnWrapper,
  OneProductBlockFooter,
  OneProductCategoryWrapper,
  OneProductFooterContent,
  OneProductNameProduct,
  OneProductPirceWrapper,
  OneProductType,
  OneProductWinWinWrapper,
  OneProductWrapper,
  DeleteProductButton,
} from './styles';

interface Props {
  theme: string;
  block: Block;
  mobileOverview: boolean;
  isEditing: boolean;
  offer: Offer;
  deleteItemFromRecomended: (id: string) => void;
  dowloadDataToAdd: () => void;
}

const RecomendedProductionOneComponent: React.FC<Props> = ({
  theme,
  mobileOverview,
  block,
  isEditing,
  offer,
  deleteItemFromRecomended,
  dowloadDataToAdd,
}) => {
  const {FontColorHex, BackgroundColorHex, WebArea, TitleColorHex} = block;
  const {Width, Height} = WebArea;

  const [category, setCategory] = useState<string>('Muzyka');
  const navigate = useNavigate();

  const toProductInfo = (id: string) => {
    if (!isEditing) {
      navigate(`/products/main/${id}`);
    }
  };

  const {
    Id,
    Name,
    IsWinWin,
    Description,
    GrossValue,
    Currency,
    Category,
    CoverFile,
    ProductType,
  } = offer;
  const colorBrgb = hexToRgb(TitleColorHex);
  return (
    <OneProductWrapper bgColor={hexToRgb(block.BackgroundColorHex)}>
      {isEditing ? (
        <SingleProductContentWrapper
          bgColor={colorBrgb}
          useSlideInAnimation={false}
          style={{
            top: '0',
            left: `${Width === 4 ? '330px' : Width === 3 ? '215px' : '105px'}`,
            zIndex: 5,
          }}
        >
          <ProductAddButonWrapper theme={theme} onClick={dowloadDataToAdd}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              viewBox="0 0 9 9"
            >
              <g
                id="Icon_feather-plus-circle"
                data-name="Icon feather-plus-circle"
                transform="translate(0.5 0.5)"
                opacity="0.76"
              >
                <path
                  id="Path_3319"
                  data-name="Path 3319"
                  d="M11,7A4,4,0,1,1,7,3,4,4,0,0,1,11,7Z"
                  transform="translate(-3 -3)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_3320"
                  data-name="Path 3320"
                  d="M18,12v3.2"
                  transform="translate(-14 -9.6)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_3321"
                  data-name="Path 3321"
                  d="M12,18h3.2"
                  transform="translate(-9.6 -14)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </svg>
          </ProductAddButonWrapper>
        </SingleProductContentWrapper>
      ) : null}
      {isEditing ? (
        <DeleteProductButton onClick={() => deleteItemFromRecomended(Id)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.718"
            height="24.667"
            viewBox="0 0 10.718 12.667"
          >
            <path
              id="Icon"
              d="M8.282,12.667H2.436A1.463,1.463,0,0,1,.975,11.2V2.923H0V1.949H2.923V1.458A1.46,1.46,0,0,1,4.381,0H6.337A1.46,1.46,0,0,1,7.795,1.458v.491h2.923v.975H9.743V11.2A1.463,1.463,0,0,1,8.282,12.667ZM1.949,2.923V11.2a.482.482,0,0,0,.488.487H8.282A.482.482,0,0,0,8.77,11.2V2.923ZM4.381.975a.473.473,0,0,0-.484.483v.491H6.82V1.458A.473.473,0,0,0,6.337.975Zm1.465,9.743H4.872V3.9h.975v6.82Zm-1.949,0H2.923V3.9H3.9v6.82Zm3.9,0H6.82V3.9h.975v6.82Z"
              transform="translate(0 0)"
            />
          </svg>
        </DeleteProductButton>
      ) : null}
      <OneProductBlockColumnWrapper Height={Height}>
        <OneProductNameProduct Width={Width} fontColor={hexToRgb(FontColorHex)}>
          {Name}
        </OneProductNameProduct>
        <img src={CoverFile} alt="coverImg" />
        <OneProductCategoryWrapper
          Width={Width}
          fontColor={hexToRgb(FontColorHex)}
        >
          <p>{category} · </p>
          <p>{category} · </p>
          <p>{category}</p>
        </OneProductCategoryWrapper>
        <OneProductType Width={Width} fontColor={hexToRgb(FontColorHex)}>
          {ProductType === 1
            ? 'Produkt cyfrowy'
            : ProductType === 2
            ? 'Produkt fizyczny'
            : ProductType === 4
            ? 'Usługa'
            : ProductType === 8
            ? 'Track'
            : ''}
        </OneProductType>
      </OneProductBlockColumnWrapper>
      <OneProductBlockFooter>
        <OneProductFooterContent Width={Width}>
          <OneProductPirceWrapper>
            {' '}
            <p>{`${GrossValue / 100} ${Currency}`}</p>
          </OneProductPirceWrapper>
          {IsWinWin ? (
            <OneProductWinWinWrapper>
              {' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.747"
                height="9.194"
                viewBox="0 0 13.747 9.194"
              >
                <g
                  id="Group_3341"
                  data-name="Group 3341"
                  transform="translate(-2144.093 -1145.759)"
                >
                  <path
                    id="Path_3575"
                    data-name="Path 3575"
                    d="M2144.787,1161.872a4.128,4.128,0,0,1,3.427-1.822"
                    transform="translate(0 -7.613)"
                    fill="none"
                    stroke="#fb9614"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <circle
                    id="Ellipse_118"
                    data-name="Ellipse 118"
                    cx="1.523"
                    cy="1.523"
                    r="1.523"
                    transform="translate(2146.508 1147.762)"
                    fill="none"
                    stroke="#fb9614"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_3576"
                    data-name="Path 3576"
                    d="M2154.023,1161.276a5.23,5.23,0,0,1,8.209-.591"
                    transform="translate(-5.098 -7.017)"
                    fill="none"
                    stroke="#fb9614"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <circle
                    id="Ellipse_119"
                    data-name="Ellipse 119"
                    cx="1.927"
                    cy="1.927"
                    r="1.927"
                    transform="translate(2151.102 1146.259)"
                    fill="none"
                    stroke="#fb9614"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                  />
                </g>
              </svg>
              <p>WIN-WIN</p>
            </OneProductWinWinWrapper>
          ) : null}
        </OneProductFooterContent>
      </OneProductBlockFooter>
    </OneProductWrapper>
  );
};

export default RecomendedProductionOneComponent;
