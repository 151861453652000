import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const CoverImage = styled.img`
  width: 67px;
  height: 67px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 11px;
    width: 154px;
    height: 154px;
  }
`;

export const IconWithDataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14.5px;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    gap: 7px;
  }
`;

export const StyledMobileLink = styled(Link)`
  text-decoration: none;
  display: none;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: unset;
  }
`;

export const MobileDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 154px;
  gap: 2px;

  & > p {
    font-size: 11px;
    color: rgba(7, 2, 15, 0.7);
    letter-spacing: 0.25px;
    line-height: 15px;
  }

  & > h4 {
    font-size: 18px;
    color: rgba(7, 2, 15, 1);
    letter-spacing: 0.1px;
    font-weight: 400;
    line-height: 21px;
    min-height: 48px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }
`;

export const SingleProductWrapper = styled.div<{
  isHovered?: boolean;
}>`
  /* height: 170px; */
  width: 100%;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.producersBorderColor};
  background: ${({theme}) => theme.colors.tracksTopSectionBackground};
  border-radius: 16px;
  display: flex;
  position: relative;
  cursor: pointer;
  transition: all 0.15s;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileSingleProductWrapper = styled.div`
  min-height: 390px;
  width: 100%;
  flex-direction: column;
  border: 1px solid rgba(112, 112, 112, 0.3);
  background: rgba(239, 240, 242, 0.3);
  border-radius: 16px;
  display: none;
  position: relative;
  cursor: pointer;
  transition: all 0.15s;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    min-height: auto;
    height: 266px;
    border: none;
    background: ${({theme}) => theme.colors.mobileTrackListHeaderBq};
    padding: 0px;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    /* border-radius: 0px; */
  }
`;

export const MobileSingleProductWithViewsCountWrapper = styled.div`
  /* min-height: 413px; */
  height: 266px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const MobileViewsCount = styled.div<{isFollow: boolean}>`
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  margin-left: 15px;
  gap: 10px;
  font-weight: 400;
  letter-spacing: -0.05px;
  /* position: absolute;
  bottom: 10px;
  left: 126px; */
  color: ${({theme}) => theme.colors.viewsCountColor};
  & > p {
    color: ${({theme}) => theme.colors.viewsCountColor};
    font-weight: 400;
    letter-spacing: -0.05px;
    font-size: 11px;
  }
  & > svg {
    width: 21px;
    margin-top: 3px;
    & > path {
      fill: ${({theme, isFollow}) =>
        isFollow ? theme.colors.blueTheme : theme.colors.bgBlack};
    }
  }
  @media (max-width: 1024px) {
    margin-left: 7px;
    margin-top: 5px;
    width: auto;
    justify-content: flex-start;
    & > p {
      width: 220px;
    }
    & > svg {
      /* margin-left: 8px; */
      /* position: absolute;
      right: 15px;
      bottom: 9px; */
      margin-top: 0px;
    }
  }
  @media (max-width: 400px) {
    & > svg {
      /* margin-left: 8px; */
    }
  }
  @media (max-width: 375px) {
    & > p {
      font-size: 10px;
      width: 202px;
    }
    & > svg {
      /* margin-left: 0px; */
    }
  }
  @media (max-width: 360px) {
    & > svg {
      /* margin-left: 5px; */
    }
  }
  @media (max-width: 340px) {
    gap: 5px;
    & > p {
      font-size: 8px;
      width: 152px;
    }
    & > svg {
      margin-left: 0px;
    }
  }
`;

export const DesktopTopSection = styled.div`
  height: 46px;
  width: 100%;
  padding: 4px 12px;
  display: flex;
  gap: 12px;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 57px;
    padding: 10px 18px;
  }
`;

export const TopSection = styled.div`
  height: 54px;
  width: 100%;
  padding: 4px 2px 4px;
  display: flex;
  gap: 9px;
  align-items: center;
  @media (max-width: 1024px) {
    padding: 1px 0px 1px;
    gap: 12px;
  }
`;

export const ProducerImage = styled.img`
  /* width: 48px;
  height: 48px;
  border-radius: 16px; */
  width: 34px;
  height: 34px;
  border-radius: 7px;
  @media (max-width: 1024px) {
    width: 34px;
    height: 34px;
    border-radius: 7px;
    margin-left: 11px;
  }
`;

export const ProducerDataWrapper = styled.div`
  width: calc(100% - 9px - 37px);
  height: 100%;
  display: flex;
  padding-bottom: 1px;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;

  & > h4 {
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    & > svg {
      margin-left: 9px;
      margin-bottom: 4px;
    }
  }

  & > p {
    font-size: 11px;
    font-weight: bold;
    line-height: 11px;
    letter-spacing: -0.05px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
`;

export const PromotionText = styled.h2`
  font-size: 11px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1px;
  transition: all 0.15s;
  text-decoration: none;
  position: absolute;
  right: 16px;
`;

export const DesktopBottomSection = styled.div`
  /* height: 124px; */
  width: 100%;
  /* background: rgba(255, 255, 255, 0.3); */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 15%,
    rgba(255, 255, 255, 0.3) 15%,
    rgba(255, 255, 255, 0.3) 15%
  );
  display: flex;
  position: relative;
  flex-direction: column;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const BottomSection = styled.div`
  height: calc(100% - 54px);
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  @media (max-width: 1024px) {
    border-radius: 14px;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    /* border: 1px solid rgba(112, 112, 112, 0.3); */
    border: none;
    background: ${({theme}) => theme.colors.mobileTrackListBodyBq};
  }
`;

export const DesktopCoverWithNameWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: unset;
  gap: 14px;
  position: relative;

  & > img {
    border-radius: 11px;
    border: 1px solid rgba(112, 112, 112, 0.3);
    height: 112px;
    width: 112px;
  }
`;

export const MobileCoverWithNameWrapper = styled.div`
  width: 100%;
  height: 112px;
  display: flex;
  padding: unset;
  gap: 12px;

  & > img {
    border-radius: 11px;
    border: 1px solid rgba(112, 112, 112, 0.3);
    height: 102px !important;
    width: 102px !important;
    margin: 7px 0px 7px 7px;
  }
`;

export const DesktopNameWithCategoriesWrapper = styled.div`
  height: 100%;
  /* width: 575px; */
  width: 555px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 5px;

  & > h4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    margin-bottom: 4px;
  }

  & > p {
    font-size: 11px;
    letter-spacing: -0.05px;
    line-height: 15px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > h5 {
    margin-top: 5px;
    font-size: 11px;
    letter-spacing: -0.25px;
    line-height: 16px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% - 104px - 10px);
  }
`;

export const MobileNameWithCategoriesWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 0;

  & > h4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    margin-bottom: 4px;
    & > svg {
      margin-left: 5px;
    }
  }

  & > p {
    font-size: 11px;
    letter-spacing: -0, 05px;
    line-height: 15px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* width: calc(100% - 104px - 10px); */

    height: auto;
    /* width: max-content; */
    padding: 5px 0 0 0;
    width: 100%;
    & > p {
      line-height: 18px;
      color: ${({theme}) => theme.colors.fontBlackOp50};
    }
    & > h4 {
      max-width: 243px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: transform 0.3s ease;
      margin-bottom: 0px;
      /* &::after {
        content: '...';
      } */
    }
    & > svg {
      position: absolute;
      right: 8px;
      fill: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
  @media (max-width: 400px) {
    & > h4 {
      font-size: 17px;
      width: 220px;
    }
  }
  @media (max-width: 360px) {
    & > h4 {
      font-size: 15px;
      width: 180px;
    }
  }
  @media (max-width: 340px) {
    & > h4 {
      font-size: 12px;
      width: 160px;
    }
    & > p {
      font-size: 8px;
    }
  }
`;

export const MobileCoverWithDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 253px;
  /* background: ${({theme}) => theme.colors.headerContentBackground}; */
  border-radius: 16px;
  padding: 6px;

  & > p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 21px;
    padding: 0 4px;
    color: #352f40;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 100%;
    min-height: auto;
    padding: 0px;
    & > p {
      margin-left: 15px;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: -0.35px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const CoverWithNameWrapper = styled.div`
  width: 657px;
  height: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 5px 52px 5px 5px;

  & > img {
    border-radius: 11px;
    background: white;
    width: 104px;
    height: 104px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    height: 208px;
    position: relative;
    padding: 7px 6px 3px;

    & > img {
      position: absolute;
      top: 4px;
      left: 5px;
    }
  }
`;

export const NameWithDescriptionWrapper = styled.div`
  height: 100%;
  width: calc(100% - 10px - 104px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  & > h4 {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: #07020f;
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: #352f40;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;

    & > h4 {
      width: 100%;
      height: 104px;
      padding-left: 119px;
    }

    & > p {
      padding: 0 5px;
      font-size: 14px;
    }
  }
`;

export const PriceWithUserDataWrapper = styled.div`
  height: 100%;
  width: 337px;
  display: flex;
  flex-direction: column;
  background: rgba(47, 47, 104, 0.05);
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 75px;
    width: 100%;
    background: transparent;
    border-top-right-radius: unset;
    border-top-left-radius: unset;
    justify-content: space-between;
    border-bottom-left-radius: 16px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const PriceWithWinWinWrapper = styled.div`
  height: 57px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2px 18px 8px 9px;
  border-top-right-radius: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    border-top-right-radius: 0px;
  }
`;

export const PriceWithTitleWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & > h4 {
    margin-top: 4px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: #07020f;

    & > span {
      font-size: 21px;
      font-weight: 400;
      letter-spacing: -0.15px;
      line-height: 21px;
      color: #07020f;
    }
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 24px;
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* justify-content: center; */
    width: 100%;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    & > h4 {
      margin-top: 0px;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      line-height: 21px;
      justify-content: center;
      letter-spacing: -0.15px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
    & > p {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.15px;
      line-height: 21px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
  @media (max-width: 340px) {
    & > h4 {
      font-size: 14px;
    }
    & > p {
      font-size: 14px;
    }
  }
`;

export const UserImageWithDataWrapper = styled.div`
  height: 57px;
  width: 100%;
  display: flex;
  gap: 9px;
  border-bottom-right-radius: 16px;
  background: rgba(47, 47, 104, 0.05);
  padding: 5.5px 7px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-bottom-left-radius: 16px;
    height: 60px;
    padding: 6px 9px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export const UserImage = styled.img`
  border-radius: 9px;
  background: white;
  width: 46px;
  height: 46px;
`;

export const UserDataWrapper = styled.div`
  height: 100%;
  width: calc(100% - 46px - 9px);
  display: flex;
  flex-direction: column;

  & > h4 {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: #352f40;
  }

  & > p {
    font-size: 11px;
    font-weight: 600;
    letter-spacing: -0.05px;
    line-height: 14px;
    color: #352f40;
  }
`;

export const SingleSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;

  & > h4 {
    color: white;
    font-size: 15px;
    font-weight: 400;
  }

  & > p {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }

  & > h5 {
    font-size: 15px;
    color: white;
  }

  &:nth-child(6) {
    gap: 10px;
  }
`;

export const StyledStatus = styled.h2`
  color: #19d627;
  font-size: 15px;
  font-weight: 400;
`;

export const StyledDate = styled.h2`
  color: #19d627;
  font-size: 9px;
  font-weight: 300;
  letter-spacing: 0.5px;
`;

export const ProductGenreWrapper = styled.div`
  width: 100px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 22px);
  gap: 9px;

  & > div {
    background: transparent;
    border: 1px solid rgba(112, 112, 112, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;

    & > p {
      font-weight: 500;
      color: rgba(53, 47, 64, 1);
      font-size: 11px;
      letter-spacing: 0.1px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(1, 22px);
    gap: 9px;
    padding: 0 10px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    padding: unset;
    gap: 4px;
  }
`;

export const ProductInfoWithCategoriesSpan = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-right: 13px;
`;

export const WinWinWithPriceSpan = styled.div`
  width: 85px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  gap: 12px;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-right: 10px;
    gap: unset;
    margin-top: unset;
    height: unset;
    width: 100%;
    align-items: flex-end;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    padding-right: 9px;
  }
`;

export const CategoriesWithDescriptionsSpan = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 11px;
`;

export const MobileImageWithInfoSpan = styled.div`
  display: flex;

  & > img {
    width: 128px;
    height: 128px;
    border-radius: 9px;
    aspect-ratio: 1;
    margin-right: 14px;
  }

  &:hover {
    filter: brightness(1.2);
    background: rgba(255, 255, 255, 0.15);
  }
`;

export const MobileWinWinWithPriceSpan = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const ProductInfoWrapper = styled.div`
  width: 90%;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  position: relative;

  & > h4 {
    font-size: 18px;
    line-height: 24px;
    color: #07020f;
    position: relative;
    letter-spacing: -0.15;
    font-weight: 500;

    & > svg {
      height: 11px;
      width: 11px;
      position: absolute;
      right: -18px;
      top: -2px;

      & > path {
        fill: #0066ff;
      }
    }
  }

  & > p {
    color: rgba(7, 2, 15, 0.7);
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.1px;
    font-weight: 400;
  }

  & > span {
    display: flex;
    gap: 2.8px;
    color: #0066ff;
    position: absolute;
    top: -12px;
    align-items: center;
    margin-left: -8px;
    line-height: 11px;
    font-size: 9px;
    font-weight: 700;

    & > svg {
      height: 11px;
      width: 11px;

      & > path {
        fill: #0066ff;
      }
    }
  }

  @media (max-width: 1100px) {
    margin-right: unset;
    width: unset;

    & > span {
      position: absolute;
      top: 0px;
      right: 10px;
    }

    & > h4 {
      margin-top: 6px;
    }
  }
`;

export const ProductDescription = styled.div`
  width: 654px;
  min-height: 78px;
  background: transparent;
  border: 1px solid rgba(112, 112, 112, 0.3);
  border-radius: 9px;
  padding: 10px 16px;
  position: relative;

  & > p {
    font-size: 9px;
    color: #352f40;
    line-height: 15px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 67px;
    min-height: unset;
    width: calc(100% - 20px);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
  }
`;

export const WinWinInfo = styled.div<{IsWinWin?: boolean}>`
  visibility: ${({IsWinWin}) => (IsWinWin ? 'visible' : 'hidden')};
  margin-top: 3px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-top: 2px;
    margin-left: 5px;
  }
`;

export const SingleProductWithViewsCountWrapper = styled.div<{
  isHovered?: boolean;
}>`
  height: 238px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const CoverWithDescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  /* height: 159px; */
  background: ${({theme}) => theme.colors.headerContentBackground};
  border: 1px solid transparent;
  transition: border 0.15s;
  border-radius: 16px;
  padding: 6px;

  &:hover {
    border: 1px solid ${({theme}) => theme.colors.blueTheme};
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 21px;
    padding: 0 4px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const DesktopPriceWithWinWinWrapper = styled.div`
  /* width: 255px; */
  /* width: 275px; */
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* position: absolute;
  right: 15px;
  top: 3px; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 39px;
    align-items: center;
    padding: 0px 23px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    border-top-right-radius: 0px;
  }
`;

export const DesktopPriceWithTitleWrapper = styled.div`
  display: flex;
  gap: 14.5px;

  & > h4 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};

    & > span {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.15px;
      line-height: 21px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.15px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    justify-content: center;

    & > h4 {
      display: flex;
      gap: 7px;
      margin-top: unset;
    }
  }
`;

export const DesktopWinWinInfo = styled.div<{IsWinWin?: boolean}>`
  display: ${({IsWinWin}) => (IsWinWin ? 'unset' : 'none')};
  margin-top: 2px;
`;

export const PriceInfoWrapper = styled.div`
  /* width: 85px;
  height: 22px; */
  /* border-radius: 5px; */
  /* border: none; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(47, 47, 104, 0.05); */

  & > p {
    font-size: 13px;
    font-weight: 700;
    color: #07020f;
    letter-spacing: 0.1px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    & > p {
      font-size: 18px;
      font-weight: 700;
      color: #07020f;
      letter-spacing: -0.15px;
      line-height: 24px;
    }
  }
`;

export const InColumnWrapper = styled.div`
  width: calc(100% - 17px - 158px - 18.5px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InLineWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PublishDataWrapper = styled.div`
  /* width: 98%; */
  position: absolute;
  bottom: -15px;
  left: 6px;
  display: flex;
  gap: 35px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    position: unset;
    flex-direction: column;
    gap: unset;
    margin-bottom: 6px;
  }
`;

export const TextInLine = styled.div`
  display: flex;
  gap: 4px;

  & > h5 {
    font-weight: 500;
    letter-spacing: 0.15px;
    font-size: 9px;
    color: rgba(53, 47, 64, 0.5);
  }

  & > p {
    font-weight: 500;
    letter-spacing: 0.15px;
    font-size: 9px;
    color: rgba(53, 47, 64, 1);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    & > h5 {
      font-weight: 500;
      letter-spacing: 0.15px;
      font-size: 9px;
      color: rgba(53, 47, 64, 0.5);
      line-height: 15px;
    }

    & > p {
      font-weight: 500;
      letter-spacing: 0.15px;
      font-size: 9px;
      color: rgba(53, 47, 64, 1);
      line-height: 15px;
    }

    &:first-of-type {
      margin-left: 6px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }
`;

export const MobileTextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  width: 100%;
`;

export const SvgRightTopWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
  display: flex;
  gap: 10px;
  & > svg {
    & > g {
      & > path {
        fill: ${({theme}) => theme.colors.mobileSearchBarTextColor};
      }
    }
  }
`;
