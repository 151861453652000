import React, {FC, useContext, useRef, useState} from 'react';
import {
  VideoAddButtonWrapper,
  VideoBlockContent,
  VideoBlockHeader,
  VideoBlockWrapper,
} from './styles';

import {VideoComponent} from './video';
import {Block, BlockFile} from '../../interface';
import {useCookies} from 'react-cookie';

import useAlert from 'hooks/useAlert';
import api from '../../../../../services/axiosConfig';
import {
  ButtonBg,
  ButtonLeftAndRightWrapper,
} from '../RecomendedProductionBlock/styles';
import {hexToRgb} from '../../index';
import {EditHoverContext} from '../../../../../contexts/EditHoverContext';

interface Props {
  theme: string;
  block: Block;
  isEditing: boolean;
  mobileOverview: boolean;
  updateVideo: ({blockFileIds: BlockFileIds, blockFiles: BlockFiles}) => void;
}

export const VideoBlockComponent: React.FC<Props> = ({
  theme,
  block,
  isEditing,
  mobileOverview,
  updateVideo,
}) => {
  const {BlockFiles, WebArea, BackgroundColorHex} = block;
  const {Width} = WebArea;
  const initVideos = BlockFiles
    ? BlockFiles
    : [
        {
          BlockId: '',
          CreationDate: '',
          FileName: '',
          FilePath: '',
          Id: '',
        },
      ];

  const {Alert} = useAlert();

  const [videoBlock, setVideoBlock] = useState<BlockFile[]>(initVideos);
  const [cookies, setCookie] = useCookies(['token']);
  const imgRef = useRef<HTMLInputElement>(document.createElement('input'));
  const [blockHover, setBlockHover] = useContext(EditHoverContext);

  const getImageFile = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();
      try {
        if (
          extFile === 'mp4' ||
          extFile === 'mov' ||
          extFile === 'wmv' ||
          extFile === 'avi' ||
          extFile === 'avchd' ||
          extFile === 'flv' ||
          extFile === 'f4v' ||
          extFile === 'swf' ||
          extFile === 'mkv' ||
          extFile === 'webm' ||
          extFile === 'html5'
        ) {
          const formData = new FormData();
          formData.append('File', e.target.files[0]);

          const body = formData;
          const response = await api.post(
            `Producer/block/${block.Id}/BlockFile`,
            body,
            {
              headers: {Authorization: `bearer ${cookies.token}`},
            },
          );

          const newBlockFile = {
            BlockId: block.Id,
            CreationDate: '',
            FileName: '',
            FilePath: URL.createObjectURL(e.target.files[0]),
            Id: response.data.Response.Id,
          };

          updateVideo({
            blockFileIds: [response.data.Response.Id],
            blockFiles: [newBlockFile],
          });

          setVideoBlock([newBlockFile]);
          Alert(`Dodano plik ${e.target.files[0].name}`, 'Komunikat');
        } else {
          Alert(
            'Wybierz video w jednym z podanych formatów: .mp4, .mov, .wmv, .avi, .avchd, .flv, .f4v, .swf, .mkv, .webm, .html5',
            'Komunikat',
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const hoverTextOn = (text: string) => {
    setBlockHover((prev) => ({Active: true, Text: text}));
  };

  const hoverTextOff = () => {
    setBlockHover((prev) => ({Active: false, Text: ''}));
  };

  function deleteVideo() {
    setVideoBlock([]);
    updateVideo({blockFileIds: [], blockFiles: []});
  }

  return (
    <VideoBlockWrapper
      bgColor={hexToRgb(block.BackgroundColorHex)}
      backgroundAlpha={block.BlockOpacityIn0To100Ratio}
    >
      {/*<VideoBlockHeader theme={theme}>*/}
      {/*  <svg*/}
      {/*    xmlns="http://www.w3.org/2000/svg"*/}
      {/*    width="27.376"*/}
      {/*    height="21"*/}
      {/*    viewBox="0 0 27.376 21"*/}
      {/*  >*/}
      {/*    <path*/}
      {/*      id="Icon_zocial-persona"*/}
      {/*      data-name="Icon zocial-persona"*/}
      {/*      d="M11.233,16.481a51.26,51.26,0,0,1-5.675-.192s.222-3.388,1.772-4,1.93-.523,2.467-.848l.04-.022v-.912A4.2,4.2,0,0,1,8.669,8.594a1.514,1.514,0,0,1-.441-1.033,1.407,1.407,0,0,1,.215-.735c.092-1.91,1.307-3.175,2.794-3.175s2.7,1.264,2.794,3.175a1.4,1.4,0,0,1,.215.735A1.514,1.514,0,0,1,13.8,8.594a4.192,4.192,0,0,1-1.167,1.914v.912l.04.022c.539.325.918.238,2.468.848s1.771,4,1.771,4a51.529,51.529,0,0,1-5.683.192M18.37-.035H.994a1,1,0,0,0-1,1v19a1,1,0,0,0,1,1H18.37l9-10.5-9-10.5Z"*/}
      {/*      transform="translate(0.006 0.035)"*/}
      {/*      fill="#0c426a"*/}
      {/*    />*/}
      {/*  </svg>*/}

      {/*  <h4>Video</h4>*/}
      {/*</VideoBlockHeader>*/}
      <VideoBlockContent>
        {isEditing ? (
          <ButtonLeftAndRightWrapper Width={Width}>
            <ButtonBg
              onMouseEnter={() => hoverTextOn('Usuń video')}
              onMouseLeave={hoverTextOff}
              onClick={deleteVideo}
              className="DeleteTrackSvg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.3"
                height="13.5"
                viewBox="0 0 12.3 13.5"
              >
                <g
                  id="Icon_feather-trash"
                  data-name="Icon feather-trash"
                  transform="translate(0.75 0.75)"
                >
                  <path
                    id="Path_3663"
                    data-name="Path 3663"
                    d="M4.5,9H15.3"
                    transform="translate(-4.5 -6.6)"
                    fill="none"
                    stroke={`rgb(${hexToRgb(BackgroundColorHex)})`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                  <path
                    id="Path_3664"
                    data-name="Path 3664"
                    d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                    transform="translate(-6.3 -3)"
                    fill="none"
                    stroke={`rgb(${hexToRgb(BackgroundColorHex)})`}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </ButtonBg>
          </ButtonLeftAndRightWrapper>
        ) : null}
        {videoBlock.length > 0 ? (
          <VideoComponent
            video={videoBlock[0].FilePath}
            block={block}
            theme={theme}
            isEditing={isEditing}
            deleteVideo={deleteVideo}
          />
        ) : (
          <VideoAddButtonWrapper
            onClick={() => imgRef.current.click()}
            theme={theme}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="9"
              viewBox="0 0 9 9"
            >
              <g
                id="Icon_feather-plus-circle"
                data-name="Icon feather-plus-circle"
                transform="translate(0.5 0.5)"
                opacity="0.76"
              >
                <path
                  id="Path_3319"
                  data-name="Path 3319"
                  d="M11,7A4,4,0,1,1,7,3,4,4,0,0,1,11,7Z"
                  transform="translate(-3 -3)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_3320"
                  data-name="Path 3320"
                  d="M18,12v3.2"
                  transform="translate(-14 -9.6)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
                <path
                  id="Path_3321"
                  data-name="Path 3321"
                  d="M12,18h3.2"
                  transform="translate(-9.6 -14)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </g>
            </svg>
            <input
              type="file"
              onChange={getImageFile}
              accept=".mp4, .mov, .wmv, .avi, .avchd, .flv, .f4v, .swf, .mkv, .webm, .html5,"
              hidden
              ref={imgRef}
            />
          </VideoAddButtonWrapper>
        )}
      </VideoBlockContent>
    </VideoBlockWrapper>
  );
};
