import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  border-radius: 24px;
  background: ${({theme}) => theme.colors.bgWhite};
  height: 396px;
  display: flex;
  padding: 2px 2px 0px;
  align-items: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 36px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.12);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* display: none; */
    height: unset;
    padding: 0px;
    width: 392px;
    padding: 10px 2px 0px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: calc(100% - 14px);
    padding: 2px 2px 0px;
  }
`;

export const TopHeader = styled.div`
  width: 100%;
  display: flex;
  height: 244px;
  position: relative;

  & > img {
    height: 244px;
    width: 244px;
    border-radius: 22px;
  }

  & > a {
    text-decoration: none;
    position: absolute;
    font-size: 11px;
    right: 14.5px;
    bottom: 14px;
    font-weight: 400;
    letter-spacing: -0.05px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #0091ff;

    & > svg {
      margin-top: 2px;
    }
  }

  & > div {
    width: calc(100% - 244px);
    padding: 36px 31px 0px;
    display: flex;
    flex-direction: column;

    & > h2 {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: -0.05px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      text-transform: uppercase;
      margin-bottom: 7px;
    }

    & > h1 {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.15px;
      margin-bottom: 12px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }

    & > p {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.15px;
      line-height: 24px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    height: unset;

    & > img {
      border-radius: 18px;
      width: 372px;
      margin: 0 auto;
      height: 372px;
      aspect-ratio: 1;
      /* height: 420px; */
    }

    & > a {
      top: 382px;
      bottom: unset;
      color: transparent;

      & > svg {
        width: 9.37px;
        height: 16px;
      }
    }

    & > div {
      padding: 11px 20px 38px 11px;
      width: 100%;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    & > img {
      width: 100%;
      height: 100%;
      border-radius: 22px;
    }
  }
`;

export const SliderWrapper = styled.div`
  display: none;
  padding-bottom: 45px;
  /* margin-bottom: 10px; */
  width: 100%;
  /* height: 330px; */

  .slick-dots {
    bottom: -24px;
  }

  .slick-dots li {
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp100};
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: unset;
  }
`;

export const ProducersWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2px;
  align-items: center;
  justify-content: flex-start;
  gap: 3px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileProducersWrapper = styled.div`
  display: flex !important;
  width: 100%;
  margin-top: 2px;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

export const SingleProducer = styled.div`
  width: 146px;
  height: 146px;
  border-radius: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(112, 112, 112, 0.3);
  position: relative;
  cursor: pointer;

  &:hover {
    & > div {
      opacity: 1;
    }
  }

  & > img {
    height: 146px;
    width: 146px;
    border-radius: 21px;
  }

  & > div {
    transition: all 0.1s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    opacity: 0;
    width: 100%;
    position: absolute;
    bottom: 0px;
    height: 100%;
    padding: 7px 15px 8px;
    border-radius: 0px 0px 11px 11px;
    background: rgba(255, 255, 255, 0.5);

    & > h1 {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: -0.15px;
      color: #17161d;
      text-align: left;
      width: 100%;
    }

    & > p {
      font-size: 11px;
      font-weight: bold;
      letter-spacing: -0.15px;
      color: rgba(23, 22, 29, 0.7);
      text-align: left;
      width: 100%;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 138px;
    height: 138px;

    & > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const MobileSingleProducer = styled.div`
  margin-bottom: 18px;
  width: 172px;
  height: 235px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid rgba(112, 112, 112, 0.3); */
  position: relative;
  cursor: pointer;

  &:hover {
    & > div {
      opacity: 1;
    }
  }

  & > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background: ${({theme}) => theme.colors.popularCategoryBackground};
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
    border-radius: 24px;
    padding: 6px 2px 0px;
    box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.12);

    & > img {
      min-height: 158px;
      aspect-ratio: 1;
      width: 158px;
      border: 1px solid rgba(112, 112, 112, 0.3);
      border-radius: 18px;
    }

    & > div {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      /* gap: 5px; */
      height: 48px;
    }

    & p {
      /* height: 27px; */
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 11px;
      font-weight: 400;
      letter-spacing: -0.15px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }

    & > h1 {
      height: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      font-weight: bolder;
      letter-spacing: -0.15px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }

    /* & > div {
      transition: all 0.1s;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      opacity: 0;
      width: 100%;
      position: absolute;
      bottom: 0px;
      height: 100%;
      padding: 7px 15px 8px;
      border-radius: 0px 0px 11px 11px;
      background: rgba(255, 255, 255, 0.5);

      & > h1 {
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -0.15px;
        color: #17161d;
        text-align: left;
        width: 100%;
      }

      & > p {
        font-size: 11px;
        font-weight: bold;
        letter-spacing: -0.15px;
        color: rgba(23, 22, 29, 0.7);
        text-align: left;
        width: 100%;
      }
    } */
  }
`;
