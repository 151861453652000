import styled, {css} from 'styled-components';
import {
  CheckboxInfoWrapper,
  ConditionDetailsWrapper,
  BlueHeaderComponent,
  ConditionSectionDescription,
  SectionHeaderNoEditing,
  SellerObligationWrapper,
  AddSellerObligationWrapper,
  AddSellerObligationInputWrapper,
  SelectViewContainer,
  SmallSectionTitleWithSlider,
  ConditionSlider,
  ConditionTitleWithInputWrapper,
  ConditionTitleWithInputAndSelectWrapper,
  DisplayGrossValueNoEditing,
  ConditionTitleWithSliderAndSelectWrapper,
  DisplayGrossValueWithTaxNoEditing,
  StyledInputView,
} from 'pages/MyStudio/Conditions/EditCondition';
import {InputView, SelectView} from 'pages/MyAccount/Settings/styles';

export const ConditionDetailsBox = styled.div`
  height: calc(100% - 5px);
  margin-top: 5px;
  width: 100%;
  border-radius: 0 0 24px 24px;
  background: ${({theme}) => theme.colors.bodyBg};
`;

export const GalleryWithPlaceholderWrapper = styled.div`
  width: 100%;
  height: 128px;
  display: flex;
  margin-top: 7px;
  margin-bottom: 7px;
  gap: 3px;
  display: flex;
  align-items: center;
`;

export const GalleryWrapper = styled.div<{isProductEditing: boolean}>`
  width: ${({isProductEditing}) =>
    isProductEditing ? 'calc(100% - 128px - 3px)' : '100%'};
  display: flex;
  overflow: hidden;
  position: relative;
`;

export const ImagesContainer = styled.div`
  display: flex;
  transition: transform 0.3s ease;
`;

export const ImageWrapper = styled.div`
  flex: 0 0 128px;
  height: 128px;
  margin-right: 3px;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Placeholder = styled.div`
  min-width: 128px;
  max-width: 128px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  position: relative;

  & > svg {
    width: unset !important;
    height: unset !important;
    position: unset !important;

    & path {
      fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-width: unset;
    max-width: unset;
    height: 174px;
    width: 174px;
    border-radius: 11px;
  }
`;

export const MobileGallerySingleImage = styled.div`
  height: 174px;
  width: 174px;
  border-radius: 11px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > img {
    height: 100%;
    width: 100%;
    border-radius: 11px;
  }
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #ff0000;
`;

export const ConditionTextareaWrapper = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
}>`
  position: relative;
  width: 100%;
  border-radius: 5px;
  min-height: 148px;
  background: ${({isEditing, isError}) =>
    isEditing
      ? isEditing && isError
        ? '#FF7E7D'
        : 'transparent'
      : 'transparent'};
  border: ${({theme}) => `1px solid ${theme.colors.fontBlackOp15}`};
  color: ${({isEditing}) => (isEditing ? `#707070` : `#07020F`)};

  & > h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.625px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    position: absolute;
    top: 2px;
    left: 11.5px;
  }

  & > h4 {
    display: ${({isEditing}) => (isEditing ? 'unset' : 'none')};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border: none;
  }
`;

export const ConditionStyledTextarea = styled.textarea<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  width: 100%;
  min-height: 148px;
  border: none;
  padding: 20px 11.5px 6px;
  background: transparent;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-size: 12px;
  border: solid 1px #e1e1e1;
  line-height: 24px;
  border-radius: 5px;
  resize: none;
  outline: none;
  font-weight: 400;

  &:focus {
    border: 1px solid #0091ff;
  }
`;

export const ArrowButtonRight = styled.button`
  position: absolute;
  top: 50%;
  width: 19px;
  height: 19px;
  transform: translateY(-50%);
  background: #0091ff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  left: unset;
`;

export const ArrowButtonLeft = styled(ArrowButtonRight)`
  left: 5px;
  right: unset;
`;

export const BackArrowButtonLeft = styled.button`
  position: absolute;
  top: 17px !important;
  width: 19px;
  height: 19px;
  /* transform: translateY(-50%); */
  background: #0091ff !important;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 10px;
  top: 16px;
`;

export const DeleteConditionButton = styled.button`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  background: #ff0300;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s;
  position: absolute;
  top: 14px;
  right: 10px;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const PlaceholderImage = styled.img`
  width: unset !important;
  height: unset !important;
`;

export const SaveConditionButton = styled.button`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  background: ${({theme}) => theme.colors.blueTheme};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;
  pointer-events: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MainWrapperStep1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  top: 88px;
  pointer-events: none;
`;

export const MobileMainWrapper = styled.div`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #f8f8f8; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    background: ${({theme}) => theme.colors.mobileOfferDetailTopBackground};
  }
`;

export const MobileMain = styled.div`
  width: 100%;
  min-height: calc(100vh - 105px);
  margin-bottom: 57px;
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  flex-direction: column;
  align-items: center;
  background: transparent;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    padding: unset;
  }
`;

export const Main = styled.div`
  width: 1020px;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 9px;
  border-radius: 9px 9px 21px 21px;
  /* margin-bottom: 20px; */
  pointer-events: all;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1100px) {
    width: 380px;
    height: unset;
    margin-bottom: 20px;
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const FirstStepMain = styled.div`
  width: 1020px;
  min-height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.columnBorder};
  background: ${({theme}) => theme.colors.headerContentBackground};
  position: relative;
  border-radius: 24px 24px 21px 21px;
  /* margin-bottom: 20px; */
  pointer-events: all;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1100px) {
    width: 380px;
    height: unset;
    margin-bottom: 20px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    padding: 23px 36px;
    border: none;
    border-radius: 0;
  }
  @media (max-width: 400px) {
    padding: 8px;
  }
`;

export const InputViewStyledInput = styled.input<{isEditing?: boolean}>`
  background: ${({isEditing}) =>
    isEditing
      ? `${({theme}) => theme.colors.headerContentBackground} !important`
      : 'inherit'};
  border-radius: 5px;
`;

export const TextWithLink = styled.div`
  display: flex;
  width: 100%;
  gap: 2px;
  margin-bottom: 19px;

  & > p {
    font-size: 9px;
    letter-spacing: 0.25px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    font-weight: 400;
  }

  & > span {
    font-size: 9px;
    letter-spacing: 0.25px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    font-weight: 400;
    text-decoration: underline;

    & a {
      color: ${({theme}) => theme.colors.grayFontOp100};
    }
  }
`;

export const OfferSectionWrapper = styled.div`
  width: 1020px;
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 24px 24px 21px 21px;
  /* margin-bottom: 20px; */
  pointer-events: all;
  border: 1px solid ${({theme}) => theme.colors.columnBorder};
  box-shadow: ${({theme}) => `0px 15px 30px ${theme.colors.blackBoxShadow}`};

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1100px) {
    width: 380px;
    height: unset;
    margin-bottom: 20px;
  }
`;

export const MobileAvailibityDurationWrapper = styled.div`
  /* width: 367px; */
  width: 100%;
  gap: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 35px;
  margin-bottom: 66px;
`;

export const MobileOfferSectionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > p {
    width: 348px;
    text-align: center;
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    line-height: 18px;
    margin-bottom: 25px;
    margin-top: 13px;
    letter-spacing: -0.25px;
  }

  & > h5 {
    margin-top: 34px;
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 18px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: -0.15px;

    & > span {
      font-size: 18px;
      font-weight: bold;
      color: #0091ff;
      letter-spacing: -0.15px;
    }
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  min-height: 55px;
  border-radius: 24px 24px 0 0;
  background: ${({theme}) => theme.colors.pageHeader};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  & > p {
    color: ${({theme}) => theme.colors.headerBlackFont};
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.15px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const FirstStepContentWrapper = styled.div`
  width: 100%;
  padding: 17px 24px 56px;
  display: flex;
  align-items: center;
  flex-direction: column;

  & > h5 {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 25px;
    letter-spacing: -0.15px;
  }
  @media (max-width: 1024px) {
    margin-top: 10px;
    padding: 17px 0px 56px;
    & > h5 {
      font-size: 18px;
      line-height: 21px;
      font-weight: bold;
    }
  }
`;

export const SelectProductTypeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 30px;
  }
  @media (max-width: 400px) {
    align-items: center;
  }
`;

export const SingleProductType = styled.div`
  width: 314px;
  display: flex;
  flex-direction: column;
  gap: 19px;

  & > p {
    color: ${({theme}) => theme.colors.grayFontOp100};
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    text-align: center;
  }
  @media (max-width: 1024px) {
    width: auto;
  }
  @media (max-width: 400px) {
    width: 90%;
  }
`;

export const ProductTypeButton = styled.div`
  width: 100%;
  height: 78px;
  border-radius: 11px;
  background: ${({theme}) => theme.colors.headerContentBackground};
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  transition: border 0.1s;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > h1 {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
  }

  & > svg {
    &:first-of-type {
      position: absolute;
      left: 24px;
    }

    &:last-of-type {
      position: absolute;
      right: 24px;
    }
  }

  & svg {
    & path {
      fill: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }

  &:hover {
    border: 1px solid #0066ff;
  }
  @media (max-width: 1024) {
    & svg {
      & path {
        fill: auto;
      }
    }
  }
`;

export const ProductTypeButtonMobile = styled.div<{isActive: boolean}>`
  width: 100%;
  /* height: 78px; */

  background: ${({theme}) => theme.colors.headerContentBackground};

  transition: border 0.1s;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > h1 {
    color: ${({theme, isActive}) =>
      isActive ? theme.colors.blueTheme : theme.colors.fontBlackOp100};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
  }

  & > svg {
    &:first-of-type {
      position: absolute;
      left: -10px;
      top: 0px;
    }

    &:last-of-type {
      position: absolute;
      right: 0px;
      top: 0px;
    }
    & > path {
      fill: ${({isActive, theme}) =>
        isActive ? theme.colors.blueTheme : theme.colors.fontBlackOp100};
    }
    & > g {
      & > path {
        fill: ${({isActive, theme}) =>
          isActive ? theme.colors.blueTheme : theme.colors.fontBlackOp100};
      }
    }
  }
`;

export const MobileSaveProductConditionButton = styled.button`
  width: 312px;
  height: 44px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 16px;
  font-size: 15px;
  background: #0091ff;
  margin: 30px auto 0;
`;

export const MobileBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
  & > button {
    width: 312px;
    height: 44px;
    color: white;
    border: none;
    border-radius: 16px;
    background: #0091ff;
  }
`;

export const ProductTypeButtonTextWrapper = styled.div<{isActive: boolean}>`
  width: 257px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  & > h1 {
    color: ${({theme, isActive}) =>
      isActive ? theme.colors.blueTheme : theme.colors.fontBlackOp100};
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
  }

  & > p {
    color: ${({theme}) => theme.colors.grayFontOp100};
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    /* text-align: center; */
  }
`;

export const ProductDataWithConditionsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`;

export const MobileHeader = styled.div`
  height: 54px;
  width: 100%;
  background: ${({theme}) => theme.colors.bgWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  border-bottom: 1px solid
    ${({theme}) => theme.colors.mobileOfferDetailHeaderBorderColor};

  & > h1 {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: 0.07px;
    font-weight: 400;
  }

  & > svg {
    left: 21px;
    position: absolute;
  }
`;

export const UserDataSelectViewContainer = styled.div<{isEditing?: boolean}>`
  width: 358px;
  margin-top: 0px;
  margin-bottom: ${({isEditing}) => (isEditing ? '15px' : '12px')};
  margin-left: auto;
  margin-right: auto;

  & > div {
    margin-top: 0px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 358px;

    & > input {
      font-size: 13px;
      font-weight: 400 !important;
    }

    & > div {
      background: transparent;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: ${({isEditing}) => (isEditing ? '0 6px' : '0px')};
      border-radius: ${({isEditing}) => (isEditing ? '5px' : '0px')};
      /* border: ${({isEditing}) =>
        isEditing ? '1px solid red !important' : 'none'}; */
      border: ${({isEditing, theme}) =>
        isEditing ? `1px solid ${theme.colors.fontBlackOp15}` : `none`};
      border-bottom: ${({theme}) => `1px solid ${theme.colors.fontBlackOp15}`};

      & > input {
        font-size: 13px;
        font-weight: 400 !important;
      }

      & > p {
        position: unset;
        font-size: 13px;
      }

      & > select {
        width: 55%;
        border: none;
        font-size: 13px;
      }
    }
  }
`;

export const MobileDisplayDescriptionWrapper = styled.div`
  width: 358px;
  display: flex;
  flex-direction: column;
  gap: 9px;

  & > h5 {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    letter-spacing: 0.1px;
    font-weight: 400;
  }

  & > p {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: 0.1px;
    font-weight: 400;
  }
`;

export const MobileHeaderWithButtonWrapper = styled.div`
  width: 367px;
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 35px;
  position: relative;

  & > div {
    position: unset !important;
  }

  & > h1 {
    font-size: 18px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: -0.15px;
    font-weight: bold;
  }
`;

export const MobileProductDataWrapper = styled.div`
  width: 367px;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MobileConditionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProductDataWrapper = styled.div<{isEditing?: boolean}>`
  width: 678px;
  height: 565px;
  border-radius: 24px 24px 24px 24px;
  /* border: 1px solid rgba(112, 112, 112, 0.3); */
  background: transparent;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: ${({theme, isEditing}) =>
    isEditing
      ? `0px 15px 30px ${theme.colors.headerBoxShadow}`
      : `0px 15px 30px ${theme.colors.blackBoxShadow}`};
  border: 1px solid
    ${({isEditing, theme}) =>
      isEditing ? '#0091FF' : theme.colors.columnBorder};
`;

export const ConditionsWrapper = styled.div<{isEditing?: boolean}>`
  width: 334px;
  height: 565px;
  border-radius: 24px 24px 24px 24px;
  background: transparent;
  box-shadow: ${({theme, isEditing}) =>
    isEditing
      ? `0px 15px 30px ${theme.colors.headerBoxShadow}`
      : `0px 15px 30px ${theme.colors.blackBoxShadow}`};
  border: 1px solid
    ${({isEditing, theme}) =>
      isEditing ? '#0091FF' : theme.colors.columnBorder};
  display: flex;
  flex-direction: column;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const SectionHeader = styled.div<{isEditing?: boolean}>`
  position: relative;
  width: 100%;
  background: transparent;
  height: 55px;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid
    ${({isEditing, theme}) =>
      isEditing ? '#0091FF' : theme.colors.columnBorder};

  & > p {
    color: ${({isEditing, theme}) =>
      isEditing ? '#0091FF' : theme.colors.grayFontOp100};
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.15px;
  }

  & > button {
    position: absolute;
    top: 2px;
    right: 0;
    border: none;
    background: transparent;
  }
`;

export const OfferSectionContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  display: flex;
  background: ${({theme}) => theme.colors.headerContentBackground};
  border-radius: 0px 0px 21px 21px;
  justify-content: space-between;
  padding: 10px 12px 13px 24px;
`;

export const OfferSectionLeftSide = styled.div<{isReadyForOffer?: boolean}>`
  width: ${({isReadyForOffer}) => (!isReadyForOffer ? '650px' : '272px')};
  display: flex;
  flex-direction: column;

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const OfferSectionCenterSide = styled.div<{isReadyForOffer?: boolean}>`
  display: ${({isReadyForOffer}) => (!isReadyForOffer ? 'none' : 'flex')};
  width: 300px;
  flex-direction: column;
  justify-content: space-evenly;

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const OfferSliderWrapper = styled.div`
  width: 100%;
  padding: 0px 0px 6px 7px;
  border-bottom: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  display: flex;
  transition: all 0.15s ease-in-out;
  align-items: center;
  justify-content: space-between;

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.0625;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const OfferDisplayData = styled.div`
  width: 100%;
  padding: 0px 0px 6px 0px;
  border-bottom: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  display: flex;
  transition: all 0.15s ease-in-out;
  align-items: center;
  justify-content: space-between;

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.0625;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }

  & > h5 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.0625;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 367px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
  }
`;

export const OfferSlider = styled.div<{isHighlighted: boolean}>`
  width: 30.46px;
  height: 18px;
  border-radius: 15px;
  background: transparent;
  transition: all 0.15s ease-in-out;
  border: 1px solid
    ${({isHighlighted, theme}) =>
      isHighlighted ? '#0066FF' : `${theme.colors.fontBlackOp15}`};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  & > div {
    position: absolute;
    width: 12.6px;
    height: 12.6px;
    border-radius: 50%;
    transition: all 0.15s ease-in-out;
    background: ${({isHighlighted, theme}) =>
      isHighlighted ? '#0066FF' : `${theme.colors.fontBlackOp15}`};
    left: ${({isHighlighted}) => (isHighlighted ? '15.1px' : '2.7px')};
  }
`;

export const OfferCounter = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;

  & > h5 {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.0625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }

  & > p {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.0625px;
    line-height: 24px;
    color: #ff0300;
  }
`;

export const OfferSectionRightSide = styled.div`
  width: 312px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: #352f40;
  }
`;

export const StyledStatusWithTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  & > p {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 21x;
    color: ${({theme}) => theme.colors.grayFontOp100};
    position: absolute;
    right: 120px;
    top: -4.5px;
  }
`;

export const StyledStatusWrapper = styled.div<{ProductDisplayStatus?: number}>`
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 0 auto;

  & > h1 {
    letter-spacing: 0.1px;
    font-size: 11px;
    font-weight: 500;

    ${({ProductDisplayStatus, theme}) => {
      switch (ProductDisplayStatus) {
        case 0:
        case 99:
          return css`
            color: ${theme.colors.statusNameBlack};
          `;
        case 1:
          return css`
            color: #00d10f;
          `;
        case 3:
        case 4:
          return css`
            color: #0091ff;
          `;
        case 2:
        case 5:
          return css`
            color: #fb9614;
          `;
        case 6:
          return css`
            color: #ff0300;
          `;

        default:
          return css`
            color: #fb9614;
          `;
      }
    }}
  }
`;

export const MobileStyledStatusWrapper = styled(StyledStatusWrapper)<{
  ProductDisplayStatus?: number;
}>`
  width: 280px;
`;

export const StyledStatusLine = styled.div<{ProductDisplayStatus?: number}>`
  width: 220px;
  border-bottom: 3px solid ${({theme}) => theme.colors.statusDefaultLine};
  position: relative;
  border-radius: 25px;

  & > div {
    position: absolute;
    left: 0px;
    border-radius: 25px;
    height: 3px;

    ${({ProductDisplayStatus, theme}) => {
      switch (ProductDisplayStatus) {
        case 0:
        case 99:
          return css`
            background: ${theme.colors.statusLineBlack};
            width: 30px;
          `;
        case 1:
          return css`
            background: #00d10f;
            width: 60px;
          `;
        case 3:
        case 4:
          return css`
            background: #0091ff;
            width: 220px;
          `;
        case 2:
        case 5:
          return css`
            background: #fb9614;
            width: 155px;
          `;
        case 6:
          return css`
            background: #ff0300;
            width: 220px;
          `;

        default:
          return css`
            background: #fb9614;
            width: 155px;
          `;
      }
    }}
  }
`;

export const MobileStyledStatusLine = styled(StyledStatusLine)<{
  ProductDisplayStatus?: number;
}>`
  width: 280px;

  & > div {
    ${({ProductDisplayStatus, theme}) => {
      switch (ProductDisplayStatus) {
        case 0:
        case 99:
          return css`
            background: ${theme.colors.statusLineBlack};
            width: 38.5px;
          `;
        case 1:
          return css`
            background: #00d10f;
            width: 77.5px;
          `;
        case 3:
        case 4:
          return css`
            background: #0091ff;
            width: 280px;
          `;
        case 2:
        case 5:
          return css`
            background: #fb9614;
            width: 198px;
          `;
        case 6:
          return css`
            background: #ff0300;
            width: 280px;
          `;

        default:
          return css`
            background: #fb9614;
            width: 198px;
          `;
      }
    }}
  }
`;

export const StyledStatus = styled.h2<{ProductDisplayStatus: number}>`
  font-size: 13px;
  font-weight: 500;
  width: 134px;
  height: 28px;
  letter-spacing: -0.15px;
  inline-size: 112px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  overflow-wrap: break-word;
  text-align: center;
  color: black;

  background: ${({ProductDisplayStatus}) =>
    ProductDisplayStatus === 0
      ? '#FB9B1F'
      : ProductDisplayStatus === 1
      ? '#19d627'
      : ProductDisplayStatus === 2
      ? '#19d627'
      : ProductDisplayStatus === 3
      ? '#7270f2'
      : ProductDisplayStatus === 4
      ? '#0066FF'
      : ProductDisplayStatus === 5
      ? '#d61919'
      : ProductDisplayStatus === 6
      ? '#d61919'
      : '#FB9B1F'};
`;

export const PublishOfferButton = styled.button<{isReadyForOffer?: boolean}>`
  width: 100%;
  height: 44px;
  cursor: pointer;
  border-radius: 16px;
  border: none;
  cursor: ${({isReadyForOffer}) =>
    isReadyForOffer ? 'pointer' : 'not-allowed'};
  background: ${({isReadyForOffer, theme}) =>
    isReadyForOffer
      ? theme.colors.blueTheme
      : theme.colors.disabledButtonBackground};
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.07px;
  color: white;
`;

export const MobileGenerateImageButton = styled.button`
  width: 284px;
  height: 36px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background: ${({theme}) => theme.colors.blueTheme};
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.07px;
  color: white;
  position: absolute;
  bottom: 15px;
  left: 17px;
`;

export const GenerateImageButton = styled.button`
  width: 84px;
  height: 22px;
  cursor: pointer;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  background: ${({theme}) => theme.colors.blueTheme};
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.07px;
  color: white;
  position: absolute;
  bottom: 4.5px;
  left: 5.5px;
`;

export const CreateConditionsButton = styled.button<{
  isReadyForOffer?: boolean;
}>`
  width: 210px;
  height: 24px;
  cursor: pointer;
  border-radius: 15px;
  margin-top: 18px;
  border: none;
  cursor: ${({isReadyForOffer}) =>
    isReadyForOffer ? 'pointer' : 'not-allowed'};
  background: ${({isReadyForOffer, theme}) =>
    isReadyForOffer
      ? theme.colors.blueTheme
      : theme.colors.disabledButtonBackground};
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.1px;
  color: white;
`;

// export const EditSliderWrapper = styled.div<{isEditing: boolean}>`
//   position: absolute;
//   right: 5px;
//   width: 46px;
//   height: 50px;
//   border-radius: 16px;
//   cursor: pointer;
//   border: 1px solid rgba(7, 2, 15, 0.3);
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   ${({isEditing}) =>
//     isEditing
//       ? css`
//           background: rgba(137, 191, 255, 0.7);
//         `
//       : css`
//           background: rgba(47, 47, 104, 0.05);
//         `}

//   & > div {
//     border-radius: 15px;
//     width: 44px;
//     height: 38px;
//     position: absolute;
//     border: 1px solid rgba(112, 112, 112, 0.15px);
//     background: #ffffff;
//     transition: all 0.15s ease-in;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     user-select: none;

//     ${({isEditing}) =>
//       isEditing
//         ? css`
//             top: 10px;

//             & > svg {
//               & path {
//                 stroke: #3a87f0;
//               }
//             }
//           `
//         : css`
//             top: 0px;
//           `}
//   }
// `;

export const ProductDataContentWrapper = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  display: flex;
  align-items: center;
  flex-direction: column;
  background: transparent;
  border-radius: 0px 0px 24px 24px;
  padding: 8px 14px 19px 8px;
`;

export const BasicDataWrapper = styled.div`
  width: 100%;
  height: 168px;
  border-radius: 16px;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  padding: 5.5px 9px 4.5px 5px;
  margin-bottom: 12px;
`;

export const TrackPlaceholderSvgWrapper = styled.div`
  position: unset !important;

  & > svg {
    width: unset !important;
    height: unset !important;
    position: unset !important;

    & path {
      fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
    }
  }
`;

export const MobileTrackPlaceholderSvgWrapper = styled.div`
  position: unset !important;

  & > svg {
    width: 108px !important;
    height: 112px !important;
    position: unset !important;
    margin-bottom: 32px;

    & path {
      fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
    }
  }
`;

export const MobileProductImageWrapper = styled.div`
  width: 367px;
  /* width: 100%; */
  height: 367px;
  border-radius: 11px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 19px;
  margin-bottom: 26px;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};

  & > svg {
    top: 56.5px;
    position: absolute;
  }

  & > div {
    bottom: 15px;
    right: 17px;
    position: absolute;
  }

  & > img {
    border-radius: 11px;
    height: 100%;
    width: 100%;
  }
`;

export const ProductImageWrapper = styled.div`
  width: 156.5px;
  height: 156.5px;
  border-radius: 11px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};

  & > svg {
    top: 56.5px;
    position: absolute;
  }

  & > div {
    bottom: 4.5px;
    right: 5.5px;
    position: absolute;
  }

  & > img {
    border-radius: 11px;
    height: 100%;
    width: 100%;
  }
`;

export const AddImageButton = styled.button`
  border: none;
  background: #3a87f0;
  border-radius: 9px;
  color: white;
  font-size: 500;
  font-size: 11px;
  letter-spacing: 0.1px;
  height: 24px;
  width: 76px;
  cursor: pointer;
  position: absolute;
  bottom: 23.5px;
`;

export const ProductNameWithDescriptionWrapper = styled.div<{
  isProductEditing: boolean;
}>`
  width: 477px;
  height: 100%;
  display: flex;
  justify-content: ${({isProductEditing}) =>
    isProductEditing ? 'space-between' : 'space'};
  flex-direction: column;
  align-items: flex-start;

  & > h1 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    margin-top: 4px;
    margin-bottom: 2px;
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    width: 477px;
    overflow-wrap: break-word;
  }
`;

export const ProductDescriptionWrapper = styled.div<{isError?: boolean}>`
  width: 100%;
  height: 125px;
  border-radius: 5px;
  /* border: 1px solid ${({theme}) => theme.colors.fontBlackOp15}; */
  display: flex;
  flex-direction: column;
  background: ${({isError}) => (isError ? '#FF7E7D' : 'transparent')};
  position: relative;
  /* padding: 3px 12px; */

  & > h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.625px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    position: absolute;
    top: 2px;
    left: 11.5px;
  }

  & > textarea {
    width: 100%;
    height: 100%;
    border: none;
    padding: 20px 11.5px 6px;
    background: transparent;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 12px;
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
    border-radius: 5px;
    line-height: 24px;
    /* border-radius: 5px; */
    resize: none;
    outline: none;
    font-weight: 400;

    &:focus {
      border: 1px solid #0091ff;
    }
  }

  & > p {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #0066ff;
    position: absolute;
    bottom: 2px;
    right: 9px;
  }
`;

export const ProductAdditionalDescriptionWrapper = styled.div<{
  isEditing?: boolean;
}>`
  width: 100%;
  height: 228px;
  border-radius: 16px;
  border: ${({isEditing, theme}) =>
    isEditing ? 'none' : `1px solid ${theme.colors.fontBlackOp15}`};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
  margin-bottom: 9px;

  & > h5 {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.625px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    position: absolute;
    top: 2px;
    left: 11.5px;
  }

  & > textarea {
    width: 100%;
    height: 100%;
    border: none;
    padding: 20px 11.5px 6px;
    background: transparent;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 12px;
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
    border-radius: 16px;
    line-height: 24px;
    /* border-radius: 5px; */
    resize: none;
    outline: none;
    font-weight: 400;

    &:focus {
      border: 1px solid #0091ff;
    }

    &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 4px solid transparent;
    background-clip: padding-box;
  }
  }

  & > p {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.1px;
    color: #0066ff;
    position: absolute;
    bottom: 2px;
    right: 9px;
  }

  & > h4 {
    height: 100%;
    width: 100%;
    padding: 11.5px;
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.05px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    word-break: break-all;
    overflow-wrap: break-word;
  }
`;

export const CategoriesWithSymbolWrapper = styled.div`
  width: 100%;
  /* height: 69px; */
  gap: 15px;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
`;

export const CategoriesWrapper = styled.div`
  width: 100%;
  height: 100%;
  gap: 7px;
  padding-right: 62px;
  padding-left: 7px;
  justify-content: space-between;
  display: flex;
`;

export const TrackCategoriesWrapper = styled.div`
  width: 100%;
  gap: 13px 27px;
  padding-right: 62px;
  padding-left: 7px;
  display: grid;
  grid-auto-rows: 32px;
  grid-template-columns: repeat(3, 180px);
  flex-wrap: wrap;
`;

export const CategorySelectWrapper = styled.div`
  width: 180px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 7px;
  border: 1px solid rgba(112, 112, 112, 0.3);

  & > select {
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    background: transparent;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    outline: none;
    cursor: pointer;
    appearance: none;

    & > option {
      background: ${({theme}) => theme.colors.headerContentBackground};

      color: ${({theme}) => theme.colors.fontBlackOp100};
      cursor: pointer;
    }
    & > option:checked {
      color: ${({theme}) => theme.colors.headerBlackFont};
      background-color: ${({theme}) => theme.colors.pageHeader};
    }
  }

  & > svg {
    right: 12.8px;
    position: absolute;
  }
`;

export const DisplaySingleCategory = styled.div`
  width: 180px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 7px;
  border: 1px solid rgba(112, 112, 112, 0.3);

  & > p {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: 0.15px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const SymbolWithTagsWrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  padding-left: 7px;
  padding-right: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;

  & > p {
    font-size: 13px;
    padding-left: 7px;
    letter-spacing: 0.1px;
    line-height: 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({theme}) => theme.colors.fontBlackOp50};

    & > span {
      font-size: 13px;
      padding-left: 7px;
      letter-spacing: 0.1px;
      line-height: 24px;
      font-weight: 400;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const AddTagWrapper = styled.div`
  height: 22px;
  min-width: 67px;
  padding-left: 11.5px;
  padding-right: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  gap: 6.5px;

  & > input {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.625px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    width: calc(100% - 22px);
    background: transparent;
    border: none;
    outline: none;

    &::placeholder {
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
`;

export const TagsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8.5px;
`;

export const MobileTagsWrapper = styled.div`
  width: 367px;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 19px;

  & > div {
    width: 100%;
  }
`;

export const MobileAddTagButton = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0091ff;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const AddTagButton = styled.div`
  min-width: 19px;
  min-height: 19px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0091ff;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const SaveProductButton = styled.div`
  width: 44px;
  height: 44px;
  background: #3a87f0;
  border: 1px solid rgba(112, 112, 112, 0.3);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 8.5px;
  bottom: 10px;
  cursor: pointer;
`;

export const SaveProductConditionButton = styled(SaveProductButton)`
  z-index: 3;
  right: 7px;
  bottom: 9px;
`;

export const ConditionsContentBox = styled.div`
  height: 508px;
  display: flex;
  flex-direction: column;
  background: ${({theme}) => theme.colors.productDetailConditionBg};
  border-radius: 0 0 24px 24px;
  align-items: center;
  position: relative;
`;

export const MobileConditionsContentBox = styled.div`
  height: 508px;
  display: flex;
  flex-direction: column;
  /* background: ${({theme}) => theme.colors.productDetailConditionBg}; */
  background: transparent;
  border-radius: 0 0 24px 24px;
  align-items: center;
  position: relative;
`;

export const ConditionsContentWrapper = styled.div`
  height: 471px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  padding: 9px 11px 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 100%;
    background: ${({theme}) => theme.colors.mobileConditionsContentBackground};
    display: none;
  }
`;

export const MobileConditionsContentWrapper = styled.div`
  display: none;
  height: 471px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  padding: 9px 11px 10px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    background: ${({theme}) => theme.colors.mobileConditionsContentBackground};
    display: flex;
  }
`;

export const ConditionsBannerWrapper = styled.div`
  width: 227px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 10px;

  & > svg {
    margin-bottom: 14px;

    & path {
      fill: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }

  & > h1 {
    margin-bottom: 9px;
    letter-spacing: -0.15px;
    line-height: 21px;
    font-size: 18px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;
  }

  & > p {
    letter-spacing: -0.05px;
    line-height: 18px;
    font-size: 13px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;

    & > span {
      letter-spacing: -0.05px;
      line-height: 18px;
      font-size: 13px;
      font-weight: 500;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      text-decoration: underline;
      text-align: center;
    }
  }
`;

export const SingleTagWrapper = styled.div`
  height: 22px;
  padding-left: 1px;
  padding-right: 7.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  gap: 6.5px;

  & > p {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.625px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > svg {
    margin-top: 2px;
    cursor: pointer;
  }
`;

export const DeleteTagButton = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background: #FF0300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const DeleteFileButton = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  background: #ff0300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.15s;
  position: absolute;
  bottom: 6px;
  right: 8px;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  & > svg {
    position: unset !important;
  }
`;

// export const ConditionDetailsWrapper = styled.div`
//   height: calc(100%);
//   padding: 0 12px;
//   overflow-y: auto;
//   background: ${({theme}) => theme.colors.headerContentBackground};
//   border-radius: 16px;
//   width: 103%;
//   /* margin-top: 36px; */

//   &::-webkit-scrollbar {
//     width: 12px;
//   }

//   &::-webkit-scrollbar-track {
//     background: transparent;
//     margin: 20px;
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: #3a87f0;
//     border-radius: 25px;
//     border: 5px solid transparent;
//     background-clip: padding-box;
//   }

//   & > p {
//     font-size: 11px;
//     line-height: 21px;
//     color: rgba(255, 255, 255, 0.86);
//   }
// `;

export const SectionTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > h5 {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

// export const ConditionSectionDescription = styled.div<{isEditing: boolean}>`
//   width: 100%;
//   display: flex;
//   padding: 0 15px;
//   margin-bottom: 17.5px;
//   align-items: center;
//   justify-content: center;
//   position: relative;
//   display: ${({isEditing}) => (isEditing ? 'flex' : 'none')};

//   & > p {
//     text-align: center;
//     font-weight: 400;
//     font-size: 11px;
//     letter-spacing: 0.5px;
//     line-height: 15px;
//     color: ${({theme}) => theme.colors.fontBlackOp50};
//   }
// `;

export const SmallSectionTitleWithWinWinAndSlider = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 170px 82px 34px;
  align-items: center;
  margin-bottom: 19px;
  justify-content: space-between;
  text-align: left;

  & > h5 {
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const TextareaLettersCount = styled.h4`
  font-size: 11px;
  position: absolute;
  right: 9px;
  bottom: 8px;
  color: #3a87f0;
  font-weight: 400;
  letter-spacing: 0.625px;
`;

export const StyledTextarea = styled.textarea<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  width: 99%;
  height: 99%;
  border: none;
  background: ${({isEditing, isError}) =>
    isEditing
      ? isEditing && isError
        ? '#FF7E7D'
        : 'transparent'
      : 'transparent'};
  padding: 24px 11.5px 6px;
  border-radius: ${({isEditing, isError}) =>
    isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
  color: ${({isEditing}) => (isEditing ? `#707070` : `#07020F`)};
  font-size: 11px;
  line-height: 24px;
  resize: none;
  outline: none;
  font-weight: 400;
`;

export const TextareaWrapper = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
}>`
  position: relative;
  width: 100%;
  height: 247px;
  margin-top: 9px;
  background: ${({theme}) => theme.colors.textareaBackground};
  border: solid 1px #e1e1e1;
  border-radius: ${({isEditing, isError}) =>
    isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
  color: ${({theme}) => theme.colors.fontBlackOp100};

  & > h5 {
    font-size: 11px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.625px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    position: absolute;
    top: 2px;
    left: 11.5px;
  }

  & > h4 {
    display: ${({isEditing}) => (isEditing ? 'unset' : 'none')};
  }
`;

export const ConditionSmallSectionDescription = styled.div<{
  isEditing: boolean;
}>`
  width: 100%;
  display: flex;
  padding-right: 15px;
  margin-bottom: 17.5px;
  align-items: center;
  justify-content: center;
  position: relative;
  display: ${({isEditing}) => (isEditing ? 'flex' : 'none')};

  & > p {
    text-align: center;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 15px;
    color: ${({theme}) => theme.colors.grayFontOp100};
  }
`;

export const SelectViewStyledSelect = styled.select<{isEditing?: boolean}>`
  background: ${({isEditing}) =>
    isEditing
      ? `${({theme}) => theme.colors.headerContentBackground} !important`
      : 'inherit'};
  border-radius: 5px;
`;

export const ConditionButtonsBox = styled.div`
  padding: 0px 10px;
  padding-left: 3px;
  background: ${({theme}) => theme.colors.productDetailConditionBg};
  position: relative;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > button {
    position: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    background: ${({theme}) => theme.colors.mobileOfferDetailTopBackground};
  }
`;

export const OptionsWrapper = styled.div`
  width: 100%;
  min-height: 32px;
  background: ${({theme}) => theme.colors.productDetailConditionBg};
  gap: 2px;
  display: flex;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
`;

export const MobileOptionsWrapper = styled.div`
  width: 100%;
  min-height: 32px;
  /* background: ${({theme}) => theme.colors.productDetailConditionBg}; */
  background: ${({theme}) => theme.colors.mobileOfferDetailTopBackground};
  gap: 2px;
  display: flex;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
`;

export const SingleOption = styled.div<{
  value: number;
  selectedCondition: number;
  isEditing: boolean;
  haveCondition: boolean;
}>`
  /* width: 100%; */
  width: ${({value, selectedCondition}) =>
    value === selectedCondition ? '100%' : 'unset'};
  min-width: ${({value, selectedCondition, haveCondition}) =>
    value === selectedCondition ? 'unset' : '52px'};
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({value, selectedCondition, theme}) =>
    value === selectedCondition
      ? theme.colors.bodyBg
      : theme.colors.productDetailConditionBg};
  border-radius: 9px 9px 0 0;

  & > p {
    font-size: 13px;
    width: 100%;
    letter-spacing: -0.15px;
    color: ${({value, selectedCondition, haveCondition, theme}) =>
      haveCondition
        ? `${theme.colors.blueTheme}`
        : `${theme.colors.fontBlackOp100}`};
    font-weight: 400;
    text-align: center;
  }

  & > span {
    font-size: 13px;
    padding-bottom: 1px;
    cursor: pointer;
    border-radius: 7px;
    width: 32px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({value, selectedCondition, haveCondition, theme}) =>
      haveCondition
        ? `1px solid ${theme.colors.blueTheme}`
        : `1px solid ${theme.colors.conditionNameColor}`};
    /* border: 1px solid rgba(112, 112, 112, 0.3); */
    letter-spacing: -0.15px;
    color: ${({value, selectedCondition, haveCondition, theme}) =>
      haveCondition
        ? `${theme.colors.blueTheme}`
        : `${theme.colors.conditionNameColor}`};
    font-weight: 400;
    text-align: center;
  }
`;

export const MobileSingleOption = styled.div<{
  value: number;
  selectedCondition: number;
  isEditing: boolean;
  haveCondition: boolean;
}>`
  /* width: 100%; */
  width: ${({value, selectedCondition}) =>
    value === selectedCondition ? '100%' : 'unset'};
  min-width: ${({value, selectedCondition, haveCondition}) =>
    value === selectedCondition
      ? 'unset'
      : value !== selectedCondition && !haveCondition
      ? 'unset'
      : '52px'};
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({value, selectedCondition, theme}) =>
    value === selectedCondition
      ? theme.colors.mobileConditionsContentBackground
      : theme.colors.mobileOfferDetailTopBackground};
  border-radius: 9px 9px 0 0;

  & > p {
    font-size: 13px;
    width: 100%;
    letter-spacing: -0.15px;
    color: ${({value, selectedCondition, haveCondition, theme}) =>
      haveCondition
        ? `${theme.colors.blueTheme}`
        : `${theme.colors.fontBlackOp100}`};
    font-weight: 400;
    text-align: center;
  }

  & > span {
    font-size: 13px;
    padding-bottom: 1px;
    cursor: pointer;
    border-radius: 7px;
    width: 32px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({value, selectedCondition, haveCondition, theme}) =>
      haveCondition
        ? `1px solid ${theme.colors.blueTheme}`
        : `1px solid ${theme.colors.conditionNameColor}`};
    /* border: 1px solid rgba(112, 112, 112, 0.3); */
    letter-spacing: -0.15px;
    color: ${({value, selectedCondition, haveCondition, theme}) =>
      haveCondition
        ? `${theme.colors.blueTheme}`
        : `${theme.colors.conditionNameColor}`};
    font-weight: 400;
    text-align: center;
  }
`;

export const MobileGalleryWrapper = styled.div`
  gap: 19px 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 174px);
  align-items: center;
  margin-top: 20px;
`;

export const FilesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 11px;
  justify-content: space-between;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    grid-template-columns: unset;
  }
`;

export const DigitalFilesWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 360px 210px;
  gap: 11px;
  justify-content: space-evenly;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    grid-template-columns: unset;
  }
`;

export const FileWithTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;

  & > h1 {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.07px;
    color: ${({theme}) => theme.colors.fileHeaderFontColor};
  }

  & > p {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.25px;
    text-align: center;
    height: 60px;
    color: ${({theme}) => theme.colors.fileHeaderFontColor};
  }
`;

export const DigitalFileWithTitleWrapper = styled(FileWithTitleWrapper)`
  gap: 2px;

  & > p {
    height: unset;
  }

  & > h5 {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.25px;
    color: rgba(0, 145, 255, 0.7);

    & > span {
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.25px;
      color: #0091ff;
    }
  }
`;

export const FileWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  width: 100%;
  background: ${({theme}) => theme.colors.fileBackgroundColor};
  border: 1px solid ${({theme}) => theme.colors.fileBorderColor};
  height: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 11.5px 13.5px;

  & > h5 {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    margin-bottom: 16px;
  }

  & > h4 {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.07px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    top: 7px;
    position: absolute;
  }

  & > p {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: -0.25px;
    color: rgba(0, 145, 255, 0.7);

    & > span {
      font-size: 11px;
      font-weight: 500;
      letter-spacing: -0.25px;
      color: #0091ff;
    }
  }
`;

export const PlayButtonMainWrapper = styled.div`
  right: 24px;
  position: absolute;
  height: 48px;
  width: 48px;
  cursor: pointer;
  border: 1px solid rgba(112, 112, 112, 0.3);
  background: rgba(47, 47, 104, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 39.77px;
    width: 39.77px;
    border: 1px solid rgba(112, 112, 112, 0.3);
    background: #0091ff;

    & > svg {
      /* position: unset !important; */
      left: 2px;
      width: 45px;
      height: 45px;

      & g {
        fill: white;
        stroke: white;
      }

      & path {
        fill: white;
        stroke: white;
      }
    }
  }
`;

export const TrackFileWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  width: 100%;
  background: ${({theme}) => theme.colors.trackFileBackgroundColor};
  border: 1px solid ${({theme}) => theme.colors.trackFileBorderColor};
  height: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h5 {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    position: absolute;
    top: 6px;
    width: 193px;
    text-overflow: ellipsis;
    left: 8px;
    white-space: nowrap;
    overflow: hidden;
  }

  & svg {
    position: absolute;
    left: 25px;

    & path {
      fill: ${({theme}) => theme.colors.noFileSvgColor};
    }
  }
`;

export const DesktopPlayButtonWrapper = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  gap: 15px;
  right: 24px;
  position: absolute;
  z-index: 4;

  & > img {
    width: 48px;
    height: 48px;
    cursor: pointer;

    &:hover {
      filter: brightness(0.95);
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    left: unset;
    width: unset;
    height: unset;
    bottom: 8px;
    right: 36px;

    & > img {
      height: unset;
      width: unset;

      &:hover {
        filter: unset;
      }
    }
  }
`;

export const CustomAlertWrapper = styled.div`
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > div {
    width: 292px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    & > div {
      background: #0091ff;
      width: 100%;
      height: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 14px;
      border-top-left-radius: 14px;

      & > h4 {
        font-size: 13px;
        margin-bottom: 0px;
        color: rgba(23, 22, 29, 1);
        font-weight: normal;
      }
    }

    & > p {
      margin-top: 10px;
      color: rgba(23, 22, 29, 1);
      width: 80%;
      text-align: center;
      font-size: 13px;
      line-height: 19px;
      letter-spacing: -0.25px;
      overflow: hidden;
    }

    & > button {
      background-color: #3986ef;
      color: white;
      font-weight: normal;
      cursor: pointer;
      border: none;
      border-radius: 9px;
      text-align: center;
      padding: 5px 0px;
      font-size: 13px;
      line-height: 24px;
      width: 149px;
      height: 32px;
      margin: 4px;
      transition: all 0.15s;
      width: 110px;
    }
  }
`;

export const FileNameWithSvgSpan = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  & > svg {
    position: absolute;
    left: unset !important;
    right: 25px !important;

    & path {
      fill: #0091ff !important;
    }
  }

  & > p {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    position: absolute;
    left: 5px;
    width: 145px;
    text-align: center;
  }
`;

export const NoFileWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  width: 100%;
  background: ${({theme}) => theme.colors.noFileBackgroundColor};
  border: 1px solid ${({theme}) => theme.colors.noFileBorderColor};
  height: 92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > h5 {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.noFileTitleFontColor};
    position: absolute;
    top: 6px;
  }

  & svg {
    & path {
      fill: ${({theme}) => theme.colors.noFileSvgColor};
    }
  }
`;

export const AddFileButton = styled.div`
  width: 19px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 13px;
  bottom: 9.5px;
  background: #0091ff;
  border-radius: 4px;
  cursor: pointer;
`;

export const StyledExpandableHeaderWithDescriptionWrapper = styled.div`
  width: 100%;
  margin-top: 10px;

  & > div {
    & > div {
      width: 100%;
      padding: 0px;
      justify-content: space-between;
      margin-bottom: unset;

      & > h1 {
        letter-spacing: 0.0625px;
        font-weight: 500;
      }
    }
  }
`;

export const StyledSellerObligationWrapper = styled(SellerObligationWrapper)<{
  isEditing: boolean;
}>`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-left: ${({isEditing}) => (isEditing ? '6px' : 'unset')};
    padding-right: unset;
    gap: 5px;

    & > svg {
      position: unset;
    }
  }
`;

export const StyledDisplayGrossValueNoEditing = styled(
  DisplayGrossValueNoEditing,
)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    // width: 367px;
    width: 100%;
  }
`;

export const MobileStyledInputView = styled(InputView)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* width: 367px; */
    width: 100%;
    margin: 0 auto;
  }
`;

export const StyledConditionSectionDescription = styled(
  ConditionSectionDescription,
)<{isEditing: boolean}>`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    & > p {
      width: ${({isEditing}) =>
        isEditing ? '259px !important' : '367px !important'};
      text-align: left;
      margin: 0 auto;
    }
  }
`;

export const StyledDisplayGrossValueWithTaxNoEditing = styled(
  DisplayGrossValueWithTaxNoEditing,
)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    // width: 367px;
    width: 100%;
  }
`;

export const StyledSectionHeaderNoEditing = styled(SectionHeaderNoEditing)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* width: 367px; */
    width: 100%;
  }
`;

export const StyledSelectViewContainer = styled(SelectViewContainer)<{
  isEditing: boolean;
}>`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: ${({isEditing}) => (isEditing ? '259px' : '367px')};
  }
`;

export const StyledBlueHeaderWrapper = styled.div`
  width: 259px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 4px 0;
  gap: 4px;

  & > h1 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.15px;
    line-height: 24px;
    color: #0091ff;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* width: 367px; */
    width: 100%;
  }
`;

export const StyledLeftDot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #0091ff;
`;
