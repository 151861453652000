import React, {FC} from 'react';

interface Props {
  percent: number;
  type: 'small' | 'medium' | 'big';
  color: string;
}

export const SoundTrack: React.FC<Props> = ({percent, type, color}) => {
  if (type === 'small')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="150.25"
        height="67"
        viewBox="0 0 150.25 67"
      >
        <g
          id="Group_4875"
          data-name="Group 4875"
          transform="translate(-158 -203.2)"
        >
          <line
            id="Line_483"
            data-name="Line 483"
            y2="32.761"
            transform="translate(197.341 220.32)"
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={color}
            opacity={percent >= 28 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_498"
            data-name="Line 498"
            y2="64"
            transform="translate(235.181 204.7)"
            fill="none"
            stroke={color}
            opacity={percent >= 53 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_506"
            data-name="Line 506"
            y2="6.054"
            transform="translate(273.845 233.468)"
            fill="none"
            stroke={color}
            opacity={percent >= 78 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_514"
            data-name="Line 514"
            y2="10.135"
            transform="translate(159.5 231.825)"
            fill="none"
            stroke={color}
            opacity={percent >= 1 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_490"
            data-name="Line 490"
            y2="19.558"
            transform="translate(216.261 226.894)"
            fill="none"
            stroke={color}
            opacity={percent >= 40 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_494"
            data-name="Line 494"
            y2="35.528"
            transform="translate(254.102 219.224)"
            fill="none"
            stroke={color}
            opacity={percent >= 65 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_502"
            data-name="Line 502"
            y2="13.258"
            transform="translate(292.765 230.181)"
            fill="none"
            stroke={color}
            opacity={percent >= 90 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_510"
            data-name="Line 510"
            y2="4.547"
            transform="translate(178.42 234.564)"
            fill="none"
            stroke={color}
            opacity={percent >= 15 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_486"
            data-name="Line 486"
            y2="19.558"
            transform="translate(207.212 226.894)"
            fill="none"
            stroke={color}
            opacity={percent >= 34 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_496"
            data-name="Line 496"
            y2="12.339"
            transform="translate(245.053 229.913)"
            fill="none"
            stroke={color}
            opacity={percent >= 59 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_504"
            data-name="Line 504"
            y2="23.01"
            transform="translate(282.894 225.251)"
            fill="none"
            stroke={color}
            opacity={percent >= 84 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_512"
            data-name="Line 512"
            y2="11.505"
            transform="translate(169.371 231.277)"
            fill="none"
            stroke={color}
            opacity={percent >= 9 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_488"
            data-name="Line 488"
            y2="39.117"
            transform="translate(226.133 217.033)"
            fill="none"
            stroke={color}
            opacity={percent >= 46 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_492"
            data-name="Line 492"
            y2="10.3"
            transform="translate(263.973 231.825)"
            fill="none"
            stroke={color}
            opacity={percent >= 71 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_500"
            data-name="Line 500"
            y2="5.953"
            transform="translate(301.814 234.016)"
            fill="none"
            stroke={color}
            opacity={percent >= 96 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_508"
            data-name="Line 508"
            y2="11.505"
            transform="translate(188.292 231.277)"
            fill="none"
            stroke={color}
            opacity={percent >= 21 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_484"
            data-name="Line 484"
            y2="28.16"
            transform="translate(202.276 222.511)"
            fill="none"
            stroke={color}
            opacity={percent >= 31 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_497"
            data-name="Line 497"
            y2="35.528"
            transform="translate(240.117 219.224)"
            fill="none"
            stroke={color}
            opacity={percent >= 56 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_505"
            data-name="Line 505"
            y2="10.3"
            transform="translate(277.958 231.825)"
            fill="none"
            stroke={color}
            opacity={percent >= 81 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_513"
            data-name="Line 513"
            y2="23.01"
            transform="translate(164.436 225.251)"
            fill="none"
            stroke={color}
            opacity={percent >= 5 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_489"
            data-name="Line 489"
            y2="32.761"
            transform="translate(221.197 220.32)"
            fill="none"
            stroke={color}
            opacity={percent >= 43 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_493"
            data-name="Line 493"
            y2="23.01"
            transform="translate(259.038 225.251)"
            fill="none"
            stroke={color}
            opacity={percent >= 68 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_501"
            data-name="Line 501"
            y2="8.035"
            transform="translate(296.878 232.92)"
            fill="none"
            stroke={color}
            opacity={percent >= 94 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_509"
            data-name="Line 509"
            y2="8.93"
            transform="translate(183.356 232.373)"
            fill="none"
            stroke={color}
            opacity={percent >= 18 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_485"
            data-name="Line 485"
            y2="13.915"
            transform="translate(212.148 229.633)"
            fill="none"
            stroke={color}
            opacity={percent >= 37 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_495"
            data-name="Line 495"
            y2="25.434"
            transform="translate(249.989 224.155)"
            fill="none"
            stroke={color}
            opacity={percent >= 62 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_503"
            data-name="Line 503"
            y2="13.258"
            transform="translate(287.829 230.181)"
            fill="none"
            stroke={color}
            opacity={percent >= 87 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_511"
            data-name="Line 511"
            y2="8.93"
            transform="translate(173.485 232.373)"
            fill="none"
            stroke={color}
            opacity={percent >= 12 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_487"
            data-name="Line 487"
            y2="53.142"
            transform="translate(231.068 210.459)"
            fill="none"
            stroke={color}
            opacity={percent >= 50 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_491"
            data-name="Line 491"
            y2="6.054"
            transform="translate(268.909 233.468)"
            fill="none"
            stroke={color}
            opacity={percent >= 75 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_499"
            data-name="Line 499"
            y2="5.953"
            transform="translate(306.75 234.016)"
            fill="none"
            stroke={color}
            opacity={percent >= 99 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_507"
            data-name="Line 507"
            y2="23.01"
            transform="translate(192.405 225.251)"
            fill="none"
            stroke={color}
            opacity={percent >= 25 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="3"
            style={{transition: '0.3s'}}
          />
        </g>
      </svg>
    );
  if (type === 'medium')
    return (
      //  32 all line
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="173.258"
        height="77"
        viewBox="0 0 173.258 77"
      >
        <g
          id="Group_4883"
          data-name="Group 4883"
          transform="translate(-158 -203.2)"
        >
          {/*  nr 9*/}
          <line
            id="Line_483"
            data-name="Line 483"
            y2="37.88"
            transform="translate(203.253 222.76)"
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={color}
            opacity={percent >= 28 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  17*/}
          <line
            id="Line_498"
            data-name="Line 498"
            y2="74"
            transform="translate(247.007 204.7)"
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={color}
            opacity={percent >= 53 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*25*/}
          <line
            id="Line_506"
            data-name="Line 506"
            y2="7"
            transform="translate(291.711 237.963)"
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={color}
            opacity={percent >= 78 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  nr 1*/}
          <line
            id="Line_514"
            data-name="Line 514"
            y2="11.719"
            transform="translate(159.5 236.063)"
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={color}
            opacity={percent >= 1 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  13*/}
          <line
            id="Line_490"
            data-name="Line 490"
            y2="22.614"
            transform="translate(225.13 230.361)"
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={color}
            opacity={percent >= 40 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  21*/}
          <line
            id="Line_494"
            data-name="Line 494"
            y2="41.08"
            transform="translate(268.883 221.493)"
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={color}
            opacity={percent >= 65 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  29*/}
          <line
            id="Line_502"
            data-name="Line 502"
            y2="15.329"
            transform="translate(313.588 234.162)"
            fill="none"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={color}
            opacity={percent >= 90 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  5*/}
          <line
            id="Line_510"
            data-name="Line 510"
            y2="5.258"
            transform="translate(181.377 239.23)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 15 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  11*/}
          <line
            id="Line_486"
            data-name="Line 486"
            y2="22.614"
            transform="translate(214.667 230.361)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 34 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  19*/}
          <line
            id="Line_496"
            data-name="Line 496"
            y2="14.267"
            transform="translate(258.421 233.853)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 59 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  27*/}
          <line
            id="Line_504"
            data-name="Line 504"
            y2="26.605"
            transform="translate(302.174 228.462)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 84 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  3*/}
          <line
            id="Line_512"
            data-name="Line 512"
            y2="13.303"
            transform="translate(170.914 235.429)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 9 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  15*/}
          <line
            id="Line_488"
            data-name="Line 488"
            y2="45.228"
            transform="translate(236.544 218.96)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 46 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  23*/}
          <line
            id="Line_492"
            data-name="Line 492"
            y2="11.909"
            transform="translate(280.297 236.063)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 71 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  31*/}
          <line
            id="Line_500"
            data-name="Line 500"
            y2="6.883"
            transform="translate(324.051 238.596)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 96 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  7*/}
          <line
            id="Line_508"
            data-name="Line 508"
            y2="13.303"
            transform="translate(192.791 235.429)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 21 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  10*/}
          <line
            id="Line_484"
            data-name="Line 484"
            y2="32.559"
            transform="translate(208.96 225.294)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 31 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  18*/}
          <line
            id="Line_497"
            data-name="Line 497"
            y2="41.08"
            transform="translate(252.714 221.493)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 56 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  26*/}
          <line
            id="Line_505"
            data-name="Line 505"
            y2="11.909"
            transform="translate(296.467 236.063)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 81 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  2*/}
          <line
            id="Line_513"
            data-name="Line 513"
            y2="26.605"
            transform="translate(165.207 228.462)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 5 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  14*/}
          <line
            id="Line_489"
            data-name="Line 489"
            y2="37.88"
            transform="translate(230.837 222.76)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 43 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  22*/}
          <line
            id="Line_493"
            data-name="Line 493"
            y2="26.605"
            transform="translate(274.59 228.462)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 68 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  30*/}
          <line
            id="Line_501"
            data-name="Line 501"
            y2="9.291"
            transform="translate(318.344 237.33)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 94 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  6*/}
          <line
            id="Line_509"
            data-name="Line 509"
            y2="10.325"
            transform="translate(187.084 236.696)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 18 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  12*/}
          <line
            id="Line_485"
            data-name="Line 485"
            y2="16.09"
            transform="translate(220.374 233.529)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 37 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  20*/}
          <line
            id="Line_495"
            data-name="Line 495"
            y2="29.408"
            transform="translate(264.127 227.194)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 62 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  28*/}
          <line
            id="Line_503"
            data-name="Line 503"
            y2="15.329"
            transform="translate(307.881 234.162)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 87 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  4*/}
          <line
            id="Line_511"
            data-name="Line 511"
            y2="10.325"
            transform="translate(175.67 236.696)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 12 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  16*/}
          <line
            id="Line_487"
            data-name="Line 487"
            y2="61.445"
            transform="translate(242.251 211.358)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 50 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  24*/}
          <line
            id="Line_491"
            data-name="Line 491"
            y2="7"
            transform="translate(286.004 237.963)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 75 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  32*/}
          <line
            id="Line_499"
            data-name="Line 499"
            y2="6.883"
            transform="translate(329.758 238.596)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 99 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
          {/*  8*/}
          <line
            id="Line_507"
            data-name="Line 507"
            y2="26.605"
            transform="translate(197.546 228.462)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeWidth="3"
            opacity={percent >= 25 ? '1' : '0.15'}
            style={{transition: '0.3s'}}
          />
        </g>
      </svg>
    );
  if (type === 'big')
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="301"
        height="133.652"
        viewBox="0 0 301 133.652"
      >
        <g
          id="Group_4879"
          data-name="Group 4879"
          transform="translate(-157 -202.2)"
        >
          <line
            id="Line_483"
            data-name="Line 483"
            y2="65.857"
            transform="translate(235.567 236.098)"
            fill="none"
            stroke={color}
            opacity={percent >= 28 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_498"
            data-name="Line 498"
            y2="128.652"
            transform="translate(311.634 204.7)"
            fill="none"
            stroke={color}
            opacity={percent >= 53 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_506"
            data-name="Line 506"
            y2="12.169"
            transform="translate(389.355 262.529)"
            fill="none"
            stroke={color}
            opacity={percent >= 78 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_514"
            data-name="Line 514"
            y2="20.374"
            transform="translate(159.5 259.226)"
            fill="none"
            stroke={color}
            opacity={percent >= 1 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_490"
            data-name="Line 490"
            y2="39.316"
            transform="translate(273.601 249.314)"
            fill="none"
            stroke={color}
            opacity={percent >= 40 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_494"
            data-name="Line 494"
            y2="71.419"
            transform="translate(349.668 233.895)"
            fill="none"
            stroke={color}
            opacity={percent >= 65 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_502"
            data-name="Line 502"
            y2="26.651"
            transform="translate(427.388 255.922)"
            fill="none"
            stroke={color}
            opacity={percent >= 90 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_510"
            data-name="Line 510"
            y2="9.14"
            transform="translate(197.534 264.732)"
            fill="none"
            stroke={color}
            opacity={percent >= 15 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_486"
            data-name="Line 486"
            y2="39.316"
            transform="translate(255.411 249.314)"
            fill="none"
            stroke={color}
            opacity={percent >= 34 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_496"
            data-name="Line 496"
            y2="24.804"
            transform="translate(331.478 255.383)"
            fill="none"
            stroke={color}
            opacity={percent >= 59 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_504"
            data-name="Line 504"
            y2="46.254"
            transform="translate(407.545 246.01)"
            fill="none"
            stroke={color}
            opacity={percent >= 84 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_512"
            data-name="Line 512"
            y2="23.127"
            transform="translate(179.344 258.124)"
            fill="none"
            stroke={color}
            opacity={percent >= 9 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_488"
            data-name="Line 488"
            y2="78.632"
            transform="translate(293.444 229.491)"
            fill="none"
            stroke={color}
            opacity={percent >= 46 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_492"
            data-name="Line 492"
            y2="20.704"
            transform="translate(369.511 259.226)"
            fill="none"
            stroke={color}
            opacity={percent >= 71 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_500"
            data-name="Line 500"
            y2="11.967"
            transform="translate(445.578 263.63)"
            fill="none"
            stroke={color}
            opacity={percent >= 96 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_508"
            data-name="Line 508"
            y2="23.127"
            transform="translate(217.377 258.124)"
            fill="none"
            stroke={color}
            opacity={percent >= 21 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_484"
            data-name="Line 484"
            y2="56.606"
            transform="translate(245.489 240.504)"
            fill="none"
            stroke={color}
            opacity={percent >= 31 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_497"
            data-name="Line 497"
            y2="71.419"
            transform="translate(321.556 233.895)"
            fill="none"
            stroke={color}
            opacity={percent >= 56 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_505"
            data-name="Line 505"
            y2="20.704"
            transform="translate(397.623 259.226)"
            fill="none"
            stroke={color}
            opacity={percent >= 81 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_513"
            data-name="Line 513"
            y2="46.254"
            transform="translate(169.422 246.01)"
            fill="none"
            stroke={color}
            opacity={percent >= 5 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_489"
            data-name="Line 489"
            y2="65.857"
            transform="translate(283.522 236.098)"
            fill="none"
            stroke={color}
            opacity={percent >= 43 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_493"
            data-name="Line 493"
            y2="46.254"
            transform="translate(359.589 246.01)"
            fill="none"
            stroke={color}
            opacity={percent >= 68 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_501"
            data-name="Line 501"
            y2="16.152"
            transform="translate(435.656 261.428)"
            fill="none"
            stroke={color}
            opacity={percent >= 94 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_509"
            data-name="Line 509"
            y2="17.951"
            transform="translate(207.455 260.327)"
            fill="none"
            stroke={color}
            opacity={percent >= 18 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_485"
            data-name="Line 485"
            y2="27.973"
            transform="translate(265.332 254.82)"
            fill="none"
            stroke={color}
            opacity={percent >= 37 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_495"
            data-name="Line 495"
            y2="51.127"
            transform="translate(341.399 243.808)"
            fill="none"
            stroke={color}
            opacity={percent >= 62 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_503"
            data-name="Line 503"
            y2="26.651"
            transform="translate(417.466 255.922)"
            fill="none"
            stroke={color}
            opacity={percent >= 87 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_511"
            data-name="Line 511"
            y2="17.951"
            transform="translate(187.612 260.327)"
            fill="none"
            stroke={color}
            opacity={percent >= 12 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_487"
            data-name="Line 487"
            y2="106.825"
            transform="translate(303.366 216.276)"
            fill="none"
            stroke={color}
            opacity={percent >= 50 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_491"
            data-name="Line 491"
            y2="12.169"
            transform="translate(379.433 262.529)"
            fill="none"
            stroke={color}
            opacity={percent >= 75 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_499"
            data-name="Line 499"
            y2="11.967"
            transform="translate(455.5 263.63)"
            fill="none"
            stroke={color}
            opacity={percent >= 99 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
          <line
            id="Line_507"
            data-name="Line 507"
            y2="46.254"
            transform="translate(225.645 246.01)"
            fill="none"
            stroke={color}
            opacity={percent >= 25 ? '1' : '0.15'}
            strokeLinecap="round"
            strokeWidth="5"
            style={{transition: '0.3s'}}
          />
        </g>
      </svg>
    );
};
