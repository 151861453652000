import styled from 'styled-components';
import exp from 'constants';

export const OneProductWrapper = styled.div<{bgColor: string}>`
  width: 100%;
  height: 100%;
  background: ${({bgColor}) => `rgba(${bgColor})`};
`;
export const OneProductBlockColumnWrapper = styled.div<{Height: number}>`
  padding: 5px;
  padding-top: 10px;
  width: 100%;
  height: calc(100% - 52px);
  display: flex;
  flex-direction: column;
  align-items: center;
  & > img {
    height: ${({Height}) => (Height === 3 ? '192px' : '292px')};
    width: ${({Height}) => (Height === 3 ? '192px' : '292px')};
  }
`;

export const OneProductNameProduct = styled.h2<{
  Width: number;
  fontColor: string;
}>`
  font-size: ${({Width}) => (Width === 9 ? '36px' : '21px')};
  line-height: ${({Width}) => (Width === 9 ? '48px' : '32px')};
  letter-spacing: -0.25px;
  font-weight: bold;
  color: ${({fontColor}) => `rgb(${fontColor})`};
`;

export const OneProductCategoryWrapper = styled.div<{
  Width: number;
  fontColor: string;
}>`
  display: flex;
  width: 100%;
  justify-content: center;
  & > p {
    font-size: ${({Width}) => (Width === 9 ? '18px' : '11px')};
    line-height: ${({Width}) => (Width === 9 ? '36px' : '22px')};
    letter-spacing: -0.25px;
    font-weight: bold;
    padding-right: 5px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }
`;

export const OneProductType = styled.p<{
  Width: number;
  fontColor: string;
}>`
  font-size: ${({Width}) => (Width === 9 ? '18px' : '11px')};
  line-height: ${({Width}) => (Width === 9 ? '18px' : '11px')};
  margin-bottom: ${({Width}) =>
    Width === 6 ? '14px' : Width === 9 ? '24px' : '0px'};
  letter-spacing: -0.25px;
  font-weight: normal;
  color: ${({fontColor}) => `rgb(${fontColor})`};
`;

export const OneProductDescription = styled.p<{
  Width: number;
  fontColor: string;
}>`
  padding-left: ${({Width}) => (Width === 3 ? '30px' : '0px')};
  padding-right: ${({Width}) => (Width === 3 ? '30px' : '10px')};
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.05px;
  overflow-y: hidden;
  color: ${({fontColor}) => `rgb(${fontColor})`};
`;

export const OneProductBlockFooter = styled.div`
  height: 52px;
  width: 100%;
  background-color: #ffffff;
`;

export const OneProductFooterContent = styled.div<{Width: number}>`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const OneProductPirceWrapper = styled.div`
  margin-left: 21px;
  & > p {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.25px;
  }
`;

export const OneProductWinWinWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 18px;
  width: 82px;
  height: 20px;
  border: 1px solid #fb9614;
  border-radius: 11px;

  & > p {
    font-size: 9px;
    //line-height: 24px;
    letter-spacing: 0.15px;
    color: #fb9614;
  }
`;

export const DeleteProductButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;
`;
