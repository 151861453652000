import React, {useState} from 'react';
import styled from 'styled-components';

const StyledInput = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
  isFocused?: boolean;
  style?: React.CSSProperties;
}>`
  position: relative;
  width: 100%;
  border: none;
  margin-top: 9px;
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};

  & > input {
    outline: none;
    border: none;
    background: transparent;
    border-bottom: ${({isEditing, isError}) =>
      isEditing
        ? isEditing && isError
          ? '1px solid red'
          : 'solid 1px #e1e1e1'
        : 'none'};
    border-radius: 0px;
    color: ${({isEditing}) => (isEditing ? `#352F40` : `#07020F`)};
    width: 100%;
    height: 31px;
    text-align: right;
    font-size: 17px;
    padding-right: 8.5px;
    padding-left: 100px;

    &:-webkit-autofill {
      -webkit-text-fill-color: #352f40 !important;
      color: #352f40;
      border-bottom: ${({isFocused}) =>
        isFocused ? '1px solid #0066ff' : '1px solid rgba(0,0,0,0.12)'};
    }
  }

  & > input[type='date']::-webkit-calendar-picker-indicator {
    display: ${({isEditing, notEdit}) =>
      isEditing && !notEdit ? `block` : `none`};
  }

  & > input:-webkit-autofill {
    /* border: none; */
    border-bottom: ${({isFocused}) =>
      isFocused
        ? '1px solid #0066ff !important'
        : '1px solid rgba(0,0,0,0.12)'};
  }

  & > input:focus {
    background: transparent;
    border: none;
    border-bottom: ${({isEditing, notEdit}) =>
      isEditing && !notEdit
        ? ` 1px solid #0066ff`
        : notEdit && isEditing
        ? 'solid 1px #e1e1e1'
        : 'none'};
  }

  & > p {
    position: absolute;
    //color: #07020f;
    color: ${({isEditing, isError, isFocused}) =>
      isFocused
        ? '#0066ff'
        : !isEditing
        ? `#707070`
        : isEditing && isError
        ? 'red'
        : `rgba(7,2,15,0.3)`};
    font-size: 17px;
    line-height: 22px;
    letter-spacing: 0.625px;
    font-weight: 400;
    left: 1.5px;
    top: 4px;
    cursor: default;
  }
`;

interface Props {
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}

export const MobileInput: React.FC<Props> = ({
  children,
  isEditing,
  isError,
  notEdit,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <StyledInput
      isEditing={isEditing}
      isError={isError}
      notEdit={notEdit}
      isFocused={isFocused}
      onFocus={handleFocus}
      onBlur={handleBlur}
    >
      {children}
    </StyledInput>
  );
};
