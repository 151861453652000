import React, {useContext, useEffect, useState} from 'react';
import {ResolutionContext} from '../../contexts/ResolutionContext';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {
  PageHeaderWrapper,
  PageHeaderHeaderWrapper,
  Main,
  MainWrapper,
  PageHeaderHeader,
  PageHeaderContent,
  ArticlesListWrapper,
  ArticleListColumn,
  FilterMenuWrapper,
  ArticleSearchWrapper,
} from './styles';

import SingleAtricle from './SingleArticleI';

import articlePortfel from 'images/articlePortfel.jpg';
import articleYt from 'images/articleYouTube.jpg';
import articlerShort from 'images/articleShorts.jpg';
import articleWinWin from 'images/articleWinWin.jpg';
import articleSpotify from 'images/articlesSpotify.jpg';
import api from 'services/axiosConfig';
import {useCookies} from 'react-cookie';
import {StyledSelectView} from 'pages/MyStudio/Account';
import {ArrowWrapper, FilterButtonWrapper} from 'pages/Products';

interface DataFromDetail {
  CategoryId: string;
}

interface LocationState {
  state: null | DataFromDetail;
}

interface Article {
  PhotoPath: string;
  Category: {CategoryId: string; Name: string};
  Title: string;
  Lead: string;
  UrlPath: string;
  Id: string;
}

function getElementPlusThree(array, n) {
  const resultArray = [];

  for (let i = n; i < array.length; i += 3) {
    // if (i === 0 && n === 0) {
    //   resultArray.push(array[0]);
    // }
    // if (i === 1 && n === 1) {
    //   resultArray.push(array[1]);
    // }
    // if (i === 2 && n === 2) {
    //   resultArray.push(array[2]);
    // }

    resultArray.push(array[i]);
  }

  return resultArray;
}

const Articles: React.FC = () => {
  const [cookies, setCookie] = useCookies(['token']);
  const [articlesArr, setArticlesArr] = useState<Article[]>([]);
  const [categories, setCategories] = useState([]);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const [searchString, setSearchString] = useState('');

  const {windowWidth} = useContext(ResolutionContext);

  const location = useLocation();
  const navigate = useNavigate();
  const {category} = useParams();
  const {state} = location as LocationState;

  const getArticles = async () => {
    try {
      const params = {
        CategoryId: searchString,
      };
      const response = await api.get(`Articles`, {
        params,
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setArticlesArr(response.data.Response);
      // console.log(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const selectedId = e.target.value;
    setSearchString(selectedId);
    const selectedRole = categories.find((role) => role.Id === selectedId);

    if (selectedRole === undefined) {
      return navigate(`/czytelnia`);
    }
    navigate(`/czytelnia/${selectedRole.Name.replace(/ /g, '-')}`);
  };

  const handleIsFilterMenuOpenChange = (value: boolean) =>
    setIsFilterMenuOpen(value);

  const getAllCategories = async () => {
    try {
      const response = await api.get(`product-constants/categories`);
      setCategories(
        response.data.Response.filter((item) => item.ProductType === 16),
      );
      if (category) {
        const selectedRole = response.data.Response.filter(
          (item) => item.ProductType === 16,
        ).find((role) => role.Name.replace(/ /g, '-') === category);
        setSearchString(selectedRole?.Id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(categories);

  useEffect(() => {
    getArticles();
    getAllCategories();
  }, []);

  useEffect(() => {
    if (searchString !== '') {
      getArticles();
    }
  }, [searchString]);

  useEffect(() => {
    if (state) {
      const {CategoryId} = state;
      setSearchString(CategoryId);
    }
  }, []);

  return (
    <MainWrapper>
      <Main>
        <PageHeaderWrapper
          onMouseLeave={() => {
            handleIsFilterMenuOpenChange(false);
          }}
        >
          <PageHeaderHeaderWrapper>
            <PageHeaderHeader>
              <p>Czytelnia</p>
            </PageHeaderHeader>
          </PageHeaderHeaderWrapper>
          <FilterMenuWrapper
            style={
              isFilterMenuOpen
                ? {visibility: 'unset', height: 'unset'}
                : {visibility: 'hidden', height: '0px', margin: 'unset'}
            }
          >
            <PageHeaderContent>
              {' '}
              <ArticleSearchWrapper>
                {' '}
                <StyledSelectView isEditing={true}>
                  <p>Kategoria</p>
                  <select
                    value={searchString}
                    disabled={false}
                    onChange={handleSearchSelectChange}
                  >
                    <option hidden value={''}>
                      Wybierz
                    </option>

                    {categories
                      // .filter((item) => item.FlagValue % 100 === 0)
                      .map((role) => {
                        const {Name, Id} = role;

                        return (
                          <option value={Id} key={Id}>
                            {Name}
                          </option>
                        );
                      })}
                    <option value="all">Wszystkie</option>
                  </select>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.439"
                    height="11"
                    viewBox="0 0 6.439 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(6.439 11) rotate(180)"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#3986ef"
                      />
                    </g>
                  </svg>
                </StyledSelectView>
              </ArticleSearchWrapper>
            </PageHeaderContent>
          </FilterMenuWrapper>
          <FilterButtonWrapper>
            <ArrowWrapper
              isActive={isFilterMenuOpen}
              // length={countTruthyVariables(
              //   searchString,
              //   primaryCategory,
              //   secondaryCategory,
              //   tertiaryCategory,
              //   productType !== 0 ? productType : undefined,
              //   isWinwin,
              //   minGrossValue !== 0 ? +minGrossValue : undefined,
              //   +maxGrossValue,
              // )}
              onMouseEnter={() => {
                handleIsFilterMenuOpenChange(true);
              }}
            >
              {isFilterMenuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="6.439"
                  viewBox="0 0 11 6.439"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(11) rotate(90)"
                    opacity="0.92"
                  >
                    <path
                      id="Arrow"
                      d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                      transform="translate(0 0)"
                    />
                  </g>
                </svg>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="6.439"
                    viewBox="0 0 11 6.439"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(0 6.439) rotate(-90)"
                      opacity="0.92"
                    >
                      <path
                        id="Arrow"
                        d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                        transform="translate(0 0)"
                        fill="#352f40"
                      />
                    </g>
                  </svg>
                  {/* {countTruthyVariables(
                        searchString,
                        primaryCategory,
                        secondaryCategory,
                        tertiaryCategory,
                        productType !== 0 ? productType : undefined,
                        isWinwin,
                        minGrossValue !== 0 ? +minGrossValue : undefined,
                        +maxGrossValue,
                      ) > 0 ? (
                        <p>
                          {countTruthyVariables(
                            searchString,
                            primaryCategory,
                            secondaryCategory,
                            tertiaryCategory,
                            productType !== 0 ? productType : undefined,
                            isWinwin,
                            minGrossValue !== 0 ? +minGrossValue : undefined,
                            +maxGrossValue,
                          )}
                        </p>
                      ) : null} */}
                </>
              )}
            </ArrowWrapper>
          </FilterButtonWrapper>
        </PageHeaderWrapper>
        {windowWidth > 1024 ? (
          <ArticlesListWrapper>
            <ArticleListColumn>
              {getElementPlusThree(articlesArr, 0).map((article) => (
                <SingleAtricle key={article.Id} {...article} />
              ))}
            </ArticleListColumn>
            <ArticleListColumn>
              {getElementPlusThree(articlesArr, 1).map((article) => (
                <SingleAtricle key={article.Id} {...article} />
              ))}
            </ArticleListColumn>
            <ArticleListColumn>
              {getElementPlusThree(articlesArr, 2).map((article) => (
                <SingleAtricle key={article.Id} {...article} />
              ))}
            </ArticleListColumn>
          </ArticlesListWrapper>
        ) : (
          <ArticleListColumn>
            {articlesArr.map((article) => (
              <SingleAtricle key={article.Id} {...article} />
            ))}
          </ArticleListColumn>
        )}
      </Main>
    </MainWrapper>
  );
};

export default Articles;
