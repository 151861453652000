import React, {createContext, useContext, useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';

interface SearchBarContextType {
  isMobileSearchBarOpen: boolean;
  setIsMobileSearchBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchBarContext = createContext<SearchBarContextType | undefined>(
  undefined,
);

export const useSearchBarContext = () => {
  const context = useContext(SearchBarContext);
  if (!context) {
    throw new Error(
      'useSearchBarContext must be used within a SearchBarProvider',
    );
  }
  return context;
};

export const SearchBarProvider: React.FC = ({children}) => {
  const [isMobileSearchBarOpen, setIsMobileSearchBarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsMobileSearchBarOpen(false);
  }, [location]);

  return (
    <SearchBarContext.Provider
      value={{isMobileSearchBarOpen, setIsMobileSearchBarOpen}}
    >
      {children}
    </SearchBarContext.Provider>
  );
};
