import styled from 'styled-components';

export const ImgRowWrapper = styled.div<{isMobile: boolean}>`
  width: 100%;
  /* height: 100%; */
  padding: 28px 60px 0px 60px;
  padding: ${({isMobile}) => (isMobile ? '16px' : '28px 50px 0px 50px')};
  /* display: flex;
  //padding: 1px;
  gap: 13px;
  overflow: hidden; */
  @media (max-width: 1024px) {
    padding: 16px;
  }
  @media (max-width: 375px) {
    padding: 14px;
  }
  @media (max-width: 340px) {
    padding: 10px;
  }
`;

export const ImgSliderWrapper = styled.div<{isMobile: boolean; isBig: boolean}>`
  display: flex !important;
  width: 100%;
  height: ${({isMobile, isBig}) =>
    isMobile ? '255px' : isBig ? '255px' : '200px'};
  gap: 11px;
  justify-content: ${({isMobile}) => (isMobile ? 'space-between' : 'none')};
  overflow: hidden;
  justify-content: center;
  @media (max-width: 1024px) {
    height: 255px;
    justify-content: space-between;
  }
`;

export const ImgRowFullImgWrapper = styled.div<{
  isSelected: boolean;
  isMobile: boolean;
  isBig: boolean;
}>`
  min-width: ${({isMobile, isBig}) =>
    isMobile ? '180px' : isBig ? '220px' : '168px'};
  width: ${({isMobile, isBig}) =>
    isMobile ? '180px' : isBig ? '220px' : '168px'};
  //flex-basis: 305px;
  height: ${({isMobile, isBig}) =>
    isMobile ? '180px' : isBig ? '220px' : '168px'};
  border-radius: 22px;
  /* border: ${({isSelected, theme}) =>
    isSelected
      ? `1px solid ${theme.colors.blueTheme}`
      : `1px solid rgba(112, 112, 112, 0.3)`}; */
  opacity: ${({isSelected}) => (isSelected ? 0.7 : 1)};
  & > img {
    width: 100%;
    height: 100%;
    border-radius: 22px;
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    min-width: 180px;
    width: 180px;
    height: 180px;
  }
  @media (max-width: 415px) {
    min-width: 170px;
    width: 170px;
    height: 170px;
  }
  @media (max-width: 375px) {
    min-width: 160px;
    width: 160px;
    height: 160px;
  }
  @media (max-width: 360px) {
    min-width: 150px;
    width: 150px;
    height: 150px;
  }
  @media (max-width: 330px) {
    min-width: 140px;
    width: 140px;
    height: 140px;
  }
`;

export const SliderWrapper = styled.div<{isMobile: boolean; isBig: boolean}>`
  width: 100%;
  height: 168px;
  /* height: ${({isMobile, isBig}) =>
    isMobile ? '180px' : isBig ? '220px' : '168px'}; */

  .slick-dots {
    bottom: ${({isMobile, isBig}) =>
      isMobile ? '57px' : isBig ? '0px' : '0px'};
  }

  .slick-dots li {
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp100};
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    bottom: 10px;
    height: 200px;

    .slick-dots {
      bottom: 57px;
    }
  }
`;
