import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 1018px;
  height: 100%;
  position: relative;
  background-color: ${({theme}) => theme.colors.bodyBg};
  border-radius: 0 0 24px 24px;

  .banner-carousel {
    position: relative;
    border-radius: 0 0 24px 24px;
  }

  .banner {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .slick-list {
    border-radius: 0 0 24px 24px;
  }

  .slick-dots {
    bottom: 36px;
  }

  .slick-dots li {
    border: 1px solid white;
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
    /* background: transparent; */
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: white;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 420px;
    height: unset;
    aspect-ratio: 1;
    border-radius: 0px;

    .slick-list {
      border-radius: 0px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    /* display: none; */
    /* display: none; */
    width: 390px;
    /* display: unset; */
  }
`;

export const SingleBannerWrapper = styled.div<{background; mobileBackground}>`
  width: 100%;
  height: 386px;
  position: relative;
  background-image: ${({background}) => `url(${background})`};
  border-radius: 0 0 24px 24px;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.12);

  & > h1 {
    font-size: 24px;
    font-weight: bold;
    color: white;
    letter-spacing: -0.15px;
    position: absolute;
    top: 59px;
    left: 138px;
    width: 360px;
  }

  & > p {
    font-size: 11px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    letter-spacing: 0.1px;
    position: absolute;
    top: 172px;
    left: 138px;
    width: 295px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    /* background-position-x: -387px; */
    height: unset;
    aspect-ratio: 1;
    border-radius: 0px;
    box-shadow: unset;
    background-image: ${({mobileBackground}) => `url(${mobileBackground})`};
    background-size: contain;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    & > h1 {
      font-size: 21px;
      top: 17px;
      left: 21px;
      /* width: 308px; */
    }

    & > p {
      top: 124px;
      left: 20px;
      font-size: 13px;
      width: 283px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex !important;
  }
`;

export const SearchBarWrapper = styled.div`
  position: absolute;
  bottom: 80px;
  left: 322px;
  height: 28px;
  width: 376px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 2.5px;
  background: white;
  border: 1px solid rgba(23, 22, 29, 0.3);
  border-radius: 9px;

  & > input {
    width: 324px;
    border: none;
    background: transparent;
    outline: none;
    font-size: 11px;
    letter-spacing: 0.05px;
    font-weight: 400;
    color: black;

    &::placeholder {
      color: black;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    bottom: 80px;
    /* left: 81px; */
    position: unset;
    width: 258px;
    margin-bottom: 80px;

    & > input {
      width: 204px;
    }
  }
`;

export const SearchButton = styled.div`
  width: 28px;
  height: 23px;
  border-radius: 7px;
  background: ${({theme}) => theme.colors.blueTheme};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 11px;
  letter-spacing: 0.05px;
  font-weight: 400;
  color: rgba(23, 22, 29, 0.5);

  &::placeholder {
    color: rgba(23, 22, 29, 0.5);
  }

  & > svg {
    margin-left: 2px;
  }
`;
