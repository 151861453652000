import React, {createContext, useState} from 'react';

type AlertContextProps = {
  title: string;
  text: string;
  button: string;
  reload: boolean;
  closeAlert: () => void;
  Alert: (
    text: string,
    title: string,
    button?: string,
    reload?: boolean,
  ) => void;
};

type AlertContextProviderProps = {
  children: React.ReactNode;
};

const AlertContext = createContext<AlertContextProps>({} as AlertContextProps);

export const AlertProvider: React.FC<AlertContextProviderProps> = ({
  children,
}) => {
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const [button, setButton] = useState('Zamknij');
  const [reload, setReload] = useState(false);

  const Alert = (
    text: string,
    title: string,
    button = 'Zamknij',
    reload = false,
  ) => {
    setText(text);
    setTitle(title);
    setButton(button);
    setReload(reload);
  };
  const closeAlert = () => {
    setText('');
    setTitle('');
  };

  return (
    <AlertContext.Provider
      value={{
        text,
        title,
        button,
        reload,
        closeAlert,
        Alert,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
