import React, {useEffect, useState, useCallback, useContext} from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';

import api from 'services/axiosConfig';
import {getSpecificRole, hexToRgb} from '../../MyStudio/BusinessCard';
import locationIcon from 'images/svg/grey-location-icon.svg';
import {ResolutionContext} from '../../../contexts/ResolutionContext';

import {light} from '../../../constants/theme';
import {
  ProducerNameWrapper,
  PromotionText,
  RoleAndCityWrapper,
  SingleCardContentWrapper,
  SingleCardImageWrapper,
  SingleCardMobileImgAndNameWrapper,
  SingleCardWrapper,
  StyledText,
  StyledTextName,
  StyledTextRole,
  SvgFollowWrapper,
  SvgVerificationWrapper,
} from './styles';

export interface Role {
  FieldName: string;
  FlagValue: number;
}

export interface Block {
  BackgroundColorHex: string;
  BlockOpacityIn0To100Ratio: number;
  FontColorHex: string;
  Height: number;
  Id: string;
  IsRequiredOnCard: boolean;
  StartX: number;
  Type: number;
  Width: number;
}

export interface ProducerCard {
  BackgroundColorHex: string;
  VerifiedRole: boolean;
  CardId: string;
  Country: string;
  City: string;
  PostalCode: string;
  ProducerFirstName: string;
  ProducerId: string;
  ProducerLastName: string;
  ProducerName: string;
  ProducerPhoto: string;
  Role: number;
  SecondaryRole: number;
  AboutMe: string;
  Street: string;
  BaseBlock: Block;
}

export interface Props {
  producer: ProducerCard;
  roles: Role[];
  desktopRef?: any;
  mobileRef?: any;
}

export interface City {
  City: string;
  Region: string;
}

const SingleProducer: React.FC<Props> = ({
  producer,
  roles,
  desktopRef,
  mobileRef,
}) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const {
    BackgroundColorHex,
    BaseBlock,
    CardId,
    Country,
    PostalCode,
    ProducerFirstName,
    ProducerId,
    ProducerLastName,
    ProducerName,
    ProducerPhoto,
    Role,
    SecondaryRole,
    AboutMe,
    City,
    Street,
    VerifiedRole,
  } = producer;
  const [cities, setCities] = useState<City[]>([]);
  const {windowWidth} = useContext(ResolutionContext);

  const BackgroundColorRgb = hexToRgb(BaseBlock?.BackgroundColorHex);

  const handleIsHoveredChange = (value: boolean) => setHovered(value);

  // const getCitiesFromPostalCode = async (PostalCode: string) => {
  //   try {
  //     const params = {
  //       PostalCode,
  //     };

  //     const response = await api.get('Localizations', {params});
  //     setCities(response.data.Response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   getCitiesFromPostalCode(PostalCode);
  // }, []);

  if (windowWidth <= light.breakpointsJs.mobile) {
    return (
      <Link
        ref={mobileRef}
        to={`/producers/card/${ProducerId}`}
        key={CardId}
        style={{textDecoration: 'none', width: '100%'}}
      >
        <SingleCardWrapper smallHeigth={AboutMe.length < 1}>
          <SvgFollowWrapper>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11.493"
              height="11"
              viewBox="0 0 11.493 11"
            >
              <path
                id="Icon_awesome-star"
                data-name="Icon awesome-star"
                d="M6.571.382l-1.4,2.844L2.03,3.683a.688.688,0,0,0-.38,1.173L3.92,7.069l-.537,3.126a.687.687,0,0,0,1,.724L7.188,9.443,10,10.919a.688.688,0,0,0,1-.724l-.537-3.126,2.271-2.213a.688.688,0,0,0-.38-1.173L9.207,3.226,7.8.382A.688.688,0,0,0,6.571.382Z"
                transform="translate(-1.441 0.001)"
                opacity="0.7"
              />
            </svg>
          </SvgFollowWrapper>
          <SingleCardMobileImgAndNameWrapper>
            <SingleCardImageWrapper>
              {ProducerPhoto && <img src={ProducerPhoto} />}
            </SingleCardImageWrapper>
            <SingleCardContentWrapper>
              <ProducerNameWrapper>
                <StyledTextName>
                  {ProducerFirstName
                    ? `${ProducerFirstName} `
                    : `${ProducerName}`}
                </StyledTextName>
                {ProducerFirstName && (
                  <StyledTextName style={{paddingTop: 0}}>
                    {ProducerFirstName
                      ? ` ${ProducerLastName}`
                      : `${ProducerName}`}
                  </StyledTextName>
                )}
              </ProducerNameWrapper>
              <RoleAndCityWrapper>
                <StyledTextRole>
                  {`${getSpecificRole(roles, Role)} ${
                    SecondaryRole
                      ? `• ${getSpecificRole(roles, SecondaryRole)}`
                      : ''
                  } `}
                </StyledTextRole>
                {/* <StyledTextRole>
                {` ${getSpecificRole(roles, SecondaryRole)}`} •
              </StyledTextRole> */}
                <StyledTextRole>
                  {City} • {Country}
                </StyledTextRole>
              </RoleAndCityWrapper>
              <SvgVerificationWrapper>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.303"
                  height="15"
                  viewBox="0 0 18.303 15"
                >
                  <g
                    id="Icon_feather-user-check"
                    data-name="Icon feather-user-check"
                    transform="translate(-0.5 -3.5)"
                    opacity="0.7"
                  >
                    <path
                      id="Path_4196"
                      data-name="Path 4196"
                      d="M12.333,26.833V25.389A2.889,2.889,0,0,0,9.444,22.5H4.389A2.889,2.889,0,0,0,1.5,25.389v1.444"
                      transform="translate(0 -9.333)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_4197"
                      data-name="Path 4197"
                      d="M12.528,7.389A2.889,2.889,0,1,1,9.639,4.5,2.889,2.889,0,0,1,12.528,7.389Z"
                      transform="translate(-2.722 0)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_4198"
                      data-name="Path 4198"
                      d="M25.5,14.944l1.444,1.444L29.833,13.5"
                      transform="translate(-12.444 -4.667)"
                      fill="none"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </SvgVerificationWrapper>
            </SingleCardContentWrapper>
          </SingleCardMobileImgAndNameWrapper>
          <StyledText>{AboutMe}</StyledText>
        </SingleCardWrapper>
      </Link>
    );
  }

  return (
    <Link
      ref={desktopRef}
      to={`/producers/card/${ProducerId}`}
      key={CardId}
      style={{textDecoration: 'none', width: '100%'}}
    >
      <SingleCardWrapper smallHeigth={AboutMe.length < 1}>
        <SingleCardImageWrapper>
          {ProducerPhoto && <img src={ProducerPhoto} />}
        </SingleCardImageWrapper>
        <SingleCardContentWrapper>
          {/*<svg*/}
          {/*  xmlns="http://www.w3.org/2000/svg"*/}
          {/*  width="76.207"*/}
          {/*  height="12"*/}
          {/*  viewBox="0 0 76.207 12"*/}
          {/*>*/}
          {/*  <g*/}
          {/*    id="Group_5691"*/}
          {/*    data-name="Group 5691"*/}
          {/*    transform="translate(-307 -637)"*/}
          {/*  >*/}
          {/*    <text*/}
          {/*      id="Menu_Four"*/}
          {/*      data-name="Menu Four"*/}
          {/*      transform="translate(322.207 647)"*/}
          {/*      fill="#06f"*/}
          {/*      fontSize="9"*/}
          {/*      fontFamily="SegoeUI-Bold, Segoe UI"*/}
          {/*      fontWeight="700"*/}
          {/*      letterSpacing="-0.005em"*/}
          {/*    >*/}
          {/*      <tspan x="0" y="0">*/}
          {/*        PROMOWANY*/}
          {/*      </tspan>*/}
          {/*    </text>*/}
          {/*    <path*/}
          {/*      id="Icon_material-stars"*/}
          {/*      data-name="Icon material-stars"*/}
          {/*      d="M8.495,3A5.5,5.5,0,1,0,14,8.5,5.5,5.5,0,0,0,8.495,3Zm2.332,8.8L8.5,10.4,6.174,11.8,6.79,9.155,4.738,7.378l2.706-.231L8.5,4.65,9.556,7.142l2.706.231L10.21,9.149Z"*/}
          {/*      transform="translate(304 635)"*/}
          {/*      fill="#06f"*/}
          {/*    />*/}
          {/*  </g>*/}
          {/*</svg>*/}
          <PromotionText>
            {VerifiedRole ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.811"
                height="10.5"
                viewBox="0 0 12.811 10.5"
              >
                <g
                  id="Icon_feather-user-check"
                  data-name="Icon feather-user-check"
                  transform="translate(-0.75 -3.75)"
                  opacity="0.7"
                >
                  <path
                    id="Path_4196"
                    data-name="Path 4196"
                    d="M9,25.5v-1a2,2,0,0,0-2-2H3.5a2,2,0,0,0-2,2v1"
                    transform="translate(0 -12)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                  <path
                    id="Path_4197"
                    data-name="Path 4197"
                    d="M10.75,6.5a2,2,0,1,1-2-2A2,2,0,0,1,10.75,6.5Z"
                    transform="translate(-3.5)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                  <path
                    id="Path_4198"
                    data-name="Path 4198"
                    d="M25.5,14.5l1,1,2-2"
                    transform="translate(-16 -6)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            ) : null}
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9.403"
              height="9"
              viewBox="0 0 9.403 9"
            >
              <path
                id="Icon_awesome-star"
                data-name="Icon awesome-star"
                d="M5.639.312,4.491,2.639l-2.568.374a.563.563,0,0,0-.311.96L3.47,5.784,3.03,8.341a.562.562,0,0,0,.816.592l2.3-1.207,2.3,1.207a.563.563,0,0,0,.816-.592L8.816,5.784l1.858-1.81a.563.563,0,0,0-.311-.96L7.8,2.639,6.647.312A.563.563,0,0,0,5.639.312Z"
                transform="translate(-1.441 0.001)"
                opacity="0.7"
              />
            </svg> */}
          </PromotionText>
          <StyledTextName
            title={
              ProducerFirstName !== ''
                ? `${ProducerFirstName} ${ProducerLastName}`
                : `${ProducerName}`
            }
          >
            {ProducerFirstName !== ''
              ? `${ProducerFirstName} ${ProducerLastName}`
              : `${ProducerName}`}
          </StyledTextName>
          <StyledTextRole>
            {/* {`${getSpecificRole(roles, Role)} `} •
            {` ${getSpecificRole(roles, SecondaryRole)}`} •{` `} */}
            {`${getSpecificRole(roles, Role)} ${
              SecondaryRole ? `• ${getSpecificRole(roles, SecondaryRole)}` : ''
            } `}
            • {City} • {Country}
          </StyledTextRole>
          {/*<StyledText>*/}
          {/*  {cities.length > 0 ? cities[0].City : 'Nie znaleziono'}, {Country}*/}
          {/*</StyledText>*/}
          <StyledText>{AboutMe}</StyledText>
        </SingleCardContentWrapper>
      </SingleCardWrapper>
    </Link>
  );
};

export default SingleProducer;
