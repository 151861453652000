import React, {createContext, useState, useEffect} from 'react';

interface Context {
  windowWidth: number;
}

export const ResolutionContext = createContext<Context>(null!);

interface Props {
  children: React.ReactNode;
}

const ResolutionProvider: React.FC<Props> = ({children}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  return (
    <ResolutionContext.Provider value={{windowWidth}}>
      {children}
    </ResolutionContext.Provider>
  );
};

export default ResolutionProvider;
