import React, {useContext, useEffect, useRef, useState} from 'react';
import {v4} from 'uuid';
import {
  GalleryBlockHeader,
  GalleryBlockNavigation,
  GalleryBlockWrapper,
  GalleryBlockContent,
  DeleteButton,
  GalleryImgWrapper,
  GalerryAddButonWrapper,
  SvgWrapper,
  SvgBg,
  GalleryNextWrapper,
} from './styles';
import {Block, BlockFile} from '../../interface';
import {hexToRgb} from '../../index';
import {useCookies} from 'react-cookie';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import api from '../../../../../services/axiosConfig';
import useAlert from 'hooks/useAlert';

import {EditHoverContext} from 'contexts/EditHoverContext';
import {useParams} from 'react-router-dom';
import {ImgRow} from '../GalleryBlock/imgRow';
import {ImgColumn} from '../GalleryBlock/imgColumn';

export interface GalleryBlock extends Block {
  BlockFiles: BlockFile[];
}

export interface UpdateParams {
  blockFileIds: string[];
  blockFiles: BlockFile[];
  base64Files: string[];
}

interface Props {
  theme: string;
  block: Block;
  mobileOverview: boolean;
  updateImgGallery: (param: UpdateParams) => void;
  isEditing: boolean;
  isAdminMode: boolean;
  producerIdForAdmin: string;
}

export const sliceIntoChunks = (
  arr: BlockFile[] | [],
  chunkSize: number,
  isEditing: boolean,
) => {
  if (!arr) return [];
  const res = [];
  const arrLength = isEditing ? arr.length : arr.length - 1;
  for (let i = 0; i < arrLength; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const AdminGalleryBlockComponent: React.FC<Props> = ({
  theme,
  block,
  mobileOverview,
  updateImgGallery,
  isEditing,
  isAdminMode,
  producerIdForAdmin,
}) => {
  const {WebArea, BlockFiles, BackgroundColorHex, BlockOpacityIn0To100Ratio} =
    block;
  const {Width, Height} = WebArea;

  const initImages = BlockFiles
    ? BlockFiles
    : [{BlockId: '', CreationDate: '', FileName: '', FilePath: '', Id: ''}];

  const {Alert} = useAlert();

  const [currentGalleryPage, setCurrentGalleryPage] = useState<number>(0);
  const [images, setImages] = useState<BlockFile[]>(initImages);
  const [imgIndex, setImgIndex] = useState(0);
  const [selectedImgId, setSelectedImgId] = useState('');
  const [selectedBase, setSelectedBase] = useState('');
  const [imgBase, setImgBase] = useState([]);

  const imgRef = useRef<HTMLInputElement>(document.createElement('input'));

  const [cookies, setCookie] = useCookies(['token']);

  const [admin, setAdmin] = useState(false);
  const [adminId, SetAdminId] = useState('123');

  const [blockHover, setBlockHover] = useContext(EditHoverContext);
  const {decodedToken} = useContext(ProducerDataContext);

  const maxImgIndex = images.length - 1;

  const handleSelectedImgId = (id, base) => {
    setSelectedImgId(id), setSelectedBase(base);
  };

  const nextImg = () => {
    if (imgIndex === maxImgIndex) return;
    setImgIndex((prevState) => prevState + 1);
  };

  const prevImg = () => {
    if (imgIndex === 0) return;
    setImgIndex((prevState) => prevState - 1);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getImageFile = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();

      //

      const base64 = await toBase64(e.target.files[0]);

      const newBlockFile = {
        BlockId: block.Id,
        CreationDate: '',
        FileName: e.target.files[0].name,
        FilePath: URL.createObjectURL(e.target.files[0]),
        Id: 'xxx',
      };

      updateImgGallery({
        blockFileIds: [
          ...images.map((img) => img.Id).filter((img) => img !== 'xxx'),
        ],
        blockFiles: [...images, newBlockFile],
        base64Files: [...imgBase, base64.toString().split(',')[1]],
        // base64Files: [base64.toString().split(',')[1]],
      });
      setImages((prev) => [...prev, newBlockFile]);
      setImgBase((prev) => [...prev, base64.toString().split(',')[1]]);
      Alert(`Dodano plik ${e.target.files[0].name}`, 'Komunikat');
    }
  };

  const handleDeleteAdmin = () => {
    console.log('Handle Delete:', isAdminMode, producerIdForAdmin);
    deleteImgAdmin(selectedImgId, selectedBase);
  };

  const deleteImgAdmin = async (id: string, base: string) => {
    if (id === '') return;

    // Upewnijmy się, że wartości są używane bezpośrednio w funkcji
    const producerId = isAdminMode
      ? producerIdForAdmin
      : decodedToken.ProducerId;
    // console.log('w dasdasdasdas');
    // console.log(block, 'w delete');
    console.log(isAdminMode, 'producerIdForAdmin:', producerIdForAdmin);

    try {
      const response = await api.delete(
        `Producer/${producerId}/blocks/${block.Id}/files/${id}`,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );

      setImages((prev) => prev.filter((item) => item.Id !== id));
      const imgAfterDelete = images.filter((img) => img.Id !== id);
      updateImgGallery({
        blockFileIds: imgAfterDelete.map((img) => img.Id),
        blockFiles: imgAfterDelete,
        base64Files: [...imgBase],
      });
      setSelectedImgId('');
    } catch (error) {
      alert('coś poszło nie tak ');
    }
  };

  const initObjImage: BlockFile = {
    BlockId: '',
    CreationDate: '',
    FileName: '',
    FilePath: '',
    Id: 'Add',
  };

  const slicedGallery = sliceIntoChunks(
    [...images, initObjImage],
    mobileOverview ? 1 * Height : Width * Height,
    isEditing,
  );
  const nextGalleryPage = () => {
    if (currentGalleryPage === slicedGallery.length - 1) {
      return currentGalleryPage;
    }
    return setCurrentGalleryPage((prev) => prev + 1);
  };

  const prevGalleryPage = () => {
    if (currentGalleryPage === 0) {
      return currentGalleryPage;
    }
    return setCurrentGalleryPage((prev) => prev - 1);
  };

  const hoverTextOn = (text: string) => {
    setBlockHover((prev) => ({Active: true, Text: text}));
  };

  const hoverTextOff = () => {
    setBlockHover((prev) => ({Active: false, Text: ''}));
  };

  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);

  //   useEffect(() => {
  //     console.log('Updated values:', isAdminMode, producerIdForAdmin);
  //     if (isAdminMode !== undefined && producerIdForAdmin !== undefined) {
  //       console.log('wchodze ');
  //       setAdmin(true);
  //       SetAdminId(params.id);
  //       console.log(params.id);
  //     }
  //   }, [isAdminMode, producerIdForAdmin]);

  return (
    <GalleryBlockWrapper
      backgroundAlpha={BlockOpacityIn0To100Ratio}
      backgroundColor={BackgroundColorRgb}
    >
      {Height > 3 ? (
        <ImgRow
          isMobile={mobileOverview}
          isEditing={isEditing}
          ImgIndex={imgIndex}
          Images={images}
          Height={Height}
          Width={Width}
          handleSelected={handleSelectedImgId}
          selectedId={selectedImgId}
        />
      ) : (
        <ImgColumn
          isEditing={isEditing}
          ImgIndex={imgIndex}
          Images={images}
          Width={Width}
        />
      )}
      <SvgWrapper Width={Width} isEditing={isEditing}>
        {isEditing ? (
          <SvgBg
            onMouseEnter={() => hoverTextOn('Dodaj zdjęcie')}
            onMouseLeave={hoverTextOff}
            onClick={
              images.length >= 10
                ? () =>
                    Alert(
                      'Nie można dodać więcej zdjęć, limit wynosi 10.',
                      'Nie można dodać więcej zdjęć, limit wynosi 10.',
                    )
                : () => imgRef.current.click()
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.166"
              height="18"
              viewBox="0 0 18.166 18"
            >
              <path
                id="Path_3775"
                data-name="Path 3775"
                d="M189.142,108.478l-7.507.162.131-7.445a.818.818,0,1,0-1.628,0l.131,7.445-7.507-.162a.817.817,0,1,0,0,1.628l7.507-.162-.131,7.444a.818.818,0,1,0,1.628,0l-.131-7.444,7.507.162a.817.817,0,1,0,0-1.628Z"
                transform="translate(-171.869 -100.292)"
                // fill={`rgba(${hexToRgb(block.BackgroundColorHex)})`}
                fill="black"
              />
            </svg>
          </SvgBg>
        ) : null}
        {isEditing ? (
          <SvgBg
            onMouseEnter={() => hoverTextOn('Usuń zdjęcie')}
            onMouseLeave={hoverTextOff}
            // onClick={() => deleteImg(selectedImgId, selectedBase)}
            onClick={handleDeleteAdmin}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12.3"
              height="13.5"
              viewBox="0 0 12.3 13.5"
            >
              <g
                id="Icon_feather-trash"
                data-name="Icon feather-trash"
                transform="translate(0.75 0.75)"
              >
                <path
                  id="Path_3663"
                  data-name="Path 3663"
                  d="M4.5,9H15.3"
                  transform="translate(-4.5 -6.6)"
                  fill="none"
                  // stroke={`rgba(${hexToRgb(block.BackgroundColorHex)})`}
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <path
                  id="Path_3664"
                  data-name="Path 3664"
                  d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                  transform="translate(-6.3 -3)"
                  fill="none"
                  // stroke={`rgba(${hexToRgb(block.BackgroundColorHex)})`}
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </g>
            </svg>
          </SvgBg>
        ) : null}
      </SvgWrapper>
      <input
        type="file"
        onChange={getImageFile}
        accept=".jpeq,.jpg,.png"
        hidden
        ref={imgRef}
      />
    </GalleryBlockWrapper>
  );
};

export default AdminGalleryBlockComponent;
