import React from 'react';
import {useConfirmationModalContext} from '../../../hooks/modalConfirmationContext';

type PopupButtonProps = React.HTMLProps<HTMLButtonElement> & {
  text: string;
  title: string;
  buttons: string[];
};

const PopupButton: React.FC<PopupButtonProps> = ({
  children,
  onClick,
  title,
  text,
  buttons,
}) => {
  const modalContext = useConfirmationModalContext();

  const handleOnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const result = await modalContext.showConfirmation(buttons, title, text);
    result && onClick && onClick(event);
  };

  return (
    <button style={{border: 'none', background: "transparent"}} onClick={handleOnClick}>
      {children}
    </button>
  );
};

export default PopupButton;
