import React, {useContext, useEffect, useRef, useState} from 'react';

import {
  GradientForImg,
  LdsRing,
  LdsRingDiv,
  PlayIconWrapper,
  TrackRowCategoryWrapper,
  TrackRowDescription,
  TrackRowFooterWrapper,
  TrackRowImgWrapper,
  TrackRowNameTrack,
  TrackRowPriceWrapper,
  TrackRowProductType,
  TrackRowSvgContent,
  TrackRowTextContent,
  TrackRowWinWin,
  TrackRowWrapper,
} from './styles';

import soundtrackSmall from '../../../../../../images/svg/soundtrack-small.svg';
import soundtrackBig from '../../../../../../images/svg/soundtrack-Big.svg';
import stopPlayMusic from '../../../../../../images/svg/stop-play-audio.svg';
import {DeleteButton} from '../style';
import {hexToRgb} from '../../../index';
import deleteBlack from '../../../../../../images/svg/delete-black.svg';
import {SingleCategory} from '../../../interface';
import {SoundTrack} from '../soundTrack';
import {Offer} from '../../RecomendedProductionBlock';
import {
  ButtonBg,
  ButtonLeftAndRightWrapper,
} from '../../RecomendedProductionBlock/styles';
import {VisitedProducerContext} from '../../../../../../contexts/VisitedProducerContext';
import {EditHoverContext} from '../../../../../../contexts/EditHoverContext';
import {OfferEndDataWrapper} from '../../OfferBlock/OfferBlockRowView/styles';
import {useNavigate} from 'react-router-dom';

interface Props {
  offer: Offer;
  SoundSrc: string;
  ImgSrc: string;
  IsWinWin: boolean;
  Category: SingleCategory;
  SecondCategoryId: SingleCategory;
  ThirdCategoryId: SingleCategory;
  Description: string;
  Name: string;
  FontColor: string;
  Width: number;
  Height: number;
  ProductType: number;
  GrossValue: number;
  Currency: string;
  TitleColorHex;
  BackgroundColor: string;
  removeOffer: () => void;
  isEditing: boolean;
  isMobile: boolean;
  BlockOpacityIn0To100Ratio: number;
  offerId: string;
}

const TrackBlockRowView: React.FC<Props> = ({
  SoundSrc,
  ImgSrc,
  IsWinWin,
  GrossValue,
  Name,
  Width,
  Height,
  FontColor,
  BackgroundColor,
  TitleColorHex,
  Category,
  Currency,
  ProductType,
  isEditing,
  removeOffer,
  SecondCategoryId,
  ThirdCategoryId,
  Description,
  offer,
  offerId,
  isMobile,
  BlockOpacityIn0To100Ratio,
}) => {
  const [isAudioPlayed, setIsAudioPlayed] = useState<boolean>(false);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [elapsed, setElapsed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [percentOfTrack, setPercentOfTrack] = useState<number>(0);

  const [visitedProducer, setVisitedProducer] = useContext(
    VisitedProducerContext,
  );
  const [blockHover, setBlockHover] = useContext(EditHoverContext);

  const playAudio = (
    e: React.MouseEvent<SVGSVGElement>,
    audio: HTMLAudioElement,
  ) => {
    e.stopPropagation();
    // console.log('loading');
    setIsLoading(true);
    const playPromise = audio.play();
    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // console.log('gram');
          setIsLoading(false);
          // console.log('moge tu pauze dac');
        })
        .catch((error) => {
          console.log('pauza w error???');
        });
    }
    // console.log(audio.src);
    setIsAudioPlayed(true);
  };

  const pauseAudio = (
    e: React.MouseEvent<SVGSVGElement>,
    audio: HTMLAudioElement,
  ) => {
    e.stopPropagation();
    audio.pause();
    setIsAudioPlayed(false);
  };

  const calculatePercentOfTrack = (num: number, secondNum: number) => {
    if (secondNum === 0) return;

    return Math.floor((num / secondNum) * 100);
  };

  const hoverTextOn = (text: string) => {
    setBlockHover((prev) => ({Active: true, Text: text}));
  };

  const hoverTextOff = () => {
    setBlockHover((prev) => ({Active: false, Text: ''}));
  };

  useEffect(() => {
    if (isAudioPlayed) {
      setInterval(() => {
        const _duration = Math.floor(audio.duration);
        const _elapsed = Math.floor(audio.currentTime);

        setPercentOfTrack(calculatePercentOfTrack(_elapsed, _duration));
        setDuration(_duration);
        setElapsed(_elapsed);
      }, 100);
    }
  }, [isAudioPlayed]);

  useEffect(() => {
    if (elapsed === duration && isAudioPlayed && elapsed > 10) {
      audio.currentTime = 0;
      audio.pause();
      setIsAudioPlayed(false);
    }
  }, [elapsed]);

  useEffect(() => {
    if (SoundSrc) {
      setAudio(new Audio(SoundSrc));
    }
  }, [SoundSrc]);
  console.log(isMobile, 'w track');
  const navigate = useNavigate();
  const toProductInfo = (id: string) => {
    navigate(`/myproducts/product/${id}`);
  };
  return (
    <TrackRowWrapper
      isMobile={isMobile}
      Width={Width}
      bgColor={hexToRgb(TitleColorHex)}
    >
      {isEditing && (
        <OfferEndDataWrapper onClick={() => toProductInfo(offerId)}>
          {!offer.IsActive &&
          offer.Name ===
            'Ilustracja wygenerowana przez sztuczną inteligencję' ? (
            <p onClick={() => toProductInfo(offerId)}>
              Oferta wygasła, Kliknij aby odnowić
            </p>
          ) : null}
        </OfferEndDataWrapper>
      )}
      <PlayIconWrapper isMobile={isMobile}>
        {Width === 3 || Width === 5 || Width === 9 ? (
          isLoading ? (
            <LdsRing>
              <LdsRingDiv color={`rgb(${FontColor})`}></LdsRingDiv>
              <LdsRingDiv color={`rgb(${FontColor})`}></LdsRingDiv>
              <LdsRingDiv color={`rgb(${FontColor})`}></LdsRingDiv>
            </LdsRing>
          ) : !isAudioPlayed ? (
            <svg
              onClick={(e) => playAudio(e, audio)}
              xmlns="http://www.w3.org/2000/svg"
              width="43"
              height="43"
              viewBox="0 0 43 43"
              fill={`rgb(${FontColor})`}
            >
              <g
                id="Icons_24px_Play_Video"
                data-name="Icons – 24px / Play, Video"
                transform="translate(0.5 0.5)"
                fill={`rgb(${FontColor})`}
              >
                <path
                  id="Union_1"
                  data-name="Union 1"
                  d="M15635.65,6672.347a21,21,0,1,1,14.855,6.152A20.866,20.866,0,0,1,15635.65,6672.347Zm-3-14.852a17.85,17.85,0,1,0,17.855-17.846A17.873,17.873,0,0,0,15632.65,6657.5Zm13.645-8.4,12.605,8.4-12.605,8.395Z"
                  transform="translate(-15629.498 -6636.499)"
                  stroke="rgba(0,0,0,0)"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                  fill={`rgb(${FontColor})`}
                />
              </g>
            </svg>
          ) : (
            <svg
              onClick={(e) => pauseAudio(e, audio)}
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
            >
              <g
                id="Group_4876"
                data-name="Group 4876"
                transform="translate(-1134.651 -366.929)"
              >
                <path
                  id="Path_1675"
                  data-name="Path 1675"
                  d="M1155.651,408.929a21,21,0,1,1,21-21A21.026,21.026,0,0,1,1155.651,408.929Zm0-37.333a16.333,16.333,0,1,0,16.333,16.333A16.353,16.353,0,0,0,1155.651,371.6Z"
                  transform="translate(0 0)"
                  fill={`rgb(${FontColor})`}
                />
                <rect
                  id="Rectangle_865"
                  data-name="Rectangle 865"
                  width="12.444"
                  height="12.444"
                  rx="1"
                  transform="translate(1149.431 381.706)"
                  fill={`rgb(${FontColor})`}
                />
              </g>
            </svg>
          )
        ) : isLoading ? (
          <LdsRing>
            <LdsRingDiv color={`rgb(${FontColor})`}></LdsRingDiv>
            <LdsRingDiv color={`rgb(${FontColor})`}></LdsRingDiv>
            <LdsRingDiv color={`rgb(${FontColor})`}></LdsRingDiv>
          </LdsRing>
        ) : !isAudioPlayed ? (
          <svg
            onClick={(e) => playAudio(e, audio)}
            xmlns="http://www.w3.org/2000/svg"
            width="90.039"
            height="90.04"
            viewBox="0 0 90.039 90.04"
          >
            <g
              id="Icons_24px_Play_Video"
              data-name="Icons – 24px / Play, Video"
              transform="translate(0.5 0.5)"
            >
              <path
                id="Icons_24px_Play_Video-2"
                data-name="Icons – 24px / Play, Video"
                d="M13.039,76A44.517,44.517,0,1,1,44.531,89.04,44.229,44.229,0,0,1,13.039,76ZM6.672,44.511A37.844,37.844,0,1,0,44.531,6.679,37.9,37.9,0,0,0,6.672,44.511Zm28.938-17.8L62.328,44.529l-26.719,17.8Z"
                fill={`rgb(${FontColor})`}
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </svg>
        ) : (
          <svg
            onClick={(e) => pauseAudio(e, audio)}
            id="Group_4876"
            data-name="Group 4876"
            xmlns="http://www.w3.org/2000/svg"
            width="89.04"
            height="89.04"
            viewBox="0 0 89.04 89.04"
          >
            <path
              id="Path_1675"
              data-name="Path 1675"
              d="M1179.171,455.969a44.52,44.52,0,1,1,44.52-44.52A44.575,44.575,0,0,1,1179.171,455.969Zm0-79.146a34.627,34.627,0,1,0,34.626,34.627A34.668,34.668,0,0,0,1179.171,376.822Z"
              transform="translate(-1134.651 -366.929)"
              fill={`rgb(${FontColor})`}
            />
            <rect
              id="Rectangle_865"
              data-name="Rectangle 865"
              width="26.382"
              height="26.382"
              rx="1"
              transform="translate(31.333 31.327)"
              fill={`rgb(${FontColor})`}
            />
          </svg>
        )}
      </PlayIconWrapper>

      <TrackRowImgWrapper isMobile={isMobile} Width={Width}>
        {/* <GradientForImg
          style={{
            background: `linear-gradient(90deg, rgba(${BackgroundColor},0.15) 40%,rgba(${BackgroundColor}) 100%`,
          }}
        ></GradientForImg> */}
        <img src={ImgSrc} alt="" />

        {Width === 9 ? (
          // <img src={soundtrackSmall} alt="soundTrackSmall" />
          <div
            style={{
              position: 'absolute',
              top: '70%',
              left: '50%',
              transform: `translate(-50%, -50%)`,
            }}
          >
            <SoundTrack
              color={`rgb(255,255,255)`}
              percent={percentOfTrack}
              type={'small'}
            />
          </div>
        ) : null}
      </TrackRowImgWrapper>
      <TrackRowSvgContent>
        {/*{Width === 5 ? (*/}
        {/*  // <img src={soundtrackSmall} alt="soundTrackSmall" />*/}
        {/*  <SoundTrack*/}
        {/*    color={`rgb(0,0,0)`}*/}
        {/*    percent={percentOfTrack}*/}
        {/*    type={'small'}*/}
        {/*  />*/}
        {/*) : Width === 9 ? (*/}
        {/*  // <img src={soundtrackBig} alt="soundTrack" />*/}
        {/*  <SoundTrack*/}
        {/*    color={`rgb(${BackgroundColor})`}*/}
        {/*    percent={percentOfTrack}*/}
        {/*    type={'big'}*/}
        {/*  />*/}
        {/*) : null}*/}
        <TrackRowTextContent isMobile={isMobile}>
          <TrackRowNameTrack
            isMobile={isMobile}
            style={{color: `rgb(${FontColor})`}}
            Width={Width}
            onClick={() =>
              //  console.log(`/products/main/${ProductId}`, offer, offerId)
              navigate(`/products/main/${offer.ProductId}`)
            }
          >
            {Name}
          </TrackRowNameTrack>

          <TrackRowFooterWrapper>
            <TrackRowPriceWrapper isMobile={isMobile} Width={Width}>
              <p
                style={{
                  color: `rgb(${FontColor})`,
                  marginLeft: `${IsWinWin ? '0px' : '0px'}`,
                }}
              >{`od ${GrossValue / 100}zł`}</p>
              {IsWinWin && (
                <TrackRowWinWin isMobile={isMobile} Width={Width}>
                  <svg
                    id="Group_7835"
                    data-name="Group 7835"
                    xmlns="http://www.w3.org/2000/svg"
                    width="71.396"
                    height="18"
                    viewBox="0 0 71.396 18"
                  >
                    {/* <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_3780"
                          data-name="Rectangle 3780"
                          width="71.396"
                          height="18"
                          fill="#fb9614"
                        />
                      </clipPath>
                    </defs> */}
                    <g
                      id="Group_6972"
                      data-name="Group 6972"
                      clipPath="url(#clip-path)"
                    >
                      <path
                        id="Path_4111"
                        data-name="Path 4111"
                        d="M212.43,39.406l-1.146,2.49c-.286.623-.583,1.285-.86,1.917h-.02c-.049-.613-.109-1.265-.178-1.888l-.277-2.52h-1.492l-1.186,2.53c-.287.613-.593,1.285-.85,1.878h-.02c-.039-.593-.1-1.255-.158-1.868L206,39.406h-2.125l.9,7.155h2.105l.79-1.69c.287-.613.583-1.265.86-1.9h.02c.049.623.109,1.235.178,1.868l.188,1.72h2.145l3.439-7.155Z"
                        transform="translate(-175.818 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4112"
                        data-name="Path 4112"
                        d="M274.581,39.406l-1.265,7.155h2.036l1.265-7.155Z"
                        transform="translate(-235.707 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4113"
                        data-name="Path 4113"
                        d="M298.82,39.406l-.356,2.036c-.128.731-.257,1.512-.356,2.155l-.02.01c-.346-.662-.712-1.334-1.077-2l-1.216-2.2h-1.9l-1.265,7.155h1.957l.356-2.036c.129-.731.257-1.512.356-2.154l.02-.01c.346.662.712,1.334,1.077,2l1.216,2.2h1.9l1.265-7.155Z"
                        transform="translate(-252.366 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4114"
                        data-name="Path 4114"
                        d="M349.151,61.521h2.481l.267-1.5h-2.481Z"
                        transform="translate(-301.107 -51.76)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4115"
                        data-name="Path 4115"
                        d="M379.471,39.406l-1.146,2.49c-.286.623-.583,1.285-.86,1.917h-.02c-.049-.613-.109-1.265-.178-1.888l-.277-2.52H375.5l-1.186,2.53c-.287.613-.593,1.285-.85,1.878h-.02c-.039-.593-.1-1.255-.158-1.868l-.247-2.54h-2.125l.9,7.155h2.105l.79-1.69c.287-.613.583-1.265.86-1.9h.02c.049.623.108,1.235.178,1.868l.188,1.72H378.1l3.439-7.155Z"
                        transform="translate(-319.874 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4116"
                        data-name="Path 4116"
                        d="M441.621,39.406l-1.265,7.155h2.036l1.265-7.155Z"
                        transform="translate(-379.762 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4117"
                        data-name="Path 4117"
                        d="M465.86,39.406l-.356,2.036c-.129.731-.257,1.512-.356,2.155l-.02.01c-.346-.662-.711-1.334-1.077-2l-1.216-2.2h-1.9l-1.265,7.155h1.957l.356-2.036c.128-.731.257-1.512.356-2.154l.02-.01c.346.662.712,1.334,1.077,2l1.216,2.2h1.9l1.265-7.155Z"
                        transform="translate(-396.421 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4118"
                        data-name="Path 4118"
                        d="M21.281,2.8H19.886L18.829,1.746h-3.5L18.91,5.33l.981-.05h1.391a.964.964,0,0,1,.963.963v5.5a.964.964,0,0,1-.962.963l-3.769,0-2.536,2.536a.964.964,0,0,1-1.362,0L9.723,11.352a.966.966,0,0,1-.221-1.02l6.9-.22.346-.346a3.44,3.44,0,0,0,0-4.865L12.858,1.009a3.44,3.44,0,0,0-4.865,0l-1.81,1.81-2.743,0A3.444,3.444,0,0,0,0,6.256v5.5A3.444,3.444,0,0,0,3.44,15.2H4.622l1.214,1.214h3.5L5.6,12.672l-1.04.052H3.44a.964.964,0,0,1-.963-.963v-5.5a.964.964,0,0,1,.962-.963l3.769,0L9.744,2.76a.963.963,0,0,1,1.362,0L15,6.652a.965.965,0,0,1,.221,1.02l-6.9.22-.346.346a3.44,3.44,0,0,0,0,4.865L11.863,17a3.444,3.444,0,0,0,4.865,0l1.81-1.81,2.743,0a3.444,3.444,0,0,0,3.44-3.44v-5.5a3.444,3.444,0,0,0-3.44-3.44"
                        transform="translate(0 -0.001)"
                        fill="#fb9614"
                      />
                    </g>
                  </svg>
                </TrackRowWinWin>
              )}
            </TrackRowPriceWrapper>
            <TrackRowCategoryWrapper Width={Width}>
              <p style={{color: `rgb(${FontColor})`, paddingRight: '5px'}}>
                {Category === null ? ' ' : Category.Name} -{'  '}
              </p>
              <p style={{color: `rgb(${FontColor})`, paddingRight: '5px'}}>
                {SecondCategoryId === null ? ' ' : ` ${SecondCategoryId.Name}`}
                {/* {ThirdCategoryId === null ? ' ' : ' '} */}
              </p>
              {/* <p style={{color: `rgb(${FontColor})`, paddingRight: '5px'}}>
                {ThirdCategoryId === null ? ' ' : ThirdCategoryId.Name}
              </p> */}
            </TrackRowCategoryWrapper>
          </TrackRowFooterWrapper>
        </TrackRowTextContent>
      </TrackRowSvgContent>
    </TrackRowWrapper>
  );
};

export default TrackBlockRowView;
