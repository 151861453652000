import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from 'react';
import styled, {css} from 'styled-components';
import {Link, useNavigate, useParams, useLocation} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import useAlert from 'hooks/useAlert';
import payWithCard from 'images/payWithCard.png';
import portfelMugoLogo from 'images/portfel_mugo_logo.png';
import blikImg from 'images/Blik_logo.png';
import {InputView, SelectView} from 'pages/MyAccount/Settings/styles';
import api from 'services/axiosConfig';
import {Offer} from 'pages/Products/index';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {UserDataContext} from 'contexts/UserDataContext';
import {Product, Condition} from 'pages/Products/ProductDetail/index';
import {ErrorStar} from 'components/ErrorStar';
import {LoadingCircle} from 'utils/loadingCircle';
import {
  CurrencyEnum,
  ExploitationDigitalEnum,
  ExploitationPhysicalEnum,
  ExploitationYoutubeEnum,
  ProductTypeEnum,
  ProtectiveUmbrellaEnum,
  RightsEndEnum,
  TimeToFinishEnum,
} from 'constants/conditionEnums';
import {
  BackButton,
  BoldTextInRow,
  BuyButton,
  CenterSection,
  HeaderWrapper,
  MobileHeader,
  IconWithTextSpan,
  LeftDotSvg,
  LeftSection,
  Main,
  MainWrapper,
  MobileBuyButton,
  MobileConditionContainer,
  MobileConditionsWrapper,
  MobileDescriptionTextareaWrapper,
  MobileForm,
  MobileFormContainer,
  MobileInputWrapper,
  MobileMain,
  MobileMainWrapper,
  MobileNameWithRealizationDataWrapper,
  MobileNameWithSymbolContainer,
  MobilePaymentSectionWrapper,
  MobilePaymentTypeCheckbox,
  MobilePaymentTypeContainer,
  MobilePersonTypeWrapper,
  MobilePriceWithTitleSpan,
  MobileProtectiveUmbrellaWrapper,
  MobileRealizationDateContainer,
  MobileSectionText,
  MobileSectionTextUnderline,
  MobileSinglePaymentTypeWrapper,
  MobileSinglePersonTypeWrapper,
  MobileSvgWithTitle,
  MobileWinWinTextInRow,
  PaymentTypeCheckbox,
  PaymentTypeContainer,
  PersonTypeWrapper,
  RealizationDateWithTitleSpan,
  RightSection,
  SectionContentBox,
  SectionTextGrey,
  SectionTextGreyWrapper,
  SectionTextPrice,
  SectionTextWithBorder,
  SectionTitle,
  SectionWinWinWrapper,
  SectionsWrapper,
  SinglePaymentTypeWrapper,
  SinglePersonTypeWrapper,
  CardBuyButton,
} from './styles';
import {StyledSelectView} from 'pages/MyStudio/Account';
import PayWithCard from 'components/PayWithCard';

export const BlueHeaderWrapper = styled.div`
  width: 259px;
  display: flex;
  align-items: center;
  position: relative;
  margin: 0px auto;

  & > h1 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.15px;
    line-height: 24px;
    color: #0091ff;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% - 26px);
  }
`;

export const LeftDot = styled.div`
  position: absolute;
  left: -12px;
  top: 7px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #0091ff;
`;

const BlueHeaderComponent: React.FC = ({children}) => {
  return (
    <BlueHeaderWrapper>
      <LeftDot />
      <h1>{children}</h1>
    </BlueHeaderWrapper>
  );
};

interface Props {
  currentCondition: number;
  offer: Offer;
  product: Product;
  setIsOrderOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOrderOpen: boolean;
}

export const getFormattedDate = (dateToFormat: string = undefined) => {
  const date = dateToFormat ? new Date(dateToFormat) : new Date();
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let day: string | number = date.getDate();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
};

const countries = [
  {FieldName: 'PL', FlagValue: 'PL'},
  {FieldName: 'GB', FlagValue: 'GB'},
];

interface BillingAddress {
  BuildingNumber: string;
  CompanyName: string;
  Country: string;
  Email: string;
  FirstName: string;
  FlatNumber: string;
  LastName: string;
  PhoneNumber: string;
  PostalCode: string;
  Region: string;
  Street: string;
  PESEL: string;
}
interface BillingAddressEditing {
  BuildingNumber: boolean;
  CompanyName: boolean;
  Country: boolean;
  Email: boolean;
  FirstName: boolean;
  FlatNumber: boolean;
  LastName: boolean;
  PhoneNumber: boolean;
  PostalCode: boolean;
  Region: boolean;
  Street: boolean;
  PESEL: boolean;
}

export interface ErrorVerification {
  buildingNumberError: string | null;
  companyNameError: string | null;
  countryError: string | null;
  emailError: string | null;
  firstNameError: string | null;
  NIPError: string | null;
  lastNameError: string | null;
  phoneNumberError: string | null;
  postalCodeError: string | null;
  regionError: string | null;
  streetError: string | null;
  peselError: string | null;
}

const OrderDetails: React.FC<Props> = ({
  currentCondition,
  offer,
  product,
  setIsOrderOpen,
  isOrderOpen,
}) => {
  const TRANSACTION_FEE = 15;
  const [paymentType, setPaymentType] = useState<number>(2);
  const [personType, setPersonType] = useState<number>(1);
  const [condition, setCondition] = useState<Condition | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPayP24Loading, setIsPayP24Loading] = useState<boolean>(false);
  const [isPayTransferLoading, setIsPayTransferLoading] =
    useState<boolean>(false);
  const [billingAddress, setBillingAddress] = useState<BillingAddress>({
    BuildingNumber: '',
    CompanyName: '',
    Country: '',
    Email: '',
    FirstName: '',
    FlatNumber: '',
    LastName: '',
    PhoneNumber: '',
    PostalCode: '',
    Region: '',
    Street: '',
    PESEL: '',
  });
  const [billingAddressIsEditing, setBilingAddressIsEditing] =
    useState<BillingAddressEditing>({
      BuildingNumber: false,
      CompanyName: false,
      Country: false,
      Email: false,
      FirstName: false,
      FlatNumber: false,
      LastName: false,
      PhoneNumber: false,
      PostalCode: false,
      Region: false,
      Street: false,
      PESEL: false,
    });
  const [errorVerification, setErrorVerification] = useState<ErrorVerification>(
    {
      buildingNumberError: null,
      companyNameError: null,
      countryError: null,
      emailError: null,
      firstNameError: null,
      lastNameError: null,
      NIPError: null,
      phoneNumberError: null,
      postalCodeError: null,
      regionError: null,
      streetError: null,
      peselError: null,
    },
  );
  const [NIP, setNIP] = useState<string>('');
  const [customerNote, setCustomerNote] = useState<string>('');
  const [cardPatInMode, setCardPayInMode] = useState<boolean>(false);

  const {Alert} = useAlert();
  const [cookies, setCookie] = useCookies(['token']);
  const {decodedToken} = useContext(ProducerDataContext);
  const [userData] = useContext(UserDataContext);

  const handleNIPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetOneError('NIP');
    setNIP(e.target.value);
  };

  const resetOneError = (id: string) => {
    if (id === 'BuildingNumber') {
      setErrorVerification((prev) => ({...prev, buildingNumberError: null}));
    }
    if (id === 'CompanyName') {
      setErrorVerification((prev) => ({...prev, companyNameError: null}));
    }
    if (id === 'Country') {
      setErrorVerification((prev) => ({...prev, countryError: null}));
    }
    if (id === 'Email') {
      setErrorVerification((prev) => ({...prev, emailError: null}));
    }
    if (id === 'FirstName') {
      setErrorVerification((prev) => ({...prev, firstNameError: null}));
    }
    if (id === 'LastName') {
      setErrorVerification((prev) => ({...prev, lastNameError: null}));
    }
    if (id === 'PhoneNumber') {
      setErrorVerification((prev) => ({...prev, phoneNumberError: null}));
    }
    if (id === 'PostalCode') {
      setErrorVerification((prev) => ({...prev, postalCodeError: null}));
    }
    if (id === 'Region') {
      setErrorVerification((prev) => ({...prev, regionError: null}));
    }
    if (id === 'Street') {
      setErrorVerification((prev) => ({...prev, streetError: null}));
    }
    if (id === 'NIP') {
      setErrorVerification((prev) => ({...prev, NIPError: null}));
    }
  };

  const handleCustomerNoteChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setCustomerNote(e.target.value);

  const handleCountrySelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setBillingAddress((prev) => ({...prev, Country: e.target.value}));
  };

  const handleBillingAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const {value, id} = e.target;
    resetOneError(id);
    if (id === 'PhoneNumber') {
      setBillingAddress((prev) => ({
        ...prev,
        [id]: value
          .replace(/[^0-9]/g, '')

          .trim(),
      }));
    } else if (id === 'PESEL') {
      setBillingAddress((prev) => ({
        ...prev,
        [id]: value
          .replace(/[^0-9]/g, '')

          .trim(),
      }));
    } else {
      setBillingAddress((prev) => ({...prev, [id]: value}));
    }
  };

  const payByP24 = async (id: string, cardType: any) => {
    console.log(id, cardType);
    if (isPayP24Loading) return;
    setIsPayP24Loading(true);
    try {
      const body = {
        OrderId: id,
        CardType: cardType,
      };

      const response = await api.post(
        `MangopayTransaction/PayCardPayIn`,
        body,
        {
          headers: {
            Authorization: `Bearer ${cookies.token}`,
          },
        },
      );
      // Alert('Zamówienie zostało stworzone.', 'Komunikat');
      window.location.href = response.data.Response.RedirectUrl;
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsPayP24Loading(false);
    }
  };

  const payByBlik = async (id: string) => {
    if (isPayP24Loading) return;
    setIsPayP24Loading(true);
    try {
      const body = {
        OrderId: id,
      };

      const response = await api.post(
        `MangopayTransaction/PayBlikWebPayIn`,
        body,
        {
          headers: {
            Authorization: `Bearer ${cookies.token}`,
          },
        },
      );
      // Alert('Zamówienie zostało stworzone.', 'Komunikat');
      window.location.href = response.data.Response.RedirectUrl;
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    } finally {
      setIsPayP24Loading(false);
    }
  };

  const payByMugo = async (id: string) => {
    if (isPayTransferLoading) return;
    setIsPayTransferLoading(true);
    try {
      const body = {
        OrderId: id,
      };

      const response = await api.post(`MangopayTransaction/PayTransfer`, body, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      });

      // navigate('/transactions');

      setTimeout(() => {
        navigate(`/transactions?orderId=${id}`);
      }, 2000);
    } catch (error) {
      console.error(error);
      Alert(
        error.response.data.Error.Message ===
          'Cannot find payer mangopay account.'
          ? 'Nie można znaleźć konta płatnika w Mangopay.'
          : 'Nie można wykonać przelewu: Niewystarczające saldo portfela',
        'Błąd',
      );
    } finally {
      setIsPayTransferLoading(false);
    }
  };

  const closePayCardPopup = () => {
    setCardPayInMode(false);
  };

  const createOrder = async (cardType = null) => {
    setErrorVerification({
      buildingNumberError: null,
      companyNameError: null,
      countryError: null,
      emailError: null,
      firstNameError: null,
      lastNameError: null,
      NIPError: null,
      phoneNumberError: null,
      postalCodeError: null,
      regionError: null,
      streetError: null,
      peselError: null,
    });
    let areThereErrors = false;

    if (personType === 1) {
      if (!billingAddress.FirstName) {
        setErrorVerification((prev) => ({
          ...prev,
          firstNameError: 'Uzupełnij imię',
        }));
        areThereErrors = true;
        setBilingAddressIsEditing((prev) => ({...prev, FirstName: false}));
      }

      if (billingAddress.FirstName && billingAddress.FirstName.length > 25) {
        setErrorVerification((prev) => ({
          ...prev,
          firstNameError: 'Imię jest za długie',
        }));
        areThereErrors = true;
        setBilingAddressIsEditing((prev) => ({...prev, FirstName: false}));
      }

      if (!billingAddress.LastName) {
        setErrorVerification((prev) => ({
          ...prev,
          lastNameError: 'Uzupełnij nazwisko',
        }));
        areThereErrors = true;
        setBilingAddressIsEditing((prev) => ({...prev, LastNamea: false}));
      }

      if (billingAddress.LastName && billingAddress.LastName.length > 25) {
        setErrorVerification((prev) => ({
          ...prev,
          lastNameError: 'Nazwisko jest za długie',
        }));
        areThereErrors = true;
        setBilingAddressIsEditing((prev) => ({...prev, LastName: false}));
      }
    } else if (personType === 2) {
      if (!billingAddress.CompanyName) {
        setErrorVerification((prev) => ({
          ...prev,
          companyNameError: 'Uzupełnij nazwę firmy',
        }));
        areThereErrors = true;
        setBilingAddressIsEditing((prev) => ({...prev, CompanyName: false}));
      }

      if (!NIP) {
        setErrorVerification((prev) => ({
          ...prev,
          NIPError: 'Uzupełnij numer NIP',
        }));
        areThereErrors = true;
      } else {
        const nipPattern = /^[0-9]{10}$/;
        if (!nipPattern.test(NIP)) {
          setErrorVerification((prev) => ({
            ...prev,
            NIPError: 'Numer NIP musi składać się z 10 cyfr',
          }));
          areThereErrors = true;
        } else {
          const nip = NIP.toString();
          const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
          let sum = 0;

          for (let i = 0; i < weights.length; i++) {
            sum += parseInt(nip[i]) * weights[i];
          }

          const checksum = sum % 11;
          if (checksum !== parseInt(nip[9])) {
            setErrorVerification((prev) => ({
              ...prev,
              NIPError: 'Nieprawidłowy numer NIP',
            }));
            areThereErrors = true;
          }
        }
      }
    }

    // Kraj
    if (!billingAddress.Country) {
      setErrorVerification((prev) => ({
        ...prev,
        countryError: 'Uzupełnij kraj',
      }));
      areThereErrors = true;
      setBilingAddressIsEditing((prev) => ({...prev, Country: false}));
    }

    // Region
    if (!billingAddress.Region) {
      setErrorVerification((prev) => ({
        ...prev,
        regionError: 'Uzupełnij region',
      }));
      areThereErrors = true;
      setBilingAddressIsEditing((prev) => ({...prev, Region: false}));
    }

    // Ulica
    if (!billingAddress.Street) {
      setErrorVerification((prev) => ({
        ...prev,
        streetError: 'Uzupełnij ulicę',
      }));
      areThereErrors = true;
      setBilingAddressIsEditing((prev) => ({...prev, Street: false}));
    }

    // Numer budynku
    if (!billingAddress.BuildingNumber) {
      setErrorVerification((prev) => ({
        ...prev,
        buildingNumberError: 'Uzupełnij numer budynku',
      }));
      areThereErrors = true;
      setBilingAddressIsEditing((prev) => ({...prev, BuildingNumber: false}));
    }

    // Kod pocztowy
    if (!billingAddress.PostalCode) {
      setErrorVerification((prev) => ({
        ...prev,
        postalCodeError: 'Uzupełnij kod pocztowy',
      }));
      areThereErrors = true;
      setBilingAddressIsEditing((prev) => ({...prev, PostalCode: false}));
    } else if (!/^\d{2}-\d{3}$/.test(billingAddress.PostalCode)) {
      setErrorVerification((prev) => ({
        ...prev,
        postalCodeError: 'Nieprawidłowy format kodu pocztowego',
      }));
      areThereErrors = true;
      setBilingAddressIsEditing((prev) => ({...prev, PostalCode: false}));
    }

    // Email
    if (!billingAddress.Email) {
      setErrorVerification((prev) => ({
        ...prev,
        emailError: 'Uzupełnij adres email',
      }));
      areThereErrors = true;
      setBilingAddressIsEditing((prev) => ({...prev, Email: false}));
    } else if (!/\S+@\S+\.\S+/.test(billingAddress.Email)) {
      setErrorVerification((prev) => ({
        ...prev,
        emailError: 'Nieprawidłowy adres email',
      }));
      areThereErrors = true;
      setBilingAddressIsEditing((prev) => ({...prev, Email: false}));
    }

    // Numer telefonu
    if (billingAddress.PhoneNumber.length > 0) {
      if (!billingAddress.PhoneNumber) {
        setErrorVerification((prev) => ({
          ...prev,
          phoneNumberError: 'Uzupełnij numer telefonu',
        }));
        areThereErrors = true;
      } else if (!/^\d{9}$/.test(billingAddress.PhoneNumber)) {
        setErrorVerification((prev) => ({
          ...prev,
          phoneNumberError: 'Numer telefonu powinien składać się z 9 cyfr',
        }));
        areThereErrors = true;
      }
    }

    if (areThereErrors) return;
    if (isLoading || isPayP24Loading || isPayTransferLoading) return;
    setIsLoading(true);

    try {
      const body = {
        Currency: 0,
        CustomerId: userData.UserId,
        CustomerNote: customerNote,
        CouponCode: [],
        BillingAddress: billingAddress,
        NIP: NIP,
        ShippingAddress: billingAddress,
        ShippingType: 0,
        PaymentMethod: paymentType,
        Items: [
          {
            ProductId: product.Id,
            ProducerId: offer.ProducerId,
            ConditionId: condition.Id,
            Quantity: 1,
          },
        ],
      };

      const response = await api.post(`orders`, body, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      });
      // console.log('response orders', response);
      console.log(paymentType);
      if (condition?.GrossValue === 0) {
        navigate('/transactions');
      } else {
        if (paymentType === 2) {
          payByMugo(response.data.Response.Id);
        } else if (paymentType === 3) {
          payByBlik(response.data.Response.Id);
        } else {
          payByP24(response.data.Response.Id, 'CB_VISA_MASTERCARD');
        }
      }

      // Alert('Zamówienie zostało stworzone.', 'Komunikat');
      // navigate('/transactions');
    } catch (error) {
      console.error(error);
      // Alert(error.response.data.Error.Message, 'Błąd');
      Alert(
        error.response.data.Error.Message ===
          'Insufficient funds in the wallet.'
          ? 'Za mało środków w portfelu.'
          : 'Nie można znaleźć konta płatnika w Mangopay.',
        'Błąd',
      );
      setIsLoading(false);
    } finally {
      closePayCardPopup();
    }
  };
  const resetErrors = () => {
    setErrorVerification({
      buildingNumberError: null,
      companyNameError: null,
      countryError: null,
      emailError: null,
      firstNameError: null,
      lastNameError: null,
      NIPError: null,
      phoneNumberError: null,
      postalCodeError: null,
      regionError: null,
      streetError: null,
      peselError: null,
    });
  };
  const handlePaymentTypeChange = (value: number) => setPaymentType(value);

  const handlePersonTypeChange = (value: number) => {
    resetErrors();
    setPersonType(value);
  };

  const navigate = useNavigate();

  const getMangoPayAccount = async () => {
    // console.log('pobieram mangopay');
    setIsLoading(true);
    try {
      const response = await api.get(`mangopayaccount`, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      // console.log('mangopay konto w order', response.data.Response);
      if (response.data.Response.Type === 0) {
        setBillingAddress((prevState) => ({
          ...prevState,
          BuildingNumber:
            response.data.Response.NaturalUser.Address.AddressLine2 === null
              ? response.data.Response.NaturalUser.Address.AddressLine2
              : response.data.Response.NaturalUser.Address.AddressLine2.slice(
                  response.data.Response.NaturalUser.Address.AddressLine2.indexOf(
                    '/',
                  ) + 1,
                ).trim(),
          Country: response.data.Response.NaturalUser.Address.Country,
          Email: response.data.Response.NaturalUser.Email,
          FirstName: response.data.Response.NaturalUser.FirstName,
          FlatNumber:
            response.data.Response.NaturalUser.Address.AddressLine2 === null
              ? response.data.Response.NaturalUser.Address.AddressLine2
              : response.data.Response.NaturalUser.Address.AddressLine2.slice(
                  0,
                  response.data.Response.NaturalUser.Address.AddressLine2.indexOf(
                    '/',
                  ),
                ).trim(),
          LastName: response.data.Response.NaturalUser.LastName,
          PostalCode: response.data.Response.NaturalUser.Address.PostalCode,
          Region: response.data.Response.NaturalUser.Address.Region,
          Street:
            response.data.Response.NaturalUser.Address.AddressLine1 === null
              ? response.data.Response.NaturalUser.Address.AddressLine1
              : response.data.Response.NaturalUser.Address.AddressLine1.slice(
                  0,
                  response.data.Response.NaturalUser.Address.AddressLine1.indexOf(
                    '/',
                  ),
                ).trim(),
        }));
        setBilingAddressIsEditing((prevState) => ({
          ...prevState,
          BuildingNumber:
            response.data.Response.NaturalUser.Address.AddressLine2 !== null,

          Country: response.data.Response.NaturalUser.Address.Country !== null,
          Email: response.data.Response.NaturalUser.Email !== null,
          FirstName: response.data.Response.NaturalUser.FirstName !== null,
          FlatNumber:
            response.data.Response.NaturalUser.Address.AddressLine2 !== null,
          LastName: response.data.Response.NaturalUser.LastName !== null,
          PostalCode:
            response.data.Response.NaturalUser.Address.PostalCode !== null,
          Region: response.data.Response.NaturalUser.Address.Region !== null,
          Street:
            response.data.Response.NaturalUser.Address.AddressLine1 !== null,
        }));
      }
      if (response.data.Response.Type === 1) {
        setPersonType(2);
        setBillingAddress((prevState) => ({
          ...prevState,
          BuildingNumber:
            response.data.Response.LegalUser.HeadquartersAddress
              .AddressLine2 === null
              ? response.data.Response.LegalUser.HeadquartersAddress
                  .AddressLine2
              : response.data.Response.LegalUser.HeadquartersAddress.AddressLine2.slice(
                  response.data.Response.LegalUser.HeadquartersAddress.AddressLine2.indexOf(
                    '/',
                  ) + 1,
                ).trim(),
          Country: response.data.Response.LegalUser.HeadquartersAddress.Country,
          Email: response.data.Response.LegalUser.Email,
          FirstName: response.data.Response.LegalUser.FirstName,
          CompanyName: response.data.Response.LegalUser.Name,
          FlatNumber:
            response.data.Response.LegalUser.HeadquartersAddress
              .AddressLine2 === null
              ? response.data.Response.LegalUser.HeadquartersAddress
                  .AddressLine2
              : response.data.Response.LegalUser.HeadquartersAddress.AddressLine2.slice(
                  0,
                  response.data.Response.LegalUser.HeadquartersAddress.AddressLine2.indexOf(
                    '/',
                  ),
                ).trim(),
          LastName: response.data.Response.LegalUser.LastName,
          PostalCode:
            response.data.Response.LegalUser.HeadquartersAddress.PostalCode,
          Region: response.data.Response.LegalUser.HeadquartersAddress.Region,
          Street:
            response.data.Response.LegalUser.HeadquartersAddress
              .AddressLine1 === null
              ? response.data.Response.LegalUser.HeadquartersAddress
                  .AddressLine1
              : response.data.Response.LegalUser.HeadquartersAddress.AddressLine1.slice(
                  0,
                  response.data.Response.LegalUser.HeadquartersAddress.AddressLine1.indexOf(
                    '/',
                  ),
                ).trim(),
        }));
        setBilingAddressIsEditing((prevState) => ({
          ...prevState,
          BuildingNumber:
            response.data.Response.LegalUser.HeadquartersAddress
              .AddressLine2 !== null,

          Country:
            response.data.Response.LegalUser.HeadquartersAddress.Country !==
            null,
          Email: response.data.Response.LegalUser.Email !== null,
          FirstName: response.data.Response.LegalUser.FirstName !== null,
          FlatNumber:
            response.data.Response.LegalUser.HeadquartersAddress
              .AddressLine2 !== null,
          LastName: response.data.Response.LegalUser.LastName !== null,
          PostalCode:
            response.data.Response.LegalUser.HeadquartersAddress.PostalCode !==
            null,
          Region:
            response.data.Response.LegalUser.HeadquartersAddress.Region !==
            null,
          Street:
            response.data.Response.LegalUser.HeadquartersAddress
              .AddressLine1 !== null,
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMangoPayAccount();
  }, []);

  // useEffect(() => {}, []);

  useEffect(() => {
    setCondition(
      product.AssignedConditions.filter(
        (condition, index) => index + 1 === currentCondition,
      )[0].Condition,
    );
  }, []);

  return (
    <>
      <MainWrapper isOrderOpen={isOrderOpen}>
        {/* {cardPatInMode && (
          <PayWithCard
            closePayCardPopup={closePayCardPopup}
            createOrder={createOrder}
          />
        )} */}
        <Main>
          {(isLoading || isPayP24Loading || isPayTransferLoading) && (
            <LoadingCircle />
          )}
          <HeaderWrapper>
            <BackButton onClick={() => setIsOrderOpen(false)}>
              <svg
                id="Arrow_Top_Bar_Accent"
                data-name="Arrow – Top Bar / Accent"
                xmlns="http://www.w3.org/2000/svg"
                width="8.195"
                height="14"
                viewBox="0 0 8.195 14"
              >
                <path
                  id="Arrow"
                  d="M6.513,13.715.2,7.475a.666.666,0,0,1,0-.95L6.513.285a.994.994,0,0,1,1.394,0,.967.967,0,0,1,0,1.377L2.506,7l5.4,5.337a.967.967,0,0,1,0,1.377.994.994,0,0,1-1.394,0"
                  transform="translate(0 0)"
                  fill="#fff"
                />
              </svg>
            </BackButton>
            <p>Zamówienie</p>
          </HeaderWrapper>
          <SectionsWrapper>
            <LeftSection>
              <SectionContentBox>
                <SectionTitle>Sygnatura towaru</SectionTitle>
                <SectionTextWithBorder>
                  <p>Nazwa</p>
                  <h4>{offer.Name}</h4>
                </SectionTextWithBorder>
                <SectionTextGreyWrapper>
                  <p>Opis</p>
                  <span>{offer.Description}</span>
                </SectionTextGreyWrapper>
                {/* <FirstTextInLineSpan>
                  <h4>Symbol</h4>
                  <p>{product?.Symbol}</p>
                </FirstTextInLineSpan> */}
                {/* <SectionHeader>Terminy</SectionHeader>
                <SecondTextInLineSpan>
                  <h4>Data realizacji/dostawy</h4>
                  <p>
                    {condition?.TimeToFinishDate
                      ? getFormattedDate(condition?.TimeToFinishDate)
                      : '-'}
                  </p>
                </SecondTextInLineSpan>
                <SectionText style={{width: '268px'}}>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </SectionText>
                <SectionHeader>Odbiorca</SectionHeader>
                <SectionText>
                  Wprowadź swoje dane, na które zostanie przygotowana umowa
                  pomiędzy Toba, a sprzedającym. Po zamówieniu nie można
                  zmieniać danych w umowie.
                </SectionText> */}
                <SectionTitle style={{marginBottom: '12px'}}>
                  Twoje dane do umowy
                </SectionTitle>
                <PersonTypeWrapper>
                  <SinglePersonTypeWrapper
                    value={1}
                    personType={personType}
                    onClick={() => {
                      setNIP('');
                      setBillingAddress((prev) => ({...prev, CompanyName: ''}));
                      setBilingAddressIsEditing((prev) => ({
                        ...prev,
                        CompanyName: false,
                      }));
                      handlePersonTypeChange(1);
                    }}
                  >
                    Osoba fizyczna
                  </SinglePersonTypeWrapper>
                  <SinglePersonTypeWrapper
                    value={2}
                    personType={personType}
                    onClick={() => {
                      setBillingAddress((prev) => ({
                        ...prev,
                        FirstName: '',
                        LastName: '',
                      }));
                      setBilingAddressIsEditing((prev) => ({
                        ...prev,
                        FirstName: false,
                        LastName: false,
                      }));
                      handlePersonTypeChange(2);
                    }}
                  >
                    Firma
                  </SinglePersonTypeWrapper>
                </PersonTypeWrapper>
                {personType === 2 ? (
                  <>
                    <InputView
                      isError={errorVerification.companyNameError !== null}
                      isEditing={true}
                      style={{marginTop: '12px'}}
                    >
                      <p>Nazwa firmy</p>
                      <input
                        type="text"
                        placeholder="Uzupełnij"
                        readOnly={false}
                        disabled={billingAddressIsEditing.CompanyName}
                        id="CompanyName"
                        value={billingAddress.CompanyName}
                        onChange={handleBillingAddressChange}
                      />
                      {errorVerification.companyNameError !== null && (
                        <ErrorStar
                          fixed={true}
                          text={errorVerification.companyNameError}
                        />
                      )}
                    </InputView>
                    <InputView
                      isError={errorVerification.NIPError !== null}
                      isEditing={true}
                      style={{marginTop: '6px'}}
                    >
                      <p>NIP</p>
                      <input
                        type="text"
                        id="NIP"
                        placeholder="Uzupełnij"
                        readOnly={false}
                        value={NIP}
                        onChange={handleNIPChange}
                      />
                      {errorVerification.NIPError !== null && (
                        <ErrorStar
                          fixed={true}
                          text={errorVerification.NIPError}
                        />
                      )}
                    </InputView>
                  </>
                ) : (
                  <>
                    <InputView
                      isError={errorVerification.firstNameError !== null}
                      isEditing={true}
                      style={{marginTop: '12px'}}
                    >
                      <p>Imię</p>
                      <input
                        type="text"
                        id="FirstName"
                        placeholder="Uzupełnij"
                        disabled={billingAddressIsEditing.FirstName}
                        readOnly={false}
                        value={billingAddress.FirstName}
                        onChange={handleBillingAddressChange}
                      />
                      {errorVerification.firstNameError !== null && (
                        <ErrorStar
                          fixed={true}
                          text={errorVerification.firstNameError}
                        />
                      )}
                    </InputView>
                    <InputView
                      isError={errorVerification.lastNameError !== null}
                      isEditing={true}
                      style={{marginTop: '6px'}}
                    >
                      <p>Nazwisko</p>
                      <input
                        type="text"
                        id="LastName"
                        disabled={billingAddressIsEditing.LastName}
                        placeholder="Uzupełnij"
                        readOnly={false}
                        value={billingAddress.LastName}
                        onChange={handleBillingAddressChange}
                      />
                      {errorVerification.lastNameError !== null && (
                        <ErrorStar
                          fixed={true}
                          text={errorVerification.lastNameError}
                        />
                      )}
                    </InputView>
                  </>
                )}
                <InputView
                  isError={errorVerification.peselError !== null}
                  isEditing={true}
                  style={{marginTop: '6px'}}
                >
                  <p>PESEL</p>
                  <input
                    type="text"
                    id="PESEL"
                    placeholder="Uzupełnij"
                    readOnly={false}
                    disabled={billingAddressIsEditing.PESEL}
                    value={billingAddress.PESEL}
                    onChange={handleBillingAddressChange}
                  />
                  {errorVerification.peselError !== null && (
                    <ErrorStar
                      fixed={true}
                      text={errorVerification.peselError}
                    />
                  )}
                </InputView>
                <InputView
                  isError={errorVerification.emailError !== null}
                  isEditing={true}
                  style={{marginTop: '6px'}}
                >
                  <p>Email</p>
                  <input
                    type="text"
                    id="Email"
                    disabled={billingAddressIsEditing.Email}
                    placeholder="Uzupełnij"
                    readOnly={false}
                    value={billingAddress.Email}
                    onChange={handleBillingAddressChange}
                  />
                  {errorVerification.emailError !== null && (
                    <ErrorStar
                      fixed={true}
                      text={errorVerification.emailError}
                    />
                  )}
                </InputView>
                <InputView
                  isError={errorVerification.phoneNumberError !== null}
                  isEditing={true}
                  style={{marginTop: '6px'}}
                >
                  <p>Numer telefonu</p>
                  <input
                    type="text"
                    id="PhoneNumber"
                    maxLength={9}
                    placeholder="Uzupełnij"
                    disabled={billingAddressIsEditing.PhoneNumber}
                    readOnly={false}
                    value={billingAddress.PhoneNumber}
                    onChange={handleBillingAddressChange}
                  />
                  {errorVerification.phoneNumberError !== null && (
                    <ErrorStar
                      fixed={true}
                      text={errorVerification.phoneNumberError}
                    />
                  )}
                </InputView>
                <StyledSelectView
                  isError={errorVerification.countryError !== null}
                  isEditing={true}
                  style={{marginTop: '6px'}}
                >
                  <p>Kraj</p>
                  {/* <input
                    type="text"
                    id="Country"
                    placeholder="Uzupełnij"
                    disabled={billingAddressIsEditing.Country}
                    readOnly={false}
                    value={billingAddress.Country}
                    onChange={handleBillingAddressChange}
                  /> */}
                  <select
                    value={billingAddress.Country}
                    disabled={false}
                    onChange={handleCountrySelectChange}
                  >
                    <option hidden value={''}>
                      Wybierz
                    </option>
                    {countries.map((role) => {
                      const {FieldName, FlagValue} = role;

                      return (
                        <option value={FlagValue} key={FlagValue}>
                          {FieldName}
                        </option>
                      );
                    })}
                  </select>
                  {errorVerification.countryError !== null && (
                    <ErrorStar
                      fixed={true}
                      text={errorVerification.countryError}
                    />
                  )}
                </StyledSelectView>
                <InputView
                  isError={errorVerification.postalCodeError !== null}
                  isEditing={true}
                  style={{marginTop: '6px'}}
                >
                  <p>Kod pocztowy</p>
                  <input
                    type="text"
                    id="PostalCode"
                    disabled={billingAddressIsEditing.PostalCode}
                    placeholder="Uzupełnij"
                    readOnly={false}
                    value={billingAddress.PostalCode}
                    onChange={handleBillingAddressChange}
                  />
                  {errorVerification.postalCodeError !== null && (
                    <ErrorStar
                      fixed={true}
                      text={errorVerification.postalCodeError}
                    />
                  )}
                </InputView>
                <InputView
                  isError={errorVerification.regionError !== null}
                  isEditing={true}
                  style={{marginTop: '6px'}}
                >
                  <p>Miasto</p>
                  <input
                    type="text"
                    id="Region"
                    placeholder="Uzupełnij"
                    readOnly={false}
                    disabled={billingAddressIsEditing.Region}
                    value={billingAddress.Region}
                    onChange={handleBillingAddressChange}
                  />
                  {errorVerification.regionError !== null && (
                    <ErrorStar
                      fixed={true}
                      text={errorVerification.regionError}
                    />
                  )}
                </InputView>
                <InputView
                  isError={errorVerification.streetError !== null}
                  isEditing={true}
                  style={{marginTop: '6px'}}
                >
                  <p>Ulica</p>
                  <input
                    type="text"
                    id="Street"
                    placeholder="Uzupełnij"
                    readOnly={false}
                    value={billingAddress.Street}
                    disabled={billingAddressIsEditing.Street}
                    onChange={handleBillingAddressChange}
                  />
                  {errorVerification.streetError !== null && (
                    <ErrorStar
                      fixed={true}
                      text={errorVerification.streetError}
                    />
                  )}
                </InputView>
                <InputView
                  isError={errorVerification.buildingNumberError !== null}
                  isEditing={true}
                  style={{marginTop: '6px'}}
                >
                  <p>Numer domu</p>
                  <input
                    type="text"
                    id="BuildingNumber"
                    placeholder="Uzupełnij"
                    readOnly={false}
                    disabled={billingAddressIsEditing.BuildingNumber}
                    value={billingAddress.BuildingNumber}
                    onChange={handleBillingAddressChange}
                  />
                  {errorVerification.buildingNumberError !== null && (
                    <ErrorStar
                      fixed={true}
                      text={errorVerification.buildingNumberError}
                    />
                  )}
                </InputView>
                <InputView
                  isError={false}
                  isEditing={true}
                  style={{
                    marginTop: '6px',
                    marginBottom: '21px',
                  }}
                >
                  <p>Numer mieszkania</p>
                  <input
                    type="text"
                    id="FlatNumber"
                    placeholder="Uzupełnij"
                    readOnly={false}
                    value={billingAddress.FlatNumber}
                    disabled={billingAddressIsEditing.FlatNumber}
                    onChange={handleBillingAddressChange}
                  />
                  {/* {errorVerification.nameError !== null && (
                <ErrorStar text={errorVerification.nameError} />
              )} */}
                </InputView>
              </SectionContentBox>
            </LeftSection>
            <CenterSection>
              <SectionContentBox>
                <BlueHeaderComponent>DEKLARACJE</BlueHeaderComponent>
                {condition?.SellerObligations.length > 0 && (
                  <SectionTitle
                    style={{marginTop: '10px', marginBottom: '10px'}}
                  >
                    Zobowiązania
                  </SectionTitle>
                )}

                {condition?.SellerObligations.map((sellerObligation) => (
                  // <IconWithTextSpan key={sellerObligation}>
                  //   <LeftDotSvg>
                  //     <svg
                  //       xmlns="http://www.w3.org/2000/svg"
                  //       width="9"
                  //       height="9"
                  //       viewBox="0 0 9 9"
                  //     >
                  //       <path
                  //         id="Icon_awesome-check-circle"
                  //         data-name="Icon awesome-check-circle"
                  //         d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                  //         transform="translate(-0.563 -0.563)"
                  //         fill="#00d10f"
                  //       />
                  //     </svg>
                  //   </LeftDotSvg>
                  //   <p>{sellerObligation}</p>
                  // </IconWithTextSpan>
                  <IconWithTextSpan key={sellerObligation}>
                    <LeftDotSvg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                      >
                        <path
                          id="Icon_awesome-check-circle"
                          data-name="Icon awesome-check-circle"
                          d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                          transform="translate(-0.563 -0.563)"
                          fill="#00d10f"
                        />
                      </svg>
                    </LeftDotSvg>
                    <p>{sellerObligation}</p>
                  </IconWithTextSpan>
                ))}
                {/* <IconWithTextSpan>
                  <LeftDotSvg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#00d10f"
                      />
                    </svg>
                  </LeftDotSvg>
                  <p>
                    Desktop publishing software like Aldus PageMaker including
                    versions of Lorem Ipsum.
                  </p>
                </IconWithTextSpan>
                <IconWithTextSpan>
                  <LeftDotSvg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#00d10f"
                      />
                    </svg>
                  </LeftDotSvg>
                  <p>Desktop publishing software</p>{' '}
                </IconWithTextSpan>
                <IconWithTextSpan>
                  <LeftDotSvg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#00d10f"
                      />
                    </svg>
                  </LeftDotSvg>
                  <p>
                    Desktop publishing software like Aldus PageMaker including
                    versions of Lorem Ipsum.
                  </p>{' '}
                </IconWithTextSpan> */}

                {condition?.SellerRestrictions.length > 0 && (
                  <SectionTitle style={{marginBottom: '10px'}}>
                    Wykluczenia i ograniczenia
                  </SectionTitle>
                )}
                {condition?.SellerRestrictions.map((SellerRestriction) => (
                  <IconWithTextSpan key={SellerRestriction}>
                    <LeftDotSvg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                      >
                        <path
                          id="Icon_awesome-plus-circle"
                          data-name="Icon awesome-plus-circle"
                          d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                          transform="translate(4.364 -2.796) rotate(45)"
                          fill="#ff0300"
                        />
                      </svg>
                    </LeftDotSvg>
                    <p>{SellerRestriction}</p>
                  </IconWithTextSpan>
                ))}
                {/* <IconWithTextSpan>
                  <LeftDotSvg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <path
                        id="Icon_awesome-plus-circle"
                        data-name="Icon awesome-plus-circle"
                        d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                        transform="translate(4.364 -2.796) rotate(45)"
                        fill="#ff0300"
                      />
                    </svg>
                  </LeftDotSvg>
                  <p>Desktop publishing software</p>{' '}
                </IconWithTextSpan>
                <IconWithTextSpan>
                  <LeftDotSvg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <path
                        id="Icon_awesome-plus-circle"
                        data-name="Icon awesome-plus-circle"
                        d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                        transform="translate(4.364 -2.796) rotate(45)"
                        fill="#ff0300"
                      />
                    </svg>
                  </LeftDotSvg>
                  <p>
                    Desktop publishing software like Aldus PageMaker including
                    versions of Lorem Ipsum.
                  </p>{' '}
                </IconWithTextSpan>
                <IconWithTextSpan>
                  <LeftDotSvg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <path
                        id="Icon_awesome-plus-circle"
                        data-name="Icon awesome-plus-circle"
                        d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                        transform="translate(4.364 -2.796) rotate(45)"
                        fill="#ff0300"
                      />
                    </svg>
                  </LeftDotSvg>
                  <p>
                    Desktop publishing software like Aldus PageMaker including
                    versions of Lorem Ipsum.
                  </p>{' '}
                </IconWithTextSpan> */}
                {/* <IconWithTextSpan>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <g
                      id="Icon_ionic-md-close-circle-outline"
                      data-name="Icon ionic-md-close-circle-outline"
                      transform="translate(-3.375 -3.375)"
                    >
                      <path
                        id="Path_3703"
                        data-name="Path 3703"
                        d="M10.375,4.788A5.584,5.584,0,1,1,6.424,6.424a5.563,5.563,0,0,1,3.951-1.636m0-1.413a7,7,0,1,0,7,7,7,7,0,0,0-7-7Z"
                        fill="#ff0300"
                      />
                      <path
                        id="Path_3704"
                        data-name="Path 3704"
                        d="M17.688,16.708l-.979.979-2.521-2.521-2.521,2.521-.979-.979,2.521-2.521-2.521-2.521.979-.979,2.521,2.521,2.521-2.521.979.979-2.521,2.521Z"
                        transform="translate(-3.813 -3.813)"
                        fill="#ff0300"
                      />
                    </g>
                  </svg>
                  <p>
                    YouTube -{' '}
                    {condition?.ExploatationYoutube &&
                      ExploatationYoutubeEnum.filter(
                        (el) => el.FlagValue === condition?.ExploatationYoutube,
                      )[0].FieldName}
                  </p>
                </IconWithTextSpan>
                <IconWithTextSpan>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <g
                      id="Icon_ionic-md-close-circle-outline"
                      data-name="Icon ionic-md-close-circle-outline"
                      transform="translate(-3.375 -3.375)"
                    >
                      <path
                        id="Path_3703"
                        data-name="Path 3703"
                        d="M10.375,4.788A5.584,5.584,0,1,1,6.424,6.424a5.563,5.563,0,0,1,3.951-1.636m0-1.413a7,7,0,1,0,7,7,7,7,0,0,0-7-7Z"
                        fill="#ff0300"
                      />
                      <path
                        id="Path_3704"
                        data-name="Path 3704"
                        d="M17.688,16.708l-.979.979-2.521-2.521-2.521,2.521-.979-.979,2.521-2.521-2.521-2.521.979-.979,2.521,2.521,2.521-2.521.979.979-2.521,2.521Z"
                        transform="translate(-3.813 -3.813)"
                        fill="#ff0300"
                      />
                    </g>
                  </svg>
                  <p>
                    Spotify -{' '}
                    {condition?.ExploatationDigital &&
                      ExploatationDigitalEnum.filter(
                        (el) => el.FlagValue === condition?.ExploatationDigital,
                      )[0].FieldName}
                  </p>
                </IconWithTextSpan>
                <IconWithTextSpan>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                  >
                    <g
                      id="Icon_ionic-md-close-circle-outline"
                      data-name="Icon ionic-md-close-circle-outline"
                      transform="translate(-3.375 -3.375)"
                    >
                      <path
                        id="Path_3703"
                        data-name="Path 3703"
                        d="M10.375,4.788A5.584,5.584,0,1,1,6.424,6.424a5.563,5.563,0,0,1,3.951-1.636m0-1.413a7,7,0,1,0,7,7,7,7,0,0,0-7-7Z"
                        fill="#ff0300"
                      />
                      <path
                        id="Path_3704"
                        data-name="Path 3704"
                        d="M17.688,16.708l-.979.979-2.521-2.521-2.521,2.521-.979-.979,2.521-2.521-2.521-2.521.979-.979,2.521,2.521,2.521-2.521.979.979-2.521,2.521Z"
                        transform="translate(-3.813 -3.813)"
                        fill="#ff0300"
                      />
                    </g>
                  </svg>
                  <p>
                    Dystrybucja fizyczna -{' '}
                    {condition?.ExploatationPhysical &&
                      ExploatationPhysicalEnum.filter(
                        (el) =>
                          el.FlagValue === condition?.ExploatationPhysical,
                      )[0].FieldName}
                  </p>
                </IconWithTextSpan> */}
                {offer.ProductType !== 4 && offer.ProductType !== 1 && (
                  <>
                    <BlueHeaderComponent>
                      Kanały monetyzacji
                    </BlueHeaderComponent>
                    <SectionTextWithBorder style={{marginTop: '12px'}}>
                      <p>Serwisy cyfrowe</p>
                      <h4>
                        {' '}
                        {condition?.ExploitationDigital &&
                          ExploitationDigitalEnum.filter(
                            (el) =>
                              el.FlagValue === condition?.ExploitationDigital,
                          )[0].FieldName}
                      </h4>
                    </SectionTextWithBorder>
                    <SectionTextWithBorder>
                      <p>YouTube</p>
                      <h4>
                        {' '}
                        {condition?.ExploitationYoutube &&
                          ExploitationYoutubeEnum.filter(
                            (el) =>
                              el.FlagValue === condition?.ExploitationYoutube,
                          )[0].FieldName}
                      </h4>
                    </SectionTextWithBorder>
                    <SectionTextWithBorder>
                      <p>Dystrybucja fizyczna</p>
                      <h4>
                        {' '}
                        {condition?.ExploitationPhysical &&
                          ExploitationPhysicalEnum.filter(
                            (el) =>
                              el.FlagValue === condition?.ExploitationPhysical,
                          )[0].FieldName}
                      </h4>
                    </SectionTextWithBorder>
                  </>
                )}
                <BlueHeaderComponent>Oznaczenia</BlueHeaderComponent>
                <SectionTextGrey
                  style={{marginTop: '3px', marginBottom: '12px'}}
                >
                  {condition?.IndicationOfContentAuthor
                    ? `Wymagam następującego oznaczenia`
                    : ` Zakazuje podawania moich danych w dystrybucji, wyrażam zgodę
                  na anonimowe dalsze udostępnianie`}
                </SectionTextGrey>
                {condition?.IndicationOfContentAuthor && (
                  <SectionTextWithBorder
                    style={{marginTop: '12px', justifyContent: 'flex-end'}}
                  >
                    <h4>{condition?.IndicationOfContentAuthor}</h4>
                  </SectionTextWithBorder>
                )}
              </SectionContentBox>
            </CenterSection>
            <RightSection>
              <SectionContentBox>
                <BlueHeaderComponent>Warunki współpracy</BlueHeaderComponent>
                <SectionTextWithBorder style={{marginTop: '12px'}}>
                  <p>Rodzaj umowy</p>
                  <h4>
                    {!condition?.IsExclusive ? 'Otwarta' : 'Na wyłączność'}
                  </h4>
                </SectionTextWithBorder>
                <SectionTextWithBorder>
                  <p>Licencja</p>
                  <h4>
                    {condition?.TimeToFinish &&
                      RightsEndEnum.filter(
                        (el) => el.FlagValue === condition?.RightsEnd,
                      )[0].FieldName}
                  </h4>
                </SectionTextWithBorder>
                <SectionTextWithBorder>
                  <p>Czas na wykonanie</p>
                  <h4>
                    {condition?.TimeToFinish &&
                      TimeToFinishEnum.filter(
                        (el) => el.FlagValue === condition?.TimeToFinish,
                      )[0].FieldName}
                  </h4>
                </SectionTextWithBorder>
                {product.Offer.IsWinWin && (
                  <SectionTextWithBorder>
                    <p>Data wydania MUGO</p>
                    <h4>
                      {' '}
                      {condition?.DateToUploadForCustomer
                        ? getFormattedDate(condition?.DateToUploadForCustomer)
                        : '-'}
                    </h4>
                  </SectionTextWithBorder>
                )}

                <BlueHeaderComponent>
                  Rozliczenie-Warunki finansowe
                </BlueHeaderComponent>
                {product.Offer.IsWinWin && (
                  <SectionWinWinWrapper
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      margin: '16px',
                    }}
                  >
                    <svg
                      id="Group_6973"
                      data-name="Group 6973"
                      xmlns="http://www.w3.org/2000/svg"
                      width="71.396"
                      height="18"
                      viewBox="0 0 71.396 18"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_3780"
                            data-name="Rectangle 3780"
                            width="71.396"
                            height="18"
                            fill="#fb9614"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Group_6972"
                        data-name="Group 6972"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Path_4111"
                          data-name="Path 4111"
                          d="M212.43,39.406l-1.146,2.49c-.286.623-.583,1.285-.86,1.917h-.02c-.049-.613-.109-1.265-.178-1.888l-.277-2.52h-1.492l-1.186,2.53c-.287.613-.593,1.285-.85,1.878h-.02c-.039-.593-.1-1.255-.158-1.868L206,39.406h-2.125l.9,7.155h2.105l.791-1.69c.287-.613.583-1.265.86-1.9h.02c.049.623.109,1.235.178,1.868l.188,1.72h2.145l3.439-7.155Z"
                          transform="translate(-175.818 -33.984)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4112"
                          data-name="Path 4112"
                          d="M274.581,39.406l-1.265,7.155h2.036l1.265-7.155Z"
                          transform="translate(-235.707 -33.984)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4113"
                          data-name="Path 4113"
                          d="M298.82,39.406l-.356,2.036c-.128.731-.257,1.512-.356,2.155l-.02.01c-.346-.662-.712-1.334-1.077-2l-1.216-2.2h-1.9l-1.265,7.155h1.957l.356-2.036c.129-.731.257-1.512.356-2.154l.02-.01c.346.662.712,1.334,1.077,2l1.216,2.2h1.9l1.265-7.155Z"
                          transform="translate(-252.366 -33.984)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4114"
                          data-name="Path 4114"
                          d="M349.151,61.521h2.481l.267-1.5h-2.481Z"
                          transform="translate(-301.107 -51.761)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4115"
                          data-name="Path 4115"
                          d="M379.471,39.406l-1.146,2.49c-.286.623-.583,1.285-.86,1.917h-.02c-.049-.613-.109-1.265-.178-1.888l-.277-2.52H375.5l-1.186,2.53c-.287.613-.593,1.285-.85,1.878h-.02c-.039-.593-.1-1.255-.158-1.868l-.247-2.54h-2.125l.9,7.155h2.105l.79-1.69c.287-.613.583-1.265.86-1.9h.02c.049.623.109,1.235.178,1.868l.188,1.72H378.1l3.439-7.155Z"
                          transform="translate(-319.874 -33.984)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4116"
                          data-name="Path 4116"
                          d="M441.622,39.406l-1.265,7.155h2.036l1.265-7.155Z"
                          transform="translate(-379.763 -33.984)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4117"
                          data-name="Path 4117"
                          d="M465.86,39.406l-.356,2.036c-.128.731-.257,1.512-.356,2.155l-.02.01c-.346-.662-.712-1.334-1.077-2l-1.216-2.2h-1.9l-1.265,7.155h1.957l.356-2.036c.129-.731.257-1.512.356-2.154l.02-.01c.346.662.712,1.334,1.077,2l1.216,2.2h1.9l1.265-7.155Z"
                          transform="translate(-396.421 -33.984)"
                          fill="#fb9614"
                        />
                        <path
                          id="Path_4118"
                          data-name="Path 4118"
                          d="M21.281,2.8H19.886L18.829,1.746h-3.5L18.91,5.33l.981-.05h1.391a.964.964,0,0,1,.963.963v5.5a.964.964,0,0,1-.962.963l-3.769,0-2.536,2.536a.964.964,0,0,1-1.362,0L9.723,11.352a.966.966,0,0,1-.221-1.02l6.9-.22.346-.346a3.44,3.44,0,0,0,0-4.865L12.858,1.009a3.44,3.44,0,0,0-4.865,0l-1.81,1.81-2.743,0A3.444,3.444,0,0,0,0,6.256v5.5A3.444,3.444,0,0,0,3.44,15.2H4.622l1.214,1.214h3.5L5.6,12.672l-1.04.052H3.44a.964.964,0,0,1-.963-.963v-5.5a.964.964,0,0,1,.962-.963l3.769,0L9.744,2.76a.963.963,0,0,1,1.362,0L15,6.652a.965.965,0,0,1,.221,1.02l-6.9.22-.346.346a3.44,3.44,0,0,0,0,4.865L11.863,17a3.444,3.444,0,0,0,4.865,0l1.81-1.81,2.743,0a3.444,3.444,0,0,0,3.44-3.44v-5.5a3.444,3.444,0,0,0-3.44-3.44"
                          transform="translate(0 -0.001)"
                          fill="#fb9614"
                        />
                      </g>
                    </svg>
                  </SectionWinWinWrapper>
                )}
                {product.Offer.IsWinWin && (
                  <>
                    {' '}
                    <SectionTextWithBorder style={{marginTop: '12px'}}>
                      <p>Serwisy cyfrowe</p>
                      <h4>{condition?.WinWinDigital}%</h4>
                    </SectionTextWithBorder>
                    <SectionTextWithBorder>
                      <p>Dystrybucja fizyczna </p>
                      <h4>{condition?.WinWinPhysical}%</h4>
                    </SectionTextWithBorder>
                    <SectionTextWithBorder>
                      <p>YouTube</p>
                      <h4>{condition?.WinWinYoutube}%</h4>
                    </SectionTextWithBorder>
                  </>
                )}
                {condition?.GrossValue !== 0 && condition?.GrossValue !== null && (
                  <>
                    <SectionTextPrice
                      style={
                        !product.Offer.IsWinWin ? {marginTop: '12px'} : null
                      }
                      isBlue={false}
                    >
                      <p>Kwota gwarantowana</p>
                      <div>
                        <h4>
                          {new Intl.NumberFormat('de-DE', {
                            minimumFractionDigits: 2,
                          }).format(+condition?.GrossValue / 100)}
                        </h4>
                        <p>PLN</p>
                      </div>
                    </SectionTextPrice>
                    <SectionTextPrice isBlue={false}>
                      <p>Opłata transakcyjna</p>
                      <div>
                        {' '}
                        <h4>
                          {' '}
                          {new Intl.NumberFormat('de-DE', {
                            minimumFractionDigits: 2,
                          }).format(
                            condition?.TransactionFee.Brutto / 100,
                          )}{' '}
                        </h4>
                        <p>PLN</p>
                      </div>
                    </SectionTextPrice>
                    <SectionTextPrice isBlue={true}>
                      <p>Razem</p>
                      <div>
                        {' '}
                        <h4>
                          {' '}
                          {new Intl.NumberFormat('de-DE', {
                            minimumFractionDigits: 2,
                          }).format(
                            +condition?.GrossValue / 100 +
                              condition?.TransactionFee.Brutto / 100,
                          )}{' '}
                        </h4>
                        <p>PLN</p>
                      </div>
                    </SectionTextPrice>
                  </>
                )}
                {+condition?.GrossValue === 0 ? null : (
                  <>
                    {' '}
                    <SectionTitle>Forma płatności</SectionTitle>
                    <PaymentTypeContainer>
                      <SinglePaymentTypeWrapper>
                        <img
                          src={portfelMugoLogo}
                          width={57.3}
                          height={23}
                          style={{
                            marginRight: '12.06px',
                          }}
                        />
                        <p>Błyskawiczne</p>
                        <PaymentTypeCheckbox
                          value={2}
                          paymentType={paymentType}
                          onClick={() => handlePaymentTypeChange(2)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.5"
                            height="10.5"
                            viewBox="0 0 10.5 10.5"
                          >
                            <path
                              id="Icon_awesome-check-circle"
                              data-name="Icon awesome-check-circle"
                              d="M11.062,5.812A5.25,5.25,0,1,1,5.812.562,5.25,5.25,0,0,1,11.062,5.812ZM5.205,8.592,9.1,4.7a.339.339,0,0,0,0-.479l-.479-.479a.339.339,0,0,0-.479,0L4.966,6.916,3.483,5.433a.339.339,0,0,0-.479,0l-.479.479a.339.339,0,0,0,0,.479l2.2,2.2a.339.339,0,0,0,.479,0Z"
                              transform="translate(-0.563 -0.563)"
                              fill="#0091ff"
                            />
                          </svg>
                        </PaymentTypeCheckbox>
                      </SinglePaymentTypeWrapper>

                      <SinglePaymentTypeWrapper>
                        <img src={payWithCard} width={69.37} height={23.4} />
                        <p>Płatność kartą</p>
                        <PaymentTypeCheckbox
                          value={0}
                          paymentType={paymentType}
                          onClick={() => handlePaymentTypeChange(0)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.5"
                            height="10.5"
                            viewBox="0 0 10.5 10.5"
                          >
                            <path
                              id="Icon_awesome-check-circle"
                              data-name="Icon awesome-check-circle"
                              d="M11.062,5.812A5.25,5.25,0,1,1,5.812.562,5.25,5.25,0,0,1,11.062,5.812ZM5.205,8.592,9.1,4.7a.339.339,0,0,0,0-.479l-.479-.479a.339.339,0,0,0-.479,0L4.966,6.916,3.483,5.433a.339.339,0,0,0-.479,0l-.479.479a.339.339,0,0,0,0,.479l2.2,2.2a.339.339,0,0,0,.479,0Z"
                              transform="translate(-0.563 -0.563)"
                              fill="#0091ff"
                            />
                          </svg>
                        </PaymentTypeCheckbox>
                      </SinglePaymentTypeWrapper>

                      <SinglePaymentTypeWrapper>
                        <img src={blikImg} width={69.37} height={28} />
                        <p>Płatność BLIK</p>
                        <PaymentTypeCheckbox
                          value={3}
                          paymentType={paymentType}
                          onClick={() => handlePaymentTypeChange(3)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10.5"
                            height="10.5"
                            viewBox="0 0 10.5 10.5"
                          >
                            <path
                              id="Icon_awesome-check-circle"
                              data-name="Icon awesome-check-circle"
                              d="M11.062,5.812A5.25,5.25,0,1,1,5.812.562,5.25,5.25,0,0,1,11.062,5.812ZM5.205,8.592,9.1,4.7a.339.339,0,0,0,0-.479l-.479-.479a.339.339,0,0,0-.479,0L4.966,6.916,3.483,5.433a.339.339,0,0,0-.479,0l-.479.479a.339.339,0,0,0,0,.479l2.2,2.2a.339.339,0,0,0,.479,0Z"
                              transform="translate(-0.563 -0.563)"
                              fill="#0091ff"
                            />
                          </svg>
                        </PaymentTypeCheckbox>
                      </SinglePaymentTypeWrapper>
                    </PaymentTypeContainer>
                  </>
                )}
                <BuyButton
                  style={{left: '-350px'}}
                  onClick={() => createOrder()}
                >
                  <h4>{isLoading ? 'Zamawianie...' : 'Zamawiam i płacę'}</h4>
                </BuyButton>
              </SectionContentBox>
            </RightSection>
          </SectionsWrapper>
        </Main>
      </MainWrapper>
      <MobileMainWrapper isOrderOpen={isOrderOpen}>
        <MobileMain>
          {(isLoading || isPayP24Loading || isPayTransferLoading) && (
            <LoadingCircle />
          )}
          <MobileHeader>
            <h1>Podsumowanie</h1>
            <svg
              onClick={() => setIsOrderOpen(false)}
              xmlns="http://www.w3.org/2000/svg"
              width="10.312"
              height="18"
              viewBox="0 0 10.312 18"
            >
              <path
                id="Path_4070"
                data-name="Path 4070"
                d="M9.947,16.18,2.557,9.1l7.3-7.048A1.221,1.221,0,0,0,9.943.4,1.221,1.221,0,0,0,8.289.485L0,9.1l8.378,8.646a1.207,1.207,0,0,0,1.645.076,1.208,1.208,0,0,0-.076-1.645"
                transform="translate(0 -0.114)"
                fill="#0091ff"
              />
            </svg>
          </MobileHeader>
          <SectionsWrapper>
            <MobileNameWithSymbolContainer>
              <h4>{offer.Name}</h4>
            </MobileNameWithSymbolContainer>
            <SectionTextWithBorder style={{marginTop: '12px'}}>
              <p>Termin dostarczenia</p>
              <h4>
                {condition?.TimeToFinishDate
                  ? getFormattedDate(condition?.TimeToFinishDate)
                  : '-'}
              </h4>
            </SectionTextWithBorder>
            <MobileNameWithSymbolContainer>
              <p>{condition?.Name}</p>
              <h5>{`${new Intl.NumberFormat('de-DE', {
                minimumFractionDigits: 2,
              }).format(+condition?.GrossValue / 100)}PLN`}</h5>
            </MobileNameWithSymbolContainer>
            <SectionContentBox>
              <BlueHeaderComponent>DEKLARACJE</BlueHeaderComponent>
              {condition?.SellerObligations.length > 0 && (
                <SectionTitle style={{marginTop: '10px', marginBottom: '10px'}}>
                  Zobowiązania
                </SectionTitle>
              )}

              {condition?.SellerObligations.map((sellerObligation) => (
                <IconWithTextSpan key={sellerObligation}>
                  <LeftDotSvg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <path
                        id="Icon_awesome-check-circle"
                        data-name="Icon awesome-check-circle"
                        d="M9.563,5.063a4.5,4.5,0,1,1-4.5-4.5A4.5,4.5,0,0,1,9.563,5.063ZM4.542,7.445,7.881,4.107a.29.29,0,0,0,0-.411L7.47,3.285a.29.29,0,0,0-.411,0L4.337,6.008,3.065,4.737a.29.29,0,0,0-.411,0l-.411.411a.29.29,0,0,0,0,.411L4.131,7.445A.29.29,0,0,0,4.542,7.445Z"
                        transform="translate(-0.563 -0.563)"
                        fill="#00d10f"
                      />
                    </svg>
                  </LeftDotSvg>
                  <p>{sellerObligation}</p>
                </IconWithTextSpan>
              ))}

              {condition?.SellerRestrictions.length > 0 && (
                <SectionTitle style={{marginBottom: '10px'}}>
                  Wykluczenia i ograniczenia
                </SectionTitle>
              )}
              {condition?.SellerRestrictions.map((SellerRestriction) => (
                <IconWithTextSpan key={SellerRestriction}>
                  <LeftDotSvg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="9"
                      viewBox="0 0 9 9"
                    >
                      <path
                        id="Icon_awesome-plus-circle"
                        data-name="Icon awesome-plus-circle"
                        d="M5.063.563a4.5,4.5,0,1,0,4.5,4.5A4.5,4.5,0,0,0,5.063.563ZM7.675,5.571a.218.218,0,0,1-.218.218H5.788V7.458a.218.218,0,0,1-.218.218H4.554a.218.218,0,0,1-.218-.218V5.788H2.667a.218.218,0,0,1-.218-.218V4.554a.218.218,0,0,1,.218-.218H4.337V2.667a.218.218,0,0,1,.218-.218H5.571a.218.218,0,0,1,.218.218V4.337H7.458a.218.218,0,0,1,.218.218Z"
                        transform="translate(4.364 -2.796) rotate(45)"
                        fill="#ff0300"
                      />
                    </svg>
                  </LeftDotSvg>
                  <p>{SellerRestriction}</p>
                </IconWithTextSpan>
              ))}
              {offer.ProductType !== 4 && offer.ProductType !== 1 && (
                <>
                  <BlueHeaderComponent>Kanały monetyzacji</BlueHeaderComponent>
                  <SectionTextWithBorder style={{marginTop: '12px'}}>
                    <p>Serwisy cyfrowe</p>
                    <h4>
                      {' '}
                      {condition?.ExploitationDigital &&
                        ExploitationDigitalEnum.filter(
                          (el) =>
                            el.FlagValue === condition?.ExploitationDigital,
                        )[0].FieldName}
                    </h4>
                  </SectionTextWithBorder>
                  <SectionTextWithBorder>
                    <p>YouTube</p>
                    <h4>
                      {' '}
                      {condition?.ExploitationYoutube &&
                        ExploitationYoutubeEnum.filter(
                          (el) =>
                            el.FlagValue === condition?.ExploitationYoutube,
                        )[0].FieldName}
                    </h4>
                  </SectionTextWithBorder>
                  <SectionTextWithBorder>
                    <p>Dystrybucja fizyczna</p>
                    <h4>
                      {' '}
                      {condition?.ExploitationPhysical &&
                        ExploitationPhysicalEnum.filter(
                          (el) =>
                            el.FlagValue === condition?.ExploitationPhysical,
                        )[0].FieldName}
                    </h4>
                  </SectionTextWithBorder>
                </>
              )}
              <BlueHeaderComponent>Warunki współpracy</BlueHeaderComponent>
              <SectionTextWithBorder style={{marginTop: '12px'}}>
                <p>Rodzaj umowy</p>
                <h4>{!condition?.IsExclusive ? 'Otwarta' : 'Na wyłączność'}</h4>
              </SectionTextWithBorder>
              <SectionTextWithBorder>
                <p>Licencja</p>
                <h4>
                  {condition?.TimeToFinish &&
                    RightsEndEnum.filter(
                      (el) => el.FlagValue === condition?.RightsEnd,
                    )[0].FieldName}
                </h4>
              </SectionTextWithBorder>
              <SectionTextWithBorder>
                <p>Czas na wykonanie</p>
                <h4>
                  {condition?.TimeToFinish &&
                    TimeToFinishEnum.filter(
                      (el) => el.FlagValue === condition?.TimeToFinish,
                    )[0].FieldName}
                </h4>
              </SectionTextWithBorder>
              {product.Offer.IsWinWin && (
                <SectionTextWithBorder>
                  <p>Data wydania MUGO</p>
                  <h4>
                    {' '}
                    {condition?.DateToUploadForCustomer
                      ? getFormattedDate(condition?.DateToUploadForCustomer)
                      : '-'}
                  </h4>
                </SectionTextWithBorder>
              )}
              <BlueHeaderComponent>Oznaczenia</BlueHeaderComponent>
              <SectionTextGrey style={{marginTop: '3px', marginBottom: '12px'}}>
                {condition?.IndicationOfContentAuthor
                  ? `Wymagam następującego oznaczenia`
                  : ` Zakazuje podawania moich danych w dystrybucji, wyrażam zgodę
                  na anonimowe dalsze udostępnianie`}
              </SectionTextGrey>
              {condition?.IndicationOfContentAuthor && (
                <SectionTextWithBorder
                  style={{marginTop: '12px', justifyContent: 'flex-end'}}
                >
                  <h4>{condition?.IndicationOfContentAuthor}</h4>
                </SectionTextWithBorder>
              )}
              <PersonTypeWrapper>
                <SinglePersonTypeWrapper
                  value={1}
                  personType={personType}
                  onClick={() => {
                    setNIP('');
                    setBillingAddress((prev) => ({...prev, CompanyName: ''}));
                    setBilingAddressIsEditing((prev) => ({
                      ...prev,
                      CompanyName: false,
                    }));
                    handlePersonTypeChange(1);
                  }}
                >
                  Osoba fizyczna
                </SinglePersonTypeWrapper>
                <SinglePersonTypeWrapper
                  value={2}
                  personType={personType}
                  onClick={() => {
                    setBillingAddress((prev) => ({
                      ...prev,
                      FirstName: '',
                      LastName: '',
                    }));
                    setBilingAddressIsEditing((prev) => ({
                      ...prev,
                      FirstName: false,
                      LastName: false,
                    }));
                    handlePersonTypeChange(2);
                  }}
                >
                  Firma
                </SinglePersonTypeWrapper>
              </PersonTypeWrapper>
              {personType === 2 ? (
                <>
                  <InputView
                    isError={errorVerification.companyNameError !== null}
                    isEditing={true}
                    style={{marginTop: '12px'}}
                  >
                    <p>Nazwa firmy</p>
                    <input
                      type="text"
                      placeholder="Uzupełnij"
                      readOnly={false}
                      disabled={billingAddressIsEditing.CompanyName}
                      id="CompanyName"
                      value={billingAddress.CompanyName}
                      onChange={handleBillingAddressChange}
                    />
                    {errorVerification.companyNameError !== null && (
                      <ErrorStar
                        fixed={true}
                        text={errorVerification.companyNameError}
                      />
                    )}
                  </InputView>
                  <InputView
                    isError={errorVerification.NIPError !== null}
                    isEditing={true}
                    style={{marginTop: '6px'}}
                  >
                    <p>NIP</p>
                    <input
                      type="text"
                      id="NIP"
                      placeholder="Uzupełnij"
                      readOnly={false}
                      value={NIP}
                      onChange={handleNIPChange}
                    />
                    {errorVerification.NIPError !== null && (
                      <ErrorStar
                        fixed={true}
                        text={errorVerification.NIPError}
                      />
                    )}
                  </InputView>
                </>
              ) : (
                <>
                  <InputView
                    isError={errorVerification.firstNameError !== null}
                    isEditing={true}
                    style={{marginTop: '12px'}}
                  >
                    <p>Imię</p>
                    <input
                      type="text"
                      id="FirstName"
                      placeholder="Uzupełnij"
                      disabled={billingAddressIsEditing.FirstName}
                      readOnly={false}
                      value={billingAddress.FirstName}
                      onChange={handleBillingAddressChange}
                    />
                    {errorVerification.firstNameError !== null && (
                      <ErrorStar
                        fixed={true}
                        text={errorVerification.firstNameError}
                      />
                    )}
                  </InputView>
                  <InputView
                    isError={errorVerification.lastNameError !== null}
                    isEditing={true}
                    style={{marginTop: '6px'}}
                  >
                    <p>Nazwisko</p>
                    <input
                      type="text"
                      id="LastName"
                      disabled={billingAddressIsEditing.LastName}
                      placeholder="Uzupełnij"
                      readOnly={false}
                      value={billingAddress.LastName}
                      onChange={handleBillingAddressChange}
                    />
                    {errorVerification.lastNameError !== null && (
                      <ErrorStar
                        fixed={true}
                        text={errorVerification.lastNameError}
                      />
                    )}
                  </InputView>
                </>
              )}
              <InputView
                isError={errorVerification.peselError !== null}
                isEditing={true}
                style={{marginTop: '6px'}}
              >
                <p>PESEL</p>
                <input
                  type="text"
                  id="PESEL"
                  placeholder="Uzupełnij"
                  readOnly={false}
                  disabled={billingAddressIsEditing.PESEL}
                  value={billingAddress.PESEL}
                  onChange={handleBillingAddressChange}
                />
                {errorVerification.peselError !== null && (
                  <ErrorStar fixed={true} text={errorVerification.peselError} />
                )}
              </InputView>
              <InputView
                isError={errorVerification.emailError !== null}
                isEditing={true}
                style={{marginTop: '6px'}}
              >
                <p>Email</p>
                <input
                  type="text"
                  id="Email"
                  disabled={billingAddressIsEditing.Email}
                  placeholder="Uzupełnij"
                  readOnly={false}
                  value={billingAddress.Email}
                  onChange={handleBillingAddressChange}
                />
                {errorVerification.emailError !== null && (
                  <ErrorStar fixed={true} text={errorVerification.emailError} />
                )}
              </InputView>
              <InputView
                isError={errorVerification.phoneNumberError !== null}
                isEditing={true}
                style={{marginTop: '6px'}}
              >
                <p>Numer telefonu</p>
                <input
                  type="text"
                  id="PhoneNumber"
                  maxLength={9}
                  placeholder="Uzupełnij"
                  disabled={billingAddressIsEditing.PhoneNumber}
                  readOnly={false}
                  value={billingAddress.PhoneNumber}
                  onChange={handleBillingAddressChange}
                />
                {errorVerification.phoneNumberError !== null && (
                  <ErrorStar
                    fixed={true}
                    text={errorVerification.phoneNumberError}
                  />
                )}
              </InputView>
              <InputView
                isError={errorVerification.countryError !== null}
                isEditing={true}
                style={{marginTop: '6px'}}
              >
                <p>Kraj</p>
                <input
                  type="text"
                  id="Country"
                  placeholder="Uzupełnij"
                  disabled={billingAddressIsEditing.Country}
                  readOnly={false}
                  value={billingAddress.Country}
                  onChange={handleBillingAddressChange}
                />
                {errorVerification.countryError !== null && (
                  <ErrorStar
                    fixed={true}
                    text={errorVerification.countryError}
                  />
                )}
              </InputView>
              <InputView
                isError={errorVerification.postalCodeError !== null}
                isEditing={true}
                style={{marginTop: '6px'}}
              >
                <p>Kod pocztowy</p>
                <input
                  type="text"
                  id="PostalCode"
                  disabled={billingAddressIsEditing.PostalCode}
                  placeholder="Uzupełnij"
                  readOnly={false}
                  value={billingAddress.PostalCode}
                  onChange={handleBillingAddressChange}
                />
                {errorVerification.postalCodeError !== null && (
                  <ErrorStar
                    fixed={true}
                    text={errorVerification.postalCodeError}
                  />
                )}
              </InputView>
              <InputView
                isError={errorVerification.regionError !== null}
                isEditing={true}
                style={{marginTop: '6px'}}
              >
                <p>Miasto</p>
                <input
                  type="text"
                  id="Region"
                  placeholder="Uzupełnij"
                  readOnly={false}
                  disabled={billingAddressIsEditing.Region}
                  value={billingAddress.Region}
                  onChange={handleBillingAddressChange}
                />
                {errorVerification.regionError !== null && (
                  <ErrorStar
                    fixed={true}
                    text={errorVerification.regionError}
                  />
                )}
              </InputView>
              <InputView
                isError={errorVerification.streetError !== null}
                isEditing={true}
                style={{marginTop: '6px'}}
              >
                <p>Ulica</p>
                <input
                  type="text"
                  id="Street"
                  placeholder="Uzupełnij"
                  readOnly={false}
                  value={billingAddress.Street}
                  disabled={billingAddressIsEditing.Street}
                  onChange={handleBillingAddressChange}
                />
                {errorVerification.streetError !== null && (
                  <ErrorStar
                    fixed={true}
                    text={errorVerification.streetError}
                  />
                )}
              </InputView>
              <InputView
                isError={errorVerification.buildingNumberError !== null}
                isEditing={true}
                style={{marginTop: '6px'}}
              >
                <p>Numer domu</p>
                <input
                  type="text"
                  id="BuildingNumber"
                  placeholder="Uzupełnij"
                  readOnly={false}
                  disabled={billingAddressIsEditing.BuildingNumber}
                  value={billingAddress.BuildingNumber}
                  onChange={handleBillingAddressChange}
                />
                {errorVerification.buildingNumberError !== null && (
                  <ErrorStar
                    fixed={true}
                    text={errorVerification.buildingNumberError}
                  />
                )}
              </InputView>
              <InputView
                isError={false}
                isEditing={true}
                style={{
                  marginTop: '6px',
                  marginBottom: '21px',
                }}
              >
                <p>Numer mieszkania</p>
                <input
                  type="text"
                  id="FlatNumber"
                  placeholder="Uzupełnij"
                  readOnly={false}
                  value={billingAddress.FlatNumber}
                  disabled={billingAddressIsEditing.FlatNumber}
                  onChange={handleBillingAddressChange}
                />
              </InputView>
            </SectionContentBox>
            <SectionContentBox>
              <BlueHeaderComponent>PŁATNOŚĆ</BlueHeaderComponent>
              {product.Offer.IsWinWin && (
                <SectionWinWinWrapper
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: '16px',
                  }}
                >
                  <svg
                    id="Group_6973"
                    data-name="Group 6973"
                    xmlns="http://www.w3.org/2000/svg"
                    width="71.396"
                    height="18"
                    viewBox="0 0 71.396 18"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_3780"
                          data-name="Rectangle 3780"
                          width="71.396"
                          height="18"
                          fill="#fb9614"
                        />
                      </clipPath>
                    </defs>
                    <g
                      id="Group_6972"
                      data-name="Group 6972"
                      clipPath="url(#clip-path)"
                    >
                      <path
                        id="Path_4111"
                        data-name="Path 4111"
                        d="M212.43,39.406l-1.146,2.49c-.286.623-.583,1.285-.86,1.917h-.02c-.049-.613-.109-1.265-.178-1.888l-.277-2.52h-1.492l-1.186,2.53c-.287.613-.593,1.285-.85,1.878h-.02c-.039-.593-.1-1.255-.158-1.868L206,39.406h-2.125l.9,7.155h2.105l.791-1.69c.287-.613.583-1.265.86-1.9h.02c.049.623.109,1.235.178,1.868l.188,1.72h2.145l3.439-7.155Z"
                        transform="translate(-175.818 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4112"
                        data-name="Path 4112"
                        d="M274.581,39.406l-1.265,7.155h2.036l1.265-7.155Z"
                        transform="translate(-235.707 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4113"
                        data-name="Path 4113"
                        d="M298.82,39.406l-.356,2.036c-.128.731-.257,1.512-.356,2.155l-.02.01c-.346-.662-.712-1.334-1.077-2l-1.216-2.2h-1.9l-1.265,7.155h1.957l.356-2.036c.129-.731.257-1.512.356-2.154l.02-.01c.346.662.712,1.334,1.077,2l1.216,2.2h1.9l1.265-7.155Z"
                        transform="translate(-252.366 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4114"
                        data-name="Path 4114"
                        d="M349.151,61.521h2.481l.267-1.5h-2.481Z"
                        transform="translate(-301.107 -51.761)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4115"
                        data-name="Path 4115"
                        d="M379.471,39.406l-1.146,2.49c-.286.623-.583,1.285-.86,1.917h-.02c-.049-.613-.109-1.265-.178-1.888l-.277-2.52H375.5l-1.186,2.53c-.287.613-.593,1.285-.85,1.878h-.02c-.039-.593-.1-1.255-.158-1.868l-.247-2.54h-2.125l.9,7.155h2.105l.79-1.69c.287-.613.583-1.265.86-1.9h.02c.049.623.109,1.235.178,1.868l.188,1.72H378.1l3.439-7.155Z"
                        transform="translate(-319.874 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4116"
                        data-name="Path 4116"
                        d="M441.622,39.406l-1.265,7.155h2.036l1.265-7.155Z"
                        transform="translate(-379.763 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4117"
                        data-name="Path 4117"
                        d="M465.86,39.406l-.356,2.036c-.128.731-.257,1.512-.356,2.155l-.02.01c-.346-.662-.712-1.334-1.077-2l-1.216-2.2h-1.9l-1.265,7.155h1.957l.356-2.036c.129-.731.257-1.512.356-2.154l.02-.01c.346.662.712,1.334,1.077,2l1.216,2.2h1.9l1.265-7.155Z"
                        transform="translate(-396.421 -33.984)"
                        fill="#fb9614"
                      />
                      <path
                        id="Path_4118"
                        data-name="Path 4118"
                        d="M21.281,2.8H19.886L18.829,1.746h-3.5L18.91,5.33l.981-.05h1.391a.964.964,0,0,1,.963.963v5.5a.964.964,0,0,1-.962.963l-3.769,0-2.536,2.536a.964.964,0,0,1-1.362,0L9.723,11.352a.966.966,0,0,1-.221-1.02l6.9-.22.346-.346a3.44,3.44,0,0,0,0-4.865L12.858,1.009a3.44,3.44,0,0,0-4.865,0l-1.81,1.81-2.743,0A3.444,3.444,0,0,0,0,6.256v5.5A3.444,3.444,0,0,0,3.44,15.2H4.622l1.214,1.214h3.5L5.6,12.672l-1.04.052H3.44a.964.964,0,0,1-.963-.963v-5.5a.964.964,0,0,1,.962-.963l3.769,0L9.744,2.76a.963.963,0,0,1,1.362,0L15,6.652a.965.965,0,0,1,.221,1.02l-6.9.22-.346.346a3.44,3.44,0,0,0,0,4.865L11.863,17a3.444,3.444,0,0,0,4.865,0l1.81-1.81,2.743,0a3.444,3.444,0,0,0,3.44-3.44v-5.5a3.444,3.444,0,0,0-3.44-3.44"
                        transform="translate(0 -0.001)"
                        fill="#fb9614"
                      />
                    </g>
                  </svg>
                </SectionWinWinWrapper>
              )}
              {product.Offer.IsWinWin && (
                <>
                  {' '}
                  <SectionTextWithBorder style={{marginTop: '12px'}}>
                    <p>Serwisy cyfrowe</p>
                    <h4>{condition?.WinWinDigital}%</h4>
                  </SectionTextWithBorder>
                  <SectionTextWithBorder>
                    <p>Dystrybucja fizyczna </p>
                    <h4>{condition?.WinWinPhysical}%</h4>
                  </SectionTextWithBorder>
                  <SectionTextWithBorder>
                    <p>YouTube</p>
                    <h4>{condition?.WinWinYoutube}%</h4>
                  </SectionTextWithBorder>
                </>
              )}
              {condition?.GrossValue !== 0 && condition?.GrossValue !== null && (
                <>
                  <SectionTextPrice
                    style={!product.Offer.IsWinWin ? {marginTop: '12px'} : null}
                    isBlue={false}
                  >
                    <p>Kwota gwarantowana</p>
                    <div>
                      <h4>
                        {new Intl.NumberFormat('de-DE', {
                          minimumFractionDigits: 2,
                        }).format(+condition?.GrossValue / 100)}
                      </h4>
                      <p>PLN</p>
                    </div>
                  </SectionTextPrice>
                  <SectionTextPrice isBlue={false}>
                    <p>Opłata transakcyjna</p>
                    <div>
                      {' '}
                      <h4>
                        {' '}
                        {new Intl.NumberFormat('de-DE', {
                          minimumFractionDigits: 2,
                        }).format(condition?.TransactionFee.Brutto / 100)}{' '}
                      </h4>
                      <p>PLN</p>
                    </div>
                  </SectionTextPrice>
                  <SectionTextPrice isBlue={true}>
                    <p>Razem</p>
                    <div>
                      {' '}
                      <h4>
                        {' '}
                        {new Intl.NumberFormat('de-DE', {
                          minimumFractionDigits: 2,
                        }).format(
                          +condition?.GrossValue / 100 +
                            condition?.TransactionFee.Brutto / 100,
                        )}{' '}
                      </h4>
                      <p>PLN</p>
                    </div>
                  </SectionTextPrice>
                </>
              )}
              {+condition?.GrossValue === 0 ? null : (
                <>
                  {' '}
                  <PaymentTypeContainer>
                    <SinglePaymentTypeWrapper>
                      <img src={portfelMugoLogo} width={57.3} height={23} />
                      <p style={{paddingLeft: '10px'}}>Błyskawiczne</p>
                      <PaymentTypeCheckbox
                        value={2}
                        paymentType={paymentType}
                        onClick={() => handlePaymentTypeChange(2)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10.5"
                          height="10.5"
                          viewBox="0 0 10.5 10.5"
                        >
                          <path
                            id="Icon_awesome-check-circle"
                            data-name="Icon awesome-check-circle"
                            d="M11.062,5.812A5.25,5.25,0,1,1,5.812.562,5.25,5.25,0,0,1,11.062,5.812ZM5.205,8.592,9.1,4.7a.339.339,0,0,0,0-.479l-.479-.479a.339.339,0,0,0-.479,0L4.966,6.916,3.483,5.433a.339.339,0,0,0-.479,0l-.479.479a.339.339,0,0,0,0,.479l2.2,2.2a.339.339,0,0,0,.479,0Z"
                            transform="translate(-0.563 -0.563)"
                            fill="#0091ff"
                          />
                        </svg>
                      </PaymentTypeCheckbox>
                    </SinglePaymentTypeWrapper>

                    <SinglePaymentTypeWrapper>
                      <img src={payWithCard} width={69.37} height={23.4} />
                      <p>Płatność kartą</p>
                      <PaymentTypeCheckbox
                        value={0}
                        paymentType={paymentType}
                        onClick={() => handlePaymentTypeChange(0)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10.5"
                          height="10.5"
                          viewBox="0 0 10.5 10.5"
                        >
                          <path
                            id="Icon_awesome-check-circle"
                            data-name="Icon awesome-check-circle"
                            d="M11.062,5.812A5.25,5.25,0,1,1,5.812.562,5.25,5.25,0,0,1,11.062,5.812ZM5.205,8.592,9.1,4.7a.339.339,0,0,0,0-.479l-.479-.479a.339.339,0,0,0-.479,0L4.966,6.916,3.483,5.433a.339.339,0,0,0-.479,0l-.479.479a.339.339,0,0,0,0,.479l2.2,2.2a.339.339,0,0,0,.479,0Z"
                            transform="translate(-0.563 -0.563)"
                            fill="#0091ff"
                          />
                        </svg>
                      </PaymentTypeCheckbox>
                    </SinglePaymentTypeWrapper>

                    <SinglePaymentTypeWrapper>
                      <img src={blikImg} width={69.37} height={28} />
                      <p>Płatność BLIK</p>
                      <PaymentTypeCheckbox
                        value={3}
                        paymentType={paymentType}
                        onClick={() => handlePaymentTypeChange(3)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10.5"
                          height="10.5"
                          viewBox="0 0 10.5 10.5"
                        >
                          <path
                            id="Icon_awesome-check-circle"
                            data-name="Icon awesome-check-circle"
                            d="M11.062,5.812A5.25,5.25,0,1,1,5.812.562,5.25,5.25,0,0,1,11.062,5.812ZM5.205,8.592,9.1,4.7a.339.339,0,0,0,0-.479l-.479-.479a.339.339,0,0,0-.479,0L4.966,6.916,3.483,5.433a.339.339,0,0,0-.479,0l-.479.479a.339.339,0,0,0,0,.479l2.2,2.2a.339.339,0,0,0,.479,0Z"
                            transform="translate(-0.563 -0.563)"
                            fill="#0091ff"
                          />
                        </svg>
                      </PaymentTypeCheckbox>
                    </SinglePaymentTypeWrapper>
                  </PaymentTypeContainer>
                </>
              )}
              <BuyButton onClick={() => createOrder()}>
                <h4>{isLoading ? 'Zamawianie...' : 'Zamawiam i płacę'}</h4>
              </BuyButton>
            </SectionContentBox>
          </SectionsWrapper>
        </MobileMain>
      </MobileMainWrapper>
    </>
  );
};

export default OrderDetails;
