import styled from 'styled-components';
import exp from 'constants';

export const DemoReelBlockWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
`;

export const DemoReelBlockHeader = styled.div<{theme?: string}>`
  height: 10%;
  width: 100%;
  display: flex;
  gap: 11px;
  align-items: center;

  & > h4 {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    padding: 0 5px;
  }

  & > svg {
    width: 27.38px;
    height: 21px;
  }

  @media (max-width: 1065px) {
    height: 5%;
  }
  @media (max-width: 920px) {
    & > h4 {
      font-size: 16px;
    }
    & > svg {
      height: 16px;
    }
  }
  @media (max-width: 780px) {
    & > h4 {
      font-size: 14px;
    }
    & > svg {
      height: 14px;
    }
  }
  @media (max-width: 720px) {
    & > h4 {
      font-size: 12px;
    }
    & > svg {
      height: 12px;
    }
  }
  @media (max-width: 650px) {
    & > h4 {
      padding: 0;
      font-size: 11px;
    }
    & > svg {
      height: 11px;
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      font-size: 10px;
    }
    & > svg {
      height: 10px;
    }
  }
  @media (max-width: 550px) {
    & > h4 {
      font-size: 8px;
    }
    & > svg {
      height: 8px;
    }
  }
`;

export const DemoReelBlockContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0px;
    height: 10px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment {
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #125faf;
    border-radius: 25px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
`;

export const DemoReelWrapper = styled.div<{
  theme: string;
  Width: number;
  Height: number;
}>`
  position: relative;
  width: ${({Width}) => (Width === 1 || Width === 2 ? '98%' : '99%')};
  min-height: ${({Width, Height}) => (Height === 1 ? '79px' : '81px')};
  padding-left: ${({Width}) => (Width === 1 ? '0' : '23px')};
  //padding: 25px 10px;
  border-radius: 13px;
  display: flex;
  justify-content: ${({Width}) => (Width === 1 ? 'center' : 'flex-start')};
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.21);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);

  & > svg {
    z-index: 2;
    cursor: pointer;
    //margin-left: 15px;
    width: 30px;
    height: 30px;
  }
  @media (max-width: 880px) {
    padding: 20px 10px;
    min-height: 62px;
    & > p {
      font-size: 10px;
    }
    & > svg {
      margin-left: 10px;
      width: 24px;
      height: 24px;
    }
  }
  @media (max-width: 700px) {
    padding: 15px 8px;
    min-height: 42px;
    & > p {
      font-size: 9px;
    }
    & > svg {
      margin-left: 8px;
      width: 18px;
      height: 18px;
    }
  }
  @media (max-width: 600px) {
    padding: 10px 5px;
    & > p {
      font-size: 8px;
    }
    & > svg {
      margin-left: 5px;
    }
  }
  @media (max-width: 500px) {
    padding: 5px 1px;
    min-height: 32px;
    & > p {
      font-size: 6px;
    }
    & > svg {
      margin-left: 5px;
      width: 12px;
      height: 12px;
    }
  }
`;

export const SoundTrackBg = styled.div`
  opacity: 0.3;
  position: absolute;
  height: 100%;
  width: 110px;
  border-radius: 13px 0 0 13px;
  left: 0;
  top: 0;
  right: 0;
  background: white;
`;

export const TextWrapper = styled.div<{Width: number; Height: number}>`
  display: flex;
  flex-direction: column;
  padding-left: ${({Width}) => (Width === 2 ? '27px' : '17px')};
  z-index: 2;

  & > p {
    //text-align: center;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.15px;
    padding-left: ${({Width}) => (Width === 2 ? '0px' : '6px')};
  }
  & > span {
    opacity: 0.7;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.07px;
  }
`;

export const DeleteButton = styled.button<{theme: string}>`
  z-index: 2;
  position: absolute;
  top: 0;
  right: 5px;
  background-color: transparent;
  padding: 5px;
  border: none;
  color: ${({theme}) =>
    theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  cursor: pointer;
`;

export const DemoAddButonWrapper = styled.div<{theme: string}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  & > svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    & > g {
      & > path {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};
      }
    }
  }
`;

export const DemoAddButonInEditWrapper = styled.div<{theme: string}>`
  //position: relative;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 30px;
    height: 30px;
    cursor: pointer;
    & > g {
      & > path {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};
      }
    }
  }
`;

export const DemoEditWrapper = styled.div<{theme: string}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  & > textarea {
    padding-top: 15px;
    flex-basis: 80%;
    background: transparent;
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 16px;
    overflow: hidden;
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    resize: none;
  }
`;
