import {HeaderOverview, OverviewWrapper} from './styles';
import React, {useContext} from 'react';
import {Block, BlockColor} from '../../../interface';
import {UserThemeContext} from '../../../../../../contexts/UserThemeContext';
import ShortDescriptionBlockComponent, {
  ShortDescriptionBlock,
} from '../../ShortDescriptionBlock';
import LongDescriptionBlockComponent, {
  LongDescriptionBlock,
} from '../../LongDescriptionBlock';
import DemoReelBlockComponent, {DemoReelBlock} from '../../DemoReelBlock';
import {VideoBlockComponent} from '../../VideoBlock';
import GalleryBlockComponent, {GalleryBlock} from '../../GalleryBlock';
import CommentsBlockComponent, {CommentsBlock} from '../../CommentsBlock';
import {BannerBlockComponent} from '../../BannerBlock';
import RecomendedProductionsBlockComponent from '../../RecomendedProductionBlock';
import {EmptyBlockComponent} from '../../EmptyBlock';
import TrackBlockComponent from '../../TrackBlock';
import RecomendedTracksBlockComponent from '../../RecomendedTracksBlock';
import SocialMediaBlockComponent from '../../SocialMediaBlock';
import OfferBlockComponent from '../../OfferBlock';
import DefaultBlockComponent from '../../DefaultBlock';

import audioBlockImg from '../../../../../../images/CardOverview/760x180_galeria_audio.png';
import banner from '../../../../../../images/CardOverview/760x180_baner.png';
import czysty from '../../../../../../images/CardOverview/760x180_czysty.png';
import galeriaObrazu from '../../../../../../images/CardOverview/760x180_galeria_obrazow.png';
import komentarze from '../../../../../../images/CardOverview/760x180_komentarze.png';
import multiprodukt from '../../../../../../images/CardOverview/760x180_polecane_produkty.png';
import multitrack from '../../../../../../images/CardOverview/760x180_polecane_tracki.png';
import nagłówek from '../../../../../../images/CardOverview/760x180_naglowek.png';
import nagłówekDuży from '../../../../../../images/CardOverview/760x180_naglowek-duzy.png';
import oMnieDługi from '../../../../../../images/CardOverview/760x180_tekst_dlugi.png';
import zdjecieztekstem from '../../../../../../images/CardOverview/760x180_obraz-z-tekstem.png';
import oMnieKrótki from '../../../../../../images/CardOverview/760x180_tekst_krotki.png';
import produkt from '../../../../../../images/CardOverview/760x180_polecany_produkt.png';
import social from '../../../../../../images/CardOverview/760x180_social_media.png';
import track from '../../../../../../images/CardOverview/760x180_polecany_track.png';
import tytuł from '../../../../../../images/CardOverview/760x180_tytułowy.png';
import ytImg from '../../../../../../images/CardOverview/760x180_youtube.png';
import blokPodstawowy from 'images/grafikaPodstawowy.png';

import {TitleBlock} from '../../TitleBlock';
import {HeaderBlock} from '../../HeaderBlock';
import {BusinessCardColorsContext} from '../../../../../../contexts/BusinessCardColorsContext';

interface Props {
  Type: number;
  Cords: {x: number; y: number};
}

const block = {
  BackgroundColorHex: BlockColor.DARK,
  BlockOpacityIn0To100Ratio: 100,
  FontColorHex: '#000',
  TitleColorHex: '#000',
  Id: '00000000-0000-0000-0000-000000000000',
  Type: 1,
  WebArea: {
    Height: 3,
    StartX: 0,
    StartY: 99,
    Width: 3,
  },
  MobileArea: {
    Height: 3,
    StartX: 0,
    StartY: 99,
    Width: 3,
  },
  Offer: {
    AvailabilityDuration: 7,
    AvailabilityEndDate: '2023-07-10T11:03:07.090299',
    AvailabilityStartDate: '2023-07-03T11:03:07.090299',
    Category: {
      CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
      Name: 'Koszulka',
    },
    SecondCategoryId: {
      CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb887',
      Name: 'Koszulka',
    },
    ThirdCategoryId: {
      CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb88',
      Name: 'Koszulka',
    },
    CoverFile:
      'https://mugostudiostorage.blob.core.windows.net/product/92b5ead1-7e7f-4506-9686-f472bbc895f5.jpg',
    Currency: 'PLN',
    Description:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomis',
    Featured: true,
    GrossValue: 350,
    Id: '08db7bb5-14c7-478d-8a2d-3694d58abe6f',
    IsWinWin: false,
    Name: 'Fizyczny 3',
    NegotiatedOffer: false,
    ProducerBuildingNumber: null,
    ProducerCountry: null,
    ProducerFirstName: 'Aleksander',
    ProducerFlatNumber: null,
    ProducerId: '1ce94f05-4ef6-4395-b2b1-3b48911c6cdb',
    ProducerName: 'AEXKON',
    ProducerPostalCode: null,
    ProducerRegion: null,
    ProducerSecondName: 'Kowalski-Nowak',
    ProducerStreet: null,
    ProductDemoFile: null,
    ProductId: '8cc82313-b9a4-4122-b4ec-6ea484680cec',
    ProductType: 2,
    RightsEnd: 0,
  },
  Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  TextOne:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  TextTwo: 'test2',
  TextThree: 'test3',
  BlockFileIds: [],
  BlockFiles: [],
  Comments: [],
  Base64BannerString: '',
  CurrentFileFtpFilePath: '',
  RemoveCurrentBanner: false,
  Offers: [],
  OfferIds: [],
  OfferId: '08db7bb5-14c7-478d-8a2d-3694d58abe6f',
  Street: '',
  Region: '',
  FirstName: '',
  LastName: '',
  PostalCode: '',
  Role: 0,
  Title: 'Title',
  Subtitle: 'Subtitle',
  UrlType: 5,
};

const heightForTypeBlock = (Type: number) => {
  switch (Type) {
    case 1:
      return 2;
    case 2:
      return 2;
    case 4:
      return 1;
    case 8:
      return 2;
    case 16:
      return 4;
    case 32:
      return 5;
    case 128:
      return 2;
    case 256:
      return 6;
    case 512:
      return 3;
    case 1024:
      return 6;
    case 2048:
      return 3;
    case 4096:
      return 1;
    case 8192:
      return 2;
    case 16384:
      return 1;
    case 32768:
      return 6;
    default:
      return 3;
  }
};

const widthForTypeBlock = (Type: number) => {
  switch (Type) {
    case 1:
      return 12;
    case 2:
      return 12;
    case 4:
      return 12;
    case 8:
      return 12;
    case 16:
      return 12;
    case 32:
      return 12;
    case 128:
      return 12;
    case 256:
      return 12;
    case 512:
      return 12;
    case 1024:
      return 12;
    case 2048:
      return 12;
    case 4096:
      return 12;
    case 8192:
      return 12;
    case 16384:
      return 12;
    case 32768:
      return 12;
    default:
      return 12;
  }
};

const createBlock = (
  Type: number,
  colors: {BackgroundColor: string; FontColor: string; TitleColor: string},
) => {
  return {
    BackgroundColorHex: colors.BackgroundColor,
    BlockOpacityIn0To100Ratio: 100,
    FontColorHex: colors.FontColor,
    TitleColorHex: colors.TitleColor,
    Id: '00000000-0000-0000-0000-000000000000',
    Type: 1,
    WebArea: {
      Height: heightForTypeBlock(Type),
      StartX: 0,
      StartY: 99,
      Width: widthForTypeBlock(Type),
    },
    MobileArea: {
      Height: heightForTypeBlock(Type),
      StartX: 0,
      StartY: 99,
      Width: widthForTypeBlock(Type),
    },
    Offer: {
      AvailabilityDuration: 7,
      AvailabilityEndDate: '2023-07-10T11:03:07.090299',
      AvailabilityStartDate: '2023-07-03T11:03:07.090299',
      Category: {
        CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
        Name: 'Koszulka',
      },
      SecondCategoryId: {
        CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
        Name: 'Koszulka',
      },
      ThirdCategoryId: {
        CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
        Name: 'Koszulka',
      },
      CoverFile:
        'https://mugostudiostorage.blob.core.windows.net/product/92b5ead1-7e7f-4506-9686-f472bbc895f5.jpg',
      Currency: 'PLN',
      Description:
        'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomis',
      Featured: true,
      GrossValue: 350,
      Id: '08db7bb5-14c7-478d-8a2d-3694d58abe6f',
      IsWinWin: false,
      Name: 'Fizyczny 3',
      NegotiatedOffer: false,
      ProducerBuildingNumber: null,
      ProducerCountry: null,
      ProducerFirstName: 'Aleksander',
      ProducerFlatNumber: null,
      ProducerId: '1ce94f05-4ef6-4395-b2b1-3b48911c6cdb',
      ProducerName: 'AEXKON',
      ProducerPostalCode: null,
      ProducerRegion: null,
      ProducerSecondName: 'Kowalski-Nowak',
      ProducerStreet: null,
      ProductDemoFile: null,
      ProductId: '8cc82313-b9a4-4122-b4ec-6ea484680cec',
      ProductType: 2,
      RightsEnd: 0,
    },
    Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    TextOne:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    TextTwo: 'test2',
    TextThree: 'test',
    BlockFileIds: [],
    BlockFiles: [],
    Comments: [],
    Base64BannerString: '',
    CurrentFileFtpFilePath: '',
    RemoveCurrentBanner: false,
    Offers: [
      {
        AvailabilityDuration: 7,
        AvailabilityEndDate: '2023-07-10T11:03:07.090299',
        AvailabilityStartDate: '2023-07-03T11:03:07.090299',
        Category: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        SecondCategoryId: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        ThirdCategoryId: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        CoverFile:
          'https://mugostudiostorage.blob.core.windows.net/product/92b5ead1-7e7f-4506-9686-f472bbc895f5.jpg',
        Currency: 'PLN',
        Description:
          'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomis',
        Featured: true,
        GrossValue: 350,
        Id: '08db7bb5-14c7-478d-8a2d-3694d58abe6c',
        IsWinWin: false,
        Name: 'Fizyczny 3',
        NegotiatedOffer: false,
        ProducerBuildingNumber: null,
        ProducerCountry: null,
        ProducerFirstName: 'Aleksander',
        ProducerFlatNumber: null,
        ProducerId: '1ce94f05-4ef6-4395-b2b1-3b48911c6cdb',
        ProducerName: 'AEXKON',
        ProducerPostalCode: null,
        ProducerRegion: null,
        ProducerSecondName: 'Kowalski-Nowak',
        ProducerStreet: null,
        ProductDemoFile: null,
        ProductId: '8cc82313-b9a4-4122-b4ec-6ea484680cec',
        ProductType: 2,
        RightsEnd: 0,
      },
      {
        AvailabilityDuration: 7,
        AvailabilityEndDate: '2023-07-10T11:03:07.090299',
        AvailabilityStartDate: '2023-07-03T11:03:07.090299',
        Category: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        SecondCategoryId: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        ThirdCategoryId: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        CoverFile:
          'https://mugostudiostorage.blob.core.windows.net/product/92b5ead1-7e7f-4506-9686-f472bbc895f5.jpg',
        Currency: 'PLN',
        Description:
          'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomis',
        Featured: true,
        GrossValue: 350,
        Id: '08db7bb5-14c7-478d-8a2d-3694d58abe6q',
        IsWinWin: false,
        Name: 'Fizyczny 3',
        NegotiatedOffer: false,
        ProducerBuildingNumber: null,
        ProducerCountry: null,
        ProducerFirstName: 'Aleksander',
        ProducerFlatNumber: null,
        ProducerId: '1ce94f05-4ef6-4395-b2b1-3b48911c6cdb',
        ProducerName: 'AEXKON',
        ProducerPostalCode: null,
        ProducerRegion: null,
        ProducerSecondName: 'Kowalski-Nowak',
        ProducerStreet: null,
        ProductDemoFile: null,
        ProductId: '8cc82313-b9a4-4122-b4ec-6ea484680cec',
        ProductType: 2,
        RightsEnd: 0,
      },
      {
        AvailabilityDuration: 7,
        AvailabilityEndDate: '2023-07-10T11:03:07.090299',
        AvailabilityStartDate: '2023-07-03T11:03:07.090299',
        Category: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        SecondCategoryId: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        ThirdCategoryId: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        CoverFile:
          'https://mugostudiostorage.blob.core.windows.net/product/92b5ead1-7e7f-4506-9686-f472bbc895f5.jpg',
        Currency: 'PLN',
        Description:
          'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomis',
        Featured: true,
        GrossValue: 350,
        Id: '08db7bb5-14c7-478d-8a2d-3694d58abe6l',
        IsWinWin: false,
        Name: 'Fizyczny 3',
        NegotiatedOffer: false,
        ProducerBuildingNumber: null,
        ProducerCountry: null,
        ProducerFirstName: 'Aleksander',
        ProducerFlatNumber: null,
        ProducerId: '1ce94f05-4ef6-4395-b2b1-3b48911c6cdb',
        ProducerName: 'AEXKON',
        ProducerPostalCode: null,
        ProducerRegion: null,
        ProducerSecondName: 'Kowalski-Nowak',
        ProducerStreet: null,
        ProductDemoFile: null,
        ProductId: '8cc82313-b9a4-4122-b4ec-6ea484680cec',
        ProductType: 2,
        RightsEnd: 0,
      },
      {
        AvailabilityDuration: 7,
        AvailabilityEndDate: '2023-07-10T11:03:07.090299',
        AvailabilityStartDate: '2023-07-03T11:03:07.090299',
        Category: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        SecondCategoryId: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        ThirdCategoryId: {
          CategoryId: '0ed1f9f8-0b4f-4ee7-ae38-8c0d22adb888',
          Name: 'Koszulka',
        },
        CoverFile:
          'https://mugostudiostorage.blob.core.windows.net/product/92b5ead1-7e7f-4506-9686-f472bbc895f5.jpg',
        Currency: 'PLN',
        Description:
          'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomis',
        Featured: true,
        GrossValue: 350,
        Id: '08db7bb5-14c7-478d-8a2d-3694d58abe6o',
        IsWinWin: false,
        Name: 'Fizyczny 3',
        NegotiatedOffer: false,
        ProducerBuildingNumber: null,
        ProducerCountry: null,
        ProducerFirstName: 'Aleksander',
        ProducerFlatNumber: null,
        ProducerId: '1ce94f05-4ef6-4395-b2b1-3b48911c6cdb',
        ProducerName: 'AEXKON',
        ProducerPostalCode: null,
        ProducerRegion: null,
        ProducerSecondName: 'Kowalski-Nowak',
        ProducerStreet: null,
        ProductDemoFile: null,
        ProductId: '8cc82313-b9a4-4122-b4ec-6ea484680cec',
        ProductType: 2,
        RightsEnd: 0,
      },
    ],
    OfferIds: [],
    OfferId: '08db7bb5-14c7-478d-8a2d-3694d58abe6f',
    Street: '',
    Region: '',
    FirstName: '',
    LastName: '',
    PostalCode: '',
    Role: 0,
    Title: 'Title',
    Subtitle: 'Subtitle',
    UrlType: 5,
  };
};

const renderBlock = (
  colors: {BackgroundColor: string; FontColor: string; TitleColor: string},
  block: Block,
  theme: string,
  Type: number,
  mobileOverview: boolean,
  isEditing = false,
  updateBlockDate = null,
) => {
  switch (Type) {
    case 1:
      return (
        // <ShortDescriptionBlockComponent
        //   mobileOverview={mobileOverview}
        //   theme={theme}
        //   block={createBlock(Type, colors)}
        //   isEditing={isEditing}
        //   changeText={updateBlockDate}
        // />
        <img src={oMnieKrótki} />
      );
    case 2:
      return (
        // <LongDescriptionBlockComponent
        //   isEditing={isEditing}
        //   theme={theme}
        //   mobileOverview={mobileOverview}
        //   block={createBlock(Type, colors)}
        //   changeText={updateBlockDate}
        // />
        <img src={oMnieDługi} />
      );
    case 4:
      return <img src={audioBlockImg} />;
    case 8:
      return (
        <VideoBlockComponent
          block={createBlock(Type, colors)}
          theme={theme}
          mobileOverview={mobileOverview}
          isEditing={isEditing}
          updateVideo={updateBlockDate}
        />
      );
    case 16:
      return (
        // <GalleryBlockComponent
        //   theme={theme}
        //   mobileOverview={mobileOverview}
        //   block={createBlock(Type, colors)}
        //   updateImgGallery={updateBlockDate}
        //   isEditing={isEditing}
        // />
        <img src={galeriaObrazu} />
      );
    case 32:
      return (
        // <CommentsBlockComponent
        //   block={createBlock(Type, colors)}
        //   theme={theme}
        //   mobileOverview={mobileOverview}
        //   isEditing={isEditing}
        //   updateComments={updateBlockDate}
        // />
        <img src={komentarze} />
      );
    case 128:
      return (
        // <BannerBlockComponent
        //   mobileOverview={mobileOverview}
        //   block={createBlock(Type, colors)}
        //   theme={theme}
        //   isEditing={isEditing}
        //   updateBanner={updateBlockDate}
        // />
        <img src={banner} />
      );
    case 256:
      return (
        // <RecomendedProductionsBlockComponent
        //   block={createBlock(Type, colors)}
        //   theme={theme}
        //   mobileOverview={mobileOverview}
        //   isEditing={isEditing}
        //   updateProductionBlock={updateBlockDate}
        // />
        <img src={multiprodukt} />
      );
    case 512:
      return (
        // <EmptyBlockComponent
        //   isEditing={isEditing}
        //   block={createBlock(Type, colors)}
        // />
        <img src={czysty} />
      );
    case 1024:
      return (
        // <TrackBlockComponent
        //   block={createBlock(Type, colors)}
        //   theme={theme}
        //   mobileOverview={mobileOverview}
        //   isEditing={isEditing}
        //   updateTrack={updateBlockDate}
        // />
        <img src={track} />
      );
    case 2048:
      return (
        // <RecomendedTracksBlockComponent
        //   block={createBlock(Type, colors)}
        //   theme={theme}
        //   mobileOverview={mobileOverview}
        //   isEditing={isEditing}
        //   updateTracksBlock={updateBlockDate}
        // />
        <img src={multitrack} />
      );
    case 4096:
      return (
        // <SocialMediaBlockComponent
        //   isEditing={isEditing}
        //   block={createBlock(Type, colors)}
        //   theme={theme}
        //   mobileOverview={mobileOverview}
        //   changeUrl={updateBlockDate}
        // />
        <img src={social} />
      );
    case 8192:
      return (
        // <TitleBlock
        //   theme={theme}
        //   mobileOverview={mobileOverview}
        //   isEditing={isEditing}
        //   block={createBlock(Type, colors)}
        //   changeText={updateBlockDate}
        // />
        <img src={tytuł} />
      );
    case 16384:
      return (
        // <HeaderBlock
        //   mobileOverview={mobileOverview}
        //   theme={theme}
        //   isEditing={isEditing}
        //   block={createBlock(Type, colors)}
        //   changeText={updateBlockDate}
        // />
        <img src={nagłówek} />
      );
    case 32768:
      return (
        // <OfferBlockComponent
        //   theme={theme}
        //   mobileOverview={mobileOverview}
        //   block={createBlock(Type, colors)}
        //   isEditing={isEditing}
        //   updateOffer={updateBlockDate}
        // />
        <img src={produkt} />
      );
    case 65536:
      return <img src={ytImg} />;
    case 131072:
      return <img src={nagłówekDuży} />;
    case 262144:
      return <img src={zdjecieztekstem} />;
    case 524288:
      return <img src={zdjecieztekstem} />;
    case 64:
      return <img src={blokPodstawowy} />;
    default:
      return <h1>default</h1>;
  }
};

export const OverviewBlock: React.FC<Props> = ({Type, Cords}) => {
  const [theme, setTheme] = useContext(UserThemeContext);
  const [businessCardColors, setBusinessCardColors] = useContext(
    BusinessCardColorsContext,
  );

  const cordX = Cords.x === 0 ? '0px' : '-113px';

  return (
    <OverviewWrapper
      style={{
        background: `#${businessCardColors.BackgroundColor}`,
        left: '-292px',
        // width: `${56 * widthForTypeBlock(Type)}px`,
        // height: `${56 * heightForTypeBlock(Type)}px`,
        width: '760px',
        height: '180px',
      }}
    >
      {/*<HeaderOverview></HeaderOverview>*/}
      {renderBlock(businessCardColors, block, theme, Type, false, false)}
    </OverviewWrapper>
  );
};
