import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import mugoImage from '../../../../images/post_mugo.png';
import api from '../../../../services/axiosConfig';
import useAlert from '../../../../hooks/useAlert';
import {ErrorStar} from 'components/ErrorStar';

import openEye from 'images/svg/openEye.svg';
import closeEye from 'images/svg/closeEye.svg';
import {InputView} from 'pages/MyStudio/Account/styles';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 88px;
`;

export const Main = styled.div`
  width: 1020px;
  height: calc(100vh - 112px);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  /* @media (max-width: 1045px) {
    width: 360px;
  } */
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;

  grid-template-columns: 1fr;
  gap: 11px 0;
  grid-auto-rows: unset;

  @media (max-width: 1045px) {
  }
`;

export const RecoverWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-row: span 6;
  /* height: 372px; */

  @media (max-width: 1045px) {
  }
`;

export const RecoverHeader = styled.div`
  width: 100%;
  position: relative;
  height: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: white;
  }
`;

const IconShowPasswordWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  & > svg {
    overflow: visible;
    width: 100%;
    height: 100%;
  }
  & > img {
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    top: 12px;

    & > img {
      width: 19px;
      height: 19px;
    }
  }
`;

export const RecoverContent = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px;
  gap: 14px;

  & > a {
    color: rgba(255, 255, 255, 0.7);
    font-size: 11px;
    /* line-height: 21px; */
  }
`;

export const InputWrapper = styled.div<{isRedBorder?: boolean}>`
  background: ${({isRedBorder}) => (isRedBorder ? `#FF7E7D` : 'transparent')};
  border: 1px solid rgba(112, 112, 112, 0.3);
  height: 22px;
  border-radius: 5px;
  width: 100%;
  margin-top: ${({isRedBorder}) => (isRedBorder ? `0` : '10px')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    color: #07020f;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.625px;
    font-weight: 400;
    margin-left: 8.5px;
  }

  & > input {
    text-align: right;
    font-size: 11px;
    line-height: 18px;
    margin-right: 8.5px;
    color: rgba(7, 2, 15, 0.5);
    letter-spacing: 0.625px;
    font-weight: 400;
    background-color: transparent;
    border: none;

    &::placeholder {
      color: rgba(7, 2, 15, 0.5);
    }

    &:-webkit-autofill {
      -webkit-text-fill-color: rgba(7, 2, 15, 0.5) !important;
    }
  }

  & > input:focus {
    outline: none;
  }
`;

export const PrimaryButton = styled.button<{
  background?: string;
  color?: string;
  borderColor?: string;
}>`
  width: 100%;
  height: 44px;
  border: none;
  border: ${({borderColor}) =>
    !borderColor ? 'none' : `1px solid ${borderColor}`};
  background: ${({background}) => (!background ? '#3A87F0' : background)};
  border-radius: 15px;
  font-size: 15px;
  color: ${({color}) => (!color ? '#ffffff' : color)};
  transition: all 0.15s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-weight: 400;
  letter-spacing: 0.7px;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`;

export const PageHeaderWrapper = styled.div`
  width: 1020px;
  display: flex;
  height: 590px;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 9px 9px 21px 21px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12);

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }
`;

export const PageHeaderHeaderWrapper = styled.div`
  width: 100%;
  height: 55px;
  border-radius: 24px 24px 0 0;
  background: #0091ff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageHeaderHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;

  & > p {
    color: #352f40;
    font-size: 15px;
    letter-spacing: 0.625px;
  }
`;

export const PageHeaderContent = styled.div`
  width: 100%;
  height: calc(100% - 55px);
  display: grid;
  align-items: center;
  grid-template-columns: 535px 485px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightSectionContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 24.5px 85px 11px 88px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-weight: 700;
    font-size: 21px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: #07020f;
  }

  & > a {
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.625px;
    line-height: 24px;
    color: rgba(93, 93, 97, 0.7);
    text-decoration: underline;
  }
`;

export const ConfrimRecoverPassword: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [newPasswordError, setNewPasswordError] = useState({
    isError: false,
    text: '',
  });
  const [confirmPasswordError, setConfirmPasswordError] = useState({
    isError: false,
    text: 'Oba hasła muszą być identyczne',
  });
  const [recoverPassword, setRecoverPassword] = useState({
    password: '',
    replayPassword: '',
  });

  const handleRecoverDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRecoverPassword((prev) => ({...prev, [e.target.id]: e.target.value}));
    setNewPasswordError({
      isError: false,
      text: '',
    });
  };

  const locationSearch = window.location.search;
  const navigate = useNavigate();

  const {Alert} = useAlert();

  const params = new URLSearchParams(locationSearch);

  const changePassword = async () => {
    const {password, replayPassword} = recoverPassword;

    setNewPasswordError({
      isError: false,
      text: '',
    });
    setConfirmPasswordError({
      isError: false,
      text: 'Oba hasła muszą być identyczne',
    });
    let areThereErrors = false;

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (password.length === 0) {
      setNewPasswordError({
        isError: true,
        text: 'Hasło nie może być puste',
      });
      areThereErrors = true;
      console.log('haslo nie moze byc puste');
    }

    // return Alert('Hasło nie może być puste', 'Komunikat');
    if (!passwordRegex.test(password)) {
      setNewPasswordError({
        isError: true,
        text: 'Hasło musi zawierać minimum 8 znaków, 1 znak specjalny, 1 wielką i małą literę i 1 liczbę',
      });
      areThereErrors = true;
    }

    // return Alert(
    //   'Hasło musi zawierać minimum 8 znaków, 1 znak specjalny, 1 wielką i małą literę i 1 liczbę',
    //   'Komunikat',
    // );

    if (password !== replayPassword) {
      setConfirmPasswordError({
        isError: true,
        text: 'Oba hasła muszą być identyczne',
      });
      areThereErrors = true;
    }

    console.log('haslo jest złe');
    // return Alert('Oba hasła muszą być identyczne', 'Komunikat');
    if (areThereErrors) return;
    console.log('wysyła zmiane');
    try {
      const body = {
        UserId: params.get('userId').replaceAll(' ', '+'),
        Code: params.get('code').replaceAll(' ', '+'),
        Password: password,
      };
      console.log(body);
      const response = await api.put(`Auth/ChangePassword`, body);
      Alert('Twoje hasło zostało zmienione', 'Komunikat');
      navigate('/identity/login');
    } catch (error) {
      console.error(error);
      Alert(error.response.data.Error.Message, 'Błąd');
    }
  };

  return (
    <MainWrapper>
      <Main>
        <PageHeaderWrapper>
          <PageHeaderHeaderWrapper>
            <PageHeaderHeader>
              <p>Witamy w MUGO STUDIO!</p>
            </PageHeaderHeader>
          </PageHeaderHeaderWrapper>
          <PageHeaderContent>
            <ImageWrapper>
              <img src={mugoImage} />
            </ImageWrapper>
            <RightSectionContent>
              <>
                <h1 style={{marginBottom: '27.5px'}}>Zmiana hasła</h1>
                <InputView isError={newPasswordError.isError} isEditing={true}>
                  <p>Nowe hasło</p>
                  <input
                    style={{paddingRight: '17.5px'}}
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Pole obowiązkowe"
                    value={recoverPassword.password}
                    onChange={handleRecoverDataChange}
                    id="password"
                  />
                  {newPasswordError.isError && (
                    <ErrorStar text={newPasswordError.text} />
                  )}
                </InputView>
                <InputView
                  isEditing={true}
                  isError={
                    recoverPassword.password !== recoverPassword.replayPassword
                  }
                  style={{marginBottom: '308px'}}
                >
                  <p>Potwierdź hasło</p>
                  <input
                    style={{paddingRight: '17.5px'}}
                    placeholder="Pole obowiązkowe"
                    type={showPassword ? 'text' : 'password'}
                    value={recoverPassword.replayPassword}
                    onChange={handleRecoverDataChange}
                    id="replayPassword"
                  />
                  {recoverPassword.password !==
                    recoverPassword.replayPassword && (
                    <ErrorStar text={confirmPasswordError.text} />
                  )}
                  <IconShowPasswordWrapper
                    onClick={() => setShowPassword((prevState) => !prevState)}
                  >
                    {showPassword ? (
                      <img src={closeEye} alt="hidePAssword" />
                    ) : (
                      <img src={openEye} alt="showPAssword" />
                    )}
                  </IconShowPasswordWrapper>
                </InputView>
                <PrimaryButton onClick={() => changePassword()}>
                  Zmień
                </PrimaryButton>
              </>
            </RightSectionContent>
          </PageHeaderContent>
        </PageHeaderWrapper>
      </Main>
    </MainWrapper>
  );
};
