import styled from 'styled-components';

export const OfferBlockRowWrapper = styled.div<{
  bgColor: string;
  isMobile: boolean;
}>`
  height: ${({isMobile}) => (isMobile ? '184px' : '286px')};
  width: 100%;
  border-radius: 24px;
  /* border: 1px solid rgba(255, 255, 255, 0.21);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3); */
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background: ${({bgColor}) => `rgba(${bgColor})`};
  border: 1px solid rgba(112, 112, 112, 0.3);
  @media (max-width: 1024px) {
    height: 184px;
  }
  @media (max-width: 415px) {
    height: 174px;
  }
  @media (max-width: 390px) {
    height: 164px;
  }
  @media (max-width: 375px) {
    height: 154px;
  }
  @media (max-width: 360px) {
    height: 144px;
  }
  @media (max-width: 330px) {
    height: 134px;
  }
`;

export const OfferBlockRowImgWrapper = styled.div<{
  Width: number;
  Height: number;
  isMobile: boolean;
}>`
  /* width: 284px;
  height: 284px; */
  height: ${({isMobile}) => (isMobile ? '182px ' : '284px')};
  width: ${({isMobile}) => (isMobile ? '182px ' : '284px')};
  min-height: ${({isMobile}) => (isMobile ? '182px ' : '284px')};
  min-width: ${({isMobile}) => (isMobile ? '182px ' : '284px')};
  /* border: 1px solid transparent; */
  border-radius: 22px;
  & > img {
    height: 100%;
    /* max-width: 282px; */
    max-width: ${({isMobile}) => (isMobile ? '182px ' : '282px')};
    border-radius: 22px;
  }
  @media (max-width: 1024px) {
    height: 182px;
    min-height: 182px;
    width: 182px;
    min-width: 182px;
    & > img {
      max-width: 180px;
    }
  }
  @media (max-width: 415px) {
    height: 172px;
    min-height: 172px;
    width: 172px;
    min-width: 172px;
    & > img {
      max-width: 170px;
    }
  }
  @media (max-width: 390px) {
    height: 162px;
    min-height: 162px;
    width: 162px;
    min-width: 162px;
    & > img {
      max-width: 160px;
    }
  }
  @media (max-width: 375px) {
    height: 152px;
    min-height: 152px;
    width: 152px;
    min-width: 152px;
    & > img {
      max-width: 150px;
    }
  }
  @media (max-width: 360px) {
    height: 142px;
    min-height: 142px;
    width: 142px;
    min-width: 142px;
    & > img {
      max-width: 140px;
    }
  }
  @media (max-width: 330px) {
    height: 132px;
    min-height: 132px;
    width: 132px;
    min-width: 132px;
    & > img {
      max-width: 130px;
    }
  }
`;

export const OfferBlockRowContentWrapper = styled.div<{isMobile: boolean}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: ${({isMobile}) => (isMobile ? '15px' : '58px')};
  align-items: ${({isMobile}) => (isMobile ? 'space-between' : 'flex-start')};
  justify-content: ${({isMobile}) =>
    isMobile ? 'space-between' : 'flex-start'};
  padding: ${({isMobile}) => (isMobile ? '17px 0 ' : '40px 0')};
  @media (max-width: 1024px) {
    padding: 17px 0;
    margin-left: 15px;
  }
`;

export const OfferBlockRowTypeInfo = styled.p`
  font-size: 18px;
  letter-spacing: 0.3px;
  line-height: 47px;
`;

export const OfferBlockRowNameWrapper = styled.p<{
  Width: number;
  isMobile: boolean;
}>`
  width: ${({isMobile}) => (isMobile ? 'auto' : 'auto')};
  font-size: ${({isMobile}) => (isMobile ? '21px' : '24px')};
  letter-spacing: -0.25px;
  margin-right: ${({isMobile}) => (isMobile ? '0px' : '58px')};
  line-height: 30px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  overflow: hidden;
  display: ${({isMobile}) => (isMobile ? '-webkit-box' : 'block')};
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (max-width: 1024px) {
    width: auto;
    margin-right: 0px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 21px;
  }
  @media (max-width: 360px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const OfferBlockRowCategoryWrapper = styled.div<{
  Width: number;
  Height: number;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  /* margin-left: 10px; */
  & > p {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.35px;

    opacity: 0.5;
  }
  @media (max-width: 1024px) {
    /* margin-left: 5px; */
    & > p {
      font-size: 13px;
    }
  }
`;

export const OfferBlockRowFooter = styled.div`
  width: 100%;
  margin-top: auto;
`;

export const OfferBlockRowWinWinWrapper = styled.div<{
  Width: number;
  Height: number;
  isMobile: boolean;
}>`
  display: flex;
  flex-direction: ${({Width}) => (Width === 6 ? 'column' : 'row')};
  justify-content: ${({Width}) => (Width === 6 ? 'center' : 'flex-start')};
  align-items: ${({Width}) => (Width === 6 ? 'flex-start' : 'center')};
  gap: ${({isMobile}) => (isMobile ? '5px' : '10px')};
  & > p {
    //line-height: 32px;
    letter-spacing: -0.35px;
    font-size: ${({isMobile}) => (isMobile ? '16px' : '24px')};
    font-weight: bold;
  }
  @media (max-width: 1024px) {
    gap: 5px;
    & > p {
      font-size: 16px;
    }
  }
  @media (max-width: 360px) {
    & > p {
      font-size: 14px;
    }
  }
`;

export const OfferBlockRowDescription = styled.p`
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const OfferEndDataWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  right: 315px;
  top: 0px;
  z-index: 99;
  & > p {
    font-size: 18px;
    font-weight: bold;
    color: #ff0300;
  }
`;
