import styled from 'styled-components';

export const RecomendedTracksBlockWrapper = styled.div<{
  backgroundAlpha: number;
  backgroundColor: string;
  height: number;
  isMobile: boolean;
}>`
  width: 100%;
  height: 100%;
  //background: #17181b;

  padding: ${({isMobile}) => (isMobile ? '16px ' : '20px 50px 25px 50px')};
  overflow: hidden;
  background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`};
  /* border-radius: 24px; */
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
  @media (max-width: 1024px) {
    padding: 16px;
  }
  @media (max-width: 375px) {
    padding: 14px;
  }
  @media (max-width: 340px) {
    padding: 10px;
  }
`;
export const RecomendedTrackContentWrapper = styled.div<{isMobile: boolean}>`
  position: relative;
  width: 100%;
  height: ${({isMobile}) => (isMobile ? '386px' : '330px')};
  overflow: hidden;
  display: flex !important;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  gap: ${({isMobile}) => (isMobile ? '20px' : '4px')};
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: normal;
    height: 410px;
    gap: 20px;
  }
  @media (max-width: 390px) {
    gap: 10px;
  }

  //background-color: #3a87f0;
`;
export const RecomendedTrackMoreContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 415px;
  overflow: hidden;
  display: flex !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  align-content: baseline !important;
  gap: 17px 4px;

  //background-color: #3a87f0;
`;

export const RecomendedTracksBlockHeader = styled.div<{theme?: string}>`
  height: 10%;
  width: 100%;
  display: flex;
  gap: 11px;
  align-items: center;

  & > h4 {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    padding: 0 5px;
  }

  & > svg {
    width: 27.38px;
    height: 21px;
    & > path {
      fill: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  @media (max-width: 1065px) {
    height: 5%;
  }
  @media (max-width: 920px) {
    & > h4 {
      font-size: 16px;
    }
    & > svg {
      height: 16px;
    }
  }
  @media (max-width: 780px) {
    & > h4 {
      font-size: 14px;
    }
    & > svg {
      height: 14px;
    }
  }
  @media (max-width: 720px) {
    & > h4 {
      font-size: 12px;
    }
    & > svg {
      height: 12px;
    }
  }
  @media (max-width: 650px) {
    & > h4 {
      padding: 0;
      font-size: 11px;
    }
    & > svg {
      height: 11px;
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      font-size: 10px;
    }
    & > svg {
      height: 10px;
    }
  }
  @media (max-width: 550px) {
    & > h4 {
      font-size: 8px;
    }
    & > svg {
      height: 8px;
    }
  }
  @media (max-width: 430px) {
    & > h4 {
      font-size: 6px;
    }
    & > svg {
      height: 6px;
    }
  }
`;

export const RecomendedTracksBlockContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 24px;
    height: 10px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment {
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #125faf;
    border-radius: 25px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
`;

export const RecomendedTracksWrapper = styled.div<{
  theme: string;
  Width: number;
}>`
  cursor: pointer;
  position: relative;
  width: 100%;
  min-height: 92px;
  padding: 5px;
  border: ${({theme}) =>
    theme === '000000'
      ? 'solid 1px black'
      : theme === '0C426A'
      ? 'solid 1px #0C426A'
      : 'solid 1px white'};
  border-radius: 10px;
  display: flex;
  align-items: center;
  & > img {
    height: 80px;
  }
  & > p {
    width: calc(100% - 15px);
    text-align: center;
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  }
  & > svg {
    margin-left: 15px;
    & > path {
      fill: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }
  &:hover {
    border: ${({theme}) =>
      theme === '000000'
        ? 'solid 2px black'
        : theme === '0C426A'
        ? 'solid 2px #0C426A'
        : 'solid 2px white'};
  }
  @media (max-width: 920px) {
    min-height: 80px;
    & > img {
      height: ${({Width}) => (Width === 1 ? '60px' : '70px')};
    }
  }
  @media (max-width: 760px) {
    min-height: 60px;
    & > img {
      height: ${({Width}) => (Width === 1 ? '40px' : '50px')};
    }
  }
  @media (max-width: 580px) {
    min-height: ${({Width}) => (Width === 1 ? '36px' : '50px')};
    & > img {
      height: ${({Width}) => (Width === 1 ? '28px' : '40px')};
    }
  }
  @media (max-width: 450px) {
    min-height: ${({Width}) => (Width === 1 ? '28px' : '40px')};
    & > img {
      height: ${({Width}) => (Width === 1 ? '18px' : '30px')};
    }
  }
  @media (max-width: 360px) {
    min-height: ${({Width}) => (Width === 1 ? '24px' : '32px')};
    & > img {
      height: ${({Width}) => (Width === 1 ? '14px' : '24px')};
    }
  }
`;

export const RecomendedTracksTextAndButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  gap: 5px;
  padding-right: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 650px) {
    gap: 0;
    padding-right: 0;
  }
`;
export const RecomendedTracksTextAndButtonWrapperForBigOne = styled.div<{
  fontColor: string;
  Width: number;
}>`
  width: 100%;
  gap: 5px;
  padding-right: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > p {
    font-size: 14px;
    margin-left: 10px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }
  @media (max-width: 760px) {
    & > p {
      font-size: ${({Width}) => (Width === 3 ? '12px' : '10px')};
    }
  }
  @media (max-width: 580px) {
    & > p {
      font-size: ${({Width}) => (Width === 3 ? '10px' : '8px')};
    }
  }
  @media (max-width: 450px) {
    & > p {
      font-size: ${({Width}) => (Width === 3 ? '8px' : '6px')};
    }
  }
  @media (max-width: 360px) {
    & > p {
      font-size: ${({Width}) => (Width === 3 ? '6px' : '5px')};
    }
  }
`;
export const RecomenedTracksTextAndPrice = styled.div<{fontColor: string}>`
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  & > p {
    font-size: 11px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }
  @media (max-width: 760px) {
    & > p {
      font-size: 10px;
    }
  }
  @media (max-width: 650px) {
    & > p {
      font-size: 9px;
    }
  }
  @media (max-width: 580px) {
    & > p {
      font-size: 6px;
    }
  }
  @media (max-width: 450px) {
    & > p {
      font-size: 4px;
    }
  }
  @media (max-width: 360px) {
    & > p {
      font-size: 3px;
    }
  }
`;
export const RecomendedTracksPriceButton = styled.button<{
  fontColor: string;
  bgColor: string;
  Width: number;
}>`
  width: ${({Width}) => (Width === 1 ? '64px' : '85px')};
  height: ${({Width}) => (Width === 1 ? '18px' : '22px')};
  border: ${({fontColor}) => `solid 1px rgb(${fontColor})`};
  border-radius: 5px;
  background: ${({fontColor}) => `rgb(${fontColor})`};
  font-size: ${({Width}) => (Width === 1 ? '10px' : '11px')};
  color: ${({bgColor}) => `rgb(${bgColor})`};
  transition: all 0.15s;
  cursor: pointer;
  @media (max-width: 760px) {
    height: ${({Width}) =>
      Width === 3 ? '20px' : Width === 2 ? '18px' : '16px'};
    font-size: ${({Width}) =>
      Width === 3 ? '11px' : Width === 2 ? '10px' : '8px'};
    width: ${({Width}) =>
      Width === 3 ? '85px' : Width === 2 ? '80px' : '48px'};
  }
  @media (max-width: 580px) {
    height: ${({Width}) =>
      Width === 3 ? '18px' : Width === 2 ? '16px' : '12px'};
    font-size: ${({Width}) =>
      Width === 3 ? '8px' : Width === 2 ? '6px' : '4px'};
    width: ${({Width}) =>
      Width === 3 ? '72px' : Width === 2 ? '48px' : '28px'};
  }
  @media (max-width: 450px) {
    height: ${({Width}) =>
      Width === 3 ? '14px' : Width === 2 ? '13px' : '10px'};
    font-size: ${({Width}) =>
      Width === 3 ? '6px' : Width === 2 ? '5px' : '4px'};
    width: ${({Width}) =>
      Width === 3 ? '54px' : Width === 2 ? '32px' : '24px'};
  }
  @media (max-width: 360px) {
    height: ${({Width}) =>
      Width === 3 ? '12px' : Width === 2 ? '12px' : '8px'};
    font-size: ${({Width}) =>
      Width === 3 ? '5px' : Width === 2 ? '4px' : '3px'};
    width: ${({Width}) =>
      Width === 3 ? '48px' : Width === 2 ? '28px' : '18px'};
  }
`;
export const RecomendedTracksButtonWrapper = styled.div<{
  fontColor: string;
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 5px;
  & > button {
    flex-basis: 33%;
    border: 1px solid rgba(255, 255, 255, 0.21);
    color: ${({fontColor}) => `rgb(${fontColor})`};
    background-color: transparent;
    cursor: pointer;
    font-size: 10px;
    padding: 4px;
    &:hover {
      border-color: rgba(255, 255, 255, 0.61);
    }
  }
  @media (max-width: 760px) {
    & > button {
      font-size: 8px;
      padding: 3px;
    }
  }
  @media (max-width: 650px) {
    gap: 3px;
    & > button {
      font-size: 7px;
      padding: 2px;
    }
  }
  @media (max-width: 580px) {
    gap: 0;
    & > button {
      font-size: 5px;
      padding: 0px;
    }
  }
  @media (max-width: 450px) {
    & > button {
      font-size: 4px;
    }
  }
  @media (max-width: 360px) {
    & > button {
      font-size: 3px;
    }
  }
`;

export const RecomendedTracksButtonWrapperForBigOne = styled.div<{
  fontColor: string;
  Width: number;
}>`
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
  gap: 5px;
  & > button {
    flex-basis: 33%;
    border: 1px solid rgba(255, 255, 255, 0.21);
    color: ${({fontColor}) => `rgb(${fontColor})`};
    background-color: transparent;
    cursor: pointer;
    font-size: 11px;
    padding: 5px;
    &:hover {
      border-color: rgba(255, 255, 255, 0.61);
    }
  }
  @media (max-width: 760px) {
    & > button {
      font-size: ${({Width}) => (Width === 3 ? '10px' : '8px')};
    }
  }
  @media (max-width: 580px) {
    & > button {
      font-size: ${({Width}) => (Width === 3 ? '8px' : '6px')};
      padding: ${({Width}) => (Width === 3 ? '4px' : '3px')};
    }
  }
  @media (max-width: 450px) {
    & > button {
      padding: 2px;
      font-size: ${({Width}) => (Width === 3 ? '6px' : '5px')};
    }
  }
  @media (max-width: 360px) {
    gap: 1px;
    & > button {
      font-size: ${({Width}) => (Width === 3 ? '5px' : '4px')};
      padding: 1px;
    }
  }
`;

export const RecomendedTracksWinWinInfo = styled.div<{
  fontColor: string;
  theme: string;
}>`
  height: 22px;
  width: 94px;
  border: ${({fontColor}) => `solid 1px rgb(${fontColor})`};
  display: flex;
  gap: 8.7px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin-right: 22px;
  cursor: pointer;

  & > p {
    color: ${({fontColor}) => `rgb(${fontColor})`};
    font-size: 9px;
  }
  g {
    & > path {
      stroke: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
    & > circle {
      stroke: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  @media (max-width: 1100px) {
    margin-right: unset;
  }
  @media (max-width: 760px) {
    height: 20px;
    width: 86px;
  }
  @media (max-width: 580px) {
    height: 18px;
    width: 72px;
    & > p {
      font-size: 8px;
    }
  }
  @media (max-width: 450px) {
    height: 14px;
    width: 54px;
    & > p {
      font-size: 6px;
    }
  }
  @media (max-width: 360px) {
    height: 12px;
    width: 48px;
    & > p {
      font-size: 5px;
    }
  }
`;

export const RecomendedTracksWinAndPriceWrapper = styled.div`
  display: flex;
  margin-right: 10px;
  gap: 20px;
  align-items: center;
  @media (max-width: 760px) {
    gap: 15px;
  }
  @media (max-width: 580px) {
    gap: 10px;
    margin-right: 5px;
  }
  @media (max-width: 450px) {
    gap: 5px;
    margin-right: 0px;
  }
`;

export const RecomendedAddButonWrapper = styled.div<{theme: string}>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 48px;
    height: 48px;
    cursor: pointer;
    & > g {
      & > path {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};
      }
    }
  }
`;
export const DeleteButton = styled.button<{fontColor: string; Width: number}>`
  position: absolute;
  top: 0;
  right: 5px;
  background-color: transparent;
  padding: ${({Width}) => (Width === 1 ? '0' : '5px')};
  border: none;
  color: ${({fontColor}) => `rgb(${fontColor})`};
  cursor: pointer;
`;

export const ElementToAdd = styled.div<{fontColor: string}>`
  width: 100%;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  color: ${({fontColor}) => `rgb(${fontColor})`};
`;

export const SliderWrapper = styled.div<{
  blockHeight: number;
  isMobile: boolean;
}>`
  width: 100%;
  /* height: 230px; */
  height: ${({isMobile}) => (isMobile ? '370px' : '230px')};

  .slick-dots {
    bottom: ${({isMobile}) => (isMobile ? '10px' : '0px')};
  }

  .slick-dots li {
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp100};
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    height: 370px;
    .slick-dots {
      bottom: 10px;
    }
  }
`;

export const SingleProduct = styled.div`
  width: 172px;
  height: 287px;
  background: ${({theme}) => theme.colors.popularCategoryBackground};
  cursor: pointer;
  border-radius: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
  padding: 2px 2px 0px;
  box-shadow: 0px 9px 15px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: background 0.15s;

  &:hover {
    background: ${({theme}) => theme.colors.singleArticleHoverBackground};

    & > img {
      filter: brightness(1.1);
    }
  }

  & > img {
    height: 168px;
    width: 168px;
    border-radius: 22px;
    margin-bottom: 6px;
  }

  & > h1 {
    height: 33px;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;
    padding: 0 6px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > h2 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    text-align: center;
    padding: 0 6px;
    margin-bottom: 9px;
  }

  & > p {
    height: 15px;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-align: center;
    padding: 0 6px;
    margin-bottom: 2px;
  }

  & > h4 {
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-align: center;
    padding: 0 6px;
  }

  & > svg {
    width: 59.5px;
    height: 15px;
    margin-bottom: 2px;
  }

  @media (max-width: 390px) {
    width: 162px;
    padding: 1px 1px 0;

    & > img {
      height: 158px;
      width: 158px;
    }
  }
`;
