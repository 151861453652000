// export default {
//   colors: {
//     white: '#FFFFFF',
//     whiteOp011: 'rgba(255,255,255,0.11)',
//     whiteOp021: 'rgba(255,255,255,0.21)',
//     whiteOp030: 'rgba(255,255,255,0.30)',
//     whiteOp038: 'rgba(255,255,255,0.38)',
//     whiteOp050: 'rgba(255,255,255,0.50)',
//     whiteOp058: 'rgba(255,255,255,0.58)',
//     whiteOp076: 'rgba(255,255,255,0.76)',
//     whiteOp092: 'rgba(255,255,255,0.92)',
//     black: '#000000',
//     blackOp010: 'rgba(0,0,0,0.1)',
//     blackOp030: 'rgba(0,0,0,0.3)',
//     lightGray: '#919191',
//     gray: '#5D5D5D',
//     dark: '#211D1D',
//     darkBlue: '#030027',
//   },
//   breakpoints: {
//     smallMobile: '400px',
//     mobile: '1024px',
//     tablet: '1200px',
//   },
//   breakpointsJs: {
//     smallMobile: 400,
//     mobile: 1024,
//     tablet: 1200,
//   },
// };
export const light = {
  name: 'light-theme',
  colors: {
    mainHeader: '#F8F8F8',
    blueTheme: '#0091FF',
    blueBgOp70: 'rgba(0, 145, 255,0.7)',
    blueBgOp15: 'rgba(0, 145, 255, 0.15)',
    bodyBg: '#DCE0E9',
    bgGrey: '#DCE0E9',
    popularCategoryBackground: '#FFFFFF',
    bgWhite: '#FFFFFF',
    bgWhiteOpacity09: 'rgba(255,255,255,0.9)',
    bgBlack: '#000000',
    bgForEditBtn: '#DCE0E9',
    borderInsideForEditBtn: 'rgba(0,0,0,0.15)',
    borderOutsideForEditBtn: 'rgba(0,0,0,0.3)',
    routerBg: '#0091FF',
    pageHeader: '#DCE0E9',
    pageBodyLeft: '#F8F8F8',
    pageBodyCenterAndRight: 'rgba(220,224,233, 0.3)',
    mobileBodyBg: '#F8F8F8',
    fontBlackHex: '#17161D',
    fontBlackOp100: 'rgba(23,22,29,1)',
    fontBlackOp70: 'rgba(23,22,29,0.7)',
    fontBlackOp50: 'rgba(23,22,29,0.5)',
    fontBlackOp50To100: 'rgba(23,22,29,0.5)',
    fontBlackOp15: 'rgba(23,22,29,0.15)',
    fontWhite: '#FFFFFF',
    fontBlue: '#0091FF',
    bgBlackOp15: 'rgba(23,22,29,0.15)',
    statusGreen: '#19D626',
    statusBlue: '#0091FF',
    statusRed: '#FF2320',
    statusOrange: '#FB9C1F',
    statusPurple: '#7270F2',
    statusNameBlack: '#17161D',
    statusLineBlack: '#000000',
    statusDefaultLine: 'rgba(23,22,29,0.12)',
    winWinOrange: '#FB9C1F',
    header: 'rgba(0,0,0,0.2)',
    inputBoxShadowColor: `0px 3px 6px rgba(0, 0, 0, 0.16)`,
    headerContentBackground: '#f8f8f8',
    contentBackground: '#DCE0E9',
    grayFontOp100: 'rgba(53, 47, 64, 1)',
    grayFontOp50: 'rgba(53, 47, 64, 0.5)',
    headerBlackFont: 'rgba(23,22,29,1)',
    columnBorder: 'rgba(112, 112, 112, 0.3)',
    mainBorderColor: 'rgba(23,22,29,0.21)',
    mainBoxShadow: `0px 15px 30px 0px rgba(0, 0, 0, 0.12)`,
    textareaBackground: 'white',
    headerBoxShadow: 'rgba(0,0,0,0.12)',
    blackBoxShadow: 'rgba(0,0,0,0.12)',
    disabledButtonBackground: 'rgba(0,0,0,0.3)',
    fileHeaderFontColor: '#352F40',
    fileBorderColor: 'rgba(23,22,29,0.21)',
    fileBackgroundColor: 'rgba(255,255,255,0.12)',
    noFileBackgroundColor: 'rgba(23,22,29,0.12)',
    noFileBorderColor: 'rgba(255,255,255,0.21)',
    noFileTitleFontColor: 'rgba(23,22,29,0.3)',
    noFileSvgColor: 'rgba(23,22,29,0.21)',
    trackPlaceholderSvgColor: 'rgba(23,22,29,0.12)',
    headerBorderColor: 'rgb(188,190,197)',
    trackFileBackgroundColor: 'rgba(255,255,255,0.12)',
    trackFileBorderColor: 'rgba(23,22,29,0.21)',
    trackDetailBackground: '#F0F1F3',
    trackDetailContentBackground: '#ffffff',
    singleConditionOptionBackground: 'rgba(47,47,104,0.15)',
    proTextBgGrey100: '#CED1DB',
    proTextBgGrey50: '#D4D8E2',
    transactionMainBackground: '#FFFFFF',
    transactionDetailBackground: '#f8f8f8',
    transactionBorderColor: 'rgba(112,112,112,0.3)',
    transactionButtonBorderColor: 'rgba(23,22,29,0.15)',
    transactionActiveButtonBorderColor: 'rgba(0,0,0,0.05)',
    transactionButtonBackground: 'transparent',
    transactionActiveButtonBackground: 'rgba(0,0,0,0.05)',
    transactionButtonFontColor: 'rgba(23,22,29,1)',
    transactionActiveButtonFontColor: '#0066ff',
    transactionGrayCircle: 'rgba(53,47,64,0.21)',
    transactionArrowButtonBackground: '#F0F1F3',
    transactionPhoneSvg: '#07020F',
    transactionHeaderBlackFont: '#17161D',
    transactionHeaderBlackFontOp30: 'rgba(23,22,29,0.3)',
    producersBackground: '#DDE0E7',
    producersBorderColor: 'rgba(255,255,255,0.15)',
    roleBlackFont: 'rgba(23,22,29,1)',
    myProductsBackground: 'white',
    myProductsBorder: 'rgba(23,22,29,0.18)',
    viewsCountColor: 'rgba(23,22,29,0.8)',
    tracksTopSectionBackground: '#DDE0E7',
    conditionDeclarationSvg: '#838187',
    downloadButtonFontColor: '#0091FF',
    productDetailConditionBg: '#CBD4E0',
    conditionNameColor: 'rgba(23,22,29,0.3)',
    inputMobileBg: '#EDEDED',
    inputMobileBorderColor: '#B3B3B3',
    singleArticleBackground: 'transparent',
    singleArticleHoverBackground: 'rgba(23,22,29,0.11)',
    mobileConditionDetailBackground: '#f5f5f5',

    mobileTrackDetailBackground: '#eeeef1',
    mobileTrackDetailTopBackground: '#f8f8f8',
    mobileAboutMeWrapperBackground:
      'linear-gradient(180deg,rgba(248, 248, 248, 1)80%,rgba(112, 112, 112, 0.13)100%)',
    mobileOfferDetailHeaderBorderColor: 'transparent',
    mobileAddNewProductFontColor: 'rgba(53,47,64,0.48)',
    mobileAddNewProductBorderColor: 'rgba(53,47,64,0.21)',
    mobileFilterMenuSvgColor: 'rgba(0,0,0,0.86)',
    mobileFilterMenuFontColor: 'rgba(0,0,0,0.7)',
    mobileOfferListingBackground: '#EDEFF4',

    mobileArtcileWrapperBackqround: '#ffffff',
    svgNavbarFillColor: 'rgba(23,22,29,0.3)',
    mobileTrackListHeaderBq: '#EDEFF4',
    mobileTrackListBodyBq: '#f8f8f8',
    mobileSearchBarBg: '#f5f5f5',
    mobileSearchBarTextColor: 'rgba(0,0,0,0.7)',
    mobileNavbarText: 'rgba(0,0,0,0.7)',
    mobileNavbarActiveItemBg: '#EDEDEE',
    mobileProBodyBg: '#f5f5f5',
    mobileProPlanNameColor: 'rgba(43,43,43,0.5)',
    mobileProTextOp100: 'rgba(43,43,43,1)',
    mobileProTextOp30: 'rgba(43,43,43,0.3)',
    mobileProBorderBottom: 'rgba(53,47,64,0.15)',
    mobileProBgWhiteOp50to15: ` rgba(255, 255, 255, 0.50)`,
    mobileOfferDetailTopBackground: '#F5F5F5',
    mobileConditionsContentBackground: 'white',
    transactionDetailNavbarBackground: '#FFFFFF',
    transactionDetailBackgroundColor: '#F5F5F5',
    transactionScrollbarBackground: '#CDCBCF',
    mobileTransactionTopBackground: '#F5F5F5',
    mobileTransactionTopBorder: '#DFDFDF',
    editCardEditBg: '#d8d8d8',
    cardSelectView: '#ededed',
  },

  breakpoints: {
    smallMobile: '400px',
    mobile: '1024px',
    tablet: '1200px',
  },
  breakpointsJs: {
    smallMobile: 400,
    mobile: 1024,
    tablet: 1200,
  },
};

export const dark = {
  name: 'dark-theme',
  colors: {
    mainHeader: '#000000',
    blueTheme: '#0091FF',
    blueBgOp70: 'rgba(0, 145, 255,0.7)',
    blueBgOp15: 'rgba(0, 145, 255, 0.15)',
    bodyBg: '#312F40',
    bgGrey: '#17161D',
    popularCategoryBackground: '#17161D',
    bgWhite: '#000000',
    bgWhiteOpacity09: 'rgba(0,0,0,0.9)',
    bgBlack: '#FFFFFF',
    bgForEditBtn: 'rgba(248,248,248,0.16)',
    borderInsideForEditBtn: 'rgba(248,248,248,0.15)',
    borderOutsideForEditBtn: 'rgba(248,248,248,0.3)',
    routerBg: '#0091FF',
    pageHeader: '#312F40',
    pageBodyLeft: '#000000',
    pageBodyCenterAndRight: '#100F14',
    mobileBodyBg: '#100F14',
    fontBlackHex: '#DCE0E9',
    fontBlackOp100: 'rgba(220,224,233,1)',
    fontBlackOp70: 'rgba(220,224,233,0.7)',
    fontBlackOp50: 'rgba(220,224,233,0.5)',
    fontBlackOp50To100: 'rgba(220,224,233,1)',
    fontBlackOp15: 'rgba(220,224,233,0.15)',
    fontWhite: '#000000',
    fontBlue: '#0091FF',
    bgBlackOp15: 'rgba(23,22,29,0.15)',
    statusGreen: '#19D626',
    statusBlue: '#0091FF',
    statusRed: '#FF2320',
    statusOrange: 'FB9C1F',
    statusNameBlack: '#F8F8F8',
    statusLineBlack: '#FFFFFF',
    statusDefaultLine: 'rgba(220,224,233,0.3)',
    winWinOrange: 'FB9C1F',
    header: 'hsl(0, 0%, 93%)',
    inputBoxShadowColor: `0px 3px 15px rgba(0, 145, 255, 0.15)`,
    headerContentBackground: '#000000',
    contentBackground: '#000000',
    grayFontOp100: 'white',
    grayFontOp50: 'white',
    headerBlackFont: 'white',
    columnBorder: 'rgba(112, 112, 112, 0.5)',
    mainBorderColor: 'rgba(220,224,233,0.21)',
    mainBoxShadow: `0px 15px 30px 0px rgba(0, 0, 0, 1)`,
    textareaBackground: 'transparent',
    headerBoxShadow: 'rgba(0,145,255,0.25)',
    blackBoxShadow: 'rgba(0,0,0,0.5)',
    disabledButtonBackground: 'rgba(220,224,233,0.12)',
    fileHeaderFontColor: '#DCE0E9',
    fileBorderColor: 'rgba(0,0,0,0.12)',
    fileBackgroundColor: 'rgba(255,255,255,0.03)',
    noFileBackgroundColor: 'rgba(23,22,29,0.21)',
    noFileBorderColor: 'rgba(255,255,255,0.12)',
    noFileTitleFontColor: 'rgba(220,224,233,0.3)',
    noFileSvgColor: 'rgba(255,255,255,0.12)',
    trackPlaceholderSvgColor: 'rgba(255,255,255,0.32)',
    headerBorderColor: 'rgb(80,79,88)',
    trackFileBackgroundColor: 'rgba(255,255,255,0.03)',
    trackFileBorderColor: 'rgba(255,255,255,0.12)',
    trackDetailBackground: '#100F14',
    trackDetailContentBackground: 'rgb(43,43,47)',
    singleConditionOptionBackground: '#383838',
    proTextBgGrey100: '#494757',
    proTextBgGrey50: '#3D3B4B',
    transactionMainBackground: '#17161D',
    transactionDetailBackground: '#17161D',
    transactionBorderColor: '#454450',
    transactionButtonBorderColor: 'rgba(220,224,233,0.3)',
    transactionActiveButtonBorderColor: 'rgba(255,255,255,0.12)',
    transactionButtonBackground: 'transparent',
    transactionActiveButtonBackground: 'rgba(255,255,255,0.12)',
    transactionButtonFontColor: 'rgba(220,224,233,0.3)',
    transactionActiveButtonFontColor: 'rgba(220,224,233,0.7)',
    transactionGrayCircle: 'rgba(220,224,233,0.3)',
    transactionArrowButtonBackground: '#323138',
    transactionPhoneSvg: '#DCE0E9',
    transactionHeaderBlackFont: '#F8F8F8',
    transactionHeaderBlackFontOp30: 'rgba(255,255,255,0.3)',
    producersBackground: '#434150',
    producersBorderColor: 'transparent',
    roleBlackFont: 'rgba(248,248,248,0.7)',
    myProductsBackground: 'black',
    myProductsBorder: 'rgba(248,248,248,0.18)',
    viewsCountColor: 'rgba(248,248,248,0.7)',
    tracksTopSectionBackground: '#434150',
    conditionDeclarationSvg: '#BDBAC2',
    downloadButtonFontColor: '#FFFFFF',
    productDetailConditionBg: '#292735',
    conditionNameColor: 'rgba(248,248,248,0.3)',
    inputMobileBg: '#19181C',
    inputMobileBorderColor: '#403F42',
    singleArticleBackground: 'transparent',
    singleArticleHoverBackground: 'rgba(255,255,255,0.11)',
    mobileConditionDetailBackground: 'transparent',

    mobileTrackDetailBackground: 'black',
    mobileTrackDetailTopBackground: 'black',
    mobileAboutMeWrapperBackground: 'transparent',
    mobileOfferDetailHeaderBorderColor: 'rgba(255,255,255,0.21)',
    mobileAddNewProductFontColor: 'rgba(245,245,245,0.56)',
    mobileAddNewProductBorderColor: 'rgba(245,245,245,0.21)',
    mobileFilterMenuSvgColor: 'rgba(245,245,245,0.86)',
    mobileFilterMenuFontColor: 'rgba(245,245,245,0.7)',
    mobileOfferListingBackground: '#504E5D',

    mobileArtcileWrapperBackqround: '#4b4b4b',
    svgNavbarFillColor: 'rgba(248,248,248,0.36)',
    mobileTrackListHeaderBq: '#504e5d',
    mobileTrackListBodyBq: '#17161d',
    mobileSearchBarBg: '#000000',
    mobileSearchBarTextColor: 'rgba(245,245,245,0.7)',
    mobileNavbarText: 'rgba(255,255,255,0.5)',
    mobileNavbarActiveItemBg: '#1a1a1a',
    mobileProBodyBg: '#312F40',
    mobileProPlanNameColor: 'rgba(255,255,255,0.5)',
    mobileProTextOp100: 'rgba(255,255,255,1)',
    mobileProTextOp30: 'rgba(255,255,255,0.3)',
    mobileProBorderBottom: 'rgba(220,224,233,0.15)',
    mobileProBgWhiteOp50to15: ` rgba(255, 255, 255, 0.15)`,
    mobileOfferDetailTopBackground: '#2B2B2B',
    mobileConditionsContentBackground: 'black',
    transactionDetailNavbarBackground: '#262529',
    transactionDetailBackgroundColor: '#17161D',
    transactionScrollbarBackground: '#39383E',
    mobileTransactionTopBackground: '#000000',
    mobileTransactionTopBorder: '#242424',
    editCardEditBg: '#2a2a2a',
    cardSelectView: 'transparent',
  },
  breakpoints: {
    smallMobile: '400px',
    mobile: '1024px',
    tablet: '1200px',
  },
  breakpointsJs: {
    smallMobile: 400,
    mobile: 1024,
    tablet: 1200,
  },
};
