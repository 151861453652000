import React, {useContext, useEffect, useRef, useState} from 'react';
import {Block, BlockFile} from '../../interface';

import {hexToRgb} from '../..';
import {
  BorderImgWrapper,
  BorderImgWrapperSecond,
  BotTextWrapper,
  ImgAndTextBlockContentWrapper,
  ImgAndTextBlockWrapper,
  LeftImgContent,
  MaskImgContent,
  MaskImgContentThree,
  MaskImgContentTwo,
  RightTextContent,
  TopTextWrapper,
} from './styles';
import useAlert from 'hooks/useAlert';
import api from 'services/axiosConfig';
import {useCookies} from 'react-cookie';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import styled from 'styled-components';

const Textarea = styled.textarea`
  width: 100%;
  max-height: 100px;
  overflow: hidden;
  resize: none;
`;

export interface UpdateParams {
  blockFileIds: string[];
  blockFiles: BlockFile[];
  base64Files: string[];
  texts: {TextOne: string; TextTwo: string};
}

interface Props {
  theme: string;
  block: Block;
  isEditing: boolean;
  changeText: (param: UpdateParams) => void;
  mobileOverview: boolean;
}

export const ImgAndText: React.FC<Props> = ({
  theme,
  block,
  isEditing,
  changeText,
  mobileOverview,
}) => {
  const {
    Format,
    HorizontalFormat,
    VerticalFormat,
    Interline,
    BlockOpacityIn0To100Ratio,
    BackgroundColorHex,
    BlockFiles,
    FontColorHex,
    TextOne,
    TextTwo,
    MaskType,
  } = block;

  const initImages = BlockFiles
    ? BlockFiles
    : [{BlockId: '', CreationDate: '', FileName: '', FilePath: '', Id: ''}];

  const [images, setImages] = useState<BlockFile[]>(initImages);
  const [imgBase, setImgBase] = useState([]);
  const [imgType, setimageType] = useState<number>(1);

  const [texts, setTexts] = useState({
    TextOne: TextOne,
    TextTwo: TextTwo,
  });

  const checkTitleLengthTwo = () => {
    const calculateEffectiveLength = (text) => {
      // Oblicz długość tekstu, uwzględniając każdy enter jako 100 znaków
      const enterCount = (text.match(/\n/g) || []).length;
      return text.length + enterCount * 79; // Każdy enter liczy się jako 100 znaków
    };

    const effectiveLength = calculateEffectiveLength(texts.TextTwo);

    if (effectiveLength > 850) {
      let truncatedText = texts.TextTwo;
      let charCount = 0;

      // Truncate the text by iterating through each character
      for (let i = 0; i < texts.TextTwo.length; i++) {
        const char = texts.TextTwo[i];
        if (char === '\n') {
          charCount += 80;
        } else {
          charCount++;
        }

        if (charCount > 850) {
          truncatedText = texts.TextTwo.slice(0, i);
          break;
        }
      }

      setTexts((text) => ({...text, TextTwo: truncatedText}));
      changeText({
        blockFileIds: [
          ...images.map((img) => img.Id).filter((img) => img !== 'xxx'),
        ],
        base64Files: [...imgBase],
        blockFiles: [...images],
        texts: {...texts, TextTwo: truncatedText},
      });
      return Alert('Tekst ma limit 850 znaków', 'Tekst został skrócony.');
    }
  };

  const checkTitleLength = () => {
    if (texts.TextOne.length > 28) {
      setTexts((text) => ({...text, TextOne: texts.TextOne.slice(0, 28)}));
      changeText({
        blockFileIds: [
          ...images.map((img) => img.Id).filter((img) => img !== 'xxx'),
        ],
        base64Files: [...imgBase],
        blockFiles: [...images],
        texts: {...texts, TextOne: texts.TextOne.slice(0, 28)},
      });
      return Alert('Tekst ma limit 28 znaków', 'Tekst ma limit 28 znaków');
    }
  };

  const handleAboutMeChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    key: number,
  ) => {
    if (key === 1) {
      setTexts((text) => ({...text, TextOne: e.target.value}));
      changeText({
        blockFileIds: [
          ...images.map((img) => img.Id).filter((img) => img !== 'xxx'),
        ],
        base64Files: [...imgBase],
        blockFiles: [...images],
        texts: {...texts, TextOne: e.target.value},
      });
    } else if (key === 2) {
      console.log(e.target.value.length);
      setTexts((text) => ({...text, TextTwo: e.target.value}));
      changeText({
        blockFileIds: [
          ...images.map((img) => img.Id).filter((img) => img !== 'xxx'),
        ],
        base64Files: [...imgBase],
        blockFiles: [...images],
        texts: {...texts, TextTwo: e.target.value},
      });
    }
  };

  const [cookies, setCookie] = useCookies(['token']);

  const {decodedToken} = useContext(ProducerDataContext);

  const imgRef = useRef<HTMLInputElement>(document.createElement('input'));
  const textOneRef = useRef(null);
  const textTwoRef = useRef(null);

  const {Alert} = useAlert();

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getImageFile = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();

      //

      const base64 = await toBase64(e.target.files[0]);

      const newBlockFile = {
        BlockId: block.Id,
        CreationDate: '',
        FileName: base64.toString().split(',')[1] as string,
        FilePath: URL.createObjectURL(e.target.files[0]),
        Id: 'xxx',
      };

      // updateImgGallery({
      //   blockFileIds: [
      //     ...images.map((img) => img.Id).filter((img) => img !== 'xxx'),
      //   ],
      //   blockFiles: [...images, newBlockFile],
      //   base64Files: [...imgBase, base64.toString().split(',')[1]],
      //   // base64Files: [base64.toString().split(',')[1]],
      // });
      changeText({
        texts: texts,
        blockFileIds: [
          ...images.map((img) => img.Id).filter((img) => img !== 'xxx'),
        ],
        blockFiles: [...images, newBlockFile],
        base64Files: [...imgBase, base64.toString().split(',')[1]],
      });
      setImages((prev) => [...prev, newBlockFile]);
      setImgBase((prev) => [...prev, base64.toString().split(',')[1]]);

      e.target.value = '';

      Alert(`Dodano plik ${e.target.files[0].name}`, 'Komunikat');
    }
  };

  const deleteImg = async (id: string, base: string) => {
    if (id === '') return;
    if (id === 'xxx') {
      setImages((prev) => prev.filter((item) => item.Id !== id));
      const imgAfterDelete = images.filter((img) => img.Id !== id);
      setImgBase((prev) => prev.filter((item) => item !== base));
      //   updateImgGallery({
      //     blockFileIds: imgAfterDelete.map((img) => img.Id),
      //     blockFiles: imgAfterDelete,
      //     base64Files: [...imgBase].filter((item) => item !== base),
      //   });
      return;
    }

    try {
      const response = await api.delete(
        `Producer/${decodedToken.ProducerId}/blocks/${block.Id}/files/${id}`,
        {
          headers: {Authorization: `Bearer ${cookies.token}`},
        },
      );

      setImages((prev) => prev.filter((item) => item.Id !== id));
      const imgAfterDelete = images.filter((img) => img.Id !== id);
      setImgBase((prev) => prev.filter((item) => item !== base));
      //   updateImgGallery({
      //     blockFileIds: imgAfterDelete.map((img) => img.Id),
      //     blockFiles: imgAfterDelete,
      //     base64Files: [...imgBase],
      //   });
    } catch (error) {
      alert('coś poszło nie tak');
    }
  };

  useEffect(() => {
    const adjustTextareaHeight = () => {
      const textarea = textOneRef.current;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    adjustTextareaHeight();
  }, [texts.TextOne]);

  useEffect(() => {
    const adjustTextareaHeight = () => {
      const textarea = textTwoRef.current;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

    adjustTextareaHeight();
  }, [texts.TextTwo]);

  const [text, setText] = useState('');

  const handleChange = (e) => {
    const textarea = e.target;
    const lines = textarea.value.split('\n');
    const trimmedText = trimTextToFit(lines);

    if (trimmedText !== textarea.value) {
      alert('Tekst jest za długi, został przycięty');
    }

    setText(trimmedText);
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const textarea = e.target;
    const newText = textarea.value + pastedText;
    const lines = newText.split('\n');
    const trimmedText = trimTextToFit(lines);

    if (trimmedText !== newText) {
      alert('Tekst jest za długi, został przycięty');
    }

    setText(trimmedText);
  };

  const trimTextToFit = (lines) => {
    const maxLines = 5; // Określ maksymalną liczbę linii
    const maxHeight = 100; // Maksymalna wysokość tekstarea
    let finalText = '';
    let lineCount = 0;

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      lineCount += 1;

      if (lineCount > maxLines) {
        break;
      }

      finalText += line;

      if (i < lines.length - 1) {
        finalText += '\n';
      }

      const tempTextArea = document.createElement('textarea');
      tempTextArea.style.visibility = 'hidden';
      tempTextArea.style.height = 'auto';
      tempTextArea.style.width = '100%';
      tempTextArea.style.position = 'absolute';
      tempTextArea.value = finalText;
      document.body.appendChild(tempTextArea);

      const isOverflowing = tempTextArea.scrollHeight > maxHeight;
      document.body.removeChild(tempTextArea);

      if (isOverflowing) {
        // Przycinanie tekstu, aby pasował do wysokości
        finalText = finalText.slice(0, finalText.length - line.length - 1);
        break;
      }
    }

    return finalText;
  };

  return (
    <ImgAndTextBlockWrapper
      bgColor={hexToRgb(BackgroundColorHex)}
      backgroundAlpha={BlockOpacityIn0To100Ratio}
    >
      <ImgAndTextBlockContentWrapper isMobile={mobileOverview}>
        {MaskType === 0 ? (
          <LeftImgContent isMobile={mobileOverview}>
            {images.length > 0 ? (
              <img src={images[0].FilePath} />
            ) : (
              isEditing && (
                <button onClick={() => imgRef.current.click()}>dodaj</button>
              )
            )}
            {isEditing && images.length > 0 ? (
              <button
                onClick={() => {
                  deleteImg(images[0].Id, images[0].FileName);
                }}
              >
                usuń
              </button>
            ) : null}
          </LeftImgContent>
        ) : null}
        {MaskType === 1 ? (
          <MaskImgContent
            fontColor={hexToRgb(FontColorHex)}
            isMobile={mobileOverview}
          >
            <BorderImgWrapper
              fontColor={hexToRgb(FontColorHex)}
              isMobile={mobileOverview}
            >
              <BorderImgWrapperSecond
                fontColor={hexToRgb(FontColorHex)}
                isMobile={mobileOverview}
              >
                {images.length > 0 ? (
                  <img src={images[0].FilePath} />
                ) : (
                  isEditing && (
                    <button onClick={() => imgRef.current.click()}>
                      dodaj
                    </button>
                  )
                )}
                {isEditing && images.length > 0 ? (
                  <button
                    onClick={() => {
                      deleteImg(images[0].Id, images[0].FileName);
                    }}
                  >
                    usuń
                  </button>
                ) : null}
              </BorderImgWrapperSecond>
            </BorderImgWrapper>
          </MaskImgContent>
        ) : null}
        {MaskType === 2 ? (
          <MaskImgContentTwo
            fontColor={hexToRgb(FontColorHex)}
            isMobile={mobileOverview}
          >
            {images.length > 0 ? (
              <img src={images[0].FilePath} />
            ) : (
              isEditing && (
                <button onClick={() => imgRef.current.click()}>dodaj</button>
              )
            )}
            {isEditing && images.length > 0 ? (
              <button
                onClick={() => {
                  deleteImg(images[0].Id, images[0].FileName);
                }}
              >
                usuń
              </button>
            ) : null}
          </MaskImgContentTwo>
        ) : null}
        {MaskType === 3 ? (
          <MaskImgContentThree
            fontColor={hexToRgb(FontColorHex)}
            isMobile={mobileOverview}
          >
            {images.length > 0 ? (
              <img src={images[0].FilePath} />
            ) : (
              isEditing && (
                <button onClick={() => imgRef.current.click()}>dodaj</button>
              )
            )}
            {isEditing && images.length > 0 ? (
              <button
                onClick={() => {
                  deleteImg(images[0].Id, images[0].FileName);
                }}
              >
                usuń
              </button>
            ) : null}
          </MaskImgContentThree>
        ) : null}
        {/* <LeftImgContent isMobile={mobileOverview}>
          {images.length > 0 ? (
            <img src={images[0].FilePath} />
          ) : (
            isEditing && (
              <button onClick={() => imgRef.current.click()}>dodaj</button>
            )
          )}
          {isEditing && images.length > 0 ? (
            <button
              onClick={() => {
                deleteImg(images[0].Id, images[0].FileName);
              }}
            >
              usuń
            </button>
          ) : null}
        </LeftImgContent> */}
        <RightTextContent
          isMobile={mobileOverview}
          style={
            VerticalFormat === 1
              ? {justifyContent: 'flex-start'}
              : VerticalFormat === 4
              ? {justifyContent: 'flex-end'}
              : VerticalFormat === 8
              ? {justifyContent: 'justify'}
              : {justifyContent: 'center'}
          }
        >
          {' '}
          <TopTextWrapper
            mobileOverview={mobileOverview}
            fontColor={hexToRgb(FontColorHex)}
            interline={Interline}
          >
            {' '}
            {isEditing ? (
              <textarea
                style={
                  HorizontalFormat === 1
                    ? {textAlign: 'center'}
                    : HorizontalFormat === 4
                    ? {textAlign: 'right'}
                    : HorizontalFormat === 8
                    ? {textAlign: 'justify'}
                    : {textAlign: 'left'}
                }
                value={texts.TextOne}
                ref={textOneRef}
                onChange={(e) => handleAboutMeChange(e, 1)}
                onKeyUp={(e) => checkTitleLength()}
                placeholder="Napisz coś o sobie..."
              />
            ) : (
              <textarea
                style={
                  HorizontalFormat === 1
                    ? {textAlign: 'center'}
                    : HorizontalFormat === 4
                    ? {textAlign: 'right'}
                    : HorizontalFormat === 8
                    ? {textAlign: 'justify'}
                    : {textAlign: 'left'}
                }
                ref={textOneRef}
                value={texts.TextOne}
                disabled={true}
              >
                {/* {aboutMe.TextOne} */}
              </textarea>
            )}
          </TopTextWrapper>
          <BotTextWrapper
            mobileOverview={mobileOverview}
            fontColor={hexToRgb(FontColorHex)}
            interline={Interline}
          >
            {' '}
            {isEditing ? (
              <textarea
                style={
                  HorizontalFormat === 1
                    ? {textAlign: 'center'}
                    : HorizontalFormat === 4
                    ? {textAlign: 'right'}
                    : HorizontalFormat === 8
                    ? {textAlign: 'justify'}
                    : {textAlign: 'left'}
                }
                value={texts.TextTwo}
                ref={textTwoRef}
                onChange={(e) => handleAboutMeChange(e, 2)}
                onKeyUp={() => checkTitleLengthTwo()}
                // onKeyUp={(e) => checkLength(e, 1)}
                placeholder="Napisz coś o sobie..."
              />
            ) : (
              <textarea
                style={
                  HorizontalFormat === 1
                    ? {textAlign: 'center'}
                    : HorizontalFormat === 4
                    ? {textAlign: 'right'}
                    : HorizontalFormat === 8
                    ? {textAlign: 'justify'}
                    : {textAlign: 'left'}
                }
                ref={textTwoRef}
                value={texts.TextTwo}
                disabled={true}
              >
                {/* {aboutMe.TextOne} */}
              </textarea>
            )}
          </BotTextWrapper>
        </RightTextContent>
      </ImgAndTextBlockContentWrapper>
      <input
        type="file"
        onChange={getImageFile}
        accept=".jpeq,.jpg,.png"
        hidden
        ref={imgRef}
      />
    </ImgAndTextBlockWrapper>
  );
};
