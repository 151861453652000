import React, {useState, useEffect, useContext} from 'react';
import styled, {keyframes} from 'styled-components';
import api from 'services/axiosConfig';
import {useNavigate} from 'react-router-dom';

import {
  Main,
  MainWrapper,
  PageHeaderContent,
  PageHeaderHeader,
  PageHeaderHeaderWrapper,
  PageHeaderWrapper,
  MobileSliderWrapper,
  FirstSectionWrapper,
  Content,
  SecondSectionWrapper,
  SectionWrapper,
  ThirdSectionWrapper,
  MobileTutorialButton,
  FooterNavbar,
  FooterBanner,
  FooterWrapper,
} from './styles';

import BottomBannerImage from './HomeImages/bottom_banner.png';

import {HeaderSlider} from './HeaderSlider';
import {PopularCategories} from './PopularCategories';
import {Articles} from './Articles';
import {WhiteWinWinSvg} from 'components/SvgComponents';

import Image1 from './HomeImages/image_1.png';
import MobileImage1 from './HomeImages/mobile_image_2_2.png';
import HomeFooterImage from './HomeImages/bottom_banner.png';
import MobileHomeFooterImage from './HomeImages/home_footer2.png';
import Image2 from './HomeImages/image_2_5.jpg';
import MobileImage2 from './HomeImages/mobile_image_2_5.jpg';
import Image3 from './HomeImages/image_3.png';
import {PopularProducts} from './PopularProducts';
import {BigArticle} from './BigArticle';
import {PopularTracks} from './PopularTracks';
import {Navigate} from 'react-router-dom';
import parallaxImage from 'pages/Home/HomeImages/home_footer.jpg';
import desktopTxt from 'pages/Home/HomeImages/1440desktoptxt.png';
import mobileTxt from 'pages/Home/HomeImages/420mobilkatxt.png';
import {Parallax} from 'react-parallax';

export interface RecommendedArticle {
  Id: string;
  Title: string;
  Description: string;
  PicturePath: string;
  UrlPath: string;
  Category: string;
}

export interface RecommendedTrack {
  OfferId: string;
  ProductId: string;
  ProductName: string;
  Name: string;
  Price: number;
  PicturePath: string;
  IsWinWin: boolean;
  Categories: string[];
  Attributes: null;
  SoundPath: string;
}

export interface RecommendedProducer {
  Id: string;
  ProducerName: string;
  Description: string;
  PicturePath: string;
  Location: string;
  Roles: string[];
}

export interface RecommendedProducts {
  Category: string | null;
  ProductType: number | null;
  Title: string;
  Products: Product[];
}

export interface Product {
  Categories: string[];
  IsWinWin: boolean;
  Name: string;
  OfferId: string;
  PicturePath: string;
  Position: number;
  Price: number;
  ProductId: string;
  ProductName: string;
}

export interface Article {
  Id: string;
  Title: string;
  Description: string;
  PicturePath: string;
  UrlPath: string;
  Category: string;
}

export interface RecommendedProducers {
  Producers: RecommendedProducer[];
  Article: Article;
}

export interface HomeRecommendations {
  RecommendedArticles: RecommendedArticle[];
  RecommendedTracks: RecommendedTrack[];
  RecommendedProducers: RecommendedProducers;
  RecommendedProducts: RecommendedProducts;
}

const Home = () => {
  const [homeRecommendations, setHomeRecommendations] =
    useState<HomeRecommendations | null>(null);

  const navigate = useNavigate();

  const getHomeRecommendations = async () => {
    try {
      const response = await api.get(`Home`);
      setHomeRecommendations(response.data.Response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getHomeRecommendations();
  }, []);

  return (
    <>
      <MainWrapper>
        <BackgroundColorWrapper>
          <Main>
            <PageHeaderWrapper>
              <PageHeaderHeaderWrapper>
                <PageHeaderHeader>
                  <p>MUGO STUDIO</p>
                </PageHeaderHeader>
              </PageHeaderHeaderWrapper>
              <PageHeaderContent>
                <HeaderSlider />
              </PageHeaderContent>
            </PageHeaderWrapper>
            <Content>
              <MobileSliderWrapper>
                <HeaderSlider />
              </MobileSliderWrapper>
              <PopularCategories />
              <BigArticle
                RecommendedProducers={
                  homeRecommendations?.RecommendedProducers.Producers
                }
                Article={homeRecommendations?.RecommendedProducers.Article}
              />
              <Articles
                RecommendedArticles={homeRecommendations?.RecommendedArticles}
              />
              <PopularTracks
                RecommendedTracks={homeRecommendations?.RecommendedTracks}
              />
              <FirstSectionWrapper>
                <h1>Nowe pomysły? MUGO STUDIO</h1>
                <a
                  // href="/faq/default"
                  href="https://www.youtube.com/live/_WwqUJR_taY"
                >
                  Zobacz więcej
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6.438"
                    height="11"
                    viewBox="0 0 6.438 11"
                  >
                    <g
                      id="Arrow_Top_Bar_Accent"
                      data-name="Arrow – Top Bar / Accent"
                      transform="translate(0)"
                      opacity="0.92"
                    >
                      <path
                        id="Arrow"
                        d="M1.321.224l4.961,4.9a.523.523,0,0,1,0,.747l-4.961,4.9a.781.781,0,0,1-1.095,0,.759.759,0,0,1,0-1.082L4.47,5.5.226,1.306a.76.76,0,0,1,0-1.082.781.781,0,0,1,1.095,0"
                        transform="translate(0 0)"
                        fill="#0091ff"
                      />
                    </g>
                  </svg>
                </a>
                <img
                  onClick={() =>
                    (window.location.href = 'https://youtu.be/n1H3vsPQl9U')
                  }
                  src={Image1}
                />
                <img
                  onClick={() =>
                    (window.location.href = 'https://youtu.be/n1H3vsPQl9U')
                  }
                  src={Image1}
                />
              </FirstSectionWrapper>
              <PopularProducts
                RecommendedProducts={homeRecommendations?.RecommendedProducts}
              />
              <SecondSectionWrapper
                onClick={() =>
                  navigate('/czytelnia/Poradnik/win-win-to-sie-oplaca')
                }
              >
                <h1>Czym jest WIN-WIN? Poznaj korzyści!</h1>
                <WhiteWinWinSvg />
                <img src={Image2} />
                <img src={MobileImage2} />
              </SecondSectionWrapper>
              {/* <ThirdSectionWrapper>
            <div>
              <h1>Jak zacząć? Zobacz WORKFLOW</h1>
              <h1>
                Jak zacząć?
                <br /> Zobacz WORKFLOW
              </h1>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don t look even
                slightly believable. If you are
              </p>
              <div>
                Obejrzyj tutorial
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.438"
                  height="11"
                  viewBox="0 0 6.438 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(0)"
                    opacity="0.92"
                  >
                    <path
                      id="Arrow"
                      d="M1.321.224l4.961,4.9a.523.523,0,0,1,0,.747l-4.961,4.9a.781.781,0,0,1-1.095,0,.759.759,0,0,1,0-1.082L4.47,5.5.226,1.306a.76.76,0,0,1,0-1.082.781.781,0,0,1,1.095,0"
                      transform="translate(0 0)"
                      fill="#17161d"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div>
              <img src={Image3} />
              <MobileTutorialButton>
                Obejrzyj tutorial
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6.438"
                  height="11"
                  viewBox="0 0 6.438 11"
                >
                  <g
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    transform="translate(0)"
                    opacity="0.92"
                  >
                    <path
                      id="Arrow"
                      d="M1.321.224l4.961,4.9a.523.523,0,0,1,0,.747l-4.961,4.9a.781.781,0,0,1-1.095,0,.759.759,0,0,1,0-1.082L4.47,5.5.226,1.306a.76.76,0,0,1,0-1.082.781.781,0,0,1,1.095,0"
                      transform="translate(0 0)"
                      fill="#17161d"
                    />
                  </g>
                </svg>
              </MobileTutorialButton>
            </div>
          </ThirdSectionWrapper> */}
            </Content>
          </Main>
        </BackgroundColorWrapper>
        {/* <img src={HomeFooterImage} /> */}
        {/* <img src={MobileHomeFooterImage} /> */}
        <FooterWrapper>
          <FooterNavbar>
            <div>
              <p>© 2024 MUGO</p>
              <div>
                <a
                  target="__blank"
                  href="https://fmmtp.pl/dokumentymugo/Polityka%20prywatno%C5%9Bci.pdf"
                >
                  Polityka prywatności
                </a>
                <a
                  target="__blank"
                  href="https://fmmtp.pl/mugostudio/Regulamin%20Serwisu_Mugo_Studio_17.09.2024.pdf"
                >
                  Regulamin
                </a>
                <a
                  target="__blank"
                  href="https://fmmtp.pl/mugostudio/formularz_zgloszenia_tresci_nielegalnych_18_09_2024.docx"
                >
                  Zgłoś naruszenie
                </a>
                <a href="/faq/default">FAQ</a>
              </div>
            </div>
          </FooterNavbar>
          <FooterBanner href="https://mugo.pl/" />
        </FooterWrapper>
      </MainWrapper>
      <ParallaxContainer />
    </>
  );
};

export default Home;

export const ParallaxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
`;

export const DesktopParallaxMain = styled.div`
  width: 100%;
  /* height: 100%; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const MobileParallaxMain = styled.div`
  width: 100%;
  height: 100%;
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: unset;
  }
`;

export const BackgroundColorWrapper = styled.div`
  width: 100%;
  /* height: 100%; */
  height: 100%;
  background: ${({theme}) => theme.colors.bodyBg};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Test = styled.div`
  width: 100%;
  /* height: 100%; */
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: -5;
  user-select: all;
  pointer-events: all;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-bottom: 57px;
  }
`;

const ParallaxContainer = () => (
  <Test>
    <DesktopParallaxMain>
      <Parallax
        bgImage={require('pages/Home/HomeImages/1440desktopbg.png')}
        bgImageAlt="image"
      >
        <ParallaxWrapper>
          <img src={desktopTxt} />
        </ParallaxWrapper>
      </Parallax>
    </DesktopParallaxMain>
    <MobileParallaxMain>
      <Parallax
        bgImage={require('pages/Home/HomeImages/420mobilkabg.png')}
        bgImageAlt="image"
      >
        <ParallaxWrapper>
          <img src={mobileTxt} />
        </ParallaxWrapper>
      </Parallax>
    </MobileParallaxMain>
  </Test>
);
