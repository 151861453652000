import styled from 'styled-components';

export const ImgAndTextBlockWrapper = styled.div<{
  bgColor: string;
  backgroundAlpha: number;
}>`
  width: 100%;
  height: 100%;
  background: ${({bgColor, backgroundAlpha}) =>
    `rgb(${bgColor},${backgroundAlpha})`};
`;

export const ImgAndTextBlockContentWrapper = styled.div<{isMobile: boolean}>`
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction: row; */
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  /* gap: 15px; */
  /* gap: ${({isMobile}) => (isMobile ? '5px' : '15px')}; */
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 5px;
  }
`;

export const LeftImgContent = styled.div<{isMobile: boolean}>`
  position: relative;
  /* height: 336px; */
  height: ${({isMobile}) => (isMobile ? 'auto' : '336px')};
  width: ${({isMobile}) => (isMobile ? 'auto' : '336px')};
  min-width: 336px;

  & > img {
    /* height: 100%; */
    height: ${({isMobile}) => (isMobile ? 'auto' : '100%')};
    width: ${({isMobile}) => (isMobile ? '100%' : 'auto')};
    aspect-ratio: 1 / 1;
  }
  & > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background: #f8f8f8;
    font-size: 13px;
    width: 60px;
    height: 24px;
    border-radius: 13px;
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    height: auto;
    width: auto;
    & > img {
      height: auto;
      width: 100%;
    }
  }
`;

export const MaskImgContent = styled.div<{
  isMobile: boolean;
  fontColor: string;
}>`
  position: relative;
  /* height: 336px; */
  height: ${({isMobile}) => (isMobile ? 'auto' : '336px')};
  width: ${({isMobile}) => (isMobile ? 'auto' : '336px')};
  min-width: 336px;

  display: flex;
  justify-content: right;
  align-items: center;
  @media (max-width: 1024px) {
    min-height: 336px;
    min-width: auto;
    height: 336px;
    width: 100%;
    justify-content: center;
  }
  @media (max-width: 350px) {
  }
`;

export const MaskImgContentTwo = styled.div<{
  isMobile: boolean;
  fontColor: string;
}>`
  position: relative;
  /* height: 336px; */
  height: ${({isMobile}) => (isMobile ? 'auto' : '336px')};
  width: ${({isMobile}) => (isMobile ? 'auto' : '336px')};
  min-width: 336px;

  display: flex;
  justify-content: right;
  align-items: center;
  & > img {
    width: 80%;
    height: 80%;
    border-radius: 100%;
  }

  & > button {
    position: absolute;
    top: 50%;
    left: 61%;
    transform: translate(-50%, -50%);
    border: none;
    background: #f8f8f8;
    font-size: 13px;
    width: 60px;
    height: 24px;
    border-radius: 13px;
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    min-width: 100%;
    min-height: 336px;
    height: 336px;
    width: 100%;
    justify-content: center;
    & > img {
      width: 300px;
      height: 300px;
      border-radius: 100%;
    }
  }
  @media (max-width: 350px) {
    min-height: 280px;
    height: 280px;
    min-width: auto;
    & > img {
      width: 270px;
      height: 270px;
      border-radius: 100%;
    }
  }
`;

export const MaskImgContentThree = styled.div<{
  isMobile: boolean;
  fontColor: string;
}>`
  position: relative;
  /* height: 336px; */
  height: ${({isMobile}) => (isMobile ? 'auto' : '336px')};
  width: ${({isMobile}) => (isMobile ? 'auto' : '336px')};
  min-width: 336px;

  clip-path: polygon(0 0, -20% 0, 100% 50%, -10% 100%, 0 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%);
  }

  & > button {
    position: absolute;
    top: 50%;
    left: 61%;
    transform: translate(-50%, -50%);
    border: none;
    background: #f8f8f8;
    font-size: 13px;
    width: 60px;
    height: 24px;
    border-radius: 13px;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    min-width: 336px;
    min-height: 336px;
    height: 336px;
    width: 336px;
  }
  @media (max-width: 350px) {
    min-width: 280px;
    min-height: 280px;
    height: 280px;
    width: 280px;
  }
`;

export const BorderImgWrapper = styled.div<{
  isMobile: boolean;
  fontColor: string;
}>`
  position: relative;
  /* height: 336px; */
  height: 80%;
  width: 80%;
  padding: 20px;
  border: ${({fontColor}) => `2px solid rgb(${fontColor})`};
  border-radius: 100%;

  @media (max-width: 1024px) {
    min-width: 300px;
    min-height: 300px;
    height: 300px;
    width: 300px;
  }
  @media (max-width: 350px) {
    min-width: 280px;
    min-height: 280px;
    height: 280px;
    width: 280px;
  }
`;

export const BorderImgWrapperSecond = styled.div<{
  isMobile: boolean;
  fontColor: string;
}>`
  position: relative;
  /* height: 336px; */
  height: 100%;
  width: 100%;
  padding: 20px;
  border: ${({fontColor}) => `5px solid rgb(${fontColor})`};
  border-radius: 100%;

  & > img {
    /* height: 100%; */
    height: 100%;
    width: 100%;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    border: ${({fontColor}) => `2px solid rgb(${fontColor})`};
  }

  & > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background: #f8f8f8;
    font-size: 13px;
    width: 60px;
    height: 24px;
    border-radius: 13px;
    cursor: pointer;
  }
`;

export const RightTextContent = styled.div<{isMobile: boolean}>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({isMobile}) => (isMobile ? '16px' : '0px')};
  padding-top: ${({isMobile}) => (isMobile ? '5px' : '0px')};
  margin: ${({isMobile}) => (isMobile ? '0px' : '0px 58px')};
  /* margin-top: ${({isMobile}) => (isMobile ? '0px' : '10px')}; */
  @media (max-width: 1024px) {
    padding: 16px;
    margin: 0px;
  }
`;
export const TopTextWrapper = styled.div<{
  fontColor: string;

  mobileOverview: boolean;
  interline: number;
}>`
  height: auto;
  width: 100%;
  & > textarea,
  textarea:disabled {
    display: block;

    overflow: hidden;
    width: 100%;

    /* height: auto; */

    background: transparent;
    border: none;
    text-align: left;
    outline: none;
    /* font-size: 18px; */
    /* font-size: 28px; */
    font-size: ${({mobileOverview}) => (mobileOverview ? '23px' : '28px')};
    font-weight: bold;
    /* line-height: 21px; */
    line-height: ${({mobileOverview, interline}) =>
      interline === 0 ? '32px' : mobileOverview ? '26px' : `${interline}px`};
    letter-spacing: -0.05px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    resize: none;
    opacity: 1;
  }

  @media (max-width: 1024px) {
    & > textarea,
    textarea:disabled {
      font-size: 23px;
      line-height: 26px;
    }
  }
`;

export const BotTextWrapper = styled.div<{
  fontColor: string;

  mobileOverview: boolean;
  interline: number;
}>`
  height: auto;
  width: 100%;
  position: relative;
  overflow: hidden;
  & > textarea,
  textarea:disabled {
    display: block;

    overflow: hidden;
    width: 100%;

    /* height: auto; */

    background: transparent;
    border: none;
    text-align: left;
    outline: none;
    font-size: 18px;
    /* line-height: 21px; */
    line-height: ${({mobileOverview, interline}) =>
      interline === 0 ? '21px' : mobileOverview ? '21px' : `${interline}px`};
    letter-spacing: -0.05px;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    resize: none;
    opacity: 1;
  }
  @media (max-width: 1024px) {
    & > textarea,
    textarea:disabled {
      font-size: 18px;
      line-height: 21px;
    }
  }
`;
