import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import {useCookies} from 'react-cookie';
import _ from 'lodash';

import {
  MenuMain,
  CloseIsLoginPageOpenWrapper,
  CloseIsLoginPageOpen,
  MobileIconsWrapper,
  MenuWrapper,
  InputWithSearchResultsWrapper,
  RightSectionAddressAndTitleWriteMode,
  SearchResultWrapper,
  SingleSearchResultWrapper,
  RightSectionMessageContent,
  ContentTitle,
} from 'pages/MyStudio/Messages/newMessage/styles';
import useAlert from 'hooks/useAlert';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';
import api from 'services/axiosConfig';
import {ProducerDataContext} from 'contexts/ProducerDataContext';

interface ProducerData {
  CardId: string;
  ProducerFirstName: string;
  ProducerId: string;
  ProducerLastName: string;
  ProducerPhoto: string;
}

interface Props {
  isMobileNewMessageViewOpen: boolean;
  handleIsMobileNewMessageViewOpen: (value: boolean) => void;
  producerIdFromOffer: string;
  productNameFromOffer: string;
  producerNameFromOffer: string;
}

const SendMessageBottomSheet: React.FC<Props> = ({
  isMobileNewMessageViewOpen,
  handleIsMobileNewMessageViewOpen,
  producerIdFromOffer,
  producerNameFromOffer,
  productNameFromOffer,
}) => {
  const [cookies, setCookie] = useCookies(['token']);
  const [content, setContent] = useState<string>('');
  const [topic, setTopic] = useState<string>('');
  const [receiver, setReceiver] = useState<string>('');
  const [receiverId, setReceiverId] = useState<string>('');
  const [isFocused, toggleFocused] = useState<boolean>(false);
  const [target, setTarget] = useState<number | string>(0);
  const [producersList, setProducersList] = useState<ProducerData[]>([]);

  const mobileNewMessageViewWindowRef = useRef<HTMLDivElement | null>(null);
  const {decodedToken} = useContext(ProducerDataContext);
  const {Alert} = useAlert();
  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();

  const getProducers = async (search: string) => {
    if (search.length < 2) return;
    try {
      const pageNumber = 1;
      const pageSize = 3;
      const searchString = `&searchString=${search}`;
      const response = await api.get(
        `Producer?pageNumber=${pageNumber}&pageSize=${pageSize}${
          search && searchString
        }`,
      );
      setProducersList(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const getProducersDebounce = useCallback(
    _.debounce((search) => getProducers(search), 500),
    [],
  );

  const clearFormData = () => {
    setTopic('');
    setContent('');
  };

  const onBlur = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget)) return toggleFocused(false);
    return toggleFocused(true);
  };

  const handleOnFocusChange = (value: boolean) => toggleFocused(value);

  const handleReceiverIdChange = (value: string) => setReceiverId(value);

  const handleReceiverChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setReceiver(e.target.value);

  const handleTopicChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTopic(e.target.value);

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setContent(e.target.value);

  const sendMessage = async () => {
    if (!topic || !content)
      return Alert('Uzupełnij wszystkie pola', 'Komunikat');
    if (target === 0 && !receiverId)
      return Alert(
        'Zaznacz producenta do którego chcesz wysłać wiadomość.',
        'Komunikat',
      );
    // if (isMessageSending) return;
    // setIsMessageSending(true);
    try {
      const body = {
        Topic: topic,
        Content: content,
        RecieverProducerId: receiverId,
      };
      const response = await api.post(`Message`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      //   window.history.replaceState({}, document.title);
      Alert('Wiadomość została wysłana', 'Komunikat', 'Zamknij', true);
    } catch (error) {
      console.error(error);
    }
    // finally {
    //   setIsMessageSending(false);
    // }
  };

  useEffect(() => {
    setReceiver(producerNameFromOffer);
    setReceiverId(producerIdFromOffer);
    setTopic(productNameFromOffer);
  }, [productNameFromOffer, producerIdFromOffer, producerNameFromOffer]);

  useEffect(() => {
    getProducersDebounce(receiver);
  }, [receiver]);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileNewMessageViewWindowRef.current) {
        if (isMobileNewMessageViewOpen) {
          mobileNewMessageViewWindowRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileNewMessageViewWindowRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isMobileNewMessageViewOpen]);

  useEffect(() => {
    if (isMobileNewMessageViewOpen) {
      setIsBodyOverflowBlocked(true);
    }
  }, [isMobileNewMessageViewOpen]);

  return (
    <MenuMain
      isMenuOpen={isMobileNewMessageViewOpen}
      ref={mobileNewMessageViewWindowRef}
    >
      <CloseIsLoginPageOpenWrapper>
        Nowa wiadomość
        <CloseIsLoginPageOpen
          onClick={() => {
            handleIsMobileNewMessageViewOpen(false);
            setIsBodyOverflowBlocked(false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.625"
            height="16.625"
            viewBox="0 0 16.625 16.625"
          >
            <path
              id="Path_3807"
              data-name="Path 3807"
              d="M27.313,24.987l-2.326,2.326L19,21.326l-5.987,5.987-2.326-2.326L16.674,19l-5.987-5.987,2.326-2.326L19,16.674l5.987-5.987,2.326,2.326L21.326,19Z"
              transform="translate(-10.688 -10.688)"
              fill="#07020f"
            />
          </svg>
        </CloseIsLoginPageOpen>
      </CloseIsLoginPageOpenWrapper>
      <MobileIconsWrapper>
        <svg
          onClick={() => {
            clearFormData();
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="14.833"
          height="16.315"
          viewBox="0 0 14.833 16.315"
        >
          <g
            id="Icon_feather-trash"
            data-name="Icon feather-trash"
            transform="translate(0.75 0.75)"
          >
            <path
              id="Path_3663"
              data-name="Path 3663"
              d="M4.5,9H17.833"
              transform="translate(-4.5 -6.037)"
              fill="none"
              stroke="#3986ef"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_3664"
              data-name="Path 3664"
              d="M17.87,5.963v10.37a1.481,1.481,0,0,1-1.481,1.481H8.981A1.481,1.481,0,0,1,7.5,16.333V5.963m2.222,0V4.481A1.481,1.481,0,0,1,11.2,3h2.963a1.481,1.481,0,0,1,1.481,1.481V5.963"
              transform="translate(-6.019 -3)"
              fill="none"
              stroke="#3986ef"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </g>
        </svg>
        <svg
          onClick={() => {
            sendMessage();
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="23.414"
          height="23.414"
          viewBox="0 0 23.414 23.414"
        >
          <g
            id="Icon_feather-send"
            data-name="Icon feather-send"
            transform="translate(-2 -1.586)"
          >
            <path
              id="Path_3909"
              data-name="Path 3909"
              d="M28.05,3,16.5,14.55"
              transform="translate(-4.05)"
              fill="none"
              stroke="#06f"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              id="Path_3910"
              data-name="Path 3910"
              d="M24,3,16.65,24l-4.2-9.45L3,10.35Z"
              fill="none"
              stroke="#06f"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </g>
        </svg>
      </MobileIconsWrapper>
      <MenuWrapper style={{overflow: 'unset'}}>
        <InputWithSearchResultsWrapper
          onBlur={onBlur}
          style={{marginBottom: '10px'}}
        >
          <RightSectionAddressAndTitleWriteMode>
            <p>Odbiorca</p>
            <span>{receiver}</span>
            {/* <input
              type="text"
              placeholder="Pole obowiązkowe"
              value={receiver}
              onFocus={() => handleOnFocusChange(true)}
              onChange={handleReceiverChange}
            /> */}
          </RightSectionAddressAndTitleWriteMode>
          <SearchResultWrapper
            isFocused={isFocused}
            autoCompleted={receiver.length > 1}
            tabIndex={0}
          >
            {producersList.length === 0 && receiver.length > 1 && (
              <p style={{color: '#707070'}}>Brak wyników</p>
            )}

            {producersList
              .filter(
                (producer) => producer.ProducerId !== decodedToken.ProducerId,
              )
              .map((producer) => {
                const {ProducerId, ProducerFirstName, ProducerLastName} =
                  producer;

                return (
                  <SingleSearchResultWrapper
                    key={ProducerId}
                    onClick={() => {
                      handleReceiverIdChange(ProducerId);
                      setReceiver(`${ProducerFirstName} ${ProducerLastName}`);
                      toggleFocused(false);
                    }}
                  >
                    <p>{`${ProducerFirstName} ${ProducerLastName}`}</p>
                  </SingleSearchResultWrapper>
                );
              })}
          </SearchResultWrapper>
        </InputWithSearchResultsWrapper>
        <RightSectionAddressAndTitleWriteMode>
          <p>Temat</p>
          <input
            style={{width: '95%', paddingLeft: '4px'}}
            type="text"
            placeholder="Pole obowiązkowe"
            value={topic}
            onChange={handleTopicChange}
          />
        </RightSectionAddressAndTitleWriteMode>
        <RightSectionMessageContent isWriteMode={true}>
          <ContentTitle style={{position: 'unset'}}>Treść</ContentTitle>
          <textarea
            placeholder="Wprowadź tekst..."
            value={content}
            onChange={handleContentChange}
          />
        </RightSectionMessageContent>
      </MenuWrapper>
    </MenuMain>
  );
};

export default SendMessageBottomSheet;
