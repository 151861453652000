import React, {createContext, useState, useEffect} from 'react';
import jwt_decode from 'jwt-decode';
import api from 'services/axiosConfig';
import {useCookies} from 'react-cookie';

type Context = [string, React.Dispatch<React.SetStateAction<string>>];

export const UserThemeContext = createContext<Context>(null!);

interface Props {
  children: React.ReactNode;
}

const UserThemeProvider: React.FC<Props> = ({children}) => {
  const [theme, setTheme] = useState<string>('');

  const [cookies, setCookie] = useCookies(['token']);

  const decodedToken: {ProducerId: string; OwnedLabelId: string} =
    cookies.token && jwt_decode(cookies.token);

  const getProducerBackgroundColor = async (producerId: string) => {
    if (producerId === '00000000-0000-0000-0000-000000000000') return;
    try {
      const response = await api.get(`Producer/${producerId}/colors`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      const {BackgroundHexColor} = response.data.Response;

      if (BackgroundHexColor === 'D5D5D5') {
        setTheme('D5D5D5');
      } else if (BackgroundHexColor === '0C426A') {
        setTheme('0C426A');
      } else {
        setTheme('000000');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!decodedToken) return;
    getProducerBackgroundColor(decodedToken.ProducerId);
  }, []);

  return (
    <UserThemeContext.Provider value={[theme, setTheme]}>
      {children}
    </UserThemeContext.Provider>
  );
};

export default UserThemeProvider;
