import React, {useState} from 'react';
import styled from 'styled-components';

const AcceptCookiesWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 20px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.bgWhite};
  text-align: center;
  z-index: 99;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: 100%;
    padding: 20px 0;
    background-color: ${({theme}) => theme.colors.bgWhiteOpacity09};
    overflow: auto;
  }
`;

const AcceptCookiesMaxWidth = styled.div`
  max-width: 1440px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
  }
`;

const AcceptCookiesTextWrapper = styled.div`
  color: ${({theme}) => theme.colors.fontBlackOp100};
  font-size: 9px;
  line-height: 14px;
  margin: auto;
  padding: 0 30px;
  letter-spacing: 0;
  text-align: left;

  & a {
    color: #00bfff !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0 30px;
  }
`;

const AcceptCookiesButton = styled.button`
  position: absolute;
  top: -50px;
  right: 30px;
  padding: 14px 24px;
  background: rgb(0, 145, 255);
  opacity: 0.95;
  color: #fff;
  font-size: 14px;
  font-family: inter, Arial;
  border: none;
  display: inline-block;
  margin: 18px 10px;
  cursor: pointer;
  border-radius: 24px;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
  white-space: nowrap;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: unset;
  }
`;

export const AcceptCookiesComponent = () => {
  const [isCookiesAccepted, setIsCookiesAccepted] = useState<boolean>(
    localStorage.getItem('isCookiesAccepted') === 'yes' ? true : false,
  );

  const handleIsCookiesAccepted = (value: boolean) => {
    setIsCookiesAccepted(value);
    if (value === true) {
      localStorage.setItem('isCookiesAccepted', 'yes');
    }
  };

  return (
    <>
      {!isCookiesAccepted && (
        <AcceptCookiesWrapper>
          <AcceptCookiesMaxWidth>
            <AcceptCookiesTextWrapper>
              Serwis MUGO prowadzony przez spółkę MyMusic sp. j.. (dalej:
              Administrator danych osobowych lub Administrator) z siedzibą w
              Wysogotowie, wykorzystuje pliki cookies w celu świadczenia usług.
              Informacje odczytane za pomocą plików cookies i podobnych
              technologii wykorzystywane są w celach marketingowych,
              statystycznych (na podstawie uzasadnionego interesu
              Administratora) oraz w celu dostosowania serwisu do Państwa
              indywidualnych potrzeb, w tym do profilowania. Wykorzystanie
              plików cookies pozwala zapewnić Państwu maksymalną wygodę przy
              korzystaniu z Serwisu MUGO poprzez zapamiętanie Państwa
              preferencji i ustawień. Mają Państwo możliwość samodzielnej zmiany
              ustawień dotyczących cookies w przeglądarce internetowej, której
              używacie Państwo w celu korzystania ze Serwisu MUGO. Jeżeli nie
              wyrażają Państwo zgody na wykorzystywanie plików cookies, prosimy
              o zmianę ustawień w przeglądarce albo opuszczenie Serwisu.
              Wykorzystywanie cookies można wyłączyć z wyjątkiem plików, które
              są wymagane do prawidłowego działania strony internetowej.&nbsp;
              Dalsze korzystanie ze strony
              <a href="https://mugostudio.pl/"> https://mugostudio.pl/</a>, bez
              zmiany ustawień przeglądarki dotyczących plików cookies, oznacza
              ich akceptację i będzie skutkowało zapisywaniem ich na Państwa
              urządzeniach przez Serwis MUGO. Więcej informacji o zamieszczanych
              plikach cookies oraz o możliwości zmiany ustawień przeglądarki
              oraz polityce przetwarzania danych znajdą Państwo w polityce
              prywatności. Ponadto akceptacja ustawień przeglądarki oznacza
              zgodę na możliwość tworzenia Państwa profilu opartego na
              informacji dotyczącej świadczonych usług, zainteresowania ofertą
              lub informacjami zawartymi w plikach cookies co ma na celu
              dostosowanie treści dotyczących ADO do potrzeb korzystającego ze
              strony (profilowanie). Akceptacja ustawień oznacza ponadto, że
              zgoda jest dobrowolna. W przypadku nieudzielenia bądź późniejszego
              wycofania zgody, tracą Państwo możliwość uczestniczenia w
              działaniach marketingowych MyMusic sp.j. Mają Państwo prawo do
              cofnięcia wyrażonej zgody w dowolnym momencie. Wycofanie zgody nie
              ma wpływu na zgodność z prawem przetwarzania Państwa danych,
              którego dokonano na podstawie udzielonej wcześniej zgody.
            </AcceptCookiesTextWrapper>
            <AcceptCookiesButton onClick={() => handleIsCookiesAccepted(true)}>
              Zgadzam się z polityką cookies
            </AcceptCookiesButton>
          </AcceptCookiesMaxWidth>
        </AcceptCookiesWrapper>
      )}
    </>
  );
};
