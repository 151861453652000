import React, {createContext, useContext, useState} from 'react';

type ImageContextProviderProps = {
  children: React.ReactNode;
};
// Tworzymy kontekst
const ImageContext = createContext<any>({} as ImageContextProviderProps);

export const useImage = () => useContext(ImageContext);

export const ImageProvider = ({children}) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const selectImage = (image) => {
    setSelectedImage(image);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <ImageContext.Provider value={{selectedImage, selectImage, closeImage}}>
      {children}
    </ImageContext.Provider>
  );
};
