import React, {useState, useEffect} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {RecommendedTrack} from '../index';
import {useNavigate} from 'react-router-dom';
import pauseIcon from 'images/svg/pause-icon.svg';
import playIcon from 'images/svg/play-icon.svg';
import PopularCategoryImage6 from '../HomeImages/popular_category_6.png';
import NewTrackDesktopMainImage from '../HomeImages/newHomeTracksMain.png';
import NewTrackDesktopMainImage3 from '../HomeImages/tracklist_desktop3.png';
import NewTrackDesktopMainImage4 from '../HomeImages/pazzy_desktop.png';
import NewTrackMobileMainImage from '../HomeImages/newMobileTracksMain.png';
import NewTrackMobileMainImage3 from '../HomeImages/tracklist_mobilka3.png';
import NewTrackMobileMainImage4 from '../HomeImages/pazzy_mobilka.png';

import {
  MainWrapper,
  SingleTrack,
  TracksWrapper,
  SliderWrapper,
  MobileSliderWrapper,
  MobileSingleTrack,
  DesktopPlayButtonWrapper,
} from './styles';

import PopularTrackImage1 from '../HomeImages/popular_track_1c.png';
import MobilePopularTrackImage1 from '../HomeImages/mobile_popular_track_1c.png';
import {WinWinSvg} from 'components/SvgComponents';
import {useAudio} from 'contexts/AudioContext';

interface Props {
  RecommendedTracks: RecommendedTrack[];
}

export const PopularTracks: React.FC<Props> = ({RecommendedTracks}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAudioPlayed, setIsAudioPlayed] = useState<string | null>(null);

  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: RecommendedTracks && RecommendedTracks.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 10000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const splitArrayIntoChunks = (array, chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <MainWrapper>
      <img src={NewTrackDesktopMainImage3} />
      <img src={NewTrackMobileMainImage3} />
      <SliderWrapper>
        <Slider {...settings}>
          {RecommendedTracks &&
            splitArrayIntoChunks(RecommendedTracks, 4).map((chunk, index) => {
              return (
                <TracksWrapper key={index}>
                  {chunk.map((popularTrack: RecommendedTrack, index) => {
                    const {
                      Attributes,
                      Categories,
                      IsWinWin,
                      Name,
                      OfferId,
                      PicturePath,
                      Price,
                      ProductId,
                      ProductName,
                      SoundPath,
                    } = popularTrack;

                    return (
                      <SingleTrackComponent
                        RecommendedTrack={popularTrack}
                        isAudioPlayed={isAudioPlayed}
                        setIsAudioPlayed={setIsAudioPlayed}
                        key={OfferId}
                      />
                    );
                  })}
                </TracksWrapper>
              );
            })}
        </Slider>
      </SliderWrapper>
      <MobileSliderWrapper>
        <Slider {...settings}>
          {RecommendedTracks &&
            splitArrayIntoChunks(RecommendedTracks, 2).map((chunk, index) => {
              return (
                <TracksWrapper key={index}>
                  {chunk.map((popularTrack: RecommendedTrack, index) => {
                    const {
                      Attributes,
                      Categories,
                      IsWinWin,
                      Name,
                      OfferId,
                      PicturePath,
                      Price,
                      ProductId,
                      ProductName,
                    } = popularTrack;

                    return (
                      <SingleTrackComponent
                        RecommendedTrack={popularTrack}
                        isAudioPlayed={isAudioPlayed}
                        setIsAudioPlayed={setIsAudioPlayed}
                        key={OfferId}
                      />
                    );
                  })}
                </TracksWrapper>
              );
            })}
        </Slider>
      </MobileSliderWrapper>
      {/* <svg
        onClick={() => navigate(`/tracks/main`)}
        xmlns="http://www.w3.org/2000/svg"
        width="24.047"
        height="24.047"
        viewBox="0 0 24.047 24.047"
      >
        <path
          id="Icon_ionic-md-arrow-back"
          data-name="Icon ionic-md-arrow-back"
          d="M5.977,16.5h18.26L15.82,8.081,18,5.977,30.023,18,18,30.023l-2.1-2.1L24.237,19.5H5.977Z"
          transform="translate(-5.977 -5.977)"
          fill="#fff"
        />
      </svg> */}
    </MainWrapper>
  );
};

interface SingleTrackProps {
  RecommendedTrack: RecommendedTrack;
  isAudioPlayed: string | null;
  setIsAudioPlayed: any;
}

const SingleTrackComponent: React.FC<SingleTrackProps> = ({
  RecommendedTrack,
  isAudioPlayed,
  setIsAudioPlayed,
}) => {
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const {setPlaylistAndPlay, changePlayingStatus, currentTrackPlaying} =
    useAudio();

  const {
    Attributes,
    Categories,
    IsWinWin,
    Name,
    OfferId,
    PicturePath,
    Price,
    ProductId,
    ProductName,
    SoundPath,
  } = RecommendedTrack;
  console.log(RecommendedTrack);
  const navigate = useNavigate();

  const pauseAudio = (
    e: React.MouseEvent<HTMLElement>,
    audio: HTMLAudioElement,
  ) => {
    e.stopPropagation();
    if (!isAudioPlayed) return;
    audio.pause();
    setIsAudioPlayed(null);
  };

  // useEffect(() => {
  //   if (SoundPath) {
  //     const audioElement = new Audio(SoundPath);
  //     setAudio(audioElement);

  //     return () => {
  //       audioElement.pause();
  //       setAudio(null);
  //     };
  //   }
  // }, []);

  return (
    <SingleTrack
      key={OfferId}
      onClick={() => navigate(`/tracks/main/${ProductId}`)}
    >
      <img src={PicturePath} alt="image" />
      <div>
        {IsWinWin ? <WinWinSvg /> : <p>KWOTA</p>}
        <h2>
          {IsWinWin
            ? `od ${new Intl.NumberFormat('de-DE').format(Price / 100)} zł`
            : `${new Intl.NumberFormat('de-DE').format(Price / 100)} zł`}
        </h2>
        <h1>{ProductName}</h1>
        <h5>
          {Categories.map((category, i) => {
            if (i === 0) {
              return category;
            } else {
              return `/${category}`;
            }
          })}
        </h5>
        <h4>@{Name}</h4>
        {SoundPath ? (
          <DesktopPlayButtonWrapper
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {currentTrackPlaying.isAutoPlaying &&
            currentTrackPlaying.id === ProductId ? (
              <img
                src={pauseIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  changePlayingStatus(ProductId, false);
                }}
              />
            ) : (
              <img
                src={playIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setPlaylistAndPlay({
                    Song: SoundPath,
                    CoverFile: PicturePath,
                    Name: ProductName,
                    AttributeValues: [],
                    FirstCategoryName: Categories[0],
                    SecondCategoryName: '',
                    ThirdCategoryName: '',
                    ProductId: ProductId,
                  });
                }}
              />
            )}
          </DesktopPlayButtonWrapper>
        ) : null}
      </div>
    </SingleTrack>
  );
};
