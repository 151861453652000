import React, {createContext, useState, useEffect, useContext} from 'react';
import jwt_decode from 'jwt-decode';
import api from 'services/axiosConfig';
import {useCookies} from 'react-cookie';
import {LanguageContext} from '../LanguageContext';

interface DecodedToken {
  ProducerId: string;
  OwnedLabelId: string;
}

type Context = [UserData, React.Dispatch<React.SetStateAction<UserData>>];

export const UserDataContext = createContext<Context>(null!);

interface Props {
  children: React.ReactNode;
}

interface UserData {
  FirstName: string;
  LastName: string;
  ImagePath: string;
  Language: string;
  Email: string;
  PhoneNumber: string;
  PhoneNumberCode: string;
  UserId: string;
  UserName: string;
  StudioAllowNotifications: boolean;
  TwoStepVerificationEnabled: boolean;
  StudioAcceptedMarketing: boolean;
  StudioAcceptedPrivacyPolicy: boolean;
  StudioAcceptedRequlations: boolean;
}

const UserDataProvider: React.FC<Props> = ({children}) => {
  const [userData, setUserData] = useState<UserData>({
    FirstName: '',
    ImagePath: '',
    Language: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    PhoneNumberCode: '',
    UserId: '',
    UserName: '',
    StudioAllowNotifications: false,
    TwoStepVerificationEnabled: false,
    StudioAcceptedMarketing: false,
    StudioAcceptedPrivacyPolicy: false,
    StudioAcceptedRequlations: false,
  });
  const [cookies, setCookie] = useCookies(['token']);

  const [language, setLanguage] = useContext(LanguageContext);

  const decodedToken: {ProducerId: string; OwnedLabelId: string} =
    cookies.token && jwt_decode(cookies.token);

  console.log('dekodedtoken: ', decodedToken);

  const getUser = async () => {
    try {
      const response = await api.get(`Account/GetUser`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setUserData(response.data.Response);
      console.log(response.data.Response, 'user data');
      if (response.data.Response.Language === 'pl-PL') {
        setLanguage('pl');
      }
      if (response.data.Response.Language === 'en-GB') {
        setLanguage('en');
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!decodedToken) return;
    getUser();
  }, []);

  return (
    <UserDataContext.Provider value={[userData, setUserData]}>
      {children}
    </UserDataContext.Provider>
  );
};

export default UserDataProvider;
