import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import React, {useContext, useState} from 'react';
import {useCookies} from 'react-cookie';
import {ProducerDataContext} from '../../../../contexts/ProducerDataContext';
import styled from 'styled-components';
import api from '../../../../services/axiosConfig';

const MainWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  & > p {
    color: white;
    width: 16%;
    text-align: center;
    font-size: 16px;
    line-height: 22px;
  }
  & > div {
    margin-top: 5px;
    & > button {
      margin: 5px;
    }
  }
`;

export const PopUpButton = styled.button<{isBlue?: boolean}>`
  background-color: ${({isBlue}) => (isBlue ? `#7270F2` : 'black')};
  color: white;
  cursor: pointer;
  border: ${({isBlue}) => (isBlue ? `#7270F2 solid 1px` : 'grey solid 1px')};
  border-radius: 5px;
  text-align: center;
  padding: 2px 0px;
  width: 85px;
  font-size: 11px;
`;

export const ConfirmSettings: React.FC = () => {
  const [cookies, setCookie] = useCookies(['token']);
  const [isAfterUpdate, setIsAfterUpdate] = useState(false);
  const navigate = useNavigate();

  const {decodedToken} = useContext(ProducerDataContext);
  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }
  const locationSearch = window.location.search;

  const params = new URLSearchParams(locationSearch);
  const {type} = useParams();

  const updateEmail = async () => {
    try {
      const body = {
        EncodedUserId: params.get('userId').replaceAll(' ', '+'),
        EncodedEmail: params.get('email').replaceAll(' ', '+'),
        Token: params.get('token').replaceAll(' ', '+'),
      };
      const response = await api.put(`Account/UpdateEmailConfirm`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setIsAfterUpdate(true);
    } catch (error) {
      console.error(error);
    }
  };

  const updatePhoneNumber = async () => {
    try {
      const body = {
        EncodedUserId: params.get('userId').replaceAll(' ', '+'),
        EncodedPhoneNumber: params.get('phone').replaceAll(' ', '+'),
        Token: params.get('token').replaceAll(' ', '+'),
      };
      console.log(JSON.stringify(body));
      const response = await api.put(`Account/UpdatePhoneNumberConfirm`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setIsAfterUpdate(true);
    } catch (error) {
      console.error(error);
    }
  };

  const emailView = (
    <MainWrapper>
      <h4>Zmiana e-mail</h4>
      <p>
        Jeżeli chcesz kontynuować zmiane e-mail, prosimy o potwierdzenie. W
        innym wypadku rezygnacje.
      </p>
      <div>
        <PopUpButton onClick={() => navigate('/account/settings')}>
          Rezygnuje
        </PopUpButton>
        <PopUpButton onClick={updateEmail} isBlue={true}>
          Potwierdzam
        </PopUpButton>
      </div>
    </MainWrapper>
  );

  const phoneView = (
    <MainWrapper>
      <h4>Zmiana numeru telefonu</h4>
      <p>
        Jeżeli chcesz kontynuować zmiane numeru telefonu, prosimy o
        potwierdzenie. W innym wypadku rezygnacje.
      </p>
      <div>
        <PopUpButton onClick={() => navigate('/account/settings')}>
          Rezygnuje
        </PopUpButton>
        <PopUpButton onClick={updatePhoneNumber} isBlue={true}>
          Potwierdzam
        </PopUpButton>
      </div>
    </MainWrapper>
  );

  return !isAfterUpdate ? (
    type === 'email' ? (
      emailView
    ) : (
      phoneView
    )
  ) : (
    <MainWrapper>
      <h4>Udało się zaktualizować dane</h4>
      <p>
        Twój {`${type === 'email' ? 'e-mail' : 'numer telefonu'}`} został
        zmieniony
      </p>

      <PopUpButton isBlue={true} onClick={() => navigate('/account/settings')}>
        Okej
      </PopUpButton>
    </MainWrapper>
  );
};
