import React, {useState, useEffect, useContext} from 'react';
import {v4} from 'uuid';

import {hexToRgb} from '../../index';

import {useCookies} from 'react-cookie';
import useAlert from 'hooks/useAlert';
import api from 'services/axiosConfig';
import {Block} from '../../interface';
import {Comment} from '../../../Account/interface';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {UserDataContext} from 'contexts/UserDataContext';
import {
  DeleteButton,
  CommentBlockContent,
  CommentBlockNavigation,
  CommentBlockWrapper,
  CommentContent,
  StarWrapper,
  CommentDataAndUserNameWrapper,
  CommentHeader,
  CommentWrapper,
  CommentBlockHeader,
  NewCommentContentWrapper,
  NewCommentButtonWrapper,
  NewCommentContent,
  NewCommentButton,
  NewCommentSingielCommentWrapper,
  SingielCommentHeader,
  SingielCommentNameAndTitle,
  SingielCommentStarRateWrapper,
  SingielCommentTextWrapper,
  SingielCommentBg,
  NewCommentUpAndDownArrowWrapper,
  ButtonUpAndDownBg,
  EmptyCommentInfoWrapper,
  DeleteCommentButton,
  TextLimiterWrapper,
  MainSingielCommentWrapper,
  FirstAndLastCommentWrapper,
  CircleAvatarWrapper,
  SliderWrapper,
} from './styles';
import {VisitedProducerContext} from '../../../../../contexts/VisitedProducerContext';
import {EditHoverContext} from 'contexts/EditHoverContext';
import {ResolutionContext} from 'contexts/ResolutionContext';

import avatarImg from '../../../../../images/post_mugo.png';
import commentPlaceholder from '../../../../../images/CardOverview/placeholder_comment.png';
import {CommentOne} from './CommentOne';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export interface CommentsBlock extends Block {
  Comments: Comment[];
}

type Comments = Comment[];

interface Props {
  block: CommentsBlock;
  theme: string;
  mobileOverview: boolean;
  isEditing: boolean;
  updateComments: ({comments: Comments}) => void;
}

export const sliceIntoChunks = (arr: Comment[], chunkSize: number) => {
  if (!arr) return [];
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const getFormattedDate = (dateToFormat: string = undefined) => {
  const date = dateToFormat ? new Date(dateToFormat) : new Date();
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let day: string | number = date.getDate();

  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  const formattedDate = `${day}.${month}.${year}`;

  return formattedDate;
};

const CommentsBlockComponent: React.FC<Props> = ({
  block,
  theme,
  mobileOverview,
  isEditing,
  updateComments,
}) => {
  const [visitedProducer, setVisitedProducer] = useContext(
    VisitedProducerContext,
  );
  const {decodedToken} = useContext(ProducerDataContext);
  const [userData, setUserData] = useContext(UserDataContext);

  const [blockHover, setBlockHover] = useContext(EditHoverContext);
  const {windowWidth} = useContext(ResolutionContext);

  const reversedComments =
    block.Comments &&
    block.Comments.slice()
      .reverse()
      .map((item) => ({...item, CreationDate: item.CreationDate.slice(0, 10)}));

  const createCommentsArrForRender = (comments: Comment[]) => {
    if (comments.length === 0) {
      return [
        ...comments,
        {
          Content: `Spójrz! Jeszcze nikt nie pozostawił
          śladu po sobie w postaci komentarza!`,
          CreationDate: '',
          CreatorName: '',
          Rate: 0,
          Id: 'FIRSTCOMMENT',
        },
        {
          Content: 'Zrób miły gest i zamieść swój komentarz!',
          CreationDate: '',
          CreatorName: '@nazwa',
          Rate: 3,
          Id: 'ADDNEWCOMMENT',
        },
      ];
    }
    // if (comments.length === 1) {
    //   return [
    //     ...comments,
    //     {
    //       Content: `Odwiedzasz stronę twórcy?
    //       Nie zapomnij zostawić komentarza!
    //       To dobry obyczaj.`,
    //       CreationDate: '',
    //       CreatorName: '',
    //       Rate: 0,
    //       Id: 'VISITCARD',
    //     },
    //     {
    //       Content: `'Zrób miły gest i zamieść swój komentarz!'`,
    //       CreationDate: '',
    //       CreatorName: '@nazwa',
    //       Rate: 3,
    //       Id: 'ADDNEWCOMMENT',
    //     },
    //   ];
    // }
    // if (comments.length > 1) {
    if (visitedProducer.Id === decodedToken?.ProducerId) {
      return [...comments];
    } else {
      return [
        {
          Content: 'Zrób miły gest i zamieść swój komentarz!',
          CreationDate: '',
          CreatorName: '@nazwa',
          Rate: 3,
          Id: 'ADDNEWCOMMENT',
        },
        ...comments,
      ];
    }
    // }
  };
  const addCommentForEnd: Comment[] =
    createCommentsArrForRender(reversedComments);

  const [currentCommentsPage, setCurrentCommentsPage] = useState<number>(0);
  const [comments, setComments] = useState<Comment[]>(addCommentForEnd);
  const [rating, setRating] = useState<number>(3);
  const [hover, setHover] = useState<number>(0);
  const [content, setContent] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [isWritingMod, setIsWritingMod] = useState(false);
  const [isEditingCommentMod, setIsEditingCommentMod] = useState(false);
  const [commentIndex, setCommentIndex] = useState(0);

  const [useSlideInAnimation, setUseSlideInAnimation] = useState(false);
  const [deleteAniamtion, setDeleteAnimation] = useState(false);
  const [goTop, setGoTop] = useState(false);

  const maxIndexForComment = comments.length - 1;

  const [cookies, setCookie] = useCookies(['token']);

  const {Alert} = useAlert();

  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 20000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const handleAnimaton = (key: string) => {
    setUseSlideInAnimation((prevState) => !prevState);
    setDeleteAnimation(false);
    if (key === 'top') {
      setGoTop(true);
    } else {
      setGoTop(false);
    }
  };

  const editingPersonalCommentOff = () => {
    setIsWritingMod(false);
    setIsEditingCommentMod(false);
  };

  const {
    Id,
    BackgroundColorHex,
    BlockOpacityIn0To100Ratio,
    FontColorHex,
    TitleColorHex,
    WebArea,
    Type,
    Comments,
  } = block;

  const {Width} = WebArea;

  const deleteComment = async (id: string) => {
    try {
      const body = {
        CommentId: id,
        ProducerId: visitedProducer.Id,
      };

      const response = await api.delete(`Producer/Comments`, {
        data: {...body},
        headers: {Authorization: `bearer ${cookies.token}`},
      });

      setComments((prevState) => prevState.filter((item) => item.Id !== id));
      {
        isEditing &&
          updateComments({comments: comments.filter((item) => item.Id !== id)});
      }
      setUseSlideInAnimation((prevState) => !prevState);
      setDeleteAnimation(true);
      editingPersonalCommentOff();
    } catch (error) {
      console.error(error);
    }
  };

  const commentsAfterAdd = (newComment) => {
    // setComments((prev) => [newComment, ...prev]);
    const placeholderComment = {
      Content: 'Zrób miły gest i zamieść swój komentarz!',
      CreationDate: '',
      CreatorName: '@nazwa',
      Rate: 3,
      Id: 'ADDNEWCOMMENT',
    };

    setComments((prev) => [
      placeholderComment,
      newComment,
      ...prev.filter((comment) => comment.Id !== 'ADDNEWCOMMENT'),
    ]);
  };

  const createComment = async () => {
    // if (rating === 0) return alert('Wybierz ocenę');
    if (visitedProducer.Id === '') return alert('nie mamy id producer');
    try {
      const body = {
        ReceivingProducerId: visitedProducer.Id,
        Content: content,
        Rate: rating,
        CreatorName: `${userData.FirstName} ${userData.LastName}`,
      };

      const response = await api.post(`Producer/Comments`, body, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      const newComment = {
        Id: v4(),
        Content: content,
        CreationDate: new Date().toISOString().slice(0, 10),
        CreatorName: `${userData.FirstName} ${userData.LastName}`,
        Rate: rating,
        SendingUserId: userData.UserId,
        SendingProducerImage: avatarImg,
      };
      {
        isEditing &&
          updateComments({
            comments: [
              newComment,
              ...comments.filter((item) => item.Id !== 'add'),
            ],
          });
      }
      setComments((prev) => [newComment, ...prev]);
      setContent('');
    } catch (error) {
      console.error(error);
    }
  };

  const saveUpdateComment = async (id: string) => {
    try {
      const body = {
        ProducerId: visitedProducer.Id,
        Content: content,
        Rate: rating,
        Id: id,
      };

      const response = await api.put(`Producer/Comments`, body, {
        headers: {Authorization: `Bearer ${cookies.token}`},
      });
      const newComment = {
        Id: v4(),
        Content: content,
        CreationDate: new Date().toISOString().slice(0, 10),
        CreatorName: `${userData.FirstName} ${userData.LastName}`,
        Rate: rating,
        SendingUserId: userData.UserId,
      };
      {
        isEditing &&
          updateComments({
            comments: [
              newComment,
              ...comments.filter((item) => item.Id !== 'add'),
            ],
          });
      }
      setComments((prev) => [newComment, ...prev]);
      setContent('');
    } finally {
      editingPersonalCommentOff();
    }
  };

  const showAddCommentForm = () => {
    setCurrentCommentsPage(0);
    setComments((prev) => [
      {Id: 'add', Content: '', CreationDate: '', CreatorName: '', Rate: 1},
      ...prev,
    ]);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);

  function divideArr(arr) {
    const result = [];

    if (mobileOverview || windowWidth <= 1024) {
      for (let i = 0; i < arr.length; i += 2) {
        result.push(arr.slice(i, i + 2));
      }
    } else {
      for (let i = 0; i < arr.length; i += 3) {
        result.push(arr.slice(i, i + 3));
      }
    }

    return result;
  }

  function howMuchDots(number) {
    if (mobileOverview || windowWidth <= 1024) {
      Math.ceil(number / 2);
    }
    return Math.ceil(number / 3);
  }

  return (
    <CommentBlockWrapper
      isMobile={mobileOverview}
      backgroundAlpha={BlockOpacityIn0To100Ratio}
      backgroundColor={BackgroundColorRgb}
    >
      <SliderWrapper isMobile={mobileOverview}>
        <Slider {...settings}>
          {[...new Array(howMuchDots(comments.length))].map((array, index) => {
            return (
              <NewCommentContentWrapper isMobile={mobileOverview} key={index}>
                {divideArr(comments)[index].map((comment, index) => {
                  return (
                    <CommentOne
                      isMobile={mobileOverview}
                      commentsAfterAdd={commentsAfterAdd}
                      Comment={comment}
                      key={comment.Id}
                      removeComment={deleteComment}
                    />
                  );
                })}
              </NewCommentContentWrapper>
            );
          })}
        </Slider>
      </SliderWrapper>
    </CommentBlockWrapper>
  );
};

export default CommentsBlockComponent;
