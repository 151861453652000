import styled, {css} from 'styled-components';

export const DefaultBlockWrapper = styled.div<{
  Height: number;
  backgroundAlpha: number;
  backgroundColor: string;
}>`
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  overflow: hidden;
  background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`};
  /* border-radius: 24px; */
  /* border: 1px solid rgba(255, 255, 255, 0.15); */
`;

export const DefaultBlockContent = styled.div<{
  mobileOverview: boolean;
  Width: number;
  Height: number;
}>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: ${({mobileOverview, Width, Height}) =>
    mobileOverview
      ? 'column'
      : Width === 1 && Height > 1
      ? 'column'
      : Width === 2 && Height > 2
      ? 'column'
      : 'row'};

  @media (max-width: 1024px) {
    align-items: center;
    flex-direction: column;
    border-radius: 22px;
    /* justify-content: center; */
  }

  @media (max-width: 375px) {
  }

  @media (max-width: 320px) {
  }
`;

export const DefaultBlockImage = styled.img<{
  mobileOverview: boolean;
  Width: number;
  Height: number;
}>`
  aspect-ratio: 1/1;
  //width: ${({mobileOverview}) => (mobileOverview ? '200px' : '45%')};
  /* width: ${({Height, Width}) =>
    Height === 1 || Width === 1
      ? '113px'
      : Height === 2 || Width === 2
      ? '218px'
      : Height === 3
      ? '329px'
      : '452px'}; */
  /* height: ${({Height, Width}) =>
    Height === 1 || Width === 1
      ? '113px'
      : Height === 2 || Width === 2
      ? '218px'
      : Height === 3
      ? '329px'
      : '452px'}; */
  height: ${({mobileOverview}) => (mobileOverview ? '420px' : '100%')};
  //border-radius: 9px;
  /* border-radius: 22px; */
  /* border: 1px solid transparent; */
  //box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.16);
  background: transparent;
  @media (max-width: 1024px) {
    /* width: 329px;
    height: 329px; */
    width: 100%;
    height: auto;
  }

  @media (max-width: 375px) {
    /* width: 329px;
    height: 329px; */
  }

  @media (max-width: 320px) {
    /* width: 100%;
    height: auto; */
  }
`;

export const DefaultBlockRoleAndDataWrapper = styled.div`
  @media (max-width: 1024px) {
    width: 286px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  @media (max-width: 375px) {
  }

  @media (max-width: 320px) {
  }
`;

export const DefaultBlockDataWrapper = styled.div<{
  theme?: string;
  mobileOverview: boolean;
  Width: number;
  Height: number;
}>`
  //width: ${({mobileOverview}) => (mobileOverview ? '120%' : '50%')};
  //margin-left: 120px;
  /* margin-left: ${({Width, Height}) =>
    Width === 9 && Height === 2
      ? '132px'
      : Width === 2
      ? '7px'
      : Width < 9
      ? '11px'
      : Width === 9 && Height > 2
      ? '11px'
      : '28px'};
  padding: ${({Width, Height}) =>
    Width === 2
      ? '7px 7px 7px 0px'
      : Width < 9
      ? '7px 7px 7px 0px'
      : '7px 7px 7px 0px'}; */
  margin-left: ${({mobileOverview}) => (mobileOverview ? '0px' : '42px')};
  height: ${({mobileOverview}) => (mobileOverview ? '132px' : '144px')};
  padding: ${({mobileOverview}) => (mobileOverview ? '16px' : '0px')};
  margin: ${({mobileOverview}) => (mobileOverview ? '10px 0' : 'none')};
  width: 100%;
  border-radius: 9px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1024px) {
    height: 132px;
    /* width: 286px; */
    margin-left: 0px;
    padding: 16px;
    margin: 10px 0;
  }

  @media (max-width: 375px) {
    padding: 14px;
    margin: 5px 0;
  }
  @media (max-width: 340px) {
    padding: 10px;
  }

  @media (max-width: 320px) {
  }
`;

export const DefaultBlockName = styled.h5<{
  fontColor: string;
  Width: number;
  Height: number;
}>`
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.15px;
  color: ${({fontColor}) => `rgb(${fontColor})`};
  font-weight: 900;
  font-family: 'Inter', sans-serif;
  @media (max-width: 1024px) {
    font-size: 28px;
  }

  @media (max-width: 375px) {
  }

  @media (max-width: 320px) {
  }
`;

export const DefaultBlockRole = styled.p<{
  Width: number;
  Height: number;
  fontColor: string;
}>`
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.15px;
  color: ${({fontColor}) => `rgb(${fontColor})`};
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  //padding-top: 20px;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
`;

export const DefaultBlockLocation = styled.span<{
  Width: number;
  Height: number;
  fontColor: string;
}>`
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.15px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: ${({fontColor}) => `rgb(${fontColor})`};

  & > img {
    width: 10px;
    height: 14px;
    margin-right: 11px;
  }
`;
