import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import jwt_decode from 'jwt-decode';
import {Navigate, useNavigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {ProducerDataContext} from 'contexts/ProducerDataContext';

import approveIcon from 'images/svg/transparent-approve-green-bg.svg';
import disapproveIcon from 'images/svg/transparent-disapprove-red-bg.svg';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Main = styled.div`
  width: 1038px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 20px;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1045px) {
    width: 360px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 1000px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 9px;
  padding: 0px 19px 11px;
  margin-bottom: 17px;
  position: relative;

  & > p {
    color: rgba(255, 255, 255, 0.86);
    font-size: 11px;
    line-height: 21px;
    text-indent: 40px;

    &::first-line {
      line-height: 46px;
    }
  }

  @media (max-width: 1045px) {
    width: 328px;
    padding: 0px 19px 11px;
  }
`;

export const ConditionCheckbox = styled.div<{value: boolean}>`
  width: 27px;
  height: 27px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  cursor: pointer;
  background-position: center center;
  background-image: url(${({value}) => (value ? approveIcon : disapproveIcon)});
`;

interface Regulation {
  value: boolean;
  description: string;
}

const initialValue: Regulation[] = [
  {
    value: true,
    description:
      'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset',
  },
  {
    value: false,
    description:
      'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset',
  },
  {
    value: false,
    description:
      'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset',
  },
  {
    value: false,
    description:
      'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset',
  },
  {
    value: true,
    description:
      'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset',
  },
  {
    value: true,
    description:
      'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset',
  },
];

const RegulationsComponent: React.FC = () => {
  const [cookies, setCookie] = useCookies(['token']);

  const {decodedToken} = useContext(ProducerDataContext);

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }
  // if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000') {
  //   return <Navigate to="/producer" />;
  // }

  const [regulations, setRegulations] = useState<Regulation[]>(initialValue);

  const handleRegulationValueChange = (index: number, value: boolean) =>
    setRegulations((prev) =>
      prev.map((regulation, regulationIndex) => {
        if (index === regulationIndex) {
          return {
            description: regulation.description,
            value,
          };
        }
        return regulation;
      }),
    );

  return (
    <MainWrapper>
      <Main>
        <p>Polityka prywatności i regulaminy</p>

        {regulations.map((regulation, index) => {
          const {description, value} = regulation;

          return (
            <HeaderWrapper key={index}>
              <ConditionCheckbox
                value={value}
                onClick={() => handleRegulationValueChange(index, !value)}
              />
              <p>{description}</p>
            </HeaderWrapper>
          );
        })}
      </Main>
    </MainWrapper>
  );
};

export default RegulationsComponent;
