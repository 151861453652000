import {
  AccountBankEditAndSaveBtn,
  AccountBasicButtonWrapper,
  AccountBigBlueBtn,
  AccountBigBlueBtnWrapper,
  AccountBox,
  AccountBoxText,
  AccountBoxWrapper,
  AccountInputWrapper,
  AccountMangoPayFormWrapper,
  AccountMangopayWrapper,
  AccountSmallBtn,
  AccountSmallInputWrapper,
  AccountStatusInfo,
  BlueBox,
  FlexSaveBtn,
  IdScansButtonWrapper,
  IdScansWrapper,
  LogoContainer,
  MangoPayBasicButton,
  PaymentLogoWrapper,
  RelativeDiv,
  ScansResponsWrapper,
  SmallBtnWrapper,
  WrongIdMessageWrapper,
  InputView,
  InputViewForData,
  SelectView,
  HoverTextForEdit,
  MangopayStatusWrapper,
  MangopayHeaderForm,
  DescriptionForInput,
  StyledStatusWrapper,
  StyledStatusLine,
  TwoSmallInputsWrapper,
  CenterTextForDataInfo,
} from '../styles';
import mangopayLogo from '../../../../images/svg/mangopay-logo.svg';
import ueLogo from '../../../../images/ue-logo-text.png';
import React, {useContext, useEffect, useRef, useState} from 'react';
import useAlert from '../../../../hooks/useAlert';
// import {InputView, SelectView} from '../../../MyAccount/Settings/styles';
import {ErrorStar} from '../../../../components/ErrorStar';
import {CountryAndNationalities, MangopayAccount} from '../interface';
import PopupButton from 'components/PopUp/PopupButton';
import api from '../../../../services/axiosConfig';
import {useCookies} from 'react-cookie';
import {Select} from '../../../../components/Select';
import {light} from '../../../../constants/theme';
import {ResolutionContext} from '../../../../contexts/ResolutionContext';
import {ProducerDataContext} from 'contexts/ProducerDataContext';
import {EditBtnWrapper, EditSvgWrapper} from 'constants/globalElements';
import {StyledSelectView} from '..';

interface MangopayErrors {
  FirstName: {isError: boolean; text: string};
  LastName: {isError: boolean; text: string};
  Email: {isError: boolean; text: string};
  Birthday: {isError: boolean; text: string};
  Nationality: {isError: boolean; text: string};
  CountryOfResidence: {isError: boolean; text: string};
  AddressLine1: {isError: boolean; text: string};
  City: {isError: boolean; text: string};
  PostalCode: {isError: boolean; text: string};
  Country: {isError: boolean; text: string};
  BuildingNumber: {isError: boolean; text: string};
  FlatNumber: {isError: boolean; text: string};
  Status: {isError: boolean; text: string};
  Pesel: {isError: boolean; text: string};
  Confirm: {isError: boolean; text: string};
  ShowErrors: boolean;
  TermsAndConditionsAccepted: {isError: boolean; text: string};
  IBAN: {isError: boolean; text: string};
}

interface Props {
  mangopay: MangopayAccount;
  createAccount: (data: MangopayAccount, imgs: string[]) => Promise<void>;
  formType: number;
  isEditing: boolean;
  handleEditingMode: () => void;
  handleType: (type: number) => void;
  showSavePopUp: (data: MangopayAccount, validated: boolean) => void;
  nationalities: CountryAndNationalities[];
  countries: CountryAndNationalities[];
}

const mangopayRefusedReasonType = {
  DOCUMENT_UNREADABLE: 'Dokument nieczytelny',
  DOCUMENT_NOT_ACCEPTED: 'Dokument nieakceptowany',
  DOCUMENT_HAS_EXPIRED: 'Ważność dokumentu',
  DOCUMENT_INCOMPLETE: 'Dokument niekompletny',
  DOCUMENT_MISSING: 'Brak dokumentu',
  DOCUMENT_DO_NOT_MATCH_USER_DATA: 'Dokument nie pasuję do danych użytkownika',
  DOCUMENT_FALSIFIED: 'Dokument sfałszowany',
  UNDERAGE_PERSON: 'Osoba niepełnoletnia',
  SPECIFIC_CASE: 'Szczególny przypadek',
  OUT_OF_DATE: 'Dokument nieaktualny',
  CREATED: 'Coś poszło nie tak, prosimy ponownie przesłać dokument',
};

const nationalityTypeObj = {
  PL: {text: 'Polska', value: 'PL'},
  ENG: {text: 'Angielski', value: 'ENG'},
};

const nationalityTypeOptionsArr = [
  {text: 'Polska', value: 'PL'},
  {text: 'Angielski', value: 'ENG'},
];

export const getKYCLevelStatusDescription = (status: string) => {
  switch (status) {
    case 'LIGHT':
      return 'Przetwarzany';
    case 'REGULAR':
      return 'Zaakceptowany';
    default:
      return 'Nieaktywne';
  }
};

export const AccountMangopayNaturalPersonForm: React.FC<Props> = ({
  mangopay,
  createAccount,
  formType,
  handleType,
  isEditing,
  handleEditingMode,
  showSavePopUp,
  nationalities,
  countries,
}) => {
  const [mangopayAccount, setmangopayAccount] = useState<MangopayAccount>({
    FirstName: '',
    LastName: '',
    Email: '',
    Birthday: null,
    Nationality: '',
    CountryOfResidence: '',
    Address: {
      AddressLine1: '',
      AddressLine2: '',
      City: '',
      PostalCode: '',
      Country: '',
      BuildNumber: '',
      FlatNumber: '',
    },
    Status: -1,
    Type: 0,
    UserCategory: '',
    KYCLevel: '',
    Pesel: '',
    DocIdentityProof: {
      Type: '',
      Status: '',
      RefusedReasonMessage: '',
      RefusedReasonType: '',
      ProcessedDate: '',
      Id: '',
      CreationDate: '',
    },
    TermsAndConditionsAccepted: false,
    IBAN: '',
  });
  const [mangopayErrors, setMangopayErrors] = useState<MangopayErrors>({
    FirstName: {isError: false, text: ''},
    LastName: {isError: false, text: ''},
    Email: {isError: false, text: ''},
    Birthday: {isError: false, text: ''},
    Nationality: {isError: false, text: ''},
    CountryOfResidence: {isError: false, text: ''},
    AddressLine1: {isError: false, text: ''},
    City: {isError: false, text: ''},
    PostalCode: {isError: false, text: ''},
    Country: {isError: false, text: ''},
    BuildingNumber: {isError: false, text: ''},
    FlatNumber: {isError: false, text: ''},
    Status: {isError: false, text: ''},
    Pesel: {isError: false, text: ''},
    Confirm: {isError: false, text: ''},
    ShowErrors: false,
    TermsAndConditionsAccepted: {isError: false, text: ''},
    IBAN: {isError: false, text: ''},
  });
  const [idScans, setIdScans] = useState<string[]>([]);
  const [validateScans, setValidateScans] = useState({
    front: false,
    back: false,
  });
  const [btnType, setBtnType] = useState({
    front: false,
    back: false,
  });
  const [idScansFrontError, setIdScansFrontError] = useState({
    showErrors: false,
    frontId: {
      isError: true,
      text: 'Zdjęcie skanu dowodu tożsamości jest wymagane. Powinno być w poziomie',
    },
    backId: {
      isError: true,
      text: 'Zdjęcie skanu dowodu tożsamości jest wymagane. Powinno być w poziomie',
    },
  });
  const [isHoveredForEditBtn, setIsHoveredForEditBtn] =
    useState<boolean>(false);

  const [isSomethingChange, setIsSomethingChange] = useState(false);

  useEffect(() => {
    setMangopayErrors((prev) => ({...prev, Email: {isError: false, text: ''}}));
  }, [mangopayAccount.Email]);

  const {Alert} = useAlert();

  const [cookies] = useCookies(['token']);

  const inputIdFileRef = useRef(null as any);

  const inputIdFileBackRef = useRef(null as any);
  const {windowWidth} = useContext(ResolutionContext);
  const {decodedToken} = useContext(ProducerDataContext);

  if (mangopayAccount.Status !== mangopay.Status) setmangopayAccount(mangopay);

  const checkAge = (birthday) => {
    const today = new Date();
    const birthdateArray = birthday.split('-');
    const birthdateObject = new Date(
      birthdateArray[0],
      birthdateArray[1] - 1,
      birthdateArray[2],
    );
    const ageDiffMilliseconds = today.getTime() - birthdateObject.getTime();
    const ageDate = new Date(ageDiffMilliseconds);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age >= 18;
  };

  const resetMangopayErrors = () => {
    setMangopayErrors({
      FirstName: {isError: false, text: ''},
      LastName: {isError: false, text: ''},
      Email: {isError: false, text: ''},
      Birthday: {isError: false, text: ''},
      Nationality: {isError: false, text: ''},
      CountryOfResidence: {isError: false, text: ''},
      AddressLine1: {isError: false, text: ''},
      City: {isError: false, text: ''},
      PostalCode: {isError: false, text: ''},
      Country: {isError: false, text: ''},
      BuildingNumber: {isError: false, text: ''},
      FlatNumber: {isError: false, text: ''},
      Status: {isError: false, text: ''},
      Pesel: {isError: false, text: ''},
      Confirm: {isError: false, text: ''},
      ShowErrors: false,
      TermsAndConditionsAccepted: {isError: false, text: ''},
      IBAN: {isError: false, text: ''},
    });
  };

  const validateData = (data: MangopayAccount) => {
    resetMangopayErrors();
    const onlyLettersWithPolishCharactersRegex =
      /^[A-Za-zA-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/;
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const {
      FirstName,
      LastName,
      Email,
      Birthday,
      Nationality,
      CountryOfResidence,
      Address,
      TermsAndConditionsAccepted,
      IBAN,
    } = data;

    const validatedData = {
      FirstName: false,
      LastName: false,
      Email: false,
      Birthday: false,
      Nationality: false,
      CountryOfResidence: false,
      AddressLine1: false,
      City: false,
      PostalCode: false,
      Country: false,
      BuildingNumber: false,
      FlatNumber: false,
      Status: false,
      Pesel: false,
      Confirm: false,
      ShowErrors: false,
      TermsAndConditionsAccepted: false,
      IBAN: false,
      IdScans: false,
    };
    console.log('idScan w validacji', idScans.length);
    if (
      mangopayAccount.DocIdentityProof.Status !== 'VALIDATED' &&
      mangopayAccount.DocIdentityProof.Status !== 'VALIDATION_ASKED'
    ) {
      if (idScans.length < 2) {
        setIdScansFrontError((prevState) => ({
          ...prevState,
          showErrors: true,
        }));
        validatedData.IdScans = true;
      }
    }

    if (Nationality.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        Nationality: {isError: true, text: 'Pole państwo jest wymagane'},
      }));
      validatedData.Nationality = true;
    }

    if (Address.Country.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        Country: {isError: true, text: 'Pole kraj jest wymagane'},
      }));
      validatedData.Country = true;
    }

    if (FirstName.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        FirstName: {isError: true, text: 'Pole imię jest wymagane'},
      }));
      validatedData.FirstName = true;
    }
    if (!onlyLettersWithPolishCharactersRegex.test(FirstName)) {
      setMangopayErrors((prev) => ({
        ...prev,
        FirstName: {isError: true, text: 'Pole musi miec litery'},
      }));
      validatedData.FirstName = true;
    }
    if (FirstName.length > 63) {
      setMangopayErrors((prev) => ({
        ...prev,
        FirstName: {
          isError: true,
          text: 'Pole imie nie moze byc dluzsze niz 63 znaki',
        },
      }));
      validatedData.FirstName = true;
    }
    if (LastName.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        LastName: {isError: true, text: 'Pole nazwisko jest wymagane'},
      }));
      validatedData.LastName = true;
    }
    if (!onlyLettersWithPolishCharactersRegex.test(LastName)) {
      setMangopayErrors((prev) => ({
        ...prev,
        LastName: {
          isError: true,
          text: 'Pole nazwisko nie mozem iec cyfr jest wymagane',
        },
      }));
      validatedData.LastName = true;
    }
    if (LastName.length > 63) {
      setMangopayErrors((prev) => ({
        ...prev,
        LastName: {isError: true, text: 'Pole nazwisko  znaki 63'},
      }));
      validatedData.LastName = true;
    }
    if (Email.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        Email: {isError: true, text: 'Pole email jest wymagane'},
      }));
      validatedData.Email = true;
    }
    if (!emailRegex.test(Email)) {
      setMangopayErrors((prev) => ({
        ...prev,
        Email: {isError: true, text: 'Pole email musi miec @'},
      }));
      validatedData.Email = true;
    }

    if (Email.includes('+')) {
      setMangopayErrors((prev) => ({
        ...prev,
        Email: {isError: true, text: 'Pole email nie może mieć +'},
      }));
      validatedData.Email = true;
    }

    if (IBAN.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        IBAN: {isError: true, text: 'Pole nr konta bankowego jest wymagane'},
      }));
      validatedData.IBAN = true;
    }

    if (Birthday.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        Birthday: {isError: true, text: 'Pole daa urodzenia jest wymagane'},
      }));
      validatedData.Birthday = true;
    }

    if (!checkAge(Birthday)) {
      setMangopayErrors((prev) => ({
        ...prev,
        Birthday: {isError: true, text: 'Musisz byc pelnoletni'},
      }));
      validatedData.Birthday = true;
    }

    if (Address.AddressLine1.length === 0) {
      console.log('error', mangopayAccount.Address.AddressLine1);
      setMangopayErrors((prev) => ({
        ...prev,
        AddressLine1: {isError: true, text: 'Pole nr budynku jest wymagane'},
      }));
      validatedData.AddressLine1 = true;
    }
    if (Address.City.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        City: {isError: true, text: 'Pole miasto jest wymagane'},
      }));
      validatedData.City = true;
    }
    if (Address.PostalCode.length === 0) {
      setMangopayErrors((prev) => ({
        ...prev,
        PostalCode: {isError: true, text: 'Pole kod pocztowy jest wymagane'},
      }));
      validatedData.PostalCode = true;
    }
    if (!TermsAndConditionsAccepted) {
      setMangopayErrors((prev) => ({
        ...prev,
        TermsAndConditionsAccepted: {
          isError: true,
          text: 'Regulamin musi byc zaakceptowany jest wymagane',
        },
      }));
      validatedData.TermsAndConditionsAccepted = true;
    }
    console.log(validatedData, 'walidacja true');
    if (
      !validatedData.FirstName &&
      !validatedData.LastName &&
      !validatedData.Email &&
      !validatedData.TermsAndConditionsAccepted &&
      !validatedData.Birthday &&
      !validatedData.Nationality &&
      !validatedData.CountryOfResidence &&
      !validatedData.AddressLine1 &&
      !validatedData.City &&
      !validatedData.PostalCode &&
      !validatedData.IBAN &&
      !validatedData.Country &&
      !validatedData.IdScans
    ) {
      resetMangopayErrors();
      return true;
    } else {
      setMangopayErrors((prev) => ({...prev, ShowErrors: true}));
      return false;
    }
  };

  const handleMangopayAccountChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setmangopayAccount((prev) => ({
      ...prev,
      [e.target.id]: e.target.value.replace(/\s+/g, ''),
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      [e.target.id]: {isError: false, text: ''},
    }));
    setIsSomethingChange(true);
  };

  const handleUserFirstNameInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    // Tutaj zastosuj regex, aby usunąć cyfry z wprowadzonego tekstu
    const filteredValue = value.replace(/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g, '');
    setmangopayAccount((prev) => ({...prev, FirstName: filteredValue}));
    setMangopayErrors((prevState) => ({
      ...prevState,
      [e.target.id]: {isError: false, text: ''},
    }));
    setIsSomethingChange(true);
  };

  const handleUserLastNameInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    // Tutaj zastosuj regex, aby usunąć cyfry z wprowadzonego tekstu
    const filteredValue = value.replace(/[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]/g, '');
    setmangopayAccount((prev) => ({...prev, LastName: filteredValue}));
    setMangopayErrors((prevState) => ({
      ...prevState,
      [e.target.id]: {isError: false, text: ''},
    }));
    setIsSomethingChange(true);
  };

  const handleBirthDayChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const value = e.target.value;
    // console.log(value, value.length);
    if (value.length <= 10) {
      setmangopayAccount((prevState) => ({
        ...prevState,
        Birthday: value,
      }));
      setMangopayErrors((prevState) => ({
        ...prevState,
        Birthday: {isError: false, text: ''},
      }));
    }
  };

  const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setmangopayAccount((prevState) => ({
      ...prevState,
      Address: {
        ...prevState.Address,
        PostalCode: value
          .replace(/\D/g, '')
          .slice(0, 6)
          .replace(/(\d{2})(\d{1,3})/, '$1-$2'),
      },
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      PostalCode: {isError: false, text: ''},
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayNationality = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setmangopayAccount((prevState) => ({
      ...prevState,
      Nationality: e.target.value,
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      Nationality: {isError: false, text: ''},
    }));
    setIsSomethingChange(true);
  };

  const handleMangopayCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setmangopayAccount((prevState) => ({
      ...prevState,
      Address: {...prevState.Address, Country: e.target.value},
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      Country: {isError: false, text: ''},
    }));
    setIsSomethingChange(true);
  };

  const formatIBAN = (input) => {
    // console.log(input);
    const formattedInput = input.replace(/[^0-9]/g, '');

    let formattedIBAN = '';
    for (let i = 0; i < formattedInput.length; i++) {
      if (i === 2 || i === 6 || i === 10 || i === 14 || i === 18 || i === 22) {
        formattedIBAN += ' ';
      }
      formattedIBAN += formattedInput[i];
    }

    return formattedIBAN;
  };

  const handleMangopayIBAN = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // setmangopayAccount((prevState) => ({
    //   ...prevState,
    //   // IBAN: value.replace(/\D/g, '').slice(0, 26),
    //   IBAN: value
    //     .replace(/[^0-9]/g, '')
    //     .replace(/\s/g, '')
    //     .replace(/(\d{4})/g, '$1 ')
    //     .trim(),
    // }));
    setmangopayAccount((prevState) => ({
      ...prevState,
      IBAN: formatIBAN(value),
    }));
    setMangopayErrors((prevState) => ({
      ...prevState,
      IBAN: {isError: false, text: ''},
    }));
  };

  const sentMangopayIBAN = async () => {
    try {
      const body = {IBAN: `PL${mangopayAccount.IBAN.replace(/\s+/g, '')}`};

      const response = await api.post(
        `mangopayaccount/RegisterIBANBankAccount`,
        body,
        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleMangopayAddressLineChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    if (e.target.id === 'FlatNumber') {
      const filteredValue = e.target.value.replace(
        /[^a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 -]/g,
        '',
      );
      setmangopayAccount((prev) => ({
        ...prev,
        Address: {...prev.Address, [e.target.id]: filteredValue},
      }));
    } else {
      setmangopayAccount((prev) => ({
        ...prev,
        Address: {...prev.Address, [e.target.id]: e.target.value},
      }));
    }

    setMangopayErrors((prevState) => ({
      ...prevState,
      [e.target.id]: {isError: false, text: ''},
    }));
    setIsSomethingChange(true);
  };

  const handleMangoPayRegulationsChange = () => {
    setmangopayAccount((prev) => ({
      ...prev,
      TermsAndConditionsAccepted: !prev.TermsAndConditionsAccepted,
    }));
    setIsSomethingChange(true);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const getIdImage = async (e: any) => {
    if (e.target.value.length > 0) {
      const fileName = e.target.value;
      const dotIndex = fileName.lastIndexOf('.');
      const extFile = fileName
        .substr(dotIndex + 1, fileName.length)
        .toLowerCase();
      if (extFile === 'jpg' || extFile === 'jpeg' || extFile === 'png') {
        const base64 = await toBase64(e.target.files[0]);
        const body = base64.toString().split(',')[1];
        if (idScans[0] === body) {
          return Alert(
            `Wybrano takie samo zdjęcie, proszę wybrać inne`,
            'Komunikat',
          );
        }
        if (idScans.length < 2) {
          setIdScans((prev) => [...prev, body]);
          if (btnType.front) {
            setValidateScans((prevState) => ({...prevState, front: true}));
            setIdScansFrontError((prevState) => ({
              ...prevState,
              frontId: {isError: false, text: ''},
            }));
          }
          if (btnType.back) {
            setValidateScans((prevState) => ({...prevState, back: true}));
            setIdScansFrontError((prevState) => ({
              ...prevState,
              backId: {isError: false, text: ''},
            }));
          }
          Alert(
            `Dodano plik ${e.target.files[0].name.slice(0, 20)}`,
            'Komunikat',
          );
        }
      } else {
        Alert('Wybierz zdjęcie w formacie .jpg lub .png', 'Komunikat');
      }
    }
  };
  const getOfferForProducer = async (producerId: string) => {
    try {
      const response = await api.get(
        `/producer/${producerId}/active-offers-count`,
      );
      if (response.data.Response < 1) return false;
      if (response.data.Response > 0) return true;
    } catch (error) {
      console.log(error);
    }
  };

  const checkDataBeforeSend = async () => {
    if (!validateData(mangopayAccount))
      return console.log('nie przeszlo validacji');
    // if (
    //   mangopayAccount.DocIdentityProof.Status !== 'VALIDATED' &&
    //   mangopayAccount.DocIdentityProof.Status !== 'VALIDATION_ASKED'
    // ) {
    //   if (idScans.length < 2)
    //     return setIdScansFrontError((prevState) => ({
    //       ...prevState,
    //       showErrors: true,
    //     }));
    // }

    if (await getOfferForProducer(decodedToken.ProducerId)) {
      return Alert(
        'Nie możesz aktualizować danych w Mango posiadając wystawione oferty.',
        'Komunikat',
      );
    }

    await createAccount(mangopayAccount, idScans);
    await sentMangopayIBAN();
    setIsSomethingChange(false);
    // Alert(
    //   'Formularz z danymi został wysłany, proszę czekać na zweryfikowanie',
    //   'Komunikat',
    //   'Zamknij',
    //   true,
    // );
  };
  const formatWalletIBAN = (inputString: string) => {
    const firstPart = inputString.substring(0, 4);
    const secondPart = inputString.substring(4);
    const modifiedFirstPart = firstPart.substring(2);
    const formattedSecondPart = secondPart.match(/.{1,4}/g).join(' ');
    const formattedNumber = modifiedFirstPart + ' ' + formattedSecondPart;
    return formattedNumber;
  };

  const CheckFormDataBeforeDeclineEditMode = () => {
    if (
      mangopayAccount.FirstName === '' ||
      mangopayAccount.LastName === '' ||
      mangopayAccount.Email === ''
    ) {
      handleType(-1);
    }
  };

  const statusView = () => (
    <AccountStatusInfo>
      <pre>
        {mangopayAccount.Status === 1
          ? `W trakcie weryfikacji konta zauważyliśmy braki.\nUzupełnij proszę następujące dane..`
          : mangopayAccount.Status === 2
          ? `Twoje dane są w trakcie przetwarzania przez MangoPay.\nPowiadomimy Cię
        o sytuacji w ciągu 48h`
          : mangopayAccount.KYCLevel === 'REGULAR'
          ? 'Portfel MUGO jest aktywny.\nWystawiaj oferty i wypłacaj swoje zarobki.'
          : mangopayAccount.Status === 5
          ? `Dane konta nie zgadzają się z danymi dokumentu.\nProszę zaktualizować dane`
          : `Twoje konto jest w trakcie weryfikacji.\nProszę czekać`}
      </pre>
    </AccountStatusInfo>
  );

  return (
    <AccountMangopayWrapper
      status={mangopayAccount.Status}
      isEditing={isEditing}
    >
      <MangopayHeaderForm>
        {isHoveredForEditBtn && (
          <HoverTextForEdit>
            <p>{isEditing ? 'Anuluj' : 'Edytuj'}</p>
          </HoverTextForEdit>
        )}
        <EditBtnWrapper
          onMouseEnter={() => setIsHoveredForEditBtn(true)}
          onMouseLeave={() => setIsHoveredForEditBtn(false)}
          isEditing={isEditing}
        >
          <EditSvgWrapper
            isEditing={isEditing}
            onClick={() => {
              handleEditingMode();
              CheckFormDataBeforeDeclineEditMode();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.27"
              height="16.658"
              viewBox="0 0 17.27 16.658"
            >
              <g
                id="Icon_feather-edit-3"
                data-name="Icon feather-edit-3"
                transform="translate(-3.75 -3.41)"
              >
                <path
                  id="Path_3712"
                  data-name="Path 3712"
                  d="M18,30h7.885"
                  transform="translate(-5.615 -10.682)"
                  fill="none"
                  stroke={isEditing ? 'rgba(58,135,240,1)' : 'rgba(7,2,15,0.7)'}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <path
                  id="Path_3713"
                  data-name="Path 3713"
                  d="M16.327,4.862a1.858,1.858,0,0,1,2.628,2.628L8,18.442l-3.5.876.876-3.5Z"
                  fill="none"
                  stroke={isEditing ? 'rgba(58,135,240,1)' : 'rgba(7,2,15,0.7)'}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </g>
            </svg>
          </EditSvgWrapper>
        </EditBtnWrapper>
        {/* {isSomethingChange ? (
          <FlexSaveBtn
            onClick={() => {
              showSavePopUp(mangopayAccount, validateData(mangopayAccount));
              setIsSomethingChange(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="18"
              viewBox="0 0 14 18"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect width="14" height="18" fill="none" />
                </clipPath>
              </defs>
              <g
                id="Icons_24px_Document"
                data-name="Icons – 24px / Document"
                clipPath="url(#clip-path)"
              >
                <path
                  id="Icons_24px_Document-2"
                  data-name="Icons – 24px / Document"
                  d="M14,18H0V0H9.384L9.6.194l4.2,4.154.2.216V18ZM1.4,1.384V16.616H12.6V5.539H8.4V1.384Zm8.4,1V4.154h1.794Zm.7,11.467h-7V12.461h7v1.384Zm0-2.769h-7V9.692h7v1.384Zm0-2.769h-7V6.923h7V8.307Z"
                  fill="#fff"
                />
              </g>
            </svg>
          </FlexSaveBtn>
        ) : null} */}
        <h4>
          {mangopayAccount.UserCategory !== 'OWNER'
            ? 'Rejestracja portfela MUGO'
            : 'Portfel MUGO'}
        </h4>
        <PaymentLogoWrapper status={mangopayAccount.Status}>
          <LogoContainer>
            {/* <img src={mangopayLogo} /> */}
            <svg
              id="logo"
              xmlns="http://www.w3.org/2000/svg"
              width="115.685"
              height="17"
              viewBox="0 0 115.685 17"
            >
              <path
                id="Path_3733"
                data-name="Path 3733"
                d="M62.841.564A4.653,4.653,0,0,0,60.583,0a3.828,3.828,0,0,0-2.046.494,4.43,4.43,0,0,0-1.481,1.34h-.071V.282H53.6V12.2h3.386V5.361a2.416,2.416,0,0,1,.282-1.27,2.242,2.242,0,0,1,.776-.846,2.436,2.436,0,0,1,1.27-.353,2.074,2.074,0,0,1,1.552.635A2.249,2.249,0,0,1,61.43,5.22v6.913h3.315V4.8a5.949,5.949,0,0,0-.494-2.61A4.53,4.53,0,0,0,62.841.564Z"
                transform="translate(-15.791 0)"
                fill="#f8f8f8"
              />
              <path
                id="Path_3734"
                data-name="Path 3734"
                d="M18.834,0h.141a4.169,4.169,0,0,1,4.162,4.162v7.971H19.822V3.95a1.085,1.085,0,0,0-1.058-1.058h0a1,1,0,0,0-.988.776L16.224,9.946A3.189,3.189,0,0,1,9.946,9.17V3.95A1.085,1.085,0,0,0,8.888,2.892a1,1,0,0,0-.988.776L6.419,9.876a3.244,3.244,0,0,1-3.174,2.469A3.177,3.177,0,0,1,0,9.17V.282H3.315V9.734h0L4.938,3.315a4.171,4.171,0,0,1,8.253.846v5.5h0l1.552-6.419A4.161,4.161,0,0,1,18.834,0Z"
                transform="translate(0 0)"
                fill="#f8f8f8"
              />
              <path
                id="Path_3735"
                data-name="Path 3735"
                d="M43.141,1.763h0L43.211.282H46.6V12.2H43.282V10.722h-.141a3.321,3.321,0,0,1-1.058.917,4.508,4.508,0,0,1-1.34.564,5.273,5.273,0,0,1-4.232-.564,5.308,5.308,0,0,1-1.9-2.187A7.064,7.064,0,0,1,33.9,6.207a8.07,8.07,0,0,1,.635-3.245,4.806,4.806,0,0,1,1.9-2.187A5.278,5.278,0,0,1,39.261,0a5.5,5.5,0,0,1,1.552.212,5.293,5.293,0,0,1,1.27.564A2.888,2.888,0,0,1,43.141,1.763ZM41.871,9.17a3.265,3.265,0,0,0,1.058-1.129h0a4.356,4.356,0,0,0,.353-1.763,4.621,4.621,0,0,0-.353-1.763,2.49,2.49,0,0,0-1.058-1.2,2.844,2.844,0,0,0-1.552-.423,3.021,3.021,0,0,0-1.552.423,3.265,3.265,0,0,0-1.058,1.129,4.356,4.356,0,0,0-.353,1.763,4.621,4.621,0,0,0,.353,1.763A2.744,2.744,0,0,0,38.767,9.1a2.608,2.608,0,0,0,1.481.423A3.919,3.919,0,0,0,41.871,9.17Z"
                transform="translate(-9.987)"
                fill="#f8f8f8"
                fillRule="evenodd"
              />
              <path
                id="Path_3736"
                data-name="Path 3736"
                d="M79.841,1.834h.071V.282H83.3v11.5a5.461,5.461,0,0,1-.776,2.822,4.23,4.23,0,0,1-2.187,1.763A7.834,7.834,0,0,1,77.09,17a9.379,9.379,0,0,1-2.963-.423,5.441,5.441,0,0,1-2.116-1.34,3.916,3.916,0,0,1-.988-2.187h3.315a1.746,1.746,0,0,0,.988,1.129,4.718,4.718,0,0,0,1.834.353,3.272,3.272,0,0,0,1.481-.282,1.929,1.929,0,0,0,.917-.917,3.279,3.279,0,0,0,.353-1.481V10.228h-.071a4.078,4.078,0,0,1-1.622,1.27,4.631,4.631,0,0,1-2.257.494,5.334,5.334,0,0,1-2.751-.705,5.91,5.91,0,0,1-1.9-2.046,7.429,7.429,0,0,1-.705-3.1,6.3,6.3,0,0,1,.705-3.1A5.055,5.055,0,0,1,73.21.917,3.873,3.873,0,0,1,75.89,0a4.818,4.818,0,0,1,2.257.494A5.088,5.088,0,0,1,79.841,1.834ZM78.5,8.606a3.778,3.778,0,0,0,1.058-1.058h0a3.678,3.678,0,0,0,.353-1.622A3.919,3.919,0,0,0,79.559,4.3,2.55,2.55,0,0,0,78.5,3.245a2.809,2.809,0,0,0-1.552-.353,3.591,3.591,0,0,0-1.552.353A3.778,3.778,0,0,0,74.339,4.3a3.908,3.908,0,0,0,0,3.245A1.945,1.945,0,0,0,75.4,8.606a2.809,2.809,0,0,0,1.552.353A2.415,2.415,0,0,0,78.5,8.606Z"
                transform="translate(-20.799 0)"
                fill="#f8f8f8"
                fillRule="evenodd"
              />
              <path
                id="Path_3737"
                data-name="Path 3737"
                d="M99.493.776A6.314,6.314,0,0,0,96.249,0,6.891,6.891,0,0,0,93,.776a5.359,5.359,0,0,0-2.257,2.257A7.154,7.154,0,0,0,89.9,6.278a6.364,6.364,0,0,0,.846,3.245,5.8,5.8,0,0,0,2.328,2.187,7.173,7.173,0,0,0,6.49,0,5.2,5.2,0,0,0,2.257-2.187,6.183,6.183,0,0,0,.846-3.245,6.364,6.364,0,0,0-.846-3.245A7.1,7.1,0,0,0,99.493.776Zm-.705,7.266a3.511,3.511,0,0,1-1.058,1.2,2.8,2.8,0,0,1-2.963,0,3.511,3.511,0,0,1-1.058-1.2,3.436,3.436,0,0,1-.423-1.763,3.436,3.436,0,0,1,.423-1.763,3.511,3.511,0,0,1,1.058-1.2,2.438,2.438,0,0,1,1.411-.494,3.021,3.021,0,0,1,1.552.423,3.511,3.511,0,0,1,1.058,1.2,3.622,3.622,0,0,1,.423,1.763,5,5,0,0,1-.423,1.834Z"
                transform="translate(-26.485 0)"
                fill="#f8f8f8"
                fillRule="evenodd"
              />
              <path
                id="Path_3738"
                data-name="Path 3738"
                d="M116.336,0a5.461,5.461,0,0,1,2.822.776,5.8,5.8,0,0,1,1.9,2.187,7.607,7.607,0,0,1,.635,3.245,6.68,6.68,0,0,1-.705,3.245,5.193,5.193,0,0,1-1.9,2.187,5.033,5.033,0,0,1-2.751.776,5.5,5.5,0,0,1-1.552-.212,4.679,4.679,0,0,1-1.34-.635,4.507,4.507,0,0,1-1.058-.917h0v6H109V.282h3.386V1.763h0a6.536,6.536,0,0,1,.988-.988,4.679,4.679,0,0,1,1.34-.635A13.772,13.772,0,0,1,116.336,0Zm.564,9.17a3.265,3.265,0,0,0,1.058-1.129h0a3.435,3.435,0,0,0,.353-1.763,4.356,4.356,0,0,0-.353-1.763A2.36,2.36,0,0,0,116.9,3.386a3.615,3.615,0,0,0-1.552-.423,2.683,2.683,0,0,0-1.552.423,2.961,2.961,0,0,0-1.058,1.2,3.6,3.6,0,0,0-.353,1.763,4.621,4.621,0,0,0,.353,1.763A2.341,2.341,0,0,0,113.8,9.241a2.844,2.844,0,0,0,1.552.423A4.005,4.005,0,0,0,116.9,9.17Z"
                transform="translate(-32.112 0)"
                fill="#f8f8f8"
                fillRule="evenodd"
              />
              <path
                id="Path_3739"
                data-name="Path 3739"
                d="M137.241,1.793h0a4.3,4.3,0,0,0-1.058-.917,4.114,4.114,0,0,0-1.27-.564,5.61,5.61,0,0,0-4.373.564,5.8,5.8,0,0,0-1.9,2.187A7.834,7.834,0,0,0,128,6.307a6.868,6.868,0,0,0,.705,3.245,5.192,5.192,0,0,0,1.9,2.187,5.032,5.032,0,0,0,2.751.776,4.69,4.69,0,0,0,1.481-.212,7.645,7.645,0,0,0,1.34-.564,6.583,6.583,0,0,0,1.058-.917h.071V12.3h3.315V.312h-3.386V1.793ZM136.888,8a2.341,2.341,0,0,1-1.058,1.129,2.844,2.844,0,0,1-1.552.423,2.608,2.608,0,0,1-1.481-.423A3.265,3.265,0,0,1,131.739,8a3.435,3.435,0,0,1-.353-1.763,4.622,4.622,0,0,1,.353-1.763A2.075,2.075,0,0,1,132.8,3.345a3.057,3.057,0,0,1,3.1,0,2.961,2.961,0,0,1,1.058,1.2,4.356,4.356,0,0,1,.353,1.763A3.173,3.173,0,0,1,136.888,8Z"
                transform="translate(-37.71 -0.029)"
                fill="#f8f8f8"
                fillRule="evenodd"
              />
              <path
                id="Path_3740"
                data-name="Path 3740"
                d="M153.283,6.537,155.329.4h3.456l-5.22,13.544a4.117,4.117,0,0,1-1.693,2.187,5.623,5.623,0,0,1-3.1.705H147.5v-2.68h1.27a2.811,2.811,0,0,0,1.2-.212,1.909,1.909,0,0,0,.705-.776l.282-.776L146.3.471h3.456l2.116,6.137c.212.635.423,1.34.705,2.187h.071C152.86,7.877,153.072,7.172,153.283,6.537Z"
                transform="translate(-43.101 -0.118)"
                fill="#f8f8f8"
              />
            </svg>
          </LogoContainer>
          {/*<LogoContainer>*/}
          {/*  <img src={ueLogo} />*/}
          {/*</LogoContainer>*/}
        </PaymentLogoWrapper>
        {mangopayAccount.UserCategory === 'OWNER' ? statusView() : null}
        {mangopayAccount.UserCategory !== 'OWNER' ? (
          <AccountBasicButtonWrapper
            isEditing={isEditing}
            style={isEditing ? {marginBottom: '8px'} : {display: 'none'}}
          >
            <MangoPayBasicButton
              disabled={!isEditing}
              onClick={() => handleType(0)}
              value="person"
              messagesType={formType === 0 ? 'person' : 'company'}
            >
              Osoba fizyczna
            </MangoPayBasicButton>
            <MangoPayBasicButton
              disabled={!isEditing}
              onClick={() => handleType(1)}
              value="company"
              messagesType={formType === 0 ? 'person' : 'company'}
            >
              Działalność gospodarcza
            </MangoPayBasicButton>
          </AccountBasicButtonWrapper>
        ) : (
          // <MangopayStatusWrapper
          //   style={
          //     windowWidth <= 1024
          //       ? {
          //           borderColor: `${
          //             mangopayAccount.KYCLevel === 'LIGHT'
          //               ? light.colors.statusBlue
          //               : mangopayAccount.KYCLevel === 'REGULAR'
          //               ? light.colors.statusGreen
          //               : light.colors.statusRed
          //           }`,
          //           background: `${
          //             mangopayAccount.KYCLevel === 'LIGHT'
          //               ? light.colors.statusBlue
          //               : mangopayAccount.KYCLevel === 'REGULAR'
          //               ? light.colors.statusGreen
          //               : light.colors.statusRed
          //           }`,
          //         }
          //       : {
          //           borderColor: `${
          //             mangopayAccount.KYCLevel === 'LIGHT'
          //               ? light.colors.statusBlue
          //               : mangopayAccount.KYCLevel === 'REGULAR'
          //               ? light.colors.statusGreen
          //               : light.colors.statusRed
          //           }`,
          //           background: `${
          //             mangopayAccount.KYCLevel === 'LIGHT'
          //               ? light.colors.statusBlue
          //               : mangopayAccount.KYCLevel === 'REGULAR'
          //               ? light.colors.statusGreen
          //               : light.colors.statusRed
          //           }`,
          //         }
          //   }
          // >
          //   {mangopayAccount.KYCLevel === 'LIGHT' ? (
          //     <p>Przetwarzany</p>
          //   ) : mangopayAccount.KYCLevel === 'REGULAR' ? (
          //     <p>Zaakceptowany</p>
          //   ) : (
          //     <p>Nieaktywne</p>
          //   )}
          // </MangopayStatusWrapper>
          <StyledStatusWrapper KYCLevelStatus={mangopayAccount.KYCLevel}>
            <h1>{getKYCLevelStatusDescription(mangopayAccount.KYCLevel)}</h1>
            <StyledStatusLine KYCLevelStatus={mangopayAccount.KYCLevel}>
              <div />
            </StyledStatusLine>
          </StyledStatusWrapper>
        )}
      </MangopayHeaderForm>
      <AccountMangoPayFormWrapper isEditing={isEditing}>
        {windowWidth < 1024 && (
          <CenterTextForDataInfo>
            <h4>Dane</h4>
          </CenterTextForDataInfo>
        )}
        <InputView
          isError={
            mangopayErrors.ShowErrors && mangopayErrors.FirstName.isError
          }
          isEditing={isEditing}
          style={
            windowWidth < 1024
              ? null
              : isEditing
              ? {marginTop: '8px'}
              : {marginTop: '8px'}
          }
        >
          <p>Imię</p>
          <input
            type="text"
            placeholder=""
            id="FirstName"
            readOnly={!isEditing}
            value={mangopayAccount.FirstName}
            onChange={handleUserFirstNameInputChange}
          />
          {mangopayErrors.FirstName.isError ? (
            <ErrorStar
              text={'Pole imię jest obowiązkowe. Prosimy uzupełnić dane'}
            />
          ) : null}
        </InputView>
        <InputView
          isError={
            mangopayErrors.ShowErrors && mangopayErrors.LastName.isError
              ? true
              : false
          }
          isEditing={isEditing}
          style={windowWidth < 1024 ? null : {marginTop: '5px'}}
        >
          <p>Nazwisko</p>
          <input
            type="text"
            placeholder=""
            id="LastName"
            readOnly={!isEditing}
            value={mangopayAccount.LastName}
            onChange={handleUserLastNameInputChange}
          />
          {mangopayErrors.LastName.isError ? (
            <ErrorStar
              text={'Pole nazwisko jest obowiązkowe. Prosimy uzupełnić dane'}
            />
          ) : null}
        </InputView>
        <InputView
          isError={mangopayErrors.ShowErrors && mangopayErrors.Email.isError}
          isEditing={isEditing}
          style={windowWidth < 1024 ? null : {marginTop: '5px'}}
        >
          <p>Email</p>
          <input
            type="email"
            placeholder=""
            id="Email"
            readOnly={!isEditing}
            value={mangopayAccount.Email}
            onChange={handleMangopayAccountChange}
          />
          {mangopayErrors.Email.isError ? (
            <ErrorStar text={mangopayErrors.Email.text} />
          ) : null}
        </InputView>
        <InputViewForData
          isError={mangopayErrors.ShowErrors && mangopayErrors.Birthday.isError}
          isEditing={isEditing}
          style={windowWidth < 1024 ? null : {marginTop: '5px'}}
        >
          <p>Data urodzenia</p>
          {isEditing ? (
            <input
              type="date"
              id="Birthday"
              // placeholder="dd-mm-yyyy"
              // pattern="\d{2} \d{2} \d{4}"
              name="date"
              readOnly={!isEditing}
              value={mangopayAccount.Birthday}
              // onChange={handleMangopayAccountChange}
              onChange={handleBirthDayChange}
            />
          ) : (
            <span>
              {' '}
              {new Intl.DateTimeFormat('default', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })
                .format(new Date(mangopayAccount.Birthday))
                .replace(',', '')}
            </span>
          )}
          {mangopayErrors.Birthday.isError ? (
            <ErrorStar
              text={
                'Pole data urodzenia  jest obowiązkowe, minimum 18 lat. Prosimy poprawnie uzupełnić dane.'
              }
            />
          ) : null}
        </InputViewForData>
        <StyledSelectView
          isError={
            mangopayErrors.ShowErrors && mangopayErrors.Nationality.isError
          }
          style={windowWidth < 1024 ? null : {marginTop: '5px'}}
          isEditing={isEditing}
        >
          <p>Obywatelstwo</p>
          <select
            value={mangopayAccount.Nationality}
            disabled={!isEditing}
            onChange={handleMangopayNationality}
          >
            <option hidden value={''}>
              Wybierz
            </option>
            {nationalities.map((item) => (
              <option key={item.FlagValue} value={item.FieldName}>
                {item.DisplayValue}
              </option>
            ))}
            {/* <option value={'PL'}>Polskie</option>
            <option value={'ENG'}>Angielskie</option> */}
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.439"
            height="11"
            viewBox="0 0 6.439 11"
          >
            <g
              id="Arrow_Top_Bar_Accent"
              data-name="Arrow – Top Bar / Accent"
              transform="translate(6.439 11) rotate(180)"
            >
              <path
                id="Arrow"
                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                transform="translate(0 0)"
                fill="#3986ef"
              />
            </g>
          </svg>
          {mangopayErrors.Nationality.isError ? (
            <ErrorStar
              text={'Pole pochodzenie jest obowiązkowe. Prosimy uzupełnić dane'}
            />
          ) : null}
        </StyledSelectView>
        {/* <Select
          value={
            mangopayAccount.Nationality !== null &&
            mangopayAccount.Nationality !== ''
              ? nationalityTypeObj[mangopayAccount.Nationality]
              : {value: '', text: ''}
          }
          values={nationalityTypeOptionsArr}
          onChange={(v) => handleMangopayNationality(v)}
          isEditing={isEditing}
          title="Obywatelstwo"
          selectWidth={
            windowWidth <= 340
              ? '90%'
              : windowWidth <= 400
              ? '311px'
              : windowWidth <= 1024
              ? '358px'
              : '100%'
          }
          customStyles={{
            width:
              windowWidth <= 340
                ? '90%'
                : windowWidth <= 400
                ? '311px'
                : windowWidth <= 1024
                ? '358px'
                : '100%',
            marginTop: '5px',
          }}
          isError={mangopayErrors.ShowErrors && mangopayErrors.Nationality}
        >
          {mangopayErrors.Nationality ? (
            <ErrorStar
              text={'Pole pochodzenie jest obowiązkowe. Prosimy uzupełnić dane'}
            />
          ) : null}
        </Select> */}
        <DescriptionForInput isEditing={isEditing}>Adres</DescriptionForInput>
        <InputView
          isError={
            mangopayErrors.ShowErrors && mangopayErrors.AddressLine1.isError
          }
          isEditing={isEditing}
          style={windowWidth < 1024 ? null : {marginTop: '0px'}}
        >
          <p>Ulica</p>
          <input
            type="text"
            placeholder=""
            id="AddressLine1"
            readOnly={!isEditing}
            value={mangopayAccount.Address.AddressLine1}
            onChange={handleMangopayAddressLineChange}
          />
          {mangopayErrors.AddressLine1.isError ? (
            <ErrorStar
              text={'Pole ulica jest obowiązkowe. Prosimy uzupełnić dane'}
            />
          ) : null}
        </InputView>
        <TwoSmallInputsWrapper>
          {' '}
          <InputView
            isEditing={isEditing}
            isError={
              mangopayErrors.ShowErrors && mangopayErrors.BuildingNumber.isError
            }
            style={
              windowWidth < 374
                ? {width: '141px', marginTop: '0px'}
                : windowWidth < 400
                ? {width: '155px', marginTop: '0px'}
                : windowWidth < 1024
                ? {width: '183px', marginTop: '0px'}
                : {
                    width: '50%',
                    marginTop: '5px',
                  }
            }
          >
            <p>Nr budynku</p>
            <input
              type="text"
              placeholder=""
              id="BuildNumber"
              readOnly={!isEditing}
              value={mangopayAccount.Address.BuildNumber}
              onChange={handleMangopayAddressLineChange}
            />
          </InputView>
          <InputView
            isError={
              mangopayErrors.ShowErrors && mangopayErrors.FlatNumber.isError
            }
            isEditing={isEditing}
            style={
              windowWidth < 374
                ? {width: '135px', marginTop: '0px', marginLeft: '10px'}
                : windowWidth < 400
                ? {width: '150px', marginTop: '0px', marginLeft: '10px'}
                : windowWidth < 1024
                ? {width: '178px', marginTop: '0px', marginLeft: '10px'}
                : {
                    width: '159px',
                    marginTop: '5px',
                  }
            }
          >
            <p>Nr lokalu</p>
            <input
              type="text"
              placeholder=""
              id="FlatNumber"
              readOnly={!isEditing}
              value={mangopayAccount.Address.FlatNumber}
              onChange={handleMangopayAddressLineChange}
            />
          </InputView>
        </TwoSmallInputsWrapper>
        <TwoSmallInputsWrapper>
          <InputView
            isError={
              mangopayErrors.ShowErrors && mangopayErrors.PostalCode.isError
            }
            isEditing={isEditing}
            style={
              windowWidth < 374
                ? {width: '141px', marginTop: '0px'}
                : windowWidth < 400
                ? {width: '155px', marginTop: '0px'}
                : windowWidth < 1024
                ? {width: '183px', marginTop: '0px'}
                : {
                    width: '50%',
                    marginTop: '5px',
                  }
            }
          >
            <p>Kod pocztowy</p>
            <input
              style={{paddingLeft: '70px'}}
              type="text"
              placeholder=""
              id="PostalCode"
              readOnly={!isEditing}
              maxLength={6}
              value={mangopayAccount.Address.PostalCode}
              onChange={handlePostalCodeChange}
            />
            {mangopayErrors.PostalCode.isError ? (
              <ErrorStar
                text={
                  'Pole kod pocztowy jest obowiązkowe. Prosimy uzupełnić dane'
                }
              />
            ) : null}
          </InputView>
          <InputView
            isError={mangopayErrors.ShowErrors && mangopayErrors.City.isError}
            isEditing={isEditing}
            style={
              windowWidth < 374
                ? {width: '135px', marginTop: '0px', marginLeft: '10px'}
                : windowWidth < 400
                ? {width: '150px', marginTop: '0px', marginLeft: '10px'}
                : windowWidth < 1024
                ? {width: '178px', marginTop: '0px', marginLeft: '10px'}
                : {
                    width: '159px',
                    marginTop: '5px',
                  }
            }
          >
            <p style={windowWidth > 1024 ? null : {left: '7px'}}>Miasto</p>
            <input
              style={{paddingLeft: '70px'}}
              type="text"
              placeholder=""
              id="City"
              readOnly={!isEditing}
              value={mangopayAccount.Address.City}
              onChange={handleMangopayAddressLineChange}
            />
            {mangopayErrors.City.isError ? (
              <ErrorStar
                text={'Pole miasto jest obowiązkowe. Prosimy uzupełnić dane'}
              />
            ) : null}
          </InputView>
        </TwoSmallInputsWrapper>
        <StyledSelectView
          isError={mangopayErrors.ShowErrors && mangopayErrors.Country.isError}
          style={windowWidth < 1024 ? null : {marginTop: '5px'}}
          isEditing={isEditing}
        >
          <p>Kraj</p>
          <select
            value={mangopayAccount.Address.Country}
            disabled={!isEditing}
            onChange={handleMangopayCountry}
          >
            <option hidden value={''}>
              Wybierz
            </option>
            {countries.map((item) => (
              <option key={item.FlagValue} value={item.FieldName}>
                {item.DisplayValue}
              </option>
            ))}
            {/* <option value={'PL'}>Polska</option>
            <option value={'ENG'}>Anglia</option> */}
          </select>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.439"
            height="11"
            viewBox="0 0 6.439 11"
          >
            <g
              id="Arrow_Top_Bar_Accent"
              data-name="Arrow – Top Bar / Accent"
              transform="translate(6.439 11) rotate(180)"
            >
              <path
                id="Arrow"
                d="M5.117,10.776.157,5.873a.523.523,0,0,1,0-.747L5.117.224a.781.781,0,0,1,1.095,0,.76.76,0,0,1,0,1.082L1.969,5.5,6.212,9.694a.76.76,0,0,1,0,1.082.781.781,0,0,1-1.095,0"
                transform="translate(0 0)"
                fill="#3986ef"
              />
            </g>
          </svg>
          {mangopayErrors.Country.isError ? (
            <ErrorStar
              text={'Pole kraj jest obowiązkowe. Prosimy uzupełnić dane'}
            />
          ) : null}
        </StyledSelectView>
        {/* <Select
          value={
            mangopayAccount.Address.Country !== null &&
            mangopayAccount.Address.Country !== ''
              ? nationalityTypeObj[mangopayAccount.Address.Country]
              : {value: '', text: ''}
          }
          values={nationalityTypeOptionsArr}
          isError={mangopayErrors.ShowErrors && mangopayErrors.Country}
          onChange={(v) => handleMangopayCountry(v)}
          isEditing={isEditing}
          title="Kraj"
          selectWidth={
            windowWidth <= 340
              ? '90%'
              : windowWidth <= 400
              ? '311px'
              : windowWidth <= 1024
              ? '358px'
              : '100%'
          }
          customStyles={{
            width:
              windowWidth <= 340
                ? '90%'
                : windowWidth <= 400
                ? '311px'
                : windowWidth <= 1024
                ? '358px'
                : '100%',
            marginTop: '5px',
          }}
        >
          {mangopayErrors.Country ? (
            <ErrorStar
              text={'Pole kraj jest obowiązkowe. Prosimy uzupełnić dane'}
            />
          ) : null}
        </Select> */}
        <DescriptionForInput isEditing={isEditing}>
          Rachunek bankowy
        </DescriptionForInput>
        <InputView
          style={windowWidth < 1024 ? null : {marginTop: '5px'}}
          isEditing={isEditing}
          isError={mangopayErrors.ShowErrors && mangopayErrors.IBAN.isError}
        >
          <p
            style={
              windowWidth <= light.breakpointsJs.mobile
                ? null
                : {fontSize: '12px'}
            }
          >
            {windowWidth <= 400 ? '' : 'Numer'}
          </p>
          <input
            style={
              windowWidth <= 400
                ? {paddingLeft: '0px', paddingRight: '0px', textAlign: 'center'}
                : {paddingLeft: '0px'}
            }
            type="text"
            placeholder=""
            readOnly={!isEditing}
            id="IBAN"
            onChange={handleMangopayIBAN}
            // value={mangopayAccount.IBAN.replace(/[^0-9]/g, '')
            //   .replace(/\s/g, '')
            //   .replace(/(\d{4})/g, '$1 ')
            //   .trim()}
            value={formatIBAN(mangopayAccount.IBAN)}
            maxLength={32}
          />
          {mangopayErrors.IBAN.isError ? (
            <ErrorStar
              text={
                'Pole nr konta bankowego jest obowiązkowe. Prosimy uzupełnić dane'
              }
            />
          ) : null}
        </InputView>

        {mangopayAccount.DocIdentityProof.Status !== 'VALIDATED' &&
        mangopayAccount.DocIdentityProof.Status !== 'VALIDATION_ASKED' ? (
          <IdScansWrapper
            style={
              mangopayAccount.Status === 4 || mangopayAccount.Status === 1
                ? {paddingTop: '10px'}
                : null
            }
          >
            <p>Skan dowodu tożsamości</p>
            <RelativeDiv style={{top: '-27px', right: '-213px'}}>
              <ErrorStar
                onlineInfo={true}
                color="black"
                text={
                  'Paszport, dowód osobisty, prawo jazdy lub zezwolenie na pobyt. Skan powinien być w poziomie'
                }
              />
            </RelativeDiv>
            <IdScansButtonWrapper isEditing={isEditing}>
              <p>Przód</p>
              <button
                disabled={!isEditing}
                onClick={() => {
                  setBtnType((prevState) => ({...prevState, front: true}));
                  inputIdFileRef.current.click();
                }}
                style={
                  validateScans.front
                    ? {background: '#00d10f'}
                    : mangopayAccount.DocIdentityProof.Status ===
                        'OUT_OF_DATE' ||
                      mangopayAccount.DocIdentityProof.Status === 'REFUSED' ||
                      mangopayAccount.DocIdentityProof.Status === 'CREATED' ||
                      mangopayAccount.DocIdentityProof.Status === 'null'
                    ? {background: '#FF7E7D'}
                    : idScansFrontError.showErrors &&
                      idScansFrontError.frontId.isError
                    ? {background: '#FF2320'}
                    : null
                }
              >
                {validateScans.front ? 'Dodano' : 'Dodaj'}
              </button>
              {validateScans.front ? null : mangopayAccount.DocIdentityProof
                  .Status === 'OUT_OF_DATE' ||
                mangopayAccount.DocIdentityProof.Status === 'REFUSED' ||
                mangopayAccount.DocIdentityProof.Status === 'CREATED' ||
                mangopayAccount.DocIdentityProof.Status === 'null' ? (
                <ErrorStar
                  text={
                    mangopayAccount.DocIdentityProof.RefusedReasonType !== null
                      ? mangopayRefusedReasonType[
                          mangopayAccount.DocIdentityProof.RefusedReasonType
                        ]
                      : mangopayRefusedReasonType[
                          mangopayAccount.DocIdentityProof.Status
                        ]
                  }
                />
              ) : idScansFrontError.showErrors &&
                idScansFrontError.frontId.isError ? (
                <ErrorStar text={idScansFrontError.frontId.text} />
              ) : null}
            </IdScansButtonWrapper>
            <input
              type="file"
              onChange={getIdImage}
              accept=".jpeg,.jpg,.png"
              hidden
              ref={inputIdFileRef}
            />
            <input
              type="file"
              onChange={getIdImage}
              accept=".jpeg,.jpg,.png"
              hidden
              ref={inputIdFileBackRef}
            />
            <IdScansButtonWrapper isEditing={isEditing}>
              <p>Tył</p>
              <button
                disabled={!isEditing}
                onClick={() => {
                  setBtnType((prevState) => ({...prevState, back: true}));
                  inputIdFileBackRef.current.click();
                }}
                style={
                  validateScans.back
                    ? {background: '#00d10f'}
                    : mangopayAccount.DocIdentityProof.Status ===
                        'OUT_OF_DATE' ||
                      mangopayAccount.DocIdentityProof.Status === 'REFUSED' ||
                      mangopayAccount.DocIdentityProof.Status === 'CREATED' ||
                      mangopayAccount.DocIdentityProof.Status === 'null'
                    ? {background: '#FF7E7D'}
                    : idScansFrontError.showErrors &&
                      idScansFrontError.backId.isError
                    ? {background: '#FF2320'}
                    : null
                }
              >
                {validateScans.back ? 'Dodano' : 'Dodaj'}
              </button>
              {validateScans.back ? null : mangopayAccount.DocIdentityProof
                  .Status === 'OUT_OF_DATE' ||
                mangopayAccount.DocIdentityProof.Status === 'REFUSED' ||
                mangopayAccount.DocIdentityProof.Status === 'CREATED' ||
                mangopayAccount.DocIdentityProof.Status === 'null' ? (
                <ErrorStar
                  text={
                    mangopayAccount.DocIdentityProof.RefusedReasonType !== null
                      ? mangopayRefusedReasonType[
                          mangopayAccount.DocIdentityProof.RefusedReasonType
                        ]
                      : mangopayRefusedReasonType[
                          mangopayAccount.DocIdentityProof.Status
                        ]
                  }
                />
              ) : idScansFrontError.showErrors &&
                idScansFrontError.backId.isError ? (
                <ErrorStar text={idScansFrontError.backId.text} />
              ) : null}
            </IdScansButtonWrapper>
          </IdScansWrapper>
        ) : null}
        {mangopayAccount.UserCategory !== 'OWNER' ? (
          <AccountBoxWrapper
            style={mangopayAccount.Status === 4 ? {marginTop: '0px'} : null}
          >
            <AccountBoxText>
              <a
                target="blank"
                href="https://letmegooglethat.com/?q=regulamin+mango+pay"
                style={
                  mangopayErrors.ShowErrors &&
                  !mangopayAccount.TermsAndConditionsAccepted
                    ? {color: '#FF0300'}
                    : null
                }
              >
                Regulamin MangoPay
              </a>
              {/* <span>Pobierz regulamin w formie PDF.</span> */}
            </AccountBoxText>
            <AccountBox
              isActive={mangopayAccount.TermsAndConditionsAccepted}
              onClick={handleMangoPayRegulationsChange}
            >
              <BlueBox isActive={mangopayAccount.TermsAndConditionsAccepted} />
            </AccountBox>
          </AccountBoxWrapper>
        ) : null}
        {mangopayAccount.UserCategory === 'OWNER' ? (
          <AccountBigBlueBtnWrapper
            isNaturalBottomPosition={true}
            hidden={!isEditing}
            isEditing={isEditing}
          >
            <PopupButton
              onClick={checkDataBeforeSend}
              buttons={['Rezygnuje', 'Akceptuje']}
              title="Chcesz wysłać zmiany konta na mangopay?"
              text="Wysłanie zmian spowoduje zmianę konta na nieaktywne, do czasu ponownej weryfikacji danych przez MangoPay. "
            >
              <AccountBigBlueBtn> Wyślij dane ponownie</AccountBigBlueBtn>
              {/* Wyślij dane ponownie */}
            </PopupButton>
          </AccountBigBlueBtnWrapper>
        ) : (
          <AccountBigBlueBtn
            hidden={!isEditing}
            disabled={!isEditing}
            onClick={checkDataBeforeSend}
            style={
              windowWidth <= light.breakpointsJs.mobile
                ? {
                    background: '#3986EF',
                    position: 'unset',
                    bottom: 'unset',
                    left: 'unset',
                    margin: '0 auto',
                  }
                : {
                    position: 'absolute',
                    bottom: '11px',
                    left: '11px',
                    background: '#3986EF',
                  }
            }
          >
            Załóż portfel
          </AccountBigBlueBtn>
        )}
      </AccountMangoPayFormWrapper>
    </AccountMangopayWrapper>
  );
};
