export enum MessagesType {
  RECEIVED = 'received',
  SENT = 'sent',
}

export interface Status {
  FieldName: string;
  FlagValue: number;
}

export interface Message {
  Id: string;
  Thread: string;
  Topic: string;
  Content: string;
  SentDate?: Date;
  SenderStatus: Status;
  RecieverStatus: Status;
  SenderId: string;
  SenderName: string;
  RecieverId: string;
  RecieverName: string;
  IsCurrentUserSender: boolean;
  RecieverPhotoFilePath?: string;
  SenderPhotoFilePath?: string;
  AllowResponse?: boolean;
}
