import styled from 'styled-components';

export const SocialMediaWrapper = styled.div`
  width: 100%;
  height: 100%;
`;
export const SocialMediaBlockWrapper = styled.div<{
  isEditing: boolean;
  socialType: number;
  itSaved: boolean;
  backgroundAlpha: number;
  backgroundColor: string;
}>`
  width: 100%;
  height: 100%;
  /* position: absolute; */
  padding: 16px;
  display: flex !important;
  /* flex-wrap: wrap; */
  justify-content: center;
  overflow-y: scroll;
  background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`};

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #706e81;
    border-radius: 10px;
    border: 1px solid transparent;
  }
  @media (max-width: 375px) {
    padding: 14px;
  }
  @media (max-width: 340px) {
    padding: 10px;
  }
`;

export const SocialMediaBlockContentWrapper = styled.div`
  width: 160px;
  height: 100%;
  border-radius: 13px;
  border: 1px solid rgba(255, 255, 255, 0.21);
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.3);

  @media (max-width: 1024px) {
    width: 45px;
    display: flex;
    justify-content: center;
  }
  /* @media (max-width: 360px) {
    width: 120px;
  } */
`;

export const SocialMediaSvgAndNameWrapper = styled.div<{
  Width: number;
  mobileOverview: boolean;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({Width}) => (Width === 1 ? 'center' : 'flex-start')};
  padding: ${({Width}) => (Width === 1 ? '0' : '0px')};
  & > svg {
    margin-left: ${({Width}) => (Width === 1 ? '0' : '5px')};
    width: 21px;
    height: 21px;
  }
  & > p {
    display: ${({mobileOverview}) => (mobileOverview ? 'none' : 'block')};
    color: white;
    margin-left: 12px;
    font-size: ${({Width}) => (Width === 2 ? '18px' : '18px')};
    line-height: 32px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
  }
  & > input {
    margin-left: 22px;
    width: 70%;
    border: 1px solid rgba(255, 255, 255, 0.21);
    padding: 2px;
    border-radius: 5px;
    color: white;
    background: transparent;
    &:focus {
      outline: none;
    }
  }
  @media (max-width: 1024px) {
    & > p {
      display: none;
    }
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
  @media (max-width: 360px) {
    & > svg {
      width: 18px;
      height: 18px;
    }
    /* & > p {
      font-size: 15px;
      margin-left: 9px;
    } */
  }
`;

export const EditSocialWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-items: center;

  & > input {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 80%;
    border: 1px solid white;
    padding: 2px;
    border-radius: 5px;
    background: transparent;
  }
  & > button {
    cursor: pointer;
    border-radius: 6px;
    background: transparent;
    border: 1px solid white;
    color: white;
    width: 60px;
    justify-self: flex-end;
    height: 21px;
    font-size: 12px;
  }
`;

export const SaveBtn = styled.button`
  cursor: pointer;
  border-radius: 6px;
  background: transparent;
  border: 1px solid white;
  color: white;
  width: 60px;
  justify-self: flex-end;
  height: 21px;
  font-size: 12px;
  margin-left: 10px;
`;

export const SliderWrapper = styled.div<{isMobile: boolean}>`
  /* width: 100%; */
  width: ${({isMobile}) => (isMobile ? '100%' : '100%')};
  align-self: ${({isMobile}) => (isMobile ? 'center' : ' none')};
  height: 56px;
  max-height: 56px;
  overflow-y: hidden;

  .slick-dots {
    bottom: -66px;
  }

  .slick-dots li {
    border: 1px solid ${({theme}) => theme.colors.fontBlackOp100};
    height: 11px;
    width: 11px;
    margin: 0 12px;
    border-radius: 50%;
  }

  .slick-dots li button::before {
    display: none;
  }

  .slick-dots .slick-active {
    background: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    overflow: hidden;
    width: 100%;

    align-self: center;
    .slick-dots {
      bottom: 20px;
    }
  }

  @media (max-width: 420px) {
    .slick-dots {
      bottom: 25px;
    }
  }
`;
