import styled, {css, keyframes} from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 3px; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none;
  }
`;

export const DetailArrowButton = styled.div<{isActive?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 19px;
  height: 19px;
  position: absolute;
  cursor: pointer;
  bottom: 3px;
  left: calc(50% - 9.5px);
  background: ${({isActive, theme}) =>
    isActive ? '#0091FF' : theme.colors.transactionArrowButtonBackground};

  & > svg {
    & path {
      fill: ${({isActive}) => (isActive ? '#FFFFFF' : '#0091FF')};
    }
  }
`;

export const MobileTransactionNameWithNumberWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;

  & > h2 {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > h1 {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.15px;
    line-height: 16px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }

  & > p {
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.05px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const MobileSingleTransitionWithStatusWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 11px 20px 18px;

  & > h1 {
    font-weight: bold;
    font-size: 15px;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > p {
    font-weight: 500;
    font-size: 13px;
    letter-spacing: -0.05px;
    line-height: 16px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
`;

export const MobileMainWrapper = styled.div<{isDetailsOpen: boolean}>`
  min-height: 116px;
  border-radius: 14px;
  position: relative;
  background: ${({theme}) => theme.colors.bgWhite};
  border: ${({isDetailsOpen}) =>
    isDetailsOpen ? '1px solid #0091ff' : 'unset'};
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: all 0.15s;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &:first-child {
    margin-top: 135px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    width: 375px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: calc(100% - 14px);
  }
`;

export const WithdrawMenuContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;

  & > p {
    color: black;
    font-size: 11px;
    line-height: 15px;
    text-align: justify;
  }
`;

export const WithdrawNewButton = styled.button`
  background: #0091ff;
  color: white;
  font-weight: 500;
  width: 239px !important;
  height: 32px;
  cursor: pointer;
  border: none;
  border-radius: 7px;
  text-align: center;
  padding: 5px 0px;
  font-size: 11px;
  margin-top: 40px;
`;

export const CloseComplaintWindowButton = styled.div`
  position: absolute;
  right: 14px;
  top: 3px;
  cursor: pointer;
  & > svg {
    & path {
      fill: ${({theme}) => theme.colors.bgWhite};
    }
  }
`;

export const SingleTransactionMain = styled.div<{isDetailsOpen: boolean}>`
  border-radius: ${({isDetailsOpen}) =>
    isDetailsOpen ? '16px 16px 0 0' : '16px'};
  background: ${({theme}) => theme.colors.transactionMainBackground};
`;

export const SingleTransactionWrapper = styled.div`
  width: 100%;
  height: 92px;
  border-radius: 16px;
  /* border: 1px solid ${({theme}) => theme.colors.transactionBorderColor}; */
  background: ${({theme}) => theme.colors.transactionMainBackground};
  display: grid;
  align-items: center;
  justify-content: space-between;
  /* padding: 14px 0; */
  grid-template-columns: 156px 130px 166px 148px 143px 85px 155px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.12);
  position: relative;
`;

export const MobileSingleTransactionWrapper = styled.div`
  width: 100%;
  background: white;
  display: grid;
  align-items: center;
  justify-content: space-between;
  gap: 7px 9px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 67px);
  position: relative;
`;

export const SingleSection = styled.div`
  width: 100%;
  /* height: 36px; */
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-all;
  flex-direction: column;
  gap: 8px;
  border-right: 1px solid ${({theme}) => theme.colors.fontBlackOp15};

  & > h4 {
    color: white;
    font-size: 15px;
    font-weight: 400;
  }

  & > p {
    font-size: 9px;
    color: ${({theme}) => theme.colors.fontBlackOp70};
    letter-spacing: 0.25px;
  }

  & > h5 {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: 0.1px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 138px;
    text-align: center;
  }

  &:nth-last-child(-n + 3) {
    border-right: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    border-radius: 9px;
    border-right: 1px solid rgba(112, 112, 112, 0.3);
    border: 1px solid rgba(112, 112, 112, 0.3);

    &:nth-last-child(-n + 3) {
      border-right: 1px solid rgba(112, 112, 112, 0.3);
    }

    &:last-child {
      border: none;
    }
  }
`;

export const SingleSectionPriceWithWinWin = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  & > h5 {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: 0.1px;
    font-weight: 400;
  }

  & > svg {
    margin-top: 3px;
  }
`;

export const MobileBlackArrowSvg = styled.svg`
  position: absolute;
  right: 14px;
  top: 71px;
`;

export const WinWinInfo = styled.div`
  height: 22px;
  width: 94px;
  border: 1px solid #fb9614;
  display: flex;
  gap: 8.7px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;

  & > p {
    color: #fb9614;
    font-size: 9px;
    font-weight: 500;
  }

  & > img {
    width: 12.35px;
    height: 8px;
  }

  @media (max-width: 1100px) {
    margin-right: unset;
  }
`;

export const PrimaryButton = styled.button<{
  isActive?: boolean;
  isTabButton?: boolean;
}>`
  transition: unset !important;
  border-radius: 14px 14px 0px 0px;
  border: none;
  /* border: 1px solid ${({theme}) => theme.colors.transactionBorderColor}; */
  background: transparent;
  font-size: 13px;
  color: ${({theme}) => theme.colors.fontBlackOp70};
  cursor: pointer;
  /* transition: all 0.15s; */
  font-weight: 400;
  min-width: 138px;
  padding: 0 20px;
  height: 48px;
  letter-spacing: -0.05px;
  /* box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.12); */

  ${({isActive}) =>
    isActive &&
    css`
      background: #0091ff;
      color: #ffffff;
    `}

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-width: ${({isTabButton}) => (isTabButton ? '92px' : '119px')};
  }
`;

export const DeleteOrderButton = styled.div`
  width: 38px;
  height: 38px;

  & > button {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 4px;
    background: #ff7e7d;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.15s;

    &:hover {
      filter: brightness(1.1);
    }

    &:active {
      filter: brightness(0.9);
    }
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  margin: 4px 0px;
  align-items: center;
  justify-content: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-wrap: wrap;
  }
`;

export const TransactionDetailsWrapper = styled.div<{isDetailsOpen: boolean}>`
  width: 983px;
  /* height: ${({isDetailsOpen}) => (isDetailsOpen ? 'unset' : '0px')}; */
  height: ${({isDetailsOpen}) => (isDetailsOpen ? '264px' : '0')};
  /* visibility: ${({isDetailsOpen}) =>
    isDetailsOpen ? 'unset' : 'hidden'}; */
  /* padding: ${({isDetailsOpen}) => (isDetailsOpen ? '8px 10px' : '0 0')}; */
  background: ${({theme}) => theme.colors.transactionDetailBackgroundColor};
  /* transition: height 0.25s ease-in, padding 0s ease-in; */
  transition: ${({isDetailsOpen}) =>
    isDetailsOpen
      ? 'height 0.25s ease-in, padding 0s ease-in'
      : 'height 0.25s ease-in, padding 0.25s ease-in'};
  transition-delay: ${({isDetailsOpen}) => (isDetailsOpen ? '0.25s' : 'unset')};
  border-radius: 0 0 15px 15px;
  /* box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.12); */

  * {
    transition: all 0.2s;
    transition-delay: ${({isDetailsOpen}) =>
      isDetailsOpen ? '0.4s' : 'unset'};
    opacity: ${({isDetailsOpen}) => (isDetailsOpen ? '1' : '0')};
    user-select: ${({isDetailsOpen}) => (isDetailsOpen ? 'unset' : 'none')};
    pointer-events: ${({isDetailsOpen}) => (isDetailsOpen ? 'unset' : 'none')};
    /* max-height: ${({isDetailsOpen}) => (isDetailsOpen ? 'unset' : '0px')};
    height: ${({isDetailsOpen}) => (isDetailsOpen ? 'unset' : '0px')}; */
  }
`;

export const MobileTransactionDetailsWrapper = styled.div<{
  isDetailsOpen: boolean;
}>`
  width: 100%;
  height: ${({isDetailsOpen}) => (isDetailsOpen ? 'auto' : '0')};
  min-height: ${({isDetailsOpen}) =>
    isDetailsOpen ? 'calc(100vh - 112px)' : '0'};
  padding: ${({isDetailsOpen}) => (isDetailsOpen ? '8px 10px' : '0 0')};
  background: ${({theme}) => theme.colors.mobileNavbarActiveItemBg};
  transition: ${({isDetailsOpen}) =>
    isDetailsOpen
      ? 'height 0.25s ease-in, padding 0s ease-in'
      : 'height 0.25s ease-in, padding 0.25s ease-in'};
  transition-delay: ${({isDetailsOpen}) => (isDetailsOpen ? '0.25s' : 'unset')};

  * {
    transition: all 0.2s;
    transition-delay: ${({isDetailsOpen}) =>
      isDetailsOpen ? '0.4s' : 'unset'};
    opacity: ${({isDetailsOpen}) => (isDetailsOpen ? '1' : '0')};
    user-select: ${({isDetailsOpen}) => (isDetailsOpen ? 'unset' : 'none')};
    pointer-events: ${({isDetailsOpen}) => (isDetailsOpen ? 'unset' : 'none')};
  }
`;

export const UserDataTabWrapper = styled.div`
  width: 100%;
  height: 58px;
  display: grid;
  padding-top: 15px;
  grid-template-columns: repeat(3, 1fr);

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${({theme}) => theme.colors.fontBlackOp15};

    &:last-of-type {
      border-right: none;
    }

    & > p {
      font-size: 9px;
      line-height: 15px;
      color: ${({theme}) => theme.colors.fontBlackOp70};
      font-weight: 400;
      text-align: center;
    }

    & > h5 {
      font-size: 13px;
      line-height: 24px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 300px;
      text-align: center;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-bottom: 10px;
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    padding-top: 5px;

    & > div {
      border-right: none;
      padding-top: 5px;
      padding-bottom: 10px;
      border-bottom: 1px solid
        ${({theme}) => theme.colors.mobileTransactionTopBorder};

      & > p {
        font-size: 13px;
        color: ${({theme}) => theme.colors.fontBlackOp50};
        line-height: 16px;
      }

      & > h5 {
        line-height: 21px;
        font-size: 13px;
      }
    }
  }
`;

export const FileDownloadButton = styled.button<{isWinWin?: boolean}>`
  background: #0091ff;
  cursor: pointer;
  /* position: absolute; */
  border: none;
  border-radius: 4px;
  min-width: 110px;
  height: 19px;
  padding: 0 6px;
  font-weight: 500;
  font-size: 11px;
  color: white;
  left: ${({isWinWin}) => (isWinWin ? '734px' : '758px')};
  top: 104px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border-radius: 14px;
    width: 104px;
    height: 38px;
    min-width: unset;
    border: none;
    background: #008efa;
    color: #ffffff;
    font-size: 15px;
    letter-spacing: 0.07px;
  }
`;

export const MobileDocumentsAddFileButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 9px;
  justify-content: center;

  & > button {
    border-radius: 14px;
    width: 104px;
    height: 38px;
    border: 1px solid #008efa;
    background: #f8f8f8;
    color: #008efa;
    font-size: 15px;
    letter-spacing: 0.07px;
  }
`;

export const SingleDocumentsTabHeaderWrapper = styled.div`
  width: 100%;
  min-height: 10px;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${({theme}) => theme.colors.fontBlackOp15};
    /* border-bottom: 1px solid ${({theme}) => theme.colors.fontBlackOp15}; */

    &:last-of-type {
      border-right: none;
    }

    & > p {
      font-size: 9px;
      line-height: 15px;
      color: ${({theme}) => theme.colors.fontBlackOp70};
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 214px;
      text-align: center;
    }
  }
`;

export const MobileDocumentsTabWrapper = styled.div`
  width: 100%;
  min-height: 26px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${({theme}) => theme.colors.fontBlackOp15};

    &:last-of-type {
      border-right: none;
    }

    & > p {
      font-size: 9px;
      line-height: 15px;
      color: ${({theme}) => theme.colors.fontBlackOp70};
      font-weight: 400;
      text-align: center;
    }

    & > h5 {
      font-size: 13px;
      line-height: 24px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 214px;
      text-align: center;
    }

    & > a {
      text-decoration: underline;
      font-size: 13px;
      line-height: 24px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 214px;
      text-align: center;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    padding-top: 0px;
    gap: 7px;

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-right: none;
      background: rgba(0, 0, 0, 0.02);

      & > div {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-right: none;
        padding-top: 5px;
        padding-bottom: 10px;
        border-bottom: 1px solid
          ${({theme}) => theme.colors.mobileTransactionTopBorder};

        & > p {
          font-size: 13px;
          line-height: 16px;
          color: ${({theme}) => theme.colors.fontBlackOp50};
          font-weight: 400;
          text-align: center;
        }

        & > h5 {
          font-size: 13px;
          line-height: 21px;
          color: ${({theme}) => theme.colors.fontBlackOp100};
          font-weight: 400;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 214px;
          text-align: center;
        }
      }
    }
  }
`;

export const SingleDocumentsTabWrapper = styled.div`
  width: 100%;
  min-height: 26px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${({theme}) => theme.colors.fontBlackOp15};

    &:last-of-type {
      border-right: none;
    }

    & > p {
      font-size: 9px;
      line-height: 15px;
      color: ${({theme}) => theme.colors.fontBlackOp70};
      font-weight: 400;
      text-align: center;
    }

    & > h5 {
      font-size: 13px;
      line-height: 24px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 214px;
      text-align: center;
    }

    & > a {
      text-decoration: underline;
      font-size: 13px;
      line-height: 24px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 214px;
      text-align: center;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-bottom: 10px;
    grid-template-columns: repeat(1, 1fr);
    height: auto;

    & > div {
      border-right: none;
    }
  }
`;

export const ProductTab = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 200px;
  padding-top: 15px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #0091ff;
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.colors.transactionScrollbarBackground};
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: unset;
    padding-top: 5px;
  }
`;

export const SingleProductTabItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  & > p {
    font-size: 12px;
    line-height: 15px;
    color: ${({theme}) => theme.colors.fontBlackOp70};
    font-weight: 400;
    text-align: center;
  }
`;

export const ProductsTabWrapper = styled.div`
  width: 100%;
  height: 58px;
  display: grid;
  grid-template-columns: 500px 140px 100px 100px 100px;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${({theme}) => theme.colors.fontBlackOp15};

    &:last-of-type {
      border-right: none;
    }

    & > p {
      font-size: 9px;
      line-height: 15px;
      color: ${({theme}) => theme.colors.fontBlackOp70};
      font-weight: 400;
      text-align: center;
    }

    & > h5 {
      font-size: 13px;
      line-height: 24px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      font-weight: 400;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      /* width: 176px; */
      text-align: center;
    }

    &:first-of-type {
      & > h5 {
        width: 494px;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-bottom: 10px;
    grid-template-columns: repeat(1, 1fr);
    height: unset;

    & h5 {
      width: unset !important;
    }

    & > div {
      border-right: none;
      padding-top: 5px;
      padding-bottom: 10px;
      border-bottom: 1px solid
        ${({theme}) => theme.colors.mobileTransactionTopBorder};

      & > p {
        font-size: 13px;
        color: ${({theme}) => theme.colors.fontBlackOp50};
        line-height: 16px;
      }

      & > h5 {
        line-height: 21px;
        font-size: 13px;
      }
    }
  }
`;

export const DocumentsTabWrapper = styled.div<{documentLength: number}>`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  padding-top: 15px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #0091ff;
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.colors.transactionScrollbarBackground};
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const StatusHistoryTabMain = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  /* justify-content: space-between; */
  /* align-items: center; */
  position: relative;
  align-items: center;
`;

export const StatusHistoryTabWrapper = styled.div`
  width: 90%;
  height: 120px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* align-items: center; */
  position: relative;
  overflow-x: auto;
  margin-bottom: 6px;
  margin-top: 13px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #0091ff;
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.colors.transactionScrollbarBackground};
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    width: 94%;
    height: unset;
    overflow-x: unset;
  }
`;

const dash = keyframes`
  to {
    background-position: 15px 0;
  }
`;

export const RedHr = styled.hr<{isWinWin?: boolean}>`
  width: 146px;
  height: 2px;
  border: none;
  /* border-bottom:2px dashed #FF0300; */
  position: absolute;
  left: ${({isWinWin}) => (isWinWin ? '66px' : '91px')};
  background-image: repeating-linear-gradient(
    to right,
    #ff0300,
    #ff0300 10px,
    transparent 10px,
    transparent 15px
  );
  background-position: 0 0;
  background-size: 30px 100%;
  animation: ${dash} 1s linear infinite;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 2px !important;
    height: 32px !important;
    left: 12px;
    top: 30px;
  }
`;

export const StatusHistoryInfo = styled.h5<{isWinWin?: boolean}>`
  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  /* position: absolute; */
  width: 100%;
  text-align: center;
  left: ${({isWinWin}) => (isWinWin ? '296px' : '320px')};
  top: 132px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    text-align: center;
    left: 0px;
    top: unset;
    bottom: 0px;
    width: 100%;
  }
`;

export const StatusHistoryInfoStatus6a = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '140px' : '164px')};
  text-align: center;
  top: 102px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: 50px;
  }
`;

export const StatusHistoryInfoStatus8 = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '570px' : '594px')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    left: 0px;
    bottom: 50px;
    top: unset;
    text-align: center;
  }
`;

export const StatusHistoryInfoStatus8a = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '564px' : '588px')};
  top: 132px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: 50px;
  }
`;

export const StatusHistoryInfoStatus9a = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '496px' : '520px')};
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: 00px;
  }
`;

export const StatusHistoryInfoStatus10a = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '460px' : '484px')};
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: 0px;
  }
`;

export const StatusHistoryInfoStatus10 = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '492px' : '516px')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: 50px;
  }
`;

export const StatusHistoryInfoStatus13a = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '798px' : '798px')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: 50px;
  }
`;

export const StatusHistoryInfoStatus13b = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '542px' : '542px')};
  top: 106px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: 50px;
  }
`;

export const StatusHistoryInfoStatus14 = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '708px' : '708px')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: 50px;
  }
`;

export const StatusHistoryInfoStatus6b2 = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '124px' : '148px')};
  top: 102px;
  text-align: center;
  width: 100%;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: 0px;
  }
`;

export const StatusHistoryInfoStatus6b = styled(StatusHistoryInfo)`
  left: ${({isWinWin}) => (isWinWin ? '164px' : '188px')};
  text-align: center;
  top: 54px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 0px;
    width: 100%;
    text-align: center;
    top: unset;
    bottom: 80px;
  }
`;

export const StatusHistoryButton = styled.button`
  cursor: pointer;
  background: #0091ff;
  border: none;
  border-radius: 4px;
  height: 19px;
  width: 126px;
  padding: 0 6px;
  font-weight: 500;
  font-size: 11px;
  color: white;
`;

export const StatusHistoryLightBlueButton = styled.button<{isWinWin?: boolean}>`
  background: #0091ff;
  cursor: pointer;
  /* position: absolute; */
  border: none;
  border-radius: 4px;
  height: 19px;
  width: 126px;
  padding: 0 6px;
  font-weight: 500;
  font-size: 11px;
  color: white;
  left: ${({isWinWin}) => (isWinWin ? '186px' : '210px')};
  top: 58px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    left: 33.3%;
    top: unset;
    bottom: 80px;
  }
`;

export const MobileTransactionTypeWithDateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > h5 {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.05px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }

  & > h1 {
    font-weight: bold;
    font-size: 15px;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const StatusHistoryYellowButton = styled.button<{isWinWin?: boolean}>`
  background: #fb9614;
  cursor: pointer;
  /* position: absolute; */
  border: none;
  border-radius: 4px;
  height: 19px;
  width: 126px;
  padding: 0 6px;
  font-weight: 500;
  font-size: 11px;
  color: white;
  left: ${({isWinWin}) => (isWinWin ? '822px' : '822px')};
  top: 108px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    bottom: 80px;
    left: 33.3%;
  }
`;

export const StatusHistoryCyanButton = styled(StatusHistoryYellowButton)`
  background: #89bfff;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    bottom: 80px;
    left: 33.3%;
  }
`;

export const StatusHistoryYellowButtonStatus14a = styled(
  StatusHistoryYellowButton,
)`
  left: 822px;
`;

export const StatusHistoryGreenButton = styled.button<{isWinWin?: boolean}>`
  background: #00d10f;
  cursor: pointer;
  /* position: absolute; */
  border: none;
  border-radius: 4px;
  height: 19px;
  width: 126px;
  padding: 0 6px;
  font-weight: 500;
  font-size: 11px;
  color: white;
  left: ${({isWinWin}) => (isWinWin ? '562px' : '586px')};
  top: 104px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    bottom: 80px;
    left: 30px;
    top: unset;
  }
`;

export const StatusHistoryGreenButtonStatus7 = styled(StatusHistoryGreenButton)`
  left: ${({isWinWin}) => (isWinWin ? '596px' : '620px')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    bottom: 80px;
    left: 25%;
    top: unset;
  }
`;

export const StatusHistoryBlueButton = styled.button<{isWinWin?: boolean}>`
  background: #0091ff;
  cursor: pointer;
  /* position: absolute; */
  border: none;
  border-radius: 4px;
  height: 19px;
  width: 168px;
  padding: 0 6px;
  font-weight: 500;
  font-size: 11px;
  color: white;
  left: ${({isWinWin}) => (isWinWin ? '812px' : '812px')};
  top: 104px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    bottom: 80px;
    left: 33.3%;
    top: unset;
  }
`;

export const StatusHistoryRedButton = styled.button<{isWinWin?: boolean}>`
  background: #ff0300;
  cursor: pointer;
  /* position: absolute; */
  border: none;
  border-radius: 4px;
  height: 19px;
  width: 126px;
  padding: 0 6px;
  font-weight: 500;
  font-size: 11px;
  color: white;
  left: ${({isWinWin}) => (isWinWin ? '648px' : '672px')};
  top: 104px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    bottom: 80px;
    left: 125px;
  }
`;

export const StatusHistoryRedButtonStatus7 = styled(StatusHistoryRedButton)`
  left: ${({isWinWin}) => (isWinWin ? '694px' : '718px')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    bottom: 80px;
    left: unset;
    right: 25%;
  }
`;

export const StatusHistoryOrangeButton = styled.button<{isWinWin?: boolean}>`
  background: #fb9b1f;
  cursor: pointer;
  /* position: absolute; */
  border: none;
  border-radius: 4px;
  height: 19px;
  width: 126px;
  padding: 0 6px;
  font-weight: 500;
  font-size: 11px;
  color: white;
  left: ${({isWinWin}) => (isWinWin ? '734px' : '758px')};
  top: 104px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    bottom: 80px;
    left: 220px;
  }
`;

export const StatusHistoryOrangeButtonStatus6 = styled(
  StatusHistoryOrangeButton,
)`
  left: ${({isWinWin}) => (isWinWin ? '414px' : '440px')};
  top: 126px;
  min-width: 110px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    left: 33.3%;
    bottom: 80px;
  }
`;

export const StatusHistoryOrangeButtonStatus2 = styled(
  StatusHistoryOrangeButton,
)`
  background: #0091ff;
  left: ${({isWinWin}) => (isWinWin ? '478px' : '504px')};
  top: 150px;
  min-width: 110px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    min-width: 80px;
    right: 25%;
    left: unset;
    bottom: 80px;
  }
`;

export const StatusHistoryRedButtonStatus6a = styled(StatusHistoryRedButton)`
  left: ${({isWinWin}) => (isWinWin ? '277px' : '301px')};
  top: 72px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    min-width: 80px;
    left: unset;
    right: 25%;
    bottom: 80px;
  }
`;

export const StatusHistoryGreenButtonStatus6a = styled(
  StatusHistoryGreenButton,
)`
  left: ${({isWinWin}) => (isWinWin ? '190px' : '214px')};
  top: 72px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    min-width: 80px;
    left: 25%;
    bottom: 80px;
  }
`;

export const StatusHistoryGreenButtonStatus6a3 = styled(
  StatusHistoryGreenButton,
)`
  left: ${({isWinWin}) => (isWinWin ? '358px' : '384px')};
  top: 150px;
  min-width: 110px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    min-width: 80px;
    left: 25%;
    bottom: 80px;
  }
`;

export const StatusHistoryGreenButtonStatus13a = styled(
  StatusHistoryGreenButton,
)`
  left: ${({isWinWin}) => (isWinWin ? '698px' : '698px')};
  top: 128px;
  min-width: 110px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    left: 15%;
    bottom: 80px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    left: 0px;
  }
`;

export const StatusHistoryRedButtonStatus13a = styled(StatusHistoryRedButton)`
  left: ${({isWinWin}) => (isWinWin ? '814px' : '814px')};
  top: 128px;
  min-width: 110px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    left: unset;
    right: 15%;
    bottom: 80px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    right: 0px;
  }
`;

export const YellowStatusHistoryInfo = styled.h5<{isWinWin?: boolean}>`
  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  color: #fb9614;
  text-align: center;
  /* position: absolute; */
  left: ${({isWinWin}) => (isWinWin ? '386px' : '410px')};
  top: 106px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    bottom: 50px;
    left: 0px;
    width: 100%;
    text-align: center;
  }
`;

export const RedStatusHistoryInfo = styled.h5<{isWinWin?: boolean}>`
  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  color: #ff0300;
  /* position: absolute; */
  left: ${({isWinWin}) => (isWinWin ? '214px' : '238px')};
  top: 66px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    bottom: 50px;
    left: 0px;
    width: 100%;
    text-align: center;
  }
`;

export const StatusHistoryInputWithButtonWrapper = styled.div<{
  isWinWin?: boolean;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
  /* position: absolute; */
  left: ${({isWinWin}) => (isWinWin ? '274px' : '298px')};
  top: 104px;
  height: 22px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    bottom: 28px;
    left: 0px;
    top: unset;
    flex-direction: column;
    height: auto;
  }
`;

export const StatusHistoryInputWrapper = styled.div`
  display: flex;
  width: 312px;
  height: 22px;
  padding: 0 11.5px;
  background: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.12);
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;

  & > p {
    font-size: 11px;
    font-weight: 400;
    color: #07020f;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const StatusHistoryDateInput = styled.div`
  /* width: 83px; */
  display: flex;
  gap: 11.5px;
  align-items: center;
  justify-content: center;

  & > input {
    width: 76px;
    border: none;
    background: transparent;
    color: #3a87f0;
    font-size: 11px;
    font-weight: 400;
    outline: none;
  }
`;

export const BlueHr = styled.hr<{isWinWin?: boolean}>`
  width: 146px;
  height: 2px;
  border: none;
  /* border-bottom:2px dashed #FF0300; */
  position: absolute;
  left: ${({isWinWin}) => (isWinWin ? '66px' : '91px')};
  background-image: repeating-linear-gradient(
    to right,
    #0066ff,
    #0066ff 10px,
    transparent 10px,
    transparent 15px
  );
  background-position: 0 0;
  background-size: 30px 100%;
  animation: ${dash} 1s linear infinite;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 2px !important;
    height: 32px !important;
    left: 12px;
    top: 30px;
  }
`;

export const YellowHr = styled.hr<{isWinWin?: boolean}>`
  width: 146px;
  height: 2px;
  border: none;
  /* border-bottom:2px dashed #FF0300; */
  position: absolute;
  left: ${({isWinWin}) => (isWinWin ? '66px' : '91px')};
  background-image: repeating-linear-gradient(
    to right,
    #fb9614,
    #fb9614 10px,
    transparent 10px,
    transparent 15px
  );
  background-position: 0 0;
  background-size: 30px 100%;
  animation: ${dash} 1s linear infinite;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 2px !important;
    height: 32px !important;
    left: 12px;
    top: 30px;
  }
`;

export const RedWarningWrapper = styled.div<{isWinWin?: boolean}>`
  display: flex;
  gap: 5px;
  /* position: absolute; */
  width: 100%;
  justify-content: center;
  left: ${({isWinWin}) => (isWinWin ? '186px' : '210px')};
  top: 26px;

  & > p {
    font-size: 11px;
    color: #ff0300;
    font-weight: 500;
    line-height: 13px;
    text-transform: uppercase;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: unset;
    bottom: 15px;
    left: 40px;
    width: unset;
    text-align: center;
  }
`;

export const RedWarningWrapperStatus6b = styled(RedWarningWrapper)`
  left: ${({isWinWin}) => (isWinWin ? '172px' : '196px')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 55px;
    bottom: unset;
    left: 66px;
    width: 100%;
    text-align: center;
  }
`;

export const YellowWarningWrapper = styled.div<{isWinWin?: boolean}>`
  display: flex;
  gap: 5px;
  /* position: absolute; */
  width: 100%;
  justify-content: center;
  left: ${({isWinWin}) => (isWinWin ? '186px' : '210px')};
  top: 26px;

  & > p {
    font-size: 11px;
    color: #fb9614;
    font-weight: 500;
    line-height: 13px;
    text-transform: uppercase;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 55px;
    bottom: unset;
    left: 66px;
    width: unset;
    text-align: center;
  }
`;

export const StyledHr = styled.hr<{statusType?: number; isWinWin?: boolean}>`
  width: 126px;
  border: none;
  border-bottom: ${({statusType, theme}) =>
    statusType === 0
      ? '2px dashed #0091ff'
      : statusType === 1
      ? '2px dashed #0091ff'
      : statusType === 5
      ? '2px dashed #0091ff'
      : statusType === 2
      ? `2px dashed ${theme.colors.transactionGrayCircle}`
      : statusType === 3
      ? '2px dashed #FB9614'
      : '2px dashed #FF0300'};
  position: absolute;
  top: 50px;
  left: -64px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 2px !important;
    height: 38px !important;
    left: 12px;
    top: -49px;
    border-bottom: none;
    border-right: ${({statusType, theme}) =>
      statusType === 0
        ? '2px dashed #0091ff'
        : statusType === 1
        ? '2px dashed #0091ff'
        : statusType === 5
        ? '2px dashed #0091ff'
        : statusType === 2
        ? `2px dashed ${theme.colors.transactionGrayCircle}`
        : statusType === 3
        ? '2px dashed #FB9614'
        : '2px dashed #FF0300'};
  }
`;

export const SingleStatusDiv = styled.div``;

export const blinkAnimation = keyframes`
  0% {
    border-color: rgba(53, 47, 64, 0.21);
  }
  50% {
    border-color: #FB9614;
  }
  100% {
    border-color: rgba(53, 47, 64, 0.21);
  }
`;

export const blinkAnimationFontColor = keyframes`
  0% {
    color: rgba(53, 47, 64, 0.21);
  }
  50% {
    color: #FB9614;
  }
  100% {
    color: rgba(53, 47, 64, 0.21);
  }
`;

export const NewSingleStatusWrapper = styled.div<{statusType?: number}>`
  min-width: 180px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  & > p {
    font-size: 11px;
    color: ${({statusType, theme}) =>
      statusType === 0
        ? '#0091ff'
        : statusType === 1
        ? '#0091ff'
        : statusType === 5
        ? '#0091ff'
        : statusType === 2
        ? theme.colors.grayFontOp50
        : statusType === 3
        ? '#FB9614'
        : '#FF0300'};
    font-weight: 500;
    line-height: 13px;
    text-align: center;
  }

  & > h5 {
    font-size: 11px;
    color: ${({statusType, theme}) =>
      statusType === 0
        ? '#0091ff'
        : statusType === 1
        ? '#0091ff'
        : statusType === 5
        ? '#0091ff'
        : statusType === 2
        ? theme.colors.grayFontOp50
        : statusType === 3
        ? '#FB9614'
        : '#FF0300'};
    text-align: center;
    font-weight: 500;
    line-height: 13px;
  }

  & > ${SingleStatusDiv} {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    top: 37px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({statusType, theme}) =>
      statusType === 0
        ? '2px solid #0091ff'
        : statusType === 1
        ? '2px solid #0091ff'
        : statusType === 5
        ? '2px solid #0091ff'
        : statusType === 2
        ? `2px solid ${theme.colors.transactionGrayCircle}`
        : statusType === 3
        ? '2px solid #FB9614'
        : '2px solid #FF0300'};
  }

  &:first-of-type {
    & > hr {
      display: none;
    }
  }

  ${({statusType}) =>
    statusType === 3 &&
    css`
      & > hr {
        height: 2px;
        border: none;
        background-image: repeating-linear-gradient(
          to right,
          #fb9614,
          #fb9614 10px,
          transparent 10px,
          transparent 15px
        );
        background-position: 0 0;
        background-size: 30px 100%;
        animation: ${dash} 1s linear infinite;
      }

      & > h5 {
        animation: ${blinkAnimationFontColor} 1s infinite;
      }

      & > p {
        animation: ${blinkAnimationFontColor} 1s infinite;
      }

      & > ${SingleStatusDiv} {
        border: none;
        &::before {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          border-radius: inherit;
          border: 2px solid rgba(53, 47, 64, 0.21);
          animation: ${blinkAnimation} 1s infinite;
        }
      }
    `}

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    min-width: unset;
    height: 74px;
    width: 100%;
    flex-direction: row;
    align-items: unset;

    & > ${SingleStatusDiv} {
      top: -6px;
    }

    & > p {
      margin-left: 32px;
      text-align: left;
      max-width: calc(100% - 150px);
      font-size: 13px;
    }

    & > h5 {
      font-weight: bold;
      font-size: 13px;
      text-align: right;
    }
  }
`;

export const SingleStatusWrapper = styled.div<{statusType?: number}>`
  width: 170px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & > p {
    font-size: 11px;
    color: ${({statusType, theme}) =>
      statusType === 1
        ? '#0091ff'
        : statusType === 2
        ? '#FF0300'
        : statusType === 3
        ? '#FB9614'
        : theme.colors.grayFontOp50};
    font-weight: 500;
    line-height: 13px;
    text-align: center;
    position: absolute;
    top: 10px;
  }

  & > h5 {
    font-size: 11px;
    color: ${({statusType, theme}) =>
      statusType === 1
        ? '#0091ff'
        : statusType === 2
        ? '#FF0300'
        : statusType === 3
        ? '#FB9614'
        : theme.colors.grayFontOp50};
    text-align: center;
    font-weight: 500;
    line-height: 13px;
    position: absolute;
    bottom: 0px;
  }

  & > ${SingleStatusDiv} {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({statusType, theme}) =>
      statusType === 1
        ? '2px solid #0091ff'
        : statusType === 2
        ? '2px solid #FF0300'
        : statusType === 3
        ? '2px solid #FB9614'
        : `2px solid ${theme.colors.transactionGrayCircle}`};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: unset !important;
    width: 100%;
    min-height: 68px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & > p {
      top: 0px;
      left: 36px;
      font-weight: bold;
    }

    & > h5 {
      left: 36px;
      bottom: unset;
      top: 16px;
      white-space: nowrap;

      & > br {
        display: none;
      }
    }
  }
`;

export const SingleStatusWrapperBlinkingBorderYellow = styled.div<{
  statusType?: number;
}>`
  width: 170px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & > p {
    font-size: 11px;
    color: ${({statusType}) =>
      statusType === 1
        ? '#0091ff'
        : statusType === 3
        ? '#FB9614'
        : 'rgba(53,47,64,0.5)'};
    font-weight: 500;
    line-height: 13px;
    text-align: center;
    position: absolute;
    top: 10px;
    animation: ${blinkAnimationFontColor} 1s infinite;
  }

  & > h5 {
    font-size: 11px;
    color: ${({statusType}) =>
      statusType === 1
        ? '#0091ff'
        : statusType === 2
        ? 'rgba(53,47,64,0.5)'
        : statusType === 3
        ? '#FB9614'
        : 'rgba(53,47,64,0.38)'};
    text-align: center;
    font-weight: 500;
    line-height: 13px;
    position: absolute;
    bottom: 0px;
    animation: ${blinkAnimationFontColor} 1s infinite;
  }

  & > ${SingleStatusDiv} {
    position: relative;
    width: ${({statusType}) =>
      statusType === 1 ? '26px' : statusType === 2 ? '36px' : '26px'};
    height: ${({statusType}) =>
      statusType === 1 ? '26px' : statusType === 2 ? '36px' : '26px'};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      border-radius: inherit;
      border: 2px solid rgba(53, 47, 64, 0.21);
      animation: ${blinkAnimation} 1s infinite;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin-right: unset !important;
    width: 100%;
    min-height: 68px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & > p {
      top: 0px;
      left: 36px;
      font-weight: bold;
    }

    & > h5 {
      left: 36px;
      bottom: unset;
      top: 16px;
      white-space: nowrap;

      & > br {
        display: none;
      }
    }
  }
`;

export const MessagesTabWrapper = styled.div`
  width: 100%;
  height: 194px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  gap: 20px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #0091ff;
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background: ${({theme}) => theme.colors.transactionScrollbarBackground};
    border: 0px none #ffffff;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    height: unset;
  }
`;

export const PaymentTabWrapper = styled.div`
  width: 100%;
  height: 58px;
  display: grid;
  padding-top: 15px;
  grid-template-columns: repeat(4, 1fr);

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${({theme}) => theme.colors.fontBlackOp15};

    &:last-of-type {
      border-right: none;
    }

    & > p {
      font-size: 9px;
      line-height: 15px;
      color: ${({theme}) => theme.colors.fontBlackOp70};
      font-weight: 400;
      text-align: center;
    }

    & > h5 {
      font-size: 13px;
      line-height: 24px;
      color: ${({theme}) => theme.colors.fontBlackOp100};
      font-weight: 400;
      text-align: center;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-bottom: 10px;
    grid-template-columns: repeat(1, 1fr) !important;
    height: auto;
    padding-top: 5px;

    & > div {
      border-right: none;
      padding-top: 5px;
      padding-bottom: 10px;
      border-bottom: 1px solid
        ${({theme}) => theme.colors.mobileTransactionTopBorder};

      & > p {
        font-size: 13px;
        color: ${({theme}) => theme.colors.fontBlackOp50};
        line-height: 16px;
      }

      & > h5 {
        line-height: 21px;
        font-size: 13px;
      }
    }
  }
`;

export const DetailsHeader = styled.div`
  width: 100%;
  display: flex;
  gap: 7px;
  margin-bottom: 3px;
  padding-top: 9px;
  background: ${({theme}) => theme.colors.transactionDetailNavbarBackground};
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: grid;
    grid-template-rows: repeat(2, 38px);
    grid-template-columns: repeat(3, 92px);
    gap: 18px;
    align-items: center;
    justify-content: center;
  }
`;

export const DetailsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-weight: 500;
    font-size: 9px;
    line-height: 18px;
    color: rgba(47, 47, 104, 0.86);
  }
`;

export const StyledStatus = styled.h2<{statusType?: number}>`
  font-size: 11px;
  font-weight: 500;
  width: 112px;
  height: 24px;
  letter-spacing: -0.15px;
  inline-size: 112px;
  margin-left: 10px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: black;
  overflow-wrap: break-word;
  text-align: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: absolute;
    top: 7px;
    right: 6px;
    width: 138px;
  }

  ${({statusType}) => {
    switch (statusType) {
      case 0:
        return css`
          background: rgba(0, 102, 255, 0.7);
        `;
      case 1:
        return css`
          background: rgba(251, 156, 31, 0.7);
        `;
      case 2:
      case 3:
      case 4:
      case 6:
        return css`
          background: rgba(0, 102, 255, 0.7);
        `;
      case 5:
        return css`
          background: rgba(251, 156, 31, 0.7);
        `;
      case 7:
        return css`
          background: rgba(251, 156, 31, 0.7);
        `;
      case 8:
      case 9:
        return css`
          background: rgba(255, 4, 0, 0.7);
        `;
      case 10:
        return css`
          background: rgba(255, 4, 0, 0.7);
        `;
      case 11:
      case 12:
        return css`
          background: rgba(0, 102, 255, 0.7);
        `;
      case 13:
        return css`
          background: rgba(0, 102, 255, 0.7);
        `;
      case 14:
      case 15:
        return css`
          background: rgba(0, 102, 255, 0.7);
        `;
      case 16:
        return css`
          background: rgba(255, 4, 0, 0.7);
        `;
      case 17:
        return css`
          background: rgba(255, 4, 0, 0.7);
        `;
      case 18:
        return css`
          background: rgba(0, 102, 255, 0.7);
        `;
      default:
        return css`
          background: rgba(251, 156, 31, 0.7);
        `;
    }
  }}
`;

export const MobileStyledStatus = styled(StyledStatus)`
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: absolute;
    top: 68px;
    right: 6px;
    width: 138px;
  }
`;
