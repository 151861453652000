import styled from 'styled-components';

export const AtricleWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 24px;
  background: ${({theme}) => theme.colors.bodyBg};
  border: ${({theme}) => `solid 1px ${theme.colors.fontBlackOp15}`};
  box-shadow: ${({theme}) => theme.colors.mainBoxShadow};
  @media (max-width: 1024px) {
    width: 400px;
    border-radius: 16px;
    background: ${({theme}) => theme.colors.mobileArtcileWrapperBackqround};
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const ArticleImgWrapper = styled.div`
  width: 288px;
  height: 288px;
  border-radius: 24px;
  margin-top: 1px;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 22px;
    border: 1px solid rgba(112, 112, 112, 0.3);
  }
  @media (max-width: 1024px) {
    width: 372px;
    height: 372px;
    border-radius: 9px;
    margin-top: 14px;
    & > img {
      border-radius: 9px;
    }
  }
  @media (max-width: 400px) {
    width: 90%;
    aspect-ratio: 1/1;
    margin-top: 14px;
  }
`;

export const ArticleTextWrapper = styled.div`
  padding: 10px 13px 10px 18px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (max-width: 1024px) {
    gap: 0;
  }
`;

export const ArticleCategory = styled.h2`
  align-self: flex-start;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: normal;
  line-height: 18px;
  letter-spacing: -0.05px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  @media (max-width: 1024px) {
    font-weight: bold;
  }
`;

export const ArticleTitle = styled.p`
  font-size: 15px;
  line-height: 21px;
  letter-spacing: -0.15px;
  font-weight: 700;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  @media (max-width: 1024px) {
    font-weight: normal;
  }
`;

export const ArticleLead = styled.p`
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.15px;
  font-weight: 400;
  padding-bottom: 20px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  @media (max-width: 1024px) {
    padding-bottom: 0px;
    letter-spacing: -0.65px;
  }
`;
