import React, {useState} from 'react';

import styled, {css} from 'styled-components';

export const Wrapper = styled.div<{
  isEditing: boolean;
  isDescriptionExpanded: boolean;
}>`
  position: relative;
  display: ${({isEditing}) => (isEditing ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  width: 100%;

  & > p {
    text-align: center;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 15px;
    padding-bottom: 10px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
`;

export const LongDescriptionExpandButton = styled.button<{
  isDescriptionExpanded: boolean;
  isLongDescription?: boolean;
}>`
  width: 19px;
  height: 19px;
  border-radius: 4px;
  border: ${({isDescriptionExpanded}) =>
    isDescriptionExpanded ? 'none' : '1px solid #0091FF'};
  background: ${({isDescriptionExpanded}) =>
    isDescriptionExpanded ? '#0091FF' : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${({isLongDescription}) =>
    isLongDescription &&
    css`
      bottom: 6px;
      position: absolute;
    `}

  & > svg {
    & path {
      fill: ${({isDescriptionExpanded}) =>
        isDescriptionExpanded ? '#FFFFFF' : '#0091FF'};
    }
  }
`;

export const LongDescriptionCloseButton = styled(LongDescriptionExpandButton)`
  & > svg {
    transform: rotate(180deg);
  }
`;

export const ButtonWithHeaderWrapper = styled.div`
  justify-content: space-between;
  align-items: center;
  width: 259px;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  & > h1 {
    text-align: left;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.05px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const ExpandableHeaderWithDescription = ({
  isEditing,
  children,
  text,
  header,
  isLongDescription = false,
}) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] =
    useState<boolean>(false);

  const toggleIsDescriptionExpanded = (value: boolean) => {
    setIsDescriptionExpanded(value);
  };

  return (
    <Wrapper
      isEditing={isEditing}
      isDescriptionExpanded={isDescriptionExpanded}
    >
      <ButtonWithHeaderWrapper>
        <h1>{header}</h1>
        <LongDescriptionExpandButton
          isDescriptionExpanded={isDescriptionExpanded}
          onClick={() => toggleIsDescriptionExpanded(!isDescriptionExpanded)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.71"
            height="6"
            viewBox="0 0 9.71 6"
          >
            <path
              id="Icon_metro-expand-more"
              data-name="Icon metro-expand-more"
              d="M18.848,13.605l-3.71,3.71-3.71-3.71L10.283,14.75l4.855,4.855,4.855-4.855Z"
              transform="translate(-10.283 -13.605)"
              fill="#fff"
            />
          </svg>
        </LongDescriptionExpandButton>
      </ButtonWithHeaderWrapper>
      <p>{text}</p>
      {isDescriptionExpanded ? <>{children}</> : null}
      {isLongDescription && isDescriptionExpanded && (
        <LongDescriptionCloseButton
          isDescriptionExpanded={isDescriptionExpanded}
          isLongDescription={isLongDescription}
          onClick={() => toggleIsDescriptionExpanded(!isDescriptionExpanded)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.71"
            height="6"
            viewBox="0 0 9.71 6"
          >
            <path
              id="Icon_metro-expand-more"
              data-name="Icon metro-expand-more"
              d="M18.848,13.605l-3.71,3.71-3.71-3.71L10.283,14.75l4.855,4.855,4.855-4.855Z"
              transform="translate(-10.283 -13.605)"
              fill="#fff"
            />
          </svg>
        </LongDescriptionCloseButton>
      )}
    </Wrapper>
  );
};
