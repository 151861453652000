import React, {useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useNavigate} from 'react-router-dom';

import {RecommendedProducts} from '../index';

import PopularProductImage1 from '../HomeImages/popular_product_1.jpg';
import PopularProductImage2 from '../HomeImages/popular_product_2.jpg';
import PopularProductImage3 from '../HomeImages/popular_product_3.jpg';
import PopularProductImage4 from '../HomeImages/popular_product_4.jpg';
import PopularProductImage5 from '../HomeImages/popular_product_5.jpg';

import {WinWinSvg} from 'components/SvgComponents';

import {
  MainWrapper,
  ProductsWrapper,
  SectionHeader,
  SingleProduct,
  SliderWrapper,
  MobileSliderWrapper,
  MobileProductsWrapper,
  MobileSingleProduct,
} from './styles';

interface Props {
  RecommendedProducts: RecommendedProducts;
}

export const PopularProducts: React.FC<Props> = ({RecommendedProducts}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite:
      RecommendedProducts?.Products && RecommendedProducts?.Products?.length > 5
        ? true
        : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 10000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const mobileSettings = {
    dots: true,
    infinite:
      RecommendedProducts?.Products && RecommendedProducts?.Products?.length > 2
        ? true
        : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 10000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const splitArrayIntoChunks = (array, chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <MainWrapper>
      <SectionHeader>
        <h1>{RecommendedProducts?.Title}</h1>
        <a
          onClick={() =>
            navigate('/products/main', {
              state: {
                productTypeFromHome: RecommendedProducts?.ProductType,
                categoryFromHome: RecommendedProducts?.Category,
              },
            })
          }
          // href="/products/main"
        >
          Zobacz więcej
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.438"
            height="11"
            viewBox="0 0 6.438 11"
          >
            <g
              id="Arrow_Top_Bar_Accent"
              data-name="Arrow – Top Bar / Accent"
              transform="translate(0)"
              opacity="0.92"
            >
              <path
                id="Arrow"
                d="M1.321.224l4.961,4.9a.523.523,0,0,1,0,.747l-4.961,4.9a.781.781,0,0,1-1.095,0,.759.759,0,0,1,0-1.082L4.47,5.5.226,1.306a.76.76,0,0,1,0-1.082.781.781,0,0,1,1.095,0"
                transform="translate(0 0)"
                fill="#0091ff"
              />
            </g>
          </svg>
        </a>
      </SectionHeader>
      <SliderWrapper>
        <Slider {...settings}>
          {RecommendedProducts &&
            splitArrayIntoChunks(RecommendedProducts.Products, 5).map(
              (chunk, index) => {
                return (
                  <ProductsWrapper key={index}>
                    {chunk.map((popularProduct, index) => {
                      const {
                        Categories,
                        IsWinWin,
                        Name,
                        OfferId,
                        PicturePath,
                        Position,
                        Price,
                        ProductId,
                        ProductName,
                      } = popularProduct;

                      return (
                        <SingleProduct
                          key={ProductId}
                          onClick={() =>
                            navigate(`/products/main/${ProductId}`)
                          }
                        >
                          <img src={PicturePath} alt="image" />
                          <h1>{ProductName}</h1>
                          {/* {IsWinWin ? <WinWinSvg /> : <p>KWOTA</p>} */}
                          <h2>{`od ${new Intl.NumberFormat('de-DE').format(
                            Price / 100,
                          )} zł`}</h2>
                          <h4>
                            {Categories.map((category, index) => {
                              if (index === 0) {
                                return `${category} `;
                              } else {
                                return `- ${category} `;
                              }
                            })}
                          </h4>
                        </SingleProduct>
                      );
                    })}
                  </ProductsWrapper>
                );
              },
            )}
        </Slider>
      </SliderWrapper>
      <MobileSliderWrapper>
        <Slider {...mobileSettings}>
          {RecommendedProducts &&
            splitArrayIntoChunks(RecommendedProducts.Products, 2).map(
              (chunk, index) => {
                return (
                  <MobileProductsWrapper key={index}>
                    {chunk.map((popularProduct, index) => {
                      const {
                        Categories,
                        IsWinWin,
                        Name,
                        OfferId,
                        PicturePath,
                        Position,
                        Price,
                        ProductId,
                        ProductName,
                      } = popularProduct;

                      return (
                        <MobileSingleProduct
                          key={ProductId}
                          onClick={() =>
                            navigate(`/products/main/${ProductId}`)
                          }
                        >
                          <img src={PicturePath} alt="image" />
                          <div>
                            <h1>{ProductName}</h1>
                            <div>
                              {IsWinWin && <WinWinSvg />}
                              <h2>{`od ${new Intl.NumberFormat('de-DE').format(
                                Price / 100,
                              )} zł`}</h2>{' '}
                            </div>
                            <div>
                              {Categories.map((category, index) => (
                                <h4 key={index}>{category}</h4>
                              ))}
                            </div>
                          </div>
                        </MobileSingleProduct>
                      );
                    })}
                  </MobileProductsWrapper>
                );
              },
            )}
        </Slider>
      </MobileSliderWrapper>
    </MainWrapper>
  );
};
