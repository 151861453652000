import React, {useState} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useNavigate} from 'react-router-dom';

import PopularCategoryImage1 from '../HomeImages/popular_category_1_2.png';
import PopularCategoryImage2 from '../HomeImages/popular_category_2_2.png';
import PopularCategoryImage3 from '../HomeImages/popular_category_3_2.png';
import PopularCategoryImage4 from '../HomeImages/popular_category_4_2.png';
import PopularCategoryImage5 from '../HomeImages/popular_category_5_2.png';
import PopularCategoryImage6 from '../HomeImages/popular_category_6.png';
import PopularCategoryImage7 from '../HomeImages/popular_category_7.png';
import PopularCategoryImage8 from '../HomeImages/popular_category_8.png';
import PopularCategoryImage9 from '../HomeImages/popular_category_9.png';
import PopularCategoryImage10 from '../HomeImages/popular_category_10.png';

import {
  CategoriesWrapper,
  MainWrapper,
  SectionHeader,
  SingleCategory,
  MobileCategoriesWrapper,
  SliderWrapper,
} from './styles';

export const PopularCategories = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 10000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  const popularCategories = [
    {
      title: 'Muzyka i dźwięk',
      image: PopularCategoryImage9,
      onClick: () => {
        navigate(
          '/products/main?SearchString=&ProducerName=&ProductType=&PrimaryCategory=5bc87a42-6f41-455c-93da-ac40cd61e199&SecondaryCategory=&TertiaryCategory=&MinGrossValue=&MaxGrossValue=&IsWinwin=no&IsFavourite=no',
        );
        sessionStorage.setItem(
          'offerFilterPrimaryCategory',
          '5bc87a42-6f41-455c-93da-ac40cd61e199',
        );
        sessionStorage.removeItem('offerFilterSearchString');
      },
    },
    {
      title: 'Promocja i reklama',
      image: PopularCategoryImage7,
      onClick: () => {
        navigate(
          '/products/main?SearchString=promocja&ProducerName=&ProductType=&PrimaryCategory=&SecondaryCategory=&TertiaryCategory=&MinGrossValue=&MaxGrossValue=&IsWinwin=no&IsFavourite=no',
        );
        sessionStorage.removeItem('offerFilterPrimaryCategory');
        sessionStorage.setItem('offerFilterSearchString', 'promocja');
      },
    },
    {
      title: 'TikTok',
      image: PopularCategoryImage10,
      onClick: () => {
        navigate(
          '/products/main?SearchString=tiktok&ProducerName=&ProductType=&PrimaryCategory=&SecondaryCategory=&TertiaryCategory=&MinGrossValue=&MaxGrossValue=&IsWinwin=no&IsFavourite=no',
        );
        sessionStorage.removeItem('offerFilterPrimaryCategory');
        sessionStorage.setItem('offerFilterSearchString', 'tiktok');
      },
    },
    {
      title: 'Mix/Mastering',
      image: PopularCategoryImage8,
      onClick: () => {
        navigate(
          '/products/main?SearchString=mix%2Fmastering&ProducerName=&ProductType=&PrimaryCategory=&SecondaryCategory=&TertiaryCategory=&MinGrossValue=&MaxGrossValue=&IsWinwin=no&IsFavourite=no',
        );
        sessionStorage.removeItem('offerFilterPrimaryCategory');
        sessionStorage.setItem('offerFilterSearchString', 'mix/mastering');
      },
    },
    {
      title: 'Tracki',
      image: PopularCategoryImage6,
      onClick: () => {
        navigate('tracks/main');
      },
    },
    // {
    //   title: 'Fotografia i video',
    //   image: PopularCategoryImage2,
    //   onClick: () => {
    //     navigate('products/main');
    //     sessionStorage.setItem(
    //       'offerFilterPrimaryCategory',
    //       '5c8a575d-05e0-45ac-9620-34ef5b3e613a',
    //     );
    //   },
    // },
    // {
    //   title: 'Grafika',
    //   image: PopularCategoryImage3,
    //   onClick: () => {
    //     navigate('products/main');
    //     sessionStorage.setItem(
    //       'offerFilterPrimaryCategory',
    //       '0490b60d-1cef-4e94-b45b-fdb3b67a8251',
    //     );
    //   },
    // },
    // {
    //   title: 'Media społecznościowe',
    //   image: PopularCategoryImage4,
    //   onClick: () => {
    //     navigate('products/main');
    //     sessionStorage.setItem(
    //       'offerFilterPrimaryCategory',
    //       '6c772e49-6fbd-4df1-841f-596a3032d7d7',
    //     );
    //   },
    // },
  ];

  const splitArrayIntoChunks = (array, chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <MainWrapper>
      <SectionHeader>
        <h1>Popularne kategorie</h1>
      </SectionHeader>
      <CategoriesWrapper>
        {popularCategories.map((popularCategory, index) => {
          const {image, title, onClick} = popularCategory;

          return (
            <SingleCategory key={index} onClick={() => onClick()}>
              <img src={image} alt="image" />
              <p>{title}</p>
            </SingleCategory>
          );
        })}
      </CategoriesWrapper>
      <SliderWrapper>
        <Slider {...settings}>
          {splitArrayIntoChunks(popularCategories, 2).map((chunk, index) => {
            return (
              <MobileCategoriesWrapper key={index}>
                {chunk.map((popularCategory, index) => {
                  if (index > 1) return;
                  const {image, title, onClick} = popularCategory;

                  return (
                    <SingleCategory key={index} onClick={() => onClick()}>
                      <img src={image} alt="image" />
                      <p>{title}</p>
                    </SingleCategory>
                  );
                })}
              </MobileCategoriesWrapper>
            );
          })}
        </Slider>
      </SliderWrapper>
    </MainWrapper>
  );
};
