import styled from 'styled-components';

export const HeaderBlockWrapper = styled.div<{
  bgColor: string;
  backgroundAlpha: number;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  //justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  /* padding: 12px 12px 12px 0px; */
  background: ${({bgColor, backgroundAlpha}) =>
    `rgb(${bgColor},${backgroundAlpha})`};
`;
export const HeaderBlockTitle = styled.div<{
  fontColor: string;
  mobileOverview: boolean;
  interline: number;
}>`
  width: 100%;
  text-align: center;
  padding-left: ${({mobileOverview}) => (mobileOverview ? '16px' : '58px')};
  padding-right: ${({mobileOverview}) => (mobileOverview ? '16px' : '58px')};
  padding-top: ${({mobileOverview}) => (mobileOverview ? '16px' : '0')};
  padding-bottom: ${({mobileOverview}) => (mobileOverview ? '16px' : '0')};
  height: ${({mobileOverview}) => (mobileOverview ? '100%' : '32px')};
  position: relative;
  & > p {
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0.05px;
    line-height: ${({mobileOverview, interline}) =>
      interline === 0 ? '26px' : mobileOverview ? '26px' : `${interline}px`};
    padding-top: 8px;
    font-family: 'Inter', sans-serif;
    /* padding-bottom: 12px; */
    color: ${({fontColor}) => `rgb(${fontColor})`};
  }

  & > textarea,
  textarea:disabled {
    text-align: center;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    padding-top: 4px;
    font-family: 'Inter', sans-serif;
    font-size: 28px;
    font-weight: normal;
    line-height: ${({mobileOverview, interline}) =>
      interline === 0 ? '26px' : mobileOverview ? '26px' : `${interline}px`};
    letter-spacing: -0.05px;
    overflow: hidden;
    color: ${({fontColor}) => `rgb(${fontColor})`};
    resize: none;
    opacity: 1;
  }
  @media (max-width: 1024px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0px;
    position: relative;
    height: 100%;
    & > p {
      font-size: 23px;
      /* position: absolute; */
      bottom: 0;
      line-height: 26px;
      width: 100%;
    }
    & > textarea,
    textarea:disabled {
      /* position: absolute; */
      bottom: 0;
      font-size: 28px;
      line-height: 26px;
      width: 100%;
    }
  }
  @media (max-width: 375px) {
    padding-left: 14px;
    padding-right: 14px;
  }
  @media (max-width: 340px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
