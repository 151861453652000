export const getProductTypePolishName = (productType: number): string => {
  switch (productType) {
    case 1:
      return 'Produkt cyfrowy';
    case 2:
      return 'Produkt fizyczny';
    case 4:
      return 'Usługa';
    case 8:
      return 'Track';
    default:
      return 'Undefined';
  }
};
