import React, {createContext, useState} from 'react';

type Context = [
  {Active: boolean; Text: string},
  React.Dispatch<React.SetStateAction<{Active: boolean; Text: string}>>,
];

interface Props {
  children: React.ReactNode;
}

export const EditHoverContext = createContext<Context>(null!);

const EditHoverProvider: React.FC<Props> = ({children}) => {
  const [blockHover, setBlockHover] = useState<{Active: boolean; Text: string}>(
    {Active: false, Text: ''},
  );

  return (
    <EditHoverContext.Provider value={[blockHover, setBlockHover]}>
      {children}
    </EditHoverContext.Provider>
  );
};

export default EditHoverProvider;
