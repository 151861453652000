import styled from 'styled-components';

export const MobileMainWrapper = styled.div<{isOrderOpen: boolean}>`
  width: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background: #f8f8f8; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: ${({isOrderOpen}) => (isOrderOpen ? 'flex' : 'none')};
    background: ${({theme}) => theme.colors.mobileConditionDetailBackground};
  }
`;

export const MobileMain = styled.div`
  width: 100%;
  min-height: calc(100vh - 105px);
  padding-bottom: 57px;
  background: ${({theme}) => theme.colors.mobileConditionDetailBackground};
  /* min-height: 765px; */
  /* height: calc(100vh - 48px - 57px); */
  display: none;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.smallMobile}) {
    width: 100%;
    padding: 8px;
  }
`;

export const MainWrapper = styled.div<{isOrderOpen: boolean}>`
  width: 100%;
  display: ${({isOrderOpen}) => (isOrderOpen ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: none !important;
  }
`;

export const MobileNameWithRealizationDataWrapper = styled.div`
  background: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 8px 11px;
  gap: 17px;
  border-radius: 0px 0px 16px 16px;
  margin-top: 53px;
`;

export const MobileNameWithSymbolContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > h5 {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-weight: bold;
    letter-spacing: -0.15px;
    margin-top: 12.5px;
    margin-bottom: 31px;
  }

  & > h4 {
    font-size: 18px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-weight: bold;
    letter-spacing: -0.25px;
    line-height: 21px;
    margin-bottom: 2px;
  }

  & > p {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    font-weight: 400;
    letter-spacing: -0.05px;
    margin-top: 30px;
  }
`;

export const MobileRealizationDateContainer = styled.div`
  min-height: 183px;
  width: 100%;
  border-radius: 16px;
  padding: 19px 20px 25px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h5 {
    font-size: 13px;
    color: #07020f;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 18px;
    margin-bottom: 12px;
  }

  & > p {
    font-size: 13px;
    color: #352f40;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    margin: 0 20px;
    text-align: center;
  }
`;

export const MobilePriceWithTitleSpan = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2px;
  align-items: center;

  & > p {
    font-size: 13px;
    color: rgba(7, 2, 15, 0.5);
    font-weight: 400;
    letter-spacing: 0.05px;
    line-height: 18px;
  }

  & > h5 {
    font-size: 13px;
    color: #07020f;
    font-weight: 400;
    letter-spacing: 0.0625px;
    line-height: 24px;
  }
`;

export const RealizationDateWithTitleSpan = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;

  & > p {
    font-size: 13px;
    color: #07020f;
    font-weight: 400;
    letter-spacing: 0.05px;
    line-height: 18px;
  }

  & > h5 {
    font-size: 13px;
    color: #0066ff;
    font-weight: bold;
    letter-spacing: 0.0625px;
    line-height: 24px;
  }
`;

export const BoldTextInRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-size: 13px;
    color: #07020f;
    font-weight: bold;
    letter-spacing: 0.05px;
    line-height: 18px;
  }

  & > h5 {
    font-size: 13px;
    color: #0066ff;
    font-weight: bold;
    letter-spacing: 0.0625px;
    line-height: 24px;
  }
`;

export const MobileConditionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  padding: 10px 8px 15px;
`;

export const MobileConditionContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  padding: 19px 19px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h4 {
    font-size: 13px;
    color: #352f40;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 18px;
    margin-bottom: 14px;
  }
`;

export const MobileFormContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  padding: 21px 23px 26px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h4 {
    font-size: 13px;
    color: #352f40;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 18px;
    margin-bottom: 14px;
  }
`;

export const MobileSvgWithTitle = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 7px;
  align-items: center;
  gap: 11px;

  &:last-of-type {
    margin-bottom: unset;
  }

  & > p {
    font-size: 13px;
    color: #07020f;
    font-weight: 400;
    letter-spacing: 0.05px;
    line-height: 18px;
    text-align: left;
  }
`;

export const MobileWinWinTextInRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 6px;
  gap: 56px;

  &:last-of-type {
    margin-bottom: unset;
  }

  & > p {
    font-size: 13px;
    color: #07020f;
    font-weight: 400;
    letter-spacing: 0.05px;
    line-height: 18px;
  }

  & > h5 {
    font-size: 13px;
    color: #0066ff;
    font-weight: bold;
    letter-spacing: 0.625px;
    line-height: 18px;
  }
`;

export const MobileDescriptionTextareaWrapper = styled.div`
  width: calc(100% - 8px);
  min-height: 155px;
  border-radius: 16px;
  background: #dce0e9;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  padding: 24px 28px;

  & > h4 {
    font-size: 13px;
    color: #352f40;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 18px;
  }

  & > textarea {
    width: 100%;
    resize: none;
    min-height: 132px;
    outline: none;
    background: transparent;
    border: none;
    font-size: 13px;
    color: #07020f;
    font-weight: 400;
    letter-spacing: 0.05px;
    line-height: 18px;

    &::placeholder {
      font-size: 13px;
      color: #07020f;
      font-weight: 400;
      letter-spacing: 0.05px;
      line-height: 18px;
    }
  }
`;

export const MobileSectionText = styled.p`
  margin: 0 14px;
  font-size: 13px;
  color: #352f40;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  text-align: center;
`;

export const MobileSectionTextUnderline = styled.p`
  margin: 0 14px;
  font-size: 13px;
  text-decoration: underline;
  text-transform: uppercase;
  color: #352f40;
  font-weight: 400;
  letter-spacing: -0.25px;
  line-height: 18px;
  text-align: center;
`;

export const MobilePersonTypeWrapper = styled.div`
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #dce0e9;
  border-radius: 16px;
  border: none;
  margin-top: 25px;
  padding: 5px 6px;
`;

export const MobileSinglePersonTypeWrapper = styled.div<{
  value: number;
  personType: number;
}>`
  width: 50%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({value, personType}) =>
    value === personType ? 'default' : 'pointer'};
  border-radius: 11px;
  background: ${({value, personType}) =>
    value === personType ? '#EEEEF1' : 'transparent'};
  color: ${({value, personType}) =>
    value === personType ? '#3A87F0' : 'rgba(7, 2, 15, 0.5)'};
  font-size: 13px;
  letter-spacing: -0.15px;
  font-weight: 500;
`;

export const MobileInputWrapper = styled.div`
  width: 100%;
  border-radius: 16px;
  position: relative;
  background: #dce0e9;
  height: 48px;
  gap: 5px;
  padding: 11px 25px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-size: 17px;
    color: #352f40;
    font-weight: 500;
    letter-spacing: -0.0625px;
    margin: 0;
  }

  & > input {
    width: 100%;
    text-align: right;
    border: none;
    outline: none;
    background: transparent;
    font-size: 17px;
    color: #3a87f0;
    font-weight: 500;
    letter-spacing: -0.0625px;

    &::placeholder {
      color: rgba(7, 2, 15, 0.3);
    }
  }
`;

export const MobileProtectiveUmbrellaWrapper = styled.div`
  display: flex;
  width: calc(100% - 43px);
  flex-direction: column;
  padding: 21px 19px 20px;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #3a87f0;
  background: rgba(47, 47, 104, 0.05);

  & > h5 {
    font-size: 13px;
    color: #3a87f0;
    line-height: 18px;
    font-weight: bold;
    letter-spacing: -0.15px;
    margin-bottom: 12px;
  }

  & > p {
    margin-top: 12px;
    font-size: 13px;
    color: #3a87f0;
    line-height: 18px;
    text-align: center;
    font-weight: 400;
    letter-spacing: -0.25px;
  }
`;

export const MobileForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 13px;
`;

export const MobilePaymentSectionWrapper = styled.div`
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px 16px 0px 0px;
  padding: 10px 8px 29px;
`;

export const MobileBuyButton = styled.button`
  border: none;
  border-radius: 16px;
  background: #3a87f0;
  width: calc(100% - 46px);
  height: 44px;
  transition: all 0.15s;
  cursor: pointer;
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &:hover {
    filter: brightness(1.05);
  }

  &:active {
    filter: brightness(0.95);
  }

  & > h4 {
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0.07px;
    line-height: 17px;
    color: white;
    text-align: center;
  }
`;

export const PaymentTypeContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const MobilePaymentTypeContainer = styled.div`
  width: calc(100% - 43px - 36px - 36px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 35px;

  & > h5 {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.15px;
    line-height: 18px;
    color: #352f40;
    text-align: center;
    margin-bottom: 18px;
  }
`;

export const SinglePaymentTypeWrapper = styled.div`
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
  margin-right: 10px;
  & > p {
    width: 100px;
    font-size: 13px;
    text-align: center;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }

  &:last-of-type {
    margin-bottom: unset;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 348px;

    & > p {
      width: 90px;
      text-align: center;
    }
  }
`;

export const MobileSinglePaymentTypeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;

  &:last-of-type {
    margin-bottom: unset;
  }
`;

export const PaymentTypeCheckbox = styled.div<{
  value: number;
  paymentType: number;
}>`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({value, paymentType}) =>
    value === paymentType
      ? '2px solid #0091FF'
      : '2px solid rgba(47, 47, 104, 0.5)'};
  cursor: ${({value, paymentType}) =>
    value === paymentType ? 'default' : 'pointer'};

  & > svg {
    display: ${({value, paymentType}) =>
      value === paymentType ? 'unset' : 'none'};
    /* margin-left: 0.4px; */
    margin-top: 0.5px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    border: ${({value, paymentType, theme}) =>
      value === paymentType
        ? '2px solid #0091FF'
        : `2px solid ${theme.colors.fontBlackOp50}`};
  }
`;

export const MobilePaymentTypeCheckbox = styled.div<{
  value: number;
  paymentType: number;
}>`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${({value, paymentType}) =>
    value === paymentType
      ? '3px solid #0066FF'
      : '3px solid rgba(47, 47, 104, 0.5)'};
  cursor: ${({value, paymentType}) =>
    value === paymentType ? 'default' : 'pointer'};

  & > svg {
    display: ${({value, paymentType}) =>
      value === paymentType ? 'unset' : 'none'};
    margin-left: 1px;
  }
`;

export const Main = styled.div`
  width: 1020px;
  min-height: 689px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({theme}) => theme.colors.bgWhite};
  /* background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 21%,
    rgba(220, 224, 233, 1) 74%,
    rgba(220, 224, 233, 1) 100%
  ); */
  position: absolute;
  z-index: 3;
  top: 88px;
  /* box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12); */
  border-radius: 24px 24px 21px 21px;
  border: ${({theme}) => `1px solid ${theme.colors.mainBorderColor}`};
  box-shadow: ${({theme}) => theme.colors.mainBoxShadow};

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1100px) {
    width: 380px;
    height: unset;
    margin-bottom: 20px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;
  background: #0091ff;
  height: 55px;
  border-radius: 24px 24px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > p {
    /* color: ${({theme}) => theme.colors.fontBlackOp100}; */
    color: white;
    font-size: 18px;
    font-weight: 400;
  }
`;

export const BackButton = styled.button`
  width: 55px;
  height: 100%;
  position: absolute;
  left: 0px;
  border: none;
  background: transparent;
  border-radius: 24px 0 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const SectionsWrapper = styled.div`
  width: 100%;
  min-height: 480px;
  border-radius: 9px;
  padding-top: 15px;
  /* padding-right: 13px; */
  padding-left: 40px;
  display: flex;
  /* justify-content: space-between; */
  overflow-y: auto;
  overflow-x: hidden;
  gap: 80px;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    margin: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3a87f0;
    border-radius: 25px;
    border: 2px solid transparent;
    background-clip: padding-box;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    width: 367px;
    padding-left: unset;
    padding-right: unset;
    gap: unset;
    overflow: auto;
  }
  @media (max-width: 400px) {
    width: auto;
  }
`;

export const FooterWrapper = styled.div`
  height: 99px;
  width: 100%;
  background: #dce0e9;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 20px 22px;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);
  border-radius: 0 0 21px 21px;
  position: sticky;
  bottom: 0;
`;

export const LeftSection = styled.div`
  width: 260px;
  height: 100%;
  padding-top: 10px;
  /* margin-left: 10px;
  margin-right: 17px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-size: 13px;
    letter-spacing: 1px;
    margin-bottom: 12px;
    font-weight: 400;
    color: #07020f;
  }
`;

export const CenterSection = styled.div`
  width: 260px;
  height: 100%;

  padding-top: 10px;
  /* margin-right: 9px; */
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-size: 13px;
    letter-spacing: 1px;
    margin-bottom: 12px;
    font-weight: 400;
    color: #07020f;
  }
`;

export const RightSection = styled.div`
  width: 260px;
  height: 100%;
  padding-top: 10px;
  display: flex;

  flex-direction: column;
  align-items: center;
  position: relative;

  & > h1 {
    font-size: 13px;
    letter-spacing: 1px;
    margin-bottom: 12px;
    font-weight: 400;
    color: #07020f;
  }
`;

export const SectionContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 16px;
`;

export const ProtectiveUmbrellaWrapper = styled.div`
  width: 100%;
  display: flex;
  border-radius: 0px 0px 21px 21px;
  align-items: center;
  justify-content: center;
  gap: 17.1px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 214px;

    & > h4 {
      font-size: 11px;
      font-weight: 500;
      line-height: 21px;
      color: #0066ff;
    }

    & > p {
      font-size: 9px;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: -0.15px;
      color: rgba(0, 102, 255, 0.8);
    }
  }
`;

export const BuyButton = styled.button`
  position: absolute;
  top: 575px;
  margin-top: 30px;
  border: none;
  border-radius: 16px;
  background: #0091ff;
  width: 312px;
  height: 44px;
  transition: all 0.15s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0 17px; */
  justify-content: center;
  z-index: 1;
  gap: 3px;

  &:hover {
    filter: brightness(1.05);
  }

  &:active {
    filter: brightness(0.95);
  }

  & > h4 {
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.1px;
    line-height: 17px;
    color: white;
    text-align: center;
  }

  & > p {
    font-size: 9px;
    font-weight: 400;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: unset;
  }
`;

export const CardBuyButton = styled.button`
  position: absolute;
  top: 525px;
  margin-top: 30px;
  border: none;
  border-radius: 16px;
  background: #0091ff;
  width: 312px;
  height: 44px;
  transition: all 0.15s;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 0 17px; */
  justify-content: center;
  z-index: 1;
  gap: 3px;

  &:hover {
    filter: brightness(1.05);
  }

  &:active {
    filter: brightness(0.95);
  }

  & > h4 {
    font-size: 15px;
    font-weight: normal;
    letter-spacing: 0.1px;
    line-height: 17px;
    color: white;
    text-align: center;
  }

  & > p {
    font-size: 9px;
    font-weight: 400;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.7);
    text-align: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: unset;
  }
`;

export const MobileHeader = styled.div`
  height: 54px;
  width: 100%;
  background: ${({theme}) => theme.colors.bgWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.07);
  border-bottom: 1px solid
    ${({theme}) => theme.colors.mobileOfferDetailHeaderBorderColor};

  & > h1 {
    font-size: 13px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    letter-spacing: 0.07px;
    font-weight: 400;
  }

  & > svg {
    left: 21px;
    position: absolute;
  }
`;

export const SectionHeader = styled.h4`
  font-size: 11px;
  font-weight: 400;
  color: #07020f;
  letter-spacing: 0.0625px;
  margin-bottom: 6px;
`;
export const SectionTitle = styled.h4`
  font-size: 13px;
  line-height: 24px;
  font-weight: bold;
  align-self: flex-start;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  letter-spacing: 0.05px;
  margin-bottom: 6px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding-left: 14px;
  }
`;

export const SectionTextWithBorder = styled.div`
  width: 260px;
  height: 22px;
  border-bottom: ${({theme}) =>
    `1px solid ${theme.colors.transactionHeaderBlackFontOp30}`};
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  & > p {
    font-size: 11px;
    width: 130px;
    /* line-height: 24px; */
    letter-spacing: 0.1px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
  & > h4 {
    font-size: 11px;
    /* line-height: 24px; */
    letter-spacing: 0.1px;
    text-align: right;
    width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const SectionTextPrice = styled.div<{isBlue: boolean}>`
  width: 260px;
  height: 22px;
  border-bottom: ${({theme, isBlue}) =>
    isBlue
      ? `1px solid ${theme.colors.blueTheme}`
      : `1px solid ${theme.colors.transactionHeaderBlackFontOp30}`};
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  & > p {
    font-size: 11px;
    width: 130px;
    /* line-height: 24px; */
    letter-spacing: 0.1px;
    color: ${({theme, isBlue}) =>
      isBlue ? theme.colors.blueTheme : theme.colors.fontBlackOp50};
  }
  & > div {
    display: flex;
    width: 130px;
    justify-content: flex-end;
    gap: 8.5px;
    & > h4 {
      font-size: 11px;
      /* line-height: 24px; */
      letter-spacing: 0.1px;
      text-align: right;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      color: ${({theme, isBlue}) =>
        isBlue ? theme.colors.blueTheme : theme.colors.fontBlackOp100};
    }
    & > p {
      font-size: 11px;

      /* line-height: 24px; */
      letter-spacing: 0.1px;
      color: ${({theme, isBlue}) =>
        isBlue ? theme.colors.blueTheme : theme.colors.fontBlackOp100};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const SectionTextGrey = styled.h4`
  font-size: 13px;
  line-height: 18px;
  font-weight: normal;
  align-self: flex-start;
  color: ${({theme}) => theme.colors.fontBlackOp50};
  letter-spacing: -0.25px;
  margin-bottom: 6px;
`;

export const SectionTextGreyWrapper = styled.div`
  height: 167px;
  width: 260px;
  /* margin-top: 12px; */
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  & > p {
    font-size: 11px;
    /* line-height: 24px; */
    letter-spacing: 0.1px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
  }
  & > span {
    /* height: 149px; */
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.25px;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const SectionWinWinWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
`;

export const SectionTextUnderline = styled.h4`
  font-size: 9px;
  font-weight: 400;
  color: #352f40;
  text-decoration: underline;
`;

export const FirstTextInLineSpan = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;

  & > h4 {
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0.0625px;
    color: rgba(7, 2, 15, 0.5);
  }

  & > p {
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(7, 2, 15, 1);
    letter-spacing: 0.0625px;
    text-align: right;
    width: 184px;
  }
`;

export const SecondTextInLineSpan = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;

  & > h4 {
    font-size: 11px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.0625px;
    color: rgba(7, 2, 15, 1);
  }

  & > p {
    font-size: 11px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.0625px;
    color: #0066ff;
    text-align: right;
  }
`;

export const ThirdTextInLineSpan = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;

  & > h4 {
    width: 214px;
    font-size: 11px;
    letter-spacing: 0.0625px;
    font-weight: 400;
    line-height: 13px;
    color: #07020f;
  }

  & > p {
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.0625px;
    line-height: 13px;
    color: #0066ff;
    text-align: right;
  }
`;

export const FourthTextInLineSpan = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  & > h4 {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.0625px;
    line-height: 24px;
    color: rgba(7, 2, 15, 0.5);
  }

  & > p {
    font-size: 11px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.0625px;
    color: #0066ff;
    text-align: right;
  }
`;

export const IconWithTextSpan = styled.div`
  width: 260px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  position: relative;

  & > svg {
    /* margin-top: 6px; */
  }

  & > p {
    width: 260px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.25px;
    line-height: 18px;
    color: ${({theme}) => theme.colors.fontBlackOp50};
    text-align: left;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100% - 26px);

    & > p {
      width: 100%;
    }
  }
`;

export const LeftDotSvg = styled.div`
  position: absolute;
  left: -13px;
  top: -2px;
  width: 9px;
  height: 9px;
  & > svg {
    width: 9px;
    height: 9px;
  }
`;

export const SectionText = styled.p`
  width: 100%;
  font-size: 9px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.15px;
  color: #07020f;
  margin-bottom: 7px;
  text-align: center;
`;

export const SectionLine = styled.hr`
  width: 300px;
  border: none;
  border-bottom: 1px solid rgba(7, 2, 15, 0.3);
  margin-bottom: 10px;
`;

export const FooterLine = styled.hr`
  width: 920px;
  border: none;
  border-bottom: 1px solid rgba(7, 2, 15, 0.3);
  position: absolute;
  top: 16px;
  left: 50px;
`;

export const PaymentTypeWrapper = styled.div<{
  value: number;
  paymentType: number;
}>`
  width: 272px;
  min-height: 70px;
  display: flex;
  align-items: center;
  background: #f8f8f8;
  justify-content: space-between;
  cursor: ${({value, paymentType}) =>
    value === paymentType ? 'default' : 'pointer'};
  border: ${({value, paymentType}) =>
    value === paymentType
      ? '2px solid #0066FF'
      : '2px solid rgba(112,112,112,0.3)'};
  border-radius: 5px;
  padding-left: 28px;
  padding-right: 32.3px;
  margin-bottom: 11px;

  & > div {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: ${({value, paymentType}) =>
      value === paymentType
        ? '4px solid #0066FF'
        : '4px solid rgba(112,112,112,0.3)'};

    & > div {
      display: ${({value, paymentType}) =>
        value === paymentType ? 'unset' : 'none'};
      border-radius: 50%;
      background: #0066ff;
      width: 14px;
      height: 14px;
    }
  }
`;

export const TextareaWrapper = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
}>`
  position: relative;
  width: 300px;
  min-height: 88px;
  margin-top: 9px;
  background: transparent;
  border: ${({isEditing, isError}) =>
    isEditing ? (isEditing && isError ? 'none' : 'solid 1px #e1e1e1') : 'none'};
  border-radius: ${({isEditing, isError}) =>
    isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
  color: ${({isEditing}) => (isEditing ? `#707070` : `#07020F`)};

  & > h5 {
    font-size: 11px;
    font-weight: 400;
    line-height: 24px;
    color: ${({isEditing}) => (isEditing ? '#07020f' : '#707070')};
    position: absolute;
    top: 2px;
    left: 11.5px;
  }

  & > h4 {
    display: ${({isEditing}) => (isEditing ? 'unset' : 'none')};
  }
`;

export const StyledTextarea = styled.textarea<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  width: 99%;
  height: 99%;
  border: none;
  background: ${({isEditing, isError}) =>
    isEditing
      ? isEditing && isError
        ? '#FF7E7D'
        : 'transparent'
      : 'transparent'};
  padding: 24px 11.5px 6px;
  border-radius: ${({isEditing, isError}) =>
    isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
  color: ${({isEditing}) => (isEditing ? `rgba(7, 2, 15, 0.5)` : `#07020F`)};
  font-size: 11px;
  line-height: 24px;
  resize: none;
  outline: none;
  font-weight: 400;
`;

export const TextareaLettersCount = styled.h4`
  font-size: 11px;
  position: absolute;
  right: 9px;
  bottom: 8px;
  color: #3a87f0;
  font-weight: 400;
  letter-spacing: 0.625px;
`;

export const SectionHeaderWithWinWin = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const PersonTypeWrapper = styled.div`
  width: 260px;
  min-height: 22px;
  display: flex;
  background: ${({theme}) => theme.colors.bgGrey};
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  border: ${({theme}) =>
    `solid 1px ${theme.colors.transactionHeaderBlackFontOp30}`};
  padding: 1.5px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

export const SinglePersonTypeWrapper = styled.div<{
  value: number;
  personType: number;
}>`
  width: 130px;
  min-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({value, personType}) =>
    value === personType ? 'default' : 'pointer'};
  border-radius: 4px;
  background: ${({theme, value, personType}) =>
    value === personType ? theme.colors.blueTheme : 'transparent'};
  color: ${({theme, value, personType}) =>
    value === personType ? 'white' : theme.colors.fontBlackOp50};
  font-size: 11px;
  letter-spacing: 0.1px;
  /* font-weight: 500; */

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 50%;
  }
`;
