import styled, {css} from 'styled-components';

import arrowUp from 'images/svg/blue-arrow-up.svg';
import arrowDown from 'images/svg/blue-arrow-down.svg';
import arrowRight from '../../../images/svg/select-arrow-right.svg';
import calendar from 'images/svg/calendar.svg';

export const StyledStatusWrapper = styled.div<{KYCLevelStatus?: string}>`
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin: 12px auto;
  margin-bottom: 5px;

  & > h1 {
    letter-spacing: 0.1px;
    font-size: 11px;
    font-weight: 500;

    ${({KYCLevelStatus, theme}) => {
      switch (KYCLevelStatus) {
        case 'REGULAR':
          return css`
            color: #0091ff;
          `;
        case 'LIGHT':
          return css`
            color: #fb9614;
          `;

        default:
          return css`
            color: #ff0300;
          `;
      }
    }}
  }
  @media (max-width: 1024px) {
    width: 280px;
    margin-top: 17px;
  }
`;

export const StyledStatusLine = styled.div<{KYCLevelStatus?: string}>`
  width: 220px;
  border-bottom: 3px solid ${({theme}) => theme.colors.statusDefaultLine};
  position: relative;
  border-radius: 25px;

  & > div {
    position: absolute;
    left: 0px;
    border-radius: 25px;
    height: 3px;

    ${({KYCLevelStatus, theme}) => {
      switch (KYCLevelStatus) {
        case 'REGULAR':
          return css`
            background: #0091ff;
            width: 220px;
          `;
        case 'LIGHT':
          return css`
            background: #fb9614;
            width: 155px;
          `;

        default:
          return css`
            background: #ff0300;
            width: 220px;
          `;
      }
    }}
  }
  @media (max-width: 1024px) {
    width: 280px;
    & > div {
      height: 2px;
    }

    ${({KYCLevelStatus, theme}) => {
      switch (KYCLevelStatus) {
        case 'REGULAR':
          return css`
            background: #0091ff;
            width: 280px;
          `;
        case 'LIGHT':
          return css`
            background: transparent;
            width: 280px;
          `;

        default:
          return css`
            background: #ff0300;
            width: 280px;
          `;
      }
    }}
  }
`;

export const Main = styled.div`
  width: 1038px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 20px;

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1045px) {
    width: 360px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 1000px;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 9px;
  padding: 11px 73px;
  margin-bottom: 17px;

  & > p {
    text-align: center;
    color: rgba(255, 255, 255, 0.86);
    font-size: 11px;
    line-height: 21px;
  }

  @media (max-width: 1045px) {
    width: 328px;
    padding: 11px 26px;
  }
`;

export const BundleWrapper = styled.div`
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;

  @media (max-width: 1045px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const SingleBundleWrapper = styled.div`
  width: 320px;
  height: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 26px 10px;
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.38);

  & > h4 {
    line-height: 36px;
    font-size: 32px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.92);
  }
`;

export const Status = styled.p`
  font-size: 11px;
  line-height: 41px;
  color: #00ff9d;
`;

export const Pricing = styled.p`
  font-size: 11px;
  line-height: 41px;
  color: rgba(255, 255, 255, 0.92);
  margin-bottom: 10px;
`;

export const ListHeader = styled.p`
  font-size: 11px;
  line-height: 22px;
  color: white;
  letter-spacing: 0.5px;
  font-weight: 400;
`;

export const List = styled.ul`
  height: 225px;
  list-style-type: '- ';
  width: 246px;

  & > li {
    font-size: 11px;
    line-height: 28px;
    color: white;
  }
`;

export const Footer = styled.p`
  font-size: 9px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.7);
  width: 242px;
  text-align: center;
`;

export const LinkInText = styled.a`
  font-size: 9px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.7);
`;

export const InputWithButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const StyledButton = styled.button`
  border: none;
  background: #a100ff;
  border-radius: 25px;
  height: 41px;
  width: 104px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    filter: brightness(1.1);
  }
`;

export const InputWrapper = styled.div`
  width: 148px;
  height: 38px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    position: absolute;
    top: -6px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > input {
    width: 100%;
    height: 100%;
    padding: 4px 14px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: center;
    outline: none;

    ::placeholder {
      color: white;
    }
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  //grid-auto-rows: 372px;
  gap: 2px;

  @media (max-width: 1045px) {
    grid-template-columns: 100%;
    grid-auto-rows: 372px;
    gap: 11px;
  }
`;

export const UserDataWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: span 3;

  @media (max-width: 1045px) {
    grid-column: span 1;
    grid-row: span 3;
    background: rgba(6, 8, 23, 0.95);
    order: 1;
  }
`;

export const UserDataHeader = styled.div`
  width: 100%;
  position: relative;
  border-radius: 9px 9px 0 0;
  background: #00ff9d;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: #060817;
  }
`;

export const UserDataHeaderButton = styled.button`
  background: transparent;
  border: 2px solid #060817;
  border-radius: 30px;
  font-size: 9px;
  font-weight: 600;
  color: #060817;
  padding: 0 12px;
  line-height: 20px;
  position: absolute;
  right: 17px;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    border: 2px solid rgba(255, 255, 255, 0.8);
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const UserDataCancelButton = styled(UserDataHeaderButton)`
  right: 80px;

  @media (max-width: 1045px) {
    right: unset;
    left: 17px;
  }
`;

export const UserDataContent = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  display: flex;

  @media (max-width: 1045px) {
    flex-direction: column;
  }
`;

export const UserDataAddImageWrapper = styled.div`
  width: 338px;
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(255, 255, 255, 0.21);
  justify-content: center;

  @media (max-width: 1045px) {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  }
`;

export const AddImageContent = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  & > p {
    font-size: 13px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const AddImage = styled.img`
  width: 38px;
  height: 38px;
  cursor: pointer;
  transition: all 0.15s;
  user-select: none;

  &:hover {
    filter: brightness(0.6);
  }

  & > img {
    user-select: none;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 338px;
  height: 100%;
  padding: 18px 16px 0;
  border-right: 1px solid rgba(255, 255, 255, 0.21);

  @media (max-width: 1045px) {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.21);
    justify-content: center;
  }
`;

export const CheckboxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const SingleCheckboxWrapper = styled.div`
  height: 32px;
  width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & > p {
    font-size: 9px;
    color: rgba(255, 255, 255, 0.92);
  }
`;

export const Checkbox = styled.div<{
  displayProducerNameAsPublicDisplayName: boolean;
  value: boolean;
}>`
  width: 10px;
  height: 10px;
  border-radius: 50%;

  ${({displayProducerNameAsPublicDisplayName, value}) =>
    displayProducerNameAsPublicDisplayName === value
      ? css`
          background: #00ff9d;
        `
      : css`
          border: 1px solid rgba(255, 255, 255, 0.76);
          cursor: pointer;
        `}
`;

export const UserInputWrapper = styled.div<{isProducerEditing: boolean}>`
  width: 100%;
  height: 38px;
  border-radius: 25px;
  border: 1px solid
    ${({isProducerEditing}) =>
      isProducerEditing
        ? 'rgba(23, 117, 211, 0.51)'
        : 'rgba(255, 255, 255, 0.11)'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  & > p {
    position: absolute;
    top: -6px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > input {
    width: 100%;
    height: 100%;
    padding: 4px 14px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: center;
    outline: none;

    ::placeholder {
      color: white;
    }
  }
`;

export const UserSelectWrapper = styled.div<{isProducerEditing: boolean}>`
  width: 148px;
  height: 43px;
  border-radius: 25px;
  border: 1px solid
    ${({isProducerEditing}) =>
      isProducerEditing
        ? 'rgba(23, 117, 211, 0.51)'
        : 'rgba(255, 255, 255, 0.11)'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;

  & > p {
    position: absolute;
    top: -6px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > select {
    width: 130px;
    color: white;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: center;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: 104px 4px;

    & > option {
      background: #060817;
    }
  }
`;

export const DeactivationSelectWrapper = styled.div`
  width: 148px;
  height: 43px;
  border-radius: 25px;
  border: 1px solid rgba(255, 255, 255, 0.11);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;

  & > p {
    position: absolute;
    top: -6px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.48);
  }

  & > select {
    width: 130px;
    color: white;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    background: transparent;
    border: none;
    text-align: center;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position: 104px 4px;

    & > option {
      background: #060817;
    }
  }
`;

export const SpanInputs = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StatuteWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
`;

export const StatuteHeader = styled.div`
  width: 100%;
  position: relative;
  height: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: white;
  }
`;

export const StatuteHeaderButton = styled.button`
  background: transparent;
  border: 2px solid white;
  border-radius: 30px;
  font-size: 9px;
  font-weight: 400;
  color: white;
  padding: 0 12px;
  line-height: 20px;
  position: absolute;
  right: 17px;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const StatuteContent = styled.div`
  width: 338px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 17px;
  text-align: center;

  & > h4 {
    font-size: 13px;
    color: white;
    line-height: 24px;
    font-weight: 400;
    margin: 5px 0;
  }

  & > p {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.86);
    line-height: 21px;
  }
`;

export const SingleStatuteWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7px 0;

  & > div > h4 {
    font-size: 13px;
    line-height: 21px;
    color: white;
    font-weight: 400;
    letter-spacing: 0.15px;
  }

  & > div > p {
    margin-left: 6px;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.58);
  }

  @media (max-width: 1045px) {
    margin: 16px 0;
  }
`;

export const CheckboxTitle = styled.div`
  width: 198px;
`;

export const SingleStatuteCheckbox = styled.div<{value: boolean}>`
  width: 48px;
  height: 28px;
  border-radius: 25px;
  border: 1px solid
    ${({value}) => (value ? '#19D627' : 'rgba(255,255,255,0.11)')};
  position: relative;
  cursor: pointer;
  transition: all 0.15s;

  & > div {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: absolute;
    transition: all 0.15s ease-in;

    ${({value}) =>
      value
        ? css`
            left: 20px;
            top: 1px;
            background: #19d627;
          `
        : css`
            left: 1px;
            top: 1px;
            background: rgba(255, 255, 255, 0.21);
          `};
  }
`;

export const CheckboxCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #187ee4;
  position: absolute;
  right: 1px;
`;

export const StatutePDFLink = styled.a`
  font-size: 11px;
  color: rgba(255, 255, 255, 0.58);
`;

export const OfferFAQLink = styled.a`
  font-size: 11px;
  color: rgba(255, 255, 255, 0.86);
`;

export const BlockWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);

  @media (max-width: 1045px) {
    background: rgba(6, 8, 23, 0.95);
  }
`;

export const BlockWrapperSocial = styled(BlockWrapper)`
  @media (max-width: 1045px) {
    order: 4;
  }
`;

export const BlockWrapperSuccesses = styled(BlockWrapper)`
  @media (max-width: 1045px) {
    order: 9;
  }
`;

export const BlockWrapperContact = styled(BlockWrapper)`
  @media (max-width: 1045px) {
    order: 11;
  }
`;

export const BlockHeader = styled.div`
  width: 100%;
  position: relative;
  background: #187ee4;
  border-radius: 9px 9px 0 0;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: #060817;
  }
`;

export const BlockContent = styled.div`
  width: 100%;
  padding: 38px 18px 26px;
  display: flex;
  height: calc(100% - 35px);
  flex-direction: column;
  align-items: center;
  gap: 14px;

  & > h1 {
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.15px;
    color: rgba(255, 255, 255, 0.92);
  }

  & > p {
    font-size: 11px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.86);
    text-align: justify;
  }
`;

export const BlockButton = styled.button`
  cursor: pointer;
  width: 138px;
  height: 41px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.11);
  color: white;
  letter-spacing: 0.15px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  transition: all 0.15s;
`;

export const AdWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);

  & > img {
    height: calc(100% - 35px);
  }

  @media (max-width: 1045px) {
    order: 99;
  }
`;

export const AdHeader = styled.div`
  width: 100%;
  position: relative;
  border-radius: 9px 9px 0 0;
  background: rgba(202, 202, 202, 1);
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: #060817;
  }
`;

export const AdHeaderButton = styled.button`
  background: transparent;
  border: 2px solid #060817;
  border-radius: 30px;
  font-size: 9px;
  font-weight: 600;
  color: #060817;
  padding: 0 12px;
  line-height: 20px;
  position: absolute;
  right: 7px;
  cursor: pointer;
  transition: all 0.15s;

  &:hover {
    border: 2px solid #505054;
    color: #505054;
  }
`;

export const StatisticsWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);

  @media (max-width: 1045px) {
    background: rgba(6, 8, 23, 0.95);
  }
`;

export const StatisticsWrapperProducts = styled(StatisticsWrapper)`
  @media (max-width: 1045px) {
    order: 5;
  }
`;

export const StatisticsWrapperMessages = styled(StatisticsWrapper)`
  @media (max-width: 1045px) {
    order: 6;
  }
`;

export const StatisticsWrapperFinances = styled(StatisticsWrapper)`
  @media (max-width: 1045px) {
    order: 7;
  }
`;

export const StatisticsHeader = styled.div`
  width: 100%;
  position: relative;
  height: 35px;
  background: rgba(100, 100, 100, 1);
  border-radius: 9px 9px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: white;
  }
`;

export const StatisticsContent = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 14px;
`;

export const StatisticsFooter = styled.p`
  font-size: 11px;
  color: rgba(255, 255, 255, 0.86);
  line-height: 21px;
  text-align: center;
  margin: 19px 0;
`;

export const StatisticsTextSpan = styled.div`
  display: flex;
  width: 100%;
  padding: 0 22px;
  justify-content: space-between;

  & > p {
    font-size: 13px;
    font-weight: 400;
    color: white;
    line-height: 41px;
  }

  & > h4 {
    font-weight: bold;
    font-size: 32px;
    color: rgba(255, 255, 255, 0.92);
    letter-spacing: 0.15px;
    line-height: 36px;
  }
`;

export const StatisticsDesc = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: white;
  line-height: 41px;
`;

export const StatisticsValue = styled.h4`
  font-weight: bold;
  font-size: 32px;
  color: rgba(255, 255, 255, 0.92);
  letter-spacing: 0.15px;
  line-height: 36px;
`;

export const StatisticsButton = styled.button`
  cursor: pointer;
  width: 100%;
  height: 41px;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.11);
  color: white;
  letter-spacing: 0.15px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  transition: all 0.15s;
`;

export const DeactivationWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);

  @media (max-width: 1045px) {
    order: 10;
    background: rgba(6, 8, 23, 0.95);
  }
`;

export const DeactivationHeader = styled.div`
  width: 100%;
  position: relative;
  height: 35px;
  background: rgba(100, 100, 100, 1);
  border-radius: 9px 9px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: white;
  }
`;

export const DeactivationContent = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  padding: 13px 10px 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1045px) {
    justify-content: space-between;
  }
`;

export const DeactivationButtonWithText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 9px;
  padding: 10px 21px;

  & > p {
    font-size: 11px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.86);
  }
`;

export const DeactivationButton = styled.button`
  width: 150px;
  height: 38px;
  border: 1px solid rgba(255, 255, 255, 0.21);
  border-radius: 19px;
  color: white;
  background: transparent;
  font-size: 11px;
  letter-spacing: 0.15px;
  cursor: pointer;
  transition: all 0.15s;
  margin-right: 48px;

  &:hover {
    filter: brightness(1.2);
  }

  @media (max-width: 1045px) {
    margin-right: 0;
  }
`;

export const OfferWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: span 2;

  @media (max-width: 1045px) {
    grid-column: span 1;
    grid-row: span 3;
    background: rgba(6, 8, 23, 0.95);
    order: 8;
  }
`;

export const OfferHeader = styled.div`
  width: 100%;
  position: relative;
  height: 35px;
  background: rgba(24, 126, 228, 0.21);
  border-radius: 9px 9px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: white;

    @media (max-width: 1045px) {
      font-size: 11px;
    }
  }
`;

export const DeactivationTextarea = styled.textarea`
  width: 100%;
  height: 110px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.11);
  border-radius: 25px;
  padding: 10px 13px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  line-height: 24px;
  resize: none;
  outline: none;
  font-weight: 500;
  margin-top: 3px;
  resize: none;
  outline: none;

  &:focus {
    border: 1px solid rgb(24, 118, 213);
  }
`;

export const TextareaWrapper = styled.div`
  position: relative;

  & > p {
    position: absolute;
    top: -9px;
    width: 100%;
    text-align: center;
    font-size: 9px;
    letter-spacing: 0.5px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.48);
  }
`;

export const OfferMain = styled.div`
  width: 100%;
  height: calc(100% - 35px);
  padding: 13px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const OfferButtonWithText = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 9px;
  padding: 10px 21px;

  & > p {
    font-size: 11px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.86);
    width: 390px;
  }

  @media (max-width: 1045px) {
    display: none;
  }
`;

export const MobileOfferButtonWithText = styled.div`
  width: 100%;
  display: none;
  align-items: center;
  background: rgba(255, 255, 255, 0.07);
  border-radius: 9px;
  padding: 39px 21px;
  height: 312px;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  margin-bottom: 15px;

  & > p {
    font-size: 11px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.86);

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1045px) {
    display: flex;
  }
`;

export const OfferContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1045px) {
    flex-direction: column;
  }
`;

export const OfferContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  & > h4 {
    font-size: 16px;
    text-align: center;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 41px;
    color: white;
  }

  & > p {
    font-size: 11px;
    text-align: justify;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.86);
  }

  @media (max-width: 1045px) {
    width: 100%;
    padding: 40px 15px 100px;

    &:first-child {
      border-bottom: 1px solid rgba(255, 255, 255, 0.11);
      border-top: 1px solid rgba(255, 255, 255, 0.11);
    }
  }
`;

export const LineBetween = styled.div`
  width: 1px;
  height: 76%;
  background: rgba(255, 255, 255, 0.21);
  align-self: center;
`;

export const CommentsWrapper = styled.div`
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: span 2;

  @media (max-width: 1045px) {
    display: none;
  }
`;

export const MobileCommentsWrapper = styled.div`
  border-radius: 9px;
  display: none;
  flex-direction: column;
  background: rgba(6, 8, 23, 0.66);
  grid-column: span 1;

  @media (max-width: 1045px) {
    display: flex;
    background: rgba(6, 8, 23, 0.95);
    order: 3;
  }
`;

export const CommentsHeader = styled.div`
  width: 100%;
  position: relative;
  height: 35px;
  background: rgba(100, 100, 100, 1);
  border-radius: 9px 9px 0 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 14px;
    color: white;
  }

  & > img {
    width: 7.02px;
    margin: 0 12px;
    height: 12px;
    cursor: pointer;
    transition: all 0.15s;

    &:hover {
      filter: brightness(1.2);
    }
  }

  @media (max-width: 1045px) {
    justify-content: space-between;
    text-align: center;
    padding: 0 6px;
  }
`;

export const CommentsContent = styled.div`
  width: 100%;
  padding: 5px 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  height: calc(100% - 35px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 24px;
    height: 10px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    background-image: url(${arrowUp});
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment {
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    width: 9px;
    height: 15px;
    background-position: 4px 3px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #125faf;
    border-radius: 25px;
    border: 10px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
`;

export const SingleCommentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 9px;
  background: rgba(255, 255, 255, 0.05);

  @media (max-width: 1045px) {
    height: 100%;
  }
`;

export const SingleCommentHeader = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.11);
  padding: 0 13px;

  & > span {
    margin: 0 7px;
    font-size: 13px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.38);
    line-height: 24px;
  }
`;

export const CommentHeaderTextBox = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const CommentHeaderTextWrapper = styled.div`
  display: flex;
  gap: 7px;

  & > span {
    font-size: 11px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.38);
  }
`;

export const CommentDate = styled.p`
  font-size: 13px;
  color: white;
  line-height: 24px;

  @media (max-width: 1045px) {
    font-size: 11px;
  }
`;

export const CommentUserName = styled.p`
  font-size: 13px;
  color: #187ee4;
  line-height: 24px;
  font-weight: 700;

  @media (max-width: 1045px) {
    font-size: 11px;
  }
`;

export const SingleCommentContent = styled.div`
  width: 100%;
  text-align: justify;

  & > p {
    font-size: 11px;
    padding: 14px 22px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.86);
  }

  & > button {
    width: 100%;
    height: 48px;
    background: rgba(255, 255, 255, 0.11);
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.21);
    border-radius: 0 0 9px 9px;
    font-weight: 500;
    font-size: 18px;
    transition: all 0.15s;
    color: white;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.21);
    }
  }

  @media (max-width: 1045px) {
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const StarWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-right: 16px;

  & > img {
    width: 21px;
    height: 21px;
  }
`;

export const ActiveBundleFooter = styled.p`
  color: rgba(255, 255, 255, 0.92);
  font-size: 11px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  width: 216px;
`;

export const ProducerDataWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 38px;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.11);
  justify-content: center;
  flex-direction: column;
  border-radius: 25px;
  position: relative;
  gap: 5px;
  margin: 10px 0;

  & > h4 {
    font-size: 9px;
    color: rgba(255, 255, 255, 0.48);
    position: absolute;
    top: -7px;
  }

  & > p {
    color: white;
    font-size: 13px;
    font-weight: 500;
  }
`;

export const ProducerDataSpan = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
`;

//////////////////////////////////////////////////// new account    /////////////

export const AccountMainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const AccountStyleMain = styled.div`
  width: 1020px;
  //min-height: 795px;
  //height: 82vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 88px;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  overflow: hidden;
  border-radius: 24px 24px 24px 24px;
  /* margin-bottom: 20px; */
  box-shadow: ${({theme}) => theme.colors.mainBoxShadow};

  & > p {
    font-size: 14px;
    /* color: rgba(255, 255, 255, 0.92); */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    top: 48px;
    border: none;
    border-radius: 0px;
    position: relative;
    top: 0;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: unset;
    margin-bottom: 20px;
  }
`;

export const AccountHeaderWrapper = styled.div`
  width: 100%;
  /* background: #89bfff; */
  background: ${({theme}) => theme.colors.pageHeader};
  /* border-radius: 24px 24px 0 0; */
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & > p {
    letter-spacing: 0.625px;
    font-size: 15px;
    /* color: #352f40; */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-weight: 400;
  }
  & > svg {
    position: absolute;
    left: 21px;

    & > path {
      fill: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }

  @media (max-width: 1024px) {
    border-radius: 0;
    display: none;
  }
`;

export const AccountHeaderSaveButton = styled.button`
  width: 120px;
  height: 100%;
  position: absolute;
  right: 0px;
  border: none;
  border-left: 1px solid white;
  background: transparent;
  border-radius: 0 9px 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;
  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const AccountGrid = styled.div`
  width: 100%;
  //height: calc(100% - 55px);
  height: 610px;
  border-radius: 0 0 24px 24px;
  background: ${({theme}) => theme.colors.pageBodyLeft};
  display: flex;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    border-radius: 0;
    height: auto;
    flex-direction: column;
    /* background: #eeeef1; */
    background: ${({theme}) => theme.colors.mobileBodyBg};
  }
`;

export const AccountBasicDate = styled.div<{isEditing: boolean}>`
  width: 340px;
  position: relative;
  padding: 10px 8px 7px 7.5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    /* color: black; */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-align: center;
  }
  @media (max-width: 1024px) {
    /* background: ${({theme, isEditing}) =>
      isEditing ? theme.colors.bgWhite : 'transparent'}; */
    background: transparent;
    width: 400px;
    padding: 15px 0px;
    /* border: 1px solid rgba(112, 112, 112, 0.3);
    border-color: ${({theme, isEditing}) =>
      isEditing ? theme.colors.blueTheme : 'rgba(112,112,112,0.3)'}; */
    border-radius: 16px;
    margin: 15px auto;
    & > p {
      font-size: 18px;
      font-weight: normal;
      padding: 3px 18px 24px 18px;
      align-self: flex-start;
      margin-left: 16px;

      font-weight: bold;
      -webkit-letter-spacing: -0.15px;
      -moz-letter-spacing: -0.15px;
      -ms-letter-spacing: -0.15px;
      letter-spacing: -0.15px;
    }
  }
  @media (max-width: 400px) {
    width: 358px;
  }
  @media (max-width: 320px) {
    width: 98%;
    padding: 15px 4px 32px 4px;
  }
`;

export const AccountBasicDateImgWrapper = styled.div<{
  isEditing: boolean;
  isNotPhoto: boolean;
}>`
  width: 120px;
  height: 120px;
  /* background: #f6f6f6; */
  border-radius: 15px;
  margin-bottom: 12px;
  border: ${({theme, isEditing, isNotPhoto}) =>
    isEditing && isNotPhoto
      ? `1px solid ${theme.colors.fontBlackOp15}`
      : 'none'};
  color: white;
  position: relative;

  & > img {
    height: 100%;
    width: 100%;
    border-radius: 15px;
  }

  & > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    & > g {
      & > g {
        & > path {
          fill: ${({theme}) => theme.colors.trackPlaceholderSvgColor};
        }
      }
    }
  }

  & > p {
    position: absolute;
    top: 63%;
    left: 50%;
    /* color: black; */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    cursor: pointer;
    transform: translate(-50%, -50%);
  }
  & > div {
    & > svg {
      cursor: pointer;
    }
  }

  @media (max-width: 1024px) {
    width: 176px;
    height: 176px;
    border-radius: 7px;
  }
`;

export const AccountBasicDateDateWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1024px) {
    gap: 15px;
  }
`;

export const AccountInputWrapper = styled.div<{
  isRedBorder?: boolean;
  isEditActive?: boolean;
}>`
  background: ${({isRedBorder, theme}) =>
    isRedBorder ? theme.colors.statusRed : 'transparent'};
  border: 1px solid rgba(112, 112, 112, 0.3);
  position: relative;
  height: 22px;
  border-radius: 5px;
  width: 312px;
  margin-top: ${({isRedBorder}) => (isRedBorder ? `0` : '9px')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    /* color: #07020f; */
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.625px;
    font-weight: 400;
    margin-left: 8.5px;
    cursor: default;

    & > svg {
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }

  & > input {
    text-align: right;
    font-size: 11px;
    line-height: 18px;
    margin-right: 8.5px;
    //color: rgba(7, 2, 15, 0.5);
    /* color: ${({isEditActive}) =>
      isEditActive ? `rgba(7, 2, 15, 0.5)` : '#07020f'}; */
    color: ${({theme, isEditActive}) =>
      isEditActive
        ? theme.colors.theme.colors.fontBlackOp50
        : theme.colors.fontBlackOp100};

    cursor: ${({isEditActive}) => (isEditActive ? `auto` : 'default')};
    letter-spacing: 0.625px;
    font-weight: 400;
    background-color: transparent;
    border: none;

    &::placeholder {
      /* color: rgba(7, 2, 15, 0.5); */
      color: ${({theme}) => theme.colors.fontBlackOp50};
    }
  }
  & > button {
    width: 40px;
    border: none;
    /* color: white; */
    color: ${({theme}) => theme.colors.fontWhite};
    /* background: #89bfff; */
    background: ${({theme}) => theme.colors.blueTheme};
    font-size: 11px;
    line-height: 19px;
    border-radius: 5px;
  }

  & > input:focus {
    outline: none;
  }

  & > select {
    padding-right: 17px;
    font-size: 11px;
    margin-right: 8.5px;
    background-color: transparent;
    border: none;
    opacity: 1 !important;
    color: ${({isEditActive}) =>
      isEditActive ? `rgba(7, 2, 15, 0.5)` : '#07020f'};
    cursor: ${({isEditActive}) => (isEditActive ? `pointer` : 'default')};
    background-image: url(${arrowRight});
    background-repeat: no-repeat;
    background-position: 160px 5px;
    text-align: right;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    & > option {
      background: white;
      color: rgb(137, 191, 255);
      cursor: pointer;
    }
  }

  & > select:focus {
    outline: none;
  }
`;

export const AccountBasicButtonWrapper = styled.div<{isEditing?: boolean}>`
  width: 306px;
  height: 28px;
  margin: 10px auto;
  align-self: flex-start;
  border-radius: 7px;
  padding: 2px;
  gap: 2px;
  border: ${({isEditing}) =>
    isEditing
      ? '1px solid rgba(112, 112, 112, 0.3)'
      : '1px solid rgba(112, 112, 112, 0.0)'};
  background: transparent;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    width: 367px;
    height: 24px;
    align-items: center;
    display: flex;
    background: ${({theme}) => theme.colors.inputMobileBg};
    /* background: transparent; */
    border-radius: 5px;
    border: ${({theme}) => `1px solid ${theme.colors.inputMobileBorderColor}`};
    margin-bottom: 15px;
    margin-top: 10px;
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

export const AccountBasicButton = styled.button<{
  value: string;
  messagesType: string;
}>`
  cursor: ${({value, messagesType}) =>
    value === messagesType ? 'default' : 'pointer'};
  background: ${({value, messagesType}) =>
    value === messagesType ? '#D7E9FF' : '#E2E2EA'};
  border: none;
  color: ${({value, messagesType}) =>
    value === messagesType ? '#352F40' : 'rgba(53,47,64,0.5)'};
  font-size: 11px;
  letter-spacing: 0.625px;
  font-weight: 400;
  border-radius: 5px;
  padding: 3px 5px;
  flex-basis: 33.9%;
  transition: all 0.15s;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
`;

export const AccountSellerDate = styled.div`
  width: 680px;
  background: ${({theme}) => theme.colors.pageBodyCenterAndRight};
  border-bottom-right-radius: 21px;

  @media (max-width: 1024px) {
    border-radius: 0;
    background: ${({theme}) => theme.colors.pageBodyCenterAndRight};
    width: 100%;
  }
`;

export const AccountBigBlueBtn = styled.button`
  width: 320px;
  height: 44px;
  font-size: 15px;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 0.7px;
  border: none;
  color: white;
  border-radius: 16px;
  //background: #3986ef;
  background: ${({theme}) => theme.colors.blueTheme};
  transition: all 0.15s;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
  & > a {
    text-decoration: none;
    color: white;
  }
  @media (max-width: 1024px) {
    width: 367px;
    margin-bottom: 10px;
    font-size: 17px;
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

export const AccountFormWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  //min-height: 681px;
  //height: 71vh;
  height: 100%;
  /* background: ${({theme}) => theme.colors.bgGrey}; */

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //border-radius: 16px 16px 0 0;
    /* background: #eeeef1; */
    /* background: ${({theme}) => theme.colors.bodyBg}; */
    background: ${({theme}) => theme.colors.mobileBodyBg};
  }
`;
export const AccountMangopayWrapper = styled.div<{
  status: number;
  isEditing: boolean;
}>`
  position: relative;
  //height: ${({status}) => (status === 0 ? '524px' : '545px')};
  height: 100%;
  padding: 10px 0 0 0;
  flex-basis: 50%;

  & > h4 {
    text-align: center;
    font-size: 13px;
    line-height: 24px;
    font-weight: normal;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    flex-basis: unset;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    /* border: ${({status}) =>
      status === 0 ? 'none' : '1px solid rgba(112, 112, 112, 0.3)'};
    border-color: ${({theme, isEditing}) =>
      isEditing ? theme.colors.blueTheme : 'rgba(112, 112, 112, 0.3)'}; */
    border-radius: 16px;
    padding: 27px 0px 0 0;
    width: 400px;
    /* background: ${({theme, isEditing}) =>
      isEditing ? theme.colors.bgWhite : 'transparent'}; */
    background: transparent;
    & > h4 {
      font-weight: normal;
      font-size: 17px;
    }
  }
  @media (max-width: 400px) {
    width: 358px;
  }
  @media (max-width: 360px) {
    width: 98%;
  }
`;

export const PaymentLogoWrapper = styled.div<{status: number}>`
  width: ${({status}) => (status === 0 ? '100%' : '280px')};
  margin: 0 auto;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-around;
  height: 32px;
  gap: 4px;
  padding-top: ${({status}) => (status === 0 ? '6px' : '2px')};
  padding-bottom: ${({status}) => (status === 0 ? '8px' : '0px')};
  @media (max-width: 1024px) {
    margin-bottom: 34px;
  }
`;

export const LogoContainer = styled.div`
  width: 106px;
  height: 22px;
  border-radius: 3px;
  //background: white;
  background: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  & > svg {
    & > path {
      fill: ${({theme}) => theme.colors.bgBlack};
    }
  }

  /* & > img {
    width: 62px;
    height: 15px;
  }
  @media (max-width: 1024px) {
    & > img {
      width: 115px;
      height: 17px;
    }
  } */
`;

export const MangoPayBasicButton = styled.button<{
  value: string;
  messagesType: string;
}>`
  cursor: ${({value, messagesType}) =>
    value === messagesType ? 'default' : 'pointer'};
  background: ${({value, messagesType, theme}) =>
    value === messagesType ? theme.colors.blueTheme : theme.colors.bgBlackOp15};
  border: none;
  color: ${({value, messagesType, theme}) =>
    value === messagesType
      ? theme.colors.fontBlackOp100
      : theme.colors.fontBlackOp50};
  font-size: 11px;
  letter-spacing: 0.1px;
  font-weight: 400;
  border-radius: 5px;
  padding: 3px 5px;
  flex-basis: 49.9%;
  transition: all 0.15s;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: 1024px) {
    /* margin: 2px; */
    width: 152px;
    height: 20px;
    font-size: 13px;
    line-height: 8px;
    /* background: transparent; */
    background: ${({value, messagesType, theme}) =>
      value === messagesType ? theme.colors.blueTheme : 'transparent'};
    border-radius: 4px;
    border: none;
    color: ${({value, messagesType, theme}) =>
      value === messagesType ? '#fff' : theme.colors.fontBlackOp50};

    /* border: ${({value, messagesType, theme}) =>
      value === messagesType
        ? `1px solid ${theme.colors.fontBlue}`
        : `none`}; */
  }
`;

export const AccountMangoPayFormWrapper = styled.div<{isEditing: boolean}>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px;
  //gap: 2px;

  @media (max-width: 1024px) {
    justify-content: center;
    /* background: ${({isEditing, theme}) =>
      isEditing ? theme.colors.bgWhite : 'transparent'}; */
    background: transparent;
    border-radius: 16px;
    padding: 15px 0px;
    width: 100%;
    gap: 15px;
  }
`;

export const CenterTextForDataInfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & > h4 {
    line-height: 21px;
    font-size: 13px;
    letter-spacing: 0.1px;
    font-weight: 400;
  }
  @media (max-width: 1024px) {
    & > h4 {
      margin-top: 15px;
      font-size: 17px;
      // margin-bottom: 5px;
    }
  }
`;

export const AccountSmallInputWrapper = styled.div<{
  isRedBorder?: boolean;
  isEditActive?: boolean;
}>`
  background: ${({isRedBorder, theme}) =>
    isRedBorder ? theme.colors.statusRed : 'transparent'};
  border: 1px solid rgba(112, 112, 112, 0.3);
  height: 22px;
  border-radius: 5px;
  width: 154px;
  margin-top: ${({isRedBorder}) => (isRedBorder ? `0` : '9px')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    width: 200px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    font-weight: 400;
    margin-left: 8.5px;
    position: relative;
    cursor: default;
    & > svg {
      position: absolute;
      top: 4px;
      right: -53px;
    }
  }

  & > input {
    width: 100%;
    text-align: right;
    font-size: 12px;
    line-height: 18px;
    margin-right: 8.5px;
    letter-spacing: 0.625px;
    font-weight: 400;
    background-color: transparent;
    border: none;
    color: ${({isEditActive, theme}) =>
      isEditActive ? theme.colors.fontBlackOp50 : theme.colors.fontBlackOp100};
    cursor: ${({isEditActive}) => (isEditActive ? `auto` : 'default')};

    &::placeholder {
      color: ${({theme}) => theme.colors.fontBlackOp50};
    }
  }

  & > input:focus {
    outline: none;
  }

  & > select {
    padding-right: 17px;
    font-size: 12px;
    margin-right: 8.5px;
    background-color: transparent;
    opacity: 1 !important;
    border: none;
    background-image: url(${arrowRight});
    background-repeat: no-repeat;
    background-position: 160px 5px;
    text-align: right;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    color: ${({isEditActive}) =>
      isEditActive ? `rgba(7, 2, 15, 0.5)` : '#07020f'};
    cursor: ${({isEditActive}) => (isEditActive ? `pointer` : 'default')};

    & > option {
      background: white;
      color: rgb(137, 191, 255);
      cursor: pointer;
    }
  }

  & > select:focus {
    outline: none;
  }
`;

export const IdScansWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  justify-content: center;
  position: relative;

  & > p {
    flex-basis: 100%;
    text-align: center;
    //font-size: 9px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 12px;
    letter-spacing: 0.25px;
    line-height: 15px;
    padding-bottom: 9px;
  }
`;
export const RelativeDiv = styled.div`
  position: relative;
`;

export const IdScansButtonWrapper = styled.div<{isEditing: boolean}>`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 154px;
  padding: 1px;
  height: 22px;
  border: 1px solid rgba(112, 112, 112, 0.3);
  border-radius: 5px;

  & > button {
    width: 80px;
    border: none;
    color: white;
    cursor: ${({isEditing}) => (isEditing ? 'pointer' : 'default')};
    background: ${({theme}) => theme.colors.blueTheme};
    //font-size: 11px;
    line-height: 19px;
    font-size: 12px;
    border-radius: 5px;
  }

  & > p {
    padding-left: 11px;
    //font-size: 11px;
    line-height: 24px;
    font-size: 12px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
`;

export const AccountBoxWrapper = styled.div`
  width: 100%;
  margin-top: 2px;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
`;

export const AccountBoxText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  & > p {
    //font-size: 11px;
    font-size: 13px;
    line-height: 24px;
  }

  & > a {
    //font-size: 11px;
    font-size: 13px;
    line-height: 16px;
    text-decoration: none;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }

  & > span {
    //font-size: 9px;
    font-size: 12px;
    line-height: 15px;
  }
`;

export const AccountBox = styled.div<{isActive?: boolean}>`
  margin-top: 10px;
  width: 36px;
  cursor: pointer;
  height: 21px;
  border-radius: 25px;
  border: ${({isActive}) =>
    isActive ? `1px solid #3986EF` : `1px solid grey`};
  position: relative;
  transition: all 0.15s;
`;

export const BlueBox = styled.div<{isActive?: boolean}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: absolute;
  transition: all 0.15s ease-in;
  left: ${({isActive}) => (isActive ? `14px` : `4px`)};
  top: 1px;
  background: ${({isActive}) => (isActive ? `#3986EF` : `grey`)};
`;

export const AccountMugoWrapper = styled.div<{status: number}>`
  height: ${({status}) => (status === 0 ? '524px' : '545px')};
  //position: relative;
  flex-basis: 50%;
  padding: 10px 0 0 0;

  & > h4 {
    text-align: center;
    font-size: 13px;
    line-height: 24px;
    font-weight: normal;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    margin-bottom: 200px;
    margin-top: 20px;
    padding-top: 40px;
    flex-basis: unset;
    width: 400px;
    background: ${({theme}) => theme.colors.bgBody};
    height: auto;
    //padding-bottom: 55px;
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: ${({status}) =>
      status === 0 ? 'none' : '1px solid rgba(112, 112, 112, 0.3)'}; */
    & > h4 {
      font-size: 18px;
      font-weight: bold;
      -webkit-letter-spacing: -0.15px;
      -moz-letter-spacing: -0.15px;
      -ms-letter-spacing: -0.15px;
      letter-spacing: -0.15px;
      align-self: flex-start;
      text-align: left;
      padding-left: 36px;
    }
  }
  @media (max-width: 400px) {
    width: 358px;
  }
  @media (max-width: 340px) {
    width: 98%;
  }
`;

export const WinWInWraper = styled.div`
  margin: 6px auto;
  /* width: 82px;
  height: 20px; */
  border: 1px solid #fb9614;
  border-radius: 11px;
  display: flex;
  justify-content: center;
  align-items: center;

  & > p {
    font-size: 9px;
    line-height: 24px;
    color: #fb9614;
  }
  @media (max-width: 1024px) {
    border: none;
    width: 100px;
    margin: 12px 10px 17px 10px;
    & > svg {
      /* width: 26px; */
      /* height: 18px; */
    }
    & > p {
      font-size: 13px;
      font-weight: bold;
    }
  }
`;

export const OrangeWinWInText = styled.p`
  text-align: center;
  font-size: 9px;
  line-height: 15px;
  color: #fb9614;
  @media (max-width: 1024px) {
    font-size: 13px;
    font-weight: bold;
  }
`;

export const MugoText = styled.p`
  padding-top: 10px;
  margin: 0 auto;
  width: 276px;
  text-align: center;
  //font-size: 9px;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.25px;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
`;

export const AccountStatusInfo = styled.div`
  margin: 0 auto;
  display: flex;
  height: 30px;
  flex-direction: column;
  width: 311px;

  & > p {
    text-align: center;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    //font-size: 9px;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.25px;
  }
  & > pre {
    text-align: center;
    //font-size: 9px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.25px;
  }
  @media (max-width: 1024px) {
    & > p {
      font-size: 13px;
    }
    & > pre {
      font-size: 13px;
    }
  }
`;

export const SmallBtnWrapper = styled.div`
  width: 100%;
  gap: 5px;
  display: flex;
  position: absolute;
  left: 11px;
  bottom: 11px;
`;

export const AccountSmallBtn = styled.button`
  width: 158px;
  height: 44px;
  font-size: 15px;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 0.7px;
  border: none;
  color: white;
  border-radius: 15px;
  background: black;
  transition: all 0.15s;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
`;

export const WrongIdMessageWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  width: 80%;
  justify-content: space-between;
  & > p {
    font-size: 11px;
    line-height: 24px;
    color: #ff0300;
  }
`;

export const AccountBankEditAndSaveBtn = styled.button`
  width: 40px;
  border: none;
  color: white;
  cursor: pointer;
  background: #89bfff;
  line-height: 19px;
  border-radius: 5px;
  font-size: 10px;
  position: absolute;
  right: 0px;
  top: 4px;
  &:hover {
    filter: brightness(1.2);
  }
`;

export const AccountHeaderBackButton = styled.button`
  width: 120px;
  height: 100%;
  position: absolute;
  left: 0px;
  border: none;
  border-right: 1px solid white;
  background: transparent;
  border-radius: 9px 0 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;
  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const AccountMangopayCompanyWrapper = styled.div<{
  status?: number;
  isEditing?: boolean;
}>`
  position: relative;
  height: 100%;
  flex-basis: 50%;
  padding-top: 10px;
  //overflow-y: scroll;
  & > h4 {
    text-align: center;
    font-size: 13px;
    line-height: 24px;
    font-weight: normal;
  }

  @media (max-width: 1024px) {
    height: auto;
    flex-basis: unset;
    /* border: 1px solid rgba(112, 112, 112, 0.3); */
    border-radius: 16px;
    padding-top: 27px;
    width: 400px;
    /* background: ${({theme, isEditing}) =>
      isEditing ? theme.colors.bgWhite : 'transparent'}; */
    background: transparent;
  }
  @media (max-width: 400px) {
    width: 358px;
  }
  @media (max-width: 360px) {
    width: 98%;
  }
  //&::-webkit-scrollbar {
  //  width: 7px;
  //  height: 10px;
  //}
  //
  //&::-webkit-scrollbar-corner {
  //  background-color: transparent;
  //}
  //
  //&::-webkit-scrollbar-thumb {
  //  background: #125faf;
  //  border-radius: 25px;
  //  border: 2px solid rgba(0, 0, 0, 0);
  //  background-clip: padding-box;
  //}
  //
  //&::-webkit-scrollbar-track {
  //  background: transparent;
  //  padding: 3px;
  //}
`;

export const AccountInfoText = styled.p`
  font-size: 12px;
  padding: 10px 20px 0px 20px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  margin: 0 auto;
  text-align: center;
`;

export const ScansResponsWrapper = styled.div`
  width: 100%;
  padding-left: 14px;
  display: flex;
  justify-content: center;
  & > p {
    font-size: 12px;
  }
  & > span {
    padding-left: 3px;
    padding-right: 3px;
    font-size: 12px;
  }
`;

export const AccountBigBlueBtnWrapper = styled.div<{
  isNaturalBottomPosition?: boolean;
  isScroll?: boolean;
  isEditing?: boolean;
}>`
  & > button {
    width: 320px;
    height: 44px;
    font-size: 15px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: ${({isScroll}) => (isScroll ? '10px' : '0')};
    font-weight: 400;
    letter-spacing: 0.7px;
    border: none;
    transition: all 0.15s;
    color: white;
    border-radius: 15px;
    background: #3986ef;
    position: ${({isNaturalBottomPosition}) =>
      isNaturalBottomPosition ? 'absolute' : 'unset'};
    bottom: ${({isNaturalBottomPosition}) =>
      isNaturalBottomPosition ? '11px' : 'unset'};
    left: ${({isNaturalBottomPosition}) =>
      isNaturalBottomPosition ? '11px' : 'unset'};
    &:hover {
      filter: brightness(1.1);
    }
    &:active {
      filter: brightness(0.9);
    }
  }
  & > a {
    text-decoration: none;
    color: white;
  }
  @media (max-width: 1024px) {
    visibility: ${({isEditing}) => (isEditing ? 'visible' : 'hidden')};
    height: ${({isEditing}) => (isEditing ? 'auto' : '0px')};
    width: 100%;
    display: flex;
    justify-content: center;
    & > button {
      background: #3986ef;
      width: 367px;
      height: 52px;
      position: unset;
      bottom: unset;
      left: unset;
      font-size: 17px;
      margin: 20px auto;
    }
  }
  @media (max-width: 400px) {
    & > button {
      width: 311px;
    }
  }
  @media (max-width: 340px) {
    & > button {
      width: 90%;
    }
  }
`;

export const SendBtn = styled.button`
  width: 80px;
  margin-top: 10px;
  border: none;
  cursor: pointer;
  color: white;
  background: #89bfff;
  font-size: 11px;
  line-height: 19px;
  border-radius: 5px;
  transition: all 0.15s;
  &:hover {
    filter: brightness(1.2);
  }
`;

export const ScrollWrapper = styled.div<{isImgAdded?: boolean}>`
  height: 495px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: ${({isImgAdded}) => (isImgAdded ? '40px' : '10px')};
  &::-webkit-scrollbar {
    width: 7px;
    height: 10px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #125faf;
    border-radius: 25px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
  }
  @media (max-width: 1024px) {
    height: auto;
    overflow-y: hidden;
    padding-bottom: 0px;
  }
`;

export const FirstTimeTextWrapper = styled.div`
  padding: 10px 8px 10px 8px;
`;

export const FlexSaveBtn = styled.button`
  position: absolute;
  top: -100px;
  right: 0px;
  width: 0px;
  height: 0px;
  border: none;
  border-right: 1px solid white;
  background: transparent;
  border-radius: 0 9px 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  transition: all 0.15s;
  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const PopupWrapper = styled.div`
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    background-color: rgba(112, 112, 112, 0.3);
  }
`;

export const PopupHeader = styled.div`
  background: #89bfff;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  & > h4 {
    font-size: 16px;
    color: black;
    font-weight: normal;
    margin-bottom: 10px;
  }
  //@media (max-width: 1024px) {
  //  display: none;
  //}
`;

export const PopUpContentWrapper = styled.div`
  width: 292px;
  //height: 161px;
  //border: 1px solid rgba(112, 112, 112, 0.3);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  background-color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  //justify-content: center;
  align-items: center;
  & > p {
    margin-top: 10px;
    color: black;
    width: 80%;
    text-align: center;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.25px;
  }
  & > pre {
    margin-top: 10px;
    color: black;
    width: 100%;
    text-align: center;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.25px;
  }
  //@media (max-width: 1024px) {
  //  width: 80%;
  //  & > p {
  //    margin-top: 38px;
  //    margin-bottom: 19px;
  //    font-size: 13px;
  //    letter-spacing: -0.025px;
  //  }
  //}
  //
  //@media (max-width: 400px) {
  //  width: 80%;
  //}
  //@media (max-width: 340px) {
  //  width: 80%;
  //}
`;

export const PopUpButton = styled.button<{isBlue?: boolean}>`
  background-color: ${({isBlue}) => (isBlue ? `#3986ef` : '#3986ef')};
  color: white;
  font-weight: normal;
  cursor: pointer;
  //border: ${({isBlue}) => (isBlue ? `#7270F2 solid 1px` : 'grey solid 1px')};
  border: none;
  border-radius: 9px;
  text-align: center;
  padding: 5px 0px;
  font-size: 13px;
  line-height: 24px;
  width: 149px;
  height: 32px;
  margin: 12px;
  transition: all 0.15s;
  &:hover {
    filter: brightness(1.1);
  }
  &:active {
    filter: brightness(0.9);
  }
  //@media (max-width: 1024px) {
  //  margin: 10px;
  //  height: 44px;
  //  width: 80%;
  //  font-size: 15px;
  //  border-radius: 16px;
  //}
  //@media (max-width: 400px) {
  //  width: 80%;
  //}
  //@media (max-width: 340px) {
  //  width: 80%;
  //}
`;

export const BackButton = styled.button`
  width: 55px;
  height: 100%;
  position: absolute;
  left: 0px;
  border: none;
  border-right: solid 1px white;
  background: #92c5ff;
  cursor: pointer;
  border-radius: 9px 0 0 0;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  //color: #352f40;
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const SaveAndEditButton = styled.button`
  width: 55px;
  height: 100%;
  position: absolute;
  right: 0px;
  border: none;
  border-left: 1px solid white;
  background: #92c5ff;
  border-radius: 0 9px 0 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  //color: #352f40;
  transition: all 0.15s;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const AccountProducerAboutMeWrapper = styled.div<{
  isEditing: boolean;
  type: string;
  isError: boolean;
}>`
  width: 100%;
  height: 129px;
  /* height: ${({type}) => (type === 'name' ? '100px' : '129px')}; */
  margin-top: 6px;
  /* margin-bottom: ${({isEditing}) => (isEditing ? '5px' : '15px')}; */
  border: ${({isEditing, theme, isError}) =>
    isError && isEditing
      ? '1px solid #ff0300'
      : isEditing
      ? `1px solid ${theme.colors.fontBlackOp15}`
      : 'none'};
  /* border-bottom: ${({theme}) =>
    `1px solid ${theme.colors.fontBlackOp15}`}; */
  border-radius: ${({isEditing}) => (isEditing ? '9px' : '0px')};
  background: transparent;
  overflow: hidden;
  position: relative;
  &:focus-within {
    background: ${({isEditing, theme}) =>
      isEditing ? theme.colors.bodyBg : 'transparent'};
    border: ${({isEditing, theme, isError}) =>
      isError && isEditing
        ? '1px solid #ff0300'
        : isEditing
        ? ` 1px solid ${theme.colors.blueTheme}`
        : 'none'};
    border-bottom: ${({isEditing, theme, isError}) =>
      isError && isEditing
        ? '1px solid #ff0300'
        : isEditing
        ? `solid 1px ${theme.colors.fontBlue}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
  }
  & > h4 {
    /* color: rgb(112, 112, 112); */
    color: ${({theme}) => theme.colors.fontBlackOp50};
    //font-size: 11px;
    font-size: 12px;
    line-height: 21px;
    font-weight: normal;
    letter-spacing: 0.625px;
    margin-left: 8.5px;
  }
  & > textarea {
    margin-left: 8.5px;
    margin-right: 8.5px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    //font-size: 11px;
    font-size: 12px;
    line-height: 21px;
    //font-weight: 500;
    border: none;
    outline: none;
    /* height: ${({type}) => (type === 'name' ? '71px' : '100px')}; */
    height: 105px;
    width: 97%;
    background: transparent;
    overflow: hidden;
    resize: none;
  }

  & > p {
    position: absolute;
    right: 9px;
    top: 2px;
    color: ${({theme, isError}) => (isError ? 'red ' : theme.colors.fontBlue)};
    font-size: 12px;
    letter-spacing: 0.1px;
  }

  @media (max-width: 1024px) {
    height: 170px;
    width: 367px;
    margin-top: 0px;
    /* border: 1px solid rgba(112, 112, 112, 0.3); */
    border: ${({isEditing, theme}) =>
      isEditing
        ? `1px solid ${theme.colors.inputMobileBorderColor}`
        : '1px solid transparent'};
    padding: 0px;

    margin-bottom: 10px;
    background: ${({isEditing, theme}) =>
      isEditing ? theme.colors.inputMobileBg : 'transparent'};
    border-radius: ${({isEditing}) => (isEditing ? '5px' : '0px')};

    & > h4 {
      font-size: 13px;
      margin-left: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
      color: ${({theme, isEditing}) =>
        isEditing ? theme.colors.fontBlackOp100 : theme.colors.fontBlackOp50};
    }
    & > textarea {
      font-size: 13px;
      height: 130px;
      margin-left: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
      line-height: 22px;
      font-weight: normal;
      -webkit-opacity: 1;
      color: ${({theme}) => theme.colors.fontBlackOp100}!Important;
      -webkit-text-fill-color: ${({theme}) =>
        theme.colors.fontBlackOp100}!Important;
    }
    & > p {
      top: unset;
      bottom: 4px;
    }
  }
  @media (max-width: 400px) {
    width: 311px;
    /* padding: 10px; */
  }
  @media (max-width: 340px) {
    width: 90%;
    height: 125px;
    /* padding: 10px; */
  }
`;

export const InputView = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
  isSmallInput?: boolean;
  isMediumInput?: boolean;
}>`
  position: relative;
  width: ${({isSmallInput, isEditing, isMediumInput}) =>
    isSmallInput && !isEditing
      ? '155px'
      : isSmallInput
      ? '160px'
      : isMediumInput
      ? '50%'
      : '100%'};
  margin-top: 8px;
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};
  & > input {
    outline: none;
    background: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? theme.colors.statusRed
          : 'transparent'
        : 'transparent'};
    border: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : 'none'};
    border-bottom: ${({isEditing, isError, theme}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : `solid 1px ${theme.colors.fontBlackOp15}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
    border-radius: ${({isEditing, isError}) =>
      isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
    color: ${({isEditing, theme}) =>
      isEditing ? theme.colors.fontBlackOp50 : theme.colors.fontBlackOp100};
    width: 100%;
    height: 22px;
    text-align: right;
    font-size: 12px;
    padding-right: 8.5px;
    padding-left: 110px;

    &:-webkit-autofill {
      -webkit-text-fill-color: ${({theme}) =>
        theme.colors.fontBlackOp50} !important;
    }
  }
  & > input[type='date']::-webkit-calendar-picker-indicator {
    display: ${({isEditing, notEdit}) =>
      isEditing && !notEdit ? `block` : `none`};
  }

  & > input:-webkit-autofill {
    border: 1px solid rgb(225, 225, 225);
    /* transition: background-color 0s ease-in-out 0s; */
  }

  & > input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    color: ${({theme}) => theme.colors.fontBlackOp100} !important;
    -webkit-text-fill-color: ${({theme}) =>
      theme.colors.fontBlackOp100} !important;
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
  }

  & > input:focus {
    background: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit ? theme.colors.bodyBg : 'transparent'};
    border: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit
        ? ` 1px solid ${theme.colors.blueTheme}`
        : notEdit && isEditing
        ? `solid 1px ${theme.colors.fontBlackOp15}`
        : 'none'};
    border-bottom: ${({isEditing, notEdit, theme}) =>
      isEditing && !notEdit
        ? `solid 1px ${theme.colors.blueTheme} `
        : notEdit
        ? `solid 1px ${theme.colors.fontBlackOp15}`
        : `solid 1px ${theme.colors.fontBlackOp15}`};
  }

  & > p {
    position: absolute;
    //color: #07020f;
    color: ${({isEditing, theme}) =>
      !isEditing
        ? `${theme.colors.fontBlackOp50}`
        : `${theme.colors.fontBlackOp100}`};
    //font-size: 11px;
    font-size: 12px;
    line-height: 22px;
    letter-spacing: 0.625px;
    font-weight: 400;
    left: 8.5px;
    top: 1px;
    cursor: default;
  }
  @media (max-width: 1024px) {
    & > input[type='date'] {
      text-align: right !important;
    }
    width: 367px;
    /* height: 24px; */
    height: 28px;
    margin-top: 0px;
    background: ${({isEditing, theme}) =>
      isEditing ? theme.colors.inputMobileBg : 'transparent'};
    border-radius: ${({isEditing}) => (isEditing ? '5px' : '0px')};
    /* border: ${({isEditing, notEdit}) =>
      isEditing && !notEdit
        ? ` 1px solid rgba(112,112,112,0.3)`
        : notEdit && isEditing
        ? 'solid 1px rgba(112,112,112,0.3)'
        : 'solid 1px rgba(112,112,112,0.3)'}; */
    /* border: 1px solid transparent; */
    border: ${({isEditing, theme}) =>
      isEditing ? `1px solid ${theme.colors.inputMobileBorderColor}` : 'none'};
    border-bottom: ${({theme}) =>
      `1px solid ${theme.colors.inputMobileBorderColor}`};
    /* box-shadow: ${({isEditing, theme}) =>
      isEditing ? 'none' : theme.colors.inputBoxShadowColor}; */
    &:focus-within {
      background: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit ? theme.colors.bodyBg : 'transparent'};
      border: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit
          ? ` 1px solid ${theme.colors.blueTheme}`
          : notEdit && isEditing
          ? `solid 1px ${theme.colors.fontBlackOp15}`
          : 'none'};
      border-bottom: ${({isEditing, notEdit, theme}) =>
        isEditing && !notEdit
          ? `solid 1px ${theme.colors.fontBlue}`
          : notEdit
          ? `solid 1px ${theme.colors.fontBlackOp15}`
          : `solid 1px ${theme.colors.fontBlackOp15}`};
    }
    & > input {
      width: 100%;
      height: 100%;
      font-size: 13px;
      line-height: 22px;
      font-weight: 400;
      letter-spacing: 0.625px;
      border: none;
      //opacity: ${({isEditing}) => (isEditing ? '1' : '0.3')};
      color: ${({isEditing, theme}) =>
        isEditing ? theme.colors.fontBlackOp100 : theme.colors.fontBlackOp100};
      padding-left: 0;
      padding-right: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
      border-radius: 5px;
    }

    & > input:focus {
      background: transparent;
      border: none;
      border-bottom: none;
    }
    & > p {
      font-size: 13px;
      /* line-height: 22px; */
      line-height: 24px;
      letter-spacing: 0.625px;
      left: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
    }
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

export const InputViewForData = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
  isSmallInput?: boolean;
  isMediumInput?: boolean;
}>`
  position: relative;
  display: flex;
  background: ${({isEditing, isError}) =>
    isEditing
      ? isEditing && isError
        ? '#FF2320;'
        : 'transparent'
      : 'transparent'};
  justify-content: space-between;
  width: ${({isSmallInput, isMediumInput}) =>
    isSmallInput ? '160px' : isMediumInput ? '50%' : '100%'};
  margin-top: 10px;
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};
  border: ${({isEditing, isError, theme}) =>
    isEditing
      ? isEditing && isError
        ? 'none'
        : `solid 1px ${theme.colors.fontBlackOp15}`
      : 'none'};
  border-bottom: ${({isEditing, isError, theme}) =>
    isEditing
      ? isEditing && isError
        ? 'none'
        : `solid 1px ${theme.colors.fontBlackOp15}`
      : `solid 1px ${theme.colors.fontBlackOp15}`};
  border-radius: ${({isEditing, isError}) =>
    isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
  & > span {
    display: block;

    background: transparent;

    border-radius: ${({isEditing, isError}) =>
      isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
    color: ${({isEditing, theme}) =>
      isEditing
        ? `${theme.colors.fontBlackOp50}`
        : `${theme.colors.fontBlackOp100}`};
    /* width: 100%; */
    line-height: 22px;
    text-align: right;
    font-size: 12px;
    padding-right: 8.5px;
  }
  & > input {
    outline: none;
    background: ${({isEditing, isError}) =>
      isEditing
        ? isEditing && isError
          ? '#FF2320;'
          : 'transparent'
        : 'transparent'};
    border: none;
    border-radius: ${({isEditing, isError}) =>
      isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
    color: ${({isEditing, theme}) =>
      isEditing
        ? `${theme.colors.fontBlackOp50}`
        : `${theme.colors.fontBlackOp100}`};
    /* width: 100%; */
    height: 22px;
    text-align: right;
    font-size: 12px;
    padding-right: 8.5px;
    /* padding-left: 110px; */
    -webkit-appearance: none;
    &:-webkit-autofill {
      -webkit-text-fill-color: rgba(7, 2, 15, 0.5) !important;
    }
  }
  & > input[type='date']::-webkit-calendar-picker-indicator {
    display: ${({isEditing, notEdit}) =>
      isEditing && !notEdit ? `block` : `none`};
  }

  & > input:-webkit-autofill {
    border: 1px solid rgb(225, 225, 225);
  }

  /* & > input:focus {
    background: ${({isEditing, notEdit}) =>
    isEditing && !notEdit ? '#F2F7FF' : 'transparent'};
    border: ${({isEditing, notEdit}) =>
    isEditing && !notEdit
      ? ` 1px solid #0066ff`
      : notEdit && isEditing
      ? 'solid 1px #e1e1e1'
      : 'none'};
    border-bottom: ${({isEditing, notEdit}) =>
    isEditing && !notEdit
      ? ' solid 1px #0066ff '
      : notEdit
      ? 'solid 1px #e1e1e1'
      : ' solid 1px #e1e1e1 '};
  } */
  & > p {
    /* position: absolute; */
    //color: #07020f;
    color: ${({isEditing, theme}) =>
      !isEditing ? theme.colors.fontBlackOp50 : theme.colors.fontBlackOp100};
    //font-size: 11px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.625px;
    font-weight: 400;
    left: 8.5px;
    top: 0px;
    padding-left: 8.5px;
    cursor: default;
  }
  @media (max-width: 1024px) {
    & > input[type='date']::-webkit-calendar-picker-indicator {
    }

    width: 367px;
    /* height: 24px; */
    height: 28px;
    margin-top: 0px;
    background: ${({isEditing, theme}) =>
      isEditing ? theme.colors.inputMobileBg : 'transparent'};
    border-radius: ${({isEditing}) => (isEditing ? '5px' : '0px')};
    border: ${({isEditing, theme}) =>
      isEditing
        ? `1px solid ${theme.colors.inputMobileBorderColor}`
        : '1px solid transparent'};
    border-bottom: ${({theme}) =>
      `1px solid ${theme.colors.inputMobileBorderColor}`};

    & > input {
      /* width: 100%; */
      height: 100%;
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.625px;
      border: none;
      //opacity: ${({isEditing}) => (isEditing ? '1' : '0.3')};
      color: ${({isEditing, theme}) => theme.colors.fontBlackOp100};
      padding-left: 0;
      /* padding-right: ${({isEditing}) => (isEditing ? '10px' : '25px')}; */
      padding-right: 0;
      padding-right: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
      border-radius: 16px;
    }
    & > p {
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.625px;
      padding-left: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
    }
    & > span {
      height: 100%;
      font-size: 13px;
      /* line-height: 20px; */
      line-height: 25px;
      font-weight: 400;
      letter-spacing: 0.625px;
      border: none;
      //opacity: ${({isEditing}) => (isEditing ? '1' : '0.3')};
      color: ${({isEditing, theme}) => theme.colors.fontBlackOp100};
      padding-left: 0;
      /* padding-right: ${({isEditing}) => (isEditing ? '10px' : '25px')}; */
      padding-right: 0;
      padding-right: ${({isEditing}) => (isEditing ? '8.5px' : '3px')};
      border-radius: 16px;
    }
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

export const SelectView = styled.div<{
  isEditing?: boolean;
  isError?: boolean;
  notEdit?: boolean;
}>`
  position: relative;
  width: 100%;
  margin-top: 9px;
  opacity: ${({isEditing, notEdit}) => (notEdit && isEditing ? '0.3' : '1')};
  & > p {
    position: absolute;
    //color: #07020f;
    color: ${({isEditing}) => (!isEditing ? `#707070` : `#07020F`)};
    //font-size: 11px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.625px;
    font-weight: 400;
    left: 8.5px;
    top: 0px;
    cursor: default;
  }
  & > select {
    cursor: pointer;
    outline: none;
    background: ${({isEditing, isError}) =>
      isEditing
        ? isEditing && isError
          ? '#FF7E7D'
          : 'transparent'
        : 'transparent'};
    border: ${({isEditing, isError}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : 'solid 1px #e1e1e1'
        : 'none'};
    border-bottom: ${({isEditing, isError}) =>
      isEditing
        ? isEditing && isError
          ? 'none'
          : ' solid 1px #e1e1e1 '
        : ' solid 1px #e1e1e1 '};
    border-radius: ${({isEditing, isError}) =>
      isEditing ? (isEditing && isError ? '5px' : ' 5px ') : ' 0px'};
    color: ${({isEditing}) => (isEditing ? `#707070` : `#07020F`)};
    width: 100%;
    height: 22px;
    line-height: 20px;
    text-align: right;
    //font-size: 11px;
    font-size: 12px;
    padding-right: ${({isEditing}) => (isEditing ? `14.5px` : `8.5px`)};
    opacity: 1 !important;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    & > option {
      background: #dce0e9;

      color: #707070;
      cursor: pointer;
    }
    & > option:checked {
      color: #352f40;
      background-color: #89bfff;
      border-radius: 7px;
    }
  }
  & > svg {
    display: ${({isEditing}) => (isEditing ? `block` : `none`)};
    position: absolute;
    top: 8px;
    right: 4px;
  }
  & > select:focus {
    background: #f2f7ff;
  }
`;

export const NewStyleCheckBoxWrapper = styled.div<{
  isStatute?: boolean;
  isEdit: boolean;
}>`
  width: 100%;
  position: relative;
  margin-top: ${({isStatute}) => (isStatute ? `5px` : '5px')};
  display: flex;
  justify-content: space-between;
  border-bottom: ${({theme, isEdit}) =>
    isEdit ? 'none' : `solid 1px ${theme.colors.fontBlackOp15}`};

  & > p {
    color: ${({theme}) => theme.colors.fontBlackOp100};
    //font-size: 11px;
    font-size: 12px;
    line-height: 21px;
    font-weight: 500;
    letter-spacing: 0.625px;
    margin-left: 8.5px;
  }
  & > a {
    text-decoration: none;
    cursor: pointer;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    //font-size: 11px;
    font-size: 12px;
    line-height: 21px;
    font-weight: 500;
    letter-spacing: 0.625px;
    margin-left: 8.5px;
  }

  @media (max-width: 1024px) {
    border-bottom: ${({theme, isEdit}) =>
      isEdit ? 'none' : `solid 1px ${theme.colors.inputMobileBorderColor}`};
    width: 367px;
    & > p {
      font-size: 13px;
      line-height: 24px;
      margin-left: 3px;
      font-weight: 400;
      border: none;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
    & > a {
      font-size: 13px;
      line-height: 24px;
      color: ${({theme, isEdit}) =>
        isEdit ? theme.colors.fontBlackOp100 : theme.colors.fontBlackOp50};
      margin-left: ${({isEdit}) => (isEdit ? '8.5px' : '3px')};
      font-weight: normal;
      border: none;
    }
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 90%;
  }
`;

// export const NewEditBtnWrapper = styled.div<{
//   isEditing: boolean;
//   isBasicData: boolean;
// }>`
//   position: absolute;
//   -webkit-user-select: none;
//   -webkit-tap-highlight-color: transparent;
//   right: 10px;
//   top: 8px;
//   width: 46px;
//   overflow: hidden;
//   height: 50px;
//   border-radius: 16px;
//   border: ${({theme}) => `1px solid ${theme.colors.borderOutsideForEditBtn}`};
//   background: ${({isEditing, theme}) =>
//     isEditing ? theme.colors.fontBlue : theme.colors.bgForEditBtn};
//   transition: 0.2s;
//   transform: scale(0.9);

//   @media (max-width: 1024px) {
//     right: ${({isBasicData}) => (isBasicData ? '10px' : '10px')};
//     top: ${({isBasicData}) => (isBasicData ? '10px' : '10px')};
//   }
//   @media (max-width: 400px) {
//     top: 5px;
//     right: 5px;
//   }
//   @media (max-width: 340px) {
//     right: 5px;
//     top: 5px;
//   }
// `;

// export const EditSvgWrapper = styled.div<{isEditing: boolean}>`
//   -webkit-user-select: none;
//   -webkit-tap-highlight-color: transparent;
//   width: 44px;
//   height: 38px;
//   overflow: hidden;
//   margin-top: ${({isEditing}) => (isEditing ? '9px' : '0px')};
//   border-radius: 15px;
//   border: ${({theme}) => `1px solid ${theme.colors.borderInsideForEditBtn}`};
//   background: ${({theme}) => theme.colors.bgWhite};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   transition: margin-top 0.2s;
//   & > svg {
//     & > g {
//       & > path {
//         stroke: ${({theme, isEditing}) =>
//           isEditing ? theme.colors.fontBlue : theme.colors.fontBlackOp100};
//       }
//     }
//   }
// `;

export const HoverTextForEdit = styled.div`
  width: 90px;
  height: 35px;
  border-radius: 9px;
  border: ${({theme}) => `solid 1px ${theme.colors.bgBlack}`};
  background: ${({theme}) => theme.colors.bgWhite};
  position: absolute;
  right: -70px;
  top: -26px;
  text-align: center;
  opacity: ${({theme}) => (theme.name === 'light-theme' ? 1 : 0.8)};
  & > p {
    font-size: 13px;
    line-height: 31px;
    font-weight: bold;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    display: none;
    right: 60px;
    top: -15px;
  }
`;

export const PlanAndSubWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 5px 0px 0px 0px;
  border: solid 1px rgba(112, 112, 112, 0.15);
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
  border-bottom: 0px;
  @media (max-width: 1024px) {
    width: 367px;
    border: none;
    margin-top: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  @media (max-width: 400px) {
    width: 311px;
  }
  @media (max-width: 340px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const MangopayStatusWrapper = styled.div`
  margin: 0 auto;
  width: 112px;
  height: 24px;
  border-radius: 16px;
  margin-top: 18px;
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  & > p {
    color: black;
    font-weight: bold;
    font-size: 11px;
  }
`;

export const MangopayHeaderForm = styled.div`
  & > h4 {
    text-align: center;
    font-size: 13px;
    line-height: 24px;
    font-weight: normal;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
  @media (max-width: 1024px) {
    padding: 0px 0px 10px 0px;
    background: transparent;
    margin-bottom: 15px;
    width: 100%;
    & > h4 {
      padding-bottom: 24px;
      font-size: 18px;

      padding-left: 24px;
      text-align: left;
      align-self: flex-start;
      margin-left: 12px;

      font-weight: bold;
      -webkit-letter-spacing: -0.15px;
      -moz-letter-spacing: -0.15px;
      -ms-letter-spacing: -0.15px;
      letter-spacing: -0.15px;
    }
  }
`;

export const DescriptionForInput = styled.p<{isEditing: boolean}>`
  font-size: 13px;
  line-height: 24px;
  width: 100%;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  text-align: center;
  font-weight: ${({isEditing}) => (isEditing ? 'normal' : 'normal')};
  padding-left: 5px;
  margin-top: ${({isEditing}) => (isEditing ? '5px' : '12px')};
  @media (max-width: 1024px) {
    margin-top: 15px;
    font-size: 17px;
    margin-bottom: 5px;
  }
`;

export const TwoSmallInputsWrapper = styled.div`
  display: flex;
`;
