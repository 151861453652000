import {createGlobalStyle} from 'styled-components';
import background from 'images/background.jpeg';
import * as theme from './constants/theme';

const GlobalStyle = createGlobalStyle<{isMenuOpen: boolean; bodyBg: string}>`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    overflow-wrap: break-word;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: white;
  background-color: red;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255,255,255,0) inset;
  transition: background-color 5000s ease-in-out 0s;
}

  .newBlock {
    animation-name: newBlockAnimation;
    animation-duration: 3s;
    animation-iteration-count: 1;
  }

  @keyframes newBlockAnimation {
    0% {opacity: 0.01};
    100% {opacity: 1}
  }

  body {
    overflow-y: ${({isMenuOpen}) => (isMenuOpen ? 'hidden' : 'auto')};
    /* background-image: url(${background}); */
    /* background: #DCE0E9; */
    background: ${({bodyBg}) => bodyBg};
    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #3A87F0;
      border-radius: 5px;
      border: 2px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-track {
      background: black;
      padding: 3px;
    }
  }

`;

export default GlobalStyle;
