import styled, {keyframes} from 'styled-components';

// Definiujemy animację wysuwania od dołu
const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;
export const AudioPlayerWrapper = styled.div<{isVisible: boolean}>`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${({theme}) => theme.colors.bgWhite};
  z-index: 99;

  /* Ustawienia animacji wysuwania i chowania */
  transform: ${({isVisible}) =>
    isVisible ? 'translateY(0)' : 'translateY(100%)'};
  transition: transform 0.4s ease-out;

  /* Widoczność i przezroczystość dla płynnego chowania */
  visibility: ${({isVisible}) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};

  /* Poprawka: czas przejść różni się dla opacity i visibility, aby visibility nie blokowało animacji */
  transition: transform 0.4s ease-out, opacity 0.5s ease-out,
    visibility 0s linear ${({isVisible}) => (isVisible ? '0s' : '0.4s')}; /* Visibility od razu dla pojawiania */

  @media (max-width: 1024px) {
    bottom: 57px;
  }
`;

// export const AudioPlayerWrapper = styled.div<{isOpen: boolean}>`
//   position: fixed;
//   left: 0;
//   bottom: 0;
//   width: 100%;
//   background-color: ${({theme}) => theme.colors.bgWhite};
//   z-index: 99;
//   animation: ${({isOpen}) => (!isOpen ? slideUp : slideDown)} 0.5s ease-out;
//   animation-fill-mode: forwards;

//   @media (max-width: 1024px) {
//     bottom: 57px;
//   }
// `;

export const ContentWrapper = styled.div<{
  isLight: boolean;
}>`
  display: flex;
  box-shadow: ${({isLight}) =>
    isLight
      ? '0 0 4px 0 rgba(0, 0, 0, 0.4)'
      : '0 0 4px 0 rgba(255, 255, 255, 0.4)'};

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const LeftContentWithImgWrapper = styled.div`
  padding-left: 20px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: flex-end;
  padding-bottom: 20px;
  & > img {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 1024px) {
    padding-left: 10px;
    padding-top: 10px;
    justify-content: left;
  }

  & > img {
    width: 46px;
    height: 46px;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  width: 200px;
  overflow: hidden;

  & > h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: bold;
    -webkit-letter-spacing: -0.15px;
    -moz-letter-spacing: -0.15px;
    -ms-letter-spacing: -0.15px;
    letter-spacing: -0.15px;
    line-height: 21px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
  }
  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
    -webkit-letter-spacing: -0.05px;
    -moz-letter-spacing: -0.05px;
    -ms-letter-spacing: -0.05px;
    letter-spacing: -0.05px;
    line-height: 15px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    & > span {
      font-size: 11px;
      -webkit-letter-spacing: -0.05px;
      -moz-letter-spacing: -0.05px;
      -ms-letter-spacing: -0.05px;
      letter-spacing: -0.05px;
      line-height: 15px;
      font-weight: 400;
      color: ${({theme}) => theme.colors.fontBlackOp100};
    }
  }
  @media (max-width: 1024px) {
    & > h3 {
      font-size: 16px;
      line-height: 18px;
    }
    & > p {
      line-height: 13px;
    }
    & > span {
      line-height: 13px;
    }
  }
`;

export const LeftBackBtn = styled.button`
  position: absolute;
  left: 56vw;
  bottom: 17%;
  background: transparent;
  width: 20px;
  height: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  & > svg {
    width: 20px;
    height: 20px;
  }
`;

export const RightNextBtn = styled.button`
  position: absolute;
  left: 62vw;
  bottom: 17%;
  background: transparent;
  width: 20px;
  height: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  & > svg {
    width: 20px;
    height: 20px;
  }
`;

export const CloseBtn = styled.button`
  background: transparent;
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 5px;
  top: 5px;
  & > svg {
    width: 25px;
    height: 25px;
    & > path {
      fill: ${({theme}) => theme.colors.fontBlackOp70};
    }
  }

  @media (max-width: 1024px) {
    position: absolute;
    right: 5px;
    left: none;
    top: 5px;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const CloseBtnMobile = styled.button`
  background: transparent;
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  right: 5px;
  top: 5px;
  & > svg {
    width: 25px;
    height: 25px;
    & > path {
      fill: ${({theme}) => theme.colors.fontBlackOp70};
    }
  }

  @media (max-width: 1024px) {
    position: absolute;
    right: 5px;
    left: none;
    top: 5px;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
`;
