import styled from 'styled-components';

export const GalleryBlockWrapper = styled.div<{
  backgroundAlpha: number;
  backgroundColor: string;
}>`
  width: 100%;
  height: 100%;
  /* padding: 16px; */
  position: relative;
  background: ${({backgroundAlpha, backgroundColor}) =>
    `rgba(${backgroundColor},${backgroundAlpha})`};

  overflow: hidden;
`;

export const SvgWrapper = styled.div<{Width: number; isEditing: boolean}>`
  position: absolute;
  bottom: 5px;
  //right: 25px;
  right: 5px;
  display: flex;
  flex-direction: ${({isEditing}) => (isEditing ? 'column' : 'row')};
  gap: 5px;
`;

export const SvgBg = styled.div`
  cursor: pointer;
  width: 34px;
  height: 26px;
  border-radius: 7px;
  background: rgb(241, 241, 242);
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    cursor: pointer;
  }
`;

export const GalleryBlockHeader = styled.div<{theme?: string}>`
  height: 10%;
  width: 100%;
  display: flex;
  gap: 11px;
  align-items: center;

  & > h4 {
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
    font-size: 18px;
    font-weight: 600;
    line-height: 36px;
    padding: 0 5px;
  }

  & > svg {
    width: 27.38px;
    height: 21px;
    & > path {
      fill: ${({theme}) =>
        theme === '000000'
          ? 'black'
          : theme === '0C426A'
          ? '#0C426A'
          : 'white'};
    }
  }

  @media (max-width: 1065px) {
    height: 5%;
  }
  @media (max-width: 920px) {
    & > h4 {
      font-size: 16px;
    }
    & > svg {
      height: 16px;
    }
  }
  @media (max-width: 780px) {
    & > h4 {
      font-size: 14px;
    }
    & > svg {
      height: 14px;
    }
  }
  @media (max-width: 720px) {
    & > h4 {
      font-size: 12px;
    }
    & > svg {
      height: 12px;
    }
  }
  @media (max-width: 650px) {
    & > h4 {
      padding: 0;
      font-size: 11px;
    }
    & > svg {
      height: 11px;
    }
  }
  @media (max-width: 580px) {
    & > h4 {
      font-size: 10px;
    }
    & > svg {
      height: 10px;
    }
  }
  @media (max-width: 550px) {
    & > h4 {
      font-size: 8px;
    }
    & > svg {
      height: 8px;
    }
  }
  @media (max-width: 430px) {
    & > h4 {
      font-size: 6px;
    }
    & > svg {
      height: 6px;
    }
  }
`;

export const GalleryBlockNavigation = styled.div<{theme?: string}>`
  display: flex;
  gap: 6px;
  align-items: center;
  margin-left: 30px;

  & > svg {
    height: 16px;
    width: 16px;
    cursor: pointer;

    & > g {
      & > g {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};

        & > path {
          fill: ${({theme}) =>
            theme === '000000'
              ? 'black'
              : theme === '0C426A'
              ? '#0C426A'
              : 'white'};
        }
      }
    }
  }

  & > p {
    font-size: 11px;
    color: ${({theme}) =>
      theme === '000000' ? 'black' : theme === '0C426A' ? '#0C426A' : 'white'};
  }
  @media (max-width: 920px) {
    & > svg {
      width: 15px;
      height: 15px;
    }
    & > p {
      font-size: 10px;
    }
  }
  @media (max-width: 830px) {
    gap: 3px;
    & > svg {
      width: 14px;
      height: 14px;
    }
    & > p {
      font-size: 9px;
    }
  }
  @media (max-width: 780px) {
    gap: 2px;
    & > svg {
      width: 12px;
      height: 12px;
    }
    & > p {
      font-size: 8px;
    }
  }
  @media (max-width: 650px) {
    gap: 1px;
    & > svg {
      width: 10px;
      height: 10px;
    }
    & > p {
      font-size: 7px;
    }
  }
  @media (max-width: 580px) {
    gap: 1px;
    & > svg {
      width: 8px;
      height: 8px;
    }
    & > p {
      font-size: 6px;
    }
  }
  @media (max-width: 450px) {
    gap: 1px;
    & > svg {
      width: 5px;
      height: 5px;
    }
    & > p {
      font-size: 4px;
    }
  }
`;

export const GalleryBlockContent = styled.div<{
  mobileOverview: boolean;
  Height: number;
  Width: number;
}>`
  width: 100%;
  height: 90%;
  display: grid;
  justify-content: center;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-template-rows: repeat(auto-fill, 272px);

  @media (max-width: 1065px) {
    gap: 10px;
    height: 95%;
    grid-template-columns: ${({Width, mobileOverview}) =>
      !mobileOverview ? `repeat(${Width}, 1fr)` : 'repeat(auto-fill, 287px)'};
    grid-template-rows: ${({Height, mobileOverview}) =>
      !mobileOverview ? `repeat(${Height}, 1fr)` : 'repeat(auto-fill, 258px)'};
  }
`;
export const GalleryImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  & > img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 1060px) {
    & > img {
      height: 90%;
    }
  }
  @media (max-width: 1000px) {
    & > img {
      height: 80%;
    }
  }
  @media (max-width: 700px) {
    & > img {
      height: 70%;
    }
  }
  @media (max-width: 400px) {
    & > img {
      height: 60%;
    }
  }
`;

export const DeleteButton = styled.button<{fontColor: string}>`
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 18px;
  background-color: transparent;
  padding: 5px;
  border: none;
  color: ${({fontColor}) => `rgb(${fontColor})`};
  cursor: pointer;
`;

export const GalerryAddButonWrapper = styled.div<{theme: string}>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 48px;
    height: 48px;
    cursor: pointer;
    & > g {
      & > path {
        stroke: ${({theme}) =>
          theme === '000000'
            ? 'black'
            : theme === '0C426A'
            ? '#0C426A'
            : 'white'};
      }
    }
  }
`;

export const GalleryNextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
