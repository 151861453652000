import React, {useContext, useEffect, useState} from 'react';
import {hexToRgb} from '../../index';
import {
  ElementToAdd,
  RecomendedTrackContentWrapper,
  RecomendedTrackMoreContentWrapper,
  RecomendedTracksBlockContent,
  RecomendedTracksBlockHeader,
  RecomendedTracksBlockWrapper,
  RecomendedTracksWrapper,
  SliderWrapper,
} from './styles';
import {Block} from '../../interface';
import {RecomendedTrackOneComponent} from './RecomendedTrackOne';
import {
  ButtonBg,
  ButtonLeftAndRightWrapper,
  RecomendedAddButonWrapper,
} from '../RecomendedProductionBlock/styles';
import api from '../../../../../services/axiosConfig';
import {useCookies} from 'react-cookie';
import {Offer} from '../RecomendedProductionBlock';
import {ProducerDataContext} from '../../../../../contexts/ProducerDataContext';
import {RecomendedProductionListView} from '../RecomendedProductionBlock/RecomendedProductionListView';
import {RecomendedTrackEmptyOneComponent} from './RecomendedTrackEmptyOne';
import {RecomendedTrackAddOneComponent} from './RecomendedTrackAddNewOne';
import {VisitedProducerContext} from '../../../../../contexts/VisitedProducerContext';
import {EditHoverContext} from '../../../../../contexts/EditHoverContext';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface Props {
  theme: string;
  block: Block;
  mobileOverview: boolean;
  isEditing: boolean;
  updateTracksBlock: ({offerIds, offers}) => void;
}

const arr = [1, 2, 3, 4];

const returnDiffrentElementFromTwoArr = (arr: Offer[], all: Offer[]) => {
  let newArr = [...all];
  all.forEach((item) => {
    if (arr.filter((id) => id.Id === item.Id).length > 0) {
      return (newArr = newArr.filter((newItem) => newItem.Id !== item.Id));
    }
  });
  return newArr;
};

const RecomendedTracksBlockComponent: React.FC<Props> = ({
  theme,
  block,
  mobileOverview,
  isEditing,
  updateTracksBlock,
}) => {
  const {BackgroundColorHex, WebArea, Offers, BlockOpacityIn0To100Ratio} =
    block;
  const {Width, Height} = WebArea;

  const initTracks = Offers ? Offers : [];
  const [isAdding, setIsAdding] = useState(false);
  const [tracks, setTracks] = useState<Offer[]>(initTracks);
  const [tracksList, setTracksList] = useState<Offer[]>([]);
  const [selectedTrack, setSelectTrack] = useState<Offer>({Id: ''});

  const [trackIndex, setTrackIndex] = useState(0);
  const [useSlideInAnimation, setUseSlideInAnimation] = useState(false);
  const [deleteAniamtion, setDeleteAnimation] = useState(false);
  const [goRight, setGoRight] = useState(false);

  const [cookies, setCookie] = useCookies(['token']);

  const {decodedToken} = useContext(ProducerDataContext);
  const [visitedProducer, setVisitedProducer] = useContext(
    VisitedProducerContext,
  );
  const [blockHover, setBlockHover] = useContext(EditHoverContext);

  const [currentSlide, setCurrentSlide] = useState(0);

  const changeSelectedTrack = (track) => setSelectTrack(track);

  const dowloadDataToAdd = async () => {
    try {
      const response = await api.get(`Offer`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });

      const allTracks = response.data.Response.filter(
        (item) => item.ProducerId === decodedToken.ProducerId,
      )
        .filter((item) => item.ProductType === 8)
        .map((item) => ({
          Id: item.Id,
          Name: item.Name,
          Category: item.Category,
          IsWinWin: item.IsWinWin,
          Description: item.Description,
          GrossValue: item.GrossValue,
          Currency: item.Currency,
          CoverFile: item.CoverFile,
          ProductDemoFile: item.ProductDemoFile,
          SecondCategoryId: item.SecondCategoryId,
          ThirdCategoryId: item.ThirdCategoryId,
        }));
      setTracksList(returnDiffrentElementFromTwoArr(tracks, allTracks));
      setIsAdding(true);
    } catch (error) {
      console.error(error);
    }
  };
  const addItemToRecomended = (offer: Offer) => {
    console.log('offer co dodaje', offer);
    setTracks((prev) => [...prev, offer]);
    const dataToUpdate = [...tracks, offer].map((item) => item.Id);
    updateTracksBlock({offers: [...tracks, offer], offerIds: dataToUpdate});
    setIsAdding(false);
  };

  const deleteItemFromRecomended = (id: string) => {
    if (id === '') return console.log('wybierz id');
    setTracks((prev) => prev.filter((item) => item.Id !== id));
    const dataToUpdate = [...block.Offers].filter((item) => item.Id !== id);
    updateTracksBlock({
      offers: dataToUpdate,
      offerIds: dataToUpdate.map((item) => item.Id),
    });

    setSelectTrack({Id: ''});
  };

  const handleAnimaton = (key: string) => {
    console.log('pokaz animacje', key);
    setUseSlideInAnimation((prevState) => !prevState);
    setDeleteAnimation(false);
    if (key === 'right') {
      setGoRight(true);
    } else {
      setGoRight(false);
    }
  };

  const nextProduct = () => {
    console.log('next prde retuirn');
    if (tracks.length <= 5 && !isEditing) return;
    if (trackIndex === tracks.length - 1) return;
    console.log('next');
    handleAnimaton('right');
    setTrackIndex((prevState) => prevState + 1);
  };

  const prevProduct = () => {
    console.log(' prev przed return');
    if (tracks.length <= 5 && !isEditing) return;
    if (trackIndex === 0) return;
    console.log('prev');
    handleAnimaton('left');
    setTrackIndex((prevState) => prevState - 1);
  };

  const closeList = () => setIsAdding(false);

  const hoverTextOn = (text: string) => {
    setBlockHover((prev) => ({Active: true, Text: text}));
  };

  const hoverTextOff = () => {
    setBlockHover((prev) => ({Active: false, Text: ''}));
  };

  const BackgroundColorRgb = hexToRgb(BackgroundColorHex);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 5000,
    beforeChange: (current, next) => setCurrentSlide(next),
  };

  function divideArr(arr, chunkSize: number) {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }

  function howMuchDots(number, chunkSize: number) {
    return Math.ceil(number / chunkSize);
  }

  return (
    <RecomendedTracksBlockWrapper
      isMobile={mobileOverview}
      height={Height}
      backgroundAlpha={BlockOpacityIn0To100Ratio}
      backgroundColor={BackgroundColorRgb}
    >
      {visitedProducer.CardType === 0 && !isEditing ? null : (
        <ButtonLeftAndRightWrapper
          style={
            visitedProducer.CardType === 1
              ? {flexDirection: 'row', gap: '5px', right: '5px', bottom: '5px'}
              : null
          }
          Width={Width}
        >
          {isEditing ? (
            <ButtonBg
              onMouseEnter={() => hoverTextOn('Usuń track')}
              onMouseLeave={hoverTextOff}
              onClick={() => deleteItemFromRecomended(selectedTrack.Id)}
              className="DeleteTrackSvg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.3"
                height="13.5"
                viewBox="0 0 12.3 13.5"
              >
                <g
                  id="Icon_feather-trash"
                  data-name="Icon feather-trash"
                  transform="translate(0.75 0.75)"
                >
                  <path
                    id="Path_3663"
                    data-name="Path 3663"
                    d="M4.5,9H15.3"
                    transform="translate(-4.5 -6.6)"
                    fill="none"
                    // stroke={`rgb(${hexToRgb(BackgroundColorHex)})`}
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                  <path
                    id="Path_3664"
                    data-name="Path 3664"
                    d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                    transform="translate(-6.3 -3)"
                    fill="none"
                    // stroke={`rgb(${hexToRgb(BackgroundColorHex)})`}
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  />
                </g>
              </svg>
            </ButtonBg>
          ) : null}
        </ButtonLeftAndRightWrapper>
      )}
      {Height > 1 ? (
        <SliderWrapper isMobile={mobileOverview} blockHeight={Height}>
          <Slider {...settings}>
            {[...new Array(howMuchDots(block.Offers.length, 3))].map(
              (array, index) => {
                return (
                  <RecomendedTrackContentWrapper
                    isMobile={mobileOverview}
                    key={index}
                  >
                    {divideArr(block.Offers, 3)[index].map((track, index) => {
                      return (
                        <RecomendedTrackOneComponent
                          isSelected={track.Id === selectedTrack.Id}
                          key={track.Id}
                          isEditing={isEditing}
                          block={block}
                          theme={theme}
                          mobileOverview={mobileOverview}
                          offer={track}
                          changeSelectedTrack={changeSelectedTrack}
                          isMainBlock={true}
                        />
                      );
                    })}
                  </RecomendedTrackContentWrapper>
                );
              },
            )}
          </Slider>
        </SliderWrapper>
      ) : (
        <SliderWrapper isMobile={mobileOverview} blockHeight={Height}>
          <Slider {...settings}>
            {[...new Array(howMuchDots(tracks.length, 9))].map(
              (array, index) => {
                return (
                  <RecomendedTrackMoreContentWrapper key={index}>
                    {divideArr(tracks, 9)[index].map((track, index) => {
                      return (
                        <RecomendedTrackOneComponent
                          isSelected={track.Id === selectedTrack.Id}
                          key={track.Id}
                          isEditing={isEditing}
                          block={block}
                          theme={theme}
                          mobileOverview={mobileOverview}
                          offer={track}
                          isMainBlock={true}
                          changeSelectedTrack={changeSelectedTrack}
                        />
                      );
                    })}
                  </RecomendedTrackMoreContentWrapper>
                );
              },
            )}
          </Slider>
        </SliderWrapper>
      )}
    </RecomendedTracksBlockWrapper>
  );
};
export default RecomendedTracksBlockComponent;
