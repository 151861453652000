import styled from 'styled-components';
import arrowRight from '../../../images/svg/select-arrow-right.svg';

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Main = styled.div`
  width: 1020px;
  /* min-height: 795px; */
  height: 82vh;
  display: flex;
  flex-direction: column;
  /* border: 1px solid ${({theme}) => theme.colors.headerBorderColor}; */
  align-items: center;
  /* background: ${({theme}) => theme.colors.headerContentBackground}; */
  position: absolute;
  top: 88px;
  /* border-radius: 24px 24px 21px 21px;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.12); */

  & > p {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.92);
    line-height: 24px;
    margin-bottom: 10px;
  }

  @media (max-width: 1100px) {
    width: 750px;
  }
  @media (max-width: 750px) {
    width: 600px;
  }

  @media (max-width: 600px) {
    width: 360px;
    height: unset;
    margin-bottom: 20px;
  }
`;

export const Header = styled.div`
  width: 100%;
  /* height: 55px; */
  border-radius: 24px 24px 24px 24px;
  /* background: ${({theme}) => theme.colors.pageHeader}; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  box-shadow: ${({theme}) => theme.colors.mainBoxShadow};

  & > p {
    color: ${({theme}) => theme.colors.headerBlackFont};
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 0.625px;
  }
  & > header {
    width: 100%;
    height: 55px;
    border-radius: 24px 24px 0 0;
    background: ${({theme}) => theme.colors.pageHeader};
    display: flex;
    align-items: center;
    justify-content: center;
    & > p {
      color: ${({theme}) => theme.colors.headerBlackFont};
      font-weight: 400;
      font-size: 18px;
      letter-spacing: 0.625px;
    }
  }
`;

export const HeaderIconsWrapper = styled.div`
  width: 100%;
  height: 38px;
  background-color: ${({theme}) => theme.colors.bgWhite};
  border-radius: 0 0 24px 24px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 16px;
  padding-right: 25px;
`;

export const Grid = styled.div`
  margin-top: 11px;
  width: 100%;
  height: calc(100% - 11px - 55px);
  border-radius: 9px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    height: auto;
    flex-direction: column;
  }
`;

export const LeftSectionDescriptions = styled.div`
  width: 334px;

  height: 705px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  border-radius: 24px;
  background: ${({theme}) => theme.colors.bodyBg};
`;

export const CenterSection = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.21);
  border-right: 1px solid rgba(255, 255, 255, 0.21);
  @media (max-width: 750px) {
    width: 200px;
  }

  @media (max-width: 600px) {
    width: 100%;
    border-left: none;
    border-right: none;
    border-top: 1px solid rgba(255, 255, 255, 0.21);
    border-bottom: 1px solid rgba(255, 255, 255, 0.21);
    padding: 8px 14px;
  }
`;

export const RightSection = styled.div`
  width: 212px;
  height: 705px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${({theme}) => theme.colors.headerBorderColor};
  border-radius: 24px;
  background: ${({theme}) => theme.colors.bodyBg};
  align-items: center;
  box-shadow: ${({theme}) => theme.colors.mainBoxShadow};

  & > p {
    font-size: 11px;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.5);
  }
  @media (max-width: 1100px) {
    & > p {
      font-size: 8px;
      line-height: 14px;
    }
  }
  @media (max-width: 750px) {
    width: 200px;
  }
  @media (max-width: 600px) {
    width: 100%;
    & > p {
      font-size: 11px;
      line-height: 21px;
    }
  }
`;

export const RightSectionHeader = styled.div<{
  planId?: string;
  isLeft?: boolean;
  isActive?: boolean;
}>`
  width: 100%;
  height: 55px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: ${({isLeft, theme}) =>
    isLeft ? 'none' : `1px solid ${theme.colors.headerBorderColor}`};

  & > h4 {
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -0.15px;
    color: ${({theme, planId, isActive}) =>
      isActive
        ? theme.colors.statusGreen
        : planId === '0b9da0bf-cd5d-11ed-9742-000d3a44fa94'
        ? theme.colors.fontBlackOp50
        : theme.colors.fontBlackOp100};
  }
`;

export const OneTextWrapper = styled.div<{
  bgColorType?: string;
  isOpacity50?: boolean;
}>`
  height: 19px;
  min-height: 19px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({bgColorType, theme}) =>
    bgColorType === 'grey100'
      ? theme.colors.proTextBgGrey100
      : bgColorType === 'grey50'
      ? theme.colors.proTextBgGrey50
      : 'transparent'};
  & > p {
    font-size: 13px;
    letter-spacing: 0.05px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    opacity: ${({isOpacity50}) => (isOpacity50 ? 0.5 : 1)};
    & > span {
      color: #fb9614;
    }
  }
`;

export const PlanPriceWrapper = styled.div<{isStartPlan: boolean}>`
  position: relative;
  width: 100%;
  height: 32px;
  margin-top: 11px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  & > p {
    font-size: 24px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: ${({theme, isStartPlan}) =>
      isStartPlan ? theme.colors.fontBlackOp50 : theme.colors.fontBlackOp100};
    & > span {
      color: ${({theme}) => theme.colors.fontBlackOp50};
      font-size: 13px;
    }
  }
`;

export const PromotionBlueTextWrapper = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  & > p {
    font-size: 13px;
    letter-spacing: 0.05px;
    color: ${({theme}) => theme.colors.fontBlue};
    font-weight: bold;
  }
  & > span {
    font-size: 13px;
    letter-spacing: 0.05px;
    color: ${({theme}) => theme.colors.fontBlue};
  }
`;

export const PlanPriceWithPromotion = styled.div`
  position: relative;
  width: 100%;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
  & > p {
    font-size: 36px;
    line-height: 18px;
    letter-spacing: -0.25px;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    & > span {
      color: ${({theme}) => theme.colors.fontBlackOp50};
      font-size: 18px;
    }
  }
`;

export const RedLine = styled.div`
  position: absolute;
  /* left: 80px; */
  bottom: 13px;
  /* transform: rotate(-33deg); */
  width: 150px;
  height: 2px;
  background: ${({theme}) => theme.colors.statusRed};
`;

export const PlanData = styled.div`
  line-height: 18px;
  font-size: 13px;
  letter-spacing: 0.05px;
  color: ${({theme}) => theme.colors.fontBlackOp50};
  text-align: center;
`;

export const TitlePro = styled.div<{isActive: boolean}>`
  text-align: center;
  margin-bottom: 20px;

  & > h4 {
    margin-bottom: 5px;
    color: ${({isActive, theme}) =>
      isActive ? theme.colors.fontBlackOp100 : '#3986EF'};
  }

  & > p {
    font-size: 11px;
    color: ${({isActive}) => (isActive ? '#19D627' : '#FB9614')};
  }
`;

export const ListPro = styled.ul<{isActive: boolean}>`
  margin-left: 20px;
  & > li {
    font-size: 11px;
    line-height: 21px;
    color: ${({isActive, theme}) =>
      isActive ? theme.colors.grayFontOp100 : theme.colors.fontBlackOp100};
  }
  @media (max-width: 1100px) {
    & > li {
      font-size: 10px;
      line-height: 16px;
    }
  }
  @media (max-width: 600px) {
    & > li {
      font-size: 11px;
      line-height: 21px;
    }
  }
`;

export const AdditionalInformation = styled.div`
  /* position: absolute;
  left: 0;
  bottom: 50px; */

  & > p {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 20px;
    color: ${({theme}) => theme.colors.grayFontOp100};
    font-size: 9px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.5px;
  }

  @media (max-width: 1100px) {
    width: 100%;

    & > p {
      font-size: 8px;
      line-height: 16px;
    }
  }

  @media (max-width: 600px) {
    width: 100%;

    & > p {
      font-size: 10px;
      line-height: 16px;
    }
  }
`;

export const InputWrapper = styled.div<{isRedBorder?: boolean}>`
  background: transparent;
  border: ${({isRedBorder}) =>
    isRedBorder ? `solid #D62220 1px` : '1px solid rgba(112,112,112,0.3)'};
  height: 22px;
  border-radius: 5px;
  width: 92%;
  margin: 0 auto;
  margin-top: ${({isRedBorder}) => (isRedBorder ? `0` : '10px')};
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    color: #07020f;
    font-size: 11px;
    line-height: 18px;
    margin-left: 8.5px;
  }

  & > input {
    text-align: right;
    font-size: 11px;
    line-height: 18px;
    margin-right: 8.5px;
    color: rgba(7, 2, 15, 0.5);
    background: transparent;
    border: none;

    &::placeholder {
      color: rgba(7, 2, 15, 0.5);
    }
  }

  & > input:focus {
    outline: none;
  }

  @media (max-width: 1100px) {
    height: 20px;

    & > p {
      font-size: 9px;
    }

    & > input {
      font-size: 9px;
    }
  }

  @media (max-width: 750px) {
    height: 18px;

    & > p {
      font-size: 8px;
      margin-left: 3.5px;
    }

    & > input {
      font-size: 8px;
      margin-right: 3.5px;
    }
  }

  @media (max-width: 600px) {
    height: 18px;

    & > p {
      font-size: 11px;
      line-height: 18px;
      margin-right: 8.5px;
    }

    & > input {
      font-size: 11px;
      height: 22px;
      line-height: 18px;
      margin-right: 8.5px;
    }
  }
`;

export const ButtonWrapper = styled.div<{isActive: boolean}>`
  /* position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px; */
  width: 149px;
  height: 32px;

  & > p {
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0.05px;
    color: ${({theme}) => theme.colors.statusGreen};
  }

  & > button {
    margin-top: 5px;
    width: 100%;
    height: 100%;
    font-size: 13px;
    cursor: pointer;
    color: ${({theme}) => theme.colors.fontBlackOp100};
    border-radius: 9px;
    border: ${({theme, isActive}) =>
      isActive ? `1px solid ${theme.colors.statusGreen}` : 'none'};
    background: ${({theme, isActive}) =>
      isActive ? 'transparent' : theme.colors.blueTheme};

    &:hover {
      filter: brightness(1.1);
    }

    &:active {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 1100px) {
    & > button {
      height: 40px;
      font-size: 15px;
    }
  }

  @media (max-width: 750px) {
    & > button {
      margin-top: 25px;
      height: 36px;
      font-size: 14px;
    }
  }

  @media (max-width: 600px) {
    & > button {
      margin-top: 30px;
      margin-bottom: 10px;
      height: 45px;
      font-size: 16px;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  margin-left: 25px;
  width: 241px;
  flex-direction: column;
  align-self: flex-start;
  & > div {
    display: flex;
    position: relative;
    & > span {
      position: absolute;
      left: 0;
      display: block;
      width: 20px;
      text-align: end;
      color: ${({theme}) => theme.colors.fontBlackOp50};
    }
    & > p {
      padding-left: 25px;
      font-size: 13px;
      line-height: 21px;
      letter-spacing: -0.25px;
      color: ${({theme}) => theme.colors.fontBlackOp50};
    }
  }
`;

/// mobile view

export const MobileWrapper = styled.div<{isBuy: boolean}>`
  width: 100%;
  /* height: calc(100vh - 107px); */
  height: 100%;
  background: ${({theme}) => theme.colors.mobileProBodyBg};
`;

export const MobileHeader = styled.div<{isBuy: boolean}>`
  /* display: flex; */
  padding: 10px 14px;
  padding-right: 0px;
  gap: 6px;
  display: ${({isBuy}) => (isBuy ? 'none' : 'flex')};
  & > h4 {
    width: 165px;
    color: ${({theme}) => theme.colors.mobileProPlanNameColor};
    font-size: 15px;
    /* margin-right: 10px; */
    list-style: 24px;
    letter-spacing: -0.05px;
    font-weight: normal;
  }
  @media (max-width: 415px) {
    & > h4 {
      width: 135px;
      font-size: 14px;
    }
  }
  @media (max-width: 385px) {
    & > h4 {
      width: 115px;
      font-size: 12px;
    }
  }
  @media (max-width: 365px) {
    & > h4 {
      width: 110px;
      font-size: 11px;
    }
  }
`;

export const MobileHeaderPlanName = styled.p<{isActive?: boolean}>`
  width: 72px;
  color: ${({theme, isActive}) =>
    isActive ? '#00d10f' : theme.colors.mobileProPlanNameColor};
  font-size: 15px;
  list-style: 24px;
  letter-spacing: -0.05px;
  word-spacing: -0.05px;
  text-align: center;
  @media (max-width: 415px) {
    font-size: 14px;
  }
  @media (max-width: 385px) {
    font-size: 12px;
  }
  @media (max-width: 365px) {
    font-size: 11px;
    width: 60px;
  }
`;

export const MobileContentWrapper = styled.div<{isBuy: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 21px;
  display: ${({isBuy}) => (isBuy ? 'none' : 'block')};
`;

export const MobileBgStart = styled.div<{isActive?: boolean}>`
  width: 72px;
  height: 410px;
  border-radius: 16px;
  position: absolute;
  top: -5px;
  left: 186px;
  background: ${({theme}) => theme.colors.mobileProBgWhiteOp50to15};
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  border: ${({isActive}) => (isActive ? `1px solid #00d10f` : 'none')};
  @media (max-width: 415px) {
    left: 156px;
    /* left: 38vw; */
  }
  @media (max-width: 385px) {
    left: 136px;
  }
  @media (max-width: 365px) {
    left: 131px;
    width: 60px;
  }
`;

export const MobileBgOptymalny = styled.div<{isActive?: boolean}>`
  width: 72px;
  height: 410px;
  border-radius: 16px;
  position: absolute;
  top: -5px;
  left: 263px;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  background: ${({theme}) => theme.colors.mobileProBgWhiteOp50to15};
  border: ${({isActive}) => (isActive ? `1px solid #00d10f` : 'none')};
  @media (max-width: 415px) {
    left: 233px;
    /* left: 57vw; */
  }
  @media (max-width: 385px) {
    left: 213px;
  }

  @media (max-width: 365px) {
    width: 60px;
    left: 197px;
  }
`;

export const MobileBgPro = styled.div<{isActive?: boolean}>`
  width: 72px;
  height: 410px;
  border-radius: 16px;
  position: absolute;
  top: -5px;
  left: 340px;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  background: ${({theme}) => theme.colors.mobileProBgWhiteOp50to15};
  border: ${({isActive}) => (isActive ? `1px solid #00d10f` : 'none')};
  @media (max-width: 415px) {
    left: 310px;
    /* left: 76vw; */
  }
  @media (max-width: 385px) {
    left: 290px;
  }
  @media (max-width: 365px) {
    width: 60px;
    left: 262px;
  }
`;

export const MobileListItemWrapper = styled.div`
  border-bottom: ${({theme}) =>
    `1px solid ${theme.colors.mobileProBorderBottom}`};
  height: 22px;
  width: 100%;
  display: flex;
  gap: 6px;
  padding-left: 14px;
`;
export const MobileListItemBig = styled.div<{
  isBold?: boolean;
  isGrey?: boolean;
  textSpacing?: boolean;
}>`
  width: 165px;
  /* min-width: 160px; */
  /* margin-right: 19px; */
  & > h4 {
    font-size: 11px;
    line-height: 21px;
    letter-spacing: -0.15px;
    word-spacing: -0.15px;
    color: ${({theme, isGrey}) =>
      isGrey
        ? theme.colors.mobileProTextOp30
        : theme.colors.mobileProTextOp100};
    font-weight: ${({isBold}) => (isBold ? 'bold' : 'normal')};
  }
  @media (max-width: 415px) {
    width: 135px;
    & > h4 {
      font-size: ${({textSpacing}) => (textSpacing ? '10px' : '11px')};
    }
  }
  @media (max-width: 385px) {
    width: 115px;
    & > h4 {
      font-size: ${({textSpacing}) => (textSpacing ? '9px' : '10px')};
      letter-spacing: ${({textSpacing}) =>
        textSpacing ? '-0.65px' : '-0.15px'};
      word-spacing: ${({textSpacing}) => (textSpacing ? '-0.65px' : '-0.15px')};
    }
  }
  @media (max-width: 365px) {
    width: 110px;
    & > h4 {
      font-size: 9px;
    }
  }
`;
export const MobileListItemSmall = styled.div<{
  isBold?: boolean;
  isGrey?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  z-index: 1;
  /* background: rgba(255, 255, 255, 0.15); */
  & > p {
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.25px;
    word-spacing: -0.25px;
    font-weight: ${({isBold}) => (isBold ? 'bold' : 'normal')};
    color: ${({theme, isGrey}) =>
      isGrey
        ? theme.colors.mobileProTextOp30
        : theme.colors.mobileProTextOp100};
  }
  @media (max-width: 365px) {
    width: 60px;
    & > p {
      font-size: 10px;
    }
  }
`;

export const MobileBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  & > p {
    font-size: 11px;
    line-height: 18px;
    letter-spacing: -0.15px;
    color: #00d10f;
  }
`;

export const MobileBlueBtn = styled.button`
  width: 312px;
  height: 44px;
  border-radius: 16px;
  margin-bottom: 7px;
  border: none;
  font-size: 15px;
  line-height: 44px;
  letter-spacing: 0.7px;
  color: white;
  background: ${({theme}) => theme.colors.blueTheme};
`;

export const MobileBlueGreen = styled.button`
  width: 312px;
  height: 44px;
  border-radius: 16px;
  margin: 0 auto;
  border: 1px solid #00d10f;
  font-size: 15px;
  line-height: 44px;
  letter-spacing: 0.7px;
  color: #00d10f;
  background: transparent;
`;

export const MobileLegendWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  padding-bottom: 107px;
  & > p {
    font-size: 11px;
    line-height: 15px;
    letter-spacing: -0.15px;
    color: ${({theme}) => theme.colors.mobileSearchBarTextColor};
  }
`;
