import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import {useCookies} from 'react-cookie';
import {ProducerDataContext} from '../../../../contexts/ProducerDataContext';
import {StatisticsContext} from '../../../../contexts/StatisticsContext';
import {Navigate, useNavigate, useLocation} from 'react-router-dom';
import {Message, MessagesType, Status} from '../interfaces';
import api from '../../../../services/axiosConfig';
import _ from 'lodash';
import useAlert from 'hooks/useAlert';
import {UserDataContext} from 'contexts/UserDataContext';
import {LoadingCircle} from 'utils/loadingCircle';

import {
  CancelButton,
  Flex,
  HeaderWrapper,
  InputWrapper,
  LeftSection,
  BlackScreen,
  LeftSectionButton,
  LeftSectionButtonWrapper,
  LeftSectionMessageContentWrapper,
  LeftSectionMessageHeader,
  LeftSectionMessagesWrapper,
  LeftSectionMessageWrapper,
  LeftSectionSearchWrapper,
  Main,
  MainWrapper,
  RightSection,
  RightSectionAddressAndTitleWrapper,
  RightSectionAddressAndTitleWriteMode,
  RightSectionHeaderWrapper,
  RightSectionIconsWrapper,
  RightSectionMessageContent,
  RefreshButton,
  RightSectionSingleIcon,
  InputWithSearchResultsWrapper,
  SearchResultWrapper,
  SingleSearchResultWrapper,
  ContentTitle,
  MobileMain,
  MobileMainWrapper,
  MobileLeftSection,
  CloseIsLoginPageOpen,
  CloseIsLoginPageOpenWrapper,
  MenuMain,
  MenuWrapper,
  MobileIconsWrapper,
  SvgEmptyMsgWrapper,
  RightSectionThreadMessagesWrapper,
  ThreadMessageWrapper,
  ThreadMessageHeader,
  ThreadMessageHeaderNameAndImg,
  ThreadMessageHeaderIcons,
  ThreadMessageContent,
  ThreadResponsIconWrapper,
  ThreadResponseWrappers,
  ThreadResponseHeaderNameAndImg,
  ThreadResponseButtonsWrapper,
  ThreadResponseContent,
  ThreadMobileScrollWrapper,
  MobileTopicWrapper,
  SvgReplyAndRefreshWrapper,
} from './styles';
import {useIsBodyOverflowBlockedContext} from 'contexts/IsBodyOverflowBlockedContext';
import {AccountProducerContext} from 'contexts/AccountProducerContext';

const defaultAvatar = (
  <svg
    id="Group_4321"
    data-name="Group 4321"
    xmlns="http://www.w3.org/2000/svg"
    width="193"
    height="193"
    viewBox="0 0 193 193"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_2509"
          data-name="Rectangle 2509"
          width="193"
          height="193"
          fill="#dce0e9"
        />
      </clipPath>
    </defs>
    <g id="Group_4320" data-name="Group 4320" clipPath="url(#clip-path)">
      <path
        id="Path_3684"
        data-name="Path 3684"
        d="M132.354,26.555a5.75,5.75,0,0,1-5.75-5.75V16.2a4.711,4.711,0,0,0-4.7-4.7h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a16.222,16.222,0,0,1,16.2,16.2v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(54.058 0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3685"
        data-name="Path 3685"
        d="M5.75,26.555A5.75,5.75,0,0,1,0,20.805V16.2A16.222,16.222,0,0,1,16.2,0h4.6a5.75,5.75,0,0,1,0,11.5H16.2a4.711,4.711,0,0,0-4.7,4.7v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3686"
        data-name="Path 3686"
        d="M20.805,138.105H16.2A16.224,16.224,0,0,1,0,121.9v-4.6a5.75,5.75,0,0,1,11.5,0v4.6a4.711,4.711,0,0,0,4.7,4.7h4.6a5.75,5.75,0,0,1,0,11.5"
        transform="translate(0 54.059)"
        fill="#dce0e9"
      />
      <path
        id="Path_3687"
        data-name="Path 3687"
        d="M132.354,26.555a5.75,5.75,0,0,1-5.75-5.75V16.2a4.711,4.711,0,0,0-4.7-4.7h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a16.222,16.222,0,0,1,16.2,16.2v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(54.058 0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3688"
        data-name="Path 3688"
        d="M122.464,138.668h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a4.712,4.712,0,0,0,4.7-4.7v-4.6a5.749,5.749,0,1,1,11.5,0v4.6a16.222,16.222,0,0,1-16.2,16.2"
        transform="translate(54.332 54.332)"
        fill="#dce0e9"
      />
      <path
        id="Path_3689"
        data-name="Path 3689"
        d="M122.464,138.668h-4.6a5.75,5.75,0,0,1,0-11.5h4.6a4.712,4.712,0,0,0,4.7-4.7v-4.6a5.749,5.749,0,1,1,11.5,0v4.6a16.222,16.222,0,0,1-16.2,16.2"
        transform="translate(54.332 54.332)"
        fill="#dce0e9"
      />
      <path
        id="Path_3690"
        data-name="Path 3690"
        d="M5.75,26.555A5.75,5.75,0,0,1,0,20.805V16.2A16.222,16.222,0,0,1,16.2,0h4.6a5.75,5.75,0,0,1,0,11.5H16.2a4.711,4.711,0,0,0-4.7,4.7v4.6a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(0)"
        fill="#dce0e9"
      />
      <path
        id="Path_3691"
        data-name="Path 3691"
        d="M20.805,138.105H16.2A16.224,16.224,0,0,1,0,121.9v-4.6a5.75,5.75,0,0,1,11.5,0v4.6a4.711,4.711,0,0,0,4.7,4.7h4.6a5.75,5.75,0,0,1,0,11.5"
        transform="translate(0 54.059)"
        fill="#dce0e9"
      />
      <path
        id="Path_3692"
        data-name="Path 3692"
        d="M66.283,112.6A35.9,35.9,0,0,1,30.422,76.736a5.751,5.751,0,0,1,11.5,0A24.387,24.387,0,0,0,66.283,101.1a5.75,5.75,0,1,1,0,11.5"
        transform="translate(14.743 34.401)"
        fill="#dce0e9"
      />
      <path
        id="Path_3693"
        data-name="Path 3693"
        d="M83.567,100.785H66.595a5.75,5.75,0,1,1,0-11.5h11.05C76.03,62.043,63.63,39.187,63.5,38.944A5.75,5.75,0,1,1,73.574,33.4c.643,1.17,15.743,29.012,15.743,61.632a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(29.486 14.743)"
        fill="#dce0e9"
      />
      <path
        id="Path_3694"
        data-name="Path 3694"
        d="M66.283,112.6A35.9,35.9,0,0,1,30.422,76.736a5.751,5.751,0,0,1,11.5,0A24.387,24.387,0,0,0,66.283,101.1a5.75,5.75,0,1,1,0,11.5"
        transform="translate(14.743 34.401)"
        fill="#dce0e9"
      />
      <path
        id="Path_3695"
        data-name="Path 3695"
        d="M83.567,100.785H66.595a5.75,5.75,0,1,1,0-11.5h11.05C76.03,62.043,63.63,39.187,63.5,38.944A5.75,5.75,0,1,1,73.574,33.4c.643,1.17,15.743,29.012,15.743,61.632a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(29.486 14.743)"
        fill="#dce0e9"
      />
      <path
        id="Path_3696"
        data-name="Path 3696"
        d="M36.173,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(14.743 13.514)"
        fill="#dce0e9"
      />
      <path
        id="Path_3697"
        data-name="Path 3697"
        d="M36.173,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(14.743 13.514)"
        fill="#dce0e9"
      />
      <path
        id="Path_3698"
        data-name="Path 3698"
        d="M97.018,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(44.23 13.514)"
        fill="#dce0e9"
      />
      <path
        id="Path_3699"
        data-name="Path 3699"
        d="M97.018,46.915a5.75,5.75,0,0,1-5.75-5.75V33.637a5.75,5.75,0,1,1,11.5,0v7.528a5.75,5.75,0,0,1-5.75,5.75"
        transform="translate(44.23 13.514)"
        fill="#dce0e9"
      />
    </g>
  </svg>
);

export const SearchBarWrapper = styled.div`
  // position: absolute;
  // top: 139px;
  // left: 135px;
  height: 28px;
  margin-left: 11px;
  width: 275px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 2.5px;
  background: ${({theme}) => theme.colors.bgWhite};
  border: ${({theme}) => `1px solid ${theme.colors.fontBlackOp50}`};
  border-radius: 9px;

  & > input {
    width: 324px;
    border: none;
    background: transparent;
    outline: none;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    top: 106px;
    left: 21px;
    width: 100%;
    margin-left: 7px;

    & > input {
      width: 204px;
    }
  }
`;

export const SearchButton = styled.div`
  width: 28px;
  height: 23px;
  border-radius: 7px;
  background: ${({theme}) => theme.colors.blueTheme};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 11px;
  letter-spacing: 0.05px;
  font-weight: 400;
  color: rgba(23, 22, 29, 0.5);

  &::placeholder {
    color: rgba(23, 22, 29, 0.5);
  }

  & > svg {
    margin-left: 2px;
  }
`;

export const AddButton = styled.button`
  width: 44px;
  height: 44px;
  position: absolute;
  right: 7px;
  border: ${({theme}) => `1px solid ${theme.colors.blueTheme}`};
  background: transparent;
  border-radius: 9px;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 0.625px;
  color: #352f40;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s;
  & > svg {
    & > g {
      & > path {
        stroke: ${({theme}) => theme.colors.blueTheme};
      }
    }
  }

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
`;

export const CancelMessage = styled.button`
  width: 44px;
  height: 44px;
  position: absolute;
  left: 7px;
  border: none;
  background: transparent;
  border-radius: 9px;
  cursor: pointer;
  font-weight: 400;
  font-size: 11px;
  border: ${({theme}) => `1px solid ${theme.colors.blueTheme}`};
  letter-spacing: 0.625px;
  color: ${({theme}) => theme.colors.fontBlackOp100};
  transition: all 0.15s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(248, 248, 248, 0.15);
  }
  & > svg {
    & > path {
      fill: ${({theme}) => theme.colors.blueTheme};
    }
  }
`;

interface KnowsUser {
  Name: string;
  UserId: string;
  PhotoFilePath: string;
}

interface ProducerData {
  CardId: string;
  ProducerFirstName: string;
  ProducerId: string;
  ProducerLastName: string;
  ProducerPhoto: string;
}

interface DataFromOffer {
  producerIdFromOffer: string;
  producerNameFromOffer: string;
  productNameFromOffer: string;
  productIdFromOffer: string;
}

interface LocationState {
  state: null | DataFromOffer;
}

export const NewMessagePage: React.FC = () => {
  const [cookies, setCookie] = useCookies(['token']);

  const {decodedToken} = useContext(ProducerDataContext);
  const [statistics, setStatistics] = useContext(StatisticsContext);
  const [userData, setUserData] = useContext(UserDataContext);
  const [producerData, setProducerData] = useContext(AccountProducerContext);

  if (!decodedToken) {
    return <Navigate to="/identity/login" />;
  }

  // if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000') {
  //   return <Navigate to="/producer" />;
  // }

  if (userData.FirstName === null) {
    window.location.href = '/account/settings';
  }

  const [productId, setProductId] = useState<string>('');

  const [receivedMessages, setReceivedMessages] = useState<Message[]>([]);
  const [sentMessages, setSentMessages] = useState<Message[]>([]);
  const [threadMessages, setThreadMessages] = useState<Message[]>([]);
  const [messagesType, setMessagesType] = useState<MessagesType>(
    MessagesType.RECEIVED,
  );

  const [isReceivedMessagesLoading, setIsReceivedMessagesLoading] =
    useState<boolean>(false);
  const [isSentMessagesLoading, setIsSentMessagesLoading] =
    useState<boolean>(false);
  const [isMessageSending, setIsMessageSending] = useState<boolean>(false);
  const [receivedMessagesLoading, setReceivedMessagesLoading] =
    useState<boolean>(true);
  const [sentMessagesLoading, setSentMessagesLoading] = useState<boolean>(true);
  const [isReplying, setReplying] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<Status[]>([]);
  const [topic, setTopic] = useState<string>('');
  const [thread, setThread] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [target, setTarget] = useState<number | string>(0);
  const [receiver, setReceiver] = useState<string>('');
  const [receiverId, setReceiverId] = useState<string>('');
  const [isReceiverError, setIsReceiverError] = useState<boolean>(false);
  const [currentReceivedMessage, setCurrentReceivedMessage] =
    useState<number>(0);
  const [currentSentMessage, setCurrentSentMessage] = useState<number>(0);
  const [isAfterRemovedThread, setIsAfterRemovedThread] = useState(false);
  const [selectedMessageDetails, setSelectedMessageDetails] = useState<Message>(
    {
      Thread: null,
      Content: '',
      Id: '',
      RecieverId: '',
      RecieverName: '',
      SenderId: '',
      SenderName: '',
      SenderStatus: {
        FieldName: '',
        FlagValue: 0,
      },
      RecieverStatus: {
        FieldName: '',
        FlagValue: 0,
      },
      Topic: '',
      IsCurrentUserSender: false,
    },
  );

  const [isFocused, toggleFocused] = useState<boolean>(false);
  const [isWriteMode, setIsWriteMode] = useState<boolean>(false);
  const [isThreadWriteMode, setIsThreadWriteMode] = useState<boolean>(false);
  const [isThreadViewAActiveMobile, setIsThreadViewAActiveMobile] =
    useState(false);
  const [receivedSearchString, setReceivedSearchString] = useState<string>('');
  const [sentSearchString, setSentSearchString] = useState<string>('');
  const [isMobileMessageViewOpen, setIsMobileMessageViewOpen] =
    useState<boolean>(false);
  const [isMobileNewMessageViewOpen, setIsMobileNewMessageViewOpen] =
    useState<boolean>(false);
  const [knowsUsers, setKnowsUsers] = useState<KnowsUser[]>([
    {Name: 'Damian', UserId: '1', PhotoFilePath: 'dad'},
    {Name: 'Aleksandara', UserId: '2', PhotoFilePath: 'dad'},
    {Name: 'Zosia', UserId: '3', PhotoFilePath: 'dad'},
  ]);
  const mobileMessageViewWindowRef = useRef<HTMLDivElement | null>(null);
  const mobileNewMessageViewWindowRef = useRef<HTMLDivElement | null>(null);
  const mobileThreadViewWindowRef = useRef<HTMLDivElement | null>(null);
  const {setIsBodyOverflowBlocked} = useIsBodyOverflowBlockedContext();

  const {Alert} = useAlert();

  const handleIsWriteMode = (active: boolean) => setIsWriteMode(active);

  const location = useLocation();
  const {state} = location as LocationState;

  const navigate = useNavigate();

  const threadRef = useRef(null);

  const handleIsMobileMessageViewOpen = (value: boolean) =>
    setIsMobileMessageViewOpen(value);

  const handleIsMobileNewMessageViewOpen = (value: boolean) =>
    setIsMobileNewMessageViewOpen(value);

  const handleReceivedSearchStringChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setReceivedSearchString(e.target.value);

  const handleSentSearchStringChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => setSentSearchString(e.target.value);

  // Funkcja do dodawania 2 godzin do podanej daty
  function addTwoHours(time) {
    const polishTime = new Date(time);
    polishTime.setHours(polishTime.getHours() + 2);
    return polishTime;
  }

  const clearFormData = () => {
    // setTarget('');
    setReceiver('');
    setReceiverId('');
    setTopic('');
    setContent('');
    setIsThreadWriteMode(false);
  };

  const clearSelectedMessage = () => {
    if (!selectedMessageDetails.Id) return;

    setSelectedMessageDetails({
      Thread: null,
      Content: '',
      Id: '',
      RecieverId: '',
      RecieverName: '',
      SenderId: '',
      SenderName: '',
      SenderStatus: {
        FieldName: '',
        FlagValue: 0,
      },
      RecieverStatus: {
        FieldName: '',
        FlagValue: 0,
      },
      Topic: '',
      IsCurrentUserSender: false,
    });
    setThread('');
  };

  const replyToTheMessage = () => {
    if (!selectedMessageDetails.Id) return;
    // console.log(
    //   'moje USERID:',
    //   userData.UserId,
    //   'wybrana wiadomosc dane',
    //   selectedMessageDetails,
    // );
    handleIsReplyingChange(true);
    setThread(selectedMessageDetails.Thread);
    setTopic(selectedMessageDetails.Topic);
    // setTarget(selectedMessageDetails.Target.FlagValue);
    // if (selectedMessageDetails.Target.FlagValue === 0) {
    if (userData.UserId !== selectedMessageDetails.SenderId) {
      setReceiverId(selectedMessageDetails.SenderId);
      setReceiver(selectedMessageDetails.SenderName);
    } else {
      setReceiverId(selectedMessageDetails.RecieverId);
      setReceiver(selectedMessageDetails.RecieverName);
    }
    // }
    // clearSelectedMessage();
  };

  useEffect(() => {
    if (isReplying && threadRef.current) {
      threadRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [isReplying, isThreadWriteMode]);

  const compareDates = (a, b) => b.SentDate - a.SentDate;

  const handleOnFocusChange = (value: boolean) => toggleFocused(value);

  const handleIsReplyingChange = (value: boolean) => setReplying(value);

  const onBlur = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget)) return toggleFocused(false);
    return toggleFocused(true);
  };

  const handleReceiverIdChange = (value: string) => setReceiverId(value);

  const handleMessagesTypeChange = (value: MessagesType) =>
    setMessagesType(value);

  const handleTopicChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setTopic(e.target.value);

  const handleReceiverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('producerIdFromOffer podczas zmiany oferty', state);
    if (state !== null) return;
    setReceiver(e.target.value);
    setReceiverId('');
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setContent(e.target.value);

  const selectFirstReceivedMessage = () => {
    if (receivedMessages.length > 0) {
      setSelectedMessageDetails(receivedMessages[0]);
    }
  };

  const selectFirstSentMessage = () => {
    if (sentMessages.length > 0) {
      setSelectedMessageDetails(sentMessages[0]);
    }
  };

  const getProducerData = async (producerId: string) => {
    // console.log('producer');
    if (producerData.Id.length > 1) return console.log('mam już producera');
    if (decodedToken.ProducerId === '00000000-0000-0000-0000-000000000000') {
      return;
    }

    try {
      const response = await api.get(`Producer/${producerId}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setProducerData(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const getReceivedMessagesAndSelectFirst = async (searchString: string) => {
    setReceivedMessagesLoading(true);
    setIsReceivedMessagesLoading(true);
    try {
      const params = {
        searchString: searchString.length > 0 ? searchString : undefined,
      };

      const response = await api.get(`Message/Received`, {
        params,
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      const messagesArray = response.data.Response.reverse()

        .map((item) => ({
          ...item,
          SentDate: new Date(item.SentDate),
        }))
        .sort(compareDates);

      setReceivedMessages(messagesArray);

      if (messagesArray.length > 0) {
        setSelectedMessageDetails(messagesArray[0]);
        // markMessageAsSeen(
        //   messagesArray[0].Id,
        //   decodedToken.ProducerId,
        //   messagesArray[0].ReciverStatus.FlagValue,
        // );
      }
      setReceivedMessagesLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsReceivedMessagesLoading(false);
    }
  };

  const getStatistics = async () => {
    try {
      const response = await api.get(`Statistics`, {
        headers: {
          Authorization: `Bearer ${cookies.token}`,
        },
      });
      // console.log(response.data);
      setStatistics(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const convertToLocalDateTime = (utcDateTime) => {
    const utcDate = new Date(utcDateTime);

    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000,
    ); // Konwersja na czas lokalny

    return localDate.toLocaleString();
  };

  const getSentMessages = async (searchString: string) => {
    setSentMessagesLoading(true);
    setIsSentMessagesLoading(true);
    try {
      const params = {
        searchString: searchString.length > 0 ? searchString : undefined,
      };

      const response = await api.get(`Message/Sent`, {
        params,
        headers: {Authorization: `bearer ${cookies.token}`},
      });

      setSentMessages(
        response.data.Response.reverse()
          .map((item) => ({
            ...item,
            SentDate: new Date(item.SentDate),
          }))
          .sort(compareDates),
      );

      setSentMessagesLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSentMessagesLoading(false);
    }
  };

  const getSentMessagesDebounce = useCallback(
    _.debounce((searchString) => getSentMessages(searchString), 500),
    [],
  );

  const getReceivedMessagesAndSelectFirstDebounce = useCallback(
    _.debounce(
      (searchString) => getReceivedMessagesAndSelectFirst(searchString),
      500,
    ),
    [],
  );

  const getThreadMessages = async (thread: string) => {
    console.log('pobieram wiadomości z thread', thread);
    if (thread === '') return;
    try {
      const response = await api.get(`message/ByThread/${thread}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });

      setThreadMessages(response.data.Response);
    } catch (error) {
      console.log(error);
    }
  };

  const getKnowsUsers = async () => {
    try {
      const response = await api.get(`message/knownusers`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });

      setKnowsUsers(
        response.data.Response.filter(
          (user) => user.UserId !== userData.UserId,
        ),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getSupport = async () => {
    try {
      const response = await api.get(`Producer/support`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      // console.log('get knowsUers', response.data.Response);
      // console.log(
      //   userData.UserId,
      //   'know user aftrer filter',
      //   response.data.Response.filter(
      //     (user) => user.UserId !== userData.UserId,
      //   ),
      // );
      // console.log(response.data.Response);
      if (response.data.Response.length > 0) {
        setReceiver(response.data.Response[0].Name);
        setReceiverId(response.data.Response[0].Id);
        setIsWriteMode(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteMessage = async (messageId: string, producerId: string) => {
    // console.log(messageId);
    if (!messageId) return;

    try {
      const body = {
        MessageId: messageId,
        ProducerId: producerId,
      };

      const response = await api.delete(`Message`, {
        data: {...body},
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setThreadMessages((prevArr) =>
        prevArr.filter((msg) => msg.Id !== messageId),
      );
      // getSentMessages(sentSearchString);
      // getReceivedMessagesAndSelectFirst(receivedSearchString);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteThread = async (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    thread: string,
    producerId: string,
  ) => {
    // console.log(messageId);
    e.stopPropagation();
    console.log('usuwam thread');
    if (!thread) return;

    try {
      const body = {
        MessageId: thread,
        ProducerId: producerId,
      };

      const response = await api.delete(`message/ByThread/${thread}`, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      setReceivedMessages((prevArr) =>
        prevArr.filter((msg) => msg.Thread !== thread),
      );
      setSentMessages((prevArr) =>
        prevArr.filter((msg) => msg.Thread !== thread),
      );
      setThreadMessages([]);
      clearSelectedMessage();
      setIsAfterRemovedThread(true);
    } catch (error) {
      console.log(error);
    }
  };

  const markMessageAsSeen = async (threadId: string, flagValue: number) => {
    if (!threadId) return;
    if (flagValue === 1) return;

    try {
      const response = await api.put(
        `Message/read/${threadId}`,
        {test: 'test'},
        {
          headers: {Authorization: `bearer ${cookies.token}`},
        },
      );

      // setReceivedMessages((prev: Message[]) => [
      //   ...prev.map((message: Message) => {
      //     return {
      //       ...message,
      //       RecieverStatus: {FieldName: 'Seen', FlagValue: 1},
      //       SenderStatus: {FieldName: 'Seen', FlagValue: 1},
      //     };
      //   }),
      // ]);
      // setSentMessages((prev: Message[]) => [
      //   ...prev.map((message: Message) => {
      //     return {
      //       ...message,
      //       RecieverStatus: {FieldName: 'Seen', FlagValue: 1},
      //       SenderStatus: {FieldName: 'Seen', FlagValue: 1},
      //     };
      //   }),
      // ]);
      setReceivedMessages((prev: Message[]) =>
        prev.map((message: Message) =>
          message.Thread === threadId
            ? {
                ...message,
                RecieverStatus: {FieldName: 'Seen', FlagValue: 1},
                SenderStatus: {FieldName: 'Seen', FlagValue: 1},
              }
            : message,
        ),
      );

      setSentMessages((prev: Message[]) =>
        prev.map((message: Message) =>
          message.Thread === threadId
            ? {
                ...message,
                RecieverStatus: {FieldName: 'Seen', FlagValue: 1},
                SenderStatus: {FieldName: 'Seen', FlagValue: 1},
              }
            : message,
        ),
      );

      await getStatistics();
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async (isNewMessage: boolean) => {
    if (!topic || !content)
      return Alert('Uzupełnij wszystkie pola', 'Komunikat');
    if (target === 0 && !receiverId)
      return Alert(
        'Zaznacz twórcę, do którego chcesz wysłać wiadomość.',
        'Komunikat',
      );
    if (isMessageSending) return;
    setIsMessageSending(true);
    try {
      if (thread !== '') {
        await markMessageAsSeen(thread, 2);
      }

      const body = {
        Thread: thread,
        Topic: topic,
        Content: content,
        ProductId: productId,
        ReceiverGuid: receiverId,
      };
      const response = await api.post(`Message`, body, {
        headers: {Authorization: `bearer ${cookies.token}`},
      });
      window.history.replaceState({}, document.title);
      if (isNewMessage) {
        Alert('Nowa Wiadomość została wysłana', 'Komunikat', 'Zamknij', true);
      } else {
        getThreadMessages(thread);
        setContent('');
        Alert('Wiadomość została wysłana', 'Komunikat', 'Zamknij');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsMessageSending(false);
    }
  };

  useEffect(() => {
    if (selectedMessageDetails.Thread === '') return;

    const intervalId = setInterval(async () => {
      await getThreadMessages(selectedMessageDetails.Thread);
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [selectedMessageDetails.Thread]);

  useEffect(() => {
    if (userData.UserId) {
      getKnowsUsers();
    }
  }, [userData]);

  useEffect(() => {
    if (messagesType === MessagesType.RECEIVED) {
      if (receivedMessages.length > 0 && threadMessages.length < 1) {
        getThreadMessages(selectedMessageDetails.Thread);
      }
    } else {
      if (sentMessages.length > 0 && threadMessages.length < 1) {
        getThreadMessages(selectedMessageDetails.Thread);
      }
    }
  }, [messagesType, selectedMessageDetails]);

  useEffect(() => {
    getReceivedMessagesAndSelectFirstDebounce(receivedSearchString);
  }, [receivedSearchString]);

  useEffect(() => {
    getSentMessagesDebounce(sentSearchString);
  }, [sentSearchString]);

  useEffect(() => {
    if (producerData.Id.length < 1) {
      getProducerData(decodedToken.ProducerId);
    }
  }, [producerData.Id]);

  useEffect(() => {
    if (state) {
      const {
        productIdFromOffer,
        producerIdFromOffer,
        producerNameFromOffer,
        productNameFromOffer,
      } = state;
      if (producerIdFromOffer === 'support') {
        getSupport();
        return;
      }
      setReceiver(producerNameFromOffer);
      setReceiverId(producerIdFromOffer);
      setTopic(productNameFromOffer);
      setProductId(productIdFromOffer);
      setIsWriteMode(true);
    }
  }, []);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileMessageViewWindowRef.current) {
        if (isMobileMessageViewOpen) {
          mobileMessageViewWindowRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileMessageViewWindowRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isMobileMessageViewOpen]);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileNewMessageViewWindowRef.current) {
        if (isMobileNewMessageViewOpen) {
          mobileNewMessageViewWindowRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileNewMessageViewWindowRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isMobileNewMessageViewOpen]);

  useEffect(() => {
    const checkCloseButtonVisibility = () => {
      if (mobileThreadViewWindowRef.current) {
        if (isThreadViewAActiveMobile) {
          mobileThreadViewWindowRef.current.style.height = `${window.visualViewport.height}px`;
        } else {
          mobileThreadViewWindowRef.current.style.height = `${0}px`;
        }
      }
    };

    checkCloseButtonVisibility();

    window.addEventListener('resize', checkCloseButtonVisibility);

    return () => {
      window.removeEventListener('resize', checkCloseButtonVisibility);
    };
  }, [isThreadViewAActiveMobile]);

  useEffect(() => {
    setIsThreadWriteMode(false);
  }, [selectedMessageDetails]);

  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <>
      <MainWrapper>
        <Main>
          {(isReceivedMessagesLoading ||
            isSentMessagesLoading ||
            isMessageSending) && <LoadingCircle />}
          <HeaderWrapper>
            {isWriteMode && state?.producerIdFromOffer ? (
              <CancelMessage onClick={() => navigate(-1)}>
                <svg
                  id="Arrow_Top_Bar_Accent"
                  data-name="Arrow – Top Bar / Accent"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.195"
                  height="14"
                  viewBox="0 0 8.195 14"
                >
                  <path
                    id="Arrow"
                    d="M6.513,13.715.2,7.475a.666.666,0,0,1,0-.95L6.513.285a.994.994,0,0,1,1.394,0,.967.967,0,0,1,0,1.377L2.506,7l5.4,5.337a.967.967,0,0,1,0,1.377.994.994,0,0,1-1.394,0"
                    transform="translate(0 0)"
                    fill="#fff"
                  />
                </svg>
              </CancelMessage>
            ) : (
              isWriteMode && (
                <CancelMessage
                  onClick={() => {
                    handleIsWriteMode(false);
                    if (messagesType === MessagesType.RECEIVED) {
                      selectFirstReceivedMessage();
                    } else {
                      selectFirstSentMessage();
                    }
                  }}
                >
                  <svg
                    id="Arrow_Top_Bar_Accent"
                    data-name="Arrow – Top Bar / Accent"
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.195"
                    height="14"
                    viewBox="0 0 8.195 14"
                  >
                    <path
                      id="Arrow"
                      d="M6.513,13.715.2,7.475a.666.666,0,0,1,0-.95L6.513.285a.994.994,0,0,1,1.394,0,.967.967,0,0,1,0,1.377L2.506,7l5.4,5.337a.967.967,0,0,1,0,1.377.994.994,0,0,1-1.394,0"
                      transform="translate(0 0)"
                      fill="#fff"
                    />
                  </svg>
                </CancelMessage>
              )
            )}
            {isWriteMode ? 'Nowa wiadomość' : 'Wiadomości'}
            {!isWriteMode ? null : ( // </AddButton> //   </svg> //     /> //       fill="#f8f8f8" //       transform="translate(-4.5 -4.5)" //       d="M18.214,18.214h-12v-12h6V4.5h-6A1.714,1.714,0,0,0,4.5,6.214v12a1.714,1.714,0,0,0,1.714,1.714h12a1.719,1.719,0,0,0,1.714-1.714v-6H18.214ZM13.929,4.5V6.214h3.077L8.58,14.64l1.209,1.209,8.426-8.426V10.5h1.714v-6Z" //       id="nowa_wiadomosc" //     <path //   > //     viewBox="0 0 15.428 15.428" //     height="15.428" //     width="15.428" //     xmlns="http://www.w3.org/2000/svg" //   <svg // > //   }} //     handleIsWriteMode(true); //     handleIsReplyingChange(false); //     clearFormData(); //     clearSelectedMessage(); //   onClick={() => { // <AddButton
              <AddButton
                onClick={() => {
                  sendMessage(true);
                  handleIsReplyingChange(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.414"
                  height="20.414"
                  viewBox="0 0 20.414 20.414"
                >
                  <g id="wyslij_wiadomosc" transform="translate(-2 -1.586)">
                    <path
                      id="Path_3733"
                      data-name="Path 3733"
                      d="M26.4,3l-9.9,9.9"
                      transform="translate(-5.4 0)"
                      fill="none"
                      stroke="#f8f8f8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                    <path
                      id="Path_3734"
                      data-name="Path 3734"
                      d="M21,3,14.7,21l-3.6-8.1L3,9.3Z"
                      transform="translate(0 0)"
                      fill="none"
                      stroke="#f8f8f8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </g>
                </svg>
              </AddButton>
            )}
          </HeaderWrapper>
          <Flex>
            <LeftSection isWriteMode={isWriteMode}>
              <LeftSectionButtonWrapper>
                <LeftSectionButton
                  value={MessagesType.RECEIVED}
                  messagesType={messagesType}
                  onClick={() => {
                    handleMessagesTypeChange(MessagesType.RECEIVED);
                    selectFirstReceivedMessage();
                    setThreadMessages([]);
                  }}
                >
                  Odebrane
                </LeftSectionButton>
                <LeftSectionButton
                  value={MessagesType.SENT}
                  messagesType={messagesType}
                  onClick={() => {
                    handleMessagesTypeChange(MessagesType.SENT);
                    selectFirstSentMessage();
                    setThreadMessages([]);
                  }}
                >
                  Wysłane
                </LeftSectionButton>
              </LeftSectionButtonWrapper>
              <LeftSectionSearchWrapper>
                <RefreshButton>
                  <svg
                    onClick={() => {
                      clearSelectedMessage();
                      clearFormData();
                      handleIsReplyingChange(false);
                      handleIsWriteMode(true);
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path
                      id="Icon_material-open-in-new"
                      data-name="Icon material-open-in-new"
                      d="M20.5,20.5H6.5V6.5h7v-2h-7a2,2,0,0,0-2,2v14a2,2,0,0,0,2,2h14a2.006,2.006,0,0,0,2-2v-7h-2Zm-5-16v2h3.59L9.26,16.33l1.41,1.41L20.5,7.91V11.5h2v-7Z"
                      transform="translate(-4.5 -4.5)"
                      fill="#06f"
                    />
                  </svg>
                </RefreshButton>
                {messagesType === MessagesType.RECEIVED ? (
                  // <InputWrapper>
                  //   <svg
                  //     xmlns="http://www.w3.org/2000/svg"
                  //     width="11.115"
                  //     height="11"
                  //     viewBox="0 0 11.115 11"
                  //   >
                  //     <g id="Group_3812" data-name="Group 3812" opacity="1">
                  //       <path
                  //         id="Path_1507"
                  //         data-name="Path 1507"
                  //         d="M2598.85,544.913l-2.765-2.52a4.572,4.572,0,0,0-.352-6.057h0a4.57,4.57,0,1,0-.552,6.924l2.828,2.577a.625.625,0,1,0,.843-.924Zm-8.876-2.818a3.578,3.578,0,1,1,2.529,1.048A3.581,3.581,0,0,1,2589.974,542.1Z"
                  //         transform="translate(-2587.94 -535.001)"
                  //         fill="#352F40"
                  //       />
                  //     </g>
                  //   </svg>
                  //   <input
                  //     placeholder="Wyszukaj po tytule..."
                  //     value={receivedSearchString}
                  //     onChange={handleReceivedSearchStringChange}
                  //   />
                  // </InputWrapper>
                  <SearchBarWrapper>
                    <input
                      type="text"
                      placeholder="Wyszukaj po tytule..."
                      value={receivedSearchString}
                      onChange={handleReceivedSearchStringChange}
                    />
                    <SearchButton>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.146"
                        height="14"
                        viewBox="0 0 14.146 14"
                      >
                        <path
                          id="Path_1507"
                          data-name="Path 1507"
                          d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                          transform="translate(-2587.94 -535.001)"
                          fill="#fff"
                        />
                      </svg>
                    </SearchButton>
                  </SearchBarWrapper>
                ) : (
                  <SearchBarWrapper>
                    <input
                      type="text"
                      placeholder="Wyszukaj po tytule..."
                      value={sentSearchString}
                      onChange={handleSentSearchStringChange}
                    />
                    <SearchButton>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14.146"
                        height="14"
                        viewBox="0 0 14.146 14"
                      >
                        <path
                          id="Path_1507"
                          data-name="Path 1507"
                          d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                          transform="translate(-2587.94 -535.001)"
                          fill="#fff"
                        />
                      </svg>
                    </SearchButton>
                  </SearchBarWrapper>
                  // <InputWrapper>
                  //   <svg
                  //     xmlns="http://www.w3.org/2000/svg"
                  //     width="11.115"
                  //     height="11"
                  //     viewBox="0 0 11.115 11"
                  //   >
                  //     <g id="Group_3812" data-name="Group 3812" opacity="1">
                  //       <path
                  //         id="Path_1507"
                  //         data-name="Path 1507"
                  //         d="M2598.85,544.913l-2.765-2.52a4.572,4.572,0,0,0-.352-6.057h0a4.57,4.57,0,1,0-.552,6.924l2.828,2.577a.625.625,0,1,0,.843-.924Zm-8.876-2.818a3.578,3.578,0,1,1,2.529,1.048A3.581,3.581,0,0,1,2589.974,542.1Z"
                  //         transform="translate(-2587.94 -535.001)"
                  //         fill="#352F40"
                  //       />
                  //     </g>
                  //   </svg>
                  //   <input
                  //     placeholder="Wyszukaj po tytule..."
                  //     value={sentSearchString}
                  //     onChange={handleSentSearchStringChange}
                  //   />
                  // </InputWrapper>
                )}
              </LeftSectionSearchWrapper>
              <LeftSectionMessagesWrapper>
                {messagesType === MessagesType.RECEIVED
                  ? receivedMessages
                      // .filter(
                      //   (message) => message.RecieverStatus.FlagValue !== 2,
                      // )
                      .map((message) => {
                        const {
                          Content,
                          Id,
                          RecieverId,
                          RecieverName,
                          SenderId,
                          SenderName,
                          SentDate,
                          SenderStatus,
                          RecieverStatus,
                          Topic,
                          SenderPhotoFilePath,
                          Thread,
                        } = message;
                        const createdOn = new Date(SentDate);
                        const today = new Date();
                        const msInDay = 24 * 60 * 60 * 1000;
                        const daysAgo = Math.round(
                          (+today - +createdOn) / msInDay,
                        );

                        return (
                          <LeftSectionMessageWrapper
                            isActive={Id === selectedMessageDetails.Id}
                            key={Id}
                            onClick={() => {
                              handleIsReplyingChange(false);
                              setSelectedMessageDetails(message);
                              markMessageAsSeen(
                                Thread,
                                RecieverStatus.FlagValue,
                              );
                              getStatistics();
                              getThreadMessages(Thread);
                            }}
                          >
                            <LeftSectionMessageHeader>
                              {SenderPhotoFilePath !== null &&
                              SenderPhotoFilePath !== '' ? (
                                <img
                                  src={SenderPhotoFilePath}
                                  alt={SenderName}
                                />
                              ) : (
                                defaultAvatar
                              )}
                              <div>
                                {RecieverStatus.FlagValue === 0 && <span />}
                                <p>
                                  <span>{SenderName}</span>
                                </p>
                                <p>
                                  <span style={{fontWeight: '400'}}>
                                    {Topic}
                                  </span>
                                </p>
                              </div>
                              <span>
                                {daysAgo === 0
                                  ? 'Dzisiaj'
                                  : daysAgo === 1
                                  ? 'Wczoraj'
                                  : `${daysAgo} dni temu`}
                              </span>

                              <svg
                                style={{
                                  color: 'black',
                                  position: 'absolute',
                                  right: '5px',
                                  top: '9px',
                                  fontSize: '9px',
                                  height: '11px',
                                  borderRadius: '0px',
                                  width: '11px',
                                  cursor: 'pointer',
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.3"
                                height="16.5"
                                viewBox="0 0 12.3 13.5"
                                onClick={(e) => {
                                  // handleIsReplyingChange(false);
                                  deleteThread(e, Thread, productId);
                                  e.preventDefault();
                                }}
                              >
                                <g
                                  id="Icon_feather-trash"
                                  data-name="Icon feather-trash"
                                  transform="translate(0.75 0.75)"
                                >
                                  <path
                                    id="Path_3663"
                                    data-name="Path 3663"
                                    d="M4.5,9H15.3"
                                    transform="translate(-4.5 -6.6)"
                                    fill="none"
                                    stroke="#3986EF"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    id="Path_3664"
                                    data-name="Path 3664"
                                    d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                                    transform="translate(-6.3 -3)"
                                    fill="none"
                                    stroke="#3986EF"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                </g>
                              </svg>
                            </LeftSectionMessageHeader>
                            <LeftSectionMessageContentWrapper
                              className="LeftSectionMessageContentWrapper"
                              isActive={Id === selectedMessageDetails.Id}
                            >
                              {/* <h4>{Topic}</h4> */}
                              <p>{Content}</p>
                            </LeftSectionMessageContentWrapper>
                          </LeftSectionMessageWrapper>
                        );
                      })
                  : sentMessages
                      // .filter((message) => message.SenderStatus.FlagValue !== 2)
                      .map((message) => {
                        const {
                          Content,
                          Id,
                          RecieverId,
                          RecieverName,
                          SenderId,
                          SenderName,
                          SentDate,
                          RecieverStatus,
                          SenderStatus,
                          Topic,
                          RecieverPhotoFilePath,
                          Thread,
                        } = message;
                        const createdOn = new Date(SentDate);
                        const today = new Date();
                        const msInDay = 24 * 60 * 60 * 1000;
                        const daysAgo = Math.round(
                          (+today - +createdOn) / msInDay,
                        );

                        return (
                          <LeftSectionMessageWrapper
                            isActive={Id === selectedMessageDetails.Id}
                            key={Id}
                            onClick={() => {
                              handleIsReplyingChange(false);
                              setSelectedMessageDetails(message);
                              markMessageAsSeen(Thread, SenderStatus.FlagValue);
                              getStatistics();
                              getThreadMessages(Thread);
                            }}
                          >
                            <LeftSectionMessageHeader>
                              {RecieverPhotoFilePath !== null &&
                              RecieverPhotoFilePath !== '' ? (
                                <img
                                  src={RecieverPhotoFilePath}
                                  alt={RecieverName}
                                />
                              ) : (
                                defaultAvatar
                              )}
                              <div>
                                {/* {SenderStatus.FlagValue === 0 && <span />} */}
                                <p>
                                  <span>{RecieverName}</span>
                                </p>
                                <p>
                                  <span style={{fontWeight: '400'}}>
                                    {Topic}
                                  </span>
                                </p>
                              </div>
                              <span>
                                {daysAgo === 0
                                  ? 'Dzisiaj'
                                  : daysAgo === 1
                                  ? 'Wczoraj'
                                  : `${daysAgo} dni temu`}
                              </span>
                              <svg
                                style={{
                                  color: 'black',
                                  position: 'absolute',
                                  right: '5px',
                                  top: '9px',
                                  fontSize: '9px',
                                  height: '11px',
                                  borderRadius: '0px',
                                  width: '11px',
                                  cursor: 'pointer',
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16.3"
                                height="16.5"
                                viewBox="0 0 12.3 13.5"
                                onClick={(e) => {
                                  // handleIsReplyingChange(false);
                                  deleteThread(e, Thread, productId);
                                  e.preventDefault();
                                }}
                              >
                                <g
                                  id="Icon_feather-trash"
                                  data-name="Icon feather-trash"
                                  transform="translate(0.75 0.75)"
                                >
                                  <path
                                    id="Path_3663"
                                    data-name="Path 3663"
                                    d="M4.5,9H15.3"
                                    transform="translate(-4.5 -6.6)"
                                    fill="none"
                                    stroke="#3986EF"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    id="Path_3664"
                                    data-name="Path 3664"
                                    d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                                    transform="translate(-6.3 -3)"
                                    fill="none"
                                    stroke="#3986EF"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                  />
                                </g>
                              </svg>
                            </LeftSectionMessageHeader>
                            <LeftSectionMessageContentWrapper
                              isActive={Id === selectedMessageDetails.Id}
                            >
                              {/* <h4>{Topic}</h4> */}
                              <div />
                              <p>{Content}</p>
                            </LeftSectionMessageContentWrapper>
                          </LeftSectionMessageWrapper>
                        );
                      })}
              </LeftSectionMessagesWrapper>
            </LeftSection>
            <RightSection isWriteMode={isWriteMode}>
              {!isWriteMode && (
                <ThreadResponsIconWrapper>
                  {' '}
                  <p>
                    {selectedMessageDetails.Topic === '' &&
                    isAfterRemovedThread ? (
                      <SvgEmptyMsgWrapper>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="67.2"
                          height="72"
                          viewBox="0 0 67.2 72"
                        >
                          <path
                            id="Icon_metro-mail-read"
                            data-name="Icon metro-mail-read"
                            d="M47.7,30.728h-24v4.8h24Zm-14.4-9.6H23.7v4.8h9.6Zm28.8-2.06v-7.54H51.538L38.1,1.928l-13.439,9.6H14.1v7.54l-9.6,6.86v48H71.7v-48l-9.6-6.86Zm-43.2-2.74H57.3V34.689L38.1,50.528,18.9,34.689V16.328Zm-9.6,19.2L28.2,49.9,9.3,64.328v-28.8Zm4.8,33.6,19.05-15.469,4.95,3.769,4.941-3.759L62.1,69.128h-48Zm52.8-4.8-18.928-14.4L66.9,35.528v28.8Z"
                            transform="translate(-4.499 -1.928)"
                            fill="#07020f"
                            opacity="0.3"
                          />
                        </svg>
                        <h4>Wybierz wątek</h4>
                      </SvgEmptyMsgWrapper>
                    ) : (
                      `Tytuł: ${selectedMessageDetails.Topic}`
                    )}
                  </p>
                  {!isThreadWriteMode && threadMessages.length > 0 ? (
                    <SvgReplyAndRefreshWrapper>
                      {' '}
                      <svg
                        onClick={() =>
                          getThreadMessages(selectedMessageDetails.Thread)
                        }
                        fill="#3986ef"
                        height="17px"
                        width="19px"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 489.645 489.645"
                        stroke="#3986ef"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {' '}
                          <g>
                            {' '}
                            <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3 c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5 c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8 c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2 C414.856,432.511,548.256,314.811,460.656,132.911z"></path>{' '}
                          </g>{' '}
                        </g>
                      </svg>
                      {selectedMessageDetails.AllowResponse && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.429"
                          height="17"
                          viewBox="0 0 19.429 17"
                          onClick={() => {
                            setIsThreadWriteMode(true);
                            replyToTheMessage();
                          }}
                        >
                          <path
                            id="Icon_awesome-reply"
                            data-name="Icon awesome-reply"
                            d="M.315,8.239,6.994,2.472A.911.911,0,0,1,8.5,3.161V6.2c6.1.07,10.929,1.291,10.929,7.068a7.757,7.757,0,0,1-3.162,5.849.676.676,0,0,1-1.065-.707c1.721-5.5-.816-6.964-6.7-7.048V14.7a.911.911,0,0,1-1.506.689L.315,9.618a.911.911,0,0,1,0-1.379Z"
                            transform="translate(0 -2.25)"
                            fill="#3986EF"
                          />
                        </svg>
                      )}
                    </SvgReplyAndRefreshWrapper>
                  ) : null}
                </ThreadResponsIconWrapper>
              )}

              {(isWriteMode
                ? false
                : messagesType === MessagesType.RECEIVED &&
                  receivedMessages.length <= 0) ||
              (isWriteMode
                ? false
                : messagesType === MessagesType.SENT &&
                  sentMessages.length <= 0) ? (
                <SvgEmptyMsgWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="67.2"
                    height="72"
                    viewBox="0 0 67.2 72"
                  >
                    <path
                      id="Icon_metro-mail-read"
                      data-name="Icon metro-mail-read"
                      d="M47.7,30.728h-24v4.8h24Zm-14.4-9.6H23.7v4.8h9.6Zm28.8-2.06v-7.54H51.538L38.1,1.928l-13.439,9.6H14.1v7.54l-9.6,6.86v48H71.7v-48l-9.6-6.86Zm-43.2-2.74H57.3V34.689L38.1,50.528,18.9,34.689V16.328Zm-9.6,19.2L28.2,49.9,9.3,64.328v-28.8Zm4.8,33.6,19.05-15.469,4.95,3.769,4.941-3.759L62.1,69.128h-48Zm52.8-4.8-18.928-14.4L66.9,35.528v28.8Z"
                      transform="translate(-4.499 -1.928)"
                      fill="#07020f"
                      opacity="0.3"
                    />
                  </svg>
                  <h4>Skrzynka jest pusta!</h4>
                </SvgEmptyMsgWrapper>
              ) : isWriteMode ? (
                <>
                  <RightSectionHeaderWrapper isWriteMode={isWriteMode}>
                    <RightSectionIconsWrapper>
                      <div>
                        {!isWriteMode && (
                          <RightSectionSingleIcon>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="19.429"
                              height="17"
                              viewBox="0 0 19.429 17"
                              onClick={() => {
                                handleIsWriteMode(true);
                                replyToTheMessage();
                              }}
                            >
                              <path
                                id="Icon_awesome-reply"
                                data-name="Icon awesome-reply"
                                d="M.315,8.239,6.994,2.472A.911.911,0,0,1,8.5,3.161V6.2c6.1.07,10.929,1.291,10.929,7.068a7.757,7.757,0,0,1-3.162,5.849.676.676,0,0,1-1.065-.707c1.721-5.5-.816-6.964-6.7-7.048V14.7a.911.911,0,0,1-1.506.689L.315,9.618a.911.911,0,0,1,0-1.379Z"
                                transform="translate(0 -2.25)"
                                fill="#3986EF"
                              />
                            </svg>
                          </RightSectionSingleIcon>
                        )}
                        {!isWriteMode ? (
                          <RightSectionSingleIcon>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.3"
                              height="16.5"
                              viewBox="0 0 12.3 13.5"
                              onClick={() => {
                                handleIsReplyingChange(false);
                                // deleteMessage(
                                //   selectedMessageDetails.Id,
                                //   decodedToken.ProducerId,
                                // );
                              }}
                            >
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(0.75 0.75)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9H15.3"
                                  transform="translate(-4.5 -6.6)"
                                  fill="none"
                                  stroke="#3986EF"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                                  transform="translate(-6.3 -3)"
                                  fill="none"
                                  stroke="#3986EF"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                />
                              </g>
                            </svg>
                          </RightSectionSingleIcon>
                        ) : (
                          <RightSectionSingleIcon>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.3"
                              height="16.5"
                              viewBox="0 0 12.3 13.5"
                              onClick={() => {
                                clearFormData();
                              }}
                            >
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(0.75 0.75)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9H15.3"
                                  transform="translate(-4.5 -6.6)"
                                  fill="none"
                                  stroke="#3986EF"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                                  transform="translate(-6.3 -3)"
                                  fill="none"
                                  stroke="#3986EF"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                />
                              </g>
                            </svg>
                          </RightSectionSingleIcon>
                        )}
                      </div>
                    </RightSectionIconsWrapper>
                    {!isWriteMode ? (
                      <RightSectionAddressAndTitleWrapper>
                        <p>Nadawca</p>
                        <span>{selectedMessageDetails.SenderName}</span>
                      </RightSectionAddressAndTitleWrapper>
                    ) : (
                      <InputWithSearchResultsWrapper
                        onBlur={onBlur}
                        style={{marginBottom: '10px'}}
                      >
                        <RightSectionAddressAndTitleWriteMode
                          style={
                            receiverId !== ''
                              ? {
                                  borderColor: '#0091FF',
                                  background: 'rgba(0,0,0,0.15)',
                                }
                              : null
                          }
                        >
                          <p>Odbiorca</p>
                          <input
                            type="text"
                            placeholder="Pole obowiązkowe"
                            value={receiver}
                            disabled={state !== null}
                            onFocus={() => handleOnFocusChange(true)}
                            onChange={handleReceiverChange}
                          />
                        </RightSectionAddressAndTitleWriteMode>
                        <SearchResultWrapper
                          isFocused={isFocused}
                          autoCompleted={receiver.length > 1}
                          tabIndex={0}
                        >
                          {knowsUsers.filter((user) =>
                            user.Name.toLowerCase().includes(
                              receiver.toLowerCase(),
                            ),
                          ).length === 0 &&
                            receiver.length > 1 && (
                              <p style={{color: '#707070'}}>Brak wyników</p>
                            )}

                          {knowsUsers
                            .filter((user) =>
                              user.Name.toLowerCase().includes(
                                receiver.toLowerCase(),
                              ),
                            )
                            .map((user) => {
                              const {UserId, Name, PhotoFilePath} = user;

                              return (
                                <SingleSearchResultWrapper
                                  key={UserId}
                                  onClick={() => {
                                    handleReceiverIdChange(UserId);
                                    setReceiver(`${Name} `);
                                    toggleFocused(false);
                                  }}
                                >
                                  <p>{`${Name} `}</p>
                                </SingleSearchResultWrapper>
                              );
                            })}
                        </SearchResultWrapper>
                      </InputWithSearchResultsWrapper>
                    )}
                    {!isWriteMode ? (
                      <RightSectionAddressAndTitleWrapper>
                        <p>Temat</p>
                        <span>{selectedMessageDetails.Topic}</span>
                      </RightSectionAddressAndTitleWrapper>
                    ) : (
                      <RightSectionAddressAndTitleWriteMode>
                        <p>Temat</p>
                        <input
                          type="text"
                          placeholder="Pole obowiązkowe"
                          value={topic}
                          onChange={handleTopicChange}
                        />
                      </RightSectionAddressAndTitleWriteMode>
                    )}
                  </RightSectionHeaderWrapper>
                  <RightSectionMessageContent isWriteMode={isWriteMode}>
                    {/* <ContentTitle>Treść</ContentTitle> */}
                    {!isWriteMode ? (
                      <textarea
                        value={selectedMessageDetails.Content}
                        disabled
                      ></textarea>
                    ) : (
                      <textarea
                        placeholder="Wprowadź tekst..."
                        value={content}
                        onChange={handleContentChange}
                      />
                    )}
                  </RightSectionMessageContent>{' '}
                </>
              ) : (
                <RightSectionThreadMessagesWrapper>
                  {/* <ThreadResponsIconWrapper>
                    {' '}
                    <p>
                      {selectedMessageDetails.Topic === '' ? (
                        <SvgEmptyMsgWrapper>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="67.2"
                            height="72"
                            viewBox="0 0 67.2 72"
                          >
                            <path
                              id="Icon_metro-mail-read"
                              data-name="Icon metro-mail-read"
                              d="M47.7,30.728h-24v4.8h24Zm-14.4-9.6H23.7v4.8h9.6Zm28.8-2.06v-7.54H51.538L38.1,1.928l-13.439,9.6H14.1v7.54l-9.6,6.86v48H71.7v-48l-9.6-6.86Zm-43.2-2.74H57.3V34.689L38.1,50.528,18.9,34.689V16.328Zm-9.6,19.2L28.2,49.9,9.3,64.328v-28.8Zm4.8,33.6,19.05-15.469,4.95,3.769,4.941-3.759L62.1,69.128h-48Zm52.8-4.8-18.928-14.4L66.9,35.528v28.8Z"
                              transform="translate(-4.499 -1.928)"
                              fill="#07020f"
                              opacity="0.3"
                            />
                          </svg>
                          <h4>Wybierz wątek</h4>
                        </SvgEmptyMsgWrapper>
                      ) : (
                        `Tytuł: ${selectedMessageDetails.Topic}`
                      )}
                    </p>
                    {!isThreadWriteMode && threadMessages.length > 0 ? (
                      <SvgReplyAndRefreshWrapper>
                        {' '}
                        <svg
                          onClick={() =>
                            getThreadMessages(selectedMessageDetails.Thread)
                          }
                          fill="#3986ef"
                          height="17px"
                          width="19px"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 489.645 489.645"
                          stroke="#3986ef"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {' '}
                            <g>
                              {' '}
                              <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3 c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5 c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8 c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2 C414.856,432.511,548.256,314.811,460.656,132.911z"></path>{' '}
                            </g>{' '}
                          </g>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19.429"
                          height="17"
                          viewBox="0 0 19.429 17"
                          onClick={() => {
                            setIsThreadWriteMode(true);
                            replyToTheMessage();
                          }}
                        >
                          <path
                            id="Icon_awesome-reply"
                            data-name="Icon awesome-reply"
                            d="M.315,8.239,6.994,2.472A.911.911,0,0,1,8.5,3.161V6.2c6.1.07,10.929,1.291,10.929,7.068a7.757,7.757,0,0,1-3.162,5.849.676.676,0,0,1-1.065-.707c1.721-5.5-.816-6.964-6.7-7.048V14.7a.911.911,0,0,1-1.506.689L.315,9.618a.911.911,0,0,1,0-1.379Z"
                            transform="translate(0 -2.25)"
                            fill="#3986EF"
                          />
                        </svg>
                      </SvgReplyAndRefreshWrapper>
                    ) : null}
                  </ThreadResponsIconWrapper> */}
                  {isThreadWriteMode ? (
                    <ThreadResponseWrappers ref={threadRef} id="threadResponse">
                      <ThreadResponseHeaderNameAndImg>
                        {producerData.FtpPhotoFilePath === null &&
                        userData.ImagePath === null ? (
                          defaultAvatar
                        ) : (
                          <img
                            src={
                              producerData.Id.length > 1
                                ? producerData.FtpPhotoFilePath
                                : userData.ImagePath
                            }
                            alt="ProducerAvatar"
                          />
                        )}
                        <p>
                          {producerData.Id.length > 1
                            ? producerData.Name === ''
                              ? `${producerData.FirstName} ${producerData.LastName}`
                              : `${producerData.Name}`
                            : `${userData.FirstName} ${userData.LastName}`}
                        </p>
                      </ThreadResponseHeaderNameAndImg>
                      <ThreadResponseContent>
                        <textarea
                          placeholder="Wprowadź tekst..."
                          value={content}
                          onChange={handleContentChange}
                        />
                      </ThreadResponseContent>
                      <ThreadResponseButtonsWrapper>
                        <button
                          onClick={() => {
                            setIsThreadWriteMode(false);
                            setContent('');
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14.833"
                            height="16.315"
                            viewBox="0 0 14.833 16.315"
                          >
                            <g
                              id="Icon_feather-trash"
                              data-name="Icon feather-trash"
                              transform="translate(0.75 0.75)"
                            >
                              <path
                                id="Path_3663"
                                data-name="Path 3663"
                                d="M4.5,9H17.833"
                                transform="translate(-4.5 -6.037)"
                                fill="none"
                                stroke="#f8f8f8"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_3664"
                                data-name="Path 3664"
                                d="M17.87,5.963v10.37a1.481,1.481,0,0,1-1.481,1.481H8.981A1.481,1.481,0,0,1,7.5,16.333V5.963m2.222,0V4.481A1.481,1.481,0,0,1,11.2,3h2.963a1.481,1.481,0,0,1,1.481,1.481V5.963"
                                transform="translate(-6.019 -3)"
                                fill="none"
                                stroke="#f8f8f8"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </button>
                        <button
                          onClick={() => {
                            sendMessage(false);
                            setIsThreadWriteMode(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.414"
                            height="20.414"
                            viewBox="0 0 20.414 20.414"
                          >
                            <g
                              id="wyslij_wiadomosc"
                              transform="translate(-2 -1.586)"
                            >
                              <path
                                id="Path_3733"
                                data-name="Path 3733"
                                d="M26.4,3l-9.9,9.9"
                                transform="translate(-5.4 0)"
                                fill="none"
                                stroke="#f8f8f8"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                id="Path_3734"
                                data-name="Path 3734"
                                d="M21,3,14.7,21l-3.6-8.1L3,9.3Z"
                                transform="translate(0 0)"
                                fill="none"
                                stroke="#f8f8f8"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </g>
                          </svg>
                        </button>
                      </ThreadResponseButtonsWrapper>
                    </ThreadResponseWrappers>
                  ) : null}
                  {threadMessages.map((message, index) => (
                    <ThreadMessageWrapper
                      isFirst={index === 0}
                      key={message.Id}
                      isReciever={message.SenderId === userData.UserId}
                    >
                      <ThreadMessageHeader>
                        <ThreadMessageHeaderNameAndImg>
                          {message.SenderPhotoFilePath !== null &&
                          message.SenderPhotoFilePath !== '' ? (
                            <img src={message.SenderPhotoFilePath} alt="" />
                          ) : (
                            defaultAvatar
                          )}
                          <p>{message.SenderName}</p>
                        </ThreadMessageHeaderNameAndImg>
                        <ThreadMessageHeaderIcons>
                          <p>
                            {new Intl.DateTimeFormat('default', {
                              hour: 'numeric',
                              minute: 'numeric',
                              second: 'numeric',
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })
                              .format(addTwoHours(message.SentDate))
                              .replace(',', '')}
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.3"
                            height="16.5"
                            viewBox="0 0 12.3 13.5"
                            onClick={() => {
                              handleIsReplyingChange(false);
                              deleteMessage(
                                message.Id,
                                decodedToken.ProducerId,
                              );
                            }}
                          >
                            <g
                              id="Icon_feather-trash"
                              data-name="Icon feather-trash"
                              transform="translate(0.75 0.75)"
                            >
                              <path
                                id="Path_3663"
                                data-name="Path 3663"
                                d="M4.5,9H15.3"
                                transform="translate(-4.5 -6.6)"
                                fill="none"
                                stroke="#3986EF"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                              <path
                                id="Path_3664"
                                data-name="Path 3664"
                                d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                                transform="translate(-6.3 -3)"
                                fill="none"
                                stroke="#3986EF"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                              />
                            </g>
                          </svg>
                        </ThreadMessageHeaderIcons>
                      </ThreadMessageHeader>
                      <ThreadMessageContent>
                        {message.Content}
                      </ThreadMessageContent>
                    </ThreadMessageWrapper>
                  ))}
                </RightSectionThreadMessagesWrapper>
              )}
            </RightSection>
          </Flex>
        </Main>
      </MainWrapper>
      <MobileMainWrapper>
        <MobileMain>
          <MobileLeftSection
            isWriteMode={isWriteMode}
            // isMenuOpen={isMobileNewMessageViewOpen || isThreadViewAActiveMobile}
          >
            <LeftSectionButtonWrapper>
              <LeftSectionButton
                value={MessagesType.RECEIVED}
                messagesType={messagesType}
                onClick={() => {
                  handleMessagesTypeChange(MessagesType.RECEIVED);
                  selectFirstReceivedMessage();
                }}
              >
                Odebrane
              </LeftSectionButton>
              <LeftSectionButton
                value={MessagesType.SENT}
                messagesType={messagesType}
                onClick={() => {
                  handleMessagesTypeChange(MessagesType.SENT);
                  selectFirstSentMessage();
                }}
              >
                Wysłane
              </LeftSectionButton>
            </LeftSectionButtonWrapper>
            <LeftSectionSearchWrapper>
              {messagesType === MessagesType.RECEIVED ? (
                <SearchBarWrapper>
                  <input
                    type="text"
                    placeholder="Wyszukaj po tytule..."
                    value={receivedSearchString}
                    onChange={handleReceivedSearchStringChange}
                  />
                  <SearchButton>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.146"
                      height="14"
                      viewBox="0 0 14.146 14"
                    >
                      <path
                        id="Path_1507"
                        data-name="Path 1507"
                        d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                        transform="translate(-2587.94 -535.001)"
                        fill="#fff"
                      />
                    </svg>
                  </SearchButton>
                </SearchBarWrapper>
              ) : (
                // <InputWrapper>
                //   <svg
                //     xmlns="http://www.w3.org/2000/svg"
                //     width="11.115"
                //     height="11"
                //     viewBox="0 0 11.115 11"
                //   >
                //     <g id="Group_3812" data-name="Group 3812" opacity="1">
                //       <path
                //         id="Path_1507"
                //         data-name="Path 1507"
                //         d="M2598.85,544.913l-2.765-2.52a4.572,4.572,0,0,0-.352-6.057h0a4.57,4.57,0,1,0-.552,6.924l2.828,2.577a.625.625,0,1,0,.843-.924Zm-8.876-2.818a3.578,3.578,0,1,1,2.529,1.048A3.581,3.581,0,0,1,2589.974,542.1Z"
                //         transform="translate(-2587.94 -535.001)"
                //         fill="#352F40"
                //       />
                //     </g>
                //   </svg>
                //   <input
                //     placeholder="Wyszukaj po tytule..."
                //     value={receivedSearchString}
                //     onChange={handleReceivedSearchStringChange}
                //   />
                // </InputWrapper>
                <SearchBarWrapper>
                  <input
                    type="text"
                    placeholder="Wyszukaj po tytule..."
                    value={sentSearchString}
                    onChange={handleSentSearchStringChange}
                  />
                  <SearchButton>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.146"
                      height="14"
                      viewBox="0 0 14.146 14"
                    >
                      <path
                        id="Path_1507"
                        data-name="Path 1507"
                        d="M2601.826,547.617l-3.519-3.207a5.82,5.82,0,0,0-.448-7.709h0a5.816,5.816,0,1,0-.7,8.813l3.6,3.28a.8.8,0,1,0,1.072-1.177Zm-11.3-3.586a4.554,4.554,0,1,1,3.219,1.333A4.559,4.559,0,0,1,2590.529,544.03Z"
                        transform="translate(-2587.94 -535.001)"
                        fill="#fff"
                      />
                    </svg>
                  </SearchButton>
                </SearchBarWrapper>
                // <InputWrapper>
                //   <svg
                //     xmlns="http://www.w3.org/2000/svg"
                //     width="11.115"
                //     height="11"
                //     viewBox="0 0 11.115 11"
                //   >
                //     <g id="Group_3812" data-name="Group 3812" opacity="1">
                //       <path
                //         id="Path_1507"
                //         data-name="Path 1507"
                //         d="M2598.85,544.913l-2.765-2.52a4.572,4.572,0,0,0-.352-6.057h0a4.57,4.57,0,1,0-.552,6.924l2.828,2.577a.625.625,0,1,0,.843-.924Zm-8.876-2.818a3.578,3.578,0,1,1,2.529,1.048A3.581,3.581,0,0,1,2589.974,542.1Z"
                //         transform="translate(-2587.94 -535.001)"
                //         fill="#352F40"
                //       />
                //     </g>
                //   </svg>
                //   <input
                //     placeholder="Wyszukaj po tytule..."
                //     value={sentSearchString}
                //     onChange={handleSentSearchStringChange}
                //   />
                // </InputWrapper>
              )}
              <RefreshButton>
                <svg
                  onClick={() => {
                    clearSelectedMessage();
                    clearFormData();
                    handleIsReplyingChange(false);
                    handleIsWriteMode(true);
                    setIsBodyOverflowBlocked(true);
                    handleIsMobileNewMessageViewOpen(true);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path
                    id="Icon_material-open-in-new"
                    data-name="Icon material-open-in-new"
                    d="M20.5,20.5H6.5V6.5h7v-2h-7a2,2,0,0,0-2,2v14a2,2,0,0,0,2,2h14a2.006,2.006,0,0,0,2-2v-7h-2Zm-5-16v2h3.59L9.26,16.33l1.41,1.41L20.5,7.91V11.5h2v-7Z"
                    transform="translate(-4.5 -4.5)"
                    fill="#06f"
                  />
                </svg>
              </RefreshButton>
            </LeftSectionSearchWrapper>
            <LeftSectionMessagesWrapper>
              {messagesType === MessagesType.RECEIVED
                ? receivedMessages
                    // .filter((message) => message.RecieverStatus.FlagValue !== 2)
                    .map((message) => {
                      const {
                        Content,
                        Id,
                        RecieverId,
                        RecieverName,
                        SenderId,
                        SenderName,
                        SentDate,
                        RecieverStatus,
                        SenderStatus,
                        Topic,
                        SenderPhotoFilePath,
                        Thread,
                      } = message;
                      const createdOn = new Date(SentDate);
                      const today = new Date();
                      const msInDay = 24 * 60 * 60 * 1000;
                      const daysAgo = Math.round(
                        (+today - +createdOn) / msInDay,
                      );

                      return (
                        <LeftSectionMessageWrapper
                          isActive={Id === selectedMessageDetails.Id}
                          key={Id}
                          onClick={() => {
                            handleIsReplyingChange(false);
                            setSelectedMessageDetails(message);
                            markMessageAsSeen(Thread, RecieverStatus.FlagValue);
                            getThreadMessages(Thread);
                            setIsThreadViewAActiveMobile(true);
                          }}
                        >
                          <LeftSectionMessageHeader>
                            {SenderPhotoFilePath !== null &&
                            SenderPhotoFilePath !== '' ? (
                              <img src={SenderPhotoFilePath} alt={SenderName} />
                            ) : (
                              defaultAvatar
                            )}
                            <div>
                              {RecieverStatus.FlagValue === 0 && <span />}
                              <p>
                                <span>
                                  {SenderName}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8.358"
                                    height="8"
                                    viewBox="0 0 8.358 8"
                                  >
                                    <path
                                      id="Icon_awesome-star"
                                      data-name="Icon awesome-star"
                                      d="M5.172.277,4.152,2.346l-2.283.333a.5.5,0,0,0-.277.853L3.244,5.141,2.854,7.414a.5.5,0,0,0,.725.526L5.621,6.867,7.662,7.941a.5.5,0,0,0,.725-.526L8,5.141,9.648,3.532a.5.5,0,0,0-.277-.853L7.089,2.346,6.069.277A.5.5,0,0,0,5.172.277Z"
                                      transform="translate(-1.441 0.001)"
                                      opacity="0.7"
                                    />
                                  </svg>
                                </span>
                              </p>
                              <MobileTopicWrapper>
                                <p>{Topic}</p>
                              </MobileTopicWrapper>
                            </div>
                            <span>
                              {daysAgo === 0
                                ? 'Dzisiaj'
                                : daysAgo === 1
                                ? 'Wczoraj'
                                : `${daysAgo} dni temu`}
                            </span>
                            <svg
                              style={{
                                color: 'black',
                                position: 'absolute',
                                right: '5px',
                                top: '9px',
                                fontSize: '9px',
                                height: '11px',
                                borderRadius: '0px',
                                width: '11px',
                                cursor: 'pointer',
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.3"
                              height="16.5"
                              viewBox="0 0 12.3 13.5"
                              onClick={(e) => {
                                // handleIsReplyingChange(false);
                                deleteThread(e, Thread, productId);
                                e.preventDefault();
                              }}
                            >
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(0.75 0.75)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9H15.3"
                                  transform="translate(-4.5 -6.6)"
                                  fill="none"
                                  stroke="#3986EF"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                                  transform="translate(-6.3 -3)"
                                  fill="none"
                                  stroke="#3986EF"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                />
                              </g>
                            </svg>
                          </LeftSectionMessageHeader>
                          <LeftSectionMessageContentWrapper
                            className="LeftSectionMessageContentWrapper"
                            isActive={Id === selectedMessageDetails.Id}
                          >
                            <p>{Content}</p>
                          </LeftSectionMessageContentWrapper>
                        </LeftSectionMessageWrapper>
                      );
                    })
                : sentMessages
                    // .filter((message) => message.SenderStatus.FlagValue !== 2)
                    .map((message) => {
                      const {
                        Content,
                        Id,
                        RecieverId,
                        RecieverName,
                        SenderId,
                        SenderName,
                        SentDate,
                        RecieverStatus,
                        SenderStatus,
                        Topic,
                        RecieverPhotoFilePath,
                        Thread,
                      } = message;
                      const createdOn = new Date(SentDate);
                      const today = new Date();
                      const msInDay = 24 * 60 * 60 * 1000;
                      const daysAgo = Math.round(
                        (+today - +createdOn) / msInDay,
                      );

                      return (
                        <LeftSectionMessageWrapper
                          isActive={Id === selectedMessageDetails.Id}
                          key={Id}
                          onClick={() => {
                            handleIsReplyingChange(false);
                            setSelectedMessageDetails(message);
                            markMessageAsSeen(Thread, SenderStatus.FlagValue);
                            getThreadMessages(Thread);
                            setIsThreadViewAActiveMobile(true);
                            // setIsBodyOverflowBlocked(true);
                            // handleIsMobileMessageViewOpen(true);
                          }}
                        >
                          <LeftSectionMessageHeader>
                            {RecieverPhotoFilePath !== null &&
                            RecieverPhotoFilePath !== '' ? (
                              <img
                                src={RecieverPhotoFilePath}
                                alt={RecieverName}
                              />
                            ) : (
                              defaultAvatar
                            )}
                            <div>
                              {/* {SenderStatus.FlagValue === 0 && <span />} */}
                              <p>
                                <span>
                                  {RecieverName}{' '}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8.358"
                                    height="8"
                                    viewBox="0 0 8.358 8"
                                  >
                                    <path
                                      id="Icon_awesome-star"
                                      data-name="Icon awesome-star"
                                      d="M5.172.277,4.152,2.346l-2.283.333a.5.5,0,0,0-.277.853L3.244,5.141,2.854,7.414a.5.5,0,0,0,.725.526L5.621,6.867,7.662,7.941a.5.5,0,0,0,.725-.526L8,5.141,9.648,3.532a.5.5,0,0,0-.277-.853L7.089,2.346,6.069.277A.5.5,0,0,0,5.172.277Z"
                                      transform="translate(-1.441 0.001)"
                                      opacity="0.7"
                                    />
                                  </svg>
                                </span>
                              </p>

                              <MobileTopicWrapper>
                                <p>{Topic}</p>
                              </MobileTopicWrapper>
                              {/* <span
                                  style={{
                                    fontWeight: 'bold',
                                    lineHeight: '11px',
                                    opacity: '0.5',
                                  }}
                                >
                                  {Topic} 
                                </span> */}
                            </div>
                            <span>
                              {daysAgo === 0
                                ? 'Dzisiaj'
                                : daysAgo === 1
                                ? 'Wczoraj'
                                : `${daysAgo} dni temu`}
                            </span>
                            <svg
                              style={{
                                color: 'black',
                                position: 'absolute',
                                right: '5px',
                                top: '9px',
                                height: '11px',
                                borderRadius: '0px',
                                width: '11px',
                                cursor: 'pointer',
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16.3"
                              height="16.5"
                              viewBox="0 0 12.3 13.5"
                              onClick={(e) => {
                                // handleIsReplyingChange(false);
                                deleteThread(e, Thread, productId);
                                e.preventDefault();
                              }}
                            >
                              <g
                                id="Icon_feather-trash"
                                data-name="Icon feather-trash"
                                transform="translate(0.75 0.75)"
                              >
                                <path
                                  id="Path_3663"
                                  data-name="Path 3663"
                                  d="M4.5,9H15.3"
                                  transform="translate(-4.5 -6.6)"
                                  fill="none"
                                  stroke="#3986EF"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                />
                                <path
                                  id="Path_3664"
                                  data-name="Path 3664"
                                  d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                                  transform="translate(-6.3 -3)"
                                  fill="none"
                                  stroke="#3986EF"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                />
                              </g>
                            </svg>
                          </LeftSectionMessageHeader>
                          <LeftSectionMessageContentWrapper
                            isActive={Id === selectedMessageDetails.Id}
                          >
                            <div />
                            <p>{Content}</p>
                          </LeftSectionMessageContentWrapper>
                        </LeftSectionMessageWrapper>
                      );
                    })}
            </LeftSectionMessagesWrapper>
          </MobileLeftSection>
        </MobileMain>
        <MenuMain
          isMenuOpen={isMobileMessageViewOpen}
          ref={mobileMessageViewWindowRef}
        >
          <CloseIsLoginPageOpenWrapper>
            Wiadomość
            <CloseIsLoginPageOpen
              onClick={() => {
                handleIsMobileMessageViewOpen(false);
                setIsBodyOverflowBlocked(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.625"
                height="16.625"
                viewBox="0 0 16.625 16.625"
              >
                <path
                  id="Path_3807"
                  data-name="Path 3807"
                  d="M27.313,24.987l-2.326,2.326L19,21.326l-5.987,5.987-2.326-2.326L16.674,19l-5.987-5.987,2.326-2.326L19,16.674l5.987-5.987,2.326,2.326L21.326,19Z"
                  transform="translate(-10.688 -10.688)"
                  fill="#07020f"
                />
              </svg>
            </CloseIsLoginPageOpen>
          </CloseIsLoginPageOpenWrapper>
          <MobileIconsWrapper>
            <svg
              onClick={() => {
                handleIsReplyingChange(false);
                // deleteMessage(
                //   selectedMessageDetails.Id,
                //   decodedToken.ProducerId,
                // );

                handleIsMobileMessageViewOpen(false);
                handleIsMobileNewMessageViewOpen(false);
                setIsBodyOverflowBlocked(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="14.833"
              height="16.315"
              viewBox="0 0 14.833 16.315"
            >
              <g
                id="Icon_feather-trash"
                data-name="Icon feather-trash"
                transform="translate(0.75 0.75)"
              >
                <path
                  id="Path_3663"
                  data-name="Path 3663"
                  d="M4.5,9H17.833"
                  transform="translate(-4.5 -6.037)"
                  fill="none"
                  stroke="#3986ef"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <path
                  id="Path_3664"
                  data-name="Path 3664"
                  d="M17.87,5.963v10.37a1.481,1.481,0,0,1-1.481,1.481H8.981A1.481,1.481,0,0,1,7.5,16.333V5.963m2.222,0V4.481A1.481,1.481,0,0,1,11.2,3h2.963a1.481,1.481,0,0,1,1.481,1.481V5.963"
                  transform="translate(-6.019 -3)"
                  fill="none"
                  stroke="#3986ef"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </g>
            </svg>
            <svg
              onClick={() => {
                handleIsWriteMode(true);
                replyToTheMessage();
                handleIsMobileMessageViewOpen(false);
                handleIsMobileNewMessageViewOpen(true);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="23.986"
              height="20.988"
              viewBox="0 0 23.986 20.988"
            >
              <path
                id="Icon_awesome-reply"
                data-name="Icon awesome-reply"
                d="M.389,9.644l8.245-7.12a1.125,1.125,0,0,1,1.859.851v3.75c7.525.086,13.492,1.594,13.492,8.726a9.577,9.577,0,0,1-3.9,7.221.835.835,0,0,1-1.315-.873c2.124-6.793-1.008-8.6-8.273-8.7v4.119a1.125,1.125,0,0,1-1.859.851L.389,11.346a1.125,1.125,0,0,1,0-1.7Z"
                transform="translate(0 -2.25)"
                fill="#3986ef"
              />
            </svg>
          </MobileIconsWrapper>
          <MenuWrapper>
            <RightSectionAddressAndTitleWrapper>
              <p>Nadawca</p>
              <span style={{borderBottom: 'none'}}>
                {selectedMessageDetails.SenderName}
              </span>
            </RightSectionAddressAndTitleWrapper>
            <RightSectionAddressAndTitleWrapper>
              <p>Temat</p>
              <span style={{borderBottom: 'none'}}>
                {selectedMessageDetails.Topic}
              </span>
            </RightSectionAddressAndTitleWrapper>
            <RightSectionMessageContent isWriteMode={isWriteMode}>
              {/* <ContentTitle>Treść</ContentTitle> */}
              {!isWriteMode ? (
                <textarea>{selectedMessageDetails.Content}</textarea>
              ) : (
                <textarea
                  placeholder="Wprowadź tekst..."
                  value={content}
                  onChange={handleContentChange}
                />
              )}
            </RightSectionMessageContent>
          </MenuWrapper>
        </MenuMain>

        <MenuMain
          isMenuOpen={isMobileNewMessageViewOpen}
          ref={mobileNewMessageViewWindowRef}
        >
          <CloseIsLoginPageOpenWrapper>
            Nowa wiadomość
            <CloseIsLoginPageOpen
              onClick={() => {
                handleIsMobileNewMessageViewOpen(false);
                setIsBodyOverflowBlocked(false);
                handleIsWriteMode(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.625"
                height="16.625"
                viewBox="0 0 16.625 16.625"
              >
                <path
                  id="Path_3807"
                  data-name="Path 3807"
                  d="M27.313,24.987l-2.326,2.326L19,21.326l-5.987,5.987-2.326-2.326L16.674,19l-5.987-5.987,2.326-2.326L19,16.674l5.987-5.987,2.326,2.326L21.326,19Z"
                  transform="translate(-10.688 -10.688)"
                  fill="#07020f"
                />
              </svg>
            </CloseIsLoginPageOpen>
          </CloseIsLoginPageOpenWrapper>
          <MobileIconsWrapper>
            <svg
              onClick={() => {
                clearFormData();
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="14.833"
              height="16.315"
              viewBox="0 0 14.833 16.315"
            >
              <g
                id="Icon_feather-trash"
                data-name="Icon feather-trash"
                transform="translate(0.75 0.75)"
              >
                <path
                  id="Path_3663"
                  data-name="Path 3663"
                  d="M4.5,9H17.833"
                  transform="translate(-4.5 -6.037)"
                  fill="none"
                  stroke="#3986ef"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <path
                  id="Path_3664"
                  data-name="Path 3664"
                  d="M17.87,5.963v10.37a1.481,1.481,0,0,1-1.481,1.481H8.981A1.481,1.481,0,0,1,7.5,16.333V5.963m2.222,0V4.481A1.481,1.481,0,0,1,11.2,3h2.963a1.481,1.481,0,0,1,1.481,1.481V5.963"
                  transform="translate(-6.019 -3)"
                  fill="none"
                  stroke="#3986ef"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
              </g>
            </svg>
            <svg
              onClick={() => {
                sendMessage(true);
                handleIsReplyingChange(false);
                handleIsMobileNewMessageViewOpen(false);
                setIsBodyOverflowBlocked(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="23.414"
              height="23.414"
              viewBox="0 0 23.414 23.414"
            >
              <g
                id="Icon_feather-send"
                data-name="Icon feather-send"
                transform="translate(-2 -1.586)"
              >
                <path
                  id="Path_3909"
                  data-name="Path 3909"
                  d="M28.05,3,16.5,14.55"
                  transform="translate(-4.05)"
                  fill="none"
                  stroke="#06f"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_3910"
                  data-name="Path 3910"
                  d="M24,3,16.65,24l-4.2-9.45L3,10.35Z"
                  fill="none"
                  stroke="#06f"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
              </g>
            </svg>
          </MobileIconsWrapper>
          <MenuWrapper style={{overflow: 'unset'}}>
            <InputWithSearchResultsWrapper
              onBlur={onBlur}
              style={{marginBottom: '10px'}}
            >
              <RightSectionAddressAndTitleWriteMode>
                <p>Odbiorca</p>
                <input
                  type="text"
                  placeholder="Pole obowiązkowe"
                  value={receiver}
                  onFocus={() => handleOnFocusChange(true)}
                  onChange={handleReceiverChange}
                />
              </RightSectionAddressAndTitleWriteMode>
              <SearchResultWrapper
                isFocused={isFocused}
                autoCompleted={receiver.length > 1}
                tabIndex={0}
              >
                {knowsUsers.filter((user) =>
                  user.Name.toLowerCase().includes(receiver.toLowerCase()),
                ).length === 0 &&
                  receiver.length > 1 && (
                    <p style={{color: '#707070'}}>Brak wyników</p>
                  )}

                {knowsUsers
                  .filter((user) =>
                    user.Name.toLowerCase().includes(receiver.toLowerCase()),
                  )
                  .map((user) => {
                    const {UserId, Name, PhotoFilePath} = user;

                    return (
                      <SingleSearchResultWrapper
                        key={UserId}
                        onClick={() => {
                          handleReceiverIdChange(UserId);
                          setReceiver(`${Name} `);
                          toggleFocused(false);
                        }}
                      >
                        <p>{`${Name} `}</p>
                      </SingleSearchResultWrapper>
                    );
                  })}
              </SearchResultWrapper>
            </InputWithSearchResultsWrapper>
            <RightSectionAddressAndTitleWriteMode>
              <p>Temat</p>
              <input
                type="text"
                placeholder="Pole obowiązkowe"
                value={topic}
                onChange={handleTopicChange}
              />
            </RightSectionAddressAndTitleWriteMode>
            <RightSectionMessageContent isWriteMode={isWriteMode}>
              {/* <ContentTitle style={{position: 'unset'}}>Treść</ContentTitle> */}
              {!isWriteMode ? (
                <textarea
                  value={selectedMessageDetails.Content}
                  disabled
                ></textarea>
              ) : (
                <textarea
                  placeholder="Wprowadź tekst..."
                  value={content}
                  onChange={handleContentChange}
                />
              )}
            </RightSectionMessageContent>
          </MenuWrapper>
        </MenuMain>

        <MenuMain
          isMenuOpen={isThreadViewAActiveMobile}
          ref={mobileThreadViewWindowRef}
        >
          <CloseIsLoginPageOpenWrapper>
            Wątek
            <CloseIsLoginPageOpen
              onClick={() => {
                setIsThreadViewAActiveMobile(false);
                setIsBodyOverflowBlocked(false);
                handleIsWriteMode(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.625"
                height="16.625"
                viewBox="0 0 16.625 16.625"
              >
                <path
                  id="Path_3807"
                  data-name="Path 3807"
                  d="M27.313,24.987l-2.326,2.326L19,21.326l-5.987,5.987-2.326-2.326L16.674,19l-5.987-5.987,2.326-2.326L19,16.674l5.987-5.987,2.326,2.326L21.326,19Z"
                  transform="translate(-10.688 -10.688)"
                  fill="#07020f"
                />
              </svg>
            </CloseIsLoginPageOpen>
          </CloseIsLoginPageOpenWrapper>
          <RightSectionThreadMessagesWrapper>
            <ThreadResponsIconWrapper>
              {' '}
              <p>
                {selectedMessageDetails.Topic === ''
                  ? null
                  : `Tytuł: ${selectedMessageDetails.Topic}`}
              </p>
              {!isThreadWriteMode && threadMessages.length > 0 ? (
                <SvgReplyAndRefreshWrapper>
                  {' '}
                  <svg
                    onClick={() =>
                      getThreadMessages(selectedMessageDetails.Thread)
                    }
                    fill="#3986ef"
                    height="17px"
                    width="19px"
                    version="1.1"
                    id="Capa_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 489.645 489.645"
                    stroke="#3986ef"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {' '}
                      <g>
                        {' '}
                        <path d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3 c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5 c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8 c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2 C414.856,432.511,548.256,314.811,460.656,132.911z"></path>{' '}
                      </g>{' '}
                    </g>
                  </svg>
                  {selectedMessageDetails.AllowResponse && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.429"
                      height="17"
                      viewBox="0 0 19.429 17"
                      onClick={() => {
                        setIsThreadWriteMode(true);
                        replyToTheMessage();
                      }}
                    >
                      <path
                        id="Icon_awesome-reply"
                        data-name="Icon awesome-reply"
                        d="M.315,8.239,6.994,2.472A.911.911,0,0,1,8.5,3.161V6.2c6.1.07,10.929,1.291,10.929,7.068a7.757,7.757,0,0,1-3.162,5.849.676.676,0,0,1-1.065-.707c1.721-5.5-.816-6.964-6.7-7.048V14.7a.911.911,0,0,1-1.506.689L.315,9.618a.911.911,0,0,1,0-1.379Z"
                        transform="translate(0 -2.25)"
                        fill="#3986EF"
                      />
                    </svg>
                  )}
                </SvgReplyAndRefreshWrapper>
              ) : null}
            </ThreadResponsIconWrapper>
            {isThreadWriteMode ? (
              <ThreadResponseWrappers>
                <ThreadResponseHeaderNameAndImg>
                  {producerData.FtpPhotoFilePath === null &&
                  userData.ImagePath === null ? (
                    defaultAvatar
                  ) : (
                    <img
                      src={
                        producerData.Id.length > 1
                          ? producerData.FtpPhotoFilePath
                          : userData.ImagePath
                      }
                      alt="ProducerAvatar"
                    />
                  )}
                  <p>
                    {producerData.Id.length > 1
                      ? producerData.Name === ''
                        ? `${producerData.FirstName} ${producerData.LastName}`
                        : `${producerData.Name}`
                      : `${userData.FirstName} ${userData.LastName}`}
                  </p>
                </ThreadResponseHeaderNameAndImg>
                <ThreadResponseContent>
                  <textarea
                    placeholder="Wprowadź tekst..."
                    value={content}
                    onChange={handleContentChange}
                  />
                </ThreadResponseContent>
                <ThreadResponseButtonsWrapper>
                  <button
                    onClick={() => {
                      setIsThreadWriteMode(false);
                      setContent('');
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.833"
                      height="16.315"
                      viewBox="0 0 14.833 16.315"
                    >
                      <g
                        id="Icon_feather-trash"
                        data-name="Icon feather-trash"
                        transform="translate(0.75 0.75)"
                      >
                        <path
                          id="Path_3663"
                          data-name="Path 3663"
                          d="M4.5,9H17.833"
                          transform="translate(-4.5 -6.037)"
                          fill="none"
                          stroke="#f8f8f8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                        <path
                          id="Path_3664"
                          data-name="Path 3664"
                          d="M17.87,5.963v10.37a1.481,1.481,0,0,1-1.481,1.481H8.981A1.481,1.481,0,0,1,7.5,16.333V5.963m2.222,0V4.481A1.481,1.481,0,0,1,11.2,3h2.963a1.481,1.481,0,0,1,1.481,1.481V5.963"
                          transform="translate(-6.019 -3)"
                          fill="none"
                          stroke="#f8f8f8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      sendMessage(false);
                      setIsThreadWriteMode(false);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20.414"
                      height="20.414"
                      viewBox="0 0 20.414 20.414"
                    >
                      <g id="wyslij_wiadomosc" transform="translate(-2 -1.586)">
                        <path
                          id="Path_3733"
                          data-name="Path 3733"
                          d="M26.4,3l-9.9,9.9"
                          transform="translate(-5.4 0)"
                          fill="none"
                          stroke="#f8f8f8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_3734"
                          data-name="Path 3734"
                          d="M21,3,14.7,21l-3.6-8.1L3,9.3Z"
                          transform="translate(0 0)"
                          fill="none"
                          stroke="#f8f8f8"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                  </button>
                </ThreadResponseButtonsWrapper>
              </ThreadResponseWrappers>
            ) : null}
            <ThreadMobileScrollWrapper>
              {threadMessages.map((message, index) => (
                <ThreadMessageWrapper
                  isFirst={index === 0}
                  key={message.Id}
                  isReciever={message.SenderId === userData.UserId}
                >
                  <ThreadMessageHeader>
                    <ThreadMessageHeaderNameAndImg>
                      {message.SenderPhotoFilePath !== null &&
                      message.SenderPhotoFilePath !== '' ? (
                        <img src={message.SenderPhotoFilePath} alt="" />
                      ) : (
                        defaultAvatar
                      )}
                      <p>{message.SenderName}</p>
                    </ThreadMessageHeaderNameAndImg>
                    <ThreadMessageHeaderIcons>
                      <p>
                        {new Intl.DateTimeFormat('default', {
                          hour: 'numeric',
                          minute: 'numeric',
                          second: 'numeric',
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        })
                          .format(addTwoHours(message.SentDate))
                          .replace(',', '')}
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.3"
                        height="16.5"
                        viewBox="0 0 12.3 13.5"
                        onClick={() => {
                          handleIsReplyingChange(false);
                          deleteMessage(message.Id, decodedToken.ProducerId);
                        }}
                      >
                        <g
                          id="Icon_feather-trash"
                          data-name="Icon feather-trash"
                          transform="translate(0.75 0.75)"
                        >
                          <path
                            id="Path_3663"
                            data-name="Path 3663"
                            d="M4.5,9H15.3"
                            transform="translate(-4.5 -6.6)"
                            fill="none"
                            stroke="#3986EF"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                          <path
                            id="Path_3664"
                            data-name="Path 3664"
                            d="M15.9,5.4v8.4A1.2,1.2,0,0,1,14.7,15h-6a1.2,1.2,0,0,1-1.2-1.2V5.4m1.8,0V4.2A1.2,1.2,0,0,1,10.5,3h2.4a1.2,1.2,0,0,1,1.2,1.2V5.4"
                            transform="translate(-6.3 -3)"
                            fill="none"
                            stroke="#3986EF"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                          />
                        </g>
                      </svg>
                    </ThreadMessageHeaderIcons>
                  </ThreadMessageHeader>
                  <RightSectionMessageContent>
                    {message.Content}
                  </RightSectionMessageContent>
                </ThreadMessageWrapper>
              ))}
            </ThreadMobileScrollWrapper>
          </RightSectionThreadMessagesWrapper>
        </MenuMain>
      </MobileMainWrapper>
    </>
  );
};
