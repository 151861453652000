import React from 'react';
import {ImgColumnFullImgWrapper, ImgColumnWrapper} from './styles';
import {BlockFile} from '../../../interface';
import styled from 'styled-components';

const ImgColumnLastImgWrapper = styled.div<{Width: number}>`
  flex-grow: 1;
  width: 305px;
  border-radius: 13px;
  & > img {
    border-radius: 13px;
    height: 305px;
    width: 305px;
  }
  @media (max-width: 425px) {
    width: 387px;
    min-height: 387px;
    height: 387px;
    & > img {
      width: 387px;
      min-height: 387px;
      height: 387px;
    }
  }

  @media (max-width: 375px) {
    width: 339px;
    min-height: 339px;
    height: 339px;
    & > img {
      width: 339px;
      min-height: 339px;
      height: 339px;
    }
  }
  @media (max-width: 320px) {
    width: 284px;
    min-height: 284px;
    height: 284px;
    & > img {
      width: 284px;
      min-height: 284px;
      height: 284px;
    }
  }
`;

interface Props {
  Width: number;
  Images: BlockFile[];
  ImgIndex: number;
  isEditing: boolean;
}

export const ImgColumn: React.FC<Props> = ({
  Width,
  Images,
  ImgIndex,
  isEditing,
}) => {
  return (
    <ImgColumnWrapper>
      {Images[ImgIndex] === undefined ? null : (
        <ImgColumnFullImgWrapper>
          <img src={Images[ImgIndex].FilePath} />
        </ImgColumnFullImgWrapper>
      )}
      {Images[ImgIndex + 1] === undefined ? null : (
        <ImgColumnLastImgWrapper
          style={isEditing ? {opacity: '0.25'} : null}
          Width={Width}
        >
          <img src={Images[ImgIndex + 1].FilePath} />
        </ImgColumnLastImgWrapper>
      )}
    </ImgColumnWrapper>
  );
};
