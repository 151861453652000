import React, {useEffect} from 'react';
import {useImage} from 'contexts/ImageContext';
import styled from 'styled-components';
import {useLocation} from 'react-router-dom';

export const EnlargedImgOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const EnlargedImageContainer = styled.div`
  & > img {
    max-width: 90vw;
    max-height: 90vh;
    /* box-shadow: 0 0 10px #fff; */
  }
`;

const EnlargedImage = () => {
  const {selectedImage, closeImage} = useImage();
  const location = useLocation();

  useEffect(() => {
    if (selectedImage) {
      closeImage();
    }
  }, [location]);

  if (!selectedImage) return null;

  return (
    <EnlargedImgOverlay onClick={closeImage}>
      <EnlargedImageContainer>
        <img src={selectedImage} alt="Enlarged" />
      </EnlargedImageContainer>
    </EnlargedImgOverlay>
  );
};

export default EnlargedImage;
